import { jsx as _jsx } from "react/jsx-runtime";
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useAppDispatch } from 'redux/hooks';
import { mapProductToRoute } from 'utils/product';
export function OrderDetailBackButton({ order }) {
    const dispatch = useAppDispatch();
    return (_jsx(ColumnButton, { type: "button", link: true, startIcon: _jsx(ArrowLeftIcon, { className: "h-4 w-4 stroke-2 text-column-gray-400 hover:text-column-primary-600" }), onClick: () => dispatch(push(`/${mapProductToRoute(order.modelData.product)}/`)), "aria-label": "Back" }));
}
