var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import FreeformCModal from 'components/modals/FreeFormCModal';
import CButton from 'components/CButton';
import { connect } from 'react-redux';
import api from 'api';
import { PasswordField } from 'components/PasswordField';
import Firebase from 'EnoticeFirebase';
import { dwollaInvalidPasswordMessage, dwollaPasswordValidation, passwordValidation } from 'lib/passwordValidators';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
function ResetPassword({ push }) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMatch, setErrorMatch] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState('');
    const [codeVerified, setCodeVerified] = useState(true);
    const [oobCode, setOobCode] = useState('');
    const [dwollaEnabledUser, setDwollaEnabledUser] = useState(false);
    const [validationLoading, setValidationLoading] = useState(true);
    const ButtonStyle = `${isDisabled || loading
        ? 'bg-gray-200 text-gray-700 cursor-default'
        : 'bg-gray-200 focus:border-gray-500 bg-gray-600 text-white'}`;
    const inputStyle = 'flex items-center mr-1 mb-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5';
    useEffect(() => {
        var _a;
        const code = (_a = new URLSearchParams(window.location.search)) === null || _a === void 0 ? void 0 : _a.get('oobCode');
        if (code) {
            setOobCode(code);
            Firebase.auth()
                .verifyPasswordResetCode(code)
                .then(email => {
                setEmail(email);
                setCodeVerified(true);
            })
                .catch(error => {
                setCodeVerified(false);
                setValidationLoading(false);
                console.error(`Action code is invalid${error}`);
            });
        }
    }, []);
    const getUser = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const data = yield api.post('users/get-required-password-security', {
                email
            });
            if (data.success) {
                setDwollaEnabledUser(data.dwollaPasswordSecurityEnabled);
            }
            setValidationLoading(false);
        }
        catch (err) {
            console.error(err);
        }
    });
    useEffect(() => {
        if (email) {
            void getUser();
        }
    }, [email]);
    const matchPassword = () => {
        if (password && confirmPassword) {
            if (dwollaEnabledUser && !dwollaPasswordValidation.test(password)) {
                setDisabled(true);
                return;
            }
            if (!passwordValidation.test(password)) {
                setDisabled(true);
                return;
            }
            if (password.length === confirmPassword.length &&
                password === confirmPassword) {
                setErrorMatch(false);
                if (password.length > 8) {
                    setDisabled(false);
                    return true;
                }
            }
            else {
                setErrorMatch(true);
                setDisabled(true);
                return false;
            }
        }
    };
    useEffect(() => {
        if (password) {
            if (dwollaEnabledUser) {
                if (!dwollaPasswordValidation.test(password)) {
                    setNewPasswordError(dwollaInvalidPasswordMessage);
                    setDisabled(true);
                    return;
                }
            }
            else {
                if (password.length < 9) {
                    setNewPasswordError('Password must be at least 9 characters');
                    return;
                }
                if (!passwordValidation.test(password)) {
                    setNewPasswordError('Password must contain a number or a special character');
                    return;
                }
            }
            setNewPasswordError('');
        }
    }, [password]);
    useEffect(() => {
        matchPassword();
    }, [password, confirmPassword]);
    const handleReset = () => __awaiter(this, void 0, void 0, function* () {
        if (matchPassword()) {
            setDisabled(true);
            setLoading(true);
            Firebase.auth()
                .confirmPasswordReset(oobCode, password)
                .then(() => __awaiter(this, void 0, void 0, function* () {
                yield api.post('users/update-temporary-password-status', {
                    email
                });
                setLoading(false);
                dispatch(ToastActions.toastSuccess({
                    headerText: 'Success!',
                    bodyText: 'Your password is reset. Please log in with your updated credentials.'
                }));
                setTimeout(() => {
                    push('/login');
                }, 2000);
            }))
                .catch(error => {
                console.error('error in password reset', error);
            });
        }
    });
    const [buttonText, handleClick, buttonStyle] = [
        'Set New Password',
        handleReset,
        ButtonStyle
    ];
    return (_jsx("div", { children: _jsxs("div", { children: [_jsx("div", Object.assign({ className: "hidden md:block h-0 float-right" }, { children: _jsx("img", { className: "-mt-1/3 -mr-20", src: "https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff" }) })), _jsx("div", Object.assign({ className: "hidden md:block h-0 float-left" }, { children: _jsx("img", { className: "-ml-8 mt-6/12", src: "https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff" }) })), validationLoading ? (_jsx("div", Object.assign({ className: "flex h-screen items-center justify-center" }, { children: _jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-500 h-5 w-5" }) }))) : (_jsx(FreeformCModal, Object.assign({ noExitOutsideModal: true, setOpen: () => push('/login'), header: codeVerified
                        ? 'Reset your password'
                        : 'Try resetting your password again' }, { children: codeVerified ? (_jsx("section", Object.assign({ className: "mt-3" }, { children: _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "items-start flex-col sm:mr-4" }, { children: [_jsx("label", Object.assign({ id: "email", htmlFor: "email" }, { children: _jsx("input", { id: "email", name: "email", type: "email", placeholder: "Email", className: `${inputStyle}`, value: email, disabled: true, onChange: e => setEmail(e.target.value) }) })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx(PasswordField, { id: "password", required: true, value: password, placeHolderText: "New password *", onValueChange: (value) => setPassword(value), "data-testid": "newPassword" }), newPasswordError && (_jsx("div", Object.assign({ className: "text-column-red-600 text-sm ml-1" }, { children: newPasswordError }))), _jsx(PasswordField, { id: "cpassword", required: true, value: confirmPassword, placeHolderText: "Confirm new password *", onValueChange: (value) => setConfirmPassword(value), "data-testid": "newPassword" }), errorMatch && (_jsx("div", Object.assign({ className: "text-column-red-600 text-sm ml-1" }, { children: "Passwords do not match" })))] }))] })), _jsx("div", { children: _jsx(CButton, Object.assign({ id: "reset-password", onClick: () => {
                                            void handleClick();
                                        }, startClasses: 'col-span-0', middleClasses: "col-span-10", disabled: isDisabled || loading, className: ` ${buttonStyle} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base transition w-3/6`, startIcon: loading ? (_jsx("div", { className: "loader ease-linear rounded-full border-4 text-center border-t-4 border-gray-500 h-5 w-5" })) : ('') }, { children: buttonText })) })] }) }))) : (_jsx("div", Object.assign({ className: "flex justify-center align-center mt-8" }, { children: "Your request to reset your password has expired or the link has already been used" }))) })))] }) }));
}
export default connect(null, { push })(ResetPassword);
