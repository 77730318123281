var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CancelOrSubmitModal } from '../CancelOrSubmitModal';
/**
 * Modal to confirm before deleting a table item
 * */
export default function ArchiveTableItemModal({ renderArchiveWarning, itemToArchive, onArchive, onClose, id }) {
    const archiveWarning = renderArchiveWarning(itemToArchive);
    return (_jsx(CancelOrSubmitModal, Object.assign({ tertiaryButtonText: "Cancel", primaryButtonText: archiveWarning.buttonText || 'Archive', onSubmit: () => __awaiter(this, void 0, void 0, function* () {
            yield onArchive(itemToArchive);
            onClose();
        }), onClose: onClose, header: archiveWarning.header, destructive: true, id: id, showLoadingSpinner: true }, { children: _jsx("div", Object.assign({ className: "py-8" }, { children: _jsx("p", Object.assign({ className: "text-column-gray-400" }, { children: archiveWarning.body })) })) })));
}
