var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFileTypeFromFile } from 'lib/files';
import { logAndCaptureException } from 'utils';
import getDocumentProperties from 'routes/placeScroll/helpers/getDocumentProperties';
import { ColumnService } from 'lib/services/directory';
import { AdTypeRecommendation, getAdTypeRecommendation as getAdTypeRecommendationFromProperties } from './getAdTypeRecommendationFromProperties';
export const getAdTypeRecommendationFromFileWithUploadRef = (uploadedFileAndRef, allowImages) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const fileType = getFileTypeFromFile(uploadedFileAndRef.file);
        if (!fileType) {
            throw new Error('Unable to retrieve fileType for file upload');
        }
        const properties = yield getDocumentProperties(uploadedFileAndRef.uploadRef, fileType);
        return getAdTypeRecommendationFromProperties(properties, allowImages);
    }
    catch (e) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Unable to retrieve liner or display recommendation');
        return AdTypeRecommendation.NoRecommendation;
    }
});
