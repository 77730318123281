import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { Popover } from 'lib/components/Popover';
import moment from 'moment';
import React from 'react';
function PublicationDateBadge({ publicationDate }) {
    return (_jsx(Badge, { children: moment(publicationDate, 'YYYY-MM-DD').format('MMM D, YYYY') }));
}
export function AdsTableRowPublicationDateColumn({ publishingdates }) {
    // Show an empty column if there are no publishing dates (e.g., on a draft)
    if (!publishingdates) {
        return _jsx(_Fragment, {});
    }
    const publicationDates = publishingdates.split(', ');
    if (publicationDates.length === 1) {
        const publicationDate = publicationDates[0];
        return _jsx(PublicationDateBadge, { publicationDate: publicationDate });
    }
    const [firstPublicationDate, ...popoverPublicationDates] = publicationDates;
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-1" }, { children: [_jsx(PublicationDateBadge, { publicationDate: firstPublicationDate }), _jsxs("div", { children: ["+", popoverPublicationDates.length] }), _jsx(Popover, Object.assign({ id: `publication-date-popover`, activator: _jsx(ChevronDownIcon, { className: "w-4 h-4" }), alignment: "right", stopPropagation: true }, { children: _jsx("div", Object.assign({ className: "flex flex-col m-2 gap-2 pr-5" }, { children: popoverPublicationDates.map(publicationDate => (_jsx(PublicationDateBadge, { publicationDate: publicationDate }, publicationDate))) })) }))] })));
}
