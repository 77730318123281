var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchSelectionControlledCard } from 'lib/components/Card/SwitchSelectionControlledCard';
import { ColumnButton } from 'lib/components/ColumnButton';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import { selectUser } from 'redux/auth';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { apiPost } from 'api/typed';
import { UpdateSettingRequestTypes, UpdateSettingRequestStatus } from 'lib/types/updateSettingsRequest';
import LoadingState from 'components/LoadingState';
import { getFirebaseContext } from 'utils/firebase';
export default function UpdatePublisherSettings({ publisherSettingInfo, onClose }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const [publisherBillingInfo, setPublisherBillingInfo] = useState(publisherSettingInfo);
    const [reqReasonContent, setReqReasonContent] = useState('');
    const [statusUpdateRequestLoading, setStatusUpdateRequestLoading] = useState(false);
    const getNinetyDaysOverdueInvoices = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(user))
            return false;
        if (user.data().allowedOrganizations) {
            const res = yield apiPost('customers/bulk-invoicing-eligibility', {
                organizationId: publisherBillingInfo.organization.id
            });
            if (res.success) {
                return true;
            }
        }
        return false;
    });
    const { value: allowSendingBulkInvoiceingRequest, isLoading: loading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () { return yield getNinetyDaysOverdueInvoices(); }),
        dependencies: [(_a = user === null || user === void 0 ? void 0 : user.data().activeOrganization) === null || _a === void 0 ? void 0 : _a.id]
    });
    const getResourceInitiatedRequests = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(user))
            return;
        const query = yield getFirebaseContext()
            .updateSettingRequestsRef()
            .where('resource', '==', publisherBillingInfo.customerOrg)
            .where('status', '==', UpdateSettingRequestStatus.INITIATED)
            .where('type', 'in', [
            UpdateSettingRequestTypes.DISABLE_BULK_INVOICE,
            UpdateSettingRequestTypes.ENABLE_BULK_INVOICE
        ])
            .limit(1)
            .get();
        if (query.docs.length > 0) {
            return true;
        }
        return false;
    });
    const { value: hasExistingSettingsUpdateRequest, isLoading: resourceInitiatedAnySettingUpdateRequestLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () { return yield getResourceInitiatedRequests(); }),
        dependencies: []
    });
    const sendBillingStatusChangeRequest = () => __awaiter(this, void 0, void 0, function* () {
        setStatusUpdateRequestLoading(true);
        const response = yield apiPost('customers/send-update-billing-status-request', {
            newspaperId: publisherBillingInfo.organization.id,
            billingStatusUpdateRequestReason: reqReasonContent,
            requestType: publisherBillingInfo.isBillingEnabled
                ? UpdateSettingRequestTypes.ENABLE_BULK_INVOICE
                : UpdateSettingRequestTypes.DISABLE_BULK_INVOICE
        });
        setStatusUpdateRequestLoading(false);
        if (response.success) {
            dispatch(ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: `${publisherBillingInfo.isBillingEnabled ? 'Enable' : 'Disable'} bulk invoicing request sent successfully.`
            }));
            onClose();
        }
        if (response.error) {
            dispatch(ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'There is some error in sending request.'
            }));
        }
    });
    const loadingInProgress = loading || resourceInitiatedAnySettingUpdateRequestLoading;
    return (_jsxs(FullScreenModal, Object.assign({ id: "update-customer-or-customerOrg-publication-info", headerText: 'Request Billing Settings Changes', onClose: onClose, submittable: null }, { children: [loadingInProgress && _jsx(LoadingState, {}), !loadingInProgress && (_jsxs(CardGridLayout, Object.assign({ header: {
                    title: 'Billing Properties',
                    description: `Review billing settings of ${publisherBillingInfo.organization.data().name}.`
                } }, { children: [hasExistingSettingsUpdateRequest && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx("span", Object.assign({ className: "text-column-gray-400 text-sm font-medium" }, { children: "Your request to update bulk invoicing settings has been sent and in process. You will be notified through email once processed." })) }))), !hasExistingSettingsUpdateRequest && (_jsx(SwitchSelectionControlledCard, Object.assign({ labelProps: {
                            label: 'Request to update bulk invoicing settings',
                            description: 'If enabled, you will receive monthly bulk invoices.',
                            value: (_b = publisherBillingInfo.isBillingEnabled) !== null && _b !== void 0 ? _b : false,
                            onChange: newValue => {
                                setPublisherBillingInfo(Object.assign(Object.assign({}, publisherBillingInfo), { isBillingEnabled: newValue }));
                            },
                            disabled: !allowSendingBulkInvoiceingRequest
                        }, switchStateChanged: !!publisherSettingInfo.isBillingEnabled !==
                            !!publisherBillingInfo.isBillingEnabled, header: "" }, { children: _jsxs(GridInput, Object.assign({ fullWidth: true }, { children: [_jsx("p", Object.assign({ className: "mb-2 text-column-gray-400 text-sm" }, { children: `Leave a message for ${publisherBillingInfo.organization.data().name} regarding this request.` })), _jsx("textarea", { name: "billing-status-change-request-reason", id: "billing-status-change-request-reason", className: "border border-column-gray-100 rounded-md font-medium text-sm py-4 px-4 outline-none resize-none w-full focus:border focus:border-primary-500 focus:shadow-outline-blue", maxLength: 500, placeholder: "Leave a reason...", value: reqReasonContent, onChange: e => setReqReasonContent(e.target.value) }), _jsx("div", Object.assign({ className: "flex justify-end mt-4" }, { children: _jsx(ColumnButton, { id: "billing-status-change-request", buttonText: "Send request", onClick: sendBillingStatusChangeRequest, loading: statusUpdateRequestLoading, type: "button", primary: true }) }))] })) }))), !allowSendingBulkInvoiceingRequest &&
                        !loading &&
                        !resourceInitiatedAnySettingUpdateRequestLoading && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx("span", Object.assign({ className: "text-sm text-column-gray-400" }, { children: "You cannot request to enable bulk invoicing because your organization has invoices more than 90 days overdue." })) })))] })))] })));
}
