import { jsx as _jsx } from "react/jsx-runtime";
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import React from 'react';
export function UploadMultipleFilesModal({ setShowUploadMultipleFilesModal, cancel, confirm }) {
    return (_jsx(CancelOrSubmitModal, Object.assign({ id: "upload-multiple-files-modal", header: "Confirm action", onClose: () => {
            cancel();
            setShowUploadMultipleFilesModal(null);
        }, tertiaryButtonText: "Back", primaryButtonText: "Continue", onSubmit: () => {
            confirm();
            setShowUploadMultipleFilesModal(null);
        } }, { children: _jsx("p", Object.assign({ className: "py-8 text-column-gray-400 font-medium text-sm" }, { children: "If you upload multiple files, then the publisher will receive your files without formatting, and the proof and estimated pricing will not be available until the publisher has finalized the layout of your notice. Any formatting you've created in Column may be lost." })) })));
}
