import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { shouldShowPublisherRegistrationOption } from 'layouts/registrationLayout/registrationLayoutHelpers';
import { OccupationType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import OccupationSelectItem from './OccupationSelectItem';
import OccupationsHeader from './OccupationsHeader';
import otherOrganization from './occupationPreviewImages/otherOrganization.svg';
import government from './occupationPreviewImages/government.svg';
import individual from './occupationPreviewImages/individual.svg';
import lawyer from './occupationPreviewImages/lawyer.svg';
export default function Occupations({ onOccupationTypeChange, onOccupationSelect, occupationType, loading, isDisplayingOnlyOrganizationOccupations }) {
    const user = useAppSelector(selectUser);
    return (_jsxs("div", { children: [_jsx(OccupationsHeader, {}), _jsxs("div", Object.assign({ className: "flex gap-2 mt-4" }, { children: [exists(user) && shouldShowPublisherRegistrationOption(user) && (_jsx(OccupationSelectItem, { occupationItemEnum: OccupationType.publishing, setOccupationType: onOccupationTypeChange, occupationType: occupationType, img: otherOrganization })), _jsx(OccupationSelectItem, { occupationItemEnum: OccupationType.government_official, setOccupationType: onOccupationTypeChange, occupationType: occupationType, img: government }), _jsx(OccupationSelectItem, { occupationItemEnum: OccupationType.lawyer, setOccupationType: onOccupationTypeChange, occupationType: occupationType, img: lawyer }), _jsx(OccupationSelectItem, { occupationItemEnum: OccupationType.other_organization, setOccupationType: onOccupationTypeChange, occupationType: occupationType, img: otherOrganization }), !isDisplayingOnlyOrganizationOccupations && (_jsx(OccupationSelectItem, { occupationItemEnum: OccupationType.individual, setOccupationType: onOccupationTypeChange, occupationType: occupationType, img: individual }))] })), _jsx(AdvertiserRegistrationFooter, { id: "occupation-submit", backButtonText: "Go back", nextButtonText: "Continue", onNextButtonClick: onOccupationSelect, loading: loading, disableNextButton: !occupationType, hideBackButton: true })] }));
}
