import { wrapError } from '../types/responses';
export class ColumnError extends Error {
    constructor(status, message, other) {
        super(message, other);
        this.status = status;
    }
}
ColumnError.isColumnError = (error) => {
    return !!error.status;
};
export class BadRequestError extends ColumnError {
    constructor(message, other) {
        super(400, message, other);
    }
}
export class UnauthorizedError extends ColumnError {
    constructor(message, other) {
        super(401, message, other);
    }
}
export class ForbiddenError extends ColumnError {
    constructor(message, other) {
        super(403, message, other);
    }
}
export class NotFoundError extends ColumnError {
    constructor(message, other) {
        super(404, message, other);
    }
}
export class TimeoutError extends ColumnError {
    constructor(message, other) {
        super(408, message, other);
    }
}
export class TeapotError extends ColumnError {
    constructor(message, other) {
        super(418, message, other);
    }
}
export class TooManyRequestsError extends ColumnError {
    constructor(message, other) {
        super(429, message, other);
    }
}
export class InternalServerError extends ColumnError {
    constructor(message, other) {
        super(500, message, other);
    }
}
export const wrapErrorAsColumnError = (error, ErrorClass = InternalServerError) => {
    if (ColumnError.isColumnError(error)) {
        return wrapError(error);
    }
    return wrapError(new ErrorClass(error.message, error));
};
