var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { TextField } from 'lib/components/TextField';
import { PopoverContext } from 'lib/components/Popover';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
export function AutocompleteInput(_a) {
    var { id, onArrowDown, onArrowUp, onEnter, onBlur } = _a, textFieldProps = __rest(_a, ["id", "onArrowDown", "onArrowUp", "onEnter", "onBlur"]);
    const { open, setOpen } = useContext(PopoverContext);
    function handleBlur() {
        onBlur && onBlur();
        setOpen(false);
    }
    function handleKeyPress(event) {
        const { key } = event;
        if (key === 'Enter') {
            event.preventDefault();
            open && onEnter();
            return setOpen(!open);
        }
        if (key === 'ArrowDown') {
            event.preventDefault();
            onArrowDown();
        }
        else if (key === 'ArrowUp') {
            event.preventDefault();
            onArrowUp();
        }
        setOpen(true);
    }
    return (_jsx(TextField, Object.assign({ id: id, role: "combobox", autoComplete: "off" }, textFieldProps, { onBlur: handleBlur, onKeyDown: handleKeyPress, onClick: () => setOpen(true), suffix: _jsx(ChevronDownIcon, { "aria-hidden": "true", className: "w-4" }) })));
}
