var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from '../../utils/errors';
import { getFulfilled, getRejected, sanitize } from '../../helpers';
export function uploadFilesToStorage(storage, files, uploadFolder) {
    return __awaiter(this, void 0, void 0, function* () {
        const uploadFilesAndRefs = yield Promise.allSettled(files.map((file) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            // Note to future spelunkers: our unit tests are annoyingly sensitive to changes in this
            // method, so be careful!
            const uploadRef = storage
                .ref('/')
                .child(`${uploadFolder}/${new Date().getTime()}/${sanitize(file.name)}`);
            const uploadSnap = yield uploadRef.put(file);
            return {
                file,
                uploadRef: (_a = uploadSnap === null || uploadSnap === void 0 ? void 0 : uploadSnap.ref) !== null && _a !== void 0 ? _a : uploadRef
            };
        })));
        const successfulFilesAndUploads = getFulfilled(uploadFilesAndRefs);
        const failedFilesAndUploads = getRejected(uploadFilesAndRefs);
        return {
            successfulFilesAndUploads,
            failedFilesAndUploads
        };
    });
}
export function useFirebaseStorageUpload({ storage, uploadFolder, uploadConfirmation, onFileUpload }) {
    const [uploadState, setUploadState] = useState('idle');
    const [failedUploads, setFailedUploads] = useState();
    const uploadFiles = (files) => __awaiter(this, void 0, void 0, function* () {
        if (!files || files.length === 0)
            return;
        const isConfirmed = uploadConfirmation ? yield uploadConfirmation() : true;
        if (isConfirmed) {
            setUploadState('uploading');
            const { successfulFilesAndUploads, failedFilesAndUploads } = yield uploadFilesToStorage(storage, files, uploadFolder);
            setFailedUploads(failedFilesAndUploads);
            if (failedUploads && failedUploads.length > 0) {
                getErrorReporter().logAndCaptureError(ColumnService.STATIC_ASSETS, new Error('Failed to upload file'), 'Failed to upload file', {
                    failedUploads: failedUploads.join(', ')
                });
            }
            successfulFilesAndUploads &&
                successfulFilesAndUploads.length > 0 &&
                onFileUpload &&
                onFileUpload(successfulFilesAndUploads);
        }
        setUploadState('idle');
    });
    return { uploadState, uploadFiles, failedUploads };
}
