var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FullScreenModal from 'components/FullScreenModal';
import { exists } from 'lib/types';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useLoading } from 'lib/components/hooks/useLoading';
import { getFirebaseContext } from 'utils/firebase';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { isColumnUser, removeUndefinedFields, replaceUndefinedWithDelete } from 'lib/helpers';
import LoadingState from 'components/LoadingState';
import { htmlToStoragePath } from '../htmlHelpers';
import BasicPropertiesCard from '../BasicPropertiesCard';
import { AffidavitConfiguration } from './AffidavitConfiguration';
import { extractFormData } from './templateHelpers';
import { AffidavitPreview } from './AffidavitPreview';
import { updateTemplateDefaultStatus } from '../statusHelpers';
export default function AffidavitTemplateEditForm({ affidavitTemplate, affidavitTemplateRef, activeOrganization, onClose }) {
    const user = useAppSelector(selectUser);
    const [formData, setFormData] = useState();
    const { value: initialData, isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            return extractFormData(activeOrganization, affidavitTemplateRef, affidavitTemplate);
        }),
        dependencies: [affidavitTemplateRef === null || affidavitTemplateRef === void 0 ? void 0 : affidavitTemplateRef.id, affidavitTemplate.storagePath]
    });
    useEffect(() => {
        if (!initialData)
            return;
        setFormData(initialData);
    }, [Boolean(initialData)]);
    const [submitLoading, submitWithLoading] = useLoading();
    const onSubmit = () => submitWithLoading(() => __awaiter(this, void 0, void 0, function* () {
        if (!formData)
            return;
        const { affidavitTemplateHTML, blockWidthInInches, logoHeightInInches, isColumnManaged, defaultStatus, logoSource, publisher, fontSize, name } = formData;
        const storagePath = yield htmlToStoragePath(activeOrganization, affidavitTemplateHTML, isColumnManaged ? 'column' : 'custom');
        const data = {
            version: 'v2024-03-25',
            blockWidthInInches,
            logoHeightInInches,
            isColumnManaged,
            storagePath,
            logoSource,
            publisher,
            fontSize,
            name
        };
        const ctx = getFirebaseContext();
        let templateRef;
        if (affidavitTemplateRef) {
            templateRef = affidavitTemplateRef;
            const cleanData = replaceUndefinedWithDelete(ctx, data);
            yield templateRef.update(cleanData);
        }
        else {
            const cleanData = removeUndefinedFields(data);
            templateRef = yield ctx.affidavitTemplatesRef().add(cleanData);
        }
        yield updateTemplateDefaultStatus(activeOrganization, templateRef, {
            defaultStatus,
            isColumnManaged,
            newStoragePath: storagePath
        });
        onClose();
    }));
    const disabled = !!affidavitTemplateRef && columnObjectsAreEqual(initialData, formData);
    return (_jsx(FullScreenModal, Object.assign({ headerText: affidavitTemplateRef
            ? 'Edit Affidavit Template'
            : 'Create Affidavit Template', submittable: {
            buttonText: 'Save',
            onSubmit,
            disabled
        }, previewable: {
            renderPreview: () => (_jsx(AffidavitPreview, { activeOrganization: activeOrganization, formData: formData })),
            withBorder: false
        }, onClose: onClose, id: "edit-affidavit-template", loading: submitLoading }, { children: !isLoading && formData ? (_jsxs(_Fragment, { children: [_jsx(BasicPropertiesCard, { affidavitTemplateEditData: formData, onChangeAffidavitTemplateEditData: data => setFormData(Object.assign(Object.assign({}, formData), data)) }), exists(user) && isColumnUser(user) && (_jsx(AffidavitConfiguration, { formData: formData, onChange: setFormData }))] })) : (_jsx(LoadingState, {})) })));
}
