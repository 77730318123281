var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import { InputAccessories } from 'lib/components/InputAccessories';
import { logAndCaptureException } from 'utils';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnService } from 'lib/services/directory';
import { getLayoutSettingsFromOrganization, SAMPLE_NOTICE_CONTENT_OPTIONS, generateNoticePreview, SAMPLE_NOTICE_COLUMN_OPTIONS, SAMPLE_NOTICE_PUBLICATION_OPTIONS } from '../../shared/previews';
import { PreviewContainer } from '../../shared/PreviewContainer';
function PreviewDownloadButton({ previewContent, totalRuns, columnWidth, buttonText, format, template }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [downloading, setDownloading] = useState(false);
    const downloadFile = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(activeOrganization)) {
            return;
        }
        setDownloading(true);
        const previewData = yield generateNoticePreview(template, getLayoutSettingsFromOrganization(activeOrganization), {
            format,
            totalRuns,
            columnWidth,
            previewContent,
            outputToBase64: false
        });
        downloadFileContentsInBrowser(`sample_${new Date().getTime()}.${format}`, previewData, 'application/octet-stream');
        setDownloading(false);
    });
    return (_jsx("div", Object.assign({ className: "flex-1" }, { children: _jsx(ColumnButton, { buttonText: buttonText, fullWidth: true, secondary: true, onClick: downloadFile, loading: downloading, type: "button" }) })));
}
export default function TemplatePreviewTab({ template }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [preview, setPreview] = useState();
    const { value: defaultContent } = SAMPLE_NOTICE_CONTENT_OPTIONS[0];
    const [previewContent, setPreviewContent] = useState(defaultContent);
    const [columnWidth, setColumnWidth] = useState(1);
    const [totalRuns, setTotalRuns] = useState(1);
    useEffect(() => {
        let current = true;
        if (!exists(activeOrganization)) {
            return;
        }
        const regeneratePreview = () => __awaiter(this, void 0, void 0, function* () {
            setPreview(undefined);
            try {
                const data = yield generateNoticePreview(template, activeOrganization.data(), {
                    previewContent,
                    outputToBase64: true,
                    totalRuns,
                    columnWidth,
                    format: 'jpg'
                });
                if (current) {
                    setPreview(data);
                }
            }
            catch (err) {
                logAndCaptureException(ColumnService.INDESIGN, err, 'Failed to generate template preview in the Template Drawer', { templateId: template.id, publisherId: activeOrganization.id });
            }
        });
        void regeneratePreview();
        return () => {
            current = false;
        };
    }, [previewContent, columnWidth, totalRuns, template === null || template === void 0 ? void 0 : template.id]);
    return (_jsxs("div", Object.assign({ className: "py-4 flex flex-col gap-y-6" }, { children: [_jsx(PreviewContainer, { labelText: "", preview: preview }), _jsx(InputAccessories, Object.assign({ id: "preview-download-options", labelText: "Download preview" }, { children: _jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsx(PreviewDownloadButton, { previewContent: previewContent, totalRuns: totalRuns, columnWidth: columnWidth, buttonText: "Download as IDML", format: "idml", template: template }), _jsx(PreviewDownloadButton, { previewContent: previewContent, totalRuns: totalRuns, columnWidth: columnWidth, buttonText: "Download as PDF", format: "pdf", template: template }), _jsx(PreviewDownloadButton, { previewContent: previewContent, totalRuns: totalRuns, columnWidth: columnWidth, buttonText: "Download as JPG", format: "jpg", template: template })] })) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "template-preview-content", labelText: "Preview content", onChange: setPreviewContent, options: SAMPLE_NOTICE_CONTENT_OPTIONS, value: previewContent }) })), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Column width", value: columnWidth.toString(), onChange: stringValue => setColumnWidth(Number(stringValue)), options: SAMPLE_NOTICE_COLUMN_OPTIONS, id: "template-preview-number-of-columns-select" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Publication dates", value: totalRuns.toString(), onChange: stringValue => setTotalRuns(Number(stringValue)), options: SAMPLE_NOTICE_PUBLICATION_OPTIONS, id: "template-preview-number-of-runs" }) })] })));
}
