var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
const styles = (theme) => createStyles({
    spinner: {
        marginRight: theme.spacing(1)
    }
});
function AdornedButton(props) {
    const { children, loading, classes } = props, rest = __rest(props, ["children", "loading", "classes"]);
    return (_jsxs(Button, Object.assign({}, rest, { children: [loading && _jsx(CircularProgress, { className: classes.spinner, size: 20 }), children] })));
}
export default withStyles(styles, { withTheme: true })(AdornedButton);
