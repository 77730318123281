var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Firebase from 'EnoticeFirebase';
import { createPublicationIssueAttachment } from 'lib/services/publicationIssueAttachmentService';
import { getFirebaseContext } from 'utils/firebase';
import { uuidv4 } from 'lib/utils/uuid';
const uploadStandardFile = (metaFile, filePath, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    const fullPath = `${filePath}/${fileName}`;
    const fileSnapshot = yield Firebase.storage()
        .ref(fullPath)
        .put(metaFile.file);
    return fileSnapshot;
});
const getPath = () => {
    return `/documentcloud/notices/${new Date().getTime()}`;
};
/**
 * Generates a filename for a publication issue attachment
 * Name format: TTT-YYYYMMDD-CODE-UUID.ext
 *   TTT: first 3 letters of attachment type
 *   YYYYMMDD: publication date
 *   CODE: newspaper code
 *   UUID: 8 character uuid
 */
const generateFileName = (fileExtension, type, publicationDate, newspaperCode) => {
    const uuid = uuidv4().substring(0, 8);
    const replacedPublicationDate = publicationDate.replace(/-/g, '');
    const typeStr = type.substring(0, 3).toUpperCase();
    return `${typeStr}-${replacedPublicationDate}-${newspaperCode}-${uuid}.${fileExtension}`;
};
const getFileAndPathForPublicationIssueAttachment = (fileExtension, type, newspaperCode, publicationDate) => {
    const fileName = generateFileName(fileExtension, type, publicationDate, newspaperCode);
    const filePath = getPath();
    return { fileName, filePath };
};
const handleAttachmentUpload = (publicationIssueId, metaFile, type, newspaperCode, publicationDate) => __awaiter(void 0, void 0, void 0, function* () {
    const fileExtension = metaFile.file.name.split('.').pop() || 'pdf';
    const { fileName, filePath } = getFileAndPathForPublicationIssueAttachment(fileExtension, type, newspaperCode, publicationDate);
    // get extension from file name
    const fileSnapshot = yield uploadStandardFile(metaFile, filePath, fileName);
    const downloadUrl = yield fileSnapshot.ref.getDownloadURL();
    const createAttachmentParams = {
        publicationIssueId,
        downloadUrl,
        name: fileName,
        storagePath: fileSnapshot.ref.fullPath,
        type
    };
    yield createPublicationIssueAttachment(getFirebaseContext(), createAttachmentParams);
});
/**
 * Uploads a list of attachments and creates the PublicationIssueAttachment object for each
 */
export const handlePublicationIssueAttachmentUploads = (publicationIssueId, newspaperCode, publicationDate, attachments, type) => __awaiter(void 0, void 0, void 0, function* () {
    const promises = attachments.map((file) => __awaiter(void 0, void 0, void 0, function* () {
        return handleAttachmentUpload(publicationIssueId, file, type, newspaperCode, publicationDate);
    }));
    yield Promise.all(promises);
});
