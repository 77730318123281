var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectCustomerRef } from 'redux/placement';
import { exists } from 'lib/types';
export function useFetchCustomer() {
    const customerRef = useAppSelector(selectCustomerRef);
    const [customer, setCustomer] = useState();
    useEffect(() => {
        const fetchCustomer = () => __awaiter(this, void 0, void 0, function* () {
            if (!customerRef) {
                return setCustomer(null);
            }
            const customerSnap = yield customerRef.get();
            setCustomer(exists(customerSnap) ? customerSnap : null);
        });
        void fetchCustomer();
    }, [customerRef === null || customerRef === void 0 ? void 0 : customerRef.id]);
    return customer;
}
