import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import TabGroup from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import { Product } from 'lib/enums';
import AdTemplateTable from './adTemplateTable';
import { LayoutSettings } from './layoutSettings';
import { Preview } from './preview';
const TEMPLATES_TAB = {
    label: 'Templates',
    enabled: true,
    id: 'templates-tab'
};
const LAYOUT_TAB = {
    label: 'Layout',
    enabled: true,
    id: 'layout-tab'
};
const PREVIEW_TAB = {
    label: 'Preview',
    enabled: true,
    id: 'preview-tab'
};
const DESIGN_AND_LAYOUT_TABS = [TEMPLATES_TAB, LAYOUT_TAB, PREVIEW_TAB];
export default function DesignAndLayoutSettings({ activeOrganization }) {
    const [activeTab, setActiveTab] = useState(TEMPLATES_TAB);
    return (_jsxs(SettingsPage, { children: [_jsx(TabGroup, { onClickTab: setActiveTab, activeTab: activeTab, tabs: DESIGN_AND_LAYOUT_TABS, id: "newspaper-settings-design-and-layout-tabs" }), activeTab.label === TEMPLATES_TAB.label && (_jsx(AdTemplateTable, { activeOrganization: activeOrganization, productLine: Product.Notice })), activeTab.label === LAYOUT_TAB.label && _jsx(LayoutSettings, {}), activeTab.label === PREVIEW_TAB.label && _jsx(Preview, {})] }));
}
