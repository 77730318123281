import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { dispatchValidationEvent } from '../helpers/inputValidation';
export function Form({ id, children, nativeValidate = false, 'aria-label': ariaLabel, onSubmit }) {
    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        const formElement = event.target;
        const { valid, firstInvalidFormField } = nativeValidate
            ? { valid: true, firstInvalidFormField: null }
            : checkFormValidity(formElement);
        if (!valid) {
            // If any of the internal form elements are invalid, send a validation event to each element
            // Form inputs will receive the event and run their own validation handlers to display errors,
            // and to put focus on the first invalid element
            firstInvalidFormField === null || firstInvalidFormField === void 0 ? void 0 : firstInvalidFormField.focus();
            return dispatchValidationEvent({ toForm: formElement });
        }
        // If the form is valid, call the onSubmit callback
        onSubmit(event);
    }, [onSubmit]);
    return (_jsx("form", Object.assign({ id: id, noValidate: !nativeValidate, onSubmit: handleSubmit, "aria-label": ariaLabel }, { children: children })));
}
function checkFormValidity(formElement) {
    const inputElements = Array.from(formElement.elements);
    // Check native HTML field validity (ex: <input />)
    const hasInvalidNativeFields = !formElement.checkValidity();
    // Check accessible custom form elements
    const hasInvalidCustomFields = inputElements.some(element => { var _a; return ((_a = element.attributes.getNamedItem('aria-invalid')) === null || _a === void 0 ? void 0 : _a.value) === 'true'; });
    const valid = !hasInvalidNativeFields && !hasInvalidCustomFields;
    // Get the first invalid form element
    const firstInvalidFormField = !valid
        ? inputElements.find(element => {
            var _a;
            return !element.checkValidity() ||
                ((_a = element.attributes.getNamedItem('aria-invalid')) === null || _a === void 0 ? void 0 : _a.value) === 'true';
        })
        : null;
    return { valid, firstInvalidFormField };
}
