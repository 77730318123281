var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import { filerOrgHasCustomerOrgWithBulkPaymentV2Enabled, userHasCustomerWithBulkPaymentV2Enabled } from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import { getOrThrow } from 'lib/utils/refs';
import { isPublisher } from 'lib/utils/users';
/**
 * Determines whether the user is on bulk invoice v1 service or v2 or neither
 * @param {ESnapshotExists<EUser>} user current user snapshot
 */
export const getBulkInvoiceServicesStatus = (user) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if (!exists(user)) {
        return false;
    }
    let currentUser = user;
    const shadowUserId = user.data().shadowUser;
    if (shadowUserId) {
        currentUser = yield getOrThrow(getFirebaseContext().usersRef().doc(shadowUserId));
    }
    const activeOrganization = currentUser.data().activeOrganization
        ? yield getOrThrow(currentUser.data().activeOrganization)
        : null;
    if ((activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data().bulkPaymentEnabled_v2) ||
        currentUser.data().bulkPaymentEnabled_v2) {
        return true;
    }
    const parentOrganization = yield ((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data().parent) === null || _a === void 0 ? void 0 : _a.get());
    if (isPublisher(currentUser) &&
        (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data().bulkPaymentEnabled_v2) === undefined &&
        ((_b = parentOrganization === null || parentOrganization === void 0 ? void 0 : parentOrganization.data()) === null || _b === void 0 ? void 0 : _b.bulkPaymentEnabled_v2)) {
        return true;
    }
    const userHasCustomerObjectWithBulkPaymentV2Enabled = yield userHasCustomerWithBulkPaymentV2Enabled(getFirebaseContext(), currentUser);
    const userHasCustomerOrganizationWithBulkPaymentV2Enabled = !!activeOrganization &&
        (yield filerOrgHasCustomerOrgWithBulkPaymentV2Enabled(getFirebaseContext(), activeOrganization));
    if (userHasCustomerObjectWithBulkPaymentV2Enabled ||
        userHasCustomerOrganizationWithBulkPaymentV2Enabled) {
        return true;
    }
    return false;
});
