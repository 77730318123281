var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { ColumnButton } from 'lib/components/ColumnButton';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Form } from 'lib/components/Form';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
export default function FullScreenModal({ submittable, previewable, headerText, children, onClose, loading, id }) {
    const [submitting, setSubmitting] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    return (_jsx(Form, Object.assign({ onSubmit: () => __awaiter(this, void 0, void 0, function* () {
            setSubmitting(true);
            yield (submittable === null || submittable === void 0 ? void 0 : submittable.onSubmit());
        }) }, { children: _jsxs("div", Object.assign({ className: "fixed overflow-none z-100 bg-column-gray-50 inset-0" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between py-4 px-6 bg-white border border-column-gray-100 sticky top-0 z-100", id: id }, { children: [confirmClose && (_jsx(ExitConfirmationModal, { keepEditing: () => setConfirmClose(false), body: "Your progress will be lost if you exit now.", exitWithoutSaving: onClose })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(ColumnButton, { startIcon: _jsx(XMarkIcon, { className: "h-6 w-6" }), size: "md", onClick: (e) => {
                                        // Stop the button from submitting the enclosing <Form>
                                        e === null || e === void 0 ? void 0 : e.preventDefault();
                                        if (!submittable || submittable.disabled) {
                                            onClose();
                                        }
                                        else {
                                            setConfirmClose(true);
                                        }
                                    }, type: "button" }), _jsx("span", Object.assign({ className: "pl-4 text-column-gray-800 font-semibold text-xl" }, { children: headerText }))] })), submittable && (_jsx(ColumnButton, { primary: true, buttonText: submittable.buttonText, size: "lg", loading: loading, startIcon: submitting ? _jsx(LoadingSpinner, {}) : undefined, disabled: submittable.disabled || submitting, type: "submit" }))] })), _jsx("div", Object.assign({ className: "absolute top-0 h-full pt-20 w-full" }, { children: _jsxs("div", Object.assign({ className: "flex bg-column-gray-50 overflow-scroll h-full" }, { children: [_jsx("div", Object.assign({ className: classNames('mx-14 m-6', {
                                    'w-3/5': previewable,
                                    'w-full': !previewable
                                }) }, { children: children })), previewable && (_jsxs("div", Object.assign({ className: classNames('w-2/5 bg-white sticky top-0', {
                                    'px-10 py-12': previewable.withBorder
                                }) }, { children: [previewable.header && (_jsxs("div", Object.assign({ className: "mb-6" }, { children: [_jsx("div", Object.assign({ className: "text-xl text-column-gray-800 font-semibold" }, { children: previewable.header.title })), previewable.header.description && (_jsx("div", Object.assign({ className: "pt-2 font-medium text-sm text-column-gray-400" }, { children: previewable.header.description })))] }))), _jsx("div", Object.assign({ className: classNames('w-full', {
                                            'border border-column-gray-100 rounded-lg p-6 pb-4': previewable.withBorder
                                        }) }, { children: previewable.renderPreview() }))] })))] })) }))] })) })));
}
