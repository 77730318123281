var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentStepId } from 'redux/placement';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import { ColumnButton } from 'lib/components/ColumnButton';
import { DropdownButton } from 'components/DropdownButton';
import classNames from 'classnames';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { CONFIRM_AD, CONFIRM_PROOF } from './helpers/calculatePlacementSteps';
function ScrollStepContainer({ id, formId, complete, next, nextText, nextTooltipText, previous, secondaryAction, canSkip, skip, skipText, skipTooltipText, onDisabledStepClick, children, overrideStyles, title, caption, onHoldText, draftNoticeDisplayFormattingDropdown }) {
    const currentStepId = useAppSelector(selectCurrentStepId);
    const [nextLoading, setNextLoading] = useState(false);
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const STEPS_TO_HIDE_DROPDOWN = [CONFIRM_PROOF];
    const shouldHideDropdown = STEPS_TO_HIDE_DROPDOWN.includes(id);
    // Only hide the step body if the user is actually able to toggle the view
    const [hideStepBody, setHideStepBody] = useState(enableNewPlacementFlow && !shouldHideDropdown);
    const isActive = id === currentStepId;
    const showDisplayNoticeFormattingOptions = id === CONFIRM_AD && draftNoticeDisplayFormattingDropdown;
    // Unfurl step content when the step becomes active in placement
    useEffect(() => {
        if (isActive) {
            setHideStepBody(false);
        }
    }, [isActive]);
    return (_jsx("div", Object.assign({ className: "border-column-gray-100 border rounded-md w-full shadow-column-3", onClick: () => {
            onDisabledStepClick && onDisabledStepClick(id);
        }, id: id }, { children: _jsx("div", Object.assign({ className: classNames('w-full', {
                'pointer-events-none opacity-30': !isActive
            }) }, { children: _jsxs("div", Object.assign({ className: "content-center bg-white rounded-md" }, { children: [(title || caption) && (_jsxs("div", Object.assign({ className: classNames('flex flex-row w-full place-content-between', {
                            'border-b border-column-gray-100': !hideStepBody,
                            'p-8': enableNewPlacementFlow,
                            'p-6': !enableNewPlacementFlow
                        }) }, { children: [_jsxs("div", Object.assign({ className: "relative flex-col space-y-2" }, { children: [title && (_jsx("div", Object.assign({ className: classNames('bg-transparent text-right flex flex-col') }, { children: _jsx("span", Object.assign({ className: classNames('inline-flex rounded-md text-lg text-column-gray-700', {
                                                'font-semibold': enableNewPlacementFlow,
                                                'font-medium': !enableNewPlacementFlow
                                            }) }, { children: title })) }))), caption && (_jsx("div", Object.assign({ "data-testid": "scroll-step-caption", className: "bg-transparent" }, { children: _jsx("span", Object.assign({ className: "inline-flex rounded-md text-column-gray-400 font-medium text-sm" }, { children: caption })) })))] })), enableNewPlacementFlow && !shouldHideDropdown && (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [!hideStepBody && showDisplayNoticeFormattingOptions && (_jsx("div", Object.assign({ className: "mr-2" }, { children: draftNoticeDisplayFormattingDropdown }))), _jsx(DropdownButton, { onClick: () => {
                                            setHideStepBody(!hideStepBody);
                                        }, overrideExpand: hideStepBody, id: `${id}-dropdown-button`, textLabel: hideStepBody ? 'Show form' : 'Hide form', shape: "square" })] }))), !enableNewPlacementFlow &&
                                showDisplayNoticeFormattingOptions && (_jsx("div", Object.assign({ className: "flex items-center" }, { children: draftNoticeDisplayFormattingDropdown })))] }))), !hideStepBody && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classNames({
                                    'px-8 py-6': !overrideStyles && !enableNewPlacementFlow,
                                    'p-8': !overrideStyles && enableNewPlacementFlow
                                }) }, { children: children })), (previous || next) && (_jsx("div", Object.assign({ className: "text-right w-full rounded-b-md border-t border-column-gray-100" }, { children: _jsxs("div", Object.assign({ className: classNames('flex flex-row', {
                                        'justify-between': previous || secondaryAction,
                                        'justify-end': !previous && !secondaryAction,
                                        'py-6 px-8 bg-base-2 rounded-md': enableNewPlacementFlow,
                                        'p-6 bg-gray-100 rounded-md': !enableNewPlacementFlow
                                    }) }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-start" }, { children: [previous && (_jsx("div", Object.assign({ className: "bg-transparent text-right" }, { children: _jsx("span", Object.assign({ className: "inline-flex rounded-md" }, { children: _jsx(ColumnButton, { tertiary: true, size: "lg", onClick: () => {
                                                                void previous();
                                                            }, buttonText: "Previous", type: "button" }) })) }))), secondaryAction && (_jsx("div", Object.assign({ className: classNames({ 'ml-2': previous }) }, { children: _jsx(CTooltip, Object.assign({ helpText: secondaryAction.tooltipText || '', customPosition: `text-left ${!previous ? 'left-0' : 'right-0'}` }, { children: _jsx(ColumnButton, { tertiary: true, id: secondaryAction.buttonId, size: "lg", onClick: secondaryAction.onClick, buttonText: secondaryAction.buttonText, type: "button" }) })) })))] })), (next || formId || skip || onHoldText) && (_jsxs("div", Object.assign({ className: "flex flex-row justify-end" }, { children: [skip && (_jsx("div", { children: _jsx(CTooltip, Object.assign({ helpText: skipTooltipText || '', position: "right", classes: "text-left" }, { children: _jsx("div", Object.assign({ className: "bg-transparent text-right" }, { children: _jsx("span", Object.assign({ className: "inline-flex rounded-md shadow-sm" }, { children: _jsx(ColumnButton, { secondary: true, size: "lg", id: `${id}-skip-button`, onClick: () => {
                                                                        void skip();
                                                                    }, disabled: !canSkip, buttonText: skipText || 'Skip', type: "button" }) })) })) })) })), onHoldText && (_jsx(CTooltip, Object.assign({ classes: 'text-left max-w-4', customPosition: "top-0 -mt-16 -ml-16", helpText: onHoldText }, { children: _jsx(ExclamationCircleIcon, { className: "w-4 mt-3 -mr-2 text-red-550" }) }))), (next || formId) && (_jsx("div", Object.assign({ className: "ml-2" }, { children: _jsx(CTooltip, Object.assign({ helpText: nextTooltipText || '', customPosition: "-ml-24 text-left" }, { children: _jsx("div", Object.assign({ className: "bg-transparent text-right" }, { children: _jsx("span", Object.assign({ className: "inline-flex rounded-md shadow-sm" }, { children: _jsx(ColumnButton, { primary: true, size: "lg", id: `${id}-button`, formId: formId, onClick: next
                                                                        ? () => __awaiter(this, void 0, void 0, function* () {
                                                                            setNextLoading(true);
                                                                            yield next();
                                                                            setNextLoading(false);
                                                                        })
                                                                        : undefined, buttonText: nextText || 'Next', loading: nextLoading, disabled: !complete, type: formId ? 'submit' : 'button' }) })) })) })) })))] })))] })) })))] }))] })) })) })));
}
export default ScrollStepContainer;
