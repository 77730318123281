import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { EllipsisVerticalIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { deterministicStringHash } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import { selectUser } from 'redux/auth';
import { Popover } from 'lib/components/Popover';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { getModelFromSnapshot } from 'lib/model';
import { NoteModel } from 'lib/model/objects/noteModel';
import { getFirebaseContext } from 'utils/firebase';
import { ACTIVE_NOTES_VIEW, formatAttachmentName } from './notesHelpers';
const getNoteCreatorName = (noteCreator, noteCreatedByUser) => {
    if (noteCreatedByUser)
        return 'You';
    let userName = `${noteCreator === null || noteCreator === void 0 ? void 0 : noteCreator.data().firstName} ${noteCreator === null || noteCreator === void 0 ? void 0 : noteCreator.data().lastName}`;
    if (noteCreator.data().email.includes('@column.us')) {
        userName += ' (Column Support)';
    }
    return userName;
};
export const COLOR_SELECTOR = [
    'blue',
    'green',
    'red',
    'purple',
    'rose',
    'orange'
];
const getUserStyle = (noteCreator) => {
    const hash = deterministicStringHash((noteCreator === null || noteCreator === void 0 ? void 0 : noteCreator.id) || '');
    return COLOR_SELECTOR[Math.abs(hash) % COLOR_SELECTOR.length];
};
export default function UserNoteCard({ isFinalNote, currentView, note }) {
    const Note = getModelFromSnapshot(NoteModel, getFirebaseContext(), note);
    const user = useAppSelector(selectUser);
    const noteCreator = useFirestoreSnapshot(Note.modelData.noteCreator);
    const noteCreatedByUser = Note.isCreatedByUser(user === null || user === void 0 ? void 0 : user.id);
    const userColor = getUserStyle(noteCreator);
    return (_jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsxs("div", Object.assign({ className: classNames('flex flex-col items-center', {
                    'mb-4': isFinalNote
                }) }, { children: [_jsx("div", Object.assign({ className: classNames(`bg-${userColor}-100 text-${userColor}-500`, 'rounded-full h-6 w-6 flex items-center justify-center') }, { children: _jsxs("span", Object.assign({ className: "uppercase text-xs font-bold" }, { children: [noteCreator === null || noteCreator === void 0 ? void 0 : noteCreator.data().firstName[0], ((noteCreator === null || noteCreator === void 0 ? void 0 : noteCreator.data().lastName) || '')[0]] })) })), !isFinalNote && (_jsx("div", { className: "bg-column-gray-200 flex-1", style: { width: 1 } }))] })), _jsxs("div", Object.assign({ className: classNames('flex-1 p-4 border rounded-b-lg rounded-r-lg mb-4', {
                    'bg-column-primary-50 border-column-primary-200': noteCreatedByUser,
                    ' bg-base-1 border-column-gray-100': !noteCreatedByUser
                }) }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsx("span", Object.assign({ className: classNames('font-medium', {
                                    'text-column-gray-500': noteCreatedByUser,
                                    [`text-${userColor}-500`]: !noteCreatedByUser
                                }, 'bg-none') }, { children: noteCreator && getNoteCreatorName(noteCreator, noteCreatedByUser) })), _jsxs("span", Object.assign({ className: "text-column-gray-400 flex items-center" }, { children: [_jsx("span", Object.assign({ className: "text-sm" }, { children: Note.createdAtLabelFromNow })), noteCreatedByUser && (_jsx(Popover, Object.assign({ activator: _jsx("span", Object.assign({ className: "cursor-pointer" }, { children: _jsx(EllipsisVerticalIcon, { className: "h-5" }) })), alignment: "right", id: "note-actions-popover" }, { children: _jsx("div", Object.assign({ className: "p-2" }, { children: _jsx("div", Object.assign({ className: "w-24 text-center rounded-sm text-xs p-1 cursor-pointer hover:bg-column-primary-50 text-column-primary-500", onClick: () => {
                                                    if (currentView === ACTIVE_NOTES_VIEW) {
                                                        void Note.archive();
                                                    }
                                                    else {
                                                        void Note.restore();
                                                    }
                                                }, role: "button", tabIndex: 0 }, { children: currentView === ACTIVE_NOTES_VIEW ? 'Archive' : 'Restore' })) })) })))] }))] })), _jsx("div", Object.assign({ className: "text-sm pt-2 text-justify" }, { children: Note.modelData.content })), Note.hasAttachments && (_jsx("div", Object.assign({ className: "flex mt-2" }, { children: Note.modelData.attachments.map((attachment, idx) => (_jsx(ColumnButton, { buttonText: formatAttachmentName(attachment.name), onClick: () => window.open(attachment.downloadURL, '_blank'), startIcon: _jsx(PaperClipIcon, { className: "h-5 w-5" }), type: "button", link: true }, `${note.id}-attachment-${idx}`))) })))] }))] })));
}
