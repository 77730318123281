var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { getInviteRoleOptions, getAllowedRolesForOrg } from 'lib/utils/roles';
import { BASE_ROLES } from 'lib/permissions/roles';
import { isPublisherOrganization } from 'lib/utils/organizations';
export const useRolesForOrg = (newspaper) => {
    const [rolesLoading, setRolesLoading] = useState(true);
    const [allowedRoles, setAllowedRoles] = useState(BASE_ROLES);
    const publisherOrganization = isPublisherOrganization(newspaper);
    const inviteRoles = getInviteRoleOptions(publisherOrganization, allowedRoles);
    useEffect(() => {
        if (!exists(newspaper)) {
            setRolesLoading(false);
            return;
        }
        const check = () => __awaiter(void 0, void 0, void 0, function* () {
            setRolesLoading(true);
            try {
                const roles = yield getAllowedRolesForOrg(newspaper);
                setAllowedRoles(roles);
            }
            catch (e) {
                // ...
            }
            setRolesLoading(false);
        });
        void check();
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]);
    return { allowedRoles, inviteRoles, loading: rolesLoading };
};
