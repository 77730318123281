import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { EyeIcon } from '@heroicons/react/24/outline';
import { getFirebaseContext } from 'utils/firebase';
import { CacheManager } from 'lib/integrations/caches';
import { Alert } from 'lib/components/Alert';
import SettingsHeader from '../../SettingsHeader';
import { CachesListTable } from './CachesListTable';
import { CacheEntriesTable } from './CacheEntriesTable';
export function CacheManagementSettings({ activeOrganization }) {
    const [viewingCache, setViewingCache] = useState();
    const viewingCacheManager = viewingCache
        ? new CacheManager(getFirebaseContext(), activeOrganization.ref, viewingCache.id, viewingCache)
        : undefined;
    return (_jsxs("div", Object.assign({ className: "bg-white sm:rounded-lg shadow" }, { children: [_jsx(SettingsHeader, { header: "Cache Management", description: "Internal only! Control integrations caches." }), viewingCacheManager && (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsxs(Alert, Object.assign({ id: "alert-child-cache", status: "info", icon: _jsx(EyeIcon, { className: "w-5 h-5" }) }, { children: ["Viewing cache \"", viewingCacheManager.cacheId, "\".", ' ', _jsx("a", Object.assign({ className: "underline cursor-pointer", onClick: () => setViewingCache(undefined) }, { children: "Click here" })), ' ', "to go back to all caches."] })), _jsx(CacheEntriesTable, { cache: viewingCacheManager }, viewingCacheManager.cacheId)] }))), !viewingCacheManager && (_jsx(CachesListTable, { activeOrganization: activeOrganization, onEditClick: setViewingCache }))] })));
}
