import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { push } from 'connected-react-router';
import { Alert } from 'lib/components/Alert';
import { Form } from 'lib/components/Form';
import React, { useState } from 'react';
import AuthActions, { selectActiveOrganization } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import NavigationButtons from './NavigationButtons';
import PlacementNavbar from './components/PlacementNavbar';
import { NewspapersContextProvider } from './contexts/NewspapersContextProvider';
import { useAdForm } from './contexts/AdFormStatusProvider';
function FormWrapper({ onSubmit, submitText, children, steps, loading, newspaperOrdersFormData, userError, contentRef, product }) {
    const dispatch = useAppDispatch();
    const { currentStep, updateCurrentStep } = useAdForm();
    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const handlePreviousStep = () => {
        if (currentStep > 1) {
            const previousStep = currentStep - 1;
            updateCurrentStep(previousStep);
        }
    };
    function onExit() {
        setShowExitConfirmationModal(true);
    }
    return (_jsxs(_Fragment, { children: [_jsx(Form, Object.assign({ id: "placement-step-form", onSubmit: onSubmit }, { children: _jsxs("div", Object.assign({ className: "fixed inset-0 z-modal flex flex-col" }, { children: [_jsx(PlacementNavbar, { onExit: onExit, steps: steps, product: product }), userError && (_jsx(Alert, { id: "ad-placement-flow-error", status: "error", title: userError })), _jsx("div", Object.assign({ ref: contentRef, className: "flex-grow overflow-auto " }, { children: _jsx(NewspapersContextProvider, Object.assign({ newspaperOrdersFormData: newspaperOrdersFormData }, { children: children })) })), _jsx(NavigationButtons, { currentStep: currentStep, currentStepName: steps[currentStep - 1], submitText: submitText, goToPreviousStep: handlePreviousStep, onExit: onExit, loading: loading })] })) })), showExitConfirmationModal && (_jsx(ExitConfirmationModal, { keepEditing: () => setShowExitConfirmationModal(false), exitWithoutSaving: () => {
                    if (activeOrganization) {
                        dispatch(push(`/${PRODUCT_TO_NAME[product].plural.toLowerCase()}`));
                    }
                    else {
                        dispatch(AuthActions.logout());
                        window.location.reload();
                    }
                }, body: 'Leaving this page will delete all unsaved changes. Are you sure you want to leave?', disableOverflow: true }))] }));
}
export default FormWrapper;
