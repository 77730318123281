import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ColumnButton } from '../ColumnButton';
export function Footer({ primaryAction, secondaryActions, loading, onClose }) {
    const hasActions = Boolean(primaryAction || secondaryActions);
    if (!hasActions)
        return null;
    return (_jsxs("div", Object.assign({ className: "sticky bottom-0 flex justify-between py-5 px-6" }, { children: [_jsx("div", Object.assign({ className: "flex gap-3" }, { children: secondaryActions &&
                    secondaryActions.map((action, index) => (_jsx(ColumnButton, Object.assign({ size: "lg" }, action, { onClick: action.onClick || onClose, disabled: action.disabled || loading }), index))) })), primaryAction && (_jsx(ColumnButton, Object.assign({ primary: true, size: "lg" }, primaryAction, { disabled: primaryAction.disabled || loading })))] })));
}
