import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge } from 'lib/components/Badge';
import { capitalizeWord } from 'lib/utils/strings';
import AdDetailHeaderSection from './AdDetailHeaderSection';
import { getBadgeStatusForOrderStatus, getStatusBadgeText } from '../helpers';
import { OrderActions } from './OrderActions';
import { OrderDetailBackButton } from './OrderDetailBackButton';
export function AdDetailHeader({ title, order, orderDetail, editData, anonymousUserAuthInfo }) {
    return (_jsxs(_Fragment, { children: [_jsxs(AdDetailHeaderSection, { children: [!anonymousUserAuthInfo && _jsx(OrderDetailBackButton, { order: order }), _jsx("h1", Object.assign({ className: "text-xl text-column-gray-500 font-bold" }, { children: title })), _jsx(Badge, Object.assign({ status: getBadgeStatusForOrderStatus(order.modelData.status), size: "lg" }, { children: capitalizeWord(getStatusBadgeText(order.modelData.status)) }))] }), _jsx(AdDetailHeaderSection, { children: _jsx(OrderActions, { order: order, orderDetail: orderDetail, editData: editData, anonymousUserAuthInfo: anonymousUserAuthInfo }) })] }));
}
