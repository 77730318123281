import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { PLAID_PUBLIC_KEY } from 'lib/constants';
import { PLAID_ENV, ENV, PROD, DEV } from '../../../constants';
function Plaid({ user, setOpen, setShowVerifyOptionsModal, setShowAuthorizationModal, setPlaidData }) {
    const onSuccess = useCallback((token, metadata) => {
        setPlaidData({
            accountNumber: metadata.account.mask,
            userId: user.id,
            accountId: metadata.account_id,
            publicToken: token,
            institutionName: metadata.institution.name,
            accountNickname: metadata.account.name
        });
        setShowAuthorizationModal(true);
        setOpen(false);
    }, []);
    const onExit = useCallback((err, metadata) => {
        console.log('Plaid error and metadata:', err, metadata);
        setOpen(false);
    }, []);
    const onEvent = useCallback((eventName, metadata) => {
        if (eventName === 'EXIT' &&
            metadata.exit_status === 'institution_not_found' &&
            setShowVerifyOptionsModal)
            setShowVerifyOptionsModal(true);
    }, []);
    const config = {
        clientName: 'Column',
        env: ENV === PROD ? PLAID_ENV[PROD] : PLAID_ENV[DEV],
        product: ['auth'],
        publicKey: PLAID_PUBLIC_KEY,
        selectAccount: true,
        onSuccess,
        onExit,
        onEvent
    };
    const { open } = usePlaidLink(config);
    open();
    return _jsx(_Fragment, {});
}
export default Plaid;
