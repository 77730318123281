var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { useAppSelector } from 'redux/hooks';
import Firebase from 'EnoticeFirebase';
function FileUploadQuestionInput({ question, value, onChange }) {
    const onDelete = () => {
        onChange({
            varName: question.varName,
            value: null
        });
    };
    const originalNotice = useAppSelector((state) => state.placement.original);
    const noticeId = (originalNotice === null || originalNotice === void 0 ? void 0 : originalNotice.id) || '';
    return (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "pb-2 text-sm font-medium" }, { children: question.prompt })), value && (_jsx(FileUploadListItem, { firebaseStoragePath: value.storagePath, onDelete: () => onDelete(), storage: Firebase.storage() }, value.linkToUploadedFile)), _jsx(FileUpload, { id: "madlib-file-upload", multiple: false, disabled: Boolean(value), uploadFolder: `documentcloud/madlib-notices-uploads/${noticeId}`, acceptFileTypes: question.extensions || '.pdf', onFileUpload: (filesAndRefs) => __awaiter(this, void 0, void 0, function* () {
                    const uploadPath = yield filesAndRefs[0].uploadRef.getDownloadURL();
                    onChange({
                        varName: question.varName,
                        value: {
                            sanitizedFileName: filesAndRefs[0].file.name,
                            storagePath: filesAndRefs[0].uploadRef.fullPath,
                            linkToUploadedFile: uploadPath
                        }
                    });
                }), storage: Firebase.storage(), required: true })] }));
}
export default FileUploadQuestionInput;
