var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SyncStatusCategory } from '../enums';
import { INVOICE_PAYMENT_PROCESSED, INVOICE_PAYMENT_PROCESSED_MANUAL, INVOICE_REFUNDED, MANUAL_SYNC_REQUEST, NOTICE_AT_DEADLINE, NOTICE_CANCELLED, INVOICE_PAID_OUTSIDE, CUSTOM_ID_UPDATED, DESIGN_NOTES_UPDATED, NOTICE_CREATED, INVOICE_CREATED } from '../types/events';
import * as EventTypes from '../types/events';
import { removeUndefinedFields } from '../helpers';
export const getSyncStatusCategory = (statusValue) => {
    const categoryValue = Math.floor(statusValue / 100);
    const category = SyncStatusCategory.by_value(categoryValue);
    if (!category) {
        throw new Error(`Sync status value ${statusValue} does not match an existing category`);
    }
    return category;
};
/**
 * Will return `true` if sync status matches any of either the categories or statuses provided
 */
export const syncStatusMatchesCriteria = (syncStatus, { categories, statuses }) => {
    const { value: syncCategory } = getSyncStatusCategory(syncStatus);
    for (const { value } of categories) {
        if (value === syncCategory) {
            return true;
        }
    }
    for (const { value } of statuses) {
        if (value === syncStatus) {
            return true;
        }
    }
    return false;
};
export const isNoticeCreatedEvent = (event) => event.data().type === NOTICE_CREATED;
export const isNoticeCancelledEvent = (event) => event.data().type === NOTICE_CANCELLED;
export const isNoticeAtDeadlineEvent = (event) => event.data().type === NOTICE_AT_DEADLINE;
export const isManualSyncRequestEvent = (event) => event.data().type === MANUAL_SYNC_REQUEST;
export const isInvoiceCreatedEvent = (event) => event.data().type === INVOICE_CREATED;
export const isInvoicePaidManualEvent = (event) => event.data().type === INVOICE_PAYMENT_PROCESSED_MANUAL;
export const isInvoiceRefundEvent = (event) => event.data().type === INVOICE_REFUNDED;
export const isInvoicePaidEvent = (event) => event.data().type === INVOICE_PAYMENT_PROCESSED;
export const isInvoicePaidOutsideEvent = (event) => event.data().type === INVOICE_PAID_OUTSIDE;
export const isCustomIdUpdatedEvent = (event) => event.data().type === CUSTOM_ID_UPDATED;
export const isNoticeConfirmedEvent = (event) => event.data().type === EventTypes.NOTICE_CONFIRMED;
export const startManualSync = (context, noticeRef, userRef, ignoreTriggers) => __awaiter(void 0, void 0, void 0, function* () {
    return context.eventsRef().add(Object.assign({ createdAt: context.fieldValue().serverTimestamp(), type: EventTypes.MANUAL_SYNC_REQUEST, notice: noticeRef, data: {
            inApp: true,
            requestedBy: userRef
        } }, (ignoreTriggers ? { ignoreTriggers: 1 } : {})));
});
/**
 * Create an update event for updating design notes on the given notice
 * @param {EFirebaseContext} firebaseContext - Firebase context
 * @param {ERef<ENotice>} noticeRef - A reference to the notice to update
 * @param {Record<string, any>} designNotes - The design notes for the notice
 *
 * @return {Promise<void>}
 */
export const createDesignNotesUpdatedEvent = (firebaseContext, noticeRef, designNotes) => __awaiter(void 0, void 0, void 0, function* () {
    const designNotesUpdatedEventRef = firebaseContext
        .eventsRef()
        .doc();
    yield designNotesUpdatedEventRef.set({
        type: DESIGN_NOTES_UPDATED,
        createdAt: firebaseContext.timestamp(),
        notice: noticeRef,
        data: removeUndefinedFields({
            message: designNotes.message,
            editedAt: designNotes.lastEditedAt,
            editedBy: designNotes.lastEditedBy
        })
    });
});
