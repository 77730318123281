import { OccupationType, RoleType } from '../../enums';
/**
 * Get a user's role based on their occupation and active organization.
 * This is only safe on the frontend because of the reliance on activeOrganization
 */
export const getRole = (user) => {
    const { occupation, activeOrganization, roles, postRegistrationComplete } = user.data();
    try {
        // Individuals always have admin permissions for their own account
        if (occupation === OccupationType.individual.value) {
            return RoleType.admin.value;
        }
        if (!activeOrganization) {
            if (!postRegistrationComplete) {
                throw new Error(`Cannot get role for user with incomplete registration ${user.id}`);
            }
            throw new Error(`Cannot get role for user without activeOrganization set ${user.id}`);
        }
        if (!roles) {
            throw new Error(`Cannot get role for user without roles set ${user.id}`);
        }
        const permissionsForOrg = roles[activeOrganization.id];
        if (permissionsForOrg) {
            return permissionsForOrg;
        }
        throw new Error(`Unable to get role for user ${user.id} in organization ${activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id}`);
    }
    catch (err) {
        console.error(err);
        return 0;
    }
};
