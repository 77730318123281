import { jsx as _jsx } from "react/jsx-runtime";
import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
const trueText = 'Yes, that is correct';
const falseText = 'No, that is incorrect';
const options = [trueText, falseText].map(option => ({
    tooltipText: '',
    labelText: option,
    value: option
}));
const getStringValueFromBooleanValue = (value) => {
    if (value) {
        return trueText;
    }
    if (value === undefined) {
        return '';
    }
    return falseText;
};
export function VerificationInput({ id, labelText, value, onSelectedValueChange }) {
    const internalValue = getStringValueFromBooleanValue(value);
    const handleSelectedValueChange = (newValue) => {
        onSelectedValueChange(newValue.includes('Yes'));
    };
    return (_jsx(RadioButtonGroup, { id: id, labelText: labelText, options: options, value: internalValue, onChange: handleSelectedValueChange, required: true }));
}
