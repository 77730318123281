import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
// Key in metadata object this component will write to
const METADATA_KEY = 'ppi';
export function PpiSelect({ metadata, onChange, attachmentId, uploadFieldId, disabled }) {
    const defaultValue = '600';
    const currentValue = metadata === null || metadata === void 0 ? void 0 : metadata[METADATA_KEY];
    const selectedValue = currentValue || defaultValue;
    const handleChange = (value) => {
        onChange(uploadFieldId, attachmentId, METADATA_KEY, value);
    };
    useEffect(() => {
        if (!currentValue) {
            handleChange(defaultValue);
        }
    }, []);
    return (_jsx(_Fragment, { children: _jsx(ColumnSelect, { options: [
                { value: '720', label: '720' },
                { value: '600', label: '600' },
                { value: '300', label: '300' },
                { value: '72', label: '72' }
            ], value: selectedValue, onChange: handleChange, id: `dpi-${attachmentId}`, labelText: 'PPI', size: 'small', disabled: disabled }) }));
}
