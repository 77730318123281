import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TimezoneType } from 'lib/enums';
import moment from 'moment-timezone';
import React from 'react';
import { PublishingIcon } from '../PublishingIcon';
export function ScheduleChangesRow({ activeOrganization, deadlineSetting: { publicationDate, deadlineDate, publish } }) {
    var _a;
    const { iana_timezone } = activeOrganization.data();
    const publicationDateMoment = moment(publicationDate);
    const deadlineDateMoment = moment(deadlineDate);
    const timezone = (_a = TimezoneType.by_key(iana_timezone)) === null || _a === void 0 ? void 0 : _a.abbrev;
    const dateFormat = 'dddd, M/D/YY';
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: publicationDateMoment.format(dateFormat) }), _jsx("td", Object.assign({ className: "pl-8" }, { children: _jsx(PublishingIcon, { publish: publish, publishingDay: publicationDateMoment.format('M/D/YY') }) })), _jsx("td", { children: publish ? deadlineDateMoment.format(dateFormat) : '--' }), _jsx("td", { children: publish ? `${deadlineDateMoment.format('h:mm A')} ${timezone}` : '--' })] }));
}
