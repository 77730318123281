var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { capitalizeWord } from 'lib/utils/strings';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
/**
 * Represents a single row in the notice list table
 */
function NoticeListTableRow({ notice, run }) {
    const [open, setOpen] = useState(false);
    const badgeStatus = run.isVerified()
        ? 'success'
        : run.isUnverifiable()
            ? 'critical'
            : 'warning';
    return (_jsxs("div", Object.assign({ className: "border-b px-4" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between w-full py-5" }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-400 font-semibold w-64 flex justify-between" }, { children: notice.data().referenceId })), _jsxs("div", Object.assign({ className: "flex items-center space-x-4" }, { children: [_jsx("a", Object.assign({ className: "font-medium text-column-primary-600", target: "_blank", href: `/notice/${notice.id}`, rel: "noopener noreferrer" }, { children: "View Notice" })), !open && (_jsx(ChevronUpIcon, { onClick: () => setOpen(true), className: "cursor-pointer text-column-gray-500 hover:text-column-gray-600 w-4 h-4" })), open && (_jsx(ChevronDownIcon, { onClick: () => setOpen(false), className: "cursor-pointer text-column-gray-500 hover:text-column-gray-600 w-4 h-4" }))] }))] })), open && (_jsxs("div", Object.assign({ className: "mb-5" }, { children: [_jsx("div", Object.assign({ className: "mb-1" }, { children: _jsx(Badge, Object.assign({ status: badgeStatus }, { children: capitalizeWord(run.modelData.status) })) })), _jsx("div", { dangerouslySetInnerHTML: {
                            __html: notice.data().confirmedHtml ||
                                notice.data().unusedConfirmedHtml ||
                                ''
                        } })] })))] })));
}
/**
 * Display a list of notices in the affidavit automation drawer
 */
export default function NoticeList({ runs, preLoadedNotices }) {
    const { value: noticesAndRuns, isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const snapshotsAndRuns = yield Promise.all(runs.map((run) => __awaiter(this, void 0, void 0, function* () {
                const preloadedNotice = preLoadedNotices === null || preLoadedNotices === void 0 ? void 0 : preLoadedNotices.find(notice => notice.id === run.modelData.notice.id);
                if (preloadedNotice) {
                    return { run, notice: preloadedNotice };
                }
                const notice = yield run.modelData.notice.get();
                return { run, notice };
            })));
            return snapshotsAndRuns.filter((snapshotAndRun) => exists(snapshotAndRun.notice));
        }),
        // NOTE: be careful changing this effect as you can easily trigger an infinite read loop
        // if you use simply (noticeRefs) or (noticeRefs.map(ref => ref.id))
        dependencies: [runs.map(run => run.id).join(',')]
    });
    if (!noticesAndRuns || isLoading)
        return _jsx(LoadingState, {});
    return (_jsx("div", Object.assign({ className: "flex flex-col" }, { children: noticesAndRuns.map(noticeAndRun => (_jsx(NoticeListTableRow, { notice: noticeAndRun.notice, run: noticeAndRun.run }, noticeAndRun.notice.id))) })));
}
