var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Alert } from 'lib/components/Alert';
import { NoticeType } from 'lib/enums';
import { squash } from 'components/noticePreview/mceHelpers';
import { placementSelector, selectModularSizeId, selectShowPlacementErrors } from 'redux/placement';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import NoticeEditorMCE from 'components/noticePreview/NoticeEditorMCE';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { removeUndefinedFields, cdnIfy } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { Label } from 'lib/components/Label';
import { TextAreaField } from 'lib/components/TextAreaField';
import { getBooleanFlag, useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { DESIGN_NOTES_MAX_CHARS } from '../helpers';
import { DisplayAdOnlyMessage } from '../DisplayAdOnlyMessage';
import { selectCurrentlySelectedNoticeType, selectShouldLoadTypeformForNoticeType } from '../placeScrollSelectors';
import SectionDivider from '../SectionDivider';
import { NoticeContentAlerts } from './Alerts';
import { FileUploadFormattingOptionsModal } from './FileUploadFormattingOptionsModal';
import { NoticeContentFields } from './NoticeContentFields';
import { NoticeFormattingOptionsDialog } from './NoticeFormattingOptionsDialog';
import { DisplayCropTool } from './DisplayCropTool';
import { useGetModularSizes } from '../hooks/useGetModularSizes';
import { useUpdateDisplayParamsForDisplayAd } from '../hooks/useUpdateDisplayParamsForDisplayAd';
import { AdTypeRecommendation } from './adTypeRecommendation/getAdTypeRecommendationFromProperties';
import { LoadingSpinnerContainer } from './LoadingSpinnerContainer';
export function NoticeContentInner({ placementActions, newspaper, notice, mceKey, setMCEKey, initialEditorState, setInitialEditorState, setUploadedFileState, setShowEraseContentModal, convertDisplayToText, convertTextToDisplay, submitLargeFileWithoutFormatting, setSubmitLargeFileWithoutFormatting, templateStyles, allowImages, parsing, setParsing, keepWaitingForLargeFile, setKeepWaitingForLargeFile, isTypeform, setCopyPasteContent, tiny, setIsNoticeFormattingOptionSelected, isNoticeFormattingOptionSelected, showDisabledEditorAfterTypeformMessage, isRunningOCROnDisplay }) {
    var _a, _b, _c, _d, _e;
    const placement = useAppSelector(placementSelector);
    const isPublisher = useAppSelector(selectIsPublisher);
    const user = useAppSelector(selectUser);
    const shouldLoadTypeformForNoticeType = useAppSelector(state => selectShouldLoadTypeformForNoticeType(state, newspaper));
    const currentlySelectedNoticeType = useAppSelector(state => selectCurrentlySelectedNoticeType(state, newspaper));
    const modularSizes = useGetModularSizes();
    const selectedModularSizeId = useAppSelector(selectModularSizeId);
    const selectedModularSize = modularSizes === null || modularSizes === void 0 ? void 0 : modularSizes.find(modularSize => modularSize.id === selectedModularSizeId);
    useUpdateDisplayParamsForDisplayAd({
        publisherOrganization: newspaper,
        draft: notice,
        selectedModularSize
    });
    const [designNotesErrorText, setDesignNotesErrorText] = useState('');
    const { largeFile } = useAppSelector(selectShowPlacementErrors);
    const restrictSubmitWithoutFormatting = Boolean(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().restrictSubmitWithoutFormatting);
    // Use to restrict user to show enabled editor for display-only papers in publisher account
    const [displayPaperFileReplacement, setDisplayPaperFileReplacement] = useState(false);
    const adType = placement.postWithoutFormatting
        ? AdTypeRecommendation.AsyncDisplay
        : placement.processedDisplay
            ? AdTypeRecommendation.SyncDisplay
            : AdTypeRecommendation.Liner;
    const showCloudConvertOption = useBooleanFlag(LaunchDarklyFlags.ENABLE_PDF_TO_HTML_CLOUD_CONVERT, false);
    const showPDFBounder = !parsing &&
        placement.displayUrl &&
        placement.processedDisplay &&
        !placement.postWithoutFormatting &&
        !(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().disableDisplay);
    // if the PDF bounder is not showing, if we are not placing without formatting, and
    // if we are not running OCR on a display notice, show MCE
    const showMCEEditor = !showPDFBounder &&
        !placement.postWithoutFormatting &&
        !isRunningOCROnDisplay;
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const transformedDisplayUrl = cdnIfy(placement.displayUrl, {
        cloudinaryTransformations: 'f_jpg',
        useColumnCDN
    });
    const showDesignNotes = !shouldLoadTypeformForNoticeType &&
        (placement.postWithoutFormatting ||
            (currentlySelectedNoticeType === null || currentlySelectedNoticeType === void 0 ? void 0 : currentlySelectedNoticeType.showDesignNotes));
    const showSectionDivider = !isTypeform && showMCEEditor;
    /**
     * This Dropdown will only be shown when one of the file formatting options has been selected upon initial upload
     * and the newspaper is not specific for display/liner ads.
     */
    const shouldShowFormattingText = Boolean((isNoticeFormattingOptionSelected ||
        (placement.postWithoutFormatting &&
            (placement.displayUrl || placement.unusedDisplay))) &&
        !(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().disableDisplay) &&
        !(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().displayOnlyAds) &&
        ((_a = placement.filesToAttach) === null || _a === void 0 ? void 0 : _a.length) === 1);
    const squashTables = () => {
        const squashedContent = placement.confirmedHtml
            ? squash(placement.confirmedHtml, {
                allowImages
            })
            : '';
        setInitialEditorState(squashedContent);
        // Only updating the editor state is not working for squashing. We need to reload the editor to show squashed content
        setMCEKey(mceKey + 1);
        placementActions.setSquashable(true);
    };
    const handleKeepWaitingForLargeFile = () => {
        placementActions.setShowPlacementErrors({
            wait: false,
            largeFile: false
        });
        setKeepWaitingForLargeFile(true);
    };
    const handleSubmitLargeFileWithoutFormatting = () => {
        if (placement.confirmedHtml) {
            placementActions.setUnusedConfirmedHtml(placement.confirmedHtml);
        }
        if (placement.displayUrl) {
            placementActions.setUnusedDisplay(placement.displayUrl);
        }
        placementActions.setPostWithoutFormatting(true);
        setSubmitLargeFileWithoutFormatting(true);
        placementActions.setShowPlacementErrors({
            wait: false,
            largeFile: false
        });
    };
    const formattingOptionActions = [
        {
            id: 'submit-image',
            header: 'Submit as image',
            description: 'Crop out white space before submitting your notice.',
            onClick: () => {
                setIsNoticeFormattingOptionSelected(true);
            }
        },
        {
            id: 'submit-text',
            header: showCloudConvertOption
                ? 'Convert to text (text only)'
                : 'Convert to text',
            description: 'Text in this file will be extracted inside the text editor.',
            onClick: () => __awaiter(this, void 0, void 0, function* () {
                setIsNoticeFormattingOptionSelected(true);
                yield convertDisplayToText();
            })
        }
    ];
    if (showCloudConvertOption) {
        formattingOptionActions.push({
            id: 'submit-text',
            header: 'Convert to text (text and formatting)',
            description: 'This method may not work well for some PDF files.',
            onClick: () => __awaiter(this, void 0, void 0, function* () {
                setIsNoticeFormattingOptionSelected(true);
                yield convertDisplayToText(true);
            })
        });
    }
    if ((parsing && !submitLargeFileWithoutFormatting) || isRunningOCROnDisplay) {
        if (largeFile) {
            /* Large file options should disappear if not chosen any option and the file processed
          Additionally, we should show the SWF option if the ad type recommendation is async */
            return (_jsx("div", Object.assign({ className: "relative" }, { children: _jsx("div", Object.assign({ className: "border rounded-md mt-6 flex items-center", style: { height: '408px' } }, { children: _jsx(FileUploadFormattingOptionsModal, { modalHeader: "Your file is large \u2014 consider sending the\n  original file to the publisher to format.", actions: [
                            {
                                id: 'submit-original-file',
                                header: 'Send original file(s)',
                                description: 'Send your files to the publisher and they’ll design the notice.',
                                onClick: () => {
                                    handleSubmitLargeFileWithoutFormatting();
                                }
                            },
                            {
                                id: 'keep-waiting-to-upload',
                                header: 'Keep waiting for upload',
                                description: 'Large files might take a longer time to upload.',
                                onClick: () => {
                                    handleKeepWaitingForLargeFile();
                                }
                            }
                        ] }) })) })));
        }
        return (_jsx(LoadingSpinnerContainer, { keepWaitingForLargeFile: keepWaitingForLargeFile }));
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsxs("div", Object.assign({ className: "space-y-6 mb-2" }, { children: [showSectionDivider && _jsx(SectionDivider, { text: 'or use the editor' }), _jsx(NoticeContentFields, { newspaper: newspaper, overrideHideColumnCountField: !!((modularSizes === null || modularSizes === void 0 ? void 0 : modularSizes.length) && showPDFBounder) }), placement.postWithoutFormatting && (_jsx(Alert, { id: "post-without-formatting-warning", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }), description: isPublisher
                            ? 'The proof and estimated pricing will become available after you finalize the notice layout.'
                            : 'Because you are submitting an unformatted notice, the proof with estimated pricing will be available only after the newspaper finalizes the notice layout.' })), _jsxs("div", Object.assign({ className: "flex justify-between items-end" }, { children: [!(adType === AdTypeRecommendation.AsyncDisplay) && (_jsx(Label, Object.assign({ id: "notice-content-label" }, { children: adType === AdTypeRecommendation.Liner
                                    ? 'Draft your notice in the text editor below'
                                    : modularSizes.length
                                        ? 'Select a size to crop your notice'
                                        : 'Click and drag to crop your notice' }))), shouldShowFormattingText && (_jsx(NoticeFormattingOptionsDialog, { convertToDisplayNotice: () => __awaiter(this, void 0, void 0, function* () {
                                    setIsNoticeFormattingOptionSelected(true);
                                    setSubmitLargeFileWithoutFormatting(false);
                                    yield convertTextToDisplay();
                                    placementActions.setPostWithoutFormatting(false);
                                }), handleSubmitLargeFileWithoutFormatting: restrictSubmitWithoutFormatting
                                    ? undefined
                                    : handleSubmitLargeFileWithoutFormatting, convertToLinerNotice: (useCloudConvert) => __awaiter(this, void 0, void 0, function* () {
                                    setIsNoticeFormattingOptionSelected(true);
                                    setSubmitLargeFileWithoutFormatting(false);
                                    yield convertDisplayToText(useCloudConvert);
                                    placementActions.setPostWithoutFormatting(false);
                                }), noticeType: adType }))] })), _jsx(NoticeContentAlerts, { newspaper: newspaper, setSubmitLargeFileWithoutFormatting: setSubmitLargeFileWithoutFormatting, squashTables: squashTables })] })), showPDFBounder && (_jsxs("div", Object.assign({ className: "relative" }, { children: [!((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.displayOnlyAds) &&
                        !isNoticeFormattingOptionSelected && (_jsxs(_Fragment, { children: [_jsx(FileUploadFormattingOptionsModal, { modalHeader: "How would you like to submit this file?", modalDescription: "You can still change the formatting options with gear icon at the top of this step.", actions: formattingOptionActions, overlay: true }), _jsx("img", { src: transformedDisplayUrl, alt: "uploaded file", className: "object-fill w-full p-2", style: { height: '408px' } })] })), (isNoticeFormattingOptionSelected ||
                        ((_c = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _c === void 0 ? void 0 : _c.displayOnlyAds)) && (_jsx(DisplayCropTool, { modularSizes: modularSizes, selectedModularSize: selectedModularSize, displayImageUrl: transformedDisplayUrl }))] }))), showMCEEditor && (_jsx(NoticeEditorMCE, { ref: tiny, parsing: parsing, onEditorUpdate: (html) => {
                    placementActions.setNoticeText(html);
                    // TODO: remove saveDraft call and update confirmedHtml in draft inside updatePreview
                    placementActions.saveDraft();
                }, onPaste: (html) => {
                    setParsing(true);
                    setInitialEditorState(html);
                    setMCEKey(mceKey + 1);
                    setCopyPasteContent(true);
                }, displayPaperFileReplacement: displayPaperFileReplacement, newspaper: newspaper, errorTitle: !parsing &&
                    ((_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _d === void 0 ? void 0 : _d.displayOnlyAds) &&
                    !keepWaitingForLargeFile &&
                    !placement.postWithoutFormatting ? (_jsx(DisplayAdOnlyMessage, {})) : undefined, errorFunction: e => {
                    var _a;
                    e.preventDefault();
                    placementActions.setDisplayParams(null);
                    placementActions.setNoticeText(null);
                    placementActions.setProcessedDisplay(null);
                    placementActions.setConfirmedCrop(null);
                    placementActions.setUnusedConfirmedHtml(null);
                    placementActions.setDisplayUrl(null);
                    setInitialEditorState('');
                    setUploadedFileState(null);
                    placementActions.setFilesToAttach(null);
                    setMCEKey(mceKey + 1);
                    // Custom filing notices converted to liners should be assigned correct notice types
                    if (placement.noticeType === NoticeType.display_ad.value) {
                        if (placement.previousNoticeType &&
                            placement.previousNoticeType !== NoticeType.custom.value) {
                            placementActions.setNoticeType(placement.previousNoticeType);
                        }
                        else {
                            placementActions.setNoticeType(NoticeType.custom.value);
                        }
                    }
                    placementActions.setPdfStoragePath(null);
                    placementActions.resetColumns();
                    placementActions.setProofStoragePath(null);
                    placementActions.clearFileWithoutProof();
                    setShowEraseContentModal(null);
                    if (isPublisher && ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.displayOnlyAds)) {
                        setDisplayPaperFileReplacement(true);
                    }
                }, placeholder: '', initialState: initialEditorState || '', clickText: isPublisher
                    ? 'to replace the original file with a formatted notice file.'
                    : 'to start over', templateStyles: templateStyles, columns: placement.columns, allowImages: allowImages, clearMCEEditor: () => __awaiter(this, void 0, void 0, function* () {
                    placementActions.clearNoticeType();
                    placementActions.setNoticeText(null);
                    // Typeforms notices sets text property on the notice, not clearing it can cause resets the confirmedHtml with the text
                    placementActions.setText('');
                    placementActions.resetColumns();
                    setInitialEditorState('');
                    placementActions.setFilesToAttach(null);
                    const draftRef = placement.draft;
                    // When clearing typeform notice content we do not care about what types of noticeFiles notice contains and delete all refs.
                    if (draftRef) {
                        const filesFromNotice = yield getFirebaseContext()
                            .userNoticeFilesRef(draftRef)
                            .get();
                        if (filesFromNotice.docs.length) {
                            filesFromNotice.docs.map((noticeFile) => __awaiter(this, void 0, void 0, function* () {
                                yield noticeFile.ref.delete();
                            }));
                        }
                    }
                    placementActions.saveDraft();
                }), showDisabledEditorAfterTypeformMessage: showDisabledEditorAfterTypeformMessage }, mceKey)), showDesignNotes && (_jsx("div", Object.assign({ className: "mt-3" }, { children: _jsx(TextAreaField, { id: "design-notes", labelText: "Leave a note (optional)", errorText: designNotesErrorText, rows: 3, value: (_e = placement.designNotes) === null || _e === void 0 ? void 0 : _e.message, onChange: value => {
                        let sanitizedValue;
                        if (value.length > DESIGN_NOTES_MAX_CHARS) {
                            setDesignNotesErrorText(`Maximum ${DESIGN_NOTES_MAX_CHARS} characters allowed.`);
                            sanitizedValue = value.slice(0, DESIGN_NOTES_MAX_CHARS);
                        }
                        else {
                            setDesignNotesErrorText('');
                            sanitizedValue = value;
                        }
                        placementActions.setDesignNotes(removeUndefinedFields({
                            message: sanitizedValue,
                            lastEditedBy: user === null || user === void 0 ? void 0 : user.ref,
                            lastEditedAt: getFirebaseContext().timestamp()
                        }));
                    }, disableResizing: true }) })))] })));
}
