var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { selectUserAuth } from 'redux/auth';
import FIREBASE_CONFIG from 'config.firebase';
import Embed from 'routes/typeform/Embed';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import PlacementActions, { selectDraftId, selectDraftRef, selectDraftSnap } from 'redux/placement';
export function NoticeContentStepTypeformEmbed({ formId, setIsTypeformComplete }) {
    const dispatch = useAppDispatch();
    const draft = useAppSelector(selectDraftRef);
    const draftId = useAppSelector(selectDraftId);
    const userAuth = useAppSelector(selectUserAuth);
    const draftSnap = useAppSelector(selectDraftSnap);
    const hiddenFields = {
        documentid: draftId,
        userid: userAuth ? userAuth.uid : '',
        env: FIREBASE_CONFIG.projectId
    };
    useEffect(() => {
        var _a;
        const initialDraftText = (_a = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _a === void 0 ? void 0 : _a.text;
        // Listen for changes to the draft text that updates when the typeform finishes processing via `functions/src/zapier.ts`
        return draft === null || draft === void 0 ? void 0 : draft.onSnapshot(snapshot => {
            var _a, _b, _c, _d;
            // If the text exists and has changed, mark the typeform complete and set the text value in the store
            if (((_b = (_a = snapshot === null || snapshot === void 0 ? void 0 : snapshot.data()) === null || _a === void 0 ? void 0 : _a.text) === null || _b === void 0 ? void 0 : _b.length) &&
                ((_c = snapshot.data()) === null || _c === void 0 ? void 0 : _c.text) !== initialDraftText) {
                setIsTypeformComplete(true);
                dispatch(PlacementActions.setNoticeText((_d = snapshot === null || snapshot === void 0 ? void 0 : snapshot.data()) === null || _d === void 0 ? void 0 : _d.text));
            }
        });
    }, [draftId]);
    if (!formId)
        return null;
    return (_jsx(Embed, { onSubmit: () => __awaiter(this, void 0, void 0, function* () {
            yield (draft === null || draft === void 0 ? void 0 : draft.update({ confirmedHtml: '' }));
            dispatch(PlacementActions.setNoticeText(''));
        }), formId: formId, hiddenFields: hiddenFields, applyClasses: false }));
}
