import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
function SubscriptionPropertiesRow({ label, value }) {
    return (_jsxs("div", Object.assign({ className: "flex w-full px-6 py-3.5 justify-between border-b border-column-gray-25" }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-500" }, { children: label })), _jsx("div", Object.assign({ className: "text-column-gray-400", id: `${label}` }, { children: value || '--' }))] })));
}
/**
 * Main content displayed in the drawer for affidavit e-edition upload events
 */
export default function SubscriptionDrawerBody({ setEditedSubscription, subscription }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return (_jsxs(_Fragment, { children: [_jsx(SubscriptionPropertiesRow, { label: "Name", value: (_b = (_a = subscription.data().mailing) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.name }), _jsx(SubscriptionPropertiesRow, { label: "Subscription Query", value: subscription.data().query }), _jsx(SubscriptionPropertiesRow, { label: "Frequency", value: subscription.data().frequency || 'Daily' }), _jsx(SubscriptionPropertiesRow, { label: "Address Line 1", value: (_d = (_c = subscription.data().mailing) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.line1 }), _jsx(SubscriptionPropertiesRow, { label: "Address Line 2", value: (_f = (_e = subscription.data().mailing) === null || _e === void 0 ? void 0 : _e.address) === null || _f === void 0 ? void 0 : _f.line2 }), _jsx(SubscriptionPropertiesRow, { label: "City", value: (_h = (_g = subscription.data().mailing) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.city }), _jsx(SubscriptionPropertiesRow, { label: "State", value: (_k = (_j = subscription.data().mailing) === null || _j === void 0 ? void 0 : _j.address) === null || _k === void 0 ? void 0 : _k.state }), _jsx(SubscriptionPropertiesRow, { label: "Postal Code", value: (_m = (_l = subscription.data().mailing) === null || _l === void 0 ? void 0 : _l.address) === null || _m === void 0 ? void 0 : _m.postalCode }), _jsx("div", Object.assign({ className: classNames('absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between') }, { children: _jsx(ColumnButton, { id: "edit-subscription", primary: true, size: "lg", buttonText: "Edit", onClick: () => setEditedSubscription(subscription), type: "button" }) }))] }));
}
