var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { NOTICE_NEWSPAPER_REQUESTED } from 'lib/types/events';
import PlacementActions from 'redux/placement';
import { getFirebaseContext } from 'utils/firebase';
import { useAppDispatch } from 'redux/hooks';
export function DisabledPublisherModal({ publisherOrganization, setOpen }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    const publisherName = publisherOrganization && ((_a = publisherOrganization.data()) === null || _a === void 0 ? void 0 : _a.name)
        ? (_b = publisherOrganization.data()) === null || _b === void 0 ? void 0 : _b.name
        : 'This publication';
    return (_jsx(FreeformCModal, Object.assign({ setOpen: setOpen, afterExit: () => {
            dispatch(PlacementActions.setNewspaper(null));
        }, noExitOutsideModal: true }, { children: _jsxs("div", Object.assign({ className: "font-medium text-sm" }, { children: [_jsxs("div", Object.assign({ className: "mb-8" }, { children: [publisherName, " does not currently enable public notice placement through Column.", ' ', publisherOrganization &&
                            publisherOrganization.data().nonColumnPlacementSite && (_jsx("a", Object.assign({ className: "underline text-primary-600", href: "#", onClick: (e) => __awaiter(this, void 0, void 0, function* () {
                                e.preventDefault();
                                yield openPlacementSite(publisherOrganization);
                            }) }, { children: "Click here to be directed to their website where you can place your notice." })))] })), _jsx("div", { children: _jsx("a", Object.assign({ href: "https://info.column.us/what-is-column", className: "underline text-primary-600", target: "_blank", rel: "noopener noreferrer" }, { children: "What is Column?" })) })] })) })));
}
function openPlacementSite(publisherOrganization) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const ctx = getFirebaseContext();
        const targetUrl = (_a = publisherOrganization.data()) === null || _a === void 0 ? void 0 : _a.nonColumnPlacementSite;
        if (!targetUrl) {
            console.error(`Missing target URL for paper: ${publisherOrganization.id}`);
        }
        const event = yield ctx.eventsRef().add({
            type: NOTICE_NEWSPAPER_REQUESTED,
            data: {
                newspaper: publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.ref
            }
        });
        window.open(targetUrl, '_blank');
        console.log('Event id:', event.id);
    });
}
