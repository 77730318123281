var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableLayout } from 'lib/components/TableLayout';
import React, { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { safeStringify } from 'lib/utils/stringify';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getQueryResultsWhereKeyInArray } from 'lib/utils/firebase';
import { getFirebaseContext } from 'utils/firebase';
import AutomatedAffidavitsTableRow from './automatedAffidavitsTableRow';
import { useGetPublicationIssuesForAffidavits } from './hooks/useGetPublicationIssuesForAffidavits';
import AutomatedAffidavitsTableDrawer from './automatedAffidavitsTableDrawer';
import VerifyNoticesModal from './verifyNoticesModal';
import DownloadReportButton from './downloadReportButton';
import AutomatedAffidavitsTableFilterDialog from './automatedAffidavitsTableFilterDialog';
import { shouldShowPublicationIssueRow } from './helpers';
const DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER = {};
export default function AutomatedAffidavitsTable({ activeOrganization, onShowEEditionUploadModalForPubIssue, setSuccessMessage }) {
    const columns = [
        _jsx("th", Object.assign({ className: "w-48 font-medium" }, { children: "Publication Date" }), "publication-date"),
        _jsx("th", Object.assign({ className: "w-64 font-medium" }, { children: "Upload Method" }), "upload-method"),
        _jsx("th", Object.assign({ className: "w-80 font-medium" }, { children: "File Name(s)" }), "file-name"),
        _jsx("th", Object.assign({ className: "text-center font-medium" }, { children: "Verified Notices" }), "verified"),
        _jsx("th", Object.assign({ className: "text-center font-medium" }, { children: "Unverified Notices" }), "unverified")
    ];
    const [showVerifyNoticesModal, setShowVerifyNoticesModal] = useState(false);
    // Used to force a refresh of the publication issues
    // TODO: maybe implement in row component similar to pagination table
    const [refreshTimestamp] = useState(new Date());
    const [rowFilter, setRowFilter] = useState(DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER);
    const [editedRowFilter, setEditedRowFilter] = useState(rowFilter);
    const [drawerData, setDrawerData] = useState();
    const { publicationIssuesLoading, 
    // publicationIssuesError,
    publicationIssuesAndRuns } = useGetPublicationIssuesForAffidavits(activeOrganization, refreshTimestamp);
    const { value: allEEditions } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!publicationIssuesAndRuns) {
                return [];
            }
            const eeditions = yield getQueryResultsWhereKeyInArray(getFirebaseContext().eeditionsRef(), 'publicationIssue', publicationIssuesAndRuns.map(pubIssueAndRuns => pubIssueAndRuns.publicationIssue.ref));
            return eeditions;
        }),
        dependencies: [
            publicationIssuesAndRuns === null || publicationIssuesAndRuns === void 0 ? void 0 : publicationIssuesAndRuns.map(pubIssueAndRuns => pubIssueAndRuns.publicationIssue.id).join('')
        ]
    });
    // Consolidate loading conditions here
    const loading = publicationIssuesLoading;
    return (_jsxs("div", Object.assign({ id: "automated-affidavit-table", className: "bg-white sm:rounded-lg border border-column-gray-100 mb-24 shadow-column-2" }, { children: [_jsx(TableLayout, { filterable: {
                    shouldShowTableItem: (rowData, search) => shouldShowPublicationIssueRow(rowData, allEEditions, search, rowFilter),
                    additionalFilters: {
                        applyFilterChanges: () => setRowFilter(editedRowFilter),
                        resetFilters: () => {
                            setRowFilter(DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER);
                            setEditedRowFilter(DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER);
                        },
                        filterHasChanges: safeStringify(rowFilter) !== safeStringify(editedRowFilter),
                        numFiltersActive: Object.keys(editedRowFilter).length,
                        renderDialog: () => (_jsx(AutomatedAffidavitsTableFilterDialog, { activeOrganization: activeOrganization, editedRowFilter: editedRowFilter, setEditedRowFilter: setEditedRowFilter }))
                    }
                }, loading: loading, renderRow: rowData => _jsx(AutomatedAffidavitsTableRow, { rowData: rowData }), header: {
                    title: 'Notice Verification',
                    subtitle: 'Track your e-edition uploads and manually kick off notice verification.'
                }, columns: columns, data: publicationIssuesAndRuns || [], clickable: {
                    onClick: rowData => setDrawerData(rowData)
                }, actionable: _jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx("div", Object.assign({ className: "mr-3" }, { children: _jsx(DownloadReportButton, { activeOrganization: activeOrganization, publicationIssuesAndRuns: publicationIssuesAndRuns, setSuccessMessage: setSuccessMessage }) })), _jsx(ColumnButton, { secondary: true, type: "button", id: "automated-affidavits-verify-notices-button", startIcon: _jsx(CheckCircleIcon, { className: "w-5 h-5 -ml-0.5 mr-1.5", strokeWidth: "1.25" }), buttonText: 'Verify notices', onClick: () => setShowVerifyNoticesModal(true) })] })), pagination: { pageSize: 6 } }), drawerData && (_jsx(AutomatedAffidavitsTableDrawer, { activeOrganization: activeOrganization, drawerData: drawerData, onClose: () => setDrawerData(undefined), onUploadEEditionClick: onShowEEditionUploadModalForPubIssue })), showVerifyNoticesModal && (_jsx(VerifyNoticesModal, { activeOrganization: activeOrganization, publicationIssuesAndRuns: publicationIssuesAndRuns, onClose: () => setShowVerifyNoticesModal(false), setSuccessMessage: setSuccessMessage }))] })));
}
