import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { exists } from 'lib/types';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import LoadingState from 'components/LoadingState';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectAvailableOrganizations, selectHasClassifiedsActive, selectHasObituariesActive, selectUser, selectUserAuth } from 'redux/auth';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { Product } from 'lib/enums';
import { AdvertisingDeadlinesSettings } from './deadlines';
import DesignAndLayoutSettings from './designAndLayoutSettings';
import OrganizationMemberSettings from '../OrganizationMemberSettings';
import NewspaperSettingsPayment from './paymentSettings';
import NewspaperSettingsRates from './rates';
import PublisherSettingsInfo from './publisherSettings';
import CustomBrandSettings from '../CustomBrandSettings';
import AffidavitSettings from './affidavits';
import FTPSettings from './upload';
import OrgSettingsIntegrations from '../OrgSettingsIntegrations';
import CustomersSettings from './customers/CustomersSettings';
import { BillingSettings } from './billing';
import PublisherSettingsSidebar, { CLASSIFIEDS, OBITUARIES, GENERAL_INFO, ADVERTISING_DEADLINES, ORGANIZATION_MEMBER_SETTINGS, PAYMENT, PRICING_RESTRICTIONS, ORGANIZATION_AFFIDAVIT_SETTINGS, CUSTOM_BRAND, DESIGN_AND_LAYOUT, FTP, CUSTOMERS, INTEGRATIONS, NOTICE_TYPES, BILLING } from './PublisherSettingsSidebar';
import ProductSettings from './ProductSettings';
import NoticeTypeSettings from './filingTypes/NoticeTypeSettings';
export default function NewspaperSettings() {
    const user = useAppSelector(selectUser);
    const userAuth = useAppSelector(selectUserAuth);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const availableOrganizations = useAppSelector(selectAvailableOrganizations);
    const [currentTab, setCurrentTab] = useState(getLocationParams().get('tab') ||
        (user && userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO)
            ? GENERAL_INFO
            : ORGANIZATION_MEMBER_SETTINGS));
    const hasClassifiedsActive = useAppSelector(selectHasClassifiedsActive);
    const hasObituariesActive = useAppSelector(selectHasObituariesActive);
    const updateCurrentTab = (newTab) => {
        window.history.replaceState(null, '', `/settings/organization/?tab=${newTab}`);
        setCurrentTab(newTab);
    };
    if (!exists(activeOrganization) || !exists(user) || !userAuth) {
        return _jsx(LoadingState, {});
    }
    const settingsViews = {
        [CUSTOM_BRAND]: {
            component: (_jsx(CustomBrandSettings, { activeOrganization: activeOrganization, user: user })),
            hasPermission: true
        },
        [FTP]: {
            component: (_jsx(FTPSettings, { availableOrganizations: availableOrganizations, activeOrganization: activeOrganization })),
            hasPermission: true
        },
        [ADVERTISING_DEADLINES]: {
            component: _jsx(AdvertisingDeadlinesSettings, {}),
            hasPermission: true
        },
        [ORGANIZATION_MEMBER_SETTINGS]: {
            component: (_jsx(OrganizationMemberSettings, { activeOrganization: activeOrganization, user: user })),
            hasPermission: true
        },
        [GENERAL_INFO]: {
            component: (_jsx(PublisherSettingsInfo, { activeOrganization: activeOrganization, user: user })),
            hasPermission: true
        },
        [ORGANIZATION_AFFIDAVIT_SETTINGS]: {
            component: (_jsx(AffidavitSettings, { activeOrganization: activeOrganization, user: user })),
            hasPermission: true
        },
        [BILLING]: { component: _jsx(BillingSettings, {}), hasPermission: true },
        [PRICING_RESTRICTIONS]: {
            component: (_jsx(NewspaperSettingsRates, { activeOrganization: activeOrganization })),
            hasPermission: true
        },
        [NOTICE_TYPES]: {
            component: _jsx(NoticeTypeSettings, { activeOrganization: activeOrganization }),
            hasPermission: true
        },
        [DESIGN_AND_LAYOUT]: {
            component: (_jsx(DesignAndLayoutSettings, { activeOrganization: activeOrganization })),
            hasPermission: true
        },
        [PAYMENT]: {
            component: (_jsx(NewspaperSettingsPayment, { activeOrganization: activeOrganization, userAuth: userAuth, user: user })),
            hasPermission: true
        },
        [CUSTOMERS]: {
            component: _jsx(CustomersSettings, { activeOrganization: activeOrganization }),
            hasPermission: true
        },
        [INTEGRATIONS]: {
            component: (_jsx(OrgSettingsIntegrations, { activeOrganization: activeOrganization })),
            hasPermission: true
        },
        [OBITUARIES]: {
            component: _jsx(ProductSettings, { productLine: Product.Obituary, user: user }),
            hasPermission: hasObituariesActive
        },
        [CLASSIFIEDS]: {
            component: (_jsx(ProductSettings, { productLine: Product.Classified, user: user })),
            hasPermission: hasClassifiedsActive
        }
    };
    return (_jsx("div", Object.assign({ className: "px-12" }, { children: _jsxs("div", Object.assign({ className: "flex gap-8 mt-5" }, { children: [_jsx(PublisherSettingsSidebar, { activeOrganization: activeOrganization, onChange: newView => updateCurrentTab(newView), value: currentTab, user: user }), settingsViews[currentTab].hasPermission ? (_jsx("div", Object.assign({ className: "flex-1" }, { children: settingsViews[currentTab].component }))) : (updateCurrentTab(GENERAL_INFO))] })) })));
}
