var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { exists } from 'lib/types';
import { removeUndefinedFields } from 'lib/helpers';
import { DotIcon } from 'icons';
import { BillingTermType, Product, RoleType } from 'lib/enums';
import api from 'api';
import TabGroup from 'lib/components/Tabs';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import Drawer from 'lib/components/Drawer';
import { OrganizationTypeData } from 'lib/enums/OrganizationType';
import { getRateSnapshotFromFilingTypeLabel } from 'utils/organizations';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import { getFirebaseContext } from '../../utils/firebase';
import EditCustomerOrganizationModal from './EditCustomerOrganizationModal';
import { CustomerDrawerDetailsTab } from './CustomerDrawerDetailsTab';
import { CustomerOrganizationDrawerMembersTab } from './CustomerOrganizationDrawerMembersTab';
import { CustomerDrawerSettingsTab } from './CustomerDrawerSettingsTab';
import { CustomerOrganizationDrawerFooter } from './CustomerOrganizationDrawerFooter';
import { REQUIRE_UPFRONT_PAYMENT_TYPES } from './CreateOrEditCustomerModalSettings';
import NotesTab from './NotesTab/NotesTab';
const DETAILS_TAB = {
    label: 'Details',
    enabled: true,
    id: 'details'
};
const MEMBERS_TAB = {
    label: 'Members',
    enabled: false,
    id: 'members'
};
const SETTINGS_TAB = {
    label: 'Settings',
    enabled: true,
    id: 'settings'
};
const getCustomerOrganizationDrawerTabs = (hasMembers, notesQuery) => {
    const numberOfNotes = (notesQuery === null || notesQuery === void 0 ? void 0 : notesQuery.docs.filter(doc => doc.data().status !== 'archived').length) ||
        0;
    return [
        DETAILS_TAB,
        Object.assign(Object.assign({}, MEMBERS_TAB), { enabled: hasMembers }),
        SETTINGS_TAB,
        {
            label: numberOfNotes > 0 ? `Notes (${numberOfNotes})` : 'Notes',
            enabled: true,
            id: 'notes'
        }
    ];
};
export default function CustomerOrganizationDrawer({ customerOrganizationData, activeOrganization, open, closeDrawer, disableDarkenBackground, setShowCustomerDrawer, setCustomerId, setCustomerOrg, rates }) {
    var _a;
    const [customerOrganizationEdits, setCustomerOrganizationEdits] = useState();
    const [activeTab, setActiveTab] = useState(DETAILS_TAB);
    const [customerOrganizationName, setCustomerOrganizationName] = useState('');
    const [allowBulkInvoicing, setAllowBulkInvoicing] = useState(false);
    const [billingTerm, setBillingTerm] = useState(BillingTermType.net_thirty.value);
    const [invoicedOutsideColumn, setInvoicedOutsideColumn] = useState(false);
    const [enableAffidavitsBeforePayment, setEnableAffidavitsBeforePayment] = useState(false);
    const [address, setAddress] = useState();
    const [addressLine2, setAddressLine2] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zipCode, setZipCode] = useState();
    const [phone, setPhone] = useState();
    const [internalID, setInternalID] = useState();
    const [requireUpfrontPayment, setRequireUpfrontPayment] = useState(REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT);
    const [customers, setCustomers] = useState();
    const [loadingCustomers, setLoadingCustomers] = useState(false);
    const [customerEmails, setCustomerEmails] = useState([]);
    const [customerRoles, setCustomerRoles] = useState([]);
    const [numberOfNoticesPlaced, setNumberOfNoticesPlaced] = useState(0);
    const [showEditCustomerOrganizationModal, setShowEditCustomerOrganizationModal] = useState();
    const ctx = getFirebaseContext();
    const isFuneralHome = ((_a = customerOrganizationData.clientOrganization.data()) === null || _a === void 0 ? void 0 : _a.organizationType) ===
        OrganizationTypeData.funeral_home.value;
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16;
        const getCustomersFromCustomerOrg = () => __awaiter(this, void 0, void 0, function* () {
            setLoadingCustomers(true);
            const usersInOrgRes = yield api.post('users/get-users-from-customer-org', {
                customerOrganizationId: customerOrganizationData.customerOrganization.id
            });
            if (!usersInOrgRes.success) {
                setLoadingCustomers(false);
                return;
            }
            const userSnapshots = usersInOrgRes.userIds.map(id => ctx.usersRef().doc(id));
            const customerFromCustomerOrg = yield Promise.all(userSnapshots.map((user) => __awaiter(this, void 0, void 0, function* () {
                const customer = yield ctx
                    .customersRef()
                    .where('user', '==', user)
                    .where('organization', '==', activeOrganization.ref)
                    .get();
                if (customer.docs.length) {
                    return customer.docs[0];
                }
                return null;
            })));
            const filteredCustomerFromCustomerOrg = customerFromCustomerOrg.filter(customer => {
                return customer !== null;
            });
            const customerFromCustomerOrgEmails = yield Promise.all(filteredCustomerFromCustomerOrg.map((customer) => __awaiter(this, void 0, void 0, function* () {
                var _17;
                const user = yield (customer === null || customer === void 0 ? void 0 : customer.data().user.get());
                return ((_17 = user === null || user === void 0 ? void 0 : user.data()) === null || _17 === void 0 ? void 0 : _17.email) || '--';
            })));
            const customerFromCustomerOrgRoles = (yield Promise.all(filteredCustomerFromCustomerOrg.map((customer) => __awaiter(this, void 0, void 0, function* () {
                var _18, _19, _20;
                const user = yield (customer === null || customer === void 0 ? void 0 : customer.data().user.get());
                const userRole = ((_18 = user === null || user === void 0 ? void 0 : user.data()) === null || _18 === void 0 ? void 0 : _18.roles)
                    ? (_19 = user === null || user === void 0 ? void 0 : user.data()) === null || _19 === void 0 ? void 0 : _19.roles[customerOrganizationData.clientOrganization.id]
                    : RoleType.user.value;
                return (_20 = RoleType.by_value(userRole)) === null || _20 === void 0 ? void 0 : _20.label;
            })))).filter((role) => role !== undefined);
            setCustomers(filteredCustomerFromCustomerOrg);
            setCustomerEmails(customerFromCustomerOrgEmails);
            setCustomerRoles(customerFromCustomerOrgRoles);
            setLoadingCustomers(false);
        });
        const getNumberOfNoticesPlaced = () => __awaiter(this, void 0, void 0, function* () {
            const notices = yield ctx
                .userNoticesRef()
                .where('filedBy', '==', customerOrganizationData.clientOrganization.ref)
                .where('newspaper', '==', activeOrganization.ref)
                .get();
            setNumberOfNoticesPlaced(notices.docs.length || 0);
        });
        void getCustomersFromCustomerOrg();
        void getNumberOfNoticesPlaced();
        // Update state vars on CustomerData update
        if (customerOrganizationEdits) {
            setCustomerOrganizationName(customerOrganizationEdits.name || '--');
            setRequireUpfrontPayment((customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment) === null ||
                (customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment) === undefined
                ? REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT
                : (customerOrganizationEdits === null || customerOrganizationEdits === void 0 ? void 0 : customerOrganizationEdits.requireUpfrontPayment)
                    ? REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE
                    : REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE);
            setAddress(customerOrganizationEdits.address);
            setAddressLine2(customerOrganizationEdits.addressLine2);
            setCity(customerOrganizationEdits.city);
            setState(customerOrganizationEdits.state);
            setZipCode(customerOrganizationEdits.zipCode);
            setPhone(customerOrganizationEdits.phone);
            setAllowBulkInvoicing(!!customerOrganizationEdits.bulkPaymentEnabled_v2);
            setBillingTerm(customerOrganizationEdits.billingTerm);
            setInvoicedOutsideColumn(!!customerOrganizationEdits.invoicedOutsideColumn);
            setEnableAffidavitsBeforePayment(!!customerOrganizationEdits.enableAffidavitsBeforePayment);
            setInternalID(customerOrganizationEdits.internalID);
        }
        else {
            setCustomerOrganizationName(((_a = customerOrganizationData.customerOrganization.data()) === null || _a === void 0 ? void 0 : _a.name) ||
                (((_b = customerOrganizationData.clientOrganization.data()) === null || _b === void 0 ? void 0 : _b.name) !== ' ' &&
                    ((_c = customerOrganizationData.clientOrganization.data()) === null || _c === void 0 ? void 0 : _c.name)) ||
                '--');
            setRequireUpfrontPayment(((_d = customerOrganizationData.customerOrganization.data()) === null || _d === void 0 ? void 0 : _d.requireUpfrontPayment) === null ||
                ((_e = customerOrganizationData.customerOrganization.data()) === null || _e === void 0 ? void 0 : _e.requireUpfrontPayment) === undefined
                ? REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT
                : ((_f = customerOrganizationData.customerOrganization.data()) === null || _f === void 0 ? void 0 : _f.requireUpfrontPayment)
                    ? REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE
                    : REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE);
            setAddress(((_g = customerOrganizationData.customerOrganization.data()) === null || _g === void 0 ? void 0 : _g.address) ||
                ((_h = customerOrganizationData.clientOrganization.data()) === null || _h === void 0 ? void 0 : _h.address));
            setAddressLine2(((_j = customerOrganizationData.customerOrganization.data()) === null || _j === void 0 ? void 0 : _j.addressLine2) ||
                (((_k = customerOrganizationData.customerOrganization.data()) === null || _k === void 0 ? void 0 : _k.addressLine2) !== ''
                    ? (_l = customerOrganizationData.clientOrganization.data()) === null || _l === void 0 ? void 0 : _l.addressLine2
                    : ''));
            setCity(((_m = customerOrganizationData.customerOrganization.data()) === null || _m === void 0 ? void 0 : _m.city) ||
                ((_o = customerOrganizationData.clientOrganization.data()) === null || _o === void 0 ? void 0 : _o.city));
            setState(((_p = customerOrganizationData.customerOrganization.data()) === null || _p === void 0 ? void 0 : _p.state) ||
                ((_q = customerOrganizationData.clientOrganization.data()) === null || _q === void 0 ? void 0 : _q.state));
            setZipCode(((_r = customerOrganizationData.customerOrganization.data()) === null || _r === void 0 ? void 0 : _r.zipCode) ||
                ((_s = customerOrganizationData.clientOrganization.data()) === null || _s === void 0 ? void 0 : _s.zipCode));
            setPhone(((_t = customerOrganizationData.customerOrganization.data()) === null || _t === void 0 ? void 0 : _t.phone) ||
                ((_u = customerOrganizationData.clientOrganization.data()) === null || _u === void 0 ? void 0 : _u.phone));
            setAllowBulkInvoicing(!!((_v = customerOrganizationData.customerOrganization.data()) === null || _v === void 0 ? void 0 : _v.bulkPaymentEnabled_v2));
            setBillingTerm((_w = customerOrganizationData.customerOrganization.data()) === null || _w === void 0 ? void 0 : _w.billingTerm);
            setInvoicedOutsideColumn(!!((_x = customerOrganizationData.customerOrganization.data()) === null || _x === void 0 ? void 0 : _x.invoicedOutsideColumn));
            setEnableAffidavitsBeforePayment(!!((_y = customerOrganizationData.customerOrganization.data()) === null || _y === void 0 ? void 0 : _y.enableAffidavitsBeforePayment));
            setInternalID((_z = customerOrganizationData.customerOrganization.data()) === null || _z === void 0 ? void 0 : _z.internalID);
        }
        // Check if we need to override customer data by properties from org or user
        const updates = {
            name: (!((_0 = customerOrganizationData.customerOrganization.data()) === null || _0 === void 0 ? void 0 : _0.name) ||
                ((_1 = customerOrganizationData.customerOrganization.data()) === null || _1 === void 0 ? void 0 : _1.name) === ' ') &&
                ((_2 = customerOrganizationData.clientOrganization.data()) === null || _2 === void 0 ? void 0 : _2.name)
                ? (_3 = customerOrganizationData.clientOrganization.data()) === null || _3 === void 0 ? void 0 : _3.name
                : undefined,
            address: !((_4 = customerOrganizationData.customerOrganization.data()) === null || _4 === void 0 ? void 0 : _4.address)
                ? ((_5 = customerOrganizationData.clientOrganization.data()) === null || _5 === void 0 ? void 0 : _5.address) ||
                    undefined
                : undefined,
            addressLine2: !((_6 = customerOrganizationData.customerOrganization.data()) === null || _6 === void 0 ? void 0 : _6.addressLine2) &&
                ((_7 = customerOrganizationData.customerOrganization.data()) === null || _7 === void 0 ? void 0 : _7.addressLine2) !==
                    ''
                ? ((_8 = customerOrganizationData.clientOrganization.data()) === null || _8 === void 0 ? void 0 : _8.addressLine2) ||
                    undefined
                : undefined,
            city: !((_9 = customerOrganizationData.customerOrganization.data()) === null || _9 === void 0 ? void 0 : _9.city)
                ? ((_10 = customerOrganizationData.clientOrganization.data()) === null || _10 === void 0 ? void 0 : _10.city) || undefined
                : undefined,
            state: !((_11 = customerOrganizationData.customerOrganization.data()) === null || _11 === void 0 ? void 0 : _11.state)
                ? ((_12 = customerOrganizationData.clientOrganization.data()) === null || _12 === void 0 ? void 0 : _12.state) || undefined
                : undefined,
            zipCode: !((_13 = customerOrganizationData.customerOrganization.data()) === null || _13 === void 0 ? void 0 : _13.zipCode)
                ? ((_14 = customerOrganizationData.clientOrganization.data()) === null || _14 === void 0 ? void 0 : _14.zipCode) ||
                    undefined
                : undefined,
            phone: !((_15 = customerOrganizationData.customerOrganization.data()) === null || _15 === void 0 ? void 0 : _15.phone)
                ? ((_16 = customerOrganizationData.clientOrganization.data()) === null || _16 === void 0 ? void 0 : _16.phone) || undefined
                : undefined
        };
        removeUndefinedFields(updates);
        if (Object.keys(updates).length) {
            void customerOrganizationData.customerOrganization.ref.update(updates);
        }
    }, [
        customerOrganizationEdits,
        safeStringify(customerOrganizationData.customerOrganization.data()),
        safeStringify(customerOrganizationData.clientOrganization.data())
    ]);
    const header = (_jsx("div", Object.assign({ className: "text-column-gray-800 font-semibold text-xl" }, { children: customerOrganizationName || '--' })));
    const subheader = (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsxs("div", Object.assign({ className: "text-xs leading-5 text-column-gray-400 font-medium pt-1" }, { children: ["ID: ", internalID || '--'] })), _jsx("div", Object.assign({ className: "mx-3 h-full relative" }, { children: _jsx("div", { className: "border-l border-solid border-column-gray-400", style: {
                        height: '14px',
                        transform: 'translateY(20%)'
                    } }) })), _jsx("div", Object.assign({ className: "pt-0.25" }, { children: _jsxs("div", Object.assign({ className: "billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-column-green-50 text-column-green-500" }, { children: [_jsx(DotIcon, { className: 'text-column-green-500' }), _jsxs("div", Object.assign({ style: { paddingLeft: '7px' } }, { children: [numberOfNoticesPlaced, " notices placed"] }))] })) }))] })));
    const customerOrganizationNotes = useFirestoreQueryListener(getFirebaseContext()
        .notesRef()
        .where('customerOrganization', '==', customerOrganizationData.customerOrganization.ref)
        .where('noteCreatorOrganization', '==', activeOrganization.ref), [customerOrganizationData.customerOrganization.id]);
    const tabs = getCustomerOrganizationDrawerTabs(customerEmails.length > 0, customerOrganizationNotes);
    const notesTabIsActive = activeTab.id === 'notes';
    const { value: defaultFuneralHomeRates } = useAsyncEffect({
        // TODO: Rework this section to take in filing type, publishing medium, and handle error state
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            var _b, _c, _d, _e, _f, _g, _h, _j;
            const customerOrgData = customerOrganizationData.customerOrganization.data();
            const customerOrgObituaryRate = customerOrgData
                ? yield ((_b = customerOrgData.obituaryRate) === null || _b === void 0 ? void 0 : _b.get())
                : undefined;
            const customerDeathNoticeRate = customerOrgData
                ? yield ((_c = customerOrgData.deathNoticeRate) === null || _c === void 0 ? void 0 : _c.get())
                : undefined;
            // TODO: Pass this prop in
            const publishingMedium = PublishingMedium.Print;
            // TODO: Pass this prop in
            const filingTypeLabel = ObituaryFilingTypeNames.Obituary;
            const { response: obitRate } = yield getRateSnapshotFromFilingTypeLabel(activeOrganization, Product.Obituary, publishingMedium, filingTypeLabel);
            return {
                obituaryRate: (_f = (_e = (_d = customerOrgObituaryRate === null || customerOrgObituaryRate === void 0 ? void 0 : customerOrgObituaryRate.data()) === null || _d === void 0 ? void 0 : _d.description) !== null && _e !== void 0 ? _e : obitRate === null || obitRate === void 0 ? void 0 : obitRate.description) !== null && _f !== void 0 ? _f : '',
                deathNoticeRate: (_j = (_h = (_g = customerDeathNoticeRate === null || customerDeathNoticeRate === void 0 ? void 0 : customerDeathNoticeRate.data()) === null || _g === void 0 ? void 0 : _g.description) !== null && _h !== void 0 ? _h : obitRate === null || obitRate === void 0 ? void 0 : obitRate.description) !== null && _j !== void 0 ? _j : ''
            };
        }),
        dependencies: [isFuneralHome, exists(activeOrganization)],
        initialData: {
            obituaryRate: '',
            deathNoticeRate: ''
        },
        errorConfig: {
            message: 'Error fetching rate'
        }
    });
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "relative" }, { children: [open && !showEditCustomerOrganizationModal && (_jsx(Drawer, Object.assign({ open: open, onClose: closeDrawer, header: _jsxs("div", { children: [header, subheader] }), disableDarkenBackground: disableDarkenBackground }, { children: _jsxs(_Fragment, { children: [_jsx(TabGroup, { tabs: tabs, activeTab: activeTab, onClickTab: setActiveTab, id: "customer-drawer-tabs" }), _jsxs("div", Object.assign({ className: "flex-1 flex overflow-scroll" }, { children: [activeTab.label === DETAILS_TAB.label && (_jsx(CustomerDrawerDetailsTab, { userName: customerOrganizationName, address: address, addressLine2: addressLine2, phone: phone, city: city, state: state, zipCode: zipCode, internalID: internalID, isCustomerOrganization: true })), activeTab.label === MEMBERS_TAB.label && (_jsx(CustomerOrganizationDrawerMembersTab, { customers: customers, loadingCustomers: loadingCustomers, setShowCustomerDrawer: (show) => {
                                            setShowCustomerDrawer(show);
                                            closeDrawer();
                                        }, setCustomerIdToDisplay: setCustomerId, customerEmails: customerEmails, customerRoles: customerRoles })), activeTab.label === SETTINGS_TAB.label && (_jsx(CustomerDrawerSettingsTab, { upfrontPayment: requireUpfrontPayment.description || '', activeOrganization: activeOrganization, allowBulkInvoicing: allowBulkInvoicing, billingTerm: billingTerm, affidavitsBeforePayment: enableAffidavitsBeforePayment, invoicedOutsideColumn: invoicedOutsideColumn, isCustomerOrganization: true, isFuneralHome: isFuneralHome, funeralHomeRates: defaultFuneralHomeRates })), notesTabIsActive &&
                                        exists(customerOrganizationData.customerOrganization) && (_jsx(NotesTab, { noteTopic: {
                                            customerOrganization: customerOrganizationData.customerOrganization.ref,
                                            noteType: 'customer-organization-note'
                                        }, notes: customerOrganizationNotes === null || customerOrganizationNotes === void 0 ? void 0 : customerOrganizationNotes.docs })), !notesTabIsActive && (_jsx(CustomerOrganizationDrawerFooter, { onEditCustomerOrganization: () => setShowEditCustomerOrganizationModal(true) }))] }))] }) }))), showEditCustomerOrganizationModal && (_jsx(EditCustomerOrganizationModal, { onClose: () => {
                        setShowEditCustomerOrganizationModal(false);
                        closeDrawer();
                        setCustomerOrg(undefined);
                    }, setCustomerOrganization: (customerOrganization) => {
                        setCustomerOrganizationEdits(customerOrganization);
                    }, customerOrganizationData: customerOrganizationData, activeOrganization: activeOrganization, rates: rates }))] })) }));
}
