import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PreviewRow } from 'components/FullScreenModal/PreviewRow';
import { Badge } from 'lib/components/Badge';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import React from 'react';
import FormSwitch from 'routes/settings/publisher/FormSwitch';
export function TemplateStyles({ templateStyles }) {
    const { pointsPerInch = 72 } = templateStyles !== null && templateStyles !== void 0 ? templateStyles : {};
    const pointsToInches = (pointValue) => pointValue ? (pointValue / pointsPerInch).toFixed(2) : 0;
    if (!templateStyles) {
        return _jsx(LoadingSpinner, {});
    }
    const { pageHeight, pageWidth, columnCount, columnGutter, font, fontSize, firstLineIndent, justification, borderWidth, hyphenation } = templateStyles;
    return (_jsxs(_Fragment, { children: [_jsx(PreviewRow, Object.assign({ label: "Page height", firstRow: true }, { children: _jsxs(Badge, { children: [pointsToInches(pageHeight), " inches"] }) })), _jsx(PreviewRow, Object.assign({ label: "Page width" }, { children: _jsxs(Badge, { children: [pointsToInches(pageWidth), " inches"] }) })), _jsx(PreviewRow, Object.assign({ label: "Number of columns" }, { children: _jsx(Badge, { children: columnCount }) })), _jsx(PreviewRow, Object.assign({ label: "Column gutter" }, { children: _jsxs(Badge, { children: [pointsToInches(columnGutter), " inches"] }) })), _jsx(PreviewRow, Object.assign({ label: "Font" }, { children: _jsx(Badge, { children: font }) })), _jsx(PreviewRow, Object.assign({ label: "Font size" }, { children: _jsxs(Badge, { children: [fontSize, "pt"] }) })), _jsx(PreviewRow, Object.assign({ label: "First line indent" }, { children: _jsxs(Badge, { children: [pointsToInches(firstLineIndent), " inches"] }) })), _jsx(PreviewRow, Object.assign({ label: "Justification" }, { children: _jsx(Badge, { children: justification === null || justification === void 0 ? void 0 : justification.replace('Justified', ' Justified').replace('Align', ' Aligned') }) })), _jsx(PreviewRow, Object.assign({ label: "Border width" }, { children: _jsxs(Badge, { children: [borderWidth, "pt"] }) })), _jsx(PreviewRow, Object.assign({ label: "Hyphenation" }, { children: _jsx(FormSwitch, { checked: !!hyphenation, disabled: true }) }))] }));
}
