import { Product } from '../enums';
import { PublicationIssueStatus } from '../types/publicationIssue';
import { getDateStringForDateInTimezone } from '../utils/dates';
export const productTypeUsesPublicationIssueSections = (product) => {
    if (product === undefined) {
        return false;
    }
    if (product === Product.Notice) {
        return false;
    }
    return true;
};
export const getNewSectionStatus = (newspaperIsPublishing, publicationDate, publisherTimezone) => {
    const todayDateString = getDateStringForDateInTimezone({
        date: new Date(),
        dateFormat: 'YYYY-MM-DD',
        timezone: publisherTimezone
    });
    if (!newspaperIsPublishing) {
        return PublicationIssueStatus.DISABLED;
    }
    if (todayDateString > publicationDate) {
        return PublicationIssueStatus.ARCHIVED;
    }
    return PublicationIssueStatus.PENDING;
};
