import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoticesActions from 'redux/notices';
import AuthActions from 'redux/auth';
import YourNoticesTableView from './YourNoticesTableView';
const mapStateToProps = (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return ({
        activeOrganization: state.auth.activeOrganization,
        userAuth: (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuth,
        userSnap: (_b = state.auth) === null || _b === void 0 ? void 0 : _b.user,
        showAllOrgsNotices: (_c = state.auth) === null || _c === void 0 ? void 0 : _c.showAllOrgsNotices,
        notices: (_d = state.notices) === null || _d === void 0 ? void 0 : _d.notices,
        tab: (_e = state.notices) === null || _e === void 0 ? void 0 : _e.tab,
        sort: (_f = state.notices) === null || _f === void 0 ? void 0 : _f.sort,
        showUserOnlyNotices: (_g = state.notices) === null || _g === void 0 ? void 0 : _g.showUserOnlyNotices,
        search: (_h = state.notices) === null || _h === void 0 ? void 0 : _h.search,
        total: (_j = state.notices) === null || _j === void 0 ? void 0 : _j.total,
        fetching: (_k = state.notices) === null || _k === void 0 ? void 0 : _k.fetching,
        pageSize: (_l = state.notices) === null || _l === void 0 ? void 0 : _l.pageSize,
        noticesFilterValue: (_m = state.notices) === null || _m === void 0 ? void 0 : _m.noticesFilterValue
    });
};
const mapDispatchToProps = (dispatch) => ({
    noticesActions: bindActionCreators(NoticesActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(YourNoticesTableView);
