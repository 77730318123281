var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { isIndividualOrder } from 'lib/types/order';
import { centsToDollarsString } from 'lib/helpers';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { isClassifiedModel } from 'lib/model/objects/adModel';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { AdDetailHeader } from './AdDetailHeader';
import { PublicationDetails } from './PublicationDetails';
import { useOrderEditableData } from '../place/hooks/useOrderEditableData';
export function ClassifiedDetail({ order, orderDetail, newspaperOrders, anonymousUserAuthInfo }) {
    var _a, _b;
    const userSnap = useAppSelector(selectUser);
    const isPublisher = useAppSelector(selectIsPublisher);
    const { value: classified, isLoading: loadingClassified } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () { return order.getAdByVersion(); }),
        errorConfig: {
            service: ColumnService.OBITS,
            message: 'Error fetching classified',
            tags: { orderId: order.id }
        },
        dependencies: [order.id, order.modelData.activeVersion]
    });
    const editData = useOrderEditableData({
        userSnap,
        order,
        newspaperOrders: newspaperOrders.map(no => no.modelData)
    });
    if (loadingClassified || !classified || !isClassifiedModel(classified)) {
        return (_jsx(LoadingState, { context: {
                service: ColumnService.OBITS,
                location: 'Classified Detail',
                tags: { orderId: order.id, adDetail: 'true' }
            } }));
    }
    return (_jsxs("div", Object.assign({ className: "grid grid-cols-12 gap-3" }, { children: [_jsx("div", Object.assign({ className: "col-span-12 flex justify-between lg:py-4" }, { children: _jsx(AdDetailHeader, { title: classified.modelData.title, order: order, orderDetail: orderDetail, anonymousUserAuthInfo: anonymousUserAuthInfo, editData: editData }) })), _jsxs("div", Object.assign({ className: "col-span-12 lg:col-span-8 space-y-3" }, { children: [_jsx(AdDetailsCard, Object.assign({ id: "publication-details", header: {
                            title: 'Publication Details'
                        } }, { children: newspaperOrders.map(newspaperOrder => (_jsx(PublicationDetails, { newspaperOrder: newspaperOrder, ad: classified, editData: editData === null || editData === void 0 ? void 0 : editData[newspaperOrder.id] }, newspaperOrder.id))) })), isIndividualOrder(order.modelData) && isPublisher && (_jsxs(AdDetailsCard, Object.assign({ id: "customer-details", header: { title: 'Customer Details' } }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-500 pb-1" }, { children: "Name" })), _jsxs("div", Object.assign({ className: "text-column-gray-400" }, { children: [order.modelData.firstName, " ", order.modelData.lastName] })), _jsx("div", Object.assign({ className: "text-column-gray-500 pt-4 pb-1" }, { children: "Email" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: order.modelData.contactEmail })), _jsx("div", Object.assign({ className: "text-column-gray-500 pt-4 pb-1" }, { children: "Phone" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: order.modelData.phone }))] })))] })), _jsx("div", Object.assign({ className: "col-span-12 lg:col-span-4" }, { children: _jsxs(AdDetailsCard, Object.assign({ id: "order-details", header: { title: 'Order Details' } }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-500 pb-1" }, { children: "Order no" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: order.id })), _jsx("div", Object.assign({ className: "text-column-gray-500 pt-4 pb-1" }, { children: "Price" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: ((_a = orderDetail.modelData.pricing) === null || _a === void 0 ? void 0 : _a.totalInCents)
                                ? `$${centsToDollarsString((_b = orderDetail.modelData.pricing) === null || _b === void 0 ? void 0 : _b.totalInCents)}`
                                : '' }))] })) }))] })));
}
