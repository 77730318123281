var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InviteStatus } from '../enums';
import { getOccupationValFromOrganizationVal } from '../helpers';
import { getOrThrow } from '../utils/refs';
export class InviteService {
    constructor(context) {
        this.context = context;
    }
    maybeGetOutstandingInvite(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const outstandingInvites = yield this.context
                .invitesRef()
                .where('email', '==', user.data().email)
                .where('status', '==', InviteStatus.pending.value)
                .limit(1)
                .get();
            return outstandingInvites.docs[0];
        });
    }
    createInvite(email, organization, publisher) {
        return __awaiter(this, void 0, void 0, function* () {
            const normalizedEmail = email.toLowerCase();
            const occupationType = getOccupationValFromOrganizationVal(organization.data().organizationType);
            const invite = yield this.context.invitesRef().add({
                organizationName: organization.data().name,
                createdAt: this.context.timestamp(),
                email: normalizedEmail,
                occupationType,
                status: InviteStatus.pending.value,
                invitedByPublisher: publisher.ref,
                organizationId: organization.id,
                source: 'column'
            });
            return getOrThrow(invite);
        });
    }
}
