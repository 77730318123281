var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel, getModelFromRef, getModelFromSnapshot } from '..';
import { Collections, COLUMN_EXPRESS_EMAIL } from '../../constants';
import { ImplementationStatus, OrganizationStatus, OrganizationType, Product } from '../../enums';
import { getOverrideAAC, getPublisherAAC } from '../../types/affidavits/convertARS';
import { getErrorReporter } from '../../utils/errors';
import { removeUndefinedFields } from '../../helpers';
import { wrapError, wrapSuccess } from '../../types/responses';
import { ProductPublishingSettingsService } from '../../services/productPublishingSettingsService';
import { InternalServerError } from '../../errors/ColumnErrors';
import { PublishingSettingsService } from '../../services/publishingSettingsService';
import { PublishingSettingModel } from './publishingSettingModel';
import { safeGetModelArrayFromRefs } from '../getModel';
import { ColumnService } from '../../services/directory';
import { getRolesFromAllowedOrgs } from '../../users';
import { FilingTypeVisibilityData } from '../../enums/FilingTypeVisibility';
import { safeAsync } from '../../safeWrappers';
import { ProductSiteSettingService } from '../../services/productSiteSettingService';
import { UserModel } from './userModel';
import { UserService } from '../../services/userService';
const ACTIVE_STATUSES = [
    ImplementationStatus.Live,
    ImplementationStatus.InImplementation
];
export class OrganizationModel extends SnapshotModel {
    constructor() {
        super(...arguments);
        this.parent = null;
        this.productSiteSettingService = new ProductSiteSettingService(this.ctx);
        this.productPublishingSettingsService = new ProductPublishingSettingsService(this.ctx);
        this.publishingSettingsService = new PublishingSettingsService(this.ctx);
        this.userService = new UserService(this.ctx);
    }
    get type() {
        return Collections.organizations;
    }
    getParent() {
        return __awaiter(this, void 0, void 0, function* () {
            const modelParent = this.modelData.parent;
            if (!this.parent && modelParent) {
                const { response: parent, error } = yield safeAsync(() => __awaiter(this, void 0, void 0, function* () {
                    return getModelFromRef(OrganizationModel, this.ctx, modelParent);
                }))();
                if (error) {
                    return wrapError(error);
                }
                this.parent = parent;
            }
            return wrapSuccess(this.parent);
        });
    }
    getSubOrganizations() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: querySnapshot, error } = yield safeAsync(() => __awaiter(this, void 0, void 0, function* () {
                const querySnapshot = yield this.ctx
                    .organizationsRef()
                    .where('parent', '==', this.ref)
                    .get();
                return querySnapshot;
            }))();
            if (error) {
                return wrapError(error);
            }
            const subOrganizationsModels = querySnapshot.docs.map(subOrganization => getModelFromSnapshot(OrganizationModel, this.ctx, subOrganization));
            return wrapSuccess(subOrganizationsModels);
        });
    }
    get isPublisherOrganization() {
        const typeEnum = OrganizationType.by_value(this.modelData.organizationType);
        return !!(typeEnum === null || typeEnum === void 0 ? void 0 : typeEnum.isPublisher);
    }
    isOrganizationType(type) {
        return this.modelData.organizationType === type.value;
    }
    hasAdTypeActive(product) {
        switch (product) {
            case Product.Obituary: {
                const { obituaryImplementationStatus } = this.modelData;
                return (this.isOrganizationType(OrganizationType.funeral_home) ||
                    (this.isPublisherOrganization &&
                        obituaryImplementationStatus !== undefined &&
                        ACTIVE_STATUSES.includes(obituaryImplementationStatus)));
            }
            case Product.Classified: {
                const { classifiedImplementationStatus } = this.modelData;
                return (this.isPublisherOrganization &&
                    classifiedImplementationStatus !== undefined &&
                    ACTIVE_STATUSES.includes(classifiedImplementationStatus));
            }
            case Product.Notice: {
                return !this.isOrganizationType(OrganizationType.funeral_home);
            }
            default: {
                const err = new InternalServerError(`Unknown product type: ${product}`);
                getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, err, 'Unable to determine if org has product active', {
                    organizationId: this.id,
                    product
                });
                throw err;
            }
        }
    }
    maybeFetchPublishingSettingFromProductMedium({ product, publishingMedium }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: productPublishingSetting, error: fetchProductSettingsError } = yield this.maybeFetchProductPublishingSetting({
                product,
                publishingMedium
            });
            if (fetchProductSettingsError) {
                return wrapError(fetchProductSettingsError);
            }
            if (!productPublishingSetting) {
                return wrapSuccess(null);
            }
            return productPublishingSetting.fetchPublishingSetting();
        });
    }
    createProductSiteSetting(product, setting) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.productSiteSettingService.createProductSiteSetting(this.ref, setting);
        });
    }
    maybeFetchProductSiteSetting(product) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.productSiteSettingService.maybeFetchProductSiteSetting(this.ref, product);
        });
    }
    /**
     * Fetches a single product publishing setting when you want to
     * specify both a product and a publishingMedium
     */
    maybeFetchProductPublishingSetting({ product, publishingMedium }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: productPublishingSettings, error: fetchError } = yield this.productPublishingSettingsService.fetchProductPublishingSettingArray(this.ref, product, publishingMedium);
            if (fetchError) {
                return wrapError(fetchError);
            }
            if (productPublishingSettings.length === 0) {
                getErrorReporter().logInfo('No product publishing settings found', {
                    publisherId: this.id,
                    product,
                    publishingMedium,
                    service: ColumnService.OBITS
                });
                return wrapSuccess(undefined);
            }
            if (productPublishingSettings.length > 1) {
                const error = new InternalServerError('Multiple product publishing settings found for a single publishing medium and product');
                getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, error, 'fetchProductPublishingSetting failed due to too many results. Returning error...', {
                    publisherId: this.id,
                    product,
                    publishingMedium,
                    service: ColumnService.OBITS
                });
                return wrapError(error);
            }
            return wrapSuccess(productPublishingSettings[0]);
        });
    }
    fetchOrCreateDetailedProductPublishingSetting({ product, publishingMedium }, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.productPublishingSettingsService.fetchOrCreateDetailedProductPublishingSetting(this.ref, product, publishingMedium, options);
        });
    }
    fetchPublishingSettings({ product, publishingMedium } = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: productPublishingSettings, error: fetchError } = yield this.productPublishingSettingsService.fetchProductPublishingSettingArray(this.ref, product, publishingMedium);
            if (fetchError) {
                return wrapError(fetchError);
            }
            const publishingSettingRefs = productPublishingSettings.map(productPublishingSetting => productPublishingSetting.modelData.publishingSetting);
            return safeGetModelArrayFromRefs(PublishingSettingModel, this.ctx, publishingSettingRefs);
        });
    }
    // Returns all publishing mediums enabled for the publisher at hand
    fetchAvailablePublishingMediums(product) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: productPublishingSettings, error } = yield this.productPublishingSettingsService.fetchProductPublishingSettingArray(this.ref, product);
            if (error) {
                return wrapError(error);
            }
            if (!productPublishingSettings.length) {
                return wrapSuccess(undefined);
            }
            const publishingMediums = productPublishingSettings.map(productPublishingSetting => productPublishingSetting.modelData.publishingMedium);
            return wrapSuccess(publishingMediums);
        });
    }
    /**
     * Fetches all filing types for an organization given an optional product &
     * publishing medium
     *
     * FilingTypes are nested under productPublishingSettings and publishingSettings,
     * so the combinations and high filing type counts for some products make this a
     * potentially expensive operation
     *
     * Discussion: https://columnpbc.slack.com/archives/C063V00UK6W/p1717454095427729
     */
    fetchFilingTypesForProductMedium({ product, publishingMedium }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: publishingSettings, error: fetchPublishingSettingsError } = yield this.fetchPublishingSettings({ product, publishingMedium });
            if (fetchPublishingSettingsError) {
                return wrapError(fetchPublishingSettingsError);
            }
            return this.publishingSettingsService.fetchFilingTypesFromPublishingSettings(publishingSettings);
        });
    }
    /**
     * This potentially uses a ton of queries!
     *
     * TODO(goodpaul): Don't grab every filing type to figure out if we have one with a rate on it
     * Maybe guard the write of [product]implementationStatus to confirm it's gtg before writing?
     * We then also have to listen to filing types/rates updates to unsure it remains valid...
     * Discussion: https://columnpbc.slack.com/archives/C063V00UK6W/p1717454095427729
     */
    canAcceptAdType(product) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: filingTypes, error: fetchFilingTypesError } = yield this.fetchFilingTypesForProductMedium({
                product
            });
            if (fetchFilingTypesError) {
                return wrapError(fetchFilingTypesError);
            }
            const oneFilingTypeHasARate = filingTypes.some(filingType => filingType.modelData.rate !== undefined);
            return wrapSuccess(this.hasAdTypeActive(product) && oneFilingTypeHasARate);
        });
    }
    updateFilingTypes(filingTypes) {
        return __awaiter(this, void 0, void 0, function* () {
            const allowedNotices = yield Promise.all(filingTypes.map((filingType) => __awaiter(this, void 0, void 0, function* () {
                const { affidavitReconciliationSettings } = filingType;
                if (!affidavitReconciliationSettings) {
                    return filingType;
                }
                const { response: automatedAffidavitConfiguration, error } = yield getOverrideAAC(this.ctx, this, affidavitReconciliationSettings);
                if (error) {
                    getErrorReporter().logAndCaptureError(ColumnService.AFFIDAVITS, error, 'Failed to get automated affidavit configuration from affidavit reconciliation settings for filing type', {
                        publisherId: this.id,
                        filingTypeValue: filingType.value.toString()
                    });
                    return filingType;
                }
                return Object.assign(Object.assign({}, filingType), { automatedAffidavitConfiguration: removeUndefinedFields(automatedAffidavitConfiguration) });
            })));
            yield this.ref.update({
                allowedNotices
            });
        });
    }
    updateAutomatedAffidavitConfiguration(updates) {
        return __awaiter(this, void 0, void 0, function* () {
            const { affidavitReconciliationSettings } = this.modelData;
            const newAffidavitReconciliationSettings = Object.assign(Object.assign({
                managedAffidavitTemplateStoragePath: '',
                notarizationVendor: 'notarize',
                uploadMethod: 'not-applicable',
                affidavitsManagedByColumn: false,
                notarizationRequired: true,
                reconciliationStartDate: this.ctx.timestamp()
            }, affidavitReconciliationSettings), updates);
            const { response: automatedAffidavitConfiguration, error } = yield getPublisherAAC(this.ctx, this, newAffidavitReconciliationSettings);
            if (error) {
                getErrorReporter().logAndCaptureError(ColumnService.AFFIDAVITS, error, 'Failed to get automated affidavit configuration from affidavit reconciliation settings for publisher', { publisherId: this.id });
                return yield this.ref.update({
                    affidavitReconciliationSettings: newAffidavitReconciliationSettings
                });
            }
            yield this.ref.update(removeUndefinedFields({
                affidavitReconciliationSettings: newAffidavitReconciliationSettings,
                automatedAffidavitConfiguration
            }));
        });
    }
    /**
     * Returns a list of child organizations refs given a specified parent org
     * This is used to populate/update a user's allowedOrganizations
     * array which contains all the orgs they can access
     *
     * If no children are found, just returns the original org in the array
     */
    getAllowedOrgsAndRolesFromParent(role) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: childOrgs, error } = yield this.getSubOrganizations();
            if (error) {
                return wrapError(error);
            }
            const allowedOrganizations = childOrgs.concat([this]).map(org => org.ref);
            return wrapSuccess({
                allowedOrganizations,
                roles: getRolesFromAllowedOrgs(allowedOrganizations, role)
            });
        });
    }
    /**
     * For advertisers orgs, we do not have direct parent/child relationship.
     * This will return user's new allowedOrganization and updated roles map
     */
    getNewAllowedOrgsAndRolesOfUser(role) {
        const allowedOrganizations = [this.ref];
        return {
            allowedOrganizations,
            roles: getRolesFromAllowedOrgs(allowedOrganizations, role)
        };
    }
    getUserOrgStructureFromOrganization(role) {
        return __awaiter(this, void 0, void 0, function* () {
            /**
             * Check if the user is joining an organization with existing children
             *
             * If they are, also add those orgs to their allowedOrgs
             *
             * If none are found, this default returns an array containing only
             * the specified org to join
             */
            const isPublisherOrg = this.isPublisherOrganization;
            // For publisher org, if a user gets invite from publisher org and that publisher org
            // is a parent of some child org; the user will also become the member of these child organizations and roles will add of these orgs in user;
            // Advertisers does not have parent organization
            if (isPublisherOrg) {
                const { response, error } = yield this.getAllowedOrgsAndRolesFromParent(role);
                if (error) {
                    return wrapError(error);
                }
                return wrapSuccess(response);
            }
            return wrapSuccess(this.getNewAllowedOrgsAndRolesOfUser(role));
        });
    }
    isFilingTypeAvailableForNewspaper({ selectedFilingType, product, publishingMedium }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: supportedFilingTypes, error: fetchError } = yield this.fetchFilingTypesForProductMedium({
                product,
                publishingMedium
            });
            if (fetchError) {
                return wrapError(fetchError);
            }
            const matchedFilingType = supportedFilingTypes.find(filingType => filingType.modelData.label === selectedFilingType &&
                filingType.modelData.visibility !==
                    FilingTypeVisibilityData.disabled.value);
            if (!(matchedFilingType === null || matchedFilingType === void 0 ? void 0 : matchedFilingType.modelData.rate)) {
                const err = new Error(`Filing type is unavailable`);
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, err, `Rate is not available for ${selectedFilingType} type`, {
                    publisherId: this.id,
                    selectedFilingType,
                    product,
                    publishingMedium
                });
                return wrapError(err);
            }
            if (matchedFilingType === undefined) {
                const err = new Error(`Filing type is unavailable`);
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, err, `Filing type is unavailable for this newspaper. No match found.`, {
                    publisherId: this.id,
                    selectedFilingType,
                    product,
                    publishingMedium
                });
                return wrapError(err);
            }
            return wrapSuccess(matchedFilingType);
        });
    }
    // An organization is related to the current organization if
    // 1- They have the same parent organization
    // 2- It's a child of the current organization
    getRelatedOrganizations() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: parent, error } = yield this.getParent();
            if (error) {
                return wrapError(error);
            }
            let relatedOrgs = [];
            if (parent) {
                const { response: parentSubOrganizations, error } = yield parent.getSubOrganizations();
                if (error) {
                    return wrapError(error);
                }
                relatedOrgs = parentSubOrganizations;
            }
            else {
                const { response: subOrganizations, error } = yield this.getSubOrganizations();
                if (error) {
                    return wrapError(error);
                }
                relatedOrgs = [...subOrganizations, this];
            }
            return wrapSuccess(relatedOrgs);
        });
    }
    getMembers() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.userService.getOrganizationMembers(this.ref);
        });
    }
    getNotificationRecipients() {
        return __awaiter(this, void 0, void 0, function* () {
            let recipients;
            let forceEmailRecipient;
            const organizationData = this.modelData;
            const isOrganizationColumnCustomer = !this.isOrganizationType(OrganizationType.newspaper) ||
                (organizationData.organizationStatus &&
                    [
                        OrganizationStatus.live.value,
                        OrganizationStatus.in_implementation.value,
                        OrganizationStatus.placement_only.value
                    ].includes(organizationData.organizationStatus));
            if (isOrganizationColumnCustomer) {
                const { response: columnCustomerUserQuerySnapshot, error: columnCustomerUserError } = yield this.getMembers();
                if (columnCustomerUserError) {
                    return wrapError(columnCustomerUserError);
                }
                recipients = columnCustomerUserQuerySnapshot;
                forceEmailRecipient = undefined;
            }
            else {
                const { response: columnExpressUserQuerySnapshot, error: columnExpressUserError } = yield safeAsync(() => __awaiter(this, void 0, void 0, function* () { return this.ctx.usersRef().where('email', '==', COLUMN_EXPRESS_EMAIL).get(); }))();
                if (columnExpressUserError) {
                    return wrapError(columnExpressUserError);
                }
                recipients = columnExpressUserQuerySnapshot.docs.map(userDoc => getModelFromSnapshot(UserModel, this.ctx, userDoc));
                forceEmailRecipient = { type: 'email', email: COLUMN_EXPRESS_EMAIL };
            }
            return wrapSuccess({ recipients, forceEmailRecipient });
        });
    }
}
