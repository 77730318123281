var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { TableLayout } from 'lib/components/TableLayout';
import AdditionalFeeUpdateForm from './additionalFeeUpdateForm';
import CrossPaperFeeRow from './CrossPaperFeeRow';
/**
 * Table layout for cross-paper fees
 */
export default function AdditionalFees({ activeOrganization }) {
    const [editedAdditionalFee, setEditedAdditionalFee] = useState();
    const [editedAdditionalFeeIndex, setEditedAdditionalFeeIndex] = useState(-1);
    const additionalFees = activeOrganization.data().additionalFees || [];
    return (_jsxs(_Fragment, { children: [editedAdditionalFee && (_jsx(AdditionalFeeUpdateForm, { editedAdditionalFeeIndex: editedAdditionalFeeIndex, editedAdditionalFee: editedAdditionalFee, activeOrganization: activeOrganization, closeForm: () => setEditedAdditionalFee(undefined) })), _jsx(TableLayout, { header: {
                    subtitle: `Configure additional fees that show up across all rates for ${activeOrganization.data().name}.`,
                    title: 'Cross-Paper Fees'
                }, creatable: {
                    onCreate: () => {
                        setEditedAdditionalFeeIndex(-1);
                        const newFee = {
                            type: 'flat',
                            amount: 100,
                            description: 'New Additional Fee'
                        };
                        setEditedAdditionalFee(newFee);
                    },
                    createButtonText: 'Add Fee'
                }, archivable: {
                    renderWarning: feeToArchive => ({
                        header: `Remove Fee: ${feeToArchive.description}`,
                        body: 'Once removed, fees can no longer be recovered in the app. However, the Column team will still be able to recover your settings.'
                    }),
                    isArchiveDisabled: false,
                    onArchive: (feeToArchive) => __awaiter(this, void 0, void 0, function* () {
                        var _a;
                        const newFees = (_a = activeOrganization
                            .data()
                            .additionalFees) === null || _a === void 0 ? void 0 : _a.filter(fee => !columnObjectsAreEqual(fee, feeToArchive));
                        yield activeOrganization.ref.update({
                            additionalFees: newFees || []
                        });
                    })
                }, columns: ['Fee Description', 'Amount/Percentage'], editable: {
                    onEdit: additionalFee => {
                        setEditedAdditionalFeeIndex(additionalFees.indexOf(additionalFee));
                        setEditedAdditionalFee(additionalFee);
                    }
                }, renderRow: additionalFee => (_jsx(CrossPaperFeeRow, { additionalFee: additionalFee })), data: additionalFees, filterable: {
                    shouldShowTableItem: (additionalFee, filter) => additionalFee.description
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                }, id: "additional-fees" })] }));
}
