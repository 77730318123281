var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { TextField } from 'lib/components/TextField';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Search } from 'lib/components/gifs';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ColumnService } from 'lib/services/directory';
import CustomerSearchOptionsList from './CustomerSearchOptionsList';
export default function CustomerSearch({ publicationSnap, onSelect, active }) {
    const [searchedCustomers, setSearchedCustomers] = useState([]);
    const [userTypedString, setUserTypedString] = useState('');
    const searchString = useDebounce(userTypedString, 250);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const getCustomersList = () => __awaiter(this, void 0, void 0, function* () {
            setLoading(true);
            const filters = [
                { organizationid: publicationSnap.id },
                { archived: Number(false) }
            ];
            const reqBody = {
                search: searchString,
                filters
            };
            const searchCustomersResponse = yield api.post('search/customers', reqBody);
            setLoading(false);
            if (!searchCustomersResponse.success) {
                logAndCaptureException(ColumnService.WEB_PLACEMENT, searchCustomersResponse.error, "Unable to load publication's customers in placement flow", {
                    publicationId: publicationSnap.id,
                    searchString
                });
                setSearchedCustomers([]);
                return;
            }
            setSearchedCustomers(searchCustomersResponse.results);
        });
        if (active) {
            void getCustomersList();
        }
    }, [searchString, active]);
    return (_jsxs(Popover, Object.assign({ id: "confirm-filer-step-customer-search", activator: _jsx(CustomerSearchInput, { value: userTypedString, onChange: setUserTypedString }), popoverType: "menu", headerText: searchedCustomers.length && !loading ? 'Search Results' : '', fullWidth: true }, { children: [loading && (_jsx("div", Object.assign({ className: "my-6" }, { children: _jsx(LoadingSpinner, {}) }))), !loading && !searchedCustomers.length && (_jsxs("div", Object.assign({ className: "my-8 flex flex-col items-center justify-center" }, { children: [_jsx("div", Object.assign({ className: "bg-column-primary-100 h-16 w-16 rounded-full flex items-center justify-center p-1" }, { children: _jsx("img", { src: Search }) })), _jsx("p", Object.assign({ className: "mt-3 text-column-gray-300 text-sm font-medium leading-6" }, { children: "No customers found!" }))] }))), !loading && searchedCustomers.length > 0 && (_jsx(CustomerSearchOptionsList, { searchableCustomers: searchedCustomers, onSelect: onSelect, setUserTypedString: setUserTypedString }))] })));
}
function CustomerSearchInput({ value, onChange }) {
    const { setOpen } = useContext(PopoverContext);
    return (_jsx(TextField, { id: "confirm-filer-step-customer-search-bar", labelText: "Search for an existing customer", value: value, onChange: onChange, onClick: () => setOpen(true), placeholder: "Search for customer by name, email, or organization name", autoComplete: "off", maxLength: 120, suffix: _jsx(MagnifyingGlassIcon, { strokeWidth: "2", className: "h-5 w-5 text-column-gray-400" }) }));
}
