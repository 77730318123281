import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import classNames from 'classnames';
import { ColumnButton } from 'lib/components/ColumnButton';
import BasicPropertiesTab from './BasicPropertiesTab';
import AdditionalFeesTab from './AdditionalFeesTab';
import RateDrawerTabs, { ADDITIONAL_FEES_TAB, RATE_PROPERTIES_TAB } from './RateDrawerTabs';
/**
 * Main content displayed in the drawer for affidavit e-edition upload events
 */
export default function RateDrawerBody({ setEditedRate, rate, closeDrawer }) {
    const [rateDrawerTab, setRateDrawerTab] = useState(RATE_PROPERTIES_TAB);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "border-b flex" }, { children: _jsx(RateDrawerTabs, { setRateDrawerTab: setRateDrawerTab, rateDrawerTab: rateDrawerTab }) })), _jsxs("div", Object.assign({ className: "pl-2 overflow-scroll flex-1 pb-20" }, { children: [rateDrawerTab === RATE_PROPERTIES_TAB && (_jsx(BasicPropertiesTab, { rateSnapshot: rate })), rateDrawerTab === ADDITIONAL_FEES_TAB && (_jsx(AdditionalFeesTab, { rate: rate }))] })), _jsxs("div", Object.assign({ className: classNames('absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between') }, { children: [_jsx("div", { className: "flex-1" }), _jsx(ColumnButton, { buttonText: "Edit", size: "lg", id: "edit-rate", tertiary: true, onClick: () => {
                            closeDrawer();
                            setEditedRate(rate);
                        }, type: "button" })] }))] }));
}
