import { getColumnInches, roundUp } from '../math';
export const priceEnterprise = options => {
    const { displayParameters, rateRecord } = options;
    const flatciRate = 9500;
    const ciRate = 950;
    const cInches = getColumnInches(displayParameters.height, displayParameters.columns, null);
    return cInches <= 10
        ? flatciRate
        : roundUp(cInches, rateRecord.roundOff) * ciRate;
};
