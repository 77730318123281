var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectIsPublisher } from 'redux/auth';
import { SORT_ASCENDING } from 'lib/types/searchable';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { safeStringify } from 'lib/utils/stringify';
import { debounce } from 'lodash';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { apiPost } from 'api/typed';
import { Product } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { ColumnButton } from 'lib/components/ColumnButton';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { DEFAULT_ORDER_FILTER, getOrderFilters } from './filters/helpers';
import { CANCELLED_ORDERS_TAB, ORDER_STATUS_TABS, COMPLETED_ORDERS_TAB, DRAFT_ORDERS_TAB, IN_PROGRESS_ORDERS_TAB } from './tabs';
import AdsTable from './AdsTable';
import { AdsTableColumns } from './types';
const CLASSIFIEDS_TABLE_PUBLISHER_COLUMNS = [
    AdsTableColumns.LISTING_NAME,
    AdsTableColumns.AD_DEADLINE,
    AdsTableColumns.CUSTOMER_NAME,
    AdsTableColumns.CATEGORY,
    AdsTableColumns.PUBLICATION_DATE,
    AdsTableColumns.STATUS
];
const getTableColumns = (isPublisher, tableTab) => {
    const defaultColumns = isPublisher ? CLASSIFIEDS_TABLE_PUBLISHER_COLUMNS : [];
    switch (tableTab.id) {
        case IN_PROGRESS_ORDERS_TAB.id:
            return defaultColumns;
        case COMPLETED_ORDERS_TAB.id:
        case DRAFT_ORDERS_TAB.id:
        case CANCELLED_ORDERS_TAB.id:
            return defaultColumns.filter(tab => tab !== AdsTableColumns.STATUS);
        default:
            logAndCaptureMessage('Unexpected tab selected in classifieds table', {
                tableTab: tableTab.id
            });
            return defaultColumns;
    }
};
function ClassifiedsTableContainer() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const isPublisher = useAppSelector(selectIsPublisher);
    const [userError, setUserError] = useState('');
    const [selectedAdTableTab, setSelectedAdTableTab] = useState(ORDER_STATUS_TABS[0]);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [rowFilter, setRowFilter] = useState(DEFAULT_ORDER_FILTER);
    const dispatch = useAppDispatch();
    const loadClassifieds = () => __awaiter(this, void 0, void 0, function* () {
        setUserError('');
        if (!activeOrganization || !selectedAdTableTab) {
            return [];
        }
        const filters = getOrderFilters({
            product: Product.Classified,
            activeOrganization,
            selectedAdTableTab,
            tableFilters: rowFilter,
            isPublisher
        });
        const result = yield apiPost('search/orders', {
            search: debouncedSearchTerm,
            filters,
            sort: [{ addeadline: SORT_ASCENDING }]
        });
        if (!result.success) {
            logAndCaptureException(ColumnService.OBITS, result.error, 'Error loading classifieds table', {
                activeOrganization: activeOrganization.id
            });
            setUserError('There was an error loading the data. Please try again.');
            return;
        }
        return result.results;
    });
    const { isLoading, value: classifiedsTableData } = useAsyncEffect({
        fetchData: loadClassifieds,
        dependencies: [
            !!activeOrganization,
            isPublisher,
            selectedAdTableTab === null || selectedAdTableTab === void 0 ? void 0 : selectedAdTableTab.id,
            debouncedSearchTerm,
            safeStringify(rowFilter)
        ]
    });
    const updateSearch = useMemo(() => debounce(value => {
        setDebouncedSearchTerm(value);
    }, 400), []);
    useEffect(() => {
        updateSearch(searchTerm);
    }, [searchTerm]);
    return (_jsxs(_Fragment, { children: [userError && (_jsx(Alert, { id: "classifieds-table-error", status: "error", title: userError })), _jsx(AdsTable, { product: Product.Classified, ads: classifiedsTableData !== null && classifiedsTableData !== void 0 ? classifiedsTableData : [], tabs: ORDER_STATUS_TABS, columns: getTableColumns(isPublisher, selectedAdTableTab), header: {
                    title: 'Classifieds',
                    subtitle: '',
                    additionalContent: (_jsx(ColumnButton, { type: "button", buttonText: "Place a classified", startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5" }), onClick: () => {
                            dispatch(push('/classifieds/place'));
                        }, primary: true }))
                }, selectedAdTableTab: selectedAdTableTab, setSelectedAdTableTab: setSelectedAdTableTab, loading: isLoading, activeOrganization: activeOrganization, isPublisher: isPublisher, setSearchTerm: setSearchTerm, rowFilter: rowFilter, setRowFilter: setRowFilter })] }));
}
export default ClassifiedsTableContainer;
