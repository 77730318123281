var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isEqual } from 'lodash';
import { firestoreTimestampOrDateToDate } from '../helpers';
import { getCustomerAndCustomerOrganization } from './customer';
import { LineItemType } from '../enums';
function isDiscountActive(discount, noticeSubmissionDate) {
    const { expirationTimestamp, startTimestamp } = discount;
    const discountHasNotStarted = startTimestamp &&
        noticeSubmissionDate < firestoreTimestampOrDateToDate(startTimestamp);
    const discountHasExpired = expirationTimestamp &&
        noticeSubmissionDate > firestoreTimestampOrDateToDate(expirationTimestamp);
    return !discountHasNotStarted && !discountHasExpired;
}
export function filterExpiredDiscounts(discountConfig, date) {
    const discountKeys = Object.keys(discountConfig);
    const filteredDiscounts = {};
    discountKeys.forEach(key => {
        const discount = discountConfig[key];
        if (discount && isDiscountActive(discount, date)) {
            filteredDiscounts[key] = discount;
        }
    });
    return filteredDiscounts;
}
export const getActiveDiscountConfigForCustomer = (customerSnap, customerOrganizationSnap, date) => {
    var _a, _b;
    const customerOrganizationConfig = (_a = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _a === void 0 ? void 0 : _a.discountConfig;
    const customerConfig = (_b = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _b === void 0 ? void 0 : _b.discountConfig;
    const config = customerOrganizationConfig !== null && customerOrganizationConfig !== void 0 ? customerOrganizationConfig : customerConfig;
    const activeDate = date !== null && date !== void 0 ? date : new Date();
    const activeConfig = config
        ? filterExpiredDiscounts(config, activeDate)
        : undefined;
    return activeConfig;
};
function getEffectiveDateForNotice(noticeData) {
    var _a;
    const timestamp = (_a = noticeData.confirmedReceiptTime) !== null && _a !== void 0 ? _a : noticeData.createTime;
    return timestamp ? firestoreTimestampOrDateToDate(timestamp) : new Date();
}
export function getActiveDiscountConfigForNotice(ctx, noticeData) {
    return __awaiter(this, void 0, void 0, function* () {
        const { customer, customerOrganization } = yield getCustomerAndCustomerOrganization(ctx, noticeData);
        return getActiveDiscountConfigForCustomer(customer, customerOrganization, getEffectiveDateForNotice(noticeData));
    });
}
function calculateDiscountAmountInCents(originalAmountInCents, discount) {
    const discountAmountInCents = discount.type === 'percent'
        ? Math.round((originalAmountInCents * discount.amount) / 100)
        : discount.amount;
    // If the discount would create a negative invoice, only discount by the
    // original amount
    // so that the invoice is $0
    return Math.min(discountAmountInCents, originalAmountInCents);
}
export function calculateDiscount(originalAmountInCents, discount) {
    const amountInCents = calculateDiscountAmountInCents(originalAmountInCents, discount);
    const lineItemDescription = discount.type === 'percent' ? `Discount (${discount.amount}%)` : 'Discount';
    return {
        amountInCents,
        lineItemDescription
    };
}
export const getUpdatedDiscountLineItemsForNotice = (discountConfig, lineItems) => {
    var _a;
    const lineItemsWithoutDiscounts = lineItems.filter((item) => item.type !== LineItemType.discount.value);
    if (lineItemsWithoutDiscounts.length === 0) {
        return [];
    }
    const lastDate = (_a = lineItemsWithoutDiscounts[lineItemsWithoutDiscounts.length - 1].date) !== null && _a !== void 0 ? _a : new Date();
    if (discountConfig === null || discountConfig === void 0 ? void 0 : discountConfig.subtotal) {
        const subtotal = lineItemsWithoutDiscounts.reduce((acc, item) => acc + item.amount, 0);
        const subtotalDiscount = calculateDiscount(subtotal, discountConfig.subtotal);
        if (subtotalDiscount) {
            return lineItemsWithoutDiscounts.concat({
                date: lastDate,
                amount: -subtotalDiscount.amountInCents,
                description: subtotalDiscount.lineItemDescription,
                type: LineItemType.discount.value
            });
        }
    }
    return lineItemsWithoutDiscounts;
};
export const wereDiscountLineItemsChanged = (oldLineItems, newLineItems) => {
    const oldLineItemsDateCorrected = oldLineItems.map(item => {
        return Object.assign(Object.assign({}, item), { date: firestoreTimestampOrDateToDate(item.date) });
    });
    const newLineItemsDateCorrected = newLineItems.map(item => {
        return Object.assign(Object.assign({}, item), { date: firestoreTimestampOrDateToDate(item.date) });
    });
    return !isEqual(oldLineItemsDateCorrected, newLineItemsDateCorrected);
};
