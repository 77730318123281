var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'lib/components/Form';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import React from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { CardGridLayout, GridInput, useEditableCard } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
const getBulkInvoicingValues = (activeOrganization, parentOrganization) => {
    let bulkPaymentEnabled_v2;
    let bulkInvoiceByPubDate;
    const childBulkPaymentEnabled = activeOrganization.data()
        .bulkPaymentEnabled_v2;
    const parentBulkPaymentEnabled = parentOrganization === null || parentOrganization === void 0 ? void 0 : parentOrganization.data().bulkPaymentEnabled_v2;
    if (typeof childBulkPaymentEnabled === 'boolean') {
        bulkPaymentEnabled_v2 = childBulkPaymentEnabled;
    }
    else {
        bulkPaymentEnabled_v2 = !!parentBulkPaymentEnabled;
    }
    const childBulkInvoicingByPubDate = activeOrganization.data()
        .bulkInvoiceByPubDate;
    const parentBulkInvoicingByPubDate = parentOrganization === null || parentOrganization === void 0 ? void 0 : parentOrganization.data().bulkInvoiceByPubDate;
    if (typeof childBulkInvoicingByPubDate === 'boolean') {
        bulkInvoiceByPubDate = childBulkInvoicingByPubDate;
    }
    else {
        bulkInvoiceByPubDate = !!parentBulkInvoicingByPubDate;
    }
    return { bulkPaymentEnabled_v2, bulkInvoiceByPubDate };
};
const getGeneralBillingSettingsFromOrg = (organization, bulkPaymentEnabled_v2, bulkInvoiceByPubDate) => {
    const { requireUpfrontPayment, sendInvoiceReminders, autoInvoice } = organization.data();
    return {
        requireUpfrontPayment: !!requireUpfrontPayment,
        sendInvoiceReminders: !!sendInvoiceReminders,
        autoInvoice: !!autoInvoice,
        bulkPaymentEnabled_v2: !!bulkPaymentEnabled_v2,
        bulkInvoiceByPubDate: !!bulkInvoiceByPubDate
    };
};
export function GeneralBillingSettings(props) {
    var _a, _b;
    const { activeOrganization, parentOrganization } = props;
    const { bulkPaymentEnabled_v2, bulkInvoiceByPubDate } = getBulkInvoicingValues(activeOrganization, parentOrganization);
    const currentSettings = getGeneralBillingSettingsFromOrg(activeOrganization, bulkPaymentEnabled_v2, bulkInvoiceByPubDate);
    const { onChange, onSubmitWrapper, disabled, formSettings, editable } = useEditableCard(currentSettings);
    // Shouldn't be possible, but needed for type narrowing.
    if (!formSettings) {
        return null;
    }
    return (_jsx(Form, Object.assign({ id: "publisher-settings-billings-general", onSubmit: () => onSubmitWrapper(() => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            // We do not want to update the active organization bulk invoicing enablement if it matches what's inherited from the parent
            let { bulkPaymentEnabled_v2, bulkInvoiceByPubDate } = formSettings;
            const parentBulkPaymentEnabled = (_c = parentOrganization === null || parentOrganization === void 0 ? void 0 : parentOrganization.data()) === null || _c === void 0 ? void 0 : _c.bulkPaymentEnabled_v2;
            const childBulkPaymentEnabled = activeOrganization.data()
                .bulkPaymentEnabled_v2;
            // When bulk invoicing enablement is unset on the child, it's inherited from the parent
            if (childBulkPaymentEnabled === undefined) {
                if (!formSettings.bulkPaymentEnabled_v2 &&
                    parentBulkPaymentEnabled === false) {
                    bulkPaymentEnabled_v2 = undefined;
                }
                if (parentBulkPaymentEnabled === true &&
                    formSettings.bulkPaymentEnabled_v2) {
                    bulkPaymentEnabled_v2 = undefined;
                }
            }
            // We do not want to update the active organization bulk invoicing inclusion criteria if it matches what's inherited from the parent
            const parentBulkInvoicingByPubDate = (_d = parentOrganization === null || parentOrganization === void 0 ? void 0 : parentOrganization.data()) === null || _d === void 0 ? void 0 : _d.bulkInvoiceByPubDate;
            const childBulkInvoicingByPubDate = activeOrganization.data()
                .bulkInvoiceByPubDate;
            // When bulk invoicing inclusion criteria is unset on the child, it's inherited from the parent
            if (childBulkInvoicingByPubDate === undefined) {
                if (!formSettings.bulkInvoiceByPubDate &&
                    parentBulkInvoicingByPubDate === false) {
                    bulkInvoiceByPubDate = undefined;
                }
                if (parentBulkInvoicingByPubDate === true &&
                    formSettings.bulkInvoiceByPubDate) {
                    bulkInvoiceByPubDate = undefined;
                }
            }
            // Save the new settings on the active organization
            yield activeOrganization.ref.update(replaceUndefinedWithDelete(getFirebaseContext(), Object.assign(Object.assign({}, formSettings), { bulkPaymentEnabled_v2,
                bulkInvoiceByPubDate })));
            return { success: true };
        })), "aria-label": "General Settings" }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'General Settings',
                description: 'Configure default billing settings.'
            }, editable: editable }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require upfront payment?", description: "If enabled, invoices will require upfront payment by default.", value: formSettings.requireUpfrontPayment, onChange: requireUpfrontPayment => onChange({ requireUpfrontPayment }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Automatically generate invoices?", description: "If enabled, invoices will be created automatically upon notice submission for all notices in this publication.", value: formSettings.autoInvoice, onChange: autoInvoice => onChange({ autoInvoice }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Send automatic invoice reminders?", description: "If enabled, customers with unpaid invoices will receive email notifications every 7 days after their invoice due date.", value: formSettings.sendInvoiceReminders, onChange: sendInvoiceReminders => onChange({ sendInvoiceReminders }), disabled: disabled }) })), _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                        disabled: disabled || formSettings.bulkPaymentEnabled_v2 === false,
                        label: 'Allow bulk invoicing?',
                        description: 'Allow customers you enable to receive bulk invoices.',
                        value: (_a = formSettings.bulkPaymentEnabled_v2) !== null && _a !== void 0 ? _a : false,
                        onChange: bulkPaymentEnabled_v2 => onChange({ bulkPaymentEnabled_v2 })
                    }, header: "" }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Enable bulk invoicing by last publication date?", description: "Notices with last publication dates in the last month will be included in the next bulk invoice.", value: (_b = formSettings.bulkInvoiceByPubDate) !== null && _b !== void 0 ? _b : false, onChange: bulkInvoiceByPubDate => onChange({ bulkInvoiceByPubDate }), disabled: disabled }) })) }))] })) })));
}
