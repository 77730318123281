export var EEditionUploadMethod;
(function (EEditionUploadMethod) {
    EEditionUploadMethod["MANUAL"] = "manual";
    EEditionUploadMethod["FTP"] = "ftp";
    EEditionUploadMethod["EMAIL"] = "email";
    EEditionUploadMethod["SCRAPER"] = "scraper";
})(EEditionUploadMethod || (EEditionUploadMethod = {}));
export var EEditionStatus;
(function (EEditionStatus) {
    /**
     * The e-edition has been uploaded, but we have not yet attempted to run OCR on it
     */
    EEditionStatus["OCR_PENDING"] = "ocr_pending";
    /**
     * OCR failed on this e-edition
     */
    EEditionStatus["OCR_FAILURE"] = "ocr_failure";
    /**
     * OCR succeeded on this e-edition
     */
    EEditionStatus["OCR_SUCCESS"] = "ocr_success";
})(EEditionStatus || (EEditionStatus = {}));
