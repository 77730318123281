var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OccupationType } from '../enums';
import { getDisplayName, removeUndefinedFields } from '../helpers';
import { getOrThrow } from '../utils/refs';
import { createNotificationsObject } from '../utils/users';
const createAnonymousUser = (context, customerData) => __awaiter(void 0, void 0, void 0, function* () {
    const newUserRef = yield context.usersRef().add(removeUndefinedFields({
        anonymous: true,
        notifications: createNotificationsObject(OccupationType.individual.value),
        name: getDisplayName(customerData.firstName, customerData.lastName),
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        email: customerData.email.toLowerCase() || '',
        phone: customerData.phone || '',
        lastSignInTime: null,
        address: customerData.addressLine1 || '',
        addressLine2: customerData.addressLine2 || '',
        city: customerData.city || '',
        zipCode: customerData.zip || '',
        state: customerData.state || undefined,
        // users begin as individuals who haven't completed registration yet
        // because they have a pending org invite:
        // 1. They will not be immediately prompted to select an occupation and instead will see the invite
        // 2. If they decline the invite, they will be prompted to select an occupation
        occupation: OccupationType.individual.value,
        postRegistrationComplete: false
    }));
    return getOrThrow(newUserRef);
});
export default createAnonymousUser;
