import { jsx as _jsx } from "react/jsx-runtime";
import { PreviewRow } from 'components/FullScreenModal/PreviewRow';
import { Badge } from 'lib/components/Badge';
import React from 'react';
export function DeadlinePreviewRow({ firstRow, showTime, label, date }) {
    return (_jsx(PreviewRow, Object.assign({ label: label, firstRow: firstRow }, { children: _jsx(Badge, { children: date
                .format(`dddd, MM/DD/YY${showTime ? ', h:mm A' : ''}`)
                .replace('AM', 'a.m.')
                .replace('PM', 'p.m.') }) })));
}
