import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { selectUserAuth, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import RegisterPublisherOrganization from './RegisterPublisherOrganization';
export default function RegisterPublisher() {
    const userAuth = useAppSelector(selectUserAuth);
    const user = useAppSelector(selectUser);
    if (!exists(user) || !userAuth) {
        return _jsx(LoadingState, {});
    }
    return (_jsx(RegisterPublisherOrganization, { userAuth: userAuth, user: user.data() }));
}
