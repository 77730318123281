var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { INDIVIDUAL_REGISTRATION_ENTRYPOINT } from 'redux/router';
import { getFirebaseContext } from 'utils/firebase';
import { OccupationType, OrganizationType } from 'lib/enums';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import { selectUser } from 'redux/auth';
import ToastActions from 'redux/toast';
import { exists } from 'lib/types';
import { notifyOrganizationAdminUsers, sendOrganizationInvites } from 'routes/register/individual/RegisterIndividualForm';
import classNames from 'classnames';
import JoinExistingOrganization from './JoinExistingOrganization';
import AdvertiserRegistrationFooter from '../../AdvertiserRegistrationFooter';
import JoinOrCreateNewOrganizationHeader from './JoinOrCreateOrganizationHeader';
import OrganizationRegistration from '../OrganizationRegistration';
export default function JoinOrCreateNewOrganization({ occupationType, onBackClick }) {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const [creatingNewOrganization, setCreatingNewOrganization] = useState(false);
    const [organizationsToJoin, setOrganizationsToJoin] = useState([]);
    const onRequestToJoinOrganizations = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(user)) {
            return;
        }
        yield sendOrganizationInvites(organizationsToJoin, user, getFirebaseContext());
        yield notifyOrganizationAdminUsers(user);
        dispatch(ToastActions.toastSuccess({
            headerText: 'Request sent',
            bodyText: `Requested to join ${organizationsToJoin
                .map(org => org.label)
                .join(', ')}`
        }));
        dispatch(push(INDIVIDUAL_REGISTRATION_ENTRYPOINT));
    });
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-center w-128 mx-auto" }, { children: [_jsx(JoinOrCreateNewOrganizationHeader, {}), _jsx(JoinExistingOrganization, { onStartJoiningNewOrganization: () => setCreatingNewOrganization(false), onUpdateOrganizationsToJoin: setOrganizationsToJoin, creatingNewOrganization: creatingNewOrganization, organizationsToJoin: organizationsToJoin, occupationType: occupationType }), _jsx("p", Object.assign({ className: "text-column-gray-500 font-medium text-md leading-6 my-3 text-center" }, { children: "or" })), _jsxs("div", Object.assign({ className: classNames({
                    hidden: !creatingNewOrganization
                }) }, { children: [occupationType === OccupationType.lawyer.value && (_jsx(OrganizationRegistration, { organizationType: OrganizationType.law_firm.value })), occupationType === OccupationType.government_official.value && (_jsx(OrganizationRegistration, { organizationType: OrganizationType.government.value })), occupationType === OccupationType.other_organization.value && (_jsx(OrganizationRegistration, { organizationType: OrganizationType.other_organization.value })), _jsx("div", { className: "mb-24" })] })), !creatingNewOrganization && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(ColumnButton, { id: "create-new-org", buttonText: "Create a new organization", secondary: true, size: "xl", fullWidth: true, disabled: organizationsToJoin.length > 0, onClick: () => setCreatingNewOrganization(true), type: "button" }) })), _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(AdvertiserRegistrationFooter, { id: "next-to-join", backButtonText: "Go back", nextButtonText: organizationsToJoin.length ? 'Submit Request' : 'Continue', onBackButtonClick: onBackClick, onNextButtonClick: () => onRequestToJoinOrganizations(), disableNextButton: !organizationsToJoin.length }) }))] }))] })));
}
