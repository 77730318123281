import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BuildingIcon } from 'icons';
import { getOrganizationIconColors } from '../modals/JoinOrganizationModals/helpers';
function RequestActionCard({ request, index, className }) {
    const iconStyles = getOrganizationIconColors(index);
    return (_jsx("div", Object.assign({ className: `${className} border-gray-300 p-5 bg-white space-y-6` }, { children: _jsxs("div", Object.assign({ className: "relative flex items-center space-x-4" }, { children: [_jsx("div", Object.assign({ className: "flex-shrink-0" }, { children: _jsx("div", Object.assign({ className: `h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}` }, { children: _jsx(BuildingIcon, { style: {
                                margin: 'auto',
                                transform: 'translateY(40%)'
                            }, stroke: iconStyles.stroke }) })) })), _jsx("div", Object.assign({ className: "min-w-0 flex-1" }, { children: _jsxs("p", Object.assign({ className: "text-left text-sm font-medium text-blue-800" }, { children: ["You have a pending request to join", ' ', _jsx("span", Object.assign({ className: "font-semibold" }, { children: request.organizationName })), ". You\u2019ll be added to", ' ', _jsx("span", Object.assign({ className: "font-semibold" }, { children: request.organizationName })), ' ', "when an organization admin approves your request."] })) }))] })) })));
}
export default RequestActionCard;
