import { datadogLogs } from '@datadog/browser-logs';
import { logInternal } from 'lib/types/logger';
const ConsoleLogger = {
    setUser: () => { },
    getSessionId: () => undefined,
    info: console.log,
    warn: console.warn,
    error: console.error
};
const DataDogLogger = {
    setUser: user => {
        if (user) {
            datadogLogs.setUser(user);
        }
        else {
            datadogLogs.clearUser();
        }
    },
    getSessionId: () => {
        var _a;
        return (_a = datadogLogs.getInternalContext()) === null || _a === void 0 ? void 0 : _a.session_id;
    },
    info: (message, data, ...args) => {
        console.log(message, data, ...args);
        datadogLogs.logger.info(message, data);
    },
    warn: (message, data, ...args) => {
        console.warn(message, data, ...args);
        datadogLogs.logger.warn(message, data);
    },
    error: (message, data, ...args) => {
        console.error(message, data, ...args);
        datadogLogs.logger.error(message, data);
    }
};
let _LOGGER = ConsoleLogger;
export const initializeLogger = (options) => {
    const { env, useDataDog } = options;
    if (useDataDog) {
        datadogLogs.init({
            env: env.toLowerCase(),
            service: 'enotice-app',
            clientToken: 'puba1ba9c9a26fd6e8f86db79da4949cfe0',
            sessionSampleRate: 100,
            forwardErrorsToLogs: false,
            forwardConsoleLogs: [],
            silentMultipleInit: true
        });
        _LOGGER = DataDogLogger;
    }
};
export const getLogger = () => {
    return _LOGGER;
};
/**
 * Log a message at INFO level with both structured and unstructured data.
 */
export const logInfo = (message, data, ...args) => {
    logInternal(_LOGGER.info, message, data, ...args);
};
/**
 * Log a message at WARN level with both structured and unstructured data.
 */
export const logWarn = (message, data, ...args) => {
    logInternal(_LOGGER.warn, message, data, ...args);
};
/**
 * Log a message at ERROR level with both structured and unstructured data.
 */
export const logError = (message, data, ...args) => {
    logInternal(_LOGGER.error, message, data, ...args);
};
