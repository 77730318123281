var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { TransactionType } from 'lib/enums';
import { toLocaleString } from 'lib/helpers';
import { Help, WarningOutlined } from '@material-ui/icons';
import { getFirebaseContext } from 'utils/firebase';
import { Tooltip } from 'lib/components/Tooltip';
import { ArrowDownTrayIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
const TableCell = ({ children }) => (_jsx("td", Object.assign({ className: "py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, { children: children })));
function PayoutParentRow({ rowData, expand, payoutSource, handleExpandClicked, handleDownloadCsvClicked }) {
    return (_jsxs("tr", { children: [_jsx(TableCell, { children: _jsx("div", Object.assign({ className: "flex justify-center" }, { children: _jsx(ChevronDownIcon, { className: `w-4 h-4 cursor-pointer ${!expand ? 'transform -rotate-90' : ''}`, onClick: () => handleExpandClicked(rowData.id) }) })) }), payoutSource === 'dwolla' && (_jsx(TableCell, { children: _jsx("p", Object.assign({ className: `text-right ${rowData.type === TransactionType.processing_fee_deduction.label ||
                        rowData.type === TransactionType.refund.label ||
                        (rowData.type === TransactionType.payment.label &&
                            rowData.status === 'cancelled')
                        ? 'text-red-600'
                        : 'text-black'}` }, { children: (rowData.type === TransactionType.processing_fee_deduction.label ||
                        rowData.type === TransactionType.refund.label ||
                        (rowData.type === TransactionType.payment.label &&
                            rowData.status === 'cancelled')) &&
                        '-' })) })), _jsx(TableCell, { children: _jsxs("p", Object.assign({ className: `${rowData.type === TransactionType.processing_fee_deduction.label ||
                        rowData.type === TransactionType.refund.label ||
                        (rowData.type === TransactionType.payment.label &&
                            rowData.status === 'cancelled')
                        ? 'text-red-600'
                        : 'text-black'}` }, { children: [rowData.status === 'failed' && (_jsx(Tooltip, Object.assign({ classes: "inline-block align-middle ml-2 font-normal", helpText: "Payout failed. Please update account." }, { children: _jsx(WarningOutlined, { className: "text-red-400 mr-2" }) }))), rowData.amount] })) }), _jsx(TableCell, { children: _jsx("div", {}) }), _jsx(TableCell, { children: _jsx("p", { children: payoutSource === 'dwolla'
                        ? `${toLocaleString(rowData.created)}`
                        : `${rowData.created}` }) }), _jsx(TableCell, { children: _jsx("p", { children: rowData.arrival_date.length > 0 && payoutSource === 'dwolla'
                        ? `${toLocaleString(rowData.arrival_date)}`
                        : `${rowData.arrival_date}` }) }), _jsx(TableCell, { children: _jsx("div", Object.assign({ title: "Download CSV" }, { children: _jsx(ArrowDownTrayIcon, { id: "payment-download", className: "h-4 w-4 ml-4 cursor-pointer text-blue-800", onClick: () => handleDownloadCsvClicked(rowData.id) }) })) })] }));
}
function PayoutChildRow({ rowData, payoutSource }) {
    const viewNotice = () => __awaiter(this, void 0, void 0, function* () {
        if (!rowData.noticeId) {
            return;
        }
        const noticeSnap = (yield getFirebaseContext()
            .userNoticesRef()
            .doc(rowData.noticeId)
            .get());
        const { newspaper } = noticeSnap.data();
        window.open(`/notice/${rowData.noticeId}?activeOrg=${newspaper.id}`);
    });
    return (_jsxs("tr", { children: [_jsx(TableCell, { children: _jsx("div", {}) }), payoutSource === 'dwolla' && (_jsx(TableCell, { children: _jsx("p", Object.assign({ className: `text-right ${rowData.type === TransactionType.processing_fee_deduction.label ||
                        rowData.type === TransactionType.refund.label ||
                        (rowData.type === TransactionType.payment.label &&
                            rowData.status === 'cancelled')
                        ? 'text-red-600'
                        : 'text-black'}` }, { children: (rowData.type === TransactionType.processing_fee_deduction.label ||
                        rowData.type === TransactionType.refund.label ||
                        (rowData.type === TransactionType.payment.label &&
                            rowData.status === 'cancelled')) &&
                        '-' })) })), _jsx(TableCell, { children: _jsx("p", Object.assign({ className: `${rowData.type === TransactionType.processing_fee_deduction.label ||
                        rowData.type === TransactionType.refund.label ||
                        rowData.type === TransactionType.paymentReversed.label ||
                        (rowData.type === TransactionType.payment.label &&
                            rowData.status === 'cancelled')
                        ? 'text-red-600'
                        : 'text-black'}` }, { children: rowData.amount })) }), _jsx(TableCell, { children: _jsxs("div", { children: [_jsx("span", Object.assign({ className: "inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-indigo-200 text-indigo-800" }, { children: rowData.type === TransactionType.processing_fee_deduction.label
                                ? 'Processing Fee'
                                : rowData.type === TransactionType.payment.label &&
                                    rowData.status === 'cancelled'
                                    ? 'Refund'
                                    : rowData.type === TransactionType.payment.label
                                        ? 'Notice Payment'
                                        : rowData.type === TransactionType.manualPayment.label
                                            ? 'Manual Payment'
                                            : rowData.type === TransactionType.dividend.label
                                                ? 'Dividend'
                                                : rowData.type === TransactionType.paymentReversed.label
                                                    ? 'Payout Reversed'
                                                    : 'Refund' })), rowData.type === TransactionType.processing_fee_deduction.label && (_jsx(Tooltip, Object.assign({ classes: "inline-block align-middle ml-2 font-normal", helpText: "This fee is due to Column for a notice paid outside the platform." }, { children: _jsx(Help, { fontSize: "small", className: "p-0.5 pt-0" }) }))), rowData.type === TransactionType.manualPayment.label &&
                            rowData.reason && (_jsx(Tooltip, Object.assign({ classes: "inline-block align-middle ml-2 font-normal", helpText: rowData.reason, position: 'right' }, { children: _jsx(Help, { fontSize: "small", className: "p-0.5 pt-0" }) }))), rowData.transactionTypeValue === TransactionType.feeRefund.key && (_jsx(Tooltip, Object.assign({ classes: "inline-block align-middle ml-2 font-normal", helpText: "This is a refund for a previously deducted processing fee, due to cancelling an invoice paid outside Column." }, { children: _jsx(Help, { fontSize: "small", className: "p-0.5 pt-0" }) })))] }) }), _jsx(TableCell, { children: _jsx("div", {}) }), _jsx(TableCell, { children: _jsx("div", {}) }), _jsx(TableCell, { children: rowData.type === TransactionType.dividend.label ? (_jsx("button", Object.assign({ type: "button", className: "px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs flex text-blue-600", onClick: () => window.open(rowData.receipt) }, { children: "COLUMN PAYOUT" }))) : ![
                    TransactionType.manualPayment.label,
                    TransactionType.paymentReversed.label
                ].includes(rowData.type) ? (_jsx("button", Object.assign({ type: "button", className: `${!rowData.noticeId
                        ? 'cursor-not-allowed pointer-events-none opacity-50'
                        : 'hover:bg-gray-50'} px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs text-blue-600`, onClick: () => viewNotice() }, { children: "View Notice" }))) : (_jsx(_Fragment, {})) })] }));
}
function PayoutGroupRows({ group, payoutSource, handleDownloadCsvClicked }) {
    const [expanded, setExpanded] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(PayoutParentRow, { rowData: group.parent, expand: expanded, payoutSource: payoutSource, handleExpandClicked: () => setExpanded(!expanded), handleDownloadCsvClicked: handleDownloadCsvClicked }), expanded &&
                group.children.map(child => (_jsx(PayoutChildRow, { rowData: child, payoutSource: payoutSource }, child.id)))] }));
}
export default PayoutGroupRows;
