var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { selectActiveOrganization } from 'redux/auth';
import { getNewspaperRefForCustomer } from 'lib/notice/customer';
import { ColumnService } from 'lib/services/directory';
import { extractCustomerDataFromSearchableCustomerRecord } from './utils';
export function useGetCustomerTableData(searchQuery) {
    const newspaper = useAppSelector(selectActiveOrganization);
    const [customers, setCustomers] = useState();
    const [loading, setLoading] = useState(false);
    const [refreshCustomersList, setRefreshCustomersList] = useState(false);
    const getCustomersList = () => __awaiter(this, void 0, void 0, function* () {
        if (!newspaper)
            return;
        setLoading(true);
        try {
            const parentNewspaperRef = getNewspaperRefForCustomer(newspaper);
            const filters = [
                { organizationid: parentNewspaperRef === null || parentNewspaperRef === void 0 ? void 0 : parentNewspaperRef.id },
                { archived: Number(false) }
            ];
            const reqBody = {
                search: searchQuery,
                filters
            };
            const searchCustomersResponse = yield api.post('search/customers', reqBody);
            if (!searchCustomersResponse.success) {
                logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, searchCustomersResponse.error, "Unable to load publication's customers in settings tab", {
                    publicationId: parentNewspaperRef === null || parentNewspaperRef === void 0 ? void 0 : parentNewspaperRef.id
                });
                setCustomers([]);
                setLoading(false);
                return;
            }
            const shallowCustomerRows = searchCustomersResponse.results;
            const customersWithData = yield Promise.all(shallowCustomerRows.map(extractCustomerDataFromSearchableCustomerRecord));
            const validCustomersWithData = customersWithData.filter(Boolean);
            setCustomers(validCustomersWithData);
            setLoading(false);
            setRefreshCustomersList(false);
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, "Unable to load publication's customers in settings tab", {
                publicationId: newspaper.id
            });
            setCustomers([]);
            setLoading(false);
            setRefreshCustomersList(false);
        }
    });
    useEffect(() => {
        void getCustomersList();
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id, searchQuery]);
    // Refresh the customer table after 3 seconds to allow changes to be reflected in Elastic
    useEffect(() => {
        if (refreshCustomersList) {
            const id = setTimeout(() => getCustomersList(), 3000);
            return () => clearTimeout(id);
        }
    }, [refreshCustomersList]);
    return { customers, loading, setRefreshCustomersList };
}
