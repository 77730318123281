import { jsx as _jsx } from "react/jsx-runtime";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { STRIPE_VARS } from '../../../constants';
import { BulkPaymentCardInputForm } from './CardInputForm';
import { BulkPaymentReceiptView } from './ReceiptView';
export function ProcessBulkPaymentModal({ onClose, invoices }) {
    const stripePromise = loadStripe(STRIPE_VARS.key);
    const [ledgerItemId, setLedgerItemId] = useState();
    if (!ledgerItemId) {
        return (_jsx(Elements, Object.assign({ stripe: stripePromise }, { children: _jsx(BulkPaymentCardInputForm, { onClose: onClose, onContinue: setLedgerItemId, invoices: invoices }) })));
    }
    return (_jsx(BulkPaymentReceiptView, { ledgerItemId: ledgerItemId, onClose: onClose }));
}
