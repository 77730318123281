var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnButton } from 'lib/components/ColumnButton';
import { userIsSuper } from 'utils/permissions';
import { Form } from 'lib/components/Form';
import SettingsPage from 'routes/settings/SettingsPage';
import { isColumnUser } from 'lib/helpers';
import { useAppDispatch } from 'redux/hooks';
import ImplementationInfo from './ImplementationInfo';
import SettingsHeader from '../../SettingsHeader';
import PublisherInfo from './PublisherInfo';
import PublisherInfoDrawer from './PublisherInfoDrawer';
/**
 * Control panel for editing basic information about the publisher
 */
export default function PublisherSettingsInfo({ activeOrganization, user }) {
    const dispatch = useAppDispatch();
    const [showNotes, setShowNotes] = useState(false);
    const [orgData, setOrgData] = useState(activeOrganization.data());
    const [saving, setSaving] = useState(false);
    const edited = !columnObjectsAreEqual(orgData, activeOrganization.data());
    const publisherNotesQuery = useFirestoreQueryListener(getFirebaseContext()
        .notesRef()
        .where('publisher', '==', activeOrganization.ref)
        .where('noteCreatorOrganization', '==', activeOrganization.ref), [activeOrganization.id]);
    const numberOfActiveNotes = (publisherNotesQuery === null || publisherNotesQuery === void 0 ? void 0 : publisherNotesQuery.docs.filter(note => note.data().status !== 'archived').length) || 0;
    return (_jsxs(_Fragment, { children: [_jsx(SettingsPage, { children: _jsxs(Form, Object.assign({ id: "publisher-settings-info", onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                        setSaving(true);
                        yield activeOrganization.ref.update(orgData);
                        setSaving(false);
                        dispatch(ToastActions.toastSuccess({
                            headerText: 'Success',
                            bodyText: 'Publisher settings updated successfully.'
                        }));
                    }) }, { children: [_jsxs(SettingsHeader, Object.assign({ header: "Publisher Info", description: "Tell us a bit about your publication." }, { children: [isColumnUser(user) && (_jsx("div", Object.assign({ className: "mr-3" }, { children: _jsx(ColumnButton, { startIcon: _jsx(DocumentTextIcon, { className: "w-5 h-5" }), onClick: () => setShowNotes(true), buttonText: numberOfActiveNotes
                                            ? `Show Notes (${numberOfActiveNotes})`
                                            : 'Show Notes', id: "show-notes", type: "button" }) }))), _jsx(ColumnButton, { primary: true, disabled: !edited || saving, buttonText: "Save Changes", type: "submit", id: "submit" })] })), _jsx(PublisherInfo, { value: orgData, onChange: setOrgData }), userIsSuper(user) && (_jsx(ImplementationInfo, { value: orgData, onChange: setOrgData }))] })) }), showNotes && publisherNotesQuery && (_jsx(PublisherInfoDrawer, { activeOrganization: activeOrganization, publisherNotes: publisherNotesQuery === null || publisherNotesQuery === void 0 ? void 0 : publisherNotesQuery.docs, onClose: () => setShowNotes(false) }))] }));
}
