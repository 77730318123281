import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { fuzzyStringContains } from 'lib/utils/strings';
import { TableLayout } from 'lib/components/TableLayout';
import LoadingState from 'components/LoadingState';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { useFirestoreListener } from 'routes/placeScroll/hooks/useFirestoreListener';
import { CacheEntryTableRow } from './CacheEntryTableRow';
import { CacheEntryUpdateForm } from './CacheEntryUpdateForm';
/**
 * A table of cache entries for a single cache.
 */
export function CacheEntriesTable({ cache }) {
    const [creatingEntry, setCreatingEntry] = useState(false);
    const [editingEntry, setEditingEntry] = useState();
    const entriesSnap = useFirestoreQueryListener(cache.getAllEntriesQuery(), [
        cache.cacheId
    ]);
    const entries = entriesSnap === null || entriesSnap === void 0 ? void 0 : entriesSnap.docs;
    const [cacheSnap] = useFirestoreListener(cache.cacheRef);
    if (!entries) {
        return _jsx(LoadingState, {});
    }
    const keyType = cacheSnap ? cacheSnap.data().keyType : 'string';
    const valueType = cacheSnap ? cacheSnap.data().valueType : 'string';
    // TODO: Add cache descriptions
    return (_jsxs(_Fragment, { children: [_jsx(TableLayout, { id: cache.cacheId, columns: ['Key', 'Value'], data: entries, header: {
                    subtitle: 'Manage cache entries.',
                    title: cacheSnap
                        ? `${cacheSnap.data().name} (${cache.cacheId})`
                        : cache.cacheId
                }, archivable: {
                    isArchiveDisabled: false,
                    onArchive: entry => {
                        void cache.deleteValue(entry.data().key.value);
                    },
                    renderWarning: () => ({
                        header: 'Delete entry?',
                        body: 'Are you sure you want to delete this entry? Integrations which depend on this data may stop working.',
                        buttonText: 'Delete'
                    })
                }, filterable: {
                    shouldShowTableItem: (entry, filter) => {
                        const { key, value } = entry.data();
                        return (fuzzyStringContains(safeStringify(key.value) || '', filter) ||
                            fuzzyStringContains(safeStringify(value.value) || '', filter));
                    }
                }, creatable: {
                    createButtonText: 'Add Entry',
                    onCreate: () => {
                        setCreatingEntry(true);
                    }
                }, editable: {
                    onEdit: entry => {
                        setEditingEntry(entry);
                    }
                }, renderRow: entry => {
                    return _jsx(CacheEntryTableRow, { entry: entry });
                } }), editingEntry && (_jsx(CacheEntryUpdateForm, { cache: cache, entry: editingEntry, keyType: keyType, valueType: valueType, closeForm: () => setEditingEntry(undefined) })), creatingEntry && (_jsx(CacheEntryUpdateForm, { cache: cache, entry: undefined, keyType: keyType, valueType: valueType, closeForm: () => setCreatingEntry(false) }))] }));
}
