var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TableLayout } from 'lib/components/TableLayout';
import React, { useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { fuzzyStringContains } from 'lib/utils/strings';
import { usePublisherModularSizes } from 'routes/placeScroll/hooks/usePublisherModularSizes';
import CurrencyBadge from 'components/CurrencyBadge';
import { ModularSizeForm } from './ModularSizeForm';
export function ModularSizes() {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const { modularSizes } = usePublisherModularSizes((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref) !== null && _a !== void 0 ? _a : null);
    const [changedModularSizeData, setChangedModularSizeData] = useState(undefined);
    const [changedModularSizeRef, setChangedModularSizeRef] = useState(undefined);
    if (!activeOrganization) {
        return null;
    }
    if (changedModularSizeData) {
        return (_jsx(ModularSizeForm, { modularSizeRef: changedModularSizeRef, onClose: () => {
                setChangedModularSizeData(undefined);
                setChangedModularSizeRef(undefined);
            }, modularSize: changedModularSizeData }));
    }
    return (_jsx(TableLayout, { filterable: {
            shouldShowTableItem: (item, search) => fuzzyStringContains(item.data().name, search)
        }, creatable: {
            createButtonText: 'Add new fee',
            onCreate: () => {
                setChangedModularSizeData({
                    name: 'New Modular Size',
                    height: 0,
                    width: 0,
                    archived: false,
                    owner: activeOrganization.ref,
                    publishers: [activeOrganization.ref]
                });
            }
        }, editable: {
            onEdit: modularSize => {
                setChangedModularSizeRef(modularSize.ref);
                setChangedModularSizeData(modularSize.data());
            }
        }, archivable: {
            isArchiveDisabled: () => false,
            renderWarning: modularSizeToArchive => ({
                header: `Remove Modular Size: ${modularSizeToArchive.data().name}`,
                body: `Once removed, modular sizes can no longer be recovered in the app. However, the Column team will still be able to recover your settings.`
            }),
            onArchive: (modularSizeToArchive) => __awaiter(this, void 0, void 0, function* () { return modularSizeToArchive.ref.update({ archived: true }); })
        }, renderRow: entry => {
            const data = entry.data();
            return (_jsxs(_Fragment, { children: [_jsx("td", { children: data.name }), _jsx("td", { children: data.designFeeInCents ? (_jsx(CurrencyBadge, { value: data.designFeeInCents })) : ('') })] }));
        }, header: {
            title: 'Plans',
            subtitle: 'Manage plans and fees from Column.',
            additionalContent: undefined
        }, columns: ['Size', 'Fee'], data: modularSizes, id: "modular-sizes" }));
}
