var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import MadlibEditor from 'routes/madlib/components/MadlibEditor';
import QuestionForm from 'routes/madlib/components/QuestionForm';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { logAndCaptureException } from 'utils';
import LoadingState from 'components/LoadingState';
import { NoticeContentFields } from 'routes/placeScroll/NoticeContentStep/NoticeContentFields';
import { ColumnService } from 'lib/services/directory';
import { fetchMadlibsConfig } from '../helpers/config';
function NoticeEditorMadlib({ newspaper, setIsMadlibComplete, templateStyles, madlibConfigPath, madlibConfig, madlibData, onEditorUpdate, onTemplateDataChange, noticeHandlebarData }) {
    const [questions, setQuestions] = useState([]);
    const [rawTemplate, setRawTemplate] = useState('');
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    const [renderedHtml, setRenderedHtml] = useState('');
    const [isAllNonEmptyAnswers, setIsAllNonEmptyAnswers] = useState(false);
    const [error, setError] = useState('');
    const [validationResults, setValidationResults] = useState({});
    const isLoading = !error && (!rawTemplate || !(questions === null || questions === void 0 ? void 0 : questions.length));
    useEffect(() => {
        if (madlibConfig) {
            setRawTemplate(madlibConfig.template);
            setQuestions(madlibConfig.questions);
            return;
        }
        const fetchTemplate = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield fetchMadlibsConfig(madlibConfigPath);
                setRawTemplate(res.template);
                setQuestions(res.questions);
            }
            catch (e) {
                setError('Failed to load notice type template.');
                logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Failed to fetch madlibs config', {
                    madlibConfigPath
                });
            }
        });
        void fetchTemplate();
    }, [madlibConfigPath, madlibConfig]);
    useEffect(() => {
        onEditorUpdate(renderedHtml);
        if (madlibData.templateData &&
            Object.keys(madlibData.templateData).length) {
            const noInvalidData = Object.entries(madlibData.templateData).every(([key, input]) => !!input && validationResults[key] !== false);
            setIsMadlibComplete(noInvalidData && !!isAllNonEmptyAnswers);
        }
    }, [madlibData, validationResults]);
    if (isLoading) {
        return (_jsx(LoadingState, { timeout: 600, context: { location: 'NoticeEditorMadlib', tags: { madlibConfigPath } } }));
    }
    if (error) {
        return (_jsx(Alert, { id: "madlibs-error", status: "error", title: error, icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(QuestionForm, { questions: questions, questionTemplateData: madlibData.questionTemplateData, onChange: form => {
                    // Validations for questions that are not tied to undefined as empty value
                    const allNonEmptyAnswers = form.complete &&
                        Object.entries(form.values).every(key => key[1] !== null);
                    setIsAllNonEmptyAnswers(allNonEmptyAnswers);
                    onTemplateDataChange(Object.assign(Object.assign({}, madlibData), { questionTemplateData: form.values }));
                    setAllQuestionsAnswered(form.complete);
                } }), allQuestionsAnswered && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "my-6" }, { children: _jsx(Alert, Object.assign({ id: "madlib-editor-info", icon: _jsx(ExclamationCircleIcon, { className: "h-5 w-5" }) }, { children: "Check the preview to see how your notice will look in print" })) })), _jsx(NoticeContentFields, { newspaper: newspaper }), _jsx("div", Object.assign({ className: "bg-white mb-4 border px-4 py-2 shadow-sm rounded overflow-y-scroll h-128" }, { children: _jsx(MadlibEditor, { templateStyles: templateStyles, rawTemplate: rawTemplate, setRenderedHtml: setRenderedHtml, onTemplateDataChange: onTemplateDataChange, madlibData: madlibData, setValidationResults: setValidationResults, handlebarData: noticeHandlebarData }) }))] }))] }));
}
export default NoticeEditorMadlib;
