var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TableLayout } from 'lib/components/TableLayout';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { isColumnUser, unCdnify } from 'lib/helpers';
import { selectUser } from 'redux/auth';
import SettingsPage from 'routes/settings/SettingsPage';
import { DEFAULT_AFFIDAVIT_URL } from 'lib/affidavits';
import { AffidavitTemplateModel } from 'lib/model/objects/affidavitTemplateModel';
import { getModelFromSnapshot } from 'lib/model';
import GlobalControls from './globalControls';
import AffidavitTableRow from './AffidavitTableRow';
import { AffidavitTemplateEditForm } from './affidavitTemplateEditForm';
import { getAffidavitTemplateDefaultStatus } from './affidavitTemplateEditForm/statusHelpers';
import { LegacyAffidavitTemplateDrawer } from './affidavitTemplateDrawer/v2020-01-01';
import { AffidavitTemplateDrawer } from './affidavitTemplateDrawer/v2024-03-25';
export default function AffidavitTable({ activeOrganization }) {
    const activeUser = useAppSelector(selectUser);
    const [drawerTemplate, setDrawerTemplate] = useState();
    const [editedAffidavitTemplateRef, setEditedAffidavitTemplateRef] = useState();
    const [showGlobalControls, setShowGlobalControls] = useState(false);
    const [editedAffidavitTemplate, setEditedAffidavitTemplate] = useState();
    const affidavitTemplates = useFirestoreQueryListener(getFirebaseContext()
        .affidavitTemplatesRef()
        .where('publisher', '==', activeOrganization.ref), [activeOrganization.id]);
    if (!affidavitTemplates || !exists(activeUser))
        return _jsx(LoadingState, {});
    const nonArchivedTemplates = affidavitTemplates.docs
        .map(snap => getModelFromSnapshot(AffidavitTemplateModel, getFirebaseContext(), snap))
        .filter(affidavitTemplate => !affidavitTemplate.data().archived);
    return (_jsxs(SettingsPage, { children: [_jsx(TableLayout, { header: {
                    title: 'Affidavit Templates',
                    subtitle: 'Manage the affidavit templates for your publication.',
                    additionalContent: (_jsx("div", Object.assign({ className: "mr-4" }, { children: _jsx(ColumnButton, { buttonText: "Global Settings", startIcon: _jsx(Cog8ToothIcon, { className: "w-6 h-6" }), onClick: () => setShowGlobalControls(true), type: "button" }) })))
                }, columns: ['Name'], renderRow: affidavitTemplate => (_jsx(AffidavitTableRow, { activeOrganization: activeOrganization, affidavitTemplate: affidavitTemplate })), data: nonArchivedTemplates, filterable: {
                    shouldShowTableItem: (affidavitTemplate, filterBy) => {
                        const name = affidavitTemplate.data().name.toLowerCase();
                        const filter = filterBy.toLowerCase();
                        return name.includes(filter);
                    }
                }, clickable: {
                    onClick: setDrawerTemplate
                }, editable: {
                    isEditDisabled: affidavitTemplate => {
                        if (!affidavitTemplate.data().isColumnManaged) {
                            return false;
                        }
                        return !(activeUser && isColumnUser(activeUser));
                    },
                    onEdit: affidavitTemplate => {
                        setEditedAffidavitTemplateRef(affidavitTemplate.ref);
                        setEditedAffidavitTemplate(affidavitTemplate.data());
                    }
                }, archivable: {
                    isArchiveDisabled: affidavitTemplate => ['publisher_default', 'column_default'].includes(getAffidavitTemplateDefaultStatus(activeOrganization, affidavitTemplate.ref)),
                    renderWarning: () => ({
                        header: 'Archive Affidavit Template?',
                        body: 'If you select archive you will no longer be able to access this template.'
                    }),
                    onArchive: (affidavitTemplate) => __awaiter(this, void 0, void 0, function* () {
                        yield affidavitTemplate.ref.update({
                            archived: true
                        });
                    })
                }, creatable: {
                    onCreate: () => {
                        setEditedAffidavitTemplateRef(undefined);
                        setEditedAffidavitTemplate({
                            version: 'v2020-01-01',
                            storagePath: unCdnify(DEFAULT_AFFIDAVIT_URL),
                            publisher: activeOrganization.ref,
                            name: 'New Affidavit Template',
                            isColumnManaged: false
                        });
                    },
                    createButtonText: 'Create Template'
                } }), editedAffidavitTemplate && (_jsx(AffidavitTemplateEditForm, { affidavitTemplateRef: editedAffidavitTemplateRef, activeOrganization: activeOrganization, affidavitTemplate: editedAffidavitTemplate, onClose: () => {
                    setEditedAffidavitTemplateRef(undefined);
                    setEditedAffidavitTemplate(undefined);
                } })), drawerTemplate &&
                (drawerTemplate.isV2() ? (_jsx(AffidavitTemplateDrawer, { activeOrganization: activeOrganization, drawerTemplate: drawerTemplate, onClose: () => setDrawerTemplate(undefined), onEdit: () => {
                        setEditedAffidavitTemplateRef(drawerTemplate.ref);
                        setEditedAffidavitTemplate(drawerTemplate.data());
                        setDrawerTemplate(undefined);
                    } })) : (_jsx(LegacyAffidavitTemplateDrawer, { activeOrganization: activeOrganization, onCloseDrawer: () => setDrawerTemplate(undefined), drawerTemplate: drawerTemplate, onEdit: () => {
                        setEditedAffidavitTemplateRef(drawerTemplate.ref);
                        setEditedAffidavitTemplate(drawerTemplate.data());
                        setDrawerTemplate(undefined);
                    } }))), showGlobalControls && (_jsx(GlobalControls, { activeOrganization: activeOrganization, onClose: () => setShowGlobalControls(false), user: activeUser }))] }));
}
