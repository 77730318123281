var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { Paper, Typography, Avatar, Box, Input, InputLabel, FormHelperText, FormControl, CircularProgress } from '@material-ui/core';
import AdornedButton from 'components/AdornedButton';
import LoadingState from 'components/LoadingState';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import MailOutline from '@material-ui/icons/MailOutline';
import api from 'api';
import { exists } from 'lib/types';
import { BROWSER, BROWSERS } from '../../../constants';
const styles = (theme) => createStyles({
    main: {
        width: 'auto',
        height: '100vh',
        display: 'flex',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    paper: {
        marginTop: theme.spacing(16),
        marginBottom: theme.spacing(16),
        display: BROWSER === BROWSERS.ie ? 'block' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        width: 500,
        height: 'max-content'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    ieAligner: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column'
    },
    error: {
        color: theme.palette.error.main
    },
    resend: {
        '&:hover': {
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.main,
            cursor: 'pointer'
        }
    }
});
const mapStateToProps = (state) => ({
    user: state.auth.user
});
function EmailNeedsConfirm({ user, classes, push }) {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [resendLoading, setResendLoading] = useState(false);
    const [resendEmailMessage, setResendEmailMessage] = useState('');
    const verify = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(user))
            return;
        setLoading(true);
        try {
            yield api.post('users/confirm-email', { code, uid: user.id });
        }
        catch (err) {
            const errStr = err.toString()
                ? err.toString().replace('Error: ', '')
                : 'Could not verify code';
            setError(errStr);
        }
        setLoading(false);
    });
    const resend = () => __awaiter(this, void 0, void 0, function* () {
        setResendLoading(true);
        try {
            yield api.post('users/reset-code');
            setResendEmailMessage('Email with new code sent');
        }
        catch (err) {
            setResendEmailMessage('We had trouble sending a new code. Please login again, and retry. If the problem persists, contanct help@column.us');
        }
        setResendLoading(false);
    });
    if (!exists(user)) {
        return _jsx(LoadingState, {});
    }
    if (!user.data().emailNeedsConfirm) {
        push('/');
        return _jsx(LoadingState, {});
    }
    return (_jsx("main", Object.assign({ className: classes.main }, { children: _jsxs(Paper, Object.assign({ className: classes.paper }, { children: [_jsx("div", Object.assign({ className: BROWSER === BROWSERS.ie ? classes.ieAligner : '' }, { children: _jsx(Avatar, Object.assign({ className: classes.avatar }, { children: _jsx(MailOutline, {}) })) })), _jsx(Typography, Object.assign({ component: "h1", variant: "h5" }, { children: "Verify your Email Address" })), _jsx(Box, Object.assign({ mt: 2 }, { children: _jsxs(Typography, Object.assign({ variant: "body2" }, { children: ["Enter the verification code we sent to: ", _jsx("b", { children: user.data().email }), ' ', _jsx("br", {}), "If you don't see it, check your spam folder."] })) })), _jsx(Box, Object.assign({ mt: 2, style: { width: '100%', height: 50 } }, { children: _jsxs(FormControl, Object.assign({ style: { width: '100%' } }, { children: [_jsx(InputLabel, Object.assign({ htmlFor: "code" }, { children: "Verification Code" })), _jsx(Input, { onChange: e => {
                                    setError('');
                                    setCode(e.target.value);
                                }, name: "code", fullWidth: true }), _jsx(FormHelperText, Object.assign({ className: classes.error, error: error !== '' }, { children: error }))] })) })), _jsx(Box, Object.assign({ mt: 3, style: { width: '100%' } }, { children: _jsx(AdornedButton, Object.assign({ disabled: !code || code.length === 0 || loading, id: "submit", fullWidth: true, variant: "contained", color: "primary", loading: loading, onClick: verify, className: classes.submit }, { children: "Verify" })) })), _jsx(Box, Object.assign({ mt: 2 }, { children: resendLoading ? (_jsx(CircularProgress, { size: 15 })) : resendEmailMessage ? (_jsx(Typography, Object.assign({ variant: "body2" }, { children: resendEmailMessage }))) : (_jsx("div", Object.assign({ className: classes.resend, onClick: () => {
                            if (resendLoading)
                                return;
                            void resend();
                        } }, { children: _jsx(Typography, Object.assign({ color: "primary", variant: "body2" }, { children: "Resend" })) }))) }))] })) })));
}
export default connect(mapStateToProps, { push })(withStyles(styles, { withTheme: true })(EmailNeedsConfirm));
export const EmailNeedsConfirmWithoutDispatch = connect(mapStateToProps)(withStyles(styles, { withTheme: true })(EmailNeedsConfirm));
