var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiPost } from 'api/typed';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import { ColumnService } from 'lib/services/directory';
import { Extensions, FileType } from 'lib/types/mime';
import { logAndCaptureException } from 'utils';
const downloadProof = (orderId, version) => __awaiter(void 0, void 0, void 0, function* () {
    const { response, error } = yield apiPost('orders/download-proofs', {
        orderId,
        version
    });
    if (error !== null) {
        logAndCaptureException(ColumnService.OBITS, error, 'Error downloading proofs', {
            orderId
        });
        throw error;
    }
    if (response.proofDownload) {
        if (response.fileType === FileType.PDF) {
            const buffer = Buffer.from(response.proofDownload);
            downloadFileContentsInBrowser(`${response.fileNameWithoutExtension}.${Extensions.pdf}`, new Blob([buffer]), 'application/pdf');
        }
        else if (typeof response.proofDownload === 'string') {
            const downloadUrl = response.proofDownload;
            window.open(downloadUrl, '_blank');
        }
    }
});
export default downloadProof;
