var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { exists } from '../../types';
import { getAffidavitSettingsForNotice } from '../../pricing/affidavits';
export const useNoticeAffidavitSettings = (ctx, notice) => {
    const [settings, setSettings] = useState();
    useEffect(() => {
        if (!exists(notice)) {
            setSettings(undefined);
            return;
        }
        const check = () => __awaiter(void 0, void 0, void 0, function* () {
            const settings = yield getAffidavitSettingsForNotice(ctx, notice);
            setSettings(settings);
        });
        void check();
    }, [notice === null || notice === void 0 ? void 0 : notice.id]);
    return settings;
};
