import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
function Drawer({ disableDarkenBackground, onClose, className, children, header, open }) {
    return (_jsxs(DrawerWrapper, Object.assign({ open: open, disableDarkenBackground: disableDarkenBackground, className: className, onClose: onClose }, { children: [_jsx(DrawerHeader, { header: header, onClose: onClose }), children] })));
}
function DrawerHeader({ header, onClose }) {
    return (_jsxs("div", Object.assign({ className: "flex items-center justify-between px-6 py-3 border-b" }, { children: [header, _jsx("div", Object.assign({ className: "border border-column-grey-50 rounded p-1.5 text-column-gray-400 hover:bg-column-gray-25 cursor-pointer", onClick: () => onClose() }, { children: _jsx(XMarkIcon, { className: "h-6 w-6" }) }))] })));
}
function DrawerWrapper({ disableDarkenBackground, className, children, open, onClose }) {
    const mainDrawerClass = classNames('fixed overflow-hidden z-100 inset-0 transform ease-in-out', {
        'transition-opacity opacity-100 duration-500 translate-x-0': open,
        'transition-all delay-500 opacity-0 translate-x-full': !open,
        'bg-column-gray-900 bg-opacity-25': !disableDarkenBackground
    });
    const handleClick = (e) => {
        // Check if the event target is the same as the current target
        // which means the click is directly on the overlay, not children
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    const sectionDrawerClass = classNames('w-screen max-w-xl right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform', {
        'translate-x-0 flex flex-col': open,
        'translate-x-full': !open
    }, className);
    return (_jsx("div", Object.assign({ className: mainDrawerClass, onClick: handleClick }, { children: _jsx("section", Object.assign({ className: sectionDrawerClass }, { children: children })) })));
}
export default Drawer;
