import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
function InviteWarningModal({ onConfirmClick, onUpdateInviteModalHeaderText, organization }) {
    useEffect(() => {
        onUpdateInviteModalHeaderText('Permissions warning');
    }, []);
    const handleClick = () => {
        onUpdateInviteModalHeaderText('Invite members to your organization');
        onConfirmClick();
    };
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "mb-4 font-regular text-large text-gray-700" }, { children: [`If you add new members to ${organization.data().name}, they will also gain access to the other organizations 
      you have access to on Column.`, _jsx("br", {}), _jsx("br", {}), "If you want to restrict new members\u2019 access to one newspaper, please select that newspaper in the top-left corner of your screen, visit My Organization settings, and invite new members from there.", _jsx("br", {}), _jsx("br", {}), "Click ", _jsx("b", { children: "Confirm" }), " to proceed with inviting new members to all of your organizations."] })), _jsx(ColumnButton, { onClick: handleClick, buttonText: "Confirm", type: "button", primary: true })] }));
}
export default InviteWarningModal;
