import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
const FOOTER_OFFSET = 20;
function Report({ reportUrl, height }) {
    return (_jsx("div", Object.assign({ style: {
            height,
            overflow: 'hidden'
        } }, { children: _jsx("iframe", { title: "studio-report", style: {
                height: `calc(${height} + ${FOOTER_OFFSET}px)`,
                width: '100%'
            }, src: reportUrl, frameBorder: "0" }) })));
}
export default Report;
