import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
function OptionText({ disabled, checked, labelText, labelDescription, textClasses }) {
    const titleClasses = classNames({
        'text-primary-500': checked && !disabled,
        'text-primary-400': checked && disabled,
        'text-column-gray-600': !checked && !disabled,
        'text-column-gray-500': !checked && disabled
    });
    if (!labelDescription) {
        return _jsx("span", Object.assign({ className: textClasses }, { children: labelText }));
    }
    return (_jsxs("div", { children: [_jsx("p", Object.assign({ className: titleClasses }, { children: labelText })), _jsx("p", Object.assign({ className: `text-column-gray-400 text-xs font-normal` }, { children: labelDescription }))] }));
}
function InputInner({ disabled, id, type, checked, onChange, textClasses }) {
    return (_jsx("input", { disabled: disabled, id: id, type: type, onChange: () => onChange(!checked), checked: checked, className: classNames('form-checkbox h-4 w-4', textClasses, {
            'rounded-full': type === 'radio'
        }) }));
}
export default function CheckboxInput({ disabled, checked, onChange, labelText, labelDescription, id, type = 'checkbox', inputPosition = 'right' }) {
    const labelClasses = classNames(`flex gap-2 w-full bg-white border rounded-md justify-between items-center py-3 px-4 text-sm font-medium`, {
        'border-primary-500': checked && !disabled,
        'border-primary-400': checked && disabled,
        'border-column-gray-200': !checked && !disabled,
        'border-column-gray-300': !checked && disabled,
        'cursor-pointer': !disabled,
        'cursor-not-allowed bg-column-gray-25': disabled
    });
    const textClasses = classNames(`transition duration-150 ease-in-out`, {
        'text-primary-500': checked && !disabled,
        'text-primary-400': checked && disabled,
        'text-column-gray-400': !checked && !disabled,
        'text-column-gray-300': !checked && disabled,
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled
    });
    const input = (_jsx(InputInner, { disabled: disabled, id: id, type: type, checked: checked, onChange: onChange, textClasses: textClasses }));
    if (!labelText) {
        return input;
    }
    const label = (_jsx(OptionText, { disabled: disabled, checked: checked, labelText: labelText, labelDescription: labelDescription, textClasses: textClasses }));
    return (_jsxs("label", Object.assign({ className: labelClasses, htmlFor: id }, { children: [inputPosition === 'right' && label, input, inputPosition === 'left' && label] })));
}
