var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IdentificationIcon, CreditCardIcon, CalendarIcon, HashtagIcon, WalletIcon, UserIcon, CheckCircleIcon, TagIcon } from '@heroicons/react/24/outline';
import { NoticeStatusType, ELabels, InvoiceStatus } from 'lib/enums';
import { exists } from 'lib/types';
import { Help } from '@material-ui/icons';
import { getAccountNumberForNotice, getCustomer, getCustomerName, getCustomerOrganization, getCustomerOrganizationName } from 'lib/notice/customer';
import { isPastDueInNewspaperTimezone, getDueDateAndTimeString, getNoticeType } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { TextField } from '@material-ui/core';
import api from 'api';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Badge } from 'lib/components/Badge';
import { Tooltip } from 'lib/components/Tooltip';
import { BillingService } from 'services';
import { isUnpaidInvoice } from 'lib/utils/invoices';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import { useGetNoticeSyncEvents } from 'routes/placeScroll/hooks/useGetNoticeSyncEvents';
import { useGetNoticeRequiresUpfrontPayment } from 'routes/placeScroll/hooks/useGetNoticeRequiresUpfrontPayment';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { PublicationDateStatusBadge } from './PublicationDateStatusBadge';
import ConfirmationStatusBadge from './ConfirmationStatusBadge';
function BasicInfoRow({ Icon, heading, caption, id, showEdit, headingLink, filerName, notice, invoice }) {
    const [editMode, setEditMode] = useState();
    const [orderNumber, setOrderNumber] = useState((heading === null || heading === void 0 ? void 0 : heading.toString()) || '');
    const [currentOrderNumber, setCurrentOrderNumber] = useState();
    const updateNotice = (notice, invoice) => __awaiter(this, void 0, void 0, function* () {
        yield notice.ref.update({
            customId: orderNumber
        });
        if (exists(invoice)) {
            yield api.post(`documents/${notice.id}/regenerate`, {
                docType: 'INVOICE'
            });
            // we need to update the order number in the invoice memo too
            let customMemo = invoice.data().customMemo || '';
            customMemo = customMemo.replace(`Order Number: ${currentOrderNumber}`, `Order Number: ${orderNumber}`);
            // TODO: Move this to the back-end so we can prevent all invoice updates on the FE
            yield invoice.ref.update({
                customMemo
            });
        }
    });
    return (_jsxs("div", Object.assign({ className: "flex items-center mx-6", id: id }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: Icon })), _jsxs("section", Object.assign({ className: "mx-5" }, { children: [_jsxs("div", Object.assign({ className: "flex inline-block" }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: caption })), showEdit && (_jsx("div", Object.assign({ className: `inline-block items-center px-2 text-base font-medium text-primary-500 cursor-pointer`, onClick: () => {
                                    if (editMode &&
                                        currentOrderNumber !== orderNumber &&
                                        exists(notice)) {
                                        void updateNotice(notice, invoice);
                                    }
                                    else {
                                        setCurrentOrderNumber(orderNumber);
                                    }
                                    setEditMode(!editMode);
                                }, "data-testid": "edit-button" }, { children: editMode ? 'Save' : 'Edit' })))] })), _jsxs("div", { children: [(!editMode || (editMode && id !== 'notice-custom-id')) && (_jsx("div", Object.assign({ className: `font-medium text-column-gray-500 inline-block ${headingLink
                                    ? `${filerName ? 'underline' : 'hover:underline'} cursor-pointer hover:blue-800`
                                    : ''}`, id: `${id}-sub-heading`, onClick: () => headingLink && window.open(headingLink) }, { children: heading }))), editMode && caption === ELabels.publisher_id.label && (_jsx(TextField, { id: "order-number", className: "text-base text-gray-800", value: orderNumber, onChange: e => setOrderNumber(e.target.value) }))] })] }))] })));
}
export default function NoticeDetailBasicInfo({ notice, invoice, invoiceOverdue, setInvoiceOverdue, newspaper, isPublisher, filer, isInvoicedOutsideColumn }) {
    var _a, _b;
    const [filerName, setFilerName] = useState('');
    const [invoiceStatusOrDueDate, setInvoiceStatusOrDueDate] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [customerArchived, setCustomerArchived] = useState(false);
    const [accountNumber, setAccountNumber] = useState('');
    const [noticeAwaitingBulkInvoice, setNoticeAwaitingBulkInvoice] = useState(false);
    // Used to determine publication status pill color
    const exportSettings = useSyncExportSettings(newspaper || undefined);
    const noticeSyncEvents = useGetNoticeSyncEvents(notice);
    const noticeRequiresUpfrontPayment = useGetNoticeRequiresUpfrontPayment(notice);
    const noticeSyncedAtLeastOnce = noticeSyncEvents.length > 0;
    const ctx = getFirebaseContext();
    const enableOrderNumberEditing = useBooleanFlag(LaunchDarklyFlags.ENABLE_ORDER_NUMBER_EDITING) &&
        isPublisher;
    const billingStatus = BillingService.getStatusEnum(notice, invoice, isPublisher).label;
    useEffect(() => {
        const fetchFilerName = () => __awaiter(this, void 0, void 0, function* () {
            const { filedBy } = notice.data();
            const customer = yield getCustomer(ctx, filer, newspaper);
            setCustomerArchived(!!(customer === null || customer === void 0 ? void 0 : customer.data().archived));
            const userName = getCustomerName(customer, filer, true);
            const filedBySnap = yield (filedBy === null || filedBy === void 0 ? void 0 : filedBy.get());
            const customerOrganization = yield getCustomerOrganization(ctx, filedBySnap, newspaper);
            const customerOrganizationName = getCustomerOrganizationName(customerOrganization, customer);
            setCustomerId((customer === null || customer === void 0 ? void 0 : customer.id) || '');
            // TODO: Should we be showing the pending registration message?
            // if (filedBy) {
            //   /**
            //    * Show the user's name as 'User Pending Registration' if
            //    * they have been invited to the advertising org and do not have
            //    * a name saved
            //    */
            //   const pendingInvite = (
            //     await getOpenInvitesAssociatedWithEmail(ctx, email, filedBy.id)
            //   )[0];
            //   if (exists(pendingInvite) && !userName) {
            //     setFilerName(
            //       `User Pending Registration${
            //         pendingInvite.data().organizationName
            //           ? `- ${pendingInvite.data().organizationName}`
            //           : ''
            //       }`
            //     );
            //     return;
            //   }
            // }
            if (customerOrganizationName) {
                setFilerName(`${userName} - ${customerOrganizationName}`);
                return;
            }
            return setFilerName(userName);
        });
        if (filer && exists(filer)) {
            void fetchFilerName();
        }
    }, [filer === null || filer === void 0 ? void 0 : filer.id]);
    useEffect(() => {
        const getInvoiceStatusOrDueDate = () => __awaiter(this, void 0, void 0, function* () {
            if (!exists(invoice)) {
                setInvoiceStatusOrDueDate('Awaiting Invoice Creation');
            }
            else if (invoice.data().isWithinBulkInvoice ||
                notice.data().bulkInvoice_v2) {
                setInvoiceStatusOrDueDate('Monthly Invoice');
            }
            else if (noticeAwaitingBulkInvoice) {
                setInvoiceStatusOrDueDate('Awaiting Bulk Invoice');
            }
            else if (invoice.data().invoiceOutsideColumn) {
                setInvoiceStatusOrDueDate('Invoiced Outside Column');
            }
            else {
                const dueDate = moment(invoice.data().due_date * 1000);
                setInvoiceOverdue(isPastDueInNewspaperTimezone(dueDate, newspaper.data().iana_timezone, moment()) && isUnpaidInvoice(invoice));
                if (notice.data().requireUpfrontPayment) {
                    setInvoiceStatusOrDueDate(getDueDateAndTimeString(invoice, newspaper));
                    return;
                }
                setInvoiceStatusOrDueDate(dueDate.format('MMM D'));
            }
        });
        if (exists(notice)) {
            void getInvoiceStatusOrDueDate();
        }
    }, [invoice === null || invoice === void 0 ? void 0 : invoice.id, noticeAwaitingBulkInvoice]);
    useEffect(() => {
        const fetchAndSetAccountNumber = () => __awaiter(this, void 0, void 0, function* () {
            if (!exists(notice))
                return;
            const fetchedAccountNumber = yield getAccountNumberForNotice(getFirebaseContext(), notice);
            if (fetchedAccountNumber) {
                setAccountNumber(fetchedAccountNumber.id);
            }
        });
        void fetchAndSetAccountNumber();
    }, [notice.id]);
    useEffect(() => {
        const getNoticeAwaitingBulkInvoice = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield api.get(`notices/${notice.id}/awaiting-bulk-invoice`);
                if (res.success) {
                    setNoticeAwaitingBulkInvoice(res.noticeAwaitingBulkInvoice);
                }
            }
            catch (err) {
                setNoticeAwaitingBulkInvoice(false);
            }
        });
        void getNoticeAwaitingBulkInvoice();
    }, [notice.id]);
    const publicationMoments = notice
        .data()
        .publicationDates.map((fbDate) => moment(fbDate.toDate()).tz(newspaper.data().iana_timezone || 'America/Chicago'));
    const isPublicationDateComplete = (publicationMoment) => {
        const currentDateAfterPubDate = moment().isAfter(publicationMoment);
        // 1. If the notice is in an integration paper and did not sync, return false
        if (currentDateAfterPubDate && exportSettings && !noticeSyncedAtLeastOnce) {
            return false;
        }
        // 2. If the notice requiresUpfrontPayment but the invoice hasn't been paid, return false
        if (currentDateAfterPubDate &&
            noticeRequiresUpfrontPayment &&
            !((invoice === null || invoice === void 0 ? void 0 : invoice.data().status) === InvoiceStatus.paid.value)) {
            return false;
        }
        // 3. Otherwise, just return whether the current date is after the publication date.
        // We assume that if the above constraints were not present
        // then the publisher has run the notice if the date has passed.
        return currentDateAfterPubDate;
    };
    const model = getModelFromSnapshot(UserNoticeModel, ctx, notice);
    const showAmountPaid = !!isInvoicedOutsideColumn && !!invoice;
    const noticeStatusText = `${model.isCancelled && !((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.refund_id)
        ? NoticeStatusType.cancelled.label
        : billingStatus}
  ${showAmountPaid
        ? ` ($${(((invoice === null || invoice === void 0 ? void 0 : invoice.data().amount_paid) || 0) / 100).toFixed(2)})`
        : ''}`;
    const showNoticeStatusTooltip = !isPublisher &&
        (isInvoicedOutsideColumn || billingStatus === 'Awaiting Invoice Creation');
    const noticeStatusTooltipText = isInvoicedOutsideColumn
        ? 'This publisher handles invoices outside of Column.'
        : billingStatus === 'Awaiting Invoice Creation'
            ? 'You will be notified once the publisher creates an invoice. No action is required in the meantime.'
            : '';
    const noticeType = (_b = getNoticeType(notice, newspaper, {
        skipDisplayType: true
    })) === null || _b === void 0 ? void 0 : _b.label;
    return (_jsxs("div", Object.assign({ className: "bg-white py-6 shadow-column-2 rounded-md border xl:mx-0 gap-6 flex-col flex h-full" }, { children: [isPublisher ? (_jsx(BasicInfoRow, { heading: filerName, id: "notice-filer", caption: "Customer Name", Icon: _jsx(UserIcon, { className: "w-6 h-6" }), headingLink: customerId && !customerArchived
                    ? `/settings/organization/?tab=customers&customerId=${customerId}`
                    : undefined, filerName: true })) : (_jsx(BasicInfoRow, { id: "notice-publisher", heading: newspaper.data().name, caption: "Newspaper", Icon: _jsx(UserIcon, { className: "w-6 h-6" }), headingLink: newspaper.data().hyperlinkPaperName
                    ? newspaper.data().website
                    : undefined })), _jsx(BasicInfoRow, { heading: _jsx(ConfirmationStatusBadge, { confirmationStatus: model.confirmationStatus, isCancelled: model.isCancelled }), id: "confirmation-status", caption: "Confirmation Status", Icon: _jsx(CheckCircleIcon, { className: "w-6 h-6" }) }), _jsx(BasicInfoRow, { heading: _jsx("div", Object.assign({ className: "flex flex-wrap gap-2 mt-1" }, { children: publicationMoments.map((publicationMoment, i) => {
                        const isComplete = isPublicationDateComplete(publicationMoment);
                        return (_jsx(PublicationDateStatusBadge, { publicationMoment: publicationMoment, notice: notice, newspaper: newspaper, isComplete: isComplete }, i));
                    }) })), id: "notice-dates", caption: `Publication Date${publicationMoments.length > 1 ? 's' : ''}`, Icon: _jsx(CalendarIcon, { className: "w-6 h-6" }) }), _jsx(BasicInfoRow, { heading: _jsxs("section", Object.assign({ className: "flex" }, { children: [_jsx("span", Object.assign({ className: "w-max mr-2" }, { children: noticeStatusText })), showNoticeStatusTooltip && (_jsx("span", { children: _jsx(Tooltip, Object.assign({ classes: "inline-block flex align-middle font-normal mt-1 text-gray-600 z-50 absolute", helpText: noticeStatusTooltipText, position: "bottom" }, { children: _jsx(Help, { fontSize: "small", className: "p-0.5 pt-0" }) })) }))] })), id: "notice-payment-status", caption: "Status", Icon: _jsx(WalletIcon, { className: "w-6 h-6" }) }), _jsx(BasicInfoRow, { id: "invoice-due", heading: _jsxs("div", Object.assign({ className: "flex gap-2 mt-1" }, { children: [_jsx("span", { children: invoiceStatusOrDueDate }), invoiceOverdue && (_jsx(Badge, Object.assign({ status: "critical", size: "md" }, { children: "Past Due" })))] })), caption: `Invoice Due${isPublisher ? ' by Advertiser' : ''}`, Icon: _jsx(CreditCardIcon, { className: "w-6 h-6" }) }), notice.data().customId && (_jsx(BasicInfoRow, { id: "notice-custom-id", heading: notice.data().customId, caption: ELabels.publisher_id.label, Icon: _jsx(HashtagIcon, { className: "w-6 h-6" }), showEdit: enableOrderNumberEditing, notice: notice, invoice: invoice })), accountNumber && (_jsx(BasicInfoRow, { id: ELabels.publisher_customer_id.key, heading: accountNumber, caption: ELabels.publisher_customer_id.label, Icon: _jsx(IdentificationIcon, { className: "w-6 h-6" }) })), isPublisher && noticeType && (_jsx(BasicInfoRow, { id: "notice-type", heading: noticeType, caption: "Notice Type", Icon: _jsx(TagIcon, { className: "w-6 h-6" }) }))] })));
}
