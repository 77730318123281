var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NoticeType, Country } from '../enums';
import { getInheritedProperty } from '../utils/inheritance';
export const shouldShowPaperRegardlessOfUploadReadiness = (org) => { var _a; return !!((_a = org.data()) === null || _a === void 0 ? void 0 : _a.alwaysShowInPlacementFlow); };
export const publisherReadyToUpload = (org, allowDisabled = false) => Boolean((allowDisabled || !org.data().disabled) &&
    org.data().name &&
    org.data().address &&
    org.data().phone &&
    org.data().city &&
    org.data().county &&
    org.data().state &&
    org.data().adTemplate &&
    org.data().deadlines &&
    (org.data().affidavitDisabled ||
        (org.data().signatoryName &&
            org.data().signatoryRole &&
            org.data().publicationFrequency)));
export const paperIsValidForCustomerPlacementFlow = (org, allowDisabled = false) => publisherReadyToUpload(org, allowDisabled) ||
    shouldShowPaperRegardlessOfUploadReadiness(org);
export const findMatchingNoticeType = (org, noticeType) => {
    var _a;
    const allowedNotices = (_a = org.data()) === null || _a === void 0 ? void 0 : _a.allowedNotices;
    if (!allowedNotices) {
        return;
    }
    return allowedNotices.find(allowedNotices => allowedNotices.value === noticeType);
};
/**
 * @deprecated Use getNoticeTypeFromNoticeData instead
 */
export const getNoticeTypeData = (noticeType, org) => {
    const noticeTypeNumber = parseInt(noticeType, 10);
    const selectedNoticeType = findMatchingNoticeType(org, noticeTypeNumber) ||
        NoticeType.by_value(noticeType);
    if (!selectedNoticeType) {
        console.error(`Unable to find notice type ${noticeType} for organization ${org.id}`);
        return NoticeType.custom;
    }
    return selectedNoticeType;
};
export const getNoticeTypeStr = (noticeType, org) => {
    if (noticeType)
        return getNoticeTypeData(noticeType, org).label;
    return 'Public';
};
/**
 * @deprecated Use getNoticeTypeFromNoticeData instead
 */
export const getNoticeTypeDataFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield notice.data().newspaper.get();
    return getNoticeTypeData(notice.data().noticeType, newspaper);
});
export const getMemoText = ({ customMemo = '', referenceId, publisherOrderNumber, isReceipt, country }) => {
    const customHeading = '=== Notes ===';
    const boilerplateHeading = '=== How to pay this invoice ===';
    const noticeName = referenceId ? `Notice Name: ${referenceId}\n` : '';
    const publisherCustomID = publisherOrderNumber
        ? `Order Number: ${publisherOrderNumber}\n`
        : '';
    const customIds = `${noticeName}${publisherCustomID}`;
    const check = country === Country.GBR.value ? 'cheque' : 'check';
    const boilerplate = `We accept ACH bank transfers, debit/ credit cards, and ${check}s. If you would like to pay by ${check}, please make it out to `;
    let memo = '';
    memo += `${customHeading}\n${customMemo}\n${customIds}\n`;
    if (!isReceipt)
        memo += `${boilerplateHeading}\n${boilerplate}`;
    return memo;
};
/**
 * Function checks whether or not ACH should be disabled for a given publisher.
 * @param newspaper
 * ================
 * @returns value of disableACH on the newspaper (or the value of disableACH
 * on its parent if disableACH is not defined on the newspaper); if not defined
 * on either, it returns false
 */
export const shouldDisableACHForPublisher = (newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    const newspaperParent = yield ((_b = (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.get());
    const isNewspaperACHdisabled = (_c = newspaper.data()) === null || _c === void 0 ? void 0 : _c.disableACH;
    const isParentACHdisabled = (_d = newspaperParent === null || newspaperParent === void 0 ? void 0 : newspaperParent.data()) === null || _d === void 0 ? void 0 : _d.disableACH;
    return (_e = isNewspaperACHdisabled !== null && isNewspaperACHdisabled !== void 0 ? isNewspaperACHdisabled : isParentACHdisabled) !== null && _e !== void 0 ? _e : false;
});
/**
 * Function checks whether a publisher or its parent group is on Column Pro / Reps
 */
export const getPublisherUsesColumnReps = (publisherRef) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    const planSettings = yield getInheritedProperty(publisherRef, 'planSettings');
    return (_f = planSettings === null || planSettings === void 0 ? void 0 : planSettings.features.columnReps) !== null && _f !== void 0 ? _f : false;
});
