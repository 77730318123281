import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import TabGroup from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import CrossPublisherFees from './crossPublisherFees';
import NoticeRatesTable from './NoticeRatesTable';
const RATES_TABLE_TAB = {
    label: 'Rates',
    enabled: true,
    id: 'rates-table-tab'
};
const CROSS_PUBLISHER_FEES_SETTINGS = {
    label: 'Cross-Publisher Fees',
    enabled: true,
    id: 'cross-publisher-fees-settings'
};
const RATES_TABS = [RATES_TABLE_TAB, CROSS_PUBLISHER_FEES_SETTINGS];
export default function RatesSettings({ activeOrganization }) {
    const [rateSettingsTab, setRateSettingsTab] = useState(RATES_TABLE_TAB);
    return (_jsxs(SettingsPage, { children: [_jsx(TabGroup, { onClickTab: setRateSettingsTab, activeTab: rateSettingsTab, tabs: RATES_TABS, id: "rates-settings-tabs" }), rateSettingsTab.label === RATES_TABLE_TAB.label && (_jsx(NoticeRatesTable, { activeOrganization: activeOrganization })), rateSettingsTab.label === CROSS_PUBLISHER_FEES_SETTINGS.label && (_jsx(CrossPublisherFees, { activeOrganization: activeOrganization }))] }));
}
