var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { InvoiceStatus, NoticeStatusType } from 'lib/enums';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
import { getNoticeType } from 'lib/helpers';
import { exists } from 'lib/types';
/**
 * Determines whether or not we should show the up front payment banner in the notice detail page
 * @param {ESnapshotExists<ENotice>} notice notice we are rendering on the notice details screen
 * @param {ESnapshotExists<EOrganization>} newspaper publisher that is publishing the notice
 * @param {ESnapshotExists<EInvoice> | undefined} invoice invoice associated with the notice (or undefined if it doesn't exist yet)
 * @param {boolean} isInvoicedOutsideColumn whether or not this notice should be invoiced outside Column
 * @returns {boolean} whether or not we should show the up front payment banner
 */
export const getShouldShowUpFrontPaymentBanner = (notice, newspaper, invoice, isInvoicedOutsideColumn) => {
    // do not show if invoice hasn't been created or if notice is invoiced outside Column
    if (!exists(invoice) || isInvoicedOutsideColumn === undefined)
        return false;
    if (isInvoicedOutsideColumn)
        return false;
    // don't run if notice has been cancelled
    if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
        return false;
    // don't run if the invoice has already been paid
    // TODO: Should this include InvoiceStatus.initiated.value? https://columnpbc.atlassian.net/browse/IT-4424
    if ([
        InvoiceStatus.paid.value,
        InvoiceStatus.partially_refunded.value,
        InvoiceStatus.unpayable.value
    ].includes(invoice.data().status))
        return false;
    if (notice.data().requireUpfrontPayment !== undefined) {
        return (notice.data().requireUpfrontPayment ||
            notice.data().requireUpfrontPaymentModifiedByPublisher);
    }
    const noticeType = getNoticeType(notice, newspaper);
    // short circuit if we are specifically setting the property
    if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.upFrontPayment) !== undefined)
        return noticeType.upFrontPayment;
    if (newspaper.data().requireUpfrontPayment)
        return newspaper.data().requireUpfrontPayment;
    if (hasPaymentOrPartialRefund(invoice))
        return false;
    return false;
};
/**
 * Banner at the very top of the notice detail page that shows quick actions
 */
export default function NoticeDetailUpFrontPaymentBanner({ setShowSendReminderModal, invoiceOverdue, isPublisher, invoiceSnap, noticeSnap }) {
    return (_jsx("div", Object.assign({ className: classNames((noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
            ? 'bg-primary-100'
            : 'bg-white border border-column-gray-150', 'relative mb-6 rounded-md') }, { children: _jsxs("div", Object.assign({ className: "flex justify-between items-center max-w-screen-xl mx-auto h-20 md:h-14 text-sm text-gray-800" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", { className: "w-4 md:w-2 h-20 md:h-14 mr-4" }), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [isPublisher && (_jsx("div", { children: _jsxs(Switch, Object.assign({ checked: !!(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment), onChange: () => __awaiter(this, void 0, void 0, function* () {
                                            yield (noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.ref.update({
                                                requireUpfrontPayment: !noticeSnap.data()
                                                    .requireUpfrontPayment,
                                                requireUpfrontPaymentModifiedByPublisher: true
                                            }));
                                        }), className: classNames((noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
                                            ? 'bg-blue-900'
                                            : 'bg-gray-200', 'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'), style: {
                                            width: '36px'
                                        } }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Use setting" })), _jsx("span", { "aria-hidden": "true", className: classNames((noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
                                                    ? 'translate-x-4'
                                                    : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })] })) })), _jsxs("span", Object.assign({ className: classNames('ml-2 mr-1 font-medium text-sm leading-6', {
                                        'text-column-gray-500': noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment,
                                        'text-column-gray-400': !(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data().requireUpfrontPayment)
                                    }) }, { children: ["Upfront payment required.", ' ', isPublisher
                                            ? 'The advertiser has not yet fulfilled the invoice for this notice.'
                                            : invoiceOverdue
                                                ? 'Because your invoice is overdue, your notice might not be published.'
                                                : 'If you don’t pay your invoice on time, your notice might not be published.'] }))] }))] })), isPublisher ? (_jsxs("div", Object.assign({ className: "uppercase mr-6 cursor-pointer font-medium text-sm leading-6 text-primary-600 flex items-center", onClick: () => setShowSendReminderModal(true) }, { children: [_jsx("span", { children: "send reminder" }), _jsx(ArrowRightIcon, { className: "ml-1 w-4 h-4 stroke-2" })] }))) : (_jsx("a", Object.assign({ target: "_blank", rel: "noopener noreferrer", href: exists(invoiceSnap)
                        ? `${window.location.origin}/invoices/${invoiceSnap.id}/pay`
                        : '', className: "uppercase mx-4 cursor-pointer font-bold" }, { children: "pay invoice" })))] })) })));
}
