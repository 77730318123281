import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import OldCreateInvoice from './oldCreateInvoice';
import NewCreateInvoice from './newCreateInvoice';
export default function CreateInvoice(props) {
    const enableNewCreateInvoice = useBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_CREATE_INVOICE_SCREEN);
    if (enableNewCreateInvoice) {
        return _jsx(NewCreateInvoice, {});
    }
    return _jsx(OldCreateInvoice, Object.assign({}, props));
}
