import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ColumnButton } from '../ColumnButton';
export function Header({ id, title, subtitle, titleHidden, onClose, showCloseButton }) {
    const headerClasses = classNames('flex items-start justify-between sticky', {
        'p-6': !titleHidden,
        'sr-only': titleHidden
    });
    return (_jsxs("div", Object.assign({ className: headerClasses }, { children: [_jsxs("div", { children: [title && (_jsx("h3", Object.assign({ id: id, className: "text-xl leading-6 font-semibold text-gray-900" }, { children: title }))), subtitle && (_jsx("div", Object.assign({ className: "mt-3 font-medium text-gray-700" }, { children: subtitle })))] }), showCloseButton && (_jsx(ColumnButton, { type: "button", startIcon: _jsx(XMarkIcon, { className: "h-5 w-5" }), "aria-label": "Close modal", onClick: onClose, link: true }))] })));
}
