import { jsx as _jsx } from "react/jsx-runtime";
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getOrThrow } from 'lib/utils/refs';
import { NewspapersContext } from './NewspapersContext';
const emptyPublishersArray = [];
export function NewspapersContextProvider({ children, newspaperOrdersFormData }) {
    const { value } = useAsyncEffect({
        fetchData: () => Promise.all(newspaperOrdersFormData.map(o => getOrThrow(o.newspaper))),
        dependencies: [newspaperOrdersFormData === null || newspaperOrdersFormData === void 0 ? void 0 : newspaperOrdersFormData.length],
        initialData: []
    });
    const publishers = value !== null && value !== void 0 ? value : emptyPublishersArray;
    return (_jsx(NewspapersContext.Provider, Object.assign({ value: publishers }, { children: children })));
}
