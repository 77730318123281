import { useEffect, useState } from 'react';
import * as EventTypes from 'lib/types/events';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureMessage } from 'utils';
export const useCheckInvoiceCreationInitiated = (notice) => {
    const [invoiceCreationInitiated, setInvoiceCreationInitiated] = useState(true);
    const checkInvoiceCreationInitiated = () => {
        // Cloud functions have a timeout of 9 minutes, so setting for 10 minutes to avoid duplicate invoices
        const oldEventThresholdMinutes = 10;
        return getFirebaseContext()
            .eventsRef()
            .where('notice', '==', notice.ref)
            .where('type', 'in', [
            EventTypes.INVOICE_CREATION_INITIATED,
            EventTypes.INVOICE_CREATED,
            EventTypes.INVOICE_CREATION_FAILED
        ])
            .orderBy('createdAt', 'desc')
            .onSnapshot(eventsSnap => {
            if (!eventsSnap.docs.length)
                return setInvoiceCreationInitiated(false);
            const mostRecentEvent = eventsSnap.docs[0].data();
            // createdAt is not always a property of this event!  Need to check for the property
            const mostRecentEventIsOld = mostRecentEvent.createdAt &&
                mostRecentEvent.createdAt.toMillis() <
                    Date.now() - oldEventThresholdMinutes * 60 * 1000;
            if (mostRecentEvent.type === EventTypes.INVOICE_CREATION_INITIATED) {
                if (!mostRecentEventIsOld) {
                    return setInvoiceCreationInitiated(true);
                }
                logAndCaptureMessage('Invoice creation initiated event is old and did not complete!  Allowing user to regenerate invoice...', { noticeId: notice.id });
            }
            setInvoiceCreationInitiated(false);
        });
    };
    useEffect(() => {
        const onSnapshotReference = checkInvoiceCreationInitiated();
        return () => {
            onSnapshotReference();
        };
    }, []);
    return invoiceCreationInitiated;
};
