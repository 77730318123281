// Note: this entire file could be better replace by Joi/Lodash but because we're
// in 'lib' we have to avoid dependencies!
export const isObject = (val) => {
    return !!val && typeof val === 'object';
};
export const isSerializedField = (val, type) => {
    return '__type' in val && val.__type === type;
};
export const hasProperties = (val, ...constraints) => {
    return constraints.every(c => {
        // eslint-disable-next-line valid-typeof
        return c.name in val && typeof val[c.name] === c.type;
    });
};
