var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';
import { OrganizationStatus, OrganizationType, RoleType } from 'lib/enums';
import { APP_ENTRYPOINT, POST_REGISTRATION_ENTRYPOINT } from 'redux/router';
import AuthActions from 'redux/auth';
import ToastActions from 'redux/toast';
import { logAndCaptureException } from 'utils';
import { DEFAULT_TEMPLATE_ID, DEFAULT_TEMPLATE_ID_V2 } from 'lib/constants';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { getFirebaseContext } from 'utils/firebase';
import Firebase from 'EnoticeFirebase';
import { DEFAULT_DEADLINES } from 'lib/utils/deadlines';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ColumnService } from 'lib/services/directory';
import { ENOTICE_ACCOUNT_ID } from '../../../constants';
import PublisherDetailsForm, { DEFAULT_PUBLISHER } from './PublisherDetailsForm';
import { organizationAlreadyExists } from '../helpers';
export default function RegisterOrganizationNewspaper({ userAuth, user }) {
    const dispatch = useAppDispatch();
    const [publisherData, setPublisherData] = useState(DEFAULT_PUBLISHER);
    const [creatingPublisher, setCreatingPublisher] = useState(false);
    const isLdFlagEnabledForCustomHeader = useBooleanFlag(LaunchDarklyFlags.ENABLE_CUSTOMISED_HEADERS);
    const createPublisher = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        setCreatingPublisher(true);
        try {
            const { name } = publisherData;
            const orgAlreadyExists = yield organizationAlreadyExists(OrganizationType.newspaper.value, name);
            if (orgAlreadyExists) {
                dispatch(ToastActions.toastError({
                    headerText: `Newspaper account for ${name} already created`,
                    bodyText: 'If your colleague has already created an account please contact them for an invite'
                }));
                setCreatingPublisher(false);
                return;
            }
            // Create stripe customer id for organization
            const createCustomer = getCreateCustomerFn();
            const customerResult = yield createCustomer({
                name: user.name,
                email: user.email,
                firestoreUID: userAuth.uid
            });
            const newspEnum = OrganizationType.newspaper.value;
            const orgData = Object.assign(Object.assign({ organizationType: newspEnum }, publisherData), { createdBy: userAuth.uid, parent: user.organization ? user.organization : null, deadlines: DEFAULT_DEADLINES, stripeId: customerResult.data.stripeId, shouldAutoGenOrderNums: false, accountId: ENOTICE_ACCOUNT_ID, disabled: true, organizationStatus: OrganizationStatus.in_implementation.value });
            if (user && user.email === 'placement@column.us') {
                orgData.organizationStatus = OrganizationStatus.express.value;
            }
            const templateDocId = isLdFlagEnabledForCustomHeader
                ? DEFAULT_TEMPLATE_ID_V2
                : DEFAULT_TEMPLATE_ID;
            orgData.adTemplate = getFirebaseContext()
                .adTemplatesRef()
                .doc(templateDocId);
            const created = yield getFirebaseContext()
                .organizationsRef()
                .add(orgData);
            const orgSnap = yield created.get();
            const userUpdateObject = {
                activeOrganization: created,
                allowedOrganizations: getFirebaseContext()
                    .fieldValue()
                    .arrayUnion(orgSnap.ref),
                roles: Object.assign(Object.assign({}, user.roles), { [orgSnap.id]: RoleType.admin.value }),
                postRegistrationComplete: true
            };
            if (!user.organization) {
                userUpdateObject.organization = created;
            }
            // set the currently active organization on the user snapshot
            yield getFirebaseContext()
                .usersRef()
                .doc(userAuth.uid)
                .update(userUpdateObject);
            yield ((_a = Firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true));
            dispatch(AuthActions.register());
            // Introduce a small delay, so onOrganizationCreate has time to create default rates
            yield new Promise(res => {
                setTimeout(() => res(), 540);
            });
            setCreatingPublisher(false);
            dispatch(push(APP_ENTRYPOINT));
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to create publisher organization');
            dispatch(ToastActions.toastError({
                headerText: `Newspaper account for ${name} already created`,
                bodyText: 'If your colleague has already created an account please contact them for an invite'
            }));
            setCreatingPublisher(false);
        }
    });
    return (_jsx("div", Object.assign({ className: "max-w-4xl mx-auto mt-24" }, { children: _jsx(PublisherDetailsForm, { onUpdatePublisherData: newPublisherData => setPublisherData(newPublisherData), onGoBack: () => dispatch(push(POST_REGISTRATION_ENTRYPOINT)), onCreatePublisher: createPublisher, publisherData: publisherData, loading: creatingPublisher }) })));
}
