import { useEffect, useState } from 'react';
/**
 * Get the value of a property from an object or its parent. The
 * first non-null and non-undefined value is the winner.
 */
export const useInheritedProperty = (childRef, key) => {
    const [parentRef, setParentRef] = useState();
    const [childVal, setChildVal] = useState();
    const [parentVal, setParentVal] = useState();
    const inheritedValue = childVal !== null && childVal !== undefined ? childVal : parentVal;
    useEffect(() => {
        const childUnsub = childRef === null || childRef === void 0 ? void 0 : childRef.onSnapshot(snap => {
            var _a;
            const data = snap.data();
            setParentRef((_a = data === null || data === void 0 ? void 0 : data.parent) !== null && _a !== void 0 ? _a : undefined);
            if (data) {
                setChildVal(data[key]);
            }
            else {
                setChildVal(undefined);
            }
        });
        const parentUnsub = parentRef === null || parentRef === void 0 ? void 0 : parentRef.onSnapshot(snap => {
            const data = snap.data();
            if (data) {
                setParentVal(data[key]);
            }
            else {
                setParentVal(undefined);
            }
        });
        return () => {
            childUnsub && childUnsub();
            parentUnsub && parentUnsub();
        };
    }, [childRef === null || childRef === void 0 ? void 0 : childRef.id, parentRef === null || parentRef === void 0 ? void 0 : parentRef.id]);
    return inheritedValue;
};
