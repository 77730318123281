import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { unCdnify } from 'lib/helpers';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import Firebase from 'EnoticeFirebase';
export default function TemplateSettingsTab({ template }) {
    const { fullPageTemplate } = template.data();
    return (_jsxs("div", Object.assign({ className: "py-4 grid grid-cols-6 gap-x-6 gap-y-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-6" }, { children: _jsx(LabeledSwitch, { label: "Enable bulk template?", description: "Notices that use this template will paginate using the provided bulk template, instead of the newspaper's default bulk template.", value: !!fullPageTemplate, disabled: true }) })), fullPageTemplate && (_jsx("div", Object.assign({ className: "col-span-6" }, { children: _jsx(FileUploadListItem, { firebaseStoragePath: unCdnify(fullPageTemplate), viewButtonText: "Download", viewButtonIcon: _jsx(DocumentArrowDownIcon, { className: "w-6 h-6" }), storage: Firebase.storage() }) })))] })));
}
