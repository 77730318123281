var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import browserHistory from 'customHistory';
import { delay } from 'lib/helpers';
export const useToggleElementIdForLocationPaths = (elementId, pathMatches, maxWaitTime = 10000 // Default max wait time of 10 seconds
) => {
    const [element, setElement] = useState(null);
    const pollDOMForElement = (id, timeout) => __awaiter(void 0, void 0, void 0, function* () {
        const interval = 250; // Check every 250ms
        let elapsedTime = 0;
        while (elapsedTime < timeout) {
            const element = document.getElementById(id);
            if (element) {
                setElement(element);
                return;
            }
            yield delay(interval);
            elapsedTime += interval;
        }
    });
    const onPathChange = () => {
        if (!element) {
            return;
        }
        const matchPath = pathMatches.some(path => new RegExp(path).test(window.location.href));
        if (matchPath) {
            element.style.display = 'none';
        }
        else {
            element.style.display = 'block';
        }
    };
    useEffect(() => {
        const setupListener = () => __awaiter(void 0, void 0, void 0, function* () {
            yield pollDOMForElement(elementId, maxWaitTime);
            if (!element) {
                return;
            }
            const unlisten = browserHistory.listen(onPathChange);
            // Call onPathChange initially to handle the initial state
            onPathChange();
            return () => {
                unlisten();
            };
        });
        const cleanup = setupListener();
        // Cleanup function to unmount the listener
        return () => {
            if (cleanup instanceof Function) {
                cleanup();
            }
        };
    }, [elementId, pathMatches, maxWaitTime, element]);
};
export default useToggleElementIdForLocationPaths;
