import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
export function Label({ children, id, required, disabled }) {
    const requiredMarkup = required ? (_jsx("span", Object.assign({ className: "text-column-gray-400" }, { children: "*" }))) : null;
    return (_jsxs("label", Object.assign({ id: getLabelId(id), htmlFor: id, className: classNames('text-sm font-medium', {
            'text-column-gray-500': !disabled,
            'text-column-gray-400': disabled
        }) }, { children: [children, " ", requiredMarkup] })));
}
export function getLabelId(id) {
    return `${id}-label`;
}
