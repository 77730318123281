import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SHOW_ALL_OPTION_VALUE } from './filters/helpers';
import { ConfirmationStatusFilter } from './filters/ConfirmationStatusFilter';
import { FilingTypeFilter } from './filters/FilingTypeFilter';
import { PublicationDateFilter } from './filters/PublicationDateFilter';
import { VerificationFilter } from './filters/VerificationFilter';
import { CustomerTypeFilter } from './filters/CustomerTypeFilter';
export default function AdsTableFilterDialog({ filtersToShow, confirmationStatuses, publicationCategories, rowFilter, editedRowFilter, setEditedRowFilter }) {
    return (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "uppercase text-column-gray-300 text-xs font-bold" }, { children: "Filter by" })), filtersToShow.status && (_jsx(ConfirmationStatusFilter, { confirmationStatuses: confirmationStatuses, editedRowFilter: editedRowFilter, onSelectNewFilterValue: newValue => {
                    if (newValue === SHOW_ALL_OPTION_VALUE) {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { status: undefined }));
                    }
                    else {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { status: newValue }));
                    }
                } })), filtersToShow.filingType && (_jsx(FilingTypeFilter, { publicationCategories: publicationCategories, editedRowFilter: editedRowFilter, onSelectNewFilterValue: newValue => {
                    if (newValue === SHOW_ALL_OPTION_VALUE) {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { filingType: undefined }));
                    }
                    else {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { filingType: newValue }));
                    }
                } })), filtersToShow.customerType && (_jsx(CustomerTypeFilter, { editedRowFilter: editedRowFilter, onSelectNewFilterValue: newValue => {
                    if (newValue === SHOW_ALL_OPTION_VALUE) {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { customerType: undefined }));
                    }
                    else {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { customerType: newValue }));
                    }
                } })), filtersToShow.verification && (_jsx(VerificationFilter, { editedRowFilter: editedRowFilter, onSelectNewFilterValue: newValue => {
                    if (newValue === SHOW_ALL_OPTION_VALUE) {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { verification: undefined }));
                    }
                    else {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { verification: newValue }));
                    }
                } })), filtersToShow.publicationDate && (_jsx(PublicationDateFilter, { rowFilter: rowFilter, editedRowFilter: editedRowFilter, setEditedRowFilter: setEditedRowFilter }))] }));
}
