import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { Label, getLabelId } from '../Label';
export function InputAccessories({ id, labelText, children, noteText, errorText, required, disabled }) {
    const labelMarkup = labelText ? (_jsx("div", Object.assign({ className: classNames({
            'pb-2': !!labelText
        }) }, { children: _jsx(Label, Object.assign({ id: id, required: required, disabled: disabled }, { children: labelText })) }))) : null;
    const noteTextMarkup = noteText ? (_jsx(InputNote, Object.assign({ id: id }, { children: noteText }))) : null;
    const errorMarkup = (errorText === null || errorText === void 0 ? void 0 : errorText.trim()) ? (_jsx(InlineError, Object.assign({ id: id }, { children: errorText }))) : null;
    return (_jsxs("div", { children: [labelMarkup, children, noteTextMarkup, errorMarkup] }));
}
export function InputNote({ id, children }) {
    return (_jsx("div", Object.assign({ id: getNoteId(id), role: "note", className: "pt-2 text-xs text-column-gray-400" }, { children: children })));
}
function InlineError({ id, children }) {
    return (_jsx("div", Object.assign({ id: getErrorId(id), role: "alert", className: "pt-2 text-xs text-column-red-600" }, { children: children })));
}
export function getAccessibilityLinks({ id, noteText, errorMessage }) {
    const accessibilityLinks = {};
    accessibilityLinks['aria-labelledby'] = getLabelId(id);
    if (noteText) {
        accessibilityLinks['aria-describedby'] = getNoteId(id);
    }
    if (errorMessage) {
        accessibilityLinks['aria-errormessage'] = getErrorId(id);
        accessibilityLinks['aria-invalid'] = true;
    }
    return accessibilityLinks;
}
export function getErrorId(id) {
    return `${id}-error`;
}
export function getNoteId(id) {
    return `${id}-note`;
}
