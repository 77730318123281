import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import LinkTo from 'components/LinkTo';
import moment from 'moment';
/**
 * This frontend component is likely only necessary for initial proof of concepts, but still wanted
 * to follow best practices and extract this to a unique component (which also means being able to
 * use the `useFirestoreSnapshot` hook).
 */
export function SyncErrorCard({ event, timezone }) {
    const notice = useFirestoreSnapshot(event.data().notice);
    const { createdAt, data: { syncMessage } } = event.data();
    return (_jsxs("div", Object.assign({ className: "pl-8 pr-2.5 py-5 flex border-b" }, { children: [_jsx("div", { children: _jsx("div", Object.assign({ className: "w-12 h-12 flex justify-center items-center bg-column-red-50 rounded-full" }, { children: _jsx(ArrowPathIcon, { className: "w-6 h-6 text-column-red-500" }) })) }), _jsxs("div", Object.assign({ className: "pl-4 flex-col" }, { children: [_jsx("div", { children: (notice === null || notice === void 0 ? void 0 : notice.data().referenceId) ? (_jsx("div", Object.assign({ className: "text-sm" }, { children: _jsxs("p", Object.assign({ className: "text-column-gray-500 font-semibold" }, { children: [notice === null || notice === void 0 ? void 0 : notice.data().referenceId, ' ', _jsx("span", Object.assign({ className: "text-column-gray-400 font-medium" }, { children: "failed to sync" }))] })) }))) : (_jsx(LoadingSpinner, {})) }, event.id), _jsx("div", Object.assign({ className: "pt-2 text-sm text-column-gray-500" }, { children: _jsx("p", { children: syncMessage }) })), _jsx("div", Object.assign({ className: "text-xs text-column-gray-400 font-medium pt-2" }, { children: _jsxs("p", { children: [moment(createdAt.toDate())
                                    .tz(timezone)
                                    .format('DD MMMM, YYYY [at] hh:mm A'), ' ', `${moment.tz(timezone).format('z')}`] }) })), _jsx("div", Object.assign({ className: "flex pt-5 justify-end" }, { children: _jsx(LinkTo, Object.assign({ href: `/notice/${notice === null || notice === void 0 ? void 0 : notice.id}` }, { children: _jsx(ColumnButton, { id: "view-notice-button", buttonText: 'View notice', secondary: true, type: "button" }) })) }))] }))] })));
}
