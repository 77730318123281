import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { State } from 'lib/enums';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { TextField } from 'lib/components/TextField';
import classNames from 'classnames';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { isMemberOfOrganization } from 'lib/utils/users';
import { exists } from 'lib/types';
export const textInputClasses = 'form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5';
export const checkboxInputClasses = 'form-checkbox cursor-pointer h-4 w-4 transition duration-150 ease-in-out text-gray-700 mr-3';
export const newPlaceholders = {
    phoneNumber: 'Enter phone number',
    address1: `Enter address 1`,
    address2: `Enter address 2`,
    city: 'Enter city',
    zip: 'Enter zip code',
    state: 'Select state'
};
export const oldPlaceholders = {
    phoneNumber: 'Phone Number',
    address1: 'Address line 1 *',
    address2: 'Address line 2',
    city: 'City *',
    zip: 'Zip code *',
    state: 'State *'
};
export const newLabels = {
    phoneNumber: 'Phone number',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    zip: 'Zip code',
    state: 'State'
};
export const oldLabels = {
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: ''
};
export function RecipientInfoForm({ firstName, setFirstName, lastName, setLastName, organizationName, setOrganizationName }) {
    return (_jsxs("div", Object.assign({ className: "py-2 flex flex-col space-y-2" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex space-x-2" }, { children: [_jsx("div", Object.assign({ className: "w-1/2" }, { children: _jsx("input", { id: "field-first-name", className: textInputClasses, type: "text", autoComplete: "given-name", placeholder: "First name *", value: firstName, onChange: e => setFirstName(e.target.value) }) })), _jsx("div", Object.assign({ className: "w-1/2" }, { children: _jsx("input", { id: "field-last-name", className: textInputClasses, type: "text", autoComplete: "family-name", placeholder: "Last name *", value: lastName, onChange: e => setLastName(e.target.value) }) }))] })), _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx("input", { id: "field-organization", className: textInputClasses, type: "text", placeholder: "Organization", value: organizationName, onChange: e => setOrganizationName(e.target.value) }) }))] })));
}
export function RecipientEmailForm({ email, setEmail }) {
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    return (_jsx("div", Object.assign({ id: "form-recipient-email", className: classNames('text-column-gray-500', {
            'mt-6': enableNewPlacementFlow,
            'mt-2': !enableNewPlacementFlow
        }) }, { children: _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(TextField, { id: "field-email", type: "email", labelText: enableNewPlacementFlow ? 'Email address' : '', placeholder: "Email address *", required: enableNewPlacementFlow, value: email, size: enableNewPlacementFlow ? 'standard' : 'small', onChange: email => setEmail(email) }) })) })));
}
export function RecipientMailingForm({ zip, setZip, addressLine1, setAddressLine1, addressLine2, setAddressLine2, city, setCity, state, setState }) {
    var _a;
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const placeholders = enableNewPlacementFlow
        ? newPlaceholders
        : oldPlaceholders;
    const labels = enableNewPlacementFlow ? newLabels : oldLabels;
    return (_jsxs("div", Object.assign({ id: "form-recipient-mailing", className: classNames('text-column-gray-500', {
            'space-y-6 mt-6': enableNewPlacementFlow,
            'space-y-2 mt-2': !enableNewPlacementFlow
        }) }, { children: [_jsxs("div", Object.assign({ className: classNames('flex w-full', {
                    'space-x-2': !enableNewPlacementFlow,
                    'space-x-6': enableNewPlacementFlow
                }) }, { children: [_jsx("div", Object.assign({ className: "w-1/2" }, { children: _jsx(TextField, { id: "field-address-line-1", type: "text", size: enableNewPlacementFlow ? 'standard' : 'small', placeholder: placeholders.address1, value: addressLine1, required: enableNewPlacementFlow, labelText: labels.address1, onChange: addressLine1 => setAddressLine1(addressLine1) }) })), _jsx("div", Object.assign({ className: "w-1/2" }, { children: _jsx(TextField, { id: "field-address-line-2", type: "text", size: enableNewPlacementFlow ? 'standard' : 'small', placeholder: placeholders.address2, labelText: labels.address2, value: addressLine2, onChange: addressLine2 => setAddressLine2(addressLine2) }) }))] })), _jsxs("div", Object.assign({ className: classNames('flex w-full', {
                    'space-x-2': !enableNewPlacementFlow,
                    'space-x-6': enableNewPlacementFlow
                }) }, { children: [_jsx("div", Object.assign({ className: "w-1/3" }, { children: _jsx(TextField, { id: "field-city", type: "text", size: enableNewPlacementFlow ? 'standard' : 'small', placeholder: placeholders.city, labelText: labels.city, value: city, required: enableNewPlacementFlow, onChange: city => setCity(city) }) })), _jsx("div", Object.assign({ className: "w-1/3" }, { children: _jsx(ColumnSelect, { id: "field-state", labelText: labels.state, placeholder: placeholders.state, onChange: state => {
                                var _a;
                                const stateString = (_a = State.by_value(state)) === null || _a === void 0 ? void 0 : _a.label;
                                if (stateString) {
                                    setState(stateString);
                                }
                            }, allowUndefined: true, options: State.items().map((state) => ({
                                value: String(state.value),
                                label: state.label
                            })), size: enableNewPlacementFlow ? 'standard' : 'small', value: String((_a = State.by_label(state)) === null || _a === void 0 ? void 0 : _a.value) || '' }) })), _jsx("div", Object.assign({ className: "w-1/3" }, { children: _jsx(TextField, { id: "field-zip", type: "postal-code", size: enableNewPlacementFlow ? 'standard' : 'small', placeholder: placeholders.zip, labelText: labels.zip, value: zip, required: enableNewPlacementFlow, onChange: zip => setZip(zip) }) }))] }))] })));
}
export function EmailOrMailToggle({ sendToEmail, setSendToEmail }) {
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const isPublisher = useAppSelector(selectIsPublisher);
    const labels = {
        new: {
            email: 'Send the invoice by email',
            mail: 'Send the invoice by mail'
        },
        oldAdvertiser: {
            email: 'Email',
            mail: 'Mailing address'
        },
        oldPublisher: {
            email: 'Send the invoice to the customer by email',
            mail: 'Send the invoice to the customer by mail'
        }
    };
    return (_jsx(_Fragment, { children: enableNewPlacementFlow ? (_jsxs("div", Object.assign({ className: "flex flex-row space-x-6" }, { children: [_jsx(CheckboxInput, { id: "publisher-send-to-email", labelText: labels.new.email, checked: sendToEmail, type: "radio", onChange: () => {
                        setSendToEmail(true);
                    } }), _jsx(CheckboxInput, { id: "publisher-send-to-someone-else", labelText: labels.new.mail, checked: !sendToEmail, type: "radio", onChange: () => {
                        setSendToEmail(false);
                    } })] }))) : isPublisher ? (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("input", { id: "publisher-send-to-email", checked: sendToEmail, type: "checkbox", className: checkboxInputClasses, onChange: () => {
                                setSendToEmail(true);
                            } }), _jsx("label", Object.assign({ htmlFor: "publisher-send-to-email", "aria-controls": "publisher-send-to-email" }, { children: labels.oldPublisher.email }))] })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("input", { id: "publisher-send-to-someone-else", checked: !sendToEmail, type: "checkbox", className: checkboxInputClasses, onChange: () => {
                                setSendToEmail(false);
                            } }), _jsx("label", Object.assign({ htmlFor: "publisher-send-to-someone-else", "aria-controls": "publisher-send-to-someone-else" }, { children: labels.oldPublisher.mail }))] }))] }))) : (_jsxs("div", Object.assign({ className: "pt-2 flex flex-row" }, { children: [_jsxs("div", Object.assign({ className: "w-1/2 flex items-center" }, { children: [_jsx("input", { checked: sendToEmail, onChange: () => {
                                setSendToEmail(true);
                            }, id: "checkbox-email", type: "checkbox", className: checkboxInputClasses }), _jsx("label", Object.assign({ htmlFor: "checkbox-email", "aria-controls": "checkbox-email" }, { children: labels.oldAdvertiser.email }))] })), _jsxs("div", Object.assign({ className: "w-1/2 flex items-center" }, { children: [_jsx("input", { checked: !sendToEmail, onChange: () => {
                                setSendToEmail(false);
                            }, id: "checkbox-mailing-address", type: "checkbox", className: checkboxInputClasses }), _jsx("label", Object.assign({ htmlFor: "checkbox-mailing-address", "aria-controls": "checkbox-mailing-address" }, { children: labels.oldAdvertiser.mail }))] }))] }))) }));
}
export function EmailOrMailForm({ sendToEmail, email, setEmail, zip, setZip, addressLine1, setAddressLine1, addressLine2, setAddressLine2, city, setCity, state, setState, firstName, setFirstName, lastName, setLastName, organizationName, setOrganizationName }) {
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const isPublisher = useAppSelector(selectIsPublisher);
    return (_jsx("div", { children: sendToEmail ? (!enableNewPlacementFlow && isPublisher ? (_jsx(_Fragment, {})) : (_jsx(RecipientEmailForm, { email: email, setEmail: setEmail }))) : (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [!enableNewPlacementFlow && (_jsxs(_Fragment, { children: [isPublisher && (_jsxs("div", Object.assign({ className: "mt-4 font-normal text-sm text-gray-700 uppercase" }, { children: ["Recipient Info", _jsx(RecipientInfoForm, { firstName: firstName, setFirstName: setFirstName, lastName: lastName, setLastName: setLastName, organizationName: organizationName, setOrganizationName: setOrganizationName })] }))), _jsx("div", Object.assign({ className: "mt-4 font-normal text-sm text-gray-700 uppercase" }, { children: "Recipient Address" }))] })), _jsx(RecipientMailingForm, { zip: zip, setZip: setZip, addressLine1: addressLine1, setAddressLine1: setAddressLine1, addressLine2: addressLine2, setAddressLine2: setAddressLine2, city: city, setCity: setCity, state: state, setState: setState })] }))) }));
}
export function RecipientToggle({ sendToSomeoneElse, setSendToSomeoneElse }) {
    const user = useAppSelector(selectUser);
    const isAdvertiserOrgMember = user && exists(user) && isMemberOfOrganization(user, undefined);
    const onChange = () => {
        setSendToSomeoneElse(!sendToSomeoneElse);
    };
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("input", { checked: !sendToSomeoneElse, onChange: onChange, id: "checkbox-send-to-me", type: "checkbox", className: checkboxInputClasses }), _jsx("label", Object.assign({ htmlFor: "checkbox-send-to-me", "aria-controls": "checkbox-send-to-me" }, { children: isAdvertiserOrgMember
                            ? 'Send the invoice to my organization'
                            : 'Send the invoice to me' }))] })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("input", { checked: sendToSomeoneElse, onChange: onChange, id: "checkbox-send-to-someone-else", type: "checkbox", className: checkboxInputClasses }), _jsx("label", Object.assign({ htmlFor: "checkbox-send-to-someone-else", "aria-controls": "checkbox-send-to-someone-else" }, { children: "Send the invoice to someone else" }))] }))] }));
}
