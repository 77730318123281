import { useEffect, useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from '../../utils/errors';
export function useFirestoreQueryListener(queryRef, dependencies, config) {
    const [querySnapshot, setQuerySnapshot] = useState(null);
    useEffect(() => {
        if (!queryRef) {
            return;
        }
        const unsubscribe = queryRef.onSnapshot(setQuerySnapshot, err => getErrorReporter().logAndCaptureError(ColumnService.DATABASE, err, (config === null || config === void 0 ? void 0 : config.message) || 'Error listening to query snapshot', config === null || config === void 0 ? void 0 : config.tags));
        return unsubscribe;
    }, dependencies);
    return querySnapshot;
}
