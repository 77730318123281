import { jsx as _jsx } from "react/jsx-runtime";
import { CheckIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { Tooltip } from 'lib/components/Tooltip';
import { getDeadlineString } from 'lib/utils/deadlines';
export function PublicationDateStatusBadge({ publicationMoment, notice, newspaper, isComplete }) {
    const { deadlines, deadlineOverrides = {} } = newspaper.data();
    const publicationDeadlineString = getDeadlineString(deadlines, deadlineOverrides, publicationMoment.toDate(), notice.data(), newspaper, undefined);
    const helpText = publicationDeadlineString
        ? `Deadline: ${publicationDeadlineString}`
        : '';
    return (_jsx(Tooltip, Object.assign({ helpText: helpText }, { children: _jsx("div", Object.assign({ className: "cursor-pointer" }, { children: _jsx(Badge, Object.assign({ status: isComplete ? 'success' : undefined, startIcon: isComplete ? _jsx(CheckIcon, { className: "w-4 h-4" }) : undefined, size: "lg" }, { children: publicationMoment.format('MMM D, YYYY') })) })) })));
}
