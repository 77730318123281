import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import FuneralHomeSearchResult from './FuneralHomeSearchResult';
function FuneralHomeSearchResults({ searchResults, selection, onSelect, isLoading }) {
    if (isLoading) {
        return _jsx(LoadingSpinner, {});
    }
    if (!searchResults)
        return _jsx(Alert, { id: "search-failed-alert", title: "Search failed" });
    return (_jsx("div", Object.assign({ className: "grid gap-2" }, { children: searchResults.map((advertiser, i) => (_jsx(FuneralHomeSearchResult, { value: advertiser, checked: (selection === null || selection === void 0 ? void 0 : selection.id) === advertiser.ref.id, onSelect: onSelect }, i))) })));
}
export default FuneralHomeSearchResults;
