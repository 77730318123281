import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
function YesNoQuestionInput({ question, value, onChange }) {
    const yesNoValue = value === undefined ? undefined : value ? 'yes' : 'no';
    return (_jsx(ColumnSelect, { id: `yesno-${question.varName}`, allowUndefined: true, options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' }
        ], value: yesNoValue, onChange: v => onChange({ varName: question.varName, value: v === 'yes' }), labelText: question.prompt, required: true }));
}
export default YesNoQuestionInput;
