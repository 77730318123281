import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getWordsFromNumber } from 'lib/helpers';
import PlacementActions, { selectColumnCount } from 'redux/placement';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { selectValidColumnCounts } from './placeScrollSelectors';
export function ColumnCountField({ disabled, newspaper }) {
    const dispatch = useAppDispatch();
    const currentColumnCount = useAppSelector(selectColumnCount);
    const validColumnCounts = useAppSelector(state => selectValidColumnCounts(state, newspaper));
    const selectedColumnCount = validColumnCounts.find(columnCount => columnCount === currentColumnCount) ||
        validColumnCounts[0];
    return (_jsx(ColumnSelect, { id: "select-column-count", labelText: "What's the column width for this notice?", options: validColumnCounts.map(count => ({
            value: String(count),
            label: getWordsFromNumber(count)
        })), disabled: disabled, value: String(selectedColumnCount), onChange: newColumnCount => dispatch(PlacementActions.setColumns(Number(newColumnCount))) }));
}
