var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import { InviteStatus, RoleType } from 'lib/enums';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import InviteActionModalHeader from 'components/invitesComponent/InviteActionModalHeader';
import { exists } from 'lib/types';
import { safeStringify } from 'lib/utils/stringify';
import { getWordsFromNumber } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';
import { acceptRequestHelper, declineRequestHelper, transformRequestsToActionCard } from './helpers';
import { getFirebaseContext } from '../../../utils/firebase';
function JoinOrganizationRequestModal({ user, activeOrganization }) {
    const ctx = getFirebaseContext();
    const [requests, setRequests] = useState();
    const [transformedRequests, setTransformedRequests] = useState();
    const [showModal, setShowModal] = useState(false);
    const getRequests = () => {
        var _a;
        if (exists(activeOrganization) &&
            ((_a = user.data().roles) === null || _a === void 0 ? void 0 : _a[activeOrganization.id]) === RoleType.admin.value) {
            return ctx
                .joinRequestsRef()
                .where('organization', '==', activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref)
                .where('status', '==', InviteStatus.pending.value)
                .onSnapshot(result => {
                setRequests(result.docs);
            });
        }
        return () => { };
    };
    const transformRequestsData = () => __awaiter(this, void 0, void 0, function* () {
        if (requests === null || requests === void 0 ? void 0 : requests.length) {
            const transformedRequests = yield transformRequestsToActionCard(requests, ctx);
            setTransformedRequests(transformedRequests);
            setShowModal(!!requests.length);
        }
    });
    const snoozedAllRequests = () => __awaiter(this, void 0, void 0, function* () {
        if (!(transformedRequests === null || transformedRequests === void 0 ? void 0 : transformedRequests.length))
            return;
        try {
            yield Promise.all(transformedRequests.map((request) => __awaiter(this, void 0, void 0, function* () {
                const joinReq = request.userRequest;
                yield joinReq.ref.update({
                    status: InviteStatus.snoozed.value
                });
            })));
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error snoozing all requests', {
                userId: user.id
            });
        }
        setTimeout(() => {
            setShowModal(false);
        }, 300);
    });
    const acceptRequest = (joinRequest) => __awaiter(this, void 0, void 0, function* () {
        if (!(transformedRequests === null || transformedRequests === void 0 ? void 0 : transformedRequests.length))
            return;
        yield acceptRequestHelper(ctx, [joinRequest]);
    });
    const acceptAllRequests = () => __awaiter(this, void 0, void 0, function* () {
        if (!(transformedRequests === null || transformedRequests === void 0 ? void 0 : transformedRequests.length))
            return;
        yield acceptRequestHelper(ctx, transformedRequests);
        setShowModal(false);
    });
    const declineRequest = (joinRequest) => __awaiter(this, void 0, void 0, function* () {
        yield declineRequestHelper([joinRequest]);
    });
    const updateUserRoles = (roleValue, index) => {
        if (transformedRequests) {
            const currTransformRequests = [...transformedRequests];
            currTransformRequests[index].role = RoleType.by_label(roleValue).value;
            setTransformedRequests(currTransformRequests);
        }
    };
    useEffect(() => {
        if (exists(activeOrganization)) {
            const requestsUnsub = getRequests();
            return () => {
                requestsUnsub && requestsUnsub();
            };
        }
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    useEffect(() => {
        if (requests === null || requests === void 0 ? void 0 : requests.length) {
            void transformRequestsData();
        }
        else {
            setShowModal(false);
        }
    }, [safeStringify(requests)]);
    if (!showModal)
        return null;
    return (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setShowModal(false), tertiaryButtonText: 'Skip for now', primaryButtonText: 'Accept all', backgroundStyle: 'bg-column-gray-25', showLoadingSpinner: true, onSubmit: acceptAllRequests, noExitOutsideModal: true, overrideTertiaryClose: snoozedAllRequests }, { children: _jsxs("div", Object.assign({ id: "joinrequest-modal-container", className: "text-center" }, { children: [_jsx("div", Object.assign({ style: {
                        clipPath: 'circle()'
                    }, id: "joinrequest-modal-icon", className: "flex justify-center mb-6 h-20 w-20 inline-flex overflow-hidden" }, { children: _jsx("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/cfcf.171eb-giphy%20(16).gif", style: {
                            transform: 'scale(1.4)'
                        } }) })), transformedRequests && (transformedRequests === null || transformedRequests === void 0 ? void 0 : transformedRequests.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(InviteActionModalHeader, { id: "requests-exist-header", title: "You have new team invite requests", subtitle: `${transformedRequests.length > 20
                                ? '20+'
                                : getWordsFromNumber(transformedRequests.length)} ${transformedRequests.length > 1 ? 'users have' : 'user has'} requested to join ${activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data().name} on Column! Do you want to add them to your organization?` }), _jsx("div", Object.assign({ id: "requests-exist-modal-body", className: "pb-4" }, { children: transformedRequests === null || transformedRequests === void 0 ? void 0 : transformedRequests.map((request, index) => {
                                return (_jsx("div", { children: _jsx(InviteActionCard, { request: request, index: index, type: 'request', className: 'rounded-md border my-6', organization: activeOrganization, updateUserRole: roleValue => updateUserRoles(roleValue, index), onAcceptClick: () => acceptRequest(request), onDeclineClick: () => declineRequest(request) }) }, `user-request-${request.userRequest.id}`));
                            }) }))] }))] })) })));
}
export default JoinOrganizationRequestModal;
