import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { getFirebaseContext } from 'utils/firebase';
import { isDisplay } from 'lib/notice/display';
import { Badge } from 'lib/components/Badge';
export function NoticesOverviewRow(props) {
    const { notice } = props;
    const noticeSummary = notice.data().referenceId ||
        notice.data().headerText ||
        notice.data().confirmedHtml;
    const isCancelled = !!notice.data().cancelledBy;
    const noticeModel = getModelFromSnapshot(UserNoticeModel, getFirebaseContext(), notice);
    const noticeConfirmedText = isCancelled
        ? 'Cancelled'
        : noticeModel.isConfirmed
            ? 'Confirmed'
            : 'Not Confirmed';
    const invoicedText = notice.data().invoice ? 'Invoiced' : 'Not Invoiced';
    // center this icon
    const optionClasses = 'flex items-center justify-center gap-2 cursor-pointer rounded p-3 hover:bg-column-primary-50 hover:text-column-primary-500 focus:outline-none transition ease-in duration-100';
    const onClick = () => {
        window.open(`/notice/${notice.id}`, '_blank');
    };
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-1 my-1 items-start" }, { children: [noticeSummary, isDisplay(notice) && _jsx(Badge, Object.assign({ status: 'warning' }, { children: "Display Ad" }))] })) }), _jsx("td", { children: notice.data().customId }), _jsx("td", { children: noticeConfirmedText }), _jsx("td", { children: invoicedText }), _jsx("td", { children: _jsx("div", Object.assign({ className: optionClasses, onClick: onClick }, { children: _jsx(ArrowUpRightIcon, { className: "w-4 h-4" }) })) })] }));
}
