var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { Product } from 'lib/enums';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { getModelFromSnapshot } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { Alert } from 'lib/components/Alert';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import LoadingState from 'components/LoadingState';
import { SiteSettingForm } from './SiteSettingForm';
import { createSiteSetting, updateSiteSetting } from './writeSiteSettings';
function SiteSettings({ newspaper, productLine, loadedSetting }) {
    const [siteSettingModel, setSiteSettingModel] = useState(loadedSetting);
    const [loading, setLoading] = useState(false);
    const [saveError, setSaveError] = useState('');
    const handleSiteSettingChange = (siteSettingData) => __awaiter(this, void 0, void 0, function* () {
        setSaveError('');
        setLoading(true);
        if (siteSettingModel) {
            const { response: updatedModel, error: updateError } = yield updateSiteSetting(newspaper.id, siteSettingModel, siteSettingData);
            if (updateError !== null) {
                setSaveError(updateError.message);
                setLoading(false);
                return false;
            }
            setSiteSettingModel(updatedModel);
            setLoading(false);
            return true;
        }
        const { response: newSiteSetting, error: createError } = yield createSiteSetting(newspaper.id, siteSettingData);
        if (createError !== null) {
            setSaveError(createError.message);
            setLoading(false);
            return false;
        }
        setSiteSettingModel(newSiteSetting);
        setLoading(false);
        return true;
    });
    return (_jsxs(_Fragment, { children: [saveError && (_jsx(Alert, { id: "save-error", status: "error", description: saveError })), _jsx(SiteSettingForm, { onSave: handleSiteSettingChange, url: siteSettingModel === null || siteSettingModel === void 0 ? void 0 : siteSettingModel.url, siteSetting: siteSettingModel === null || siteSettingModel === void 0 ? void 0 : siteSettingModel.modelData, productLine: productLine, loading: loading })] }));
}
function ProductSiteSettings({ activeOrganization, productLine }) {
    const ctx = getFirebaseContext();
    const organizationModel = getModelFromSnapshot(OrganizationModel, ctx, activeOrganization);
    const { value: productSiteSetting, isLoading: productSiteSettingLoading, isError: productSiteSettingLoadFailed } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () { return yield organizationModel.maybeFetchProductSiteSetting(productLine); }),
        initialData: undefined,
        dependencies: [productLine]
    });
    if (productLine === Product.Notice) {
        logAndCaptureCriticalError(ColumnService.DISPLAY_SITES, new Error('Invalid product line'), 'Attempted to load a notice product from ProductLineFilingTypeSettings', {
            organizationId: activeOrganization.id,
            productLine
        });
        return (_jsx(Alert, { id: "invalid-product", description: "Notices are not supported at this time." }));
    }
    if (productSiteSettingLoading) {
        return _jsx(LoadingState, {});
    }
    if (productSiteSettingLoadFailed) {
        return (_jsx(Alert, { id: "product-site-setting-load-failed", description: "Failed to load product site settings." }));
    }
    return (_jsx(SiteSettings, { newspaper: organizationModel, productLine: productLine, loadedSetting: productSiteSetting }));
}
export default ProductSiteSettings;
