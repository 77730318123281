var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiPost } from 'api/typed';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { logInfo } from 'utils/logger';
export const sendCancelOrderRequest = (cancellationReason, order, anonymousUserAuthInfo) => __awaiter(void 0, void 0, void 0, function* () {
    const { product } = order.modelData;
    try {
        const reqBody = {
            orderId: order.id,
            cancellationReason,
            anonymousUserAuthInfo: anonymousUserAuthInfo !== null && anonymousUserAuthInfo !== void 0 ? anonymousUserAuthInfo : null
        };
        const logData = {
            orderId: order.id,
            cancellationReason,
            product
        };
        const result = yield apiPost('orders/cancel', reqBody);
        if (!result.success) {
            logInfo('Failed to cancel order', Object.assign({ error: result.error }, logData));
            throw result.error;
        }
        logInfo('Successfully canceled order', logData);
        return wrapSuccess(undefined);
    }
    catch (err) {
        logAndCaptureException(ColumnService.OBITS, err, 'Error cancelling order', {
            orderId: order.id
        });
        return wrapError(err);
    }
});
