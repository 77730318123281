import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from 'lib/utils/errors';
import { useState, useEffect } from 'react';
export function useFirestoreQueryListeners(queries, dependencies, config) {
    const [querySnapshots, setQuerySnapshots] = useState(new Array(queries.length).fill(null));
    useEffect(() => {
        if (!queries.length) {
            return;
        }
        const unsubscribes = queries.map((query, index) => {
            return query.onSnapshot(snapshot => setQuerySnapshots(prevQuerySnapshots => {
                const newQuerySnapshots = [...prevQuerySnapshots];
                newQuerySnapshots[index] = snapshot;
                return newQuerySnapshots;
            }), err => getErrorReporter().logAndCaptureError(ColumnService.DATABASE, err, (config === null || config === void 0 ? void 0 : config.message) || 'Error listening to query snapshot', config === null || config === void 0 ? void 0 : config.tags));
        });
        return () => {
            unsubscribes.forEach(unsubscribe => unsubscribe());
        };
    }, dependencies);
    return querySnapshots;
}
