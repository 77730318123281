/**
 * The standard btoa() function can only encode strings which contain all characters in the Latin1
 * alphabet. This version can encode arbitrary unicode.
 */
export const madlibBtoa = (val) => {
    return btoa(unescape(encodeURIComponent(val)));
};
/**
 * The inverse of madlibBtoa
 */
export const madlibAtob = (val) => {
    return decodeURIComponent(escape(atob(val)));
};
