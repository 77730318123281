var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useAppDispatch } from 'redux/hooks';
import { getBulkInvoiceServicesStatus } from 'layouts/appLayout/appLayoutHelpers';
import LoadingState from 'components/LoadingState';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import InvoiceTable from './InvoicesTable';
function Payments({ user, organization }) {
    const [usesBulkInvoiceV2, setUsesBulkInvoiceV2] = useState();
    useEffect(() => {
        const getBulkInvoicesV2Status = () => __awaiter(this, void 0, void 0, function* () {
            const usesBulkInvoicesV2 = yield getBulkInvoiceServicesStatus(user);
            setUsesBulkInvoiceV2(usesBulkInvoicesV2);
        });
        void getBulkInvoicesV2Status();
    }, [user === null || user === void 0 ? void 0 : user.id]);
    const showInvoicesTabToAllUsers = getBooleanFlag(LaunchDarklyFlags.SHOW_ALL_INVOICES_IN_INVOICES_TABLE, true);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (usesBulkInvoiceV2 === false && !showInvoicesTabToAllUsers) {
            dispatch(push('/'));
        }
    }, [usesBulkInvoiceV2, showInvoicesTabToAllUsers]);
    if (typeof usesBulkInvoiceV2 === 'boolean') {
        return _jsx(InvoiceTable, Object.assign({}, { user, organization, usesBulkInvoiceV2 }));
    }
    return _jsx(LoadingState, {});
}
export default Payments;
