import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { Form } from 'lib/components/Form';
import { GridInput } from 'lib/components/Card/Grid';
export const MIN_LAST_NAME_LENGTH = 2;
const VALIDATION_ERROR_TEXT = {
    lastNameError: `Must be at least ${MIN_LAST_NAME_LENGTH} characters`,
    email: 'Invalid email address.',
    max: 'Must not exceed 30 characters.',
    passwordLengthError: 'Password must be at least 9 characters.',
    weakPasswordError: 'Password must contain letters, numbers, and symbols.',
    passwordMismatch: 'Passwords must match.',
    validate_all: 'Please ensure all fields are complete.'
};
const STRONG_PASSWORD_PATTERN = /.*([0-9]|[!"#$%&\\'()*+,-./:;<=>?@^_`{|}~]).*$/;
export default function RegisterUserForm({ onChangeRegistrationData, registrationData, submitting, onSubmit, invite }) {
    return (_jsx(Form, Object.assign({ onSubmit: onSubmit }, { children: _jsxs("div", Object.assign({ className: "flex flex-col sm:grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8" }, { children: [_jsx(GridInput, { children: _jsx(TextField, { required: true, id: "first_name", labelText: "First Name", value: registrationData.firstName, onChange: newValue => onChangeRegistrationData(Object.assign(Object.assign({}, registrationData), { firstName: newValue })) }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "last_name", required: true, labelText: "Last Name", value: registrationData.lastName, onChange: newValue => onChangeRegistrationData(Object.assign(Object.assign({}, registrationData), { lastName: newValue })), errorText: registrationData.lastName.length > 0 &&
                            registrationData.lastName.length < MIN_LAST_NAME_LENGTH
                            ? VALIDATION_ERROR_TEXT.lastNameError
                            : '' }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "email", required: true, labelText: "Email", value: registrationData.email, onChange: newValue => onChangeRegistrationData(Object.assign(Object.assign({}, registrationData), { email: newValue })), disabled: !!invite, type: "email" }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { required: true, id: "password", labelText: "Password", value: registrationData.password, type: "password", onChange: newValue => onChangeRegistrationData(Object.assign(Object.assign({}, registrationData), { password: newValue })), errorText: registrationData.password &&
                            !STRONG_PASSWORD_PATTERN.test(registrationData.password)
                            ? VALIDATION_ERROR_TEXT.weakPasswordError
                            : '' }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { required: true, id: "confirm_password", labelText: "Confirm Password", value: registrationData.confirmPassword, type: "password", onChange: newValue => onChangeRegistrationData(Object.assign(Object.assign({}, registrationData), { confirmPassword: newValue })), errorText: registrationData.confirmPassword &&
                            registrationData.password !== registrationData.confirmPassword
                            ? VALIDATION_ERROR_TEXT.passwordMismatch
                            : '' }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx("div", Object.assign({ className: "mt-6" }, { children: _jsx(ColumnButton, { size: "lg", buttonText: "Create Account", loading: submitting, fullWidth: true, primary: true, type: "submit", id: "submit" }) })) }))] })) })));
}
