import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { InputAccessories } from 'lib/components/InputAccessories';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { MadlibModalMultipleChoiceCard } from './components/MadlibModalMulitpleChoiceCard';
const makeHandlebar = (tag, props) => {
    const propsJoined = Object.entries(props)
        .filter(e => !!e[1])
        .map(([k, v]) => {
        if (v === null || v === void 0 ? void 0 : v.includes('"')) {
            return `${k}='${v}'`;
        }
        return `${k}="${v}"`;
    })
        .join(' ');
    return `{{#${tag} ${propsJoined}}}{{/${tag}}}`;
};
const FIELD_TYPE_OPTIONS = [
    {
        label: 'Text (short)',
        value: 'text'
    },
    {
        label: 'Text (long)',
        value: 'textarea'
    },
    {
        label: 'Multiple Choice',
        value: 'multiplechoice'
    },
    {
        label: 'Date',
        value: 'date'
    }
];
const TEXT_TRANSFORM_OPTIONS = [
    {
        label: 'None',
        value: 'none'
    },
    {
        label: 'All Caps',
        value: 'uppercase'
    },
    {
        label: 'Lower Case',
        value: 'lowercase'
    },
    {
        label: 'Capitalize Words',
        value: 'capitalize'
    }
];
const TEXT_TYPE_OPTIONS = [
    {
        label: 'Normal',
        value: 'text'
    },
    {
        label: 'Number',
        value: 'number'
    },
    {
        label: 'Phone',
        value: 'tel'
    },
    {
        label: 'ZIP Code',
        value: 'postal-code'
    }
];
function MadlibFieldModal({ onSubmit, onClose }) {
    const [fieldType, setFieldType] = useState('text');
    // All types
    const [key, setKey] = useState();
    const [hint, setHint] = useState();
    // Text fields only
    const [placeholder, setPlaceholder] = useState();
    const [transform, setTransform] = useState('none');
    const [textType, setTextType] = useState('text');
    // Date fields only
    const [dateFormat, setDateFormat] = useState('MM/DD/YYYY');
    // Multiple choice only
    const [options, setOptions] = useState([]);
    const isValid = !!key && !!hint;
    return (_jsx(CancelOrSubmitModal, Object.assign({ onClose: onClose, header: "Add Input", primaryButtonText: "Add", disablePrimaryButton: !isValid, tertiaryButtonText: "Cancel", onSubmit: () => {
            if (fieldType === 'text') {
                const args = {
                    key,
                    hint,
                    placeholder,
                    type: textType
                };
                if (transform && transform !== 'none') {
                    args.transform = transform;
                }
                onSubmit(makeHandlebar('input_text', args));
            }
            if (fieldType === 'textarea') {
                const args = {
                    key,
                    hint,
                    placeholder
                };
                if (transform && transform !== 'none') {
                    args.transform = transform;
                }
                onSubmit(makeHandlebar('input_textarea', args));
            }
            if (fieldType === 'date') {
                const args = {
                    key,
                    hint,
                    format: dateFormat
                };
                onSubmit(makeHandlebar('input_date', args));
            }
            if (fieldType === 'multiplechoice') {
                const optionsStr = JSON.stringify(options);
                const args = {
                    key,
                    hint,
                    options: optionsStr
                };
                onSubmit(makeHandlebar('input_multiple_choice', args));
            }
        } }, { children: _jsxs("div", Object.assign({ className: "py-4 flex flex-col gap-2" }, { children: [_jsx(ColumnSelect, { id: "madlib-field-type", options: FIELD_TYPE_OPTIONS, onChange: setFieldType, value: fieldType, labelText: 'Type' }), _jsx(TextField, { id: "madlib-key", labelText: "Variable name (key)", placeholder: "your_var_name", value: key, onChange: setKey }), _jsx(TextField, { id: "madlib-hint", labelText: "Hint", placeholder: "...", value: hint, onChange: setHint }), (fieldType === 'text' || fieldType === 'textarea') && (_jsxs(_Fragment, { children: [_jsx(TextField, { id: "madlib-placeholder", labelText: "Placeholder", placeholder: "...", value: placeholder, onChange: setPlaceholder }), fieldType === 'text' && (_jsx(ColumnSelect, { id: "madlib-text-type", options: TEXT_TYPE_OPTIONS, onChange: setTextType, value: textType, labelText: 'Text Type' })), _jsx(ColumnSelect, { id: "madlib-transform", options: TEXT_TRANSFORM_OPTIONS, onChange: setTransform, value: transform, labelText: 'Transform' })] })), fieldType === 'date' && (_jsx(_Fragment, { children: _jsx(TextField, { id: "madlib-date-format", labelText: "Date Format", placeholder: "...", value: dateFormat, onChange: setDateFormat }) })), fieldType === 'multiplechoice' && (_jsx(_Fragment, { children: _jsx(InputAccessories, Object.assign({ id: "options-array", labelText: 'Options' }, { children: _jsx(EditableCardArray, { baseHeader: "Option", onChange: setOptions, archivable: {
                                buttonText: 'Remove'
                            }, createable: {
                                buttonText: 'Add option',
                                initialValue: ''
                            }, Component: MadlibModalMultipleChoiceCard, values: options }) })) }))] })) })));
}
export default MadlibFieldModal;
