import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
function AffidavitReminderModal({ onConfirmClicked, onBackClicked }) {
    const handleSetOpen = (val) => {
        if (!val) {
            onBackClicked();
        }
    };
    return (_jsx(CancelOrSubmitModal, Object.assign({ header: 'Affidavit Reminder', tertiaryButtonText: 'Back', primaryButtonText: 'Confirm', noExitOutsideModal: true, onClose: () => handleSetOpen(false), onSubmit: onConfirmClicked }, { children: _jsxs("div", Object.assign({ className: "text-gray-800 py-2" }, { children: [_jsx("p", Object.assign({ className: "pb-3" }, { children: "This notice has already passed its first publication date. Any changes you make may affect the affidavit for this notice." })), _jsx("p", Object.assign({ className: "pb-3" }, { children: "When you create an affidavit, please verify its accuracy before submitting it to your customer. If you have already submitted an affidavit, you can upload a new one in Notice Details." })), _jsxs("p", Object.assign({ className: "pb-3" }, { children: ["Click ", _jsx("b", { children: "Confirm" }), " to submit the edits to this notice."] }))] })) })));
}
export default AffidavitReminderModal;
