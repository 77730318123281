export var CustomerValidationState;
(function (CustomerValidationState) {
    CustomerValidationState[CustomerValidationState["PUBLISHER_CUSTOMER_FOUND"] = 1] = "PUBLISHER_CUSTOMER_FOUND";
    CustomerValidationState[CustomerValidationState["NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN"] = 2] = "NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN";
    CustomerValidationState[CustomerValidationState["INVALID_PUBLISHER_CUSTOMER_ID"] = 3] = "INVALID_PUBLISHER_CUSTOMER_ID";
    CustomerValidationState[CustomerValidationState["INVALID_PUBLISHER_CUSTOMER_INFO"] = 4] = "INVALID_PUBLISHER_CUSTOMER_INFO";
    CustomerValidationState[CustomerValidationState["DID_NOT_SEARCH"] = 5] = "DID_NOT_SEARCH";
})(CustomerValidationState || (CustomerValidationState = {}));
export var CustomerFetchMethods;
(function (CustomerFetchMethods) {
    CustomerFetchMethods["FROM_CUSTOMER_INFO"] = "FROM_CUSTOMER_INFO";
    CustomerFetchMethods["FROM_ACCOUNT_NUMBER"] = "FROM_ACCOUNT_NUMBER";
    CustomerFetchMethods["ALREADY_VERIFIED"] = "ALREADY_VERIFIED";
})(CustomerFetchMethods || (CustomerFetchMethods = {}));
