var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import useAsyncEffect from './useAsyncEffect';
/**
 * Get a snapshot one time. To establish a live listener, see
 * useFirestoreDocumentListener
 */
export function useFirestoreSnapshot(ref) {
    const { value } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const snap = yield (ref === null || ref === void 0 ? void 0 : ref.get());
            return snap;
        }),
        dependencies: [ref === null || ref === void 0 ? void 0 : ref.id]
    });
    return exists(value) ? value : undefined;
}
