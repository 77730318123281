import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { push } from 'connected-react-router';
import { POST_REGISTRATION_ENTRYPOINT } from 'redux/router';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import JoinOrCreateNewOrganization from './joinOrCreateNewOrganization';
export default function JoinOrCreateNewOrganizationRouter() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const onBackClick = () => {
        dispatch(push(POST_REGISTRATION_ENTRYPOINT));
    };
    if (!exists(user))
        return _jsx(LoadingState, {});
    return (_jsx(JoinOrCreateNewOrganization, { occupationType: user.data().occupation, onBackClick: onBackClick }));
}
