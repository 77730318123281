var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { InputAccessories } from '../InputAccessories';
import CheckboxInput from './CheckboxInput';
import WithTooltip from './WithTooltip';
export default function RadioButtonGroup({ id, labelText, options, value, onChange, disabled, required }) {
    return (_jsx(InputAccessories, Object.assign({ id: id, labelText: labelText, disabled: disabled, required: required }, { children: _jsx("div", Object.assign({ className: "grid grid-cols-2 gap-4" }, { children: options.map((_a, idx) => {
                var { tooltipText, value: checkboxValue } = _a, checkBoxProps = __rest(_a, ["tooltipText", "value"]);
                return (_jsx(WithTooltip, Object.assign({ tooltipText: tooltipText }, { children: _jsx(CheckboxInput, Object.assign({ id: `${id}-${idx}` }, checkBoxProps, { checked: value === checkboxValue, onChange: checked => {
                            if (checked) {
                                onChange(checkboxValue);
                            }
                        }, type: "radio" })) }), `${id}-${idx}`));
            }) })) })));
}
