import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
const DEFAULT_AFFIDAVIT_MAX_IMAGE_WIDTH = 1;
export default function SuperUserSettingsCard({ onChangeGlobalAffidavitControls, globalAffidavitControls }) {
    var _a;
    return (_jsx(CardGridLayout, Object.assign({ header: { title: 'Super User Settings' } }, { children: _jsx(SwitchControlledCard, Object.assign({ header: "", labelProps: {
                label: 'Override the maximum image width on the first page of affidavits?',
                description: 'If set this will be the max.',
                value: globalAffidavitControls.customAffidavitMaxImageWidth !== undefined,
                onChange: value => {
                    if (value) {
                        onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { customAffidavitMaxImageWidth: DEFAULT_AFFIDAVIT_MAX_IMAGE_WIDTH }));
                    }
                    else {
                        const newGlobalControls = Object.assign({}, globalAffidavitControls);
                        delete newGlobalControls.customAffidavitMaxImageWidth;
                        onChangeGlobalAffidavitControls(newGlobalControls);
                    }
                }
            } }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { type: "number", value: (_a = globalAffidavitControls.customAffidavitMaxImageWidth) === null || _a === void 0 ? void 0 : _a.toString(), min: 0.01, max: 5, step: 0.01, onChange: newValue => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { customAffidavitMaxImageWidth: parseFloat(newValue) })), id: "affidavit-max-image-width", labelText: "Maximum width for the image on page 1 (inches)", required: true }) })) })) })));
}
