export const AutomatedAffidavitParentOrganizationData = {
    ogden: {
        value: 1,
        key: 'ogden',
        label: 'Ogden Publishing',
        ids: ['pEdqCNuQy0iDCPpJ05IJ']
    },
    lee: {
        value: 2,
        key: 'lee',
        label: 'Lee Enterprises',
        ids: ['HwTxiaAmR8ERWEdUnoR1', 'FF0H4A8r7oZgNQI1s3uD']
    },
    swift: {
        value: 3,
        key: 'swift',
        label: 'Swift Communications',
        ids: ['Uo1IKa1wC1H4HKLXqtmH']
    },
    fcc: {
        value: 4,
        key: 'fcc',
        label: 'Forum Communications Company',
        ids: ['yX5JPInqQXVKHTTM05qN']
    },
    wick: {
        value: 5,
        key: 'wick',
        label: 'Wick Communications',
        ids: ['3Sat0CG1F4cAi9XAEW4g']
    }
};
