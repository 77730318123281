import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { NoticeRateService } from 'lib/services/noticeRateService';
import { usePublisherOrgRates } from 'routes/settings/publisher/rates/hooks/usePublisherOrgRates';
import { getFirebaseContext } from 'utils/firebase';
export function RateInput({ activeOrganization, value, onChange }) {
    const context = getFirebaseContext();
    const rateService = new NoticeRateService(context);
    const { orgRates } = usePublisherOrgRates(rateService, activeOrganization);
    return (_jsx(ColumnSelect, { id: "entry-key-rate-select", labelText: "Key", value: value, onChange: onChange, allowUndefined: true, options: orgRates.map(rate => ({
            label: rate.data().description,
            value: rate.data().code.toString()
        })) }));
}
