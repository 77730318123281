var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { NotFoundError } from '../../errors/ColumnErrors';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { NewspaperOrderStatus } from '../../types/newspaperOrder';
import { wrapError, wrapSuccess } from '../../types/responses';
import { getProductDeadlineTimeForPaper } from '../../utils/deadlines';
import { OrderModel } from './orderModel';
import { safeAsync } from '../../safeWrappers';
import { ColumnService } from '../../services/directory';
import { getErrorReporter } from '../../utils/errors';
import { OrganizationModel } from './organizationModel';
import { PublishingMedium } from '../../enums/PublishingMedium';
import { FilingTypeModel } from './filingTypeModel';
import { safeGetModelFromRef } from '../getModel';
import { getDateForDateStringInTimezone } from '../../utils/dates';
export class NewspaperOrderModel extends SnapshotModel {
    constructor() {
        super(...arguments);
        this.newspaper = null;
    }
    get type() {
        return Collections.newspaperOrders;
    }
    get transferHasOccurred() {
        return !!this.modelData.transfer;
    }
    getOrderRef() {
        return this.ref.parent.parent;
    }
    getOrder() {
        return __awaiter(this, void 0, void 0, function* () {
            const orderRef = this.getOrderRef();
            if (!orderRef) {
                return wrapError(new NotFoundError('Order not found'));
            }
            return safeGetModelFromRef(OrderModel, this.ctx, orderRef);
        });
    }
    getNewspaper() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.newspaper) {
                return wrapSuccess(this.newspaper);
            }
            const { response: newspaper, error } = yield safeGetModelFromRef(OrganizationModel, this.ctx, this.modelData.newspaper);
            if (error) {
                return wrapError(error);
            }
            this.newspaper = newspaper;
            return wrapSuccess(newspaper);
        });
    }
    getSortedPublishingDates() {
        return [...this.modelData.publishingDates].sort();
    }
    getDeadline() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.modelData.publishingDates.length === 0) {
                    return wrapError(new Error('Newspaper order has no publishing dates'));
                }
                // TODO: Pass this in
                const publishingMedium = PublishingMedium.Print;
                const newspaperResp = yield this.getNewspaper();
                if (newspaperResp.error) {
                    return wrapError(newspaperResp.error);
                }
                const newspaper = newspaperResp.response;
                const firstPublishingDateStr = this.getSortedPublishingDates()[0];
                const { response: order, error: getOrderError } = yield this.getOrder();
                if (getOrderError) {
                    return wrapError(getOrderError);
                }
                const { product } = order.modelData;
                const { response: deadlineResponse, error: deadlineError } = yield getProductDeadlineTimeForPaper(newspaper, product, publishingMedium, firstPublishingDateStr);
                if (deadlineError) {
                    return wrapError(deadlineError);
                }
                if (deadlineResponse === null) {
                    const err = new Error('Deadline result response is null');
                    getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, err, 'Failed to get deadline for newspaper order', {
                        newspaperOrderId: this.id,
                        orderId: order.id,
                        product
                    });
                    return wrapError(err);
                }
                return wrapSuccess(deadlineResponse.deadlineMoment);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    getFilingType() {
        return __awaiter(this, void 0, void 0, function* () {
            return safeGetModelFromRef(FilingTypeModel, this.ctx, this.modelData.filingType);
        });
    }
    getRate() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: filingType, error: filingTypeError } = yield this.getFilingType();
            if (filingTypeError) {
                return wrapError(filingTypeError);
            }
            const { response: rate, error: rateError } = yield filingType.getRate();
            if (rateError) {
                return wrapError(rateError);
            }
            return wrapSuccess(rate);
        });
    }
    updateStatus(status) {
        return __awaiter(this, void 0, void 0, function* () {
            if (status === NewspaperOrderStatus.COMPLETE) {
                const { response: canMarkAsComplete, error: canMarkAsCompleteError } = yield this.areCompletionConditionsMet();
                if (canMarkAsCompleteError) {
                    return wrapError(canMarkAsCompleteError);
                }
                /**
                 * This is considered an error rather than just an early return because in
                 * `markOrdersAsComplete`, we query orders from Elastic that match these criteria,
                 * so if this returns false, then something may have gone wrong in that query or
                 * in the sync to Elastic.
                 */
                if (!canMarkAsComplete) {
                    return wrapError(new Error('Newspaper order does not meet conditions to be marked as complete'));
                }
            }
            const updates = { status };
            if (status === NewspaperOrderStatus.CONFIRMED) {
                updates.confirmedAt = this.ctx
                    .fieldValue()
                    .serverTimestamp();
            }
            const safeUpdate = safeAsync(() => this.update(updates));
            return safeUpdate();
        });
    }
    isAtLeastNHoursBeforeDeadline(hoursBeforeDeadline) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: deadline, error: deadlineError } = yield this.getDeadline();
            if (deadlineError) {
                return wrapError(deadlineError);
            }
            return wrapSuccess(moment().add(hoursBeforeDeadline, 'hours').isBefore(deadline));
        });
    }
    getEditableDataForPublisher(user, isBeforeDeadline) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: activeOrganization, error: activeOrganizationError } = yield user.getActiveOrganization();
            if (activeOrganizationError) {
                return wrapError(activeOrganizationError);
            }
            const newspaperId = this.modelData.newspaper.id;
            const userIsPartOfNewspaper = newspaperId === activeOrganization.id;
            const newspaperOrderIsAwaitingReview = this.modelData.status === NewspaperOrderStatus.AWAITING_REVIEW;
            const userCanEditOwnNewspaper = userIsPartOfNewspaper && !this.transferHasOccurred;
            const userCanEditForAnotherNewspaper = isBeforeDeadline && newspaperOrderIsAwaitingReview;
            const canEdit = userCanEditOwnNewspaper || userCanEditForAnotherNewspaper;
            const bannerMessage = isBeforeDeadline && canEdit
                ? undefined
                : userIsPartOfNewspaper && canEdit
                    ? 'This order has passed ad deadline. If you edit this order, ensure that the updated proof is sent to pagination to prevent incorrect content from publishing.'
                    : userIsPartOfNewspaper && !canEdit
                        ? 'A transfer has occurred for this order. No edits can be processed.'
                        : !isBeforeDeadline
                            ? 'The ad deadline for this order has passed. No edits or cancellations can be processed.'
                            : "This publication has updated this order's confirmation status. You cannot edit this portion of the order.";
            return wrapSuccess({
                canEdit,
                bannerMessage,
                isBeforeDeadline,
                newspaperId
            });
        });
    }
    getEditableDataForCustomer(isBeforeDeadline) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: isAtLeastOneHourBeforeDeadline, error: checkDeadlineError } = yield this.isAtLeastNHoursBeforeDeadline(1);
            if (checkDeadlineError) {
                return wrapError(checkDeadlineError);
            }
            const canEdit = isAtLeastOneHourBeforeDeadline;
            const bannerMessage = canEdit
                ? 'Edits can be made to your order until one hour before the ad deadline. You can cancel your order until the ad deadline.'
                : isBeforeDeadline
                    ? 'Edits cannot be made when it is less than one hour before the ad deadline. You can still cancel your order until the ad deadline.'
                    : 'The ad deadline for this order has passed. No edits or cancellations can be processed.';
            return wrapSuccess({
                canEdit,
                bannerMessage,
                isBeforeDeadline,
                newspaperId: this.modelData.newspaper.id
            });
        });
    }
    getEditableDataForUser(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: isBeforeDeadline, error: isBeforeDeadlineError } = yield this.isAtLeastNHoursBeforeDeadline(0);
            if (isBeforeDeadlineError) {
                return wrapError(isBeforeDeadlineError);
            }
            // If the newspaper order is cancelled, it can't be edited
            if (this.modelData.status === NewspaperOrderStatus.CANCELLED) {
                return wrapSuccess({
                    canEdit: false,
                    bannerMessage: undefined,
                    isBeforeDeadline,
                    newspaperId: this.modelData.newspaper.id
                });
            }
            if (user && user.isPublisher) {
                return this.getEditableDataForPublisher(user, isBeforeDeadline);
            }
            return this.getEditableDataForCustomer(isBeforeDeadline);
        });
    }
    getPublicationIssues() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response, error } = yield safeAsync(() => __awaiter(this, void 0, void 0, function* () {
                const publicationIssues = yield this.ctx
                    .publicationIssuesRef()
                    .where('publisher', '==', this.modelData.newspaper)
                    .where('publicationDate', 'in', this.modelData.publishingDates)
                    .get();
                return publicationIssues.docs;
            }))();
            if (error) {
                return wrapError(error);
            }
            return wrapSuccess(response);
        });
    }
    get isConfirmed() {
        return this.modelData.status === NewspaperOrderStatus.CONFIRMED;
    }
    get isComplete() {
        return this.modelData.status === NewspaperOrderStatus.COMPLETE;
    }
    hasLastPublishingDateElapsed() {
        return __awaiter(this, void 0, void 0, function* () {
            const lastPublishingDateStr = this.getSortedPublishingDates().pop();
            if (!lastPublishingDateStr) {
                return wrapError(new Error('Unable to determine last publishing date for newspaper order'));
            }
            const { response: newspaper, error: newspaperError } = yield this.getNewspaper();
            if (newspaperError) {
                return wrapError(newspaperError);
            }
            const timezone = newspaper.modelData.iana_timezone;
            const lastPublishingDateMoment = moment(getDateForDateStringInTimezone({
                dayString: lastPublishingDateStr,
                timezone
            }));
            const today = moment().tz(timezone);
            return wrapSuccess(today.isSameOrAfter(lastPublishingDateMoment, 'day'));
        });
    }
    areCompletionConditionsMet() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: lastPublishingDateHasElapsed, error: lastPublishingDateError } = yield this.hasLastPublishingDateElapsed();
            if (lastPublishingDateError) {
                return wrapError(lastPublishingDateError);
            }
            return wrapSuccess(lastPublishingDateHasElapsed && this.isConfirmed);
        });
    }
}
