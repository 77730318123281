var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { getFirebaseContext } from 'utils/firebase';
import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
/**
 * Full-screen form to update properties associated with a rate
 */
export default function SubscriptionEditForm({ editedSubscription, closeForm }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    const [updatedEditedSubscription, setUpdatedEditedSubscription] = useState(editedSubscription);
    const [updating, setUpdating] = useState(false);
    const edited = !columnObjectsAreEqual(updatedEditedSubscription, editedSubscription);
    const updateSubscriptionProperties = () => __awaiter(this, void 0, void 0, function* () {
        setUpdating(true);
        if (editedSubscription.id) {
            yield getFirebaseContext()
                .subscriptionsRef()
                .doc(editedSubscription.id)
                .update(updatedEditedSubscription);
        }
        else {
            yield getFirebaseContext()
                .subscriptionsRef()
                .add(updatedEditedSubscription);
        }
        yield editedSubscription;
        setUpdating(false);
        closeForm();
    });
    const formComplete = updatedEditedSubscription.query &&
        ((_b = (_a = updatedEditedSubscription.mailing) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.name) &&
        ((_d = (_c = updatedEditedSubscription.mailing) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.line1) &&
        ((_f = (_e = updatedEditedSubscription.mailing) === null || _e === void 0 ? void 0 : _e.address) === null || _f === void 0 ? void 0 : _f.city) &&
        ((_h = (_g = updatedEditedSubscription.mailing) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.state) &&
        ((_k = (_j = updatedEditedSubscription.mailing) === null || _j === void 0 ? void 0 : _j.address) === null || _k === void 0 ? void 0 : _k.postalCode);
    // Enable saving instantly for new subscriptions, and after edit for existing subscriptions
    const disableSave = !formComplete || (!!editedSubscription.id && (updating || !edited));
    return (_jsx(FullScreenModal, Object.assign({ submittable: {
            buttonText: 'Save',
            disabled: !!disableSave,
            onSubmit: updateSubscriptionProperties
        }, headerText: `${editedSubscription.id ? 'Edit' : 'Add'} Subscription`, onClose: closeForm, id: "subscription-edit-form" }, { children: _jsxs(CardGridLayout, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: "subscription-query", labelText: "Search Query", value: updatedEditedSubscription.query, onChange: newValue => setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { query: newValue })) }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "subscription-frequency", labelText: "Frequency", options: [
                            { label: 'Daily', value: 'daily' },
                            { label: 'Weekly', value: 'weekly' },
                            { label: 'Monthly', value: 'monthly' }
                        ], value: updatedEditedSubscription.frequency, onChange: newValue => setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { frequency: newValue })) }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "mail-name", labelText: "Name", value: (_m = (_l = updatedEditedSubscription.mailing) === null || _l === void 0 ? void 0 : _l.address) === null || _m === void 0 ? void 0 : _m.name, onChange: newValue => {
                            var _a;
                            return setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { mailing: Object.assign(Object.assign({}, updatedEditedSubscription.mailing), { address: Object.assign(Object.assign({}, (_a = updatedEditedSubscription.mailing) === null || _a === void 0 ? void 0 : _a.address), { name: newValue }) }) }));
                        } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "subscription-mailing-address", labelText: "Address Line 1", value: (_p = (_o = updatedEditedSubscription.mailing) === null || _o === void 0 ? void 0 : _o.address) === null || _p === void 0 ? void 0 : _p.line1, onChange: newValue => {
                            var _a;
                            return setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { mailing: Object.assign(Object.assign({}, updatedEditedSubscription.mailing), { address: Object.assign(Object.assign({}, (_a = updatedEditedSubscription.mailing) === null || _a === void 0 ? void 0 : _a.address), { line1: newValue }) }) }));
                        } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "subscription-mailing-address", labelText: "Address Line 2", value: (_r = (_q = updatedEditedSubscription.mailing) === null || _q === void 0 ? void 0 : _q.address) === null || _r === void 0 ? void 0 : _r.line2, onChange: newValue => {
                            var _a;
                            return setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { mailing: Object.assign(Object.assign({}, updatedEditedSubscription.mailing), { address: Object.assign(Object.assign({}, (_a = updatedEditedSubscription.mailing) === null || _a === void 0 ? void 0 : _a.address), { line2: newValue }) }) }));
                        } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "subscription-mailing-city", labelText: "City", value: (_t = (_s = updatedEditedSubscription.mailing) === null || _s === void 0 ? void 0 : _s.address) === null || _t === void 0 ? void 0 : _t.city, onChange: newValue => {
                            var _a;
                            return setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { mailing: Object.assign(Object.assign({}, updatedEditedSubscription.mailing), { address: Object.assign(Object.assign({}, (_a = updatedEditedSubscription.mailing) === null || _a === void 0 ? void 0 : _a.address), { city: newValue }) }) }));
                        } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "subscription-mailing-state", labelText: "State", value: (_v = (_u = updatedEditedSubscription.mailing) === null || _u === void 0 ? void 0 : _u.address) === null || _v === void 0 ? void 0 : _v.state, onChange: newValue => {
                            var _a;
                            return setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { mailing: Object.assign(Object.assign({}, updatedEditedSubscription.mailing), { address: Object.assign(Object.assign({}, (_a = updatedEditedSubscription.mailing) === null || _a === void 0 ? void 0 : _a.address), { state: newValue }) }) }));
                        } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "subscription-mailing-postal-code", labelText: "Postal Code", value: (_x = (_w = updatedEditedSubscription.mailing) === null || _w === void 0 ? void 0 : _w.address) === null || _x === void 0 ? void 0 : _x.postalCode, onChange: newValue => {
                            var _a;
                            return setUpdatedEditedSubscription(Object.assign(Object.assign({}, updatedEditedSubscription), { mailing: Object.assign(Object.assign({}, updatedEditedSubscription.mailing), { address: Object.assign(Object.assign({}, (_a = updatedEditedSubscription.mailing) === null || _a === void 0 ? void 0 : _a.address), { postalCode: newValue }) }) }));
                        } }) })] }) })));
}
