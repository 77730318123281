import { useEffect, useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { exists } from '../../types';
import { getErrorReporter } from '../../utils/errors';
/**
 * Establish a live snapshot listener for a single document.
 */
export function useFirestoreDocumentListener(ref) {
    const [snap, setSnap] = useState(null);
    useEffect(() => {
        if (!ref) {
            return setSnap(null);
        }
        const unsubscribe = ref.onSnapshot(next => setSnap(exists(next) ? next : null), err => {
            getErrorReporter().logAndCaptureError(ColumnService.DATABASE, err, 'Error listening to document snapshot', {
                ref: ref.path
            });
        });
        return unsubscribe;
    }, [ref === null || ref === void 0 ? void 0 : ref.id]);
    return snap;
}
