var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DocumentArrowDownIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { Alert } from 'lib/components/Alert';
import { cdnIfy, unCdnify } from 'lib/helpers';
import FileUpload from 'lib/components/FileUpload';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { InputAccessories } from 'lib/components/InputAccessories';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import Firebase from 'EnoticeFirebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { DEFAULT_AD_TEMPLATE } from '../../templateSettingsUtils';
/**
 * Form wrapper to update the styles associated with a template
 */
export default function TemplateFileUpload({ onChange, value, isNoticeProduct }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const title = 'File Upload';
    const description = 'Upload an IDML file with the desired layout settings for this template.';
    const [downloadingSample, setDownloadingSample] = useState(false);
    const downloadSample = () => __awaiter(this, void 0, void 0, function* () {
        setDownloadingSample(true);
        window.open(DEFAULT_AD_TEMPLATE, '_blank');
        setDownloadingSample(false);
    });
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    return (_jsxs(CardGridLayout, Object.assign({ header: { title, description } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { id: "template-instructions", status: "success", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }), description: `To create ${isNoticeProduct ? 'notices' : 'orders'}, Column flows copy into the first text frame of the first group on the first page of the IDML template.` }) })), value && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InputAccessories, Object.assign({ id: "template-download-label", labelText: "Current IDML file" }, { children: _jsx(FileUploadListItem, { firebaseStoragePath: unCdnify(value), viewButtonText: "Download", viewButtonIcon: _jsx(DocumentArrowDownIcon, { className: "w-6 h-6" }), storage: Firebase.storage() }) })) }))), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InputAccessories, Object.assign({ id: "template-upload-label", labelText: "Upload new IDML file" }, { children: _jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsx(FileUpload, { id: "template-download-url-upload", multiple: false, acceptFileTypes: ".idml", uploadFolder: `templates/${activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id}/bulkTemplates`, onFileUpload: (filesAndRefs) => __awaiter(this, void 0, void 0, function* () {
                                    const [newFileAndRef] = filesAndRefs;
                                    const newTemplateURL = cdnIfy(newFileAndRef.uploadRef.fullPath, { useColumnCDN });
                                    onChange(newTemplateURL);
                                }), storage: Firebase.storage() }), _jsx(ColumnButton, { buttonText: "Download Sample Template", loading: downloadingSample, disabled: downloadingSample, onClick: downloadSample, type: "button" })] })) })) }))] })));
}
