var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { push } from 'connected-react-router';
import PlacementActions, { selectIsEditing, selectPreviewContent, selectOriginalId, selectCurrentStepId, selectDraftRef } from 'redux/placement';
import MilestoneTracker from 'components/MilestoneTracker/MilestoneTracker';
import { DollarIcon, XIcon } from 'icons';
import { ColumnButton } from 'lib/components/ColumnButton';
import { getNoticePlacementMilestoneTrackerParams } from 'utils/milestones';
import { Badge } from 'lib/components/Badge';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { isAnonymousFlow } from './helpers';
export default function PlacementNavbar({ steps, hidePrice = false, exitModalMessage }) {
    const dispatch = useAppDispatch();
    const currentStepId = useAppSelector(selectCurrentStepId);
    const { price } = useAppSelector(selectPreviewContent);
    const editing = useAppSelector(selectIsEditing);
    const originalNoticeId = useAppSelector(selectOriginalId);
    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false);
    const hideExitButton = isAnonymousFlow();
    const priceActive = price !== '--';
    const draftRef = useAppSelector(selectDraftRef);
    return (_jsxs("div", Object.assign({ className: "flex w-full h-20 shadow border-b fixed top-0 mb-20 z-100 bg-white px-8 xl:px-12 justify-between items-center" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center xl:w-1/6" }, { children: [!hideExitButton && (_jsx(ColumnButton, { id: "exit-placement", onClick: () => {
                            setShowExitConfirmationModal(true);
                        }, size: "lg", tertiary: true, startIcon: _jsx(XIcon, { className: "w-4 h-4 my-1 text-grey-400" }), type: "button" })), _jsx("h1", Object.assign({ className: "ml-4 text-lg xl:text-xl font-semibold text-column-gray-800 leading-6" }, { children: editing ? 'Edit Notice' : 'New Notice' }))] })), _jsx("div", Object.assign({ className: "hidden lg:block" }, { children: _jsx(MilestoneTracker, { milestones: getNoticePlacementMilestoneTrackerParams(steps, currentStepId || '') }) })), _jsx("div", Object.assign({ className: "flex justify-end xl:w-1/6" }, { children: !hidePrice && (_jsx(Badge, Object.assign({ size: "lg", status: priceActive ? 'success' : undefined, startIcon: _jsx(DollarIcon, { className: "w-4 h-4 mr-1" }) }, { children: price }))) })), showExitConfirmationModal && (_jsx(ExitConfirmationModal, { keepEditing: () => setShowExitConfirmationModal(false), exitWithoutSaving: () => __awaiter(this, void 0, void 0, function* () {
                    if (editing) {
                        yield (draftRef === null || draftRef === void 0 ? void 0 : draftRef.delete());
                    }
                    else {
                        dispatch(PlacementActions.saveDraft());
                    }
                    dispatch(push(editing ? `/notice/${originalNoticeId}` : `/notices`));
                }), body: editing
                    ? 'If you don’t submit your edits, any changes may be lost.'
                    : exitModalMessage, disableOverflow: true }))] })));
}
