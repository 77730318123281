import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircle } from 'icons';
import React from 'react';
import { Alert } from 'lib/components/Alert';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { supportsDisplay } from 'lib/notice/rates';
import { useBulkPaymentV2EnabledOnOrganization } from 'components/hooks/useBulkPaymentV2EnabledOnOrganization';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { Tooltip } from 'lib/components/Tooltip';
import { isColumnUser } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { BillingTermType, enumToSelectInput } from 'lib/enums';
import { DiscountSettings, FLAT_DISCOUNT_CONFIG } from './DiscountSettings';
export const REQUIRE_UPFRONT_PAYMENT_TYPES = {
    DEFAULT: { id: '0', description: 'Default', value: null },
    ALWAYS_REQUIRE: { id: '1', description: 'Always require', value: true },
    NEVER_REQUIRE: { id: '2', description: 'Never require', value: false }
};
export const REQUIRE_UPFRONT_PAYMENT_OPTIONS = Object.values(REQUIRE_UPFRONT_PAYMENT_TYPES);
export function CreateOrEditCustomerModalSettings({ activeOrganization, rates, value, advertiser, onChange }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const user = useAppSelector(selectUser);
    // we only want to display the bulk invoice editing ability if the newspaper
    // has bulkPaymentEnabled_v2 on and the user affiliated with the customer is an individual,
    // or if the newspaper has bulkPaymentEnabled (regardless of user occupation)
    const isBulkPaymentV2EnabledOnOrganization = useBulkPaymentV2EnabledOnOrganization(activeOrganization);
    const userIsIndividual = !((_a = advertiser === null || advertiser === void 0 ? void 0 : advertiser.allowedOrganizations) === null || _a === void 0 ? void 0 : _a.length);
    const activeOrganizationHasBulkPaymentEnabled = isBulkPaymentV2EnabledOnOrganization && userIsIndividual;
    const linerRateIsArchived = !!((_c = (_b = value.linerRateSnap) === null || _b === void 0 ? void 0 : _b.data()) === null || _c === void 0 ? void 0 : _c.archived);
    const displayRateIsArchived = !!((_e = (_d = value.displayRateSnap) === null || _d === void 0 ? void 0 : _d.data()) === null || _e === void 0 ? void 0 : _e.archived);
    const linerRateOptions = rates === null || rates === void 0 ? void 0 : rates.filter(rate => {
        var _a;
        if (((_a = value.linerRateSnap) === null || _a === void 0 ? void 0 : _a.id) === rate.id)
            return true;
        return !rate.data().archived;
    }).map(rate => {
        var _a;
        return ({
            value: rate.id,
            label: (_a = rate.data()) === null || _a === void 0 ? void 0 : _a.description
        });
    });
    const displayRateOptions = rates === null || rates === void 0 ? void 0 : rates.filter(rate => {
        var _a, _b, _c;
        if (((_a = value.displayRateSnap) === null || _a === void 0 ? void 0 : _a.id) === rate.id)
            return true;
        const rateSupportsDisplay = supportsDisplay(rate.data());
        const isOrganizationDefaultDisplayRate = rate.id === ((_c = (_b = activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.defaultDisplayRate) === null || _c === void 0 ? void 0 : _c.id);
        return (isOrganizationDefaultDisplayRate ||
            (rateSupportsDisplay && !rate.data().archived));
    }).map(rate => {
        var _a;
        return ({
            value: rate.id,
            label: (_a = rate.data()) === null || _a === void 0 ? void 0 : _a.description
        });
    });
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: "Set up your customer's preferences.",
            description: 'Configure how you want this customer to interact with your publication.'
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { id: "customization-warning", status: "warning", icon: _jsx(ExclamationCircle, { className: "w-5 h-5" }), description: "Customer liner and display rates are set to your organization's default settings. You should only make changes if you want a different rate for this customer." }) })), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Liner rate", id: "liner_rate", value: (_f = value.linerRateSnap) === null || _f === void 0 ? void 0 : _f.id, options: linerRateOptions || [], onChange: newValue => {
                        const selectedRate = rates === null || rates === void 0 ? void 0 : rates.find(r => r.id === newValue);
                        if (selectedRate) {
                            onChange(Object.assign(Object.assign({}, value), { linerRateSnap: selectedRate }));
                        }
                    }, errorText: linerRateIsArchived
                        ? 'The selected rate is archived, please select another rate'
                        : undefined }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Display rate", id: "display_rate", value: (_g = value.displayRateSnap) === null || _g === void 0 ? void 0 : _g.id, options: displayRateOptions || [], onChange: newValue => {
                        const selectedRate = rates === null || rates === void 0 ? void 0 : rates.find(r => r.id === newValue);
                        if (selectedRate) {
                            onChange(Object.assign(Object.assign({}, value), { displayRateSnap: selectedRate }));
                        }
                    }, errorText: displayRateIsArchived
                        ? 'The selected rate is archived, please select another rate'
                        : undefined }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { labelText: "Do you require upfront payment for this customer?", placeholder: "Require upfront payment?", id: "upfront_payment", value: value.upfrontPaymentSetting.id, disabled: !!value.bulkPaymentEnabled_v2, options: REQUIRE_UPFRONT_PAYMENT_OPTIONS.map(option => ({
                        value: option.id,
                        label: option.description
                    })), onChange: newValue => {
                        if (newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT.id) {
                            onChange(Object.assign(Object.assign({}, value), { upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT }));
                        }
                        else if (newValue === REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE.id) {
                            onChange(Object.assign(Object.assign({}, value), { upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE }));
                        }
                        else {
                            onChange(Object.assign(Object.assign({}, value), { upfrontPaymentSetting: REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE }));
                        }
                    } }) })), user && isColumnUser(user) && (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Default columns (available to Column users only)", id: "default-columns", value: ((_h = value.defaultColumns) === null || _h === void 0 ? void 0 : _h.toString()) || 'default', options: [
                                { value: 'default', label: 'Default' },
                                { value: '1', label: '1' },
                                { value: '2', label: '2' },
                                { value: '3', label: '3' }
                            ], onChange: newValue => {
                                if (newValue === 'default') {
                                    const newValue = Object.assign({}, value);
                                    delete newValue.defaultColumns;
                                    onChange(newValue);
                                }
                                else {
                                    onChange(Object.assign(Object.assign({}, value), { defaultColumns: parseInt(newValue, 10) }));
                                }
                            } }) }), !!((_k = (_j = activeOrganization.data()) === null || _j === void 0 ? void 0 : _j.allowedNotices) === null || _k === void 0 ? void 0 : _k.length) && (_jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Default notice type (available to Column users only)", id: "default-notice-type", value: ((_l = value.defaultNoticeType) === null || _l === void 0 ? void 0 : _l.toString()) || 'default', options: [
                                { value: 'default', label: 'Default' },
                                ...(((_o = (_m = activeOrganization
                                    .data()) === null || _m === void 0 ? void 0 : _m.allowedNotices) === null || _o === void 0 ? void 0 : _o.map(noticeType => ({
                                    value: noticeType.value.toString(),
                                    label: noticeType.label
                                }))) || [])
                            ], onChange: newValue => {
                                if (newValue === 'default') {
                                    const newValue = Object.assign({}, value);
                                    delete newValue.defaultNoticeType;
                                    onChange(newValue);
                                }
                                else {
                                    onChange(Object.assign(Object.assign({}, value), { defaultNoticeType: parseInt(newValue, 10) }));
                                }
                            } }) }))] })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow affidavits before payment?", description: "Customers will be allowed to download affidavits before paying\n              invoices.", value: !!value.enableAffidavitsBeforePayment, onChange: enableAffidavitsBeforePayment => {
                        onChange(Object.assign(Object.assign({}, value), { enableAffidavitsBeforePayment }));
                    } }) })), activeOrganizationHasBulkPaymentEnabled && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Tooltip, Object.assign({ helpText: !value.bulkPaymentEnabled_v2
                        ? 'Please create an organization for this customer first'
                        : '', position: "top" }, { children: _jsx(LabeledSwitch, { label: "Allow bulk invoicing?", description: "Toggle this on if you want to create one monthly invoice for\n                this customer.", value: !!value.bulkPaymentEnabled_v2, onChange: enabled => {
                            onChange(Object.assign(Object.assign({}, value), { bulkPaymentEnabled_v2: enabled }));
                        }, disabled: !value.bulkPaymentEnabled_v2 }) })) }))), ((_p = activeOrganization.data()) === null || _p === void 0 ? void 0 : _p.allowInvoiceOutsideColumn) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Invoice outside Column?", description: "By default, invoices created for this customer will be billed outside of Column.", value: !!value.invoicedOutsideColumn, onChange: invoicedOutsideColumn => {
                        onChange(Object.assign(Object.assign({}, value), { invoicedOutsideColumn }));
                    } }) }))), ((_q = activeOrganization.data()) === null || _q === void 0 ? void 0 : _q.hideDigitalAffidavits) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow emailed affidavits?", description: "Toggle this on if you want to allow this customer to request\n                  affidavits by email.", value: !!value.allowAffidavitEmail, onChange: allowAffidavitEmail => {
                        onChange(Object.assign(Object.assign({}, value), { allowAffidavitEmail }));
                    } }) }))), _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                    label: 'Add a discount to this account?',
                    description: 'You can specify a flat discount or a percentage discount along with an expiration date.',
                    value: !!((_r = value.discountConfig) === null || _r === void 0 ? void 0 : _r.subtotal),
                    onChange: enableDiscount => {
                        if (!enableDiscount) {
                            onChange(Object.assign(Object.assign({}, value), { discountConfig: {} }));
                        }
                        else {
                            onChange(Object.assign(Object.assign({}, value), { discountConfig: { subtotal: FLAT_DISCOUNT_CONFIG } }));
                        }
                    }
                }, header: "" }, { children: _jsx(DiscountSettings, { value: (_t = (_s = value.discountConfig) === null || _s === void 0 ? void 0 : _s.subtotal) !== null && _t !== void 0 ? _t : FLAT_DISCOUNT_CONFIG, onChange: (discount) => {
                        onChange(Object.assign(Object.assign({}, value), { discountConfig: { subtotal: discount } }));
                    } }) })), userIsIndividual && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "billing-term-select", labelText: "Default invoice due date for billable notices", noteText: _jsxs(_Fragment, { children: ["Learn more about this option", ' ', _jsx("a", Object.assign({ className: "text-blue-400", href: "https://help.column.us/en_us/how-do-i-update-my-customer-invoice-due-date-rJ48X3KT", target: "_blank", rel: "noreferrer" }, { children: "here" }))] }), value: (_u = value.billingTerm) === null || _u === void 0 ? void 0 : _u.toString(), options: enumToSelectInput(BillingTermType), onChange: stringValue => onChange(Object.assign(Object.assign({}, value), { billingTerm: Number(stringValue) })) }) })))] })));
}
