import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { formatAttachmentName } from './notesHelpers';
export function NoteAttachmentField({ notesAttachment, setNotesAttachment }) {
    const noteFileUploadInput = useRef(null);
    if (notesAttachment) {
        return (_jsx(ColumnButton, { buttonText: formatAttachmentName(notesAttachment.name), onClick: () => setNotesAttachment(null), startIcon: _jsx(XMarkIcon, { className: "h-5 w-5" }), type: "button", link: true }));
    }
    return (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("input", { onChange: e => { var _a; return setNotesAttachment(((_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0]) || null); }, "aria-describedby": "file-upload-icon", className: "hidden", type: "file", ref: noteFileUploadInput, id: "note-file-upload" }), _jsx(ColumnButton, { buttonText: _jsx("span", Object.assign({ className: "text-column-gray-400" }, { children: "Attach file" })), endIcon: _jsx(PaperClipIcon, { className: "text-column-gray-400 h-5 w-5" }), onClick: () => { var _a; return (_a = noteFileUploadInput.current) === null || _a === void 0 ? void 0 : _a.click(); }, type: "button", link: true })] })));
}
