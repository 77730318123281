import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import Drawer from 'lib/components/Drawer';
import RateDrawerHeader from './RateDrawerHeader';
import RateDrawerBody from './RateDrawerBody';
/**
 * Drawer component for seeing details on a particular rate
 */
export default function RateDrawer({ activeOrganization, setEditedRateData, setEditedRateRef, drawerRate, closeDrawer }) {
    const [rate, setRate] = useState();
    useEffect(() => {
        const unsub = drawerRate.ref.onSnapshot(rate => {
            if (exists(rate))
                setRate(rate);
        });
        return () => unsub();
    }, [drawerRate.id]);
    return (_jsx(Drawer, Object.assign({ onClose: () => closeDrawer(), header: _jsx(RateDrawerHeader, { activeOrganization: activeOrganization, rate: drawerRate }), open: true, className: "h-screen flex flex-col" }, { children: rate ? (_jsx(RateDrawerBody, { activeOrganization: activeOrganization, setEditedRate: editedRate => {
                setEditedRateData(editedRate.data());
                setEditedRateRef(editedRate.ref);
            }, rate: rate, closeDrawer: closeDrawer })) : (_jsx(LoadingState, {})) })));
}
