var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getModelFromRef, getModelFromSnapshot } from '../model';
import { RunModel } from '../model/objects/runModel';
import { UserNoticeModel } from '../model/objects/userNoticeModel';
import { noticeIsSubmitted } from '../notice/helpers';
import { exists } from '../types';
import { RunStatusType, verifiableRunStatuses, verifiedRunStatuses } from '../types/runs';
import { getDateStringForDateInTimezone } from '../utils/dates';
import { getErrorReporter } from '../utils/errors';
import { getOrCreatePublicationIssueForPublisher } from './publicationIssueService';
import { getNoticeHasAllRelevantRuns } from '../affidavits';
import { NOTICE_RECONCILE_RUNS, RUN_STATUS_CHANGE } from '../types/events';
import { safeAsync } from '../safeWrappers';
const createRun = ({ ctx, publicationIssueModel, notice }) => __awaiter(void 0, void 0, void 0, function* () {
    // Runs will be initiated with a pending status
    const pendingRunData = {
        publicationIssue: publicationIssueModel.ref,
        publicationDate: publicationIssueModel.modelData.publicationDate,
        notice,
        status: RunStatusType.PENDING
    };
    const runRef = yield ctx.runsRef().add(pendingRunData);
    return getModelFromRef(RunModel, ctx, runRef);
});
export const getRunForNoticeAndPublicationDate = ({ ctx, publicationDate, noticeSnap }) => __awaiter(void 0, void 0, void 0, function* () {
    const runQuery = yield ctx
        .runsRef()
        .where('notice', '==', noticeSnap.ref)
        .where('publicationDate', '==', publicationDate)
        .get();
    if (runQuery.size > 1) {
        throw new Error(`Duplicate runs found for notice/publicationDate pair`);
    }
    if (runQuery.empty) {
        getErrorReporter().logInfo('No run found for notice/publicationDate pair', {
            noticeId: noticeSnap.id,
            publicationDate
        });
        return null;
    }
    const runSnap = runQuery.docs[0];
    return getModelFromSnapshot(RunModel, ctx, runSnap);
});
// TODO: Remove export once confident no runs are missing at verification checkpoints
export const getOrCreateRunForNoticeAndPublicationDate = ({ ctx, publicationDate, noticeSnap }) => __awaiter(void 0, void 0, void 0, function* () {
    const existingRun = yield getRunForNoticeAndPublicationDate({
        ctx,
        publicationDate,
        noticeSnap
    });
    if (existingRun) {
        return existingRun;
    }
    getErrorReporter().logInfo('No run exists for notice and publication date; will create one', {
        noticeId: noticeSnap.id,
        publicationDate
    });
    // Defensive in case this function is called on a notice that doesn't yet have a newspaper
    if (!noticeSnap.data().newspaper) {
        throw new Error(`Cannot create run for notice ${noticeSnap.id} because it does not have a newspaper`);
    }
    /**
     * Because of limitations on the cron that creates publication issues (see `createPublicationIssues`),
     * and the fact that we allow users to create notices for publication dates that do not yet have publication
     * issues, we will create a new publication issue for the purposes of creating a run if needed.
     *
     * However, as the eventual plan is to expand the creation of publication issues to a date further in the future
     * and potentially tie selectable publication dates to available publication issues, we may want to revisit this
     * logic if and when those changes are made.
     */
    const publicationIssueModel = yield getOrCreatePublicationIssueForPublisher(ctx, noticeSnap.data().newspaper, publicationDate);
    getErrorReporter().logInfo('Creating run for notice and publication issue', {
        noticeId: noticeSnap.id,
        publicationDate,
        publicationIssueId: publicationIssueModel.id
    });
    return createRun({
        ctx,
        publicationIssueModel,
        notice: noticeSnap.ref
    });
});
export const shouldReconcileRuns = ({ ctx, beforeSnap, afterSnap }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!noticeIsSubmitted(afterSnap)) {
        return false;
    }
    if (!afterSnap.data().publicationDates) {
        return false;
    }
    const numberOfPublicationDatesHasChanged = ((_a = beforeSnap.data().publicationDates) === null || _a === void 0 ? void 0 : _a.length) !==
        afterSnap.data().publicationDates.length;
    if (numberOfPublicationDatesHasChanged) {
        return true;
    }
    const noticeStatusHasChanged = beforeSnap.data().noticeStatus !== afterSnap.data().noticeStatus;
    if (noticeStatusHasChanged) {
        return true;
    }
    for (let i = 0; i < beforeSnap.data().publicationDates.length; i++) {
        if (beforeSnap.data().publicationDates[i].toMillis() !==
            afterSnap.data().publicationDates[i].toMillis()) {
            return true;
        }
    }
    const runReconciliationEventQuery = yield ctx
        .eventsRef()
        .where('type', '==', NOTICE_RECONCILE_RUNS)
        .where('notice', '==', afterSnap.ref)
        .where('processedAt', '==', null)
        .limit(1)
        .get();
    const existingRunReconciliationEvent = runReconciliationEventQuery.docs[0];
    if (existingRunReconciliationEvent) {
        getErrorReporter().logInfo('Run reconciliation event already exists for notice; will not create a new one', {
            noticeId: afterSnap.id,
            existingEventId: existingRunReconciliationEvent.id
        });
        return false;
    }
    const noticeModel = getModelFromSnapshot(UserNoticeModel, ctx, afterSnap);
    const activeRuns = yield noticeModel.getRuns();
    const noticeHasAllRelevantRuns = yield getNoticeHasAllRelevantRuns(afterSnap, activeRuns);
    if (!noticeHasAllRelevantRuns) {
        return true;
    }
    return false;
});
export const reconcileRunsForNotice = ({ ctx, noticeModel, newspaperSnap, options }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    getErrorReporter().logInfo('Reconciling runs for notice', {
        noticeId: noticeModel.id,
        noticeStatus: `${noticeModel.modelData.noticeStatus}`
    });
    if (!exists(newspaperSnap)) {
        getErrorReporter().logAndCaptureWarning('Cannot update notice runs because newspaper does not exist', {
            noticeId: noticeModel.id,
            newspaperId: newspaperSnap.id
        });
        return;
    }
    const timezone = newspaperSnap.data().iana_timezone;
    // Most notices will not have publication dates until they are submitted, so in most cases this check isn't necessary,
    // but that's not the case for duplicated notices, which have data (including publication dates) immediately copied to
    // the `usernotice` object prior to submission, but they don't get a status until they are submitted, so we check for that here.
    if (!noticeIsSubmitted(noticeModel)) {
        getErrorReporter().logInfo('Notice is not yet submitted; will not reconcile runs', {
            noticeId: noticeModel.id
        });
        return;
    }
    const allRunsForNotice = yield noticeModel.getRuns({
        includeDisabled: true,
        includeCancelled: true,
        sortOrder: 'asc'
    });
    const publicationDateStrings = (noticeModel.modelData.publicationDates || []).map(pubDate => getDateStringForDateInTimezone({
        date: pubDate.toDate(),
        timezone
    }));
    const getNewStatusForActiveRun = (run) => {
        if (noticeModel.isCancelled) {
            if (run.isVerified() || run.isCancelled()) {
                return null;
            }
            return RunStatusType.CANCELLED;
        }
        if (run.isDisabled()) {
            return RunStatusType.PENDING;
        }
        return null;
    };
    const statusChangedAt = (_b = options === null || options === void 0 ? void 0 : options.statusChangedAt) !== null && _b !== void 0 ? _b : ctx.timestamp();
    // For any new publication dates on the notice, create a run (if one doesn't already exist)
    // and make sure any previously disabled runs are re-enabled
    yield Promise.all(publicationDateStrings.map((publicationDateString) => __awaiter(void 0, void 0, void 0, function* () {
        let relevantRun = allRunsForNotice.find(run => run.modelData.publicationDate === publicationDateString);
        if (!relevantRun) {
            relevantRun = yield getOrCreateRunForNoticeAndPublicationDate({
                ctx,
                publicationDate: publicationDateString,
                noticeSnap: noticeModel
            });
        }
        const newStatus = getNewStatusForActiveRun(relevantRun);
        if (newStatus) {
            getErrorReporter().logInfo('Automatically updating status for active run', {
                runId: relevantRun.id,
                noticeId: noticeModel.id,
                currentStatus: relevantRun.modelData.status,
                newStatus,
                publicationDate: publicationDateString
            });
            yield relevantRun.updateStatus({
                status: newStatus,
                statusChangedAt
            });
        }
    })));
    // For any publication dates that are no longer on the notice, disable the runs
    // associated with those dates (if such runs exist)
    yield Promise.all(allRunsForNotice.map((run) => __awaiter(void 0, void 0, void 0, function* () {
        if (publicationDateStrings.includes(run.modelData.publicationDate) ||
            run.isDisabled()) {
            return;
        }
        getErrorReporter().logInfo('Disabling run', {
            runId: run.id,
            noticeId: noticeModel.id,
            currentStatus: run.modelData.status,
            publicationDate: run.modelData.publicationDate
        });
        yield run.updateStatus({
            status: RunStatusType.DISABLED,
            statusChangedAt
        });
    })));
});
export const isVerifiedRunStatus = (status) => {
    return verifiedRunStatuses.includes(status);
};
export const isUnverifiableRunStatus = (status) => {
    return status === RunStatusType.UNVERIFIABLE;
};
export const isVerifiableRunStatus = (status) => {
    return verifiableRunStatuses.includes(status);
};
export const isDisabledRunStatus = (status) => {
    return status === RunStatusType.DISABLED;
};
export const isCancelledRunStatus = (status) => {
    return status === RunStatusType.CANCELLED;
};
/**
 * @deprecated Use RunService.getRunModelsFromQuery instead, which is returns a `ResponseOrError`
 */
export const getRunModelsFromQuery = (ctx, query, options) => __awaiter(void 0, void 0, void 0, function* () {
    const querySnap = yield query.get();
    return querySnap.docs
        .map(runSnap => getModelFromSnapshot(RunModel, ctx, runSnap))
        .filter(runModel => {
        if (runModel.isDisabled()) {
            return options.includeDisabled;
        }
        if (runModel.isCancelled()) {
            return options.includeCancelled;
        }
        return true;
    });
});
export class RunService {
    constructor(firebaseContext) {
        this.firebaseContext = firebaseContext;
    }
    getRunStatusChangeEventsQueries(runs) {
        return runs.map(run => this.firebaseContext
            .eventsRef()
            .where('type', '==', RUN_STATUS_CHANGE)
            .where('ref', '==', run.ref)
            .orderBy('createdAt'));
    }
    getRunModelsFromQuery(query, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return safeAsync(() => getRunModelsFromQuery(this.firebaseContext, query, options))();
        });
    }
    getRunsByPublicationIssueAndStatuses(publicationIssue, statuses) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = this.firebaseContext
                .runsRef()
                .where('publicationIssue', '==', publicationIssue)
                .where('status', 'in', statuses);
            return this.getRunModelsFromQuery(query, {
                includeDisabled: true,
                includeCancelled: true
            });
        });
    }
}
export const __private = {
    createRun
};
