var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import React, { useState } from 'react';
import { apiPost } from 'api/typed';
import { logAndCaptureException } from 'utils';
import { useLoading } from 'lib/components/hooks/useLoading';
import { ColumnService } from 'lib/services/directory';
import VerifyNoticesTable from './verifyNoticesTable';
export default function VerifyNoticesModal({ activeOrganization, publicationIssuesAndRuns, setSuccessMessage, onClose }) {
    const [runIdsToVerify, setRunIdsToVerify] = useState(new Set([]));
    const onCheckRuns = (runIds, checked) => {
        const newRunIdsToVerify = new Set(runIdsToVerify);
        runIds.forEach(runId => checked ? newRunIdsToVerify.add(runId) : newRunIdsToVerify.delete(runId));
        setRunIdsToVerify(newRunIdsToVerify);
    };
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        const req = {
            runIds: Array.from(runIdsToVerify)
        };
        try {
            const { success, error } = yield apiPost('affidavit-automation/manually-verify-runs', req);
            if (!success) {
                throw new Error(error || 'Unknown error manually verifying runs');
            }
            setSuccessMessage({
                header: 'Verification Complete',
                body: 'Thank you for confirming that these notices ran!'
            });
            onClose();
        }
        catch (err) {
            logAndCaptureException(ColumnService.AFFIDAVITS, err, 'Unable to manually verify runs', {
                organizationId: activeOrganization.id
            });
        }
    });
    const [submitLoading, submitWithLoading] = useLoading();
    return (_jsx(Form, Object.assign({ id: "verify-notices-modal-form", onSubmit: () => __awaiter(this, void 0, void 0, function* () { return submitWithLoading(handleSubmit); }) }, { children: _jsx(Modal, Object.assign({ id: "verify-notices-modal", onClose: onClose, subtitle: "We will start processing your affidavits once you confirm that notices published as scheduled.", title: "Verify Notice Publication", primaryAction: {
                buttonText: 'Submit',
                type: 'submit',
                loading: submitLoading,
                formId: 'verify-notices-modal-form'
            }, secondaryActions: [
                {
                    buttonText: 'Cancel',
                    onClick: onClose,
                    type: 'button'
                }
            ], size: "3xl" }, { children: _jsxs("div", Object.assign({ className: "flex-col gap-6" }, { children: [_jsxs(Alert, Object.assign({ status: "warning", id: "publication-certification", icon: _jsx(ExclamationCircleIcon, { className: "text-column-yellow-700 h-5 w-5" }) }, { children: ["By checking the box next to a notice below, you are hereby certifying to Column that the notice ran in print in", ' ', activeOrganization.data().name, " on the date(s) listed."] })), _jsx(VerifyNoticesTable, { publicationIssuesAndRuns: publicationIssuesAndRuns, runIdsToVerify: runIdsToVerify, onCheckRuns: onCheckRuns })] })) })) })));
}
