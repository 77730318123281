import moment from 'moment';
export const timeStringToDate = (timeString) => {
    const date = new Date(Date.now());
    const h = timeString.split(':')[0];
    const m = timeString.split(':')[1];
    date.setHours(parseInt(h, 10), parseInt(m, 10));
    return date;
};
const pad = (n, width, padder) => {
    // eslint-disable-next-line no-param-reassign
    n += '';
    return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(padder) + n;
};
export const dateToTimeString = (date) => {
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    return `${pad(hours, 2, '0')}:${pad(minutes, 2, '0')}`;
};
export const getDeadlineOverrideKeyFromDate = (date) => `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
export const getDeadlineOverridePublicationDateFromKey = (key) => {
    const [month, day, year] = key.split('/');
    const publishingDate = new Date(Number(year), Number(month), Number(day));
    return publishingDate;
};
export const deadlineSettingsToDeadlineDate = (publishingDate, deadline) => {
    const savedTime = timeStringToDate(deadline.deadline.time);
    let deadlineMoment = moment(publishingDate)
        .set('day', deadline.deadline.dayEnum - 1)
        .set('hour', savedTime.getHours())
        .set('minute', savedTime.getMinutes());
    /* Because we are only storing date granularity up to the day in the
    deadline key, we should only subtract a week if the deadline is after
    the publishing DAY, rather than time; otherwise, the publishing time
    will be assumed to be 12:00 am, and any deadline on the same day of
    the week will be erroneously shown as 1 week earlier. */
    if (deadlineMoment.isAfter(publishingDate, 'day')) {
        deadlineMoment = deadlineMoment.subtract(1, 'week');
    }
    if (deadline.weeks) {
        deadlineMoment = deadlineMoment.subtract(deadline.weeks, 'week');
    }
    return deadlineMoment.toDate();
};
export const deadlineOverrideToDeadlineOverrideDate = (deadlineOverrideKey, deadline) => {
    const publishingDate = getDeadlineOverridePublicationDateFromKey(deadlineOverrideKey);
    return deadlineSettingsToDeadlineDate(publishingDate, deadline);
};
export const deadlineOverrideDateToDeadlineOverride = (publicationDate, deadlineOverrideDate, isPublishing) => {
    const deadlineSettings = {
        publish: isPublishing,
        dayEnum: publicationDate.getDay() + 1,
        deadline: {
            dayEnum: deadlineOverrideDate.getDay() + 1,
            time: dateToTimeString(deadlineOverrideDate)
        },
        weeks: moment(publicationDate)
            .endOf('day')
            .diff(deadlineOverrideDate, 'weeks')
    };
    return deadlineSettings;
};
