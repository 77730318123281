import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useHasPermission } from 'utils/useHasPermission';
import { Permissions } from 'lib/permissions/roles';
function ProcessPaymentModal({ setOpen, setProcessCheck, invoiceId, invoiceCanBeMarkedAsPaid = true, invoiceCanBePaidInColumn = true }) {
    const handlePaymentReceived = () => {
        setProcessCheck(true);
    };
    const permissionMarkInvoicePaid = useHasPermission(Permissions.INVOICES_MARK_PAID);
    const canMarkInvoicePaid = permissionMarkInvoicePaid && invoiceCanBeMarkedAsPaid;
    const handleProcessCard = () => {
        window.open(`${window.location.origin}/invoices/${invoiceId}/pay`);
        setOpen(false);
    };
    return (_jsx(FreeformCModal, Object.assign({ id: "process-payment-modal", setOpen: setOpen, header: "Accept a direct payment", body: "" }, { children: _jsxs("section", { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-4 text-sm leading-5 text-column-gray-700 mt-2 mb-4" }, { children: [canMarkInvoicePaid && (_jsxs("p", { children: ["If you have received payment outside of Column for this notice, click ", _jsx("b", { children: "Payment Received" }), " to mark this as paid."] })), invoiceCanBePaidInColumn && (_jsxs("p", { children: ["If you want to process a card payment for this notice, click", ' ', _jsx("b", { children: "Process Card" }), " to open the customer's invoice and input their card information."] }))] })), _jsxs("div", Object.assign({ className: "flex flex-col md:flex-row gap-2" }, { children: [canMarkInvoicePaid && (_jsx(ColumnButton, { id: "process-check", primary: true, onClick: handlePaymentReceived, buttonText: "Payment Received", type: "button" })), invoiceCanBePaidInColumn && (_jsx(ColumnButton, { id: "process-card", primary: true, onClick: handleProcessCard, buttonText: "Process Card", type: "button" }))] }))] }) })));
}
export default ProcessPaymentModal;
