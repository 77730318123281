import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import LoadingState from 'components/LoadingState';
import { OccupationType, OrganizationType } from 'lib/enums';
import NewspaperSettings from './publisher';
import HoldingCompanySettings from './holding_company';
import GovernmentSettings from './government';
import LawFirmSettings from './lawyer';
import OtherOrganizationsSettings from './other_organization';
function OrganizationSettings(props) {
    const { activeOrganization, user, authLoading } = props;
    if (!user || !activeOrganization || authLoading)
        return _jsx(LoadingState, {});
    const { occupation } = user.data();
    return (_jsxs(_Fragment, { children: [activeOrganization.data().organizationType ===
                OrganizationType.holding_company.value && (_jsx(HoldingCompanySettings, Object.assign({}, props))), activeOrganization.data().organizationType ===
                OrganizationType.newspaper.value && _jsx(NewspaperSettings, {}), occupation === OccupationType.lawyer.value && (_jsx(LawFirmSettings, { activeOrganization: activeOrganization, user: user })), occupation === OccupationType.government_official.value && (_jsx(GovernmentSettings, { activeOrganization: activeOrganization, user: user })), (occupation === OccupationType.other_organization.value ||
                occupation === OccupationType.funeral_director.value) && (_jsx(OtherOrganizationsSettings, { activeOrganization: activeOrganization, user: user }))] }));
}
const mapStateToProps = (state) => ({
    activeOrganization: state.auth.activeOrganization,
    user: state.auth.user,
    authLoading: state.auth.loading,
    userAuth: state.auth.userAuth
});
export default connect(mapStateToProps)(OrganizationSettings);
