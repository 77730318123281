import { sum } from 'lodash';
import { getColumnInches, getOffset, roundUp } from '../math';
import { floatToP2Float } from '../ui';
import { getSumImageHeightsFromDisplayParams } from './helpers';
import { isNoticeRate } from '../../types/rates';
const getEffectiveHeight = ({ rateRecord, displayParameters, columns }) => {
    var _a, _b, _c;
    if (((_a = rateRecord.images) === null || _a === void 0 ? void 0 : _a.flatRate) && ((_b = displayParameters.images) === null || _b === void 0 ? void 0 : _b.length)) {
        const totalLinearInches = displayParameters.height * columns;
        /*
        Orders that are sized by puppeteer (convertRenderingSizeToDisplayParams) return image width in
        terms of columns. Images in notices are assumed to take up the full width of the ad.
        */
        const totalImageLinearInches = sum(displayParameters.images.map(({ height, width }) => height * (isNoticeRate(rateRecord) ? columns : width)));
        return roundUp(totalLinearInches - totalImageLinearInches, rateRecord.roundOff);
    }
    const imageInches = getSumImageHeightsFromDisplayParams(displayParameters);
    const applicableHeight = ((_c = rateRecord.images) === null || _c === void 0 ? void 0 : _c.linesPerInch) === 0
        ? Math.max(displayParameters.height - imageInches, 0)
        : displayParameters.height;
    return getColumnInches(applicableHeight, columns, rateRecord.roundOff);
};
export const priceColumnInch = options => {
    const { rateRecord, applicableRate } = options;
    const offset = getOffset(rateRecord);
    const applicableHeight = getEffectiveHeight(options);
    const netColumnInches = Math.max(applicableHeight - offset, 0);
    return floatToP2Float(netColumnInches * applicableRate);
};
