var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import CButton from 'components/CButton';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ColumnService } from 'lib/services/directory';
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { logAndCaptureException } from 'utils';
export const ConfirmNoticeButton = ({ buttonStyles, disabled: externallyDisabled, notice, user }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const disabled = externallyDisabled || isLoading;
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            yield notice.confirm(user);
        }
        catch (e) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Failed to confirm notice using button', {
                noticeId: notice.id,
                userId: user.id
            });
            dispatch(ToastActions.toastError({
                headerText: 'Failed to confirm notice',
                bodyText: 'Please refresh the page and try again.'
            }));
        }
        setIsLoading(false);
    });
    return (_jsx(CButton, Object.assign({ id: "confirm-notice", middleClasses: 'col-span-9 text-left ml-2', className: classnames(buttonStyles, {
            'opacity-50 cursor-not-allowed': disabled
        }), startIcon: isLoading ? _jsx(LoadingSpinner, {}) : _jsx(CheckCircleIcon, { className: "h-6 w-6" }), disabled: disabled, onClick: handleClick }, { children: "Confirm Notice" })));
};
