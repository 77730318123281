import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Badge } from 'lib/components/Badge';
import { centsToExtendedCurrency } from 'lib/utils/rates';
/**
 * Shows a formatted version of the run rate
 */
export default function CurrencyBadge({ value }) {
    return (_jsx("span", Object.assign({ className: "mr-1" }, { children: _jsxs(Badge, { children: ["$", centsToExtendedCurrency(value)] }) })));
}
