var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { take, takeEvery, call, put, takeLatest, all } from 'redux-saga/effects';
import { appSagaSelect } from 'redux/hooks';
import { logAndCaptureException } from 'utils';
import { eventChannel } from 'redux-saga';
import { authSelector, AuthTypes } from 'redux/auth';
import { NoticeType, OccupationType, Product } from 'lib/enums';
import { getColumnRangeConfigForPublisher } from 'lib/notice/columns';
import { getCustomer, getOrCreateCustomerOrganization } from 'lib/notice/customer';
import { getMailDataFromNoticeOrDraft, getNoticeMailAndSetOnDraft } from 'lib/mail';
import { exists } from 'lib/types';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { generateFormattedFooter } from 'lib/headers_footers/footers';
import { checkForDefault, getShouldUpdateRateInEditOrDuplicationFlow } from 'routes/placeScroll/rates';
import { getDefaultColumnsForUserUserOrgWithNewspaper, getNoticeTypeFromNoticeData, removeUndefinedFields } from 'lib/helpers';
import { supportsDisplay } from 'lib/notice/rates';
import { getFirebaseContext, timestampOrDateToTimestamp } from 'utils/firebase';
import { selectColumns, selectDraftRef, selectDraftSnap, selectIsDisplayNoticeType, selectIsEditing, selectModularSizeRef, syncDynamicHeadersChange, selectNewspaper } from 'redux/placement';
import { selectColumnCountRangeConfig, selectCurrentlySelectedNoticeType } from 'routes/placeScroll/placeScrollSelectors';
import { getNoticeDraftFieldsFromPlacement, getPartialNoticeFromPlacement } from 'utils/dataCleaning';
import { getClosestFuturePublishingDay } from 'lib/utils/deadlines';
import moment from 'moment';
import { getDefaultInvoiceRecipient, getNewMadlibFileData, shouldDisableDate } from 'routes/placeScroll/helpers';
import { PlacementError } from 'lib/errors/PlacementError';
import { determineNoticeFilesToReattach, getFilesDataFromNoticeOrDraft, getNoticeFilesAndSetOnDraft } from 'lib/files';
import { isSupplementalUploadData } from 'lib/types/notice';
import { createDbPricingObjFromPlacement } from 'utils/pricing';
import { getAdTemplate } from 'lib/notice/template';
import { createNewNotice } from 'redux/placement/placementActions';
import { logInfo } from 'utils/logger';
import { getJustSubmittedDraft } from 'lib/notice/helpers';
import { ColumnService } from 'lib/services/directory';
import PlacementActions, { PlacementTypes, placementSelector } from '../redux/placement';
export function* watchNotice() {
    const draftRef = yield* appSagaSelect(selectDraftRef);
    if (!draftRef)
        return;
    // Open a new snapshot listener to the draft
    const draftChannel = eventChannel(emitter => draftRef.onSnapshot(emitter, err => logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Error listening to notice snapshot', {
        draftId: draftRef.id
    })));
    // Store a reference to the listener channel so we can close it later
    yield put(PlacementActions.setDraftSnapshotUnsubscribe(() => draftChannel.close()));
    // ignore the first update
    yield take(draftChannel);
    yield takeLatest(draftChannel, function* f(draftSnap) {
        yield put(PlacementActions.setDraftSnap(draftSnap));
        if (!exists(draftSnap)) {
            console.log(`Ignoring update on deleted draft ${draftSnap.id}`);
            return;
        }
        const { confirming } = yield* appSagaSelect(placementSelector);
        if (confirming) {
            console.log(`Ignoring update while confirming draft ${draftSnap.id}`);
            return;
        }
        const { text, confirmedHtml, proofStoragePath, referenceId, fixedPrice } = draftSnap.data();
        /**
         * When a draft is populated via Typeform, we need to sync its data to Redux.
         * `text` is a vague field name, but it seems to be the primary way we can
         * determine if a draft has been populated with content via Typeform.
         *
         * TODO: Investigate if there's a better way to listen for Typeform completion
         * and sync the draft to Redux
         */
        const isTypeformData = !!text;
        // Sync the reference ID for a typeform notice to the Redux state
        if (isTypeformData && referenceId) {
            yield put(PlacementActions.confirmReferenceId(referenceId));
        }
        // Sync the fixed price (if available) for a typeform notice to the Redux state
        if (isTypeformData && fixedPrice) {
            yield put(PlacementActions.setFixedPrice(fixedPrice));
        }
        // Sync the supplemental files from a typeform notice to the Redux state
        if (isTypeformData) {
            const filesArray = yield call(getFilesDataFromNoticeOrDraft, getFirebaseContext(), draftSnap.ref);
            const supplementalFiles = filesArray.filter(isSupplementalUploadData);
            if (supplementalFiles.length > 0) {
                yield put(PlacementActions.setFilesToAttach(supplementalFiles));
            }
        }
        if (proofStoragePath) {
            yield put(PlacementActions.setProofStoragePath(proofStoragePath));
        }
        // confirmedHtml should be set with the current value in the placement.confirmedHtml
        yield put(PlacementActions.setNoticeText(confirmedHtml || text));
    });
}
function* listenToNotice() {
    yield call(watchNotice);
}
function* updateDynamicHeader() {
    const { noticeType, previousNoticeType, newspaper } = yield* appSagaSelect(placementSelector);
    if (!newspaper)
        return;
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    const shouldUpdateDynamicHeader = (newspaperSnap.data().headerFormatString ||
        newspaperSnap.data().oneRunHeader) &&
        noticeType !== previousNoticeType;
    if (shouldUpdateDynamicHeader) {
        yield put(yield call(syncDynamicHeadersChange, newspaperSnap));
    }
}
function* fetchAndHydrateNoticeDataHelper(action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    if (!action.noticeId) {
        yield put(yield call(createNewNotice));
    }
    else {
        try {
            const noticeRef = getFirebaseContext()
                .userNoticesRef()
                .doc(action.noticeId);
            const notice = yield call([noticeRef, noticeRef.get]);
            // Prevent editing archived notices
            // TODO: We should perform the same logic used in notice details UI to check if user is able to edit
            if ((_a = notice.data()) === null || _a === void 0 ? void 0 : _a.isArchived) {
                yield put(PlacementActions.setPlacementError(new PlacementError('Notice cannot be edited after archiving. You may place a new notice.')));
            }
            yield put(PlacementActions.setEditing(!!((_b = notice.data()) === null || _b === void 0 ? void 0 : _b.noticeStatus)));
            yield put(PlacementActions.setOriginal(noticeRef));
            const draftSnaps = [];
            for (const doc of ((_c = notice.data()) === null || _c === void 0 ? void 0 : _c.drafts) || []) {
                const snapshot = yield call([doc, doc.get]);
                if (exists(snapshot))
                    draftSnaps.push(snapshot);
            }
            let draftSnap = null;
            const auth = yield* appSagaSelect(authSelector);
            /**
             *
             * if the drafts exist
             * if the user exists
             * if only one draft and no owner
             * anonymous user flow
             */
            if (draftSnaps.length) {
                if (auth.user) {
                    const { user } = auth;
                    if (draftSnaps.length === 1 && !((_d = draftSnaps[0].data()) === null || _d === void 0 ? void 0 : _d.owner)) {
                        [draftSnap] = draftSnaps;
                    }
                    else {
                        draftSnap =
                            draftSnaps.find(snap => {
                                const { owner } = snap.data();
                                return owner && owner.id === user.ref.id;
                            }) || null;
                    }
                }
                else {
                    draftSnap = draftSnaps.find(snap => !snap.data().owner) || null;
                }
            }
            // eslint-disable-next-line no-extra-boolean-cast
            if (auth.user &&
                auth.user.data().occupation !== OccupationType.publishing.value) {
                if (draftSnaps.length > 1) {
                    draftSnap = draftSnaps[draftSnaps.length - 1];
                }
                [draftSnap] = draftSnaps;
            }
            /**
             * When a notice is submitted, the onNoticeUpdate function will eventually
             * delete the draft that was used to create or edit the notice. However, this can take
             * several seconds. So if a user submits a notice and then immediately tries to edit it,
             * this creates a race condition where we hydrate the notice data using the draft that was
             * just submitted. Because other transformations occur in the onNoticeUpdate function (e.g.,
             * deleting the drafts mail and noticeFile subcollections), this can lead to incomplete data
             * loading in the edit flow.
             *
             * To prevent this, we check if the draft was just submitted and if so, assume it is about to be
             * delete and create a new draft.
             */
            const justSubmittedDraft = getJustSubmittedDraft(notice, draftSnaps);
            const draftSnapWasAlreadySubmitted = !!justSubmittedDraft && (draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.id) === justSubmittedDraft.id;
            if (draftSnapWasAlreadySubmitted) {
                logInfo('Draft snapshot was just submitted, will create new draft for user', {
                    noticeID: notice.id,
                    submittedDraftId: draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.id,
                    user: (_e = auth.user) === null || _e === void 0 ? void 0 : _e.id
                });
                draftSnap = null;
            }
            if (draftSnap) {
                const filesFromDraft = yield call(getFilesDataFromNoticeOrDraft, getFirebaseContext(), draftSnap.ref);
                const { noticeFiles: filesToReattach, error } = yield call(determineNoticeFilesToReattach, filesFromDraft);
                if (error) {
                    logAndCaptureException(ColumnService.WEB_PLACEMENT, error, 'Error thrown refreshing draft files', {
                        draftId: draftSnap.id,
                        noticeId: notice.id
                    });
                }
                const mailArray = yield call(getMailDataFromNoticeOrDraft, draftSnap.ref);
                yield put(PlacementActions.setDraftSnap(draftSnap));
                yield put(PlacementActions.setFilesToAttach(filesToReattach));
                yield put(PlacementActions.setMail(mailArray));
                yield put(PlacementActions.setDraft(draftSnap.ref));
                // The destructuring of `confirming` existed in old code before draftSnap was typed properly
                // Not certain whether/how `confirming` property is making it onto the draft since it's not typed there
                // But keeping this destructuring here so as not to introduce unwanted side effects
                const _p = draftSnap.data(), { proofStoragePath, confirming } = _p, data = __rest(_p, ["proofStoragePath", "confirming"]);
                yield put(PlacementActions.populateNoticeData(data));
                if (data.confirmedHtml || data.text) {
                    yield put(PlacementActions.setConfirmedText(data.confirmedHtml || data.text));
                }
                if ((_f = draftSnap.data()) === null || _f === void 0 ? void 0 : _f.newspaper) {
                    yield put(PlacementActions.setNewspaper(draftSnap.data().newspaper));
                }
                if (!((_g = draftSnap.data()) === null || _g === void 0 ? void 0 : _g.userId)) {
                    let user;
                    if (auth.user)
                        user = auth.user;
                    else {
                        const action = yield take(AuthTypes.SET_USER);
                        user = action.user;
                    }
                    if (user.data().occupation !== OccupationType.publishing.value) {
                        yield put(PlacementActions.setFiler(user.ref));
                    }
                }
                if (!((_h = draftSnap.data()) === null || _h === void 0 ? void 0 : _h.filedBy)) {
                    const filedBy = auth.isPublisher
                        ? undefined
                        : ((_k = (_j = auth.user) === null || _j === void 0 ? void 0 : _j.data()) === null || _k === void 0 ? void 0 : _k.activeOrganization) || undefined;
                    yield put(PlacementActions.setFiledBy(filedBy));
                }
            }
            else {
                const draftRef = getFirebaseContext().userDraftsRef().doc();
                logInfo('Creating new draft for user', {
                    noticeID: notice.id,
                    newDraftId: draftRef.id,
                    user: (_l = auth.user) === null || _l === void 0 ? void 0 : _l.id
                });
                const originalData = notice.data() || {};
                delete originalData.drafts;
                const draftObject = Object.assign(Object.assign({}, originalData), { original: notice.ref, owner: auth.user ? auth.user.ref : null });
                delete draftObject.editedAt;
                delete draftObject.lastEditedBy;
                delete draftObject.proofStoragePath;
                const drafts = ((_m = notice.data()) === null || _m === void 0 ? void 0 : _m.drafts) || [];
                drafts.push(draftRef);
                const noticeRef = notice.ref;
                yield call([noticeRef, noticeRef.update], { drafts });
                yield call([draftRef, draftRef.set], draftObject);
                if (auth.user) {
                    const { filesArray, error } = yield call(getNoticeFilesAndSetOnDraft, getFirebaseContext(), notice.ref, draftRef);
                    if (error) {
                        logAndCaptureException(ColumnService.WEB_PLACEMENT, error, 'Error thrown while copying notice files to draft', {
                            noticeId: notice.id,
                            draftId: draftRef.id
                        });
                    }
                    const mailArray = yield call(getNoticeMailAndSetOnDraft, notice.ref, draftRef);
                    yield put(PlacementActions.setFilesToAttach(filesArray));
                    yield put(PlacementActions.setMail(mailArray));
                    /**
                     * Whenever existing Madlib notice is drafted, we reupload the file from original notice and attach with newly created draft
                     * Reason: if we use same storage ref for all drafs and file deleted from the draft and draft did not save, then notice
                     * referencing to non-existent storage.
                     */
                    if (((_o = draftObject.madlibData) === null || _o === void 0 ? void 0 : _o.questionTemplateData) &&
                        !getLocationParams().get('duplicate')) {
                        const updatedMadlibData = yield call(getNewMadlibFileData, draftObject.madlibData, noticeRef.id, draftRef.id);
                        if (updatedMadlibData) {
                            draftObject.madlibData = updatedMadlibData;
                        }
                    }
                }
                draftSnap = yield call([draftRef, draftRef.get]);
                yield put(PlacementActions.populateNoticeData(draftObject));
                if (draftObject.confirmedHtml || draftObject.text) {
                    yield put(PlacementActions.setConfirmedText(draftObject.confirmedHtml || draftObject.text));
                }
                yield put(PlacementActions.setDraftSnap(draftSnap));
                yield put(PlacementActions.setDraft(draftRef));
            }
            const { modularSize } = (draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) || {};
            if (modularSize) {
                yield put(PlacementActions.setModularSizeId(modularSize.id));
            }
        }
        catch (e) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Placement: Error setting draft contents in fetchAndHydrateNoticeData:', {
                noticeId: action.noticeId
            });
            yield put(PlacementActions.setPlacementError(new PlacementError()));
        }
    }
}
function* fetchAndHydrateNoticeData(action) {
    yield put(PlacementActions.resetState());
    yield call(fetchAndHydrateNoticeDataHelper, action);
}
function* saveDraft() {
    var _a;
    const placement = yield* appSagaSelect(placementSelector);
    const { draft } = placement;
    if (!draft) {
        return;
    }
    const updateObject = getNoticeDraftFieldsFromPlacement(placement);
    /**
     * Note: somewhat unclear what the following condition is meant
     * to catch. Since updateObject is derived from placement fields,
     * placement.displayParams and updateObj.displayParams
     * should either be both existent or both nonexistent
     *
     * See tests in dataCleaning
     */
    if (!updateObject.displayParams ||
        !Object.keys(placement.displayParams || {}).length) {
        delete updateObject.displayParams;
    }
    // Remove imgs from the display params before saving in the database
    // as this field is *massive*
    if ((_a = updateObject.displayParams) === null || _a === void 0 ? void 0 : _a.imgs) {
        const newDisplayParams = Object.assign({}, updateObject.displayParams);
        delete newDisplayParams.imgs;
        updateObject.displayParams = newDisplayParams;
    }
    try {
        updateObject.userId = placement.filer ? placement.filer.id : null;
        if (!updateObject.publicationDates) {
            updateObject.publicationDates = null;
        }
        const placementDraft = yield call([
            draft,
            draft.get
        ]);
        if (exists(placementDraft)) {
            // TODO(BACKEND-212): This cast should not be necessary but our types currently
            // don't make complete sense since EPlacement uses nullable fileds while ENoticeDraft
            // uses optional fields.
            const updatePartial = updateObject;
            updatePartial.modularSize = yield* appSagaSelect(selectModularSizeRef);
            yield call([draft, draft.update], removeUndefinedFields(updatePartial));
        }
    }
    catch (e) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Placement: Error saving draft in saveDraft', {
            draft: (draft === null || draft === void 0 ? void 0 : draft.id) || ''
        });
        yield put(PlacementActions.setPlacementError(new PlacementError()));
    }
}
/**
 * This generator update handles:
 *  1. Setting templates
 *  2. Re-setting the customer object
 *  3. Setting the notice type
 */
export function* processNewspaperUpdate(action) {
    var _a, _b, _c, _d;
    const placement = yield* appSagaSelect(placementSelector);
    if (action.newspaperRef) {
        const { newspaperRef } = action;
        try {
            const { draft, draftSnap, processedDisplay, columns, noticeType, customerOrganization } = placement;
            const newspaper = yield call([
                newspaperRef,
                newspaperRef.get
            ]);
            const { adTemplate, defaultColumns, defaultNoticeType, deadlines, deadlineOverrides = {} } = newspaper.data();
            // Reset placement data on newspaper update
            if (newspaper.id !== ((_b = (_a = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _a === void 0 ? void 0 : _a.newspaper) === null || _b === void 0 ? void 0 : _b.id) && draftSnap) {
                // Reset notice types to defaults for display/liner notices
                if (processedDisplay) {
                    yield put(PlacementActions.setNoticeType(NoticeType.display_ad.value));
                }
                else {
                    const defaultLinerNoticeType = defaultNoticeType || NoticeType.custom.value;
                    yield put(PlacementActions.setNoticeType(defaultLinerNoticeType));
                }
                // Reset publication dates as schedules vary between papers
                yield call([draftSnap.ref, draftSnap.ref.update], {
                    publicationDates: getFirebaseContext().fieldValue().delete(),
                    fixedPrice: getFirebaseContext().fieldValue().delete()
                });
                if (!deadlines)
                    throw new Error('No deadlines found for newspaper');
                yield put(PlacementActions.setPublicationDates([
                    getClosestFuturePublishingDay(deadlines, deadlineOverrides, newspaper.data().iana_timezone, placement, newspaper)
                ].map(timestampOrDateToTimestamp)));
            }
            /* When the newspaper is reset and the notice type has already been selected
            (e.g., when returning to placement from a Typeform), we do not want to
            miss setting any required publication dates, so we verify the publication dates again. */
            yield* verifyPublicationDates();
            // Before overriding the template, check if the notice has a template set by the
            // custom notice type
            const noticeTypeCustomTemplate = (_c = getNoticeTypeFromNoticeData({ noticeType }, newspaper)) === null || _c === void 0 ? void 0 : _c.template;
            /* We should use the same template hierarchy when returning to the placement flow
            from a Typeform as we would if we were setting the template in a normal flow, so
            we use the same helper function */
            const adTemplateToUse = yield call(getAdTemplate, newspaper, noticeTypeCustomTemplate || null, customerOrganization);
            if (adTemplateToUse) {
                yield put(PlacementActions.setTemplate(adTemplateToUse));
            }
            else {
                yield put(PlacementActions.setTemplate(adTemplate));
            }
            // reset the number of columns if needed after the paper has been updated
            const { minColumns, maxColumns } = getColumnRangeConfigForPublisher(newspaper, processedDisplay);
            if (columns < minColumns) {
                yield put(PlacementActions.setColumns(minColumns));
            }
            else if (columns > maxColumns) {
                yield put(PlacementActions.setColumns(maxColumns));
            }
            if (defaultColumns && !((_d = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _d === void 0 ? void 0 : _d.columns)) {
                yield put(PlacementActions.setColumns(defaultColumns));
            }
            if (draft) {
                yield draft.update({
                    newspaper: newspaperRef
                });
            }
        }
        catch (e) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Placement: Error in processNewspaperUpdate');
            yield put(PlacementActions.setPlacementError(new PlacementError()));
        }
    }
}
export function* processAdTemplateChanges() {
    var _a, _b, _c;
    try {
        const { newspaper, filer, noticeType, customerOrganization } = yield* appSagaSelect(placementSelector);
        if (!newspaper)
            return;
        const filerSnap = filer
            ? yield call([filer, filer.get])
            : null;
        const advertiserOrg = (_a = filerSnap === null || filerSnap === void 0 ? void 0 : filerSnap.data()) === null || _a === void 0 ? void 0 : _a.organization;
        const advertiserOrgSnap = advertiserOrg
            ? yield call([advertiserOrg, advertiserOrg.get])
            : null;
        // `advertiserOrgSnap` and `customerOrganization` should be either both set or unset.
        // Having one of them set, means the customerOrganization is not yet updated accordingly.
        if ((advertiserOrgSnap && !customerOrganization) ||
            (!advertiserOrgSnap && customerOrganization)) {
            return;
        }
        const newspaperSnap = yield call([
            newspaper,
            newspaper.get
        ]);
        const chosenCustomType = (_c = (_b = newspaperSnap
            .data()) === null || _b === void 0 ? void 0 : _b.allowedNotices) === null || _c === void 0 ? void 0 : _c.find((nt) => nt.value === noticeType);
        const chosenCustomTypeTemplate = chosenCustomType
            ? chosenCustomType.template
            : null;
        const template = yield call(getAdTemplate, newspaperSnap, chosenCustomTypeTemplate, customerOrganization);
        yield put(PlacementActions.setTemplate(template));
    }
    catch (e) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Placement: Error in processAdTemplateChanges');
        yield put(PlacementActions.setPlacementError(new PlacementError()));
    }
}
export function* updateRates(action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { newspaper, draft, rate, noticeType, previousNoticeType, editing, original } = yield* appSagaSelect(placementSelector);
    if (!newspaper)
        return;
    const draftSnap = draft
        ? yield call([draft, draft.get])
        : undefined;
    if ((editing && draft) || getLocationParams().get('duplicate') === 'true') {
        const shouldUpdateRateInEditOrDuplicationFlow = yield call(getShouldUpdateRateInEditOrDuplicationFlow, action, { noticeType, previousNoticeType, rate, newspaper }, draftSnap);
        if (!shouldUpdateRateInEditOrDuplicationFlow) {
            return;
        }
    }
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    let oldRate;
    try {
        oldRate = rate ? yield call([rate, rate.get]) : undefined;
    }
    catch (err) {
        // Malformed rate references like rate/xyz instead of rates/xyz
        // caused hard to trace permissions errors
        logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Error getting rate in placement saga', {
            rateReference: rate === null || rate === void 0 ? void 0 : rate.path,
            noticeId: original === null || original === void 0 ? void 0 : original.id
        });
    }
    // if we are on a display notice, pull rates from the notice
    // type associated with "previous notice type"
    const relevantNoticeTypeForRates = noticeType === NoticeType.display_ad.value
        ? previousNoticeType
        : noticeType;
    const chosenCustomType = (_b = (_a = newspaperSnap
        .data()) === null || _a === void 0 ? void 0 : _a.allowedNotices) === null || _b === void 0 ? void 0 : _b.find(nt => nt.value === relevantNoticeTypeForRates);
    /**
     * Clear fixed price if was previously set on the draft
     *
     * If a user later switches back to a typeform notice (see ingestNotice) with a fixedPrice,
     * the fixedPrice will be set from zapier after setNoticeType is called
     */
    if ((draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.exists) && draftSnap.data().noticeType !== noticeType) {
        yield put(PlacementActions.setFixedPrice(null));
    }
    let newRate;
    const auth = yield* appSagaSelect(authSelector);
    if (auth.user) {
        newRate = yield call(checkForDefault, yield* appSagaSelect(placementSelector), newspaperSnap, noticeType);
    }
    const defaultRates = [
        (_c = newspaperSnap.data().defaultLinerRate) === null || _c === void 0 ? void 0 : _c.id,
        (_d = newspaperSnap.data().defaultDisplayRate) === null || _d === void 0 ? void 0 : _d.id
    ];
    const placementHasNewCustomAdvertiserRate = newRate && !defaultRates.includes(newRate.id);
    const isDisplay = noticeType === NoticeType.display_ad.value;
    /**
     * Order of precedence when setting rates:
     * - any custom rate associated with the notice type
     * - any custom rate associated with the advertiser
     * - newspaper defaults
     */
    // custom rate with advertiser
    if (placementHasNewCustomAdvertiserRate) {
        yield put(PlacementActions.setRate(newRate));
    }
    // custom rate with notice type for non display notices
    else if ((chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate) && !isDisplay) {
        yield put(PlacementActions.setRate(chosenCustomType.rate));
    }
    // newspaper defaults
    else if (isDisplay) {
        // determine if the existing rate is associated with the paper
        const previousRateIsAssociatedWithNoticeType = Boolean((_e = newspaperSnap
            .data()
            .allowedNotices) === null || _e === void 0 ? void 0 : _e.find(an => { var _a; return ((_a = an.rate) === null || _a === void 0 ? void 0 : _a.id) === (oldRate === null || oldRate === void 0 ? void 0 : oldRate.id); }));
        const previousRateCanRunOnPaper = Boolean(!((_f = oldRate === null || oldRate === void 0 ? void 0 : oldRate.data().organization) === null || _f === void 0 ? void 0 : _f.id) ||
            newspaperSnap.ref.id === ((_g = oldRate.data().organization) === null || _g === void 0 ? void 0 : _g.id));
        const previousRateIsCustomRate = Boolean((((_h = oldRate === null || oldRate === void 0 ? void 0 : oldRate.data().filers) === null || _h === void 0 ? void 0 : _h.length) || 0) > 0 ||
            ((_j = oldRate === null || oldRate === void 0 ? void 0 : oldRate.data().organizations) === null || _j === void 0 ? void 0 : _j.length));
        const customRateTypeSnapshot = (chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate)
            ? yield call([chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate, chosenCustomType === null || chosenCustomType === void 0 ? void 0 : chosenCustomType.rate.get])
            : null;
        // if we are swapping notice types and the old rate
        // supports display, use it still!
        if (exists(customRateTypeSnapshot) &&
            supportsDisplay(customRateTypeSnapshot.data())) {
            yield put(PlacementActions.setRate(customRateTypeSnapshot.ref));
        }
        else if (exists(oldRate) &&
            supportsDisplay(oldRate.data()) &&
            (previousRateCanRunOnPaper || previousRateIsCustomRate) &&
            !previousRateIsAssociatedWithNoticeType) {
            yield put(PlacementActions.setRate(oldRate.ref));
        }
        else {
            yield put(PlacementActions.setRate(newspaperSnap.data().defaultDisplayRate));
        }
    }
    else {
        yield put(PlacementActions.setRate(newspaperSnap.data().defaultLinerRate));
    }
}
export function* updateNoticeColumns() {
    var _a;
    const publisherOrganizationRef = yield* appSagaSelect(selectNewspaper);
    if (!publisherOrganizationRef)
        return;
    const newspaperSnapshot = yield call([
        publisherOrganizationRef,
        publisherOrganizationRef.get
    ]);
    const isEditing = yield* appSagaSelect(selectIsEditing);
    const draftSnap = yield* appSagaSelect(selectDraftSnap);
    const isDisplay = yield* appSagaSelect(selectIsDisplayNoticeType);
    const currentColumns = yield* appSagaSelect(selectColumns);
    const columnCountRangeConfig = yield* appSagaSelect(state => selectColumnCountRangeConfig(state, newspaperSnapshot));
    let newColumns = currentColumns;
    const selectedNoticeType = yield* appSagaSelect(state => selectCurrentlySelectedNoticeType(state, newspaperSnapshot));
    // If the custom type has a default number of columns, prefer that
    if (selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.defaultColumns) {
        newColumns = selectedNoticeType.defaultColumns;
    }
    else if (
    // Otherwise, if we're changing to a new notice type, use the default columns
    exists(draftSnap) &&
        draftSnap.data().noticeType !== draftSnap.data().previousNoticeType &&
        // Display ads will always have different values for `noticeType`
        // and `previousNoticeType`, so we should exclude them from this check
        !isDisplay) {
        newColumns = ((_a = newspaperSnapshot.data()) === null || _a === void 0 ? void 0 : _a.defaultColumns) || 1;
    }
    // For notice editing if notice type does not change, column width value should remain as previous selection
    if (isEditing &&
        exists(draftSnap) &&
        draftSnap.data().noticeType === draftSnap.data().previousNoticeType) {
        newColumns = currentColumns;
    }
    // Make sure the column value is bounded by the publisher and notice type min and max
    const { minColumns, maxColumns } = columnCountRangeConfig;
    newColumns = Math.max(minColumns, Math.min(newColumns, maxColumns));
    if (newColumns !== currentColumns) {
        yield put(PlacementActions.setColumns(newColumns));
        yield call(saveDraft);
    }
}
// Adds one more publication date to match the notice type required publication dates
const addAdditionalPublicationDate = (publicationDates, newspaper, placement, noticeType, isPublisher) => {
    let newPublicationDates = [...publicationDates];
    const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaper.data();
    if (!deadlines)
        throw new Error('No deadlines found for newspaper');
    // if we are adding an additional publication date to a notice that has required publications
    // and restricted publishing days, we need to change the first publication day because the closest
    // future publishing day for the newspaper may not match the closest future publishing day for the
    // notice type (e.g. if a notice type only publishes on Monday while the paper publishes Mon, Wed, Fri)
    if (publicationDates.length === 1 &&
        (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
        (noticeType === null || noticeType === void 0 ? void 0 : noticeType.restrictedPublicationDays) &&
        isPublisher) {
        newPublicationDates = [
            getClosestFuturePublishingDay(deadlines, deadlineOverrides, iana_timezone, placement, newspaper)
        ].map(timestampOrDateToTimestamp);
    }
    const nextPotentialPublishingDate = moment(publicationDates[publicationDates.length - 1].toMillis())
        .add((noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultDaysBetweenPublication) || 7, 'days')
        .toDate();
    const nextPublishingDate = getClosestFuturePublishingDay(deadlines, deadlineOverrides, iana_timezone, placement, newspaper, nextPotentialPublishingDate);
    newPublicationDates.push(nextPublishingDate);
    return newPublicationDates.map(timestampOrDateToTimestamp);
};
// When the publication dates update, we should check if the chosen dates are valid (ie: they are publishing days, not after the deadline)
// When notice type update, we should check if the notice type has restrictions on number of the dates or on specific publishing days
export function* verifyPublicationDates() {
    var _a;
    const placement = yield* appSagaSelect(placementSelector);
    const auth = yield* appSagaSelect(authSelector);
    const { newspaper, publicationDates, draftSnap, editing, publicationDatesUpdated, previousNoticeType } = placement;
    if (!newspaper)
        return;
    const newspaperSnapshot = yield call([
        newspaper,
        newspaper.get
    ]);
    const isPublisher = !!auth.isPublisher;
    /**
     * It's not clear to me why we pull pubdates from the draft
     * As the draft does not always have the most updated data.
     * Updated the noticeType call to pull from placement instead
     * of the draft, but didn't want to touch the pubdates logic
     * to prevent breaking anything.
     */
    if (exists(newspaperSnapshot) && exists(draftSnap)) {
        const noticeType = getNoticeTypeFromNoticeData({ previousNoticeType, noticeType: placement.noticeType }, newspaperSnapshot);
        let requiredPublications = 0;
        if (!isPublisher && !editing) {
            requiredPublications = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) || 0;
        }
        else if (!isPublisher && editing) {
            requiredPublications = ((_a = draftSnap.data().publicationDates) === null || _a === void 0 ? void 0 : _a.length) || 0;
        }
        else if (isPublisher) {
            requiredPublications = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) || 0;
        }
        const numPublicationDates = (publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length) || 0;
        // If a new notice type is chosen that has fewer required publications than
        // the currently selected number of dates and the dates have not been
        // manually edited yet, we reset the dates back to the initial state.
        const hasTooManyPublicationDates = !isPublisher &&
            !editing &&
            !publicationDatesUpdated &&
            numPublicationDates > 0 &&
            requiredPublications > 0 &&
            numPublicationDates > requiredPublications;
        // When the notice type changes, it's possible we have invalid dates in
        // placement data, as one notice type may have different restricted days
        // from another. So if there are any invalid, we reset to one valid date.
        const disabledDates = publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.filter(day => {
            var _a;
            return shouldDisableDate({
                day: day.toDate(),
                newspaper: newspaperSnapshot,
                user: (_a = auth.user) !== null && _a !== void 0 ? _a : undefined,
                notice: placement,
                noticeType: noticeType !== null && noticeType !== void 0 ? noticeType : undefined,
                isPublisher: auth.isPublisher
            });
        });
        const hasInvalidDates = !publicationDates || numPublicationDates === 0 || !!(disabledDates === null || disabledDates === void 0 ? void 0 : disabledDates.length);
        const hasRestrictedPublicationDays = numPublicationDates === 0 &&
            (noticeType === null || noticeType === void 0 ? void 0 : noticeType.restrictedPublicationDays) &&
            isPublisher;
        // we want to set the publication dates to just the closest future publication date if
        // the notice has invalid dates, too many dates, or the notice type
        // has restricted publication days and does not have pub dates set yet
        if (hasInvalidDates ||
            hasTooManyPublicationDates ||
            hasRestrictedPublicationDays) {
            const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaperSnapshot.data();
            if (!deadlines)
                throw new Error('No deadlines found for newspaper');
            yield put(PlacementActions.setPublicationDates([
                getClosestFuturePublishingDay(deadlines, deadlineOverrides, iana_timezone, placement, newspaperSnapshot)
            ].map(timestampOrDateToTimestamp)));
            return;
        }
        const publisherHasEditedPublicationDates = isPublisher && publicationDatesUpdated;
        if (!publisherHasEditedPublicationDates &&
            (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
            publicationDates.length < requiredPublications) {
            // This only adds one date, but the change will fire a redux action and this
            // function will be triggered again until it's added all necessary dates.
            const newPublicationDates = addAdditionalPublicationDate(publicationDates, newspaperSnapshot, placement, noticeType, isPublisher);
            yield put(PlacementActions.setPublicationDates(newPublicationDates));
        }
    }
}
function* updateFooter() {
    var _a;
    const placement = yield* appSagaSelect(placementSelector);
    try {
        const { draft } = placement;
        if (!draft)
            return;
        const placementDraft = yield call([
            draft,
            draft.get
        ]);
        const partialNotice = getPartialNoticeFromPlacement(placement);
        let pricing;
        try {
            pricing = yield call(createDbPricingObjFromPlacement, placement, Product.Notice);
        }
        catch (e) {
            // Ignore errors, this happens when the pricing is incomplete
        }
        const footer = yield call(generateFormattedFooter, getFirebaseContext(), Object.assign(Object.assign({}, partialNotice), { customId: (_a = placementDraft.data()) === null || _a === void 0 ? void 0 : _a.customId }), pricing, window.DOMParser);
        yield put(PlacementActions.setDynamicFooter(footer));
        /**
         * Tech debt ticketed in COREDEV-1559:
         * It doesn't appear that the below block ever runs because
         * we are check the old placement which doesn't have the
         * updated dynamicFooter
         */
        if (placement.dynamicFooter && placementDraft.exists) {
            yield call([draft, draft.update], {
                dynamicFooter: placement.dynamicFooter
            });
        }
    }
    catch (e) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Placement: Error in updateFooter');
    }
}
// Override the default column width if the height extends beyond a threshold
function* processDisplayParameterUpdate({ displayParams }) {
    var _a;
    const { newspaper, columns } = yield* appSagaSelect(placementSelector);
    if (!newspaper)
        return;
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    const thresholds = (_a = newspaperSnap.data()) === null || _a === void 0 ? void 0 : _a.thresholds;
    if (!thresholds)
        return;
    if (!displayParams)
        return;
    let minimumColumnSize = columns;
    const totalColumnInches = displayParams.height * columns;
    /**
     * We need to sort in ascending order to set the correct column width based on the high threshold.
     * Without this higher value of totalColumnInches continues and Column resets to lower threshold column value
     * */
    Object.keys(thresholds)
        .sort((a, b) => {
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);
        return numA - numB;
    })
        .forEach(thresholdSize => {
        if (parseFloat(thresholdSize) > totalColumnInches)
            return;
        minimumColumnSize = thresholds[thresholdSize];
    });
    if (minimumColumnSize > columns)
        yield put(PlacementActions.setColumns(minimumColumnSize));
}
function* processColumnUpdate() {
    const { newspaper, columns, noticeType, adTemplate } = yield* appSagaSelect(placementSelector);
    if (noticeType === NoticeType.display_ad.value)
        return;
    if (!newspaper)
        return;
    const newspaperSnap = yield call([
        newspaper,
        newspaper.get
    ]);
    const { templateThresholds } = newspaperSnap.data();
    if (!templateThresholds)
        return;
    let updatedTemplate = adTemplate;
    Object.keys(templateThresholds)
        .sort()
        .forEach(columnThreshold => {
        var _a;
        const thresholdNumber = parseInt(columnThreshold, 10);
        if (columns < thresholdNumber)
            return;
        if ((updatedTemplate === null || updatedTemplate === void 0 ? void 0 : updatedTemplate.id) !== ((_a = templateThresholds[thresholdNumber]) === null || _a === void 0 ? void 0 : _a.id)) {
            updatedTemplate = templateThresholds[thresholdNumber];
        }
    });
    if ((updatedTemplate === null || updatedTemplate === void 0 ? void 0 : updatedTemplate.id) !== (adTemplate === null || adTemplate === void 0 ? void 0 : adTemplate.id)) {
        yield put(PlacementActions.setTemplate(updatedTemplate));
    }
}
/**
 * This generator function is called whenever a new filer is set for the notice.
 * All logic that needs to fire when the filer is updated should branch from this saga
 *
 */
export function* processFilerUpdate() {
    var _a, _b;
    try {
        const { filer, newspaper, filedBy, customerOrganization, customer, anonymousFilerId, owner, editing } = yield* appSagaSelect(placementSelector);
        const auth = yield* appSagaSelect(authSelector);
        if (!((_a = auth.userAuth) === null || _a === void 0 ? void 0 : _a.isAnonymous) && !!anonymousFilerId) {
            yield put(PlacementActions.setAnonymousFilerId(null));
        }
        // if we haven't set a paper or the filer, short circuit as we are still
        // too early in the placement flow
        if (!filer || !newspaper)
            return;
        // Update the draft owner if it has not been set previously
        if (!owner && !editing) {
            if ((_b = auth.user) === null || _b === void 0 ? void 0 : _b.ref) {
                yield put(PlacementActions.setOwner(auth.user.ref));
                yield put(PlacementActions.saveDraft());
            }
        }
        // pull associated data
        const userOrg = filedBy
            ? yield call([filedBy, filedBy.get])
            : null;
        // `advertiserOrgSnap` and `customerOrganization` should be either both set or unset.
        // Having one of them set, means the customerOrganization is not yet updated accordingly.
        if ((userOrg && !customerOrganization) ||
            (!userOrg && customerOrganization)) {
            return;
        }
        // determine the default number of columns needed for this user
        const defaultColumnsForPlacement = yield call(() => __awaiter(this, void 0, void 0, function* () {
            return getDefaultColumnsForUserUserOrgWithNewspaper(customer, customerOrganization);
        }));
        // only update columns if we have a new default on that user
        if (defaultColumnsForPlacement) {
            yield put(PlacementActions.setColumns(defaultColumnsForPlacement));
        }
    }
    catch (err) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Placement: Error in processFilerUpdate');
    }
}
/**
 * This generator function is called whenever the filer or the newspaper are updated to get or create the customer organization.
 * This function should prevent creating duplicates of customer organizations as it is the only one allowed to call getOrCreateCustomerOrganization()
 *
 */
export function* updateCustomerOrganization() {
    try {
        const { filer, newspaper, filedBy } = yield* appSagaSelect(placementSelector);
        if (!filer || !newspaper || !filedBy) {
            yield put(PlacementActions.setCustomerOrganization(null));
            return;
        }
        const advertiserOrg = yield call([
            filedBy,
            filedBy.get
        ]);
        const newspaperSnap = yield call([
            newspaper,
            newspaper.get
        ]);
        const ctx = getFirebaseContext();
        const customerOrgSnap = yield call(getOrCreateCustomerOrganization, ctx, advertiserOrg, newspaperSnap);
        yield put(PlacementActions.setCustomerOrganization(customerOrgSnap.ref || null));
    }
    catch (err) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Placement: Error in updateCustomerOrganization');
    }
}
export function* updateDefaultInvoiceRecipient() {
    try {
        const { filer, filedBy, customer, customerOrganization } = yield* appSagaSelect(placementSelector);
        if (!filer) {
            return;
        }
        const defaultInvoiceRecipient = yield call(getDefaultInvoiceRecipient, filer, filedBy, customer, customerOrganization);
        yield put(PlacementActions.setDefaultInvoiceRecipient(defaultInvoiceRecipient));
    }
    catch (err) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Placement: Error in updateDefaultInvoiceRecipient');
    }
}
/**
 * This generator function is called whenever the filer or the newspaper are updated to get or create the customer.
 * This function should prevent creating duplicates of customers as it is the only one allowed to call getOrCreateCustomer()
 *
 */
export function* updateCustomer() {
    try {
        const { filer, newspaper } = yield* appSagaSelect(placementSelector);
        const { userAuth } = yield* appSagaSelect(authSelector);
        /**
         * Don't set the customer if there's no filer or newspaper, as
         * both are required to fetch the customer
         *
         * Also don't set the customer in the anonymous flow - users only have
         * permission to do so after logging in
         */
        if (!filer || !newspaper || (userAuth === null || userAuth === void 0 ? void 0 : userAuth.isAnonymous)) {
            yield put(PlacementActions.setCustomer(null));
            return;
        }
        const newspaperSnap = yield call([
            newspaper,
            newspaper.get
        ]);
        const filerSnap = yield call([filer, filer.get]);
        const ctx = getFirebaseContext();
        /* We should not create a new customer in the placement flow until the very end when the notice is confirmed */
        const customerSnap = yield call(getCustomer, ctx, filerSnap, newspaperSnap);
        yield put(PlacementActions.setCustomer((customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.ref) || null));
    }
    catch (err) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Placement: Error in updateCustomer');
    }
}
function* placementSaga() {
    yield all([
        yield takeLatest(PlacementTypes.HYDRATE_NOTICE_DATA, fetchAndHydrateNoticeData),
        yield takeEvery(PlacementTypes.SAVE_DRAFT, saveDraft),
        yield takeEvery(PlacementTypes.SET_NEWSPAPER, processNewspaperUpdate),
        yield takeEvery([
            PlacementTypes.SET_CUSTOMER_ORGANIZATION,
            PlacementTypes.SET_CUSTOMER,
            PlacementTypes.SET_FILER
        ], processFilerUpdate),
        yield takeLatest([PlacementTypes.SET_CUSTOMER, PlacementTypes.SET_CUSTOMER_ORGANIZATION], updateDefaultInvoiceRecipient),
        yield takeEvery([
            PlacementTypes.SET_FILER,
            PlacementTypes.SET_NOTICE_TYPE,
            PlacementTypes.SET_NEWSPAPER,
            PlacementTypes.SET_CUSTOMER_ORGANIZATION
        ], processAdTemplateChanges),
        yield takeLatest([PlacementTypes.SET_CONFIRMED_CROP], saveDraft),
        yield takeEvery([
            PlacementTypes.POPULATE_NOTICE_DATA,
            PlacementTypes.SET_NOTICE_TEXT,
            PlacementTypes.SET_COLUMNS,
            PlacementTypes.SET_DISPLAY_PARAMS,
            PlacementTypes.CONFIRM_SCHEDULE
        ], updateFooter),
        yield takeLatest([PlacementTypes.SET_NOTICE_TYPE, PlacementTypes.RESET_COLUMNS], updateNoticeColumns),
        yield takeLatest([
            PlacementTypes.SET_NOTICE_TYPE,
            PlacementTypes.SET_PUBLICATION_DATES,
            PlacementTypes.HYDRATE_NOTICE_DATA,
            PlacementTypes.CONFIRM_SCHEDULE
        ], verifyPublicationDates),
        yield takeLatest([
            PlacementTypes.SET_NOTICE_TYPE,
            PlacementTypes.SET_CUSTOMER,
            PlacementTypes.SET_PREVIOUS_NOTICE_TYPE,
            PlacementTypes.SET_NEWSPAPER
        ], action => updateRates(action)),
        yield takeLatest(PlacementTypes.SET_DISPLAY_PARAMS, action => processDisplayParameterUpdate(action)),
        yield takeEvery(PlacementTypes.SET_COLUMNS, processColumnUpdate),
        yield takeLatest([
            PlacementTypes.SET_FILED_BY,
            PlacementTypes.SET_FILER,
            PlacementTypes.SET_NEWSPAPER,
            PlacementTypes.POPULATE_NOTICE_DATA
        ], updateCustomerOrganization),
        yield takeLatest([
            PlacementTypes.SET_FILER,
            PlacementTypes.SET_NEWSPAPER,
            PlacementTypes.POPULATE_NOTICE_DATA
        ], updateCustomer),
        yield takeLatest([PlacementTypes.SET_NOTICE_TYPE, PlacementTypes.SET_PUBLICATION_DATES], updateDynamicHeader),
        yield takeLatest(PlacementTypes.SET_DRAFT, listenToNotice)
    ]);
}
export default placementSaga;
