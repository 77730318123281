export const DEFAULT_LINER_MIN_COLUMNS = 1;
export const DEFAULT_DISPLAY_MIN_COLUMNS = 1;
export const DEFAULT_LINER_MAX_COLUMNS = 3;
export const DEFAULT_DISPLAY_MAX_COLUMNS = 6;
/**
 * Determines the minimum and maximum number of columns allowed for a given paper,
 * conditioned on whether or not that notice is a display notice. Conditioning on whether
 * or not is a display notice is important as papers can have different requirements.
 *
 * @param {ESnapshotExists<EOrganization>} publisherOrganization the publisher we are determining params for
 * @param {boolean} isDisplay whether or not it is a display notice
 */
export const getColumnRangeConfigForPublisher = (publisherOrganization, isDisplay) => {
    const { linerMinColumns, linerMaxColumns, displayMaxColumns, displayMinColumns } = (publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data()) || {};
    const minColumns = isDisplay
        ? displayMinColumns || DEFAULT_DISPLAY_MIN_COLUMNS
        : linerMinColumns || DEFAULT_LINER_MIN_COLUMNS;
    const maxColumns = isDisplay
        ? displayMaxColumns || DEFAULT_DISPLAY_MAX_COLUMNS
        : linerMaxColumns || DEFAULT_LINER_MAX_COLUMNS;
    return {
        minColumns,
        maxColumns: Math.max(minColumns, maxColumns)
    };
};
/**
 * Determines the minimum and maximum number of columns allowed for a given notice type
 */
function getColumnRangeConfigForNoticeType(noticeType, isDisplay) {
    const { linerMinColumns, linerMaxColumns, displayMaxColumns, displayMinColumns } = noticeType || {};
    const minColumns = (isDisplay ? displayMinColumns : linerMinColumns) || 1;
    const maxColumns = isDisplay ? displayMaxColumns : linerMaxColumns;
    return {
        minColumns,
        maxColumns
    };
}
/**
 * Determines the minimum and maximum number of columns allowed for a given publisher and notice type combo
 */
export function getColumnRangeConfig({ publisherOrganization, noticeType, isDisplayNotice }) {
    const publisherOrganizationColumnRange = getColumnRangeConfigForPublisher(publisherOrganization, isDisplayNotice);
    const noticeTypeColumnRange = getColumnRangeConfigForNoticeType(noticeType, isDisplayNotice);
    // The notice type column range should not extend beyond the publisher organization column range
    const minColumns = Math.max(publisherOrganizationColumnRange.minColumns, noticeTypeColumnRange.minColumns);
    const maxColumns = noticeTypeColumnRange.maxColumns
        ? Math.min(publisherOrganizationColumnRange.maxColumns, noticeTypeColumnRange.maxColumns)
        : publisherOrganizationColumnRange.maxColumns;
    return {
        minColumns,
        // Ensure that the max number of columns is *at least* the minimum number of columns
        maxColumns: Math.max(minColumns, maxColumns)
    };
}
