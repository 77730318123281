import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { GridInput } from './Grid';
import LabeledSwitch from '../LabeledSwitch';
/**
 * UI Component that combines a LabeledSwitch with a nested card of additional settings that only
 * appear when change the toggle current settings. For example, turned off; if turned on earlier
 * and vice versa.
 */
export function SwitchSelectionControlledCard({ header, children, labelProps, switchStateChanged }) {
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, Object.assign({}, labelProps)) })), switchStateChanged && (_jsx("div", Object.assign({ className: "col-span-2 bg-column-gray-25 p-6 border border-column-gray-100 rounded-md" }, { children: _jsxs("div", Object.assign({ className: "grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2 text-column-gray-400" }, { children: [header && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx("p", Object.assign({ className: "text-sm uppercase font-medium" }, { children: header })) }))), children] })) })))] }));
}
