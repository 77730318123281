var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { NotFoundError, wrapErrorAsColumnError } from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { wrapSuccess } from '../../types/responses';
import { findLayoutById, getDefaultLayoutsByFilingType } from '../../orders/layouts';
export class FilingTypeModel extends SnapshotModel {
    get type() {
        return Collections.filingTypes;
    }
    getRate() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: rate, error: rateError } = yield safeGetOrThrow(this.modelData.rate);
            if (rateError) {
                return wrapErrorAsColumnError(rateError, NotFoundError);
            }
            return wrapSuccess(rate);
        });
    }
    get supportedLayouts() {
        const populatedLayouts = populateLayoutsForFilingType(this.modelData);
        if (populatedLayouts) {
            return populatedLayouts;
        }
        return getDefaultLayoutsByFilingType(this.modelData);
    }
}
function populateLayoutsForFilingType(filingType) {
    const { supportedLayoutIds } = filingType;
    const hasSupportedLayouts = supportedLayoutIds && (supportedLayoutIds === null || supportedLayoutIds === void 0 ? void 0 : supportedLayoutIds.length) > 0;
    if (hasSupportedLayouts) {
        const populatedLayouts = supportedLayoutIds
            .map(findLayoutById)
            .filter((layout) => !!layout);
        if (populatedLayouts.length > 0) {
            return populatedLayouts;
        }
    }
    return null;
}
