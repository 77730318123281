import { jsx as _jsx } from "react/jsx-runtime";
import { TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import ClickableIconWrapper from '../ClickableIconWrapper';
/**
 * Determines the tooltip for the archive button in our table tow
 * @param rowData data for the table row
 * @param archivable archivable props for the table
 * @returns {string | undefined} tooltip for the archive button
 */
function getArchivedTooltip(rowData, archivable) {
    if (!archivable)
        return;
    if (archivable.isArchiveDisabled && archivable.isArchiveDisabled(rowData)) {
        return archivable.disabledArchiveTooltip;
    }
    if (archivable.enabledArchiveTooltip) {
        return archivable.enabledArchiveTooltip;
    }
}
export function ArchivableRowClickableIcon({ archivable, onArchive, rowData, index }) {
    if (!archivable) {
        return null;
    }
    return (_jsx(ClickableIconWrapper, { id: `archive-table-item-${index}`, onClick: () => onArchive(rowData), disabled: archivable.isArchiveDisabled && archivable.isArchiveDisabled(rowData), tooltip: getArchivedTooltip(rowData, archivable), icon: _jsx(TrashIcon, { className: classNames('w-5 h-5 text-column-gray-400', {
                'text-column-gray-50': archivable.isArchiveDisabled &&
                    archivable.isArchiveDisabled(rowData)
            }) }) }));
}
