import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
/**
 * We use this component to explicitly override the styles of the MDX docs and customize them
 * to our liking. This is a bit of a hack, but it's the best way to do it without ejecting or
 * doing a whole bunch of craziness to the MDX loader.
 * TODO: once we upgrade react, we can upgrade mdx, and in turn use the new MDXProvider API to
 * override the styles of the MDX docs without custom CSS injection.
 */
export default function DocumentationStyles() {
    return (_jsx("style", { children: `
        #column-docs {
          width: 500px;
          max-height: 100vh;
          overflow-y: scroll;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 16px;
        }

        #column-docs h1 {
          font-size: 2rem;
          font-weight: 600;
          color: rgba(18, 32, 45, var(--text-opacity));
        }

        #column-docs h2 {
          color: rgba(18, 32, 45, var(--text-opacity));
          font-size: 1.5rem;
          font-weight: 500;
          margin-top: 8px;
        }

        #column-docs p {
          color: rgba(18, 32, 45, var(--text-opacity));
          font-size: 1rem;
        }

        #column-docs code {
          background: #FEECEB;
          color: #F04438;
          font-size: 1rem;
        }

        #column-docs ol {
          list-style-type: decimal;
          margin-left: 16px;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        #column-docs ul {
          list-style-type: disc;
          margin-left: 16px;
          margin-top: 8px;
          margin-bottom: 8px;
        }
      ` }));
}
