import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FolderArrowDownIcon, CurrencyDollarIcon, ReceiptRefundIcon, CurrencyPoundIcon, DocumentPlusIcon, DocumentTextIcon, UserGroupIcon, ArrowPathIcon, ClipboardIcon, CalendarIcon, SwatchIcon, UsersIcon, HomeIcon, BanknotesIcon, MegaphoneIcon, IdentificationIcon } from '@heroicons/react/24/outline';
import { userHasPermission } from 'utils/permissions';
import { getBooleanFlag } from 'utils/flags';
import { Permissions } from 'lib/permissions/roles';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { CurrencyType, State } from 'lib/enums';
import classNames from 'classnames';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import { isColumnUser } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectHasClassifiedsActive, selectHasObituariesActive } from 'redux/auth';
export const GENERAL_INFO = 'general-setting';
export const ADVERTISING_DEADLINES = 'advertising-deadlines';
export const ORGANIZATION_MEMBER_SETTINGS = 'member-settings';
export const PAYMENT = 'payment-settings';
export const PRICING_RESTRICTIONS = 'pricing-settings';
export const ORGANIZATION_AFFIDAVIT_SETTINGS = 'affidavit-settings';
export const CUSTOM_BRAND = 'custom-brand';
export const DESIGN_AND_LAYOUT = 'design-and-layout';
export const FTP = 'ftp';
export const CUSTOMERS = 'customers';
export const INTEGRATIONS = 'integrations';
export const NOTICE_TYPES = 'notice-types';
export const BILLING = 'billing';
export const OBITUARIES = 'obituaries';
export const CLASSIFIEDS = 'classifieds';
function SidebarTab({ startIcon, labelText, onClick, active, id }) {
    return (_jsxs("div", Object.assign({ className: classNames('flex rounded font-medium text-base leading-5 mt-1 py-3 w-64 cursor-pointer', 'hover:bg-primary-50 hover:text-primary-500', {
            'bg-primary-50 text-primary-500': active,
            'text-column-gray-400': !active
        }), onClick: onClick, id: id }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-1/6" }, { children: startIcon })), _jsx("div", Object.assign({ className: "flex-1" }, { children: labelText }))] })));
}
export default function PublisherSettingsSidebar({ activeOrganization, onChange, value, user }) {
    var _a;
    const hasClassifiedsActive = useAppSelector(selectHasClassifiedsActive);
    const hasObituariesActive = useAppSelector(selectHasObituariesActive);
    const syncExportSettings = useSyncExportSettings(activeOrganization);
    const showIntegrationSettingsTab = syncExportSettings &&
        user &&
        (isColumnUser(user) ||
            userHasPermission(user, Permissions.SETTINGS_FEATURE_FLAGS));
    /*
      If obits or classifieds are active and the user has permission
      to view any of the nested tabs, we should show the parent tab
    */
    const obitsTabPermission = hasObituariesActive &&
        (userHasPermission(user, Permissions.SETTINGS_DEADLINES) ||
            userHasPermission(user, Permissions.SETTINGS_RATES) ||
            userHasPermission(user, Permissions.SETTINGS_ORDER_TYPES));
    const classifiedsTabPermission = hasClassifiedsActive &&
        (userHasPermission(user, Permissions.SETTINGS_DEADLINES) ||
            userHasPermission(user, Permissions.SETTINGS_RATES) ||
            userHasPermission(user, Permissions.SETTINGS_ORDER_TYPES));
    return (_jsxs("div", Object.assign({ className: "bg-white rounded-lg shadow py-3 px-4 h-full" }, { children: [userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) && (_jsx(SidebarTab, { onClick: () => onChange(GENERAL_INFO), active: value === GENERAL_INFO, id: GENERAL_INFO, labelText: "Publisher Info", startIcon: _jsx(HomeIcon, { className: "w-5 h-5" }) })), _jsx(SidebarTab, { onClick: () => onChange(ORGANIZATION_MEMBER_SETTINGS), active: value === ORGANIZATION_MEMBER_SETTINGS, id: ORGANIZATION_MEMBER_SETTINGS, labelText: "Members", startIcon: _jsx(UsersIcon, { className: "w-5 h-5" }) }), userHasPermission(user, Permissions.SETTINGS_CUSTOMERS) && (_jsx(SidebarTab, { onClick: () => onChange(CUSTOMERS), active: value === CUSTOMERS, id: CUSTOMERS, labelText: "Customers", startIcon: _jsx(UserGroupIcon, { className: "w-5 h-5" }) })), userHasPermission(user, Permissions.SETTINGS_DEADLINES) && (_jsx(SidebarTab, { onClick: () => onChange(ADVERTISING_DEADLINES), active: value === ADVERTISING_DEADLINES, id: ADVERTISING_DEADLINES, labelText: "Advertising Deadlines", startIcon: _jsx(CalendarIcon, { className: "w-5 h-5" }) })), userHasPermission(user, Permissions.SETTINGS_DESIGN_AND_LAYOUT) && (_jsx(SidebarTab, { onClick: () => onChange(DESIGN_AND_LAYOUT), active: value === DESIGN_AND_LAYOUT, id: DESIGN_AND_LAYOUT, labelText: "Design and Layout", startIcon: _jsx(DocumentPlusIcon, { className: "w-5 h-5" }) })), getBooleanFlag(LaunchDarklyFlags.ENABLE_NOTICE_TYPE_SETTINGS) &&
                userHasPermission(user, Permissions.SETTINGS_NOTICE_TYPES) && (_jsx(SidebarTab, { onClick: () => onChange(NOTICE_TYPES), active: value === NOTICE_TYPES, id: NOTICE_TYPES, labelText: "Notice Types", startIcon: _jsx(DocumentTextIcon, { className: "w-5 h-5" }) })), userHasPermission(user, Permissions.SETTINGS_RATES) && (_jsx(SidebarTab, { id: PRICING_RESTRICTIONS, labelText: "Rates", startIcon: _jsx(ReceiptRefundIcon, { className: "w-5 h-5" }), onClick: () => onChange(PRICING_RESTRICTIONS), active: value === PRICING_RESTRICTIONS })), userHasPermission(user, Permissions.SETTINGS_BILLING) && (_jsx(SidebarTab, { onClick: () => onChange(BILLING), active: value === BILLING, id: BILLING, labelText: "Billing", startIcon: _jsx(BanknotesIcon, { className: "w-5 h-5" }) })), user &&
                userHasPermission(user, Permissions.SETTINGS_CUSTOM_BRANDING) && (_jsx(SidebarTab, { onClick: () => onChange(CUSTOM_BRAND), active: value === CUSTOM_BRAND, id: CUSTOM_BRAND, labelText: "Custom Branding", startIcon: _jsx(SwatchIcon, { className: "w-5 h-5" }) })), userHasPermission(user, Permissions.SETTINGS_AFFIDAVITS) &&
                !activeOrganization.data().affidavitDisabled && (_jsx(SidebarTab, { onClick: () => onChange(ORGANIZATION_AFFIDAVIT_SETTINGS), active: value === ORGANIZATION_AFFIDAVIT_SETTINGS, id: ORGANIZATION_AFFIDAVIT_SETTINGS, labelText: "Affidavits", startIcon: _jsx(ClipboardIcon, { className: "w-5 h-5" }) })), userHasPermission(user, Permissions.SETTINGS_PAYMENT) && (_jsx(SidebarTab, { onClick: () => onChange(PAYMENT), active: value === PAYMENT, id: PAYMENT, labelText: "Payouts", startIcon: activeOrganization.data().currency === CurrencyType.gbp.key ? (_jsx(CurrencyPoundIcon, { className: "w-5 h-5" })) : (_jsx(CurrencyDollarIcon, { className: "w-5 h-5" })) })), showIntegrationSettingsTab && (_jsx(SidebarTab, { onClick: () => onChange(INTEGRATIONS), active: value === INTEGRATIONS, id: INTEGRATIONS, labelText: "Integration", startIcon: _jsx(ArrowPathIcon, { className: "w-5 h-5" }) })), (((_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.showUploads) ||
                activeOrganization.data().ftp) && (_jsx(SidebarTab, { onClick: () => onChange(FTP), active: value === FTP, id: FTP, labelText: "Statewide Site Uploads", startIcon: _jsx(FolderArrowDownIcon, { className: "w-5 h-5" }) })), obitsTabPermission && (_jsx(SidebarTab, { onClick: () => onChange(OBITUARIES), active: value === OBITUARIES, id: OBITUARIES, labelText: "Obituaries", startIcon: _jsx(IdentificationIcon, { className: "w-5 h-5" }) })), classifiedsTabPermission && (_jsx(SidebarTab, { onClick: () => onChange(CLASSIFIEDS), active: value === CLASSIFIEDS, id: CLASSIFIEDS, labelText: "Classifieds", startIcon: _jsx(MegaphoneIcon, { className: "w-5 h-5" }) }))] })));
}
