var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { createDBPricingFromNotice } from 'lib/pricing';
import { prettyFormatNumber } from 'routes/settings/publisher/rates/ratesTable/ratesTableSettingsUtils';
import { getFirebaseContext } from 'utils/firebase';
const getDescriptionFromLineItem = (lineItem) => {
    if (lineItem.description)
        return lineItem.description;
    if (lineItem.date) {
        return `Publication Fee - ${moment(lineItem.date).format('MM/DD/YYYY')}`;
    }
    return lineItem.description;
};
export const getInitialInvoiceCreationDataFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { lineItems } = yield createDBPricingFromNotice(getFirebaseContext(), notice);
    const lineItemsWithDescriptions = lineItems.map(lineItem => (Object.assign(Object.assign({}, lineItem), { description: getDescriptionFromLineItem(lineItem) })));
    return {
        requireUpFrontPayment: !!notice.data().requireUpfrontPayment,
        orderNumber: notice.data().customId || '',
        customMemo: '',
        lineItems: lineItemsWithDescriptions,
        dueDate: new Date(),
        taxPct: ((_a = notice.data().pricing) === null || _a === void 0 ? void 0 : _a.taxPct) || 0
    };
});
export const getInvoiceDataInvalidReason = (notice, rate, invoiceData) => {
    if (!notice) {
        return 'Notice missing';
    }
    if (!rate) {
        return 'Rate missing';
    }
    if (!invoiceData) {
        return 'Invoice data missing';
    }
    const total = invoiceData.lineItems.reduce((acc, lineItem) => acc + lineItem.amount, 0);
    if (rate.data().minimum && total < rate.data().minimum) {
        return `Minimum invoice amount is $${prettyFormatNumber(rate.data().minimum)}`;
    }
    return null;
};
