import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DocumentArrowUpIcon, DocumentArrowDownIcon, PencilIcon } from '@heroicons/react/24/outline';
import { isAffidavitDisabled } from 'lib/affidavits';
import { ColumnButton } from 'lib/components/ColumnButton';
import { cdnIfy } from 'lib/helpers';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import { isPublisher } from 'lib/utils/users';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { shouldUploadsBeDisabledForNotice } from './affidavitActionsUtils';
export default function AffidavitActionSectionHeader({ uploadFile, newspaper, loading, invoice, notice, user }) {
    const userIsPublisher = isPublisher(user);
    const { generatedAffidavitURL } = notice.data();
    const affidavitDisabled = isAffidavitDisabled(notice.data(), newspaper);
    const createDisabled = !invoice || affidavitDisabled;
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const uploadsDisabled = shouldUploadsBeDisabledForNotice(notice, newspaper, invoice, user);
    const isInvoice = !!invoice;
    const isESignDisabled = !generatedAffidavitURL || !userIsPublisher;
    return (_jsx("div", Object.assign({ className: "flex justify-between items-center" }, { children: userIsPublisher && (_jsxs("div", Object.assign({ className: "flex flex-col sm:flex-row gap-4" }, { children: [_jsx("div", { children: _jsx(CTooltip, Object.assign({ position: "right", classes: "flex-1", helpText: affidavitDisabled
                            ? 'Affidavit not required'
                            : !isInvoice
                                ? `You can't create an affidavit before invoice creation. You will receive an email reminder.`
                                : '' }, { children: notice.data().invoice && !notice.data().generatedAffidavitURL ? (_jsx(ColumnButton, { id: "generate-affidavit", startIcon: _jsx(PencilIcon, {}), disabled: !userIsPublisher || !generatedAffidavitURL, endIcon: _jsx("div", { className: "loader-yellow ease-linear rounded-full border-4 border-t-4 border-column-gray-50 h-6 w-6" }), buttonText: "Generating Affidavit", type: "button" })) : (_jsx(ColumnButton, { id: "download-affidavit-template", startIcon: _jsx(DocumentArrowDownIcon, { className: "w-6 h-6" }), tertiary: true, onClick: () => {
                                if (isESignDisabled ||
                                    !notice.data().generatedAffidavitStoragePath) {
                                    return;
                                }
                                window.open(cdnIfy(notice.data().generatedAffidavitStoragePath, {
                                    useImgix: true,
                                    useColumnCDN
                                }));
                            }, disabled: createDisabled, buttonText: "Download affidavit template", type: "button" })) })) }), _jsx("div", { children: _jsx(CTooltip, Object.assign({ classes: "flex-1", helpText: affidavitDisabled
                            ? 'Affidavit not required'
                            : !uploadsDisabled
                                ? 'Upload your completed affidavit.'
                                : uploadsDisabled && notice.data().affidavit
                                    ? 'To re-upload an affidavit, click on the trash icon and upload a new affidavit.'
                                    : `You can't upload an affidavit before publication and invoice creation. You will receive an affidavit upload email reminder.` }, { children: loading ? (_jsx(ColumnButton, { disabled: true, startIcon: _jsx("div", { className: "loader ease-linear rounded-full border-4 text-center border-t-4 border-column-yellow-400 h-6 w-6" }), buttonText: 'Loading', type: "button" })) : (_jsx(ColumnButton, { id: "upload-affidavit", disabled: uploadsDisabled, startIcon: _jsx(DocumentArrowUpIcon, { className: "w-6 h-6" }), onClick: () => {
                                var _a;
                                if (uploadsDisabled)
                                    return;
                                (_a = document.getElementById('contained-button-file')) === null || _a === void 0 ? void 0 : _a.click();
                            }, type: "button", secondary: true, buttonText: _jsxs("div", { children: ["Upload", _jsx("input", { type: "file", accept: "application/pdf", className: `hidden`, id: "contained-button-file", onChange: ({ target: { validity, files } }) => {
                                            validity.valid && files && uploadFile(files[0]);
                                        }, disabled: uploadsDisabled })] }) })) })) })] }))) })));
}
