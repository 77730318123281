var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wrapErrorAsColumnError, InternalServerError, NotFoundError } from '../errors/ColumnErrors';
import { FilingTypeVisibility, Product } from '../enums';
import { FilingTypeModel } from '../model/objects/filingTypeModel';
import { wrapError, wrapSuccess } from '../types/responses';
import { safeAsync } from '../safeWrappers';
import { ObituaryFilingTypeNames } from '../types/obituary';
import { ClassifiedFilingTypeNames } from '../types/classified';
import { safeGetModelFromRef } from '../model/getModel';
export class FilingTypeService {
    constructor(ctx) {
        this.ctx = ctx;
    }
    createFilingType(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: newFilingType, error: addError } = yield safeAsync(() => this.ctx.filingTypesRef().add(data))();
            if (addError) {
                return wrapErrorAsColumnError(addError, InternalServerError);
            }
            return safeGetModelFromRef(FilingTypeModel, this.ctx, newFilingType);
        });
    }
    createFilingTypesFromNames(names, authorizedOrganizationRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const filingTypeModels = [];
            for (const name of names) {
                const filingTypeData = {
                    authorizedOrganization: authorizedOrganizationRef,
                    label: name,
                    visibility: FilingTypeVisibility.disabled.value
                };
                const { response: filingTypeModel, error: filingTypeError
                // eslint-disable-next-line no-await-in-loop
                 } = yield this.createFilingType(filingTypeData);
                if (filingTypeError) {
                    return wrapError(filingTypeError);
                }
                filingTypeModels.push(filingTypeModel);
            }
            return wrapSuccess(filingTypeModels);
        });
    }
    createFilingTypesForProduct(product, authorizedOrganizationRef) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (product) {
                case Product.Classified:
                    return this.createFilingTypesFromNames(Object.values(ClassifiedFilingTypeNames), authorizedOrganizationRef);
                case Product.Obituary:
                    return this.createFilingTypesFromNames(Object.values(ObituaryFilingTypeNames), authorizedOrganizationRef);
                default:
                    return wrapError(new NotFoundError('Unrecognized product'));
            }
        });
    }
}
