import { jsx as _jsx } from "react/jsx-runtime";
import { centsToDollarsString } from 'lib/helpers';
import { Alert } from 'lib/components/Alert';
import PricingComparison from './PricingComparison';
import OrderSummaryRow from '../OrderSummaryRow';
function Pricing({ orderModel, showComparison, totalInCents }) {
    if (!totalInCents) {
        return _jsx(Alert, { id: "pricing-alert", title: "Missing total" });
    }
    if (showComparison) {
        return (_jsx(PricingComparison, { orderModel: orderModel, totalInCents: totalInCents }));
    }
    return (_jsx(OrderSummaryRow, { label: "Total Price", data: `$${centsToDollarsString(totalInCents)}` }));
}
export default Pricing;
