import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Tooltip } from 'lib/components/Tooltip';
import { ColumnButton } from 'lib/components/ColumnButton';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
export function CustomerDrawerFooter({ customerObjectSnap, onEditCustomer }) {
    const [isAccountOnHold, setIsAccountOnHold] = useState(customerObjectSnap.data().isAccountOnHold);
    return (_jsxs("div", Object.assign({ className: "absolute bottom-0 flex items-center w-full py-4 px-8 border-t bg-white justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(ColumnButton, { buttonText: isAccountOnHold ? 'Remove hold' : 'Put account on hold', primary: !isAccountOnHold, size: "lg", destructive: true, onClick: () => {
                            setIsAccountOnHold(!isAccountOnHold);
                            void customerObjectSnap.ref.update({
                                isAccountOnHold: !isAccountOnHold
                            });
                        }, type: "button" }), _jsx(Tooltip, Object.assign({ helpText: "Restrict this customer from placing a notice.", position: "top" }, { children: _jsx(QuestionMarkCircleIcon, { className: "h-5 w-5 ml-2.5 text-grey-300 stroke-2" }) }))] })), _jsx(ColumnButton, { id: "edit-customer-drawer-button", buttonText: "Edit", size: "lg", onClick: onEditCustomer, type: "button" })] })));
}
