import { useEffect } from 'react';
import { phoneFormattersByRegion } from './phoneFormat';
import { postalCodeFormattersByRegion } from './postalCodeFormat';
export const VALIDATION_CHECK_EVENT = 'validation-check';
/**
 * Dispatches a custom validation event to child fields of the given form
 * Child fields can listen to the validation event to determine when to show a validation status message
 * @param toForm
 */
export function dispatchValidationEvent({ toForm }) {
    const event = new Event(VALIDATION_CHECK_EVENT);
    const formElements = Array.from(toForm.elements);
    formElements.forEach(element => {
        element.dispatchEvent(event);
    });
}
export function useValidationEventListener({ onValidationEvent, inputRef }) {
    useEffect(() => {
        if (inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) {
            const inputElement = inputRef.current;
            // Display the validation status for this input when it received a custom validation check event
            inputElement.addEventListener(VALIDATION_CHECK_EVENT, onValidationEvent);
            return () => {
                inputElement.removeEventListener(VALIDATION_CHECK_EVENT, onValidationEvent);
            };
        }
    }, [Boolean(inputRef.current), onValidationEvent]);
}
export function getInputTypeValidationConfig(type, region = 'us') {
    if (type === 'currency') {
        return {
            props: {
                min: '0',
                step: '0.01'
            }
        };
    }
    if (type === 'tel') {
        return phoneFormattersByRegion[region];
    }
    if (type === 'postal-code') {
        return postalCodeFormattersByRegion[region];
    }
    return {};
}
const validityStatuses = [
    'badInput',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'typeMismatch',
    'valueMissing'
];
export function getCustomValidationMessage(validity, validationMessages) {
    const currentStatus = validityStatuses.find(status => validity[status]);
    return currentStatus ? validationMessages[currentStatus] : null;
}
