import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import NoticesActions from 'redux/notices';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { MagnifyingGlassIcon, ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useGlobalFilter, usePagination, useFilters, useSortBy, useTable } from 'react-table';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { publisherReadyToUpload } from 'lib/publishers';
import { exists } from 'lib/types';
import { OccupationType } from 'lib/enums';
import PlaceNoticeButton from 'components/PlaceNoticeButton';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { Tooltip } from 'lib/components/Tooltip';
import LinkTo from 'components/LinkTo';
import { SORT_DESCENDING, SORT_ASCENDING } from 'lib/types/searchable';
import { Popover, PopoverContext } from 'lib/components/Popover';
function NoticeFilterPopoverItem({ description, onClick }) {
    return (_jsx("div", Object.assign({ id: "show-all-notices", className: "p-3 hover:bg-column-primary-25 text-column-gray-500 hover:text-column-primary-500 cursor-pointer rounded-md font-medium text-sm", onClick: onClick }, { children: description })));
}
function NoticeFilterMenu({ onSetShowUserOnlyNotices }) {
    const { setOpen } = useContext(PopoverContext);
    return (_jsxs("div", Object.assign({ className: "w-40 p-2" }, { children: [_jsx(NoticeFilterPopoverItem, { onClick: () => {
                    onSetShowUserOnlyNotices(false);
                    setOpen(false);
                }, description: "All Notices" }), _jsx(NoticeFilterPopoverItem, { onClick: () => {
                    onSetShowUserOnlyNotices(true);
                    setOpen(false);
                }, description: "Only my notices" })] })));
}
function GlobalFilter({ actions, user, setSearchValue, setShowUserOnlyNotices, showUserOnlyNotices, searchValue }) {
    const showDropdownMenu = () => {
        var _a;
        if (!user)
            return false;
        if (!((_a = user.data()) === null || _a === void 0 ? void 0 : _a.activeOrganization))
            return false;
        if ([
            OccupationType.lawyer.value,
            OccupationType.government_official.value,
            OccupationType.other_organization.value
        ].includes(user.data().occupation))
            return true;
        return false;
    };
    const shouldShowDropdownMenu = showDropdownMenu();
    return (_jsxs("div", Object.assign({ className: "inline-flex float-right h-18 gap-3 items-center" }, { children: [_jsx("div", { className: "flex-1" }), shouldShowDropdownMenu && (_jsx(Popover, Object.assign({ id: "user-notice-list-filter-popover", activator: _jsx(ColumnButton, { buttonText: showUserOnlyNotices ? 'Only my notices' : 'All notices', endIcon: _jsx(ChevronDownIcon, { className: "h-5 w-5 text-column-gray-400 bold mt-1" }), type: "button" }), alignment: "right" }, { children: _jsx(NoticeFilterMenu, { onSetShowUserOnlyNotices: setShowUserOnlyNotices }) }))), _jsx("div", Object.assign({ className: classNames('rounded-md h-18 flex items-center', {
                    'ml-10': !actions
                }) }, { children: _jsx("div", Object.assign({ className: "w-64" }, { children: _jsx(TextField, { id: 'notices-search', labelText: '', placeholder: "Search Notices...", value: searchValue || '', size: "small", onChange: newValue => setSearchValue(newValue), prefix: _jsx(MagnifyingGlassIcon, { className: "w-5 text-column-gray-300 py-1", "aria-hidden": "true" }) }) })) })), actions && actions] })));
}
const mapStateToPropsForGlobalFilter = (state) => ({
    user: state.auth.user
});
const CGlobalFilter = connect(mapStateToPropsForGlobalFilter)(GlobalFilter);
function Table({ columns, data, actions, id, loading, tableState, sort, onPageChange, onRowsChange, onSort, setSearchValue, setShowUserOnlyNotices, showUserOnlyNotices, searchValue, user, activeOrganization, showAllOrgsNotices, emptyHeaderText, emptySubtitleText }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, page, state: { pageSize, sortBy }, setPageSize, setSortBy } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: tableState.currentPage,
            pageSize: tableState.rowCount || 5,
            globalFilter: '',
            sortBy: sort.field
                ? [
                    {
                        id: sort.field,
                        desc: sort.direction === SORT_DESCENDING
                    }
                ]
                : []
        },
        manualSortBy: true,
        manualPagination: true,
        autoResetSortBy: false,
        autoResetGlobalFilter: false
    }, useFilters, useGlobalFilter, useSortBy, usePagination);
    // Triggered when the sort is changed in the UI of the tab at hand
    useEffect(() => {
        onSort({
            field: sortBy[0] ? sortBy[0].id : null,
            direction: sortBy[0]
                ? sortBy[0].desc
                    ? SORT_DESCENDING
                    : SORT_ASCENDING
                : null
        });
    }, [sortBy]);
    // Triggered when the sort is changed in redux (the UI of a different tab than the one at hand)
    useEffect(() => {
        var _a, _b;
        if (!sort.field) {
            if (sortBy[0]) {
                setSortBy([]);
            }
        }
        else if (((_a = sortBy[0]) === null || _a === void 0 ? void 0 : _a.id) !== sort.field ||
            ((_b = sortBy[0]) === null || _b === void 0 ? void 0 : _b.desc) !== (sort.direction === SORT_DESCENDING)) {
            setSortBy([{ id: sort.field, desc: sort.direction === SORT_DESCENDING }]);
        }
    }, [sort]);
    useEffect(() => {
        if (loading)
            return;
        setPageSize(tableState.rowCount);
    }, [loading, tableState.rowCount]);
    const [showFilter, setShowFilter] = useState({
        id: null
    });
    if (!exists(user))
        return null;
    const isPublisher = user.data().occupation === OccupationType.publishing.value ||
        user.data().occupation === OccupationType.press_association_manager.value;
    const publisherCanSeeNewspaperSelectinPlacement = canPublisherUserSeeNewspaperSelect(user, true);
    const tableTitleHeadStyles = 'rounded-t-md bg-white mx-3 my-5 px-3 text-left';
    const tableHeadStyles = 'bg-base-2 px-6 border-b py-3 bg-white text-left text-sm leading-4 text-column-gray-400 text-md font-medium border-t tracking-wider';
    const tableBodyStyles = 'bg-white divide-y divide-gray-200';
    const tableRowStyles = 'p-0';
    let pageHeight = 'h-32';
    switch (page.length) {
        case 1:
            pageHeight = 'h-56 border-none';
            break;
        case 2:
            pageHeight = 'h-48 border-none';
            break;
        case 3:
            pageHeight = 'h-32 border-none';
            break;
        case 4:
            pageHeight = 'h-16 border-none';
            break;
        default:
            break;
    }
    return (_jsxs("div", Object.assign({ className: "min-w-full inline-block shadow-column-2 border border-column-gray-100 rounded-lg" }, { children: [_jsxs("table", Object.assign({ id: id, className: "min-w-full divide-y divide-gray-200 pt-4 border-b table-fixed" }, getTableProps(), { children: [_jsx("thead", { children: headerGroups.map((headerGroup, titleIndex) => (_createElement("tr", Object.assign({}, headerGroup.getHeaderGroupProps(), { id: `thead-${titleIndex}`, key: `thead-${titleIndex}`, className: "h-18" }), headerGroup.headers.map((column, index) => (_createElement("th", Object.assign({}, column.getHeaderProps(column.getSortByToggleProps()), { scope: "col", id: `thead-column-${index}`, key: `thead-column-${index}`, className: titleIndex === 0
                                ? tableTitleHeadStyles
                                : `${tableHeadStyles} ${column.width && column.width} ${index === 0 && 'whitespace-no-wrap'}`, onMouseEnter: () => setShowFilter({ id: column.id }), onMouseLeave: () => setShowFilter({ id: null }) }),
                            column.render('Header'),
                            titleIndex === 0 && (_jsx(CGlobalFilter, { setSearchValue: setSearchValue, actions: actions, setShowUserOnlyNotices: setShowUserOnlyNotices, showUserOnlyNotices: showUserOnlyNotices, searchValue: searchValue })),
                            titleIndex === 1 && !column.disableSortBy && (_jsx("span", { children: column.isSorted ? (column.isSortedDesc ? (_jsx("span", Object.assign({ className: "pl-2" }, { children: "\u2191" }))) : (_jsx("span", Object.assign({ className: "pl-2" }, { children: "\u2193" })))) : (_jsx("span", Object.assign({ className: `${showFilter.id === column.id
                                        ? 'text-column-gray-400 pl-2'
                                        : 'text-transparent pl-2'}` }, { children: "\u2193" }))) })))))))) }), rows.length > 0 && (_jsx("tbody", Object.assign({}, getTableBodyProps(), { className: tableBodyStyles }, { children: page.map((row, i) => {
                            prepareRow(row);
                            return (_createElement("tr", Object.assign({}, row.getRowProps(), { key: `tr-${i}`, id: row.original.id, className: "table-row hover:bg-base-2 h-18" }), row.cells.map((cell, index) => {
                                return (_createElement("td", Object.assign({}, cell.getCellProps(), { key: `td-${index}`, id: `td-${index}`, className: tableRowStyles }), cell.column.Header !== 'ACTIONS' ? (_jsx(LinkTo, Object.assign({ className: "block", href: `/${row.original.isdraft ? 'place' : 'notice'}/${row.original.orginalnoticeid || row.original.id}` }, { children: cell.render('Cell') }))) : (cell.render('Cell'))));
                            })));
                        }) })))] })), loading && (_jsx("span", Object.assign({ className: "bg-white h-56 m-auto pt-24 text-center block" }, { children: _jsx("div", Object.assign({ className: "flex justify-center align-center w-full" }, { children: _jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-column-gray-50 h-6 w-6" }) })) }))), !loading && rows.length === 0 && (_jsx(_Fragment, { children: _jsx("div", Object.assign({ id: "empty-table-banner", className: "bg-white w-full" }, { children: _jsxs("div", Object.assign({ className: "m-auto text-center pb-32 pt-20 w-100" }, { children: [_jsx("div", Object.assign({ style: {
                                    clipPath: 'circle()',
                                    marginBottom: '14px'
                                }, id: "empty-table-icon", className: "inline-block justify-center bg-column-yellow-400 w-24 h-24 overflow-hidden" }, { children: _jsx("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/58d1.a511f-postman.gif", className: "scale-125" }) })), _jsx("div", Object.assign({ className: "mb-3 text-column-gray-700 text-lg font-semibold" }, { children: emptyHeaderText })), _jsx("div", Object.assign({ className: "mb-6 text-column-gray-400 text-sm font-medium" }, { children: emptySubtitleText })), _jsx(Tooltip, Object.assign({ position: "bottom", helpText: showAllOrgsNotices &&
                                    !publisherCanSeeNewspaperSelectinPlacement
                                    ? 'Select a specific organization to upload notice'
                                    : isPublisher &&
                                        exists(activeOrganization) &&
                                        !publisherReadyToUpload(activeOrganization, true) &&
                                        !showAllOrgsNotices
                                        ? 'Your account implementation is still being finalized. A member of the Column team will reach out.'
                                        : '' }, { children: _jsx(PlaceNoticeButton, { id: "place-notice-button" }) }))] })) })) })), rows.length > 0 && page.length !== pageSize && page.length < 5 && (_jsx("p", { className: `bg-white ${pageHeight} m-auto pt-12 text-center` })), _jsx("footer", Object.assign({ className: "px-5 py-0.5 bg-column-gray-25 h-18 flex items-center w-full border-t rounded-b-lg" }, { children: _jsxs("nav", Object.assign({ className: "px-4 flex items-center justify-between sm:px-0 w-full" }, { children: [_jsx("div", Object.assign({ className: "sm:block flex-1 flex" }, { children: _jsxs("p", Object.assign({ className: "text-sm text-column-gray-400" }, { children: ["Showing", _jsx("span", Object.assign({ className: "font-medium mx-1" }, { children: page.length > 0 ? tableState.currentPage * pageSize + 1 : 0 })), "to", _jsx("span", Object.assign({ className: "font-medium mx-1" }, { children: tableState.currentPage
                                            ? tableState.currentPage * pageSize + page.length
                                            : rows.length })), "of", _jsx("span", Object.assign({ id: "total-results", className: "font-medium mx-1" }, { children: tableState.total })), "results"] })) })), _jsxs("div", Object.assign({ className: "-mt-px w-0 flex-1 flex justify-end" }, { children: [_jsx("select", Object.assign({ "aria-invalid": "false", value: pageSize, className: "select select-main bg-transparent text-column-gray-300 mr-2 focus:outline-none font-medium text-sm", onChange: e => {
                                        setPageSize(Number(e.target.value));
                                        onRowsChange && onRowsChange(Number(e.target.value));
                                        onPageChange(0);
                                    } }, { children: [5, 10, 20, 50, 100].map(rowSize => (_jsxs("option", Object.assign({ value: rowSize }, { children: [rowSize, " rows"] }), rowSize))) })), _jsx(ReactPaginate, { previousLabel: _jsx(ChevronLeftIcon, { className: "h-6 w-6" }), previousClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400", nextLabel: _jsx(ChevronRightIcon, { className: "h-6 w-6" }), nextClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400", breakLabel: '...', forcePage: tableState.currentPage, breakClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400", pageClassName: "focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400 hover:border-column-gray-100", activeLinkClassName: "focus:outline-none outline-none text-column-primary-500 border-column-primary-500", pageLinkClassName: "px-4 text-sm", pageCount: Math.ceil(Math.min(tableState.total, pageSize * 100) / pageSize), marginPagesDisplayed: 2, pageRangeDisplayed: 5, onPageChange: pageTo => {
                                        onPageChange && onPageChange(pageTo.selected);
                                    }, containerClassName: 'pagination flex', activeClassName: 'text-column-primary-500 border-column-primary-500 outline-none' })] }))] })) }))] })));
}
const mapStateToProps = (state) => ({
    activeOrganization: state.auth.activeOrganization,
    showAllOrgsNotices: !!state.auth.showAllOrgsNotices,
    user: state.auth.user
});
const mapDispatchToProps = (dispatch) => ({
    noticeActions: bindActionCreators(NoticesActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Table);
