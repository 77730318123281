import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { TextField } from 'lib/components/TextField';
import { SharedResourceAlert } from 'routes/settings/publisher/sharing/SharedResourceAlert';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
export default function TemplateOverviewCard({ onChange, value, isNoticeProduct }) {
    var _a, _b, _c;
    const [isDefault] = useState(value.useAsDefaultTemplate);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Template Overview',
            description: 'Configure basic details about your template.'
        } }, { children: [!!((_a = value.publisherOrganizations) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(SharedResourceAlert, { resourceNoun: "template", 
                    // Add 1 because the template is used by the number of items in this array plus the
                    // main owner
                    numOrganizations: value.publisherOrganizations.length + 1 }) }))), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "template-name", labelText: "Template name", required: true, value: value.name, onChange: name => onChange(Object.assign(Object.assign({}, value), { name })) }) })), isNoticeProduct && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: isDefault ? (_jsx(Alert, { id: "template-is-default", status: "success", icon: _jsx(InformationCircleIcon, { className: "w-5 h-5" }), title: "Default Template", description: `This is the default template for ${(_b = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.name}. To change the default template, please edit the desired template and assign it as the default.` })) : (_jsx(LabeledSwitch, { label: `Use this as the default template for ${(_c = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.name}?`, description: "If enabled, notices will use this template unless a custom template is assigned to the notice type or customer.", onChange: useAsDefaultTemplate => onChange(Object.assign(Object.assign({}, value), { useAsDefaultTemplate })), value: value.useAsDefaultTemplate })) })))] })));
}
