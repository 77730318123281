import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { HorizontalDivider } from 'lib/components/Card/Grid';
import { HeadersAndFooters } from './HeadersAndFooters';
import { NoticeSizes } from './NoticeSizes';
import { Borders } from './Borders';
import { FileUploads } from './FileUploads';
export function LayoutSettings() {
    return (_jsxs(_Fragment, { children: [_jsx(SettingsHeader, { header: "Layout", description: "Control design and layout options for your notices." }), _jsx(HeadersAndFooters, {}), _jsx(HorizontalDivider, {}), _jsx(NoticeSizes, {}), _jsx(HorizontalDivider, {}), _jsx(Borders, {}), _jsx(HorizontalDivider, {}), _jsx(FileUploads, {})] }));
}
