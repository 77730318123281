import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { selectActiveOrganization, selectIsClaimLogin, selectIsPublisher, selectUser, selectUserAuth } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { exists } from 'lib/types';
import { ColumnService } from 'lib/services/directory';
import LoadingState from '../../components/LoadingState';
import YourNoticesTableView from './table';
export default function Notices() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const isPublisher = useAppSelector(selectIsPublisher);
    const userAuth = useAppSelector(selectUserAuth);
    const user = useAppSelector(selectUser);
    const isClaimLogin = useAppSelector(selectIsClaimLogin);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if ((userAuth === null || userAuth === void 0 ? void 0 : userAuth.isAnonymous) || isClaimLogin) {
            dispatch(push('/login'));
        }
    }, [userAuth, isClaimLogin]);
    if (!exists(user)) {
        return (_jsx(LoadingState, { timeout: 60, context: {
                tags: {
                    userExists: `${exists(user)}`,
                    noticeLoadingVariant: 'missing-user'
                },
                service: ColumnService.WEB_PLACEMENT,
                location: 'YourNotices'
            } }));
    }
    if (isPublisher && !exists(activeOrganization)) {
        return (_jsx(LoadingState, { timeout: 60, context: {
                location: 'YourNotices',
                tags: {
                    missingActiveOrganization: `${!exists(activeOrganization)}`,
                    activeOrganizationId: (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id) || '',
                    noticeLoadingVariant: 'missing-active-organization'
                },
                service: ColumnService.WEB_PLACEMENT
            } }));
    }
    return (_jsx("div", Object.assign({ id: "yournotices" }, { children: _jsx(YourNoticesTableView, { user: user }) })));
}
