var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { exists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import { useInheritedProperty } from 'lib/frontend/hooks/useInheritedProperty';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import ConfirmEditDeadlineModal from './ConfirmEditDeadlineModal';
const getActiveOrgSettings = (activeOrganization) => {
    if (!exists(activeOrganization)) {
        return {};
    }
    const { requireConfirmation, cancelNoticeSettings } = activeOrganization.data();
    return Object.assign(Object.assign({}, cancelNoticeSettings), { requireConfirmation });
};
export default function GlobalDeadlineSettings({ onClose, onSubmit }) {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const activeOrgSettings = getActiveOrgSettings(activeOrganization);
    const [formState, setFormState] = useState(activeOrgSettings);
    const [parentSettings, setParentSettings] = useState({});
    const edited = Object.keys(activeOrgSettings).length
        ? !columnObjectsAreEqual(parentSettings, formState)
        : !columnObjectsAreEqual(activeOrgSettings, formState);
    const [saving, setSaving] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const inheritedCancelNoticeSettings = useInheritedProperty(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref, 'cancelNoticeSettings');
    const inheritedConfirmationSettings = useInheritedProperty(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref, 'requireConfirmation');
    const isRequireInvoiceInherited = (inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.requireInvoiceAtDeadline) !== undefined &&
        activeOrgSettings.requireInvoiceAtDeadline === undefined;
    const isRequireUpfrontPaymentInherited = (inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.requireUpfrontPaymentAtDeadline) !==
        undefined &&
        activeOrgSettings.requireUpfrontPaymentAtDeadline === undefined;
    const isConfirmationInherited = inheritedConfirmationSettings !== undefined &&
        activeOrgSettings.requireConfirmation === undefined;
    const isAllowInitiatedInherited = inheritedConfirmationSettings !== undefined &&
        activeOrgSettings.allowInitiated === undefined;
    const isInheritingSettingsFromParent = isRequireInvoiceInherited ||
        isRequireUpfrontPaymentInherited ||
        isConfirmationInherited ||
        isAllowInitiatedInherited;
    const showAlert = !!((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.parent) && isInheritingSettingsFromParent;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        var _a;
        const timeout = setTimeout(() => setLoading(false), 750);
        setParentSettings(removeUndefinedFields({
            allowInitiated: inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.allowInitiated,
            requireConfirmation: inheritedConfirmationSettings,
            requireInvoiceAtDeadline: inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.requireInvoiceAtDeadline,
            requireUpfrontPaymentAtDeadline: inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.requireUpfrontPaymentAtDeadline
        }));
        const shouldShowModal = !!((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.parent) && isInheritingSettingsFromParent;
        setShowModal(shouldShowModal);
        isInheritingSettingsFromParent
            ? setFormState(removeUndefinedFields({
                allowInitiated: inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.allowInitiated,
                requireConfirmation: inheritedConfirmationSettings,
                requireInvoiceAtDeadline: inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.requireInvoiceAtDeadline,
                requireUpfrontPaymentAtDeadline: inheritedCancelNoticeSettings === null || inheritedCancelNoticeSettings === void 0 ? void 0 : inheritedCancelNoticeSettings.requireUpfrontPaymentAtDeadline
            }))
            : setFormState(removeUndefinedFields(activeOrgSettings));
        return () => clearTimeout(timeout);
    }, [isInheritingSettingsFromParent]);
    if (!exists(activeOrganization)) {
        return null;
    }
    return (_jsx(FullScreenModal, Object.assign({ headerText: "Edit Deadline Settings", onClose: onClose, submittable: {
            buttonText: 'Save',
            onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                setSaving(true);
                yield onSubmit(formState);
                setSaving(false);
                onClose();
            }),
            disabled: saving || !edited
        }, id: '' }, { children: _jsx(_Fragment, { children: loading ? (_jsx("div", Object.assign({ className: "grid h-full place-items-center" }, { children: _jsx(LoadingSpinner, {}) }))) : showModal ? (_jsx(ConfirmEditDeadlineModal, { onOpen: () => {
                    setShowModal(false);
                }, onClose: () => onClose() })) : (_jsxs(CardGridLayout, Object.assign({ header: {
                    title: 'Global Deadline Settings',
                    description: 'Configure general preferences for your publication’s advertising deadlines.'
                } }, { children: [showAlert && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { id: "edit-deadline-cancel-warning", status: "warning", title: `This organization no longer shares settings with the parent organization`, icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }) }) }))), _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                            label: 'Cancel unpaid prepay notices at deadline?',
                            description: 'If enabled, notices will be automatically cancelled at ad deadline for the first publication date if upfront payment is required and the invoice is unpaid.',
                            value: !!formState.requireUpfrontPaymentAtDeadline,
                            onChange: requireUpfrontPaymentAtDeadline => {
                                setFormState(Object.assign(Object.assign({}, formState), { requireUpfrontPaymentAtDeadline }));
                            }
                        }, header: "" }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow initiated payments at deadline?", description: "If enabled, notices with pending payments initiated via ACH or paper check will NOT be automatically cancelled at ad deadline for the first publication date if upfront payment is required.", value: !!formState.allowInitiated, onChange: allowInitiated => {
                                    setFormState(Object.assign(Object.assign({}, formState), { allowInitiated }));
                                } }) })) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Cancel notices without invoices at deadline?", description: "If enabled, notices without invoices will be automatically cancelled at ad deadline for the first publication date.", value: !!formState.requireInvoiceAtDeadline, onChange: requireInvoiceAtDeadline => {
                                setFormState(Object.assign(Object.assign({}, formState), { requireInvoiceAtDeadline }));
                            } }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Cancel unconfirmed notices at deadline?", description: "If enabled, unconfirmed notices will be automatically cancelled at ad deadline for the first publication date.", value: !!formState.requireConfirmation, onChange: requireConfirmation => {
                                setFormState(Object.assign(Object.assign({}, formState), { requireConfirmation }));
                            } }) }))] }))) }) })));
}
