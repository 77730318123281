import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
/**
 * A switch component for use in forms in rates settings.
 */
export default function FormSwitch({ checked, onChange, disabled, id }) {
    return (_jsxs(Switch, Object.assign({ checked: checked, onChange: onChange, disabled: disabled, className: classNames('cursor-pointer relative inline-flex h-5 w-9 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2', {
            'bg-column-primary-500 hover:bg-primary-600': checked && !disabled,
            'bg-column-primary-200': checked && disabled,
            'bg-column-gray-50': !checked,
            'hover:bg-column-gray-200': !checked && !disabled,
            'cursor-pointer': !disabled,
            'cursor-not-allowed': disabled
        }), id: id }, { children: [_jsx("span", Object.assign({ id: id, className: "sr-only" }, { children: "Enable" })), _jsx("span", { "aria-hidden": "true", className: classNames(checked ? 'translate-x-4' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })] })));
}
