var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAffidavitSettingsForNewspaper } from '../../pricing/affidavits';
import { getDateStringForDateInTimezone } from '../../utils/dates';
import { getErrorReporter } from '../../utils/errors';
import { NotarizationVendor } from '../notarization';
import { wrapError, wrapSuccess } from '../responses';
const getAffidavitFeeSplit = (affidavitFeeSplit) => {
    if (affidavitFeeSplit === null || affidavitFeeSplit === void 0 ? void 0 : affidavitFeeSplit.feeSplit) {
        return affidavitFeeSplit.feeSplit;
    }
    return null;
};
const getAutomatedAffidavitFeeInCents = (automatedAffidavitFeeInCents) => {
    if (automatedAffidavitFeeInCents) {
        return automatedAffidavitFeeInCents;
    }
    return 500;
};
const getManagedAffidavitTemplate = (ctx, publisher, { managedAffidavitTemplate, managedAffidavitTemplateStoragePath }) => __awaiter(void 0, void 0, void 0, function* () {
    if (managedAffidavitTemplate) {
        return managedAffidavitTemplate;
    }
    if (!managedAffidavitTemplateStoragePath) {
        return undefined;
    }
    const templateQuery = ctx
        .affidavitTemplatesRef()
        .where('publisher', '==', publisher)
        .where('storagePath', '==', managedAffidavitTemplateStoragePath)
        .limit(1);
    const { docs, empty } = yield templateQuery.get();
    if (!empty) {
        return docs[0].ref;
    }
    getErrorReporter().logInfo('Creating new template ref from storage path', {
        managedAffidavitTemplateStoragePath
    });
    return yield ctx.affidavitTemplatesRef().add({
        isColumnManaged: true,
        name: `New Affidavit Template (${Date.now()})`,
        publisher,
        storagePath: managedAffidavitTemplateStoragePath,
        version: 'v2020-01-01'
    });
});
const getNotarizationVendor = (notarizationVendor) => {
    switch (notarizationVendor) {
        case 'notarize': {
            return NotarizationVendor.PROOF;
        }
        case 'manual': {
            return NotarizationVendor.PUBLIC_OATH;
        }
        default: {
            throw new Error(`Unknown notarization vendor: ${notarizationVendor}`);
        }
    }
};
const getReconciliationStartDate = (publisher, reconciliationStartDate) => {
    return getDateStringForDateInTimezone({
        date: reconciliationStartDate.toDate(),
        timezone: publisher.data().iana_timezone
    });
};
const getRequiresInStateNotary = (requiresInStateNotary) => {
    return !!requiresInStateNotary;
};
const getScraperConfiguration = (portalUrl) => {
    if (portalUrl) {
        return {
            portalUrl
        };
    }
    return null;
};
export const getPublisherAAC = (ctx, publisher, ars) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { affidavitsManagedByColumn, notarizationRequired, uploadMethod } = ars;
        const affidavitFeeSplit = getAffidavitFeeSplit(ars.affidavitFeeSplit);
        const automatedAffidavitFeeInCents = getAutomatedAffidavitFeeInCents(ars.automatedAffidavitFeeInCents);
        const managedAffidavitTemplate = yield getManagedAffidavitTemplate(ctx, publisher.ref, ars);
        if (!managedAffidavitTemplate) {
            throw new Error(`Could not identify a managed affidavit template.`);
        }
        const notarizationVendor = getNotarizationVendor(ars.notarizationVendor);
        const reconciliationStartDate = getReconciliationStartDate(publisher, ars.reconciliationStartDate);
        const requiresInStateNotary = getRequiresInStateNotary(ars.requiresInStateNotary);
        const scraperConfiguration = getScraperConfiguration(ars.editionPortalURL);
        return wrapSuccess({
            affidavitFeeSplit,
            affidavitsManagedByColumn,
            automatedAffidavitFeeInCents,
            managedAffidavitTemplate,
            notarizationRequired,
            notarizationVendor,
            reconciliationStartDate,
            requiresInStateNotary,
            scraperConfiguration,
            uploadMethod
        });
    }
    catch (error) {
        return wrapError(error);
    }
});
export const getOverrideAAC = (ctx, publisher, ars) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { affidavitsManagedByColumn, automatedAffidavitFeeInCents, notarizationRequired, requiresInStateNotary } = ars;
        const affidavitFeeSplit = ars.affidavitFeeSplit && getAffidavitFeeSplit(ars.affidavitFeeSplit);
        const managedAffidavitTemplate = yield getManagedAffidavitTemplate(ctx, publisher.ref, ars);
        const notarizationVendor = ars.notarizationVendor && getNotarizationVendor(ars.notarizationVendor);
        const reconciliationStartDate = ars.reconciliationStartDate &&
            getReconciliationStartDate(publisher, ars.reconciliationStartDate);
        return wrapSuccess({
            affidavitFeeSplit,
            affidavitsManagedByColumn,
            automatedAffidavitFeeInCents,
            managedAffidavitTemplate,
            notarizationRequired,
            notarizationVendor,
            reconciliationStartDate,
            requiresInStateNotary
        });
    }
    catch (err) {
        return wrapError(err);
    }
});
const getLastAssignedAt = (lastAssignedAt) => {
    if (lastAssignedAt) {
        return lastAssignedAt;
    }
    return null;
};
export const getNoticeAAC = (ctx, publisher, noticeARS) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { overrideNotarizationPreconditions } = noticeARS;
    const publisherARS = yield getAffidavitSettingsForNewspaper(publisher);
    if (!publisherARS) {
        return wrapError(new Error(`Publisher for notice does not have affidavitReconciliationSettings`));
    }
    const { response: baseAAC, error } = yield getPublisherAAC(ctx, publisher, Object.assign(Object.assign({}, publisherARS), noticeARS));
    if (error) {
        return wrapError(error);
    }
    const lastAssignedToNotarizationAt = getLastAssignedAt(noticeARS.lastAssignedToNotarizationOrMailCenterAt);
    const notarization = (_a = noticeARS.notarization) !== null && _a !== void 0 ? _a : null;
    return wrapSuccess({
        affidavitFeeSplit: baseAAC.affidavitFeeSplit,
        affidavitsManagedByColumn: baseAAC.affidavitsManagedByColumn,
        automatedAffidavitFeeInCents: baseAAC.automatedAffidavitFeeInCents,
        managedAffidavitTemplate: baseAAC.managedAffidavitTemplate,
        notarizationRequired: baseAAC.notarizationRequired,
        notarizationVendor: baseAAC.notarizationVendor,
        reconciliationStartDate: baseAAC.reconciliationStartDate,
        requiresInStateNotary: baseAAC.requiresInStateNotary,
        lastAssignedToNotarizationAt,
        notarization,
        overrideNotarizationPreconditions
    });
});
