var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Editor from '@monaco-editor/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import api from 'api';
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { PDFViewer } from 'lib/components/PDFViewer';
import TabGroup from 'lib/components/Tabs';
import { State } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { safeStringify } from 'lib/utils/stringify';
import { useState } from 'react';
const PREVIEW_TAB = {
    id: 'preview',
    label: 'Preview',
    enabled: true
};
const DYNAMIC_DATA_TAB = {
    id: 'dynamic-data',
    label: 'Dynamic Data',
    enabled: true
};
const getInitialAffidavitData = (activeOrganization) => {
    var _a, _b, _c;
    const stateData = {
        LABEL: (_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.label,
        ABBREVIATION: (_b = State.by_value(activeOrganization.data().state)) === null || _b === void 0 ? void 0 : _b.abbrev.toUpperCase()
    };
    return {
        PUBLICATION_NAME: activeOrganization.data().name,
        PUBLICATION_ADDRESS: activeOrganization.data().address,
        PUBLICATION_ADDRESS_LINE_2: activeOrganization.data().addressLine2,
        PUBLICATION_CITY: activeOrganization.data().city,
        PUBLICATION_STATE: stateData,
        PUBLICATION_ZIP: activeOrganization.data().zipCode,
        PUBLICATION_COUNTY: activeOrganization.data().county,
        PUBLICATION_PHONE: activeOrganization
            .data()
            .phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
        PUBLICATION_FREQUENCY: activeOrganization.data().publicationFrequency,
        AGENT_NAME: activeOrganization.data().signatoryName,
        AGENT_ROLE: activeOrganization.data().signatoryRole,
        COMMISSION_EXPIRATION_DATE: (_c = activeOrganization.data().commissionExpirationDate) !== null && _c !== void 0 ? _c : '',
        TOTAL_PAGES: 2,
        PUBLICATION_DATES: ['Mar 1, 2024', 'Apr 1, 2024'],
        NOTICE_ID: 'aioc7yq9824h198h19h3',
        NOTICE_NAME: 'Test Notice',
        NOTICE_ORDER_NUMBER: '123456',
        NOTICE_TYPE: 'Legal Notice',
        PRICING: {
            TOTAL: '$110.00',
            SUBTOTAL: '$100.00',
            PROCESSING_FEE: '$10.00'
        },
        RATE_DESCRIPTION: '$5 / Column Inch',
        LINES: '10',
        COLUMNS: '2',
        COLUMN_INCHES: '20.00',
        CUSTOMER_NAME: 'Test Customer',
        CUSTOMER_ADDRESS: '1234 Test St',
        CUSTOMER_ADDRESS_LINE_2: 'Apt 123',
        CUSTOMER_CITY: 'Test City',
        CUSTOMER_STATE: stateData,
        CUSTOMER_ZIP: '12345',
        CUSTOMER_PHONE: '(123) 456-7890',
        CUSTOMER_ACCOUNT_NUMBER: 'ABC123',
        MADLIB_TEMPLATE_DATA: {}
    };
};
export function AffidavitPreview({ activeOrganization, formData }) {
    const [tab, setTab] = useState(PREVIEW_TAB);
    const [dynamicData, setDynamicData] = useState(getInitialAffidavitData(activeOrganization));
    const debouncedFormData = useDebounce(formData
        ? {
            affidavitData: dynamicData,
            templateHandlebars: formData.affidavitTemplateHTML,
            logoSource: formData.logoSource,
            logoHeightInInches: formData.logoHeightInInches,
            blockWidthInInches: formData.blockWidthInInches,
            fontSize: formData.fontSize
        }
        : null, 1000);
    const { value: previewUrl, isLoading, isError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!debouncedFormData) {
                return null;
            }
            const { logoHeightInInches, blockWidthInInches, templateHandlebars, affidavitData, logoSource, fontSize } = debouncedFormData;
            const req = {
                templateHandlebars,
                logoHeightInInches,
                blockWidthInInches,
                affidavitData,
                logoSource,
                fontSize,
                newspaperId: activeOrganization.id
            };
            const res = yield api.post('documents/affidavits/preview', req);
            if (res.error) {
                throw res.error;
            }
            return res.response.downloadUrl;
        }),
        dependencies: [safeStringify(debouncedFormData)]
    });
    return (_jsxs(_Fragment, { children: [_jsx(TabGroup, { onClickTab: setTab, activeTab: tab, tabs: [PREVIEW_TAB, DYNAMIC_DATA_TAB], id: "affidavit-preview-tabs" }), _jsxs("div", Object.assign({ className: "px-10 py-6" }, { children: [tab.id === PREVIEW_TAB.id && (_jsx(_Fragment, { children: isLoading || !previewUrl ? (_jsx(LoadingSpinner, {})) : isError ? (_jsx(Alert, { status: "error", icon: _jsx(ExclamationCircleIcon, {}), title: "Error loading preview", id: "affidavit-preview-error" })) : (_jsx(PDFViewer, { startOpen: true, header: "Affidavit Preview", pdfUrl: previewUrl })) })), tab.id === DYNAMIC_DATA_TAB.id && (_jsx(Editor, { height: "75vh", className: 'rounded-md border border-column-gray-200 p-2 my-4', defaultLanguage: "html", defaultValue: JSON.stringify(dynamicData, null, 2), onChange: newContent => {
                            if (newContent) {
                                setDynamicData(JSON.parse(newContent));
                            }
                        }, options: {
                            wordWrap: 'on',
                            language: 'json',
                            minimap: { enabled: false }
                        } }))] }))] }));
}
