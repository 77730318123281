import { useCallback } from 'react';
import { useValidationEventListener } from '../../helpers/inputValidation';
export function useValidationChecks({ value, required, inputRef, validationMessages, setShowErrors, checkDependentValidations }) {
    const currentValidationMessage = getValidationMessage(value, required, {
        validationMessages,
        checkDependentValidations
    });
    const onValidationEvent = useCallback(() => setShowErrors(true), [
        setShowErrors
    ]);
    useValidationEventListener({ onValidationEvent, inputRef });
    return { currentValidationMessage };
}
function getValidationMessage(value, required, { validationMessages, checkDependentValidations }) {
    if (required && !value) {
        return (validationMessages === null || validationMessages === void 0 ? void 0 : validationMessages.valueMissing) || 'Please select an option';
    }
    const customValidationMessage = checkDependentValidations
        ? checkDependentValidations(value)
        : null;
    if (customValidationMessage) {
        return customValidationMessage;
    }
    return '';
}
