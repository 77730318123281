import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { useAppDispatch } from 'redux/hooks';
import CreateInvitesFormContainer from '../invites/CreateInvitesFormContainer';
import OrgSettingsMemberTable from './OrgSettingsMemberTable';
import SettingsHeader from './SettingsHeader';
export default function OrganizationMemberSettings({ user, activeOrganization }) {
    const dispatch = useAppDispatch();
    const [showInviteForm, setShowInviteForm] = useState(false);
    const [inviteModalHeaderText, setInviteModalHeaderText] = useState('Invite members to your organization');
    const [refresh, setRefresh] = useState(false);
    return (_jsxs("div", Object.assign({ className: "bg-white border box-border rounded-lg" }, { children: [_jsx(SettingsHeader, Object.assign({ header: "Organization", description: "These users belong to your organization." }, { children: userHasPermission(user, Permissions.ORGANIZATION_MODIFY_MEMBERS) && (_jsx(ColumnButton, { id: "invite", tertiary: true, size: "lg", startIcon: _jsx(PlusSmallIcon, { className: "h-6 w-6" }), buttonText: 'Invite Additional', onClick: () => {
                        setShowInviteForm(true);
                    }, type: "button" })) })), _jsx(OrgSettingsMemberTable, { setToastMessage: newMessage => dispatch(ToastActions.toastSuccess({
                    headerText: 'Success',
                    bodyText: newMessage
                })), activeOrganization: activeOrganization, setRefresh: setRefresh, refresh: refresh, user: user }), showInviteForm && (_jsx(FreeformCModal, Object.assign({ header: inviteModalHeaderText, setOpen: () => setShowInviteForm(false) }, { children: _jsx(CreateInvitesFormContainer, { organization: activeOrganization, onSetShowInviteForm: setShowInviteForm, onUpdateInviteModalHeaderText: setInviteModalHeaderText, onSetToastMessage: newMessage => dispatch(ToastActions.toastSuccess({
                        headerText: 'Success',
                        bodyText: newMessage
                    })) }) })))] })));
}
