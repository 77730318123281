var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import FileUpload from 'lib/components/FileUpload';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import { cdnIfy, getWordsFromNumber } from 'lib/helpers';
import { getFileTypeFromFile } from 'lib/files';
import { logAndCaptureException } from 'utils';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { ALLOWED_FINALIZED_DISPLAY_TYPES, isAllowedFinalizedDisplayType } from 'lib/asyncDesign/types';
import { usePublisherModularSizes } from 'routes/placeScroll/hooks/usePublisherModularSizes';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { apiPost } from 'api/typed';
import Firebase from 'EnoticeFirebase';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import AdDetailsCard from './AdDetailsCard';
export default function DisplayAdActions({ notice, newspaper }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    // TODO: In the future (if this is exposed beyond Column Reps) we may want to limit
    // the min/max columns based on the newspaper template and notice type.
    const [columns, setColumns] = useState();
    const [modularSizeId, setModularSizeId] = useState();
    const [uploadedFile, setUploadedFile] = useState();
    const [fileType, setFileType] = useState();
    const [uploadLoading, setUploadLoading] = useState(false);
    const { modularSizes, loading: modularSizesLoading } = usePublisherModularSizes(newspaper.ref);
    const templateId = (_a = newspaper.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id;
    const { value: templateColumnCount, isLoading: templateColumnCountLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!templateId) {
                return undefined;
            }
            try {
                const res = yield apiPost('templates/styles', {
                    templateId
                });
                if (res.success) {
                    return res.styles.columnCount;
                }
            }
            catch (e) {
                logAndCaptureException(ColumnService.AFFINITY_X, e, 'Failed to fetch template styles', {
                    templateId
                });
            }
            return undefined;
        }),
        dependencies: [templateId]
    });
    const loading = modularSizesLoading || templateColumnCountLoading || uploadLoading;
    const sizeOptions = [
        ...modularSizes.map(i => ({
            value: i.id,
            label: i.data().name,
            description: `${i.data().width.toFixed(2)}" x ${i
                .data()
                .height.toFixed(2)}"`
        })),
        { value: '', label: 'None' }
    ];
    const columnOptions = Array.from({
        length: templateColumnCount !== null && templateColumnCount !== void 0 ? templateColumnCount : 0
    }).map((v, index) => ({
        value: `${index + 1}`,
        label: getWordsFromNumber(index + 1)
    }));
    const hasModularSizes = !!modularSizes.length;
    const submitEnabled = !!uploadedFile && fileType;
    const resetForm = () => {
        setUploadedFile(undefined);
        setFileType(undefined);
        setColumns(undefined);
        setModularSizeId(undefined);
    };
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        var _c;
        const fileUrl = uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.url;
        if (!fileUrl || !fileType) {
            return;
        }
        const req = Object.assign(Object.assign({ noticeId: notice.id, url: fileUrl, fileType }, (columns ? { columns } : {})), (modularSizeId ? { modularSizeId } : {}));
        setUploadLoading(true);
        try {
            const res = yield apiPost('notices/async-design/upload-finalized-design', req);
            if (res.success) {
                dispatch(ToastActions.toastSuccess({
                    headerText: 'Success',
                    bodyText: 'Display ad file updated.'
                }));
                resetForm();
            }
            else {
                dispatch(ToastActions.toastError({
                    headerText: 'Failure',
                    bodyText: (_c = res.error) !== null && _c !== void 0 ? _c : 'There was an error uploading the display ad'
                }));
            }
        }
        catch (e) {
            logAndCaptureException(ColumnService.AFFINITY_X, e, 'Failed to upload async design', {
                noticeId: req.noticeId
            });
            dispatch(ToastActions.toastError({
                headerText: 'Failure',
                bodyText: 'There was an error uploading the display ad'
            }));
        }
        finally {
            setUploadLoading(false);
        }
    });
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    return (_jsx(AdDetailsCard, Object.assign({ id: "display-ad-actions", header: { title: 'Display Ad Design' } }, { children: _jsx(Form, Object.assign({ onSubmit: handleSubmit }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [!uploadedFile && (_jsx(FileUpload, { id: 'display-ad-file', acceptFileTypes: ALLOWED_FINALIZED_DISPLAY_TYPES.map(fileType => `.${fileType}`).join(','), uploadFolder: `documentcloud/display-ad-uploads/${notice.id}`, onFileUpload: (filesAndRefs) => __awaiter(this, void 0, void 0, function* () {
                            const file = filesAndRefs[0];
                            if (!file) {
                                setUploadedFile(undefined);
                                return;
                            }
                            const uploadFileType = getFileTypeFromFile(file.file);
                            if (!isAllowedFinalizedDisplayType(uploadFileType)) {
                                return;
                            }
                            setFileType(uploadFileType);
                            setUploadedFile({
                                name: file.file.name,
                                url: cdnIfy(file.uploadRef.fullPath, { useColumnCDN }),
                                storagePath: file.uploadRef.fullPath
                            });
                        }), storage: Firebase.storage() })), uploadedFile && (_jsxs(_Fragment, { children: [_jsx(FileUploadListItem, { firebaseStoragePath: uploadedFile.storagePath, onDelete: () => setUploadedFile(undefined), disableDelete: loading, storage: Firebase.storage() }, uploadedFile.storagePath), _jsxs("div", Object.assign({ className: "grid grid-cols-2 gap-4" }, { children: [_jsx(ColumnSelect, { id: 'select-ad-size', allowUndefined: true, options: sizeOptions, value: modularSizeId, disabled: !hasModularSizes || !!columns || loading, onChange: value => setModularSizeId(value), labelText: 'Ad Size' }), _jsx(ColumnSelect, { id: 'select-columns', labelText: 'Columns', placeholder: "Column width", options: columnOptions, allowUndefined: true, disabled: hasModularSizes || !!modularSizeId || loading, value: (_b = columns === null || columns === void 0 ? void 0 : columns.toString()) !== null && _b !== void 0 ? _b : '', onChange: value => setColumns(parseInt(value, 10) || undefined) })] })), _jsx(ColumnButton, { primary: true, type: "submit", buttonText: "Upload Finalized Ad", disabled: !submitEnabled, loading: loading })] }))] })) })) })));
}
