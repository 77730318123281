var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import NumberFormat from 'react-number-format';
function CurrencyFormatter(props) {
    const { inputRef, onChange, currency } = props, other = __rest(props, ["inputRef", "onChange", "currency"]);
    return (_jsx(NumberFormat, Object.assign({}, other, { getInputRef: inputRef, onValueChange: values => {
            onChange({
                target: {
                    value: values.value
                }
            });
        }, thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true })));
}
export default CurrencyFormatter;
