import { jsx as _jsx } from "react/jsx-runtime";
import { Button, SvgIcon } from '@material-ui/core';
import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
const styles = () => createStyles({
    cardButton: {
        backgroundColor: 'white',
        textTransform: 'none',
        marginRight: '7px',
        marginTop: '8px',
        width: '100%',
        color: '#4A5568',
        fontWeight: 400
    },
    cardButtonDisabled: {
        backgroundColor: '#c7c7c7',
        textTransform: 'none',
        paddingRight: '92px',
        marginRight: '7px',
        marginTop: '8px',
        color: '#4A5568',
        fontWeight: 400,
        cursor: 'not-allowed'
    }
});
function PayWithCreditCardButton({ disabled, selected, clickHandler, classes }) {
    return (_jsx(Button, Object.assign({ className: disabled ? classes.cardButtonDisabled : classes.cardButton, variant: "outlined", id: "stripe-card", onClick: () => {
            clickHandler('card');
        }, startIcon: _jsx(SvgIcon, Object.assign({ fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: selected ? '#4285F4' : 'currentColor', style: { fill: 'none' } }, { children: _jsx("path", { d: "M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" }) })), style: {
            color: selected ? '#4285F4' : '#4A5568',
            border: selected ? '1px solid #4285F4' : '1px solid #DADADA' //  blue border if selected, gray otherwise
        }, disabled: disabled }, { children: "Card" })));
}
export default withStyles(styles)(PayWithCreditCardButton);
