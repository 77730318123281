var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ColumnDatePicker from 'components/ColumnDatePicker';
import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Label } from 'lib/components/Label';
import { TextField } from 'lib/components/TextField';
import { Product, TimezoneType } from 'lib/enums';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { DeadlinePreview } from '../Preview';
import { getTimeOptionsForDeadlines } from '../getTimeOptionsForDeadlines';
import { getScheduleChangeSettingsForNewPublicationDate, scheduleChangesTableDataToPublisherDeadline } from './helpers';
const PREVIEW_TIME_FORMAT = 'HH:mm';
const getFixedPreviewDeadline = (updatedScheduleChange, deadlineTime) => moment(`${moment(updatedScheduleChange.deadlineDate).format('YYYY-MM-DD')}${deadlineTime}`, `YYYY-MM-DD${PREVIEW_TIME_FORMAT}`).toDate();
export function ScheduleChangeForm({ productLine, activeOrganization, scheduleChange, onSubmit, onClose }) {
    var _a, _b, _c;
    const productName = PRODUCT_TO_NAME[productLine].plural.toLocaleLowerCase();
    const [updatedScheduleChange, setUpdatedScheduleChange] = useState(scheduleChange);
    const [deadlineTime, setDeadlineTime] = useState(moment(scheduleChange.deadlineDate).format(PREVIEW_TIME_FORMAT));
    const [updating, setUpdating] = useState(false);
    const currDeadlineSettings = scheduleChangesTableDataToPublisherDeadline(scheduleChange);
    const updatedDeadlineSettings = scheduleChangesTableDataToPublisherDeadline(updatedScheduleChange, deadlineTime);
    const disableSave = updating ||
        (!!scheduleChange.isEdit &&
            columnObjectsAreEqual(currDeadlineSettings, updatedDeadlineSettings));
    const dayLabel = moment(updatedScheduleChange.publicationDate).format('dddd, M/D/YY');
    const timezoneLabel = (_b = (_a = TimezoneType.by_key(activeOrganization.data().iana_timezone)) === null || _a === void 0 ? void 0 : _a.abbrev) !== null && _b !== void 0 ? _b : '';
    useEffect(() => {
        if (scheduleChange.isEdit) {
            return;
        }
        const { publicationDate } = updatedScheduleChange;
        setUpdatedScheduleChange(getScheduleChangeSettingsForNewPublicationDate(publicationDate, activeOrganization));
    }, [updatedScheduleChange.publicationDate.getTime()]);
    const saveScheduleChange = () => __awaiter(this, void 0, void 0, function* () {
        setUpdating(true);
        try {
            yield onSubmit(dayLabel, updatedScheduleChange, updatedDeadlineSettings);
        }
        finally {
            setUpdating(false);
        }
    });
    const fixedPreviewDeadline = getFixedPreviewDeadline(updatedScheduleChange, deadlineTime);
    return (_jsx(FullScreenModal, Object.assign({ id: "schedule-change-form", submittable: {
            buttonText: scheduleChange.isEdit
                ? 'Save Changes'
                : 'Create Schedule Change',
            disabled: disableSave,
            onSubmit: saveScheduleChange
        }, previewable: {
            header: { title: 'Preview' },
            renderPreview: () => (_jsx(DeadlinePreview, { deadlineSettings: updatedDeadlineSettings, timezone: 'America/New_York', fixedPreviewDate: updatedScheduleChange.publicationDate, fixedPreviewDeadline: fixedPreviewDeadline })),
            withBorder: true
        }, headerText: scheduleChange.isEdit ? 'Edit Schedule Change' : 'New Schedule Change', onClose: onClose }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'Schedule Change',
                description: `Configure the deadline day and time for ${scheduleChange.isEdit ? `the ${dayLabel}` : 'a specific'} publication date.`
            } }, { children: [!scheduleChange.isEdit && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsxs(Label, Object.assign({ id: "edit-schedule-change-publication-date" }, { children: [_jsx("p", Object.assign({ className: "mb-3" }, { children: "Which publication date needs a schedule change?" })), _jsx(ColumnDatePicker, { format: "M/d/yy", momentFormat: "M/D/YY", placeholderText: "M/D/YY", value: updatedScheduleChange.publicationDate, onChange: newDate => {
                                    if (newDate) {
                                        setUpdatedScheduleChange(Object.assign(Object.assign({}, updatedScheduleChange), { publicationDate: newDate }));
                                    }
                                }, shouldDisableDate: date => {
                                    if (date) {
                                        return moment(date).isSameOrBefore(moment(), 'd');
                                    }
                                    return false;
                                }, className: "p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary" })] })) }))), _jsxs(SwitchControlledCard, Object.assign({ labelProps: {
                        label: `Will you publish on ${dayLabel}?`,
                        description: `Enable publication to allow customers to place ${productName} for publication on ${dayLabel}.`,
                        value: updatedScheduleChange.publish,
                        onChange: publish => setUpdatedScheduleChange(Object.assign(Object.assign({}, updatedScheduleChange), { publish }))
                    }, header: "Deadline Preferences" }, { children: [_jsx(GridInput, { children: _jsxs(Label, Object.assign({ id: "edit-schedule-change-deadline-date", disabled: !updatedScheduleChange.publish }, { children: [_jsx("p", Object.assign({ className: "mb-3" }, { children: "What day is the deadline?" })), _jsx(ColumnDatePicker, { format: "M/d/yy", momentFormat: "M/D/YY", placeholderText: "M/D/YY", disabled: !updatedScheduleChange.publish, value: updatedScheduleChange.deadlineDate, onChange: newDate => {
                                            if (newDate) {
                                                setUpdatedScheduleChange(Object.assign(Object.assign({}, updatedScheduleChange), { deadlineDate: newDate }));
                                            }
                                        }, shouldDisableDate: date => {
                                            if (date) {
                                                return moment(date).isSameOrAfter(moment(updatedScheduleChange.publicationDate), 'd');
                                            }
                                            return false;
                                        }, className: "p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary" })] })) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "edit-schedule-change-deadline-time", labelText: `What time is the deadline? (${timezoneLabel})`, disabled: !updatedScheduleChange.publish, options: getTimeOptionsForDeadlines(), value: deadlineTime, onChange: newVal => setDeadlineTime(newVal) }) }), productLine === Product.Notice && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "edit-deadline-display-offset", labelText: "How many hours earlier is the deadline for display ads?", noteText: "Adjust this field if deadline is earlier for display ads than for liner ads.", type: "number", min: 0, placeholder: "0", disabled: !updatedScheduleChange.publish, value: `${(_c = updatedScheduleChange.displayOffset) !== null && _c !== void 0 ? _c : ''}`, onChange: newVal => setUpdatedScheduleChange(Object.assign(Object.assign({}, updatedScheduleChange), { displayOffset: Number(newVal) })) }) })))] }))] })) })));
}
