import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Notice, TickNote, BalanceIcon, DollarIcon, PlusNote, AlarmNote, CommunityProfiles } from 'icons';
import { NotificationType } from 'lib/enums';
import { PartyPopper, MoneyWithWings, AlarmClock, CancelledAndNoEntry, PageFacingUp, MoneyBag, Memo } from '../../emojis/index';
const blueStyles = 'bg-blue-100 text-blue-900';
const greenStyles = 'bg-column-green-100  text-column-green-500';
const redStyles = 'bg-red-100 text-red-500';
// Add svg image and styles based on the notification type
export function getNotificationTypeStyling(key) {
    switch (key) {
        case NotificationType.publisher_edited_notice.key:
        case NotificationType.notice_receipt_confirmed.key:
        case NotificationType.notice_submitted_on_behalf_of_anonymous.key:
            return { img: _jsx(Notice, {}), styles: greenStyles };
        case NotificationType.charge_succeeded.key:
        case NotificationType.invoice_paid_by_advertiser.key:
            return { img: _jsx(TickNote, {}), styles: greenStyles };
        case NotificationType.affidavit_available.key:
        case NotificationType.publisher_updated_affidavit.key:
        case NotificationType.new_affidavit.key:
            return { img: _jsx(BalanceIcon, {}), styles: greenStyles };
        case NotificationType.payout_paid.key:
            return { img: _jsx(DollarIcon, {}), styles: greenStyles };
        case NotificationType.organization_request_approved.key:
            return { img: _jsx(CommunityProfiles, {}), styles: greenStyles };
        case NotificationType.notice_filed.key:
            return { img: _jsx(Notice, {}), styles: blueStyles };
        case NotificationType.new_invoice.key:
            return { img: _jsx(PlusNote, {}), styles: blueStyles };
        case NotificationType.affidavit_upload_reminder.key:
            return { img: _jsx(BalanceIcon, {}), styles: blueStyles };
        case NotificationType.new_invoice_upfront_payment.key:
        case NotificationType.invoice_reminder.key:
            return { img: _jsx(PlusNote, {}), styles: redStyles };
        case NotificationType.charge_failed.key:
        case NotificationType.invoice_cancelled.key:
        case NotificationType.invoice_cancelled_no_payment.key:
        case NotificationType.automated_invoice_reminder.key:
        case NotificationType.invoice_cancelled_paid_through_gateway.key:
            return { img: _jsx(AlarmNote, {}), styles: redStyles };
        case NotificationType.request_notice_cancellation.key:
        case NotificationType.notice_cancelled.key:
        case NotificationType.refund_issued.key:
            return { img: _jsx(Notice, {}), styles: redStyles };
        case NotificationType.organization_request_denied.key:
            return { img: _jsx(CommunityProfiles, {}), styles: redStyles };
        default:
            return { img: _jsx(Notice, {}), styles: blueStyles };
    }
}
// Add emojis in notice header based on the notification types
export function getNotificationsEmoji(key) {
    switch (key) {
        case NotificationType.notice_receipt_confirmed.key:
        case NotificationType.notice_filed.key:
        case NotificationType.organization_request_approved.key:
            return _jsx(PartyPopper, {});
        case NotificationType.publisher_edited_notice.key:
            return _jsx(Memo, {});
        case NotificationType.new_invoice.key:
        case NotificationType.charge_succeeded.key:
        case NotificationType.invoice_paid_by_advertiser.key:
            return _jsx(MoneyWithWings, {});
        case NotificationType.new_invoice_upfront_payment.key:
        case NotificationType.automated_invoice_reminder.key:
        case NotificationType.invoice_reminder.key:
            return _jsx(AlarmClock, {});
        case NotificationType.charge_failed.key:
        case NotificationType.invoice_cancelled.key:
        case NotificationType.notice_cancelled.key:
        case NotificationType.refund_issued.key:
        case NotificationType.invoice_cancelled_paid_through_gateway.key:
        case NotificationType.invoice_cancelled_no_payment.key:
        case NotificationType.request_notice_cancellation.key:
        case NotificationType.organization_request_denied.key:
            return _jsx(CancelledAndNoEntry, {});
        case NotificationType.affidavit_available.key:
        case NotificationType.publisher_updated_affidavit.key:
        case NotificationType.new_affidavit.key:
        case NotificationType.affidavit_upload_reminder.key:
        case NotificationType.notice_submitted_on_behalf_of_anonymous.key:
            return _jsx(PageFacingUp, {});
        case NotificationType.payout_paid.key:
            return _jsx(MoneyBag, {});
        default:
            return _jsx(_Fragment, {});
    }
}
