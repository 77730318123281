import { jsx as _jsx } from "react/jsx-runtime";
import { Product } from 'lib/enums';
import NoticeRatesTable from '../rates/NoticeRatesTable';
import RatesTable from '../obituaries/ProductRatesTable';
function RatesSettings({ activeOrganization, productLine }) {
    switch (productLine) {
        case Product.Notice:
            return _jsx(NoticeRatesTable, { activeOrganization: activeOrganization });
        default:
            return (_jsx(RatesTable, { activeOrganization: activeOrganization, productLine: productLine }));
    }
}
export default RatesSettings;
