import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { TextField } from '../TextField';
import { centsToExtendedCurrency, currencyToCents } from '../../utils/rates';
/**
 * TextField component specifically for use with money. This component expects an initialValue
 * of cents and will convert it to a currency string for display. It will also convert the value
 * back to cents when the user changes the value and pass it back through the onChange function.
 */
export default function CurrencyTextField({ initialValue, onChange, id, placeholder, disabled, labelText, step = 0.0001, required, errorText, max, size, validationMessages }) {
    const [value, setValue] = useState(initialValue ? centsToExtendedCurrency(initialValue) : undefined);
    return (_jsx(TextField, { id: id, labelText: labelText, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : '0.00', disabled: disabled, type: "number", value: value, onChange: newValue => {
            setValue(newValue);
            onChange(currencyToCents(newValue));
        }, prefix: _jsx(CurrencyDollarIcon, { className: "w-5 h-5 text-column-gray-400" }), min: 0, step: step, required: required !== null && required !== void 0 ? required : false, errorText: errorText, max: max, size: size, validationMessages: validationMessages }));
}
