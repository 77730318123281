import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import FormSwitch from '../../FormSwitch';
import DrawerPropertyRow from '../../DrawerPropertyRow';
export default function BillingTab({ noticeType }) {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const rate = useFirestoreSnapshot(noticeType.rate);
    if (noticeType.rate && !rate)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: 'Rate', value: noticeType.rate ? rate === null || rate === void 0 ? void 0 : rate.data().description : 'Default Rate' }), _jsx(DrawerPropertyRow, { label: 'Upfront payment', value: _jsx(FormSwitch, { disabled: true, checked: !!noticeType.upFrontPayment }) }), _jsx(DrawerPropertyRow, { label: 'Auto-invoice', value: _jsx(FormSwitch, { disabled: true, checked: !!noticeType.autoInvoice }) }), ((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.allowInvoiceOutsideColumn) && (_jsx(DrawerPropertyRow, { label: 'Invoice outside Column', value: _jsx(FormSwitch, { disabled: true, checked: !!noticeType.invoiceOutsideColumn }) }))] }));
}
