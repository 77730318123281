var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { apiPost } from 'api/typed';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import AuthActions from 'redux/auth';
import { push } from 'connected-react-router';
import { logAndCaptureException } from 'utils';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import classNames from 'classnames';
import ToastActions from 'redux/toast';
import firebase from 'firebase/app';
import { SigninProviderType } from 'lib/enums/SigninProviderType';
import { logInfo } from 'utils/logger';
import { ColumnService } from 'lib/services/directory';
import googleIcon from './loginIcons/googleIcon.svg';
import PromptForPasswordModal from './PromptForPasswordModal';
import { errorMsg } from './utils';
export default function GoogleSignIn() {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [authError, setAuthError] = useState();
    const handleGoogleLogin = () => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        try {
            const googleProvider = new firebase.auth.GoogleAuthProvider();
            googleProvider.setCustomParameters({ prompt: 'consent' });
            const result = yield firebase.auth().signInWithPopup(googleProvider);
            const credentialData = result.credential;
            const token = credentialData === null || credentialData === void 0 ? void 0 : credentialData.idToken;
            const profile = (_a = result.additionalUserInfo) === null || _a === void 0 ? void 0 : _a.profile;
            const uid = (_b = result.user) === null || _b === void 0 ? void 0 : _b.uid;
            if (profile && token && uid) {
                setLoading(true);
                // Exchange the Google Sign In token for a Firebase auth token
                const res = yield apiPost('auth/signin-exchange-token-for-google-microsoft-provider', {
                    token,
                    userProfile: profile,
                    uid,
                    type: SigninProviderType.GOOGLE
                });
                // Sign in with Firebase Auth
                if (res.success) {
                    dispatch(AuthActions.loginToken(res.firebaseToken));
                    setLoading(false);
                    // Go to / and let our routingSaga take user to the correct place
                    dispatch(push('/'));
                }
                else if (!res.success) {
                    setLoading(false);
                    dispatch(ToastActions.toastError({
                        headerText: 'Error',
                        bodyText: errorMsg
                    }));
                }
            }
        }
        catch (error) {
            const authError = error;
            const { code, email, credential } = authError;
            /**
             * Google serves as both an email and social identity provider. Email IDPs are authoritative for all
             * email addresses related to their hosted email domain while social IDPs assert email identities based
             * having done a one time confirmation of the email address. A user logging in with Google will never cause
             * this error when their account is hosted at Google even if they signed up for their account with a password or a social IDP.
             * https://firebase.google.com/docs/auth/web/google-signin#expandable-1
             */
            if (code === 'auth/account-exists-with-different-credential') {
                try {
                    const existingProviders = yield firebase
                        .auth()
                        .fetchSignInMethodsForEmail(email);
                    if (existingProviders.length) {
                        if (existingProviders[0] === 'microsoft.com') {
                            yield firebase
                                .auth()
                                .signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'));
                            if (credential && firebase.auth().currentUser) {
                                logInfo('User linking Google account with Microsoft', {
                                    email
                                });
                                yield ((_c = firebase
                                    .auth()
                                    .currentUser) === null || _c === void 0 ? void 0 : _c.linkWithCredential(credential));
                                dispatch(push('/'));
                            }
                        }
                        else if (existingProviders[0] === 'password') {
                            setAuthError(authError);
                        }
                    }
                }
                catch (error) {
                    dispatch(ToastActions.toastError({
                        headerText: 'Something went wrong',
                        bodyText: errorMsg
                    }));
                    logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to fetch and link with new signin provider', { email });
                }
            }
            else {
                dispatch(ToastActions.toastError({
                    headerText: 'Error',
                    bodyText: errorMsg
                }));
                logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to signin with Google');
            }
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ id: "custom-google-button", onClick: handleGoogleLogin, disabled: loading, className: classNames('w-full flex p-2.5 border border-column-gray-200 rounded-md bg-white focus:outline-none', {
                    'hover:bg-column-gray-50 justify-center': !loading
                }) }, { children: [_jsx("img", { src: googleIcon, className: "h-6 w-6" }), _jsx("span", Object.assign({ className: "font-medium text-column-gray-500 text-base pl-2" }, { children: "Google" })), loading && (_jsx("div", Object.assign({ className: "flex w-full justify-end" }, { children: _jsx(LoadingSpinner, { inline: true }) })))] })), authError && (_jsx(PromptForPasswordModal, { onClose: () => setAuthError(undefined), userAuthData: authError }))] }));
}
