import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import JoinOrCreateNewOrganization from 'routes/register/organization/joinOrCreateNewOrganization';
import Occupations from 'routes/register/occupations/Occupations';
/**
 * TODO: Collapse this flow with the other organization registration flow
 */
export default function CreateAdvertiserOrganization() {
    const registrationFlowSteps = {
        CHOOSE_OCCUPATION: 0,
        JOIN_OR_CREATE_ORGANIZATION: 1
    };
    const [occupationType, setOccupationType] = useState(0);
    const [stepToDisplay, setStepToDisplay] = useState(registrationFlowSteps.CHOOSE_OCCUPATION);
    return (_jsxs(_Fragment, { children: [stepToDisplay === registrationFlowSteps.CHOOSE_OCCUPATION && (_jsx(Occupations, { occupationType: occupationType, onOccupationTypeChange: setOccupationType, onOccupationSelect: () => setStepToDisplay(registrationFlowSteps.JOIN_OR_CREATE_ORGANIZATION), isDisplayingOnlyOrganizationOccupations: true })), stepToDisplay === registrationFlowSteps.JOIN_OR_CREATE_ORGANIZATION && (_jsx(JoinOrCreateNewOrganization, { occupationType: occupationType, onBackClick: () => setStepToDisplay(registrationFlowSteps.CHOOSE_OCCUPATION) }))] }));
}
