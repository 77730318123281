var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { getFirebaseContext } from 'utils/firebase';
import { isColumnUser, removeUndefinedFields, replaceUndefinedWithDelete } from 'lib/helpers';
import FullScreenModal from 'components/FullScreenModal';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { isFlatAdditionalFee, isNoticeRate, isPercentAdditionalFee } from 'lib/types/rates';
import { PublisherOrgSharingCard } from 'routes/settings/publisher/sharing/PublisherOrgSharingCard';
import { RateType } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { rateTypeSupportsDisplay } from '../ratesTableSettingsUtils';
import BasicPropertiesCard from './BasicPropertiesCard';
import AdditionalFeesCard from './AdditionalFeesCard';
import { getDefaultBehaviorFromNewspaperAndRateData } from './rateUpdateFormUtils';
import AdvancedSettingsCard from './AdvancedSettingsCard';
import AdminSettingsCard from './AdminSettingsCard';
function rateUpdatesAreValid(rate) {
    const baseRates = [rate.minimum, rate.rate_0, rate.rate_1, rate.rate_2];
    const additionalRates = [...Object.values(rate.additionalRates || [])];
    const flatAdditionalFees = (rate.additionalFees || [])
        .filter((fee) => isFlatAdditionalFee(fee))
        .map(fee => fee.amount);
    const percentAdditionalFees = (rate.additionalFees || [])
        .filter((fee) => isPercentAdditionalFee(fee))
        .map(fee => fee.feePercentage);
    const flatAmountsAreValid = [
        ...baseRates,
        ...additionalRates,
        ...flatAdditionalFees
    ].every(n => n === undefined || n === null || n >= 0);
    const percentAmountsAreValid = percentAdditionalFees.every(n => n === undefined || n === null || n > 0);
    return flatAmountsAreValid && percentAmountsAreValid;
}
/**
 * Full-screen form to update properties associated with a rate
 */
export default function RateUpdateForm({ activeOrganization, closeForm, rateData, rateRef }) {
    var _a;
    const user = useAppSelector(selectUser);
    const [updatedRateData, setUpdatedRateData] = useState(rateData);
    const [updating, setUpdating] = useState(false);
    const initialDefaultBehavior = getDefaultBehaviorFromNewspaperAndRateData(activeOrganization, rateRef === null || rateRef === void 0 ? void 0 : rateRef.id);
    const [defaultBehavior, setDefaultBehavior] = useState(initialDefaultBehavior);
    const isEditingNoticeRate = isNoticeRate(updatedRateData);
    const edited = !columnObjectsAreEqual(updatedRateData, rateData) ||
        defaultBehavior !== initialDefaultBehavior;
    const updateRateProperties = () => __awaiter(this, void 0, void 0, function* () {
        setUpdating(true);
        try {
            let rateRefToUpdate = rateRef;
            // this runs if we are creating a new rate
            if (!rateRefToUpdate) {
                rateRefToUpdate = yield getFirebaseContext()
                    .adRatesRef()
                    .add(removeUndefinedFields(Object.assign({}, updatedRateData)));
            }
            // determine if we need to update the default rates
            if (isEditingNoticeRate && defaultBehavior !== initialDefaultBehavior) {
                const defaultRate = rateRefToUpdate;
                if (defaultBehavior === 'both') {
                    yield activeOrganization.ref.update({
                        defaultDisplayRate: defaultRate,
                        defaultLinerRate: defaultRate
                    });
                }
                else if (defaultBehavior === 'display') {
                    yield activeOrganization.ref.update({
                        defaultDisplayRate: defaultRate
                    });
                }
                else if (defaultBehavior === 'liner') {
                    yield activeOrganization.ref.update({
                        defaultLinerRate: defaultRate
                    });
                }
            }
            yield rateRefToUpdate.update(replaceUndefinedWithDelete(getFirebaseContext(), updatedRateData));
            closeForm();
        }
        catch (err) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Unable to update rate properties', {
                rateId: rateRef === null || rateRef === void 0 ? void 0 : rateRef.id
            });
        }
        setUpdating(false);
    });
    // Automatically turn off supporting display if the rate type is line-based
    useEffect(() => {
        if (!rateTypeSupportsDisplay(updatedRateData.rateType)) {
            setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { supportsDisplay: false }));
        }
        // Default hide pricing for iowa forms as they need to be
        // manually priced during invoice creation
        if (updatedRateData.rateType === RateType.iowa_form.value) {
            setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { hidePreview: true }));
        }
    }, [updatedRateData.rateType]);
    // Enable saving instantly for new rates, and after edit for existing rates
    const disableSave = !updatedRateData.description ||
        (rateRef && (updating || !edited)) ||
        !rateUpdatesAreValid(updatedRateData);
    return (_jsxs(FullScreenModal, Object.assign({ submittable: {
            buttonText: rateRef ? 'Save' : 'Create Rate',
            disabled: !!disableSave,
            onSubmit: updateRateProperties
        }, onClose: closeForm, headerText: rateRef ? 'Edit Rate' : 'New Rate', id: "rate-update-form" }, { children: [_jsx(BasicPropertiesCard, { activeOrganization: activeOrganization, setDefaultBehavior: setDefaultBehavior, onUpdateRateData: setUpdatedRateData, updatedRateData: updatedRateData, defaultBehavior: defaultBehavior, rateRef: rateRef }), _jsx(AdditionalFeesCard, { setUpdatedRateData: setUpdatedRateData, updatedRateData: updatedRateData, rateData: updatedRateData }), _jsx(AdvancedSettingsCard, { onUpdateRateData: setUpdatedRateData, updatedRateData: updatedRateData, initialRateData: rateData }), exists(user) && isColumnUser(user) && (_jsx(PublisherOrgSharingCard, { resourceNoun: "rate", value: (_a = updatedRateData.publisherOrganizations) !== null && _a !== void 0 ? _a : [], owner: updatedRateData.organization, onChange: value => {
                    setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { publisherOrganizations: value }));
                } })), isEditingNoticeRate &&
                exists(user) &&
                userHasPermission(user, Permissions.SETTINGS_RATES_ADMIN) && (_jsx(AdminSettingsCard, { onChange: newVals => setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), newVals)), value: updatedRateData }))] })));
}
