import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useState } from 'react';
import CustomerCreationModal from './CustomerCreationModal';
function CustomerCreation({ activeOrganization, onCreate }) {
    const [showModal, setShowModal] = useState(false);
    return (_jsxs(_Fragment, { children: [showModal && (_jsx(CustomerCreationModal, { onClose: () => {
                    setShowModal(false);
                }, onSubmit: customer => {
                    onCreate(customer);
                }, activeOrganization: activeOrganization })), _jsx(ColumnButton, { fullWidth: true, type: "button", buttonText: "Create new", startIcon: _jsx(PlusCircleIcon, { className: "text-column-primary-500 h-4 w-4" }), onClick: () => {
                    setShowModal(true);
                } })] }));
}
export default CustomerCreation;
