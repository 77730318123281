export const RoleTypeData = {
    admin: {
        value: 1,
        canInvite: true,
        label: 'Admin',
        key: 'admin'
    },
    billing: {
        value: 2,
        canInvite: true,
        label: 'Billing',
        key: 'billing'
    },
    user: {
        value: 3,
        canInvite: true,
        label: 'User',
        key: 'user'
    },
    super: {
        value: 4,
        canInvite: false,
        label: 'super',
        key: 'super'
    },
    rates: {
        value: 5,
        canInvite: true,
        publisherOnly: true,
        label: 'Rates',
        key: 'rates'
    },
    /**
     * TODO: In the future it would be better if custom roles were not hardcoded into
     * our codebase and were instead something assembled by the user form permissions.
     * However right now we only have 1-2 custom roles and the code benefits a lot from
     * being able to assume that all permission checks are synchronous,
     */
    lee_billing: {
        value: 6,
        canInvite: true,
        publisherOnly: true,
        customRole: true,
        label: 'Billing (Lee)',
        key: 'lee_billing'
    },
    /**
     * Similar to the Admin role but invisible. This is used to add Columnists to
     * organizations without confusing them with internal admins.
     */
    columnist: {
        value: 7,
        canInvite: false,
        label: 'Columnist',
        key: 'columnist'
    }
};
