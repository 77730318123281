var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getOrThrow } from 'lib/utils/refs';
import { getAdWidth } from 'lib/orders/layouts';
import OrderImageEditor from '../Images/OrderImageEditor';
import TitleEditor from './TitleEditor';
import ContentEditor from './ContentEditor';
function FormattedEditor({ adData, onChange, layout, newspaperOrdersFormData, disableEditing, product, madlibProps, forceFreeEdit }) {
    const { value: adWidth, isLoading: isAdWidthLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!newspaperOrdersFormData.length)
                return;
            const [firstNewspaperOrder] = newspaperOrdersFormData;
            const publisher = yield getOrThrow(firstNewspaperOrder.newspaper);
            const template = yield getOrThrow(publisher.data().adTemplate);
            const templateStyles = template.data().styles;
            if (!templateStyles) {
                logAndCaptureCriticalError(ColumnService.OBITS, new Error('No styles found for ad template'), 'No styles found for ad template', {
                    publisher: publisher.id,
                    template: template.id
                });
                return;
            }
            const { response: adWidth, error: adWidthError } = getAdWidth(layout, templateStyles);
            if (adWidthError) {
                logAndCaptureCriticalError(ColumnService.OBITS, adWidthError, 'Error getting ad width');
            }
            return adWidth;
        }),
        dependencies: [
            layout.id,
            newspaperOrdersFormData.map(o => { var _a; return (_a = o.newspaper) === null || _a === void 0 ? void 0 : _a.id; }).join(',')
        ]
    });
    if (isAdWidthLoading)
        return null;
    return (_jsx("div", Object.assign({ className: "bg-white", style: { width: `${adWidth}px` } }, { children: _jsxs("div", Object.assign({ className: "border border-dashed" }, { children: [_jsx(OrderImageEditor, { adData: adData, onChange: onChange, layout: layout, disableEditing: disableEditing }), _jsx("h1", Object.assign({ className: "overflow-auto border-dashed border-b py-4 px-1" }, { children: _jsx(TitleEditor, { adData: adData, disableEditing: disableEditing, product: product, onChange: onChange }) })), _jsx("div", Object.assign({ className: "px-1" }, { children: _jsx(ContentEditor, { onChange: onChange, adData: adData, disableEditing: disableEditing, product: product, madlibProps: madlibProps, forceFreeEdit: forceFreeEdit }) }))] })) })));
}
export default FormattedEditor;
