var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
import { OrganizationType } from '../enums';
export const PUBLISHER_ORGANIZATIONS = OrganizationType.items()
    .filter(organizationType => organizationType.isPublisher)
    .map(organizationType => organizationType.value);
export const ADVERTISER_ORGANIZATIONS = OrganizationType.items()
    .filter(organizationType => !organizationType.isPublisher)
    .map(organizationType => organizationType.value);
/**
 * Check if an organization is a publisher. If not, it's an advertiser.
 * @deprecated When possible, use the `OrganizationModel.isPublisherOrganization` getter instead.
 */
export const isPublisherOrganization = (org) => {
    if (!exists(org)) {
        return false;
    }
    const typeEnum = OrganizationType.by_value(org.data().organizationType);
    return !!(typeEnum === null || typeEnum === void 0 ? void 0 : typeEnum.isPublisher);
};
export function organizationToContextState(organizationSnap) {
    const { name, state, organizationType, subdomain, allowedNotices, filingFlowSubdomainImage, searchContext, darkLogo } = organizationSnap.data();
    return {
        id: organizationSnap.id,
        name,
        state,
        organizationType,
        subdomain,
        allowedNotices,
        filingFlowSubdomainImage,
        searchContext,
        darkLogo
    };
}
export const getNewspaperParentOrg = (newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return yield ((_a = newspaper.data().parent) === null || _a === void 0 ? void 0 : _a.get());
});
export const getSubOrganizations = (ctx, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const querySnapshot = yield ctx
        .organizationsRef()
        .where('parent', '==', organization)
        .get();
    return querySnapshot.docs;
});
export const getSubOrganizationRefs = (ctx, organization) => __awaiter(void 0, void 0, void 0, function* () {
    const subOrganizations = yield getSubOrganizations(ctx, organization);
    return subOrganizations.map(subOrganization => subOrganization.ref);
});
