import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { fuzzyStringContains } from 'lib/utils/strings';
import { TableLayout } from 'lib/components/TableLayout';
import { getFirebaseContext } from 'utils/firebase';
import LoadingState from 'components/LoadingState';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { CachesListTableRow } from './CacheListTableRow';
import { CacheCreationForm } from './CacheCreationForm';
/**
 * A table of caches entries for a single org.
 */
export function CachesListTable({ activeOrganization, onEditClick }) {
    const [showCacheCreationForm, setShowCacheCreationForm] = useState(false);
    const query = getFirebaseContext().cachesRef(activeOrganization.ref);
    const cachesSnap = useFirestoreQueryListener(query, [activeOrganization.id]);
    const caches = cachesSnap === null || cachesSnap === void 0 ? void 0 : cachesSnap.docs;
    if (!caches) {
        return _jsx(LoadingState, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(TableLayout, { id: "caches-list", columns: ['Name', 'Key type', 'Value type'], data: caches, header: {
                    title: 'Caches',
                    subtitle: 'Manage caches.'
                }, filterable: {
                    shouldShowTableItem: (entry, filter) => {
                        return (fuzzyStringContains(safeStringify(entry.data().name) || '', filter) || fuzzyStringContains(safeStringify(entry.id) || '', filter));
                    }
                }, creatable: {
                    createButtonText: 'Add Cache',
                    onCreate: () => {
                        setShowCacheCreationForm(true);
                    }
                }, editable: {
                    onEdit: entry => {
                        onEditClick(entry);
                    }
                }, renderRow: entry => {
                    return _jsx(CachesListTableRow, { entry: entry });
                } }), showCacheCreationForm && (_jsx(CacheCreationForm, { org: activeOrganization.ref, closeForm: () => setShowCacheCreationForm(false) }))] }));
}
