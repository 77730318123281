import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import React from 'react';
import { PAGINATION_ISSUE_DATE_FILTERS } from './paginationTableUtils';
export default function PaginationTableFilterDialog({ setUpdatedFilter, updatedFilter }) {
    return (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "uppercase text-column-gray-300 text-xs font-bold" }, { children: "Filter" })), _jsx(ColumnSelect, { id: "publication-date", labelText: "Publication date", size: "small", options: PAGINATION_ISSUE_DATE_FILTERS.map(dateFilter => ({
                    value: dateFilter,
                    label: dateFilter
                })), value: updatedFilter.publicationDate, onChange: newValue => setUpdatedFilter(Object.assign(Object.assign({}, updatedFilter), { publicationDate: newValue })) }), _jsx(ColumnSelect, { id: "ad-deadline", labelText: "Deadline", size: "small", options: PAGINATION_ISSUE_DATE_FILTERS.map(dateFilter => ({
                    value: dateFilter,
                    label: dateFilter
                })), value: updatedFilter.deadline, onChange: newValue => setUpdatedFilter(Object.assign(Object.assign({}, updatedFilter), { deadline: newValue })) })] }));
}
