import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import TabGroup from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import RegularNoticeDeadlines from './RegularNoticeDeadlines';
import NoticeScheduleChanges from './NoticeScheduleChanges';
const REGULAR_DEADLINES_TAB = {
    label: 'Regular Deadlines',
    enabled: true,
    id: 'regular-deadlines-tab'
};
const OVERRIDE_DEADLINES_TAB = {
    label: 'Schedule Changes',
    enabled: true,
    id: 'schedule-changes-tab'
};
const DEADLINES_TABS = [REGULAR_DEADLINES_TAB, OVERRIDE_DEADLINES_TAB];
export function AdvertisingDeadlinesSettings() {
    const [deadlineTab, setDeadlineTab] = useState(REGULAR_DEADLINES_TAB);
    return (_jsxs(SettingsPage, { children: [_jsx(TabGroup, { onClickTab: setDeadlineTab, activeTab: deadlineTab, tabs: DEADLINES_TABS, id: "newspaper-settings-deadlines-tabs" }), deadlineTab.label === REGULAR_DEADLINES_TAB.label && (_jsx(RegularNoticeDeadlines, {})), deadlineTab.label === OVERRIDE_DEADLINES_TAB.label && (_jsx(NoticeScheduleChanges, {}))] }));
}
