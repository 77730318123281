import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { Tooltip } from 'lib/components/Tooltip';
import { OrganizationStatus } from 'lib/enums';
import { isColumnUser } from 'lib/helpers';
import { exists } from 'lib/types';
import { selectActiveOrganization, selectIsImpersonating, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { userIsSuper } from 'utils/permissions';
const getOrganizationStatusAlertProps = (activeOrganization) => {
    if (!exists(activeOrganization)) {
        return null;
    }
    const organizationStatusEnum = OrganizationStatus.by_value(activeOrganization.data().organizationStatus);
    if (!organizationStatusEnum) {
        return null;
    }
    const title = organizationStatusEnum.label;
    const tooltip = organizationStatusEnum.description;
    switch (organizationStatusEnum.value) {
        case OrganizationStatus.in_implementation.value: {
            return { tooltip, alertProps: { title, status: 'info' } };
        }
        case OrganizationStatus.state_site_uploader.value: {
            return { tooltip, alertProps: { title, status: 'warning' } };
        }
        case OrganizationStatus.express.value: {
            return { tooltip, alertProps: { title, status: 'warning' } };
        }
        case OrganizationStatus.disengaged.value: {
            return { tooltip, alertProps: { title, status: 'error' } };
        }
        case OrganizationStatus.placement_only.value: {
            return { tooltip, alertProps: { title, status: 'error' } };
        }
        case OrganizationStatus.live.value:
        case OrganizationStatus.not_applicable.value:
        case undefined:
        default: {
            return null;
        }
    }
};
/**
 * Ex: johndoe@foobar.com -> j***e@foobar.com
 */
const anonymizeEmail = (email) => {
    const [user, domain] = email.split('@');
    if (!user || !domain) {
        return '';
    }
    const anonymizedUser = `${user.charAt(0)}***${user.charAt(user.length - 1)}`;
    return `${anonymizedUser}@${domain}`;
};
export function InternalLabels() {
    var _a;
    const user = useAppSelector(selectUser);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const isImpersonating = useAppSelector(selectIsImpersonating);
    const organizationStatusAlertProps = getOrganizationStatusAlertProps(activeOrganization);
    if (!exists(user)) {
        return _jsx(_Fragment, {});
    }
    const anonymizedEmail = anonymizeEmail((_a = user.data().email) !== null && _a !== void 0 ? _a : '');
    return (_jsxs("div", Object.assign({ className: "ml-10 flex gap-3 items-center" }, { children: [isColumnUser(user) && userIsSuper(user) && (_jsx(Alert, { id: "super-user-alert", title: "Super User", status: "success" })), isImpersonating && (_jsx(Alert, { id: "impersonate-alert", title: `Impersonating ${anonymizedEmail}`, status: "error", icon: _jsx(UserIcon, { className: "w-5 h-5" }) })), isColumnUser(user) && organizationStatusAlertProps && (_jsx(Tooltip, Object.assign({ helpText: organizationStatusAlertProps.tooltip, position: "left" }, { children: _jsx(Alert, Object.assign({ id: "organization-status" }, organizationStatusAlertProps.alertProps)) })))] })));
}
