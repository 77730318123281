import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
function MadlibOverrideAlert({ product, isPublisher }) {
    const productName = product.toLowerCase();
    if (isPublisher) {
        return (_jsx(Alert, { id: "publisher-madlib-override-warning", status: "warning", title: "Template Override", description: `You have turned off templating for this ${productName}, please make sure to fill in all required information and to price the order correctly when invoicing.`, icon: _jsx(ExclamationTriangleIcon, { className: "h-5 w-5" }) }));
    }
    return (_jsx(Alert, { id: "advertiser-madlib-override-warning", status: "warning", title: "Editing Unavailable", description: `The publisher has made edits to this ${productName} and you can no longer make edits. Please contact help@column.us if you need to edit your ${product} content.`, icon: _jsx(ExclamationTriangleIcon, { className: "h-5 w-5" }) }));
}
export default MadlibOverrideAlert;
