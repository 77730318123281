var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import FileUpload from 'lib/components/FileUpload';
import { InputAccessories } from 'lib/components/InputAccessories';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import Firebase from 'EnoticeFirebase';
import { DEFAULT_FULL_PAGE_TEMPLATE } from '../../templateSettingsUtils';
export default function TemplateBulkDownloadUpdateCard({ onChange, value }) {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [downloadingSample, setDownloadingSample] = useState(false);
    const downloadSample = () => __awaiter(this, void 0, void 0, function* () {
        setDownloadingSample(true);
        window.open(DEFAULT_FULL_PAGE_TEMPLATE, '_blank');
        setDownloadingSample(false);
    });
    return (_jsx(CardGridLayout, Object.assign({ header: {
            title: 'Bulk Downloads',
            description: `Override ${(_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.name}'s default bulk download template for notices that use this template.`
        } }, { children: _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                label: 'Enable bulk template?',
                description: "Notices that use this template will paginate using the provided bulk template, instead of the newspaper's default bulk template.",
                value: value.enableBulkDownload,
                onChange: enableBulkDownload => onChange({ bulkDownloadStoragePath: undefined, enableBulkDownload })
            }, header: "" }, { children: value.enableBulkDownload && (_jsxs(_Fragment, { children: [value.bulkDownloadStoragePath && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InputAccessories, Object.assign({ id: "bulk-template-download-label", labelText: "Current bulk IDML file" }, { children: _jsx(FileUploadListItem, { firebaseStoragePath: value.bulkDownloadStoragePath, viewButtonText: "Download", viewButtonIcon: _jsx(DocumentArrowDownIcon, { className: "w-6 h-6" }), storage: Firebase.storage() }) })) }))), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InputAccessories, Object.assign({ id: "bulk-template-upload-label", labelText: "Upload bulk IDML file" }, { children: _jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsx(FileUpload, { id: "template-bulk-download-file-upload", multiple: false, acceptFileTypes: ".idml", uploadFolder: `templates/${activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id}/bulkTemplates`, onFileUpload: (filesAndRefs) => __awaiter(this, void 0, void 0, function* () {
                                            const [newFileAndRef] = filesAndRefs;
                                            onChange(Object.assign(Object.assign({}, value), { bulkDownloadStoragePath: newFileAndRef.uploadRef.fullPath }));
                                        }), storage: Firebase.storage() }), _jsx(ColumnButton, { buttonText: "Download Sample Bulk Template", loading: downloadingSample, disabled: downloadingSample, onClick: downloadSample, type: "button" })] })) })) }))] })) })) })));
}
