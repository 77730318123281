import AuthActions, { selectShowAllOrgsNotices } from 'redux/auth';
import NoticeActions from 'redux/notices';
import { push } from 'connected-react-router';
import { ACTIVE_NOTICES_TAB } from 'routes/notices/table/types';
export function reloadAppState() {
    return dispatch => {
        const currentPath = window.location.pathname;
        if (currentPath.startsWith('/notice') ||
            currentPath.startsWith('/settings') ||
            currentPath.startsWith('/affidavits')) {
            dispatch(push('/notices/'));
            dispatch(NoticeActions.setTab(ACTIVE_NOTICES_TAB));
            dispatch(NoticeActions.setCurrentPage(0));
        }
        else {
            // Reloads app state without losing auth state
            dispatch(push(currentPath));
        }
    };
}
export function processActiveOrganizationChange(newActiveOrg) {
    return (dispatch, getState) => {
        const state = getState();
        if (selectShowAllOrgsNotices(state)) {
            dispatch(AuthActions.showAllOrgsNotices(false));
        }
        // We set the active organization to null before redirecting to the notices table
        // to avoid any listener on other pages that could reset the active organization
        dispatch(AuthActions.setActiveOrganization(null));
        dispatch(AuthActions.setActiveOrganization(newActiveOrg));
        dispatch(reloadAppState());
    };
}
