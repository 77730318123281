var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import { InviteStatus, OccupationType } from 'lib/enums';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import InviteActionModalHeader from 'components/invitesComponent/InviteActionModalHeader';
import { exists } from 'lib/types';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnService } from 'lib/services/directory';
import { acceptInvitesHelper, declineInviteHelper, transformInvitesToActionCardInvites } from './helpers';
import { getFirebaseContext } from '../../../utils/firebase';
export default function JoinOrganizationModal({ user, authActions, showRequests }) {
    const ctx = getFirebaseContext();
    const inviteFromSession = sessionStorage.getItem('inviteId');
    const [invites, setInvites] = useState();
    const [transformedInvites, setTransformedInvites] = useState();
    const pendingInvites = transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.filter(invite => invite.userInvite.data().status === InviteStatus.pending.value);
    const [showModal, setShowModal] = useState(false);
    const getInvites = () => {
        return ctx
            .invitesRef()
            .where('email', '==', user.data().email)
            .where('organizationId', '>', '')
            .onSnapshot(result => {
            setInvites(result.docs);
        });
    };
    const getInvitedOrgData = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (invites === null || invites === void 0 ? void 0 : invites.length) {
            let pendingInvites = invites.slice();
            if (inviteFromSession) {
                const [inviteFromSessionSnap] = pendingInvites.filter(invite => invite.id === inviteFromSession);
                if (exists(inviteFromSessionSnap)) {
                    // Shuffle the clicked-on invite to the top of the invites modal
                    pendingInvites = pendingInvites.filter(invite => invite.id !== inviteFromSessionSnap.id);
                    pendingInvites.unshift(inviteFromSessionSnap);
                }
            }
            const transformedInvites = yield transformInvitesToActionCardInvites(pendingInvites);
            setTransformedInvites(transformedInvites);
            const updatedShowModal = ((_a = transformedInvites.filter(invite => invite.userInvite.data().status === InviteStatus.pending.value)) === null || _a === void 0 ? void 0 : _a.length) > 0;
            /**
             * If the user previously had pending invites but now has none
             * and does not have an occupation set,
             * update their occupation to individual
             */
            const refreshedUserSnap = yield user.ref.get();
            if (showModal &&
                !updatedShowModal &&
                exists(refreshedUserSnap) &&
                !refreshedUserSnap.data().occupation) {
                yield refreshedUserSnap.ref.update({
                    occupation: OccupationType.individual.value
                });
            }
            setShowModal(updatedShowModal);
        }
    });
    const acceptInvite = (inviteSnap) => __awaiter(this, void 0, void 0, function* () {
        const { organizationId, email } = inviteSnap.data();
        try {
            yield acceptInvitesHelper(ctx, [inviteSnap], user, authActions);
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Failed to join organization from invite', {
                userEmail: email || '',
                orgId: organizationId || '',
                inviteId: inviteSnap.id || ''
            });
        }
    });
    const declineInvite = (user, invite) => __awaiter(this, void 0, void 0, function* () {
        if (inviteFromSession === invite.ref.id) {
            sessionStorage.removeItem('inviteId');
        }
        yield declineInviteHelper(user, invite);
    });
    const snoozeAllInvites = () => __awaiter(this, void 0, void 0, function* () {
        if (!(pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length)) {
            return;
        }
        // TODO: If you snooze invites and have no occupation set, we are setting you as an individual upon clicking snooze.
        // That behavior needs to be codified!
        // https://columnpbc.atlassian.net/browse/APP-1423
        if (!user.data().occupation) {
            yield user.ref.update({
                postRegistrationComplete: true,
                occupation: OccupationType.individual.value
            });
        }
        try {
            yield Promise.all(pendingInvites.map((invite) => __awaiter(this, void 0, void 0, function* () {
                if (inviteFromSession === invite.userInvite.id) {
                    sessionStorage.removeItem('inviteId');
                }
                const inviteSnap = invite.userInvite;
                yield inviteSnap.ref.update({
                    status: InviteStatus.snoozed.value
                });
            })));
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error snoozing all invites', {
                userId: user.id
            });
        }
        setShowModal(false);
    });
    const acceptAllInvites = () => __awaiter(this, void 0, void 0, function* () {
        if (!(pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length)) {
            return;
        }
        try {
            yield acceptInvitesHelper(ctx, pendingInvites.map(invite => invite.userInvite), user, authActions);
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error accepting all invites', {
                userId: user.id
            });
        }
    });
    useEffect(() => {
        const invitesUnsub = getInvites();
        return () => {
            invitesUnsub();
        };
    }, []);
    useEffect(() => {
        void getInvitedOrgData();
    }, [safeStringify(invites)]);
    useEffect(() => {
        if (!(pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length)) {
            showRequests(true);
        }
        else
            showRequests(false);
    }, [pendingInvites]);
    if (!showModal)
        return null;
    return (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setShowModal(false), tertiaryButtonText: 'Skip for now', primaryButtonText: 'Accept all', backgroundStyle: 'bg-column-gray-25', showLoadingSpinner: true, onSubmit: acceptAllInvites, overrideExitOutsideModal: snoozeAllInvites, overrideTertiaryClose: snoozeAllInvites }, { children: _jsxs("div", Object.assign({ id: "invite-modal-container", className: "text-center" }, { children: [_jsx("div", Object.assign({ style: {
                        clipPath: 'circle()'
                    }, id: "invite-modal-icon", className: "flex justify-center mb-6 h-20 w-20 inline-flex overflow-hidden" }, { children: _jsx("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/cfcf.171eb-giphy%20(16).gif", style: {
                            transform: 'scale(1.4)'
                        } }) })), (pendingInvites === null || pendingInvites === void 0 ? void 0 : pendingInvites.length) ? (_jsxs(_Fragment, { children: [_jsx(InviteActionModalHeader, { id: "invites-exist-header", title: "You have pending team invites", subtitle: "Accept invites to manage notices, invoices, & affidavits for your organization." }), _jsx("div", Object.assign({ id: "invites-exist-modal-body relative", className: "pb-4" }, { children: transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.map((invite, index) => {
                                return invite.userInvite.data().status ===
                                    InviteStatus.pending.value ? (_jsx("div", { children: _jsx(InviteActionCard, { invite: invite, onAcceptClick: () => acceptInvite(invite.userInvite), onDeclineClick: () => declineInvite(user, invite.userInvite), index: index, organization: invite.organization, className: 'rounded-md border my-6', type: 'invite' }) }, `user-invite-${invite.userInvite.id}`)) : (_jsx(_Fragment, {}));
                            }) }))] })) : (_jsx(InviteActionModalHeader, { id: "invites-cleared-header", title: "Pending invites cleared", titleEndIcon: _jsx("span", Object.assign({ role: "img", "aria-label": "Closed mailbox with lowered flag" }, { children: "\uD83D\uDCED" })), subtitle: "You have either accepted or declined all of your pending invites. We will notify you when you get new ones." }))] })) })));
}
