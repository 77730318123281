var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicationIssueModel } from './publicationIssueModel';
import { SnapshotModel, getModelFromRef } from '..';
import { Collections } from '../../constants';
import { wrapError, wrapSuccess } from '../../types/responses';
export class EEditionModel extends SnapshotModel {
    get type() {
        return Collections.eeditions;
    }
    getPublicationIssue() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { publicationIssue: publicationIssueRef } = this.modelData;
                const publicationIssue = yield getModelFromRef(PublicationIssueModel, this.ctx, publicationIssueRef);
                return wrapSuccess(publicationIssue);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    updateStatus(status) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.update({
                    status
                });
                return wrapSuccess(undefined);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
}
