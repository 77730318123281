import { exists } from 'lib/types';
const DEFAULT_USER_REGISTRATION_DATA = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
};
export const getDefaultRegistrationDataFromInvite = (invite) => {
    let defaultRegistrationData = DEFAULT_USER_REGISTRATION_DATA;
    if (exists(invite)) {
        defaultRegistrationData = Object.assign(Object.assign({}, defaultRegistrationData), { email: invite.data().email });
    }
    return defaultRegistrationData;
};
