import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Drawer from 'lib/components/Drawer';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { AffidavitPreview } from '../affidavitTemplateEditForm/v2024-03-25/AffidavitPreview';
import { extractFormData } from '../affidavitTemplateEditForm/v2024-03-25/templateHelpers';
import { AffidavitTemplateDrawerHeader } from './AffidavitTemplateDrawerHeader';
import { AffidavitTemplateDrawerFooter } from './AffidavitTemplateDrawerFooter';
export function AffidavitTemplateDrawer({ activeOrganization, drawerTemplate, onClose, onEdit }) {
    const { value: formData } = useAsyncEffect({
        fetchData: () => extractFormData(activeOrganization, drawerTemplate.ref, drawerTemplate.data()),
        dependencies: [activeOrganization.id, drawerTemplate.id]
    });
    return (_jsxs(Drawer, Object.assign({ open: true, onClose: onClose, header: _jsx(AffidavitTemplateDrawerHeader, { activeOrganization: activeOrganization, drawerTemplate: drawerTemplate }) }, { children: [formData ? (_jsx(AffidavitPreview, { activeOrganization: activeOrganization, formData: formData })) : (_jsx(LoadingSpinner, {})), _jsx(AffidavitTemplateDrawerFooter, { onEdit: onEdit })] })));
}
