import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { TableLayout } from 'lib/components/TableLayout';
import React, { useEffect, useState } from 'react';
import { fuzzyStringContains } from 'lib/utils/strings';
import VerifyNoticesTableRow from './verifyNoticesTableRow';
import { useSortRunDataByNotice } from '../hooks/useSortRunDataByNotice';
export default function VerifyNoticesTable({ publicationIssuesAndRuns, runIdsToVerify, onCheckRuns }) {
    /**
     * We load the data in the parent table by publication issue because that's more sensible for the
     * other table's UI, but here it makes more sense to rearrange it by notice. We should probably call
     * into question the way we're rendering data in the parent table as well and make adjustments, but
     * for now this step is necessary to convert the data into a format that makes sense for this modal's table.
     */
    const { noticeAndRunData: allUnverifiedNoticesWithRuns, noticeAndRunDataLoading } = useSortRunDataByNotice(publicationIssuesAndRuns || [], {
        limitToNoticesWithUnverifiedRuns: true
    });
    const [noticesWithAllUnverifiedRunsChecked, setNoticesWithAllUnverifiedRunsChecked] = useState();
    useEffect(() => {
        setNoticesWithAllUnverifiedRunsChecked((allUnverifiedNoticesWithRuns || []).filter(unverifiedNoticeWithRuns => unverifiedNoticeWithRuns.noticeRuns
            .filter(run => !run.isVerified())
            .every(unverifiedRun => runIdsToVerify.has(unverifiedRun.id))));
    }, [runIdsToVerify, allUnverifiedNoticesWithRuns === null || allUnverifiedNoticesWithRuns === void 0 ? void 0 : allUnverifiedNoticesWithRuns.length]);
    if (!publicationIssuesAndRuns)
        return _jsx(LoadingSpinner, {});
    return (_jsx(TableLayout, { data: allUnverifiedNoticesWithRuns || [], loading: noticeAndRunDataLoading, columns: ['Notice Name', 'Past Publication Dates'], renderRow: noticeAndRunData => (_jsx(VerifyNoticesTableRow, { noticeAndRunData: noticeAndRunData, runIdsToVerify: runIdsToVerify, onCheckRun: (runId, checked) => onCheckRuns([runId], checked) })), filterable: {
            shouldShowTableItem: ({ noticeId, noticeModel }, search) => {
                const { referenceId = '' } = noticeModel.modelData;
                if (fuzzyStringContains(referenceId, search)) {
                    return true;
                }
                if (fuzzyStringContains(noticeId, search)) {
                    return true;
                }
                return false;
            }
        }, selectable: {
            selectedItems: new Set(noticesWithAllUnverifiedRunsChecked === null || noticesWithAllUnverifiedRunsChecked === void 0 ? void 0 : noticesWithAllUnverifiedRunsChecked.map(noticeAndRuns => noticeAndRuns.noticeId)),
            onSelectItems: (noticesAndRuns, checked) => {
                const runIds = noticesAndRuns
                    .flatMap(noticeAndRuns => noticeAndRuns.noticeRuns)
                    .filter(run => !run.isVerified())
                    .map(run => run.id);
                onCheckRuns(runIds, checked);
            },
            serializeItemToUniqueId: noticeAndRuns => noticeAndRuns.noticeId
        }, header: {
            title: 'Notices to Verify',
            subtitle: 'Notices with at least one unverified publication date.'
        } }));
}
