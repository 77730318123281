var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { exists } from 'lib/types';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { getMailProviderFromNoticeOrDraft } from 'lib/affidavits';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { selectDraftSnap } from 'redux/placement';
import { AffidavitMailingAddressForm } from './AffidavitMailingAddressForm';
import { actions as affidavitRecipientActions } from './affidavitRecipient.slice';
const { addMailingRecipient } = affidavitRecipientActions;
export function MailingRecipientManager({ dispatch, recipients, errors, showErrors }) {
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const noticeDraft = useAppSelector(selectDraftSnap);
    const [addingRecipients, setAddingRecipients] = useState(false);
    return (_jsxs("div", Object.assign({ className: classNames('my-2', {
            'pl-8': !enableNewPlacementFlow
        }) }, { children: [recipients.map((mailingAddress, index) => {
                return (_jsx(AffidavitMailingAddressForm, { mailingAddress: mailingAddress, dispatch: dispatch, index: index, error: errors[index], showErrors: showErrors }, `${index}-mail-container`));
            }), _jsx(ColumnButton, { id: "addMoreRecipients", secondary: true, link: true, startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5" }), buttonText: "Add more recipients", onClick: () => __awaiter(this, void 0, void 0, function* () {
                    setAddingRecipients(true);
                    if (!exists(noticeDraft))
                        return;
                    const mailProvider = yield getMailProviderFromNoticeOrDraft(getFirebaseContext(), noticeDraft);
                    dispatch(addMailingRecipient({
                        mailProvider
                    }));
                    setAddingRecipients(false);
                }), disabled: !exists(noticeDraft), loading: addingRecipients, type: "button" })] })));
}
