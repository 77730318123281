import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ConfirmationStatusBadge from 'routes/notice/ConfirmationStatusBadge';
import NoticeTableCell from './NoticeTableCell';
export default function ConfirmationStatusCell({ row }) {
    const { original } = row;
    if (original.isdraft)
        return null;
    const { iscancelled, confirmationstatus } = original;
    return (_jsx(NoticeTableCell, { children: _jsx(ConfirmationStatusBadge, { confirmationStatus: confirmationstatus, isCancelled: Boolean(iscancelled) }) }));
}
