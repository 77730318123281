import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import { exists } from 'lib/types';
import Payments from './PaymentsRoute';
const mapStateToProps = (state) => ({
    user: state.auth.user,
    activeOrganization: state.auth.activeOrganization
});
function PaymentsWrapper({ user, activeOrganization }) {
    if (!exists(user)) {
        return _jsx(_Fragment, {});
    }
    return _jsx(Payments, { user: user, organization: activeOrganization });
}
export default connect(mapStateToProps)(PaymentsWrapper);
