var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ScheduleChanges } from './ScheduleChanges';
function ProductScheduleChanges({ activeOrganization, productLine, publishingSetting }) {
    const [deadlineOverrides, setDeadlineOverrides] = React.useState(publishingSetting.modelData.deadlineOverrides);
    const handleDeadlineOverrideChange = (newOverrides) => __awaiter(this, void 0, void 0, function* () {
        yield publishingSetting.ref.update({
            deadlineOverrides: newOverrides
        });
        setDeadlineOverrides(newOverrides);
    });
    return (_jsx(ScheduleChanges, { productLine: productLine, activeOrganization: activeOrganization, onChange: handleDeadlineOverrideChange, deadlineOverrides: deadlineOverrides }));
}
export default ProductScheduleChanges;
