import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { InputNote } from 'lib/components/InputAccessories';
import { NOTICE_NOTE_ADDED } from 'lib/types/events';
import { getFirebaseContext } from 'utils/firebase';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { useFirestoreQueryListeners } from 'lib/frontend/hooks/useFirestoreQueryListeners';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import NotesEditor from 'components/CustomerDrawer/NotesTab/NotesEditor';
import { safeStringify } from 'lib/utils/stringify';
import { NoticeService } from 'lib/services/NoticeService';
import { RunService } from 'lib/services/runService';
import { getEventDataFromEventSnaps } from './utils';
import ActivityLogEvents from './ActivityLogEvents';
import ActivityLogLoader from './ActivityLogLoader';
export default function NoticeActivityLog({ notice, runs, user }) {
    const { populatedEvents, isLoadingEventData } = useGetPopulatedEventsForNotice({ notice, runs, user });
    const isPublisher = useAppSelector(selectIsPublisher);
    const isLoading = !populatedEvents || isLoadingEventData;
    const showNoteField = getBooleanFlag(LaunchDarklyFlags.ENABLE_NOTICE_NOTES, false);
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-between h-full" }, { children: [isLoading && _jsx(ActivityLogLoader, {}), !isLoading && _jsx(ActivityLogEvents, { events: populatedEvents }), showNoteField && (_jsxs("div", Object.assign({ className: "bg-white sticky p-6 bottom-0" }, { children: [_jsx(NotesEditor, { noteTopic: {
                            notice: notice.ref,
                            noteType: 'notice-note'
                        } }), _jsx(InputNote, Object.assign({ id: "shared-note" }, { children: `Notice notes will be visible to both you and the ${isPublisher ? 'customer' : 'publisher'}.` }))] })))] })));
}
function useGetPopulatedEventsForNotice({ notice, runs, user }) {
    const noticeService = new NoticeService(getFirebaseContext());
    const getEventsForNotice = noticeService.getNoticeEventsQuery(notice);
    const runService = new RunService(getFirebaseContext());
    const getRunStatusChangeEvents = runService.getRunStatusChangeEventsQueries(runs !== null && runs !== void 0 ? runs : []);
    const eventsQueryResult = useFirestoreQueryListener(getEventsForNotice, [notice === null || notice === void 0 ? void 0 : notice.id], {
        message: 'Error fetching events for notice',
        tags: {
            noticeId: notice === null || notice === void 0 ? void 0 : notice.id
        }
    });
    const runEventsQueryResults = useFirestoreQueryListeners(getRunStatusChangeEvents, [notice === null || notice === void 0 ? void 0 : notice.id, safeStringify(runs)], {
        message: 'Error fetching run status change events for notice',
        tags: {
            noticeId: notice === null || notice === void 0 ? void 0 : notice.id
        }
    });
    const showNoteField = getBooleanFlag(LaunchDarklyFlags.ENABLE_NOTICE_NOTES, false);
    // Temporary filter if notice note flag is off
    const eventRecords = !showNoteField
        ? eventsQueryResult === null || eventsQueryResult === void 0 ? void 0 : eventsQueryResult.docs.filter(event => event.data().type !== NOTICE_NOTE_ADDED)
        : eventsQueryResult === null || eventsQueryResult === void 0 ? void 0 : eventsQueryResult.docs;
    runEventsQueryResults.forEach(runEvents => { var _a; return eventRecords === null || eventRecords === void 0 ? void 0 : eventRecords.push(...((_a = runEvents === null || runEvents === void 0 ? void 0 : runEvents.docs) !== null && _a !== void 0 ? _a : [])); });
    const { value: populatedEvents, isLoading } = useAsyncEffect({
        fetchData: () => getEventDataFromEventSnaps(eventRecords || [], notice, user),
        dependencies: [eventRecords === null || eventRecords === void 0 ? void 0 : eventRecords.length, notice === null || notice === void 0 ? void 0 : notice.id, user === null || user === void 0 ? void 0 : user.id],
        errorConfig: {
            message: 'Error populating event data for notice',
            tags: {
                noticeId: notice === null || notice === void 0 ? void 0 : notice.id
            }
        }
    });
    const isLoadingEventData = !populatedEvents || isLoading;
    return {
        populatedEvents,
        isLoadingEventData
    };
}
