var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { exists } from 'lib/types';
import { push } from 'connected-react-router';
import AuthActions, { selectActiveOrganization, selectAvailableOrganizations, selectIsPublisher, selectUser } from 'redux/auth';
import { OccupationType } from 'lib/enums';
import { ADD_ORGANIZATION_ENTRYPOINT } from 'redux/router';
import { processActiveOrganizationChange, reloadAppState } from 'redux/auth/authActions';
import OrganizationSelect from './OrganizationSelect';
export function ActiveOrganizationSelect() {
    var _a;
    const dispatch = useAppDispatch();
    const isPublisher = useAppSelector(selectIsPublisher);
    const user = useAppSelector(selectUser);
    const displayCreateJoinOrganizationTab = (user === null || user === void 0 ? void 0 : user.data().occupation) !== OccupationType.publishing.value;
    const availableOrganizations = (_a = useAppSelector(selectAvailableOrganizations)) !== null && _a !== void 0 ? _a : [];
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const value = exists(activeOrganization) ? activeOrganization : undefined;
    const popoverHeader = isPublisher
        ? 'Switch Publications'
        : 'Switch Organizations';
    /**
     * Enables all organization notices to show
     */
    const onAllOrgsClicked = () => __awaiter(this, void 0, void 0, function* () {
        dispatch(AuthActions.showAllOrgsNotices(true));
        dispatch(reloadAppState());
    });
    const onCreateOrgClicked = () => {
        dispatch(push(ADD_ORGANIZATION_ENTRYPOINT));
    };
    return (_jsx(OrganizationSelect, { popoverHeader: popoverHeader, options: availableOrganizations, value: value, onChange: newOrg => {
            dispatch(processActiveOrganizationChange(newOrg));
        }, showAllOrgsOption: true, onAllOrgsClicked: onAllOrgsClicked, showCreateOrgOption: displayCreateJoinOrganizationTab, onCreateOrgClicked: onCreateOrgClicked }));
}
