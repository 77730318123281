var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DocumentArrowDownIcon, DocumentDuplicateIcon, ExclamationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { isAffidavitDisabled } from 'lib/affidavits';
import { getFirebaseContext } from 'utils/firebase';
import { shouldReleaseAffidavitForNotice } from 'lib/helpers';
import { logAndCaptureException } from 'utils';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';
const MAX_AFFIDAVIT_NAME = 60;
const extractAffidavitDownloadButtonText = (notice, affidavitDisabled, isPublisher) => {
    const { affidavit } = notice.data();
    if (!affidavit) {
        if (affidavitDisabled) {
            return 'Affidavit not required';
        }
        if (isPublisher) {
            return 'Waiting for signature...';
        }
        return 'Waiting for publisher...';
    }
    const fname = affidavit.split('/')[2] || affidavit.split('/')[1];
    if (fname.length < MAX_AFFIDAVIT_NAME) {
        return fname;
    }
    const lastPeriod = fname.lastIndexOf('.');
    return `${fname
        .slice(0, lastPeriod)
        .slice(0, MAX_AFFIDAVIT_NAME - 5)}....${fname.slice(lastPeriod + 1)}`;
};
export default function AffidavitDownloadSection({ setShowReplaceAffidavitModal, setShowPayInvoiceModal, affidavitURL, isPublisher, newspaper, notice }) {
    const affidavitDisabled = isAffidavitDisabled(notice.data(), newspaper);
    const noticeHasAffidavit = Boolean(notice.data().affidavit);
    const downloadAffidavit = () => __awaiter(this, void 0, void 0, function* () {
        if (isPublisher) {
            window.open(affidavitURL);
            return;
        }
        const w = window.open();
        try {
            const ctx = getFirebaseContext();
            const allowed = yield shouldReleaseAffidavitForNotice(ctx, notice);
            if (!w)
                return;
            if (allowed) {
                w.location = affidavitURL;
            }
            else {
                w.close();
                setShowPayInvoiceModal(true);
            }
        }
        catch (err) {
            logAndCaptureException(ColumnService.AFFIDAVITS, err, 'Failed to open affidavit file', {
                noticeId: notice.id
            });
            w === null || w === void 0 ? void 0 : w.close();
        }
    });
    return (_jsxs("div", Object.assign({ className: "p-6 flex flex-col md:flex-row gap-3 md:items-center justify-between rounded-md bg-column-gray-25 text-column-gray-400" }, { children: [_jsxs("div", Object.assign({ className: "truncate" }, { children: [noticeHasAffidavit ? (_jsx(DocumentDuplicateIcon, { className: "w-6 h-6 inline-block mr-1" })) : (_jsx(ExclamationCircleIcon, { className: "w-6 h-6 inline-block mr-1" })), extractAffidavitDownloadButtonText(notice, Boolean(affidavitDisabled), isPublisher)] })), _jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [noticeHasAffidavit && (_jsx(ColumnButton, { onClick: downloadAffidavit, startIcon: _jsx(DocumentArrowDownIcon, { className: "w-5 h-5" }), buttonText: "Download notarized affidavit", size: "sm", type: "button" })), noticeHasAffidavit && isPublisher && (_jsx(ColumnButton, { startIcon: _jsx(TrashIcon, { className: "w-5 h-5" }), onClick: () => {
                            setShowReplaceAffidavitModal(true);
                        }, size: "sm", buttonText: "Replace", type: "button" }))] }))] })));
}
