import moment from 'moment-timezone';
/**
 * Applies a minutes offset to a date and returns a date.
 * If offsetAsBusinessHours is false, we simply call Date.add.
 * If offsetAsBusinessHours is true then the following happens:
 *   Apply a minutes offset within Column business hours of 8am-5pm M-F.
 *   Does NOT yet take holidays into account.
 *   If we want to account for newspaper business hours we'll need to build that out,
 *   but the intent for now is to buffer hours that Column has support and engineering staff working.
 *
 *   e.g. business hours offset calculations:
 *    17:00 Friday with a +120 minute offset will return 10:00 on the following Monday
 *    09:00 on a Monday with a -120 minute offset will return 16:00 on the previous Friday
 *    17:00 on a Monday with a +600 minute offset will return 09:00 on following Wednesday
 *    09:00 on a Monday with a -600 minute offset will return 17:00 on the previous Thursday
 */
export const applyMinutesOffsetAndGetDate = (date, settings) => {
    const { offsetInMinutes, offsetAsBusinessHours } = settings;
    if (offsetInMinutes === 0) {
        return date;
    }
    if (!offsetAsBusinessHours) {
        return date.add(offsetInMinutes, 'minutes');
    }
    // Business Hours
    const openingHour = 8;
    const closingHour = 17;
    const isWorkingDay = (date) => date.day() !== 6 && date.day() !== 0;
    let currentOffsetMinutes = offsetInMinutes;
    while (currentOffsetMinutes !== 0) {
        if (currentOffsetMinutes > 0) {
            const remainingMinutesInCurrentDay = isWorkingDay(date)
                ? (closingHour - date.hour()) * 60
                : 0;
            if (remainingMinutesInCurrentDay >= Math.abs(currentOffsetMinutes)) {
                date.add(currentOffsetMinutes, 'minutes');
                currentOffsetMinutes = 0;
                continue;
            }
            currentOffsetMinutes -= remainingMinutesInCurrentDay;
            date
                .add(1, 'day')
                .hours(openingHour)
                .minutes(0)
                .seconds(0)
                .milliseconds(0);
            continue;
        }
        const remainingMinutesInCurrentDay = isWorkingDay(date)
            ? (date.hour() - openingHour) * 60
            : 0;
        if (remainingMinutesInCurrentDay >= Math.abs(currentOffsetMinutes)) {
            date.add(currentOffsetMinutes, 'minutes');
            currentOffsetMinutes = 0;
            continue;
        }
        currentOffsetMinutes += remainingMinutesInCurrentDay;
        date
            .subtract(1, 'day')
            .hours(closingHour)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
    }
    return date;
};
export const getDateForDateStringInTimezone = ({ 
/**
 * Must be in format YYYY-MM-DD
 */
dayString, 
/**
 * Must be in format HH:MM
 */
time = '00:00', 
/**
 * Same values as iana_timezone
 */
timezone }) => {
    return moment.tz(`${dayString} ${time}`, timezone).toDate();
};
/**
 * @returns A date string in the format specified or YYYY-MM-DD by default
 */
export const getDateStringForDateInTimezone = ({ date, dateFormat = 'YYYY-MM-DD', 
/**
 * Same values as iana_timezone
 */
timezone }) => {
    return moment(date).tz(timezone).format(dateFormat);
};
export const getPublicationTimestampForElasticQuery = ({ dayString, dateFormat = 'YYYY-MM-DD' }) => {
    // Publication timestamps are set to start of day (midnight) UTC in Elastic
    // (see functions/src/search/notices.ts)
    return moment.utc(dayString, dateFormat).startOf('day').valueOf();
};
export const getDateStringFromElasticTimestamp = ({ timestamp, dateFormat = 'YYYY-MM-DD' }) => {
    return moment.utc(timestamp).format(dateFormat);
};
export const convertDateStringFormat = (dateString, { from, to }) => {
    return moment(dateString, from).format(to);
};
export const generateDateNDaysFromToday = (daysInTheFuture, dateFormat = 'MM-DD-YYYY') => {
    // Get today's date
    const today = moment();
    // Add N days to today's date
    const futureDate = today.add(daysInTheFuture, 'days');
    // Format the date
    const formattedDate = futureDate.format(dateFormat);
    return formattedDate;
};
export const formatDateString = (dateString, format = 'YYYY-MM-DD') => {
    const date = moment(dateString);
    return date.format(format);
};
export const getFirestoreTimestampFromStripeTimestamp = (ctx, stripeTimestamp) => {
    return ctx.timestampFromDate(new Date(stripeTimestamp * 1000));
};
