import { z } from 'zod';
import { EHandlebars } from '../headers_footers/shared';
import { ObituaryFilingTypeNames } from '../types/obituary';
import { wrapError, wrapSuccess } from '../types/responses';
import { getFontFaceDefinition } from './fonts';
import { getInlineCSSStylesFromCrop } from './images';
import { ClassifiedFilingTypeNames } from '../types/classified';
// TODO(goodpaul): Move this to the db
const ONE_COL_DEATH_NOTICE = {
    id: 'one-col-death-notice',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 1,
    photos: 0,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716673316156/template_a_normal.svg',
    photoAlignment: 'none',
    aspectRatio: 'none',
    photoWidth: 'none'
};
const TWO_COL_DEATH_NOTICE = {
    id: 'two-col-death-notice',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 2,
    photos: 0,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716673363185/template_g_normal.svg',
    photoAlignment: 'none',
    aspectRatio: 'none',
    photoWidth: 'none'
};
export const DEFAULT_DEATH_NOTICE_LAYOUTS = [
    ONE_COL_DEATH_NOTICE,
    TWO_COL_DEATH_NOTICE
];
const ONE_COL_ONE_PHOTO_LAYOUT = {
    id: 'one-col-3-4-image-1-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 1,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716478716163/template_sv_normal.svg',
    photoAlignment: 'center',
    aspectRatio: '3 / 4',
    photoWidth: '100%'
};
export const TEMPLATE_S_LAYOUT = {
    id: 'three-col-4-3-image-1-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 3,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716474500733/template_s_normal.svg',
    photoAlignment: 'center',
    aspectRatio: '4 / 3',
    photoWidth: '100%'
};
const TEMPLATE_SV_LAYOUT = {
    id: 'three-col-3-4-image-1-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 3,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716478716163/template_sv_normal.svg',
    photoAlignment: 'center',
    aspectRatio: '3 / 4',
    photoWidth: '100%'
};
export const TEMPLATE_T_LAYOUT = {
    id: 'three-col-4-3-image-2-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px;">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; margin-right: 5px; aspect-ratio: 4 / 3;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; margin-left: 5px; aspect-ratio: 4 / 3;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 3,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716478847918/template_t_normal.svg',
    photoAlignment: 'center',
    aspectRatio: '4 / 3',
    photoWidth: '100%'
};
const TEMPLATE_TV_LAYOUT = {
    id: 'three-col-3-4-image-2-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px;">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; margin-right: 5px; aspect-ratio: 3 / 4;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; margin-left: 5px; aspect-ratio: 3 / 4;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 3,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716478958991/template_tv_normal.svg',
    photoAlignment: 'center',
    aspectRatio: '3 / 4',
    photoWidth: '100%'
};
const TEMPLATE_U_LAYOUT = {
    id: 'three-col-4-3-image-1-photo-variant-2-big-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 60%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 3,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716479060868/template_u_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '4 / 3',
    photoWidth: '50%'
};
const TEMPLATE_UV_LAYOUT = {
    id: 'three-col-3-4-image-1-photo-variant-2-big-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 60%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 3,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716479145916/template_uv_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '3 / 4',
    photoWidth: '50%'
};
const TEMPLATE_V_LAYOUT = {
    id: 'three-col-4-3-image-1-photo-variant-2-small-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 40%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 3,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716479425509/template_v_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '4 / 3',
    photoWidth: '30%'
};
const TEMPLATE_VV_LAYOUT = {
    id: 'three-col-3-4-image-1-photo-variant-2-small-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 40%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 3,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716555885314/template_vv_normal__1_.svg',
    photoAlignment: 'right',
    aspectRatio: '3 / 4',
    photoWidth: '30%'
};
// FOUR COLS
const TEMPLATE_VC4NP_LAYOUT = {
    id: 'four-col-no-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 4,
    photos: 0,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716559894275/template_vc5np_normal.svg',
    photoAlignment: 'none',
    aspectRatio: 'none',
    photoWidth: 'none'
};
const TEMPLATE_VC41PV_LAYOUT = {
    id: 'four-col-3-4-image-1-photo-skinny-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 30%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 4,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716559512112/template_vc41pv_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '3 / 4',
    photoWidth: '30%'
};
const TEMPLATE_VC41PH_LAYOUT = {
    id: 'four-col-4-3-image-1-photo-skinny-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 30%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 4,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716559576942/template_vc41ph_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '4 / 3',
    photoWidth: '30%'
};
const TEMPLATE_VC42PV_LAYOUT = {
    id: 'four-col-3-4-image-2-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 4,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716559710646/template_vc42pv_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '3 / 4',
    photoWidth: '25%'
};
const TEMPLATE_VC42PH_LAYOUT = {
    id: 'four-col-4-3-image-2-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 4,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716560593311/template_vc42ph_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '4 / 3',
    photoWidth: '25%'
};
// FIVE COLS
const TEMPLATE_VC51PV_LAYOUT = {
    id: 'five-col-3-4-image-1-photo-skinny-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 5,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716559983071/template_vc51pv_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '3 / 4',
    photoWidth: '20%'
};
const TEMPLATE_VC51PH_LAYOUT = {
    id: 'five-col-4-3-image-1-photo-skinny-rhs',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 5,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716560055432/template_vc51ph_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '4 / 3',
    photoWidth: '20%'
};
const TEMPLATE_VC52PV_LAYOUT = {
    id: 'five-col-3-4-image-2-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 5,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716560101529/template_vc52pv_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '3 / 4',
    photoWidth: '20%'
};
const TEMPLATE_VC52PH_LAYOUT = {
    id: 'five-col-4-3-image-2-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  <div style="{{{textStyles}}};font-weight:normal; overflow: hidden;">
    {{#if headerImage }}
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    {{#if secondHeaderImage }}
    <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right; padding-left: 4px; padding-bottom: 4px;" />
    {{/if}}
    <span style="{{{textStyles}}};font-weight:bold; margin: 0;">{{{title}}}</span>
    <span style="{{{textStyles}}};font-weight:normal; overflow: hidden;">{{{content}}}</span>
  </div>
</div>`,
    columns: 5,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716560152267/template_vc52ph_normal.svg',
    photoAlignment: 'right',
    aspectRatio: '4 / 3',
    photoWidth: '25%'
};
const TEMPLATE_VC5NP_LAYOUT = {
    id: 'five-col-no-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 5,
    photos: 0,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents/permalink/1716559894275/template_vc5np_normal.svg',
    photoAlignment: 'center',
    aspectRatio: 'none',
    photoWidth: 'none'
};
const TEMPLATE_2COL1PH_LAYOUT = {
    id: 'two-col-one-photo',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 2,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F367f.d4956-Template-S-Normal.svg',
    photoAlignment: 'center',
    aspectRatio: '4 / 3',
    photoWidth: 'none'
};
const TEMPLATE_2COL1PHV2_LAYOUT = {
    id: 'two-col-one-photo-3-4-image',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div>
    <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4; width: 50%; float: right; margin-left: 5px; " />
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 2,
    photos: 1,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F7bd6.404dc-Template-H-Normal.svg',
    photoAlignment: 'right',
    aspectRatio: '3 / 4',
    photoWidth: '50%'
};
const TEMPLATE_2COL2PHV1_LAYOUT = {
    id: 'two-col-2-photo-3-4-image',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; margin-right: 5px; aspect-ratio: 3 / 4;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; margin-left: 5px; aspect-ratio: 3 / 4;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 2,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fef82.19618-Template-J-Normal.svg',
    photoAlignment: 'center',
    aspectRatio: '3 / 4',
    photoWidth: '100%'
};
const TEMPLATE_2COL2PHV2_LAYOUT = {
    id: 'two-col-2-photo-4-3-image',
    handlebarTemplate: `<div style="width:{{{ adWidth }}}px">
  {{#if headerImage }}
  <div style="display: flex;">
    <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; margin-right: 5px; aspect-ratio: 4 / 3;" /></div>
    <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; margin-left: 5px; aspect-ratio: 4 / 3;" /></div>
  </div>
  {{/if}}
  {{#if title }}
  <h1 style="{{{textStyles}}};font-weight:bold">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}};font-weight:normal">{{{content}}}</section>
  </div>`,
    columns: 2,
    photos: 2,
    iconSvgUrl: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F4eb6.02e6b-Template-K-Normal.svg',
    photoAlignment: 'center',
    aspectRatio: '4 / 3',
    photoWidth: '100%'
};
export const DEFAULT_CLASSIFIED_LAYOUTS = [
    TEMPLATE_2COL1PHV2_LAYOUT,
    TEMPLATE_2COL1PH_LAYOUT,
    TEMPLATE_2COL2PHV1_LAYOUT,
    TEMPLATE_2COL2PHV2_LAYOUT,
    TEMPLATE_S_LAYOUT,
    TEMPLATE_T_LAYOUT
];
export const DEFAULT_OBITUARY_LAYOUTS = [
    TEMPLATE_SV_LAYOUT,
    TEMPLATE_TV_LAYOUT,
    TEMPLATE_U_LAYOUT,
    TEMPLATE_UV_LAYOUT,
    TEMPLATE_V_LAYOUT,
    TEMPLATE_VV_LAYOUT,
    TEMPLATE_VC4NP_LAYOUT,
    TEMPLATE_VC41PV_LAYOUT,
    TEMPLATE_VC41PH_LAYOUT,
    TEMPLATE_VC42PV_LAYOUT,
    TEMPLATE_VC42PH_LAYOUT,
    TEMPLATE_VC51PV_LAYOUT,
    TEMPLATE_VC51PH_LAYOUT,
    TEMPLATE_VC52PV_LAYOUT,
    TEMPLATE_VC52PH_LAYOUT,
    TEMPLATE_VC5NP_LAYOUT
];
export const SELECTABLE_LAYOUTS = [
    ...DEFAULT_DEATH_NOTICE_LAYOUTS,
    ONE_COL_ONE_PHOTO_LAYOUT,
    ...DEFAULT_CLASSIFIED_LAYOUTS,
    ...DEFAULT_OBITUARY_LAYOUTS
];
const getTextStyles = ({ fontSize, font, leading }) => [
    `min-height:${fontSize}pt`,
    'margin:0',
    'padding:3px',
    'text-align:justify',
    `font-family:${font}`,
    // font-size will probably need to be different for headers and content, but...
    `font-size:${fontSize}pt`,
    `line-height:${leading ? `${leading}pt` : '120%'}`,
    'overflow-wrap:break-word',
    'word-spacing:0.1em',
    'letter-spacing:0',
    'font-smooth:never',
    'word-break:break-word',
    'box-sizing:border-box',
    'border: 1px dashed white'
].join(';');
const OrderContentHandlebarDataSchema = z.object({
    headerImage: z.string().optional().nullable(),
    headerImageStyles: z.string().optional().nullable(),
    secondHeaderImage: z.string().optional().nullable(),
    secondImageStyles: z.string().optional().nullable(),
    textStyles: z.string(),
    content: z.string().optional(),
    adWidth: z.number(),
    title: z.string().optional()
});
/**
 * Dynamically determine the template that we should use for a particular obituary order.
 * We re-determine the handlebar value instead of using the value cached on the object because
 * it may have changed since the object was created.
 */
const getHandlebarStringForLayout = (layout) => {
    const relevantTemplate = findLayoutById(layout.id);
    if (!relevantTemplate) {
        return wrapError(new Error('No template found for layout'));
    }
    return wrapSuccess(relevantTemplate.handlebarTemplate);
};
export const DEFAULT_DPI_VALUES = {
    INDESIGN: 72,
    PUPPETEER: 96
};
/**
 * Determine the width of an ad based on the layout and template styles. Consider the following layout:
 * | column |gutter| column |gutter| column |gutter| column |gutter| column |gutter| column |
 * Our templates don't have the column width directly, so we need to calculate it. In order to figure out
 * the width of a single column, we need to subtract the total gutter width from the page width, then divide by the
 * number of columns to get the width of a single column.
 * Once we have the width of a single column, we can multiply it by the number of columns to get the total width of the ad
 * *without gutters*. The final step is to add the total gutter width to the column width to get the final size.
 * Before exporting, we take the final step of scaling by the default DPI of puppeteer (96) over the default DPI of
 * Indesign (72).
 */
export const getAdWidth = (layout, templateStyles) => {
    const { columns } = layout;
    if (columns < 1) {
        return wrapError(new Error('Invalid number of columns'));
    }
    if (!templateStyles.pageWidth ||
        !templateStyles.columnGutter ||
        !templateStyles.columnCount) {
        return wrapError(new Error('Invalid template styles'));
    }
    const totalGutterWidth = templateStyles.columnGutter * (templateStyles.columnCount - 1);
    const pageWidthWithoutGutter = templateStyles.pageWidth - totalGutterWidth;
    const columnWidth = pageWidthWithoutGutter / templateStyles.columnCount;
    const adWidthFromColumns = columnWidth * layout.columns;
    const adWidthFromGutter = templateStyles.columnGutter * (columns - 1);
    const adWidth = adWidthFromColumns + adWidthFromGutter;
    const scaledAdWidth = adWidth * (DEFAULT_DPI_VALUES.PUPPETEER / DEFAULT_DPI_VALUES.INDESIGN);
    return wrapSuccess(scaledAdWidth);
};
// TODO: Figure out what we want to do around placeholders in previews
const PLACEHOLDER_IMG = 'https://placehold.co/600x400?text=Preview';
export const layoutOrderContent = (layout, adContent, templateStyles) => {
    var _a, _b;
    const { response: handlebarString, error: handlebarError } = getHandlebarStringForLayout(layout);
    if (handlebarError) {
        return wrapError(handlebarError);
    }
    const handlebarTemplate = EHandlebars.compile(handlebarString);
    const { response: adWidth, error: adWidthError } = getAdWidth(layout, templateStyles);
    if (adWidthError) {
        return wrapError(adWidthError);
    }
    const firstOrderImage = (_a = adContent.orderImages) === null || _a === void 0 ? void 0 : _a[0];
    const secondOrderImage = (_b = adContent.orderImages) === null || _b === void 0 ? void 0 : _b[1];
    const htmlContent = handlebarTemplate({
        title: adContent.title,
        content: adContent.content,
        secondHeaderImage: (secondOrderImage === null || secondOrderImage === void 0 ? void 0 : secondOrderImage.imageUrl) ||
            adContent.secondHeaderImage ||
            PLACEHOLDER_IMG,
        headerImage: (firstOrderImage === null || firstOrderImage === void 0 ? void 0 : firstOrderImage.imageUrl) || adContent.headerImage || PLACEHOLDER_IMG,
        adWidth,
        textStyles: getTextStyles(templateStyles),
        headerImageStyles: getInlineCSSStylesFromCrop((firstOrderImage === null || firstOrderImage === void 0 ? void 0 : firstOrderImage.crop) || adContent.headerImageCrop),
        secondImageStyles: getInlineCSSStylesFromCrop((secondOrderImage === null || secondOrderImage === void 0 ? void 0 : secondOrderImage.crop) || adContent.secondImageCrop)
    });
    return wrapSuccess({ htmlContent, adWidth });
};
export const createHtmlDocument = (htmlContent, fontResources) => {
    const document = `
      <!DOCTYPE html>
      <html style="margin: 0; padding: 0;">
          <head>
              <style>
                  ${fontResources.map(getFontFaceDefinition).join('')}
                  html {
                      -webkit-print-color-adjust: exact;
                      font-size: 0;
                  }
              </style>
          </head>
          <body style="margin: 0; padding: 0;">
              <div id="listing">
                  ${htmlContent}
              </div>
          </body>
      </html>`;
    return document;
};
export function getDefaultLayoutsByFilingType(filingType) {
    if (filingType.label === ObituaryFilingTypeNames.DeathNotice ||
        filingType.label === ClassifiedFilingTypeNames.CommunityEngagementLetters) {
        return DEFAULT_DEATH_NOTICE_LAYOUTS;
    }
    if (filingType.label === ObituaryFilingTypeNames.Obituary) {
        return [
            TWO_COL_DEATH_NOTICE,
            ...DEFAULT_CLASSIFIED_LAYOUTS,
            ...DEFAULT_OBITUARY_LAYOUTS
        ];
    }
    return [...DEFAULT_DEATH_NOTICE_LAYOUTS, ...DEFAULT_CLASSIFIED_LAYOUTS];
}
export function findLayoutById(layoutId) {
    return SELECTABLE_LAYOUTS.find(layout => layout.id === layoutId);
}
