import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import ImageEditorOrUploader from './ImageEditorOrUploader';
export default function OrderImageEditor({ adData, onChange, layout, disableEditing }) {
    const [localOrderImages, setLocalOrderImages] = useState(adData.orderImages || []);
    if (layout.photos === 0) {
        return null;
    }
    const insertImageAtIndex = (index, newOrderImage) => {
        const newOrderImages = [...localOrderImages];
        newOrderImages[index] = newOrderImage;
        onChange({ orderImages: newOrderImages.filter(Boolean) });
        setLocalOrderImages(newOrderImages);
    };
    const removeImageAtIndex = (index) => {
        const newOrderImages = [...localOrderImages];
        newOrderImages[index] = null;
        onChange({ orderImages: newOrderImages.filter(Boolean) });
        setLocalOrderImages(newOrderImages);
    };
    const FirstImageEditor = (_jsx(ImageEditorOrUploader, { orderImage: localOrderImages[0] || null, layout: layout, onDeleteOrderImage: () => removeImageAtIndex(0), onUpdateOrderImage: newOrderImage => insertImageAtIndex(0, newOrderImage), disabled: disableEditing }));
    if (layout.photos === 1) {
        if (layout.photoAlignment === 'center') {
            return (_jsx("div", Object.assign({ className: "flex items-center w-full", style: { aspectRatio: layout.aspectRatio } }, { children: FirstImageEditor })));
        }
        return (_jsx("div", Object.assign({ className: "relative z-10 float-right bg-white border-dashed border-b border-l p-2", style: { width: layout.photoWidth, aspectRatio: layout.aspectRatio } }, { children: FirstImageEditor })));
    }
    if (layout.photos === 2) {
        const SecondImageEditor = (_jsx(ImageEditorOrUploader, { orderImage: localOrderImages[1] || null, layout: layout, onDeleteOrderImage: () => removeImageAtIndex(1), onUpdateOrderImage: newOrderImage => insertImageAtIndex(1, newOrderImage), disabled: disableEditing }));
        if (layout.photoAlignment === 'center') {
            return (_jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsx("div", Object.assign({ className: "flex-1", style: { aspectRatio: layout.aspectRatio } }, { children: FirstImageEditor })), _jsx("div", Object.assign({ className: "flex-1", style: { aspectRatio: layout.aspectRatio } }, { children: SecondImageEditor }))] })));
        }
        return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "relative z-10 float-right bg-white border-dashed border-b border-l p-2", style: { width: layout.photoWidth, aspectRatio: layout.aspectRatio } }, { children: FirstImageEditor })), _jsx("div", Object.assign({ className: "relative z-10 float-right bg-white border-dashed border-b border-l p-2", style: { width: layout.photoWidth, aspectRatio: layout.aspectRatio } }, { children: SecondImageEditor }))] }));
    }
    logAndCaptureCriticalError(ColumnService.OBITS, new Error(`Unexpected layout.photos value: ${layout.photos}`), 'Unexpected layout.photos value');
    return null;
}
