import { jsx as _jsx } from "react/jsx-runtime";
import { createStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import * as typeformEmbed from '@typeform/embed';
const objectToUrlParams = (obj) => Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');
const styles = () => createStyles({
    root: {
        marginLeft: -24,
        marginRight: -24,
        marginTop: -24,
        height: 'calc(100vh - 64px)',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        background: 'white'
    },
    formContainer: {
        width: 'calc(100% - 100px)',
        marginRight: 60,
        height: '100%'
    }
});
const TYPEFORM_BASE = 'https://leohentschker154703.typeform.com/to';
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
function TypeformEmbed({ onSubmit, formId, hiddenFields, classes, applyClasses = true }) {
    const [mounted, setMounted] = useState(false);
    const prevProps = usePrevious({ formId });
    let formContainer = null;
    const loadForm = () => {
        let timesRun = 0;
        if (!formContainer)
            return;
        typeformEmbed.makeWidget(formContainer, `${TYPEFORM_BASE}/${formId}?${objectToUrlParams(hiddenFields)}`, {
            onSubmit: () => {
                if (timesRun > 0) {
                    return;
                }
                timesRun += 1;
                onSubmit();
            }
        });
    };
    useEffect(() => {
        setMounted(true);
        loadForm();
    }, []);
    useEffect(() => {
        if (mounted && formId !== (prevProps === null || prevProps === void 0 ? void 0 : prevProps.formId)) {
            loadForm();
        }
    }, [mounted, formId]);
    return (_jsx("div", Object.assign({ className: applyClasses ? classes.root : 'h-full' }, { children: _jsx("div", { className: applyClasses ? classes.formContainer : 'h-full', ref: ref => (formContainer = ref), id: "form-container" }) })));
}
export default withStyles(styles, { withTheme: true })(TypeformEmbed);
