var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import FullScreenModal from 'components/FullScreenModal';
import { extractGlobalNoticeTypeSettings } from './noticeTypeGlobalSettingsHelpers';
import BasicGlobalNoticeTypeSettingsCard from './BasicGlobalNoticeTypeSettingsCard';
export default function NoticeTypeGlobalSettings({ activeOrganization, onClose }) {
    const [newGlobalSettings, setNewGlobalSettings] = useState(extractGlobalNoticeTypeSettings(activeOrganization));
    const [loading, setLoading] = useState(false);
    const updateGlobalNoticeTypeSettings = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        yield activeOrganization.ref.update(newGlobalSettings);
        setLoading(false);
        onClose();
    });
    const edited = !columnObjectsAreEqual(newGlobalSettings, extractGlobalNoticeTypeSettings(activeOrganization));
    const disableSave = loading || !edited;
    return (_jsx(FullScreenModal, Object.assign({ submittable: {
            buttonText: 'Save',
            disabled: disableSave,
            onSubmit: updateGlobalNoticeTypeSettings
        }, headerText: "Edit Notice Type Settings", onClose: onClose, id: "notice-type-update-form" }, { children: _jsx(BasicGlobalNoticeTypeSettingsCard, { onUpdateGlobalSettings: setNewGlobalSettings, newGlobalSettings: newGlobalSettings }) })));
}
