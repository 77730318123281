var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { Form } from 'lib/components/Form';
import ScrollStep from './ScrollStep';
import { SCHEDULE_NOTICE } from './helpers/calculatePlacementSteps';
import { ReferenceIdForm, useReferenceIdState } from './ReferenceIdForm';
import { InvoiceRecipientForm, useInvoiceRecipientFormState } from './InvoiceRecipientForm';
import { ConfirmScheduleForm, useConfirmScheduleState } from './ConfirmScheduleForm';
import { AffidavitRecipientsForm } from './AffidavitRecipients/AffidavitRecipientsForm';
import { useAffidavitRecipientsState } from './AffidavitRecipients/useAffidavitRecipientsState';
import SectionDivider from './SectionDivider';
import { useFetchCustomer } from './hooks/useFetchCustomer';
import { useFetchCustomerOrganization } from './hooks/useFetchCustomerOrganization';
export function ScheduleNoticeStep({ publisherOrganization, notice, filer, next, previous, onDisabledStepClick }) {
    const stepId = SCHEDULE_NOTICE;
    const isPublisher = useAppSelector(selectIsPublisher);
    const customer = useFetchCustomer();
    const customerOrganization = useFetchCustomerOrganization();
    const { referenceId, setReferenceId, saveReferenceId } = useReferenceIdState();
    const { isStepComplete: invoiceRecipientComplete, onExit: updateInvoiceRecipientInformation, firstName, setFirstName, lastName, setLastName, organizationName, setOrganizationName, email, setEmail, addressLine1, setAddressLine1, addressLine2, setAddressLine2, city, setCity, state, setState, zip, setZip, sendToEmail, setSendToEmail, sendToSomeoneElse, setSendToSomeoneElse, billTo, setBillTo } = useInvoiceRecipientFormState();
    const { onExit: confirmSchedule, complete: confirmScheduleComplete } = useConfirmScheduleState({
        newspaper: publisherOrganization,
        notice
    });
    const { state: affidavitFormState, dispatch: affidavitStateDispatch, validateAffidavitForm, complete: affidavitMailingComplete, doesNotRequireEmailAffidavit, hideEmailAffidavitOption, customerMustReceiveMailedAffidavit } = useAffidavitRecipientsState({
        stepId,
        publisherOrganization,
        draft: notice,
        filer
    });
    const title = 'Tell us a bit more about your notice';
    const caption = `Provide details to help ${publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data().name} publish this notice correctly.`;
    const complete = confirmScheduleComplete &&
        invoiceRecipientComplete &&
        affidavitMailingComplete;
    function handleNext() {
        return __awaiter(this, void 0, void 0, function* () {
            saveReferenceId();
            updateInvoiceRecipientInformation();
            confirmSchedule();
            const { success: affidavitAddressValidationSuccess } = yield validateAffidavitForm();
            if (affidavitAddressValidationSuccess) {
                next();
            }
        });
    }
    const formId = `${stepId}-referenceId-form`;
    return (_jsxs(ScrollStep, Object.assign({ id: stepId, formId: formId, title: title, caption: caption, previous: previous, onDisabledStepClick: onDisabledStepClick, complete: complete }, { children: [_jsxs("div", Object.assign({ className: "space-y-6" }, { children: [_jsx(Form, Object.assign({ id: formId, onSubmit: handleNext }, { children: _jsx(ReferenceIdForm, { referenceId: referenceId, setReferenceId: setReferenceId }) })), _jsx(ConfirmScheduleForm, { notice: notice, newspaper: publisherOrganization, customer: customer, customerOrganization: customerOrganization }), _jsx(InvoiceRecipientForm, { isPublisher: isPublisher, firstName: firstName, setFirstName: setFirstName, lastName: lastName, setLastName: setLastName, organizationName: organizationName, setOrganizationName: setOrganizationName, email: email, setEmail: setEmail, addressLine1: addressLine1, setAddressLine1: setAddressLine1, addressLine2: addressLine2, setAddressLine2: setAddressLine2, city: city, setCity: setCity, state: state, setState: setState, zip: zip, setZip: setZip, sendToEmail: sendToEmail, setSendToEmail: setSendToEmail, sendToSomeoneElse: sendToSomeoneElse, setSendToSomeoneElse: setSendToSomeoneElse, billTo: billTo, setBillTo: setBillTo })] })), _jsx("div", Object.assign({ className: "uppercase my-8" }, { children: _jsx(SectionDivider, { text: "Affidavit Preferences" }) })), _jsx(AffidavitRecipientsForm, { state: affidavitFormState, dispatch: affidavitStateDispatch, publisherOrganization: publisherOrganization, doesNotRequireEmailAffidavit: doesNotRequireEmailAffidavit, hideEmailAffidavitOption: hideEmailAffidavitOption, customerMustReceiveMailedAffidavit: customerMustReceiveMailedAffidavit })] })));
}
