var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Product } from 'lib/enums';
import { safeGetModelFromRef } from 'lib/model/getModel';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { ColumnService } from 'lib/services/directory';
import { getErrors, getResponses, wrapError, wrapSuccess } from 'lib/types/responses';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
const fetchNewspaperAndFilingType = ({ newspaperOrder, selectedFilingTypeLabel, product, publishingMedium, anonymousOrder }) => __awaiter(void 0, void 0, void 0, function* () {
    const { newspaper: newspaperRef } = newspaperOrder;
    if (!newspaperRef) {
        return wrapError(new Error('Newspaper order is missing newspaper'));
    }
    if (!selectedFilingTypeLabel) {
        return wrapError(new Error('Selected filing type label is missing'));
    }
    const { response: newspaper, error: newspaperError } = yield safeGetModelFromRef(OrganizationModel, getFirebaseContext(), newspaperRef);
    if (newspaperError) {
        return wrapError(newspaperError);
    }
    const { response: matchedFilingType, error: matchedFilingTypeError } = yield newspaper.isFilingTypeAvailableForNewspaper({
        selectedFilingType: selectedFilingTypeLabel,
        product,
        publishingMedium,
        anonymousOrder
    });
    if (matchedFilingTypeError) {
        return wrapError(matchedFilingTypeError);
    }
    if (!matchedFilingType) {
        return wrapError(new Error('Filing type not available for newspaper'));
    }
    return wrapSuccess({
        newspaperId: newspaper.id,
        newspaperName: newspaper.modelData.name,
        filingTypeLabel: matchedFilingType.modelData.label,
        madlibConfig: matchedFilingType.modelData.madlib
    });
});
export const isTemplateValidationIssue = (details) => {
    return !!details
        .papersWithTemplatedFilingTypes;
};
export const validateNewspapersAndFilingTypes = ({ newspaperOrders, selectedFilingTypeLabel, product, publishingMedium, anonymousOrder }) => __awaiter(void 0, void 0, void 0, function* () {
    /**
     * The validation check in this step should only apply to classifieds because
     * the user will select the filing type prior to selecting a publication. For
     * obits, this validation happens as part of the filing type selection in the
     * scheduling step (see SelectSchedules/validation.ts).
     */
    if (product !== Product.Classified) {
        return {
            isValid: true
        };
    }
    const newspaperAndFilingTypeResults = yield Promise.all(newspaperOrders.map(newspaperOrder => fetchNewspaperAndFilingType({
        newspaperOrder,
        selectedFilingTypeLabel,
        product,
        publishingMedium,
        anonymousOrder
    })));
    const errors = getErrors(newspaperAndFilingTypeResults);
    if (errors.length > 0) {
        logAndCaptureException(ColumnService.OBITS, errors, 'Error validating publication selection in order placement flow');
        return {
            isValid: false,
            details: {
                errors
            }
        };
    }
    const newspapersAndFilingTypes = getResponses(newspaperAndFilingTypeResults);
    const uniqueMadlibConfigs = Array.from(new Set(newspapersAndFilingTypes.map(({ madlibConfig }) => madlibConfig !== null && madlibConfig !== void 0 ? madlibConfig : '')));
    const papersWithTemplatedFilingTypes = newspapersAndFilingTypes
        .filter(({ madlibConfig }) => madlibConfig)
        .map(({ newspaperName }) => newspaperName);
    if (uniqueMadlibConfigs.length > 1) {
        return {
            isValid: false,
            details: {
                papersWithTemplatedFilingTypes
            }
        };
    }
    return {
        isValid: true
    };
});
export const getOnlyValidNewspapersAndFilingTypes = ({ newspaperOrders, selectedFilingTypeLabel, product, publishingMedium, anonymousOrder }) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperAndFilingTypeResults = yield Promise.all(newspaperOrders.map(newspaperOrder => fetchNewspaperAndFilingType({
        newspaperOrder,
        selectedFilingTypeLabel,
        product,
        publishingMedium,
        anonymousOrder
    })));
    const errors = getErrors(newspaperAndFilingTypeResults);
    if (errors.length > 0) {
        logAndCaptureException(ColumnService.OBITS, errors, 'Error validating publication selection in order placement flow');
        return [];
    }
    const newspapersAndFilingTypes = getResponses(newspaperAndFilingTypeResults);
    const uniqueMadlibConfigs = Array.from(new Set(newspapersAndFilingTypes.map(({ madlibConfig }) => madlibConfig !== null && madlibConfig !== void 0 ? madlibConfig : '')));
    /**
     * If there is only one madlib config, this means either no newspaper
     * has a template for this category or they all have the same one,
     * so we can retain all of them
     */
    if (uniqueMadlibConfigs.length === 1) {
        return newspaperOrders;
    }
    const papersWithoutTemplatesForCategory = newspapersAndFilingTypes
        .filter(({ madlibConfig }) => !madlibConfig)
        .map(({ newspaperId }) => newspaperOrders.find(({ newspaper }) => (newspaper === null || newspaper === void 0 ? void 0 : newspaper.id) === newspaperId))
        .filter((possibleNewspaperOrder) => !!possibleNewspaperOrder);
    return papersWithoutTemplatesForCategory;
});
