var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckIcon, ChevronDownIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { Popover } from 'lib/components/Popover';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import React, { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { shouldShowDisabledColumnForUser } from 'lib/pagination/helpers';
import { exists } from 'lib/types';
import { isColumnUser } from 'lib/helpers';
import { Product } from 'lib/enums';
import { PublicationIssueStatusLabels } from './paginationTableUtils';
const OBIT_AVAILABLE_STATUSES = [
    PublicationIssueStatus.PENDING,
    PublicationIssueStatus.APPROVED,
    PublicationIssueStatus.DISABLED,
    PublicationIssueStatus.ARCHIVED
];
const CLASSIFIED_AVAILABLE_STATUSES = [
    PublicationIssueStatus.PENDING,
    PublicationIssueStatus.APPROVED,
    PublicationIssueStatus.DISABLED,
    PublicationIssueStatus.ARCHIVED
];
const filterObitIssueStatuses = (status) => OBIT_AVAILABLE_STATUSES.includes(status);
const filterClassifiedIssueStatuses = (status) => CLASSIFIED_AVAILABLE_STATUSES.includes(status);
const filterNoticeIssueStatuses = (user) => shouldShowDisabledColumnForUser(user)
    ? () => true
    : (status) => status !== PublicationIssueStatus.DISABLED;
export const getFilterStatusesFromProduct = (product, user) => {
    switch (product) {
        case Product.Obituary:
            return filterObitIssueStatuses;
        case Product.Classified:
            return filterClassifiedIssueStatuses;
        default:
            return filterNoticeIssueStatuses(user);
    }
};
export const blockUserFromChangingToStatus = (user, publisher, currentStatus, newStatus, product) => {
    var _a;
    if (currentStatus === newStatus) {
        return true;
    }
    if (product === Product.Obituary) {
        if (currentStatus === PublicationIssueStatus.PENDING &&
            newStatus === PublicationIssueStatus.APPROVED) {
            return false;
        }
        return true;
    }
    // Keeping separate from the previous block to allow for divergence between products
    if (product === Product.Classified) {
        if (currentStatus === PublicationIssueStatus.PENDING &&
            newStatus === PublicationIssueStatus.APPROVED) {
            return false;
        }
        return true;
    }
    // Column users can change the status of any issue at any time
    if (isColumnUser(user)) {
        // Column users can go from pending to ready for pagination
        if (currentStatus === PublicationIssueStatus.PENDING &&
            newStatus === PublicationIssueStatus.READY_FOR_PAGINATION) {
            return false;
        }
        // Column users *not on pagination* can go from pending to archived
        if (currentStatus === PublicationIssueStatus.PENDING &&
            newStatus === PublicationIssueStatus.ARCHIVED &&
            !((_a = publisher.data().planSettings) === null || _a === void 0 ? void 0 : _a.features.customPagination)) {
            return false;
        }
        // Column users can go from ready for pagination to awaiting approval
        if (currentStatus === PublicationIssueStatus.READY_FOR_PAGINATION &&
            newStatus === PublicationIssueStatus.AWAITING_APPROVAL) {
            return false;
        }
        // Column users can go from approved back to ready for pagination
        if (currentStatus === PublicationIssueStatus.APPROVED &&
            newStatus === PublicationIssueStatus.READY_FOR_PAGINATION) {
            return false;
        }
        // Column users can request changes on blocks
        if (currentStatus === PublicationIssueStatus.AWAITING_APPROVAL &&
            newStatus === PublicationIssueStatus.CHANGES_REQUESTED) {
            return false;
        }
        // Column users can shift pagination issues back to the reps team
        if (newStatus === PublicationIssueStatus.PENDING) {
            return false;
        }
        // Column users can move anything out of completed statuses for more work
        if (currentStatus === PublicationIssueStatus.PRINT_READY ||
            currentStatus === PublicationIssueStatus.DISABLED ||
            currentStatus === PublicationIssueStatus.APPROVED ||
            currentStatus === PublicationIssueStatus.ARCHIVED) {
            return false;
        }
    }
    // liaisons can approve blocks
    if (currentStatus === PublicationIssueStatus.AWAITING_APPROVAL &&
        newStatus === PublicationIssueStatus.APPROVED) {
        return false;
    }
    // liaisons can request changes on blocks
    if (currentStatus === PublicationIssueStatus.AWAITING_APPROVAL &&
        newStatus === PublicationIssueStatus.CHANGES_REQUESTED) {
        return false;
    }
    // liaisons can mark blocks as print ready
    if (currentStatus === PublicationIssueStatus.APPROVED &&
        newStatus === PublicationIssueStatus.PRINT_READY) {
        return false;
    }
    // liaisons can go from approved back to ready for pagination
    if (currentStatus === PublicationIssueStatus.APPROVED &&
        newStatus === PublicationIssueStatus.AWAITING_APPROVAL) {
        return false;
    }
    // liaisons can go from print ready back to ready for pagination
    if (currentStatus === PublicationIssueStatus.PRINT_READY &&
        newStatus === PublicationIssueStatus.AWAITING_APPROVAL) {
        return false;
    }
    // liaisons can move any block out of disabled at their discretion in case of issue
    if (currentStatus === PublicationIssueStatus.DISABLED &&
        newStatus !== PublicationIssueStatus.DISABLED) {
        return false;
    }
    // block all other status changes
    return true;
};
function IssueStatusOptions({ id, currentStatus, handleStatusChange, publisher, product }) {
    const user = useAppSelector(selectUser);
    if (!exists(user))
        return null;
    const filterStatuses = getFilterStatusesFromProduct(product, user);
    return (_jsx("div", Object.assign({ className: "w-48 space-y-2 p-4", onClick: (e) => {
            // Prevent the drawer from opening
            e.stopPropagation();
        } }, { children: Object.values(PublicationIssueStatus)
            .filter(filterStatuses)
            .map((statusOption, i) => {
            return (_jsx("div", Object.assign({ className: "w-full flex" }, { children: _jsx(ColumnButton, { type: 'button', link: true, secondary: currentStatus !== statusOption, tertiary: currentStatus === statusOption, buttonText: PublicationIssueStatusLabels[statusOption], disabled: blockUserFromChangingToStatus(user, publisher, currentStatus, statusOption, product), onClick: () => {
                        void handleStatusChange(statusOption);
                    } }, `${id}-${i}`) }), `wrapper-${id}-${i}`));
        }) })));
}
function getIssueStatusBadgeIcon(status) {
    switch (status) {
        case PublicationIssueStatus.APPROVED:
            return _jsx(CheckIcon, { className: "w-4 h-4" });
        default:
            return _jsx(ClockIcon, { className: "w-4 h-4" });
    }
}
const getIssueStatusBadgeStyle = (status) => {
    switch (status) {
        case PublicationIssueStatus.APPROVED:
            return 'success';
        case PublicationIssueStatus.AWAITING_APPROVAL:
            return 'warning';
        default:
            return undefined;
    }
};
export function IssueStatusBadge({ publicationIssue, section, reloadPublicationIssues, publisher, product }) {
    const user = useAppSelector(selectUser);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { publicationDate } = publicationIssue.modelData;
    const { status } = (section || publicationIssue).modelData;
    const handleStatusChange = (newStatus) => __awaiter(this, void 0, void 0, function* () {
        if (newStatus === PublicationIssueStatus.APPROVED) {
            setShowConfirmationModal(true);
        }
        else {
            yield updateStatusAndReload(newStatus);
        }
    });
    const handleConfirmApproval = () => __awaiter(this, void 0, void 0, function* () {
        yield updateStatusAndReload(PublicationIssueStatus.APPROVED);
        setShowConfirmationModal(false);
    });
    const updateStatusAndReload = (status) => __awaiter(this, void 0, void 0, function* () {
        if (!exists(user)) {
            return;
        }
        setLoading(true);
        yield (section || publicationIssue).updateStatus(user, status);
        void reloadPublicationIssues();
    });
    return (_jsxs(_Fragment, { children: [loading ? (_jsx("div", Object.assign({ className: "inline-flex" }, { children: _jsx(LoadingSpinner, { size: "sm" }) }))) : (_jsx(Popover, Object.assign({ id: `issue-status-popover-${publicationIssue.id}`, activator: _jsx(Badge, Object.assign({ status: getIssueStatusBadgeStyle(status), startIcon: getIssueStatusBadgeIcon(status) }, { children: _jsxs("div", Object.assign({ className: "flex space-x-1 cursor-pointer" }, { children: [_jsx("span", { children: PublicationIssueStatusLabels[status] }), _jsx("span", Object.assign({ className: "m-auto" }, { children: _jsx(ChevronDownIcon, { className: "w-3 h-3" }) }))] })) })) }, { children: _jsx(IssueStatusOptions, { id: `issue-status-${publicationIssue.id}`, currentStatus: status, handleStatusChange: handleStatusChange, publisher: publisher, product: product }) }))), showConfirmationModal && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setShowConfirmationModal(false), primaryButtonText: 'Submit', tertiaryButtonText: "Cancel", onSubmit: handleConfirmApproval, header: _jsxs("div", Object.assign({ className: "text-xl font-bold" }, { children: ["Approve ", publicationDate, " for ", publisher.data().name] })) }, { children: _jsx("div", Object.assign({ className: "text-l font-bold py-4" }, { children: "Are you sure you want to submit this issue for approval?" })) })))] }));
}
