export const getIntegrationIsConfiguredHTTP = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.format) === 'HTTP';
};
export const DEFAULT_FTP_PORT = 21;
export const DEFAULT_SFTP_PORT = 22;
/**
 * For validating structure of `IntegrationUploadSettings` before using them to generate the FTP
 * `upload` and `close` functions in `getUploadFunctionsFTP`
 */
export const getIntegrationIsConfiguredFTP = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.format) === 'FTP';
};
export const SYNC_ON_CANCEL = 'sync_on_cancel';
export const SYNC_ON_CONFIRM = 'sync_on_confirm';
export const SYNC_ON_EDIT = 'sync_on_edit';
export const SYNC_ON_INVOICE_CREATE = 'sync_on_invoice_create';
export const SYNC_ON_INVOICE_PAYMENT_INITIATED = 'sync_on_invoice_payment_initiated';
export const SYNC_ON_INVOICE_PAID = 'sync_on_invoice_paid';
export const SYNC_ON_INVOICE_REFUND = 'sync_on_invoice_refund';
export const SYNC_ON_NOTICE_CREATE = 'sync_on_notice_create';
export const SYNC_ON_CUSTOM_ID_UPDATE = 'sync_on_custom_id_update';
export const SYNC_ON_DEADLINE = 'sync_on_deadline';
export const SYNC_ON_RESPONSE = 'sync_on_response';
export const SYNC_ON_UPFRONT_PAYMENT_WAIVER = 'sync_on_upfront_payment_waiver';
export const MANUAL_SYNC = 'manual_sync';
// these sync triggers are designated for build integrations (i.e., systems like AffinityX that actually build the ad content)
// they should always include the string `build_ad` (see `isTriggerEventForBuildIntegration`)
export const MANUAL_BUILD_AD = 'manual_build_ad';
export const MANUAL_CANCEL_BUILD_AD = 'manual_cancel_build_ad';
export const BUILD_AD_ON_CANCEL = 'build_ad_on_cancel';
export const GANNETT = 'GANNETT';
export const LEE_ADPOINT = 'LEE_ADPOINT';
export const WICK_AFFINITY = 'WICK_AFFINITY';
export const COLUMN_AFFINITY = 'COLUMN_AFFINITY';
