import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { useState } from 'react';
import RefundInvoiceModalInnerActionBox from './RefundInvoiceModalInnerActionBox';
import RefundInvoiceModalInnerDisplayBox from './RefundInvoiceModalInnerDisplayBox';
export default function RefundInvoiceModalInner({ loading, disabled, notice, newspaper, invoice, refundReason, refundUIProps, isFullRefund, lineItemValues, invoicePubLineItemDiffs, refundAmount, setOpen, setRefundReason, setDisabled, setIsFullRefund, setRefundAmount }) {
    var _a;
    const { refundDisabled, destructive, body, handler, secondaryHandler, buttonText, buttonId, header } = refundUIProps;
    const disableButtons = refundDisabled || disabled || loading || !refundReason;
    const disableSecondaryButton = disableButtons || !isFullRefund;
    const [totalRefundedInCentsFromPriorInvoiceTransactions, setTotalRefundedInCentsFromPriorInvoiceTransactions] = useState((_a = invoice.data().refund_amount) !== null && _a !== void 0 ? _a : 0);
    return (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setOpen(false), header: "Process a Refund", destructive: destructive, showLoadingSpinner: loading, tertiaryButtonText: "Back", primaryButtonText: buttonText, primaryButtonId: buttonId, secondaryButtonText: "Void Invoice and Refund Full Amount", disablePrimaryButton: disableButtons, disableSecondaryButton: disableSecondaryButton, formId: "void-invoice-form", onSubmit: handler, onSecondaryButtonClick: secondaryHandler, maxHeightOverride: "70vh", maxWidth: "sm:max-w-6xl" }, { children: _jsxs("div", Object.assign({ id: "refund-modal-container", className: "flex flex-row h-full" }, { children: [_jsx("div", Object.assign({ id: "refund-modal-left", className: "flex-1 overflow-y-auto w-2/3" }, { children: _jsx(RefundInvoiceModalInnerDisplayBox, { notice: notice, newspaper: newspaper, invoice: invoice, lineItemValues: lineItemValues, invoicePubLineItemDiffs: invoicePubLineItemDiffs, totalRefundedInCentsFromPriorInvoiceTransactions: totalRefundedInCentsFromPriorInvoiceTransactions, setTotalRefundedInCentsFromPriorInvoiceTransactions: setTotalRefundedInCentsFromPriorInvoiceTransactions }) })), _jsx("div", Object.assign({ id: "refund-modal-right", className: "w-1/3 h-1/3 hidden lg:block" }, { children: _jsx(RefundInvoiceModalInnerActionBox, { invoice: invoice, header: header, isPublisher: true, gateway: newspaper.data().gateway, isInvoicedOutsideColumn: notice.data().invoicedOutsideColumn, setRefundReason: setRefundReason, refundReason: refundReason, setDisabled: setDisabled, refundAmount: refundAmount, setRefundAmount: setRefundAmount, body: body, setIsFullRefund: setIsFullRefund, totalRefundedInCentsFromPriorInvoiceTransactions: totalRefundedInCentsFromPriorInvoiceTransactions }) }))] })) })));
}
