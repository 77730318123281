import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { matchPath } from 'react-router';
import PlacementActions from 'redux/placement';
import AuthActions from 'redux/auth';
import PlaceNotice from './PlaceNotice';
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path)),
    replace: (path) => dispatch(replace(path)),
    placementActions: bindActionCreators(PlacementActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        activeOrganization: state.auth.activeOrganization,
        isPublisher: state.auth.isPublisher,
        user: state.auth.user,
        isAnonymous: (_a = state.auth.userAuth) === null || _a === void 0 ? void 0 : _a.isAnonymous,
        placement: state.placement,
        noticeId: (((_b = matchPath(state.router.location.pathname, {
            path: '/place/:id/',
            exact: true,
            strict: false
        })) === null || _b === void 0 ? void 0 : _b.params) || {}).id
    });
};
export default connect(mapStateToProps, mapDispatchToProps)(PlaceNotice);
