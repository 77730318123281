var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { Badge } from 'lib/components/Badge';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { formatDateString } from 'lib/utils/dates';
import { useState } from 'react';
import { selectActiveOrganization, selectIsPublisher } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { VerificationFilterString } from 'routes/ads/filters/helpers';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { logAndCaptureException } from 'utils';
import { DeceasedDetailItem } from './DeceasedDetailItem';
const getVerificationStatus = (obituary) => obituary.isVerified
    ? VerificationFilterString.COMPLETE
    : VerificationFilterString.INCOMPLETE;
const verificationOptions = [
    VerificationFilterString.COMPLETE,
    VerificationFilterString.INCOMPLETE
].map(option => ({
    label: option,
    value: option
}));
export function DeceasedDetailsCard({ obituary, newspaperOrders }) {
    var _a;
    const dispatch = useAppDispatch();
    const isPublisher = useAppSelector(selectIsPublisher);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const disableVerificationStatusSelect = [
        NewspaperOrderStatus.ARCHIVED,
        NewspaperOrderStatus.CANCELLED,
        NewspaperOrderStatus.COMPLETE,
        undefined
    ].includes((_a = newspaperOrders
        .find(o => o.data().newspaper.id === (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id))) === null || _a === void 0 ? void 0 : _a.data().status);
    const [verificationStatus, setVerificationStatus] = useState(getVerificationStatus(obituary));
    const [firstLoad, setFirstLoad] = useState(true);
    const { isLoading, isError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            try {
                if (firstLoad) {
                    setFirstLoad(false);
                    return;
                }
                yield obituary.setVerificationStatus(verificationStatus === VerificationFilterString.COMPLETE);
                dispatch(ToastActions.toastSuccess({
                    headerText: 'Success',
                    bodyText: 'Verification status updated'
                }));
            }
            catch (e) {
                logAndCaptureException(ColumnService.OBITS, e, '[OBITS BETA] Publisher verification of obituary failed', { obituaryId: obituary.id, service: ColumnService.OBITS });
                dispatch(ToastActions.toastError({
                    headerText: 'Failure',
                    bodyText: 'There was an error. Please update the verification again.'
                }));
            }
        }),
        dependencies: [verificationStatus]
    });
    if (isError ||
        !obituary.modelData.deceasedBirthDate ||
        !obituary.modelData.deceasedDeathDate) {
        return (_jsx(Alert, { id: "deceased-details-loading-failure", description: "Failed to load deceased details" }));
    }
    if (isLoading) {
        return _jsx(LoadingState, { context: { service: ColumnService.OBITS } });
    }
    return (_jsx(AdDetailsCard, Object.assign({ id: "deceased-details", header: { title: 'Deceased Details' } }, { children: _jsxs("div", Object.assign({ className: "grid gap-4" }, { children: [_jsx(DeceasedDetailItem, { label: "Name", value: _jsx("div", Object.assign({ className: "text-column-gray-500" }, { children: obituary.modelData.deceasedName })) }), isPublisher && (_jsx(DeceasedDetailItem, { label: "Verification", value: _jsx(ColumnSelect, { id: "verification-select", labelText: "", size: "small", options: verificationOptions, onChange: value => {
                            setVerificationStatus(value);
                        }, value: verificationStatus, disabled: disableVerificationStatusSelect }) })), _jsx(DeceasedDetailItem, { label: "Date of Death", value: _jsx(Badge, Object.assign({ size: "md" }, { children: formatDateString(obituary.modelData.deceasedDeathDate, 'MM/DD/YYYY') })) }), _jsx(DeceasedDetailItem, { label: "Date of Birth", value: _jsx(Badge, Object.assign({ size: "md" }, { children: formatDateString(obituary.modelData.deceasedBirthDate, 'MM/DD/YYYY') })) })] })) })));
}
