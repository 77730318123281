var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'lib/components/Badge';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { capitalizeWord } from 'lib/utils/strings';
import React from 'react';
import { selectActiveOrganizationModel } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getBadgeStatusForNewspaperOrderStatus } from 'routes/ads/helpers';
import { logAndCaptureException } from 'utils';
import ToastActions from 'redux/toast';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { ColumnService } from 'lib/services/directory';
const editableStatuses = [
    NewspaperOrderStatus.AWAITING_REVIEW,
    NewspaperOrderStatus.IN_REVIEW,
    NewspaperOrderStatus.CONFIRMED
];
export function ConfirmationStatusSelector({ newspaperOrder }) {
    const activeOrganization = useAppSelector(selectActiveOrganizationModel);
    const dispatch = useAppDispatch();
    const userIsInRelevantNewspaper = (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id) === newspaperOrder.data().newspaper.id;
    const onChange = (value) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (!userIsInRelevantNewspaper) {
            return wrapSuccess({ updated: false });
        }
        const { error: updateNewspaperStatusError } = yield newspaperOrder.updateStatus(value);
        if (updateNewspaperStatusError) {
            logAndCaptureException(ColumnService.OBITS, updateNewspaperStatusError, 'Failed to update newspaper order status from order details page', {
                newspaperOrderId: newspaperOrder.id,
                orderId: (_a = newspaperOrder.ref.parent.parent) === null || _a === void 0 ? void 0 : _a.id,
                service: ColumnService.OBITS
            });
            return wrapError(updateNewspaperStatusError);
        }
        return wrapSuccess({ updated: true });
    });
    if (!userIsInRelevantNewspaper) {
        return (_jsx(Badge, Object.assign({ status: getBadgeStatusForNewspaperOrderStatus(newspaperOrder.data().status) }, { children: capitalizeWord(newspaperOrder.data().status) })));
    }
    return (_jsx("div", Object.assign({ className: "w-40" }, { children: _jsx(ColumnSelect, { id: "order-detail-confirmation-status", labelText: "", size: "small", options: editableStatuses.map(status => ({
                label: capitalizeWord(status),
                value: status
            })), onChange: (newValue) => __awaiter(this, void 0, void 0, function* () {
                const { response, error } = yield onChange(newValue);
                if (error) {
                    dispatch(ToastActions.toastError({
                        headerText: 'Error',
                        bodyText: 'There was an error. Please update the confirmation status again.'
                    }));
                }
                if (response === null || response === void 0 ? void 0 : response.updated) {
                    dispatch(ToastActions.toastSuccess({
                        headerText: 'Success',
                        bodyText: 'Confirmation status updated.'
                    }));
                }
            }), value: newspaperOrder.data().status, allowUndefined: true, placeholder: capitalizeWord(newspaperOrder.data().status), disabled: !editableStatuses.includes(newspaperOrder.data().status) }) })));
}
