var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { Product } from 'lib/enums';
import { TableLayout } from 'lib/components/TableLayout';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getFirebaseContext } from 'utils/firebase';
import { NoticeRateService } from 'lib/services/noticeRateService';
import { AdRateService } from 'lib/services/adRateService';
import RateTableFilterDialog from './RateTableFilterDialog';
import GlobalRateSettings from '../globalRateSettings';
import RateUpdateForm from './rateUpdateForm';
import RatesTableRow from './RatesTableRow';
import RateDrawer from './ratesSettingsDrawer';
import { getNumActiveFromFilter, shouldShowRateInTable, DEFAULT_RATE_FILTER, isDefaultRate } from './ratesTableSettingsUtils';
import { usePublisherOrgRates } from '../hooks/usePublisherOrgRates';
/**
 * Wrapper component for the Rates table page.
 */
export default function RatesTableTab({ adType, activeOrganization, getInitialData }) {
    const [showGlobalRateSettings, setShowGlobalRateSettings] = useState(false);
    const [editedRateData, setEditedRateData] = useState();
    const [editedRateRef, setEditedRateRef] = useState();
    const [drawerRate, setDrawerRate] = useState();
    const [filter, setFilter] = useState(DEFAULT_RATE_FILTER);
    const [updatedFilter, setUpdatedFilter] = useState(filter);
    const context = getFirebaseContext();
    const rateService = adType === Product.Notice
        ? new NoticeRateService(context)
        : new AdRateService(context.adRatesRef(), adType);
    const { orgRates, loading: loadingData } = usePublisherOrgRates(rateService, activeOrganization.ref, { product: adType });
    if (showGlobalRateSettings) {
        return (_jsx(GlobalRateSettings, { activeOrganization: activeOrganization, onClose: () => setShowGlobalRateSettings(false) }));
    }
    if (editedRateData) {
        return (_jsx(RateUpdateForm, { activeOrganization: activeOrganization, closeForm: () => {
                setEditedRateData(undefined);
                setEditedRateRef(undefined);
            }, rateData: editedRateData, rateRef: editedRateRef }));
    }
    const rates = orgRates.sort((a, b) => {
        if (isDefaultRate(activeOrganization, a.id) &&
            !isDefaultRate(activeOrganization, b.id)) {
            return -1;
        }
        if (!isDefaultRate(activeOrganization, a.id) &&
            isDefaultRate(activeOrganization, b.id)) {
            return 1;
        }
        return a.data().description.localeCompare(b.data().description);
    });
    return (_jsxs(_Fragment, { children: [_jsx(TableLayout, { filterable: {
                    shouldShowTableItem: (rate, search) => shouldShowRateInTable(rate, search, filter),
                    additionalFilters: {
                        applyFilterChanges: () => setFilter(updatedFilter),
                        filterHasChanges: !columnObjectsAreEqual(filter, updatedFilter),
                        numFiltersActive: getNumActiveFromFilter(filter),
                        resetFilters: () => {
                            setUpdatedFilter(DEFAULT_RATE_FILTER);
                            setFilter(DEFAULT_RATE_FILTER);
                        },
                        renderDialog: () => (_jsx(RateTableFilterDialog, { onUpdate: setUpdatedFilter, value: updatedFilter }))
                    }
                }, creatable: {
                    onCreate: () => __awaiter(this, void 0, void 0, function* () {
                        const initialData = yield getInitialData();
                        setEditedRateData(initialData);
                    }),
                    createButtonText: 'Add Rate'
                }, header: {
                    subtitle: `Configure the cost of ${PRODUCT_TO_NAME[adType].plural.toLocaleLowerCase()} in your paper.`,
                    title: 'Rates'
                }, configurable: adType === Product.Notice
                    ? {
                        buttonText: 'Global Rate Settings',
                        onClick: () => {
                            setDrawerRate(undefined);
                            setShowGlobalRateSettings(true);
                        }
                    }
                    : undefined, archivable: {
                    renderWarning: rateToArchive => ({
                        header: `Archive Rate ${rateToArchive.data().description}`,
                        body: 'Once archived, rates can no longer be recovered in the app. However, the Column team will still be able to recover your settings.'
                    }),
                    onArchive: (rateToArchive) => __awaiter(this, void 0, void 0, function* () { return yield rateToArchive.ref.update({ archived: true }); }),
                    isArchiveDisabled: rate => isDefaultRate(activeOrganization, rate.id),
                    disabledArchiveTooltip: "Can't delete default rates.",
                    enabledArchiveTooltip: 'Archive this rate.'
                }, editable: {
                    editTooltip: 'Edit this rate.',
                    onEdit: rate => {
                        setEditedRateData(rate.data());
                        setEditedRateRef(rate.ref);
                    }
                }, clickable: {
                    onClick: rate => setDrawerRate(rate)
                }, columns: ['Rate Name', 'Rate Type', 'Rate Per Run'], renderRow: rate => (_jsx(RatesTableRow, { activeOrganization: activeOrganization, rate: rate.data(), rateId: rate.id })), data: rates || [], loading: loadingData, id: "rates-settings" }), drawerRate && (_jsx(RateDrawer, { activeOrganization: activeOrganization, setEditedRateData: setEditedRateData, setEditedRateRef: setEditedRateRef, closeDrawer: () => setDrawerRate(undefined), drawerRate: drawerRate }))] }));
}
