import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Listbox } from '@headlessui/react';
import { InputAccessories, getAccessibilityLinks } from '../InputAccessories';
import { ColumnSelectItem } from './ColumnSelectItem';
import { useValidationChecks } from './hooks/useValidationChecks';
/**
 * Converts an enum into the format required by our ColumnSelect component
 * @param enumItem input enum item
 * @returns option for ColumnSelect
 */
export const enumKeyToSelectInput = (enumItem) => ({
    value: enumItem.value.toString(),
    label: enumItem.label,
    description: enumItem.description
});
export function ColumnSelect({ id = '', options, labelText, noteText, errorText, required = false, value, placeholder = 'Select option', onChange, itemsListDescription, inline, size = 'standard', allowUndefined, disabled, validationMessages, allowAutoFocus, checkDependentValidations }) {
    const [showErrors, setShowErrors] = useState(!!errorText);
    const inputRef = React.createRef();
    const selectedValue = options.find(o => o.value === value);
    const selectedOption = allowUndefined
        ? selectedValue
        : selectedValue || options[0];
    const { currentValidationMessage } = useValidationChecks({
        value: (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) || '',
        required,
        inputRef,
        validationMessages,
        setShowErrors,
        checkDependentValidations
    });
    const errorMessage = errorText || currentValidationMessage;
    const wrapperClasses = classNames('relative', {
        'inline-block px-1': inline
    });
    const innerWrapperClasses = classNames('text-sm', {
        'text-column-gray-500': !inline && !disabled
    });
    const accessibilityLinks = getAccessibilityLinks({
        id,
        noteText,
        errorMessage
    });
    useEffect(() => {
        var _a;
        if (allowAutoFocus) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [allowAutoFocus]);
    return (_jsx("div", Object.assign({ id: `${id}-field`, className: wrapperClasses }, { children: _jsx(Listbox, Object.assign({ value: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value, "aria-invalid": true, onChange: onChange, disabled: disabled }, { children: ({ open }) => (_jsx(InputAccessories, Object.assign({ id: id, labelText: labelText, noteText: noteText, errorText: showErrors ? errorMessage : '', disabled: disabled, required: required }, { children: _jsxs("div", Object.assign({ className: innerWrapperClasses }, { children: [_jsx(ColumnSelectButton, { inputRef: inputRef, inline: inline, size: size, accessibilityLinks: accessibilityLinks, buttonText: (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) || placeholder, error: showErrors && !!errorMessage, disabled: disabled, valueSet: !!selectedOption, onBlur: () => setShowErrors(true), allowAutoFocus: allowAutoFocus }), _jsx(ColumnSelectOptions, { open: open, options: options, inline: inline, itemsListDescription: itemsListDescription })] })) }))) })) })));
}
function ColumnSelectButton({ inputRef, accessibilityLinks, inline, size, buttonText, disabled, error, valueSet, onBlur }) {
    return (_jsx(Listbox.Button, Object.assign({ as: React.Fragment }, { children: ({ open }) => (_jsxs("button", Object.assign({ ref: inputRef }, accessibilityLinks, { onBlur: () => {
                if (!open) {
                    onBlur();
                }
            }, className: classNames('flex items-center bg-white w-full rounded-md font-medium focus:outline-none', {
                border: !inline,
                'p-3': !inline && size === 'standard',
                'p-2': !inline && size === 'small',
                'text-primary-500': inline,
                'border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary': !error,
                'border-column-red-600 shadow-outline-column-red': error,
                'bg-column-gray-25 text-column-gray-300 cursor-default': disabled
            }) }, { children: [_jsx("span", Object.assign({ className: classNames('truncate', {
                        'text-column-gray-300': !valueSet
                    }) }, { children: buttonText })), _jsx(ChevronDownIcon, { "aria-hidden": "true", className: classNames('h-4 w-4', {
                        'ml-auto': !inline,
                        'ml-1': inline
                    }) })] }))) })));
}
function ColumnSelectOptions({ open, inline, options, itemsListDescription }) {
    const optionsClasses = classNames('absolute max-h-64 overflow-auto w-full bg-white p-1.5 mt-2 rounded border border-column-gray-100 focus:outline-none z-50 shadow-column-3', {
        'w-screen max-w-40': inline,
        hidden: !open
    });
    return (_jsxs(Listbox.Options, Object.assign({ "aria-hidden": !open, className: optionsClasses, static: true }, { children: [options.length === 0 && (_jsx("div", Object.assign({ className: "text-center py-2 text-column-gray-200" }, { children: "No options" }))), itemsListDescription && (_jsx("li", Object.assign({ role: "presentation", className: "uppercase font-semibold text-xs text-column-gray-300 p-3" }, { children: itemsListDescription }))), options.map((option, index) => (_jsx(Listbox.Option, Object.assign({ value: option.value }, { children: ({ active, selected }) => (_jsx(ColumnSelectItem, { active: active, selected: selected, option: option })) }), `${option.value}-${index}`)))] })));
}
