import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { affidavitUploadURLToFileName } from 'lib/affidavits';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Tooltip } from 'lib/components/Tooltip';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { EEditionUploadMethod } from 'lib/types/eedition';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { getFirebaseContext } from 'utils/firebase';
function getUploadMethodForRow(eeditionQuerySnap) {
    if (!eeditionQuerySnap) {
        return _jsx(LoadingSpinner, {});
    }
    if (eeditionQuerySnap.empty) {
        return '--';
    }
    const allUploadMethods = new Set(eeditionQuerySnap.docs.map(eeditionSnap => eeditionSnap.data().uploadMethod));
    return Array.from(allUploadMethods)
        .map(uploadMethod => uploadMethod === EEditionUploadMethod.FTP
        ? uploadMethod.toUpperCase()
        : capitalize(uploadMethod))
        .join(', ');
}
function getFileNamesForRow(eeditionQuerySnap) {
    if (!eeditionQuerySnap) {
        return _jsx(LoadingSpinner, {});
    }
    if (eeditionQuerySnap.empty) {
        return '--';
    }
    const firstFileName = affidavitUploadURLToFileName(eeditionQuerySnap.docs[0].data().storagePath);
    if (eeditionQuerySnap.size === 1) {
        return firstFileName;
    }
    return `${firstFileName} + ${eeditionQuerySnap.size - 1} more`;
}
export default function AutomatedAffidavitTableRow({ rowData }) {
    const eeditionQuerySnap = useFirestoreQueryListener(getFirebaseContext()
        .eeditionsRef()
        .where('publicationIssue', '==', rowData.publicationIssue.ref), [rowData.publicationIssue.id]);
    const { unverifiedRuns, verifiedRuns } = rowData;
    const rowHasUnverifiedNoticesAndNoEedition = eeditionQuerySnap && eeditionQuerySnap.empty && unverifiedRuns.length > 0;
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsx(Tooltip, Object.assign({ helpText: rowHasUnverifiedNoticesAndNoEedition ? 'Missing e-edition' : '' }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center" }, { children: [rowHasUnverifiedNoticesAndNoEedition && (_jsx(ExclamationTriangleIcon, { className: "text-column-red-400 w-6 h-6 mr-2" })), moment(rowData.publicationIssue.modelData.publicationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')] })) })) }), _jsx("td", { children: getUploadMethodForRow(eeditionQuerySnap) }), _jsx("td", { children: getFileNamesForRow(eeditionQuerySnap) }), _jsx("td", Object.assign({ className: "text-center" }, { children: verifiedRuns.length })), _jsx("td", Object.assign({ className: "text-center" }, { children: unverifiedRuns.length }))] }));
}
