var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const assertConfigValueExists = function (val, key) {
    if (!val) {
        throw new Error(`Functions config key ${key} is missing!`);
    }
};
/**
 * Helper function to get query results where a key is in an array of values that has
 * length >30. This is used to get around the 30-record limit of firebase's 'in' operator
 */
export const getQueryResultsWhereKeyInArray = (query, key, values) => __awaiter(void 0, void 0, void 0, function* () {
    const queryPromises = values.map(value => query
        .where(key, '==', value)
        .get()
        .then(querySnapshot => querySnapshot.docs));
    // If any promise fails, this will throw. Error handling should be implemented in the callers
    const results = yield Promise.all(queryPromises);
    return results.flat();
});
