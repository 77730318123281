var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';
import { getFirebaseContext } from 'utils/firebase';
import { getUserByEmail } from 'utils/users';
import { exists } from 'lib/types';
import { InviteStatus } from 'lib/enums';
import { inviteIsOpen } from 'lib/users';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUserAuth } from 'redux/auth';
import { safeStringify } from 'lib/utils/stringify';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import Register from '../register/user';
import LoadingState from '../../components/LoadingState';
function Invitation({ id }) {
    const userAuth = useAppSelector(selectUserAuth);
    const dispatch = useAppDispatch();
    const ctx = getFirebaseContext();
    const inviteRef = id ? getFirebaseContext().invitesRef().doc(id) : undefined;
    const inviteSnap = useFirestoreSnapshot(inviteRef);
    const [existingUser, setExistingUser] = useState(false);
    useEffect(() => {
        if (exists(inviteSnap)) {
            void (() => __awaiter(this, void 0, void 0, function* () {
                /**
                 * Query for an existing registered (not anonymous)
                 * account associated with the email in the invite
                 */
                const { email } = inviteSnap.data();
                const user = yield getUserByEmail(email);
                setExistingUser(!!(user === null || user === void 0 ? void 0 : user.data().anonymous));
            }))();
        }
    }, [safeStringify(inviteSnap)]);
    useEffect(() => {
        var _a, _b, _c, _d;
        const getUserData = () => __awaiter(this, void 0, void 0, function* () {
            if (!id) {
                return;
            }
            const inviteSnap = yield ctx.invitesRef().doc(id).get();
            if (exists(inviteSnap)) {
                yield inviteSnap.ref.update({
                    status: InviteStatus.snoozed.value
                });
            }
        });
        /**
         * If a user is logged in, redirect to the notice table if:
         *  - the invite has already been accepted
         *  - the invite has not been accepted but the invite user is the one logged in
         */
        if ((((_a = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _a === void 0 ? void 0 : _a.status) === InviteStatus.accepted.value &&
            userAuth) ||
            (inviteIsOpen(inviteSnap) && userAuth && ((_b = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _b === void 0 ? void 0 : _b.user))) {
            if ((_c = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _c === void 0 ? void 0 : _c.user) {
                void getUserData();
            }
            dispatch(push('/notices'));
        }
        else if (((_d = inviteSnap === null || inviteSnap === void 0 ? void 0 : inviteSnap.data()) === null || _d === void 0 ? void 0 : _d.status) === InviteStatus.accepted.value ||
            existingUser) {
            /**
             * If the invite has been accepted or the invite is associated
             * with an existing column user, redirect to the login page
             */
            dispatch(push('/login'));
        }
    }, [userAuth, safeStringify(inviteSnap), safeStringify(existingUser)]);
    if (!id || !exists(inviteSnap))
        return _jsx(LoadingState, {});
    // If we get here, redirect the user to registration as they don't yet exist in Column
    sessionStorage.setItem('inviteId', id);
    return _jsx(Register, { invite: inviteSnap });
}
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        id: (_b = (_a = matchPath(state.router.location.pathname, {
            path: '/invites/:id',
            exact: true,
            strict: false
        })) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id
    });
};
export default connect(mapStateToProps)(Invitation);
