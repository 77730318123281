var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import Firebase from 'EnoticeFirebase';
import { LogosInLiners } from 'lib/constants';
import { cdnIfy, sanitize } from 'lib/helpers';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Modal } from 'lib/components/Modal';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { MimeTypes } from 'lib/types/mime';
function MCEImageUploadModal({ setOpen, onSubmit }) {
    const [file, setFile] = useState();
    const [fileUrl, setFileUrl] = useState();
    const [imageLoaded, setImageLoaded] = useState(false);
    const shouldAllowImageInLinerToAcceptPdf = getBooleanFlag(LaunchDarklyFlags.ALLOW_PDF_TO_IMAGE_IN_LINERS);
    const FILE_TYPES_FOR_IMAGE_IN_LINERS = [MimeTypes.IMG_JPG, MimeTypes.IMG_PNG];
    const FILE_TYPES_FOR_IMAGE_IN_LINERS_WITH_PDF = FILE_TYPES_FOR_IMAGE_IN_LINERS.concat(MimeTypes.APP_PDF);
    const handleFileUpload = (file) => __awaiter(this, void 0, void 0, function* () {
        setImageLoaded(false);
        setFile(file);
        if (!file) {
            setFileUrl(undefined);
            return;
        }
        const snap = yield Firebase.storage()
            .ref()
            .child(`documentcloud/notices/${new Date().getTime()}/${sanitize(file.name)}`)
            .put(file);
        let transformation = 'q_auto:best';
        if (shouldAllowImageInLinerToAcceptPdf && file.type === MimeTypes.APP_PDF) {
            // Transform PDF to JPG
            transformation = transformation.concat(',f_png');
        }
        const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
        const url = cdnIfy(snap.ref.fullPath, {
            cloudinaryTransformations: transformation,
            useColumnCDN
        });
        setFileUrl(url);
    });
    return (_jsx(Modal, Object.assign({ id: "mce-image-upload-modal", onClose: () => setOpen(false), title: "Insert Image", secondaryActions: [
            { buttonText: 'Cancel', onClick: () => setOpen(false), type: 'button' }
        ], primaryAction: {
            buttonText: 'Save',
            onClick: () => {
                if (!fileUrl) {
                    return;
                }
                onSubmit(fileUrl);
                setOpen(false);
            },
            type: 'button',
            disabled: !(fileUrl && imageLoaded)
        }, showCloseButton: false }, { children: _jsxs("div", Object.assign({ className: "my-8" }, { children: [file && fileUrl && (_jsxs("div", Object.assign({ className: "flex flex-col items-center" }, { children: [_jsx("img", { src: fileUrl, className: "max-h-64", onLoad: () => setImageLoaded(true) }), fileUrl && !imageLoaded && _jsx(LoadingSpinner, {}), _jsx("div", Object.assign({ className: "text-xs text-center text-grey-400 mt-4 truncate" }, { children: file === null || file === void 0 ? void 0 : file.name }))] }))), !fileUrl && (_jsx("div", Object.assign({ className: "w-full", style: {
                        display: 'grid',
                        gridTemplateColumns: 'auto',
                        gridTemplateRows: '18rem auto'
                    } }, { children: _jsx(FileDropzone, { id: "mce-image-upload", multiple: false, acceptFileTypes: shouldAllowImageInLinerToAcceptPdf
                            ? FILE_TYPES_FOR_IMAGE_IN_LINERS_WITH_PDF
                            : FILE_TYPES_FOR_IMAGE_IN_LINERS, maxSizeBytes: LogosInLiners.MAX_UPLOAD_SIZE_BYTES, loading: !!file && !fileUrl, onDrop: files => {
                            const droppedFile = files[0];
                            void handleFileUpload(droppedFile);
                        } }) })))] })) })));
}
export default MCEImageUploadModal;
