var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { RoleType } from 'lib/enums';
import { useRolesForOrg } from 'hooks/useRolesForOrg';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { getOrganizationIconColors } from '../modals/JoinOrganizationModals/helpers';
import VerticalDivider from '../VerticalDivider';
function InviteActionCard({ invite, request, onAcceptClick, onDeclineClick, index, className, type, organization, updateUserRole }) {
    const [acceptingInviteLoading, setAcceptingInviteLoading] = useState(false);
    const [decliningInviteLoading, setDecliningInviteLoading] = useState(false);
    const [userRole, setUserRole] = useState('');
    const iconStyles = getOrganizationIconColors(index);
    const { inviteRoles } = useRolesForOrg(organization);
    return (_jsxs("div", Object.assign({ className: `${className} border-column-gray-100 p-5 bg-white space-y-6 shadow-column-2` }, { children: [_jsxs("div", Object.assign({ className: "relative flex items-center space-x-4" }, { children: [_jsx("div", Object.assign({ className: "flex-shrink-0" }, { children: _jsx("div", Object.assign({ className: `h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}` }, { children: _jsx(BuildingOfficeIcon, { className: "w-6 h-6", style: {
                                    margin: 'auto',
                                    transform: 'translateY(35%)'
                                }, stroke: iconStyles.stroke }) })) })), _jsxs("div", Object.assign({ className: "min-w-0 flex-1" }, { children: [type === 'invite' && invite && (_jsx(_Fragment, { children: _jsxs("p", Object.assign({ className: "text-left text-sm font-medium text-column-gray-400" }, { children: ["You have received an invite", invite.userName ? (_jsxs(_Fragment, { children: [_jsx("span", { children: " from " }), _jsx("span", Object.assign({ className: "font-semibold text-column-gray-500" }, { children: invite.userName }))] })) : (''), _jsx("span", { children: " to join " }), _jsx("span", Object.assign({ className: "font-semibold text-column-gray-500" }, { children: invite.userInvite.data().organizationName }))] })) })), type === 'request' && request && (_jsx("div", { children: _jsxs("div", Object.assign({ className: "text-left text-sm font-medium text-column-gray-400" }, { children: ["You've received a request", request.userName ? (_jsxs(_Fragment, { children: [_jsx("span", { children: " from " }), _jsx("span", Object.assign({ className: "font-semibold text-column-gray-500" }, { children: request.userName }))] })) : (''), _jsx("span", { children: " to join " }), _jsx("span", Object.assign({ className: "font-semibold text-column-gray-500" }, { children: request.organizationName })), _jsxs("span", Object.assign({ className: "pl-1" }, { children: ["with the role", _jsx(ColumnSelect, { id: `role-${index}`, labelText: "", inline: true, options: inviteRoles.map(role => {
                                                        return { label: role.label, value: role.label };
                                                    }), onChange: value => {
                                                        setUserRole(RoleType.by_label(value).label);
                                                        updateUserRole && updateUserRole(value);
                                                    }, value: userRole, itemsListDescription: "Choose user's role" })] }))] })) }))] }))] }), `${index}-inviter-avatar`), _jsxs("div", Object.assign({ className: "flex w-full justify-end mt-4" }, { children: [_jsx(ColumnButton, { size: "sm", buttonText: 'Decline', loading: decliningInviteLoading, onClick: () => __awaiter(this, void 0, void 0, function* () {
                            setDecliningInviteLoading(true);
                            yield onDeclineClick(invite);
                            setDecliningInviteLoading(false);
                        }), type: "button" }), _jsx(VerticalDivider, {}, `${index}-vertical-divider`), _jsx(ColumnButton, { size: "sm", secondary: true, buttonText: 'Accept', loading: acceptingInviteLoading, onClick: () => __awaiter(this, void 0, void 0, function* () {
                            setAcceptingInviteLoading(true);
                            yield onAcceptClick(invite);
                            setAcceptingInviteLoading(false);
                        }), type: "button" })] }), `${index}-invite-actions`)] }), `${index}-invites-row`));
}
export default InviteActionCard;
