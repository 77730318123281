import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PlacementActions, { selectDefaultInvoiceRecipient } from 'redux/placement';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { TextField } from 'lib/components/TextField';
import classNames from 'classnames';
import { safeStringify } from 'lib/utils/stringify';
import { getDisplayName } from 'lib/helpers';
import SectionDivider from './SectionDivider';
import { RecipientInfoForm, EmailOrMailToggle, EmailOrMailForm, RecipientToggle } from './InvoiceRecipientFormComponents';
export const useInvoiceRecipientFormState = () => {
    var _a, _b, _c, _d, _e, _f;
    const dispatch = useAppDispatch();
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const invoiceRecipient = useAppSelector(state => state.placement.invoiceRecipient);
    const defaultInvoiceRecipient = useAppSelector(selectDefaultInvoiceRecipient);
    const invoiceRecipientEmail = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'email' ? invoiceRecipient : undefined;
    const invoiceRecipientMail = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'mail' ? invoiceRecipient : undefined;
    const defaultAddress = invoiceRecipientMail === null || invoiceRecipientMail === void 0 ? void 0 : invoiceRecipientMail.address;
    const [sendToSomeoneElse, setSendToSomeoneElse] = useState(!!invoiceRecipient);
    const [sendToEmail, setSendToEmail] = useState(!((invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'mail'));
    const [splitFirstName, splitLastName] = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.name)
        ? invoiceRecipient.name.split(' ')
        : ['', ''];
    const [firstName, setFirstName] = useState((invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.firstName) || splitFirstName || '');
    const [lastName, setLastName] = useState((invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.lastName) || splitLastName || '');
    const [billTo, setBillTo] = useState('');
    const [organizationName, setOrganizationName] = useState((_a = invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.organizationName) !== null && _a !== void 0 ? _a : '');
    const [email, setEmail] = useState((invoiceRecipientEmail === null || invoiceRecipientEmail === void 0 ? void 0 : invoiceRecipientEmail.email) || (defaultInvoiceRecipient === null || defaultInvoiceRecipient === void 0 ? void 0 : defaultInvoiceRecipient.email) || '');
    const [addressLine1, setAddressLine1] = useState((_b = defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_line1) !== null && _b !== void 0 ? _b : '');
    const [addressLine2, setAddressLine2] = useState((_c = defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_line2) !== null && _c !== void 0 ? _c : '');
    const [city, setCity] = useState((_d = defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_city) !== null && _d !== void 0 ? _d : '');
    const [state, setState] = useState(typeof (defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_state) === 'string'
        ? defaultAddress === null || defaultAddress === void 0 ? void 0 : defaultAddress.address_state
        : '');
    const [zip, setZip] = useState((_f = (_e = invoiceRecipientMail === null || invoiceRecipientMail === void 0 ? void 0 : invoiceRecipientMail.address) === null || _e === void 0 ? void 0 : _e.address_zip) !== null && _f !== void 0 ? _f : '');
    useEffect(() => {
        if (!defaultInvoiceRecipient)
            return;
        setEmail((invoiceRecipientEmail === null || invoiceRecipientEmail === void 0 ? void 0 : invoiceRecipientEmail.email) || defaultInvoiceRecipient.email || '');
        if (enableNewPlacementFlow) {
            const deprecatedInvoiceRecipientName = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.organizationName) ||
                getDisplayName(invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.firstName, invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.lastName);
            setBillTo(deprecatedInvoiceRecipientName ||
                (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.name) ||
                defaultInvoiceRecipient.name ||
                '');
        }
        // Don't default fill address information if invoiceRecipient is set to email
        if (!!invoiceRecipient && invoiceRecipient.type === 'email')
            return;
        // Pre-fill address fields either from the set invoice recipient
        // or from the default
        const entityToPopulateAddress = (invoiceRecipientMail === null || invoiceRecipientMail === void 0 ? void 0 : invoiceRecipientMail.address.address_line1)
            ? invoiceRecipientMail.address
            : defaultInvoiceRecipient.mailingAddress;
        const { address_line1, address_line2, address_city, address_state, // This is for some reason saved as Label, not Value
        address_zip } = entityToPopulateAddress;
        if (address_line1) {
            setAddressLine1(address_line1);
        }
        if (address_line2) {
            setAddressLine2(address_line2);
        }
        if (address_city) {
            setCity(address_city);
        }
        if (address_state) {
            setState(String(address_state) || '');
        }
        if (address_zip) {
            setZip(address_zip);
        }
    }, [safeStringify(invoiceRecipient), safeStringify(defaultInvoiceRecipient)]);
    const shouldSetInvoiceRecipientOnNotice = 
    // Should always be set when sendToSomeoneElse is explicitly checked (old flow only)
    sendToSomeoneElse ||
        // If the email has been updated from the default
        (sendToEmail && (defaultInvoiceRecipient === null || defaultInvoiceRecipient === void 0 ? void 0 : defaultInvoiceRecipient.email) !== email) ||
        // If the billing name has been updated from the default
        (sendToEmail && (defaultInvoiceRecipient === null || defaultInvoiceRecipient === void 0 ? void 0 : defaultInvoiceRecipient.name) !== billTo) ||
        // Should always be set when sending a paper copy
        !sendToEmail;
    const sendToMailingAddress = !sendToEmail;
    const recipientInfoValid = enableNewPlacementFlow
        ? !!billTo
        : [firstName, lastName].every(e => !!e);
    // TextField has native validation for email/zip
    // but I'm not sure if it's possible to pull that validation into these checks
    const emailRe = /.+?@.+?\..+/;
    const recipientEmailValid = !sendToEmail || (!!email && emailRe.test(email));
    const zipRe = /^[0-9]{5}$/;
    const zipValid = zipRe.test(zip.trim());
    const recipientMailingValid = !sendToMailingAddress ||
        ([addressLine1, city, state, zip].every(e => !!e) && zipValid);
    const isStepComplete = (!shouldSetInvoiceRecipientOnNotice &&
        /**
         * The old placement flow doesn't always show the recipient info
         * fields - for new placement flow, this field is always shown + required
         * regardless of whether an invoice recipient is actually set
         */
        (enableNewPlacementFlow ? recipientInfoValid : true)) ||
        // All form fields have valid inputs
        (recipientInfoValid && recipientEmailValid && recipientMailingValid);
    const onExit = () => {
        if (!shouldSetInvoiceRecipientOnNotice) {
            const data = {
                invoiceRecipient: null
            };
            dispatch(PlacementActions.confirmInvoiceRecipient(data));
            return;
        }
        // We are deprecating all fields in favor of `name`
        const basicInfo = enableNewPlacementFlow
            ? { name: billTo }
            : {
                name: organizationName || getDisplayName(firstName, lastName)
            };
        if (sendToEmail) {
            const invoiceRecipient = Object.assign({ type: 'email', email }, basicInfo);
            const data = {
                invoiceRecipient
            };
            dispatch(PlacementActions.confirmInvoiceRecipient(data));
        }
        else {
            const invoiceRecipient = Object.assign({ type: 'mail', address: {
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    address_city: city,
                    address_state: state,
                    address_zip: zip
                } }, basicInfo);
            const data = {
                invoiceRecipient
            };
            dispatch(PlacementActions.confirmInvoiceRecipient(data));
        }
    };
    return {
        isStepComplete,
        onExit,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        organizationName,
        setOrganizationName,
        email,
        setEmail,
        addressLine1,
        setAddressLine1,
        addressLine2,
        setAddressLine2,
        city,
        setCity,
        state,
        setState,
        zip,
        setZip,
        sendToEmail,
        setSendToEmail,
        sendToSomeoneElse,
        setSendToSomeoneElse,
        zipValid,
        recipientEmailValid,
        billTo,
        setBillTo
    };
};
export function InvoiceRecipientForm({ isPublisher, firstName, setFirstName, lastName, setLastName, organizationName, setOrganizationName, email, setEmail, addressLine1, setAddressLine1, addressLine2, setAddressLine2, city, setCity, state, setState, zip, setZip, sendToEmail, setSendToEmail, sendToSomeoneElse, setSendToSomeoneElse, billTo, setBillTo }) {
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    // Only advertisers in the old flow should initially see the recipient toggle
    const showRecipientToggle = !enableNewPlacementFlow && !isPublisher;
    // Everyone else should immediately see the email/mail toggle
    const defaultShowEmailOrMailForm = enableNewPlacementFlow || isPublisher;
    return (_jsxs(_Fragment, { children: [enableNewPlacementFlow && (_jsx("div", Object.assign({ className: "uppercase" }, { children: _jsx(SectionDivider, { text: 'Invoice Information' }) }))), _jsx("div", Object.assign({ className: classNames({
                    'bg-gray-100 border rounded-md p-6': enableNewPlacementFlow
                }) }, { children: _jsxs("form", { children: [enableNewPlacementFlow && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ id: "invoice-recipient-subheader", className: "text-sm text-grey-400 font-medium mb-6" }, { children: isPublisher
                                        ? 'Edit the billing details if someone other than the customer should pay for this notice.'
                                        : 'Invoices are sent by e-mail to the customer and organization members who have billing notifications enabled. Complete the fields below if you wish to override this and send the invoice and invoice reminders only to a recipient specified below. Invoices are accessible by the customer and organization members in their Column account. You are still responsible for ensuring the invoices gets paid.' })), _jsxs("div", Object.assign({ className: "space-y-6" }, { children: [true && (_jsx(TextField, { id: "bill-to", labelText: "Bill to", required: true, onChange: billTo => setBillTo(billTo), value: billTo })), _jsx("div", Object.assign({ className: "mt-3 pb-2 text-sm text-column-gray-500 font-medium" }, { children: "How would you like to send this invoice?" }))] }))] })), showRecipientToggle && (_jsx(RecipientToggle, { sendToSomeoneElse: sendToSomeoneElse, setSendToSomeoneElse: setSendToSomeoneElse })), ((showRecipientToggle && sendToSomeoneElse) ||
                            defaultShowEmailOrMailForm) && (_jsxs(_Fragment, { children: [!enableNewPlacementFlow && !isPublisher && (_jsxs("div", Object.assign({ className: "mt-4 font-normal text-sm text-gray-700 uppercase" }, { children: ["Recipient Info", _jsx(RecipientInfoForm, { firstName: firstName, setFirstName: setFirstName, lastName: lastName, setLastName: setLastName, organizationName: organizationName, setOrganizationName: setOrganizationName })] }))), _jsx(EmailOrMailToggle, { sendToEmail: sendToEmail, setSendToEmail: setSendToEmail }), _jsx(EmailOrMailForm, { email: email, setEmail: setEmail, sendToEmail: sendToEmail, zip: zip, setZip: setZip, addressLine1: addressLine1, setAddressLine1: setAddressLine1, addressLine2: addressLine2, setAddressLine2: setAddressLine2, city: city, setCity: setCity, state: state, setState: setState, firstName: firstName, setFirstName: setFirstName, lastName: lastName, setLastName: setLastName, organizationName: organizationName, setOrganizationName: setOrganizationName })] }))] }) }))] }));
}
