import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SuccessModal } from 'lib/components/SuccessModal';
export function CancelNoticeSuccessModal({ setCancelSuccessModalConfig, cancelSuccessModalConfig }) {
    return (_jsx(SuccessModal, { setOpen: setCancelSuccessModalConfig, header: cancelSuccessModalConfig.header, body: cancelSuccessModalConfig.body, buttonText: 'Okay, got it', gif: _jsx(SailboatGif, {}) }));
}
function SailboatGif() {
    return (_jsx("img", { src: "https://enotice-production.imgix.net/custom-documents/permalink/11e6.50832-giphy-9-.gif", alt: "Two cats in a sailboat made out of a newspaper", className: "bg-red-200", style: {
            clipPath: 'circle()',
            width: '104px',
            height: '104px'
        } }));
}
