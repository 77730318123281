var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Firebase from 'EnoticeFirebase';
import moment from 'moment';
export const DEFAULT_EDITOR_OPTIONS = {
    wordWrap: 'on',
    minimap: {
        enabled: false
    },
    language: 'html'
};
/**
 * Loads the affidavit HTML from a source URL.
 */
export const loadAffidavitTemplateHTML = (url) => __awaiter(void 0, void 0, void 0, function* () {
    const affidavitTemplateHTMLResponse = yield fetch(url);
    if (affidavitTemplateHTMLResponse.ok) {
        return affidavitTemplateHTMLResponse.text();
    }
    throw new Error(`Failed to load affidavit template HTML from ${url}`);
});
export const htmlToStoragePath = (activeOrganization, affidavitHTML, type) => __awaiter(void 0, void 0, void 0, function* () {
    // upload the new custom affidavit
    const uploadPath = `direct-ftp-upload/${activeOrganization.id}/${type}_affidavits/${moment().format()}_affidavit.html`;
    yield Firebase.storage()
        .ref()
        .child(uploadPath)
        .put(new Blob([affidavitHTML], { type: 'text/html' }));
    return uploadPath;
});
