import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../directory';
export const NOTE_CREATION_FAILED = 'NOTE_CREATION_FAILED';
export const NOTE_ATTACHMENT_UPLOAD_FAILED = 'NOTE_ATTACHMENT_UPLOAD_FAILED';
const noteServiceErrorCodeMap = {
    [NOTE_CREATION_FAILED]: {
        message: 'Failed to create note'
    },
    [NOTE_ATTACHMENT_UPLOAD_FAILED]: {
        message: 'Failed to upload note attachment'
    }
};
export class NoteServiceError extends Error {
    constructor(error, errorCode) {
        const { message } = noteServiceErrorCodeMap[errorCode];
        super(message);
        this.originalError = error;
        this.errorCode = errorCode;
    }
    static getError(error) {
        if (error instanceof NoteServiceError) {
            return error;
        }
        return new NoteServiceError(error, NOTE_CREATION_FAILED);
    }
    captureError(tags) {
        getErrorReporter().logAndCaptureError(ColumnService.GENERAL_INFRASTRUCTURE, this.originalError, this.message, tags);
    }
}
