var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { logAndCaptureMessage } from 'utils';
import api from 'api';
import { Product, RateType } from 'lib/enums';
import RatesTable from './ratesTable';
const product = Product.Notice;
function NoticeRatesTable({ activeOrganization }) {
    return (_jsx(RatesTable, { adType: product, activeOrganization: activeOrganization, getInitialData: () => __awaiter(this, void 0, void 0, function* () {
            const result = yield api.get('rates/generate-code');
            if (result.success) {
                return {
                    product,
                    enotice_fee_pct: null,
                    organization: activeOrganization.ref,
                    description: `New Rate ${new Date().toLocaleDateString()}`,
                    isGovernment: false,
                    rateType: RateType.column_inch.value,
                    archived: false,
                    minimum: 100,
                    roundOff: 0,
                    rate_0: 500,
                    rate_1: 500,
                    rate_2: 500,
                    code: result.code
                };
            }
            logAndCaptureMessage(result.error);
            return undefined;
        }) }));
}
export default NoticeRatesTable;
