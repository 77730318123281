var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Firebase from 'EnoticeFirebase';
const fetchString = (downloadUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield fetch(downloadUrl);
    return res.text();
});
/**
 * Load the handlebars template and questions JSON from Google Cloud Storage.
 * @param path the path to the config FOLDER containing the files.
 */
export const fetchMadlibsConfig = (path) => __awaiter(void 0, void 0, void 0, function* () {
    const folderRef = Firebase.storage().ref('config').child(path);
    const templateRef = folderRef.child('template.handlebars');
    const questionsRef = folderRef.child('questions.json');
    const [templateDownloadUrl, questionDownloadUrl] = yield Promise.all([
        templateRef.getDownloadURL(),
        questionsRef.getDownloadURL()
    ]);
    const [template, questionStr] = yield Promise.all([
        fetchString(templateDownloadUrl),
        fetchString(questionDownloadUrl)
    ]);
    const questionsFile = JSON.parse(questionStr);
    const { questions } = questionsFile;
    return {
        template,
        questions
    };
});
