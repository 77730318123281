/**
 * ONLY EXPORTED FOR TESTING.
 */
export const logInternal = (logFn, message, data, ...args) => {
    if (!data) {
        logFn(message);
        return;
    }
    // This uses a simplified version of Heroku's key-value logging convention (logfmt)
    // https://www.npmjs.com/package/logfmt
    //
    // By putting this in the message as well we make the logs really easy to read while
    // browsing DataDog. The data is also passed as a JSON payload so it's available for
    // faceting/filtering without needing to parse the logfmt though.
    const dataString = Object.entries(data)
        .map(([key, val]) => {
        return `${key}=${JSON.stringify(val)}`;
    })
        .join(' ');
    const messageWithDataString = `${message} ${dataString}`;
    logFn(messageWithDataString, data, ...args);
};
