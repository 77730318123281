import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { push } from 'connected-react-router';
import React from 'react';
import { includeSearchParams } from 'utils/urls';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useAppDispatch } from 'redux/hooks';
export default function RegisterUserFooter() {
    const dispatch = useAppDispatch();
    return (_jsxs("div", Object.assign({ className: "mb-24" }, { children: [_jsx("div", Object.assign({ className: "mt-6" }, { children: _jsxs("div", Object.assign({ className: "relative flex justify-center text-base leading-5" }, { children: [_jsx("hr", { className: "flex-1 text-column-gray-400 mt-3 px-10" }), _jsx("p", Object.assign({ className: "flex-auto text-center text-column-gray-500 text-base" }, { children: "Already have an account?" })), _jsx("hr", { className: "flex-1 text-column-gray-400 mt-3 px-10  " })] })) })), _jsx("div", Object.assign({ className: "mt-6" }, { children: _jsx(ColumnButton, { tertiary: true, onClick: () => dispatch(push(includeSearchParams('/login/'))), buttonText: "Back to Sign In", id: "back-to-signin", fullWidth: true, size: "lg", type: "button" }) }))] })));
}
