import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { exists } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { usePublisherOrgRates } from 'routes/settings/publisher/rates/hooks/usePublisherOrgRates';
import { getFirebaseContext } from 'utils/firebase';
import { NoticeRateService } from 'lib/services/noticeRateService';
function basicValueToRowContent({ type, value }) {
    switch (type) {
        case 'boolean': {
            return value ? (_jsx(CheckCircleIcon, { className: "w-6 h-6 text-column-green-400" })) : (_jsx(XCircleIcon, { className: "w-6 h-6 text-column-red-400" }));
        }
        case 'number-array':
        case 'string-array': {
            return (_jsx("div", Object.assign({ className: "flex gap-2" }, { children: value.map(val => (_jsx(Badge, { children: val }, val))) })));
        }
        default: {
            return value.toString();
        }
    }
}
const noticeTypeOrRateValueToRowContent = (cacheValue, eligibleOptions = []) => {
    const relevantNoticeType = eligibleOptions.find(option => option.value === cacheValue.value);
    if (relevantNoticeType) {
        return `${relevantNoticeType.label} (${cacheValue.value})`;
    }
    return cacheValue.value.toString();
};
function LoadingRow() {
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsx(LoadingSpinner, {}) }), _jsx("td", { children: _jsx(LoadingSpinner, {}) })] }));
}
function CacheEntryTableRowInner({ activeOrganization, entry }) {
    const context = getFirebaseContext();
    const rateService = new NoticeRateService(context);
    const rates = usePublisherOrgRates(rateService, activeOrganization.ref);
    const { key, value } = entry.data();
    if (key.type === 'rate' && rates.loading) {
        return _jsx(LoadingRow, {});
    }
    const keyNode = key.type === 'notice-type' || key.type === 'rate'
        ? noticeTypeOrRateValueToRowContent(key, key.type === 'notice-type'
            ? activeOrganization.data().allowedNotices
            : rates.orgRates.map(rate => ({
                value: rate.data().code,
                label: rate.data().description
            })))
        : basicValueToRowContent(key);
    const valueNode = basicValueToRowContent(value);
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsx("span", { children: keyNode }) }), _jsx("td", { children: _jsx("span", { children: valueNode }) })] }));
}
/**
 * A single row in a cache table.
 */
export function CacheEntryTableRow({ entry }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    if (!exists(activeOrganization) || !exists(entry)) {
        return _jsx(LoadingRow, {});
    }
    return (_jsx(CacheEntryTableRowInner, { activeOrganization: activeOrganization, entry: entry }));
}
