import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { InputAccessories } from '../InputAccessories';
import { useValidationChecks } from '../TextField/hooks/useValidationChecks';
export function TextAreaField({ id, value, placeholder, onChange, onFocus, onBlur, maxLength, required, disabled, rows, labelText, errorText, noteText, disableResizing, inline }) {
    const [showErrors, setShowErrors] = useState(!!errorText);
    const [focus, setFocus] = useState(false);
    const inputRef = useRef(null);
    const { currentValidationMessage } = useValidationChecks({
        value,
        inputRef,
        errorText,
        setShowErrors
    });
    const errorMessage = errorText || currentValidationMessage;
    const inErrorState = showErrors && errorMessage;
    const wrapperClasses = classNames('flex text-sm', {
        'rounded-md border p-1': !inline,
        'border-column-gray-200 ': !focus && !inErrorState,
        'border-column-primary-500 shadow-outline-column-primary': focus && !inErrorState && !inline,
        'border-column-red-600 shadow-outline-column-red': inErrorState && !inline,
        'bg-column-gray-25 text-column-gray-300': disabled,
        'bg-white': !disabled
    });
    const conditionalClasses = classNames('focus:outline-none w-full', {
        'resize-none': disableResizing
    });
    function handleOnChange(e) {
        onChange && onChange(e.target.value);
    }
    function handleOnFocus(event) {
        setFocus(true);
        if (onFocus) {
            onFocus(event);
        }
    }
    function handleOnBlur(event) {
        onBlur && onBlur(event);
        setFocus(false);
        setShowErrors(true);
    }
    return (_jsx(InputAccessories, Object.assign({ id: id, labelText: labelText, errorText: showErrors ? errorMessage : '', noteText: noteText, required: required, disabled: disabled }, { children: _jsx("div", Object.assign({ className: wrapperClasses }, { children: _jsx("textarea", { id: id, ref: inputRef, value: value, onChange: handleOnChange, onFocus: handleOnFocus, onBlur: handleOnBlur, placeholder: placeholder, maxLength: maxLength, required: required, disabled: disabled, rows: rows, className: conditionalClasses }) })) })));
}
