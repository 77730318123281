import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
/**
 * Note: our react-router setup is a bit strange so we can't use the normal <Link> tag.
 * This custom component fills in for the most basic use cases.
 */
function LinkTo({ id, href, className, push, children }) {
    return (_jsx("a", Object.assign({ id: id, className: className, href: href, onClick: e => {
            // When ctrlKey is pressed the user is trying to open the link a new
            // tab, so we let the default happen.
            //
            // When ctrlKey is NOT pressed this should avoid the browser default
            // behavior and do a router action.
            if (!(e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                push(href);
            }
        } }, { children: children })));
}
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path))
});
export default connect(null, mapDispatchToProps)(LinkTo);
