export var IntegrationFeature;
(function (IntegrationFeature) {
    /** Can we validate a customer account number? */
    IntegrationFeature["CUSTOMERS_VALIDATE_ACCOUNT_NUMBER"] = "customers.validate_account_number";
    /** Can we find a customer in the external system by their contact info? */
    IntegrationFeature["CUSTOMERS_FIND_BY_INFO"] = "customers.find_by_info";
})(IntegrationFeature || (IntegrationFeature = {}));
const SUPPORTED_FEATURES = {
    SCNG: [IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER],
    BANG: [IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER],
    GANNETT: [
        IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER,
        IntegrationFeature.CUSTOMERS_FIND_BY_INFO
    ],
    LEE_ADPOINT: [
        IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER,
        IntegrationFeature.CUSTOMERS_FIND_BY_INFO
    ],
    CHAMPION: [IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER]
};
export const integrationHasFeature = (format, feature) => {
    if (!format) {
        return false;
    }
    const features = SUPPORTED_FEATURES[format] || [];
    return features.includes(feature);
};
export const integrationHasAnyFeature = (format, features) => {
    return features.some(f => integrationHasFeature(format, f));
};
