import { InvoiceStatus, NoticeStatusType } from 'lib/enums';
import { exists } from 'lib/types';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
const getInvoiceCannotUpdatePaymentStatus = (invoice, notice) => {
    var _a;
    const hasInvoicePdf = !!((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.invoice_pdf);
    const invoiceCannotUpdatePaymentStatus = !hasInvoicePdf ||
        notice.data().noticeStatus === NoticeStatusType.cancelled.value ||
        invoice.data().invoiceOutsideColumn ||
        invoice.data().void ||
        hasPaymentOrPartialRefund(invoice);
    return invoiceCannotUpdatePaymentStatus;
};
export const getInvoiceCanBePaidInColumn = (invoice, notice) => {
    if (exists(invoice)) {
        const invoiceCannotUpdatePaymentStatus = getInvoiceCannotUpdatePaymentStatus(invoice, notice);
        return ((invoice === null || invoice === void 0 ? void 0 : invoice.data().status) !== InvoiceStatus.unpayable.value &&
            !invoiceCannotUpdatePaymentStatus);
    }
    return false;
};
export const getInvoiceCanBeMarkedAsPaid = (invoice, notice) => {
    if (exists(invoice)) {
        const invoiceCannotUpdatePaymentStatus = getInvoiceCannotUpdatePaymentStatus(invoice, notice);
        return !invoiceCannotUpdatePaymentStatus;
    }
    return false;
};
