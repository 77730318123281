import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ColumnButton } from 'lib/components/ColumnButton';
import React, { useEffect } from 'react';
function CreateInvitesResult({ emailsLinkedToNotices, onUpdateInviteModalHeaderText, onHandleTryAgainClick, invitesData }) {
    const haveInvitesToCurrentOrg = invitesData.alreadyHaveInvitesToCurrentOrg.length;
    const usersLinkedToIncompatibleOrg = invitesData.alreadyLinkedToIncompatibleOrganization.length;
    const usersInvitedToIncompatibleOrg = invitesData.alreadyInvitedToIncompatibleOrganization.length;
    const usersMembersOfCurrentOrg = invitesData.alreadyMembersOfInvitedOrg.length;
    const invitationFailed = haveInvitesToCurrentOrg ||
        emailsLinkedToNotices ||
        usersLinkedToIncompatibleOrg ||
        usersMembersOfCurrentOrg ||
        usersInvitedToIncompatibleOrg;
    useEffect(() => {
        if (onUpdateInviteModalHeaderText && invitationFailed) {
            onUpdateInviteModalHeaderText('Oops! The invitation was not sent.');
        }
    }, []);
    function renderEmailsRow(emails) {
        return emails.map((email, i) => (_jsxs("div", { children: [_jsx("text", { children: `\u2022` }), _jsx("text", Object.assign({ className: "pl-5" }, { children: email }))] }, i)));
    }
    /**
     * RESULT MODAL ORDER:
     * 1. User is already in an incompatible org
     * 2. User has already been invited to an incompatible org
     * 3. User has already placed notices and is not an individual
     * 4. User already belongs to this org
     * 5. User has already been invited to this org
     */
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "font-regular text-base text-column-gray-400 mb-8" }, { children: _jsx(_Fragment, { children: usersLinkedToIncompatibleOrg ? (_jsxs(_Fragment, { children: [`It looks like the following users already belong to an
              organization on Column:`, ' ', _jsx("br", {}), _jsx("br", {}), renderEmailsRow(invitesData.alreadyLinkedToIncompatibleOrganization), _jsx("br", {}), "Please try inviting a different user or contact help@column.us for assistance."] })) : usersInvitedToIncompatibleOrg ? (`${invitesData.alreadyInvitedToIncompatibleOrganization.join(', ')} ${usersInvitedToIncompatibleOrg > 1 ? 'have' : 'has'} already been invited to another organization.`) : emailsLinkedToNotices ? (_jsx(_Fragment, { children: `It looks like the user(s) you selected have already placed notices
              on Column. If you would like to add them to your organization,
              please contact help@column.us for assistance.` })) : usersMembersOfCurrentOrg ? (`${invitesData.alreadyMembersOfInvitedOrg.join(', ')} already ${usersMembersOfCurrentOrg > 1 ? 'belong' : 'belongs'} to this organization.`) : (`${invitesData.alreadyHaveInvitesToCurrentOrg.join(', ')} ${haveInvitesToCurrentOrg > 1 ? 'have' : 'has'} already been invited.`) }) })), _jsx(_Fragment, { children: invitationFailed ? (_jsx(ColumnButton, { secondary: true, type: "button", buttonText: "Try Again", onClick: onHandleTryAgainClick })) : (_jsx(_Fragment, {})) })] }));
}
export default CreateInvitesResult;
