var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { IndesignRoute } from '../constants';
import { exists } from '../types';
import { addFooterXML, generateFormattedFooter } from '../headers_footers/footers';
import { htmlToIndesignHtml, shouldPreserveLongSequencesForNotice, getBoldWords } from './helpers';
import { getAdTemplateCacheID } from '../types/templates';
import { createDBPricingFromNotice, invoiceToDBPricingObject } from '../pricing';
import { getOrThrow } from '../utils/refs';
import { getLaunchDarklyContext } from '../utils/flags';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
// v2 Indesign Service
export const LINER_QUEUE_NAME = 'indesign';
export const PAGINATE_QUEUE_NAME = 'paginate';
export const InDesignRoutes = [
    'liner_ad_image',
    'display_ad_image',
    'template_specs',
    'display_ad_marginalia',
    'paginate',
    'paginate_rtf'
];
export const isInDesignRoute = (route) => {
    return InDesignRoutes.includes(route);
};
const requestDocument = (ctx, indesignClient, notice, options, DOMparser, idRequestOptionOverrides = {}) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    if (!exists(notice)) {
        throw new Error(`Notice not found`);
    }
    const { formats, type, optimizeColumns, resizeTextFramesForProofPDF } = options;
    const adTemplate = yield getOrThrow(notice.data().adTemplate);
    const newspaperSnap = yield getOrThrow(notice.data().newspaper);
    const invoiceSnap = yield ((_a = notice.data().invoice) === null || _a === void 0 ? void 0 : _a.get());
    const rateSnap = yield ((_b = notice.data().rate) === null || _b === void 0 ? void 0 : _b.get());
    let pricing;
    /**
     * If the notice already has an invoice, then we should pull
     * pricing information from the invoice so that it is consistent in all places
     *
     * If there is no invoice yet, then we pull the pricing information from the notice
     */
    if (exists(invoiceSnap)) {
        pricing = invoiceToDBPricingObject({
            invoiceSnap,
            rateSnap,
            newspaperSnap
        });
    }
    else {
        pricing = yield createDBPricingFromNotice(ctx, notice);
    }
    const generatedFooter = yield generateFormattedFooter(ctx, notice.data(), pricing, DOMparser);
    const { confirmedHtml, columns, dynamicHeaders, publicationDates, headerText } = notice.data();
    const { isFirstPHeading, downloadUrl, icmlSubstitutions } = adTemplate.data();
    const { linerMaxColumns } = newspaperSnap.data();
    const preserveLongSequences = yield shouldPreserveLongSequencesForNotice(notice);
    const dynamicFooter = generatedFooter || notice.data().dynamicFooter;
    const xmlFooter = dynamicFooter && dynamicFooter.length > 0
        ? addFooterXML(dynamicFooter)
        : '';
    if (xmlFooter && notice.data().dynamicFooter !== xmlFooter) {
        yield notice.ref.update({ dynamicFooter: xmlFooter });
    }
    const customHeadersEnabled = !!headerText && !!((_c = newspaperSnap.data().noticeHeaders) === null || _c === void 0 ? void 0 : _c.enabled);
    const adjustTableWidths = yield getLaunchDarklyContext().getBooleanFeatureFlag(LaunchDarklyFlags.ADJUST_TABLE_WIDTHS_IN_BULK_DOWNLOAD_FILE, {
        type: 'organization',
        snapshot: newspaperSnap,
        defaultValue: false
    });
    const request = Object.assign(Object.assign({ id: getAdTemplateCacheID(adTemplate), noticeId: notice.id, downloadUrl, html: htmlToIndesignHtml(confirmedHtml !== null && confirmedHtml !== void 0 ? confirmedHtml : '', DOMparser, {
            isFirstPHeading: !!isFirstPHeading,
            preserveLongSequences,
            adjustTableWidths
        }, {
            dates: publicationDates
        }, headerText) + xmlFooter, formats, quality: 'high', columns: columns ? columns + (optimizeColumns ? 1 : 0) : 1, dynamicHeader: (dynamicHeaders === null || dynamicHeaders === void 0 ? void 0 : dynamicHeaders[0]) || null, linerBorder: adTemplate.data().linerBorder || newspaperSnap.data().linerBorder, icmlSubstitutions,
        linerMaxColumns, optimizeColumns: !!optimizeColumns, resizeTextFramesForProofPDF: !!resizeTextFramesForProofPDF, outputToBase64: type === 'DISPLAY_PARAMETERS' }, (customHeadersEnabled ? { customHeader: true } : {})), idRequestOptionOverrides);
    const renderRequestOptions = type === 'RAW'
        ? {
            responseType: 'arraybuffer'
        }
        : {};
    const res = yield indesignClient.call(IndesignRoute.liner_ad_image, {
        request,
        options: renderRequestOptions
    });
    const { data } = res;
    if (type === 'RAW') {
        return { data };
    }
    const boldWords = confirmedHtml ? getBoldWords(confirmedHtml, DOMparser) : 0;
    return Object.assign(Object.assign({}, data), { boldWords });
});
export const requestRawDocument = (ctx, client, notice, DOMparser, formats, idRequestOptionOverrides = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        formats,
        type: 'RAW'
    };
    const res = yield requestDocument(ctx, client, notice, options, DOMparser, idRequestOptionOverrides);
    return res;
});
export const DEFAULT_DISPLAY_PARAMETERS_OPTIONS = {
    optimizeColumns: false,
    resizeTextFramesForProofPDF: true
};
export const requestDisplayParameters = (ctx, client, notice, domParser, options = DEFAULT_DISPLAY_PARAMETERS_OPTIONS, idRequestOptionOverrides = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const requestOptions = {
        formats: ['jpg'],
        type: 'DISPLAY_PARAMETERS',
        optimizeColumns: options.optimizeColumns,
        resizeTextFramesForProofPDF: options.resizeTextFramesForProofPDF
    };
    const res = yield requestDocument(ctx, client, notice, requestOptions, domParser, idRequestOptionOverrides);
    return res;
});
