var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { getFirebaseContext } from './firebase';
export const getRateSnapshotFromFilingTypeLabel = (newspaperSnap, product, publishingMedium, filingTypeLabel) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(newspaperSnap)) {
        return wrapError(new NotFoundError('Newspaper not found'));
    }
    const newspaper = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), newspaperSnap);
    const { response: filingTypes, error: filingTypesError } = yield newspaper.fetchFilingTypesForProductMedium({
        product,
        publishingMedium
    });
    if (filingTypesError) {
        return wrapError(filingTypesError);
    }
    const filingType = filingTypes.find(type => type.modelData.label === filingTypeLabel);
    if (!filingType) {
        return wrapError(new NotFoundError(`Filing type ${filingTypeLabel} not found`));
    }
    const { response: rate, error: rateError } = yield filingType.getRate();
    if (rateError) {
        return wrapError(rateError);
    }
    return wrapSuccess(rate.data());
});
