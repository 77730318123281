var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
export const getAffidavitTemplateDefaultStatus = (activeOrganization, affidavitTemplateRef) => {
    var _a, _b, _c, _d, _e;
    const isPublisherDefault = affidavitTemplateRef &&
        affidavitTemplateRef.id ===
            ((_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.defaultAffidavitTemplate) === null || _b === void 0 ? void 0 : _b.id);
    if (isPublisherDefault) {
        return 'publisher_default';
    }
    const isColumnDefault = affidavitTemplateRef &&
        affidavitTemplateRef.id ===
            ((_e = (_d = (_c = activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.affidavitReconciliationSettings) === null || _d === void 0 ? void 0 : _d.managedAffidavitTemplate) === null || _e === void 0 ? void 0 : _e.id);
    if (isColumnDefault) {
        return 'column_default';
    }
    return 'not_publisher_default';
};
export const updateTemplateDefaultStatus = (activeOrganization, editedAffidavitTemplateRef, { defaultStatus, isColumnManaged, newStoragePath }) => __awaiter(void 0, void 0, void 0, function* () {
    /**
     * If this is not a default template, we don't need to update anything
     */
    if (defaultStatus !== 'publisher_default') {
        return;
    }
    /**
     * If this is not used for column managed affidavits, we just update the default template on the
     * organization
     */
    if (!isColumnManaged) {
        yield activeOrganization.ref.update({
            defaultAffidavitTemplate: editedAffidavitTemplateRef
        });
    }
    /**
     * For column managed affidavits, we need to create/or update the reconciliation settings
     */
    const existingReconciliationSettings = activeOrganization.data()
        .affidavitReconciliationSettings;
    const organizationModel = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrganization);
    /**
     * If we have never set up Column managed affidavits before, add reconciliation settings
     */
    if (!existingReconciliationSettings) {
        return yield organizationModel.updateAutomatedAffidavitConfiguration({
            managedAffidavitTemplateStoragePath: newStoragePath,
            notarizationVendor: 'notarize',
            uploadMethod: 'not-applicable',
            managedAffidavitTemplate: editedAffidavitTemplateRef,
            affidavitsManagedByColumn: false,
            notarizationRequired: true,
            reconciliationStartDate: getFirebaseContext().timestamp()
        });
    }
    /**
     * If there are existing affidavit reconciliation settings, we just update the managed
     * affidavit template
     */
    yield organizationModel.updateAutomatedAffidavitConfiguration(Object.assign(Object.assign({}, existingReconciliationSettings), { managedAffidavitTemplate: editedAffidavitTemplateRef }));
});
