import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useState } from 'react';
import { CancelOrSubmitModal } from '../CancelOrSubmitModal';
import { ColumnButton } from '../ColumnButton';
import { EditableCardWrapper } from './EditableCardWrapper';
import { GridInput } from './Grid';
export function EditableCardArray({ Component, archivable, baseHeader, columns, createable, onChange, values, background = 'gray', disabled }) {
    const [confirmArchive, setConfirmArchive] = useState(-1);
    const onRemoveElement = (index) => {
        onChange([...values.slice(0, index), ...values.slice(index + 1)]);
    };
    const onChangeElement = (index, change) => onChange([...values.slice(0, index), change, ...values.slice(index + 1)]);
    const onCreateElement = (newVal) => onChange([...values, newVal]);
    return (_jsxs(_Fragment, { children: [confirmArchive >= 0 && (archivable === null || archivable === void 0 ? void 0 : archivable.confirmation) && (_jsx(CancelOrSubmitModal, Object.assign({ header: archivable.confirmation.header, primaryButtonText: "Remove", tertiaryButtonText: "Cancel", onSubmit: () => {
                    onRemoveElement(confirmArchive);
                    setConfirmArchive(-1);
                }, onClose: () => setConfirmArchive(-1), destructive: true }, { children: _jsx("p", Object.assign({ className: "py-3" }, { children: archivable.confirmation.body })) }))), _jsxs("div", Object.assign({ className: classNames('grid gap-6 grid-cols-1', {
                    'sm:grid-cols-1': !columns || columns === 1,
                    'sm:grid-cols-2': columns === 2
                }) }, { children: [values.map((value, idx) => {
                        var _a;
                        let archivableCard;
                        if (archivable) {
                            const allowFn = (_a = archivable.shouldAllow) !== null && _a !== void 0 ? _a : ((_val, { isLast }) => isLast);
                            const shouldAllow = allowFn(value, {
                                index: idx,
                                isFirst: idx === 0,
                                isLast: idx === values.length - 1
                            });
                            if (shouldAllow) {
                                archivableCard = {
                                    buttonText: archivable.buttonText,
                                    onArchive: () => archivable.confirmation
                                        ? setConfirmArchive(idx)
                                        : onRemoveElement(idx)
                                };
                            }
                        }
                        return (_jsx(EditableCardWrapper, { Component: Component, archivable: archivableCard, header: `${baseHeader} ${idx + 1}`, value: value, onChange: change => onChangeElement(idx, change), index: idx, background: background, disabled: disabled }, idx));
                    }), createable && (_jsx(GridInput, Object.assign({ fullWidth: columns === 2 }, { children: _jsx(ColumnButton, { startIcon: _jsx(PlusCircleIcon, { className: "w-6 h-6" }), buttonText: createable.buttonText, secondary: true, link: true, onClick: () => onCreateElement(createable.initialValue), type: "button", disabled: createable.disabled || disabled }) })))] }))] }));
}
