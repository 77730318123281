import { createSelector } from 'reselect';
import { selectAvailableOrganizations, selectIsOnSubdomain, selectIsPublisher, selectIsUserLoggedIn, selectOrgContext, selectUser } from 'redux/auth';
import { selectDraftSnap, selectIsEditing, selectNoticeType, selectPreviousNoticeType, selectIsDisplayNoticeType, selectConfirmedHtml, selectRate, selectPostWithoutFormatting, selectFixedPrice, selectDisplayParams } from 'redux/placement';
import { calculatePlacementSteps, doesSelectedNewspaperHaveNoticeTypes } from 'routes/placeScroll/helpers/calculatePlacementSteps';
import { exists } from 'lib/types';
import { getColumnRangeConfig } from 'lib/notice/columns';
import { NoticeType, FilingTypeVisibility, OccupationType, State } from 'lib/enums';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { getRestrictedPublisherIds } from './helpers';
export const selectPlacementSteps = createSelector([
    (_, shouldShowAccountIDInPlacement) => shouldShowAccountIDInPlacement,
    selectUser,
    selectIsUserLoggedIn,
    selectIsPublisher,
    selectOrgContext,
    selectIsEditing
], (shouldShowAccountIDInPlacement, user, isUserLoggedIn, isPublisher, orgContext, isEditing) => {
    return calculatePlacementSteps({
        user,
        isUserLoggedIn,
        isPublisher,
        orgContext,
        isEditing,
        showAccountInfoStep: shouldShowAccountIDInPlacement
    });
});
export const selectHasNoticeTypeSelect = createSelector([
    (_, newspaper) => newspaper,
    selectOrgContext
], (newspaper, orgContext) => doesSelectedNewspaperHaveNoticeTypes(newspaper, orgContext));
export const selectHasStateLevelNoticeTypes = createSelector([
    (_, newspaper) => newspaper,
    selectHasNoticeTypeSelect,
    selectOrgContext,
    selectIsOnSubdomain
], (newspaper, hasNoticeTypeSelect, orgContext, isOnSubdomain) => {
    var _a, _b;
    if (hasNoticeTypeSelect)
        return false; // Prefer allowedNotices if it exists
    return ((isOnSubdomain &&
        !!orgContext &&
        !!orgContext.state &&
        !!((_a = State.by_value(orgContext.state)) === null || _a === void 0 ? void 0 : _a.hasTypeform)) ||
        (exists(newspaper) &&
            !!((_b = State.by_value(newspaper.data().state)) === null || _b === void 0 ? void 0 : _b.hasTypeform)));
});
export const selectShouldShowNoticeTypeFields = createSelector([
    (_, newspaper) => newspaper,
    selectHasNoticeTypeSelect,
    selectHasStateLevelNoticeTypes,
    selectIsEditing,
    selectIsPublisher
], (newspaper, hasNoticeTypeSelect, hasStateLevelNoticeTypes, isEditing, isPublisher) => {
    return ((isPublisher || !isEditing) &&
        (hasNoticeTypeSelect || hasStateLevelNoticeTypes));
});
export const selectValidNoticeTypes = createSelector([
    (_, newspaper) => newspaper,
    selectHasStateLevelNoticeTypes,
    selectUser
], (newspaper, hasStateLevelNoticeTypes, user) => {
    if (!exists(newspaper)) {
        return;
    }
    // Some states like KS and CO have state-level prefillable notice types
    // These should only be shown as options if the newspaper
    // does not have custom notice types set
    if (hasStateLevelNoticeTypes) {
        return getFormattedNoticeTypesForState(newspaper.data().state);
    }
    return getValidNoticeTypesForPlacement(newspaper, user);
});
export const selectCurrentlySelectedNoticeTypeId = createSelector([selectNoticeType, selectPreviousNoticeType, selectIsDisplayNoticeType], (noticeType, previousNoticeType, isDisplayNoticeType) => isDisplayNoticeType ? previousNoticeType : noticeType);
export const selectCurrentlySelectedNoticeType = createSelector([
    (_, newspaper) => newspaper,
    selectValidNoticeTypes,
    selectCurrentlySelectedNoticeTypeId
], (newspaper, validNoticeTypes, currentlySelectedNoticeTypeId) => {
    const foundNoticeType = validNoticeTypes === null || validNoticeTypes === void 0 ? void 0 : validNoticeTypes.find(validNoticeType => validNoticeType.value === currentlySelectedNoticeTypeId);
    if (!foundNoticeType) {
        return;
    }
    return Object.assign(Object.assign({}, foundNoticeType), { isTypeform: foundNoticeType.value !== NoticeType.custom.value &&
            foundNoticeType.value !== NoticeType.display_ad.value &&
            !!foundNoticeType.typeform });
});
export const selectColumnCountRangeConfig = createSelector([
    (_, publisherOrganization) => publisherOrganization,
    selectCurrentlySelectedNoticeType,
    selectIsDisplayNoticeType
], (publisherOrganization, noticeType, isDisplayNotice) => {
    return getColumnRangeConfig({
        publisherOrganization,
        noticeType,
        isDisplayNotice
    });
});
export const selectDisplayOnlyAds = createSelector([(_, newspaper) => newspaper], newspaper => {
    return !!(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().displayOnlyAds);
});
const DEPRECATED_TYPEFORM_KEY = 'yrNuK9';
export const selectShouldLoadTypeformForNoticeType = createSelector([
    (_, newspaper) => newspaper,
    selectCurrentlySelectedNoticeType,
    selectShouldShowNoticeTypeFields,
    selectHasStateLevelNoticeTypes,
    selectIsEditing,
    selectDraftSnap,
    selectPreviousNoticeType,
    selectConfirmedHtml
], (newspaper, selectedNoticeType, shouldShowNoticeTypeFields, hasStateLevelNoticeTypes, isEditing, draftSnap, previousNoticeType, confirmedHtml) => {
    if (!selectedNoticeType || !shouldShowNoticeTypeFields || isEditing) {
        return false;
    }
    // if we are a custom value, don't go through typeform
    if (!hasStateLevelNoticeTypes &&
        selectedNoticeType.value === NoticeType.custom.value) {
        return false;
    }
    // if we don't have typeform set, don't go through typeform
    if (!selectedNoticeType.typeform) {
        return false;
    }
    // don't show the default typeform anymore! This has been deprecated
    if (!hasStateLevelNoticeTypes &&
        selectedNoticeType.typeform === DEPRECATED_TYPEFORM_KEY) {
        return false;
    }
    // If editing a display ad notice
    if ((draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data().noticeType) === NoticeType.display_ad.value) {
        return false;
    }
    if (confirmedHtml &&
        String(previousNoticeType) === String(selectedNoticeType.value) &&
        !selectedNoticeType.isTypeform) {
        return false;
    }
    return true;
});
export const selectShouldLoadMadlibForNoticeType = createSelector([
    (_, newspaper) => newspaper,
    selectCurrentlySelectedNoticeType
], (newspaper, selectedNoticeType) => {
    return !!(selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.madlib);
});
export const selectValidColumnCounts = createSelector([
    (_, newspaper) => newspaper,
    selectCurrentlySelectedNoticeType,
    selectColumnCountRangeConfig
], (newspaper, currentlySelectedNoticeType, columnCountRangeConfig) => {
    const { minColumns, maxColumns } = columnCountRangeConfig;
    const validColumnCounts = [];
    for (let i = minColumns; i <= maxColumns; i++) {
        validColumnCounts.push(i);
    }
    const noticeTypeColumnCount = Number(currentlySelectedNoticeType === null || currentlySelectedNoticeType === void 0 ? void 0 : currentlySelectedNoticeType.defaultColumns);
    if (noticeTypeColumnCount &&
        !validColumnCounts.includes(noticeTypeColumnCount)) {
        validColumnCounts.push(noticeTypeColumnCount);
    }
    return validColumnCounts.sort((a, b) => a - b);
});
export const selectRestrictedPublisherIds = createSelector([selectUser, selectIsPublisher, selectAvailableOrganizations], getRestrictedPublisherIds);
export const getNewspaperDefaultRates = (newspaperSnap) => {
    if (!newspaperSnap) {
        return [];
    }
    const { defaultLinerRate, defaultDisplayRate } = newspaperSnap.data();
    if (!defaultLinerRate || !defaultDisplayRate) {
        return [];
    }
    return [defaultLinerRate.id, defaultDisplayRate.id];
};
export const selectIsDefaultRate = createSelector([
    (_, newspaper) => newspaper,
    selectRate
], (newspaper, rate) => {
    const newspaperDefaults = getNewspaperDefaultRates(newspaper);
    return newspaperDefaults.includes((rate === null || rate === void 0 ? void 0 : rate.id) || '');
});
export const selectShouldHideRateField = createSelector([selectIsPublisher, selectPostWithoutFormatting, selectFixedPrice], (isPublisher, postWithoutFormatting, fixedPrice) => {
    // Rate field should not appear for publishers when notice submitted without formatting or Typeform/Madlib has default pricing
    return !isPublisher || postWithoutFormatting || fixedPrice;
});
export const selectNoticeTitleFieldConfig = createSelector([
    (_, newspaper) => newspaper,
    selectIsDisplayNoticeType,
    selectPostWithoutFormatting
], (newspaper, isDisplayNoticeType, postWithoutFormatting) => {
    var _a, _b;
    const enableCustomHeaders = getBooleanFlag(LaunchDarklyFlags.ENABLE_CUSTOMISED_HEADERS);
    const enabled = Boolean(enableCustomHeaders &&
        !isDisplayNoticeType &&
        !postWithoutFormatting &&
        ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().noticeHeaders) === null || _a === void 0 ? void 0 : _a.enabled));
    const required = Boolean(enabled && ((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().noticeHeaders) === null || _b === void 0 ? void 0 : _b.required));
    return { enabled, required };
});
export const selectShouldHideColumnCountField = createSelector([
    (_, newspaper) => newspaper,
    selectCurrentlySelectedNoticeType,
    selectIsPublisher,
    selectPostWithoutFormatting
], (newspaper, selectedNoticeType, isPublisher, postWithoutFormatting) => {
    // Hide when the notice is submitted without formatting
    return (postWithoutFormatting ||
        // Hide if the typeform/madlib notice type has set number of columns and user is an advertiser
        (((selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.isTypeform) || (selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.madlib)) &&
            !!(selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.defaultColumns) &&
            !isPublisher));
});
export const selectShowHeightWarning = createSelector([
    (_, newspaper) => newspaper,
    selectDisplayParams
], (newspaper, displayParams) => {
    var _a, _b, _c, _d;
    if (displayParams === null || displayParams === void 0 ? void 0 : displayParams.maxHeightExceeded) {
        return {
            showHeightWarning: true,
            heightWarningMessage: 'Your content is taller than the max height of 215 inches.'
        };
    }
    const noticeIsTooTallForNewspaper = newspaper &&
        ((_a = newspaper.data().noticeHeightThresholds) === null || _a === void 0 ? void 0 : _a.maxNoticeHeight) &&
        (displayParams === null || displayParams === void 0 ? void 0 : displayParams.height) &&
        (displayParams === null || displayParams === void 0 ? void 0 : displayParams.height) >
            (((_b = newspaper.data().noticeHeightThresholds) === null || _b === void 0 ? void 0 : _b.maxNoticeHeight) || 0);
    if (noticeIsTooTallForNewspaper) {
        return {
            showHeightWarning: true,
            heightWarningMessage: ((_c = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().noticeHeightThresholds) === null || _c === void 0 ? void 0 : _c.noticeHeightExceededWarning) ||
                `The maximum height of a notice placed in ${newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().name} is
          ${(_d = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().noticeHeightThresholds) === null || _d === void 0 ? void 0 : _d.maxNoticeHeight} inches.`
        };
    }
    return {
        showHeightWarning: false,
        heightWarningMessage: ''
    };
});
function getValidNoticeTypesForPlacement(newspaper, user) {
    var _a;
    const { allowedNotices: noticeTypes } = newspaper.data();
    if (!noticeTypes) {
        return;
    }
    const isPublisher = !!user &&
        [
            OccupationType.publishing.value,
            OccupationType.press_association_manager.value
        ].includes(user.data().occupation);
    const newspaperIsAllowedOrg = (_a = user === null || user === void 0 ? void 0 : user.data().allowedOrganizations) === null || _a === void 0 ? void 0 : _a.some((org) => org.id === newspaper.id);
    const showPublisherOnlyNoticeTypes = isPublisher && newspaperIsAllowedOrg;
    const allEnabledNoticeTypes = noticeTypes.filter(notice => notice.visibility !== FilingTypeVisibility.disabled.value);
    const customerEnabledNoticeTypes = allEnabledNoticeTypes.filter(notice => notice.visibility !== FilingTypeVisibility.publisher_only.value);
    const enabledNoticeTypes = showPublisherOnlyNoticeTypes
        ? allEnabledNoticeTypes
        : customerEnabledNoticeTypes;
    if (!enabledNoticeTypes.length) {
        return;
    }
    // Alphabetize the notice types by their label. All notice types
    // *should* have a label but because there is some messy ReTool-created
    // data from the past we default to empty string to be safe.
    return [...enabledNoticeTypes].sort((a, b) => (a.label || '').localeCompare(b.label || ''));
}
function getFormattedNoticeTypesForState(state) {
    const allFormattedNoticeTypes = NoticeType.rootItems();
    function getFormattedChildNoticeTypes(noticeType) {
        const childrenTypes = noticeType.children();
        const formattedChildrenTypes = childrenTypes.map(childType => (Object.assign(Object.assign({}, childType), { label: `${noticeType.label} - ${childType.label}` })));
        allFormattedNoticeTypes.push(...formattedChildrenTypes);
        // Recursively get sub-notice types until no more exist
        formattedChildrenTypes.forEach(getFormattedChildNoticeTypes);
    }
    allFormattedNoticeTypes.forEach(getFormattedChildNoticeTypes);
    return allFormattedNoticeTypes
        .filter(noticeType => { var _a; return ((_a = noticeType.states) === null || _a === void 0 ? void 0 : _a.includes(state)) && noticeType.typeform; })
        .sort((a, b) => a.label.localeCompare(b.label));
}
