// nosemgrep: ban-untyped-saga-select
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useSelector, useDispatch } from 'react-redux';
import { select } from 'redux-saga/effects';
export const useAppSelector = useSelector;
export function* appSagaSelect(selector) {
    const result = yield select(selector);
    return result;
}
export const useAppDispatch = useDispatch;
