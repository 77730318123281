var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
/**
 * Send a request to the backend requesting to cancel or refund the active
 * invoice for a notice.
 */
export const cancelOrRefundInvoiceForNotice = (notice, voidReason) => __awaiter(void 0, void 0, void 0, function* () {
    const reqBody = {
        noticeId: notice.id,
        voidReason
    };
    yield api.post('payments/cancel-invoice', reqBody);
});
