var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { wrapSuccess } from 'lib/types/responses';
import { getFirebaseContext } from 'utils/firebase';
const newspaperOrderDependencies = [
    'status',
    'transfer'
];
const getNewspaperOrderDependencies = (newspaperOrders) => {
    return newspaperOrderDependencies.map(key => `${newspaperOrders.map(no => no[key])}`);
};
export const useOrderEditableData = ({ userSnap, order, newspaperOrders }) => {
    const { value: editData } = useSafeAsyncEffect({
        fetchData: () => __awaiter(void 0, void 0, void 0, function* () {
            if (!order) {
                return wrapSuccess(null);
            }
            const user = userSnap
                ? getModelFromSnapshot(UserModel, getFirebaseContext(), userSnap)
                : null;
            return order.getEditableDataForNewspapers(user);
        }),
        dependencies: [
            !!userSnap,
            !!order,
            ...getNewspaperOrderDependencies(newspaperOrders)
        ]
    });
    return editData;
};
