import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State, enumToSelectInput } from 'lib/enums';
import React from 'react';
const addressStateToEnumValue = (addressState) => {
    var _a, _b;
    if (!addressState) {
        return;
    }
    if (typeof addressState === 'number') {
        return addressState;
    }
    const valueByLabel = (_a = State.by_label(addressState)) === null || _a === void 0 ? void 0 : _a.value;
    if (valueByLabel) {
        return valueByLabel;
    }
    const valueByAbbrev = (_b = State.items().find(state => state.abbrev.toLowerCase() === addressState.toLowerCase())) === null || _b === void 0 ? void 0 : _b.value;
    if (valueByAbbrev) {
        return valueByAbbrev;
    }
    return State.alabama.value;
};
export function PayToAddress({ value, onChange, disabled }) {
    var _a;
    const onChangeHelper = (change) => {
        if (!value) {
            return;
        }
        onChange(Object.assign(Object.assign({}, value), change));
    };
    return (_jsxs(SwitchControlledCard, Object.assign({ header: "", labelProps: {
            label: 'Customize billing address',
            description: `By default, Column's mailing address is provided for check payments. Toggle this on to provide the newspaper's address instead.`,
            value: !!value,
            onChange: enableCustomAddress => onChange(enableCustomAddress
                ? {
                    payable_to: '',
                    address_city: '',
                    address_line1: '',
                    address_state: '',
                    address_zip: ''
                }
                : undefined),
            disabled
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "invoice-address-payable-to", labelText: "Payable to", required: true, value: value === null || value === void 0 ? void 0 : value.payable_to, onChange: payable_to => onChangeHelper({ payable_to }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "invoice-address-line-1", labelText: "Address line 1", required: true, value: value === null || value === void 0 ? void 0 : value.address_line1, onChange: address_line1 => onChangeHelper({ address_line1 }), disabled: disabled }) })), _jsx(GridInput, { children: _jsx(TextField, { id: "invoice-address-line-2", labelText: "Address line 2", value: value === null || value === void 0 ? void 0 : value.address_line2, onChange: address_line2 => onChangeHelper({ address_line2 }), disabled: disabled }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "invoice-address-city", labelText: "City", required: true, value: value === null || value === void 0 ? void 0 : value.address_city, onChange: address_city => onChangeHelper({ address_city }), disabled: disabled }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "invoice-address-state", labelText: "State", value: (_a = addressStateToEnumValue(value === null || value === void 0 ? void 0 : value.address_state)) === null || _a === void 0 ? void 0 : _a.toString(), options: enumToSelectInput(State), onChange: stringValue => onChangeHelper({ address_state: Number(stringValue) }), disabled: disabled, allowUndefined: true, required: true }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "invoice-address-zip", labelText: "Zip Code", required: true, value: value === null || value === void 0 ? void 0 : value.address_zip, onChange: address_zip => onChangeHelper({ address_zip }), disabled: disabled, type: "postal-code" }) })] })));
}
