export const AffidavitStatusTypeData = {
    affidavit_disabled: {
        value: 1,
        label: 'Not Required',
        key: 'affidavit_disabled'
    },
    awaiting_publication: {
        value: 2,
        label: 'Awaiting Publication',
        key: 'awaiting_publication'
    },
    incomplete: {
        value: 3,
        label: 'Incomplete',
        key: 'incomplete'
    },
    awaiting_upload: {
        value: 4,
        label: 'Awaiting Upload',
        key: 'awaiting_upload'
    },
    submitted: {
        value: 5,
        label: 'Submitted',
        key: 'submitted'
    },
    outside_of_column: {
        value: 6,
        label: 'Outside of Column',
        key: 'outside_of_column'
    },
    publication_in_progress: {
        value: 7,
        label: 'Publication in Progress',
        key: 'publication_in_progress'
    }
};
