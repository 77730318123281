var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NOTE_ATTACHMENT_UPLOAD_FAILED, NoteService, NoteServiceError } from 'lib/services/NoteService';
import { cdnIfy } from 'lib/helpers';
import { uploadFilesToStorage } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import Firebase from 'EnoticeFirebase';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getFirebaseContext } from './firebase';
import { getBooleanFlag } from './flags';
const noteService = new NoteService(getFirebaseContext());
export function createNoteFromClient({ noteTopic, noteContent, notesAttachment }) {
    return __awaiter(this, void 0, void 0, function* () {
        const createNotePayload = {
            noteTopic,
            noteContent,
            attachments: []
        };
        try {
            if (notesAttachment) {
                const attachments = yield uploadNoteAttachments([notesAttachment]);
                createNotePayload.attachments = attachments;
            }
        }
        catch (error) {
            const uploadError = NoteServiceError.getError(error);
            uploadError.captureError({
                fileName: (notesAttachment === null || notesAttachment === void 0 ? void 0 : notesAttachment.name) || '',
                fileSize: String(notesAttachment === null || notesAttachment === void 0 ? void 0 : notesAttachment.size) || '',
                userId: noteContent.noteCreator.id,
                noteCreatorId: noteContent.noteCreator.id,
                noteCreatorOrganizationId: noteContent.noteCreatorOrganization.id
            });
            return { errorCode: uploadError.errorCode };
        }
        return yield noteService.createNote(createNotePayload);
    });
}
function uploadNoteAttachments(attachments) {
    return __awaiter(this, void 0, void 0, function* () {
        const formattedAttachments = [];
        if (attachments) {
            const { successfulFilesAndUploads, failedFilesAndUploads } = yield uploadFilesToStorage(Firebase.storage(), attachments, NoteService.uploadPath);
            if (failedFilesAndUploads.length) {
                throw new NoteServiceError(failedFilesAndUploads[0], NOTE_ATTACHMENT_UPLOAD_FAILED);
            }
            const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
            successfulFilesAndUploads.forEach(fileAndUpload => {
                const storagePath = fileAndUpload.uploadRef.fullPath;
                formattedAttachments.push({
                    storagePath,
                    name: fileAndUpload.file.name,
                    downloadURL: cdnIfy(storagePath, { useColumnCDN })
                });
            });
        }
        return formattedAttachments;
    });
}
