var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getErrorReporter } from '../utils/errors';
import { getOrThrow } from '../utils/refs';
import { exists } from '../types';
import { safeStringify } from '../utils/stringify';
import { getRejected } from '../helpers';
export class CacheManager {
    constructor(ctx, owner, cacheId, cacheSnapshot = undefined) {
        this.ctx = ctx;
        this.owner = owner;
        this.cacheId = cacheId;
        this.cacheSnapshot = cacheSnapshot;
        this.cacheRef = ctx.cachesRef(owner).doc(cacheId);
    }
    /**
     * Can be used in migrations to create the cache in the right place.
     */
    createCache(data) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const snap = (_a = this.cacheSnapshot) !== null && _a !== void 0 ? _a : (yield this.cacheRef.get());
            if (exists(snap)) {
                throw new Error(`Cache ${this.cacheId} already exists.`);
            }
            yield this.cacheRef.set(data);
            getErrorReporter().logInfo('[INAPP CACHE] createCache -- setting cache data', {
                cacheId: this.cacheRef.id,
                cacheOwner: this.cacheRef.parent.id,
                cacheData: (_b = safeStringify(data)) !== null && _b !== void 0 ? _b : ''
            });
        });
    }
    /**
     * Lazily get the cache snapshot, only once per cache manager.
     */
    getCache() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.cacheSnapshot) {
                const snap = yield getOrThrow(this.cacheRef);
                this.cacheSnapshot = snap;
            }
            return this.cacheSnapshot;
        });
    }
    /** Gets the cache description string. */
    getCacheDescription() {
        return __awaiter(this, void 0, void 0, function* () {
            const cache = yield this.getCache();
            return cache === null || cache === void 0 ? void 0 : cache.data().description;
        });
    }
    /**
     * Sets or updates the cache description string
     */
    setCacheDescription(newCacheDescription) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const existingCacheDescription = yield this.getCacheDescription();
            yield this.cacheRef.update({
                description: newCacheDescription
            });
            getErrorReporter().logInfo(`[INAPP CACHE] ${existingCacheDescription ? 'Updating' : 'Setting'} cache description`, Object.assign({ cacheId: (_a = this.cacheRef) === null || _a === void 0 ? void 0 : _a.id, cacheOwner: (_c = (_b = this.cacheRef) === null || _b === void 0 ? void 0 : _b.parent) === null || _c === void 0 ? void 0 : _c.id, newCacheDescription }, (existingCacheDescription && { existingCacheDescription })));
        });
    }
    /** Gets whether the cache is required for a cache with a key type of 'notice-type' or 'rate' */
    getWhetherRequired() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const cache = yield this.getCache();
            const { keyType } = cache.data();
            if (keyType !== 'notice-type' || keyType !== 'rate') {
                return false;
            }
            return (_a = cache.data().required) !== null && _a !== void 0 ? _a : false;
        });
    }
    /** Sets whether the cache is required (only available for caches with key types of 'notice-type' or 'rate') */
    setWhetherRequired(isRequired) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const cache = yield this.getCache();
            const { keyType } = cache.data();
            if (keyType !== 'notice-type' && keyType !== 'rate') {
                throw this.getErrorCannotSetRequiredForCacheWithIneligibleKeyTypes(keyType);
            }
            yield this.cacheRef.update({
                required: isRequired
            });
            getErrorReporter().logInfo(`[INAPP CACHE] Change -- setting cache to ${isRequired ? 'required' : 'not required'}`, {
                cacheId: (_a = this.cacheRef) === null || _a === void 0 ? void 0 : _a.id,
                cacheOwner: (_c = (_b = this.cacheRef) === null || _b === void 0 ? void 0 : _b.parent) === null || _c === void 0 ? void 0 : _c.id,
                keyType,
                isRequired: isRequired ? 'true' : 'false'
            });
        });
    }
    /**
     * Get a Firestore query returning all entries in the cache with a certain key.
     */
    getEntriesQuery(key) {
        const entriesRef = this.ctx.cacheEntriesRef(this.cacheRef);
        return entriesRef.where('key.value', '==', key).get();
    }
    /**
     * Get a query to retrieve all entries.
     */
    getAllEntriesQuery() {
        const entriesRef = this.ctx.cacheEntriesRef(this.cacheRef);
        return entriesRef.orderBy('key.value', 'asc');
    }
    /**
     * Load all entries in the cache.
     */
    getAllEntries() {
        return __awaiter(this, void 0, void 0, function* () {
            const snap = yield this.getAllEntriesQuery().get();
            return snap.docs;
        });
    }
    /** Get the cache entry value and cache entry description (by key) if the cache entry exists */
    getValueAndCacheEntryDescriptionIfExists(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const match = yield this.getEntriesQuery(key);
            if (match.docs.length > 1) {
                throw this.getErrorMultipleEntries(key);
            }
            if (match.empty) {
                return undefined;
            }
            return {
                value: match.docs[0].data().value.value,
                description: match.docs[0].data().description
            };
        });
    }
    getValueIfExists(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const { value } = (yield this.getValueAndCacheEntryDescriptionIfExists(key)) || {};
            return value;
        });
    }
    /**
     * Query the cache by key and return the matching value. Throws
     * if there is not exactly one match.
     */
    getValue(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const match = yield this.getValueIfExists(key);
            if (match == null) {
                throw this.getErrorDoesNotExist(key);
            }
            return match;
        });
    }
    /**
     * Query the cache by key and return the matching cache entry description.
     */
    getCacheEntryDescriptionIfExists(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const { description } = (yield this.getValueAndCacheEntryDescriptionIfExists(key)) || {};
            return description;
        });
    }
    /**
     * Add a new entry to the cache. Throws if there is already a
     * matching entry for the key.
     */
    addValue(keyData, valueData, descriptionData) {
        return __awaiter(this, void 0, void 0, function* () {
            // Check for existing
            const match = yield this.getEntriesQuery(keyData);
            if (!match.empty) {
                throw new Error(`Document already exists in cache ${this.cacheRef.path} with key ${keyData}`);
            }
            const cache = yield this.getCache();
            const entry = Object.assign({ key: {
                    type: cache.data().keyType,
                    value: keyData
                }, value: {
                    type: cache.data().valueType,
                    value: valueData
                } }, (descriptionData && { description: descriptionData }));
            yield this.ctx.cacheEntriesRef(cache.ref).add(entry);
            getErrorReporter().logInfo('[INAPP CACHE] Adding cache entry', Object.assign({ cacheId: this.cacheRef.id, cacheOwner: this.cacheRef.parent.id, keyData: keyData.toString(), valueData: valueData.toString() }, (descriptionData && { descriptionData })));
        });
    }
    addValues(inputs) {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield Promise.allSettled(inputs.map(({ key, value, description }) => this.addValue(key, value, description)));
            const errors = getRejected(results);
            if (errors.length) {
                throw new Error(`Unable to add at least one cache value: ${errors
                    .map(e => e.message)
                    .join('; ')}`);
            }
        });
    }
    /**
     * Update an entry in the cache. Only the value will change, and this throws if the entry does not
     * exist (lookup by key) or the key is somehow already in use for multiple entries.
     */
    updateValue(keyData, valueData, descriptionData) {
        return __awaiter(this, void 0, void 0, function* () {
            // Check for existing
            const match = yield this.getEntriesQuery(keyData);
            // Key is unchanged, but no entries means nothing to update.
            if (match.empty) {
                throw this.getErrorDoesNotExist(keyData);
            }
            // Key is somehow already in use for multiple cache entries.
            if (match.docs.length > 1) {
                throw this.getErrorMultipleEntries(keyData);
            }
            const cache = yield this.getCache();
            const entry = Object.assign({ key: {
                    type: cache.data().keyType,
                    value: keyData
                }, value: {
                    type: cache.data().valueType,
                    value: valueData
                } }, (descriptionData && { description: descriptionData }));
            yield match.docs[0].ref.update(entry);
            getErrorReporter().logInfo('[INAPP CACHE] Updating cache entry', Object.assign({ cacheId: this.cacheRef.id, cacheOwner: this.cacheRef.parent.id, keyData: keyData.toString(), valueData: valueData.toString() }, (descriptionData && { descriptionData })));
        });
    }
    /**
     * Delete a single entry from the cache.
     */
    deleteValue(keyData) {
        return __awaiter(this, void 0, void 0, function* () {
            // Check for existing
            const match = yield this.getEntriesQuery(keyData);
            if (match.empty) {
                throw this.getErrorDoesNotExist(keyData);
            }
            if (match.docs.length > 1) {
                throw this.getErrorMultipleEntries(keyData);
            }
            const formerValueData = match.docs[0].data().value.value;
            const formerDescriptionData = match.docs[0].data().description;
            yield match.docs[0].ref.delete();
            getErrorReporter().logInfo('[INAPP CACHE] Deleting cache entry', Object.assign({ cacheId: this.cacheRef.id, cacheOwner: this.cacheRef.parent.id, keyData: keyData.toString(), formerValueData: formerValueData.toString() }, (formerDescriptionData && { formerDescriptionData })));
        });
    }
    getErrorDoesNotExist(key) {
        return new Error(`Document does not exist in cache ${this.cacheRef.path} with key ${safeStringify(key)}`);
    }
    getErrorMultipleEntries(key) {
        return new Error(`Multiple entries in cache ${this.cacheRef.path} for key ${JSON.stringify(key)}`);
    }
    getErrorCannotSetRequiredForCacheWithIneligibleKeyTypes(cacheKeyType) {
        return new Error(`Cannot set 'required' property on cache ${this.cacheRef.path} given keyType ${cacheKeyType}; can only set 'required' property on caches with 'notice-type' or 'rate' keyTypes`);
    }
}
