var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import Dwolla from 'react-dwolla-iav';
import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
function DwollaIav({ environment, customerToken, onClose }) {
    const [showContinue, setShowContinue] = useState('');
    const [pageLoad, setPageLoad] = useState();
    const dwollaIavConfig = {
        backButton: true,
        customerToken,
        environment,
        fallbackToMicroDeposits: true,
        microDeposits: true,
        subscriber: ({ currentPage }) => {
            setPageLoad(currentPage);
        }
    };
    const addFundingSource = (data) => __awaiter(this, void 0, void 0, function* () {
        const response = yield api.post('dwolla/add-bank', {
            fundingSourceId: data,
            verification: pageLoad === 'SuccessIAV'
        });
        if (response.success) {
            onClose();
        }
    });
    const onSuccess = (data) => {
        try {
            setShowContinue(data);
        }
        catch (err) {
            console.warn('Failed to update showContinue state:', err);
        }
    };
    const onError = (err) => {
        logAndCaptureException(ColumnService.PAYMENTS, err, 'Failed to add Dwolla funding source', {
            customerToken
        });
    };
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: `${pageLoad && 'hidden'} w-full flex justify-center` }, { children: _jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10 mr-2 mt-8" }) })), _jsx("div", Object.assign({ className: `${!pageLoad && 'hidden'}` }, { children: _jsx(FreeformCModal, Object.assign({ header: '', setOpen: !pageLoad ? undefined : () => onClose(), noExitOutsideModal: true }, { children: _jsxs(_Fragment, { children: [_jsx(Dwolla, { onSuccess: onSuccess, onError: onError, dwollaConfig: dwollaIavConfig }), showContinue && (_jsx("button", Object.assign({ type: "button", className: "px-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border", onClick: () => addFundingSource(showContinue) }, { children: "Continue" })))] }) })) }))] }));
}
export default DwollaIav;
