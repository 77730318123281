import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { useAppSelector } from 'redux/hooks';
import { TextField } from 'lib/components/TextField';
import { isFlatAdditionalFee, isNoticeRate } from 'lib/types/rates';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCards } from 'lib/components/Card/SwitchControlledCards';
import { userIsSuper } from 'utils/permissions';
import AdditionalFeeElement from './AdditionalFeeElement';
const DEFAULT_ADDITIONAL_FEE = {
    type: 'flat',
    amount: 0,
    description: 'Additional Fee',
    perRun: false,
    perAffidavitFee: false
};
export default function AdditionalFeesCard({ setUpdatedRateData, updatedRateData, rateData }) {
    var _a, _b, _c;
    const user = useAppSelector(state => state.auth.user);
    const isSuperUser = user && userIsSuper(user);
    const [enableAdditionalFees, setEnableAdditionalFees] = useState(!!rateData.additionalFees);
    const [showConfirmDeleteFeesModal, setShowConfirmDeleteFeesModal] = useState(false);
    const hasLegacyFee = isNoticeRate(rateData) && !!rateData.additionalFee;
    const hasOffsetFee = !!((_a = updatedRateData.additionalFees) === null || _a === void 0 ? void 0 : _a.filter(fee => isFlatAdditionalFee(fee) && fee.isOffsetFee).length);
    const disableAdditionalFees = hasLegacyFee || hasOffsetFee;
    /** If we remove all additional fees in the UI, disable the form switch */
    useEffect(() => {
        var _a;
        setEnableAdditionalFees(!!((_a = updatedRateData.additionalFees) === null || _a === void 0 ? void 0 : _a.length));
    }, [updatedRateData.additionalFees]);
    return (_jsxs(_Fragment, { children: [showConfirmDeleteFeesModal && (_jsx(CancelOrSubmitModal, Object.assign({ primaryButtonText: "Delete Fees", tertiaryButtonText: "Cancel", onSubmit: () => {
                    setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { additionalFees: [] }));
                    setEnableAdditionalFees(false);
                    setShowConfirmDeleteFeesModal(false);
                }, onClose: () => setShowConfirmDeleteFeesModal(false), header: `Delete All Fees?`, destructive: true }, { children: _jsx("div", Object.assign({ className: "py-3" }, { children: _jsx("p", { children: "Are you sure you want to delete additional fees for this rate?" }) })) }))), _jsxs(CardGridLayout, Object.assign({ header: {
                    title: 'Additional Fees',
                    description: 'Charge additional fees alongside the regular price for this rate.'
                } }, { children: [isSuperUser && isNoticeRate(updatedRateData) && (_jsx(GridInput, { children: _jsx(TextField, { id: "column-rep-fee-input", labelText: "What is the additional fee for Column Reps (%)?", value: `${(_b = updatedRateData.columnRepFeePct) !== null && _b !== void 0 ? _b : 0}`, type: "number", onChange: val => {
                                const columnRepFeePct = parseInt(val, 10);
                                if (columnRepFeePct > 0 || !!updatedRateData.columnRepFeePct) {
                                    setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { columnRepFeePct }));
                                }
                            } }) })), _jsx(SwitchControlledCards, { labelProps: {
                            disabled: disableAdditionalFees,
                            value: enableAdditionalFees,
                            onChange: newValue => {
                                if (!newValue) {
                                    setShowConfirmDeleteFeesModal(true);
                                    return;
                                }
                                setEnableAdditionalFees(true);
                                setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { additionalFees: [DEFAULT_ADDITIONAL_FEE] }));
                            },
                            label: 'Enable additional fees?',
                            description: hasLegacyFee
                                ? `Additional Fees cannot be used while an "additional fee" is defined. Contact help@column.us to remove the additional fee.`
                                : hasOffsetFee
                                    ? `Additional Fees cannot be turned off while you have an "flat fee with open rate" structure. To remove all fees, turn off the setting below in Advanced Settings.`
                                    : undefined
                        }, cardProps: {
                            Component: AdditionalFeeElement,
                            baseHeader: 'Additional Fee',
                            onChange: additionalFees => setUpdatedRateData(Object.assign(Object.assign({}, updatedRateData), { additionalFees })),
                            values: (_c = updatedRateData.additionalFees) !== null && _c !== void 0 ? _c : [],
                            archivable: {
                                confirmation: {
                                    header: 'Remove additional fee?',
                                    body: 'Are you sure you want to remove this additional fee?'
                                },
                                buttonText: 'Remove fee',
                                shouldAllow: (value, { isLast }) => value.type === 'flat' && !value.isOffsetFee && isLast
                            },
                            createable: {
                                buttonText: 'Add another fee',
                                initialValue: DEFAULT_ADDITIONAL_FEE
                            }
                        } })] }))] }));
}
