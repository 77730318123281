import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';
export function usePublisherModularSizes(publisherRef) {
    var _a, _b;
    const activeModularSizesQuery = getFirebaseContext()
        .modularSizesRef()
        .where('archived', '==', false);
    const ownedModularSizesQuery = activeModularSizesQuery.where('owner', '==', publisherRef);
    const ownedModularSizes = useFirestoreQueryListener(ownedModularSizesQuery, [publisherRef === null || publisherRef === void 0 ? void 0 : publisherRef.id], {
        message: 'Error listening to owned modular sizes query snapshot',
        tags: {
            publisherOrganizationId: (publisherRef === null || publisherRef === void 0 ? void 0 : publisherRef.id) || ''
        }
    });
    const allowedModularSizesQuery = activeModularSizesQuery.where('publishers', 'array-contains', publisherRef);
    const allowedModularSizes = useFirestoreQueryListener(allowedModularSizesQuery, [publisherRef === null || publisherRef === void 0 ? void 0 : publisherRef.id], {
        message: 'Error listening to allowed modular sizes query snapshot',
        tags: {
            publisherOrganizationId: (publisherRef === null || publisherRef === void 0 ? void 0 : publisherRef.id) || ''
        }
    });
    const allModularSizes = [
        ...((_a = ownedModularSizes === null || ownedModularSizes === void 0 ? void 0 : ownedModularSizes.docs) !== null && _a !== void 0 ? _a : []),
        ...((_b = allowedModularSizes === null || allowedModularSizes === void 0 ? void 0 : allowedModularSizes.docs) !== null && _b !== void 0 ? _b : [])
    ];
    const deduplicatedModularSizes = allModularSizes.filter((modularSize, index) => allModularSizes.findIndex(ms => ms.id === modularSize.id) === index);
    const loading = ownedModularSizes === null || allowedModularSizes === null;
    return { loading, modularSizes: deduplicatedModularSizes };
}
