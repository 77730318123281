import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Search } from 'lib/components/gifs';
import SearchedOrganizationsListItem from './SearchedOrganizationsListItem';
export default function SearchedOrganizationsList({ organizationsList, selectedItems, updateSelectedItems, onCreateNewOrganization, loading }) {
    const isItemSelected = (organizationName) => selectedItems &&
        selectedItems.some(selectedItem => (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) === organizationName);
    return (_jsxs("div", Object.assign({ className: "bg-white rounded mt-2 border shadow px-2 pb-1 overflow-scroll", style: {
            maxHeight: '230px',
            height: !organizationsList.length ? '236px' : ''
        } }, { children: [_jsx("p", Object.assign({ className: "uppercase font-semibold text-gray-500 text-xs ml-2 mt-4 mb-4" }, { children: "Search to join existing organizations" })), _jsx("div", Object.assign({ className: "divide-y divide-gray-250" }, { children: !loading &&
                    organizationsList.map((organizationSnap, index) => {
                        return (organizationSnap && (_jsx("div", { children: _jsx(SearchedOrganizationsListItem, { organizationSnap: organizationSnap, onItemSelected: item => updateSelectedItems([...selectedItems, item]), selected: isItemSelected(organizationSnap.name), index: index }) }, `search-item-${organizationSnap.id}`)));
                    }) })), !loading && !organizationsList.length && (_jsxs("div", Object.assign({ className: "mt-8 flex flex-col items-center justify-center" }, { children: [_jsx("div", Object.assign({ className: "bg-blue-100 h-16 w-16 rounded-full flex items-center justify-center p-1" }, { children: _jsx("img", { src: Search }) })), _jsx("p", Object.assign({ className: "mt-3 text-gray-450 text-sm\tfont-medium\tleading-6" }, { children: "No results found!" })), _jsx("div", Object.assign({ className: "text-primary-500 underline underline-offset-3 text-sm font-medium leading-6 cursor-pointer", onClick: () => onCreateNewOrganization(), 
                        // textUnderlineOffset property not included in tailwind verion 1.8.11 (currently used)
                        style: { textUnderlineOffset: '3px' } }, { children: "Create a new organization" }))] }))), loading && (_jsx("div", Object.assign({ className: "mt-8 mb-4 flex flex-col items-center justify-center" }, { children: _jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5" }) })))] })));
}
