import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
export default function BasicGlobalNoticeTypeSettingsCard({ onUpdateGlobalSettings, newGlobalSettings }) {
    var _a, _b, _c;
    const [useDefaultNoticeType, setUseDefaultNoticeType] = useState(!!newGlobalSettings.defaultNoticeType);
    return (_jsx(CardGridLayout, Object.assign({ header: {
            title: 'Notice Types Settings',
            description: "Configure general preferences for your publication's notice types."
        } }, { children: _jsx(SwitchControlledCard, Object.assign({ header: "Default Notice Type", labelProps: {
                label: 'Should we set a default notice type?',
                disabled: !((_a = newGlobalSettings.allowedNotices) === null || _a === void 0 ? void 0 : _a.length),
                description: 'If enabled, the specified notice type will be selected by default when placing notices.',
                value: useDefaultNoticeType,
                onChange: newValue => {
                    var _a;
                    if (newValue) {
                        const newDefault = (_a = newGlobalSettings.allowedNotices) === null || _a === void 0 ? void 0 : _a[0];
                        if (!newDefault)
                            return;
                        onUpdateGlobalSettings(Object.assign(Object.assign({}, newGlobalSettings), { defaultNoticeType: newDefault.value }));
                        setUseDefaultNoticeType(true);
                    }
                    else {
                        setUseDefaultNoticeType(false);
                        onUpdateGlobalSettings(Object.assign(Object.assign({}, newGlobalSettings), { defaultNoticeType: null }));
                    }
                }
            } }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { labelText: "", value: ((_b = newGlobalSettings.defaultNoticeType) === null || _b === void 0 ? void 0 : _b.toString()) || '', options: ((_c = newGlobalSettings.allowedNotices) === null || _c === void 0 ? void 0 : _c.map(noticeType => ({
                        label: noticeType.label,
                        value: noticeType.value.toString()
                    }))) || [], onChange: newValue => {
                        onUpdateGlobalSettings(Object.assign(Object.assign({}, newGlobalSettings), { defaultNoticeType: parseInt(newValue, 10) }));
                    }, id: "default-notice-type-select" }) })) })) })));
}
