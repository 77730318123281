var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Product } from 'lib/enums';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { useState } from 'react';
import FilingTypeSettings from '../filingTypes';
function getTableFilingTypes(filingTypes) {
    return filingTypes
        .map(filingType => filingType.modelData)
        .sort((a, b) => (a.label || '').localeCompare(b.label || ''));
}
function OrderFilingTypeSettings({ activeOrganization, filingTypes, productLine }) {
    const [tableFilingTypes, setTableFilingTypes] = useState(getTableFilingTypes(filingTypes));
    const handleFilingTypeChange = (filingType) => __awaiter(this, void 0, void 0, function* () {
        const index = filingTypes.findIndex(type => filingType.label === type.modelData.label);
        yield filingTypes[index].ref.update(filingType);
        const newTableFilingTypes = getTableFilingTypes(filingTypes);
        setTableFilingTypes(newTableFilingTypes);
    });
    return (_jsx(FilingTypeSettings, { activeOrganization: activeOrganization, onSave: handleFilingTypeChange, filingTypes: tableFilingTypes, productLine: productLine }));
}
function ProductLineFilingTypeSettings({ activeOrganization, productLine, filingTypes }) {
    const organizationModel = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrganization);
    if (productLine === Product.Notice) {
        logAndCaptureCriticalError(ColumnService.OBITS, new Error('Invalid product line'), 'Attempted to load a notice product from ProductLineFilingTypeSettings', {
            organizationId: activeOrganization.id,
            productLine
        });
        return (_jsx(Alert, { id: "invalid-product", description: "Notices are not supported at this time." }));
    }
    return (_jsx(OrderFilingTypeSettings, { activeOrganization: organizationModel, productLine: productLine, filingTypes: filingTypes }));
}
export default ProductLineFilingTypeSettings;
