import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Grid, Modal, IconButton } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
const styles = (theme) => createStyles({
    modalContent: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        maxWidth: 900,
        textAlign: 'center',
        outline: 'none',
        overflow: 'unset'
    },
    confirmButton: {
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important;`
        },
        maxWidth: '50%',
        paddingLeft: 0,
        paddingRight: 0
    },
    headingText: {
        color: theme.palette.grey[700],
        textTransform: 'uppercase'
    },
    heading: {
        fontWeight: 600
    },
    cancelButton: {
        color: theme.palette.grey[500],
        position: 'absolute',
        top: '-.7em',
        left: '-.7em',
        padding: 0,
        zIndex: -1
    },
    modalScrollContainer: {
        overflowY: 'scroll',
        maxHeight: '75vh'
    }
});
function ImagePreviewModal({ jpgURLs, classes, setOpenModal }) {
    const [loading, setLoading] = useState(true);
    return (_jsx(Modal, Object.assign({ open: true, onBackdropClick: () => setOpenModal(false) }, { children: _jsxs(Grid, Object.assign({ className: classes === null || classes === void 0 ? void 0 : classes.modalContent }, { children: [!loading && (_jsx(IconButton, Object.assign({ className: classes === null || classes === void 0 ? void 0 : classes.cancelButton, onClick: () => setOpenModal(false) }, { children: _jsx(Cancel, {}) }))), _jsx("div", Object.assign({ className: classes === null || classes === void 0 ? void 0 : classes.modalScrollContainer }, { children: jpgURLs.map((url, index) => (_jsx("div", { children: _jsx("img", { style: { width: '100%' }, src: url, ref: img => {
                                if (!img)
                                    return;
                                // eslint-disable-next-line no-param-reassign
                                img.onload = () => {
                                    setLoading(false);
                                };
                            } }) }, index))) }))] })) })));
}
export default withStyles(styles)(ImagePreviewModal);
