var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import api from 'api';
import { push } from 'connected-react-router';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { exists } from 'lib/types';
import { useState } from 'react';
import AuthActions, { selectActiveOrganization, selectUser, selectUserAuth } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { POST_ORGANIZATION_REGISTRATION_ENTRYPOINT, POST_REGISTRATION_ENTRYPOINT } from 'redux/router';
import { organizationAlreadyExists, updatePendingInviteStatusForAnonUsers } from 'routes/register/helpers';
import { logAndCaptureMessage } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import RegisterOrganizationAddressForm, { EMPTY_ADDRESS } from './RegisterOrganizationAddressForm';
import RegisterOrganizationAlreadyExists from './RegisterOrganizationAlreadyExists';
import { createOrganization } from './createOrganization';
export default function OrganizationRegistration({ organizationType }) {
    const [showCancelWarning, setShowCancelWarning] = useState(false);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const userAuth = useAppSelector(selectUserAuth);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [address, setAddress] = useState(EMPTY_ADDRESS);
    const [loading, setLoading] = useState(false);
    const [alreadyExists, setAlreadyExists] = useState(false);
    const allFieldsValid = address.validation.valid;
    const goBackLink = () => {
        dispatch(push(POST_REGISTRATION_ENTRYPOINT));
    };
    const nextButtonClicked = () => __awaiter(this, void 0, void 0, function* () {
        if (!allFieldsValid) {
            return;
        }
        yield handleSubmit();
    });
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (!exists(user)) {
            logAndCaptureMessage('User does not exist');
            return;
        }
        setLoading(true);
        // Check if org already exists
        const orgAlreadyExists = yield organizationAlreadyExists(organizationType, address.name);
        if (orgAlreadyExists) {
            setAlreadyExists(true);
            setLoading(false);
            return;
        }
        const newlyCreatedOrganization = yield createOrganization({
            organizationType,
            name: address.name,
            address: address.addressLine1,
            addressLine2: address.addressLine2,
            city: address.city,
            state: address.state.id,
            zipCode: address.zipCode,
            phone: address.phone,
            createdBy: userAuth === null || userAuth === void 0 ? void 0 : userAuth.uid,
            stripeId: user.data().stripeId || ((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.stripeId),
            userName: user.data().name,
            email: user.data().email
        });
        const reqBody = {
            // if there is an active organization, skip the notice migration
            skipNoticeMigration: Boolean(activeOrganization),
            // link to the new organization
            organizationId: newlyCreatedOrganization.id,
            uid: (userAuth === null || userAuth === void 0 ? void 0 : userAuth.uid) || ''
        };
        const resp = yield api.post('users/link-filer-to-org', reqBody);
        if (!resp.success) {
            throw new Error(`Unable to link filer ${uid} to organization ${newlyCreatedOrganization.id}`);
        }
        yield updatePendingInviteStatusForAnonUsers(getFirebaseContext(), user);
        yield user.ref.update({
            postRegistrationComplete: true,
            activeOrganization: newlyCreatedOrganization
        });
        dispatch(AuthActions.register());
        dispatch(push(POST_ORGANIZATION_REGISTRATION_ENTRYPOINT));
        window.location.reload();
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col w-200" }, { children: [_jsx("form", Object.assign({ className: "flex flex-col bg-white rounded-md px-8 pt-8 pb-12 shadow-2 border" }, { children: _jsx(RegisterOrganizationAddressForm, { nameFieldText: 'Name of organization', handleInputChanged: value => setAddress(value), namePlaceholderText: "Name of organization" }) })), _jsx(AdvertiserRegistrationFooter, { id: "submit-organization", backButtonText: "Go back", nextButtonText: "Create organization", onBackButtonClick: () => setShowCancelWarning(true), onNextButtonClick: nextButtonClicked, loading: loading, disableNextButton: !allFieldsValid })] })), showCancelWarning && (_jsx(CancelOrSubmitModal, Object.assign({ header: 'Are you sure?', secondaryButtonText: 'Cancel', onSecondaryButtonClick: () => {
                    setShowCancelWarning(false);
                }, onClose: () => setShowCancelWarning(false), primaryButtonText: `Yes, continue`, onSubmit: goBackLink }, { children: _jsx("p", Object.assign({ className: "py-5" }, { children: "If you go back now, your progress will be lost. Please click \"Yes, continue\" to confirm." })) }))), alreadyExists && (_jsx(RegisterOrganizationAlreadyExists, { name: address.name, onBackClicked: () => setAlreadyExists(false) }))] }));
}
