var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
import { getOrThrow } from './refs';
/**
 * Return a proxy of the child that defers to the parent when a
 * property is null or undefined.
 */
const getInheritanceProxy = (child, parent) => {
    return new Proxy(child, {
        get(target, prop) {
            if (typeof prop !== 'string') {
                return undefined;
            }
            const childVal = target[prop];
            const parentVal = parent === null || parent === void 0 ? void 0 : parent[prop];
            return childVal !== null && childVal !== void 0 ? childVal : parentVal;
        }
    });
};
/**
 * Get a single object which merges parent and child using a proxy.
 */
export const getInheritedData = (ref) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const childSnap = yield getOrThrow(ref);
    const parentSnap = yield ((_b = (_a = childSnap.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.get());
    return getInheritanceProxy(childSnap.data(), parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data());
});
/**
 * Get an inherited property on any object, preferring the child value
 * to the parent value.
 */
export const getInheritedProperty = (child, key) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const childSnap = yield child.get();
    if (!exists(childSnap)) {
        return undefined;
    }
    const childVal = childSnap.data()[key];
    if (childVal !== null && childVal !== undefined) {
        return childVal;
    }
    const parentSnap = yield ((_c = childSnap.data().parent) === null || _c === void 0 ? void 0 : _c.get());
    if (!exists(parentSnap)) {
        return undefined;
    }
    return parentSnap.data()[key];
});
