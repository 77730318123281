import { getColumnInches } from '../math';
export const priceBerthoudGovernment = options => {
    const { runNumber, displayParameters, rateRecord } = options;
    const lineRate = 44;
    const ciRate = 766;
    return runNumber === 0
        ? lineRate * displayParameters.lines
        : ciRate *
            getColumnInches(displayParameters.height, displayParameters.columns, rateRecord.roundOff);
};
