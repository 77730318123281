import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { exists } from 'lib/types';
import PlacementActions, { selectOriginalId } from 'redux/placement';
import { selectIsPublisher } from 'redux/auth';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import LinkTo from 'components/LinkTo';
import { getFirebaseContext } from 'utils/firebase';
import { useFetchAvailablePublishers } from 'hooks/useFetchAvailablePublishers';
import { Product } from 'lib/enums';
import ScrollStep from '../ScrollStep';
import { publisherReadyToUpload } from '../../../lib/publishers';
import { DisabledPublisherModal } from './DisabledPublisherModal';
import { ConfirmPublisherForm } from './ConfirmPublisherForm';
import { CONFIRM_PUBLISHER } from '../helpers/calculatePlacementSteps';
import { useFetchCustomer } from '../hooks/useFetchCustomer';
import { getStateFromLocationParams } from '../helpers';
import { selectRestrictedPublisherIds } from '../placeScrollSelectors';
export default function ConfirmPublisherStep({ next, onDisabledStepClick, publisherOrganization }) {
    var _a;
    const dispatch = useAppDispatch();
    const restrictedSingleState = getStateFromLocationParams();
    const isUserPublisher = useAppSelector(selectIsPublisher);
    const restrictedPublisherIds = useAppSelector(selectRestrictedPublisherIds);
    const { loading: publisherListLoading, stateOptions, stateFilter, setStateFilter, publisherOptions } = useFetchAvailablePublishers({
        restrictedSingleState,
        isUserPublisher,
        restrictedPublisherIds,
        product: Product.Notice
    });
    const originalId = useAppSelector(selectOriginalId);
    const [showDisabledPublisherModal, setShowDisabledPublisherModal] = useState(false);
    const customer = useFetchCustomer();
    const enableColumnExpressLink = getBooleanFlag(LaunchDarklyFlags.ENABLE_CANT_FIND_NEWSPAPER);
    const publisherOrganizationDisabled = exists(publisherOrganization) &&
        !publisherReadyToUpload(publisherOrganization, isUserPublisher);
    useEffect(() => {
        if (publisherOrganizationDisabled) {
            setShowDisabledPublisherModal(true);
        }
    }, [publisherOrganizationDisabled]);
    function onNext() {
        if (publisherOrganizationDisabled) {
            return setShowDisabledPublisherModal(true);
        }
        return next();
    }
    function handlePublisherOrganizationChange(publisherOrganizationId) {
        if (!publisherOrganizationId) {
            return dispatch(PlacementActions.setNewspaper(null));
        }
        const newPublisherOrganizationRef = getFirebaseContext()
            .organizationsRef()
            .doc(publisherOrganizationId);
        dispatch(PlacementActions.setNewspaper(newPublisherOrganizationRef));
    }
    function handleStateFilterChange(stateId) {
        if (stateId &&
            exists(publisherOrganization) &&
            publisherOrganization.data().state !== stateId) {
            dispatch(PlacementActions.setNewspaper(null));
        }
        setStateFilter(stateId);
    }
    const isAccountOnHold = (_a = customer === null || customer === void 0 ? void 0 : customer.data()) === null || _a === void 0 ? void 0 : _a.isAccountOnHold;
    const title = 'Where would you like to place a notice?';
    const caption = enableColumnExpressLink && originalId ? (_jsxs(_Fragment, { children: [_jsx("span", { children: "Can't find your local newspaper? " }), _jsx(LinkTo, Object.assign({ className: "text-primary-500", href: `/form/newspaper/${originalId}` }, { children: "Click here to let us know." }))] })) : ('Select a publication. Use the filter button to search by location.');
    const onHoldText = isAccountOnHold && exists(publisherOrganization)
        ? `Your account is on hold. Please reach out to ${publisherOrganization.data().name} for more information.`
        : undefined;
    const isComplete = !!publisherOrganization && !isAccountOnHold && !publisherListLoading;
    return (_jsxs(ScrollStep, Object.assign({ id: CONFIRM_PUBLISHER, next: onNext, complete: isComplete, onDisabledStepClick: onDisabledStepClick, title: title, caption: caption, onHoldText: onHoldText }, { children: [_jsx(ConfirmPublisherForm, { publisherOrganization: publisherOrganization, onPublisherOrganizationChange: handlePublisherOrganizationChange, publisherOptions: publisherOptions, stateOptions: stateOptions, onStateChange: handleStateFilterChange, loading: publisherListLoading, activeFilters: {
                    stateFilter
                } }), showDisabledPublisherModal && (_jsx(DisabledPublisherModal, { publisherOrganization: publisherOrganization, setOpen: setShowDisabledPublisherModal }))] })));
}
