var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Calculates the template to use based on defaults set on the newspaper, custom notice type, and customer organization
 * @param newspaper The newspaper that we are filing with
 * @param customTypeTemplate The template that exists on the custom notice type if any
 * @param customerOrganization The customer organization linking between the paper and the advertiser's org if any
 * @returns the default template to use
 */
export const getAdTemplate = (newspaper, customTypeTemplate, customerOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    // Logic hierarchy for adTemplate:
    // 0. Customer Organization
    const customerOrganizationSnap = yield (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.get());
    if ((_a = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _a === void 0 ? void 0 : _a.adTemplate) {
        return (_b = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _b === void 0 ? void 0 : _b.adTemplate;
    }
    // 1. Custom Notice Type
    if (customTypeTemplate)
        return customTypeTemplate;
    // 2. Newspaper
    return (_c = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _c === void 0 ? void 0 : _c.adTemplate;
});
