import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Drawer from 'lib/components/Drawer';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import TemplateDrawerHeader from './TemplateDrawerHeader';
import TemplateDrawerBody from './TemplateDrawerBody';
/**
 * Drawer component for detail view of a template
 */
export default function TemplateSettingsDrawer({ value, onClose, onEdit }) {
    const template = useFirestoreDocumentListener(value.ref);
    return (_jsx(_Fragment, { children: _jsx(Drawer, Object.assign({ onClose: () => {
                onClose();
            }, header: _jsx(TemplateDrawerHeader, { template: template }), open: !!template }, { children: _jsx(TemplateDrawerBody, { template: template, onEdit: onEdit }) })) }));
}
