import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import React from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { getArrayInputType, getInputType, isArrayType } from './inputs';
export function ValueInput({ value, onChange, valueType }) {
    const isArray = isArrayType(valueType);
    const arrayValues = isArray ? JSON.parse(value) : [];
    return (_jsx(GridInput, Object.assign({ fullWidth: isArray }, { children: valueType === 'boolean' ? (_jsx(LabeledSwitch, { label: "What is the cached value?", description: "Will serve a boolean; on is True and off is False.", value: value === 'true', onChange: newValue => {
                onChange(newValue ? 'true' : 'false');
            } })) : isArray ? (_jsx(EditableCardArray, { baseHeader: "Value", columns: 2, onChange: valueArray => onChange(JSON.stringify(valueArray)), Component: ({ value, index, onChange }) => (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: `cache-value-${index}`, labelText: "What is the cached value?", value: value, type: getArrayInputType(valueType), onChange: onChange }) }))), values: arrayValues, createable: {
                initialValue: '',
                buttonText: 'Add Value'
            }, archivable: {
                buttonText: 'Remove Value',
                shouldAllow: () => arrayValues.length > 1
            } })) : (_jsx(TextField, { id: "entry-value", labelText: "What is the cached value?", value: value, type: getInputType(valueType), onChange: onChange })) })));
}
