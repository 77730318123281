import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { affidavitUploadURLToFileName, eeditionUploadMethodToUploadMethodDescription } from 'lib/affidavits';
import { ColumnButton } from 'lib/components/ColumnButton';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Buddy } from 'lib/components/gifs';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { cdnIfy } from 'lib/helpers';
import React from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { PDFViewer } from 'lib/components/PDFViewer';
import moment from 'moment';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
function EmptyEditionPreview({ onUploadEEdition, publicationIssue }) {
    return (_jsxs("div", Object.assign({ className: "h-full flex flex-col items-center mt-24" }, { children: [_jsx("div", { children: _jsx("img", { className: "h-64", src: Buddy }) }), _jsx("div", Object.assign({ className: "text-xl text-column-primary-500" }, { children: "No e-edition" })), _jsxs("div", Object.assign({ className: "text-sm leading-6 text-column-gray-400 text-center" }, { children: ["Click on the button below to upload the e-edition for", _jsx("br", {}), moment(publicationIssue.modelData.publicationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')] })), _jsx("div", Object.assign({ className: "mt-4" }, { children: _jsx(ColumnButton, { buttonText: "Upload e-edition", onClick: () => {
                        onUploadEEdition(publicationIssue);
                    }, type: "button", primary: true }) }))] })));
}
export default function AutomatedAffidavitsTableDrawerEEditionsTab({ publicationIssue, onUploadEEditionClick }) {
    const eeditionQuerySnap = useFirestoreQueryListener(getFirebaseContext()
        .eeditionsRef()
        .where('publicationIssue', '==', publicationIssue.ref), [publicationIssue.id]);
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    if (eeditionQuerySnap === null) {
        return _jsx(LoadingSpinner, {});
    }
    if (eeditionQuerySnap === null || eeditionQuerySnap === void 0 ? void 0 : eeditionQuerySnap.empty) {
        return (_jsx(EmptyEditionPreview, { publicationIssue: publicationIssue, onUploadEEdition: onUploadEEditionClick }));
    }
    return (_jsx(_Fragment, { children: eeditionQuerySnap === null || eeditionQuerySnap === void 0 ? void 0 : eeditionQuerySnap.docs.map((eedition, idx) => (_jsx(PDFViewer, { startOpen: idx === 0, header: affidavitUploadURLToFileName(cdnIfy(eedition.data().storagePath, { useColumnCDN })), pdfUrl: cdnIfy(eedition.data().storagePath, {
                useImgix: true,
                useColumnCDN
            }), extraMetadata: _jsxs(_Fragment, { children: ["Uploaded", ' ', eedition.data().uploadedAt.toDate().toLocaleDateString(), ' ', eeditionUploadMethodToUploadMethodDescription(eedition.data().uploadMethod)] }) }, eedition.id))) }));
}
