var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { userIsSuper } from 'utils/permissions';
import { removeUndefinedFields } from 'lib/helpers';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { extractGlobalAffidavitControls } from './globalAffidavitControlHelpers';
import BasicSettingsCard from './BasicSettingsCard';
import ColumnManagedAffidavitsCard from './ColumnManagedAffidavitSettings';
import SuperUserSettingsCard from './SuperUserSettingsCard';
export default function GlobalControls({ activeOrganization, onClose, user }) {
    const [globalAffidavitControls, setGlobalAffidavitControls] = useState(extractGlobalAffidavitControls(activeOrganization));
    const edited = !columnObjectsAreEqual(globalAffidavitControls, extractGlobalAffidavitControls(activeOrganization));
    const onSave = () => __awaiter(this, void 0, void 0, function* () {
        const _a = removeUndefinedFields(globalAffidavitControls), { affidavitReconciliationSettings } = _a, otherProps = __rest(_a, ["affidavitReconciliationSettings"]);
        const organizationModel = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrganization);
        if (affidavitReconciliationSettings) {
            yield organizationModel.updateAutomatedAffidavitConfiguration(affidavitReconciliationSettings);
        }
        yield activeOrganization.ref.update(otherProps);
        onClose();
    });
    return (_jsxs(FullScreenModal, Object.assign({ submittable: {
            buttonText: 'Save',
            onSubmit: onSave,
            disabled: !edited
        }, headerText: "Edit Affidavit Settings", onClose: onClose, id: "affidavit-settings-global-controls" }, { children: [_jsx(BasicSettingsCard, { onChangeGlobalAffidavitControls: setGlobalAffidavitControls, globalAffidavitControls: globalAffidavitControls }), userIsSuper(user) && (_jsx(SuperUserSettingsCard, { onChangeGlobalAffidavitControls: setGlobalAffidavitControls, globalAffidavitControls: globalAffidavitControls })), _jsx(ColumnManagedAffidavitsCard, { onChangeGlobalAffidavitControls: setGlobalAffidavitControls, globalAffidavitControls: globalAffidavitControls })] })));
}
