import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ELAVON, PAYWAY, STRIPE } from 'lib/constants';
import StripeCardCheckoutForm from './checkoutForms/StripeCardCheckoutForm';
import { PaywayCardCheckoutForm } from './checkoutForms/PaywayCardCheckoutForm';
import { ElavonCardCheckoutForm } from './checkoutForms/ElavonCardCheckoutForm';
function PayInvoiceCreditCard({ payInvoiceData, invoicePricingData, disableSavedCards, enableAuthCapture, handleSuccessfulPayment }) {
    const { gateway } = payInvoiceData;
    return (_jsxs(_Fragment, { children: [gateway === STRIPE && (_jsx(StripeCardCheckoutForm, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, disableSavedCards: disableSavedCards, handleSuccessfulPayment: handleSuccessfulPayment, enableAuthCapture: enableAuthCapture })), gateway === PAYWAY && (_jsx(PaywayCardCheckoutForm, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, handleSuccessfulPayment: handleSuccessfulPayment })), gateway === ELAVON && (_jsx(ElavonCardCheckoutForm, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, handleSuccessfulPayment: handleSuccessfulPayment }))] }));
}
export default PayInvoiceCreditCard;
