import { logAndCaptureMessage } from 'utils';
const checkFilingTypeCompatibility = (selection, newspaperOrdersWithFilingTypes, newspaperOrdersFormData) => {
    const madlibConfigs = [];
    const allFilingTypesHaveTheSameLabel = newspaperOrdersFormData.every(no => {
        var _a, _b, _c;
        const { filingType } = no;
        if (!filingType) {
            return true;
        }
        const relevantNewspaperOrderFilingTypes = newspaperOrdersWithFilingTypes.find(({ newspaperOrder }) => {
            var _a, _b;
            return ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id) === ((_b = no.newspaper) === null || _b === void 0 ? void 0 : _b.id);
        });
        if (!relevantNewspaperOrderFilingTypes) {
            logAndCaptureMessage('No publishing settings found for newspaper order', {
                newspaperId: (_a = no.newspaper) === null || _a === void 0 ? void 0 : _a.id
            });
            return false;
        }
        const relevantFilingType = (_b = relevantNewspaperOrderFilingTypes.filingTypes) === null || _b === void 0 ? void 0 : _b.find(ft => ft.id === filingType.id);
        if (!relevantFilingType) {
            logAndCaptureMessage('Filing type not found in publishing settings', {
                filingTypeLabel: selection,
                newspaperId: (_c = no.newspaper) === null || _c === void 0 ? void 0 : _c.id
            });
            return false;
        }
        madlibConfigs.push(relevantFilingType.modelData.madlib);
        return relevantFilingType.modelData.label === selection;
    });
    const isSingleFilingTypeOrAllFreeform = madlibConfigs.length === 1 || madlibConfigs.every(mc => !mc);
    return {
        allFilingTypesHaveTheSameLabel,
        isSingleFilingTypeOrAllFreeform
    };
};
export const getValidationMessageForFilingTypeSelection = (selection, newspaperOrdersWithFilingTypes, newspaperOrdersFormData) => {
    if (!selection) {
        return null;
    }
    const selectionIsAvailableInAllPapers = newspaperOrdersWithFilingTypes.every(({ filingTypes }) => {
        return filingTypes === null || filingTypes === void 0 ? void 0 : filingTypes.some(filingType => filingType.modelData.label === selection);
    });
    if (!selectionIsAvailableInAllPapers) {
        return 'Category is not available in all selected newspapers';
    }
    const { allFilingTypesHaveTheSameLabel, isSingleFilingTypeOrAllFreeform } = checkFilingTypeCompatibility(selection, newspaperOrdersWithFilingTypes, newspaperOrdersFormData);
    if (!allFilingTypesHaveTheSameLabel) {
        return 'Please select the same category for all newspapers';
    }
    if (!isSingleFilingTypeOrAllFreeform) {
        return 'Ad templates for the selected category are not compatible across all newspapers';
    }
    return null;
};
