var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DetailsRow } from 'components/CustomerDrawer/DetailsRow';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { Badge } from 'lib/components/Badge';
import { formatDateString } from 'lib/utils/dates';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Alert } from 'lib/components/Alert';
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { newspaperOrderToPublicationCard } from './utils';
import { ConfirmationStatusSelector } from './ConfirmationStatusSelector';
export function PublicationDetails({ newspaperOrder, ad, editData }) {
    const { value: cardData, isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            return yield newspaperOrderToPublicationCard(newspaperOrder, ad.modelData.filingTypeName || '');
        }),
        dependencies: [
            newspaperOrder.id,
            newspaperOrder.modelData.status,
            newspaperOrder.modelData.publishingDates.join(''),
            ad.modelData.filingTypeName
        ]
    });
    const showEditableBanner = !!(editData === null || editData === void 0 ? void 0 : editData.bannerMessage);
    const getBannerProps = (editData) => {
        const { canEdit, isBeforeDeadline, bannerMessage: description } = editData;
        if (canEdit && isBeforeDeadline) {
            return {
                status: 'info',
                icon: _jsx(InformationCircleIcon, { className: "w-5 h-5" }),
                description
            };
        }
        const editingAfterDeadline = canEdit && !isBeforeDeadline;
        if (editingAfterDeadline) {
            return {
                status: 'warning',
                icon: _jsx(ExclamationTriangleIcon, { className: "w-5 h-5" }),
                description
            };
        }
        return {
            status: undefined,
            icon: undefined,
            description
        };
    };
    if (isLoading || !cardData) {
        return _jsx(LoadingSpinner, {});
    }
    return (_jsx("div", Object.assign({ className: "text-sm leading-6 font-medium w-full" }, { children: _jsxs(AdDetailsCard, Object.assign({ id: "Publication Details", header: {
                title: (_jsx("img", { src: cardData.logo, alt: cardData.publisherName, className: "max-w-32 max-h-32" })),
                actions: (_jsx(ConfirmationStatusSelector, { newspaperOrder: newspaperOrder }))
            } }, { children: [showEditableBanner && (_jsx(Alert, Object.assign({ id: "newspaper-order-editable-banner" }, getBannerProps(editData)))), _jsx(DetailsRow, { label: "State", value: cardData.state }), _jsx(DetailsRow, { label: "Publication Category", value: _jsx(Badge, Object.assign({ status: 'info', size: "md" }, { children: cardData.publicationCategory })) }), _jsx(DetailsRow, { label: "Ad Deadline", value: _jsx(Badge, Object.assign({ status: "warning", size: "md" }, { children: _jsx("div", Object.assign({ className: "whitespace-normal" }, { children: cardData.adDeadline })) })) }), _jsx(DetailsRow, { label: "Publication Dates", value: cardData.publicationSchedule.map(date => (_jsx(Badge, Object.assign({ status: "info", size: "md" }, { children: formatDateString(date, 'MMMM DD') }), date))) })] })) })));
}
