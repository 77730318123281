import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useMemo } from 'react';
const AdFormContext = createContext(undefined);
export function AdFormStatusProvider({ children }) {
    const [currentStep, setCurrentStep] = useState(1);
    const updateCurrentStep = (newCurrentStep) => {
        setCurrentStep(newCurrentStep);
    };
    const contextValue = useMemo(() => {
        return {
            currentStep,
            updateCurrentStep
        };
    }, [currentStep]);
    return (_jsx(AdFormContext.Provider, Object.assign({ value: contextValue }, { children: children })));
}
export const useAdForm = () => {
    const context = useContext(AdFormContext);
    if (!context) {
        throw new Error('useAdForm must be used within an AdFormStatusProvider');
    }
    return context;
};
