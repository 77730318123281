import { jsx as _jsx } from "react/jsx-runtime";
import { Day } from 'lib/enums';
import { SwitchControlledCards } from 'lib/components/Card/SwitchControlledCards';
import { DayRateElement } from './DayRateElement';
const DEFAULT_DAY_RATE = {
    rate: 1000,
    day: Day.sunday.value
};
export function DayRates({ values, initialValue, onChange }) {
    const validValues = values !== null && values !== void 0 ? values : [];
    const resetValue = initialValue !== null && initialValue !== void 0 ? initialValue : [DEFAULT_DAY_RATE];
    const remainingDays = Day.items().filter(({ value }) => !(values === null || values === void 0 ? void 0 : values.find(({ day }) => day === value)));
    return (_jsx(SwitchControlledCards, { labelProps: {
            label: `Enable day rates?`,
            description: 'If enabled, you can charge different rates for different days of the week.',
            value: !!validValues.length,
            onChange: val => onChange(val ? resetValue : null)
        }, cardProps: {
            values: validValues,
            onChange: changes => onChange(changes.length ? changes : null),
            baseHeader: 'DAY RATE',
            Component: DayRateElement,
            createable: remainingDays.length
                ? {
                    buttonText: 'Add another day rate',
                    initialValue: Object.assign(Object.assign({}, DEFAULT_DAY_RATE), { day: remainingDays[0].value })
                }
                : undefined,
            archivable: {
                confirmation: {
                    header: 'Remove Day Rate?',
                    body: 'Are you sure you want to remove this day rate?'
                },
                buttonText: 'Remove'
            }
        } }));
}
