import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { canCancelNoticeWithoutSupport } from 'lib/utils/deadlines';
import { TextField } from 'lib/components/TextField';
import { InvoiceStatus } from 'lib/enums';
import { getInvoiceAmountsBreakdown } from 'lib/pricing';
import { isPaidDirectToPublisher } from 'lib/utils/invoices';
import { CurrencyDollarIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ELAVON, PAYWAY } from 'lib/constants';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { getAmountOfRefundToRecoverFromPublisherInCents } from '../../../lib/payments/refunds';
export default function RefundInvoiceModalInnerActionBox({ invoice, isPublisher, gateway, isInvoicedOutsideColumn, refundReason, refundAmount, header, body, totalRefundedInCentsFromPriorInvoiceTransactions, setDisabled, setRefundAmount, setRefundReason, setIsFullRefund }) {
    var _a, _b, _c, _d;
    const { totalInCents, publisherAmountInCents, columnAmountInCents } = getInvoiceAmountsBreakdown(invoice);
    // The maximum amount a publisher can return to the advertiser
    const maxRefundAmount = (Math.min(totalInCents - ((_a = invoice.data().refund_amount) !== null && _a !== void 0 ? _a : 0), totalInCents - totalRefundedInCentsFromPriorInvoiceTransactions) / 100).toFixed(2);
    // The portion of the refund amount that comes from the publisher
    const [refundPublisherAmount, setRefundPublisherAmount] = useState('0.00');
    // The portion of the refund amount that comes from Column
    const [refundFeeAmount, setRefundFeeAmount] = useState('0.00');
    /* while in the CacncelNoticeModal, partial refund is disabled (in the context of cancelling a notice)
    ONLY for Elavon, here (since in APP-2220 we are allowing multiple partial refunds with or without cancellation)
    we should also disable it for Payway until we can confirm we have multiple partial refund capability for Payway */
    const partialRefundDisabled = gateway === ELAVON || gateway === PAYWAY;
    const canCancel = canCancelNoticeWithoutSupport(exists(invoice) ? invoice : null, isPublisher);
    const invoicePaidDirectToPublisher = !!invoice && isPaidDirectToPublisher(invoice);
    const invoicePaidByBulkInvoice = !!invoice.data().paidByBulkInvoice;
    // TODO (APP-16): currently this option is behind a LD flag; it should be made available app-wide
    const paymentInitiatedInNewRefundFlow = ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _b === void 0 ? void 0 : _b.status) === InvoiceStatus.initiated.value;
    // TODO (APP-16): simply change this to check whether invoice is paid or initiated
    const isInvoiceRefundable = [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes((_d = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _c === void 0 ? void 0 : _c.status) !== null && _d !== void 0 ? _d : 0) || paymentInitiatedInNewRefundFlow;
    const isRefund = !isInvoicedOutsideColumn &&
        (!invoicePaidDirectToPublisher || invoicePaidByBulkInvoice) &&
        canCancel &&
        isPublisher &&
        isInvoiceRefundable;
    const refundAmountExceedsMax = Number(refundAmount) > Number(maxRefundAmount);
    const nullRefundAmount = !refundAmount ||
        parseFloat(refundAmount) === 0 ||
        parseFloat(refundAmount) < 0;
    const handleTotalChange = (newRefundAmount) => {
        setRefundAmount(newRefundAmount);
    };
    useEffect(() => {
        var _a;
        if (!exists(invoice)) {
            return;
        }
        if (refundAmountExceedsMax || nullRefundAmount) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
        const refundAmountInCents = ((_a = Number(refundAmount)) !== null && _a !== void 0 ? _a : 0) * 100;
        const amountOfRefundToRecoverFromPublisherInCents = getAmountOfRefundToRecoverFromPublisherInCents(refundAmountInCents, totalInCents, publisherAmountInCents, columnAmountInCents, true // this modal should only appear when enablePartialRefundsV2 is true, so no need to retrieve the flag here
        );
        setRefundPublisherAmount((amountOfRefundToRecoverFromPublisherInCents / 100).toFixed(2));
        setRefundFeeAmount(((refundAmountInCents - amountOfRefundToRecoverFromPublisherInCents) /
            100).toFixed(2));
        setIsFullRefund(refundAmount === maxRefundAmount);
    }, [refundAmount]);
    return (_jsx(_Fragment, { children: isRefund && (_jsx("div", { children: _jsxs(CardGridLayout, Object.assign({ header: {
                    title: header,
                    description: body
                } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "refund-subtotal-amount", type: "currency", labelText: "Refund Amount *", placeholder: refundAmount, value: refundAmount, max: maxRefundAmount, min: 0, onChange: handleTotalChange, disabled: partialRefundDisabled, prefix: _jsx(CurrencyDollarIcon, { className: "w-6 h-6 text-column-gray-400" }), validationMessages: {
                                rangeOverflow: 'You cannot refund the advertiser for more than the subtotal of this notice.'
                            } }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: canCancel && (_jsx("div", Object.assign({ className: "w-full mt-3" }, { children: _jsx(TextField, { id: "refund-invoice-reason", labelText: "Reason for refund", placeholder: "Reason for refund", value: refundReason, onChange: setRefundReason, required: true, validationMessages: {
                                    valueMissing: 'Please specify a reason for refunding or voiding this invoice.'
                                } }) }))) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: !refundAmountExceedsMax && !nullRefundAmount && (_jsxs("div", Object.assign({ className: "flex flex-row items-center text-sm rounded-md align-middle min-h-11 w-full space-x-2 py-1.5 pr-3 mt-3 bg-column-green-50" }, { children: [_jsx(ExclamationCircleIcon, { className: "text-column-green-500 h-16 w-16 pl-3" }), _jsxs("div", Object.assign({ className: "text-column-green-500" }, { children: ["The advertiser will receive a total refund of $", Number(refundAmount).toFixed(2), " ($", refundPublisherAmount, " from the publisher and $", refundFeeAmount, " from Column)."] }))] }))) }))] })) })) }));
}
