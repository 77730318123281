var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NoticeType } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { PlacementTypes } from 'redux/placement';
import { getOrThrow } from 'lib/utils/refs';
import { supportsDisplay } from 'lib/notice/rates';
import { NoticeRateService } from 'lib/services/noticeRateService';
const chooseRate = (noticeType, userRates, orgRates) => {
    // make sure that rates associated with the notice type show up first
    const highestPriorityNoticeTypeOnRate = noticeType !== NoticeType.custom.value ? noticeType : null;
    const sortedOrgRates = orgRates
        ? orgRates.sort(a => a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1)
        : null;
    const sortedUserRates = userRates.sort(a => a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1);
    const orgCandidate = sortedOrgRates ? sortedOrgRates[0] : null;
    const userCandidate = sortedUserRates.length ? sortedUserRates[0] : null;
    if (orgCandidate && !userCandidate)
        return orgCandidate.ref;
    return userCandidate && userCandidate.ref;
};
/**
 * This function determines whether or not a particular rate can be
 * tied to an existing placement state.
 * @param rateSnapshot this is the rate that we are checking to see if it can run
 * @param placement the current redux state
 * @returns {boolean} true if we can use this rate, false if not
 */
export const rateCanPublishWithPlacementData = (rateSnapshot, placement) => {
    // first determine if we can use this rate based on whether or not
    // the notice is a display notice
    const isDisplayNotice = placement.noticeType === NoticeType.display_ad.value;
    const rateSupportsDisplay = supportsDisplay(rateSnapshot.data());
    if (isDisplayNotice && !rateSupportsDisplay) {
        return false;
    }
    return true;
};
/**
 * Order of precedence for setting rates:
 * 1. customer defaults
 * 2. user / user organization defaults (see chooseRate for priority ranking)
 * 3. newspaper defaults
 */
export const checkForDefault = (placement, newspaper, newNoticeType) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const noticeType = newNoticeType || placement.noticeType;
    const isDisplay = noticeType === NoticeType.display_ad.value;
    let defaultOrgRates;
    if (!placement.filer)
        return;
    if (!newspaper)
        return;
    const user = yield placement.filer.get();
    const { customer, customerOrganization } = placement;
    const customerOrganizationSnap = yield (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.get());
    if (((_a = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _a === void 0 ? void 0 : _a.defaultLinerRate) && !isDisplay) {
        return (_b = customerOrganizationSnap.data()) === null || _b === void 0 ? void 0 : _b.defaultLinerRate;
    }
    if (((_c = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data()) === null || _c === void 0 ? void 0 : _c.defaultDisplayRate) && isDisplay) {
        return (_d = customerOrganizationSnap.data()) === null || _d === void 0 ? void 0 : _d.defaultDisplayRate;
    }
    const customerSnap = yield (customer === null || customer === void 0 ? void 0 : customer.get());
    if (((_e = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _e === void 0 ? void 0 : _e.linerRate) && !isDisplay) {
        return (_f = customerSnap.data()) === null || _f === void 0 ? void 0 : _f.linerRate;
    }
    if (((_g = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _g === void 0 ? void 0 : _g.displayRate) && isDisplay) {
        return (_h = customerSnap.data()) === null || _h === void 0 ? void 0 : _h.displayRate;
    }
    const ctx = getFirebaseContext();
    const rateService = new NoticeRateService(ctx);
    // Rates that are assigned to the user's advertiser organization
    const userOrg = (_j = user.data()) === null || _j === void 0 ? void 0 : _j.activeOrganization;
    if (userOrg) {
        const orgRates = yield rateService.getAdvertiserOrgRates(newspaper.ref, userOrg);
        defaultOrgRates = orgRates.filter(r => rateCanPublishWithPlacementData(r, placement));
    }
    // Rates that are assigned to the user as an individual advertiser
    const userRates = yield rateService.getAdvertiserRates(newspaper.ref, user.ref);
    const defaultUserRates = userRates.filter(r => rateCanPublishWithPlacementData(r, placement));
    const d = chooseRate(noticeType, defaultUserRates, defaultOrgRates !== null && defaultOrgRates !== void 0 ? defaultOrgRates : []);
    if (d) {
        return d;
    }
    const defaultRate = isDisplay
        ? newspaper.data().defaultDisplayRate
        : newspaper.data().defaultLinerRate;
    if (rateCanPublishWithPlacementData(yield getOrThrow(defaultRate), placement)) {
        return defaultRate;
    }
    const rateSnap = defaultOrgRates === null || defaultOrgRates === void 0 ? void 0 : defaultOrgRates[0];
    if (!rateSnap) {
        throw new Error('Could not find rate for organization');
    }
    return rateSnap.ref;
});
/**
 * In the editing or duplication flows, there are some instances where we explicitly do not want to update the rate
 */
export const getShouldUpdateRateInEditOrDuplicationFlow = (action, placementData, draftSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _k, _l, _m;
    const { noticeType, previousNoticeType, rate, newspaper } = placementData;
    const oldNoticeType = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data().noticeType;
    const oldPreviousNoticeType = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data().previousNoticeType;
    // If the rate does not apply to the selected newspaper, then we should update the rate
    const rateSnap = rate ? yield rate.get() : null;
    const rateIsAvailableOnNewspaper = ((_l = (_k = rateSnap === null || rateSnap === void 0 ? void 0 : rateSnap.data()) === null || _k === void 0 ? void 0 : _k.organization) === null || _l === void 0 ? void 0 : _l.id) === (newspaper === null || newspaper === void 0 ? void 0 : newspaper.id) ||
        (((_m = rateSnap === null || rateSnap === void 0 ? void 0 : rateSnap.data()) === null || _m === void 0 ? void 0 : _m.publisherOrganizations) || []).some(orgRef => orgRef.id === (newspaper === null || newspaper === void 0 ? void 0 : newspaper.id));
    if (!rateIsAvailableOnNewspaper) {
        return true;
    }
    if (![
        PlacementTypes.SET_NOTICE_TYPE,
        PlacementTypes.SET_PREVIOUS_NOTICE_TYPE,
        PlacementTypes.SET_NEWSPAPER
    ].includes(action.type)) {
        return false;
    }
    if (action.type === PlacementTypes.SET_NOTICE_TYPE &&
        oldNoticeType === noticeType) {
        return false;
    }
    if (action.type === PlacementTypes.SET_PREVIOUS_NOTICE_TYPE &&
        oldPreviousNoticeType === previousNoticeType) {
        return false;
    }
    return true;
});
