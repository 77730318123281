var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureCriticalError, logAndCaptureException } from 'utils';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { getFirebaseContext } from 'utils/firebase';
import FullScreenModal from 'components/FullScreenModal';
import { exists } from 'lib/types';
import { TemplateTypes } from 'lib/types/templates';
import { cdnIfy, isColumnUser, removeUndefinedFields, replaceUndefinedWithDelete } from 'lib/helpers';
import { PublisherOrgSharingCard } from 'routes/settings/publisher/sharing/PublisherOrgSharingCard';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { ColumnService } from 'lib/services/directory';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import TemplateFileUpload from './TemplateFileUpload';
import TemplateOverviewCard from './TemplateOverviewCard';
import { extractTemplateConfigurationFromActiveOrganization } from './templateSettingsUpdateFormUtils';
import { getTemplateStylesFromURL } from '../../templateSettingsUtils';
import TemplateBulkDownloadUpdateCard from './TemplateBulkDownloadUpdateCard';
import { TemplateStyles } from '../shared/TemplateStyles';
/**
 * Form component to update the configuration settings of a newspaper pagination template
 */
export default function TemplateUpdateForm({ activeOrganization, templateRef, template, isNoticeProduct, onClose }) {
    var _a;
    const user = useAppSelector(selectUser);
    const [updatedTemplateConfiguration, setUpdatedTemplateConfiguration] = useState(extractTemplateConfigurationFromActiveOrganization(activeOrganization, templateRef, template));
    const [enableBulkDownload, setEnableBulkDownload] = useState(!!updatedTemplateConfiguration.bulkDownloadStoragePath);
    const [templateStyles, setTemplateStyles] = useState();
    const [saving, setSaving] = useState(false);
    /**
     * Loads template styles from the template's download URL
     */
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            const { downloadUrl } = updatedTemplateConfiguration;
            try {
                setTemplateStyles(yield getTemplateStylesFromURL(downloadUrl));
            }
            catch (err) {
                logAndCaptureException(ColumnService.INDESIGN, err, 'Error loading template styles', {
                    organizationId: activeOrganization.id,
                    downloadUrl
                });
            }
        }))();
    }, [updatedTemplateConfiguration.downloadUrl]);
    const updateTemplateProperties = () => __awaiter(this, void 0, void 0, function* () {
        var _b;
        if (!templateStyles)
            return;
        setSaving(true);
        const { publisherOrganizations, bulkDownloadStoragePath, downloadUrl } = updatedTemplateConfiguration;
        const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
        const templateUpdate = {
            name: updatedTemplateConfiguration.name,
            type: TemplateTypes.legal,
            downloadUrl,
            publisherOrganizations,
            columnsWide: 1,
            fullPageTemplate: bulkDownloadStoragePath
                ? cdnIfy(bulkDownloadStoragePath, { useColumnCDN })
                : undefined,
            styles: templateStyles
        };
        let editedRef;
        if (templateRef) {
            editedRef = templateRef;
            const existingTemplateSnapshot = yield templateRef.get();
            if (!((_b = existingTemplateSnapshot.data()) === null || _b === void 0 ? void 0 : _b.organization)) {
                templateUpdate.organization = activeOrganization.ref;
                logAndCaptureCriticalError(ColumnService.PAGINATION, new Error('Template missing organization'), 'The organization ref was not properly set up on obits template - No action required, this has been fixed', {
                    organizationId: activeOrganization.id,
                    templateId: templateRef.id
                });
            }
            yield templateRef.update(replaceUndefinedWithDelete(getFirebaseContext(), templateUpdate));
        }
        else {
            templateUpdate.organization = activeOrganization.ref;
            editedRef = yield getFirebaseContext()
                .adTemplatesRef()
                .add(removeUndefinedFields(templateUpdate));
        }
        if (updatedTemplateConfiguration.useAsDefaultTemplate) {
            yield activeOrganization.ref.update({
                adTemplate: editedRef
            });
        }
        setSaving(false);
        onClose();
    });
    const editedTemplateConfig = !columnObjectsAreEqual(updatedTemplateConfiguration, extractTemplateConfigurationFromActiveOrganization(activeOrganization, templateRef, template));
    const edited = editedTemplateConfig;
    const invalidBulkDownload = enableBulkDownload && !updatedTemplateConfiguration.bulkDownloadStoragePath;
    const saveDisabled = saving ||
        invalidBulkDownload ||
        (!!templateRef && (!templateStyles || !edited));
    return (_jsxs(FullScreenModal, Object.assign({ headerText: `${templateRef ? 'Edit' : 'Create'} Template`, submittable: {
            buttonText: 'Save',
            onSubmit: updateTemplateProperties,
            disabled: saveDisabled
        }, onClose: onClose, id: "template-settings-update-form", previewable: isNoticeProduct
            ? {
                header: {
                    title: 'Design Settings',
                    description: 'Review the styles in the IDML for this template.'
                },
                renderPreview: () => (_jsx(TemplateStyles, { templateStyles: templateStyles })),
                withBorder: true
            }
            : undefined }, { children: [_jsx(TemplateOverviewCard, { onChange: overviewDetails => setUpdatedTemplateConfiguration(Object.assign(Object.assign({}, updatedTemplateConfiguration), overviewDetails)), isNoticeProduct: isNoticeProduct, value: updatedTemplateConfiguration }), exists(user) && isColumnUser(user) && isNoticeProduct && (_jsx(PublisherOrgSharingCard, { resourceNoun: "template", value: (_a = updatedTemplateConfiguration.publisherOrganizations) !== null && _a !== void 0 ? _a : [], owner: template.organization, onChange: value => {
                    setUpdatedTemplateConfiguration(Object.assign(Object.assign({}, updatedTemplateConfiguration), { publisherOrganizations: value }));
                } })), _jsx(TemplateFileUpload, { onChange: downloadUrl => setUpdatedTemplateConfiguration(Object.assign(Object.assign({}, updatedTemplateConfiguration), { downloadUrl })), value: updatedTemplateConfiguration.downloadUrl, isNoticeProduct: isNoticeProduct }), isNoticeProduct && activeOrganization.data().fullPageTemplate && (_jsx(TemplateBulkDownloadUpdateCard, { onChange: ({ bulkDownloadStoragePath, enableBulkDownload }) => {
                    setUpdatedTemplateConfiguration(Object.assign(Object.assign({}, updatedTemplateConfiguration), { bulkDownloadStoragePath }));
                    setEnableBulkDownload(enableBulkDownload);
                }, value: {
                    bulkDownloadStoragePath: updatedTemplateConfiguration.bulkDownloadStoragePath,
                    enableBulkDownload
                } })), _jsx("div", { className: "pb-20 w-full" })] })));
}
