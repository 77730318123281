var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { sanitize } from 'lib/helpers';
import { getVerifiedExtensionFromFileName } from 'lib/types/mime';
const getDocumentProperties = (fileRef, fileType) => __awaiter(void 0, void 0, void 0, function* () {
    const sanitizedName = sanitize(fileRef.name);
    const { verifiedExtension: originalFormat } = getVerifiedExtensionFromFileName(sanitizedName);
    const filePath = fileRef.fullPath;
    const resp = yield api.post('documents/properties', {
        filePath,
        fileType,
        fileFormat: originalFormat
    });
    if (!resp.success) {
        throw new Error('API call to documents/properties returned false');
    }
    return resp.properties;
});
export default getDocumentProperties;
