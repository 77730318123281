var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Collections } from '../constants';
/* TODO: I'm currently setting the max number of mail documents per recipient at 5.
This should already be handled elsewhere when mail refs are being created, so the below is an extra check for additional safety.
There is currently no easy way to get more granular about limiting types of mails (i.e., by mail type instead of recipient),
but this could be introduced in a later change */
const MAX_MAILS_PER_NAME_PER_DRAFT = 5;
/**
 * Delete all mail documents on a draft and replace them with new ones.
 */
export const refreshDraftMail = (draft, newMails) => __awaiter(void 0, void 0, void 0, function* () {
    const mailRef = draft.collection(Collections.mail);
    const mailSnap = yield mailRef.get();
    const oldMails = mailSnap.docs;
    if (oldMails && oldMails.length) {
        for (const oldMailSnap of oldMails) {
            yield oldMailSnap.ref.delete();
        }
    }
    const mailsPerName = {};
    for (let i = 0; i < newMails.length; i++) {
        const countForName = mailsPerName[newMails[i].name] || 0;
        if (countForName >= MAX_MAILS_PER_NAME_PER_DRAFT) {
            continue;
        }
        yield mailRef.add(newMails[i]);
        mailsPerName[newMails[i].name] = countForName + 1;
    }
});
export const addDraftMailToNotice = (draftRef, noticeRef) => __awaiter(void 0, void 0, void 0, function* () {
    const draftMailCollectionRef = draftRef.collection(Collections.mail);
    const draftMailCollectionSnap = yield draftMailCollectionRef.get();
    const noticeMailCollectionRef = noticeRef.collection(Collections.mail);
    const noticeMailCollectionSnap = yield noticeMailCollectionRef.get();
    if (noticeMailCollectionSnap.docs) {
        for (const doc of noticeMailCollectionSnap.docs) {
            const mRef = doc.ref;
            yield mRef.delete();
        }
    }
    if (draftMailCollectionSnap.docs) {
        for (const doc of draftMailCollectionSnap.docs) {
            yield noticeMailCollectionRef.add(doc.data());
            const mRef = doc.ref;
            yield mRef.delete();
        }
    }
});
export const getNoticeMail = (noticeOrDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const mailCollectionRef = noticeOrDraftRef.collection(Collections.mail);
    const mailCollectionSnap = yield mailCollectionRef.get();
    return mailCollectionSnap.docs;
});
export const getNoticeMailAndSetOnDraft = (noticeRef, draftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const draftMailCollectionRef = draftRef.collection(Collections.mail);
    const noticeMails = yield getNoticeMail(noticeRef);
    const mailArray = [];
    for (const mailSnap of noticeMails) {
        yield draftMailCollectionRef.add(mailSnap.data());
        mailArray.push(mailSnap.data());
    }
    return mailArray;
});
export const getMailDataFromNoticeOrDraft = (noticeOrDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const mails = yield getNoticeMail(noticeOrDraftRef);
    return mails.map(m => m.data());
});
