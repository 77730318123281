import { isNoticeRate, isPercentAdditionalFee } from 'lib/types/rates';
import { RateType } from 'lib/enums';
const CHARGE_METHODS = ['perRun', 'once', 'perMailedAffidavit'];
export const isValidChargeMethod = (key) => {
    return CHARGE_METHODS.includes(key);
};
export const getAdditionalFeeChargeMethod = (additionalFee) => {
    if (isPercentAdditionalFee(additionalFee)) {
        return 'once';
    }
    if (additionalFee.perRun) {
        return 'perRun';
    }
    if (additionalFee.perAffidavitFee) {
        return 'perMailedAffidavit';
    }
    return 'once';
};
const getAdditionalFeeChargeMethodDescription = (chargeMethod) => {
    if (chargeMethod === 'perRun') {
        return 'Charge fee per run';
    }
    if (chargeMethod === 'perMailedAffidavit') {
        return 'Charge fee per mailed affidavit';
    }
    return 'Charge fee once';
};
export const getAdditionalFeeTypeDescription = (feeType) => {
    if (feeType === 'flat') {
        return 'Flat fee';
    }
    if (feeType === 'percent') {
        return 'Percentage of subtotal';
    }
    return 'Flat fee';
};
export const getAdditionalFeeChargeMethodSelectOption = (chargeMethod) => ({
    value: chargeMethod,
    label: getAdditionalFeeChargeMethodDescription(chargeMethod)
});
export const getAdditionalFeeTypeSelectionOption = (feeType) => ({
    value: feeType,
    label: getAdditionalFeeTypeDescription(feeType)
});
export const getAdditionalFeeChargeMethodDescriptionFromAdditionalFee = (additionalFee) => {
    return getAdditionalFeeChargeMethodDescription(getAdditionalFeeChargeMethod(additionalFee));
};
/**
 * For special rates, like the Oklahoma statutory rate, the pricing details
 * are hard-coded and should not be shown in the UI for editing.
 *
 * This helper is used in the New/ Edit Rate UI
 */
export const hideRatePricingDetailsWhenEditing = (rate) => {
    return rate.rateType === RateType.oklahoma.value;
};
/**
 * This flags some UI elements in the rates table
 *
 * At the moment, this shows/hides the per-run pricing as applicable
 * (which it isn't for the OK or IA rates)
 */
export const hidePricingDetailsInRatesTable = (rate) => {
    return (hideRatePricingDetailsWhenEditing(rate) ||
        rate.rateType === RateType.iowa_form.value);
};
/**
 * Some rate types, like the Iowa form, should not show run-specific pricing details as they do not
 * support this pricing configuration
 */
export const hideRunPricingDetails = (rate) => {
    return rate.rateType === RateType.iowa_form.value;
};
export const showLinesPerInchRatio = (rate) => {
    return (rate.rateType === RateType.oklahoma.value ||
        /**
         * Iowa form rates are usually meant to apply for both liners and displays,
         * but in the case only liner is selected,
         * hide the option to enter the line/inch ratio
         */
        (rate.rateType === RateType.iowa_form.value &&
            isNoticeRate(rate) &&
            rate.supportsDisplay) ||
        rate.rateType === RateType.line.value);
};
