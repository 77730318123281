var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { RaisedHand, Lock } from 'emojis';
import { EyeIcon, EyeSlashIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import copy from 'copy-to-clipboard';
import { ColumnButton } from 'lib/components/ColumnButton';
import { PasswordField } from 'components/PasswordField';
import { useAppSelector } from 'redux/hooks';
import { selectTemporaryPassword } from 'redux/auth';
import Firebase, { FirebaseAuth } from 'EnoticeFirebase';
import { passwordValidation } from 'lib/passwordValidators';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { ColumnService } from 'lib/services/directory';
import { ModalHeader } from './ModalHeader';
export function SaveOrResetPassword({ user, onClosed, onBack, onResetPasswordConfirmation }) {
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [passwordValidationError, setPasswordValidationError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const temporaryPassword = useAppSelector(selectTemporaryPassword) || '';
    const onSubmittingResetPassword = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const { currentUser } = Firebase.auth();
        if (!currentUser || !currentUser.email) {
            return;
        }
        try {
            const credentials = FirebaseAuth.EmailAuthProvider.credential(currentUser.email, temporaryPassword);
            const getFreshCredentials = yield currentUser.reauthenticateWithCredential(credentials);
            if (getFreshCredentials.user) {
                yield getFreshCredentials.user.updatePassword(newPassword);
                yield api.post('users/update-temporary-password-status', {
                    email: currentUser.email
                });
                setLoading(false);
                setShowResetPasswordModal(false);
                onResetPasswordConfirmation();
            }
        }
        catch (ex) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, ex, 'Failed to authenticate firebase user in anonymous password reset flow.', { email: currentUser.email });
        }
    });
    const matchPassword = () => {
        if (!newPassword && !confirmNewPassword)
            setDisabled(true);
        if (newPassword &&
            (newPassword.length < 9 || !passwordValidation.test(newPassword))) {
            setPasswordValidationError('Password must be at least 9 characters and must contain a number or a symbol.');
            setDisabled(true);
            return;
        }
        if (passwordValidation.test(newPassword))
            setPasswordValidationError('');
        if (!newPassword && !confirmNewPassword)
            return false;
        if (newPassword !== confirmNewPassword) {
            setError('Passwords must match.');
            setDisabled(true);
        }
        else {
            setError('');
            setDisabled(false);
        }
    };
    useEffect(() => {
        matchPassword();
    }, [confirmNewPassword, newPassword]);
    const closeResetPasswordModal = () => {
        setShowResetPasswordModal(false);
        setNewPassword('');
        setConfirmNewPassword('');
    };
    return (_jsx(_Fragment, { children: _jsxs(CancelOrSubmitModal, Object.assign({ primaryButtonText: showResetPasswordModal ? 'Save' : 'Done', tertiaryButtonText: "Back", noExitOutsideModal: true, showLoadingSpinner: loading, onClose: onClosed, disablePrimaryButton: showResetPasswordModal && disabled, onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                showResetPasswordModal
                    ? yield onSubmittingResetPassword()
                    : onClosed();
            }), overrideTertiaryClose: () => {
                showResetPasswordModal ? closeResetPasswordModal() : onBack();
            } }, { children: [_jsx(ModalHeader, { id: "save-reset-password", headerText: showResetPasswordModal ? (_jsxs(_Fragment, { children: ["Reset password ", _jsx(Lock, {})] })) : (_jsxs(_Fragment, { children: ["One last thing ", _jsx(RaisedHand, {})] })) }), _jsxs("div", Object.assign({ className: "mb-12 mx-4", id: "password-reset-save-modal-body" }, { children: [_jsx("p", Object.assign({ className: "mb-6 text-column-gray-400 text-sm font-medium text-center" }, { children: showResetPasswordModal ? (_jsx(_Fragment, { children: "Choose a password you\u2019ll remember. You can login later to view your notices, invoices, and affidavits in Column." })) : (_jsxs(_Fragment, { children: ["We\u2019ve created a temporary password for your account -", ' ', _jsx("span", Object.assign({ className: "font-semibold text-column-gray-500" }, { children: user.data().email })), ". Save it to use next time you login, or reset your password now."] })) })), showResetPasswordModal ? (_jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx(PasswordField, { id: "new-password", value: newPassword, labelText: "New password", placeHolderText: "\u2022\u2022\u2022\u2022\u2022\u2022", errorText: passwordValidationError, onValueChange: (value) => setNewPassword(value) }), _jsx(PasswordField, { id: "confirm-new-password", value: confirmNewPassword, labelText: "Confirm password", placeHolderText: "\u2022\u2022\u2022\u2022\u2022\u2022", errorText: error, onValueChange: (value) => {
                                        setConfirmNewPassword(value);
                                    } })] }))) : (_jsx(ResetOrSavePasswordBody, { password: temporaryPassword, onResetPasswordClick: () => setShowResetPasswordModal(true) }))] }))] })) }));
}
function IconedCircle({ icon, handleClick }) {
    return (_jsx("div", Object.assign({ className: `h-10 w-10 rounded-full bg-column-primary-50 hover:bg-column-primary-100 text-column-primary-500 flex justify-center items-center cursor-pointer`, onClick: handleClick }, { children: icon })));
}
function ResetOrSavePasswordBody({ password, onResetPasswordClick }) {
    const [showPassword, setShowPassword] = useState(false);
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    }, [copied]);
    return (_jsxs(_Fragment, { children: [copied ? (_jsx("span", Object.assign({ className: "h-3 mb-2 flex justify-end text-column-gray-500 font-medium text-sm" }, { children: "Password copied!" }))) : (_jsx("p", { className: "h-3 mb-2" })), _jsxs("div", Object.assign({ className: "flex justify-between items-center border border-column-gray-100 rounded-md px-4 py-3 mb-4" }, { children: [_jsxs("div", { children: [_jsx("input", { id: "anonymous-user-temporary-password", type: showPassword ? 'text' : 'password', value: password, disabled: true, className: "appearance-none text-sm font-semibold text-column-gray-500 bg-white" }), _jsx("p", Object.assign({ className: "font-medium text-xs text-column-gray-400" }, { children: "Keep your password safe" }))] }), _jsxs("div", Object.assign({ className: "flex space-x-4" }, { children: [_jsx(IconedCircle, { icon: showPassword ? (_jsx(EyeIcon, { className: "h-5 w-5" })) : (_jsx(EyeSlashIcon, { className: "h-5 w-5" })), handleClick: () => {
                                    setShowPassword(!showPassword);
                                } }), _jsx(IconedCircle, { icon: _jsx(DocumentDuplicateIcon, { className: "h-5 w-5" }), handleClick: () => {
                                    copy(password);
                                    setCopied(true);
                                } })] }))] })), _jsx(ColumnButton, { secondary: true, fullWidth: true, buttonText: 'Reset your password', size: "lg", onClick: onResetPasswordClick, type: "button" })] }));
}
