var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { PopoverContext } from 'lib/components/Popover';
import { logAndCaptureException } from 'utils';
import { getUserByEmail } from 'utils/users';
import UserIDCard from 'lib/components/UserIDCard';
import { ColumnService } from 'lib/services/directory';
const getAdditionalInfoStringForCustomer = (searchableCustomer) => {
    const { internalid, userorganizationname } = searchableCustomer;
    if (internalid && internalid) {
        return `${userorganizationname} | #${internalid}`;
    }
    if (internalid) {
        return `#${internalid}`;
    }
    return userorganizationname || '';
};
export default function CustomerSearchOption({ searchableCustomer, index, onSelect, setUserTypedString }) {
    const { setOpen } = useContext(PopoverContext);
    const handleClick = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const user = yield getUserByEmail(searchableCustomer.useremail);
            if (!user) {
                throw new Error('Could not find customer by email');
            }
            onSelect(user);
            setUserTypedString('');
            setOpen(false);
        }
        catch (err) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Elastic customer does not exist in Firestore', {
                emailFromElastic: searchableCustomer.useremail,
                customerIdFromElastic: searchableCustomer.id
            });
        }
    });
    return (_jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center cursor-pointer", onClick: handleClick }, { children: [_jsx(UserIDCard, { name: searchableCustomer.username, email: searchableCustomer.useremail, index: index }), _jsx("div", Object.assign({ className: "text-column-gray-300 text-sm" }, { children: getAdditionalInfoStringForCustomer(searchableCustomer) }))] })));
}
