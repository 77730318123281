import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { Tooltip } from '../Tooltip';
/**
 * Wrapper around table icon components to handle click events.
 */
function ClickableIconWrapper({ disabled, loading, tooltip, onClick, icon, id }) {
    const innerButtonContent = tooltip ? (_jsx(Tooltip, Object.assign({ helpText: tooltip }, { children: icon }))) : (icon);
    return (_jsx("button", Object.assign({ id: id, className: classNames('p-3 rounded-md', {
            'hover:bg-column-gray-50': !disabled
        }, {
            'cursor-not-allowed': disabled && !tooltip
        }, {
            'cursor-default': disabled && tooltip
        }, { 'cursor-wait': loading }), disabled: disabled, onClick: e => {
            e.stopPropagation();
            onClick();
        } }, { children: innerButtonContent })));
}
export default ClickableIconWrapper;
