import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import classNames from 'classnames';
import { ColumnSelectItem } from 'lib/components/ColumnSelect/ColumnSelectItem';
import { PopoverContext } from 'lib/components/Popover';
function Option({ option, index, selectOption, setOpen, selectedOptions, groupIndex, highlightedIndex, showCheckBoxForSelectedItems }) {
    const selected = !!(selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions.map(o => o.value).includes(option.value));
    return (_jsx("li", Object.assign({ role: "option", "aria-selected": selected, onMouseDown: () => {
            selectOption(option);
            setOpen(false);
        } }, { children: _jsx(ColumnSelectItem, { option: option, active: groupIndex === undefined ? index === highlightedIndex : false, selected: selected, showCheckBox: showCheckBoxForSelectedItems }) }), option.value));
}
function GroupedOptions({ optionsGroups, selectOption, setOpen, selectedOptions, highlightedIndex, showCheckBoxForSelectedItems }) {
    if (!(optionsGroups === null || optionsGroups === void 0 ? void 0 : optionsGroups.length)) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(_Fragment, { children: optionsGroups.map((group, groupIndex) => (_jsxs("div", { children: [_jsx("div", Object.assign({ className: classNames('font-semibold ml-1', {
                        'mt-8': groupIndex !== 0,
                        'mt-2': groupIndex === 0
                    }) }, { children: group.title })), group.options.map((option, index) => (_jsx(Option, Object.assign({}, {
                    selectedOptions,
                    highlightedIndex,
                    optionsGroups,
                    showCheckBoxForSelectedItems,
                    selectOption,
                    option,
                    index,
                    groupIndex,
                    setOpen
                }), index)))] }, `group_${groupIndex}`))) }));
}
export function AutocompletePanel({ loading, filteredOptions, selectedOptions, highlightedIndex, optionsGroups, showCheckBoxForSelectedItems, selectOption }) {
    const { setOpen } = useContext(PopoverContext);
    const renderOptionsPanel = () => {
        if (loading || filteredOptions.length === 0) {
            return _jsx(_Fragment, {});
        }
        if (optionsGroups && optionsGroups.length > 0) {
            return (_jsx(GroupedOptions, Object.assign({}, {
                selectedOptions,
                highlightedIndex,
                optionsGroups,
                showCheckBoxForSelectedItems,
                selectOption,
                setOpen
            })));
        }
        return filteredOptions.map((option, index) => (_jsx(Option, Object.assign({}, {
            selectedOptions,
            highlightedIndex,
            optionsGroups,
            showCheckBoxForSelectedItems,
            selectOption,
            option,
            index,
            setOpen
        }), index)));
    };
    return (_jsxs("ul", Object.assign({ className: "text-sm p-2 max-h-64 overflow-y-scroll" }, { children: [(filteredOptions.length === 0 || loading) && (_jsx("div", Object.assign({ className: "text-center py-2 text-column-gray-600" }, { children: loading ? 'Loading...' : 'No options' }))), renderOptionsPanel()] })));
}
