import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { ColumnButton } from '../ColumnButton';
export function EditableCardWrapper({ Component, archivable, header, index, onChange, value, background = 'gray', disabled }) {
    return (_jsxs("div", Object.assign({ className: classNames('p-6 border border-column-gray-100 rounded-md', {
            'bg-column-gray-25': background === 'gray',
            'bg-white': background === 'white'
        }) }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between pb-4" }, { children: [_jsx("p", Object.assign({ className: "text-column-gray-400 text-sm uppercase font-medium" }, { children: header })), archivable && (_jsx(ColumnButton, { buttonText: archivable.buttonText, startIcon: _jsx(TrashIcon, { className: "w-5 h-5" }), destructive: true, link: true, secondary: true, type: "button", onClick: () => archivable.onArchive(value), disabled: disabled }))] })), _jsx("div", Object.assign({ className: "grid grid-cols-2 gap-4" }, { children: _jsx(Component, { value: value, onChange: onChange, index: index, disabled: disabled }) }))] })));
}
