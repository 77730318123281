import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { ACTIVE_NOTICES_TAB, ARCHIVED_NOTICES_TAB, DRAFT_NOTICES_TAB } from './types';
function TabButton({ onClick, active, label, id }) {
    return (_jsx("button", Object.assign({ onClick: () => onClick(), id: id, className: classNames('text-lg px-3 focus:outline-none font-medium h-18', {
            'text-column-primary-500 border-b-2 border-column-primary-500': active
        }, {
            'text-column-gray-500 hover:text-column-primary-500': !active
        }) }, { children: label })));
}
export default function TableHeaderTabs({ onSelectTab, activeTab }) {
    return (_jsxs("div", Object.assign({ className: "inline-flex" }, { children: [_jsx(TabButton, { onClick: () => onSelectTab(ACTIVE_NOTICES_TAB), active: activeTab === ACTIVE_NOTICES_TAB, label: 'Active Notices', id: ACTIVE_NOTICES_TAB }), _jsx(TabButton, { onClick: () => onSelectTab(ARCHIVED_NOTICES_TAB), active: activeTab === ARCHIVED_NOTICES_TAB, label: 'Archived Notices', id: ARCHIVED_NOTICES_TAB }), _jsx(TabButton, { onClick: () => onSelectTab(DRAFT_NOTICES_TAB), active: activeTab === DRAFT_NOTICES_TAB, label: _jsx("div", { children: _jsx("span", { children: "Draft Notices" }) }), id: DRAFT_NOTICES_TAB })] })));
}
