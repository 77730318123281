import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import React from 'react';
import { capitalizeWord } from 'lib/utils/strings';
import { SHOW_ALL_OPTION_VALUE } from './helpers';
export function FilingTypeFilter({ publicationCategories, editedRowFilter, onSelectNewFilterValue }) {
    return (_jsx(ColumnSelect, { id: "filing-type", labelText: "Category", size: "small", options: [
            {
                value: SHOW_ALL_OPTION_VALUE,
                label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
            }
        ].concat(publicationCategories.map(category => ({
            value: category,
            label: capitalizeWord(category)
        }))), value: editedRowFilter.filingType, onChange: onSelectNewFilterValue }));
}
