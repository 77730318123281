var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FullScreenModal from 'components/FullScreenModal';
import { exists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import React, { useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
const DEFAULT_CONFIGURATION = {
    requireLastNameOnFilers: false,
    requireAddressOnFilers: false,
    requirePhoneOnFilers: false,
    advertiserEditingEnabled: true
};
const getInitialState = (activeOrganization) => {
    if (!exists(activeOrganization)) {
        return DEFAULT_CONFIGURATION;
    }
    const { requireLastNameOnFilers, requireAddressOnFilers, requirePhoneOnFilers, advertiserEditingEnabled } = activeOrganization.data();
    const initialState = Object.assign(Object.assign({}, DEFAULT_CONFIGURATION), { advertiserEditingEnabled: !!advertiserEditingEnabled });
    if (requireAddressOnFilers !== undefined) {
        initialState.requireAddressOnFilers = requireAddressOnFilers;
    }
    if (requirePhoneOnFilers !== undefined) {
        initialState.requirePhoneOnFilers = requirePhoneOnFilers;
    }
    if (requireLastNameOnFilers !== undefined) {
        initialState.requireLastNameOnFilers = requireLastNameOnFilers;
    }
    return initialState;
};
export default function GlobalCustomerSettings({ onClose }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const initialState = getInitialState(activeOrganization);
    const [configuration, setConfiguration] = useState(initialState);
    const [saving, setSaving] = useState(false);
    const edited = !columnObjectsAreEqual(initialState, configuration);
    if (!exists(activeOrganization)) {
        return null;
    }
    return (_jsx(FullScreenModal, Object.assign({ headerText: "Edit Customer Settings", onClose: onClose, submittable: {
            buttonText: 'Save',
            onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                setSaving(true);
                yield activeOrganization.ref.update(configuration);
                setSaving(false);
                onClose();
            }),
            disabled: saving || !edited
        }, id: '' }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'Customer Settings',
                description: 'Configure general settings for customer management.'
            } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require last name from customers?", description: "If enabled, legal reps and customers will be required to provide last names when placing notices.", value: configuration.requireLastNameOnFilers, onChange: requireLastNameOnFilers => setConfiguration(Object.assign(Object.assign({}, configuration), { requireLastNameOnFilers })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require address from customers?", description: "If enabled, legal reps and customers will be required to provide mailing addresses when placing notices.", value: configuration.requireAddressOnFilers, onChange: requireAddressOnFilers => setConfiguration(Object.assign(Object.assign({}, configuration), { requireAddressOnFilers })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require phone numbers from customers?", description: "If enabled, legal reps and customers will be required to provide phone numbers when placing notices.", value: configuration.requirePhoneOnFilers, onChange: requirePhoneOnFilers => setConfiguration(Object.assign(Object.assign({}, configuration), { requirePhoneOnFilers })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow customers to edit notices?", description: "If enabled, customers can edit notices before ad deadline and invoice creation.", value: configuration.advertiserEditingEnabled, onChange: advertiserEditingEnabled => setConfiguration(Object.assign(Object.assign({}, configuration), { advertiserEditingEnabled })) }) }))] })) })));
}
