import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CheckIcon, ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { AffidavitStatusType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { Badge } from 'lib/components/Badge';
import NoticeTableCell from './NoticeTableCell';
const ICONS = {
    check: _jsx(CheckIcon, { className: "w-4 h-4 mr-1" }),
    waiting: _jsx(ClockIcon, { className: "w-4 h-4 mr-1" })
};
export default function AffidavitStatusCell({ row }) {
    const isPublisher = useAppSelector(selectIsPublisher);
    const { iscancelled, affidavitsubmitted, affidavitdisabled, requireemailaffidavit, mailaffidavitsoutsidecolumn, publicationtimestamps, isdraft } = row.original;
    if (isdraft)
        return null;
    // TODO: should be removed after releasing BACKEND-298
    const beforeLastPubDate = moment().isBefore(publicationtimestamps &&
        moment(publicationtimestamps[publicationtimestamps.length - 1]));
    const afterFirstPubDate = moment().isAfter(publicationtimestamps && moment(publicationtimestamps[0]));
    let statusIcon = !iscancelled ? ICONS.waiting : undefined;
    let affidavitStatusLabel;
    let status;
    if (iscancelled) {
        affidavitStatusLabel = 'Cancelled';
        statusIcon = _jsx(XMarkIcon, { className: "w-4 h-4 mr-1" });
    }
    else if (affidavitdisabled) {
        affidavitStatusLabel = AffidavitStatusType.affidavit_disabled.label;
        statusIcon = undefined;
    }
    else if (affidavitsubmitted) {
        affidavitStatusLabel = AffidavitStatusType.submitted.label;
        statusIcon = ICONS.check;
        status = 'success';
    }
    else if (!affidavitsubmitted && afterFirstPubDate && beforeLastPubDate) {
        affidavitStatusLabel = AffidavitStatusType.publication_in_progress.label;
    }
    else if (!affidavitsubmitted && !afterFirstPubDate) {
        affidavitStatusLabel = AffidavitStatusType.awaiting_publication.label;
    }
    else if (isPublisher &&
        !affidavitsubmitted &&
        !affidavitdisabled &&
        !beforeLastPubDate) {
        affidavitStatusLabel = AffidavitStatusType.incomplete.label;
        status = 'warning';
    }
    else if (!isPublisher &&
        !affidavitsubmitted &&
        !affidavitdisabled &&
        !beforeLastPubDate) {
        affidavitStatusLabel = AffidavitStatusType.awaiting_upload.label;
    }
    else if (!requireemailaffidavit &&
        mailaffidavitsoutsidecolumn &&
        !affidavitsubmitted) {
        affidavitStatusLabel = AffidavitStatusType.outside_of_column.label;
    }
    return (_jsx(NoticeTableCell, { children: _jsx(Badge, Object.assign({ size: "lg", startIcon: statusIcon, status: status }, { children: affidavitStatusLabel })) }));
}
