import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Alert } from 'lib/components/Alert';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import PlacementActions, { placementSelector, selectDisplayParams, selectPreviewContent, selectShowPlacementErrors } from 'redux/placement';
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ERRORS } from '../PlaceNoticePreview';
import { selectColumnCountRangeConfig, selectShowHeightWarning } from '../placeScrollSelectors';
export function NoticeContentAlerts({ newspaper, setSubmitLargeFileWithoutFormatting, squashTables }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    const placement = useAppSelector(placementSelector);
    const previewContent = useAppSelector(selectPreviewContent);
    const { wait: showWaitWarning } = useAppSelector(selectShowPlacementErrors);
    const { showHeightWarning, heightWarningMessage } = useAppSelector(state => selectShowHeightWarning(state, newspaper));
    const displayParams = useAppSelector(selectDisplayParams);
    const { maxColumns } = useAppSelector(state => selectColumnCountRangeConfig(state, newspaper));
    const showWidthWarning = (displayParams === null || displayParams === void 0 ? void 0 : displayParams.isTableOverflow) ||
        (displayParams === null || displayParams === void 0 ? void 0 : displayParams.areAnyTablesTooWide) ||
        (!!(displayParams === null || displayParams === void 0 ? void 0 : displayParams.columns) && displayParams.columns > maxColumns);
    /**
     * Show a warning to let users squash notice content to fit
     * into one column. The warning will only show if selected number of columns is equal
     * to max columns that newspaper allows and notice content has a <table tag. It will also show if indesign call return table overflow error.
     */
    const showSquashTableAlert = placement.displayParams &&
        (((_a = placement.displayParams) === null || _a === void 0 ? void 0 : _a.isTableOverflow) ||
            (placement.columns >= maxColumns &&
                ((_b = placement.confirmedHtml) === null || _b === void 0 ? void 0 : _b.includes('<table')))) &&
        !placement.squashable;
    const shouldStopAutoIncreaseColumnToOptimalColumnWidth = getBooleanFlag(LaunchDarklyFlags.STOP_AUTO_INCREASE_COLUMN_WIDTH, false);
    const handleSubmitLargeFileWithoutFormatting = () => {
        dispatch(PlacementActions.setPostWithoutFormatting(true));
        setSubmitLargeFileWithoutFormatting(true);
        dispatch(PlacementActions.setShowPlacementErrors({ wait: false, largeFile: false }));
    };
    if (![
        showWidthWarning,
        showSquashTableAlert,
        showHeightWarning,
        showWaitWarning
    ].some(Boolean)) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", Object.assign({ className: "space-y-3 pb-3" }, { children: [showWidthWarning && placement.columns < maxColumns && (_jsx(Alert, { id: "alert-resizing-notice", status: "warning", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }), title: shouldStopAutoIncreaseColumnToOptimalColumnWidth
                    ? ERRORS.RESIZING_NOTICE_V2
                    : ERRORS.RESIZING_NOTICE })), showWidthWarning && placement.columns >= maxColumns && (_jsx(Alert, { id: "alert-content-too-wide", status: "warning", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }), title: ERRORS.TOO_WIDE })), showHeightWarning && (_jsx("div", { children: _jsx(Alert, { id: "alert-content-too-tall", title: heightWarningMessage, status: "error", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }) }) })), showSquashTableAlert && (_jsx(Alert, Object.assign({ id: 'notice-too-wide', icon: _jsx(InformationCircleIcon, { className: "h-5 w-5" }) }, { children: _jsxs("div", { children: [_jsx("span", Object.assign({ className: "cursor-pointer underline hover:text-grey-500", onClick: () => {
                                squashTables && squashTables();
                            } }, { children: "Click here" })), ' ', "to remove all table formatting and fit your notice into one column. No text will be removed from your notice."] }) }))), showWaitWarning &&
                (placement.continueWithLargeFile || !previewContent.displayParams) && (_jsx("div", Object.assign({ className: "cursor-pointer" }, { children: _jsx(Alert, { id: "alert-wait-request", status: "warning", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }), title: _jsx("span", Object.assign({ className: "cursor-pointer", onClick: handleSubmitLargeFileWithoutFormatting }, { children: ERRORS.WAIT_REQUEST })) }) })))] })));
}
