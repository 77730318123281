import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { push } from 'connected-react-router';
import { Form } from 'lib/components/Form';
import { GridInput } from 'lib/components/Card/Grid';
import { useAppDispatch } from 'redux/hooks';
export const DEFAULT_LOGIN_DATA = {
    email: '',
    password: ''
};
export default function LoginUserForm({ onChangeLoginData, loggingIn, loginData, onSubmit }) {
    const dispatch = useAppDispatch();
    return (_jsx(Form, Object.assign({ onSubmit: onSubmit }, { children: _jsxs("div", Object.assign({ className: "grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8" }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "email", required: true, type: "email", labelText: "Email", value: loginData.email, onChange: newValue => onChangeLoginData(Object.assign(Object.assign({}, loginData), { email: newValue })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "password", required: true, type: "password", labelText: "Password", value: loginData.password, onChange: newValue => onChangeLoginData(Object.assign(Object.assign({}, loginData), { password: newValue })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnButton, { buttonText: _jsx("span", Object.assign({ className: "underline" }, { children: "Forgot Password?" })), link: true, id: "forgot-password", type: "button", onClick: () => dispatch(push('/forgot-password')) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnButton, { size: "lg", buttonText: "Log In", loading: loggingIn, fullWidth: true, primary: true, type: "submit", id: "submit" }) }))] })) })));
}
