var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { ConfirmationStatus } from 'lib/enums';
import { useState } from 'react';
export function ConfirmNoticeModal({ notice, user, onClose }) {
    const [loading, setLoading] = useState(false);
    const [newStatus, setNewStatus] = useState(ConfirmationStatus.Pending);
    return (_jsx(Modal, Object.assign({ id: "confirm-notice-modal", onClose: onClose, title: "Confirm Notice", primaryAction: {
            buttonText: 'Done',
            formId: 'notice-confirmation-form',
            type: 'submit',
            disabled: newStatus === ConfirmationStatus.Pending,
            loading
        }, secondaryActions: [
            {
                buttonText: 'Skip',
                type: 'button'
            }
        ] }, { children: _jsx(Form, Object.assign({ id: "notice-confirmation-form", onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                setLoading(true);
                if (newStatus === ConfirmationStatus.Confirmed) {
                    yield notice.confirm(user);
                }
                else if (newStatus === ConfirmationStatus.AwaitingConfirmation) {
                    yield notice.markReviewing();
                }
                onClose();
            }) }, { children: _jsxs("span", Object.assign({ className: "grid gap-5" }, { children: [_jsx("span", Object.assign({ className: "text-sm text-column-gray-400 font-medium" }, { children: "Confirm this notice to let the customer know it will publish on the requested dates. If you want to review or edit before confirming, please confirm the notice before deadline to ensure it runs on time." })), _jsx(CheckboxInput, { id: "review-input", checked: newStatus === ConfirmationStatus.AwaitingConfirmation, onChange: value => {
                            if (value) {
                                setNewStatus(ConfirmationStatus.AwaitingConfirmation);
                            }
                        }, labelText: "Review before confirming", labelDescription: "We will hold off on notifying customer about their notice status.", type: "radio" }), _jsx(CheckboxInput, { id: "confirm-input", checked: newStatus === ConfirmationStatus.Confirmed, onChange: value => {
                            if (value) {
                                setNewStatus(ConfirmationStatus.Confirmed);
                            }
                        }, labelText: "Confirm this notice", labelDescription: "The customer will be notified that their notice is all set to publish.", type: "radio" })] })) })) })));
}
