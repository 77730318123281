import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { ZoomOutMap } from '@material-ui/icons';
import { useAppSelector } from 'redux/hooks';
import { placementSelector, selectConfirmedHtml, selectIsDisplayNoticeType, selectPreviewContent } from 'redux/placement';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { PreviewPlaceholder, EstimatedPriceContainer, DisplayParamsContainer } from './NoticePreviewContainerForms';
export default function NoticePreviewContainer({ loading, openModal, isTypeform, madlibConfigPath, uploading, displayJPGUrl, displayPDFUrl, rate, newspaper }) {
    var _a, _b, _c;
    const [isHovering, setIsHovering] = useState(false);
    const placement = useAppSelector(placementSelector);
    const { displayParams, price } = useAppSelector(selectPreviewContent);
    const isDisplay = useAppSelector(selectIsDisplayNoticeType);
    const confirmedHtml = (_a = useAppSelector(selectConfirmedHtml)) !== null && _a !== void 0 ? _a : '';
    const isProcessing = loading || uploading;
    const imagesArePopulated = displayJPGUrl || ((_b = displayParams === null || displayParams === void 0 ? void 0 : displayParams.imgs) === null || _b === void 0 ? void 0 : _b.length);
    /**
     * See the long comment in NoticeEditorMCE.tsx for
     * more context on the relationship between the inner html
     * of the TinyMCE editor and our cleaned/sanitized placement html
     *
     * For some reason, a blank editor does not always correspond to the
     * exact same html when run through our cleaning functions.
     */
    const editorIsEmpty = !isDisplay && ['<p><br></p>', '<p><br /></p>'].includes(confirmedHtml);
    const showPlaceholder = 
    // Preview initial state
    (!isProcessing && !imagesArePopulated) ||
        // Preview is loading
        isProcessing ||
        // Preview has generated but the html is empty
        editorIsEmpty;
    const showExpandButton = isHovering && imagesArePopulated && !isProcessing;
    const showPreviewImage = !isProcessing && imagesArePopulated && !editorIsEmpty;
    const hasDisplayParams = placement.displayParams && !isEmpty(placement.displayParams);
    return (_jsxs("div", Object.assign({ className: "rounded-md border border-column-gray-100 shadow-column-3" }, { children: [_jsxs("div", Object.assign({ id: "preview-container-content", style: {
                    height: '50vh',
                    minWidth: '180px'
                }, className: classNames('relative w-full rounded-t-md text-center overflow-y-scroll text-column-gray-300', {
                    'bg-column-gray-25': 
                    // BG when a preview image exists, to contrast with the white bg image
                    !isProcessing && imagesArePopulated,
                    'bg-white': 
                    // BG when the placeholder is in place
                    isProcessing || !imagesArePopulated
                }), onClick: () => {
                    var _a;
                    return !loading &&
                        (displayPDFUrl
                            ? window.open(displayPDFUrl)
                            : ((_a = displayParams === null || displayParams === void 0 ? void 0 : displayParams.imgs) === null || _a === void 0 ? void 0 : _a.length) && openModal());
                }, onMouseEnter: () => setIsHovering(true), onMouseLeave: () => setIsHovering(false) }, { children: [showExpandButton && (_jsxs("div", Object.assign({ className: "sticky top-0 left-0 w-full h-full flex flex-col items-center justify-center cursor-pointer z-100 text-column-gray-500" }, { children: [_jsx(ZoomOutMap, {}), _jsx("div", Object.assign({ className: "text-2xl" }, { children: "Click to Expand" }))] }))), showPreviewImage && hasDisplayParams && (_jsx("div", Object.assign({ className: "absolute top-0 h-full w-full flex justify-center items-start" }, { children: _jsxs("div", Object.assign({ className: "min-h-full flex flex-col items-center justify-center p-2", style: {
                                opacity: isHovering ? 0.35 : 1,
                                maxWidth: `${displayJPGUrl
                                    ? 2.5
                                    : (displayParams === null || displayParams === void 0 ? void 0 : displayParams.width) || 1.5}in`
                            } }, { children: [displayJPGUrl && (_jsx("img", { id: "notice-preview-image", src: displayJPGUrl })), !displayJPGUrl && ((_c = displayParams === null || displayParams === void 0 ? void 0 : displayParams.imgs) === null || _c === void 0 ? void 0 : _c.length) && (_jsx("div", Object.assign({ id: "notice-preview-image" }, { children: displayParams.imgs.map((img, i) => (_jsx("img", { src: img }, i))) })))] })) }))), showPlaceholder && (_jsx(PreviewPlaceholder, { displayParams: displayParams, isDisplay: isDisplay, isTypeform: isTypeform, madlibConfigPath: madlibConfigPath }))] })), _jsx("div", Object.assign({ id: "preview-container-details" }, { children: _jsx("div", Object.assign({ style: { minWidth: '180px' }, className: "h-24 flex flex-col justify-center text-left rounded-b-md px-4 pt-2 pb-4 min-w-0 border-t bg-white text-column-gray-500" }, { children: isProcessing ? (_jsx("div", Object.assign({ className: "h-full bg-white m-auto flex items-center text-primary-400" }, { children: _jsx(CircularProgress, { color: 'inherit', size: 21 }) }))) : (_jsxs(_Fragment, { children: [_jsx(EstimatedPriceContainer, { newspaper: newspaper, rate: rate, price: price }), _jsx(DisplayParamsContainer, { displayParams: displayParams, rate: rate })] })) })) }))] })));
}
