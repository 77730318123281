var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { getUserPaymentMethods } from 'utils/userPaymentMethods';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import PayWithCreditCardButton from './buttons/PayWithCreditCardButton';
import SavedPaymentMethods from './SavedPaymentMethods';
import { getPreventLatePaymentValue } from '../helpers/preventLatePayment';
export function PayInvoicePaymentMethods({ payInvoiceData, savedPaymentsAccess, selectedPaymentMethod, selectedSavedMethodId, user, selectPaymentClickHandler, setShowLoginModal }) {
    const { invoice } = payInvoiceData;
    const preventLatePayment = getPreventLatePaymentValue(payInvoiceData);
    const { advertiserOrganization } = invoice.data();
    const [userPaymentMethods, setUserPaymentMethods] = useState();
    useEffect(() => {
        const loadSavedPaymentData = () => __awaiter(this, void 0, void 0, function* () {
            if (!exists(user))
                return;
            if (savedPaymentsAccess.disableACH &&
                savedPaymentsAccess.disableSavedCards)
                return;
            try {
                const res = yield getUserPaymentMethods(user, advertiserOrganization);
                setUserPaymentMethods(res);
            }
            catch (e) {
                logAndCaptureException(ColumnService.PAYMENTS, e, 'Failed to load bank accounts and credit cards', { invoiceId: invoice.id, user: user.id });
            }
        });
        void loadSavedPaymentData();
    }, [
        advertiserOrganization === null || advertiserOrganization === void 0 ? void 0 : advertiserOrganization.id,
        user === null || user === void 0 ? void 0 : user.id,
        savedPaymentsAccess.disableACH,
        savedPaymentsAccess.disableSavedCards
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsx(PayWithCreditCardButton, { disabled: preventLatePayment, selected: selectedPaymentMethod === 'card', clickHandler: selectPaymentClickHandler }) }), _jsx(SavedPaymentMethods, { payInvoiceData: payInvoiceData, userPaymentMethods: userPaymentMethods, savedPaymentsAccess: savedPaymentsAccess, user: user, selectPaymentClickHandler: selectPaymentClickHandler, selectedSavedMethodId: selectedSavedMethodId, setShowLoginModal: setShowLoginModal })] }));
}
