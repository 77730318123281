import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import moment from 'moment';
import OrderSummaryRow from './OrderSummaryRow';
function PublishingDateBadge({ date }) {
    return (_jsx(Badge, Object.assign({ startIcon: _jsx(ClockIcon, { className: "h-5 w-5" }), size: "md" }, { children: moment(date).format('ddd DD MMM, YYYY') })));
}
function NewspaperOrderSummary({ newspaper, publishingDates, filingTypeLabel }) {
    return (_jsx(OrderSummaryRow, { label: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "mr-4" }, { children: newspaper.data().name })), _jsx(Badge, Object.assign({ status: "info", size: "md" }, { children: filingTypeLabel }))] }), data: _jsx("div", Object.assign({ className: "grid grid-cols-1 gap-1" }, { children: publishingDates === null || publishingDates === void 0 ? void 0 : publishingDates.map((date, i) => (_jsx(PublishingDateBadge, { date: date }, i))) })) }));
}
export default NewspaperOrderSummary;
