var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { push } from 'connected-react-router';
import AuthActions, { selectUserAuth } from 'redux/auth';
import { OccupationType, InviteStatus, State } from 'lib/enums';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import api from 'api';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureException } from 'utils';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { updatePendingInviteStatusForAnonUsers } from 'routes/register/helpers';
import { POST_REGISTRATION_ENTRYPOINT } from 'redux/router';
import { getRedirect } from 'utils/urls';
import { TextField } from 'lib/components/TextField';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Autocomplete } from 'lib/components/Autocomplete';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnService } from 'lib/services/directory';
import * as validators from '../organization/validators';
export const sendOrganizationInvites = (selectedOrganizations, user, ctx) => __awaiter(void 0, void 0, void 0, function* () {
    for (const item of selectedOrganizations) {
        const requestObj = {
            createdAt: getFirebaseContext().fieldValue().serverTimestamp(),
            email: user.data().email,
            userId: user.id,
            organization: getFirebaseContext().organizationsRef().doc(item.value),
            status: InviteStatus.pending.value
        };
        // eslint-disable-next-line no-await-in-loop
        yield ctx.joinRequestsRef().add(requestObj);
    }
});
export const notifyOrganizationAdminUsers = (user) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userRequests = yield getFirebaseContext()
            .joinRequestsRef()
            .where('email', '==', user.data().email)
            .get();
        if (!userRequests.docs.length)
            return;
        yield api.post('users/send-requests');
    }
    catch (err) {
        logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error on sending joining requests email to admin users', {
            userId: user.id
        });
    }
});
export const isFieldNonEmpty = (field) => {
    return field.trim().length > 0;
};
export default function RegisterIndividualForm({ user }) {
    const userAuth = useAppSelector(selectUserAuth);
    const dispatch = useAppDispatch();
    const [addressLine1, setAddressLine1] = useState(user.data().address || '');
    const [addressLine2, setAddressLine2] = useState(user.data().addressLine2 || '');
    const [city, setCity] = useState(user.data().city || '');
    const [state, setState] = useState(user.data().state);
    const [zipCode, setZipCode] = useState(user.data().zipCode || '');
    const [phone, setPhone] = useState(user.data().phone || '');
    // We have separate variables for when we actually want to display an error if a field
    // is not valid because we do not want them displaying for blank fields when the user
    // gets to the address step of the registration flow.
    const isAddressLine1Valid = isFieldNonEmpty(addressLine1) && validators.addressField(addressLine1);
    const isAddressLine1ValidDisplayError = isAddressLine1Valid || !addressLine1;
    const isAddressLine2Valid = !addressLine2 || validators.addressField(addressLine2);
    const isCityValid = isFieldNonEmpty(city) && validators.addressField(city);
    const isCityValidDisplayError = isCityValid || !city;
    const isZipValid = validators.zipAdvertiser(zipCode);
    const isPhoneValid = validators.phoneRegex.test(phone);
    const [loading, setLoading] = useState(false);
    const ctx = getFirebaseContext();
    const disable = !isAddressLine1Valid ||
        !isAddressLine2Valid ||
        !isCityValid ||
        !state ||
        !isZipValid ||
        !isPhoneValid;
    const onBackClick = () => {
        dispatch(push(POST_REGISTRATION_ENTRYPOINT));
    };
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        if (disable || !userAuth) {
            return;
        }
        setLoading(true);
        // Create stripe customer id for individual
        const createCustomer = getCreateCustomerFn();
        // If a newspaper has already invoiced this user pre-registration,
        // the user will have an existing stripe ID
        let result;
        let newStripeId;
        if (!user.data().stripeId) {
            result = yield createCustomer({
                name: user.data().name,
                email: user.data().email,
                firestoreUID: userAuth.uid
            });
            if (!result) {
                console.error('Unable to create new Stripe customer ID');
                return;
            }
            newStripeId = result.data.stripeId;
        }
        const userUpdateObject = {
            occupation: OccupationType.individual.value,
            address: addressLine1,
            addressLine2: addressLine2 || '',
            city,
            state,
            zipCode,
            phone,
            stripeId: user.data().stripeId || newStripeId,
            postRegistrationComplete: true
        };
        yield ctx.usersRef().doc(userAuth.uid).update(userUpdateObject);
        // Confusing naming alert:
        // The below auth action doesn't actually register - it populates
        // user session information
        dispatch(AuthActions.register());
        yield notifyOrganizationAdminUsers(user);
        /* Updating status of penging invites to accepted at the end because we are
        handling the routing of registration flow based on the pending invites (member invited by an organization) in routing saga */
        yield updatePendingInviteStatusForAnonUsers(ctx, user);
        dispatch(push(getRedirect()));
    });
    const nextButtonClicked = () => __awaiter(this, void 0, void 0, function* () {
        yield handleSubmit();
    });
    return (_jsxs(_Fragment, { children: [_jsxs(CardGridLayout, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: 'address-line-1', required: true, value: addressLine1, onChange: setAddressLine1, placeholder: 'Address line 1', labelText: 'Address line 1', errorText: isAddressLine1ValidDisplayError ? '' : 'Address must be valid.' }) }), _jsx(GridInput, { children: _jsx(TextField, { id: 'address-line-2', value: addressLine2, onChange: setAddressLine2, placeholder: 'Address line 2', labelText: 'Address line 2', errorText: isAddressLine2Valid ? '' : 'Address must be valid.' }) }), _jsx(GridInput, { children: _jsx(TextField, { id: 'city', required: true, value: city, onChange: setCity, placeholder: 'City', labelText: 'City', errorText: isCityValidDisplayError ? '' : 'City must be valid.' }) }), _jsx(GridInput, { children: _jsx(Autocomplete, { id: 'state', required: true, value: state === null || state === void 0 ? void 0 : state.toString(), options: State.items().map(item => ({
                                value: item.value.toString(),
                                label: item.label
                            })), onChange: newState => setState(parseInt(newState, 10)), placeholder: 'State', labelText: 'State' }) }), _jsx(GridInput, { children: _jsx(TextField, { id: 'phone', required: true, type: 'tel', value: phone, placeholder: 'Phone', onChange: setPhone, labelText: "Phone number" }) }), _jsx(GridInput, { children: _jsx(TextField, { id: 'zipCode', required: true, type: 'postal-code', value: zipCode, placeholder: 'Zip Code', onChange: setZipCode, labelText: "Zip Code" }) })] }), _jsx("div", Object.assign({ className: "px-6" }, { children: _jsx(AdvertiserRegistrationFooter, { id: "submit-individual", backButtonText: "Go back", nextButtonText: "Create account", onBackButtonClick: onBackClick, onNextButtonClick: nextButtonClicked, loading: loading, disableNextButton: disable || loading }) }))] }));
}
