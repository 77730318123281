var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { push } from 'connected-react-router';
import { logAndCaptureException } from 'utils';
import { duplicateNotice } from 'utils/duplicateNotice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsPublisher, selectUser } from 'redux/auth';
import ToastActions from 'redux/toast';
import { ColumnService } from 'lib/services/directory';
export function DuplicateNoticeButton({ notice }) {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const isPublisher = useAppSelector(selectIsPublisher);
    const [duplicating, setDuplicating] = useState(false);
    const handleDuplicateNoticeClick = (noticeId, user) => __awaiter(this, void 0, void 0, function* () {
        try {
            setDuplicating(true);
            const { newNoticeRef } = yield duplicateNotice(noticeId, user);
            dispatch(push(`/place/${newNoticeRef.id}?duplicate=true`));
        }
        catch (e) {
            dispatch(ToastActions.toastError({
                headerText: 'Error duplicating notice',
                bodyText: 'There is some issue in duplicating notice'
            }));
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Error duplicating notice', {
                noticeId
            });
        }
        finally {
            setDuplicating(false);
        }
    });
    if (!isPublisher || !user)
        return _jsx(_Fragment, {});
    return (_jsx(ColumnButton, { tertiary: true, type: "button", id: "duplicate-notice", loading: duplicating, buttonText: "Duplicate", onClick: () => void handleDuplicateNoticeClick(notice.id, user) }));
}
