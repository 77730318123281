import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State, TimezoneType } from 'lib/enums';
import React from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
export default function PublisherInfo({ value, onChange }) {
    var _a;
    return (_jsxs(CardGridLayout, { children: [_jsx(GridInput, { children: _jsx(TextField, { labelText: "Name of publisher", required: true, onChange: newValue => onChange(Object.assign(Object.assign({}, value), { name: newValue })), value: value.name, id: "name" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Timezone", size: "standard", options: TimezoneType.items().map(tz => ({
                        value: tz.key,
                        label: tz.label
                    })), onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { iana_timezone: newValue }));
                    }, value: value.iana_timezone || '', id: "timezone" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "State", options: State.items().map(state => ({
                        value: state.value.toString(),
                        label: state.label
                    })), onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { state: Number(newValue) }));
                    }, value: ((_a = value.state) === null || _a === void 0 ? void 0 : _a.toString()) || '', id: "state" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Address line 1", required: true, onChange: newValue => onChange(Object.assign(Object.assign({}, value), { address: newValue })), value: value.address, id: "address" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Address line 2", onChange: newValue => onChange(Object.assign(Object.assign({}, value), { addressLine2: newValue })), value: value.addressLine2, id: "address-2" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "County", required: true, onChange: newValue => onChange(Object.assign(Object.assign({}, value), { county: newValue })), value: value.county, id: "county" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "City", required: true, onChange: newValue => onChange(Object.assign(Object.assign({}, value), { city: newValue })), value: value.city, id: "city" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Zip Code", required: true, onChange: newValue => onChange(Object.assign(Object.assign({}, value), { zipCode: newValue })), value: value.zipCode, type: "postal-code", id: "zip" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Phone number", required: true, onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { phone: newValue }));
                    }, value: value.phone, type: "tel", id: "phone" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Publisher email", onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { email: newValue }));
                    }, value: value.email, type: "email", id: "email" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Publisher point of contact name", onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { publisherPointOfContact: newValue }));
                    }, value: value.publisherPointOfContact, id: "primary-contact-name" }) }), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Publisher website", onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { website: newValue }));
                    }, value: value.website, type: "url", id: "website" }) })] }));
}
