import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import classNames from 'classnames';
export function Tooltip({ id, helpText, children, position, customPosition, classes, helpHeaderText, innerBackgroundColor }) {
    const tooltipRef = useRef(null);
    const positionClass = {
        left: 'mr-2 left-0',
        right: 'ml-2 right-0',
        bottom: 'top-full',
        bottomRight: 'top-full',
        top: 'bottom-full',
        topLeft: 'bottom-full right-0',
        topCenter: 'bottom-full'
    }[position !== null && position !== void 0 ? position : 'top'];
    const backgroundColorClass = {
        black: 'bg-black',
        gray: 'bg-gray-850'
    }[innerBackgroundColor !== null && innerBackgroundColor !== void 0 ? innerBackgroundColor : 'black'];
    return (_jsxs("div", Object.assign({ id: id, className: `relative tooltip-container ${classes || ''}` }, { children: [children, helpText && (_jsxs("div", Object.assign({ style: { minWidth: helpText.length > 40 ? '200px' : '24px' }, ref: tooltipRef, id: "tooltip-text", className: classNames(`absolute text-white text-xs rounded py-1 px-4 w-auto z-50 hidden ${backgroundColorClass} ${customPosition || positionClass}`, { 'whitespace-normal': helpText.length > 50 }) }, { children: [helpHeaderText && (_jsx("p", Object.assign({ className: "mb-2 font-semibold" }, { children: helpHeaderText }))), helpText] })))] })));
}
