import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import NotesTab from 'components/CustomerDrawer/NotesTab/NotesTab';
import Drawer from 'lib/components/Drawer';
export default function PublisherInfoDrawer({ activeOrganization, publisherNotes, onClose }) {
    return (_jsx(Drawer, Object.assign({ onClose: onClose, header: _jsxs("div", { children: [_jsx("div", Object.assign({ className: "text-xl text-column-gray-800" }, { children: "Notes" })), _jsxs("div", Object.assign({ className: "text-xs text-column-gray-400" }, { children: ["View all notes for ", activeOrganization.data().name, "."] }))] }), open: true }, { children: _jsx(NotesTab, { noteTopic: {
                publisher: activeOrganization.ref,
                noteType: 'publisher-note'
            }, notes: publisherNotes }) })));
}
