import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { push } from 'connected-react-router';
import { exists } from 'lib/types';
import { CTable } from 'components/table';
import { selectCurrentPage, selectCurrentTab } from 'redux/notices';
import { PUBLISHER_BILLING_STATUS, ADVERTISER_BILLING_STATUS, ARCHIVED_NOTICES_TAB, PUBLICATION_DATES, DRAFT_NOTICES_TAB, AFFIDAVIT_STATUS, NEWSPAPER_NAME, CONFIRMED } from './types';
import ConfirmationStatusCell from './noticeTableCells/ConfirmationStatusCell';
import AffidavitStatusCell from './noticeTableCells/AffidavitStatusCell';
import BillingStatusCell from './noticeTableCells/BillingStatusCell';
import CustomerOrganizationCell from './noticeTableCells/CustomerOrganizationCell';
import NoticeCell from './noticeTableCells/NoticeCell';
import PublicationDateCell from './noticeTableCells/PublicationDateCell';
import PublisherNameCell from './noticeTableCells/PublisherNameCell';
import SyncStatusCell from './noticeTableCells/SyncStatusCell';
import TableActions from './TableActions';
import TableHeaderTabs from './TableHeaderTabs';
function YourNoticesTable({ notices, loading, actions, showAllOrgsNotices, rowsPerNoticeTablePage, user, setSort, setCurrentPage, setPageSize, setTab, searchValue, setSearchValue, total, setShowUserOnlyNotices, showUserOnlyNotices, showSyncStatus, sort }) {
    const isPublisher = useAppSelector(selectIsPublisher);
    const currentPage = useAppSelector(selectCurrentPage);
    const activeTab = useAppSelector(selectCurrentTab);
    let columns = [
        !!(!isPublisher && showAllOrgsNotices) && {
            Header: 'ORGANIZATION',
            id: NEWSPAPER_NAME,
            accessor: 'filer',
            isVisible: !!(!isPublisher && showAllOrgsNotices),
            Cell: CustomerOrganizationCell,
            disableSortBy: true
        },
        {
            Header: 'NOTICE',
            accessor: 'referenceId',
            Cell: NoticeCell,
            disableSortBy: true
        },
        {
            Header: 'PUBLICATION DATE',
            accessor: 'publicationDatesString',
            Cell: PublicationDateCell,
            id: PUBLICATION_DATES
        },
        {
            Header: 'CONFIRMATION',
            accessor: 'confirmed',
            Cell: ConfirmationStatusCell,
            id: CONFIRMED
        },
        {
            Header: 'PAYMENT',
            accessor: 'billingStatus',
            Cell: BillingStatusCell,
            id: isPublisher ? PUBLISHER_BILLING_STATUS : ADVERTISER_BILLING_STATUS
        },
        {
            Header: 'AFFIDAVIT',
            accessor: 'affidavitSubmitted',
            Cell: AffidavitStatusCell,
            id: AFFIDAVIT_STATUS
        },
        !!(isPublisher && showAllOrgsNotices) && {
            Header: 'PUBLICATION',
            id: NEWSPAPER_NAME,
            accessor: 'newspaperName',
            isVisible: !!(isPublisher && showAllOrgsNotices),
            Cell: PublisherNameCell,
            disableSortBy: true
        },
        showSyncStatus && {
            Header: 'SYNC',
            accessor: 'table-sync',
            id: 'table-sync',
            Cell: SyncStatusCell,
            disableSortBy: true
        },
        {
            Header: 'ACTIONS',
            accessor: 'table-actions',
            id: 'table-actions',
            Cell: TableActions,
            disableSortBy: true
        }
    ];
    columns = columns.filter((cols) => cols);
    // TODO: do I drop the affidavit column here
    const columnsData = React.useMemo(() => [
        {
            Header: TableHeaderTabs({
                onSelectTab: setTab,
                activeTab
            }),
            id: `${activeTab}-notices`,
            columns
        }
    ], [columns]);
    const [emptyHeader, emptySubtitle] = activeTab === ARCHIVED_NOTICES_TAB
        ? [
            `You don't have any notices yet`,
            'Notices will appear here after they finish publication or are cancelled.'
        ]
        : activeTab === DRAFT_NOTICES_TAB
            ? [
                `You don't have any drafts yet`,
                'Drafts will appear here before you finalize submission for your notices.'
            ]
            : [
                `You don't have any notices yet`,
                'You can track and manage your notices here. Click the button below to place a notice.'
            ];
    return (_jsx(_Fragment, { children: _jsx(CTable, { columns: columnsData, id: `${activeTab}-table`, data: notices, emptyHeaderText: emptyHeader, emptySubtitleText: emptySubtitle, loading: loading, actions: actions, onSort: (sort) => setSort(sort), setSearchValue: setSearchValue, sort: sort, tableState: {
                rowCount: rowsPerNoticeTablePage || 5,
                currentPage,
                total
            }, onPageChange: page => setCurrentPage(page || 0), onRowsChange: rows => {
                if (exists(user)) {
                    void user.ref.update({
                        noticeTablePageSize: rows
                    });
                }
                setPageSize(rows);
            }, setShowUserOnlyNotices: setShowUserOnlyNotices, showUserOnlyNotices: showUserOnlyNotices, searchValue: searchValue }) }));
}
const mapStateToProps = (state) => ({
    showAllOrgsNotices: state.auth.showAllOrgsNotices,
    user: state.auth.user
});
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path))
});
export default connect(mapStateToProps, mapDispatchToProps)(YourNoticesTable);
