import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
export default function AdvancedNoticeTypeSettingsCard({ updatedNoticeType, onUpdateNoticeType }) {
    const [enableInternalID, setEnableInternalID] = useState(!!updatedNoticeType.internalID);
    const [internalID, setInternalID] = useState(updatedNoticeType.internalID || '');
    return (_jsx(CardGridLayout, Object.assign({ header: {
            title: 'Integration Settings',
            description: 'Update the integration setup for this notice type. Before changing these settings, consult with the CSM for this account.'
        } }, { children: _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                label: 'Should we set an internal ID for this notice type?',
                description: 'This setting actively impacts integrations, so it should only be changed if you know what you are doing.',
                value: enableInternalID,
                onChange: newVal => {
                    if (!newVal) {
                        onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { internalID: null }));
                    }
                    setEnableInternalID(newVal);
                }
            }, header: "Deadline Preferences" }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { labelText: "What should we set as the internal ID on the notice type?", value: internalID, required: true, onChange: val => {
                        setInternalID(val);
                        onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { internalID: val }));
                    }, id: "internal-id" }) })) })) })));
}
