import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { AffinityXSyncStatus } from './helpers';
function AffinityXSyncStatusPill({ syncStatus }) {
    return (_jsxs(_Fragment, { children: [syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL && (_jsx(Badge, Object.assign({ status: "success", shape: "rectangle" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center pt-1" }, { children: [_jsx(ArrowPathIcon, { className: "h-3 w-3" }), _jsx("p", Object.assign({ className: "pl-1" }, { children: "Sync complete" }))] })) }))), syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS && (_jsx(Badge, Object.assign({ status: "warning", shape: "rectangle" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center pt-1" }, { children: [_jsx(ArrowPathIcon, { className: "h-3 w-3" }), _jsx("p", Object.assign({ className: "pl-1" }, { children: "In progress" }))] })) }))), syncStatus &&
                [
                    AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION,
                    AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION
                ].includes(syncStatus) && (_jsx(Badge, Object.assign({ status: "critical", shape: "rectangle" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center pt-1" }, { children: [_jsx(ArrowPathIcon, { className: "h-3 w-3" }), _jsx("p", Object.assign({ className: "pl-1" }, { children: "Sync failed" }))] })) }))), syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED && (_jsx(Badge, Object.assign({ status: "info", shape: "rectangle" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center pt-1" }, { children: [_jsx(ArrowPathIcon, { className: "h-3 w-3" }), _jsx("p", Object.assign({ className: "pl-1" }, { children: "Sync cancelled" }))] })) })))] }));
}
export default AffinityXSyncStatusPill;
