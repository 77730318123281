var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Tooltip } from 'lib/components/Tooltip';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { ColumnButton } from 'lib/components/ColumnButton';
import { buildingStyles } from 'lib/components/UserIDCard';
import { PhotoIcon, ArrowDownIcon, PencilIcon, StarIcon } from '@heroicons/react/24/outline';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { AdTypeRecommendation } from './adTypeRecommendation/getAdTypeRecommendationFromProperties';
function FormattingOptionsRow({ id, onClick, color, icon, mainText, helperText }) {
    return (_jsxs("div", Object.assign({ id: id, className: "p-2 hover:bg-column-gray-25 cursor-pointer rounded-md flex items-center", onClick: () => {
            onClick();
        } }, { children: [_jsx("div", Object.assign({ className: "w-8 flex" }, { children: _jsx("div", Object.assign({ className: `w-6 h-6 rounded-full flex items-center justify-center ${color}` }, { children: icon })) })), _jsxs("div", Object.assign({ className: "truncate text-sm" }, { children: [_jsx("div", Object.assign({ className: "font-medium text-column-gray-500" }, { children: mainText })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: helperText }))] }))] })));
}
function FormattingOptions({ convertToDisplayNotice, convertToLinerNotice, handleSubmitLargeFileWithoutFormatting, noticeType }) {
    // selectors
    const { setOpen } = useContext(PopoverContext);
    const showCloudConvertOption = useBooleanFlag(LaunchDarklyFlags.ENABLE_PDF_TO_HTML_CLOUD_CONVERT, false);
    return (_jsxs("div", Object.assign({ className: "space-y-2 p-4" }, { children: [(noticeType !== AdTypeRecommendation.Liner ||
                showCloudConvertOption) && (_jsx(FormattingOptionsRow, { id: "convert-to-text", onClick: () => __awaiter(this, void 0, void 0, function* () {
                    setOpen(false);
                    yield convertToLinerNotice();
                }), color: buildingStyles[0], icon: _jsx(PencilIcon, { className: `w-4 h-4 font-medium` }), mainText: showCloudConvertOption
                    ? 'Convert to text (text only)'
                    : 'Convert to text', helperText: showCloudConvertOption
                    ? 'Text in this file will be extracted (without formatting) in the text editor.'
                    : 'Extract the text from your document.' })), showCloudConvertOption && (_jsx(FormattingOptionsRow, { id: "convert-to-text", onClick: () => __awaiter(this, void 0, void 0, function* () {
                    setOpen(false);
                    yield convertToLinerNotice(true);
                }), color: buildingStyles[0], icon: _jsx(PencilIcon, { className: `w-4 h-4 font-medium` }), mainText: "Convert to text (text and formatting)", helperText: "Text in this file will be extracted along with formatting. This method may not work well scanned PDF files." })), noticeType !== AdTypeRecommendation.SyncDisplay && (_jsx(FormattingOptionsRow, { id: "convert-to-image", onClick: () => __awaiter(this, void 0, void 0, function* () {
                    setOpen(false);
                    yield convertToDisplayNotice();
                }), color: buildingStyles[3], icon: _jsx(PhotoIcon, { className: `w-4 h-4 font-medium` }), mainText: "Submit as an image", helperText: "Crop your notice and preserve original formatting." })), handleSubmitLargeFileWithoutFormatting &&
                noticeType !== AdTypeRecommendation.AsyncDisplay && (_jsx(FormattingOptionsRow, { id: "request-expert-formatting", onClick: () => {
                    handleSubmitLargeFileWithoutFormatting();
                    setOpen(false);
                }, color: buildingStyles[1], icon: _jsx(StarIcon, { className: `w-4 h-4 font-medium` }), mainText: "Request expert formatting", helperText: "Send your files to an expert to format your notice." }))] })));
}
export function NoticeFormattingOptionsDialog({ convertToDisplayNotice, convertToLinerNotice, handleSubmitLargeFileWithoutFormatting, noticeType }) {
    const helpText = noticeType === AdTypeRecommendation.SyncDisplay
        ? 'Click this button to submit your notice as text, or request that an expert format the notice.'
        : noticeType === AdTypeRecommendation.Liner
            ? 'Having trouble formatting? Click this button to submit your notice as an image and preserve the original formatting, or request that an expert format your notice.'
            : "Don't want to request expert formatting? Click this toggle to submit your notice as text or as an image.";
    return (_jsx(Popover, Object.assign({ id: "formatting-options-menu-button", headerText: 'Format Options', alignment: "right", activator: _jsx("div", { children: _jsx(Tooltip, Object.assign({ helpText: helpText, position: "topLeft" }, { children: _jsx(ColumnButton, { type: "button", secondary: true, buttonText: "Change file format", endIcon: _jsx(ArrowDownIcon, { className: "w-4 h-4" }), size: "sm" }) })) }) }, { children: _jsx(FormattingOptions, { convertToDisplayNotice: convertToDisplayNotice, convertToLinerNotice: convertToLinerNotice, handleSubmitLargeFileWithoutFormatting: handleSubmitLargeFileWithoutFormatting, noticeType: noticeType }) })));
}
