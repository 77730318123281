var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { SyncStatusCategory } from 'lib/enums';
import * as affinityXLibHelpers from 'lib/integrations/affinityx/helpers';
import { checkHasSyncEventWithStatus } from 'lib/integrations/affinityx/helpers';
import { AFFINITY_ORDER_NUMBER_INCREMENTORS } from 'lib/integrations/affinityx/types';
import { ColumnService } from 'lib/services/directory';
import { isUndefined } from 'lodash';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
export const getMaxAdHeightForTemplate = (adTemplate) => __awaiter(void 0, void 0, void 0, function* () {
    const reqBody = {
        templateId: adTemplate.id,
        forceRefresh: true
    };
    let styles;
    try {
        const resp = yield api.post('templates/styles', reqBody);
        if (!resp.success) {
            throw new Error('Unable to get template styles');
        }
        styles = resp.styles;
    }
    catch (err) {
        logAndCaptureException(ColumnService.AFFINITY_X, err, 'Unable to get template styles', {
            adTemplateId: adTemplate.id
        });
        return null;
    }
    const { pageHeight: pageHeightInPx, borderWidth: borderWidthInPx, pointsPerInch } = styles;
    if (!pointsPerInch || !pageHeightInPx || isUndefined(borderWidthInPx)) {
        return null;
    }
    const maxPageHeightInPx = pageHeightInPx - 2 * borderWidthInPx;
    const maxPageHeightInInches = maxPageHeightInPx / pointsPerInch;
    return maxPageHeightInInches;
});
const didTriggerEventHaveSuccessfulResolution = (triggerEvent) => {
    const context = getFirebaseContext();
    const successCategories = [SyncStatusCategory.success];
    return checkHasSyncEventWithStatus(context, triggerEvent, {
        categories: successCategories,
        statuses: []
    });
};
const didTriggerEventHaveUnsuccessfulResolution = (triggerEvent) => {
    const context = getFirebaseContext();
    const failureCategories = [
        SyncStatusCategory.column_errors,
        SyncStatusCategory.client_errors,
        SyncStatusCategory.publisher_system_errors
    ];
    return checkHasSyncEventWithStatus(context, triggerEvent, {
        categories: failureCategories,
        statuses: []
    });
};
export var AffinityXSyncStatus;
(function (AffinityXSyncStatus) {
    /**
     * Indicates that no sync has yet been attempted with the current order number, no sync is in progress,
     * and the last sync attempt did not fail
     */
    AffinityXSyncStatus["READY_TO_SYNC"] = "READY_TO_SYNC";
    /**
     * Indicates that a sync for this notice is already in progress
     */
    AffinityXSyncStatus["SYNC_IN_PROGRESS"] = "SYNC_IN_PROGRESS";
    /**
     * Indicates that the last sync was successful, but a new order number is required to sync again
     */
    AffinityXSyncStatus["SYNC_SUCCESSFUL"] = "SYNC_SUCCESSFUL";
    /**
     * Indicates that the last sync was cancelled, but a new order number is required to sync again
     */
    AffinityXSyncStatus["SYNC_CANCELLED_EDIT_REQUIRED"] = "SYNC_CANCELLED_EDIT_REQUIRED";
    /**
     * Indicates that the last attempt to sync failed at some point after the order was created in AffinityX, so a new order number is required to retry
     */
    AffinityXSyncStatus["SYNC_FAILED_AFTER_ORDER_CREATION"] = "SYNC_FAILED_AFTER_ORDER_CREATION";
    /**
     * Indicates that the last attempt to sync failed in Column before the order was created in AffinityX, so it is ready for a retry with the current order number
     */
    AffinityXSyncStatus["SYNC_FAILED_BEFORE_ORDER_CREATION"] = "SYNC_FAILED_BEFORE_ORDER_CREATION";
})(AffinityXSyncStatus || (AffinityXSyncStatus = {}));
export const getAffinityXSyncStatus = ({ affinityBuildEventsQuerySnap, orderNumber }) => __awaiter(void 0, void 0, void 0, function* () {
    // If we've never synced with this notice, then we can initiate a sync
    if (affinityBuildEventsQuerySnap.empty || !orderNumber) {
        return AffinityXSyncStatus.READY_TO_SYNC;
    }
    // The query to get the trigger events calls a descending sort on `createdAt`
    const mostRecentTriggerEvent = affinityBuildEventsQuerySnap.docs[0];
    const hasSuccessEvent = yield didTriggerEventHaveSuccessfulResolution(mostRecentTriggerEvent);
    const hasFailureEvent = yield didTriggerEventHaveUnsuccessfulResolution(mostRecentTriggerEvent);
    if (hasSuccessEvent && hasFailureEvent) {
        logAndCaptureMessage('[AffinityX] Expected only one terminal event for trigger, but got multiple', {
            triggerId: mostRecentTriggerEvent.id
        });
    }
    const mostRecentTriggerWasCancelled = yield affinityXLibHelpers.hasBuildRequestBeenCancelled(getFirebaseContext(), mostRecentTriggerEvent);
    if (!hasFailureEvent && !hasSuccessEvent && !mostRecentTriggerWasCancelled) {
        return AffinityXSyncStatus.SYNC_IN_PROGRESS;
    }
    /**
     * We cannot sync to AffinityX with the same order number twice
     *
     * Note: these checks should go after the in-progress check above, because
     * showing the user that a sync is in progress should take priority over
     * telling them that a new sync would be duplicative
     */
    const orderNumberHasAlreadyBeenSyncedToAffinity = yield affinityXLibHelpers.getOrderNumberHasAlreadyBeenSentToAffinity(getFirebaseContext(), orderNumber, affinityBuildEventsQuerySnap);
    /**
     * Similarly, with our current UX scenarios, if a sync was both cancelled
     * _and_ received a failure/success event, it is more important that the
     * sync show as cancelled. This is the reason for the order of the below
     * if clauses; changing this order could adversely affect the UX.
     */
    if (mostRecentTriggerWasCancelled &&
        orderNumberHasAlreadyBeenSyncedToAffinity) {
        return AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;
    }
    if (mostRecentTriggerWasCancelled &&
        !orderNumberHasAlreadyBeenSyncedToAffinity) {
        return AffinityXSyncStatus.READY_TO_SYNC;
    }
    if (hasSuccessEvent && orderNumberHasAlreadyBeenSyncedToAffinity) {
        return AffinityXSyncStatus.SYNC_SUCCESSFUL;
    }
    if (hasFailureEvent && orderNumberHasAlreadyBeenSyncedToAffinity) {
        return AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION;
    }
    if (hasFailureEvent && !orderNumberHasAlreadyBeenSyncedToAffinity) {
        return AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION;
    }
    return AffinityXSyncStatus.READY_TO_SYNC;
});
export const getButtonTextFromSyncStatus = (syncStatus) => {
    switch (syncStatus) {
        case AffinityXSyncStatus.READY_TO_SYNC:
        case AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION:
            return 'Start sync';
        case AffinityXSyncStatus.SYNC_IN_PROGRESS:
        case AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION:
        case AffinityXSyncStatus.SYNC_SUCCESSFUL:
            return 'Update order';
        case AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED:
            return 'Sync cancelled';
        default:
            return 'Start sync';
    }
};
const incrementOrderNumber = (previousOrderNumber, previousIncrementor, newIncrementor) => {
    if (previousIncrementor === '') {
        return `${previousOrderNumber}${newIncrementor}`;
    }
    return `${previousOrderNumber.slice(0, -1)}${newIncrementor}`;
};
const getNextValidAffinityXOrderNumber = (previousOrderNumber) => {
    const qualifiedIncrementor = affinityXLibHelpers.getOrderNumberIncrementor(previousOrderNumber);
    if (isUndefined(qualifiedIncrementor)) {
        throw new Error('AffinityX order number does not have qualified incrementor');
    }
    const previousIncrementorIndex = AFFINITY_ORDER_NUMBER_INCREMENTORS.indexOf(qualifiedIncrementor);
    const newIncrementor = AFFINITY_ORDER_NUMBER_INCREMENTORS[previousIncrementorIndex + 1];
    if (isUndefined(newIncrementor)) {
        throw new Error(`Cannot increment AffinityX order number: no more qualified incrementors`);
    }
    return incrementOrderNumber(previousOrderNumber, qualifiedIncrementor, newIncrementor);
};
export const getValidAffinityXOrderNumber = ({ customId, cancelEvents, mostRecentTriggerEvent, incrementOrderNumberIfUsed }) => {
    if (!affinityXLibHelpers.isAffinityXOrderNumber(customId)) {
        throw new Error('Custom ID invalid for AffinityX sync');
    }
    if (!mostRecentTriggerEvent) {
        return customId;
    }
    const mostRecentTriggerWasCancelled = !!cancelEvents.find(cancelEvent => cancelEvent.data().data.initialOrderRequest.id ===
        mostRecentTriggerEvent.id);
    if (mostRecentTriggerWasCancelled && incrementOrderNumberIfUsed) {
        return getNextValidAffinityXOrderNumber(mostRecentTriggerEvent.data().data.orderNumber);
    }
    return mostRecentTriggerEvent.data().data.orderNumber;
};
export const __private = {
    incrementOrderNumber
};
