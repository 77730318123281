import { StateData } from './State';
export const MailCenterData = {
    mail_world: {
        value: 1,
        label: 'Mail World',
        key: 'mail_world',
        state: StateData.oklahoma.value
    },
    charleston_mail_n_more: {
        value: 2,
        key: 'charleston_mail_n_more',
        label: 'Charleston Mail & More',
        state: StateData.south_carolina.value
    },
    the_mailing_room: {
        value: 3,
        key: 'the_mailing_room',
        label: 'The Mailing Room',
        state: StateData.alabama.value
    }
};
