export const isFileUploadQuestionInputValue = (val) => {
    if (typeof val === 'object' && val !== null) {
        const keys = Object.keys(val);
        return !!(keys.includes('sanitizedFileName') &&
            keys.includes('storagePath') &&
            keys.includes('linkToUploadedFile'));
    }
    return false;
};
export const clearDataForEditorOverride = (data) => {
    var _a;
    // Keep only the file uploads, destroy all the other question answers
    const questionTemplateData = {};
    for (const e of Object.entries((_a = data === null || data === void 0 ? void 0 : data.questionTemplateData) !== null && _a !== void 0 ? _a : {})) {
        const [k, v] = e;
        if (isFileUploadQuestionInputValue(v)) {
            questionTemplateData[k] = v;
        }
    }
    return {
        templateData: {},
        questionTemplateData,
        metadata: {},
        editorOverride: true
    };
};
