import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Grid, Typography, Modal, IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';
import EButton from '../EButton';
const styles = (theme) => createStyles({
    modalContent: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        maxWidth: 900,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3, 8, 3),
        textAlign: 'center',
        outline: 'none'
    },
    cancelButton: {
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important;`
        },
        maxWidth: '50%',
        paddingLeft: 10,
        paddingRight: 10
    },
    headingText: {
        color: theme.palette.grey[700],
        textTransform: 'uppercase',
        position: 'relative'
    },
    partition: {
        padding: theme.spacing(1)
    },
    progress: {
        color: 'white'
    },
    heading: {
        fontWeight: 600
    },
    closeIcon: {
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        color: theme.palette.grey[600]
    }
});
function EModal({ classes, setOpen, header, body, onConfirm, buttonText, id, setClose }) {
    return (_jsx(Modal, Object.assign({ id: id, onBackdropClick: () => (setOpen ? setOpen(false) : null), open: true }, { children: _jsxs(Grid, Object.assign({ className: classes === null || classes === void 0 ? void 0 : classes.modalContent }, { children: [_jsxs(Grid, Object.assign({ className: classes === null || classes === void 0 ? void 0 : classes.partition }, { children: [setOpen && (_jsx(IconButton, Object.assign({ size: "small", className: classes === null || classes === void 0 ? void 0 : classes.closeIcon, onClick: () => (setClose ? setClose() : setOpen(false)) }, { children: _jsx(Cancel, {}) }))), _jsx(Typography, Object.assign({ variant: "subtitle2", className: classes === null || classes === void 0 ? void 0 : classes.heading }, { children: header }))] })), _jsx(Grid, Object.assign({ className: classes === null || classes === void 0 ? void 0 : classes.partition }, { children: _jsx(Typography, Object.assign({ variant: "subtitle2" }, { children: body })) })), _jsxs(Grid, Object.assign({ container: true, className: classes === null || classes === void 0 ? void 0 : classes.partition, alignItems: "center" }, { children: [_jsx(Grid, { item: true, xs: true }), _jsx(EButton, Object.assign({}, (id ? { id: `confirm-${id}` } : {}), { className: classes === null || classes === void 0 ? void 0 : classes.cancelButton, onClick: () => onConfirm() }, { children: buttonText })), _jsx(Grid, { item: true, xs: true })] }))] })) })));
}
/** @deprecated Use Modal instead */
export default withStyles(styles)(EModal);
