var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { exists } from 'lib/types';
import { BuildingIcon } from 'icons';
import { extractCustomerOrganizationDataFromOrganization } from 'routes/settings/publisher/customers/customerOrganizationTable/utils';
import { buildingStyles } from 'lib/components/UserIDCard';
import { Badge } from 'lib/components/Badge';
import CustomerTableLoader from './CustomerTableLoader';
function CustomerDrawerOrganizationDisplay(displayName, displayID, index, isPending) {
    return (_jsx("div", Object.assign({ className: "px-4 flex w-full border-b border-grey-50" }, { children: _jsxs("div", Object.assign({ className: "flex w-full items-center justify-between pl-4 pb-4 pt-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: `${buildingStyles[index % buildingStyles.length]} w-10 h-10 rounded-full flex items-center justify-center` }, { children: _jsx(BuildingIcon, { className: "w-5 h-5" }) })), _jsxs("div", Object.assign({ className: "flex gap-2 align-middle" }, { children: [_jsx("div", Object.assign({ className: "pl-4 text-grey-500" }, { children: displayName })), isPending && _jsx(Badge, Object.assign({ status: "warning" }, { children: "Pending" }))] }))] })), _jsx("div", Object.assign({ className: "pl-4 mt-1 text-grey-400" }, { children: displayID }))] })) })));
}
export function CustomerDrawerOrganizationTab({ customerOrganizations, loadingCustomerOrganizations, setShowCustomerOrganizationDrawer, setCustomerOrganizationToDisplay, allowedOrganizationNames, invitedOrganizatonNames }) {
    return (_jsx("div", Object.assign({ className: "flex flex-col items-start text-sm leading-6 font-medium w-full" }, { children: _jsxs("div", Object.assign({ className: "pt-4 pb-16 h-75vh max-h-full overflow-y-scroll hide-scrollbar text-sm leading-6 font-medium w-full" }, { children: [loadingCustomerOrganizations && _jsx(CustomerTableLoader, {}), !loadingCustomerOrganizations &&
                    (customerOrganizations === null || customerOrganizations === void 0 ? void 0 : customerOrganizations.map((customerOrganization, index) => {
                        return (_jsx("div", Object.assign({ className: "flex w-full hover:bg-blue-50 cursor-pointer", onClick: () => __awaiter(this, void 0, void 0, function* () {
                                if (exists(customerOrganization)) {
                                    const customerOrganizationTableData = yield extractCustomerOrganizationDataFromOrganization(customerOrganization);
                                    if (customerOrganizationTableData) {
                                        setShowCustomerOrganizationDrawer();
                                        setCustomerOrganizationToDisplay(customerOrganizationTableData);
                                    }
                                }
                            }) }, { children: CustomerDrawerOrganizationDisplay((customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().name) ||
                                allowedOrganizationNames[index] ||
                                '', (customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().internalID) || '--', index, false) }), index));
                    })), invitedOrganizatonNames.map((organizationName, index) => {
                    return (_jsx("div", Object.assign({ className: "flex w-full" }, { children: CustomerDrawerOrganizationDisplay(`${organizationName}` || '', '--', index, true) }), index));
                })] })) })));
}
