var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFulfilled, getRejected } from 'lib/helpers';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { getProductDeadlineTimeForPaper } from 'lib/utils/deadlines';
import { BadRequestError, InternalServerError, NotFoundError, wrapErrorAsColumnError } from 'lib/errors/ColumnErrors';
import { getFirebaseContext } from 'utils/firebase';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { safeGetModelFromRef } from 'lib/model/getModel';
const getAdDeadlineForNewspaperOrder = (product, newspaperOrder) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const newspaperRef = newspaperOrder.newspaper;
    const { publishingMedium } = newspaperOrder;
    if (!newspaperRef) {
        return wrapError(new BadRequestError('Newspaper not set on newspaperOrder'));
    }
    if (!publishingMedium) {
        return wrapError(new BadRequestError('Publishing medium not set on newspaperOrder'));
    }
    if (!newspaperOrder.publishingDates ||
        newspaperOrder.publishingDates.length < 1) {
        return wrapError(new BadRequestError(`No publishing dates found for paper: ${(_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id}.`));
    }
    const { response: newspaper, error: newspaperError } = yield safeGetModelFromRef(OrganizationModel, getFirebaseContext(), newspaperRef);
    if (newspaperError) {
        return wrapErrorAsColumnError(newspaperError, NotFoundError);
    }
    const [earliestPublishingDate] = newspaperOrder.publishingDates;
    const { response: deadlineTime, error: deadlineTimeError } = yield getProductDeadlineTimeForPaper(newspaper, product, publishingMedium, earliestPublishingDate);
    if (deadlineTimeError) {
        return wrapError(deadlineTimeError);
    }
    if (!deadlineTime) {
        return wrapError(new NotFoundError('No deadline found'));
    }
    return wrapSuccess(deadlineTime.deadlineMoment);
});
export const getSoonestAdDeadline = (product, newspaperOrdersFormData) => __awaiter(void 0, void 0, void 0, function* () {
    const deadlinePromises = yield Promise.allSettled(newspaperOrdersFormData.map(newspaperOrder => getAdDeadlineForNewspaperOrder(product, newspaperOrder)));
    const deadlines = [];
    const rejectedPromise = getRejected(deadlinePromises)[0];
    if (rejectedPromise) {
        logAndCaptureCriticalError(ColumnService.OBITS, rejectedPromise, '[OBITS BETA] Error getting ad deadlines for obits publication', {
            publishers: newspaperOrdersFormData
                .map(({ newspaper }) => newspaper === null || newspaper === void 0 ? void 0 : newspaper.id)
                .join(', ')
        });
        return wrapError(new InternalServerError('Error getting ad deadlines'));
    }
    const deadlineResponses = getFulfilled(deadlinePromises);
    for (const { response: deadline, error: deadlineError } of deadlineResponses) {
        if (deadlineError) {
            logAndCaptureCriticalError(ColumnService.OBITS, deadlineError, '[OBITS BETA] Error getting ad deadlines for obits publication. Please make sure that all publications selected have valid obit deadlines enabled.', {
                publishers: newspaperOrdersFormData
                    .map(({ newspaper }) => newspaper === null || newspaper === void 0 ? void 0 : newspaper.id)
                    .join(', ')
            });
            return wrapError(new InternalServerError('Error getting ad deadlines'));
        }
        deadlines.push(deadline);
    }
    if (deadlines.length === 0) {
        return wrapError(new InternalServerError('No valid ad deadlines found'));
    }
    const earliestDeadline = deadlines.reduce((min, current) => current.isBefore(min) ? current : min);
    return wrapSuccess(earliestDeadline);
});
