var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppSelector } from 'redux/hooks';
import { push } from 'connected-react-router';
import { BellIcon } from '@heroicons/react/24/outline';
import AuthActions from 'redux/auth';
import { connect } from 'react-redux';
import { RoleType, InviteStatus } from 'lib/enums';
import { OPEN_INVITE_STATUSES } from 'lib/users';
import { getFirebaseContext } from 'utils/firebase';
import { safeStringify } from 'lib/utils/stringify';
import { calculateTimeDifferenceFromTimestamp, logAndCaptureException } from 'utils';
import { exists } from 'lib/types';
import HeaderBarClickableIcon from 'layouts/appLayout/HeaderBarClickableIcon';
import { acceptInvitesHelper, acceptRequestHelper, declineInviteHelper, declineRequestHelper, transformInvitesToActionCardInvites, transformRequestsToActionCard } from 'components/modals/JoinOrganizationModals/helpers';
import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import RequestActionCard from 'components/requestsComponents/RequestActionCard';
import Drawer from 'lib/components/Drawer';
import HorizontalDivider from 'components/HorizontalDivider';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';
import EmptyNotificationTray from './EmptyNotificationTray';
import { getNotificationsEmoji, getNotificationTypeStyling } from './NotificationTypesStyles';
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path)),
    authActions: bindActionCreators(AuthActions, dispatch)
});
function Notifications({ authActions, user, push }) {
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [invites, setInvites] = useState([]);
    const [requests, setRequests] = useState([]);
    const [clearingNotifications, setClearingNotifications] = useState(false);
    const [decliningInvitesLoading, setDecliningInvitesLoading] = useState(false);
    const [acceptingInvitesLoading, setAcceptingInvitesLoading] = useState(false);
    const [transformedInvites, setTransformedInvites] = useState();
    const [transformedOrganizationRequests, setTransformedOrganizationRequests] = useState();
    const [joinRequests, setJoinRequests] = useState([]);
    const [title, setTitle] = useState('General');
    const activeOrganization = useAppSelector(state => state.auth.activeOrganization || undefined);
    const tableTitleHeadStyles = 'inline-block my-3 text-lg text-black text-left pt-3 focus:outline-none font-medium pb-3 border-b-2 border-transparent hover:border-b-2 hover:border-column-primary-600 hover:text-column-primary-900';
    const highlight = 'border-b-2 border-column-primary-600 text-column-primary-900';
    const highlightCount = 'bg-column-primary-100 text-column-primary-900';
    const tableTitleTabStyles = 'ml-2 rounded-full px-3 py-0.25 text-sm';
    const emptyNotificationTrayHeight = 'my-48';
    const allNotifications = notifications.length +
        invites.length +
        requests.length +
        joinRequests.length;
    const invitesAndRequestsNotifications = invites.length + requests.length + joinRequests.length;
    const ctx = getFirebaseContext();
    const getUserNotifications = () => {
        return ctx
            .notificationsRef()
            .where('user', '==', user.ref)
            .where('inApp.read', '==', false)
            .orderBy('created', 'desc')
            .limit(51)
            .onSnapshot(notificationsSnaps => {
            setNotifications(notificationsSnaps.docs);
        });
    };
    const getInvites = () => {
        var _a;
        return ctx
            .invitesRef()
            .where('email', '==', (_a = user.data()) === null || _a === void 0 ? void 0 : _a.email)
            .where('status', '==', InviteStatus.snoozed.value)
            .orderBy('createdAt', 'desc')
            .onSnapshot(invitesSnaps => {
            setInvites(invitesSnaps.docs);
        });
    };
    // Requests that individual send to organization at the time of registration,
    // this query get results for individual users
    const getIndividualRequests = () => {
        return ctx
            .joinRequestsRef()
            .where('email', '==', user.data().email)
            .where('status', 'in', OPEN_INVITE_STATUSES)
            .orderBy('createdAt', 'desc')
            .onSnapshot((requestsSnaps) => __awaiter(this, void 0, void 0, function* () {
            yield transformRequests(requestsSnaps.docs);
        }));
    };
    // Requests that individual send to organization at the time of registration,
    // this query works to show notification for organization users
    const getOrganizationRequests = () => {
        var _a;
        if (exists(activeOrganization) &&
            ((_a = user.data().roles) === null || _a === void 0 ? void 0 : _a[activeOrganization.id]) === RoleType.admin.value) {
            return ctx
                .joinRequestsRef()
                .where('organization', '==', activeOrganization.ref)
                .where('status', '==', InviteStatus.snoozed.value)
                .orderBy('createdAt', 'desc')
                .onSnapshot((requestsSnaps) => __awaiter(this, void 0, void 0, function* () {
                setJoinRequests(requestsSnaps.docs);
            }));
        }
        return () => { };
    };
    const transformOrganizationRequests = () => __awaiter(this, void 0, void 0, function* () {
        const transformedRequests = yield transformRequestsToActionCard(joinRequests, ctx);
        setTransformedOrganizationRequests(transformedRequests);
    });
    const transformRequests = (requests) => __awaiter(this, void 0, void 0, function* () {
        const requestItemsList = [];
        yield Promise.all(requests.map((doc) => __awaiter(this, void 0, void 0, function* () {
            const organizationSnap = yield doc.data().organization.get();
            if (!exists(organizationSnap))
                return;
            const reqObject = {
                joinRequestRef: doc.ref,
                organizationName: organizationSnap.data().name
            };
            requestItemsList.push(reqObject);
        })));
        setRequests(requestItemsList);
    });
    const transformedUserInvites = () => __awaiter(this, void 0, void 0, function* () {
        const transformedInvites = yield transformInvitesToActionCardInvites(invites);
        setTransformedInvites(transformedInvites);
    });
    useEffect(() => {
        const notificationsUnsub = getUserNotifications();
        const invitesUnsub = getInvites();
        const requestsUnsub = getIndividualRequests();
        return () => {
            notificationsUnsub();
            invitesUnsub();
            requestsUnsub();
        };
    }, []);
    useEffect(() => {
        if (exists(activeOrganization)) {
            const orgRequestsUnsub = getOrganizationRequests();
            return () => {
                orgRequestsUnsub();
            };
        }
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    useEffect(() => {
        void transformedUserInvites();
    }, [safeStringify(invites)]);
    useEffect(() => {
        void transformOrganizationRequests();
    }, [safeStringify(joinRequests)]);
    const markNotificationAsRead = (notificationSnap) => __awaiter(this, void 0, void 0, function* () {
        const { inApp } = notificationSnap.data();
        const updateObj = Object.assign({}, inApp);
        updateObj.read = true;
        if (exists(notificationSnap))
            yield notificationSnap.ref.update({ inApp: updateObj });
    });
    // Render list of all General notifications
    const listOfGeneralNotifications = () => notifications.map((docSnapshot, index) => {
        const { inApp, created, notice } = docSnapshot.data();
        const { img, styles } = getNotificationTypeStyling(inApp.key);
        const link = (inApp && inApp.link) || (notice && `/notice/${notice.id}`) || '';
        return (_jsxs("div", Object.assign({ 
            // inApp.key is not related to design -- it's purpose is to differentiate notifications in pendo
            className: `${inApp.key} flex py-5 px-6 cursor-pointer`, onClick: () => {
                setOpen(false);
                push(link);
                void markNotificationAsRead(docSnapshot);
            } }, { children: [_jsx("div", Object.assign({ className: `${styles} w-12 h-12 rounded-full flex items-center justify-center p-4` }, { children: img })), _jsxs("div", Object.assign({ className: `${!inApp.body && 'flex items-center'} ml-5 w-full` }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-between" }, { children: [_jsxs("div", Object.assign({ className: "text-column-primary-800 text-sm font-medium" }, { children: [inApp.text, getNotificationsEmoji(inApp.key)] })), _jsx("div", Object.assign({ className: "text-column-gray-300 text-xs font-medium" }, { children: _jsx("p", { children: calculateTimeDifferenceFromTimestamp(created) }) }))] })), _jsx("p", Object.assign({ className: "mt-1 text-column-gray-400 text-xs font-medium" }, { children: inApp.body }))] }))] }), index));
    });
    const updateUserRoles = (roleValue, index) => {
        if (transformedOrganizationRequests) {
            const currTransformRequests = [...transformedOrganizationRequests];
            currTransformRequests[index].role = RoleType.by_label(roleValue).value;
            setTransformedOrganizationRequests(currTransformRequests);
        }
    };
    // Render list of all snoozed invites
    const renderSnoozedInvitesList = () => transformedInvites &&
        transformedInvites.map((invite, index) => {
            return (_jsx("div", { children: _jsx(InviteActionCard, { invite: invite, onAcceptClick: () => acceptInvite(invite.userInvite), onDeclineClick: () => declineInvite(invite.userInvite), organization: activeOrganization, index: index, className: 'border-b', type: "invite" }) }, `user-invite-${invite.userInvite.id}`));
        });
    const renderJoinOrganizationRequests = () => transformedOrganizationRequests &&
        transformedOrganizationRequests.map((request, index) => {
            return (_jsx("div", { children: _jsx(InviteActionCard, { request: request, index: index, type: 'request', className: 'border-b', organization: activeOrganization, updateUserRole: roleValue => updateUserRoles(roleValue, index), onAcceptClick: () => acceptRequest(request), onDeclineClick: () => declineRequest(request) }) }, `user-request-${request.userRequest.id}`));
        });
    // Render pending/snoozed requests list
    const renderRequestsList = () => requests.length > 0 &&
        requests.map((request, index) => {
            return (_jsx("div", { children: _jsx(RequestActionCard, { request: request, index: index, className: 'border-b' }) }, `user-request-${request.joinRequestRef.id}`));
        });
    const clearAllNotifications = () => __awaiter(this, void 0, void 0, function* () {
        yield Promise.all(notifications.map((notification) => __awaiter(this, void 0, void 0, function* () {
            yield markNotificationAsRead(notification);
        })));
    });
    const declineInvite = (inviteSnap) => __awaiter(this, void 0, void 0, function* () {
        yield declineInviteHelper(user, inviteSnap);
    });
    const acceptInvite = (inviteSnap) => __awaiter(this, void 0, void 0, function* () {
        const { organizationId, email } = inviteSnap.data();
        try {
            yield acceptInvitesHelper(ctx, [inviteSnap], user, authActions);
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Failed to join organization from invite', {
                userEmail: email || '',
                orgId: organizationId || '',
                inviteId: inviteSnap.id || ''
            });
        }
    });
    const acceptAllInvites = () => __awaiter(this, void 0, void 0, function* () {
        if (!(transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.length)) {
            return;
        }
        try {
            yield Promise.all(transformedInvites.map((invite) => __awaiter(this, void 0, void 0, function* () {
                const inviteSnap = invite.userInvite;
                yield acceptInvite(inviteSnap);
            })));
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error accepting all invites', {
                userId: user.id
            });
        }
    });
    const declineAllInvites = () => __awaiter(this, void 0, void 0, function* () {
        if (!(transformedInvites === null || transformedInvites === void 0 ? void 0 : transformedInvites.length)) {
            return;
        }
        try {
            yield Promise.all(transformedInvites.map((invite) => __awaiter(this, void 0, void 0, function* () {
                const inviteSnap = invite.userInvite;
                yield declineInvite(inviteSnap);
            })));
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error declining all invites', {
                userId: user.id
            });
        }
    });
    const acceptRequest = (joinRequest) => __awaiter(this, void 0, void 0, function* () {
        yield acceptRequestHelper(ctx, [joinRequest]);
    });
    const declineRequest = (joinRequest) => __awaiter(this, void 0, void 0, function* () {
        yield declineRequestHelper([joinRequest]);
    });
    const acceptAllRequests = () => __awaiter(this, void 0, void 0, function* () {
        if (!(transformedOrganizationRequests === null || transformedOrganizationRequests === void 0 ? void 0 : transformedOrganizationRequests.length))
            return;
        yield acceptRequestHelper(ctx, transformedOrganizationRequests);
    });
    const declineAllRequests = () => __awaiter(this, void 0, void 0, function* () {
        if (!(transformedOrganizationRequests === null || transformedOrganizationRequests === void 0 ? void 0 : transformedOrganizationRequests.length))
            return;
        yield declineRequestHelper(transformedOrganizationRequests);
    });
    const closeDrawer = () => {
        setOpen(!open);
    };
    const header = (_jsx("div", Object.assign({ className: "text-column-gray-500 font-semibold text-xl" }, { children: "Notifications" })));
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "relative" }, { children: [_jsx("div", { children: _jsx("div", Object.assign({ className: "flex inline-block relative bg-white p-1 rounded-full text-white hover:text-column-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" }, { children: _jsx(HeaderBarClickableIcon, { id: "notifications-icon", icon: _jsxs(_Fragment, { children: [allNotifications > 0 && (_jsx("span", Object.assign({ className: `pt-0.25 absolute top-0 right-0 block h-4 w-4 rounded-circle bg-yellow-350 items-center justify-center text-column-gray-500 font-bold text-xxs -mt-1 -mr-1 ${notifications.length > 50 && 'w-6'}` }, { children: allNotifications > 50 ? '50+' : allNotifications }))), _jsx(BellIcon, { className: "w-6 h-6" })] }), onClick: () => setOpen(!open), label: "Notifications" }) })) }), open && (_jsx(Drawer, Object.assign({ open: open, onClose: closeDrawer, header: header }, { children: _jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "h-16" }, { children: _jsxs("div", Object.assign({ className: "inline-block" }, { children: [_jsx("div", Object.assign({ className: 'inline-block mx-6' }, { children: _jsx("button", Object.assign({ onClick: () => setTitle('General'), id: "general", className: `${tableTitleHeadStyles} ${title === 'General'
                                                        ? highlight
                                                        : 'text-column-primary-800'}` }, { children: _jsxs("div", Object.assign({ className: "flex" }, { children: ["General", _jsx("p", Object.assign({ className: `${tableTitleTabStyles} ${title === 'General'
                                                                    ? highlightCount
                                                                    : 'bg-column-gray-50 text-column-primary-800'}` }, { children: notifications.length > 50
                                                                    ? '50+'
                                                                    : notifications.length }))] })) })) })), _jsx("div", Object.assign({ className: 'inline-block mx-1' }, { children: _jsx("button", Object.assign({ onClick: () => setTitle('Team Invites'), id: "team-invites", className: `${tableTitleHeadStyles} ${title === 'Team Invites'
                                                        ? highlight
                                                        : 'text-column-gray-500'}` }, { children: _jsxs("div", Object.assign({ className: "flex" }, { children: ["Team Invites", _jsx("p", Object.assign({ className: `${tableTitleTabStyles} ${title === 'Team Invites'
                                                                    ? highlightCount
                                                                    : 'bg-column-gray-50 text-column-primary-800'}` }, { children: invitesAndRequestsNotifications > 50
                                                                    ? '50+'
                                                                    : invitesAndRequestsNotifications }))] })) })) }))] })) })), _jsx(HorizontalDivider, {}), _jsxs("div", { children: [title === 'General' &&
                                            (notifications.length === 0 ? (_jsx("div", Object.assign({ className: emptyNotificationTrayHeight }, { children: _jsx(EmptyNotificationTray, {}) }))) : (_jsx("div", Object.assign({ className: "h-75vh max-h-full overflow-y-scroll hide-scrollbar md:pb-5 divide-y" }, { children: listOfGeneralNotifications() })))), title === 'Team Invites' &&
                                            (invitesAndRequestsNotifications === 0 ? (_jsx("div", Object.assign({ className: emptyNotificationTrayHeight }, { children: _jsx(EmptyNotificationTray, {}) }))) : (_jsxs("div", Object.assign({ className: "h-75vh max-h-full overflow-y-scroll hide-scrollbar md:pb-5" }, { children: [renderSnoozedInvitesList(), renderRequestsList(), renderJoinOrganizationRequests()] }))))] }), title === 'General' && notifications.length > 0 && (_jsx("div", Object.assign({ className: "absolute bottom-0 px-6 py-3 shadow flex w-full justify-start border-t bg-white" }, { children: _jsx(ColumnButton, { id: "clear-all-notifications", size: "lg", buttonText: "Clear all", loading: clearingNotifications, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                            setClearingNotifications(true);
                                            try {
                                                yield clearAllNotifications();
                                            }
                                            finally {
                                                setClearingNotifications(false);
                                            }
                                        }), type: "button" }) }))), title === 'Team Invites' &&
                                    (invites.length > 0 || joinRequests.length > 0) && (_jsxs("div", Object.assign({ className: "absolute bottom-0 px-6 py-3 shadow w-full flex justify-between border-t bg-white" }, { children: [_jsx(ColumnButton, { id: "decline-all-invites", size: "lg", buttonText: "Decline all", loading: decliningInvitesLoading, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                                setDecliningInvitesLoading(true);
                                                try {
                                                    yield declineAllInvites();
                                                    yield declineAllRequests();
                                                }
                                                finally {
                                                    setDecliningInvitesLoading(false);
                                                }
                                            }), type: "button" }), _jsx(ColumnButton, { id: "accept-all-invites", primary: true, size: "lg", buttonText: "Accept all", loading: acceptingInvitesLoading, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                                setAcceptingInvitesLoading(true);
                                                try {
                                                    yield acceptAllInvites();
                                                    yield acceptAllRequests();
                                                }
                                                finally {
                                                    setAcceptingInvitesLoading(false);
                                                }
                                            }), type: "button" })] })))] }) })))] })), _jsx("style", { children: `
              button#team-invites:hover p {
                background-color: #EAF5FB;
                color: #2D9BDB;
              }
              
              button#general:hover p {
                background-color: #EAF5FB;
                color: #2D9BDB;
              }
            ` })] }));
}
export default connect(null, mapDispatchToProps)(Notifications);
