import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { Badge } from 'lib/components/Badge';
import { isNoticeRate } from 'lib/types/rates';
import { isDefaultRate, isLegacyRate } from '../ratesTableSettingsUtils';
/**
 * Header component for the e-edition drawer
 */
export default function RateDrawerHeader({ activeOrganization, rate }) {
    const [localRate, setLocalRate] = useState(rate);
    useEffect(() => {
        const unsub = rate.ref.onSnapshot(newSnapshot => {
            if (exists(newSnapshot))
                setLocalRate(newSnapshot);
        });
        return () => unsub();
    }, [rate.id]);
    const rateData = rate.data();
    return (_jsxs("div", Object.assign({ className: "flex items-center text-column-gray-800 font-semibold text-xl gap-2" }, { children: [_jsx("div", { children: localRate.data().description }), isNoticeRate(rateData) && isDefaultRate(activeOrganization, rate.id) && (_jsx(Badge, Object.assign({ status: "success" }, { children: "Default Rate" }))), isLegacyRate(rateData) && _jsx(Badge, { children: "Legacy Rate" })] })));
}
