import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { MilestoneCheckIcon, DotIcon, MilestoneDiagonalArrowIcon, QuestionMarkWithCircleIcon, XIcon } from 'icons';
import { Tooltip } from 'lib/components/Tooltip';
import classNames from 'classnames';
function MilestoneNode({ milestoneElement }) {
    const { status, text, tooltip } = milestoneElement;
    return (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: classNames('flex items-center justify-center h-5 w-5 rounded-full border-1.5', {
                    'border-column-primary-500': [
                        'complete',
                        'completeOutsideColumn',
                        'active'
                    ].includes(status),
                    'border-column-gray-300': status === 'inactive',
                    'border-red-500': status === 'error'
                }) }, { children: _jsx("div", Object.assign({ className: "shrink-0" }, { children: getMilestoneIcon(status) })) })), _jsx("div", Object.assign({ className: classNames('ml-2 font-medium text-sm xl:text-base leading-4', {
                    'text-column-primary-500': [
                        'complete',
                        'completeOutsideColumn'
                    ].includes(status),
                    'text-column-gray-500': status === 'active',
                    'text-column-gray-300': status === 'inactive',
                    'text-red-500': status === 'error'
                }) }, { children: text })), _jsx("div", Object.assign({ className: "milestone-text flex items-center" }, { children: tooltip && (_jsx(Tooltip, Object.assign({ id: "milestone-help-text", helpText: tooltip, position: 'top', innerBackgroundColor: "gray" }, { children: _jsx("div", { children: status === 'completeOutsideColumn' && (_jsx(QuestionMarkWithCircleIcon, { className: "h-4 w-4 ml-1 text-grey-300" })) }) }))) }))] })));
}
function getMilestoneIcon(status) {
    switch (status) {
        case 'error':
            return _jsx(XIcon, { className: "h-4 w-4 text-red-500" });
        case 'complete':
            return _jsx(MilestoneCheckIcon, { className: "text-column-primary-500" });
        case 'inactive':
            return _jsx(DotIcon, { className: "text-column-gray-300" });
        case 'active':
            return _jsx(DotIcon, { className: "text-column-primary-500" });
        case 'completeOutsideColumn':
            return _jsx(MilestoneDiagonalArrowIcon, { className: "text-column-primary-500" });
    }
}
export default MilestoneNode;
