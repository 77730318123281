import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { clearAllBooleanFlagOverrides, getAllFeatureFlags, getBooleanFlagOverride, setBooleanFlagOverride } from 'utils/flags';
import { Autocomplete } from 'lib/components/Autocomplete';
import SettingsHeader from '../SettingsHeader';
function UserSettingsFeatureFlags() {
    const [keySelection, setKeySelection] = useState();
    const [valueSelection, setValueSelection] = useState('true');
    const [overrides, setOverrides] = useState([]);
    const allFlags = useMemo(() => getAllFeatureFlags(), []);
    const options = Object.keys(allFlags)
        .sort((a, b) => a.localeCompare(b))
        .map(v => ({
        label: v,
        value: v
    }));
    const booleanOptions = [
        {
            label: 'true',
            value: 'true'
        },
        {
            label: 'false',
            value: 'false'
        }
    ];
    const getOverrides = () => {
        const res = [];
        for (const o of options) {
            const key = o.value;
            const value = getBooleanFlagOverride(key);
            if (typeof value === 'boolean') {
                res.push({
                    key,
                    value
                });
            }
        }
        setOverrides(res);
    };
    const handleSetOverrideClick = () => {
        if (!keySelection) {
            return;
        }
        setBooleanFlagOverride(keySelection, valueSelection === 'true');
        setKeySelection(undefined);
        setValueSelection('true');
        getOverrides();
    };
    const handleDeleteOverrideCLick = (key) => {
        setBooleanFlagOverride(key, undefined);
        getOverrides();
    };
    const handleClearAllClick = () => {
        clearAllBooleanFlagOverrides();
        getOverrides();
    };
    useEffect(() => {
        getOverrides();
    }, []);
    return (_jsxs("div", Object.assign({ className: "bg-white sm:rounded-lg border border-gray-300 shadow" }, { children: [_jsx(SettingsHeader, { header: "Feature Flags", description: "Internal only! Override feature flags." }), _jsxs("div", Object.assign({ className: "px-5 py-3" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row gap-4 mb-4" }, { children: [_jsx("div", Object.assign({ className: "w-1/4" }, { children: _jsx(Autocomplete, { id: "flag-key", labelText: "Flag Key", options: options, value: keySelection, onChange: setKeySelection }) })), _jsx("div", Object.assign({ className: "w-1/4" }, { children: _jsx(ColumnSelect, { labelText: "Flag Value", id: "flag-value", options: booleanOptions, value: `${valueSelection}`, onChange: setValueSelection }) }))] })), _jsx("div", Object.assign({ className: "w-1/4 mb-2" }, { children: _jsx(ColumnButton, { buttonText: "Set Override", primary: true, onClick: handleSetOverrideClick, disabled: !keySelection, type: "button" }) })), _jsxs("div", Object.assign({ className: "flex flex-row items-center mt-6 mb-2" }, { children: [_jsx("h3", Object.assign({ className: "mr-auto font-bold" }, { children: "Overrides" })), _jsx(ColumnButton, { disabled: !overrides.length, destructive: true, tertiary: true, buttonText: "Clear All", size: "sm", onClick: handleClearAllClick, type: "button" })] })), overrides.length === 0 && (_jsx("span", Object.assign({ className: "text-gray-500 text-sm mb-4" }, { children: "No overrides" }))), _jsx("table", Object.assign({ className: "border-collapse border border-slate-400 mb-4" }, { children: overrides.map(o => {
                            return (_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "border border-slate-400 px-4 py-2" }, { children: _jsx("code", { children: o.key }) })), _jsx("td", Object.assign({ className: "border border-slate-400 px-4 py-2" }, { children: _jsx("code", { children: `${o.value}` }) })), _jsx("td", Object.assign({ className: "border border-slate-400 px-4 py-2" }, { children: _jsx("span", Object.assign({ className: "text-red-500 hover:text-red-600 cursor-pointer", onClick: () => handleDeleteOverrideCLick(o.key) }, { children: "Delete" })) }))] }, o.key));
                        }) }))] }))] })));
}
export default UserSettingsFeatureFlags;
