import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import { isFlatAdditionalFee, isPercentAdditionalFee } from 'lib/types/rates';
import { getAdditionalFeeChargeMethodDescriptionFromAdditionalFee, getAdditionalFeeTypeDescription } from '../../ratesUtils';
import DrawerPropertyRow from '../../../DrawerPropertyRow';
import FormSwitch from '../../../FormSwitch';
/**
 * Tab for displaying and enabling (or removing) additional fees for a rate.
 */
export default function AdditionalFeesTab({ rate }) {
    const { additionalFees } = rate.data();
    return (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: "Enabled", value: _jsx(FormSwitch, { disabled: true, checked: !!(additionalFees === null || additionalFees === void 0 ? void 0 : additionalFees.length) }) }), additionalFees &&
                additionalFees.map((additionalFee, index) => (_jsxs(React.Fragment, { children: [_jsxs("div", Object.assign({ className: "py-3.5 px-4 text-lg font-semibold text-column-gray-500" }, { children: ["Additional Fee #", index + 1] })), _jsx(DrawerPropertyRow, { label: `Fee name`, value: additionalFee.description }), _jsx(DrawerPropertyRow, { label: `How fee is charged`, value: getAdditionalFeeTypeDescription(additionalFee.type) }), isFlatAdditionalFee(additionalFee) && (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: `When fee is assessed`, value: getAdditionalFeeChargeMethodDescriptionFromAdditionalFee(additionalFee) }), _jsx(DrawerPropertyRow, { label: `Fee amount`, value: additionalFee.amount
                                        ? `$${dbToUICurrencyString(additionalFee.amount)}`
                                        : '' })] })), isPercentAdditionalFee(additionalFee) && (_jsx(DrawerPropertyRow, { label: `Fee percentage`, value: `${additionalFee.feePercentage}%` }))] }, index)))] }));
}
