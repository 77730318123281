var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NotificationType, OccupationType, OrganizationType } from '../enums';
import { getOpenInvitesAssociatedWithEmail, filterOpenInvitesForAnonUsers } from '../helpers';
import { getAllowedOrganizationSnaps, getPendingOrgInvitesForUser } from '../users';
import { exists } from '../types';
/**
 * Check if a user has an organization ref and that they have the occupation
 * of an organization member.
 */
export const isMemberOfOrganization = (user, orgId) => {
    if (!exists(user)) {
        return false;
    }
    const { allowedOrganizations, occupation } = user.data();
    if (!allowedOrganizations || allowedOrganizations.length === 0) {
        return false;
    }
    const allowedOrgIds = allowedOrganizations.map(org => org.id);
    if (orgId && !allowedOrgIds.includes(orgId)) {
        return false;
    }
    const hasOrgOccupation = [
        OccupationType.government_official.value,
        OccupationType.lawyer.value,
        OccupationType.other_organization.value,
        OccupationType.publishing.value,
        OccupationType.funeral_director.value
    ].includes(occupation);
    return hasOrgOccupation;
};
/**
 * Get the appropriate stripeId for a user given a target organization
 * (e.g. if an invoice has a filedBy org, pass that in)
 */
export const getUserStripeId = (user, targetUserOrg) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (isMemberOfOrganization(user, targetUserOrg === null || targetUserOrg === void 0 ? void 0 : targetUserOrg.id)) {
        const orgStripeId = (_a = targetUserOrg === null || targetUserOrg === void 0 ? void 0 : targetUserOrg.data()) === null || _a === void 0 ? void 0 : _a.stripeId;
        if (orgStripeId) {
            return orgStripeId;
        }
    }
    return user.data().stripeId;
});
/**
 * Determine if a user is a publisher (as opposed to an advertiser).
 */
export const isPublisher = (user) => {
    return user.data().occupation === OccupationType.publishing.value;
};
export const getUserNeedsToBeRedirectedToRegistration = ({ ctx, user, pathname }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(user))
        return false;
    const { postRegistrationComplete, anonymous, email } = user.data();
    if (postRegistrationComplete) {
        return false;
    }
    const pendingUserInvites = yield getOpenInvitesAssociatedWithEmail(ctx, (email || '').toLowerCase());
    const pendingIndividualInvites = filterOpenInvitesForAnonUsers(pendingUserInvites);
    const onInviteRoute = pathname.includes('/invites/');
    if (pendingIndividualInvites.length && onInviteRoute && anonymous) {
        return true;
    }
    const pendingOrgInvites = yield getPendingOrgInvitesForUser(ctx, user);
    if (pendingOrgInvites.length) {
        return false;
    }
    const onRegistrationRequiredRoute = pathname === '/register/' ||
        pathname.includes('/notices') ||
        pathname === '/';
    return onRegistrationRequiredRoute;
});
/**
 * Determine if a user is a government official.
 */
export const isGovernmentOfficial = (user) => {
    return user.data().occupation === OccupationType.government_official.value;
};
/**
 * Determine if a user belongs to a government organization.
 * Adapt this if we need to check parent org, but we're currently assuming
 * a child org will be government as well.
 */
export const belongsToGovernmentOrganization = (user) => __awaiter(void 0, void 0, void 0, function* () {
    const organizations = yield getAllowedOrganizationSnaps(user);
    for (const organization of organizations) {
        if (organization.data().organizationType === OrganizationType.government.value) {
            return true;
        }
    }
    return false;
});
export const createNotificationsObject = (occupation) => {
    var _a;
    const occupation_key = (_a = OccupationType.by_value(occupation)) === null || _a === void 0 ? void 0 : _a.key;
    if (!occupation_key) {
        throw new Error(`invalid occupation value ${occupation}`);
    }
    const notifications = {};
    NotificationType.items().forEach((notification) => {
        const isRelevantToUser = (notification.recipient_occupation_type_keys || []).includes(occupation_key || '');
        notifications[notification.key] = {
            inApp: isRelevantToUser,
            email: isRelevantToUser,
            isConfigurable: isRelevantToUser && notification.isConfigurable
        };
    });
    return notifications;
};
