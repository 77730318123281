import { useCallback, useEffect, useState } from 'react';
import { getCustomValidationMessage, useValidationEventListener } from '../../helpers/inputValidation';
export function useValidationChecks({ value, inputRef, errorText, validationMessages, setValidityTracker, setShowErrors }) {
    const [currentValidationMessage, setCurrentValidationMessage] = useState();
    useEffect(() => {
        setCurrentValidationMessage(getValidationMessage(inputRef, validationMessages));
    }, [value, Boolean(inputRef.current), validationMessages]);
    useEffect(() => {
        if (setValidityTracker) {
            setValidityTracker(!currentValidationMessage);
        }
        if (!currentValidationMessage && !errorText) {
            setShowErrors(false);
        }
    }, [currentValidationMessage, value, errorText]);
    const onValidationEvent = useCallback(() => setShowErrors(true), [
        setShowErrors
    ]);
    useValidationEventListener({ onValidationEvent, inputRef });
    return { currentValidationMessage };
}
function getValidationMessage(inputRef, validationMessages) {
    if ((inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) && !inputRef.current.validity.valid) {
        const customValidationMessage = validationMessages
            ? getCustomValidationMessage(inputRef.current.validity, validationMessages)
            : null;
        const nativeValidationMessage = inputRef.current.validationMessage;
        return customValidationMessage || nativeValidationMessage;
    }
}
