var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { isPayOrderInvoiceData } from 'lib/types/invoices';
import { getUserStripeId } from 'lib/utils/users';
import { logInfo } from 'utils/logger';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { Product } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { getOrThrow } from 'lib/utils/refs';
import PayInvoicePage from './PayInvoicePage';
import { getSavedPaymentsAccess } from './helpers/getSavedPaymentsAccess';
import { getPayInvoicePaymentData } from './helpers/loadPayInvoiceData';
const mapDispatchToProps = (dispatch) => ({
    push: (path) => dispatch(push(path))
});
const mapStateToProps = (state) => ({
    user: state.auth.user
});
function PayInvoice({ user }) {
    const [payInvoiceData, setPayInvoiceData] = useState();
    const [savedPaymentsAccess, setSavedPaymentsAccess] = useState();
    const [userStripeId, setUserStripeId] = useState();
    const enableAuthCapture = !!getLocationParams().get('enableAuthCapture');
    const invoicePricingData = payInvoiceData === null || payInvoiceData === void 0 ? void 0 : payInvoiceData.invoicePricingData;
    const [product, setProduct] = useState(Product.Notice);
    const isLoading = invoicePricingData === undefined ||
        payInvoiceData === undefined ||
        savedPaymentsAccess === undefined;
    const onLoadingTimeout = () => {
        logInfo('PayInvoice timed out', {
            isLoading: !!isLoading,
            invoicePricingData: !!invoicePricingData,
            payInvoiceData: !!payInvoiceData,
            savedPaymentsAccess: !!savedPaymentsAccess
        });
    };
    useEffect(() => {
        const loadPayInvoiceData = () => __awaiter(this, void 0, void 0, function* () {
            const invoiceId = window.location.href.split('/')[4];
            const payInvoiceData = yield getPayInvoicePaymentData(invoiceId);
            if (!payInvoiceData) {
                throw Error('Failed to load PayInvoice data');
            }
            if (payInvoiceData.invoice.isOrderInvoice()) {
                const orderRef = getFirebaseContext()
                    .ordersRef()
                    .doc(payInvoiceData.invoice.modelData.order.id);
                const orderSnap = yield getOrThrow(orderRef);
                setProduct(orderSnap.data().product);
                /*
                 * Include the product in the payInvoiceData object.
                 * This allows us to categorize and analyze completed invoice payment logs based on the product.
                 */
                if (isPayOrderInvoiceData(payInvoiceData)) {
                    payInvoiceData.product = orderSnap.data().product;
                }
            }
            setPayInvoiceData(payInvoiceData);
        });
        void loadPayInvoiceData();
    }, []);
    useEffect(() => {
        const loadUserStripeId = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!exists(user)) {
                return setUserStripeId(undefined);
            }
            const userActiveOrganization = yield ((_a = user
                .data()
                .activeOrganization) === null || _a === void 0 ? void 0 : _a.get());
            const userStripeId = yield getUserStripeId(user, userActiveOrganization);
            setUserStripeId(userStripeId);
        });
        void loadUserStripeId();
    }, [user === null || user === void 0 ? void 0 : user.id]);
    useEffect(() => {
        if (!payInvoiceData) {
            setSavedPaymentsAccess(undefined);
        }
        else {
            setSavedPaymentsAccess(getSavedPaymentsAccess(payInvoiceData, user, userStripeId));
        }
    }, [user === null || user === void 0 ? void 0 : user.id, payInvoiceData === null || payInvoiceData === void 0 ? void 0 : payInvoiceData.invoice.id, userStripeId]);
    if (isLoading) {
        return (_jsx(LoadingState, { context: { location: 'PayInvoice' }, onTimeout: onLoadingTimeout }));
    }
    return (_jsx(PayInvoicePage, { payInvoiceData: payInvoiceData, invoicePricingData: invoicePricingData, savedPaymentsAccess: savedPaymentsAccess, user: user, enableAuthCapture: enableAuthCapture, product: product }));
}
export default connect(mapStateToProps, mapDispatchToProps)(PayInvoice);
