var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { PAYWAY_SUCCESS_CODE, PaywayGatewayTransactionResponseSchema } from 'lib/types/payway';
import { logAndCaptureException } from 'utils';
import { validateSchemaOrThrow } from 'lib/utils/joi';
import { ColumnService } from 'lib/services/directory';
export const authorizePaywayCardAndGetToken = (requestData, paymentAuthorizationUrl) => __awaiter(void 0, void 0, void 0, function* () {
    if (!paymentAuthorizationUrl) {
        throw Error('Payment authorization URL not found');
    }
    const paywayUrl = paymentAuthorizationUrl;
    const { data } = yield axios.post(`${paywayUrl}/Payment/CreditCard`, requestData);
    const validation = validateSchemaOrThrow(PaywayGatewayTransactionResponseSchema, data, 'Invalid response from Payway authorization');
    if (validation.paywayCode !== PAYWAY_SUCCESS_CODE) {
        const errorMessage = getPaywayErrorMessageFromErrorCode(data.paywayCode, data.paywayMessage);
        throw Error(errorMessage);
    }
    if (validation.cardAccount.paywayToken) {
        return validation.cardAccount.paywayToken.toString();
    }
    logAndCaptureException(ColumnService.PAYMENTS, new Error('Payway token not received from successful authorization attempt'), 'Failed to authorize card with Payway');
    throw Error('Payment was not successful. Please try again.');
});
export const getPaywayErrorMessageFromErrorCode = (errorCode, message) => {
    let userMessage = '';
    switch (errorCode) {
        case '5012':
            userMessage =
                'The transaction was declined by the issuer. Please check that the card details entered above are correct. If the card details are correct, contact your card issuer.';
            break;
        case '5020':
            userMessage =
                'Duplicate transaction request. The payment request has been rejected.';
            break;
        case '5032':
            userMessage =
                'The card issuer has disallowed this type of transaction. Contact your card issuer if you expect this transaction type to be permitted.';
            break;
        case '5039':
            userMessage =
                'The merchant is not authorized to process this card type. Please try another method of payment.';
            break;
        // detail-specific declines
        case '5036':
            userMessage =
                'Invalid address entered. Please check that the card details entered above are correct.';
            break;
        case '5037':
            userMessage =
                'Invalid credit card expiration date entered. Please check that the card details entered above are correct.';
            break;
        case '5038':
            userMessage =
                'Invalid CVV or card security code. Please check that the card details entered above are correct.';
            break;
        case '5040':
            userMessage =
                'Invalid city entered. Please check that the card details entered above are correct.';
            break;
        case '5044':
            userMessage =
                'Invalid state entered. Please check that the card details entered above are correct.';
            break;
        case '5045':
            userMessage =
                'Invalid zip or postal code entered. Please check that the card details entered above are correct.';
            break;
        case '1':
            userMessage = 'Payment was not successful. Please try again.';
            break;
        default:
            userMessage = `The transaction could not be processed. ${message} Contact help@column.us for further assistance.`;
            break;
    }
    return userMessage;
};
