import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { exists } from 'lib/types';
import { getLocationParams } from 'lib/frontend/utils/browser';
import LoadingState from 'components/LoadingState';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectIsPublisher, selectUser, selectUserAuth } from 'redux/auth';
import NotificationSettings from './NotificationSettings';
import SettingsForm from './SettingsForm';
import UserSettingsBankAccounts from './BankAccountSettings';
import UserSettingsDocuments from './Documents';
import UserSettingsFeatureFlags from './FeatureFlags';
import PublisherSettings from './publisherSettings';
import UserSettingsSidebar, { BANK_ACCOUNTS, BASIC_INFO, DOCUMENTS, FEATURE_FLAGS, NOTIFICATIONS, PUBLISHER_SETTINGS, getSidebarSettingsForUser } from './UserSettingsSidebar';
export default function UserSettings() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const user = useAppSelector(selectUser);
    const isPublisher = useAppSelector(selectIsPublisher);
    const userAuth = useAppSelector(selectUserAuth);
    const [view, setView] = useState(getLocationParams().get('tab') || BASIC_INFO);
    if (!user || !userAuth)
        return _jsx(LoadingState, {});
    const { showPublisherSettingsTab, showBankAccountsTab, showNotificationsTab, showFeatureFlagsTab, userInOrganization } = getSidebarSettingsForUser(user);
    const tabs = {
        [BASIC_INFO]: (_jsx(SettingsForm, { isPublisher: isPublisher, userAuth: userAuth, user: user })),
        [DOCUMENTS]: _jsx(UserSettingsDocuments, {})
    };
    if (showBankAccountsTab) {
        tabs[BANK_ACCOUNTS] = _jsx(UserSettingsBankAccounts, { user: user });
    }
    if (showNotificationsTab) {
        tabs[NOTIFICATIONS] = (_jsx(NotificationSettings, { user: user, activeOrganization: exists(activeOrganization) ? activeOrganization : null, isPublisher: isPublisher }));
    }
    if (showPublisherSettingsTab) {
        tabs[PUBLISHER_SETTINGS] = _jsx(PublisherSettings, { user: user });
    }
    if (showFeatureFlagsTab) {
        tabs[FEATURE_FLAGS] = _jsx(UserSettingsFeatureFlags, {});
    }
    // If the user somehow gets a link to a tab they can't access, redirect.
    if (!Object.keys(tabs).includes(view)) {
        if (view === BANK_ACCOUNTS && userInOrganization) {
            // The user may have been linked to the wrong place for their bank accounts
            // Note: all of my attempts to use react-router for this redirect failed, but
            // this should be an uncommon operation so a full page refresh is OK.
            window.location.href = '/settings/organization/?tab=bank-accounts';
        }
        else {
            // Bounce back to basic info
            setView(BASIC_INFO);
        }
    }
    return (_jsxs("div", Object.assign({ className: "px-12 flex gap-8 mt-5" }, { children: [_jsx(UserSettingsSidebar, { onViewChange: setView, activeView: view, user: user }), _jsx("main", Object.assign({ className: "flex-1" }, { children: tabs[view] }))] })));
}
