import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useValidationChecks } from 'lib/components/ColumnSelect/hooks/useValidationChecks';
import { InputAccessories, getAccessibilityLinks } from 'lib/components/InputAccessories';
import { useRef, useState } from 'react';
/**
 * Use this instead of ColumnSelect when styling the options list is unnecessary.
 * Useful for when the options list creates awkward scrolling.
 */
function NativeSelect({ id = '', options, labelText, noteText, errorText, required = false, value, placeholder = 'Select option', allowUndefined, onChange, validationMessages, checkDependentValidations }) {
    const selectRef = useRef(null);
    const [focused, setFocused] = useState(false);
    const [showErrors, setShowErrors] = useState(!!errorText);
    const { currentValidationMessage } = useValidationChecks({
        value: value || '',
        required,
        inputRef: selectRef,
        validationMessages,
        setShowErrors,
        checkDependentValidations
    });
    const errorMessage = showErrors ? errorText || currentValidationMessage : '';
    const accessibilityLinks = getAccessibilityLinks({
        id,
        noteText,
        errorMessage
    });
    return (_jsx(InputAccessories, Object.assign({ id: id, labelText: labelText, errorText: showErrors ? errorMessage : '' }, { children: _jsx("div", Object.assign({ className: classNames('text-sm border bg-white p-3 rounded-md font-medium text-column-gray-500', {
                'border-column-primary-500 shadow-outline-column-primary': focused,
                'border-column-red-600 shadow-outline-column-red': errorMessage,
                'border-column-gray-200': !focused && !errorMessage
            }), onClick: () => {
                var _a;
                (_a = selectRef === null || selectRef === void 0 ? void 0 : selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            } }, { children: _jsxs("select", Object.assign({ ref: selectRef, id: `${id}-field`, name: id, className: "w-full focus:outline-none truncate", onBlur: () => {
                    setFocused(false);
                }, onChange: value => {
                    onChange(value.target.value);
                }, onFocus: () => {
                    setFocused(true);
                } }, accessibilityLinks, { required: required, value: value }, { children: [_jsx("option", Object.assign({ value: "", disabled: !allowUndefined }, { children: placeholder })), options.map(o => (_jsx("option", Object.assign({ value: o.value }, { children: o.label }), o.value)))] })) })) })));
}
export default NativeSelect;
