import { unCdnify } from 'lib/helpers';
export const extractTemplateConfigurationFromActiveOrganization = (activeOrganization, templateRef, template) => {
    var _a;
    return {
        bulkDownloadStoragePath: template.fullPageTemplate
            ? unCdnify(template.fullPageTemplate)
            : '',
        useAsDefaultTemplate: Boolean(templateRef &&
            ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id) === (templateRef === null || templateRef === void 0 ? void 0 : templateRef.id)),
        downloadUrl: template.downloadUrl,
        name: template.name || '',
        publisherOrganizations: template.publisherOrganizations
    };
};
