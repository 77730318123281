var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * Constants.
 */
const scriptSrc = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
/**
 * Browser paths to hide the chat widget (not including the hostname)
 */
export const pathsToHideFrontChat = ['/obituaries/place', '/classifieds/place'];
/**
 * The ID of the iframe element that contains the Front chat widget
 */
export const frontIFrameId = 'front-chat-iframe';
/*
 * Helper to load the Front chat widget
 */
export function loadFrontChat(element, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute('src', scriptSrc);
        if (options === null || options === void 0 ? void 0 : options.nonce) {
            scriptTag.setAttribute('nonce', options.nonce);
        }
        const scriptContainer = element !== null && element !== void 0 ? element : document.body;
        const loadScriptPromise = new Promise(resolve => {
            scriptTag.onload = () => {
                if (!window.FrontChat) {
                    throw new Error('[front-chat-sdk] Could not set up window.FrontChat');
                }
                resolve(window.FrontChat);
            };
        });
        scriptContainer.appendChild(scriptTag);
        return loadScriptPromise;
    });
}
/*
 * Helper to initialize frontChat
 */
export function initializeFrontChat(chatId, element) {
    return __awaiter(this, void 0, void 0, function* () {
        const frontChat = yield loadFrontChat(element);
        frontChat('init', { chatId, useDefaultLauncher: true });
    });
}
