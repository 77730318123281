var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { getErrors, getResponses, wrapError, wrapSuccess } from '../types/responses';
import { NewspaperOrderStatus } from '../types/newspaperOrder';
import { asyncFilter } from '../helpers';
import { getErrorReporter } from '../utils/errors';
export class NewspaperOrderService {
    constructor(context) {
        this.context = context;
    }
    getNewspaperOrderWithEarliestDeadline(newspaperOrders) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!newspaperOrders.length) {
                return wrapError(new Error('No newspaper orders provided'));
            }
            const newspaperOrderAndDeadlineResults = yield Promise.all(newspaperOrders.map((no) => __awaiter(this, void 0, void 0, function* () {
                const { response: deadline, error: deadlineError } = yield no.getDeadline();
                if (deadlineError) {
                    return wrapError(deadlineError);
                }
                return wrapSuccess({
                    newspaperOrder: no,
                    deadline
                });
            })));
            const newspaperOrderAndDeadlineResultErrors = getErrors(newspaperOrderAndDeadlineResults);
            if (newspaperOrderAndDeadlineResultErrors.length) {
                return wrapError(newspaperOrderAndDeadlineResultErrors[0]);
            }
            const newspaperOrdersAndDeadlines = getResponses(newspaperOrderAndDeadlineResults);
            const sortedNewspaperOrdersAndDeadlines = newspaperOrdersAndDeadlines.sort((a, b) => a.deadline.valueOf() - b.deadline.valueOf());
            return wrapSuccess(sortedNewspaperOrdersAndDeadlines[0]);
        });
    }
    isAnyNewspaperOrderPastDeadline(newspaperOrders) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: earliestDeadlineAndNewspaperOrder, error: earliestDeadlineError } = yield this.getNewspaperOrderWithEarliestDeadline(newspaperOrders);
            if (earliestDeadlineError) {
                return wrapError(earliestDeadlineError);
            }
            return wrapSuccess(moment().isAfter(earliestDeadlineAndNewspaperOrder.deadline));
        });
    }
    getNewspaperOrdersAtLeastNHoursBeforeDeadline(newspaperOrders, hoursBeforeDeadline) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield asyncFilter(newspaperOrders, (no) => __awaiter(this, void 0, void 0, function* () {
                const { response: isNHoursBeforeDeadline, error: isNHoursBeforeDeadlineError } = yield no.isAtLeastNHoursBeforeDeadline(hoursBeforeDeadline);
                if (isNHoursBeforeDeadlineError) {
                    return wrapError(isNHoursBeforeDeadlineError);
                }
                return wrapSuccess(isNHoursBeforeDeadline ? no : null);
            }));
            return result;
        });
    }
    cloneForEditFlow(orderRef, antecedentNewspaperOrders, newVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(antecedentNewspaperOrders.map((currentNewspaperOrder) => __awaiter(this, void 0, void 0, function* () {
                const ref = yield this.context.orderNewspaperOrdersRef(orderRef).add(Object.assign(Object.assign({}, currentNewspaperOrder.modelData), { orderVersion: newVersion, status: NewspaperOrderStatus.DRAFT }));
                getErrorReporter().logInfo('Created new newspaper order for edit flow', {
                    antecedentNewspaperOrderId: currentNewspaperOrder.id,
                    newNewspaperOrderId: ref.id,
                    newVersion: `${newVersion}`
                });
            })));
        });
    }
}
