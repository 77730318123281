import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export function SmilingFaceWithSmilingEyes() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Smiling face with smiling eyes" }, { children: "\uD83D\uDE0A" })));
}
export function PartyPopper() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Celebrate" }, { children: "\uD83C\uDF89" })));
}
export function MoneyWithWings() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Money with wings" }, { children: "\uD83D\uDCB8" })));
}
export function Rocket() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Rocket" }, { children: "\uD83D\uDE80" })));
}
export function AlarmClock() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Alarm Clock" }, { children: "\u23F0" })));
}
export function CancelledAndNoEntry() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Cancel" }, { children: "\u26D4" })));
}
export function PageFacingUp() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Page facing up" }, { children: "\uD83D\uDCC4" })));
}
export function MoneyBag() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Money bag" }, { children: "\uD83D\uDCB0" })));
}
export function Memo() {
    return (_jsx("span", Object.assign({ className: "ml-1", role: "img", "aria-label": "Edit" }, { children: "\uD83D\uDCDD" })));
}
export function OpenMailboxWithLoweredFlag() {
    return (_jsx("span", Object.assign({ role: "img", "aria-label": 'Open mailbox with lowered flag' }, { children: "\uD83D\uDCED" })));
}
export function PartyingFace() {
    return (_jsx("span", Object.assign({ role: "img", "aria-label": 'Partying face' }, { children: "\uD83E\uDD73" })));
}
export function RaisedHand() {
    return (_jsx("span", Object.assign({ role: "img", "aria-label": 'Raised hand' }, { children: "\u270B" })));
}
export function GreenCheckMark() {
    return (_jsx("span", Object.assign({ role: "img", "aria-label": 'Green heavy check mark' }, { children: "\u2705" })));
}
export function Lock() {
    return (_jsx("span", Object.assign({ role: "img", "aria-label": 'Lock' }, { children: "\uD83D\uDD12" })));
}
export function Lotus() {
    return (_jsx("span", Object.assign({ role: "img", "aria-label": 'Person meditating' }, { children: "\uD83E\uDDD8" })));
}
