import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SyncStatusCategory } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getSyncStatusCategory } from 'lib/utils/events';
import Drawer from 'lib/components/Drawer';
import SyncErrorSection from './SyncErrorSection';
function getMostRecentSyncEventPerNotice(snapshot) {
    if (!snapshot || snapshot.empty) {
        return [];
    }
    const noticeIDsWithEvents = new Set();
    return snapshot.docs.filter(event => {
        const noticeID = event.data().notice.id;
        if (noticeIDsWithEvents.has(noticeID)) {
            return false;
        }
        noticeIDsWithEvents.add(noticeID);
        return true;
    });
}
function getCategoryErrors(syncEvents, syncStatusCategory) {
    return syncEvents.filter(event => {
        const { data: { syncStatus } } = event.data();
        const syncEventCategory = getSyncStatusCategory(syncStatus);
        return syncEventCategory.value === syncStatusCategory.value;
    });
}
function SyncErrorDrawer({ activeOrganization, showSyncErrorDrawer, setShowSyncErrorDrawer }) {
    const eventsQuery = getFirebaseContext()
        .eventsRef()
        .where('newspaper', '==', activeOrganization.ref)
        .orderBy('createdAt', 'desc')
        // This order by will ensure we only get Sync Events
        .orderBy('data.syncStatus')
        .limit(100);
    const eventsSnapshot = useFirestoreQueryListener(eventsQuery, [
        activeOrganization.id
    ]);
    const syncEvents = getMostRecentSyncEventPerNotice(eventsSnapshot);
    const technicalErrors = getCategoryErrors(syncEvents, SyncStatusCategory.column_errors);
    const noticeErrors = getCategoryErrors(syncEvents, SyncStatusCategory.client_errors);
    const connectionErrors = getCategoryErrors(syncEvents, SyncStatusCategory.publisher_system_errors);
    const timezone = activeOrganization.data().iana_timezone;
    return (_jsx(Drawer, Object.assign({ header: _jsxs("div", Object.assign({ className: "pl-2" }, { children: [_jsx("p", Object.assign({ className: "text-column-gray-800 font-semibold text-xl" }, { children: "Sync Errors" })), _jsxs("p", Object.assign({ className: "text-column-gray-400 font-medium text-xs" }, { children: ["Track sync errors for notices in ", activeOrganization.data().name, "."] }))] })), open: showSyncErrorDrawer, onClose: () => setShowSyncErrorDrawer(false) }, { children: _jsxs("div", Object.assign({ className: "overflow-y-scroll h-full pb-24" }, { children: [_jsx(SyncErrorSection, { cardHeader: 'Connection Errors', filteredEvents: connectionErrors, timezone: timezone }), _jsx(SyncErrorSection, { cardHeader: 'Technical Errors', filteredEvents: technicalErrors, timezone: timezone }), _jsx(SyncErrorSection, { cardHeader: 'Notice Errors', filteredEvents: noticeErrors, timezone: timezone })] })) })));
}
export default SyncErrorDrawer;
