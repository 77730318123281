import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import PlacementActions, { selectModularSizeId } from 'redux/placement';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
export function ModularSizeField({ modularSizes }) {
    const dispatch = useAppDispatch();
    const selectedModularSizeId = useAppSelector(selectModularSizeId);
    const modularSizeOptions = modularSizes.map(formatModularSizeOption);
    return (_jsx(ColumnSelect, { id: "modular-size-select", labelText: "", value: selectedModularSizeId || '', options: modularSizeOptions, onChange: newModularSizeId => {
            dispatch(PlacementActions.setModularSizeId(newModularSizeId));
        } }));
}
function formatModularSizeOption(modularSize) {
    return {
        value: modularSize.id,
        label: modularSize.data().name,
        description: `${modularSize.data().width} x ${modularSize.data().height} inches`
    };
}
