var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { dateObjectToDay, getClosestFuturePublishingDay, getDeadlineString, getIsAfterPublishingDeadline } from 'lib/utils/deadlines';
import { getNoticeType } from 'lib/helpers';
import { exists } from 'lib/types';
import { NoticeType } from 'lib/enums';
import PlacementActions, { placementSelector, syncDynamicHeadersChange } from 'redux/placement';
import { selectIsColumnRep, selectIsPublisher, selectUser } from 'redux/auth';
import { safeStringify } from 'lib/utils/stringify';
import { ExclamationCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { InputAccessories } from 'lib/components/InputAccessories';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Alert } from 'lib/components/Alert';
import { getDateForDateStringInTimezone, getDateStringForDateInTimezone } from 'lib/utils/dates';
import { Buddy } from 'lib/components/gifs';
import ConfirmScheduleRow from './ConfirmScheduleRow';
import { handlePubDateChangeForWeekendEdition, removeRowClickForWeekendEdition, SATURDAY_DAY_INDEX, shouldDisableDate, SUNDAY_DAY_INDEX } from './helpers';
import { getFirebaseContext, timestampOrDateToTimestamp } from '../../utils/firebase';
import { getDeadlineBufferConfigFromMinutes, getDeadlineBufferMinutesForNoticePlacement } from './helpers/deadlineBuffer';
export function ConfirmScheduleForm({ notice, newspaper, customer, customerOrganization }) {
    const placement = useAppSelector(placementSelector);
    const user = useAppSelector(selectUser);
    const isPublisher = useAppSelector(selectIsPublisher);
    const { canAddMoreDates, cannotFileWPaper, deadlines, deadlineOverrides, publicationDates, newspaperTimezone, handleAddMoreClick, handlePubDateChange, handleRemoveRowClick, handleCustomNoticeTypesFlowDisable } = useConfirmScheduleState({
        notice,
        newspaper
    });
    const [deadlineBufferSettings, setDeadlineBufferSettings] = useState();
    const deadlineConfig = newspaper && deadlines && newspaperTimezone
        ? getDeadlineText({
            publicationDate: publicationDates[0],
            deadlines,
            deadlineOverrides,
            newspaperTimezone,
            placement,
            newspaper,
            isPublisher,
            deadlineBufferSettings
        })
        : null;
    const emphasizeDeadlineAlert = useAppSelector(selectIsColumnRep);
    useEffect(() => {
        function getDeadlineBufferMinutes() {
            return __awaiter(this, void 0, void 0, function* () {
                const bufferMinutes = yield getDeadlineBufferMinutesForNoticePlacement(notice, newspaper, customer, customerOrganization, isPublisher);
                const bufferSettings = getDeadlineBufferConfigFromMinutes(bufferMinutes);
                setDeadlineBufferSettings(bufferSettings);
            });
        }
        void getDeadlineBufferMinutes();
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id, notice === null || notice === void 0 ? void 0 : notice.id, isPublisher]);
    const isDuplicateDate = (publicationDate, publicationDates, timezone) => {
        return (publicationDates
            .map(date => getDateStringForDateInTimezone({
            date,
            timezone
        }))
            .filter(dateString => dateString ===
            getDateStringForDateInTimezone({
                date: publicationDate,
                timezone
            })).length > 1);
    };
    const [someDateIsDuplicate, setSomeDateIsDuplicate] = useState(false);
    useEffect(() => {
        if (!publicationDates || !newspaperTimezone) {
            return;
        }
        setSomeDateIsDuplicate(publicationDates.some(pd => isDuplicateDate(pd, publicationDates, newspaperTimezone)));
    }, [safeStringify(publicationDates), newspaperTimezone]);
    const showAddMoreDates = !cannotFileWPaper && canAddMoreDates;
    return (_jsx(_Fragment, { children: _jsxs(InputAccessories, Object.assign({ id: 'schedule-notice', labelText: 'When should your notice be published?', errorText: someDateIsDuplicate
                ? 'Cannot select the same publication date twice'
                : '' }, { children: [cannotFileWPaper && (_jsx("div", { children: "This paper has not set up a publication schedule yet." })), !cannotFileWPaper && (_jsxs(_Fragment, { children: [deadlineConfig && (_jsx("div", Object.assign({ className: "mb-3" }, { children: _jsx(Alert, Object.assign({ id: "notice-schedule-deadline", title: deadlineConfig.text, status: deadlineConfig.status, icon: _jsx(ExclamationCircleIcon, { className: "h-5 w-5" }) }, { children: emphasizeDeadlineAlert &&
                                    (deadlineConfig === null || deadlineConfig === void 0 ? void 0 : deadlineConfig.status) === 'error' && (_jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx("img", { className: "w-16", src: Buddy }), _jsx("p", Object.assign({ className: "text-xl pt-4" }, { children: _jsx("b", { children: "Are you sure you want to file this notice?" }) })), _jsx("img", { className: "w-16", src: Buddy })] }))) })) }))), _jsx("div", Object.assign({ className: 'grid grid-cols-2 gap-x-6 gap-y-3' }, { children: newspaper &&
                                !!(publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length) &&
                                !!(deadlines === null || deadlines === void 0 ? void 0 : deadlines.length) &&
                                newspaperTimezone && (_jsxs(_Fragment, { children: [publicationDates.map((publicationDate, index) => {
                                        const isDuplicate = isDuplicateDate(publicationDate, publicationDates, newspaperTimezone);
                                        return (_jsx("div", Object.assign({ className: showAddMoreDates || publicationDates.length > 1
                                                ? 'col-span-1'
                                                : 'col-span-2' }, { children: _jsx(ConfirmScheduleRow, { index: index, publicationDate: publicationDate, isPublisher: isPublisher, user: user, deadlines: deadlines, newspaperTimezone: newspaperTimezone, newspaper: newspaper, notice: notice, handlePubDateChange: handlePubDateChange, handleRemoveRowClick: handleRemoveRowClick, disabled: handleCustomNoticeTypesFlowDisable(publicationDate), showDelete: publicationDates.length !== 1, isDuplicateDate: isDuplicate }) }), index));
                                    }), showAddMoreDates && (_jsx("div", Object.assign({ className: "col-span-1" }, { children: _jsx(ColumnButton, { id: "add-more-dates", secondary: true, buttonText: "Add another publication date", startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5" }), onClick: handleAddMoreClick, fullWidth: true, type: "button" }) })))] })) }))] }))] })) }));
}
const shouldAutoSetDates = (notice, newspaper, publicationDates) => {
    var _a, _b, _c;
    // don't auto-set if there isn't an allowed notice array
    if (!((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.allowedNotices))
        return false;
    // don't auto-set if it is a default notice type
    if (((_b = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _b === void 0 ? void 0 : _b.noticeType) === NoticeType.custom.value ||
        ((_c = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _c === void 0 ? void 0 : _c.noticeType) === NoticeType.display_ad.value)
        return false;
    // get the associated notice type
    const noticeType = getNoticeType(notice, newspaper);
    if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
        publicationDates.length <= (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications))
        return true;
    return false;
};
/**
 * This logic was previously nested inside of ConfirmScheduleStep.
 * It was moved here to modularize the schedule form and make it
 * reusable in ScheduleNoticeStep.
 * TODO: Write characterization tests for this form
 * TODO: Refactor action-based functions into thunks and derived state into selectors
 */
export function useConfirmScheduleState({ notice, newspaper }) {
    var _a, _b, _c;
    const dispatch = useAppDispatch();
    const placement = useAppSelector(placementSelector);
    const user = useAppSelector(selectUser);
    const isPublisher = useAppSelector(selectIsPublisher);
    const [complete, setComplete] = useState(false);
    const [initialPublicationDate, setInitialPublicationDate] = useState((_b = (_a = placement.publicationDates) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.toDate());
    const noticeType = getNoticeType(notice, newspaper);
    const deadlines = exists(newspaper) ? newspaper.data().deadlines : null;
    const deadlineOverrides = exists(newspaper)
        ? (_c = newspaper.data().deadlineOverrides) !== null && _c !== void 0 ? _c : {}
        : {};
    const newspaperTimezone = exists(newspaper)
        ? newspaper.data().iana_timezone
        : null;
    const cannotFileWPaper = !deadlines || !newspaperTimezone;
    const publicationDates = placement.publicationDates
        ? placement.publicationDates.map((timestamp) => timestamp.toDate())
        : [];
    const setPlacementPublicationDates = (...dates) => {
        dispatch(PlacementActions.setPublicationDates(dates.map(timestampOrDateToTimestamp)));
    };
    const isComplete = (publicationDates) => {
        const areRepeatedDateSelections = (publicationDates) => {
            const dateStrings = publicationDates
                .map(date => {
                if (!date)
                    return false;
                return date.toDateString();
            })
                .filter(Boolean);
            return new Set(dateStrings).size !== dateStrings.length;
        };
        const areAnyDaysDisabled = (publicationDates) => {
            return publicationDates.find(day => shouldDisableDate({
                day,
                newspaper,
                user,
                notice: placement,
                noticeType,
                isPublisher
            }));
        };
        return (!areRepeatedDateSelections(publicationDates) &&
            (!areAnyDaysDisabled(publicationDates) || isPublisher));
    };
    const onExit = (updatedPublicationDates) => {
        const sortedPublicationDates = (updatedPublicationDates || publicationDates)
            .sort((a, b) => a.getTime() - b.getTime())
            .map(d => {
            /**
             * Setting the publication date time to 12:00pm in the newspaper's timezone
             * Eventually, publication dates should be strings ('YYYY-MM-DD'), not dates/timestamps,
             * or replaced with `runs`
             */
            return getDateForDateStringInTimezone({
                dayString: getDateStringForDateInTimezone({
                    date: d,
                    timezone: newspaperTimezone || 'America/Chicago'
                }),
                timezone: newspaperTimezone || 'America/Chicago',
                time: '12:00'
            });
        });
        const fbDates = sortedPublicationDates.map(getFirebaseContext().timestampFromDate);
        dispatch(PlacementActions.confirmSchedule({
            publicationDates: fbDates,
            dynamicFooter: null,
            footerFormatString: null
        }));
        dispatch(syncDynamicHeadersChange(newspaper));
    };
    const handleAddMoreClick = () => {
        if (!deadlines || !newspaperTimezone) {
            return;
        }
        let newPublicationDates = [...publicationDates];
        const DEFAULT_DAYS_TO_ADD = 7;
        const daysToAdd = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) &&
            (noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultDaysBetweenPublication)
            ? noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultDaysBetweenPublication
            : DEFAULT_DAYS_TO_ADD;
        const nextPotentialPublishingDate = moment(publicationDates[publicationDates.length - 1])
            .add(daysToAdd, 'days')
            .toDate();
        const nextPublishingDate = getClosestFuturePublishingDay(deadlines, deadlineOverrides, newspaperTimezone, placement, newspaper, nextPotentialPublishingDate);
        // Adds both next Saturday and next Sunday if next publishing day is a weekend
        if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) &&
            moment(nextPublishingDate).day() === SATURDAY_DAY_INDEX) {
            const sunday = moment(nextPublishingDate).day(7).toDate();
            newPublicationDates.push(sunday);
        }
        else if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) &&
            moment(nextPublishingDate).day() === SUNDAY_DAY_INDEX) {
            const saturday = moment(nextPublishingDate).day(-1).toDate();
            newPublicationDates.push(saturday);
        }
        newPublicationDates.push(nextPublishingDate);
        /**
         * Using a unary + operator to coerce Date to Number avoids ts(2362) error
         * https://github.com/microsoft/TypeScript/issues/5710
         */
        newPublicationDates = newPublicationDates.sort((a, b) => +a - +b);
        dispatch(PlacementActions.setPublicationDatesUpdated(true));
        setPlacementPublicationDates(...newPublicationDates);
    };
    const handleRemoveRowClick = (i) => __awaiter(this, void 0, void 0, function* () {
        let newDates = [];
        if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) {
            newDates = removeRowClickForWeekendEdition(publicationDates, i);
        }
        else {
            newDates = publicationDates.filter((s, sIndex) => i !== sIndex);
        }
        // If the array is empty add the initial date to prevent error
        if (newDates.length === 0 && initialPublicationDate) {
            newDates.push(initialPublicationDate);
        }
        dispatch(PlacementActions.setPublicationDatesUpdated(true));
        setPlacementPublicationDates(...newDates);
    });
    const handlePubDateChange = (pubDate, i) => {
        /**
         * NOTE: This is a legacy fix, and I don't think setting the hours to noon in
         * the user's device timezone is a reliable fix for so-called "late night filing" issues.
         * We should probably be setting the date and time based on the newspaper's timezone
         */
        pubDate.setHours(12);
        let newPubDates;
        if (!initialPublicationDate) {
            setInitialPublicationDate(publicationDates[0]);
        }
        if (exists(newspaper) &&
            shouldAutoSetDates(notice, newspaper, publicationDates) &&
            !isPublisher) {
            newPubDates = [pubDate];
        }
        else {
            newPubDates = [...publicationDates];
            newPubDates[i] = pubDate;
        }
        if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.weekendEditionEnabled) {
            newPubDates = handlePubDateChangeForWeekendEdition(pubDate, publicationDates, i);
        }
        /**
         * Using a unary + operator to coerce Date to Number avoids ts(2362) error
         * https://github.com/microsoft/TypeScript/issues/5710
         */
        newPubDates = newPubDates.sort((a, b) => +a - +b);
        setPlacementPublicationDates(...newPubDates);
    };
    /**
     * Set the publication dates to the next valid one for the chosen newspaper.
     */
    const resetPublicationDates = () => {
        if (!exists(newspaper) || !deadlines || !newspaperTimezone) {
            return;
        }
        setPlacementPublicationDates(getClosestFuturePublishingDay(deadlines, deadlineOverrides, newspaperTimezone, placement, newspaper));
    };
    useEffect(() => {
        if (isComplete(publicationDates)) {
            onExit(publicationDates);
        }
    }, [isComplete(publicationDates)]);
    useEffect(() => {
        if (!exists(newspaper) ||
            !exists(notice) ||
            !deadlines ||
            !newspaperTimezone) {
            return;
        }
        if (!(publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length)) {
            resetPublicationDates();
        }
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id, notice === null || notice === void 0 ? void 0 : notice.id, publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length]);
    useEffect(() => {
        if (!publicationDates.length || publicationDates.find(d => d === null))
            return;
        setComplete(isComplete(publicationDates));
    }, [safeStringify(placement.publicationDates), deadlines, newspaperTimezone]);
    useEffect(() => {
        if (!publicationDates.length || publicationDates.find(d => d === null)) {
            return;
        }
        setComplete(isComplete(publicationDates));
    }, []);
    // TODO: Merge with other disabled logic in ConfirmScheduleRow
    const handleCustomNoticeTypesFlowDisable = (publicationDate) => {
        // publishers can always edit
        if (isPublisher)
            return false;
        if (publicationDates.indexOf(publicationDate) === 0)
            return false;
        // only disable on custom notice types with preset runs and that do not allow for
        // additional runs to be added beyond the required ones
        if (exists(newspaper) &&
            shouldAutoSetDates(notice, newspaper, publicationDates) &&
            !(noticeType === null || noticeType === void 0 ? void 0 : noticeType.canAddBeyondRequired))
            return true;
        // by default allow editing
        return false;
    };
    const canAddMoreDates = isPublisher ||
        (exists(newspaper) &&
            !(newspaper.data().allowedNotices &&
                placement.noticeType !== NoticeType.custom.value &&
                (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
                !(noticeType === null || noticeType === void 0 ? void 0 : noticeType.canAddBeyondRequired)));
    return {
        onExit,
        complete,
        canAddMoreDates,
        cannotFileWPaper,
        deadlines,
        deadlineOverrides,
        publicationDates,
        newspaperTimezone,
        handleAddMoreClick,
        handlePubDateChange,
        handleRemoveRowClick,
        handleCustomNoticeTypesFlowDisable
    };
}
export const getDeadlineText = ({ publicationDate, deadlines, deadlineOverrides, newspaperTimezone, placement, newspaper, isPublisher, deadlineBufferSettings }) => {
    if (!publicationDate) {
        return {
            text: 'Deadline information will appear once a date has been selected.',
            status: 'info'
        };
    }
    const isAfterPublishingDeadline = getIsAfterPublishingDeadline(publicationDate, deadlines, deadlineOverrides, newspaperTimezone, placement, newspaper, undefined);
    const isWithinDeadlineBuffer = !isAfterPublishingDeadline &&
        getIsAfterPublishingDeadline(publicationDate, deadlines, deadlineOverrides, newspaperTimezone, placement, newspaper, deadlineBufferSettings);
    if (isPublisher && isAfterPublishingDeadline) {
        return {
            text: `Your deadline for ${dateObjectToDay(publicationDate)} has passed, but you can still upload this notice.`,
            status: 'error'
        };
    }
    let text;
    const status = isAfterPublishingDeadline ? 'error' : 'warning';
    const deadlineString = getDeadlineString(deadlines, deadlineOverrides, publicationDate, placement, newspaper, undefined);
    const deadlineBufferedString = getDeadlineString(deadlines, deadlineOverrides, publicationDate, placement, newspaper, deadlineBufferSettings);
    if (isWithinDeadlineBuffer) {
        text = `The deadline for this paper is ${deadlineString}, and pre-payment is required. To ensure timely invoice processing, submit your notice before ${deadlineBufferedString} to avoid order cancellation`;
    }
    else if (!isAfterPublishingDeadline) {
        text = `To meet the deadline for this publication, please submit it no later than ${deadlineString}`;
    }
    else {
        text = `The deadline for advertisements for ${dateObjectToDay(publicationDate)} was ${deadlineString}`;
    }
    return {
        text: `${text}.`,
        status
    };
};
