import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import classNames from 'classnames';
export function CustomerOrganizationDrawerFooter({ onEditCustomerOrganization }) {
    return (_jsx("div", Object.assign({ className: classNames('absolute bottom-0 flex items-center w-full py-4 px-8 border-t bg-white', {
            'justify-between': !onEditCustomerOrganization,
            'justify-end': onEditCustomerOrganization
        }) }, { children: _jsx(ColumnButton, { id: "edit-organization-drawer-button", size: "lg", buttonText: "Edit", onClick: onEditCustomerOrganization, type: "button" }) })));
}
