var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import CButton from '../../components/CButton';
function ConfirmNoticeWithoutProof({ setOpen, newspaper }) {
    const [confirmed, setConfirmed] = useState(false);
    const HELP_LINK = 'https://help.column.us/en_us/how-do-i-process-notices-that-havent-been-formatted-r1d3tq9z3';
    const MODAL_DESC = (_jsxs("p", Object.assign({ className: "text-sm font-normal" }, { children: ["The advertiser opted to not use auto-layout for this notice. By clicking Continue, you acknowledge that ", newspaper.data().name, " will manually format the content of this public notice as necessary for publication. You will need to edit the notice and upload the finalized ad.", _jsx("br", {}), "Need help? Click", ' ', _jsx("a", Object.assign({ href: HELP_LINK, rel: "noopener noreferrer", target: "_blank", className: "text-blue-600" }, { children: "here" })), "."] })));
    const buttonStyles = 'bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-3/6';
    return (_jsx(FreeformCModal, Object.assign({ header: "Notice Submitted Without Proof", body: MODAL_DESC }, { children: _jsx("div", Object.assign({ className: "flex justify-center md:justify-start" }, { children: _jsxs(CButton, Object.assign({ id: "confirm-receipt-without-proof", disabled: confirmed, className: buttonStyles, onClick: () => __awaiter(this, void 0, void 0, function* () {
                    setConfirmed(true);
                    yield new Promise(resolve => {
                        setTimeout(resolve, 2000);
                    });
                    setOpen(false);
                }) }, { children: [confirmed ? (_jsx("div", { className: "loader ease-linear rounded-full border-4 border-gray-200 h-6 w-6 align-middle inline-block" })) : (_jsx(_Fragment, {})), _jsx("span", Object.assign({ className: confirmed ? 'ml-2 inline-block' : '' }, { children: "Continue" }))] })) })) })));
}
export default ConfirmNoticeWithoutProof;
