export const LineItemTypeData = {
    publication: {
        value: 1,
        label: 'Publication',
        key: 'publication'
    },
    fee: {
        value: 2,
        label: 'Fee',
        key: 'fee'
    },
    bulk_invoice: {
        value: 3,
        label: 'Bulk Invoice',
        key: 'bulk_invoice'
    },
    discount: {
        value: 4,
        label: 'Discount',
        key: 'discount'
    },
    credit: {
        value: 5,
        label: 'Credit',
        key: 'credit'
    }
};
