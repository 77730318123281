export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';
export const MAX_ELASTIC_FACETS_LENGTH = 250;
// Types of documents we sync to Elastic
export const ELASTIC_DOCUMENT_TYPES = [
    'notice',
    'draft',
    'customer',
    'organization',
    'publicNotice',
    'invoice',
    'order',
    'adjudicationArea'
];
export const isSearchableCustomerRecord = (record) => {
    const requiredFields = [
        'userid',
        'organizationid',
        'verified'
    ];
    if (requiredFields.every(requiredField => record[requiredField] !== undefined)) {
        return true;
    }
    return false;
};
export const isSearchableOrderRecord = (record) => {
    const requiredFields = [
        'orderid',
        'publisherid',
        'newspaperorderstatus'
    ];
    if (requiredFields.every(requiredField => record[requiredField] !== undefined)) {
        return true;
    }
    return false;
};
