var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InviteStatus, RoleType } from '../enums';
import { getOpenInvitesAssociatedWithEmail } from '../helpers';
import { exists } from '../types';
export const OPEN_INVITE_STATUSES = [
    InviteStatus.pending.value,
    InviteStatus.snoozed.value
];
export const CLOSED_INVITE_STATUSES = [
    InviteStatus.declined.value,
    InviteStatus.accepted.value
];
export const getAllowedOrganizationSnaps = (user) => __awaiter(void 0, void 0, void 0, function* () {
    const { allowedOrganizations } = user.data();
    if (!allowedOrganizations || allowedOrganizations.length === 0) {
        return [];
    }
    return yield Promise.all(allowedOrganizations.map((org) => __awaiter(void 0, void 0, void 0, function* () {
        // Just in case there's broken refs out there, we're not using getOrThrow for now
        return (yield org.get());
    })));
});
export const getRolesFromAllowedOrgs = (allowedOrganizations, role) => {
    return allowedOrganizations.reduce((acc, org) => (Object.assign(Object.assign({}, acc), { [org.id]: role || RoleType.admin.value })), {});
};
/**
 * Filter for only open organization invites
 */
export const getOpenOrgInvitesForUser = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield getOpenInvitesAssociatedWithEmail(ctx, user.data().email)).filter(invite => !!invite.data().organizationId);
});
/**
 * Filter open organization invites for only pending invites
 */
export const getPendingOrgInvitesForUser = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield getOpenOrgInvitesForUser(ctx, user)).filter(invite => invite.data().status === InviteStatus.pending.value);
});
/**
 * Open invite statuses: pending, snoozed
 * Closed invite statuses: declined, accepted
 */
export const inviteIsOpen = (invite) => {
    return exists(invite) && OPEN_INVITE_STATUSES.includes(invite.data().status);
};
export const updateRoleForOrg = (user, role, organization) => __awaiter(void 0, void 0, void 0, function* () {
    yield user.ref.update({
        roles: Object.assign(Object.assign({}, user.data().roles), { [organization.id]: role })
    });
});
export const getAdminUsersOfOrganization = (organizationSnap, ctx) => __awaiter(void 0, void 0, void 0, function* () {
    const membersSnap = yield ctx
        .usersRef()
        .where('allowedOrganizations', 'array-contains', organizationSnap.ref)
        .get();
    if (!membersSnap.docs.length)
        return;
    const adminMembers = membersSnap.docs.filter(member => {
        var _a;
        return exists(member) &&
            ((_a = member.data()) === null || _a === void 0 ? void 0 : _a.roles[organizationSnap.id]) === RoleType.admin.value;
    });
    return adminMembers;
});
