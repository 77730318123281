/**
 * NOTE: This file uses a series of TypeScript tricks to extract some type
 * information into a form that is available at runtime. By doing this we
 * ensure that the helpers in this file stay useful and correct as we update
 * our types.
 */
import { replaceNullWithUndefined } from 'lib/helpers';
const PLACEMENT_NOTICE_OBJ = {
    filer: undefined,
    publicationDates: undefined,
    adTemplate: undefined,
    rate: undefined,
    newspaper: undefined,
    noticeType: undefined,
    previousNoticeType: undefined,
    columns: undefined,
    confirmedHtml: undefined,
    unusedConfirmedHtml: undefined,
    confirmedCrop: undefined,
    displayParams: undefined,
    displayUrl: undefined,
    invoiceMailings: undefined,
    filedBy: undefined,
    createdBy: undefined,
    processedDisplay: undefined,
    squashable: undefined,
    dynamicHeaders: undefined,
    dynamicFooter: undefined,
    footerFormatString: undefined,
    pdfStoragePath: undefined,
    jpgStoragePath: undefined,
    jpgURL: undefined,
    referenceId: undefined,
    proofStoragePath: undefined,
    customAffidavit: undefined,
    defaultRateOverride: undefined,
    userId: undefined,
    formattingError: undefined,
    postWithoutFormatting: undefined,
    requiresFormatting: undefined,
    designNotes: undefined,
    continueWithLargeFile: undefined,
    requireEmailAffidavit: undefined,
    mailAffidavitsOutsideColumn: undefined,
    invoiceRecipient: undefined,
    fixedPrice: undefined,
    owner: undefined,
    madlibData: undefined,
    text: undefined,
    headerText: undefined,
    anonymousFilerId: undefined
};
/**
 * A type-safe list of all ENotice fields which are in EPlacement.
 */
const PLACEMENT_NOTICE_FIELDS = Object.keys(PLACEMENT_NOTICE_OBJ);
const PLACEMENT_NOTICE_DRAFT_OBJ = Object.assign(Object.assign({}, PLACEMENT_NOTICE_OBJ), { original: undefined, unusedDisplay: undefined, anonymousFilerId: undefined, madlibData: undefined, publicationDatesUpdated: undefined });
/**
 * A type-safe list of all ENoticeDraft fields which are in EPlacement.
 */
const PLACEMENT_NOTICE_DRAFT_FIELDS = Object.keys(PLACEMENT_NOTICE_DRAFT_OBJ);
const pickAllowedFields = (data, allowedFields) => {
    const res = {};
    const keys = Object.keys(data);
    for (const k of keys) {
        // This 'as any' is required because of how 'includes()` is typed. We know
        // that 'k' will be a string so this is safe.
        if (allowedFields.includes(k)) {
            res[k] = data[k];
        }
    }
    return res;
};
/**
 * Get the fields from an existing notice which were set during the placement flow.
 */
export const getPlacementFlowFieldsFromNotice = (notice) => {
    return pickAllowedFields(notice, PLACEMENT_NOTICE_FIELDS);
};
/**
 * Extract ENoticeDraft fields from EPlacement.
 */
export const getNoticeDraftFieldsFromPlacement = (placement) => {
    return pickAllowedFields(placement, PLACEMENT_NOTICE_DRAFT_FIELDS);
};
/**
 * Extract a partial ENotice object from Placement state.
 */
export const getPartialNoticeFromPlacement = (placement) => {
    return replaceNullWithUndefined(pickAllowedFields(placement, PLACEMENT_NOTICE_FIELDS));
};
