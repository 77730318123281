import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { isColumnUser } from 'lib/helpers';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';
import { useAppSelector } from 'redux/hooks';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { exists } from 'lib/types';
import { Label } from 'lib/components/Label';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import moment from 'moment-timezone';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { getDateForDateStringInTimezone, getDateStringForDateInTimezone } from 'lib/utils/dates';
import { EEditionUploadMethod } from 'lib/types/eedition';
import AffidavitFeeSplitSettingsCard from './AffidavitFeeSplitSettingsCard';
export default function ColumnManagedAffidavitsCard({ onChangeGlobalAffidavitControls, globalAffidavitControls }) {
    var _a, _b, _c, _d;
    const user = useAppSelector(selectUser);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const { affidavitReconciliationSettings } = globalAffidavitControls;
    const affidavitTemplatesQuery = useFirestoreQueryListener(getFirebaseContext()
        .affidavitTemplatesRef()
        .where('publisher', '==', activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref), [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    const affidavitTemplates = (affidavitTemplatesQuery === null || affidavitTemplatesQuery === void 0 ? void 0 : affidavitTemplatesQuery.docs.filter(affidavitTemplate => !affidavitTemplate.data().archived &&
        affidavitTemplate.data().isColumnManaged)) || [];
    const canEditColumnManagedAffidavitSettings = exists(user) && isColumnUser(user);
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: 'Column Managed Affidavits' } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Affidavits managed by Column?", description: "Turn on Column's automated affidavits service for this publisher.", value: !!affidavitReconciliationSettings.affidavitsManagedByColumn, onChange: newVal => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { affidavitsManagedByColumn: newVal }) })), disabled: !canEditColumnManagedAffidavitSettings }) })), canEditColumnManagedAffidavitSettings &&
                affidavitReconciliationSettings.affidavitsManagedByColumn && (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require notarization?", description: "If enabled, Column-managed affidavits for this publisher will require notarization.", value: !!affidavitReconciliationSettings.notarizationRequired, onChange: newVal => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { notarizationRequired: newVal }) })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require wet signature?", description: "If enabled, Column-managed affidavits will be mailed to customers with a wet signature.", value: affidavitReconciliationSettings.notarizationVendor ===
                                'manual', onChange: newVal => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { notarizationVendor: newVal ? 'manual' : 'notarize' }) })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require in-state notary?", description: "If enabled, Column-managed affidavits will be notarized by a notary in the publication's state.", value: !!affidavitReconciliationSettings.requiresInStateNotary, onChange: newVal => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { requiresInStateNotary: newVal }) })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { options: [
                                {
                                    label: 'Column will automatically scrape their e-editions from a hosted site',
                                    value: EEditionUploadMethod.SCRAPER
                                },
                                {
                                    label: 'The newspaper will set up an automated FTP feed, uploading their e-editions after publication',
                                    value: EEditionUploadMethod.FTP
                                },
                                {
                                    label: 'The newspaper will manually upload their e-editions after publication',
                                    value: EEditionUploadMethod.MANUAL
                                },
                                {
                                    label: 'Not applicable',
                                    value: 'not-applicable'
                                }
                            ], value: (_a = affidavitReconciliationSettings.uploadMethod) !== null && _a !== void 0 ? _a : 'not-applicable', onChange: method => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { uploadMethod: method }) })), id: "edit-e-edition-upload-method", labelText: "What is the expected upload method for this paper's e-editions?" }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsxs(Label, Object.assign({ id: "edit-reconciliation-start-date" }, { children: [_jsx("p", Object.assign({ className: "mb-3" }, { children: "Reconciliation start date" })), _jsx(ColumnDatePicker, { format: "M/d/yy", momentFormat: "M/D/YY", placeholderText: "M/D/YY", value: getDateStringForDateInTimezone({
                                        date: affidavitReconciliationSettings.reconciliationStartDate.toDate(),
                                        timezone: ((_b = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _b === void 0 ? void 0 : _b.iana_timezone) ||
                                            'America/Chicago'
                                    }), onChange: newDate => {
                                        var _a;
                                        if (newDate) {
                                            onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { reconciliationStartDate: getFirebaseContext().timestampFromDate(getDateForDateStringInTimezone({
                                                        dayString: moment(newDate).format('YYYY-MM-DD'),
                                                        timezone: ((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.iana_timezone) ||
                                                            'America/Chicago'
                                                    })) }) }));
                                        }
                                    }, className: "p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary" })] })) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "column-affidavit-template", value: (_c = affidavitReconciliationSettings.managedAffidavitTemplate) === null || _c === void 0 ? void 0 : _c.id, labelText: "Affidavit template", options: affidavitTemplates.map(affidavitTemplate => ({
                                label: affidavitTemplate.data().name,
                                value: affidavitTemplate.id
                            })), onChange: newVal => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { managedAffidavitTemplate: getFirebaseContext()
                                        .affidavitTemplatesRef()
                                        .doc(newVal) }) })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(CurrencyTextField, { id: "affidavit-fee-in-cents", labelText: "Affidavit automation fee", initialValue: (_d = affidavitReconciliationSettings.automatedAffidavitFeeInCents) !== null && _d !== void 0 ? _d : 0, onChange: newVal => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { automatedAffidavitFeeInCents: newVal }) })) }) })), _jsx(AffidavitFeeSplitSettingsCard, { onChangeGlobalAffidavitControls: onChangeGlobalAffidavitControls, globalAffidavitControls: globalAffidavitControls, affidavitReconciliationSettings: affidavitReconciliationSettings })] }))] })));
}
