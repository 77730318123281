import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Day, TimezoneType } from 'lib/enums';
import moment from 'moment-timezone';
import { PublishingIcon } from '../PublishingIcon';
export function RegularDeadlinesRow({ timeZone, deadlineSetting: { dayEnum, deadline: { dayEnum: deadlineDayEnum, time }, weeks, publish } }) {
    var _a, _b, _c;
    const publishingDay = (_a = Day.by_value(dayEnum)) === null || _a === void 0 ? void 0 : _a.label;
    const deadlineDay = (_b = Day.by_value(deadlineDayEnum)) === null || _b === void 0 ? void 0 : _b.label;
    // TODO: Convert to 12-hour
    const [hour, minute] = time.split(':').map(numStr => Number(numStr));
    const deadlineTime = moment()
        .tz(timeZone)
        .set({ hour, minute })
        .format('h:mm A');
    const timezone = (_c = TimezoneType.by_key(timeZone)) === null || _c === void 0 ? void 0 : _c.abbrev;
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: publishingDay }), _jsx("td", Object.assign({ className: "pl-8" }, { children: _jsx(PublishingIcon, { publish: publish, publishingDay: publishingDay !== null && publishingDay !== void 0 ? publishingDay : '' }) })), _jsx("td", { children: publish ? (_jsxs(_Fragment, { children: [_jsx("p", { children: deadlineDay }), _jsx("p", Object.assign({ className: "font-sm" }, { children: weeks ? `${weeks} week${weeks > 1 ? 's' : ''} before` : '' }))] })) : ('--') }), _jsx("td", { children: publish ? `${deadlineTime} ${timezone}` : '--' })] }));
}
