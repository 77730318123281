var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { getUserName } from 'components/helpers';
import { logAndCaptureException } from 'utils';
import { RoleType, InviteStatus, OccupationType } from 'lib/enums';
import { exists } from 'lib/types';
import { getAllowedOrganizationSnaps } from 'lib/users';
import { getFirebaseContext } from 'utils/firebase';
import { createNotificationsObject } from 'lib/utils/users';
import { ColumnService } from 'lib/services/directory';
/**
 * Used in the JoinOrganizationModal and JoinOrganizationRequestModal to get
 * icon colors for organizations
 */
export const getOrganizationIconColors = (index) => {
    return [
        { bg: 'purple-100', stroke: '#7A5AF8' },
        { bg: 'green-100', stroke: '#00925E' },
        { bg: 'red-100', stroke: '#dc2626' },
        { bg: 'blue-100', stroke: '#2563eb' }
    ][index % 4];
};
export const transformInvitesToActionCardInvites = (invites) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Promise.all(invites.map((invite, index) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const { organizationId } = invite.data();
        if (!organizationId) {
            throw new Error(`In transformInvitesToActionCardInvites expected organizationId to be a non-empty string but received ${typeof organizationId === 'string'
                ? 'an empty string'
                : typeof organizationId}.`);
        }
        const organization = yield getFirebaseContext()
            .organizationsRef()
            .doc(organizationId)
            .get();
        return {
            userInvite: invite,
            userName: invite.data().user
                ? (_c = (_b = (yield ((_a = invite.data().user) === null || _a === void 0 ? void 0 : _a.get()))) === null || _b === void 0 ? void 0 : _b.data()) === null || _c === void 0 ? void 0 : _c.name
                : '',
            organization,
            iconStyles: getOrganizationIconColors(index)
        };
    })));
});
export const transformRequestsToActionCard = (requests, ctx) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Promise.all(requests.map((request) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        return ({
            userRequest: request,
            userName: yield getUserName(request.data().userId, ctx),
            role: RoleType.admin.value,
            organizationName: (_d = (yield request.data().organization.get()).data()) === null || _d === void 0 ? void 0 : _d.name
        });
    })));
});
export const declineInviteHelper = (user, inviteSnap) => __awaiter(void 0, void 0, void 0, function* () {
    if (!user.data().notifications) {
        const occupation = user.data().occupation || OccupationType.individual.value;
        const notifications = createNotificationsObject(occupation);
        yield user.ref.update({
            notifications
        });
    }
    yield inviteSnap.ref.update({
        status: InviteStatus.declined.value
    });
});
const syncingExistingNotices = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield api.post('users/sync-existing-notices', {
        userId
    });
    if (!resp.success) {
        throw new Error(resp.error);
    }
});
export const acceptInvitesHelper = (ctx, inviteSnaps, user, authActions) => __awaiter(void 0, void 0, void 0, function* () {
    var _e, _f;
    const userWasIndividualOrAnonymous = !((_e = user.data()) === null || _e === void 0 ? void 0 : _e.occupation) ||
        ((_f = user.data()) === null || _f === void 0 ? void 0 : _f.occupation) === OccupationType.individual.value;
    yield Promise.all(inviteSnaps.map((inviteSnap) => __awaiter(void 0, void 0, void 0, function* () {
        const { organizationId } = inviteSnap.data();
        if (!organizationId) {
            throw new Error(`In acceptInvitesHelper expected organizationId to be a non-empty string but received ${typeof organizationId === 'string'
                ? 'an empty string'
                : typeof organizationId}.`);
        }
        const orgSnap = yield ctx.organizationsRef().doc(organizationId).get();
        if (!exists(orgSnap)) {
            return;
        }
        const response = yield api.post(`users/${user.id}/invites/${inviteSnap.id}/accept`);
        if (!response.success) {
            throw new Error(response.error);
        }
    })));
    /* User referencee updated in addUserToOrganizationFromInvite and new user data required to get the users updated allowedOrganization's list
      That is the reason of re-fetching the user to pass in getAllowedOrganizationSnaps */
    const availableOrgs = yield getAllowedOrganizationSnaps((yield user.ref.get()));
    authActions.setAvailableOrganizations(availableOrgs);
    const inviteFromSession = sessionStorage.getItem('inviteId');
    if (inviteFromSession &&
        inviteSnaps.map(i => i.id).includes(inviteFromSession)) {
        sessionStorage.removeItem('inviteId');
    }
    /**
     * Only sync existing notices to the newly joined org
     * if the user was formerly an individual and not joining
     * additional orgs
     */
    if (userWasIndividualOrAnonymous) {
        authActions.setActiveOrganization(availableOrgs[0]);
        yield syncingExistingNotices(user.id);
        yield user.ref.update({
            postRegistrationComplete: true
        });
    }
});
export const acceptRequestHelper = (ctx, joinRequests) => __awaiter(void 0, void 0, void 0, function* () {
    yield Promise.all(joinRequests.map((joinRequest) => __awaiter(void 0, void 0, void 0, function* () {
        const { organization, userId } = joinRequest.userRequest.data();
        try {
            const userRole = joinRequest.role;
            const orgSnap = yield ctx.organizationsRef().doc(organization.id).get();
            if (!exists(orgSnap))
                return;
            const userSnap = yield ctx.usersRef().doc(userId).get();
            if (!exists(userSnap))
                return;
            const userWasIndividualOrAnonymous = !userSnap.data().occupation ||
                userSnap.data().occupation === OccupationType.individual.value;
            const response = yield api.post('users/requests/accept', {
                userId,
                joinRequestId: joinRequest.userRequest.id,
                userRole
            });
            if (!response.success) {
                throw new Error(response.error);
            }
            /**
             * Only sync existing notices to the newly joined org
             * if the user was formerly an individual and not joining
             * additional orgs
             */
            if (userWasIndividualOrAnonymous) {
                yield syncingExistingNotices(userId);
            }
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to join organization from request', {
                joinRequestId: joinRequest.userRequest.id,
                organizationId: organization.id,
                userId
            });
        }
    })));
});
export const declineRequestHelper = (joinRequests) => __awaiter(void 0, void 0, void 0, function* () {
    yield Promise.all(joinRequests.map((joinRequest) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const request = joinRequest.userRequest;
            const response = yield api.post('users/requests/decline', {
                userId: request.data().userId,
                joinRequestId: request.id
            });
            if (!response.success) {
                throw new Error(response.error);
            }
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to decline join organization request', {
                joinRequestId: joinRequest.userRequest.id
            });
        }
    })));
});
