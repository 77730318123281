var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { NoticesOverviewTable } from './NoticesOverviewTable';
export function NoticesTab(props) {
    const { publicationIssue } = props;
    const [notices, setNotices] = useState([]);
    const [loadingNotices, setLoadingNotices] = useState(true);
    const loading = loadingNotices;
    useEffect(() => {
        function getNoticesForPublicationIssue() {
            return __awaiter(this, void 0, void 0, function* () {
                setLoadingNotices(true);
                const { response: notices, error: noticeLoadError } = yield publicationIssue.slowGetNoticesForPublicationIssue();
                if (noticeLoadError) {
                    logAndCaptureException(ColumnService.PAGINATION, noticeLoadError, 'Error getting notices for publication issue');
                }
                else {
                    setNotices(notices);
                }
                setLoadingNotices(false);
            });
        }
        void getNoticesForPublicationIssue();
    }, [publicationIssue.id]);
    return _jsx(NoticesOverviewTable, { notices: notices, loading: loading });
}
