import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
import React, { useState } from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import LabeledSwitch from 'lib/components/LabeledSwitch';
const DISTRIBUTE_NONE = {
    evenly: false,
    finalLineItem: false
};
const DISTRIBUTE_EVENLY = {
    evenly: true,
    finalLineItem: false
};
const DISTRIBUTE_FINAL_LINE_ITEM = {
    evenly: false,
    finalLineItem: true
};
export function DistributeFeeSettings({ distributeEnoticeFee, onChange, disabled }) {
    const switchValue = Boolean((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.evenly) || (distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem));
    const [radioButton, setRadioButton] = useState((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem)
        ? DISTRIBUTE_FINAL_LINE_ITEM
        : DISTRIBUTE_EVENLY);
    /**
     * Changes the values of `evenly` and `finalLineItem`, while preserving the value of `affidavitFee`.
     */
    const onConvenienceFeeDistributeChange = (change) => {
        onChange(Object.assign(Object.assign({}, change), { affidavitFee: !!(distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.affidavitFee) }));
    };
    /**
     * Helper to change both the overall form state and the cached radio button value. That way, if
     * you togle the switch off and back on, it retains the selected radio button.
     */
    const onRadioButtonChange = (change) => {
        onConvenienceFeeDistributeChange(change);
        setRadioButton(change);
    };
    return (_jsxs(_Fragment, { children: [_jsx(SwitchControlledCard, Object.assign({ labelProps: {
                    label: 'Embed the Column fee?',
                    description: 'If enabled, the Column fee(s) will be embedded into invoice line item(s).',
                    value: switchValue,
                    onChange: newValue => onConvenienceFeeDistributeChange(newValue ? radioButton : DISTRIBUTE_NONE),
                    disabled
                }, header: "DISTRIBUTION SETTINGS" }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(RadioButtonGroup, { id: "billing-settings-distribution-options", labelText: "", options: [
                            {
                                labelText: 'Divided evenly across invoice line items',
                                value: 'evenly',
                                disabled
                            },
                            {
                                labelText: 'Embedded in the final invoice line item',
                                value: 'finalLineItem',
                                disabled
                            }
                        ], value: radioButton.evenly ? 'evenly' : 'finalLineItem', onChange: distributeType => {
                            switch (distributeType) {
                                case 'evenly': {
                                    return onRadioButtonChange(DISTRIBUTE_EVENLY);
                                }
                                case 'finalLineItem': {
                                    return onRadioButtonChange(DISTRIBUTE_FINAL_LINE_ITEM);
                                }
                            }
                        } }) })) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Add Column's affidavit fee to publisher's affidavit fee?", description: 'If enabled, the Column affidavit fee will be added to the first invoice line item that includes "affidavit" in its description.', value: !!(distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.affidavitFee), onChange: affidavitFee => onChange(Object.assign(Object.assign({}, distributeEnoticeFee), { affidavitFee })), disabled: disabled }) }))] }));
}
