import { jsx as _jsx } from "react/jsx-runtime";
import { push } from 'connected-react-router';
import { PlusCircleIcon } from 'icons';
import { publisherReadyToUpload } from 'lib/publishers';
import React from 'react';
import { connect } from 'react-redux';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { OccupationType } from 'lib/enums';
import { ColumnButton } from 'lib/components/ColumnButton';
function PlaceNoticeButton({ id, activeOrganization, user, showAllOrgsNotices, push }) {
    const publisherCanSeeNewspaperSelectinPlacement = canPublisherUserSeeNewspaperSelect(user, true);
    const isPublisher = user.data().occupation === OccupationType.publishing.value ||
        user.data().occupation === OccupationType.press_association_manager.value;
    const placementDisabled = 
    /**
     * If the user is a publisher and they are currently viewing
     * a paper that is not ready for upload
     */
    (isPublisher &&
        !showAllOrgsNotices &&
        !publisherReadyToUpload(activeOrganization, true)) ||
        /**
         * If the user is a publisher, viewing the All Organizations page,
         * and they don't have newspaper selection enabled in placement
         */
        (showAllOrgsNotices && !publisherCanSeeNewspaperSelectinPlacement);
    return (_jsx(ColumnButton, { id: id, trackingClass: "place-notice-button", startIcon: _jsx(PlusCircleIcon, {}), buttonText: 'Place a notice', primary: true, disabled: placementDisabled, type: "button", onClick: () => {
            user.data().occupation ===
                OccupationType.press_association_manager.value
                ? push('/place_press')
                : push('/place/');
        } }));
}
const mapStateToProps = (state) => ({
    activeOrganization: state.auth.activeOrganization,
    user: state.auth.user,
    showAllOrgsNotices: state.auth.showAllOrgsNotices
});
export default connect(mapStateToProps, { push })(PlaceNoticeButton);
