var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { exists } from 'lib/types';
import EModal from 'components/modals/EModal';
import FreeformCModal from 'components/modals/FreeFormCModal';
import AuthActions from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { logAndCaptureException } from 'utils';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { safeStringify } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';
import InvoiceForm from './InvoiceForm';
import { getActiveDiscountConfigForNotice, getUpdatedDiscountLineItemsForNotice, wereDiscountLineItemsChanged } from '../../../../lib/notice/discounts';
import { getFirebaseContext } from '../../../../utils/firebase';
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        organization: state.auth.organization,
        activeOrganization: state.auth.activeOrganization,
        availableOrganizations: state.auth.availableOrganizations,
        id: (_b = (_a = matchPath(state.router.location.pathname, {
            path: '/:path(notice|publish)/:id/invoice/create',
            exact: true,
            strict: false
        })) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id,
        state
    });
};
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    push: (path) => dispatch(push(path))
});
function CreateInvoice({ id, push, activeOrganization, availableOrganizations, authActions }) {
    var _a, _b;
    const [advertiserSnap, setAdvertiserSnap] = useState();
    const [rateSnap, setRateSnap] = useState();
    const [newspaperSnap, setNewspaperSnap] = useState();
    const [lastModifiedDate, setLastModifiedDate] = useState();
    const [needRefresh, setNeedRefresh] = useState(false);
    const [openPostModal, setOpenPostModal] = useState();
    const noticeSnap = useFirestoreDocumentListener(id ? getFirebaseContext().userNoticesRef().doc(id) : undefined);
    const getRelatedFields = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(noticeSnap)) {
            return;
        }
        const [rateSnap, advertiserSnap, newspaperSnap] = yield Promise.all([
            noticeSnap.data().rate.get(),
            noticeSnap.data().filer.get(),
            noticeSnap.data().newspaper.get()
        ]);
        const newLastModifiedDate = (noticeSnap.data().editedAt || noticeSnap.data().confirmedAt).toMillis();
        if (!lastModifiedDate || newLastModifiedDate === lastModifiedDate)
            setLastModifiedDate(newLastModifiedDate);
        else
            setNeedRefresh(true);
        if (exists(rateSnap)) {
            setRateSnap(rateSnap);
        }
        if (exists(advertiserSnap)) {
            setAdvertiserSnap(advertiserSnap);
        }
        if (exists(newspaperSnap)) {
            setNewspaperSnap(newspaperSnap);
        }
    });
    const updateDiscountLineItems = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(noticeSnap)) {
            return;
        }
        const { pricing } = noticeSnap.data();
        if (!pricing) {
            logAndCaptureException(ColumnService.PAYMENTS, new Error('No pricing on notice'), 'Returning early in updateDiscountLineItems', { noticeId: noticeSnap.id });
            return;
        }
        const ctx = getFirebaseContext();
        const discountConfig = yield getActiveDiscountConfigForNotice(ctx, noticeSnap.data());
        const updatedLineItems = getUpdatedDiscountLineItemsForNotice(discountConfig, pricing.lineItems);
        const changed = wereDiscountLineItemsChanged(pricing.lineItems, updatedLineItems);
        if (changed) {
            yield noticeSnap.ref.update(removeUndefinedFields({
                pricing: Object.assign(Object.assign({}, pricing), { lineItems: updatedLineItems })
            }));
        }
    });
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            yield updateDiscountLineItems();
        }))();
    }, [safeStringify((_a = noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data()) === null || _a === void 0 ? void 0 : _a.pricing)]);
    useEffect(() => {
        if (!exists(noticeSnap)) {
            return;
        }
        void getRelatedFields();
        if (noticeSnap.data().postWithoutFormatting)
            setOpenPostModal(noticeSnap.data().postWithoutFormatting);
    }, [safeStringify(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data())]);
    useEffect(() => {
        if (exists(newspaperSnap) &&
            exists(activeOrganization) &&
            newspaperSnap.id !== activeOrganization.id) {
            authActions.setActiveOrganization(availableOrganizations.find(o => o.id === newspaperSnap.id));
        }
    }, [newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id, activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    if (!exists(noticeSnap) ||
        !exists(advertiserSnap) ||
        !exists(rateSnap) ||
        !exists(newspaperSnap) ||
        (newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id) !== (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id)) {
        return (_jsx(LoadingState, { context: {
                location: 'CreateInvoice',
                tags: {
                    noticeId: (_b = noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.id) !== null && _b !== void 0 ? _b : ''
                }
            } }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(InvoiceForm, { forward: () => {
                    if (!window.location.pathname.includes('/notices/')) {
                        push(`/notice/${id}`);
                    }
                }, back: () => {
                    push(`/notice/${id}`);
                }, rateSnap: rateSnap, advertiserSnap: advertiserSnap, noticeSnap: noticeSnap, newspaperSnap: newspaperSnap, forwardLabel: "Review & Submit" }), needRefresh && (_jsx(EModal, { header: "Notice Update", body: `The notice content has been updated by another user. 
        In order to submit your invoice, you must refresh this page to see an updated price.`, buttonText: "Refresh Invoice", onConfirm: () => push(window.location.pathname) })), openPostModal && exists(newspaperSnap) && (_jsx(FreeformCModal, Object.assign({ id: "upload-proof", header: "Upload Proof", body: `Before creating an invoice, please upload a final proof for this notice as it will appear in ${newspaperSnap.data().name}.`, setOpen: setOpenPostModal }, { children: _jsx("section", { children: _jsx("button", Object.assign({ id: `confirm-upload-proof`, onClick: () => push(`/place/${id}?step=confirm-ad`), className: "bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-32" }, { children: "Continue" })) }) })))] }));
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
