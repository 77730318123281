var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { getNoticeFiltersWhereNoticesWerePublishedAndUseAffidavits } from 'lib/affidavits';
import { MAX_ELASTIC_FACETS_LENGTH } from 'lib/types/searchable';
import moment from 'moment';
/**
 * Determines the dates that the currently active organization is publishing on
 * on that day. If no notices were published on that day, we return null.
 * @param activeOrganization currently active organization
 * @returns {PublishingDateMetadata[]}
 *
 * TODO: refactor to use runs schema?
 */
export const getAffidavitUploadDatesForNewspaper = (activeOrganization, limit = MAX_ELASTIC_FACETS_LENGTH) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const facets = {
        publicationtimestamps: {
            type: 'value',
            name: 'dates',
            size: limit,
            sort: { value: 'desc' }
        }
    };
    const reconciliationStartDate = (_a = activeOrganization.data()
        .affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.reconciliationStartDate;
    const facetFilters = getNoticeFiltersWhereNoticesWerePublishedAndUseAffidavits(reconciliationStartDate, activeOrganization.data().iana_timezone);
    const { facets: { publicationtimestamps } } = yield api.post('search/usernotices/facets', {
        search: '',
        showAllOrgsNotices: false,
        facets,
        filters: facetFilters
    });
    const allPublishingDays = publicationtimestamps[0].data.map((timestamp) => {
        const publishingMoment = moment.utc(timestamp.value);
        publishingMoment.set('hours', 12);
        return {
            numberOfNotices: timestamp.count,
            timestamp: timestamp.value,
            date: publishingMoment
        };
    });
    const relevantPublishingDays = allPublishingDays.filter(publishingDay => moment(publishingDay.date).isSameOrBefore(moment(), 'day'));
    return relevantPublishingDays;
});
