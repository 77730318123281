var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFirebaseContext } from 'utils/firebase';
import { queryPublicationIssues, sortPublicationIssuesByDate } from 'lib/services/publicationIssueService';
import { getDateStringForDateInTimezone } from 'lib/utils/dates';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { logAndCaptureException } from 'utils';
import { asyncFilter, getFulfilled, getRejected } from 'lib/helpers';
import moment from 'moment';
import { ColumnService } from 'lib/services/directory';
import { sortRunsIntoVerifiedAndUnverified } from '../helpers';
export function useGetPublicationIssuesForAffidavits(activeOrganization, refreshTimestamp) {
    const publicationDateTo = getDateStringForDateInTimezone({
        date: refreshTimestamp,
        timezone: activeOrganization.data().iana_timezone
    });
    const publicationDateFrom = getDateStringForDateInTimezone({
        date: moment(refreshTimestamp).subtract(3, 'months').toDate(),
        timezone: activeOrganization.data().iana_timezone
    });
    const publicationIssueQuery = {
        publisherIds: [activeOrganization.id],
        publicationDateTo,
        publicationDateFrom
    };
    const { value: publicationIssuesAndRuns, isError: publicationIssuesError, isLoading: publicationIssuesLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const { response: publicationIssues, error: queryPublicationIssuesError } = yield queryPublicationIssues(getFirebaseContext(), publicationIssueQuery);
            if (queryPublicationIssuesError) {
                throw queryPublicationIssuesError;
            }
            const filteredPublicationIssueResults = yield asyncFilter(publicationIssues, (publicationIssue) => __awaiter(this, void 0, void 0, function* () {
                try {
                    // TODO (APP-2486): figure out best way to filter out runs for notices that are archived
                    // (see note on model method)
                    const runsResp = yield publicationIssue.getRuns();
                    if (runsResp.error) {
                        throw runsResp.error;
                    }
                    return runsResp.response.length > 0
                        ? { publicationIssue, runs: runsResp.response }
                        : null;
                }
                catch (err) {
                    logAndCaptureException(ColumnService.AFFIDAVITS, err, 'Error fetching runs for publication issue', {
                        publicationIssueId: publicationIssue.id
                    });
                    return null;
                }
            }));
            if (filteredPublicationIssueResults.error) {
                throw filteredPublicationIssueResults.error;
            }
            const filteredPublicationIssue = filteredPublicationIssueResults.response;
            const pubIssuesWithRunsSortedByVerifResults = yield Promise.allSettled(filteredPublicationIssue.map((pubIssueAndRuns) => __awaiter(this, void 0, void 0, function* () {
                const sortedRunsResp = yield sortRunsIntoVerifiedAndUnverified(pubIssueAndRuns.runs);
                if (sortedRunsResp.error) {
                    throw sortedRunsResp.error;
                }
                return Object.assign({ publicationIssue: pubIssueAndRuns.publicationIssue }, sortedRunsResp.response);
            })));
            const pubIssuesWithRunsSortedErrors = getRejected(pubIssuesWithRunsSortedByVerifResults);
            if (pubIssuesWithRunsSortedErrors.length) {
                logAndCaptureException(ColumnService.AFFIDAVITS, pubIssuesWithRunsSortedErrors[0], 'Error sorting runs for publication issues', {
                    organizationId: activeOrganization.id,
                    publicationDateTo
                });
            }
            const pubIssuesWithRunsSortedByVerificaiton = getFulfilled(pubIssuesWithRunsSortedByVerifResults);
            const sortedPublicationIssues = sortPublicationIssuesByDate(pubIssuesWithRunsSortedByVerificaiton, 'desc');
            return sortedPublicationIssues;
        }),
        dependencies: [activeOrganization.id, refreshTimestamp.valueOf()]
    });
    return {
        publicationIssuesLoading,
        publicationIssuesError,
        publicationIssuesAndRuns
    };
}
