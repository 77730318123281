var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import { isPublisher } from 'lib/utils/users';
import { Collections } from 'lib/constants';
import { removeUndefinedFields } from 'lib/helpers';
import { isNoticeContent, isSupplementalUpload } from 'lib/types/notice';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { getNewMadlibFileData } from 'routes/placeScroll/helpers';
import Firebase from 'EnoticeFirebase';
import { uploadFilesToStorage } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import { ColumnService } from 'lib/services/directory';
import { getPlacementFlowFieldsFromNotice } from './dataCleaning';
import { getFirebaseContext } from './firebase';
export const duplicateNotice = (originalNoticeId, user) => __awaiter(void 0, void 0, void 0, function* () {
    const ctx = getFirebaseContext();
    const noticeSnap = yield ctx.userNoticesRef().doc(originalNoticeId).get();
    if (!exists(noticeSnap)) {
        throw Error(`Cannot duplicate notice ${originalNoticeId} because it does not exist.`);
    }
    const newNoticeRef = ctx.userNoticesRef().doc();
    const newDraftRef = ctx.userDraftsRef().doc();
    const { duplicatedNotice, duplicatedDraftNotice } = yield duplicateNoticeInner(user, noticeSnap, newNoticeRef, newDraftRef);
    yield newDraftRef.set(duplicatedDraftNotice);
    yield newNoticeRef.set(duplicatedNotice);
    yield duplicateNoticeMail(noticeSnap.ref, newDraftRef);
    yield duplicateNoticeFiles(noticeSnap.ref, newNoticeRef, newDraftRef);
    yield duplicateMadlibFiles(noticeSnap, newDraftRef);
    return {
        newNoticeRef,
        newDraftRef
    };
});
/**
 * THIS FUNCTION IS ONLY EXPORTED FOR TESTING.
 */
export const duplicateNoticeInner = (user, noticeSnap, newNoticeRef, newDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeData = noticeSnap.data();
    const userIsPublisher = isPublisher(user);
    // For advertisers we make the following changes:
    //  1) Clear publication dates that have already passed
    //  2) Set the 'filer' to the current user
    const publicationDates = userIsPublisher
        ? noticeData.publicationDates
        : noticeData.publicationDates.filter(d => d.toMillis() > new Date().getTime());
    const filer = userIsPublisher ? noticeData.filer : user.ref;
    // We select only the fields from the existing notice that would have been
    // set client-side during the placement flow. This makes the duplication
    // operation much more like a whole-form copy-paste than actually duplicating
    // the whole database object and leaves behind invoice, affidavits, etc.
    const commonFields = Object.assign(Object.assign({}, getPlacementFlowFieldsFromNotice(noticeData)), { referenceId: `Copy ${noticeSnap.data().referenceId}`, isArchived: false, filer, userId: filer.id, user: user.ref, createdBy: user.ref, editedAt: getFirebaseContext().fieldValue().serverTimestamp(), createTime: getFirebaseContext().fieldValue().serverTimestamp() });
    // Only add publicationDates if at least one exists
    if (publicationDates && publicationDates.length > 0) {
        commonFields.publicationDates = publicationDates;
    }
    else {
        delete commonFields.publicationDates;
    }
    // we clear invoiceMailings records because they are not relevant to the new notice
    // these will contain lob urls linking to the notice being copied. The invoice
    // recipient information will still be copied over via invoiceRecipient field
    // and that will populate in the placement flow
    commonFields.invoiceMailings = [];
    const duplicatedDraftNotice = Object.assign(Object.assign({}, commonFields), { owner: user.ref, original: newNoticeRef });
    const duplicatedNotice = Object.assign(Object.assign({}, commonFields), { drafts: [newDraftRef] });
    return {
        duplicatedNotice,
        duplicatedDraftNotice
    };
});
const duplicateNoticeMail = (originalNoticeRef, newDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeMailCollectionRef = originalNoticeRef.collection(Collections.mail);
    const newDraftMailCollectionRef = newDraftRef.collection(Collections.mail);
    const noticeMailQuerySnap = yield noticeMailCollectionRef.get();
    for (const doc of noticeMailQuerySnap.docs) {
        // We want to copy all of the mail fields besides the status and delivery date
        const mailData = removeUndefinedFields(Object.assign(Object.assign({}, doc.data()), { expected_delivery_date: undefined, mailStatus: undefined }));
        // eslint-disable-next-line no-await-in-loop
        yield newDraftMailCollectionRef.add(mailData);
    }
});
export const getUploadedFileData = (noticeFileData, fileNamePrefix = '') => __awaiter(void 0, void 0, void 0, function* () {
    const { linkToUploadedFile, sanitizedFileName } = noticeFileData;
    const resp = yield fetch(linkToUploadedFile);
    const fileBlob = yield resp.blob();
    const newSanitizedFileName = `${fileNamePrefix}${sanitizedFileName}`;
    const file = new File([fileBlob], newSanitizedFileName, {
        type: fileBlob.type
    });
    return file;
});
export const reuploadNoticeFileContent = (noticeFileData, uploadLocation, duplicatePrefix = '') => __awaiter(void 0, void 0, void 0, function* () {
    const file = yield getUploadedFileData(noticeFileData, duplicatePrefix);
    const { successfulFilesAndUploads, failedFilesAndUploads } = yield uploadFilesToStorage(Firebase.storage(), [file], uploadLocation);
    if (failedFilesAndUploads.length) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, failedFilesAndUploads[0], 'Failure uploading file', {
            uploadLocation
        });
    }
    return successfulFilesAndUploads[0];
});
const duplicateMadlibFiles = (originalNoticeSnap, newDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const originalNoticeMadlib = originalNoticeSnap.data().madlibData;
    if (!originalNoticeMadlib)
        return;
    const updatedMadlibData = yield getNewMadlibFileData(originalNoticeMadlib, originalNoticeSnap.id, newDraftRef.id, true // determine if notice duplicated or not
    );
    if (!updatedMadlibData)
        return;
    yield newDraftRef.update({
        madlibData: updatedMadlibData
    });
});
const duplicateNoticeFiles = (originalNoticeRef, newNoticeRef, newDraftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeFilesCollectionRef = getFirebaseContext().userNoticeFilesRef(originalNoticeRef);
    const newDraftFilesCollectionRef = getFirebaseContext().userNoticeFilesRef(newDraftRef);
    const noticeFileQuerySnap = yield noticeFilesCollectionRef.get();
    for (const noticeFileSnap of noticeFileQuerySnap.docs) {
        const fileData = removeUndefinedFields(Object.assign({}, noticeFileSnap.data()));
        if (isNoticeContent(noticeFileSnap) ||
            isSupplementalUpload(noticeFileSnap)) {
            /**
             * EARLY RETURN - see this thread: https://columnpbc.slack.com/archives/C04H29HJQKH/p1679525251965249
             * We cannot access the folder `/zapier_uploads` from the front end due to our storage permissions
             * Rather than change our storage rules, we simply perform a shallow copy of notices in this folder
             * because we don't believe it's necessary to allow users to edit/delete these kinds of supplemental uploads
             * (those that were upload via Type/Zap)
             *
             * The reason we make this check based on the path name rather than the notice file type (e.g.,
             * checking whether isSupplementalUpload(noticeFileSnap)) is that this provision need not apply to Madlibs
             * files, which will be uploaded to the `/documentcloud` folder along with other notice files (this folder has
             * more lenient storage permissions). And, unlike with files uploaded via Type/Zaps, users *will* have the
             * ability to edit/delete files uploaded via Madlibs
             */
            const { firebaseStoragePath } = noticeFileSnap.data();
            if (firebaseStoragePath &&
                firebaseStoragePath.includes('zapier_uploads')) {
                // eslint-disable-next-line no-await-in-loop
                yield newDraftFilesCollectionRef.add(fileData);
                continue;
            }
            // See NoticeContentStep
            const uploadLocation = `/documentcloud/${newNoticeRef.id}`;
            // eslint-disable-next-line no-await-in-loop
            const newNoticeFile = yield reuploadNoticeFileContent(noticeFileSnap.data(), uploadLocation, 'duplicated_');
            if (!newNoticeFile) {
                logAndCaptureMessage('Unable to duplicate notice file in notice duplication', {
                    originalNoticeId: originalNoticeRef.id,
                    newNoticeId: newNoticeRef.id,
                    noticeFileName: noticeFileSnap.data().sanitizedFileName
                });
                continue;
            }
            // eslint-disable-next-line no-await-in-loop
            const newDownloadUrl = yield newNoticeFile.uploadRef.getDownloadURL();
            fileData.sanitizedFileName = newNoticeFile.file.name;
            fileData.linkToUploadedFile = newDownloadUrl;
        }
        // eslint-disable-next-line no-await-in-loop
        yield newDraftFilesCollectionRef.add(fileData);
    }
});
export const __private = {
    duplicateNoticeMail,
    duplicateNoticeFiles
};
