import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { RateType } from 'lib/enums';
import { roundUp } from 'lib/pricing';
import { getCurrencySymbol, getDisplayUnits, getNoticePreviewLines } from 'lib/helpers';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Badge } from 'lib/components/Badge';
import { selectColumns, selectNoticeType } from 'redux/placement';
import { useAppSelector } from 'redux/hooks';
export function EstimatedPriceContainer({ newspaper, rate, price }) {
    var _a, _b;
    const currencySymbol = getCurrencySymbol(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().currency);
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    return enableNewPlacementFlow ? (_jsx(_Fragment, { children: !((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.hidePreview) && (_jsxs("div", Object.assign({ className: "flex flex-row justify-between text-md mb-4" }, { children: [_jsx("div", { children: 'Estimated Price: ' }), _jsx("div", Object.assign({ className: "text-column-green-600" }, { children: `${currencySymbol}${Number(price) ? Number(price).toFixed(2) : '--'}` }))] }))) })) : (_jsx(_Fragment, { children: !((_b = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _b === void 0 ? void 0 : _b.hidePreview) && (_jsxs("div", Object.assign({ className: "text-2xl" }, { children: ["Estimated Price:", ' ', `${currencySymbol}${Number(price) ? Number(price).toFixed(2) : '--'}`, ' '] }))) }));
}
export function DisplayParamsContainer({ displayParams, rate }) {
    var _a, _b;
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const measurement = getDisplayUnits(rate, displayParams);
    const noticeType = useAppSelector(selectNoticeType);
    const lines = getNoticePreviewLines(rate, displayParams, noticeType);
    return (_jsx("div", Object.assign({ className: "w-full" }, { children: enableNewPlacementFlow ? (_jsxs("div", Object.assign({ className: "flex justify-between overflow-auto" }, { children: [_jsx(Badge, Object.assign({ shape: "rectangle" }, { children: `${RateType.word_count.plural}: ${displayParams && displayParams.words ? displayParams.words : 0}` })), _jsx(Badge, Object.assign({ shape: "rectangle" }, { children: `${RateType.line.plural}: ${lines}` })), _jsx(Badge, Object.assign({ shape: "rectangle" }, { children: `${measurement.unit}: ${measurement.value
                        ? roundUp(measurement.value, (_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.roundOff).toFixed(2)
                        : 0}` }))] }))) : (_jsx(_Fragment, { children: `${displayParams && displayParams.words ? displayParams.words : 0} ${RateType.word_count.plural} - ${displayParams && displayParams.lines ? displayParams.lines : 0} ${RateType.line.plural} - ${measurement.value
                ? roundUp(measurement.value, (_b = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _b === void 0 ? void 0 : _b.roundOff).toFixed(2)
                : 0} ${measurement.unit}` })) })));
}
export function PreviewPlaceholder({ displayParams, isDisplay, isTypeform, madlibConfigPath }) {
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const placementColumns = useAppSelector(selectColumns);
    const NUM_COLS = (displayParams === null || displayParams === void 0 ? void 0 : displayParams.columns) || placementColumns || 1;
    const columns = new Array(NUM_COLS).fill(0);
    return (_jsxs("div", Object.assign({ id: "preview-placeholder", className: "p-3 text-2xl flex h-full w-full overflow-hidden" }, { children: [enableNewPlacementFlow && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: `bg-white h-full w-full grid grid-cols-${NUM_COLS} gap-y-2 gap-x-2` }, { children: columns.map((_, index) => (_jsx("div", { className: "gradient" }, `gradient-${index}`))) })), _jsx("style", { children: `
          .gradient {
            background-image: linear-gradient(0deg, #e9ecef 12.50%, #ffffff 12.50%, #ffffff 50%, #e9ecef 50%, #e9ecef 62.50%, #ffffff 62.50%, #ffffff 100%);
            background-size: 24.00px 24.00px;
        ` })] })), !enableNewPlacementFlow && (_jsx("div", Object.assign({ className: "self-center" }, { children: isDisplay
                    ? 'Preview available after completing Draft Notice step'
                    : isTypeform || !!madlibConfigPath
                        ? 'Preview available upon reaching Draft Notice step'
                        : 'Preview available after upload or copy and paste' })))] })));
}
