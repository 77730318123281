var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'lib/components/Tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { exists } from 'lib/types';
import FormSwitch from './publisher/FormSwitch';
// TODO: This component should be absorbed into a single "Organization Settings Info" component
// and state should be managed locally there, not via the database state.
export default function OrganizationSearchSettingToggle() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [localActiveOrg, setLocalActiveOrg] = useState();
    useEffect(() => {
        if (!exists(activeOrganization)) {
            return;
        }
        const subscription = activeOrganization.ref.onSnapshot(snapshot => {
            if (exists(snapshot)) {
                setLocalActiveOrg(snapshot);
            }
        });
        return () => subscription();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    if (!exists(localActiveOrg)) {
        return null;
    }
    const { showInSearch } = localActiveOrg.data();
    const updateSearchOrgValue = () => __awaiter(this, void 0, void 0, function* () {
        yield localActiveOrg.ref.update({
            showInSearch: !showInSearch
        });
    });
    return (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between h-12 w-full p-4 mt-4 rounded-md bg-white', {
            'border border-primary-500': showInSearch,
            'border border-grey-200': !showInSearch
        }) }, { children: [_jsxs("div", Object.assign({ className: classNames('text-sm font-medium flex items-center', {
                    'text-primary-500': showInSearch,
                    'text-grey-400': !showInSearch
                }) }, { children: [_jsx(Tooltip, Object.assign({ helpText: "If enabled, this organization will appear when users search for existing\n        organizations to join on Column. Keep this turned on if colleagues may\n        need access to your notices.", customPosition: "-mt-40" }, { children: _jsx(QuestionMarkCircleIcon, { className: "h-4 w-4 stroke-2" }) })), _jsx("p", Object.assign({ className: "pl-2" }, { children: "Allow new users to find my organization" }))] })), _jsx(FormSwitch, { checked: Boolean(showInSearch), onChange: () => updateSearchOrgValue() })] })));
}
