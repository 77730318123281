import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { ColumnButton } from 'lib/components/ColumnButton';
import { getRedirect } from 'utils/urls';
import { useAppDispatch } from 'redux/hooks';
export default function ExistingUserRegistrationHeader() {
    const dispatch = useAppDispatch();
    return (_jsx("div", Object.assign({ className: "flex justify-between py-4 px-6 bg-white border border-column-gray-100 sticky top-0 z-100 bg-white" }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(ColumnButton, { startIcon: _jsx(XMarkIcon, { className: "h-6 w-6" }), onClick: () => dispatch(push(getRedirect())), size: "md", type: "button" }), _jsx("span", Object.assign({ className: "pl-4 text-column-gray-800 font-semibold text-xl" }, { children: "New Organization" }))] })) })));
}
