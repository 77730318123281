import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Modal } from 'lib/components/Modal';
export default function ImageEditorModal({ onUpdateOrderImage, orderImage, onClose, layout, disabled }) {
    var _a, _b, _c, _d;
    const aspectRatioValues = layout.aspectRatio.split(' / ');
    const [crop, setCrop] = useState({
        aspect: parseInt(aspectRatioValues[0], 10) / parseInt(aspectRatioValues[1], 10),
        // default to 100% width
        width: ((_a = orderImage.crop) === null || _a === void 0 ? void 0 : _a.widthPct) || 100,
        // don't default on height so the aspect ratio starts working
        height: ((_b = orderImage.crop) === null || _b === void 0 ? void 0 : _b.heightPct) || 0,
        unit: '%',
        x: ((_c = orderImage.crop) === null || _c === void 0 ? void 0 : _c.xPct) || 0,
        y: ((_d = orderImage.crop) === null || _d === void 0 ? void 0 : _d.yPct) || 0
    });
    return (_jsx(Modal, Object.assign({ title: 'Crop Image', id: "crop-modal", onClose: onClose, primaryAction: {
            buttonText: 'Save Edits',
            onClick: () => {
                onUpdateOrderImage(Object.assign(Object.assign({}, orderImage), { crop: {
                        heightPct: crop.height,
                        widthPct: crop.width,
                        xPct: crop.x,
                        yPct: crop.y
                    } }));
                onClose();
            },
            type: 'button'
        } }, { children: _jsx("div", Object.assign({ className: "flex justify-center" }, { children: _jsx(ReactCrop, { ruleOfThirds: true, onChange: (_crop, pctCrop) => {
                    setCrop(pctCrop);
                }, locked: true, crop: crop, src: orderImage.imageUrl, disabled: disabled }) })) })));
}
