import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { ADD_ORGANIZATION_ENTRYPOINT } from 'redux/router';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Tooltip } from 'lib/components/Tooltip';
import { useAppDispatch } from 'redux/hooks';
export default function AddCustomerOrganizationNavLink() {
    const dispatch = useAppDispatch();
    return (_jsx("div", Object.assign({ "data-testid": "create-organization", className: "px-7" }, { children: _jsx(Tooltip, Object.assign({ helpText: "Create or join an organization to share notices, invoices, and affidavits with your team.", position: "bottom", customPosition: "w-80 text-center" }, { children: _jsx(ColumnButton, { id: "create-organization", buttonText: "New organization", startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5 stroke-2 text-column-gray-400" }), tertiary: true, onClick: () => dispatch(push(ADD_ORGANIZATION_ENTRYPOINT)), type: "button" }) })) })));
}
