import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { isForSaleClassified, isHousingPropertyClassified } from 'lib/types/classified';
import { isClassified } from 'lib/types/ad';
import { Label } from 'lib/components/Label';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import MultiStepHeader from '../../components/MultiStepHeader';
import ListingLocationInputs from '../../components/ListingLocationInputs';
const adHasExtraFields = (ad) => {
    const adIsClassified = isClassified(ad);
    return (adIsClassified &&
        (isForSaleClassified(ad) || isHousingPropertyClassified(ad)));
};
function ExtraFieldInputs({ adData, onAdChange }) {
    var _a, _b, _c, _d, _e, _f;
    if (!adHasExtraFields(adData)) {
        return null;
    }
    const onChangeLocationField = (key, value) => {
        const newLocation = Object.assign(Object.assign({}, adData.location), { [key]: value });
        onAdChange(Object.assign(Object.assign({}, adData), { location: newLocation }));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-center text-center" }, { children: [_jsx(MultiStepHeader, { title: 'Additional Data', description: 'This information will make it easier for users to find your ad.' }), _jsx("div", Object.assign({ className: "mb-6" }, { children: _jsx(CurrencyTextField, { id: "listing-price", initialValue: adData.listingPrice, step: "0.01", size: "small", onChange: listingPrice => onAdChange(Object.assign(Object.assign({}, adData), { listingPrice })), labelText: "What is the price of the listed item or property?", required: true, validationMessages: {
                        valueMissing: 'The listing price is required for this ad category'
                    } }) })), _jsx("div", Object.assign({ className: "mb-6 text-center" }, { children: _jsxs("fieldset", Object.assign({ id: "deceased-death-date" }, { children: [_jsx(Label, Object.assign({ id: "classified-listing-location-label" }, { children: "Where is the item or property located? *" })), _jsx("div", Object.assign({ className: "grid md:grid-cols-10 gap-3" }, { children: _jsx(ListingLocationInputs, { id: "classified-listing-location", city: (_b = (_a = adData.location) === null || _a === void 0 ? void 0 : _a.city) !== null && _b !== void 0 ? _b : '', onChangeCity: value => onChangeLocationField('city', value), state: (_d = (_c = adData.location) === null || _c === void 0 ? void 0 : _c.state) !== null && _d !== void 0 ? _d : 0, onChangeState: value => onChangeLocationField('state', value), zipCode: (_f = (_e = adData.location) === null || _e === void 0 ? void 0 : _e.zipCode) !== null && _f !== void 0 ? _f : '', onChangeZipCode: value => onChangeLocationField('zipCode', value) }) }))] })) }))] })));
}
export default ExtraFieldInputs;
