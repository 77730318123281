import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import CurrencyFormatter from 'components/CurrencyFormatter';
import { Permissions } from 'lib/permissions/roles';
import { useHasPermission } from 'utils/useHasPermission';
import { formatPrice, uiToDBCurrency } from 'lib/pricing/ui';
import { InvoiceFormCell } from './InvoiceFormCell';
export function InvoiceFormLineItemRow({ lineItem, currency, handleChange, total, preventPricingModification = false, highlightCorrespondingNoticePubDateDoesNotExist = false, highlightIfRefunded = false }) {
    const { amount, date, description } = lineItem;
    const highlightRefunded = highlightIfRefunded && lineItem.refund;
    const descriptionValue = description || moment(date.toString()).format('MM/DD/YY');
    const defaultSum = '$0.00';
    const canModifyPricing = useHasPermission(Permissions.INVOICES_MODIFY_PRICE);
    const formattedAmount = formatPrice(currency, amount);
    const formattedTotal = formatPrice(currency, total || 0);
    return (_jsxs("tr", Object.assign({ className: highlightCorrespondingNoticePubDateDoesNotExist
            ? highlightRefunded
                ? 'bg-column-gray-200'
                : 'bg-column-red-100'
            : '' }, { children: [_jsx(InvoiceFormCell, { children: highlightCorrespondingNoticePubDateDoesNotExist
                    ? highlightRefunded
                        ? 'Refunded'
                        : 'Pub date removed from notice'
                    : 1 }), _jsx(InvoiceFormCell, { children: _jsx("span", Object.assign({ className: highlightRefunded ? 'line-through' : '' }, { children: descriptionValue })) }), _jsx(InvoiceFormCell, { children: canModifyPricing && !preventPricingModification ? (_jsx(TextField, { value: formattedAmount, onChange: event => {
                        const amountInDollars = event.target.value;
                        const amountInCents = uiToDBCurrency(amountInDollars) || 0;
                        handleChange(amountInCents);
                    }, id: "formatted-numberformat-input", InputProps: {
                        style: {
                            fontSize: '14px',
                            width: '70px'
                        },
                        inputComponent: CurrencyFormatter
                    } })) : (_jsx(NumberFormat, { placeholder: formattedAmount, value: formattedAmount, thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: 'text' })) }), _jsx(InvoiceFormCell, { children: _jsx(NumberFormat, { placeholder: defaultSum, value: formattedTotal, thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: 'text' }) })] })));
}
