import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { capitalizeWord } from 'lib/utils/strings';
import { CustomerTypeString, SHOW_ALL_OPTION_VALUE } from './helpers';
export function CustomerTypeFilter({ editedRowFilter, onSelectNewFilterValue }) {
    return (_jsx(ColumnSelect, { id: "customer-type-filter", labelText: "Customer type", size: "small", options: [
            {
                value: SHOW_ALL_OPTION_VALUE,
                label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
            },
            {
                value: CustomerTypeString.FUNERAL_HOME,
                label: CustomerTypeString.FUNERAL_HOME
            },
            {
                value: CustomerTypeString.INDIVIDUAL,
                label: CustomerTypeString.INDIVIDUAL
            }
        ], value: editedRowFilter.customerType, onChange: onSelectNewFilterValue }));
}
