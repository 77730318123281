import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Alert } from '../Alert';
import FileDropzone from './FileDropzone';
import { useFirebaseStorageUpload } from '../../frontend/hooks/useFirebaseStorageUpload';
export default function FileUpload({ id, multiple = true, disabled = false, required = false, acceptFileTypes, uploadFolder, uploadConfirmation, onFileUpload, storage, fullHeight = false }) {
    const { uploadState, uploadFiles, failedUploads } = useFirebaseStorageUpload({
        storage,
        uploadFolder,
        uploadConfirmation,
        onFileUpload
    });
    return (_jsxs(_Fragment, { children: [_jsx(FileDropzone, { id: id, onDrop: uploadFiles, multiple: multiple, disabled: disabled, required: required, loading: uploadState === 'uploading', acceptFileTypes: acceptFileTypes, fullHeight: fullHeight }), failedUploads && failedUploads.length > 0 && (_jsx("div", Object.assign({ className: "my-2" }, { children: _jsx(Alert, { id: "upload-file-error", status: "error", title: `There was an error uploading ${failedUploads.length} file(s).`, description: "Please try again." }) })))] }));
}
