import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
export function CopyText({ copyText, children, copyLabel }) {
    const [textCopied, setTextCopied] = useState(false);
    const beforeCopyHelpText = copyLabel
        ? `Click to copy ${copyLabel}`
        : 'Click to Copy';
    const afterCopyHelpText = copyLabel
        ? `Copied ${copyLabel} to clipboard!`
        : 'Copied to clipboard!';
    const helpText = textCopied ? afterCopyHelpText : beforeCopyHelpText;
    const onCopy = () => {
        copy(copyText);
        setTextCopied(true);
    };
    return (_jsxs("button", Object.assign({ className: classNames('text-left inline-flex items-center space-x-2', {
            'text-column-gray-700': textCopied
        }), onClick: onCopy, title: helpText, "aria-label": helpText }, { children: [children, textCopied && _jsx(CheckIcon, { className: "ml-1 w-4" }), !textCopied && _jsx(DocumentDuplicateIcon, { className: "ml-1 w-4" })] })));
}
