import moment from 'moment';
export const getTimeOptionsForDeadlines = () => {
    // 24 hours times 4 multiples of 0:15 each hour = 96 total options
    return new Array(96).fill(null).map((_val, idx) => {
        const hour = Math.floor(idx / 4);
        const minute = (idx % 4) * 15;
        const timeMoment = moment().set({ hour, minute });
        const label = timeMoment
            .format('h:mm A')
            .replace('AM', 'a.m.')
            .replace('PM', 'p.m.');
        const value = timeMoment.format('HH:mm');
        return { label, value };
    });
};
