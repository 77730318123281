import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import { CalendarIcon } from '@heroicons/react/24/outline';
export default function ColumnDatePicker({ className, format, momentFormat, placeholderText, value, onChange, shouldDisableDate, disabled, fullWidth }) {
    const dateValue = typeof value === 'string' ? moment(value).toDate() : value;
    return (_jsx(MuiPickersUtilsProvider, Object.assign({ utils: DateFnsUtils }, { children: _jsx(DatePicker, { "data-testid": "datePicker", label: "", value: dateValue, format: format, className: 'p-2', disabled: disabled, TextFieldComponent: props => (_jsxs("div", Object.assign({ className: classNames('p-2 px-3 h-12 border rounded border-column-gray-200 flex items-center text-sm', {
                    'bg-white cursor-pointer': !disabled,
                    'bg-column-gray-25 text-column-gray-300 cursor-default': disabled,
                    'w-full': fullWidth
                }, className !== null && className !== void 0 ? className : ''), onClick: e => props.onClick(e) }, { children: [_jsx("span", Object.assign({ className: classNames('mr-auto', {
                            'text-column-gray-400': !value
                        }) }, { children: value ? moment(value).format(momentFormat) : placeholderText })), _jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(CalendarIcon, { className: "w-5 h-5 text-column-gray-400", strokeWidth: "2", stroke: "currentColor" }) }))] }))), InputProps: {
                disableUnderline: true,
                className: ''
            }, autoOk: true, onChange: newDate => {
                if (newDate) {
                    onChange(new Date(newDate.toISOString()));
                }
                else {
                    onChange(undefined);
                }
            }, shouldDisableDate: date => {
                if (shouldDisableDate) {
                    return shouldDisableDate(date);
                }
                return false;
            } }) })));
}
