import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EditableRowClickableIcon } from './Editable';
import { ArchivableRowClickableIcon } from './Archivable';
export function ActionableHeaderCell({ archivable, editable }) {
    if (!editable && !archivable) {
        return null;
    }
    return (_jsx("th", Object.assign({ className: "font-medium" }, { children: _jsxs("div", Object.assign({ className: "flex pr-12" }, { children: [_jsx("div", { className: "flex-1" }), _jsx("div", { children: "Actions" })] })) })));
}
export function ActionableRowCell({ archivable, onArchive, editable, rowData, index }) {
    if (!editable && !archivable) {
        return null;
    }
    return (_jsx(_Fragment, { children: _jsx("td", { children: _jsxs("div", Object.assign({ className: "flex mr-10" }, { children: [_jsx("div", { className: "flex-1" }), _jsx(EditableRowClickableIcon, { editable: editable, rowData: rowData, index: index }), _jsx(ArchivableRowClickableIcon, { archivable: archivable, onArchive: onArchive, rowData: rowData, index: index })] })) }) }));
}
