var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import TabGroup from 'lib/components/Tabs';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import classNames from 'classnames';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { getFirebaseContext } from 'utils/firebase';
import NoticePreview from '../noticePreview';
import NoticeActivityLog from '../activityLog';
// Views that are shown on the right panel
export const ACTIVITY_LOG_DETAIL_TAB = {
    enabled: true,
    label: 'Activity',
    id: 'activity'
};
export const NOTICE_PREVIEW_DETAIL_TAB = {
    label: 'Preview',
    enabled: true,
    id: 'preview'
};
export default function NoticeDetailDrawer({ setActiveNoticeDetailTab, setShowPreviewOnMobile, activeNoticeDetailTab, showPreviewOnMobile, invoiceOverdue, isPublisher, newspaper, notice, user, push, showAffinityXSyncPanel }) {
    const noticeModel = getModelFromSnapshot(UserNoticeModel, getFirebaseContext(), notice);
    const { value: runs } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            return yield noticeModel.getRuns();
        }),
        dependencies: [notice.id],
        initialData: []
    });
    return (_jsxs("div", Object.assign({ className: classNames({
            'absolute block w-full left-0': showPreviewOnMobile,
            hidden: !showPreviewOnMobile
        }, 'lg:flex bg-white flex-col justify-between overflow-auto w-160 xl:w-200 border-l border-r shadow-column-2') }, { children: [_jsxs("div", Object.assign({ className: "sticky z-10 top-0 bg-white" }, { children: [_jsx(TabGroup, { onClickTab: setActiveNoticeDetailTab, activeTab: activeNoticeDetailTab, tabs: [NOTICE_PREVIEW_DETAIL_TAB, ACTIVITY_LOG_DETAIL_TAB], id: "notice-detail-drawer" }), _jsx("div", Object.assign({ className: "pr-5 pt-3 absolute right-0 top-0 lg:hidden" }, { children: _jsx(ColumnButton, { id: "close-notice-detail-drawer", onClick: () => setShowPreviewOnMobile(false), startIcon: _jsx(XMarkIcon, { className: "w-6 h-6" }), type: "button" }) }))] })), activeNoticeDetailTab === NOTICE_PREVIEW_DETAIL_TAB ? (_jsx(NoticePreview, { invoiceOverdue: invoiceOverdue, isPublisher: isPublisher, newspaper: newspaper, notice: notice, user: user, push: push, showAffinityXSyncPanel: showAffinityXSyncPanel })) : (_jsx(NoticeActivityLog, { notice: noticeModel, runs: runs, user: user }))] })));
}
