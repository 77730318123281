var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import FullScreenModal from 'components/FullScreenModal';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { getFirebaseContext } from 'utils/firebase';
import { PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED } from 'lib/types/events';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
const getGlobalRateSettingsFromActiveOrganization = (activeOrganization) => {
    const { taxPct } = activeOrganization.data();
    return {
        taxPct
    };
};
export default function GlobalRateSettings({ activeOrganization, onClose }) {
    const user = useAppSelector(selectUser);
    const [globalRateSettings, setGlobalRateSettings] = useState(getGlobalRateSettingsFromActiveOrganization(activeOrganization));
    const [saving, setSaving] = useState(false);
    const edited = !columnObjectsAreEqual(globalRateSettings, getGlobalRateSettingsFromActiveOrganization(activeOrganization));
    const { taxPct } = globalRateSettings;
    return (_jsx(FullScreenModal, Object.assign({ headerText: "Edit Settings", onClose: onClose, submittable: {
            buttonText: 'Save',
            disabled: !edited || saving,
            onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                setSaving(true);
                if (user) {
                    yield getFirebaseContext()
                        .eventsRef()
                        .add(removeUndefinedFields({
                        type: PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED,
                        publisher: activeOrganization.ref,
                        createdAt: getFirebaseContext().timestamp(),
                        data: {
                            before: getGlobalRateSettingsFromActiveOrganization(activeOrganization),
                            after: globalRateSettings,
                            changedBy: user === null || user === void 0 ? void 0 : user.ref
                        }
                    }));
                }
                yield activeOrganization.ref.update(removeUndefinedFields({
                    taxPct
                }));
                setSaving(false);
                onClose();
            })
        }, id: "edit-global-rate-settings-form" }, { children: _jsx(CardGridLayout, Object.assign({ header: {
                title: 'Global Rate Settings',
                description: `Configure settings that apply by default to all rates for ${activeOrganization.data().name}.`
            } }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { type: "number", value: taxPct ? taxPct.toString() : '', onChange: newValue => setGlobalRateSettings(Object.assign(Object.assign({}, globalRateSettings), { taxPct: parseFloat(newValue) })), placeholder: "0.000", step: 0.001, max: 25, min: 0, labelText: "What tax will Column apply by default to rates?", id: "tax-amount" }) })) })) })));
}
