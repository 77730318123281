export var Product;
(function (Product) {
    Product["Notice"] = "notice";
    Product["Obituary"] = "obituary";
    Product["Classified"] = "classified";
})(Product || (Product = {}));
export const isValidProduct = (product) => Object.values(Product).includes(product);
export const PRODUCT_TO_NAME = {
    [Product.Notice]: { singular: 'Public Notice', plural: 'Public Notices' },
    [Product.Classified]: { singular: 'Classified', plural: 'Classifieds' },
    [Product.Obituary]: { singular: 'Obituary', plural: 'Obituaries' }
};
