export var RunStatusType;
(function (RunStatusType) {
    RunStatusType["PENDING"] = "pending";
    RunStatusType["MANUALLY_VERIFIED"] = "manually verified";
    RunStatusType["OCR_VERIFICATION_SUCCEEDED"] = "verified via OCR";
    RunStatusType["OCR_VERIFICATION_FAILED"] = "OCR verification failed";
    RunStatusType["UNVERIFIABLE"] = "unverifiable";
    RunStatusType["CANCELLED"] = "cancelled";
    RunStatusType["DISABLED"] = "disabled";
})(RunStatusType || (RunStatusType = {}));
export const verifiedRunStatuses = [
    RunStatusType.MANUALLY_VERIFIED,
    RunStatusType.OCR_VERIFICATION_SUCCEEDED
];
/**
 * Run statuses that are compatible with running the verification process.
 */
export const verifiableRunStatuses = [
    RunStatusType.PENDING,
    RunStatusType.OCR_VERIFICATION_FAILED,
    RunStatusType.UNVERIFIABLE
];
