import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import React from 'react';
import { capitalizeWord } from 'lib/utils/strings';
import { SHOW_ALL_OPTION_VALUE, VerificationFilterString } from './helpers';
export function VerificationFilter({ editedRowFilter, onSelectNewFilterValue }) {
    return (_jsx(ColumnSelect, { id: "verification-status-filter", labelText: "Verification status", size: "small", options: [
            {
                value: SHOW_ALL_OPTION_VALUE,
                label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
            },
            {
                value: VerificationFilterString.COMPLETE,
                label: VerificationFilterString.COMPLETE
            },
            {
                value: VerificationFilterString.INCOMPLETE,
                label: VerificationFilterString.INCOMPLETE
            }
        ], value: editedRowFilter.verification, onChange: onSelectNewFilterValue }));
}
