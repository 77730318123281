var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { dwollaInvalidPasswordMessage, dwollaPasswordValidation } from 'lib/passwordValidators';
import { PasswordField } from 'components/PasswordField';
import Firebase, { FirebaseAuth } from 'EnoticeFirebase';
function DwollaPasswordResetModal({ user, userAuth, fromMainView, passwordUpdateSuccess, setDwollaPasswordModal }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [isDisabled, setDisabled] = useState(true);
    const [currPassword, setCurrPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
    const [validationError, setValidationError] = useState();
    const [passwordMatchingError, setPasswordMatchingError] = useState();
    const matchPassword = () => !error && !validationError;
    useEffect(() => {
        if (newPassword && !dwollaPasswordValidation.test(newPassword)) {
            setValidationError(dwollaInvalidPasswordMessage);
            return;
        }
        setValidationError('');
    }, [newPassword]);
    useEffect(() => {
        if (confirmNewPassword && newPassword) {
            if (newPassword.length === confirmNewPassword.length &&
                newPassword === confirmNewPassword) {
                setError('');
                if (dwollaPasswordValidation.test(newPassword)) {
                    setDisabled(false);
                }
            }
            else {
                setError('Passwords do not match.');
                setDisabled(true);
            }
        }
    }, [confirmNewPassword, newPassword]);
    if (!userAuth) {
        console.error('UserAuth is undefined');
        return _jsx(_Fragment, {});
    }
    const changePassword = () => __awaiter(this, void 0, void 0, function* () {
        if (matchPassword() && currPassword && newPassword) {
            setLoading(true);
            const { currentUser } = Firebase.auth();
            if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.email)) {
                console.error('Current User`s email is undefined');
                return;
            }
            const credentials = FirebaseAuth.EmailAuthProvider.credential(currentUser.email, currPassword);
            try {
                const userAuthResponse = yield userAuth.reauthenticateWithCredential(credentials);
                if (userAuthResponse) {
                    currentUser
                        .updatePassword(newPassword)
                        .then(() => __awaiter(this, void 0, void 0, function* () {
                        yield user.ref.update({
                            dwollaPasswordSecurityEnabled: true
                        });
                        setLoading(false);
                        if (passwordUpdateSuccess) {
                            passwordUpdateSuccess();
                        }
                    }))
                        .catch((error) => {
                        setError(error.message);
                        setLoading(false);
                    });
                }
            }
            catch (error) {
                setPasswordMatchingError(error.message);
                setLoading(false);
            }
        }
    });
    return (_jsx("div", { children: _jsx(FreeformCModal, Object.assign({ header: 'Update Your Column Password', setOpen: fromMainView || !setDwollaPasswordModal
                ? undefined
                : () => setDwollaPasswordModal(), customStyle: 'px-2', noExitOutsideModal: true, width: '30%' }, { children: _jsx("form", Object.assign({ onSubmit: e => {
                    e.preventDefault();
                    void changePassword();
                } }, { children: _jsxs("div", Object.assign({ className: "overflow-y-auto py-2 mt-2 px-2" }, { children: [_jsxs("div", Object.assign({ className: "text-column-gray-400 mb-6 text-sm -mt-3" }, { children: [fromMainView
                                    ? "Another user recently linked a bank account in your newspaper's payment settings. To increase the security of your account, please change your Column password."
                                    : 'Before linking your bank account, please change your Column password to increase the security of your account.', ' ', ' Your new password should be at least 12 characters long and include a letter, number and symbol.'] })), _jsxs("div", Object.assign({ className: "space-y-2" }, { children: [_jsx(PasswordField, { id: "current-password", required: true, value: currPassword || '', placeHolderText: "Current password *", onValueChange: (value) => setCurrPassword(value) }), passwordMatchingError && (_jsx("div", Object.assign({ className: "text-column-red-500 text-sm mb-3", id: "currentPassword" }, { children: passwordMatchingError }))), _jsx(PasswordField, { id: "new-password", required: true, value: newPassword || '', placeHolderText: "New password *", onValueChange: (value) => setNewPassword(value) }), validationError && (_jsx("div", Object.assign({ className: "text-column-red-500 text-sm mb-3" }, { children: validationError }))), _jsx(PasswordField, { id: "confirm-new-password", required: true, value: confirmNewPassword || '', placeHolderText: "Confirm new password *", onValueChange: (value) => setConfirmNewPassword(value) }), error && (_jsx("div", Object.assign({ className: "text-column-red-500 text-sm my-3" }, { children: error }))), _jsx("button", Object.assign({ className: `mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${isDisabled
                                        ? 'cursor-not-allowed pointer-events-none bg-column-gray-300'
                                        : 'bg-blue-500'}`, type: "submit", disabled: isDisabled || loading }, { children: _jsxs("span", Object.assign({ className: "flex" }, { children: [loading && (_jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-column-gray-50 h-5 w-5 mr-2" })), "Confirm"] })) }))] }))] })) })) })) }));
}
export default DwollaPasswordResetModal;
