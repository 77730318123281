import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
export function AllowedPaymentMethods({ restrictPaymentMethods, onChange, disabled }) {
    const enabled = restrictPaymentMethods != null;
    return (_jsx(SwitchControlledCard, Object.assign({ labelProps: {
            label: 'Restrict payment methods?',
            description: 'If enabled, customers can pay invoices with only selected payment methods.',
            value: enabled,
            onChange: newVal => {
                if (!newVal) {
                    onChange(undefined);
                }
                else {
                    onChange({
                        ach_credit_transfer: false,
                        card: false,
                        paper_check: false,
                        us_bank_account: false
                    });
                }
            },
            disabled
        }, header: "" }, { children: restrictPaymentMethods && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(CheckboxGroup, { labelText: 'How should customers be able to pay invoices?', options: [
                    {
                        labelText: 'Card',
                        checked: true,
                        disabled: true,
                        onChange: () => { },
                        tooltipText: 'Card payment acceptance is required.'
                    },
                    {
                        labelText: 'ACH',
                        checked: true,
                        disabled: true,
                        onChange: () => { },
                        tooltipText: 'ACH payment acceptance is required.'
                    },
                    {
                        labelText: 'Paper Checks',
                        checked: !restrictPaymentMethods.paper_check,
                        disabled,
                        onChange: () => {
                            onChange(Object.assign(Object.assign({}, restrictPaymentMethods), { paper_check: !restrictPaymentMethods.paper_check }));
                        }
                    }
                ], id: 'allowed-Payment methods', disabled: disabled }) }))) })));
}
