import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import LabeledSwitch from '../LabeledSwitch';
import { GridInput } from './Grid';
import { EditableCardArray } from './EditableCardArray';
/**
 * UI Component that combines a LabeledSwitch with an EditableCardArray when we want the appearance
 * of the latter to be controlled by the former. For example, in rates settings, we have a toggle
 * that controls whether to charge additional fees for the rate, and then editable cards for users
 * to add, change, and remove specific fee configurations.
 */
export function SwitchControlledCards({ cardProps, labelProps }) {
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, Object.assign({}, labelProps)) })), labelProps.value && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(EditableCardArray, Object.assign({}, cardProps)) })))] }));
}
