import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { TextField } from 'lib/components/TextField';
import React from 'react';
export function ContactInfo({ value, onChange, disabled }) {
    return (_jsxs(SwitchControlledCard, Object.assign({ header: "", labelProps: {
            label: 'Customize contact information',
            description: `By default Column's help center URL appears on invoices. Toggle this on to provide the newspaper's contact info instead.`,
            value: !!value,
            onChange: enableCustomContact => onChange(enableCustomContact ? { email: '', phone: '', url: '' } : undefined),
            disabled
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "invoice-contact-email", labelText: "Email", value: value === null || value === void 0 ? void 0 : value.email, onChange: email => onChange(Object.assign(Object.assign({}, value), { email })), type: "email", disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "invoice-contact-phone", labelText: "Phone", value: value === null || value === void 0 ? void 0 : value.phone, onChange: phone => onChange(Object.assign(Object.assign({}, value), { phone })), type: "tel", disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "invoice-contact-url", labelText: "Website", value: value === null || value === void 0 ? void 0 : value.url, onChange: url => onChange(Object.assign(Object.assign({}, value), { url })), type: "url", disabled: disabled }) }))] })));
}
