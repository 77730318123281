var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { removeUndefinedFields } from 'lib/helpers';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { OccupationType } from 'lib/enums';
import ColumnManagedAffidavitSettingsCard from 'routes/settings/publisher/customers/ColumnManagedAffidavitSettingsCard';
import HorizontalDivider from '../HorizontalDivider';
import CreateOrEditModal from './CreateOrEditModal';
import { CreateOrEditModalDetails } from './CreateOrEditModalDetails';
import { CreateOrEditCustomerModalSettings, REQUIRE_UPFRONT_PAYMENT_TYPES } from './CreateOrEditCustomerModalSettings';
export default function EditCustomerModal({ customerSnap, userSnap, activeOrganization, rates, closeModal }) {
    var _a;
    const dispatch = useAppDispatch();
    /**
     * Intiailize fields from customer and user
     */
    const customer = customerSnap.data();
    const user = userSnap.data();
    const linerRateSnap = (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a; return rate.id === ((_a = customer.linerRate) === null || _a === void 0 ? void 0 : _a.id); })) ||
        (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a, _b; return rate.id === ((_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.defaultLinerRate) === null || _b === void 0 ? void 0 : _b.id); }));
    const displayRateSnap = (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a; return rate.id === ((_a = customer.displayRate) === null || _a === void 0 ? void 0 : _a.id); })) ||
        (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a, _b; return rate.id === ((_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.defaultDisplayRate) === null || _b === void 0 ? void 0 : _b.id); }));
    const initialCustomerData = Object.assign(Object.assign({}, customer), { email: user.email, firstName: customer.firstName || user.firstName, lastName: customer.lastName || user.lastName, linerRateSnap,
        displayRateSnap, upfrontPaymentSetting: customer.requireUpfrontPayment === null ||
            customer.requireUpfrontPayment === undefined
            ? REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT
            : customer.requireUpfrontPayment
                ? REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE
                : REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE });
    const [updatedCustomerData, setUpdatedCustomerData] = useState(initialCustomerData);
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    useEffect(() => {
        if (!updatedCustomerData.email || !updatedCustomerData.firstName) {
            setDisableSave(true);
        }
        else {
            setDisableSave(false);
        }
    }, [updatedCustomerData]);
    const onSave = () => __awaiter(this, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        setLoading(true);
        const linerRateIsPublisherDefault = ((_b = updatedCustomerData.linerRateSnap) === null || _b === void 0 ? void 0 : _b.id) ===
            ((_d = (_c = activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.defaultLinerRate) === null || _d === void 0 ? void 0 : _d.id);
        const displayRateIsPublisherDefault = ((_e = updatedCustomerData.displayRateSnap) === null || _e === void 0 ? void 0 : _e.id) ===
            ((_g = (_f = activeOrganization.data()) === null || _f === void 0 ? void 0 : _f.defaultDisplayRate) === null || _g === void 0 ? void 0 : _g.id);
        /**
         * Convert CustomerDataFields to an object that more closely resembles the
         * Customer type
         */
        const updates = {
            firstName: updatedCustomerData.firstName,
            lastName: updatedCustomerData.lastName,
            address: updatedCustomerData.address,
            addressLine2: updatedCustomerData.addressLine2,
            city: updatedCustomerData.city,
            state: updatedCustomerData.state,
            zipCode: updatedCustomerData.zipCode,
            phone: updatedCustomerData.phone,
            internalID: updatedCustomerData.internalID,
            enableAffidavitsBeforePayment: updatedCustomerData.enableAffidavitsBeforePayment,
            allowAffidavitEmail: updatedCustomerData.allowAffidavitEmail,
            bulkPaymentEnabled_v2: updatedCustomerData.bulkPaymentEnabled_v2,
            billingTerm: updatedCustomerData.billingTerm,
            invoicedOutsideColumn: updatedCustomerData.invoicedOutsideColumn,
            requireUpfrontPayment: (_h = updatedCustomerData.upfrontPaymentSetting) === null || _h === void 0 ? void 0 : _h.value,
            defaultColumns: updatedCustomerData.defaultColumns,
            defaultNoticeType: updatedCustomerData.defaultNoticeType,
            linerRate: linerRateIsPublisherDefault
                ? getFirebaseContext().fieldValue().delete()
                : (_j = updatedCustomerData.linerRateSnap) === null || _j === void 0 ? void 0 : _j.ref,
            displayRate: displayRateIsPublisherDefault
                ? getFirebaseContext().fieldValue().delete()
                : (_k = updatedCustomerData.displayRateSnap) === null || _k === void 0 ? void 0 : _k.ref,
            organizationName: updatedCustomerData.organizationName,
            discountConfig: ((_m = (_l = updatedCustomerData.discountConfig) === null || _l === void 0 ? void 0 : _l.subtotal) === null || _m === void 0 ? void 0 : _m.amount) === 0
                ? {}
                : updatedCustomerData.discountConfig,
            affidavitReconciliationSettings: updatedCustomerData.affidavitReconciliationSettings
        };
        removeUndefinedFields(updates);
        yield customerSnap.ref.update(updates);
        const toastText = `Your changes to ${(_o = updatedCustomerData.firstName) === null || _o === void 0 ? void 0 : _o.trim()} ${(_p = updatedCustomerData.lastName) === null || _p === void 0 ? void 0 : _p.trim()}'s details have been updated.`;
        dispatch(ToastActions.toastSuccess({
            headerText: 'Changes successfully saved',
            bodyText: toastText
        }));
        setLoading(false);
        closeModal(true);
    });
    const formId = 'edit-customer-modal-form';
    /*
     * Temporary measure: The ability to edit settings is being disabled for customers
     * who are funeral directors during the obituaries launch phase.
     */
    const hideCustomerModalSettings = userSnap.data().occupation === OccupationType.funeral_director.value;
    return (_jsxs(CreateOrEditModal, Object.assign({ headerText: "Edit", buttonText: "Save", onClose: () => closeModal(false), loading: loading, onSubmit: onSave, disableSave: disableSave, id: formId }, { children: [_jsx(CreateOrEditModalDetails, { value: updatedCustomerData, onChange: data => {
                    setUpdatedCustomerData(data);
                }, activeOrganization: activeOrganization, showOrganizationName: !((_a = user.allowedOrganizations) === null || _a === void 0 ? void 0 : _a.length) }), _jsx("div", Object.assign({ className: "py-6 max-w-4xl m-auto" }, { children: _jsx(HorizontalDivider, {}) })), !hideCustomerModalSettings && (_jsx(CreateOrEditCustomerModalSettings, { value: updatedCustomerData, onChange: data => setUpdatedCustomerData(data), rates: rates, activeOrganization: activeOrganization, advertiser: user })), _jsx("div", Object.assign({ className: "py-6 max-w-4xl m-auto" }, { children: _jsx(HorizontalDivider, {}) })), _jsx(ColumnManagedAffidavitSettingsCard, { onUpdateCustomer: data => setUpdatedCustomerData(data), updatedCustomer: updatedCustomerData })] })));
}
