import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { normalizeSpaces } from 'lib/helpers';
import PlacementActions, { selectNoticeName } from 'redux/placement';
import { TextField } from 'lib/components/TextField';
const MAX_CHAR_LENGTH = 45;
export function useReferenceIdState() {
    const dispatch = useAppDispatch();
    const currentNoticeName = useAppSelector(selectNoticeName);
    const [referenceId, setReferenceId] = useState('');
    const normalizedReferenceId = normalizeSpaces(referenceId);
    useEffect(() => {
        // If the notice name has changed in the store, update the local state
        setReferenceId(currentNoticeName || '');
    }, [currentNoticeName]);
    function saveReferenceId() {
        dispatch(PlacementActions.confirmReferenceId(normalizedReferenceId));
    }
    return {
        referenceId,
        setReferenceId,
        saveReferenceId
    };
}
export function ReferenceIdForm({ referenceId, setReferenceId }) {
    const LABELS = {
        FIELD_LABEL: 'What would you like to call this notice?',
        FIELD_NOTE: 'This name will not appear in the publication.',
        PLACEHOLDER: 'Case number, purchase order number, or title',
        MAXCHARS: `Maximum ${MAX_CHAR_LENGTH} characters allowed.`
    };
    return (_jsx(TextField, { id: "notice-name", labelText: LABELS.FIELD_LABEL, required: true, placeholder: LABELS.PLACEHOLDER, value: referenceId || '', onChange: newValue => {
            setReferenceId(newValue.slice(0, MAX_CHAR_LENGTH));
        }, noteText: referenceId.length >= MAX_CHAR_LENGTH
            ? LABELS.MAXCHARS
            : LABELS.FIELD_NOTE }));
}
