import { INDESIGN_SERVICE_URL, IndesignRoute } from 'lib/constants';
import { callInDesignService } from 'lib/requests';
export const getIndesignServerClient = () => {
    return {
        call(route, args) {
            return callInDesignService(`${INDESIGN_SERVICE_URL}/${route}`, args.request, args.options);
        }
    };
};
export const callIndesignTemplateSpecs = (args) => {
    const route = IndesignRoute.template_specs;
    return callInDesignService(`${INDESIGN_SERVICE_URL}/${route}`, args.request, args.options);
};
export const callIndesignLinerResponse = (args) => {
    const route = IndesignRoute.liner_ad_image;
    return callInDesignService(`${INDESIGN_SERVICE_URL}/${route}`, args.request, args.options);
};
