import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
function MilestoneConnector({ status }) {
    return (_jsx("div", Object.assign({ "data-testid": "milestone-connector" }, { children: _jsx("div", { className: classNames('border w-8', {
                'border-column-primary-500': status === 'active',
                'border-column-gray-100': status === 'inactive'
            }) }) })));
}
export default MilestoneConnector;
