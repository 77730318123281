var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BadRequestError, InternalServerError, wrapErrorAsColumnError } from '../errors/ColumnErrors';
import { safeAsync } from '../safeWrappers';
import { ProductSiteSettingModel } from '../model/objects/productSiteSettingModel';
import { wrapError, wrapSuccess } from '../types/responses';
import { safeGetModelArrayFromQuery, safeGetModelFromRef } from '../model/getModel';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';
export class ProductSiteSettingService {
    constructor(ctx) {
        this.ctx = ctx;
    }
    createProductSiteSetting(organizationRef, siteSetting) {
        return __awaiter(this, void 0, void 0, function* () {
            // check if a site setting exists for this product first
            const { response: existingProductSiteSetting, error: getError } = yield safeAsync(() => this.ctx
                .organizationProductSiteSettingsRef(organizationRef)
                .where('product', '==', siteSetting.product)
                .get())();
            if (getError) {
                return wrapErrorAsColumnError(getError, InternalServerError);
            }
            if (existingProductSiteSetting.docs.length > 0) {
                return wrapErrorAsColumnError(new Error('Product site setting already exists'), BadRequestError);
            }
            const { response: newProductSiteSetting, error: addError } = yield safeAsync(() => this.ctx
                .organizationProductSiteSettingsRef(organizationRef)
                .add(siteSetting))();
            if (addError) {
                return wrapErrorAsColumnError(addError, InternalServerError);
            }
            return safeGetModelFromRef(ProductSiteSettingModel, this.ctx, newProductSiteSetting);
        });
    }
    fetchProductSiteSettingArray(organizationRef, product) {
        return __awaiter(this, void 0, void 0, function* () {
            let productPublishingSettingsQuery = this.ctx.organizationProductSiteSettingsRef(organizationRef);
            if (product) {
                productPublishingSettingsQuery = productPublishingSettingsQuery.where('product', '==', product);
            }
            return safeGetModelArrayFromQuery(ProductSiteSettingModel, this.ctx, productPublishingSettingsQuery);
        });
    }
    maybeFetchProductSiteSetting(ref, product) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: productSiteSettings, error: fetchError } = yield this.fetchProductSiteSettingArray(ref, product);
            if (fetchError) {
                return wrapError(fetchError);
            }
            if (productSiteSettings.length > 1) {
                const error = new InternalServerError('Multiple product site settings found for a single product');
                getErrorReporter().logAndCaptureCriticalError(ColumnService.DISPLAY_SITES, error, 'fetchProductSiteSettings failed due to too many results. Returning error...', {
                    publisherId: ref.id,
                    product,
                    service: ColumnService.DISPLAY_SITES
                });
                return wrapError(error);
            }
            if (productSiteSettings.length === 0) {
                getErrorReporter().logInfo('No product site settings found', {
                    publisherId: ref.id,
                    product,
                    service: ColumnService.DISPLAY_SITES
                });
                return wrapSuccess(null);
            }
            return wrapSuccess(productSiteSettings[0]);
        });
    }
}
