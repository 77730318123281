var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import PlacementActions, { selectConfirmedHtml, selectCurrentStepId, selectNewspaper, selectRate } from 'redux/placement';
import { exists } from 'lib/types';
import { ESelect } from 'components/ColorColumnFormComponents';
import { getFirebaseContext } from 'utils/firebase';
import { shouldRemoveImagesFromLiners } from 'lib/utils/images';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { NoticeRateService } from 'lib/services/noticeRateService';
import { CONFIRM_AD } from './helpers/calculatePlacementSteps';
function RateField() {
    const dispatch = useAppDispatch();
    const rate = useAppSelector(selectRate);
    const [pendingRate, setPendingRate] = useState();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [localRateSnap, setLocalRateSnap] = useState();
    const setRate = (rate) => {
        dispatch(PlacementActions.setRate((rate === null || rate === void 0 ? void 0 : rate.ref) || null));
        dispatch(PlacementActions.setDefaultRateOverride(true));
    };
    useEffect(() => {
        if (!rate)
            return;
        const unsubscribe = rate.onSnapshot(snapshot => {
            if (!exists(snapshot))
                return;
            setLocalRateSnap(snapshot);
        });
        return () => unsubscribe();
    }, [rate === null || rate === void 0 ? void 0 : rate.id]);
    return (_jsxs(_Fragment, { children: [pendingRate && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => { }, header: "Incompatible Rate", tertiaryButtonText: 'Cancel', primaryButtonText: 'Continue', overrideTertiaryClose: () => {
                    setPendingRate(undefined);
                }, onSubmit: () => {
                    setRate(pendingRate);
                    setPendingRate(undefined);
                }, overrideEntablaturePadding: "px-6 py-4" }, { children: _jsxs("div", Object.assign({ className: "my-4" }, { children: ["This notice contains an image, but the rate you have selected cannot price notices with images. Click ", _jsx("b", { children: "Continue" }), " if you'd like to proceed with the selected rate and remove images from this notice. Otherwise, click ", _jsx("b", { children: "Cancel" }), "."] })) }))), _jsx("div", { children: _jsx(RateDropdown, { rate: localRateSnap, setRate: setRate, setPendingRate: setPendingRate }) })] }));
}
export default RateField;
export function RateDropdown({ rate, setRate, setPendingRate }) {
    var _a;
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const ctx = getFirebaseContext();
    const rateService = new NoticeRateService(ctx);
    const placementConfirmedHtml = (_a = useAppSelector(selectConfirmedHtml)) !== null && _a !== void 0 ? _a : '';
    const currentStepId = useAppSelector(selectCurrentStepId);
    const disabled = currentStepId !== CONFIRM_AD;
    const newspaper = useAppSelector(selectNewspaper);
    const [availableRates, setAvailableRates] = useState([]);
    useEffect(() => {
        if (!newspaper)
            return;
        void (() => __awaiter(this, void 0, void 0, function* () {
            const rates = yield rateService.getPublisherOrgRates(newspaper);
            const activeRates = rates.filter(r => !r.data().archived);
            setAvailableRates(activeRates);
        }))();
    }, [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]);
    if (enableNewPlacementFlow) {
        return (_jsx(ColumnSelect, { id: "rate-dropdown", disabled: disabled, options: availableRates.map(rate => ({
                label: rate.data().description,
                value: rate.id
            })), labelText: "What's the rate for this notice?", value: exists(rate) ? rate.id : '', onChange: newRate => {
                const chosenRate = availableRates.find(rate => rate.id === newRate);
                if (!chosenRate) {
                    return;
                }
                if (shouldRemoveImagesFromLiners(chosenRate, placementConfirmedHtml)) {
                    // Show the user a dialog that their rate is bad, and then wait for the warning
                    // to be cleared (from the dialog) before changing the rate.
                    setPendingRate(chosenRate);
                    return;
                }
                setRate(chosenRate);
            } }));
    }
    return (_jsx(ESelect, { disabled: disabled, values: availableRates.map(rate => ({
            label: rate.data().description,
            val: rate.id
        })), label: "Select Rate", value: exists(rate) ? rate.id : '', onChange: e => {
            const chosenRate = availableRates.find(rate => rate.id === e.target.value);
            if (!chosenRate) {
                return;
            }
            if (shouldRemoveImagesFromLiners(chosenRate, placementConfirmedHtml)) {
                // Show the user a dialog that their rate is bad, and then wait for the warning
                // to be cleared (from the dialog) before changing the rate.
                setPendingRate(chosenRate);
                return;
            }
            setRate(chosenRate);
        } }));
}
