import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Product } from 'lib/enums';
import Header from 'components/Header';
import { selectOrderIdClaims } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { AdFormStatusProvider } from './contexts/AdFormStatusProvider';
import AdPlacementFlow from './AdPlacementFlow';
export default function PlaceAdContainer({ match, location }) {
    const orderIdClaims = useAppSelector(selectOrderIdClaims);
    const [claim] = orderIdClaims;
    const segments = location.pathname.split('/');
    const product = segments[1] === 'obituaries' ? Product.Obituary : Product.Classified;
    return (_jsxs(AdFormStatusProvider, { children: [_jsx(Header, { productLine: product }), _jsx(AdPlacementFlow, { claim: claim, orderDraftId: match.params.orderDraftId, product: product })] }));
}
