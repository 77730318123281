import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import TabGroup from 'lib/components/Tabs';
import TemplateDesignTab from './TemplateDesignTab';
import TemplateSettingsTab from './TemplateSettingsTab';
import TemplatePreviewTab from './TemplatePreviewTab';
const PREVIEW_TAB = {
    label: 'Preview',
    enabled: true,
    id: 'preview-tab'
};
const DESIGN_TAB = {
    label: 'Design',
    enabled: true,
    id: 'design-tab'
};
const SETTINGS_TAB = {
    label: 'Settings',
    enabled: true,
    id: 'settings-tab'
};
const TEMPLATE_DRAWER_TABS = [PREVIEW_TAB, DESIGN_TAB, SETTINGS_TAB];
export default function TemplateDrawerBody({ template, onEdit }) {
    const [activeTab, setActiveTab] = useState(TEMPLATE_DRAWER_TABS[0]);
    if (!template) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: "relative flex flex-col h-full" }, { children: [_jsx(TabGroup, { onClickTab: setActiveTab, activeTab: activeTab, tabs: TEMPLATE_DRAWER_TABS, id: "template-drawer-tabs" }), _jsxs("div", Object.assign({ className: "overflow-auto px-8 pt-4 pb-8 flex-grow" }, { children: [activeTab.label === PREVIEW_TAB.label && (_jsx(TemplatePreviewTab, { template: template })), activeTab.label === DESIGN_TAB.label && (_jsx(TemplateDesignTab, { template: template })), activeTab.label === SETTINGS_TAB.label && (_jsx(TemplateSettingsTab, { template: template }))] })), _jsx("div", { className: "my-10" }), _jsxs("div", Object.assign({ className: "sticky bottom-0 flex w-full py-4 px-8 border-t bg-white" }, { children: [_jsx("div", { className: "flex-1" }), _jsx(ColumnButton, { id: "edit-template", size: "lg", buttonText: "Edit", onClick: onEdit, type: "button" })] }))] })));
}
