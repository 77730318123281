import { getFirebaseContext } from 'utils/firebase';
import { unCdnify } from 'lib/helpers';
import { DEFAULT_AFFIDAVIT_URL } from 'lib/affidavits';
// Default the fee for automated affidavits to $5.00
const DEFAULT_AUTOMATED_AFFIDAVIT_FEE = 500;
const DEFAULT_AFFIDAVIT_RECONCILIATION_SETTINGS = {
    notarizationRequired: true,
    affidavitsManagedByColumn: false,
    uploadMethod: 'not-applicable',
    notarizationVendor: 'notarize',
    managedAffidavitTemplateStoragePath: unCdnify(DEFAULT_AFFIDAVIT_URL),
    reconciliationStartDate: getFirebaseContext().timestamp(),
    automatedAffidavitFeeInCents: DEFAULT_AUTOMATED_AFFIDAVIT_FEE
};
export const extractGlobalAffidavitControls = (activeOrganization) => {
    const { alwaysAllowAffidavitDownload, affidavitReconciliationSettings, customAffidavitMaxImageWidth, publicationFrequency, physicalAffidavit, signatoryRole, signatoryName } = activeOrganization.data();
    return {
        affidavitReconciliationSettings: affidavitReconciliationSettings ||
            DEFAULT_AFFIDAVIT_RECONCILIATION_SETTINGS,
        alwaysAllowAffidavitDownload: !!alwaysAllowAffidavitDownload,
        customAffidavitMaxImageWidth,
        /**
         * Default to daily if not set on the publisher's organization
         */
        publicationFrequency: publicationFrequency || 'Daily',
        physicalAffidavit: !!physicalAffidavit,
        signatoryRole,
        signatoryName
    };
};
