var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { logAndCaptureException } from 'utils';
import lobApi from 'lob';
import { ColumnService } from 'lib/services/directory';
import { LOB_CONFIG, ENV } from '../../../constants';
const LOB_VALIDATION_RESPONSES = {
    Deliverable: 'deliverable',
    DeliverableUnnecessaryUnit: 'deliverable_unnecessary_unit',
    DeliverableIncorrectUnit: 'deliverable_incorrect_unit',
    DeliverableMissingUnit: 'deliverable_missing_unit',
    Undeliverable: 'undeliverable'
};
export const verifyAddressDeliverability = ({ mailingAddress, draft }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const originalId = (_a = draft === null || draft === void 0 ? void 0 : draft.data().original) === null || _a === void 0 ? void 0 : _a.id;
    if (mailingAddress.isNoticeTypeDefault) {
        return true;
    }
    try {
        const verification = yield lobApi(LOB_CONFIG[ENV].key).usVerifications.verify({
            primary_line: mailingAddress.address.address_line1,
            secondary_line: mailingAddress.address.address_line2,
            city: mailingAddress.address.address_city,
            state: mailingAddress.address.address_state,
            zip_code: mailingAddress.address.address_zip
        });
        const { deliverability } = verification;
        return deliverability !== LOB_VALIDATION_RESPONSES.Undeliverable;
    }
    catch (err) {
        logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Failed to verify address', {
            noticeId: originalId
        });
    }
});
