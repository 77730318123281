import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ColumnDatePicker from 'components/ColumnDatePicker';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Label } from 'lib/components/Label';
import moment from 'moment';
import React from 'react';
import { ALL_INVOICE_CREATION_DATES, CUSTOM_INVOICE_CREATION_DATES, INVOICE_CREATION_DATE_OPTIONS, INVOICE_STATUSE_OPTIONS, LAST_MONTH_INVOICE_CREATION_DATES, THIS_MONTH_INVOICE_CREATION_DATES, ALL_INVOICES_INVOICE_TYPE, BULK_INVOICES_INVOICE_TYPE, SINGLE_INVOICES_INVOICE_TYPE } from './invoiceTableSettingsUtils';
export default function InvoicesTableFilterDialog({ setUpdatedInvoiceFilter, updatedInvoiceTableFilter, enableInvoiceTypeFiltering }) {
    const isCustomDateRange = ![
        ALL_INVOICE_CREATION_DATES,
        THIS_MONTH_INVOICE_CREATION_DATES,
        LAST_MONTH_INVOICE_CREATION_DATES,
        {}
    ].includes(updatedInvoiceTableFilter.dateRange);
    // parse the date range into a more easily typed format
    const currentDateRange = isCustomDateRange
        ? updatedInvoiceTableFilter.dateRange
        : null;
    return (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "uppercase text-column-gray-300 text-xs font-bold" }, { children: "Filter by" })), enableInvoiceTypeFiltering && (_jsx(ColumnSelect, { id: "invoice-type", labelText: "Invoice Type", size: "small", options: [
                    { label: 'All', value: ALL_INVOICES_INVOICE_TYPE },
                    { label: 'Bulk Invoices', value: BULK_INVOICES_INVOICE_TYPE },
                    { label: 'Single Invoices', value: SINGLE_INVOICES_INVOICE_TYPE }
                ], value: updatedInvoiceTableFilter.bulkInvoiceFilter, onChange: newValue => setUpdatedInvoiceFilter(Object.assign(Object.assign({}, updatedInvoiceTableFilter), { bulkInvoiceFilter: newValue })) })), _jsx(ColumnSelect, { id: "status", labelText: "Status", size: "small", options: INVOICE_STATUSE_OPTIONS, value: updatedInvoiceTableFilter.invoiceTableStatus, onChange: newValue => setUpdatedInvoiceFilter(Object.assign(Object.assign({}, updatedInvoiceTableFilter), { invoiceTableStatus: newValue })) }), _jsx(ColumnSelect, { id: "date-created", labelText: "Date Created", size: "small", options: INVOICE_CREATION_DATE_OPTIONS, value: isCustomDateRange
                    ? CUSTOM_INVOICE_CREATION_DATES
                    : updatedInvoiceTableFilter.dateRange.toString(), onChange: newValue => {
                    if (newValue === CUSTOM_INVOICE_CREATION_DATES) {
                        setUpdatedInvoiceFilter(Object.assign(Object.assign({}, updatedInvoiceTableFilter), { dateRange: {
                                start: moment().subtract(1, 'month').toDate(),
                                end: moment().toDate()
                            } }));
                    }
                    else {
                        setUpdatedInvoiceFilter(Object.assign(Object.assign({}, updatedInvoiceTableFilter), { dateRange: newValue }));
                    }
                } }), currentDateRange && typeof currentDateRange !== 'string' && (_jsxs("div", { children: [_jsx(Label, Object.assign({ id: "date-filter-label" }, { children: "Choose a date range" })), _jsxs("div", Object.assign({ className: "flex flex-row gap-2" }, { children: [_jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "Start date", value: currentDateRange === null || currentDateRange === void 0 ? void 0 : currentDateRange.start, onChange: newDate => newDate &&
                                    setUpdatedInvoiceFilter(Object.assign(Object.assign({}, updatedInvoiceTableFilter), { dateRange: Object.assign(Object.assign({}, currentDateRange), { start: newDate }) })), shouldDisableDate: date => !date || moment(date).isAfter(currentDateRange.end) }), _jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "End date", value: currentDateRange.end, onChange: newDate => newDate &&
                                    setUpdatedInvoiceFilter(Object.assign(Object.assign({}, updatedInvoiceTableFilter), { dateRange: Object.assign(Object.assign({}, currentDateRange), { end: newDate }) })), shouldDisableDate: date => !date || moment().isBefore(date) })] }))] }))] }));
}
