import { InvoiceStatus } from '../enums';
import { cdnIfy } from '../helpers';
import { exists } from '../types';
export const getReceiptUrlFromInvoice = (invoice) => {
    var _a;
    const receipt_pdf = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.receipt_pdf;
    if (!exists(invoice) || !receipt_pdf) {
        return undefined;
    }
    if (invoice.data().status === InvoiceStatus.refunded.value &&
        receipt_pdf.startsWith('https://')) {
        return receipt_pdf;
    }
    return cdnIfy(receipt_pdf);
};
