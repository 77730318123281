var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { FormControlLabel, IconButton } from '@material-ui/core';
import FreeformCModal from 'components/modals/FreeFormCModal';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import { State as States } from 'lib/enums';
import lobApi from 'lob';
import api from 'api';
import * as EmailValidator from 'email-validator';
import { exists } from 'lib/types';
import { getRecipientName } from 'lib/utils/invoices';
import { AddIcon, DeleteIcon } from 'icons';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { LOB_CONFIG, ENV, PROD } from '../../constants';
export default function SendReminderModal({ filer, notice, invoiceId, newspaper, onCloseModal }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    const DEFAULT_MAIL = {
        address: {
            address_line1: '',
            address_line2: '',
            address_city: '',
            address_state: '',
            address_zip: ''
        },
        description: '',
        name: ''
    };
    const invoiceRecipient = (_a = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _a === void 0 ? void 0 : _a.invoiceRecipient;
    const defaultEmail = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'email'
        ? invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.email
        : ((_b = filer === null || filer === void 0 ? void 0 : filer.data()) === null || _b === void 0 ? void 0 : _b.email) || '';
    const defaultMailChecked = (invoiceRecipient === null || invoiceRecipient === void 0 ? void 0 : invoiceRecipient.type) === 'mail';
    const defaultEmailChecked = !defaultMailChecked;
    const zipRegex = /^(([0-9]{5})|([0-9]{5}-[0-9]{4}))$/g;
    const DEFAULT_ERROR = Array(5).fill('');
    const MAX_ADDRESSEES = 5;
    const [emailChecked, setEmailChecked] = useState(defaultEmailChecked);
    const [mailChecked, setMailChecked] = useState(defaultMailChecked);
    const [emails, setEmails] = useState([defaultEmail]);
    const [mails, setMails] = useState([DEFAULT_MAIL]);
    const [emailError, setEmailError] = useState(DEFAULT_ERROR);
    const [mailError, setMailError] = useState(DEFAULT_ERROR);
    const [showErrors, setShowErrors] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const prefillNoticeAddress = () => __awaiter(this, void 0, void 0, function* () {
        var _c, _d, _e;
        if (invoiceRecipient && invoiceRecipient.type === 'mail') {
            const address_state = ((_c = States.by_label(`${invoiceRecipient.address.address_state}`)) === null || _c === void 0 ? void 0 : _c.value) ||
                '';
            setMails([
                {
                    name: getRecipientName(invoiceRecipient),
                    address: Object.assign(Object.assign({}, invoiceRecipient.address), { address_state }),
                    description: 'Prefilled from invoice recipient'
                }
            ]);
            return;
        }
        const organizationRef = (_d = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _d === void 0 ? void 0 : _d.filedBy;
        if (organizationRef) {
            try {
                const organization = yield organizationRef.get();
                const { address, addressLine2, city, state, zipCode } = organization.data() || {};
                setMails([
                    {
                        address: {
                            address_line1: address || DEFAULT_MAIL.address.address_line1,
                            address_line2: addressLine2 || DEFAULT_MAIL.address.address_line2,
                            address_city: city || DEFAULT_MAIL.address.address_city,
                            address_state: state || DEFAULT_MAIL.address.address_state,
                            address_zip: zipCode || DEFAULT_MAIL.address.address_zip
                        },
                        name: ((_e = filer === null || filer === void 0 ? void 0 : filer.data()) === null || _e === void 0 ? void 0 : _e.name) || DEFAULT_MAIL.name,
                        description: 'Prefilled from organization info'
                    }
                ]);
            }
            catch (err) {
                logAndCaptureException(ColumnService.PAYMENTS, err, 'failed to load organization', {
                    orgId: organizationRef.id
                });
                setMails([DEFAULT_MAIL]);
            }
        }
        else if (filer && exists(filer)) {
            const { address, addressLine2, city, state, zipCode, name } = filer.data();
            setMails([
                {
                    address: {
                        address_line1: address || DEFAULT_MAIL.address.address_line1,
                        address_line2: addressLine2 || DEFAULT_MAIL.address.address_line2,
                        address_city: city || DEFAULT_MAIL.address.address_city,
                        address_state: state || DEFAULT_MAIL.address.address_state,
                        address_zip: zipCode || DEFAULT_MAIL.address.address_zip
                    },
                    name: name || DEFAULT_MAIL.name,
                    description: 'Prefilled from user info'
                }
            ]);
        }
    });
    useEffect(() => {
        if (mailChecked && filer) {
            void prefillNoticeAddress();
        }
    }, [mailChecked]);
    useEffect(() => {
        setIsMobile(window.matchMedia('(max-width: 500px)').matches);
    }, []);
    const addEmails = () => {
        if (emails && emails.length >= MAX_ADDRESSEES)
            return;
        setEmails(emails.concat(''));
    };
    const addMails = () => {
        if (mails && mails.length >= MAX_ADDRESSEES)
            return;
        setMails(mails.concat(DEFAULT_MAIL));
    };
    const renderEmails = () => (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "ml-8" }, { children: emails.map((e, i) => {
                    return (_jsx("div", Object.assign({ className: "mb-1" }, { children: _jsxs("div", { children: [_jsx("div", Object.assign({ className: "w-11/12 inline-block" }, { children: _jsx("input", { id: `email-${i}`, placeholder: "Email", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                            setEmails(emails.map((e2, j) => (i === j ? e.target.value : e2)));
                                        }, value: e }) })), i > 0 && (_jsx("div", Object.assign({ className: "w-1/12 inline-block" }, { children: _jsx(IconButton, Object.assign({ id: `delete-email-{i}`, onClick: () => {
                                            const newEmails = emails
                                                .slice(0, i)
                                                .concat(emails.slice(i + 1, emails.length));
                                            setEmails(newEmails);
                                        } }, { children: _jsx(DeleteIcon, { className: "w-5 h-5 text-grey-700" }) })) }))), showErrors && emailError[i] && (_jsx("div", { children: _jsx("div", Object.assign({ className: "justify-start items-start text-red-600 text-sm font-normal pt-2" }, { children: emailError[i] })) }))] }, `${i}-email-item`) }), `${i}-email-container`));
                }) })), _jsxs("button", Object.assign({ type: "button", id: "addEmails", onClick: addEmails, className: "ml-4 inline-flex items-center px-3 py-2 text-sm leading-4 font-medium text-sm text-gray-700 rounded-full bg-white hover:bg-gray-100" }, { children: [_jsx(AddIcon, { className: "w-5 h-5 mr-1" }), "Add Email"] }))] }));
    const renderMails = () => (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "ml-8" }, { children: mails.map((m, i) => {
                    var _a;
                    return (_jsxs("div", Object.assign({ className: "mb-2" }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: "w-11/12 inline-block" }, { children: _jsx("input", { id: `name-${i}`, placeholder: "Name", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                                setMails(mails.map((m2, j) => i === j ? Object.assign(Object.assign({}, m2), { name: e.target.value }) : m2));
                                            }, value: m.name }) })), i > 0 && (_jsx("div", Object.assign({ className: "w-1/12 inline-block" }, { children: _jsx(IconButton, Object.assign({ id: `delete-mail-${i}`, onClick: () => {
                                                const newMails = mails
                                                    .slice(0, i)
                                                    .concat(mails.slice(i + 1, mails.length));
                                                setMails(newMails);
                                            } }, { children: _jsx(DeleteIcon, { className: "w-5 h-5 text-grey-700" }) })) })))] }, `${i}-mail-row-1`), _jsxs("div", Object.assign({ className: "w-11/12 flex justify-between" }, { children: [_jsx("div", Object.assign({ className: "w-8/12 inline-block" }, { children: _jsx("input", { id: `addressLine1${i}`, className: "mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", placeholder: "Address", value: m.address.address_line1, onChange: (e) => setMails(mails.map((m2, j) => i === j
                                                ? Object.assign(Object.assign({}, m2), { address: Object.assign(Object.assign({}, m2.address), { address_line1: e.target.value }) }) : m2)) }) })), _jsx("div", Object.assign({ className: "ml-1 w-4/12 inline-block" }, { children: _jsx("input", { id: `addressLine2${i}`, value: m.address.address_line2, placeholder: "Apt.", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                                setMails(mails.map((m2, j) => i === j
                                                    ? Object.assign(Object.assign({}, m2), { address: Object.assign(Object.assign({}, m2.address), { address_line2: e.target.value }) }) : m2));
                                            } }) }))] }), `${i}-mail-row-2`), _jsxs("div", Object.assign({ className: " w-11/12 flex justify-between" }, { children: [_jsx("div", Object.assign({ className: "w-5/12" }, { children: _jsx("input", { id: `city${i}`, value: m.address.address_city, placeholder: "City", className: "mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                                setMails(mails.map((m2, j) => i === j
                                                    ? Object.assign(Object.assign({}, m2), { address: Object.assign(Object.assign({}, m2.address), { address_city: e.target.value }) }) : m2));
                                            } }) })), _jsx("div", Object.assign({ className: "w-4/12 mt-1 mx-1" }, { children: _jsx(SelectDropdown, { id: `state${i}`, className: "state-select h-full leading-7 md:leading-6", placeholder: "State", onChange: (selected) => {
                                                if (selected) {
                                                    const state = selected.id;
                                                    setMails(mails.map((m2, j) => i === j
                                                        ? Object.assign(Object.assign({}, m2), { address: Object.assign(Object.assign({}, m2.address), { address_state: state }) }) : m2));
                                                }
                                            }, selected: m.address.address_state
                                                ? {
                                                    id: `${m.address.address_state}`,
                                                    label: (_a = States.by_value(m.address.address_state)) === null || _a === void 0 ? void 0 : _a.label
                                                }
                                                : null, value: m.address.address_state
                                                ? States.by_value(m.address.address_state)
                                                : '', options: States.items().map((state) => ({
                                                id: state.value,
                                                label: state.label
                                            })), borderColor: '#e2e8f0', placeholderText: '#a0aec0' }) })), _jsx("div", Object.assign({ className: "w-3/12" }, { children: _jsx("input", { id: `zipcode${i}`, value: m.address.address_zip, placeholder: isMobile ? 'Zip' : 'Zipcode', className: "mt-1 form-input block w-full \u2248border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5", onChange: (e) => {
                                                const regex = /^(([0-9]{0,5})|([0-9]{5}-[0-9]{0,4}))$/g;
                                                if (e.target.value.match(regex))
                                                    setMails(mails.map((m2, j) => i === j
                                                        ? Object.assign(Object.assign({}, m2), { address: Object.assign(Object.assign({}, m2.address), { address_zip: e.target.value }) }) : m2));
                                            } }) }))] }), `${i}-mail-row-3`), showErrors && mailError[i] && (_jsx("div", { children: _jsx("div", Object.assign({ className: "justify-start items-start text-red-600 text-sm font-normal pt-2" }, { children: mailError[i] })) }))] }), `${i}-mail-container`));
                }) })), _jsxs("button", Object.assign({ type: "button", id: "addMails", onClick: addMails, className: "ml-4 inline-flex items-center px-3 py-2 text-sm leading-4 font-medium text-sm text-gray-700 rounded-full bg-white hover:bg-gray-100" }, { children: [_jsx(AddIcon, { className: "w-5 h-5 mr-1" }), "Add Mailing Address"] }))] }));
    const validateEmail = () => {
        const newError = [...DEFAULT_ERROR];
        if (emailChecked) {
            for (const [i, e] of emails.entries()) {
                const previous = emails.slice(0, i);
                const validEmail = EmailValidator.validate(e);
                if (!e)
                    newError[i] = 'Email is missing';
                else if (!validEmail)
                    newError[i] = 'Invalid email';
                else if (previous.indexOf(e) > -1)
                    newError[i] = 'Duplicate email';
            }
        }
        setEmailError([...newError]);
    };
    const validateMail = () => {
        const newError = [...DEFAULT_ERROR];
        if (mailChecked) {
            for (const [i, m] of mails.entries()) {
                if (!m.name)
                    newError[i] = 'Recipient name is missing';
                else if (m.name.length > 40)
                    newError[i] = 'Recipient name must be less than 40 characters';
                else if (!m.address.address_line1)
                    newError[i] = 'Address line 1 is missing';
                else if (!m.address.address_city)
                    newError[i] = 'City is missing';
                else if (!m.address.address_state)
                    newError[i] = 'State is missing';
                else if (!m.address.address_zip)
                    newError[i] = 'Zip code is missing';
                else if (!m.address.address_zip.match(zipRegex))
                    newError[i] = 'Zip code is invalid';
                else
                    newError[i] = '';
            }
        }
        setMailError([...newError]);
    };
    useEffect(() => {
        validateMail();
    }, [mails]);
    useEffect(() => {
        validateEmail();
    }, [emails]);
    useEffect(() => {
        if (mailChecked) {
            validateMail();
        }
    }, [mailChecked]);
    useEffect(() => {
        if (emailChecked) {
            validateEmail();
        }
    }, [emailChecked]);
    const verifyAddresses = (m) => __awaiter(this, void 0, void 0, function* () {
        yield new Promise((resolve, reject) => {
            lobApi(LOB_CONFIG[ENV].key).usVerifications.verify({
                primary_line: ENV === PROD
                    ? m.address.address_line1
                    : LOB_CONFIG[ENV].address.primary_line,
                secondary_line: m.address.address_line2,
                city: m.address.address_city,
                state: m.address.address_state,
                zip_code: m.address.address_zip
            }, (err, res) => {
                var _a;
                if (err) {
                    logAndCaptureException(ColumnService.PAYMENTS, err, 'failed to verify address', {
                        address: m.address
                    });
                    reject();
                }
                if (res && res.deliverability === 'undeliverable') {
                    void api.post('notifications/slack', {
                        message: `Wrong address was submitted for mailed invoice reminder to: ${m.name}. 
                address line 1: ${m.address.address_line1}, 
                address line 2: ${m.address.address_line2}, 
                city: ${m.address.address_city}, 
                state: ${(_a = States.by_value(m.address.address_state)) === null || _a === void 0 ? void 0 : _a.label}, 
                zip code: ${m.address.address_zip}`
                    });
                }
                resolve();
            });
        });
    });
    const onExit = () => __awaiter(this, void 0, void 0, function* () {
        if (emailChecked && !emailError.every(e => !e)) {
            setShowErrors(true);
            return false;
        }
        if (mailChecked && !mailError.every(e => !e)) {
            setShowErrors(true);
            return false;
        }
        if (mailChecked)
            yield Promise.all([mails.map(verifyAddresses)]);
        return true;
    });
    const isComplete = () => {
        if (!emailChecked && !mailChecked)
            return false;
        if (showErrors) {
            if (emailChecked) {
                return emailError.every(e => !e);
            }
            if (mailChecked) {
                return mailError.every(e => !e);
            }
        }
        return true;
    };
    const sendReminders = () => __awaiter(this, void 0, void 0, function* () {
        if (!invoiceId)
            return;
        if (emailChecked) {
            yield Promise.all([
                emails.map(email => {
                    var _a;
                    return api.post('notifications/invoice-reminder', {
                        noticeId: notice === null || notice === void 0 ? void 0 : notice.id,
                        invoiceId,
                        newspaperName: (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.name,
                        email
                    });
                })
            ]);
        }
        if (mailChecked) {
            yield Promise.all([
                mails.map(mailSnap => api.post('notifications/mail-invoice-reminder', {
                    invoiceId,
                    mailSnap
                }))
            ]);
        }
    });
    const isPlural = () => {
        let recipients = 0;
        if (mailChecked)
            recipients += mails.length;
        if (emailChecked)
            recipients += emails.length;
        return recipients > 1;
    };
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        if (isComplete() && (yield onExit())) {
            yield sendReminders();
            onCloseModal();
            dispatch(ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: `Success! Your invoice reminder${isPlural() ? 's have' : ' has'} been sent.`
            }));
        }
    });
    return (_jsx(FreeformCModal, Object.assign({ header: 'How would you like to send this reminder?', body: 'A copy of the invoice will be attached.', id: 'send-reminder-modal', setOpen: () => onCloseModal(), noExitOutsideModal: true }, { children: _jsxs("section", Object.assign({ className: "mt-3" }, { children: [_jsx("div", { children: _jsx(FormControlLabel, { control: _jsx("input", { checked: emailChecked, onChange: () => setEmailChecked(!emailChecked), value: "checkedA", id: "sendEmailCheckBox", type: "checkbox", className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3" }), label: 'Send reminder by email' }) }), emailChecked && renderEmails(), _jsx("div", Object.assign({ className: "mt-2" }, { children: _jsx(FormControlLabel, { control: _jsx("input", { checked: mailChecked, onChange: () => setMailChecked(!mailChecked), value: "checkedA", id: "sendMailCheckBox", type: "checkbox", className: "form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3" }), label: 'Send reminder by mail' }) })), mailChecked && renderMails(), _jsx("div", Object.assign({ className: "flex justify-center md:justify-start" }, { children: _jsx("button", Object.assign({ id: `confirm-send-reminder`, onClick: () => handleSubmit(), className: `bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 transition w-full md:w-auto mt-4 ${!emailChecked && !mailChecked
                            ? 'cursor-not-allowed pointer-events-none bg-opacity-50 opacity-50'
                            : 'hover:bg-blue-600 hover:text-white'}` }, { children: "Send Reminder" })) })), _jsx("style", { children: `
            .MuiFormControlLabel-label {
              color: #6B7280 !important;
            }
            #send-reminder-modal-inner {
              max-height: 500px;
              overflow: scroll;
            }
          ` })] })) })));
}
