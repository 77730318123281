import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { exists } from 'lib/types';
import { selectActiveOrganization } from 'redux/auth';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useAppSelector } from 'redux/hooks';
import DrawerPropertyRow from '../../DrawerPropertyRow';
import FormSwitch from '../../FormSwitch';
const affidavitsAreEnabled = (activeOrganization, noticeType) => {
    var _a, _b;
    if (!((_a = activeOrganization.data().affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.affidavitsManagedByColumn))
        return false;
    if (((_b = noticeType.affidavitReconciliationSettings) === null || _b === void 0 ? void 0 : _b.affidavitsManagedByColumn) ===
        false)
        return false;
    return true;
};
export default function AffidavitTab({ noticeType }) {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const rate = useFirestoreSnapshot(noticeType.rate);
    if ((noticeType.rate && !rate) || !exists(activeOrganization))
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: 'Require affidavits', value: _jsx(FormSwitch, { disabled: true, checked: !noticeType.affidavitDisabled }) }), _jsx(DrawerPropertyRow, { label: 'Column manages affidavits', value: _jsx(FormSwitch, { disabled: true, checked: affidavitsAreEnabled(activeOrganization, noticeType) }) }), _jsx(DrawerPropertyRow, { label: 'Affidavit mailing', value: _jsx(FormSwitch, { disabled: true, checked: !!((_a = noticeType.defaultMailingAddresses) === null || _a === void 0 ? void 0 : _a.length) }) }), _jsx(DrawerPropertyRow, { label: 'Custom affidavit', value: _jsx(FormSwitch, { disabled: true, checked: !!noticeType.defaultAffidavitTemplate }) })] }));
}
