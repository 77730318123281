var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { InputAccessories } from 'lib/components/InputAccessories';
import { unCdnify, cdnIfy } from 'lib/helpers';
import React from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import Firebase from 'EnoticeFirebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
export function BulkTemplateSettings({ value, initialValue, onChange }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    if (!activeOrganization) {
        return null;
    }
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Bulk Template Settings',
            description: 'Enable a full page layout template. Download all notices for each publication date, automatically formatted into one IDML file.'
        } }, { children: [_jsx(SwitchControlledCard, Object.assign({ labelProps: {
                    label: 'Enable IDML bulk downloads?',
                    description: 'If enabled, your downloads folder will include a single IDML file containing all notices set to publish on that day.',
                    value: value.oneColumnEnabled,
                    onChange: oneColumnEnabled => onChange(Object.assign(Object.assign({}, value), { oneColumnEnabled, fullPageTemplate: oneColumnEnabled
                            ? initialValue.fullPageTemplate
                            : undefined }))
                }, header: "bulk download template" }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsxs(InputAccessories, Object.assign({ id: "bulk-template-upload", labelText: "Column automatically flows IDML files into the first text frame of the first page of the template.", disabled: true }, { children: [value.fullPageTemplate && (_jsx(FileUploadListItem, { firebaseStoragePath: unCdnify(value.fullPageTemplate), storage: Firebase.storage() })), _jsx(FileUpload, { id: "bulk-template-upload", multiple: false, acceptFileTypes: ".idml", uploadFolder: `templates/${activeOrganization.id}/bulkTemplates`, onFileUpload: ([{ uploadRef, file }]) => __awaiter(this, void 0, void 0, function* () {
                                    if (!file) {
                                        return;
                                    }
                                    onChange(Object.assign(Object.assign({}, value), { fullPageTemplate: cdnIfy(uploadRef.fullPath, { useColumnCDN }) }));
                                }), storage: Firebase.storage() })] })) })) })), _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                    label: 'Enable IDML bulk downloads for two-column notices?',
                    description: 'If enabled, your downloads folder will include a single IDML file containing all two-column notices set to publish on that day.',
                    value: value.twoColumnEnabled,
                    onChange: twoColumnEnabled => onChange(Object.assign(Object.assign({}, value), { twoColumnEnabled, twoColumnFullPageTemplate: twoColumnEnabled
                            ? initialValue.twoColumnFullPageTemplate
                            : undefined }))
                }, header: "two-column bulk download template" }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsxs(InputAccessories, Object.assign({ id: "two-column-bulk-template", labelText: "Column automatically flows IDML files into the first text frame of the first page of the template.", disabled: true }, { children: [value.twoColumnFullPageTemplate && (_jsx(FileUploadListItem, { firebaseStoragePath: unCdnify(value.twoColumnFullPageTemplate), storage: Firebase.storage() })), _jsx(FileUpload, { id: "two-column-template-upload", multiple: false, acceptFileTypes: ".idml", uploadFolder: `templates/${activeOrganization.id}/bulkTemplates`, onFileUpload: ([{ uploadRef, file }]) => __awaiter(this, void 0, void 0, function* () {
                                    if (!file) {
                                        return;
                                    }
                                    onChange(Object.assign(Object.assign({}, value), { twoColumnFullPageTemplate: cdnIfy(uploadRef.fullPath, {
                                            useColumnCDN
                                        }) }));
                                }), storage: Firebase.storage() })] })) })) }))] })));
}
