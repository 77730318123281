var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel } from '../index';
import { Collections } from '../../constants';
import { getErrorReporter } from '../../utils/errors';
import { removeUndefinedFields } from '../../helpers';
import { ColumnService } from '../../services/directory';
import { getOverrideAAC } from '../../types/affidavits/convertARS';
import { OrganizationModel } from './organizationModel';
import { safeGetModelFromRef } from '../getModel';
import { wrapSuccess } from '../../types/responses';
export class CustomerOrganizationModel extends SnapshotModel {
    constructor() {
        super(...arguments);
        this.type = Collections.customerOrganizations;
        // TODO: replace with model once one exists?
        this.publisher = null;
    }
    getPublisher() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.publisher) {
                return wrapSuccess(this.publisher);
            }
            return safeGetModelFromRef(OrganizationModel, this.ctx, this.modelData.organization);
        });
    }
    updateAutomatedAffidavitConfiguration(updates) {
        return __awaiter(this, void 0, void 0, function* () {
            const { affidavitReconciliationSettings } = this.modelData;
            const { response: publisher, error: publisherError } = yield this.getPublisher();
            if (publisherError) {
                getErrorReporter().logAndCaptureError(ColumnService.AFFIDAVITS, publisherError, 'Failed to get publisher for customer organization', { customerOrganizationId: this.id });
                return;
            }
            const newAffidavitReconciliationSettings = Object.assign(Object.assign({}, affidavitReconciliationSettings), updates);
            const { response: automatedAffidavitConfiguration, error: aacError } = yield getOverrideAAC(this.ctx, publisher, newAffidavitReconciliationSettings);
            if (aacError) {
                getErrorReporter().logAndCaptureError(ColumnService.AFFIDAVITS, aacError, 'Failed to get automated affidavit configuration from affidavit reconciliation settings for publisher', { publisherId: this.id });
                return yield this.ref.update({
                    affidavitReconciliationSettings: newAffidavitReconciliationSettings
                });
            }
            yield this.ref.update(removeUndefinedFields({
                affidavitReconciliationSettings: newAffidavitReconciliationSettings,
                automatedAffidavitConfiguration
            }));
        });
    }
}
