var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiPost } from 'api/typed';
import { getFirebaseContext } from 'utils/firebase';
const getOrCreateCustomerWithCustomerOrganization = (body) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiPost('customers/get-or-create-with-customer-organization', body);
    if (response.success) {
        return getFirebaseContext().customersRef().doc(response.customerId);
    }
    throw new Error(response.error);
});
export default getOrCreateCustomerWithCustomerOrganization;
