import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import CheckboxInput from '../Checkbox/CheckboxInput';
/**
 * A simple text-only select item.
 */
export function ColumnSelectItem({ option, active, selected, showCheckBox }) {
    return (_jsxs("div", Object.assign({ id: `${option.value}-option-label`, className: classNames({
            'bg-blue-50 text-blue-500': active || (selected && !showCheckBox)
        }, 'cursor-pointer rounded p-3 hover:bg-blue-50 hover:text-blue-500 focus:outline-none transition ease-in duration-100') }, { children: [_jsxs("span", { children: [!!showCheckBox && (_jsx("span", Object.assign({ className: "mr-2" }, { children: _jsx(CheckboxInput, { id: `checkbox_${option.value}`, checked: selected, labelText: "", onChange: () => { } }) }))), option.label] }), option.description && (_jsxs("span", Object.assign({ className: "opacity-75" }, { children: [" - ", option.description] })))] })));
}
