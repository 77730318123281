var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { syncGetRequiresUpfrontPayment } from 'lib/notice/helpers';
import { getLastInvoiceForCustomer, getLastInvoiceForCustomerOrganization } from 'lib/customers/invoices';
import { getFirebaseContext } from 'utils/firebase';
import { logInfo } from 'utils/logger';
export const getDeadlineBufferMinutesForNoticePlacement = (notice, newspaper, customer, customerOrganization, isPublisher) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const enableDeadlineBuffer = getBooleanFlag(LaunchDarklyFlags.ENABLE_NOTICE_PLACEMENT_DEADLINE_BUFFER);
    const deadlineBufferMinutesForRUPNotice = 4 * 60;
    if (!exists(notice) ||
        !exists(newspaper) ||
        !enableDeadlineBuffer ||
        isPublisher) {
        return 0;
    }
    let invoice = yield getLastInvoiceForCustomerOrganization(getFirebaseContext(), customerOrganization);
    if (!exists(invoice)) {
        invoice = yield getLastInvoiceForCustomer(getFirebaseContext(), customer);
    }
    const lastInvoiceIOC = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.invoiceOutsideColumn;
    if (lastInvoiceIOC) {
        // If the last invoice was outside column, we don't need to apply the deadline buffer
        return 0;
    }
    const requireUpfrontPayment = syncGetRequiresUpfrontPayment(notice.data(), newspaper.data(), customer === null || customer === void 0 ? void 0 : customer.data(), customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data());
    if (!requireUpfrontPayment) {
        return 0;
    }
    logInfo(`Using deadline buffer of ${deadlineBufferMinutesForRUPNotice} minutes for notice placement`, {
        noticeId: notice.id,
        newspaperId: newspaper.id,
        customerId: customer === null || customer === void 0 ? void 0 : customer.id,
        customerOrganizationId: customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.id
    });
    return deadlineBufferMinutesForRUPNotice;
});
export const getDeadlineBufferConfigFromMinutes = (minutes) => {
    return {
        offsetInMinutes: -1 * Math.abs(minutes),
        offsetAsBusinessHours: true
    };
};
