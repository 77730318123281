var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrganizationType } from 'lib/enums';
import { getCustomerName } from 'lib/notice/customer';
import { getOrThrow } from 'lib/utils/refs';
const getFuneralHomeCustomerInformationFromCustomer = (customerRef) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const customerSnap = yield getOrThrow(customerRef);
    const customer = customerSnap.data();
    const userSnap = yield getOrThrow(customer.user);
    const user = userSnap.data();
    if (((_a = user.allowedOrganizations) === null || _a === void 0 ? void 0 : _a.length) !== 1) {
        return;
    }
    const organization = yield getOrThrow(user.allowedOrganizations[0]);
    if (organization.data().organizationType !== OrganizationType.funeral_home.value) {
        return;
    }
    return {
        name: getCustomerName(customerSnap, userSnap, true),
        email: user.email,
        ref: customerRef,
        organization
    };
});
export default getFuneralHomeCustomerInformationFromCustomer;
