var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDisplayName, removeUndefinedFields } from '../helpers';
import { exists } from '../types';
import { getErrorReporter } from '../utils/errors';
import { State } from '../enums';
import { sanitizeValue } from '../utils/csv';
export const getNewspaperRefForCustomer = (newspaper) => {
    var _a;
    const { parent } = newspaper.data() || {};
    const newspaperRef = !!parent && ((_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.preferCustomerOnParent)
        ? parent
        : newspaper.ref;
    return newspaperRef;
};
export const getCustomer = (ctx, user, newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperRef = getNewspaperRefForCustomer(newspaper);
    const existingCustomerQuery = yield ctx
        .customersRef()
        .where('user', '==', user.ref)
        .where('organization', '==', newspaperRef)
        .get();
    if (existingCustomerQuery.docs.length) {
        return existingCustomerQuery.docs[0];
    }
    return null;
});
export const getCustomerForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const { filer, newspaper } = noticeSnap.data();
    const filerSnap = yield filer.get();
    const publisherSnap = yield newspaper.get();
    return yield getCustomer(ctx, filerSnap, publisherSnap);
});
export const getOrCreateCustomer = (ctx, user, newspaper, info = null) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    let customer = yield getCustomer(ctx, user, newspaper);
    let organizationName;
    const userOrg = (yield ((_b = (_a = user.data()) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.get()));
    if (userOrg) {
        organizationName = (_c = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _c === void 0 ? void 0 : _c.name;
    }
    else if ((_d = user.data()) === null || _d === void 0 ? void 0 : _d.organizationName) {
        organizationName = (_e = user.data()) === null || _e === void 0 ? void 0 : _e.organizationName;
    }
    if (!customer) {
        const newspaperRef = getNewspaperRefForCustomer(newspaper);
        const customerRef = ctx.customersRef().doc(`${user.id}_${newspaperRef.id}`);
        const customerData = Object.assign({ organization: newspaperRef, user: user.ref, internalID: '', userName: (_f = user.data()) === null || _f === void 0 ? void 0 : _f.name, firstName: (_g = user.data()) === null || _g === void 0 ? void 0 : _g.firstName, lastName: (_h = user.data()) === null || _h === void 0 ? void 0 : _h.lastName, address: ((_j = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _j === void 0 ? void 0 : _j.address) || ((_k = user.data()) === null || _k === void 0 ? void 0 : _k.address) || null, addressLine2: ((_l = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _l === void 0 ? void 0 : _l.addressLine2) || ((_m = user.data()) === null || _m === void 0 ? void 0 : _m.addressLine2) || null, city: ((_o = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _o === void 0 ? void 0 : _o.city) || ((_p = user.data()) === null || _p === void 0 ? void 0 : _p.city) || null, state: ((_q = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _q === void 0 ? void 0 : _q.state) || ((_r = user.data()) === null || _r === void 0 ? void 0 : _r.state) || null, zipCode: ((_s = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _s === void 0 ? void 0 : _s.zipCode) || ((_t = user.data()) === null || _t === void 0 ? void 0 : _t.zipCode) || null, phone: ((_u = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _u === void 0 ? void 0 : _u.phone) || ((_v = user.data()) === null || _v === void 0 ? void 0 : _v.phone) || null }, (organizationName && { organizationName }));
        yield customerRef.set(removeUndefinedFields(customerData));
        customer = (yield customerRef.get());
    }
    if (info) {
        yield customer.ref.update(Object.assign({}, info));
        customer = (yield customer.ref.get());
    }
    return customer;
});
export const combineCustomerNameFields = (customer) => {
    if (!exists(customer)) {
        return '';
    }
    const { firstName, lastName, userName } = customer.data();
    return (getDisplayName(sanitizeValue(firstName || ''), sanitizeValue(lastName || '')) ||
        userName ||
        '');
};
export const getCustomerName = (customer, user, concatenateFirstAndLastName) => {
    var _a, _b, _c;
    const customerName = (_a = combineCustomerNameFields(customer)) === null || _a === void 0 ? void 0 : _a.trim();
    const userName = user.data().name.trim();
    let name;
    if (concatenateFirstAndLastName) {
        if ((_c = (_b = customer === null || customer === void 0 ? void 0 : customer.data()) === null || _b === void 0 ? void 0 : _b.firstName) === null || _c === void 0 ? void 0 : _c.trim()) {
            name = customerName;
        }
        else {
            const { firstName, lastName } = user.data();
            name = user.data().name || getDisplayName(firstName, lastName);
        }
        name = (name || '').trim();
    }
    return customerName || name || userName || '';
};
export const userHasCustomerWithBulkPaymentEnabled = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    if (!user) {
        return false;
    }
    const customerWithBulkPaymentEnabled = yield ctx
        .customersRef()
        .where('user', '==', user.ref)
        .where('bulkPaymentEnabled', '==', true)
        .get();
    if (customerWithBulkPaymentEnabled.size) {
        return true;
    }
    return false;
});
export const userHasCustomerWithBulkPaymentV2Enabled = (ctx, user) => __awaiter(void 0, void 0, void 0, function* () {
    if (!user) {
        return false;
    }
    const customerWithBulkPaymentV2Enabled = yield ctx
        .customersRef()
        .where('user', '==', user.ref)
        .where('bulkPaymentEnabled_v2', '==', true)
        .get();
    if (customerWithBulkPaymentV2Enabled.size) {
        return true;
    }
    return false;
});
export const getCustomerOrganization = (ctx, advertiserOrg, newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(advertiserOrg)) {
        return null;
    }
    const newspaperRef = getNewspaperRefForCustomer(newspaper);
    const existingCustomerOrgQuery = yield ctx
        .customerOrganizationsRef()
        .where('client', '==', advertiserOrg.ref)
        .where('organization', '==', newspaperRef)
        .get();
    if (existingCustomerOrgQuery.docs.length) {
        if (existingCustomerOrgQuery.docs.length > 1) {
            getErrorReporter().logAndCaptureWarning('More than one customer organization exists for the following client organization pair', {
                client: advertiserOrg.id,
                organization: newspaper.id
            });
        }
        return existingCustomerOrgQuery.docs[0];
    }
    return null;
});
export const getOrCreateCustomerOrganization = (ctx, advertiserOrg, newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
    const newspaperRef = getNewspaperRefForCustomer(newspaper);
    let customerOrganization = yield getCustomerOrganization(ctx, advertiserOrg, newspaper);
    if (!customerOrganization) {
        const customerOrgRef = ctx
            .customerOrganizationsRef()
            .doc(`${advertiserOrg.id}_${newspaperRef.id}`);
        // when we create the customer org, add contact fields
        // from the organization
        yield customerOrgRef.set({
            organization: newspaperRef,
            client: advertiserOrg.ref,
            internalID: ((_w = advertiserOrg.data()) === null || _w === void 0 ? void 0 : _w.internalID) || '',
            name: ((_x = advertiserOrg.data()) === null || _x === void 0 ? void 0 : _x.name) || '',
            address: ((_y = advertiserOrg.data()) === null || _y === void 0 ? void 0 : _y.address) || null,
            addressLine2: ((_z = advertiserOrg.data()) === null || _z === void 0 ? void 0 : _z.addressLine2) || null,
            city: ((_0 = advertiserOrg.data()) === null || _0 === void 0 ? void 0 : _0.city) || null,
            state: ((_1 = advertiserOrg.data()) === null || _1 === void 0 ? void 0 : _1.state) || null,
            zipCode: ((_2 = advertiserOrg.data()) === null || _2 === void 0 ? void 0 : _2.zipCode) || null,
            phone: ((_3 = advertiserOrg.data()) === null || _3 === void 0 ? void 0 : _3.phone) || null
        });
        customerOrganization = (yield customerOrgRef.get());
    }
    else {
        // check to see if we need to update nonexisting fields on the customer organization
        const updates = {
            name: !((_5 = (_4 = customerOrganization.data()) === null || _4 === void 0 ? void 0 : _4.name) === null || _5 === void 0 ? void 0 : _5.trim()) && ((_6 = advertiserOrg.data()) === null || _6 === void 0 ? void 0 : _6.name)
                ? (_7 = advertiserOrg.data()) === null || _7 === void 0 ? void 0 : _7.name
                : undefined,
            address: !((_8 = customerOrganization.data()) === null || _8 === void 0 ? void 0 : _8.address)
                ? ((_9 = advertiserOrg.data()) === null || _9 === void 0 ? void 0 : _9.address) || undefined
                : undefined,
            addressLine2: !((_10 = customerOrganization.data()) === null || _10 === void 0 ? void 0 : _10.addressLine2) &&
                ((_11 = customerOrganization.data()) === null || _11 === void 0 ? void 0 : _11.addressLine2) !== ''
                ? ((_12 = advertiserOrg.data()) === null || _12 === void 0 ? void 0 : _12.addressLine2) || undefined
                : undefined,
            city: !((_13 = customerOrganization.data()) === null || _13 === void 0 ? void 0 : _13.city)
                ? ((_14 = advertiserOrg.data()) === null || _14 === void 0 ? void 0 : _14.city) || undefined
                : undefined,
            state: !((_15 = customerOrganization.data()) === null || _15 === void 0 ? void 0 : _15.state)
                ? ((_16 = advertiserOrg.data()) === null || _16 === void 0 ? void 0 : _16.state) || undefined
                : undefined,
            zipCode: !((_17 = customerOrganization.data()) === null || _17 === void 0 ? void 0 : _17.zipCode)
                ? ((_18 = advertiserOrg.data()) === null || _18 === void 0 ? void 0 : _18.zipCode) || undefined
                : undefined,
            phone: !((_19 = customerOrganization.data()) === null || _19 === void 0 ? void 0 : _19.phone)
                ? ((_20 = advertiserOrg.data()) === null || _20 === void 0 ? void 0 : _20.phone) || undefined
                : undefined
        };
        removeUndefinedFields(updates);
        if (Object.keys(updates).length) {
            yield customerOrganization.ref.update(updates);
        }
    }
    return customerOrganization;
});
export const getCustomerOrganizationName = (customerOrganization, customer) => {
    return ((customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().name) ||
        (customer === null || customer === void 0 ? void 0 : customer.data().organizationName) ||
        '').trim();
};
export const getOrCreateCustomerOrganizationForNotice = (ctx, notice) => __awaiter(void 0, void 0, void 0, function* () {
    const { filedBy, newspaper } = notice.data();
    if (!filedBy)
        return null;
    const clientOrgSnapshot = yield filedBy.get();
    const newspaperSnapshot = yield newspaper.get();
    return getOrCreateCustomerOrganization(ctx, clientOrgSnapshot, newspaperSnapshot);
});
export const filerOrgHasCustomerOrgWithBulkPaymentV2Enabled = (ctx, client) => __awaiter(void 0, void 0, void 0, function* () {
    if (!client) {
        return false;
    }
    const customerOrgsWithBulkPaymentV2Enabled = yield ctx
        .customerOrganizationsRef()
        .where('client', '==', client.ref)
        .where('bulkPaymentEnabled_v2', '==', true)
        .get();
    if (customerOrgsWithBulkPaymentV2Enabled.size) {
        return true;
    }
    return false;
});
export const filerOrgHasCustomerOrgWithBulkPaymentEnabled = (ctx, client) => __awaiter(void 0, void 0, void 0, function* () {
    if (!client) {
        return false;
    }
    const customerOrgsWithBulkPaymentEnabled = yield ctx
        .customerOrganizationsRef()
        .where('client', '==', client.ref)
        .where('bulkPaymentEnabled', '==', true)
        .get();
    if (customerOrgsWithBulkPaymentEnabled.size) {
        return true;
    }
    return false;
});
export const getShouldInvoiceCustomerOrCustomerOrgOutsideColumn = (customer) => __awaiter(void 0, void 0, void 0, function* () {
    var _21, _22, _23, _24, _25;
    if (!customer) {
        return null;
    }
    const customerInvoicedOutsideColumn = (_21 = customer.data()) === null || _21 === void 0 ? void 0 : _21.invoicedOutsideColumn;
    if (customerInvoicedOutsideColumn != null) {
        return customerInvoicedOutsideColumn;
    }
    // invoiceOutsideColumn will be deprecated at the organization level
    // keep this until the property is moved to customerOrganizations
    if ((_22 = customer.data()) === null || _22 === void 0 ? void 0 : _22.user) {
        const user = yield customer
            .data()
            .user.get();
        const userOrg = yield ((_24 = (_23 = user.data()) === null || _23 === void 0 ? void 0 : _23.organization) === null || _24 === void 0 ? void 0 : _24.get());
        const userInvoicedOutsideColumn = (_25 = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _25 === void 0 ? void 0 : _25.invoiceOutsideColumn;
        if (userInvoicedOutsideColumn != null) {
            return userInvoicedOutsideColumn;
        }
    }
    return null;
});
/**
 *
 * @param ctx
 * @param noticeSnap
 * @param disconnectedFilerSnap is used if you are passing in a notice draft that does not have a filer on it yet
 * @returns the account number for a notice in the following priority order:
 * 0. notice.syncData.syncCustomerId
 * 1. customer.internalID
 * 2. customerOrg.internalID
 * 3. null
 */
export const getAccountNumberForNotice = (ctx, noticeSnap, disconnectedFilerSnap, disconnectedFiledBySnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _26;
    // 0. return the internalID on the notice if exists
    const noticeCustomerId = (_26 = noticeSnap.data().syncData) === null || _26 === void 0 ? void 0 : _26.syncCustomerId;
    if (noticeCustomerId) {
        return {
            id: noticeCustomerId,
            source: 'notice'
        };
    }
    const { filer: filerRef, newspaper: newspaperRef, filedBy: filedByRef } = noticeSnap.data();
    const filerSnap = disconnectedFilerSnap || (yield (filerRef === null || filerRef === void 0 ? void 0 : filerRef.get()));
    const newspaperSnap = yield (newspaperRef === null || newspaperRef === void 0 ? void 0 : newspaperRef.get());
    // 0a. if no existing filer or newspaper can be identified, return null
    if (!exists(filerSnap) || !exists(newspaperSnap)) {
        return null;
    }
    const customerSnap = yield getOrCreateCustomer(ctx, filerSnap, newspaperSnap);
    // 1. return the internalID on the customer if exists
    const customerInternalId = customerSnap.data().internalID;
    if (customerInternalId) {
        return {
            id: customerInternalId,
            source: 'customer'
        };
    }
    const filedBySnap = disconnectedFiledBySnap || (yield (filedByRef === null || filedByRef === void 0 ? void 0 : filedByRef.get()));
    if (exists(filedBySnap)) {
        const customerOrganizationSnap = yield getOrCreateCustomerOrganization(ctx, filedBySnap, newspaperSnap);
        // 2. & 3. return the internalID on the customer organization
        // if exists; else return null
        const customerOrgInternalId = customerOrganizationSnap === null || customerOrganizationSnap === void 0 ? void 0 : customerOrganizationSnap.data().internalID;
        if (customerOrgInternalId) {
            return {
                id: customerOrgInternalId,
                source: 'customerorg'
            };
        }
    }
    return null;
});
export const getAddressFromCustomerInfo = (customerOrg, customer, filer) => {
    var _a, _b;
    let address;
    let addressLine2;
    let city;
    let state;
    let zipCode;
    if (exists(customerOrg)) {
        ({ address, addressLine2, city, state, zipCode } = customerOrg.data());
    }
    else if (exists(customer)) {
        ({ address, addressLine2, city, state, zipCode } = customer.data());
    }
    else if (exists(filer)) {
        ({ address, addressLine2, city, state, zipCode } = filer.data());
    }
    return {
        address_line1: address || '',
        address_line2: addressLine2 || '',
        address_city: city || '',
        address_state: (_b = (_a = State.by_value(state)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '',
        address_zip: zipCode || ''
    };
};
export const getCustomerAndCustomerOrganization = (ctx, noticeData) => __awaiter(void 0, void 0, void 0, function* () {
    var _27, _28;
    const { newspaper, filer, filedBy } = noticeData;
    // This really should never happen but it sometimes happens when pricing is calculated
    // in unit tests or very very early in the placement flow lifecycle. Easier to just
    // ignore it!
    if (!filer) {
        return {
            customer: undefined,
            customerOrganization: undefined
        };
    }
    const publisherSnap = yield newspaper.get();
    const filedBySnap = yield (filedBy === null || filedBy === void 0 ? void 0 : filedBy.get());
    const customerOrganization = (_27 = (yield getCustomerOrganization(ctx, filedBySnap, publisherSnap))) !== null && _27 !== void 0 ? _27 : undefined;
    const filerSnap = yield filer.get();
    const customer = (_28 = (yield getCustomer(ctx, filerSnap, publisherSnap))) !== null && _28 !== void 0 ? _28 : undefined;
    return {
        customerOrganization,
        customer
    };
});
