export const isJpgFileName = (name) => {
    if (!name) {
        return false;
    }
    return (name.toLowerCase().endsWith('.jpg') || name.toLowerCase().endsWith('.jpeg'));
};
export const getMetaFilesFromFiles = (files) => {
    return files.map(file => {
        return {
            file,
            metadata: {}
        };
    });
};
