import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Eye } from 'icons';
import { exists } from 'lib/types';
import { debounce } from 'utils/funcs';
import { selectPostWithoutFormatting, selectSquashable } from 'redux/placement';
import { selectIsPublisher } from 'redux/auth';
import { isFontInstalled, templateStylesToCss } from 'utils/styles';
import { LockClosedIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'redux/hooks';
import { selectDisplayOnlyAds, selectHasStateLevelNoticeTypes } from 'routes/placeScroll/placeScrollSelectors';
import classNames from 'classnames';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { getEditor, setEditor, preProcess, postProcess, resizeTables, formatTablesOnCopyPaste, setImagesMaxWidth, squash, cleanContent } from './mceHelpers';
import MCEImageUploadModal from './MCEImageUploadModal';
const setComputedWidth = (tables) => {
    for (let i = 0; i < tables.length; i++) {
        const table = tables[i];
        const firstRow = table.querySelector('tr');
        const cells = firstRow ? [...firstRow.querySelectorAll('td')] : [];
        for (let z = 0; z < cells.length; z++) {
            const computedStyles = window.getComputedStyle(cells[z]);
            const paddingLeft = parseFloat(computedStyles.paddingLeft) || 0;
            const paddingRight = parseFloat(computedStyles.paddingRight) || 0;
            const width = parseFloat(computedStyles.width);
            const computedWidth = width + paddingLeft + paddingRight;
            cells[z].setAttribute('computed-width', `${computedWidth}px;`);
        }
    }
};
const addComputedWidthAttributeToTables = (contentDOM) => {
    const tables = contentDOM && contentDOM.querySelectorAll('table');
    if (tables && tables.length) {
        setComputedWidth(tables);
    }
};
const getMceToolbar = (options) => {
    const sections = [
        'customzoom',
        'undo redo',
        'bold italic underline strikethrough',
        'alignleft aligncenter alignright blockquote',
        'bullist numlist',
        'table',
        'customTable'
    ];
    if (options.allowImages) {
        sections.push('customimage');
    }
    const showHtmlCodeButton = getBooleanFlag(LaunchDarklyFlags.ENABLE_TINY_MCE_SOURCE_CODE, false);
    if (showHtmlCodeButton) {
        sections.push('code');
    }
    return sections.join(' | ');
};
const getMceToolbar2 = () => {
    const sections = [
        'tabledelete',
        'tableinsertrowbefore tableinsertrowafter tabledeleterow',
        'tableinsertcolbefore tableinsertcolafter tabledeletecol',
        'applystroke'
    ];
    return sections.join(' | ');
};
const getMcePlugins = (options) => {
    const res = [
        'advlist',
        'autolink',
        'lists',
        'link',
        'charmap',
        'preview',
        'anchor',
        'searchreplace',
        'visualblocks',
        'code',
        'insertdatetime',
        'media',
        'paste',
        'code',
        'help',
        'nonbreaking'
    ];
    if (options.allowImages) {
        res.push('image');
    }
    if (options.allowTables) {
        res.push('table');
    }
    return res;
};
const MCE = React.forwardRef((props, ref) => {
    var _a, _b, _c;
    const { onEditorUpdate = () => { }, initialState, placeholder, errorTitle, errorFunction, clickText, onPaste, newspaper, displayPaperFileReplacement, templateStyles, columns, allowImages = false, parsing, clearMCEEditor, showDisabledEditorAfterTypeformMessage } = props;
    const isPublisher = useAppSelector(selectIsPublisher);
    const squashableColumn = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().cleanVariant;
    const squashable = useAppSelector(selectSquashable);
    const newspaperHasStateLevelNoticeTypes = useAppSelector(state => selectHasStateLevelNoticeTypes(state, newspaper));
    const postWithoutFormatting = useAppSelector(selectPostWithoutFormatting);
    const displayOnlyAds = useAppSelector(state => selectDisplayOnlyAds(state, newspaper));
    const [zoom, setZoom] = useState(1.5);
    const [tableInNotice, setTableInNotice] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [overrideDisabledTextEditor, setOverrideDisabledTextEditor] = useState(false);
    const cleanFunction = exists(newspaper) && newspaper.data().cleanVariant && squashable !== false
        ? squash
        : cleanContent;
    const showEditorDisabledAfterTypeformMessage = showDisabledEditorAfterTypeformMessage && !overrideDisabledTextEditor;
    const disabled = parsing ||
        postWithoutFormatting ||
        displayOnlyAds ||
        showEditorDisabledAfterTypeformMessage;
    useEffect(() => {
        const editor = getEditor();
        if (!editor)
            return;
        if (!disabled) {
            editor.setMode('design');
        }
        else {
            editor.setMode('readonly');
        }
    });
    const isTableInNotice = (notice) => {
        setTableInNotice(notice.includes('<table') && notice.includes('</table>'));
    };
    const handleEditorChange = debounce(() => {
        const editor = getEditor();
        if (!editor) {
            return;
        }
        const contentDOM = editor.getBody();
        if (!contentDOM)
            return;
        isTableInNotice(contentDOM.innerHTML);
        // Make sure images don't exceed column bounds
        if (allowImages) {
            setImagesMaxWidth(contentDOM);
        }
        /*
          Add a computed-width attribute to table cells
          This attribute is stringified and passed along the Indesign
          pipeline so that it can be used to accurately determine
          column widths based on what the user sees in TinyMCE.
        */
        addComputedWidthAttributeToTables(contentDOM);
        if (squashableColumn === 'squash') {
            onEditorUpdate(formatTablesOnCopyPaste(contentDOM.innerHTML, { allowImages }));
        }
        else {
            onEditorUpdate(contentDOM.innerHTML);
        }
        if (!disabled) {
            editor.setMode('design');
        }
        else {
            editor.setMode('readonly');
        }
    }, 500);
    const handleOnPaste = debounce(() => {
        const editor = getEditor();
        if (!editor)
            return;
        const contentDOM = editor.getBody();
        if (!contentDOM)
            return;
        if (squashableColumn !== 'squash')
            return;
        isTableInNotice(contentDOM.innerHTML);
        // Make sure images don't exceed column bounds
        if (allowImages) {
            setImagesMaxWidth(contentDOM);
        }
        /*
          Add a computed-width attribute to table cells
          This attribute is stringified and passed along the Indesign
          pipeline so that it can be used to accurately determine
          column widths based on what the user sees in TinyMCE.
        */
        addComputedWidthAttributeToTables(contentDOM);
        onPaste &&
            onPaste(formatTablesOnCopyPaste(contentDOM.innerHTML, { allowImages }));
        if (!disabled) {
            editor.setMode('design');
        }
        else {
            editor.setMode('readonly');
        }
    }, 500);
    const isTemplateFontInstalled = useMemo(() => {
        return templateStyles.font ? isFontInstalled(templateStyles.font) : true;
    }, [templateStyles.font]);
    // We should only show the custom font message when the editor has been initialised.
    // If not, than the message may appear earlier and impacts UX
    const shouldShowCustomFontText = () => {
        const editor = getEditor();
        return !isTemplateFontInstalled && (editor === null || editor === void 0 ? void 0 : editor.initialized);
    };
    // Control the zoom level (handled outside the body styles)
    const transformCss = `
    .tox-edit-area {
      transform: scale(${zoom});
      transform-origin: 0 0;
      max-width: ${100 / zoom}% !important;
      max-height: ${100 / zoom}% !important;
    }`;
    const customMCEBorderRadius = `
  .tox-tinymce {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: ${shouldShowCustomFontText() ? '0rem;' : '0.375rem;'} /* rounded-md */
    border-bottom-right-radius: ${shouldShowCustomFontText() ? '0rem;' : '0.375rem;'}
  }
  `;
    const getBodyStyles = () => {
        const templateBodyStyles = templateStylesToCss(templateStyles, columns, isTemplateFontInstalled);
        const otherBodyStyles = {
            'border-left': '2px dotted rgba(0, 0, 0, 0.2)',
            'border-right': '2px dotted rgba(0, 0, 0, 0.2)',
            margin: '12px 0 0 12px',
            padding: 0,
            'min-height': '95%',
            'text-align': 'justify'
        };
        return Object.assign(Object.assign({}, otherBodyStyles), templateBodyStyles);
    };
    const bodyStyles = getBodyStyles();
    const bodyCss = Object.entries(bodyStyles)
        .map(([a, b]) => `${a}: ${b};`)
        .join('\n');
    // Custom style added to the TinyMCE editor. This only affects how things
    // look inside the MCE editor box, it does not affect Indesign in any way.
    const contentStyle = `
  html {
    height: 100%;
  }

  body {
    ${bodyCss}
  }

  .mce-content-readonly {
    background: #cacaca;
  }

  .mce-content-body {
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
  
  .mce-content-body p { 
    margin-bottom: 0;
    margin-top: 0;
  }

  .mce-content-body ul, 
  .mce-content-body ol {
    list-style-position: inside;
  
    margin-top: 0;
    margin-bottom: 0;

    padding-left: 0;
    padding-right: 0;
  }

  .mce-content-body table tr th,
  .mce-content-body table tr td {
    padding: 0;
  }`;
    /**
     * Updates the CSS inside the MCE iframe, since TinyMCE only takes custom_styles
     * at initialization time.
     */
    const updateEditorStyle = () => {
        var _a, _b;
        const editor = getEditor();
        if (!editor) {
            return;
        }
        const styleElements = (_b = (_a = editor.iframeElement) === null || _a === void 0 ? void 0 : _a.contentDocument) === null || _b === void 0 ? void 0 : _b.getElementsByTagName('style');
        if (!styleElements || styleElements.length === 0) {
            return;
        }
        styleElements[0].innerHTML = contentStyle;
    };
    useEffect(() => {
        updateEditorStyle();
    }, [contentStyle]);
    /**
     * NOTE: Currently we're saving the raw `innerHTML` of the editor body from the actual DOM as the HTML string
     * for a notice. However, the TinyMCE input uses a different verion of the HTML markup as its actual value, which
     * it then uses to render the resulting `innterHTML`. See documentation at [this link](https://www.tiny.cloud/docs/integrations/react/#oneditorchange).
     * Compare the `value` of `onEditorChange` to `editor.getBody().innerHTMl` to see the difference.
     *
     * Specifically, the actual markup on the page contains `<p><br data-mce-bogus="1"><\/p>` for empty line breaks after
     * TinyMCE renders it, but the "value" stored by the input and used to actually render the content in the form
     * contains `\n<p>&nbsp;</p>` for the same effect. Previously we were replacing the form's value with the resulting
     * DOM markup, causing rendering issues and cursor jumps when a user presses enter.
     *
     * This workaround reverses the raw DOM markup back to the markup TinyMCE expects to render for the linebreak.
     * This is not a sustainable fix, but I'm not clear on how our entire rendering process handles the current version of the
     * markup using `<p><br data-mce-bogus="1"><\/p>`, or if there are further differences between the raw DOM and the value
     * TinyMCE uses to render the content that may need to be addressed.
     */
    const formattedHtmlString = (initialState || '').replace(/<p><br data-mce-bogus="1"><\/p>/g, '\n<p>&nbsp;</p>');
    return (_jsxs(_Fragment, { children: [showImageModal && (_jsx(MCEImageUploadModal, { setOpen: setShowImageModal, onSubmit: fileDataUrl => {
                    const editor = getEditor();
                    if (!editor) {
                        return;
                    }
                    editor.insertContent(`<img src='${fileDataUrl}' />`);
                } })), ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.cleanVariant) && tableInNotice && (_jsxs("div", Object.assign({ className: "flex items-center mb-2 px-3 py-2 rounded overflow-hidden text-sm bg-gray-100 text-gray-600" }, { children: [_jsx("div", Object.assign({ className: "ml-1 mr-3 text-gray-700" }, { children: _jsx(Eye, {}) })), _jsx("div", { children: "This newspaper does not allow tables so the text may appear differently in the editor below and the rendered preview." })] }))), _jsxs("div", Object.assign({ style: { height: 500, minHeight: 500, width: '100%' }, ref: ref, className: classNames('relative', {
                    'pb-8': shouldShowCustomFontText()
                }) }, { children: [disabled && errorTitle && (_jsxs("div", Object.assign({ className: "absolute bg-white h-full opacity-75 w-full z-10 flex items-center flex-col" }, { children: [errorTitle, !((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.displayOnlyAds) && errorFunction && (_jsxs("p", Object.assign({ className: "text-center text-gray-600" }, { children: [_jsx("a", Object.assign({ href: "#", rel: "noreferrer", className: "underline", onClick: errorFunction }, { children: "Click here" })), ' ', clickText] }))), ((_c = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _c === void 0 ? void 0 : _c.displayOnlyAds) &&
                                !displayPaperFileReplacement &&
                                isPublisher &&
                                postWithoutFormatting &&
                                errorFunction && (_jsxs("p", Object.assign({ className: "text-center text-gray-600" }, { children: [_jsx("a", Object.assign({ href: "#", rel: "noreferrer", className: "underline", onClick: errorFunction }, { children: "Click here" })), ' ', clickText] })))] }))), showEditorDisabledAfterTypeformMessage && (_jsx("div", Object.assign({ className: "absolute h-full bg-base-2 w-full z-10 flex items-center flex-col border rounded-md" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-1/2 items-center text-center px-4 m-auto space-y-6" }, { children: [_jsx(LockClosedIcon, { className: "w-8 text-column-gray-700" }), newspaperHasStateLevelNoticeTypes ? (_jsxs("div", Object.assign({ className: "font-semibold text-sm text-column-gray-400" }, { children: ["We've drafted your notice based on your answers. ", _jsx("br", {}), _jsx("a", Object.assign({ href: "#", className: "underline", onClick: () => {
                                                setOverrideDisabledTextEditor(true);
                                            } }, { children: "Click here to edit the notice text" })), "."] }))) : (_jsxs("div", Object.assign({ className: "font-semibold text-sm text-column-gray-400" }, { children: ["We've drafted your notice based on your answers. If you need to make edits, you can", ' ', _jsx("a", Object.assign({ href: "#", className: "underline", onClick: () => {
                                                clearMCEEditor && clearMCEEditor();
                                            } }, { children: "click here to start over" })), ' ', "or request edits after submitting your notice."] })))] })) }))), _jsx(Editor, { tinymceScriptSrc: "/static/js/tinymce/tinymce.min.js", onEditorChange: handleEditorChange, onInit: () => {
                            if (initialState !== placeholder)
                                handleEditorChange();
                        }, initialValue: formattedHtmlString, onPaste: handleOnPaste, disabled: disabled, init: {
                            images_upload_handler: (blobInfo, success) => {
                                success(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`);
                            },
                            browser_spellcheck: true,
                            setup: (editor) => {
                                const TOOLBAR_2_QUERY = '.tox-toolbar:nth-child(2)';
                                setEditor(editor);
                                disabled && editor.setMode('readonly');
                                !disabled && editor.setMode('design');
                                editor.ui.registry.addMenuButton('customzoom', {
                                    icon: 'zoom-in',
                                    tooltip: 'Zoom',
                                    fetch: callback => {
                                        callback([
                                            {
                                                type: 'menuitem',
                                                text: '100% Zoom (actual size)',
                                                onAction: () => {
                                                    setZoom(1.0);
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: '150% Zoom (default)',
                                                onAction: () => {
                                                    setZoom(1.5);
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: '200% Zoom',
                                                onAction: () => {
                                                    setZoom(2.0);
                                                }
                                            }
                                        ]);
                                    }
                                });
                                editor.ui.registry.addButton('customimage', {
                                    icon: 'image',
                                    tooltip: 'Image',
                                    onAction: () => {
                                        setShowImageModal(true);
                                    }
                                });
                                editor.ui.registry.addButton('applystroke', {
                                    text: 'Apply Stroke',
                                    tooltip: 'Apply/Remove stroke',
                                    onAction: () => {
                                        var _a, _b, _c;
                                        const selectedNode = editor.selection.getNode();
                                        function getClosest(el, tag) {
                                            const localTag = tag.toUpperCase();
                                            let localEl = el;
                                            do {
                                                if (localEl && localEl.nodeName === localTag) {
                                                    return localEl;
                                                }
                                                localEl = localEl === null || localEl === void 0 ? void 0 : localEl.parentNode;
                                            } while (localEl);
                                            return null;
                                        }
                                        const selectedTable = getClosest(selectedNode, 'table');
                                        if (!selectedTable)
                                            return;
                                        const isStroke = ((_a = selectedTable.parentElement) === null || _a === void 0 ? void 0 : _a.getAttribute('data-custom-style')) === 'stroke';
                                        const styles = {
                                            isStroke: {
                                                border: '1px solid rgba(0, 0, 0, 0.87)',
                                                attributeName: 'stroke'
                                            },
                                            default: {
                                                border: '1px solid #ccc'
                                            }
                                        };
                                        if (!isStroke) {
                                            (_b = selectedTable.parentElement) === null || _b === void 0 ? void 0 : _b.setAttribute('data-custom-style', styles.isStroke.attributeName);
                                        }
                                        if (isStroke) {
                                            (_c = selectedTable.parentElement) === null || _c === void 0 ? void 0 : _c.removeAttribute('data-custom-style');
                                        }
                                        const childCells = selectedTable.parentElement
                                            ? [...selectedTable.parentElement.querySelectorAll('td')]
                                            : [];
                                        for (let i = 0; i < childCells.length; i++) {
                                            const style = isStroke
                                                ? styles.default.border
                                                : styles.isStroke.border;
                                            childCells[i].style.border = style;
                                        }
                                        if (editor) {
                                            onEditorUpdate(editor.getBody().innerHTML);
                                        }
                                    }
                                });
                                /** "NodeChange" Called whenever mce editor node changes
                                 * We get the parent table node of Editor's current selection from the editor DOM,
                                 * if that node's parent is "table", show the toolbar2 with table options
                                 */
                                editor.on('NodeChange', function () {
                                    const toolbar2 = editor.editorContainer.querySelector(TOOLBAR_2_QUERY);
                                    const element = editor.dom.getParent(editor.selection.getNode(), 'table');
                                    if (element && element.tagName === 'TABLE') {
                                        if (toolbar2) {
                                            toolbar2.style.display = 'flex';
                                        }
                                    }
                                    else if (toolbar2) {
                                        toolbar2.style.display = 'none';
                                    }
                                });
                                editor.on('init', () => {
                                    // set placeholder text
                                    const { menuItems } = editor.ui.registry.getAll();
                                    if (menuItems.tableprops) {
                                        delete menuItems.tableprops;
                                    }
                                    // Hide the toolbar2 on editor init
                                    const toolbar2 = editor.editorContainer.querySelector(TOOLBAR_2_QUERY);
                                    if (toolbar2) {
                                        toolbar2.style.display = 'none';
                                    }
                                    resizeTables();
                                    // There are a lot of components of the default table plugin we do not support
                                    // this is a way of hiding them, and making room for custom components in the future
                                    editor.ui.registry.addMenuButton('customTable', {
                                        icon: 'table',
                                        tooltip: 'Table',
                                        fetch: callback => {
                                            const items = [
                                                editor.ui.registry.getAll().menuItems.inserttable,
                                                editor.ui.registry.getAll().menuItems.deletetable,
                                                editor.ui.registry.getAll().menuItems.row,
                                                editor.ui.registry.getAll().menuItems.column
                                            ];
                                            callback(items);
                                        }
                                    });
                                });
                                return editor;
                            },
                            // Note: we have specifically decided NOT to allow images in copy-paste
                            // even when they are otherwise enabled (for now) because of the difficulties
                            // in supporting pasting images across all operating systems, browsers, file types.
                            // and image sources.
                            paste_preprocess: preProcess((val) => cleanFunction(val, { allowImages: false })),
                            paste_postprocess: postProcess,
                            init_instance_callback: () => {
                                const freeTiny = document.querySelector('.tox-notifications-container');
                                if (freeTiny && freeTiny.style) {
                                    freeTiny.style.display = 'none';
                                }
                            },
                            underline: {
                                inline: 'u',
                                styles: { 'text-decoration': 'underline' },
                                exact: true
                            },
                            formats: {
                                strikethrough: [
                                    { inline: 's' },
                                    { inline: 'del' },
                                    { inline: 'strike' },
                                    { inline: 'span', styles: { textDecoration: 'line-through' } }
                                ]
                            },
                            height: '100%',
                            menubar: false,
                            nonbreaking_force_tab: true,
                            plugins: getMcePlugins({
                                allowImages,
                                allowTables: !(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().cleanVariant)
                            }),
                            paste_word_valid_elements: '@[style],-strong/b,-em/i,-span,-p,-ol,-ul,-li,-table,-tr,-td[colspan|rowspan],-th,-thead,-tfoot,-tbody,-a[href|name],sub,sup,strike,br,u',
                            content_style: contentStyle,
                            toolbar1: getMceToolbar({
                                allowImages,
                                allowTables: !(newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().cleanVariant)
                            }),
                            toolbar2: getMceToolbar2(),
                            table_toolbar: '',
                            contextmenu: false,
                            resize: false,
                            // Only allow simple disc bullets and standard numbered lists
                            // See: https://www.tiny.cloud/docs/plugins/opensource/advlist/#advlist_bullet_styles
                            advlist_bullet_styles: 'default',
                            advlist_number_styles: 'default',
                            // Image options
                            // See: https://www.tiny.cloud/docs/plugins/opensource/image/
                            image_dimensions: false,
                            images_file_types: 'jpeg,jpg,png'
                        } }), shouldShowCustomFontText() && (_jsxs("div", Object.assign({ className: "flex items-center justify-center bg-base-1 border-b border-l border-r border-column-gray-150 text-column-gray-400 font-normal text-xs rounded-b-md py-1.5" }, { children: [_jsx(InformationCircleIcon, { className: "h-4 w-4" }), _jsx("p", Object.assign({ className: "pl-1" }, { children: "This newspaper uses a custom font, so text may appear differently in the editor and the rendered preview." }))] })))] })), _jsx("style", { children: `
          .mceContentBody p {
            margin-bottom: 0;
            margin-top: 0;
          }
        
          .mce-notification {
            display: none !important;
          }

          .tox-tinymce {
            border-color: #e9ecef /* column-gray-100 */
          }
          .tox-editor-header {
            z-index: 0 !important;
          }
          ${customMCEBorderRadius}
          ${transformCss}
  ` })] }));
});
export default MCE;
