import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectContextOrganizationName, selectIsColumnRep } from 'redux/auth';
import { TextField } from 'lib/components/TextField';
import { isPercentAdditionalFee } from 'lib/types/rates';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { userIsSuper } from 'utils/permissions';
export default function ColumnRepFeeCard({ onUpdateColumnRepFee, updatedColumnRepFee }) {
    const organizationName = useAppSelector(selectContextOrganizationName);
    const user = useAppSelector(state => state.auth.user);
    const isSuperUser = user && userIsSuper(user);
    const isColumnRep = useAppSelector(selectIsColumnRep);
    const [columnRepFeePct, setColumnRepFeePct] = useState(`${isPercentAdditionalFee(updatedColumnRepFee)
        ? updatedColumnRepFee.feePercentage
        : 0}`);
    return (_jsx(_Fragment, { children: (isSuperUser || isColumnRep) &&
            isPercentAdditionalFee(updatedColumnRepFee) && (_jsx(CardGridLayout, Object.assign({ header: {
                title: 'Column Rep fee',
                description: `Configure Column Rep fee for ${organizationName}.`
            } }, { children: _jsx(GridInput, { children: _jsx(TextField, { id: "column-rep-fee-input", labelText: "What is the additional fee percentage for Column Reps?", value: columnRepFeePct, type: "number", suffix: "%", onChange: val => {
                        const columnRepFeePct = parseInt(val, 10);
                        if (columnRepFeePct >= 0) {
                            onUpdateColumnRepFee(Object.assign(Object.assign({}, updatedColumnRepFee), { feePercentage: columnRepFeePct }));
                        }
                        setColumnRepFeePct(val);
                    } }) }) }))) }));
}
