import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getOrganizationIconColors } from 'components/modals/JoinOrganizationModals/helpers';
import { BuildingIcon, TickIcon } from 'icons';
import { State } from 'lib/enums';
export default function SearchedOrganizationsListItem({ organizationSnap, onItemSelected, selected, index }) {
    var _a;
    const iconStyles = getOrganizationIconColors(index);
    return (_jsxs("div", Object.assign({ className: "flex justify-between items-center py-2 px-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: `h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}` }, { children: _jsx(BuildingIcon, { className: "m-auto transform translate-y-2", stroke: iconStyles.stroke }) })), _jsxs("div", Object.assign({ className: "pl-2" }, { children: [_jsx("p", Object.assign({ className: "font-medium\ttext-sm\ttext-gray-800" }, { children: organizationSnap.name })), _jsx("p", Object.assign({ className: "text-gray-600 text-sm leading-400" }, { children: `${(_a = State.by_value(organizationSnap.state)) === null || _a === void 0 ? void 0 : _a.label}, ${organizationSnap.city}` }))] }))] })), _jsx("div", { children: selected ? (_jsx("div", Object.assign({ className: "h-8 w-24 bg-column-green-100 rounded-full items-center justify-center flex" }, { children: _jsxs("div", Object.assign({ className: "flex text-xs font-medium leading-5 text-column-green-500 items-center " }, { children: [_jsx("p", Object.assign({ className: "mr-2" }, { children: "Selected" })), _jsx(TickIcon, {})] })) }))) : (_jsx("button", Object.assign({ id: `ask-join-org-${index}`, className: "bg-blue-50 font-medium text-sm rounded-md transition duration-300 ease-in-out border w-128 hover:bg-blue-100 text-blue-500 hover:text-blue-600 border-blue-500 focus:shadow-outline-blue focus:border-blue-50 w-full py-1 px-2", onClick: () => {
                        const updatedObj = {
                            value: organizationSnap.id,
                            label: organizationSnap.name
                        };
                        onItemSelected(updatedObj);
                    } }, { children: "Ask to Join" }))) })] })));
}
