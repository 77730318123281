import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { ColumnCountField } from '../ColumnCountField';
import { selectShouldHideColumnCountField, selectNoticeTitleFieldConfig, selectShouldHideRateField } from '../placeScrollSelectors';
import RateField from '../RateField';
import { NoticeTitleField } from './NoticeTitleField';
export function NoticeContentFields({ newspaper, overrideHideColumnCountField }) {
    const hideRateField = useAppSelector(selectShouldHideRateField);
    const hideColumnCountField = useAppSelector(state => selectShouldHideColumnCountField(state, newspaper)) || overrideHideColumnCountField;
    const noticeTitleFieldConfig = useAppSelector(state => selectNoticeTitleFieldConfig(state, newspaper));
    const hideNoticeTitleField = !noticeTitleFieldConfig.enabled;
    return (_jsxs("div", Object.assign({ className: "md:grid grid-cols-2 gap-6" }, { children: [!hideColumnCountField && _jsx(ColumnCountField, { newspaper: newspaper }), !hideRateField && _jsx(RateField, {}), !hideNoticeTitleField && (_jsx("div", Object.assign({ className: "col-span-2" }, { children: _jsx(NoticeTitleField, { required: noticeTitleFieldConfig.required }) })))] })));
}
