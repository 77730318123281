import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Box, Button, CircularProgress, createStyles, withStyles } from '@material-ui/core';
const styles = () => createStyles({
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    payInvoice: {
        backgroundColor: '#093377',
        textTransform: 'none',
        width: '100%',
        color: 'white',
        border: '1px solid #4285F4',
        marginTop: '13px',
        '&:hover': {
            backgroundColor: '#0b3d8e !important' // gives the pay invoice button a lighter dark blue background than above
        },
        '&:disabled': {
            opacity: 0.5,
            color: 'white'
        }
    }
});
function PayInvoiceButton({ id, loading, disabled, type, clickHandler, classes }) {
    return (_jsx(Box, Object.assign({ className: classes.flex }, { children: _jsx(Button, Object.assign({ type: type, onClick: clickHandler, id: id, className: classes.payInvoice, disabled: disabled || loading, startIcon: loading && (_jsx(CircularProgress, { size: 20, className: classes.progress, style: { color: 'white' } })) }, { children: "Pay invoice" })) })));
}
export default withStyles(styles)(PayInvoiceButton);
