import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { logAndCaptureException } from 'utils';
import { connect } from 'react-redux';
import { UNABLE_TO_FETCH_USER } from 'sagas/AuthSaga';
import { WarningIcon } from 'icons';
import { ColumnService } from 'lib/services/directory';
import { ENV, PROD, DEMO } from '../constants';
const determineColumnService = (path) => {
    if (path.includes('obituaries') || path.includes('obituary')) {
        return ColumnService.OBITS;
    }
    if (path.includes('place') && !path.includes('obituaries')) {
        return ColumnService.WEB_PLACEMENT;
    }
    if (path.includes('invoice')) {
        return ColumnService.PAYMENTS;
    }
    return ColumnService.UNKNOWN;
};
class SuspenseErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // See EnoticeFirebase.ts for an explanation of why we set this property.
        this.showLongPollingSelector = () => {
            return [this.props.error, this.state.hasError].some(e => e === UNABLE_TO_FETCH_USER);
        };
        this.state = {
            hasError: false
        };
    }
    static getDerivedStateFromError(error) {
        const service = determineColumnService(window.location.pathname || '');
        if (ENV === DEMO || ENV === PROD) {
            logAndCaptureException(service, error, 'Suspense Error Boundary Error', {
                env: ENV
            });
        }
        return {
            hasError: true
        };
    }
    render() {
        if (this.props.error || this.state.hasError) {
            return (_jsx(_Fragment, { children: _jsx(Grid, Object.assign({ container: true, style: {
                        width: '100vw',
                        height: '100vh',
                        alignItems: 'center',
                        justifyContent: 'center'
                    } }, { children: _jsxs(Grid, { children: [_jsx(Typography, Object.assign({ variant: "h5" }, { children: "Whoops! We've encountered an error" })), _jsx(Typography, { children: "Please refresh the page. If that doesn't help, email help@column.us and we will assist you!" }), this.showLongPollingSelector() && (_jsxs("div", Object.assign({ className: "mt-10 flex items-center bg-yellow-200 text-gray-900 text-sm font-normal px-4 py-3", role: "alert" }, { children: [_jsx(WarningIcon, { className: "h-5 w-5 mr-3" }), _jsxs("p", { children: ["We detected an issue with your network that may be preventing you from accessing Column. ", _jsx("br", {}), " Click", ' ', _jsx("a", Object.assign({ href: "#", onClick: e => {
                                                    e.preventDefault();
                                                    window.localStorage.setItem('enableAutoDetectLongPolling', 'true');
                                                    window.location.reload();
                                                    return false;
                                                }, className: "font-semibold underline transition duration-150 ease-in-out" }, { children: "here" })), ' ', "to enable an experimental setting which may fix the issue"] })] })))] }) })) }));
        }
        return this.props.children;
    }
}
const mapStateToProps = (state) => ({
    error: state.errors.error
});
export default connect(mapStateToProps)(SuspenseErrorBoundary);
