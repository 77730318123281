var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { InformationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import ToastActions from 'redux/toast';
import { Alert } from 'lib/components/Alert';
import api from 'api';
import { useAppDispatch } from 'redux/hooks';
export function InitiateTemporaryPasswordResetAlert({ onClickPasswordReset, sendingResetEmail }) {
    return (_jsx(Alert, Object.assign({ id: "temporary-password-alert", title: "Temporary Password", status: "warning", icon: sendingResetEmail ? (_jsx(LoadingSpinner, {})) : (_jsx(InformationCircleIcon, { className: "w-5 h-5" })) }, { children: _jsxs("p", { children: ["A temporary password was set on your account. Please", ' ', _jsx("span", Object.assign({ className: "font-bold underline cursor-pointer", onClick: onClickPasswordReset }, { children: "click here" })), ' ', "to receive an email with a link to reset your password."] }) })));
}
function PasswordResetInitiatedAlert() {
    return (_jsx(Alert, Object.assign({ id: "temporary-password-alert", title: "Password Reset Email Sent", status: "success", icon: _jsx(CheckCircleIcon, { className: "w-5 h-5" }) }, { children: "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder." })));
}
export default function TemporaryPasswordResetContainer({ email }) {
    const dispatch = useAppDispatch();
    const [sendingResetEmail, setSendingResetEmail] = useState(false);
    const [sentResetEmail, setSentResetEmail] = useState(false);
    const handlePasswordReset = () => __awaiter(this, void 0, void 0, function* () {
        if (sendingResetEmail) {
            return;
        }
        setSendingResetEmail(true);
        const reqData = {
            email
        };
        const resp = yield api.post('users/send-password-reset-email', reqData);
        if (resp.success) {
            dispatch(ToastActions.toastSuccess({
                headerText: 'Check your inbox',
                bodyText: "We've sent you a link to reset your password"
            }));
            setSentResetEmail(true);
        }
        else {
            dispatch(ToastActions.toastError({
                headerText: 'Something went wrong',
                bodyText: 'We ran into an issue sending you a password reset email. Please try again in a few minutes or contact help@column.us for assistance.'
            }));
        }
        setSendingResetEmail(false);
    });
    return (_jsx("div", Object.assign({ className: "mt-5" }, { children: sentResetEmail ? (_jsx(PasswordResetInitiatedAlert, {})) : (_jsx(InitiateTemporaryPasswordResetAlert, { onClickPasswordReset: handlePasswordReset, sendingResetEmail: sendingResetEmail })) })));
}
