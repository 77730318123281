/**
 * The utilities in this file help ensure consistent data across our various
 * CSV export endpoints.
 */
import moment from 'moment';
import htmlToText from 'html-to-text';
import { dbToUICurrency } from '../pricing/ui';
import { firestoreTimestampOrDateToDate, getDisplayName } from '../helpers';
import { getDistributeFeeSettings, invoiceDataToDBPricingObject } from '../pricing';
import { exists } from '../types';
export const sanitizeValue = (val) => {
    if (!val) {
        return '';
    }
    let newVal = typeof val === 'string' ? val : `${val}`;
    // Replace newlines with spaces
    newVal = newVal.replace(/\n/g, ' ');
    // Replace quotes with double quotes
    newVal = newVal.replace(/"/g, '""');
    // Remove commas
    newVal = newVal.replace(/,/g, '');
    // Trim excess whitespace
    newVal = newVal.trim();
    return newVal;
};
/**
 * Stringify CSV data.
 */
export const makeCsvContent = (headers, body) => {
    const sanitizedHeaders = headers.map(sanitizeValue);
    const sanitizedBody = body.map(row => row.map(sanitizeValue));
    const csvRows = [sanitizedHeaders, ...sanitizedBody];
    return csvRows.map(e => e.join(',')).join('\n');
};
export const PAYOUT_CSV_HEADERS = [
    'ID',
    'Paper Name',
    'Total Cost',
    'Total Invoiced Amount',
    'Publication Dates',
    'Invoice Number',
    'Filer',
    'Organization',
    'Account ID',
    'Transaction Type',
    'Custom ID',
    'Transaction Date',
    'Preview'
];
// Header: 'ID'
export const noticeID = (notice) => {
    return (notice === null || notice === void 0 ? void 0 : notice.id) || 'N/A';
};
// Header: 'Paper Name'
export const paperName = (newspaper) => {
    if (!newspaper)
        return 'N/A';
    return sanitizeValue(newspaper.data().name);
};
// Header: 'Publication Dates'
export const publicationDates = (notice) => {
    return ((notice === null || notice === void 0 ? void 0 : notice.data().publicationDates.map(ts => {
        const publicationDate = ts.toDate();
        return publicationDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    }).join(';')) || 'N/A');
};
// Header: 'Notice name'
export const noticeName = (notice) => {
    // eslint-disable-next-line no-useless-escape
    return notice.data().referenceId ? `\"${notice.data().referenceId}\"` : '';
};
// Header: 'Total Invoiced Amount'
export const totalInvoicedAmount = (newspaperSnap, invoiceSnap, rateSnap) => {
    if (!invoiceSnap || !newspaperSnap || !rateSnap)
        return 'N/A';
    const { inAppLineItems, convenienceFeePct, convenienceFeeCap, pricing: { affidavitFeeInCents }, inAppTaxPct } = invoiceSnap.data();
    // TODO: Do we need to do all this just to get the total?
    const dbPricingObject = invoiceDataToDBPricingObject(inAppLineItems, convenienceFeePct, convenienceFeeCap, affidavitFeeInCents, inAppTaxPct, getDistributeFeeSettings(newspaperSnap, rateSnap), rateSnap.data());
    const uiTotal = dbToUICurrency(dbPricingObject.total);
    if (uiTotal) {
        return uiTotal.toFixed(2);
    }
    return 'N/A';
};
// Header: 'Invoice Number'
export const invoiceNumber = (invoiceSnap) => {
    return (invoiceSnap === null || invoiceSnap === void 0 ? void 0 : invoiceSnap.data().invoice_number) || 'N/A';
};
// Header: 'Filer'
export const filer = (user) => {
    if (!user) {
        return 'N/A';
    }
    const userData = user.data();
    return userData
        ? sanitizeValue(getDisplayName(userData.firstName, userData.lastName))
        : 'N/A';
};
// Header: 'Organization'
export const organization = (org) => {
    var _a;
    return sanitizeValue(((_a = org === null || org === void 0 ? void 0 : org.data()) === null || _a === void 0 ? void 0 : _a.name) || 'N/A');
};
// Header: 'Account ID'
export const accountID = (customer, customerOrganization) => {
    const customerAccountNumber = customer === null || customer === void 0 ? void 0 : customer.data().internalID;
    if (customerAccountNumber) {
        return customerAccountNumber;
    }
    const customerOrganizationAccountNumber = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().internalID;
    if (customerOrganizationAccountNumber) {
        return customerOrganizationAccountNumber;
    }
    return 'N/A';
};
// Header: 'Total Cost'
export const totalCost = (invoiceSnap, newspaperSnap, rate) => {
    const { inAppLineItems, convenienceFeePct, convenienceFeeCap, pricing: { affidavitFeeInCents }, inAppTaxPct, appliedBalance } = invoiceSnap.data();
    const DBPricingObject = invoiceDataToDBPricingObject(inAppLineItems, convenienceFeePct, convenienceFeeCap, affidavitFeeInCents, inAppTaxPct, getDistributeFeeSettings(newspaperSnap, rate), rate === null || rate === void 0 ? void 0 : rate.data(), appliedBalance);
    return `$${DBPricingObject.total / 100}`;
};
// Header: 'Invoice Paid Date'
export const invoicePaidDate = (invoiceSnap) => {
    const { finalized_at } = invoiceSnap.data();
    if (!finalized_at)
        return '';
    const invoicePaidDate = firestoreTimestampOrDateToDate(finalized_at);
    return moment(invoicePaidDate).format('MM/DD/YYYY');
};
// Header: Custom ID
export const customID = (notice) => {
    if (!exists(notice))
        return 'N/A';
    const noticeCustomId = notice.data().customId;
    return typeof noticeCustomId === 'string'
        ? sanitizeValue(noticeCustomId)
        : 'N/A';
};
// Header: Preview
export const preview = (notice) => {
    if (!exists(notice))
        return '';
    const { confirmedHtml, text } = notice.data();
    try {
        let previewText = '';
        if (confirmedHtml) {
            previewText = htmlToText.fromString(confirmedHtml, {
                singleNewLineParagraphs: true
            });
        }
        if (text && !previewText) {
            previewText = text;
        }
        // Get the first 100 characters, escape any quotes for CSV and trim excess whitespace
        previewText = sanitizeValue(previewText).slice(0, 100).trim();
        // Wrap preview text in quotes so it show up in one cell in the CSV, even if it has commas, etc.
        return `"${previewText}"`;
    }
    catch (e) {
        const err = e;
        console.error(err.toString());
    }
    return '';
};
// Header: 'Transaction Date'
/**
 * When was the transaction created?
 * @param created when the transaction was created in seconds from the Unix epoch.
 * @returns {string} date of the transaction. Ex. 02/12/2022
 */
export const transactionDate = (created) => {
    if (!created) {
        return '';
    }
    // Stripe 'payout.created' values are numbers
    if (typeof created === 'number') {
        return moment(created * 1000).format('MM/DD/YYYY');
    }
    // Dwolla transfers use an ISO-8601 timestamp string for "created"
    if (typeof created === 'string') {
        return moment(created, moment.ISO_8601).format('MM/DD/YYYY');
    }
    return '';
};
/**
 * This cache contains the organizations that ought to show an extra column in the payouts table.
 *
 * The keys are the organization ID for the newspapers (or parent orgs) that should show an additional column.
 * The values are objects with two properties: `headers` and `getters`. `headers` contains an array of headers for the extra columns; `getters` contains an array of functions that each take in a notice and return a string value that should be included in the table.
 */
export const EXTRA_COLUMN_ORG_CACHE = {
    CXw1sRYePqvhEqoT8U8u: {
        headers: ['Order ID'],
        getters: [
            (noticeSnap) => {
                var _a, _b;
                return `${((_b = (_a = noticeSnap.data()) === null || _a === void 0 ? void 0 : _a.mcClatchy) === null || _b === void 0 ? void 0 : _b.orderID) ||
                    'N/A'}`;
            }
        ]
    }
};
export const extraColumns = (notice, newspaper, parent) => {
    if (!exists(notice)) {
        return [];
    }
    if (exists(newspaper) && EXTRA_COLUMN_ORG_CACHE[newspaper.id]) {
        return EXTRA_COLUMN_ORG_CACHE[newspaper.id].getters.map(getter => getter(notice));
    }
    if (parent && exists(parent) && EXTRA_COLUMN_ORG_CACHE[parent.id]) {
        return EXTRA_COLUMN_ORG_CACHE[parent.id].getters.map(getter => getter(notice));
    }
    return [];
};
export const addExtraColumnHeaders = (standardHeaders, newspaperId, parentId) => {
    if (EXTRA_COLUMN_ORG_CACHE[newspaperId]) {
        EXTRA_COLUMN_ORG_CACHE[newspaperId].headers.forEach(extraHeader => standardHeaders.splice(standardHeaders.length - 1, 0, extraHeader));
    }
    else if (parentId && EXTRA_COLUMN_ORG_CACHE[parentId]) {
        EXTRA_COLUMN_ORG_CACHE[parentId].headers.forEach(extraHeader => standardHeaders.splice(standardHeaders.length - 1, 0, extraHeader));
    }
};
export const csvToHtml = (rows, headers) => {
    let parsedHtml = '<table><tr>';
    headers.forEach(header => (parsedHtml += `<th>${header}</th>`));
    parsedHtml += '</tr>';
    rows.forEach(row => (parsedHtml += `<tr>${row
        .map(c => `<td>${c.replace(/"/g, '')}</td>`)
        .join('')}</tr>`));
    parsedHtml += '</table>';
    return parsedHtml;
};
export const getColumnValueForRow = (row, header, headers) => {
    return row[headers.indexOf(header)];
};
