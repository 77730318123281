var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { CacheManager } from 'lib/integrations/caches';
import React, { useState } from 'react';
import SettingsUpdateContainer from 'routes/settings/publisher/SettingsUpdateContainer';
import { logAndCaptureMessage } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
const commonOptions = [
    {
        label: 'String',
        value: 'string'
    },
    {
        label: 'Number',
        value: 'number'
    }
];
const keyOptions = [
    ...commonOptions,
    { label: 'Notice Type', value: 'notice-type' },
    { label: 'Rate', value: 'rate' }
];
const valueOptions = [
    ...commonOptions,
    { label: 'Boolean', value: 'boolean' },
    { label: 'Strings (Array)', value: 'string-array' },
    { label: 'Numbers (Array)', value: 'number-array' }
];
/**
 * Full-screen form to update a cache entry.
 */
export function CacheCreationForm({ org, closeForm }) {
    const title = 'Create Cache';
    const description = 'Enter a name and select key/value types.';
    const [cacheName, setCacheName] = useState();
    const [cacheId, setCacheId] = useState();
    const [keyType, setKeyType] = useState();
    const [valueType, setValueType] = useState();
    const disabled = !cacheName || !cacheId || !keyType || !valueType;
    return (_jsx(SettingsUpdateContainer, Object.assign({ title: title, saveDisabled: disabled, onSaveClicked: () => __awaiter(this, void 0, void 0, function* () {
            if (disabled) {
                return;
            }
            try {
                const manager = new CacheManager(getFirebaseContext(), org, cacheId);
                yield manager.createCache({
                    name: cacheName,
                    valueType,
                    keyType
                });
            }
            catch (e) {
                logAndCaptureMessage('Failed to create cache', {
                    cacheId,
                    orgId: org.id
                });
            }
            closeForm();
        }), onCloseClicked: closeForm }, { children: _jsxs(CardGridLayout, Object.assign({ header: { title, description } }, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: "cache-name", labelText: "Name", type: "text", placeholder: "ex: Media ID cache", value: cacheName, onChange: setCacheName }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "cache-id", labelText: "ID", type: "text", placeholder: "ex: media-id", value: cacheId, onChange: setCacheId }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "cache-key-type", labelText: "What type of Keys should this cache use to index values?", options: keyOptions, allowUndefined: true, value: keyType, onChange: val => setKeyType(val) }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "cache-value-type", labelText: "What type of Values should this cache store?", options: valueOptions.sort((a, b) => a.label.localeCompare(b.label)), allowUndefined: true, value: valueType, onChange: val => setValueType(val) }) })] })) })));
}
