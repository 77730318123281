import { jsx as _jsx } from "react/jsx-runtime";
// polyfills
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'es6-map/implement';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/main.css';
import * as serviceWorker from './serviceWorker';
import createStore from './store/createStore';
import './i18n';
smoothscroll.polyfill();
const initialState = window.__INITIAL_STATE__;
export const store = createStore(initialState);
if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept();
    }
}
ReactDOM.render(_jsx(App, { store: store }), document.getElementById('root'));
serviceWorker.unregister();
