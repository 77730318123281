import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import FlowChoiceForm from './FlowChoiceForm';
import FuneralHomeCustomerSearch from './FuneralHomeSearch';
function FlowChoiceStep({ chosenCustomer, onChosenCustomerChange }) {
    const [flowChoice, setFlowChoice] = useState(chosenCustomer
        ? CustomerTypeString.FUNERAL_HOME
        : CustomerTypeString.INDIVIDUAL);
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-center p-4" }, { children: [_jsx("h2", Object.assign({ className: "text-xl font-medium mb-4" }, { children: "Customer type" })), _jsxs("div", Object.assign({ className: "grid gap-4 w-full md:w-2/3 lg:w-1/2" }, { children: [_jsx(FlowChoiceForm, { onFlowChoiceChange: o => {
                            setFlowChoice(o);
                            onChosenCustomerChange(undefined);
                        }, flowChoice: flowChoice }), flowChoice === CustomerTypeString.FUNERAL_HOME && (_jsx(FuneralHomeCustomerSearch, { chosenCustomer: chosenCustomer, onChosenCustomerChange: o => {
                            onChosenCustomerChange(o);
                        } }))] }))] })));
}
export default FlowChoiceStep;
