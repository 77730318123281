var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { appendToCurrentParams, updateHistoryNoRerender } from 'utils/urls';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { NoticeStatusType } from 'lib/enums';
import { isPublisher as isUserPublisher } from 'lib/utils/users';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import { push } from 'connected-react-router';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { history } from 'redux/router';
import { selectAuthError, selectIsUserLoggedIn } from 'redux/auth';
import { getFirebaseContext } from 'utils/firebase';
import classNames from 'classnames';
import { canEditNoticeWithoutSupport } from 'utils/permissions';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { ColumnService } from 'lib/services/directory';
import PlacementLoginModal from './PlacementLoginModal';
import { usePlacementAnonymousLogin } from './hooks/usePlacementAnonymousLogin';
import NoticeContentStep from './NoticeContentStep';
import ConfirmPublisherStep from './ConfirmPublisher/ConfirmPublisherStep';
import ConfirmProofStep from './ConfirmProofStep';
import ConfirmFilerStep from './ConfirmFilerStep';
import { isAnonymousFlow, removeSubdomainFromHostIfExists, shouldShowAccountIdFeatures } from './helpers';
import { CONFIRM_PUBLISHER, CONFIRM_PROOF, CONFIRM_AD, CONFIRM_FILER, SCHEDULE_NOTICE, getFirstStep, getFormattedPlacementSteps, getVisiblePlacementSteps } from './helpers/calculatePlacementSteps';
import PlacementNavbar from './PlacementNavbar';
import { useSyncFormat } from './hooks/useSyncFormat';
import PlacementSidebar from './PlacementSidebar';
import { ScheduleNoticeStep } from './ScheduleNoticeStep';
import { useFetchCustomer } from './hooks/useFetchCustomer';
import { selectPlacementSteps } from './placeScrollSelectors';
function PlaceNotice({ isPublisher, user, isAnonymous, push, placementActions, authActions, placement, noticeId }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    usePlacementAnonymousLogin();
    const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
    const authError = useAppSelector(selectAuthError);
    const [current, setCurrent] = useState();
    const [hydrated, setHydrated] = useState(false);
    const [noticeState, setNoticeState] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [simultaneousActivity, setSimultaneousActivity] = useState();
    const [showSubmitFileWithoutFormattingModal, setShowSubmitFileWithoutFormattingModal] = useState(false);
    const [newspaperUnsub, setNewspaperUnsub] = useState();
    const [userEmail, setUserEmail] = useState('');
    const [showEnterPasswordModal, setShowEnterPasswordModal] = useState(false);
    const syncFormat = useSyncFormat(noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper);
    const shouldShowAccountIDInPlacement = shouldShowAccountIdFeatures(syncFormat);
    const placementStepsList = useAppSelector(state => selectPlacementSteps(state, shouldShowAccountIDInPlacement));
    const visibleSteps = getVisiblePlacementSteps(placementStepsList);
    const placementStepsMap = getFormattedPlacementSteps(placementStepsList);
    const stepsLoaded = !!Object.keys(placementStepsMap).length;
    const loading = !placement.draft ||
        !noticeState ||
        !noticeState.notice ||
        (!isUserLoggedIn && !isAnonymousFlow());
    const [showPreview, setShowPreview] = useState(false);
    const { refs, formContainer, navSpacer } = usePlaceScrollRefs();
    const ctx = getFirebaseContext();
    function scrollToRef(ref) {
        // Set timeout ensures that the ref is updated with the correct offset before scrolling
        setTimeout(() => {
            if (!formContainer.current || !ref.current || !navSpacer.current)
                return;
            const navStyle = window.getComputedStyle(navSpacer.current);
            const navHeight = parseFloat(navStyle.getPropertyValue('height'));
            formContainer.current.scrollTo({
                top: ref.current.offsetTop - navHeight - 16,
                behavior: 'smooth'
            });
        }, 0);
    }
    const next = () => {
        if (current) {
            const nextStepId = placementStepsMap[current.id].next;
            if (nextStepId) {
                setCurrent(placementStepsMap[nextStepId]);
            }
        }
        placementActions.saveDraft();
    };
    const previous = () => {
        if (!current) {
            return;
        }
        const previousStepId = placementStepsMap[current.id].previous;
        if (!previousStepId) {
            return;
        }
        setCurrent(placementStepsMap[previousStepId]);
    };
    const onDisabledStepClick = (stepId) => {
        const stepOrder = Object.keys(placementStepsMap);
        const isStepBeforeCurrentStep = stepOrder.indexOf(current ? current.id : '') > stepOrder.indexOf(stepId);
        if (!isStepBeforeCurrentStep)
            return;
        setCurrent(placementStepsMap[stepId]);
    };
    const handleScrollUI = () => {
        var _a, _b;
        if (!current || !current.id || !placement || !placement.original)
            return;
        updateHistoryNoRerender(`/place/${placement.original.id}?${appendToCurrentParams('step', current.id).toString()}`);
        const ref = refs[current.id].container;
        if (ref) {
            if (current.id === CONFIRM_AD &&
                refs[current.id] &&
                refs[current.id].step &&
                ((_a = refs[current.id].step) === null || _a === void 0 ? void 0 : _a.current) &&
                ((_b = refs[current.id].step) === null || _b === void 0 ? void 0 : _b.current.animateIn)) {
                scrollToRef(ref);
                const { step } = refs[current.id];
                step === null || step === void 0 ? void 0 : step.current.animateIn();
            }
            else {
                scrollToRef(ref);
            }
        }
    };
    const urlStep = getLocationParams().get('step');
    useEffect(() => {
        var _a;
        if (!current || !refs)
            return;
        if (placement && placement.postWithoutFormatting) {
            scrollToRef(refs[current === null || current === void 0 ? void 0 : current.id].container);
        }
        if (((_a = placement === null || placement === void 0 ? void 0 : placement.currentStep) === null || _a === void 0 ? void 0 : _a.id) !== (current === null || current === void 0 ? void 0 : current.id)) {
            placementActions.setCurrentStep(current);
        }
    }, [current]);
    useEffect(() => {
        if (!stepsLoaded ||
            !hydrated ||
            (placement.newspaper && !(noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper))) {
            return;
        }
        const startStep = getFirstStep({ placementStepsMap, urlStep });
        setCurrent(startStep);
    }, [stepsLoaded, (_a = noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper) === null || _a === void 0 ? void 0 : _a.id, hydrated]);
    useEffect(() => {
        !!current && handleScrollUI();
    }, [current, !!(placement === null || placement === void 0 ? void 0 : placement.original)]);
    useEffect(() => {
        if ((current === null || current === void 0 ? void 0 : current.id) === CONFIRM_AD)
            setShowPreview(true);
    }, [current === null || current === void 0 ? void 0 : current.id]);
    useEffect(() => {
        if (!isUserLoggedIn || authError)
            return;
        placementActions.hydrateNoticeData(noticeId);
        setHydrated(true);
    }, [isUserLoggedIn]);
    useEffect(() => {
        if (placement === null || placement === void 0 ? void 0 : placement.placementError) {
            setSimultaneousActivity({
                id: 'draft-submitted',
                header: 'Placement Error',
                body: (placement === null || placement === void 0 ? void 0 : placement.placementError.message) ||
                    `An error occurred while placing the notice. Please try opening a new tab and contact help@column.us if the problem persists.`,
                onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                    history.push('/notices/');
                    window.location.reload();
                }),
                buttonText: 'Back to Notices'
            });
        }
    }, [placement === null || placement === void 0 ? void 0 : placement.placementError]);
    useEffect(() => {
        if (!placement.original || !noticeState)
            return;
        const handleSimultaneousActivity = () => __awaiter(this, void 0, void 0, function* () {
            if (!placement.original || !noticeState)
                return;
            if (placement.draftSnap && !exists(placement.draftSnap)) {
                yield new Promise(resolve => {
                    setTimeout(resolve, 8000);
                });
                const originalNotice = yield ctx
                    .userNoticesRef()
                    .doc(placement.original.id)
                    .get();
                if (exists(originalNotice) &&
                    originalNotice.data().noticeStatus ===
                        NoticeStatusType.cancelled.value) {
                    // notice cancelled
                    setSimultaneousActivity({
                        id: 'notice-cancelled',
                        header: 'Notice Cancelled',
                        body: `Another user just cancelled this notice.
             You will no longer be able to make edits. 
             For additional help, please reach out to customer support.`,
                        onConfirm: () => push(`/notice/${originalNotice.id}`),
                        buttonText: 'Back To Notice Details'
                    });
                }
                // invoice created
                else if (noticeState.newspaper &&
                    noticeState.notice &&
                    !noticeState.newspaper.data().allowedNotices &&
                    exists(originalNotice) &&
                    originalNotice.data().invoice) {
                    setSimultaneousActivity({
                        id: 'invoice-created',
                        header: 'Invoice Created',
                        body: `The newspaper just submitted an invoice for this notice.
             You are no longer able to make edits, as edits may affect price. 
             Please reach out to customer support for help if needed.`,
                        onConfirm: () => {
                            placementActions.resetState();
                            push(`/notice/${originalNotice.id}`);
                        },
                        buttonText: 'Back To Notice Details'
                    });
                }
                // draft updated
            }
            else {
                setSimultaneousActivity({
                    id: 'notice-updated',
                    header: 'Notice Update',
                    body: `This notice has been updated by another user. 
          In order to continue with your edits, you must refresh 
          this draft with the latest changes.`,
                    onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                        var _a, _b;
                        const original = yield ((_a = placement.original) === null || _a === void 0 ? void 0 : _a.get());
                        if (!exists(original)) {
                            return;
                        }
                        const { drafts } = original.data();
                        const otherDrafts = drafts
                            ? drafts.filter(draft => { var _a; return draft.id !== ((_a = placement.draftSnap) === null || _a === void 0 ? void 0 : _a.id); })
                            : [];
                        yield original.ref.update({
                            drafts: otherDrafts
                        });
                        yield ((_b = placement.draftSnap) === null || _b === void 0 ? void 0 : _b.ref.delete());
                        window.location.reload();
                    }),
                    buttonText: 'Refresh Draft'
                });
            }
        });
        if (placement.draftSnap &&
            (!(placement.draftSnap && exists(placement.draftSnap)) ||
                placement.draftSnap.data().inactive)) {
            void handleSimultaneousActivity();
        }
    }, [placement.draftSnap, placement.original]);
    /**
     * TODO: Move data fetching to an async thunk action and consider merging with hydrateNoticeData
     * and storing snapshots in Redux state.
     */
    const fetchNoticeData = (placement) => __awaiter(this, void 0, void 0, function* () {
        var _o;
        if (!placement.draft)
            return;
        const draftSnap = yield placement.draft.get();
        if (!exists(draftSnap))
            return;
        const { newspaper, adTemplate, rate, filer } = placement;
        let rateSnap;
        let adTemplateSnap;
        const newspaperSnap = yield (newspaper === null || newspaper === void 0 ? void 0 : newspaper.get());
        if (user &&
            !isPublisher &&
            exists(newspaperSnap) &&
            ((_o = draftSnap.data().publicationDates) === null || _o === void 0 ? void 0 : _o.length) > 0 &&
            placement.editing) {
            const canEditNotice = canEditNoticeWithoutSupport(draftSnap, user, newspaperSnap);
            /**
             * NOTE: editRunDatesAfterInvoicePaid is a confusing field name.
             * It doesn't appear to actually control whether "run dates" can be edited
             * and it doesn't seem to be related to whethere the invoice is paid or not.
             * It appears to control whether an advertiser can edit a notice after an
             * invoice is created in general, so here we check if the notice has an invoice
             * and if editing after an invoice is created is enabled.
             */
            const noticeHasInvoiceAndCannotEdit = canEditNotice &&
                draftSnap.data().invoice &&
                !newspaperSnap.data().editRunDatesAfterInvoicePaid;
            // If the advertiser is not allowed to edit the notice, redirect them to place a new notice
            if (!canEditNotice || noticeHasInvoiceAndCannotEdit) {
                return push(`/place`);
            }
        }
        if (newspaper && exists(newspaperSnap)) {
            /**
             * move it to the saga
             * cancel the subscription
             */
            if (draftSnap.data() &&
                draftSnap.data().noticeStatus &&
                !isPublisher &&
                !(noticeState === null || noticeState === void 0 ? void 0 : noticeState.newspaper)) {
                const unsub = newspaper.onSnapshot(doc => {
                    var _a;
                    if (!simultaneousActivity && !((_a = doc.data()) === null || _a === void 0 ? void 0 : _a.advertiserEditingEnabled)) {
                        setSimultaneousActivity({
                            header: 'Editing Disabled',
                            body: `The newspaper has disabled editing. 
                You are no longer able to make edits. 
                Please reach out to customer support for help if needed.`,
                            buttonText: 'Back to Notice Details',
                            onConfirm: () => {
                                var _a;
                                placementActions.resetState();
                                push(`/notice/${(_a = placement.original) === null || _a === void 0 ? void 0 : _a.id}`);
                            }
                        });
                    }
                });
                setNewspaperUnsub(unsub);
            }
        }
        if (adTemplate) {
            adTemplateSnap = yield adTemplate.get();
        }
        else if (exists(newspaperSnap)) {
            placementActions.setTemplate(newspaperSnap.data().adTemplate);
        }
        if (!filer && draftSnap) {
            // todo tech-debt ticket coredev-1437
            // to move the isAnonymous check somewhere less risky
            if (draftSnap.data().userId && isAnonymous === false) {
                try {
                    const user = yield ctx.usersRef().doc(draftSnap.data().userId).get();
                    if (exists(user) && !isUserPublisher(user)) {
                        placementActions.setFiler(user.ref);
                    }
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
        if (rate)
            rateSnap = yield rate.get();
        const filerSnap = yield (filer === null || filer === void 0 ? void 0 : filer.get());
        const state = {
            notice: draftSnap,
            newspaper: exists(newspaperSnap) ? newspaperSnap : undefined,
            rate: exists(rateSnap) ? rateSnap : undefined,
            adTemplate: exists(adTemplateSnap) ? adTemplateSnap : undefined,
            filer: exists(filerSnap) ? filerSnap : undefined
        };
        setNoticeState(state);
    });
    useEffect(() => {
        if (!placement.draft || !placement.original || !hydrated)
            return;
        void fetchNoticeData(placement);
        return () => {
            if (newspaperUnsub) {
                newspaperUnsub();
                setNewspaperUnsub(undefined);
            }
        };
    }, [
        (_b = placement.draft) === null || _b === void 0 ? void 0 : _b.id,
        (_c = placement.newspaper) === null || _c === void 0 ? void 0 : _c.id,
        (_d = placement.adTemplate) === null || _d === void 0 ? void 0 : _d.id,
        (_e = placement.rate) === null || _e === void 0 ? void 0 : _e.id,
        (_f = placement.filer) === null || _f === void 0 ? void 0 : _f.id,
        placement.proofStoragePath,
        (_h = (_g = placement.draftSnap) === null || _g === void 0 ? void 0 : _g.data()) === null || _h === void 0 ? void 0 : _h.noticeType,
        hydrated
    ]);
    /**
     * Show a Modal to block the placement if customer is on hold.
     * This will only be visible to advertiser at the time of new placement when the placment step is not confirm newspaper
     */
    const customer = useFetchCustomer();
    const isCustomerAccountOnHold = !isPublisher &&
        !placement.editing &&
        (current === null || current === void 0 ? void 0 : current.id) !== CONFIRM_PUBLISHER &&
        noticeState &&
        exists(noticeState.newspaper) &&
        customer &&
        customer.data().isAccountOnHold;
    if (loading) {
        return _jsx(LoadingState, { context: { service: ColumnService.WEB_PLACEMENT } });
    }
    const renderConfirmPublisherStep = () => {
        return (_jsx("div", Object.assign({ ref: refs[CONFIRM_PUBLISHER].container }, { children: _jsx(ConfirmPublisherStep, { next: next, onDisabledStepClick: onDisabledStepClick, publisherOrganization: noticeState.newspaper }) })));
    };
    const handleNoticeWithoutAdProof = () => {
        placementActions.setPostWithoutFormatting(true);
        setShowSubmitFileWithoutFormattingModal(false);
    };
    const renderSubmitFileWithoutFormattingModal = () => (_jsx(FreeformCModal, Object.assign({ setOpen: setShowSubmitFileWithoutFormattingModal, header: "Submit file without formatting?", id: "submit-without-formatting-modal", body: `You can opt to submit your file directly to the newspaper instead of Column formatting your notice. If you proceed, you will not receive a proof until the newspaper designs your notice manually.` }, { children: _jsxs("section", { children: [_jsx("button", Object.assign({ type: "button", className: "bg-blue-200 border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-50", id: "submit-without-formatting", onClick: handleNoticeWithoutAdProof }, { children: "Submit Without Formatting" })), _jsx("button", Object.assign({ type: "button", className: "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-900 hover:text-blue-800 bg-transparent transition duration-150 ease-in-out", id: "cancel-submit-without-formatting", onClick: () => setShowSubmitFileWithoutFormattingModal(false) }, { children: "Cancel" }))] }) })));
    const renderContentStep = () => {
        return (_jsx("div", Object.assign({ ref: refs[CONFIRM_AD].container }, { children: _jsx(NoticeContentStep, { placementActions: placementActions, ref: refs[CONFIRM_AD].step, id: CONFIRM_AD, activeStepId: current === null || current === void 0 ? void 0 : current.id, notice: noticeState.notice, newspaper: noticeState.newspaper, setUploading: setUploading, next: () => {
                    if (!placement.continueWithLargeFile &&
                        !placement.postWithoutFormatting) {
                        placementActions.setFormattingError(null);
                    }
                    placementActions.saveDraft();
                    if (current === null || current === void 0 ? void 0 : current.next) {
                        setCurrent(placementStepsMap[current.next]);
                    }
                }, previous: placementStepsMap[CONFIRM_AD].previous ? previous : undefined, onDisabledStepClick: onDisabledStepClick, showSubmitFileWithoutFormattingModal: showSubmitFileWithoutFormattingModal, renderSubmitFileWithoutFormattingModal: renderSubmitFileWithoutFormattingModal }) })));
    };
    const renderConfirmProof = () => (_jsxs("div", Object.assign({ id: CONFIRM_PROOF, ref: refs[CONFIRM_PROOF].container }, { children: [_jsx(ConfirmProofStep, { id: CONFIRM_PROOF, newspaper: noticeState.newspaper, notice: noticeState.notice, isPublisher: isPublisher, user: user, onDisabledStepClick: onDisabledStepClick, editing: placement.editing, placementActions: placementActions }), _jsx("div", { className: "flex w-full h-16 items-center mb-6 justify-end" })] })));
    const renderFilerStep = () => (_jsx("div", Object.assign({ ref: refs[CONFIRM_FILER].container }, { children: _jsx(ConfirmFilerStep, { publisherOrganization: noticeState.newspaper, filer: noticeState.filer, syncFormat: syncFormat, setShowEnterPasswordModal: setShowEnterPasswordModal, next: next, previous: placementStepsMap[CONFIRM_FILER].previous ? previous : undefined, onDisabledStepClick: onDisabledStepClick, placementActions: placementActions, authActions: authActions, setUserEmail: setUserEmail }) })));
    return (_jsxs("div", Object.assign({ className: 'fixed min-w-full bg-gray-80 h-full w-screen z-modal' }, { children: [_jsx(PlacementNavbar, { steps: visibleSteps, hidePrice: !!((_k = (_j = noticeState.rate) === null || _j === void 0 ? void 0 : _j.data()) === null || _k === void 0 ? void 0 : _k.hidePreview), exitModalMessage: 'Your notice will be saved under "Draft Notices" so you can easily pick up where you left off.' }), _jsx("div", Object.assign({ className: "relative w-full h-full flex flex-nowrap" }, { children: _jsxs("div", Object.assign({ className: classNames('overflow-y-scroll', {
                        'w-full': !showPreview,
                        'w-2/3': showPreview
                    }), id: "place-notice-form-container", ref: formContainer }, { children: [_jsxs("div", Object.assign({ style: { minWidth: '600px', transition: 'width .5s' }, className: classNames('mx-auto space-y-12', {
                                'w-3/5': !showPreview,
                                'w-11/12': showPreview
                            }) }, { children: [_jsx("div", { style: { height: '72px' }, id: 'nav-spacer', ref: navSpacer }), placementStepsMap[CONFIRM_PUBLISHER] &&
                                    renderConfirmPublisherStep(), placementStepsMap[CONFIRM_FILER] && renderFilerStep(), placementStepsMap[CONFIRM_AD] && renderContentStep(), placementStepsMap[SCHEDULE_NOTICE] && (_jsx("div", Object.assign({ ref: refs[SCHEDULE_NOTICE].container }, { children: _jsx(ScheduleNoticeStep, { publisherOrganization: noticeState.newspaper, notice: noticeState.notice, filer: noticeState.filer, next: next, previous: previous, onDisabledStepClick: onDisabledStepClick }) }))), placementStepsMap[CONFIRM_PROOF] && renderConfirmProof()] })), _jsx(PlacementSidebar, { showContent: showPreview, setShowContent: setShowPreview, rate: noticeState.rate, adTemplate: (_l = noticeState.adTemplate) !== null && _l !== void 0 ? _l : null, newspaper: noticeState.newspaper, uploading: uploading }), simultaneousActivity && (_jsx(FreeformCModal, Object.assign({ id: simultaneousActivity.id, header: simultaneousActivity.header, body: simultaneousActivity.body }, { children: _jsx("section", { children: _jsx("button", Object.assign({ id: `confirm-${simultaneousActivity.id}`, onClick: () => simultaneousActivity.onConfirm(), className: "bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium hover:bg-blue-600 leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 hover:text-white transition w-32" }, { children: simultaneousActivity.buttonText })) }) }))), isAnonymousFlow() && showEnterPasswordModal && (_jsx(PlacementLoginModal, { onClose: () => setShowEnterPasswordModal(false), email: userEmail })), isCustomerAccountOnHold && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => {
                                push(`/notices`);
                            }, header: "Your account is on hold", primaryButtonText: "Select different newspaper", tertiaryButtonText: "Cancel", onSubmit: () => {
                                // If there is a subdomain, we need to replace that with column host only
                                const subdomain = removeSubdomainFromHostIfExists(window.location.host);
                                if (!subdomain || window.location.host.includes('web.app')) {
                                    window.location.replace('/place/');
                                }
                                else {
                                    window.location.replace(`${window.location.protocol}//${subdomain}/place/`);
                                }
                            }, noExitOutsideModal: true }, { children: _jsx("p", Object.assign({ className: "py-5" }, { children: `${(_m = noticeState.newspaper) === null || _m === void 0 ? void 0 : _m.data().name} has put your account on hold. Please contact the publisher for more information, or place your notice in a different publication.

` })) })))] })) }))] })));
}
function usePlaceScrollRefs() {
    const refs = {
        [CONFIRM_PUBLISHER]: {
            container: useRef(null)
        },
        [CONFIRM_FILER]: {
            container: useRef(null),
            step: useRef(null)
        },
        [CONFIRM_AD]: {
            container: useRef(null),
            step: useRef(null)
        },
        [CONFIRM_PROOF]: {
            container: useRef(null),
            step: useRef(null)
        },
        [SCHEDULE_NOTICE]: {
            container: useRef(null)
        }
    };
    const formContainer = useRef();
    const navSpacer = useRef();
    return { refs, formContainer, navSpacer };
}
export default connect(null, { push })(PlaceNotice);
