import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { sumFirstI } from './helpers';
import { InvoiceFormCell } from './InvoiceFormCell';
import { InvoiceFormLineItemRow } from './InvoiceFormLineItemRow';
export default function InvoiceFormTable({ handleChange, values, diffValues, currency, preventPricingModification }) {
    const tableRows = values.length > 0
        ? values.map((lineItem, index) => {
            var _a;
            const runningTotal = sumFirstI(values.map(value => value.amount), index);
            let isInvoicePubLineItemMissingInNoticePubDates = false;
            if ((_a = diffValues === null || diffValues === void 0 ? void 0 : diffValues.invoicePubLineItemDiffValues) === null || _a === void 0 ? void 0 : _a.length) {
                isInvoicePubLineItemMissingInNoticePubDates = diffValues.invoicePubLineItemDiffValues.some(diffValue => {
                    return columnObjectsAreEqual(diffValue, lineItem);
                });
            }
            return (_jsx(InvoiceFormLineItemRow, { lineItem: lineItem, total: runningTotal, currency: currency, handleChange: amountInCents => handleChange(index, amountInCents), preventPricingModification: preventPricingModification !== null && preventPricingModification !== void 0 ? preventPricingModification : false, highlightCorrespondingNoticePubDateDoesNotExist: isInvoicePubLineItemMissingInNoticePubDates }, `row-${index}`));
        })
        : null;
    return (_jsx("div", Object.assign({ className: "overflow-x-auto shadow sm:rounded-lg" }, { children: _jsxs("table", Object.assign({ className: "bg-white min-w-full text-gray-800 divide-y divide-gray-200" }, { children: [_jsx("thead", Object.assign({ className: "text-xs font-bold uppercase tracking-wider" }, { children: _jsxs("tr", { children: [_jsx(InvoiceFormCell, { children: "Quantity" }), _jsx(InvoiceFormCell, { children: "Description" }), _jsx(InvoiceFormCell, { children: "Price" }), _jsx(InvoiceFormCell, { children: "Total" })] }) })), _jsx("tbody", Object.assign({ className: "text-sm whitespace-no-wrap divide-y divide-gray-200" }, { children: tableRows }))] })) })));
}
