var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import moment from 'moment';
import { DEFAULT_TEMPLATE_ID, DEFAULT_TEMPLATE_ID_V2 } from 'lib/constants';
import { getFirebaseContext } from 'utils/firebase';
import { TemplateTypes } from 'lib/types/templates';
import LoadingState from 'components/LoadingState';
import { Badge } from 'lib/components/Badge';
import { exists } from 'lib/types';
import { TableLayout } from 'lib/components/TableLayout';
import { getPublisherOrgOrderTemplate, isTemplateShared } from 'lib/utils/templates';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { deterministicStringHash } from 'lib/helpers';
import { apiPostWithParams } from 'api/typed';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import TemplateSettingsDrawer from './templateSettingsDrawer';
import TemplateUpdateForm from './templateSettingsUpdateForm';
import { usePublisherOrgTemplates } from '../../rates/hooks/usePublisherOrgTemplates';
import BulkDownloadSettings from './bulkDownloadSettings';
/**
 * Settings page for newspaper templates
 */
export default function AdTemplateTable({ activeOrganization, productLine }) {
    const [showBulkDownloadsForm, setShowBulkDownloadsForm] = useState(false);
    const isNoticeProduct = productLine === Product.Notice;
    const { activeTemplates, loading: orgTemplatesLoading } = usePublisherOrgTemplates(activeOrganization.ref);
    const [drawerTemplate, setDrawerTemplate] = useState();
    const [editedTemplateRef, setEditedTemplateRef] = useState();
    const [editedTemplate, setEditedTemplate] = useState();
    const { isLoading: orgOrderTemplatesLoading, value: activeOrderTemplateRef } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const { response } = yield getPublisherOrgOrderTemplate(getFirebaseContext(), activeOrganization.ref, productLine);
            return response;
        }),
        dependencies: [activeOrganization.id]
    });
    const activeOrderTemplate = useFirestoreDocumentListener(activeOrderTemplateRef || undefined);
    const isLdFlagEnabledForCustomHeader = useBooleanFlag(LaunchDarklyFlags.ENABLE_CUSTOMISED_HEADERS);
    const templateDocId = isLdFlagEnabledForCustomHeader
        ? DEFAULT_TEMPLATE_ID_V2
        : DEFAULT_TEMPLATE_ID;
    const globalDefaultTemplate = useFirestoreSnapshot(getFirebaseContext().adTemplatesRef().doc(templateDocId));
    if (isNoticeProduct ? orgTemplatesLoading : orgOrderTemplatesLoading) {
        return _jsx(LoadingState, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(TableLayout, { filterable: {
                    shouldShowTableItem: (template, filter) => template.data().name.toLowerCase().includes(filter.toLowerCase())
                }, creatable: isNoticeProduct
                    ? {
                        onCreate: () => {
                            if (!exists(globalDefaultTemplate)) {
                                console.error('Global default template does not exist!');
                                return;
                            }
                            setEditedTemplate({
                                organization: activeOrganization.ref,
                                downloadUrl: globalDefaultTemplate.data().downloadUrl,
                                name: `${activeOrganization.data().name} Template -- ${moment().format('MM_DD_YY')} -- ${deterministicStringHash(Date.now().toString())}`,
                                type: TemplateTypes.legal,
                                columnsWide: 1
                            });
                        },
                        createButtonText: 'Add Template'
                    }
                    : undefined, header: {
                    title: 'Template Settings',
                    subtitle: isNoticeProduct
                        ? 'Edit the InDesign template used to generate your notices.'
                        : 'Edit the template used to generate your orders.'
                }, configurable: isNoticeProduct
                    ? {
                        buttonText: 'Bulk Downloads',
                        onClick: () => setShowBulkDownloadsForm(true)
                    }
                    : undefined, archivable: isNoticeProduct
                    ? {
                        renderWarning: () => ({
                            header: 'Archive Template',
                            body: "Once archived, templates can no longer be recovered in the app. Any notices that were created using this template will continue using this template until they are edited. Any customer organizations or notice types that use this template will instead use the newspaper's default template. Do you want to archive?"
                        }),
                        onArchive: (template) => __awaiter(this, void 0, void 0, function* () {
                            return yield apiPostWithParams(`templates/${template.id}/archive`, {});
                        }),
                        isArchiveDisabled: template => { var _a; return template.id === ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id); }
                    }
                    : undefined, clickable: isNoticeProduct
                    ? {
                        onClick: template => setDrawerTemplate(template)
                    }
                    : undefined, data: isNoticeProduct
                    ? activeTemplates
                    : activeOrderTemplate
                        ? [activeOrderTemplate]
                        : [], loading: orgTemplatesLoading, columns: ['Template Name'], editable: {
                    onEdit: template => {
                        setEditedTemplate(template.data());
                        setEditedTemplateRef(template.ref);
                    }
                }, renderRow: template => {
                    var _a;
                    return (_jsxs("td", { children: [_jsx("span", { children: template.data().name }), template.id === ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id) && (_jsx("span", Object.assign({ className: "ml-2" }, { children: _jsx(Badge, Object.assign({ status: "success" }, { children: "Default Template" })) }))), isTemplateShared(template.data()) && (_jsx("span", Object.assign({ className: "ml-2" }, { children: _jsx(Badge, Object.assign({ status: "info" }, { children: "Shared" })) })))] }));
                }, id: "ad-template-settings" }), drawerTemplate && (_jsx(TemplateSettingsDrawer, { value: drawerTemplate, onClose: () => setDrawerTemplate(undefined), onEdit: () => {
                    setEditedTemplate(drawerTemplate.data());
                    setEditedTemplateRef(drawerTemplate.ref);
                    setDrawerTemplate(undefined);
                } })), editedTemplate && (_jsx(TemplateUpdateForm, { activeOrganization: activeOrganization, templateRef: editedTemplateRef, template: editedTemplate, isNoticeProduct: isNoticeProduct, onClose: () => {
                    setEditedTemplateRef(undefined);
                    setEditedTemplate(undefined);
                } })), showBulkDownloadsForm && (_jsx(BulkDownloadSettings, { activeOrganization: activeOrganization, onClose: () => setShowBulkDownloadsForm(false) }))] }));
}
