var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getContextKey } from 'sagas/AuthSaga';
import api from 'api';
import { logError } from 'utils/logger';
export function useFetchSubdomainAssociatedPapers(product, stateFilter) {
    const [relatedPublisherOptions, setRelatedPublisherOptions] = useState();
    const [autoSelectedPaper, setAutoSelectedPaper] = useState();
    const subdomain = getContextKey();
    const applyStateFilter = (paperOptions) => {
        return stateFilter
            ? paperOptions.filter(paper => paper.state === stateFilter)
            : paperOptions;
    };
    const fetchData = () => __awaiter(this, void 0, void 0, function* () {
        // We only need to fetch the papers (call the api) once. Filtering by state is applied on the already fetched papers
        if (relatedPublisherOptions) {
            const filteredPapers = applyStateFilter(relatedPublisherOptions);
            setRelatedPublisherOptions(filteredPapers);
            return;
        }
        // Fetch related papers
        const { response, error } = yield api.post(`organizations/related-papers`, { product, subdomain: subdomain !== null && subdomain !== void 0 ? subdomain : '' });
        if (!response) {
            logError(error);
            return;
        }
        const { relatedPapers, subdomainPaper, publisherActivePaper } = response;
        // Apply state filter if any
        const filteredPapers = applyStateFilter(relatedPapers);
        // Sort the papers alphabetically
        const sortedRelatedPublisherOptions = filteredPapers.sort((a, b) => a.label.replace('The ', '').localeCompare(b.label.replace('The ', '')));
        setRelatedPublisherOptions(sortedRelatedPublisherOptions);
        setAutoSelectedPaper(publisherActivePaper || subdomainPaper);
    });
    const { isLoading } = useAsyncEffect({
        fetchData,
        dependencies: [subdomain, stateFilter]
    });
    return {
        loading: isLoading,
        relatedPublisherOptions: relatedPublisherOptions
            ? relatedPublisherOptions.map(paper => ({
                label: paper.label,
                value: paper.value
            }))
            : [],
        autoSelectedPaper: autoSelectedPaper
            ? { label: autoSelectedPaper.label, value: autoSelectedPaper.value }
            : null
    };
}
