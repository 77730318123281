/**
 * Generate a random UUID without including a library.
 * From https://www.geeksforgeeks.org/how-to-create-a-guid-uuid-in-javascript/
 * (Then chatgpt used to get rid of the bitwise operators)
 */
export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.floor(Math.random() * 16);
        const v = c === 'x' ? r : (r % 4) + 8;
        return v.toString(16);
    });
}
