var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { INDESIGN_SERVICE_URL } from './constants';
// When polling for a response from Memorystore, we will poll
// a maximum of 13 times (while exponentially backing off) before giving up.
// With an initial wait period of 100ms, this will give InDesign worker a maximum
// of ~6 minutes to send the response to Memorystore. This is an overly generous timeout.
const MAXIMUM_POLL_ATTEMPTS = 13;
export const callIndesignServer = (url, request, options) => __awaiter(void 0, void 0, void 0, function* () {
    const reqOptions = options || {};
    // For many years the InDesign Server has used an 'ePandoc' executable
    // which is a fork of Pandoc which included a specific table styling fix
    // we needed. Pandoc 3.0 includes this fix as well as the changes we need
    // for Logos in Liners, so this header enables us to use "standard" Pandoc
    // instead of our fork.
    reqOptions.headers = Object.assign(Object.assign({}, (reqOptions.headers || {})), { 'x-column-pandoc-version': 'standard' });
    return axios.post(url, request, reqOptions);
});
/**
 * Gets the buffer from the response
 * @param {string} jobID The job ID
 * @param endpointName The name of the endpoint to which the job was submitted
 */
const getBufferFromResponse = (jobID, endpointName) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${INDESIGN_SERVICE_URL}/jobs/${endpointName}/${jobID}`;
    const response = yield axios.get(url, {
        params: {
            binaryBuffer: true
        },
        responseType: 'arraybuffer'
    });
    return response.data;
});
/**
 * Polls the Indesign Service for a response using an exponential backoff until
 * the job is complete.
 * @param {string} jobID The job ID
 * @param endpointName The name of the endpoint to which the job was submitted
 * @param {AxiosRequestConfig} options The request options
 */
export const pollForResponse = (jobID, endpointName, options) => __awaiter(void 0, void 0, void 0, function* () {
    let response;
    let retryCount = 0;
    const initialInterval = 100;
    const maxInterval = 90000;
    const url = `${INDESIGN_SERVICE_URL}/jobs/${endpointName}/${jobID}`;
    do {
        // eslint-disable-next-line no-await-in-loop
        response = yield axios.get(url);
        if (response.status !== 200 || response.data.complete === undefined) {
            throw new Error(`Unexpected response from Indesign Service: ${JSON.stringify(response.data)}`);
        }
        if (response.data.complete !== true && retryCount < MAXIMUM_POLL_ATTEMPTS) {
            // If job not complete yet, apply exponential backoff before the next poll
            const nextInterval = Math.min(initialInterval * Math.pow(2, retryCount), maxInterval);
            retryCount++;
            // eslint-disable-next-line no-await-in-loop,no-promise-executor-return
            yield new Promise(resolve => setTimeout(resolve, nextInterval));
        }
        else {
            // if the response is complete and the responseType is arraybuffer,
            // do another request to get the buffer
            if ((options === null || options === void 0 ? void 0 : options.responseType) === 'arraybuffer') {
                // eslint-disable-next-line no-await-in-loop
                const buffer = yield getBufferFromResponse(jobID, endpointName);
                return {
                    data: buffer
                };
            }
            return {
                data: response.data.result
            };
        }
    } while (true);
});
export const callInDesignService = (url, request, options) => __awaiter(void 0, void 0, void 0, function* () {
    const reqOptions = options || {};
    const res = yield axios.post(url, request);
    const urlParts = url.split('/');
    const endpointName = urlParts[urlParts.length - 1];
    if (!res || !res.data || !res.data.jobID) {
        throw new Error('No Job ID returned from Indesign Service');
    }
    return pollForResponse(res.data.jobID, endpointName, reqOptions);
});
