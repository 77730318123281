import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MadlibInput } from 'routes/madlib/elements/MadlibInput';
import { MadlibDateInput } from 'routes/madlib/elements/MadlibDateInput';
import { MadlibHint } from 'routes/madlib/elements/MadlibHint';
import { MadlibMultipleChoiceInput } from 'routes/madlib//elements/MadlibMultipleChoiceInput';
import { safeStringify } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import { logAndCaptureException } from 'utils';
import { EHandlebars } from 'lib/headers_footers/shared';
import { ColumnService } from 'lib/services/directory';
import { registerHandlebarsHelpers } from '../helpers/handlebars';
import { MadlibTextArea } from '../elements/MadlibTextArea';
import { madlibAtob } from '../helpers/encoding';
// Define custom elements
if (!customElements.get(MadlibInput.TAG_NAME)) {
    customElements.define(MadlibInput.TAG_NAME, MadlibInput);
}
if (!customElements.get(MadlibDateInput.TAG_NAME)) {
    customElements.define(MadlibDateInput.TAG_NAME, MadlibDateInput);
}
if (!customElements.get(MadlibHint.TAG_NAME)) {
    customElements.define(MadlibHint.TAG_NAME, MadlibHint);
}
if (!customElements.get(MadlibMultipleChoiceInput.TAG_NAME)) {
    customElements.define(MadlibMultipleChoiceInput.TAG_NAME, MadlibMultipleChoiceInput);
}
if (!customElements.get(MadlibTextArea.TAG_NAME)) {
    customElements.define(MadlibTextArea.TAG_NAME, MadlibTextArea);
}
function MadlibEditor({ rawTemplate, setRenderedHtml, madlibData, onTemplateDataChange, setValidationResults, handlebarData, ignoreStyling }) {
    const [innerHtml, setInnerHtml] = useState('');
    const css = {
        // Hyphens
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        hyphens: 'auto',
        // Zoom
        transform: 'scale(1.5)',
        transformOrigin: '0 0',
        // Padding at the bottom to give space for hints
        paddingTop: '0.5em',
        paddingBottom: '2em',
        // Border styling
        borderRight: '2px dotted rgba(0, 0, 0, 0.2)',
        borderLeft: '2px dotted rgba(0, 0, 0, 0.2)',
        // Height of editor
        minHeight: '70%',
        // Width to show dotted line
        width: '400px',
        // Editor font styling
        fontSize: '10px',
        lineHeight: '1.5'
    };
    const template = useMemo(() => {
        return EHandlebars.compile(rawTemplate);
    }, [rawTemplate, safeStringify(handlebarData)]);
    const getMadlibState = () => {
        const els = [
            ...document.querySelectorAll(MadlibInput.TAG_NAME),
            ...document.querySelectorAll(MadlibMultipleChoiceInput.TAG_NAME),
            ...document.querySelectorAll(MadlibDateInput.TAG_NAME),
            ...document.querySelectorAll(MadlibTextArea.TAG_NAME)
        ];
        const newTemplateData = {};
        const fields = [];
        for (const el of els) {
            // All Madlib inputs have these properties
            const { key, hint, value: encodedValue } = el;
            // These properties are only present on some Madlib inputs
            const placeholder = 'placeholder' in el ? el.placeholder : undefined;
            const encoding = 'encoding' in el ? el.encoding : undefined;
            const validate = 'validate' in el ? el.validate : undefined;
            if (!key) {
                console.warn('madlib-input without key', el);
                continue;
            }
            const isValid = validate ? validate.bind(el)() : true;
            // The encoding only applies to how we pass the value to the element
            // and how it handles it internally. We always store the decoded value
            // outside of the component
            const value = encoding === 'base64' ? madlibAtob(encodedValue) : encodedValue;
            fields.push({
                varName: key,
                value,
                hint: hint || '',
                placeholder: placeholder || '',
                isValid
            });
            newTemplateData[key] = value;
        }
        // TODO: Should we be preserving keys here that are no longer in the template?
        const previousTemplateData = {};
        for (const [key, value] of Object.entries(madlibData.templateData)) {
            if (value) {
                previousTemplateData[key] = value;
            }
        }
        return {
            fields,
            templateData: Object.assign(Object.assign({}, previousTemplateData), newTemplateData)
        };
    };
    /**
     * Renders the madlib template with the given data both from our madlib inputs and
     * from the notice data.
     */
    const getRenderedHtml = (madlibData, handlebarData, editMode) => {
        const data = Object.assign(Object.assign({}, madlibData), handlebarData);
        try {
            return template(data, {
                data: {
                    editMode
                }
            });
        }
        catch (e) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Error rendering madlibs template');
            return '<div>Error in notice template</div>';
        }
    };
    const onMadlibEditorUpdate = useCallback(() => {
        var _a, _b;
        const partialState = getMadlibState();
        const data = Object.assign(Object.assign({}, madlibData.questionTemplateData), partialState.templateData);
        const renderedHtml = getRenderedHtml(data, handlebarData, false);
        const parser = new DOMParser();
        const madlibDoc = parser.parseFromString(renderedHtml, 'text/html');
        const metaTags = madlibDoc.querySelectorAll('meta');
        let madlibMetaData = null;
        if (metaTags.length) {
            const noticeName = (_a = madlibDoc
                .querySelector('meta[name="notice_reference_id"]')) === null || _a === void 0 ? void 0 : _a.getAttribute('content');
            const price = (_b = madlibDoc
                .querySelector('meta[name="notice_price"]')) === null || _b === void 0 ? void 0 : _b.getAttribute('content');
            const noticePrice = price ? parseInt(price, 10) : undefined;
            madlibMetaData = { noticeName, noticePrice };
            removeUndefinedFields(madlibMetaData);
        }
        setRenderedHtml(renderedHtml);
        onTemplateDataChange(Object.assign({ templateData: partialState.templateData, questionTemplateData: madlibData.questionTemplateData }, (madlibMetaData && { metadata: madlibMetaData })));
        const newValidationResults = partialState.fields.reduce((acc, { varName, isValid }) => (Object.assign(Object.assign({}, acc), { [varName]: isValid })), {});
        setValidationResults(newValidationResults);
    }, [template, madlibData.questionTemplateData]);
    useEffect(() => {
        registerHandlebarsHelpers();
    }, []);
    useEffect(() => {
        window.addEventListener('template-updated', onMadlibEditorUpdate);
        return () => {
            window.removeEventListener('template-updated', onMadlibEditorUpdate);
        };
    }, [onMadlibEditorUpdate]);
    useEffect(() => {
        const data = Object.assign(Object.assign({}, madlibData.questionTemplateData), madlibData.templateData);
        // Compile the madlibs (non-rendered) HTML
        const html = getRenderedHtml(data, handlebarData, true);
        setInnerHtml(html);
    }, [
        rawTemplate,
        safeStringify(madlibData.questionTemplateData),
        // refresh the editor when notice data changes that could affect the template
        safeStringify(handlebarData)
    ]);
    useEffect(() => {
        // Trigger an editor update
        onMadlibEditorUpdate();
    }, [innerHtml]);
    return (_jsx("div", { id: "madlib", style: ignoreStyling ? {} : css, dangerouslySetInnerHTML: { __html: innerHtml } }));
}
export default MadlibEditor;
