var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { exists } from 'lib/types';
import { filterOpenInvitesForAnonUsers, getDisplayName, getOpenInvitesAssociatedWithEmail, removeUndefinedFields } from 'lib/helpers';
import { InviteStatus, OccupationType } from 'lib/enums';
import { createNotificationsObject } from 'lib/utils/users';
import Firebase from 'EnoticeFirebase';
import { logAndCaptureException } from 'utils';
import { apiPost } from 'api/typed';
import { ColumnService } from 'lib/services/directory';
import { getFirebaseContext } from './firebase';
export const getUserByEmail = (email) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield apiPost('users/get-user-by-email', {
        email
    });
    if (!res.success) {
        return null;
    }
    const user = yield getFirebaseContext().usersRef().doc(res.userId).get();
    if (!exists(user)) {
        return null;
    }
    return user;
});
export function registerUser(userRegistrationRequest) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield api.post('users/register', userRegistrationRequest);
    });
}
export function loginUser(email, password) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield Firebase.auth().signInWithEmailAndPassword(email, password);
            return [true, null];
        }
        catch (err) {
            const authError = err;
            const shouldLogError = !(authError.code &&
                (authError.code === 'auth/wrong-password' ||
                    authError.code === 'auth/user-not-found'));
            if (authError.code === 'auth/wrong-password') {
                const existingSigninMethodsForEmail = yield Firebase.auth().fetchSignInMethodsForEmail(email);
                if (existingSigninMethodsForEmail.length &&
                    !existingSigninMethodsForEmail.includes('password')) {
                    authError.message =
                        'This user may be signed in with either Microsoft or Google. Please try signing in with Microsoft or Google, or re-setting your password';
                }
            }
            if (shouldLogError) {
                logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Login error', { email });
            }
            return [null, authError];
        }
    });
}
export function getAnonymousUserRegistrationRequest(userFields) {
    const { email, firstName, lastName = '' } = userFields;
    return {
        firstName,
        lastName,
        email,
        password: Math.random().toString(36).substring(2),
        hasTemporaryPassword: true
    };
}
export function isEmailRegistered(email) {
    return __awaiter(this, void 0, void 0, function* () {
        const authResponse = yield api.post('users/get-auth-providers-by-email', {
            email
        });
        if (authResponse.success) {
            // Non-registered users have a non-zero number of firebase auth providers
            return !!authResponse.numAuthProviders;
        }
        return false;
    });
}
function createFiler(email, options) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const ctx = getFirebaseContext();
        const pendingInvites = yield getOpenInvitesAssociatedWithEmail(ctx, email.toLowerCase());
        // Filter anonymous user pending invites
        const pendingAnonInvitedUsers = filterOpenInvitesForAnonUsers(pendingInvites);
        const filerInfo = Object.assign(Object.assign({}, options.defaultFilerInfo), { state: ((_a = options.defaultFilerInfo) === null || _a === void 0 ? void 0 : _a.state) || options.defaultGeographicState });
        /**
         * If the input user has no existing pending invites (individual or organization),
         * OR this user is being created via the Add Customer flow
         * send them an individual invite. In the placement flow, we only send an
         * invite for the first anonymous user created.
         */
        if (!pendingAnonInvitedUsers.length) {
            yield ctx.invitesRef().add(removeUndefinedFields({
                email: email.toLowerCase(),
                occupationType: OccupationType.individual.value,
                createdAt: getFirebaseContext().fieldValue().serverTimestamp(),
                status: InviteStatus.pending.value,
                invitedByPublisher: options.invitedBy,
                source: 'publisher'
            }));
        }
        return yield ctx.usersRef().add(removeUndefinedFields(Object.assign(Object.assign({}, filerInfo), { anonymous: true, notifications: createNotificationsObject(OccupationType.individual.value), name: getDisplayName(filerInfo.firstName, filerInfo.lastName), firstName: filerInfo.firstName, lastName: filerInfo.lastName, email: ((_b = filerInfo.email) === null || _b === void 0 ? void 0 : _b.toLowerCase()) || '', phone: filerInfo.phone || '', lastSignInTime: null, address: filerInfo.address || '', addressLine2: filerInfo.addressLine2 || '', city: filerInfo.city || '', zipCode: filerInfo.zipCode || '', state: filerInfo.state })));
    });
}
/**
 * 1. If the user exists and is anonymous, update their basic info and optionally send an invite email
 * 2. If the user exists and is not anonymous, return the existing user
 * 3. If the user does not exist, create a new user record and handle invite email
 */
export function createOrUpdateAnonymousFiler(newspaper, filerInfo, options) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const { invitedBy } = options || {};
        const { email } = filerInfo;
        if (!email) {
            throw new Error('Email is required to create a filer');
        }
        const existingFiler = yield getUserByEmail(email);
        if (exists(existingFiler)) {
            if (existingFiler.data().anonymous && filerInfo) {
                yield existingFiler.ref.update(removeUndefinedFields({
                    firstName: filerInfo.firstName || '',
                    lastName: filerInfo.lastName || '',
                    name: getDisplayName(filerInfo.firstName, filerInfo.lastName),
                    organizationName: filerInfo.organizationName || '',
                    phone: filerInfo.phone || '',
                    address: filerInfo.address || '',
                    addressLine2: filerInfo.addressLine2 || '',
                    city: filerInfo.city || '',
                    zipCode: filerInfo.zipCode || '',
                    state: filerInfo.state
                        ? parseInt(`${filerInfo.state}`, 10)
                        : (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.state
                }));
                // If this anonymous user is updated from the Add Customer flow, send an invite
                if (invitedBy) {
                    yield getFirebaseContext()
                        .invitesRef()
                        .add(removeUndefinedFields({
                        email: email.toLowerCase(),
                        occupationType: OccupationType.individual.value,
                        createdAt: getFirebaseContext().fieldValue().serverTimestamp(),
                        status: InviteStatus.pending.value,
                        invitedByPublisher: invitedBy,
                        source: 'publisher'
                    }));
                }
            }
            return existingFiler.ref;
        }
        return yield createFiler(email, {
            defaultFilerInfo: filerInfo,
            defaultGeographicState: (_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.state,
            invitedBy
        });
    });
}
