import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { CurrencyDollarIcon, DocumentTextIcon, PencilSquareIcon, NewspaperIcon, BellIcon, HomeIcon } from '@heroicons/react/24/outline';
import { isMemberOfOrganization } from 'lib/utils/users';
import { userHasPermission } from 'utils/permissions';
import { OccupationType } from 'lib/enums';
import { Permissions } from 'lib/permissions/roles';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
export const BASIC_INFO = 'basic-info';
export const BANK_ACCOUNTS = 'bank-accounts';
export const NOTIFICATIONS = 'notifications';
export const DOCUMENTS = 'documents';
export const FEATURE_FLAGS = 'flags';
export const PUBLISHER_SETTINGS = 'publisher-settings';
export const getSidebarSettingsForUser = (user) => {
    const userModel = getModelFromSnapshot(UserModel, getFirebaseContext(), user);
    const isPublishingUser = userModel.isPublisher;
    // Bank accounts are only shown in the user settings if the user is an advertiser
    // and is NOT part of any organization.
    const userInOrganization = user && isMemberOfOrganization(user, undefined);
    const showBankAccountsTab = !isPublishingUser && !userInOrganization;
    const showPublisherSettingsTab = !isPublishingUser &&
        user &&
        userModel.isOccupationType(OccupationType.individual);
    // Hiding the notifications tab for funeral directors until more notification features are defined for funeral homes
    const showNotificationsTab = !userModel.isOccupationType(OccupationType.funeral_director);
    const showFeatureFlagsTab = user &&
        (userModel.isColumnUser ||
            userHasPermission(user, Permissions.SETTINGS_FEATURE_FLAGS));
    return {
        showBankAccountsTab,
        showPublisherSettingsTab,
        showFeatureFlagsTab,
        showNotificationsTab,
        userInOrganization,
        isPublishingUser
    };
};
function SidebarTab({ onClick, active, label, icon, id }) {
    return (_jsxs("div", Object.assign({ className: classNames('flex rounded font-medium text-base leading-5 text-column-gray-400 mt-1 py-3 w-64 cursor-pointer', 'hover:bg-column-primary-50 hover:text-primary-500', {
            'bg-column-primary-50 text-primary-500': active
        }), onClick: onClick, id: id }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-1/6" }, { children: icon })), _jsx("div", Object.assign({ className: "flex-1" }, { children: label }))] })));
}
export default function UserSettingsSidebar({ onViewChange, activeView, user }) {
    const { showBankAccountsTab, showNotificationsTab, showPublisherSettingsTab, showFeatureFlagsTab, isPublishingUser } = getSidebarSettingsForUser(user);
    const tabIconStyle = 'h-5 w-5';
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/?tab=${view}`);
        onViewChange(view);
    };
    return (_jsxs("div", Object.assign({ className: "bg-white rounded-lg shadow py-3 px-4", style: {
            height: 'calc(100vh - 150px)'
        } }, { children: [_jsx(SidebarTab, { id: BASIC_INFO, label: "Basic Info", icon: _jsx(HomeIcon, { className: tabIconStyle }), onClick: () => updateView(BASIC_INFO), active: activeView === BASIC_INFO }), showNotificationsTab && (_jsx(SidebarTab, { id: NOTIFICATIONS, label: "Notifications", icon: _jsx(BellIcon, { className: tabIconStyle }), onClick: () => updateView(NOTIFICATIONS), active: activeView === NOTIFICATIONS })), showBankAccountsTab && (_jsx(SidebarTab, { id: BANK_ACCOUNTS, label: "Bank Accounts", icon: _jsx(CurrencyDollarIcon, { className: tabIconStyle }), onClick: () => updateView(BANK_ACCOUNTS), active: activeView === BANK_ACCOUNTS })), !isPublishingUser && (_jsx(SidebarTab, { id: DOCUMENTS, label: "Documents", icon: _jsx(DocumentTextIcon, { className: tabIconStyle }), onClick: () => updateView(DOCUMENTS), active: activeView === DOCUMENTS })), showPublisherSettingsTab && (_jsx(SidebarTab, { id: PUBLISHER_SETTINGS, label: "Publisher Settings", icon: _jsx(NewspaperIcon, { className: tabIconStyle }), onClick: () => updateView(PUBLISHER_SETTINGS), active: activeView === PUBLISHER_SETTINGS })), showFeatureFlagsTab && (_jsx(SidebarTab, { id: FEATURE_FLAGS, label: "Feature Flags", icon: _jsx(PencilSquareIcon, { className: tabIconStyle }), onClick: () => updateView(FEATURE_FLAGS), active: activeView === FEATURE_FLAGS }))] })));
}
