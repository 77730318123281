import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useState } from 'react';
import { isOrderFilingType } from 'lib/types/filingType';
import { TextField } from 'lib/components/TextField';
import { Autocomplete } from 'lib/components/Autocomplete';
import { SELECTABLE_LAYOUTS } from 'lib/orders/layouts';
import { MADLIB_ID_PATTERN } from '../noticeTypesHelpers';
const layoutOptions = SELECTABLE_LAYOUTS.map(({ id }) => ({
    label: id,
    value: id
}));
export default function SuperUserOrderFilingTypeSettings({ filingType, updatedFilingType, onUpdateFilingType }) {
    const [entryMethod, setEntryMethod] = useState(updatedFilingType.madlib ? 'madlib' : 'standard');
    if (!isOrderFilingType(updatedFilingType)) {
        return _jsx(_Fragment, {});
    }
    const { supportedLayoutIds = [] } = updatedFilingType;
    return (_jsxs(_Fragment, { children: [_jsxs(CardGridLayout, Object.assign({ header: {
                    title: 'Filing Type Format',
                    description: 'Configure special formatting settings for this filing type.'
                } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: entryMethod === 'standard' }, { children: _jsx(ColumnSelect, { id: "notice-type-entry-method", labelText: "Content creation method", options: [
                                {
                                    value: 'standard',
                                    label: 'Enter text and upload images using default editor'
                                },
                                {
                                    value: 'madlib',
                                    label: 'Complete fill-in-the-blank template (Madlib)'
                                }
                            ], value: entryMethod, onChange: newValue => {
                                const newFilingType = Object.assign({}, updatedFilingType);
                                if (newValue === 'standard') {
                                    newFilingType.madlib = '';
                                    setEntryMethod('standard');
                                }
                                else if (newValue === 'madlib') {
                                    newFilingType.madlib = filingType.madlib;
                                    setEntryMethod('madlib');
                                }
                                onUpdateFilingType(newFilingType);
                            } }) })), entryMethod === 'madlib' && (_jsx(GridInput, { children: _jsx(TextField, { id: "notice-type-madlib-id", value: updatedFilingType.madlib || '', labelText: "Madlib storage path", pattern: MADLIB_ID_PATTERN, validationMessages: {
                                patternMismatch: 'Please input a valid madlib config.',
                                valueMissing: 'Please enter a path to a madlib config.'
                            }, required: true, onChange: newValue => {
                                onUpdateFilingType(Object.assign(Object.assign({}, updatedFilingType), { madlib: newValue }));
                            }, placeholder: "madlibs/publisher/folder" }) }))] })), _jsx(CardGridLayout, Object.assign({ header: {
                    title: 'Filing Type Layouts',
                    description: 'Set up layouts'
                } }, { children: _jsx(GridInput, { children: _jsx(Autocomplete, { options: layoutOptions, onChange: newValue => {
                            const newSupportedLayoutIds = supportedLayoutIds.includes(newValue)
                                ? supportedLayoutIds.filter(id => id !== newValue)
                                : [...supportedLayoutIds, newValue];
                            onUpdateFilingType(Object.assign(Object.assign({}, updatedFilingType), { supportedLayoutIds: newSupportedLayoutIds }));
                        }, id: "supported-layout-ids-select", labelText: "Supported layout IDs", selectedOptionsValues: supportedLayoutIds, showCheckBoxForSelectedItems: true, placeholder: supportedLayoutIds.join(', ') }) }) }))] }));
}
