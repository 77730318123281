export const FilingTypeVisibilityData = {
    /** Filing type is visible to both publisher and advertiser */
    all_users: {
        value: 1,
        label: `Enabled for all users`,
        key: 'all_users'
    },
    /** Filing type is visible to publisher only when placing or editing an order */
    publisher_only: {
        value: 2,
        label: `Publisher only (this order type will not appear to customers, but publishers can place it on their behalf)`,
        key: 'publisher_only'
    },
    /** Filing type cannot be used when placing or editing an order */
    disabled: {
        value: 3,
        label: `Disabled (this order type will not appear to customers or publishers in the placement flow)`,
        key: 'disabled'
    }
};
