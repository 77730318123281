import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TableLayout } from 'lib/components/TableLayout';
import { NoticesOverviewRow } from './NoticesOverviewRow';
export function NoticesOverviewTable(props) {
    const columns = ['Notice', 'Custom Id', 'Confirmed', 'Invoiced', 'View'];
    const { notices, loading } = props;
    return (_jsx("div", Object.assign({ id: "notices-table", className: "overflow-scroll" }, { children: _jsx(TableLayout, { filterable: {
                searchEnabled: false,
                shouldShowTableItem: () => true
            }, loading: loading, renderRow: notice => _jsx(NoticesOverviewRow, { notice: notice }), header: {
                title: 'Notices',
                subtitle: 'All notices for this publication issue'
            }, columns: columns, data: notices, pagination: { pageSize: 15 } }) })));
}
