var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { BuildingOffice2Icon } from '@heroicons/react/24/solid';
import { getFirebaseContext } from 'utils/firebase';
import { fuzzyStringContains } from 'lib/utils/strings';
import { getNewspaperRefForCustomer } from 'lib/notice/customer';
import ToastActions from 'redux/toast';
import { buildingStyles } from 'lib/components/UserIDCard';
import { TableLayout } from 'lib/components/TableLayout';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { extractCustomerOrganizationDataFromOrganization } from './utils';
/**
 * Customer organization table row on the customers settings page
 */
function CustomerOrgRow({ customerOrg }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const name = ((_b = (_a = customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.customerOrganization) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.name) ||
        ((_c = customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.clientOrganization.data()) === null || _c === void 0 ? void 0 : _c.name) ||
        '';
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: `${buildingStyles[customerOrg.customerOrganization.id.charCodeAt(0) %
                                buildingStyles.length]} w-10 h-10 rounded-full flex items-center justify-center` }, { children: _jsx(BuildingOffice2Icon, { className: "w-5 h-5" }) })), _jsx("div", Object.assign({ className: "pl-3 text-sm leading-6 font-medium text-column-gray-800" }, { children: name }))] })) }), _jsx("td", { children: `${((_e = (_d = customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.customerOrganization) === null || _d === void 0 ? void 0 : _d.data()) === null || _e === void 0 ? void 0 : _e.internalID)
                    ? (_g = (_f = customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.customerOrganization) === null || _f === void 0 ? void 0 : _f.data()) === null || _g === void 0 ? void 0 : _g.internalID
                    : '--'}` })] }));
}
/**
 * Filter function for the customer organizations table
 */
function customerOrgFilter({ customerOrg, filter }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const matchString = filter.toLowerCase();
    if (fuzzyStringContains(((_b = (_a = customerOrg.customerOrganization) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.name) || '', matchString)) {
        return true;
    }
    if (fuzzyStringContains(((_d = (_c = customerOrg.clientOrganization) === null || _c === void 0 ? void 0 : _c.data()) === null || _d === void 0 ? void 0 : _d.name) || '', matchString)) {
        return true;
    }
    if (fuzzyStringContains(((_g = (_f = (_e = customerOrg.customerOrganization) === null || _e === void 0 ? void 0 : _e.data()) === null || _f === void 0 ? void 0 : _f.internalID) === null || _g === void 0 ? void 0 : _g.toLowerCase()) || '', matchString)) {
        return true;
    }
    return false;
}
/**
 * Wrapper component for the Customer Organizations table page.
 */
export default function CustomerOrganizationTableTab({ activeOrganization, setCustomerOrg, setShowCustomerOrgDrawer, setShowEditCustomerOrgModal }) {
    const dispatch = useAppDispatch();
    const [customerOrgs, setCustomerOrgs] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const newspaperRef = getNewspaperRefForCustomer(activeOrganization);
    useEffect(() => {
        const unsubscribe = getFirebaseContext()
            .customerOrganizationsRef()
            .where('organization', '==', newspaperRef)
            .onSnapshot((result) => __awaiter(this, void 0, void 0, function* () {
            const shallowCustomerOrgRows = result.docs;
            const customerOrgsWithData = yield Promise.all(shallowCustomerOrgRows.map(extractCustomerOrganizationDataFromOrganization));
            const validCustomerOrgsWithData = customerOrgsWithData.filter(Boolean);
            setCustomerOrgs(validCustomerOrgsWithData);
            setLoadingData(false);
        }));
        return () => unsubscribe();
    }, []);
    return (_jsx(_Fragment, { children: _jsx(TableLayout, { header: {
                subtitle: 'Governments, legal services, and agencies using your publication.',
                title: 'Organizations'
            }, columns: ['Name', 'ID'], renderRow: customerOrg => CustomerOrgRow({ customerOrg }), data: customerOrgs || [], loading: loadingData, id: "customer-organization-settings", clickable: {
                onClick: customerOrg => {
                    setCustomerOrg(customerOrg);
                    setShowCustomerOrgDrawer(true);
                }
            }, filterable: {
                shouldShowTableItem: (customerOrg, filter) => customerOrgFilter({ customerOrg, filter })
            }, editable: {
                editTooltip: 'Edit this organization.',
                onEdit: customerOrg => {
                    setCustomerOrg(customerOrg);
                    setShowEditCustomerOrgModal(true);
                }
            }, archivable: {
                renderWarning: () => ({
                    header: `Delete Organization?`,
                    body: 'Are you sure you want to delete this organization? Once an organization is deleted, it will no longer be accessible. Please click “Delete” to confirm this action or “Cancel” to go back.',
                    buttonText: 'Delete'
                }),
                onArchive: (customerOrg) => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    try {
                        const customerOrgRef = getFirebaseContext()
                            .customerOrganizationsRef()
                            .doc(customerOrg.customerOrganization.id);
                        yield customerOrgRef.delete();
                        dispatch(ToastActions.toastSuccess({
                            headerText: 'Organization Deleted',
                            bodyText: `${(_a = customerOrg.clientOrganization.data()) === null || _a === void 0 ? void 0 : _a.name} has been successfully deleted from your organization list.`
                        }));
                    }
                    catch (e) {
                        logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, e, 'Error deleting customer org', {
                            customerId: customerOrg.customerOrganization.id
                        });
                    }
                }),
                isArchiveDisabled: false,
                enabledArchiveTooltip: 'Delete this organization.'
            } }) }));
}
