export var PlacementFlowStep;
(function (PlacementFlowStep) {
    PlacementFlowStep["CustomerType"] = "Customer Type";
    PlacementFlowStep["Category"] = "Category";
    PlacementFlowStep["Details"] = "Customer Details";
    PlacementFlowStep["Publication"] = "Publication";
    PlacementFlowStep["Schedule"] = "Schedule";
    PlacementFlowStep["Verification"] = "Deceased Details";
    PlacementFlowStep["Content"] = "Draft Content";
    PlacementFlowStep["Summary"] = "Order Summary";
})(PlacementFlowStep || (PlacementFlowStep = {}));
