import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { exists } from 'lib/types';
import { State } from 'lib/enums';
import LoadingState from 'components/LoadingState';
import TabGroup from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import Archive from './Archive';
import Upload from './Upload';
import { ExternalUpload } from './ExternalUpload';
const UPLOAD_TAB = {
    label: 'Upload',
    id: 'upload',
    enabled: true
};
const ARCHIVE_TAB = {
    label: 'Archive',
    id: 'archive',
    enabled: true
};
function FTPSettings({ availableOrganizations, activeOrganization, organization }) {
    var _a;
    const [activeTab, setActiveTab] = useState(UPLOAD_TAB);
    if (!exists(organization)) {
        return _jsx(LoadingState, {});
    }
    const uploadFormat = (_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.showUploads;
    return (_jsxs(SettingsPage, { children: [_jsx(TabGroup, { onClickTab: setActiveTab, activeTab: activeTab, tabs: [UPLOAD_TAB, ARCHIVE_TAB], id: "statewide-site-uploads-tabs" }), activeTab.id === UPLOAD_TAB.id &&
                (!uploadFormat || uploadFormat === 'INTERNAL' ? (_jsx(Upload, { availableOrganizations: availableOrganizations, activeOrganization: activeOrganization, organization: organization })) : (_jsx(ExternalUpload, { uploadFormat: uploadFormat }))), activeTab.id === ARCHIVE_TAB.id && (_jsx(Archive, { organization: activeOrganization }))] }));
}
const mapStateToProps = (state) => ({
    organization: state.auth.organization
});
export default connect(mapStateToProps)(FTPSettings);
