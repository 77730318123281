import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TableLayout } from 'lib/components/TableLayout';
import { ClassifiedsOverviewRow } from './ClassifiedsOverviewRow';
export function ClassifiedsOverviewTable(props) {
    const columns = ['Title', 'Customer Name', 'Category', 'View'];
    const { classifieds, loading } = props;
    return (_jsx("div", Object.assign({ id: "classifieds-table", className: "overflow-scroll" }, { children: _jsx(TableLayout, { filterable: {
                searchEnabled: false,
                shouldShowTableItem: () => true
            }, loading: loading, renderRow: classified => (_jsx(ClassifiedsOverviewRow, { classified: classified })), header: {
                title: 'Classifieds',
                subtitle: 'All classifieds for this publication issue'
            }, columns: columns, data: classifieds, pagination: { pageSize: 15 } }) })));
}
