var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import React from 'react';
export function InheritableSettingSelectInput(_a) {
    var { optionText: { yesLabel, noLabel, defaultLabel }, value, onChange } = _a, props = __rest(_a, ["optionText", "value", "onChange"]);
    return (_jsx(ColumnSelect, Object.assign({ options: [
            { value: 'true', label: yesLabel },
            { value: 'false', label: noLabel },
            { value: 'default', label: defaultLabel }
        ], value: value === true ? 'true' : value === false ? 'false' : 'default', onChange: newValue => {
            switch (newValue) {
                case 'true': {
                    return onChange(true);
                }
                case 'false': {
                    return onChange(false);
                }
                default: {
                    return onChange(undefined);
                }
            }
        } }, props)));
}
