export const DRAFT_ORDERS_TAB = {
    label: 'Drafts',
    enabled: true,
    id: 'draftOrders-tab'
};
export const IN_PROGRESS_ORDERS_TAB = {
    label: 'In progress',
    enabled: true,
    id: 'inProgressOrders-tab'
};
export const COMPLETED_ORDERS_TAB = {
    label: 'Completed',
    enabled: true,
    id: 'completedOrders-tab'
};
export const CANCELLED_ORDERS_TAB = {
    label: 'Cancelled',
    enabled: true,
    id: 'cancelledOrders-tab'
};
export const ORDER_STATUS_TABS = [
    IN_PROGRESS_ORDERS_TAB,
    COMPLETED_ORDERS_TAB,
    DRAFT_ORDERS_TAB,
    CANCELLED_ORDERS_TAB
];
