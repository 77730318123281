import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
const blueCustomerOrgStyles = 'bg-blue-100 text-blue-900';
const greenCustomerOrgStyles = 'bg-column-green-100 text-column-green-600';
const redCustomerOrgStyles = 'bg-red-100 text-red-500';
const purpleCustomerOrgStyles = 'bg-purple-100 text-purple-500';
const pinkCustomerOrgStyles = 'bg-pink-100 text-pink-500';
const roseCustomerOrgStyles = 'bg-rose-100 text-rose-500';
const orangeCustomerOrgStyles = 'bg-orange-100 text-orange-500';
export const buildingStyles = [
    blueCustomerOrgStyles,
    greenCustomerOrgStyles,
    redCustomerOrgStyles,
    purpleCustomerOrgStyles,
    pinkCustomerOrgStyles,
    roseCustomerOrgStyles,
    orangeCustomerOrgStyles
];
const blueMemberStyles = 'bg-blue-650';
const greenMemberStyles = 'bg-column-green-400';
const redMemberStyles = 'bg-red-500';
const purpleMemberStyles = 'bg-purple-500';
const pinkMemberStyles = 'bg-pink-500';
const roseMemberStyles = 'bg-rose-500';
const orangeMemberStyles = 'bg-orange-500';
export const memberStyles = [
    blueMemberStyles,
    greenMemberStyles,
    redMemberStyles,
    purpleMemberStyles,
    pinkMemberStyles,
    roseMemberStyles,
    orangeMemberStyles
];
function UserIDCard({ name, index, email }) {
    const randomInt = useMemo(() => Math.floor(Math.random() * memberStyles.length), []);
    const indexToUse = index !== null && index !== void 0 ? index : randomInt;
    return (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(InitialAvatar, { userName: name, index: indexToUse }), _jsxs("div", Object.assign({ className: "pl-3 text-sm leading-6 font-medium" }, { children: [_jsx("div", Object.assign({ className: "text-gray-850" }, { children: name })), _jsx("div", Object.assign({ className: "text-gray-650" }, { children: email }))] }))] })));
}
export function InitialAvatar({ userName, index }) {
    const [firstName, lastName] = userName.split(' ');
    return (_jsx("div", Object.assign({ className: `${memberStyles[index % memberStyles.length]} w-10 h-10 rounded-full flex items-center justify-center text-white text-sm leading-6 font-semibold uppercase` }, { children: _jsxs("span", { children: [firstName && firstName.charAt(0), lastName && lastName.charAt(0)] }) })));
}
export default UserIDCard;
