export const AutomatedAffidavitSigners = {
    stefan_pla: {
        value: 1,
        key: 'stefan_pla',
        label: 'Stefan Edward Pla',
        phone: '2039182130',
        email: 'stefan+signer@column.us',
        firstName: 'Stefan',
        lastName: 'Pla',
        active: false
    },
    bailee_liston: {
        value: 2,
        key: 'bailee_liston',
        label: 'Bailee Liston',
        phone: '7754500189',
        email: 'bailee@column.us',
        firstName: 'Bailee',
        lastName: 'Liston',
        active: true
    },
    leo_hentschker: {
        value: 3,
        key: 'leo_hentschker',
        label: 'Leo Hentschker',
        phone: '9739002959',
        email: 'leo@column.us',
        firstName: 'Leo',
        lastName: 'Hentschker',
        active: true
    },
    deidre_stevens_digiovanni: {
        value: 4,
        key: 'deidre_stevens_digiovanni',
        label: 'Deidre Stevens-DiGiovanni',
        phone: '2102137919',
        email: 'deidre@column.us',
        firstName: 'Deidre',
        lastName: 'Stevens-DiGiovanni',
        active: true
    },
    megan_villanueva: {
        value: 5,
        key: 'megan_villanueva',
        label: 'Megan Villanueva',
        phone: '6503073612',
        email: 'megan+signer@column.us',
        firstName: 'Megan',
        lastName: 'Villanueva',
        active: true
    },
    frances_fischman: {
        value: 6,
        key: 'frances_fischman',
        label: 'Frances Fischman',
        phone: '',
        email: 'frances@column.us',
        firstName: 'Frances',
        lastName: 'Fischman',
        active: false
    },
    emily_meffert: {
        value: 7,
        key: 'emily_meffert',
        label: 'Emily Meffert',
        phone: '5025514240',
        email: 'emily@column.us',
        firstName: 'Emily',
        lastName: 'Meffert',
        active: false
    },
    samuel_stern: {
        value: 8,
        key: 'samuel_stern',
        label: 'Samuel Stern',
        phone: '6094050762',
        email: 'sam@column.us',
        firstName: 'Samuel',
        lastName: 'Stern',
        signer: 'Samuel Stern',
        active: false
    },
    madeline_vostrejs: {
        value: 9,
        key: 'madeline_vostrejs',
        label: 'Madeline Vostrejs',
        phone: '6147472902',
        email: 'madeline@column.us',
        firstName: 'Madeline',
        lastName: 'Vostrejs',
        active: true
    },
    nicholas_stimler: {
        value: 10,
        key: 'nicholas_stimler',
        label: 'Nicholas Stimler',
        phone: '3303666537',
        email: 'nicks+signer@column.us',
        firstName: 'Nick',
        lastName: 'Stimler',
        active: false
    },
    casey_allen: {
        value: 11,
        key: 'casey_allen',
        label: 'Casey Allen',
        phone: '6102357415',
        email: 'casey@column.us',
        firstName: 'Casey',
        lastName: 'Allen',
        active: true
    },
    jesse_sassaman: {
        value: 12,
        key: 'jesse_sassaman',
        label: 'Jesse Sassaman',
        phone: '2107236661',
        email: 'jesse@column.us',
        firstName: 'Jesse',
        lastName: 'Sassaman',
        active: true
    },
    carly_cochran_worthen: {
        value: 13,
        key: 'carly_cochran_worthen',
        label: 'Ayesha Carletta M Cochran-Worthen',
        phone: '6822616709',
        email: 'carly@column.us ',
        firstName: 'Ayesha Carletta M',
        lastName: 'Cochran-Worthen',
        active: true
    },
    sarah_alpert: {
        value: 14,
        key: 'sarah_alpert',
        label: 'Sarah Alpert',
        phone: '6176103125',
        email: 'sarah@column.us',
        firstName: 'Sarah',
        lastName: 'Alpert',
        active: false
    },
    kade_hill: {
        value: 15,
        key: 'kade_hill',
        label: 'Kade Hill',
        phone: '4045090572',
        email: 'kade@column.us',
        firstName: 'Kade',
        lastName: 'Hill',
        active: true
    },
    delaney_anne_conforth: {
        value: 16,
        key: 'delaney_anne_conforth',
        label: 'Delaney Anne Cornforth',
        phone: '3195597555',
        email: 'delaneycornforth@gmail.com',
        firstName: 'Delaney',
        lastName: 'Cornforth',
        active: true
    },
    jason_clark: {
        value: 17,
        key: 'jason_clark',
        label: 'Jason Clark',
        phone: '3858678554',
        email: 'jasonclarkagain@gmail.com',
        firstName: 'Jason',
        lastName: 'Clark',
        active: false
    },
    pablo_villeda_echverria: {
        value: 18,
        key: 'pablo_villeda_echverria',
        label: 'Pablo Villeda-Echverria',
        phone: '3604901637',
        email: 'pablov.e12345@gmail.com',
        firstName: 'Pablo',
        lastName: 'Villeda-Echverria',
        active: false
    },
    samantha_pryor: {
        value: 19,
        key: 'samantha_pryor',
        label: 'Samantha Pryor',
        phone: '7169401705',
        email: 'samantha@column.us',
        firstName: 'Samantha',
        lastName: 'Pryor',
        active: true
    },
    hannah_ward: {
        value: 20,
        key: 'hannah_ward',
        label: 'Hannah Ward',
        phone: '3022879590',
        email: 'hannah@column.us',
        firstName: 'Hannah',
        lastName: 'Ward',
        active: true
    },
    yuade_moore: {
        value: 21,
        key: 'yuade_moore',
        label: 'Yuade Moore',
        phone: '34702340673',
        email: 'yuade@column.us',
        firstName: 'Yuade',
        lastName: 'Moore',
        active: true
    },
    laquansay_nickson_watkins: {
        value: 22,
        key: 'laquansay_nickson_watkins',
        label: 'Laquansay Nickson Watkins',
        phone: '3343548220',
        email: 'quanwatkins77@gmail.com',
        firstName: 'Laquansay Nickson',
        lastName: 'Watkins',
        active: true
    },
    kevin_king: {
        value: 23,
        key: 'kevin_king',
        label: 'Kevin King',
        phone: '2143161630',
        email: 'kevin@column.us',
        firstName: 'Kevin',
        lastName: 'King',
        active: true
    },
    nicole_riegart: {
        value: 24,
        key: 'nicole_riegart',
        label: 'Nicole Riegert',
        phone: '7014515711',
        email: 'nicole@column.us',
        firstName: 'Nicole',
        lastName: 'Riegert',
        active: true
    },
    tatiana_dorval: {
        value: 25,
        key: 'tatiana_dorval',
        label: 'Tatiana Dorval',
        phone: '4847734805',
        email: 'tatiana@column.us',
        firstName: 'Tatiana',
        lastName: 'Dorval',
        active: true
    },
    nichole_seitz: {
        value: 26,
        key: 'nichole_seitz',
        label: 'Nichole Seitz',
        phone: '7014515668',
        email: 'nichole@column.us',
        firstName: 'Nichole',
        lastName: 'Seitz',
        active: true
    },
    kevin_richman: {
        value: 27,
        key: 'kevin_richman',
        label: 'Kevin Richman',
        phone: '8045129906',
        email: 'richman@column.us',
        firstName: 'Kevin',
        lastName: 'Richman',
        active: true
    },
    lea_boreland: {
        value: 28,
        key: 'lea_boreland',
        label: 'Lea Boreland',
        phone: '2482029499',
        email: 'lea@column.us',
        firstName: 'Lea',
        lastName: 'Boreland',
        active: true
    },
    enrique_diaz: {
        value: 29,
        key: 'enrique_diaz',
        label: 'Enrique Diaz',
        phone: '3054311224',
        email: 'enrique@column.us',
        firstName: 'Enrique',
        lastName: 'Diaz',
        active: true
    },
    alex_lutz: {
        value: 30,
        key: 'alex_lutz',
        label: 'Alex Lutz',
        phone: '8144707556',
        email: 'Alex@column.us',
        firstName: 'Alex',
        lastName: 'Lutz',
        active: true
    },
    jason_poon: {
        value: 31,
        key: 'jason_poon',
        label: 'Jason Poon',
        phone: '5126998100',
        email: 'jason@column.us',
        firstName: 'Jason',
        lastName: 'Poon',
        active: true
    },
    hadley_christman: {
        value: 32,
        key: 'hadley_christman',
        label: 'Hadley Christman',
        phone: '8568163112',
        email: 'hadley@column.us',
        firstName: 'Hadley',
        lastName: 'Christman',
        active: true
    },
    david_goolcharran: {
        value: 33,
        key: 'david_goolcharran',
        label: 'David Goolcharran',
        phone: '6468192914',
        email: 'david@column.us',
        firstName: 'David',
        lastName: 'Goolcharran',
        active: true
    },
    rachel_cozart: {
        value: 34,
        key: 'rachel_cozart',
        label: 'Rachel Cozart',
        phone: '2483304786',
        email: 'rachel@column.us',
        firstName: 'Rachel',
        lastName: 'Cozart',
        active: true
    },
    iris_yuan: {
        value: 35,
        key: 'iris_yuan',
        label: 'Iris Yuan',
        phone: '5304006247',
        email: 'iris@column.us',
        firstName: 'Iris',
        lastName: 'Yuan',
        active: true
    },
    monique_gadzik: {
        value: 36,
        key: 'monique_gadzik',
        label: 'Monique Gadzik',
        phone: '7162381351',
        email: 'monique@column.us',
        firstName: 'Monique',
        lastName: 'Gadzik',
        active: true
    },
    will_lamb: {
        value: 37,
        key: 'will_lamb',
        label: 'Will Lamb',
        phone: '4083841267',
        email: 'will@column.us',
        firstName: 'Will',
        lastName: 'Lamb',
        active: true
    },
    ari_korotkin: {
        value: 38,
        key: 'ari_korotkin',
        label: 'Ari Korotkin',
        phone: '2485359135',
        email: 'ari@column.us',
        firstName: 'Ari',
        lastName: 'Korotkin',
        active: true
    },
    jake_seaton: {
        value: 39,
        key: 'jake_seaton',
        label: 'Jake Seaton',
        phone: '7853132445',
        email: 'seaton@column.us',
        firstName: 'Jake',
        lastName: 'Seaton',
        active: true
    },
    bo_xiang: {
        value: 40,
        key: 'bo_xiang',
        label: 'Bo Xiang',
        phone: '7049629180',
        email: 'bo@column.us',
        firstName: 'Bo',
        lastName: 'Xiang',
        active: true
    },
    justin_mattos: {
        value: 41,
        key: 'justin_mattos',
        label: 'Justin Mattos',
        phone: '7607999388',
        email: 'justin@column.us',
        firstName: 'Justin',
        lastName: 'Mattos',
        active: true
    },
    teresa_tseng: {
        value: 42,
        key: 'teresa_tseng',
        label: 'Teresa Tseng',
        phone: '9144863552',
        email: 'teresa@column.us',
        firstName: 'Teresa',
        lastName: 'Tseng',
        active: true
    },
    benjamin_revier: {
        value: 43,
        key: 'benjamin_revier',
        label: 'Benjamin Revier',
        phone: '7012122456',
        email: 'benjamin@column.us',
        firstName: 'Benjamin',
        lastName: 'Revier',
        active: true
    },
    kevin_mcgillivray: {
        value: 44,
        key: 'kevin_mcgillivray',
        label: 'Kevin McGillivray',
        phone: '9202658062',
        email: 'kevinm@column.us',
        firstName: 'Kevin',
        lastName: 'McGillivray',
        active: true
    },
    danielle_underwood: {
        value: 45,
        key: 'danielle_underwood',
        label: 'Danielle Underwood',
        phone: '7138522879',
        email: 'danielle@column.us',
        firstName: 'Danielle',
        lastName: 'Underwood',
        active: true
    },
    paul_atkins: {
        value: 46,
        key: 'paul_atkins',
        label: 'Paul Atkins',
        phone: '2508964454',
        email: 'paul@column.us',
        firstName: 'Paul',
        lastName: 'Atkins',
        active: true
    },
    viet_tran: {
        value: 47,
        key: 'viet_tran',
        label: 'Viet Tran',
        phone: '2246782695',
        email: 'viet@column.us',
        firstName: 'Viet',
        lastName: 'Tran',
        active: true
    },
    emmie_atwood: {
        value: 48,
        key: 'emmie_atwood',
        label: 'Emmie Atwood',
        phone: '6178333606',
        email: 'emmie@column.us',
        firstName: 'Emmie',
        lastName: 'Atwood',
        active: true
    },
    stefani_beard: {
        value: 49,
        key: 'stefani_beard',
        label: 'Stefani Beard',
        phone: '5076009254',
        email: 'stefani@column.us',
        firstName: 'Stefani',
        lastName: 'Beard',
        active: true
    },
    rebecca_bikul: {
        value: 50,
        key: 'rebecca_bikul',
        label: 'Rebecca Bikul',
        phone: '4085048753',
        email: 'becky@column.us',
        firstName: 'Rebecca',
        lastName: 'Bikul',
        active: true
    },
    amber_finneseth: {
        value: 51,
        key: 'amber_finneseth',
        label: 'Amber Finneseth',
        phone: '7016301556',
        email: 'amber@column.us',
        firstName: 'Amber',
        lastName: 'Finneseth',
        active: true
    },
    marco_coppola: {
        value: 52,
        key: 'marco_coppola',
        label: 'Marco Coppola',
        phone: '5206048472',
        email: 'marco@column.us',
        firstName: 'Marco',
        lastName: 'Coppola',
        active: true
    },
    morgan_creekmore: {
        value: 53,
        key: 'morgan_creekmore',
        label: 'Morgan Creekmore',
        phone: '9187983385',
        email: 'creekmore@column.us',
        firstName: 'Morgan',
        lastName: 'Creekmore',
        active: true
    },
    alison_farmwald: {
        value: 54,
        key: 'alison_farmwald',
        label: 'Alison Farmwald',
        phone: '4153088698',
        email: 'alison@column.us',
        firstName: 'Alison',
        lastName: 'Farmwald',
        active: true
    },
    hayden_lipsky: {
        value: 55,
        key: 'hayden_lipsky',
        label: 'Hayden Lipsky',
        phone: '9177478769',
        email: 'hayden@column.us',
        firstName: 'Hayden',
        lastName: 'Lipsky',
        active: true
    }
};
export const stringIsSignerKey = (signerKeyString) => {
    return Object.keys(AutomatedAffidavitSigners).some(key => signerKeyString === key);
};
