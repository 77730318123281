import { ELAVON_TOKENIZER_URL_TEST, ELAVON_TOKENIZER_URL_PROD, PROD, ENV } from '../../../../../../../constants';
export function elavonCardTokenizerInjector() {
    const url = ENV === PROD ? ELAVON_TOKENIZER_URL_PROD : ELAVON_TOKENIZER_URL_TEST;
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
}
