var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownTrayIcon, ChevronDownIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Popover } from 'lib/components/Popover';
import { ColumnService } from 'lib/services/directory';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import downloadProof from 'routes/ads/place/helpers/downloadProof';
import { logAndCaptureException } from 'utils';
import MoreActionsItem from './MoreActionsItem';
function MoreActions({ order, receiptUrl, invoiceIsLoading }) {
    const [downloadIsLoading, setDownloadIsLoading] = useState(false);
    const loading = downloadIsLoading || invoiceIsLoading;
    const dispatch = useAppDispatch();
    const handleDownloadPDF = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setDownloadIsLoading(true);
            yield downloadProof(order.id, order.modelData.activeVersion);
        }
        catch (error) {
            logAndCaptureException(ColumnService.OBITS, error, 'Error downloading proofs', {
                orderId: order.id
            });
            dispatch(ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Error downloading proofs. Please try again.'
            }));
        }
        finally {
            setDownloadIsLoading(false);
        }
    });
    return (_jsx(Popover, Object.assign({ id: "more-options", activator: _jsx(ColumnButton, { type: "button", loading: loading, buttonText: "More actions", endIcon: _jsx(ChevronDownIcon, { className: "w-4 h-4 text-column-gray-400" }) }), alignment: "right" }, { children: _jsxs("div", Object.assign({ className: "p-2 space-y-2" }, { children: [_jsx(MoreActionsItem, { icon: _jsx(ArrowDownTrayIcon, {}), label: "Download proof", onClick: handleDownloadPDF }), receiptUrl && (_jsx(MoreActionsItem, { icon: _jsx(ReceiptPercentIcon, {}), label: "View receipt", onClick: () => {
                        window.open(receiptUrl, '_blank');
                    } }))] })) })));
}
export default MoreActions;
