import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { safeStringify } from 'lib/utils/stringify';
import React, { useEffect, useState } from 'react';
import MultipleChoiceQuestionInput from './MultipleChoiceQuestionInput';
import NumberQuestionInput from './NumberQuestionInput';
import YesNoQuestionInput from './YesNoQuestionInput';
import FileUploadQuestionInput from './FileUploadQuestionInput';
import { TextQuestionInput } from './TextQuestionInput';
function QuestionForm({ questions, questionTemplateData, onChange }) {
    const [values, setValues] = useState(questionTemplateData);
    const firstUnansweredQuestionIndex = questions.findIndex(q => values[q.varName] === undefined);
    const allAnswered = firstUnansweredQuestionIndex === -1;
    const visibleQuestions = allAnswered
        ? questions
        : questions.slice(0, firstUnansweredQuestionIndex + 1);
    const handleChange = (e) => {
        setValues(previousValue => (Object.assign(Object.assign({}, previousValue), { [e.varName]: e.value })));
    };
    useEffect(() => {
        onChange({ complete: !isEmpty(values) && allAnswered, values });
    }, [safeStringify(values)]);
    return (_jsx("div", { children: visibleQuestions.map(q => {
            return (_jsxs("div", Object.assign({ className: "py-2" }, { children: [q.type === 'yesno' && (_jsx(YesNoQuestionInput, { value: values[q.varName], question: q, onChange: handleChange })), q.type === 'number' && (_jsx(NumberQuestionInput, { question: q, value: values[q.varName], onChange: handleChange })), q.type === 'multiplechoice' && (_jsx(MultipleChoiceQuestionInput, { question: q, value: values[q.varName], onChange: handleChange })), q.type === 'file' && (_jsx(FileUploadQuestionInput, { question: q, value: values[q.varName], onChange: handleChange })), q.type === 'text' && (_jsx(TextQuestionInput, { question: q, value: values[q.varName], onChange: handleChange }))] }), q.varName));
        }) }));
}
export default QuestionForm;
