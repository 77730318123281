export var UpdateSettingRequestTypes;
(function (UpdateSettingRequestTypes) {
    UpdateSettingRequestTypes["ENABLE_BULK_INVOICE"] = "enableBulkInvoicing";
    UpdateSettingRequestTypes["DISABLE_BULK_INVOICE"] = "disableBulkInvoicing";
})(UpdateSettingRequestTypes || (UpdateSettingRequestTypes = {}));
export var UpdateSettingRequestStatus;
(function (UpdateSettingRequestStatus) {
    UpdateSettingRequestStatus["INITIATED"] = "initiated";
    UpdateSettingRequestStatus["APPROVED"] = "approved";
    UpdateSettingRequestStatus["REJECTED"] = "rejected";
})(UpdateSettingRequestStatus || (UpdateSettingRequestStatus = {}));
export var UpdateSettingRequestResourceType;
(function (UpdateSettingRequestResourceType) {
    UpdateSettingRequestResourceType["CUSTOMER_ORGANIZATION"] = "customerOrganization";
})(UpdateSettingRequestResourceType || (UpdateSettingRequestResourceType = {}));
