import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Drawer from 'lib/components/Drawer';
import AutomatedAffidavitsTableDrawerHeader from './automatedAffidavitsTableDrawerHeader';
import AutomatedAffidavitsTableDrawerBody from './automatedAffidavitsTableDrawerBody';
export default function AutomatedAffidavitsTableDrawer({ activeOrganization, drawerData, onUploadEEditionClick, onClose }) {
    return (_jsx(Drawer, Object.assign({ open: true, onClose: onClose, header: _jsx(AutomatedAffidavitsTableDrawerHeader, { publicationIssue: drawerData.publicationIssue, activeOrganization: activeOrganization }) }, { children: _jsx(AutomatedAffidavitsTableDrawerBody, { drawerData: drawerData, onUploadEEditionClick: (publicationIssue) => {
                onUploadEEditionClick(publicationIssue);
                onClose();
            } }) })));
}
