import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
import { OccupationType } from 'lib/enums';
export const useDetermineShowPaymentsTab = (user) => {
    const userModel = user
        ? getModelFromSnapshot(UserModel, getFirebaseContext(), user)
        : null;
    const isOnInvoicesRoute = window.location.pathname.startsWith('/payments');
    return (isOnInvoicesRoute ||
        !(userModel === null || userModel === void 0 ? void 0 : userModel.isOccupationType(OccupationType.funeral_director)));
};
