var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { getFirebaseContext } from 'utils/firebase';
import { getErrors, getResponses, wrapError, wrapSuccess } from 'lib/types/responses';
import { safeStringify } from 'lib/utils/stringify';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import PerPublisherSettings, { getValidFilingTypes } from './PerPublisherSettings';
import Placemat from '../../Placemat';
import { getValidationMessageForFilingTypeSelection } from './validation';
function SelectSchedules({ newspaperOrdersFormData, onNewspaperOrdersFormDataChange, onFilingTypeChange, product, isInitialPlacementFlow, editData, orderModel }) {
    const isPublisher = useAppSelector(selectIsPublisher);
    const publishingSettingService = new ProductPublishingSettingsService(getFirebaseContext());
    const { value: newspaperOrdersWithFilingTypes, isLoading: publishingSettingsLoading } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const results = yield Promise.all(newspaperOrdersFormData.map((newspaperOrder) => __awaiter(this, void 0, void 0, function* () {
                const { response: detailedPublishingSettings, error: publishingSettingsError } = yield publishingSettingService.fetchOrCreateDetailedProductPublishingSetting(newspaperOrder.newspaper, product, newspaperOrder.publishingMedium, {
                    shouldCreate: false
                });
                if (publishingSettingsError) {
                    return wrapError(publishingSettingsError);
                }
                const { filingTypes: allFilingTypes } = detailedPublishingSettings;
                return wrapSuccess({
                    newspaperOrder,
                    filingTypes: getValidFilingTypes(allFilingTypes, isPublisher)
                });
            })));
            if (getErrors(results).length > 0) {
                return wrapError(getErrors(results)[0]);
            }
            return wrapSuccess(getResponses(results));
        }),
        initialData: null,
        dependencies: [
            safeStringify(newspaperOrdersFormData.map(no => { var _a; return `${((_a = no.newspaper) === null || _a === void 0 ? void 0 : _a.id, no.publishingMedium)}`; }))
        ]
    });
    if (publishingSettingsLoading || !newspaperOrdersWithFilingTypes) {
        return (_jsx(LoadingState, { context: {
                service: ColumnService.OBITS,
                location: 'Ad placement - Select Schedules',
                tags: {
                    product,
                    adPlacementFlow: 'true',
                    publishingSettingsLoading: publishingSettingsLoading
                        ? 'true'
                        : 'false',
                    newspaperOrdersWithFilingTypes: newspaperOrdersWithFilingTypes
                        ? 'available'
                        : 'null',
                    orderId: orderModel.id
                }
            } }));
    }
    const onNewspaperOrderChange = (newspaperOrder) => {
        onNewspaperOrdersFormDataChange(currentNewspaperOrders => currentNewspaperOrders.map(o => {
            var _a, _b;
            return ((_a = o.newspaper) === null || _a === void 0 ? void 0 : _a.id) === ((_b = newspaperOrder.newspaper) === null || _b === void 0 ? void 0 : _b.id)
                ? Object.assign(Object.assign({}, o), newspaperOrder) : o;
        }));
    };
    const editableNewspaperIds = Object.values(editData || {})
        .filter(ed => ed.canEdit)
        .map(ed => ed.newspaperId);
    const canEditNewspaperOrder = (newspaperOrder) => {
        if (isInitialPlacementFlow) {
            return true;
        }
        return editableNewspaperIds.some(newspaperId => { var _a; return newspaperId === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
    };
    return (_jsx("div", { children: newspaperOrdersFormData.map(newspaperOrder => {
            var _a;
            return (_jsx(Placemat, { children: _jsx(PerPublisherSettings, { product: product, newspaperOrder: newspaperOrder, isPublisher: isPublisher, onNewspaperOrderChange: onNewspaperOrderChange, onFilingTypeChange: onFilingTypeChange, validateFilingTypeSelection: (selection) => getValidationMessageForFilingTypeSelection(selection, newspaperOrdersWithFilingTypes, newspaperOrdersFormData), isInitialPlacementFlow: isInitialPlacementFlow, disableEdits: !canEditNewspaperOrder(newspaperOrder), orderModel: orderModel }) }, (_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id));
        }) }));
}
export default SelectSchedules;
