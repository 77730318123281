import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { BriefcaseIcon, UserIcon } from '@heroicons/react/24/outline';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import FlowChoiceButton from './FlowChoiceButton';
const choices = [
    {
        title: CustomerTypeString.INDIVIDUAL,
        description: 'Place an obituary on behalf of an individual',
        icon: _jsx(UserIcon, { className: "w-14 h-14 stroke-1" })
    },
    {
        title: CustomerTypeString.FUNERAL_HOME,
        description: 'Place an obituary on behalf of a funeral home',
        icon: _jsx(BriefcaseIcon, { className: "w-14 h-14 stroke-1" })
    }
];
function FlowChoiceForm({ onFlowChoiceChange, flowChoice }) {
    return (_jsx(_Fragment, { children: choices.map(({ title, description, icon }) => (_jsx(FlowChoiceButton, { title: title, description: description, icon: icon, onChosenFlowChange: onFlowChoiceChange, checked: title === flowChoice }, title))) }));
}
export default FlowChoiceForm;
