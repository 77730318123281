export const getInputType = (dataType) => {
    switch (dataType) {
        case 'notice-type':
        case 'number': {
            return 'number';
        }
        case 'string':
        default: {
            return 'text';
        }
    }
};
export const isArrayType = (dataType) => {
    switch (dataType) {
        case 'number-array':
        case 'string-array': {
            return true;
        }
        default: {
            return false;
        }
    }
};
export const getArrayInputType = (dataType) => {
    switch (dataType) {
        case 'number-array': {
            return 'number';
        }
        case 'string-array':
        default: {
            return 'text';
        }
    }
};
