import { getEnum } from './enums';
import { AdTypesData, AffidavitStatusTypeData, AutomatedAffidavitNotaries, AutomatedAffidavitParentOrganizationData, AutomatedAffidavitSigners, BankAccountVerificationData, BillingStatusData, BillingTermTypeData, CountryData, CurrencyData, CustomAffidavitData, DayData, DisplaySiteUploadStatusData, DwollaCustomerStatusData, DwollaTransferStatusData, ExportFormatTypeData, FilingTypeVisibilityData, FrequencyData, InviteStatusTypeData, InvoiceItemTypeData, InvoiceStatusData, LabelsData, LeeDisplayAdSizeData, LineItemTypeData, MailCenterData, MailStatusData, NotarizationPreconditionData, NoticeStatusData, NoticeTypeData, NotificationTypeData, OccupationTypeData, OrganizationStatusData, OrganizationTypeData, PayoutStatusTypeData, RateTypeData, RoleTypeData, StateData, StripeMetadataTypeData, SubdomainStatusData, SubscriptionInvoiceItemStatusData, SyncStatusCategoryData, SyncStatusData, TimezoneData, TransactionTypeData, XMLReplacementTagData } from './shared';
import { CourtHouseData } from './CourtHouse';
import { IntegrationTypeData } from './PublisherIntegrationType';
export const enumItemToSelectInput = ({ value, label }) => ({
    value: value.toString(),
    label
});
export const enumToSelectInput = (enumRef) => enumRef.items().map(enumItemToSelectInput);
// BASIC ENUMS, not extended with additional data:
export const AdType = getEnum(AdTypesData);
export const AffidavitStatusType = getEnum(AffidavitStatusTypeData);
export const BankAccountVerification = getEnum(BankAccountVerificationData);
export const BillingStatusType = getEnum(BillingStatusData);
export const BillingTermType = getEnum(BillingTermTypeData);
export const CustomAffidavit = getEnum(CustomAffidavitData);
export const Day = getEnum(DayData);
export const DwollaCustomerStatus = getEnum(DwollaCustomerStatusData);
export const DwollaTransferStatus = getEnum(DwollaTransferStatusData);
export const ELabels = getEnum(LabelsData);
export const Frequency = getEnum(FrequencyData);
export const InviteStatus = getEnum(InviteStatusTypeData);
export const InvoiceItemType = getEnum(InvoiceItemTypeData);
export const SubscriptionInvoiceItemStatus = getEnum(SubscriptionInvoiceItemStatusData);
export const InvoiceStatus = getEnum(InvoiceStatusData);
export const LineItemType = getEnum(LineItemTypeData);
export const LeeDisplayAdSize = getEnum(LeeDisplayAdSizeData);
export const MailStatus = getEnum(MailStatusData);
export const FilingTypeVisibility = getEnum(FilingTypeVisibilityData);
export const PayoutStatusType = getEnum(PayoutStatusTypeData);
export const StripeMetadataType = getEnum(StripeMetadataTypeData);
export const SubdomainStatus = getEnum(SubdomainStatusData);
export const SyncStatusCategory = getEnum(SyncStatusCategoryData);
export const TransactionType = getEnum(TransactionTypeData);
// COMPLEX ENUMS, extended with additional data (requires type arguments to get proper types):
export const AutomatedAffidavitNotary = getEnum(AutomatedAffidavitNotaries);
export const AutomatedAffidavitParentOrganization = getEnum(AutomatedAffidavitParentOrganizationData);
export const AutomatedAffidavitSigner = getEnum(AutomatedAffidavitSigners);
export const Country = getEnum(CountryData);
export const CurrencyType = getEnum(CurrencyData);
export const DisplaySiteUploadStatus = getEnum(DisplaySiteUploadStatusData);
export const ExportFormatType = getEnum(ExportFormatTypeData);
export const IntegrationType = getEnum(IntegrationTypeData);
export const MailCenter = getEnum(MailCenterData);
export const NotarizationPrecondition = getEnum(NotarizationPreconditionData);
export const NoticeStatusType = getEnum(NoticeStatusData);
export const NoticeType = getEnum(NoticeTypeData);
export const NotificationType = getEnum(NotificationTypeData);
export const OccupationType = getEnum(OccupationTypeData);
export const OrganizationStatus = getEnum(OrganizationStatusData);
export const OrganizationType = getEnum(OrganizationTypeData);
export const RateType = getEnum(RateTypeData);
export const RoleType = getEnum(RoleTypeData);
export const State = getEnum(StateData);
export const SyncStatusType = getEnum(SyncStatusData);
export const TimezoneType = getEnum(TimezoneData);
export const XMLReplacementTagType = getEnum(XMLReplacementTagData);
// Not exactly an Enum, but hey, we're open-minded around here and there's plenty of room
export const CourtHouse = CourtHouseData;
export { ConfirmationStatus } from './ConfirmationStatus';
export { ImplementationStatus } from './ImplementationStatus';
export { Product } from './Product';
