import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Product } from 'lib/enums';
import { matchPath } from 'react-router';
import Header from 'components/Header';
import UserNotices from '../notices';
import ObitsTableContainer from './ObitsTableContainer';
import ClassifiedsTableContainer from './ClassifiedsTableContainer';
const OBITUARIES_PATH = '/obituaries';
const CLASSIFIEDS_PATH = '/classifieds';
function getActiveTable(path) {
    if (matchPath(path, { path: OBITUARIES_PATH })) {
        return Product.Obituary;
    }
    if (matchPath(path, { path: CLASSIFIEDS_PATH })) {
        return Product.Classified;
    }
    return Product.Notice;
}
const tabsTables = {
    [Product.Notice]: _jsx(UserNotices, {}),
    [Product.Obituary]: _jsx(ObitsTableContainer, {}),
    [Product.Classified]: _jsx(ClassifiedsTableContainer, {})
};
export default function Ads({ location }) {
    const activeTable = getActiveTable(location.pathname);
    return (_jsxs("div", Object.assign({ className: "flex p-4 gap-3" }, { children: [_jsx(Header, { productLine: activeTable }), _jsx("main", Object.assign({ className: "flex-grow" }, { children: tabsTables[activeTable] }))] })));
}
