var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFirebaseContext } from 'utils/firebase';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import { exists } from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { logError } from 'utils/logger';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { PAGINATION_TABS_TO_STATUSES, getDatesForIssueQueryFromTableFilter } from '../paginationTableUtils';
function getPublishingMediumQuery(organization, product) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!exists(organization)) {
            return wrapSuccess(undefined);
        }
        const organizationModel = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), organization);
        const { response: publishingMediums, error } = yield organizationModel.fetchAvailablePublishingMediums(product);
        if (error) {
            return wrapError(error);
        }
        if (publishingMediums) {
            return wrapSuccess(publishingMediums);
        }
        return wrapSuccess(undefined);
    });
}
export function useGetPublicationIssueQuery(issueFilter, paginationTableTab, activeOrganization, showAllOrgsNotices, availableOrganizations, product) {
    const DEFAULT_PUBLICATION_ISSUE_QUERY = {
        publisherIds: [],
        statuses: [PublicationIssueStatus.PENDING],
        sectionType: product,
        publishingMediums: []
    };
    const activeOrganizationId = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id;
    const getSelectedPublisherIds = () => {
        const publisherIds = showAllOrgsNotices
            ? availableOrganizations.map(org => org.id)
            : activeOrganizationId
                ? [activeOrganizationId]
                : [];
        return publisherIds;
    };
    const { value } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const statuses = PAGINATION_TABS_TO_STATUSES[paginationTableTab.id];
            const updatedDatesForQuery = getDatesForIssueQueryFromTableFilter(issueFilter);
            const publisherIds = getSelectedPublisherIds();
            const { response: publishingMediums, error } = yield getPublishingMediumQuery(activeOrganization, product);
            if (error) {
                logError(error.message, { organizationId: activeOrganizationId });
            }
            const publicationIssueQuery = Object.assign({ publisherIds,
                statuses, publishingMediums: publishingMediums || [], sectionType: product }, updatedDatesForQuery);
            return { publishingMediums, publicationIssueQuery };
        }),
        dependencies: [
            paginationTableTab.id,
            JSON.stringify(issueFilter),
            activeOrganizationId,
            showAllOrgsNotices,
            availableOrganizations.length
        ]
    });
    const { publicationIssueQuery, publishingMediums } = value || {};
    return {
        publicationIssueQuery: publicationIssueQuery || DEFAULT_PUBLICATION_ISSUE_QUERY,
        publishingMediums: publishingMediums || []
    };
}
