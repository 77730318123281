import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { GridInput } from 'lib/components/Card/Grid';
import { InputAccessories } from 'lib/components/InputAccessories';
import { TextField } from 'lib/components/TextField';
import React from 'react';
function EmailInput({ value, onChange, index }) {
    return (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: `automated-bulk-download-destination-email-${index}`, labelText: "", type: "email", value: value, onChange: onChange, required: true }) })));
}
export function DestinationEmail({ value, onChange }) {
    const protectSystemEmails = (email) => {
        if (/(info|help)(\+\S*?)?@column\.us/i.test(email)) {
            return 'This email address is reserved for system use. Please use a different email address.';
        }
        return '';
    };
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "automated-bulk-download-reply-to-email", labelText: "Where should replies to the bulk download email go?", type: "email", value: value.replyTo, onChange: replyTo => onChange(Object.assign(Object.assign({}, value), { replyTo })), errorText: protectSystemEmails(value.replyTo), required: true }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InputAccessories, Object.assign({ labelText: "Where should we send the bulk download?", id: "automated-bulk-download-destination-emails" }, { children: _jsx(EditableCardArray, { Component: EmailInput, baseHeader: 'EMAIL', onChange: to => onChange(Object.assign(Object.assign({}, value), { to })), values: value.to, archivable: {
                            buttonText: 'Remove email',
                            shouldAllow: () => value.to.length > 1
                        }, createable: value.to.length < 5
                            ? { buttonText: 'Add email', initialValue: '' }
                            : undefined, background: "white" }) })) }))] }));
}
