/**
 * FINALIZED_DISPLAY_AD is a single file that represents the entirety of a display notice that is to be published
 *
 * DISPLAY_AD_COMPONENT is a file that constitutes part of a display notice that must be further built before being published
 *
 * SUPPLEMENTAL_UPLOAD is a file, typically uploaded via Zapier, that is not part of the notice content itself, but rather used to verify the information in or the legal compliance of a notice
 *
 * TEXT_FILE is a file, e.g. a Word file, RTF, or plain text file, that is uploaded and used as the notice content for a *liner* ad
 *
 *
 * A file that is originally classified as a FINALIZED_DISPLAY_AD or a TEXT_FILE will be reclassified as a DISPLAY_AD_COMPONENT if multiple files are uploaded or if the file is otherwise submitted without formatting.
 *
 * When we receive a response from a build integration (AffinityX), we will set the built ad as a FINALIZED_DISPLAY_AD, which will then replace the existing files upon the next notice edit.
 */
export var NoticeFileTypes;
(function (NoticeFileTypes) {
    NoticeFileTypes["finalized_display_ad"] = "finalized display ad";
    NoticeFileTypes["display_ad_component"] = "display ad component";
    NoticeFileTypes["supplemental_upload"] = "supplemental upload";
    NoticeFileTypes["text_file"] = "text file";
})(NoticeFileTypes || (NoticeFileTypes = {}));
export const isLegacySchemaData = (noticeFile) => {
    return !!noticeFile.legacySchema;
};
export const isLegacySchema = (noticeFileSnap) => {
    return isLegacySchemaData(noticeFileSnap.data());
};
export const isNoticeContentData = (noticeFile) => {
    if (!noticeFile)
        return false;
    const isValidType = [
        NoticeFileTypes.display_ad_component,
        NoticeFileTypes.finalized_display_ad,
        NoticeFileTypes.text_file
    ].includes(noticeFile.type);
    const isNotLegacy = !isLegacySchemaData(noticeFile);
    return isValidType && isNotLegacy;
};
export const isNoticeContent = (noticeFileSnap) => {
    return isNoticeContentData(noticeFileSnap.data());
};
export const isSupplementalUploadData = (noticeFile) => {
    const isValidType = noticeFile.type === NoticeFileTypes.supplemental_upload;
    const isNotLegacy = !isLegacySchemaData(noticeFile);
    return isValidType && isNotLegacy;
};
export const isSupplementalUpload = (noticeFileSnap) => {
    return isSupplementalUploadData(noticeFileSnap.data());
};
