import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Provider } from 'react-redux';
import SuspenseErrorBoundary from 'components/SuspenseErrorBoundary';
import LoadingState from 'components/LoadingState';
import GlobalToast from 'components/Toast/Toast';
import { Product } from 'lib/enums';
import { STRIPE_VARS } from './constants';
import EnoticeTheme from './Theme';
import Router from './Router';
import Header from './components/Header';
export default function App({ store }) {
    const [stripe, setStripe] = useState(null);
    const setStripeAccount = () => {
        if (window.Stripe) {
            setStripe(window.Stripe(STRIPE_VARS.key));
        }
        else {
            const script = document.querySelector('#stripe-js');
            if (script) {
                script.addEventListener('load', () => {
                    setStripe(window.Stripe(STRIPE_VARS.key));
                });
            }
            else {
                console.log('Stripe not loaded correctly');
            }
        }
    };
    useEffect(() => {
        setStripeAccount();
    }, []);
    return (_jsx("div", Object.assign({ className: "App" }, { children: _jsx(StripeProvider, Object.assign({ stripe: stripe }, { children: _jsxs(Provider, Object.assign({ store: store }, { children: [_jsx(EnoticeTheme, { children: _jsx(SuspenseErrorBoundary, { children: _jsxs(Suspense, Object.assign({ fallback: _jsx(LoadingState, {}) }, { children: [_jsx(Router, {}), _jsx(GlobalToast, {})] })) }) }), _jsx(Header, { productLine: Product.Notice })] })) })) })));
}
