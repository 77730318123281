import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import LinkTo from 'components/LinkTo';
/**
 * Navbar link in the header to items like notices, invoices, etc.
 */
export default function HeaderNavBarLink({ label, icon, path, id }) {
    const isActive = window.location.pathname.startsWith(path.split('?')[0]);
    return (_jsxs(LinkTo, Object.assign({ id: id, href: path, className: classNames('flex font-medium h-full p-3 cursor-pointer hover:bg-primary-50 hover:text-primary-500 rounded-md', {
            'text-column-gray-400': !isActive,
            'text-primary-500': isActive
        }) }, { children: [icon, _jsx("span", Object.assign({ className: "ml-2" }, { children: label }))] })));
}
