import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ColumnDatePicker from 'components/ColumnDatePicker';
import { Buddy } from 'lib/components/gifs';
import { Label } from 'lib/components/Label';
import { getDeadlineTimeForRegularDeadline } from 'lib/utils/deadlines';
import moment from 'moment';
import React, { useState } from 'react';
import { DeadlinePreviewRow } from './PreviewRow';
export function DeadlinePreview({ fixedPreviewDeadline, deadlineSettings, timezone, fixedPreviewDate }) {
    const { displayOffset, publish } = deadlineSettings;
    const [exampleDate, setExampleDate] = useState(fixedPreviewDate !== null && fixedPreviewDate !== void 0 ? fixedPreviewDate : moment()
        // Our Day enum values are 1-indexed, while moment & JS Date are 0-indexed.
        .set({ day: deadlineSettings.dayEnum - 1 })
        // Add a week so the example publication date is definitely in the future.
        .add({ week: 1 })
        .toDate());
    const linerDeadline = fixedPreviewDeadline
        ? moment(fixedPreviewDeadline)
        : getDeadlineTimeForRegularDeadline(exampleDate, deadlineSettings, timezone);
    const displayDeadline = displayOffset
        ? linerDeadline.clone().subtract({ hours: displayOffset })
        : undefined;
    if (!publish) {
        return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 items-center my-14" }, { children: [_jsx("div", Object.assign({ className: "w-14 h-14 rounded-full bg-column-primary-100 p-2" }, { children: _jsx("img", { src: Buddy }) })), _jsx("p", Object.assign({ className: "text-center text-xs text-column-gray-400 font-medium" }, { children: "Preview will show up when you enable publication day" }))] })));
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [!fixedPreviewDate && (_jsxs(Label, Object.assign({ id: "example-deadline-date-picker" }, { children: [_jsx("p", Object.assign({ className: "mb-3" }, { children: "Select sample publication date" })), _jsx(ColumnDatePicker, { format: "mm/dd/yy", momentFormat: "MM/DD/YY", placeholderText: "", shouldDisableDate: date => (date === null || date === void 0 ? void 0 : date.getDay()) !== deadlineSettings.dayEnum - 1, value: exampleDate, onChange: newDate => {
                            if (newDate) {
                                setExampleDate(newDate);
                            }
                        }, className: "p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary" })] }))), _jsxs("div", { children: [fixedPreviewDate && (_jsx(DeadlinePreviewRow, { label: "Publication date", date: moment(fixedPreviewDate), firstRow: true })), _jsx(DeadlinePreviewRow, { label: displayDeadline ? 'Deadline for liners' : 'Deadline', date: linerDeadline, showTime: true }), displayDeadline && (_jsx(DeadlinePreviewRow, { label: "Deadline for displays", date: displayDeadline, showTime: true }))] })] })));
}
