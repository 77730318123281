var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import { getPublisherOrgTemplatesQueries } from 'lib/utils/templates';
import React, { useEffect, useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { getFirebaseContext } from 'utils/firebase';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SAMPLE_NOTICE_COLUMN_OPTIONS, SAMPLE_NOTICE_CONTENT_OPTIONS, SAMPLE_NOTICE_PUBLICATION_OPTIONS, generateNoticePreview } from '../shared/previews';
import { PreviewContainer } from '../shared/PreviewContainer';
export function Preview() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState();
    useEffect(() => {
        if (!exists(activeOrganization)) {
            return;
        }
        const { ownedTemplatesQuery } = getPublisherOrgTemplatesQueries(getFirebaseContext(), activeOrganization.ref);
        return ownedTemplatesQuery.onSnapshot((snapshot) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const defaultTemplate = yield ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.get());
            if (exists(defaultTemplate)) {
                setTemplate(defaultTemplate);
            }
            setTemplates(snapshot.docs);
        }));
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    const { value: defaultContent } = SAMPLE_NOTICE_CONTENT_OPTIONS[0];
    const [previewContent, setPreviewContent] = useState(defaultContent);
    const [columnWidth, setColumnWidth] = useState(1);
    const [totalRuns, setTotalRuns] = useState(1);
    const { value: preview, isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!exists(template) || !exists(activeOrganization)) {
                return;
            }
            return generateNoticePreview(template, activeOrganization.data(), {
                previewContent,
                outputToBase64: true,
                totalRuns,
                columnWidth,
                format: 'jpg'
            });
        }),
        dependencies: [template === null || template === void 0 ? void 0 : template.id, previewContent, columnWidth, totalRuns]
    });
    return (_jsxs(_Fragment, { children: [_jsx(SettingsHeader, { header: "Preview", description: "See what notices will look like with your current template and layout settings." }), _jsxs(CardGridLayout, { children: [_jsx(GridInput, { children: _jsx(ColumnSelect, { options: templates.map(template => ({
                                label: template.data().name,
                                value: template.id
                            })), value: template === null || template === void 0 ? void 0 : template.id, onChange: templateId => {
                                if (!templateId) {
                                    return;
                                }
                                const matchedTemplate = templates.find(template => template.id === templateId);
                                if (matchedTemplate) {
                                    setTemplate(matchedTemplate);
                                }
                            }, id: "preview-template", labelText: "Select a template to preview" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "preview-content", labelText: "Notice Preview Content", onChange: setPreviewContent, options: SAMPLE_NOTICE_CONTENT_OPTIONS, value: previewContent }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "preview-columns", labelText: "Column width", onChange: stringValue => setColumnWidth(Number(stringValue)), options: SAMPLE_NOTICE_COLUMN_OPTIONS, value: columnWidth.toString() }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "preview-total-runs", labelText: "Number of publications", onChange: stringValue => setTotalRuns(Number(stringValue)), options: SAMPLE_NOTICE_PUBLICATION_OPTIONS, value: totalRuns.toString() }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(PreviewContainer, { labelText: "Template preview", preview: isLoading ? null : preview }) }))] })] }));
}
