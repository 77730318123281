import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';
export function Alert({ id, title, description, icon, children, status, onDismiss }) {
    // Open state is controlled by parent
    // except for when the onDismiss is specified and the user closes the alert
    const [showAlert, setShowAlert] = useState(true);
    const alertBaseClasses = classNames('flex space-between p-4 rounded-lg text-sm', {
        'bg-gray-50 text-grey-400': !status,
        'bg-primary-25 text-primary-600': status === 'info',
        'bg-red-50 text-red-600': status === 'error',
        'bg-yellow-50 text-yellow-600': status === 'warning',
        'bg-green-50 text-green-600': status === 'success',
        'font-medium': !title
    });
    const alertTitleClasses = classNames('font-medium', {
        'text-grey-500': !status,
        'text-primary-700': status === 'info',
        'text-red-700': status === 'error',
        'text-yellow-700': status === 'warning',
        'text-green-700': status === 'success'
    });
    const labelProps = {
        role: onDismiss ? 'alertdialog' : 'alert',
        'aria-labelledby': labelId(id),
        'aria-describedby': descriptionId(id)
    };
    return showAlert ? (_jsxs("div", Object.assign({}, labelProps, { className: alertBaseClasses }, { children: [icon && _jsx("div", Object.assign({ className: "pr-3 self-center" }, { children: icon })), _jsxs("div", Object.assign({ className: "w-full" }, { children: [title && (_jsx("h1", Object.assign({ id: labelId(id), className: alertTitleClasses }, { children: title }))), _jsx("div", Object.assign({ id: descriptionId(id) }, { children: description })), children] })), !!onDismiss && (_jsx("div", Object.assign({ className: "pt-1" }, { children: _jsxs("button", Object.assign({ type: "button", onClick: () => {
                        setShowAlert(false);
                        onDismiss && onDismiss();
                    }, className: `rounded-md ${alertTitleClasses}` }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Close" })), _jsx(XMarkIcon, { className: "h-4 w-4" })] })) })))] }))) : (_jsx(_Fragment, {}));
}
function labelId(id) {
    return `alert-${id}-label`;
}
function descriptionId(id) {
    return `alert-${id}-description`;
}
