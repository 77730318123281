import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import PlacementActions from 'redux/placement';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useAppDispatch } from 'redux/hooks';
export function SelectContentPrepared({ value, onChange }) {
    const dispatch = useAppDispatch();
    return (_jsx(ColumnSelect, { id: "content-prepared-select", options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
        ], onChange: newValue => {
            if (newValue === 'no' && value === 'yes') {
                dispatch(PlacementActions.clearNoticeType());
            }
            onChange(newValue);
        }, value: value, labelText: "Would you like some help writing your notice?", placeholder: "Select yes or no", allowUndefined: true }));
}
