import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNoticeFilingType } from 'lib/types/filingType';
import { FilingTypeVisibility, enumToSelectInput } from 'lib/enums';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { last } from 'lodash';
import { FilingTypeLabelField } from './FilingTypeLabelField';
export default function BasicFilingTypePropertiesCard({ onUpdateFilingType, updatedFilingType, productLine }) {
    var _a, _b, _c;
    const productNameSingular = last(PRODUCT_TO_NAME[productLine].singular.toLocaleLowerCase().split(' '));
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Basic Properties',
            description: `Configure basic properties about this ${productNameSingular} type.`
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(FilingTypeLabelField, { onUpdateFilingType: onUpdateFilingType, updatedFilingType: updatedFilingType, productLine: productLine }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "select-filing-type-visibility", options: enumToSelectInput(FilingTypeVisibility), labelText: `Who should be able to view this ${productNameSingular} type and select it for placement?`, onChange: newValue => {
                        const newFilingType = Object.assign({}, updatedFilingType);
                        if (isNoticeFilingType(newFilingType)) {
                            delete newFilingType.disabled;
                        }
                        if (newValue) {
                            newFilingType.visibility = parseInt(newValue, 10);
                        }
                        onUpdateFilingType(newFilingType);
                    }, value: (_b = (_a = updatedFilingType.visibility) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : (_c = FilingTypeVisibility.by_key('all_users')) === null || _c === void 0 ? void 0 : _c.value.toString() }) })), isNoticeFilingType(updatedFilingType) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Show note field during placement?", description: "If enabled, users will be allowed to provide an optional note when placing this notice type.", value: updatedFilingType.showDesignNotes || false, onChange: newValue => onUpdateFilingType(Object.assign(Object.assign({}, updatedFilingType), { showDesignNotes: newValue })) }) })))] })));
}
