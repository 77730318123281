var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { getCustomerName, getCustomerOrganizationName, getCustomerAndCustomerOrganization } from '../notice/customer';
import { dbToUICurrencyString } from '../pricing/ui';
import { htmlToIndesignHtml, shouldPreserveLongSequencesForNewspaper } from '../indesign/helpers';
import { dateTimeLikeToDate } from '../date';
import { EHandlebars } from './shared';
import { DateParseError, UnknownDateFormat } from '../errors/NoticePreviewErrors';
import { getDisplayName, getNoticeTypeFromNoticeData } from '../helpers';
import { exists } from '../types';
import { getLaunchDarklyContext } from '../utils/flags';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
const generateV1Footer = (footerFormatString, notice) => {
    if (!footerFormatString)
        return '';
    const formatFooterString = (match) => {
        // {{***}} -> ***
        const footerTag = match.slice(2, -1);
        const formatType = footerTag.split(' ')[0];
        if (formatType === 'SQUASH') {
            let monthFormat;
            let dayFormat;
            let yearFormat;
            try {
                // ...(***)... -> ***
                monthFormat = footerTag.match(/\(.*?\)/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('month', footerTag);
            }
            try {
                // ...[***]... -> ***
                dayFormat = footerTag.match(/\[.*?\]/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('day', footerTag);
            }
            try {
                // ...|***|... -> ***
                yearFormat = footerTag.match(/\|.*?\|/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('year', footerTag);
            }
            const firstDate = notice.publicationDates.map(dateTimeLikeToDate)[0];
            let currentYear = firstDate.getFullYear();
            let currentMonth = firstDate.getMonth();
            let formattedString = `${moment(firstDate).format(monthFormat)}`;
            for (const timestamp of notice.publicationDates) {
                const date = dateTimeLikeToDate(timestamp);
                if (date.getFullYear() !== currentYear) {
                    formattedString += `${moment(firstDate).format(yearFormat)}, `;
                }
                if (date.getMonth() !== currentMonth) {
                    formattedString += moment(date).format(monthFormat);
                }
                formattedString += moment(date).format(dayFormat);
                currentYear = date.getFullYear();
                currentMonth = date.getMonth();
            }
            const lastDate = dateTimeLikeToDate(notice.publicationDates[notice.publicationDates.length - 1]);
            formattedString += moment(lastDate).format(yearFormat);
            return formattedString;
        }
        if (formatType === 'DATE') {
            let format;
            let separator;
            try {
                // ...(***)... -> ***
                format = footerTag.match(/\(.*?\)/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('date', footerTag);
            }
            try {
                // ...[***]... -> ***
                separator = footerTag.match(/\[.*?\]/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('separator', footerTag);
            }
            return (notice.publicationDates || [])
                .map(t => `${moment(dateTimeLikeToDate(t)).format(format)}`)
                .join(separator);
        }
        throw new UnknownDateFormat(match);
    };
    return footerFormatString.replace(/{{.*?}}/g, formatFooterString);
};
export const addFooterXML = (footer) => {
    const xmlMarkupRegex = /<\?xml\s+version="1\.0"\s+encoding="UTF-8"\?>\s*<dynamic-footer[^>]*>.*<\/dynamic-footer>/;
    if (xmlMarkupRegex.test(footer)) {
        return footer; // Return the string and do not wrap again as it already contains the XML markup.
    }
    return `<?xml version="1.0" encoding="UTF-8"?><dynamic-footer xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/">${footer}</dynamic-footer>`;
};
export const removeFooterXML = (footer) => {
    const regex = /(?<=<dynamic-footer[^>]*>)(.*?)(?=<\/dynamic-footer>)/;
    const match = footer.match(regex);
    return match ? match[0] : footer;
};
export const createCustomFooter = (dynamicFooter, customId, dbPricing, oldCustomId) => {
    let customFooter = dynamicFooter || '';
    // reset footer
    if (oldCustomId) {
        customFooter = customFooter.replace(oldCustomId, '#'.repeat(6));
    }
    // replace in custom ID
    if (customId) {
        customFooter = customFooter.replace('#'.repeat(6), customId);
    }
    // replace in for total price
    if (dbPricing === null || dbPricing === void 0 ? void 0 : dbPricing.subtotal) {
        customFooter = customFooter.replace('*'.repeat(6), `${(dbPricing.subtotal / 100).toFixed(2)}`);
    }
    return customFooter;
};
export const generateFormattedFooter = (ctx, notice, pricing, DOMparser) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    if (!((_a = notice.publicationDates) === null || _a === void 0 ? void 0 : _a.length)) {
        return '';
    }
    if (!notice.newspaper) {
        return '';
    }
    const newspaper = yield notice.newspaper.get();
    if (!exists(newspaper)) {
        return '';
    }
    const footerFormatString = notice.publicationDates.length === 1 && ((_b = newspaper.data()) === null || _b === void 0 ? void 0 : _b.oneRunFooter)
        ? (_c = newspaper.data()) === null || _c === void 0 ? void 0 : _c.oneRunFooter
        : (_d = newspaper.data()) === null || _d === void 0 ? void 0 : _d.footerFormatString;
    if (!footerFormatString) {
        return '';
    }
    let footer;
    const isV1Footer = footerFormatString.slice(0, 3) === 'V1:';
    if (isV1Footer) {
        footer = generateV1Footer(footerFormatString.slice(3, footerFormatString.length), notice);
    }
    else {
        const compiled = EHandlebars.compile(footerFormatString);
        let filerName = '';
        let filerOrgName = '';
        /**
         * Even though filer is technically required on the notice,
         * This check is still necessary because we sometimes call
         * this function on a notice before a filer has been set.
         * See: generateDailyGazetteFooter
         */
        if (notice.filer) {
            const { customer, customerOrganization } = yield getCustomerAndCustomerOrganization(ctx, {
                filer: notice.filer,
                filedBy: notice.filedBy,
                newspaper: notice.newspaper
            });
            const user = yield notice.filer.get();
            if (exists(user)) {
                /**
                 * NOTE: filerName and filerOrgName are inaccurate
                 * variable names because they actually point to customer information,
                 * but in order to not mess up existing naming assumptions
                 * for the implementation team and further downstream in the doc
                 * generation process, we keep the names as is for now.
                 */
                // Hierarchy: customer name -> user name
                filerName = exists(customer)
                    ? getCustomerName(customer, user, true)
                    : getDisplayName(user.data().firstName, user.data().lastName);
                const filerOrg = yield ((_e = notice.filedBy) === null || _e === void 0 ? void 0 : _e.get());
                // Hierarchy: customerOrg name -> customer.orgName
                filerOrgName = exists(customer)
                    ? getCustomerOrganizationName(customerOrganization !== null && customerOrganization !== void 0 ? customerOrganization : null, customer)
                    : ((_f = filerOrg === null || filerOrg === void 0 ? void 0 : filerOrg.data()) === null || _f === void 0 ? void 0 : _f.name) || '';
            }
        }
        let price = '';
        let subtotalPrice = '';
        let totalPrice = '';
        if (pricing) {
            price = dbToUICurrencyString(pricing.subtotal);
            subtotalPrice = dbToUICurrencyString(pricing.subtotal);
            totalPrice = dbToUICurrencyString(pricing.total);
        }
        const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
        footer = compiled({
            dates: notice.publicationDates,
            subtotalPrice: subtotalPrice || '#.##',
            // *TODO: (see comment above), should this be `noticeType?.label || 'Public Notice'`?
            noticeType: noticeType || 'Public Notice',
            filerOrgName,
            filerName,
            totalPrice: totalPrice || '#.##',
            price: price || '#.##'
        });
    }
    const fixedFooter = createCustomFooter(footer, notice.customId, notice.pricing);
    const preserveLongSequences = yield shouldPreserveLongSequencesForNewspaper(newspaper);
    const adjustTableWidths = yield getLaunchDarklyContext().getBooleanFeatureFlag(LaunchDarklyFlags.ADJUST_TABLE_WIDTHS_IN_BULK_DOWNLOAD_FILE, {
        type: 'organization',
        snapshot: newspaper,
        defaultValue: false
    });
    return htmlToIndesignHtml(fixedFooter, DOMparser, {
        isFirstPHeading: false,
        preserveLongSequences,
        adjustTableWidths
    }, {});
});
