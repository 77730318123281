var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { apiPost } from 'api/typed';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Modal } from 'lib/components/Modal';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { cdnIfy } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';
import { logAndCaptureException } from 'utils';
export function BulkPaymentReceiptView({ ledgerItemId, onClose }) {
    const { value: receiptUrl, isLoading, isError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!ledgerItemId) {
                return null;
            }
            const { response: storagePath, error: errorMessage } = yield apiPost('ledger/receipt', { ledgerItemId });
            if (errorMessage !== null) {
                const error = new Error(errorMessage);
                logAndCaptureException(ColumnService.PAYMENTS, error, 'Failed to get receipt after bulk payment', { ledgerItemId });
                throw error;
            }
            return cdnIfy(storagePath);
        }),
        dependencies: []
    });
    return (_jsx(Modal, Object.assign({ id: "bulk-pay-invoices-modal", onClose: onClose, title: ledgerItemId ? 'Payment Receipt' : 'Pay Invoices', primaryAction: {
            onClick: onClose,
            buttonText: 'Close',
            type: 'button'
        }, secondaryActions: [
            {
                onClick: () => receiptUrl && window.open(receiptUrl, '_blank'),
                buttonText: 'Download Receipt',
                type: 'button',
                loading: !receiptUrl
            }
        ], size: "3xl" }, { children: _jsxs("div", Object.assign({ className: "h-80" }, { children: [isLoading && _jsx(LoadingSpinner, {}), isError && _jsx("div", { children: "Error loading receipt" }), receiptUrl && (_jsx("embed", { className: "w-full h-full", src: `${receiptUrl}#toolbar=0&navpanes=0&scrollbar=0`, type: "application/pdf" }))] })) })));
}
