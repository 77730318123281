var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicationIssueAttachmentModel } from '../model/objects/publicationIssueAttachmentModel';
import { PublicationIssueAttachmentStatus } from '../types/publicationIssueAttachment';
import { getModelFromRef } from '../model';
export const createPublicationIssueAttachment = (ctx, params) => __awaiter(void 0, void 0, void 0, function* () {
    const { publicationIssueId, storagePath, downloadUrl, name, type, metadata } = params;
    const publicationIssueRef = ctx
        .publicationIssuesRef()
        .doc(publicationIssueId);
    const publicationIssueAttachmentRef = yield ctx
        .publicationIssueAttachmentsRef(publicationIssueRef)
        .add({
        name,
        storagePath,
        downloadUrl,
        notes: [],
        metadata: metadata || {},
        type,
        status: PublicationIssueAttachmentStatus.PENDING,
        statusChanges: [],
        createdAt: ctx.fieldValue().serverTimestamp(),
        modifiedAt: ctx.fieldValue().serverTimestamp()
    });
    const publicationIssueAttachment = yield getModelFromRef(PublicationIssueAttachmentModel, ctx, publicationIssueAttachmentRef);
    return publicationIssueAttachment;
});
