import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import htmlToText from 'html-to-text';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
export function ClassifiedsOverviewRow(props) {
    var _a, _b, _c;
    const { classified } = props;
    const orderConfirmed = classified.newspaperorderstatus === NewspaperOrderStatus.CONFIRMED;
    const title = htmlToText.fromString((_b = (_a = classified.title) === null || _a === void 0 ? void 0 : _a.replace(/,/g, '')) !== null && _b !== void 0 ? _b : '');
    const customerName = `${classified.filerfirstname} ${classified.filerlastname}`;
    const category = (_c = classified.filingtype) !== null && _c !== void 0 ? _c : '';
    const renderTableData = (data, widthClass) => (_jsx("td", Object.assign({ className: classNames(widthClass, {
            'text-column-gray-300': !orderConfirmed
        }) }, { children: _jsx(Tooltip, Object.assign({ helpText: !orderConfirmed
                ? 'You must confirm the order for this to be included in the publication issue.'
                : '' }, { children: _jsx(_Fragment, { children: data }) })) })));
    const optionClasses = 'flex items-center justify-center gap-2 cursor-pointer rounded p-3 hover:bg-column-primary-50 hover:text-column-primary-500 focus:outline-none transition ease-in duration-100';
    const onClick = () => {
        window.open(`/classified/${classified.orderid}`, '_blank');
    };
    return (_jsxs(_Fragment, { children: [renderTableData(title, 'w-24'), renderTableData(customerName, 'w-40'), renderTableData(category, 'w-40'), _jsx("td", Object.assign({ className: "w-12" }, { children: _jsx("div", Object.assign({ className: optionClasses, onClick: onClick }, { children: _jsx(ArrowUpRightIcon, { className: "w-4 h-4" }) })) }))] }));
}
