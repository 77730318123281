import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Editor from '@monaco-editor/react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { DEFAULT_EDITOR_OPTIONS } from '../htmlHelpers';
export default function AffidavitTemplateEditorCard({ onChangeAffidavitTemplateEditData, affidavitTemplateEditData }) {
    return (_jsx(CardGridLayout, Object.assign({ header: { title: 'Affidavit Template' } }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Editor, { height: "80vh", defaultLanguage: "html", defaultValue: affidavitTemplateEditData.affidavitTemplateHTML, onChange: newContent => {
                    if (newContent) {
                        onChangeAffidavitTemplateEditData(Object.assign(Object.assign({}, affidavitTemplateEditData), { affidavitTemplateHTML: newContent }));
                    }
                }, options: DEFAULT_EDITOR_OPTIONS }) })) })));
}
