import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import ExistingUserRegistrationHeader from './ExistingUserRegistrationHeader';
import NewUserRegistrationHeader from './NewUserRegistrationHeader';
import BackgroundImg from './backgroundImg.svg';
import { shouldShowPublisherRegistrationOption, hasUserCompletedRegistration } from './registrationLayoutHelpers';
export default function RegistrationLayoutFrame({ children }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const user = useAppSelector(selectUser);
    const userHasCompletedRegistration = hasUserCompletedRegistration(user, activeOrganization);
    const showPublisherRegistrationOption = shouldShowPublisherRegistrationOption(user);
    return (_jsxs("div", Object.assign({ className: "bg-base-2 overflow-y-scroll h-screen w-screen", style: { backgroundImage: `url(${BackgroundImg})` } }, { children: [userHasCompletedRegistration ? (_jsx(ExistingUserRegistrationHeader, {})) : (_jsx(NewUserRegistrationHeader, {})), _jsx("div", Object.assign({ className: classNames('mx-auto', {
                    'max-w-6xl': showPublisherRegistrationOption,
                    'max-w-4xl': !showPublisherRegistrationOption,
                    'mt-32': !userHasCompletedRegistration,
                    'mt-24': userHasCompletedRegistration
                }) }, { children: children }))] })));
}
