var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useMemo } from 'react';
import Editor from '@monaco-editor/react';
import Handlebars from 'handlebars';
import { PlusCircleIcon, ExclamationCircleIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import { exists } from 'lib/types';
import * as headers from 'lib/headers_footers/headers';
import { getNoticeType } from 'lib/helpers';
import { requestDisplayParameters } from 'lib/indesign/request';
import { getFirebaseContext } from 'utils/firebase';
import NoticePreviewContainer from 'components/noticePreview/NoticePreviewContainer';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import LoadingState from 'components/LoadingState';
import { createDBPricingObject } from 'lib/pricing';
import NoticeEditorMadlib from 'routes/madlib/components/NoticeEditorMadlib';
import { getIndesignServerClient } from 'utils/indesign';
import { useAppSelector } from 'redux/hooks';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import { safeStringify } from 'lib/utils/stringify';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import MadlibFieldModal from './MadlibFieldModal';
const DEFAULT_QUESTIONS = [
    {
        type: 'yesno',
        varName: 'example',
        prompt: 'Yes or no?'
    }
];
const DEFAULT_TEMPLATE_HTML = '<div>Hello World</div>\n<div>Answer: {{example}}</div>';
const DEFAULT_EDITOR_OPTIONS = {
    wordWrap: 'on',
    minimap: {
        enabled: false
    }
};
const insertText = (monacoInstance, text) => {
    const selection = monacoInstance.getSelection();
    const id = { major: 1, minor: 1 };
    const op = {
        identifier: id,
        range: {
            startLineNumber: (selection === null || selection === void 0 ? void 0 : selection.selectionStartLineNumber) || 1,
            startColumn: (selection === null || selection === void 0 ? void 0 : selection.selectionStartColumn) || 1,
            endLineNumber: (selection === null || selection === void 0 ? void 0 : selection.endLineNumber) || 1,
            endColumn: (selection === null || selection === void 0 ? void 0 : selection.endColumn) || 1
        },
        text,
        forceMoveMarkers: true
    };
    monacoInstance.executeEdits('my-source', [op]);
};
const replaceText = (monacoInstance, text) => {
    var _a;
    (_a = monacoInstance.getModel()) === null || _a === void 0 ? void 0 : _a.setValue(text);
};
function Madlib() {
    var _a;
    const activeOrganization = useAppSelector((state) => state.auth.activeOrganization);
    const [renderedHtml, setRenderedHtml] = useState('');
    // for generating preview
    const [notice, setNotice] = useState();
    const [columns, setColumns] = useState(1);
    const [noticeData, setNoticeData] = useState();
    const [previewLoading, setPreviewLoading] = useState(false);
    const [idError, setIDError] = useState('');
    // Get the last session from localStorage
    const storedQuestions = useMemo(() => {
        const str = localStorage.getItem('madlibs_questions');
        if (str) {
            return JSON.parse(str);
        }
        return undefined;
    }, []);
    const storedTemplate = useMemo(() => {
        var _a;
        return (_a = localStorage.getItem('madlibs_template')) !== null && _a !== void 0 ? _a : undefined;
    }, []);
    const [questions, setQuestions] = useState(storedQuestions !== null && storedQuestions !== void 0 ? storedQuestions : DEFAULT_QUESTIONS);
    const [template, setTemplate] = useState(storedTemplate !== null && storedTemplate !== void 0 ? storedTemplate : DEFAULT_TEMPLATE_HTML);
    const [questionsValid, setQuestionsValid] = useState(true);
    const [templateValid, setTemplateValid] = useState(true);
    const [previewContent, setPreviewContent] = useState({
        displayParams: {},
        price: '0'
    });
    const [madlibData, setMadlibData] = useState({
        templateData: {},
        questionTemplateData: {},
        metadata: { noticeName: null, noticePrice: null }
    });
    const [jsonEditor, setJsonEditor] = useState();
    const [handlebarsEditor, setHandlebarsEditor] = useState();
    const [selectedQuestionType, setSelectedQuestionType] = useState('yesno');
    const [showFieldModal, setShowFieldModal] = useState(false);
    const debouncedTemplate = useDebounce(template, 1000);
    const debouncedRenderedHtml = useDebounce(renderedHtml, 1000);
    const ctx = getFirebaseContext();
    const GENERIC_ID_ERROR = "Whoops, we're having trouble loading the preview. Please refresh the page. If that doesn't help email help@column.us and we will assist you!";
    useEffect(() => {
        const fetchData = () => __awaiter(this, void 0, void 0, function* () {
            if (!exists(notice)) {
                return;
            }
            const rateSnap = yield notice.data().rate.get();
            if (!exists(rateSnap)) {
                return;
            }
            setNoticeData({
                rateSnap,
                notice: notice
            });
        });
        void fetchData();
    }, [notice === null || notice === void 0 ? void 0 : notice.id, (_a = notice === null || notice === void 0 ? void 0 : notice.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id, notice === null || notice === void 0 ? void 0 : notice.data().rate.id]);
    const updatePreview = (html) => __awaiter(this, void 0, void 0, function* () {
        var _b;
        if (!html || !noticeData) {
            return;
        }
        try {
            setPreviewLoading(true);
            let displayParams = {};
            try {
                if (!exists(notice))
                    return;
                yield notice.ref.update({
                    confirmedHtml: html
                });
                const updatedNotice = yield notice.ref.get();
                displayParams = yield requestDisplayParameters(getFirebaseContext(), getIndesignServerClient(), updatedNotice, window.DOMParser);
            }
            catch (err) {
                setPreviewLoading(false);
                setIDError(GENERIC_ID_ERROR);
                return;
            }
            const dbPricingObject = yield createDBPricingObject(getFirebaseContext(), noticeData.notice, displayParams, 
            /* rateOverride= */ undefined);
            const noticePreviewPrice = ((_b = madlibData.metadata) === null || _b === void 0 ? void 0 : _b.noticePrice)
                ? (madlibData.metadata.noticePrice / 100).toFixed(2)
                : dbPricingObject.total.toFixed(2);
            setPreviewContent({
                displayParams,
                price: noticePreviewPrice
            });
            if (displayParams.columns && displayParams.columns !== columns) {
                setColumns(displayParams.columns);
            }
            setIDError('');
        }
        catch (err) {
            console.error(err);
            setIDError(GENERIC_ID_ERROR);
        }
        setPreviewLoading(false);
    });
    useEffect(() => {
        void updatePreview(renderedHtml);
    }, [debouncedRenderedHtml]);
    // Save the template and questions to local storage
    // in case of an error
    useEffect(() => {
        localStorage.setItem('madlibs_template', template);
        localStorage.setItem('madlibs_questions', JSON.stringify(questions));
    }, [debouncedTemplate, safeStringify(questions)]);
    const getAppropriateHeader = (dates, activeOrganization) => {
        if (dates.length === 1 && activeOrganization.data().oneRunHeader)
            return activeOrganization.data().oneRunHeader;
        return activeOrganization.data().headerFormatString;
    };
    const refreshPreviewNotice = (n) => __awaiter(this, void 0, void 0, function* () {
        if (!exists(activeOrganization)) {
            return;
        }
        const { publicationDates } = n.data();
        const header = getAppropriateHeader(publicationDates, activeOrganization);
        const noticeType = getNoticeType(n, activeOrganization);
        const dynamicHeaders = header
            ? headers.generate(header, publicationDates, noticeType)
            : null;
        yield n.ref.update({
            adTemplate: activeOrganization.data().adTemplate,
            dynamicHeaders,
            publicationDates
        });
        const updated = yield n.ref.get();
        return updated;
    });
    const createPreviewNotice = () => __awaiter(this, void 0, void 0, function* () {
        var _c, _d;
        if (!exists(activeOrganization)) {
            return;
        }
        const publicationDates = [
            getFirebaseContext().timestampFromDate(new Date())
        ];
        const headerF = (_c = activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.headerFormatString;
        const noticeType = notice
            ? getNoticeType(notice, activeOrganization)
            : null;
        const { adTemplate } = activeOrganization.data();
        const defaultLinerRate = (_d = activeOrganization.data()) === null || _d === void 0 ? void 0 : _d.defaultLinerRate;
        if (!defaultLinerRate) {
            throw new Error('No default liner rate');
        }
        const previewNoticeObj = Object.assign(Object.assign({ publicationDates, newspaper: activeOrganization.ref }, (adTemplate && { adTemplate })), { rate: defaultLinerRate, confirmedHtml: '', dynamicHeaders: headerF
                ? headers.generate(headerF, publicationDates, noticeType)
                : null });
        const ref = yield ctx.previewNoticesRef().add(previewNoticeObj);
        const snap = yield ref.get();
        return snap;
    });
    const getPreviewNotice = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(activeOrganization))
            return;
        const results = yield ctx
            .previewNoticesRef()
            .where('newspaper', '==', activeOrganization.ref)
            .limit(1)
            .get();
        const n = results.empty
            ? yield createPreviewNotice()
            : yield refreshPreviewNotice(results.docs[0]);
        setNotice(n);
    });
    useEffect(() => {
        void getPreviewNotice();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    const madlibConfig = {
        questions,
        template
    };
    const debouncedMadlibConfig = useDebounce(madlibConfig, 1000);
    if (!exists(activeOrganization) || !exists(notice)) {
        return _jsx(LoadingState, {});
    }
    const dataLoading = previewLoading || !noticeData;
    const handleJsonEditorChange = (value) => {
        var _a;
        try {
            const valueParsed = JSON.parse(value || '{}');
            setQuestions((_a = valueParsed.questions) !== null && _a !== void 0 ? _a : []);
            setQuestionsValid(true);
        }
        catch (e) {
            setQuestionsValid(false);
        }
    };
    const handleHandlebarsEditorChange = (value) => {
        if (!value) {
            return;
        }
        try {
            Handlebars.precompile(value, { strict: true });
            setTemplate(value);
            setTemplateValid(true);
        }
        catch (e) {
            setTemplateValid(false);
        }
    };
    const handleAddQuestionClicked = () => {
        var _a;
        if (!jsonEditor || !selectedQuestionType) {
            return;
        }
        let question;
        switch (selectedQuestionType) {
            case 'yesno':
                question = {
                    type: 'yesno',
                    varName: 'your_var_name',
                    prompt: 'Your Prompt'
                };
                break;
            case 'multiplechoice':
                question = {
                    type: 'multiplechoice',
                    varName: 'your_var_name',
                    prompt: 'Your Prompt',
                    choices: [
                        {
                            value: 'Choice A'
                        },
                        {
                            value: 'Choice B'
                        }
                    ]
                };
                break;
            case 'number':
                question = {
                    type: 'number',
                    varName: 'your_var_name',
                    prompt: 'Your Prompt',
                    min: 1,
                    max: 5
                };
                break;
            case 'file':
                question = {
                    type: 'file',
                    varName: 'your_var_name',
                    prompt: 'Your Prompt',
                    extensions: '.pdf' // default extesnion
                };
                break;
            case 'text':
                question = {
                    type: 'text',
                    varName: 'your_var_name',
                    prompt: 'Your Prompt'
                };
                break;
        }
        insertText(jsonEditor, JSON.stringify(question, undefined, 2));
        (_a = jsonEditor.getAction('editor.action.formatDocument')) === null || _a === void 0 ? void 0 : _a.run();
    };
    const handleAddHandlebarClicked = () => {
        setShowFieldModal(true);
    };
    const handleImport = (templateFile, questionsFile) => __awaiter(this, void 0, void 0, function* () {
        if (templateFile) {
            const templateText = yield templateFile.text();
            setTemplate(templateText);
            if (handlebarsEditor) {
                replaceText(handlebarsEditor, templateText);
            }
        }
        if (questionsFile) {
            const questionsText = yield questionsFile.text();
            const questionsJson = JSON.parse(questionsText);
            setQuestions(questionsJson.questions);
            if (jsonEditor) {
                replaceText(jsonEditor, questionsText);
            }
        }
    });
    const handleExportClicked = () => {
        downloadFileContentsInBrowser('template.handlebars', template);
        downloadFileContentsInBrowser('questions.json', JSON.stringify({ questions }, undefined, 2));
    };
    return (_jsxs("div", Object.assign({ className: "mt-4 mx-20" }, { children: [showFieldModal && (_jsx(MadlibFieldModal, { onClose: () => setShowFieldModal(false), onSubmit: val => {
                    var _a;
                    if (!handlebarsEditor) {
                        return;
                    }
                    insertText(handlebarsEditor, val);
                    (_a = handlebarsEditor.getAction('editor.action.formatDocument')) === null || _a === void 0 ? void 0 : _a.run();
                    setShowFieldModal(false);
                } })), _jsx("h3", Object.assign({ className: "font-bold mb-1" }, { children: "Import/Export" })), _jsxs("div", Object.assign({ className: "flex flex-row align-center gap-4 mb-4" }, { children: [_jsx("div", Object.assign({ className: "w-1/2" }, { children: _jsx(FileDropzone, { id: "madlib-import", multiple: true, onDrop: (files) => {
                                const templateFile = files.find(f => f.name === 'template.handlebars');
                                const questionsFile = files.find(f => f.name === 'questions.json');
                                void handleImport(templateFile, questionsFile);
                            } }) })), _jsx(ColumnButton, { primary: true, buttonText: "Export", startIcon: _jsx(ArrowDownCircleIcon, { className: "w-5 h-5" }), onClick: handleExportClicked, type: "button" })] })), _jsx("h3", Object.assign({ className: "font-bold mb-1" }, { children: "Edit" })), _jsxs("div", Object.assign({ className: "grid grid-cols-2 gap-4" }, { children: [_jsx("div", Object.assign({ className: "p-2 rounded shadow-sm bg-white border border-column-gray-300" }, { children: _jsx(NoticeEditorMadlib, { madlibConfigPath: "unused", madlibConfig: debouncedMadlibConfig, onEditorUpdate: renderedHtml => {
                                setRenderedHtml(renderedHtml);
                            }, setIsMadlibComplete: () => { }, madlibData: madlibData, onTemplateDataChange: newMadlibData => {
                                setMadlibData(newMadlibData);
                            }, noticeHandlebarData: {
                                publicationDates: notice.data().publicationDates
                            } }) })), _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex flex-row items-center text-xs font-bold mb-1" }, { children: [_jsx("code", { children: "questions.json" }), !questionsValid && (_jsx(ExclamationCircleIcon, { className: "text-red-700 ml-1 w-4 h-4" }))] })), _jsxs("div", Object.assign({ className: "my-2 flex gap-2" }, { children: [_jsx("div", Object.assign({ className: "w-1/3" }, { children: _jsx(ColumnSelect, { labelText: "", id: "select-add-question", size: "small", options: [
                                                        {
                                                            label: 'Yes/No',
                                                            value: 'yesno'
                                                        },
                                                        {
                                                            label: 'Multiple Choice',
                                                            value: 'multiplechoice'
                                                        },
                                                        {
                                                            label: 'Number',
                                                            value: 'number'
                                                        },
                                                        {
                                                            label: 'File',
                                                            value: 'file'
                                                        },
                                                        {
                                                            label: 'Text',
                                                            value: 'text'
                                                        }
                                                    ], value: selectedQuestionType, onChange: (value) => {
                                                        setSelectedQuestionType(value);
                                                    } }) })), _jsx(ColumnButton, { secondary: true, size: "sm", buttonText: "Add", endIcon: _jsx(PlusCircleIcon, { className: "h-6 w-6" }), onClick: handleAddQuestionClicked, type: "button" })] })), _jsx(Editor, { height: "30vh", defaultLanguage: "json", defaultValue: JSON.stringify({ questions }, undefined, 2), onChange: handleJsonEditorChange, onMount: editor => setJsonEditor(editor), options: Object.assign(Object.assign({}, DEFAULT_EDITOR_OPTIONS), { language: 'json' }) })] }), _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex flex-row items-center text-xs font-bold mb-1" }, { children: [_jsx("code", { children: "template.handlebars" }), !templateValid && (_jsx(ExclamationCircleIcon, { className: "text-red-700 ml-1 w-4 h-4" }))] })), _jsx("div", Object.assign({ className: "my-2 flex gap-2" }, { children: _jsx(ColumnButton, { secondary: true, size: "sm", buttonText: "Add Field", endIcon: _jsx(PlusCircleIcon, { className: "h-6 w-6" }), onClick: handleAddHandlebarClicked, type: "button" }) })), _jsx(Editor, { height: "30vh", defaultLanguage: "handlebars", defaultValue: template, onChange: handleHandlebarsEditorChange, onMount: editor => setHandlebarsEditor(editor), options: Object.assign(Object.assign({}, DEFAULT_EDITOR_OPTIONS), { language: 'handlebars' }) })] }), _jsx("div", { children: dataLoading ? (_jsx("div", Object.assign({ className: "w-full h-96" }, { children: _jsx(LoadingState, {}) }))) : (_jsx(NoticePreviewContainer, { error: idError, rate: noticeData.rateSnap.data(), hidePricing: false, setColumnsWide: setColumns, columnsWide: columns, newspaper: activeOrganization, disableColumnWidthControls: true, madlibSandboxPreviewContent: previewContent })) })] }))] }))] })));
}
export default Madlib;
