import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import EmptyNotesDisplay from './EmptyNotesDisplay';
import UserNoteCard from './UserNoteCard';
export default function NotesDisplay({ currentView, notes }) {
    var _a;
    const notesContainer = useRef(null);
    useEffect(() => {
        var _a;
        (_a = notesContainer.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, notesContainer.current.scrollHeight);
    }, [!!((_a = notesContainer.current) === null || _a === void 0 ? void 0 : _a.scrollHeight), currentView, notes.length]);
    return (_jsx("div", Object.assign({ className: "flex-1 overflow-scroll" }, { children: !notes.length ? (_jsx(EmptyNotesDisplay, { currentView: currentView })) : (_jsx("div", Object.assign({ className: "py-6 flex flex-col gap-2 h-full overflow-scroll", ref: notesContainer }, { children: notes.map((note, idx) => (_jsx(UserNoteCard, { isFinalNote: idx === notes.length - 1, currentView: currentView, note: note }, note.id))) }))) })));
}
