import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { exists } from 'lib/types';
import OrganizationSelect from 'layouts/appLayout/OrganizationSelect';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { GridInput } from 'lib/components/Card/Grid';
export default function PublisherOrgElement({ options, onChange, value: orgRef, hasAccess }) {
    var _a;
    const publisherSnap = useFirestoreSnapshot(orgRef);
    const publisherName = (_a = publisherSnap === null || publisherSnap === void 0 ? void 0 : publisherSnap.data().name) !== null && _a !== void 0 ? _a : 'a newspaper';
    // Options never includes the selected item
    const selectOptions = publisherSnap ? [publisherSnap, ...options] : options;
    if (!!publisherSnap && !exists(publisherSnap)) {
        return _jsx(LoadingSpinner, {});
    }
    if (!!orgRef && !hasAccess) {
        return (_jsxs("div", Object.assign({ className: "text-sm text-column-gray-500" }, { children: ["This rate is shared with \"", publisherName, "\" but you do not have access to that organization"] })));
    }
    return (_jsx(GridInput, { children: _jsx(OrganizationSelect, { options: selectOptions, value: publisherSnap, onChange: newSnap => {
                onChange(newSnap.ref);
            } }) }));
}
