import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Product } from 'lib/enums';
import Header from 'components/Header';
import { selectOrderIdClaims } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import OrderEditFlow from './OrderEditFlow';
import { AdFormStatusProvider } from './contexts/AdFormStatusProvider';
export default function EditOrderContainer({ match }) {
    const orderIdClaims = useAppSelector(selectOrderIdClaims);
    const [claim] = orderIdClaims;
    const segments = location.pathname.split('/');
    const product = segments[1] === 'obituaries' ? Product.Obituary : Product.Classified;
    return (_jsxs(AdFormStatusProvider, { children: [_jsx(Header, { productLine: Product.Obituary }), _jsx(OrderEditFlow, { claim: claim, orderId: match.params.orderId, product: product })] }));
}
