import { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { affidavitsAreManagedByColumn } from 'lib/affidavits';
export const useDetermineShowAutomatedAffidavitsTab = (activeOrganization) => {
    const [showAutomatedAffidavitsTab, setShowAutomatedAffidavitsTab] = useState(false);
    const reloadTab = () => {
        if (window.location.pathname.startsWith('/affidavits')) {
            setShowAutomatedAffidavitsTab(true);
            return;
        }
        setShowAutomatedAffidavitsTab(exists(activeOrganization) &&
            affidavitsAreManagedByColumn(activeOrganization));
    };
    useEffect(() => {
        reloadTab();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    return showAutomatedAffidavitsTab;
};
