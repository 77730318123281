import { STRIPE } from 'lib/constants';
import { exists } from 'lib/types';
import { getPreventLatePaymentValue } from './preventLatePayment';
export const getSavedPaymentsAccess = (payInvoiceData, user, userStripeId) => {
    var _a, _b, _c, _d;
    let disableSavedCards = false;
    let disableACH = false;
    let disableSavedPaymentMethods = false;
    const { advertiser, invoice, gateway, invoicePricingData } = payInvoiceData;
    const preventLatePayment = getPreventLatePaymentValue(payInvoiceData);
    // If the invoice is billed to an organization but the user has a different
    // activeOrganization. In this case we do not show saved payment methods
    const activeOrganizationMismatch = !!invoice.modelData.advertiserOrganization &&
        ((_b = (_a = user === null || user === void 0 ? void 0 : user.data()) === null || _a === void 0 ? void 0 : _a.activeOrganization) === null || _b === void 0 ? void 0 : _b.id) !==
            ((_c = invoice.modelData.advertiserOrganization) === null || _c === void 0 ? void 0 : _c.id);
    /**
     * If the invoice does not have an advertiser organization but the advertiser
     * on the invoice is part of an organization, we do not show saved payment methods
     * or provide the option to save a credit card to the Stripe customer.
     */
    const individualFilerAndOrgFilerMismatch = !invoice.modelData.advertiserOrganization &&
        !!(advertiser === null || advertiser === void 0 ? void 0 : advertiser.modelData.activeOrganization);
    if (!exists(user) ||
        !advertiser ||
        preventLatePayment ||
        gateway !== STRIPE) {
        disableSavedPaymentMethods = true;
    }
    else {
        const { advertiserOrganization } = invoice.modelData;
        if (advertiserOrganization) {
            const sameOrg = advertiserOrganization.id === ((_d = user.data().activeOrganization) === null || _d === void 0 ? void 0 : _d.id);
            disableSavedPaymentMethods = !sameOrg;
        }
        else {
            disableSavedPaymentMethods = advertiser.id !== user.id;
        }
        const stripeIdMismatch = invoicePricingData.stripeCustomerId !== userStripeId;
        if (individualFilerAndOrgFilerMismatch ||
            activeOrganizationMismatch ||
            stripeIdMismatch) {
            disableSavedPaymentMethods = true;
        }
        if (invoice.modelData.disableACH)
            disableACH = true;
    }
    if (disableSavedPaymentMethods) {
        disableACH = true;
        disableSavedCards = true;
    }
    return {
        disableSavedCards,
        disableACH,
        activeOrganizationMismatch
    };
};
