import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorIcon from '@material-ui/icons/Error';
import { getColumnInches } from 'lib/pricing';
import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import { RateType } from 'lib/enums';
import { getCurrencySymbol } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectPreviewContent } from 'redux/placement';
const styles = () => createStyles({
    error: {
        '& .MuiSvgIcon-root': {
            height: '1.5em',
            width: '1.5em'
        }
    }
});
function NoticePreviewContainer({ classes, theme, rate, hidePricing, columnsWide, setColumnsWide, error, newspaper, disableColumnWidthControls, madlibSandboxPreviewContent }) {
    var _a, _b, _c, _d, _e;
    const noticePreviewContent = useAppSelector(selectPreviewContent);
    const previewContent = madlibSandboxPreviewContent || noticePreviewContent;
    const displayParameters = previewContent.displayParams;
    const { price } = previewContent;
    const previewImgs = displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.imgs;
    const [magnify, setMagnify] = useState(false);
    const currencySymbol = getCurrencySymbol(newspaper.data().currency);
    const columnsImageWidthMap = {
        1: '180px',
        2: '300px',
        3: '400px'
    };
    const inches = rate.rateType === RateType.inch.value
        ? {
            unit: RateType.inch.plural,
            value: displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.area
        }
        : {
            unit: RateType.column_inch.plural,
            value: getColumnInches((displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.height) || 0, columnsWide, rate.roundOff)
        };
    return (_jsxs(_Fragment, { children: [_jsxs(Card, Object.assign({ className: classes.card, style: {
                    height: 500,
                    minWidth: 300,
                    overflowY: 'scroll'
                } }, { children: [error ? (_jsx(CardHeader, { style: {
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            paddingLeft: 0
                        }, subheader: _jsx(_Fragment, { children: _jsxs(Grid, Object.assign({ container: true, direction: "row" }, { children: [_jsx(Grid, Object.assign({ container: true, direction: "column", alignItems: "center", justify: "center", xs: 2, className: classes.error }, { children: _jsx(ErrorIcon, {}) })), _jsx(Grid, Object.assign({ xs: true }, { children: error })), ' '] })) }) })) : (_jsx(CardHeader, { style: {
                            backgroundColor: theme.palette.primary.main,
                            color: 'white'
                        }, title: hidePricing ? '' : `Estimated Price: ${currencySymbol}${price}`, subheader: `${displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.words} ${RateType.word_count.plural} - ${displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.lines} ${RateType.line.plural} - ${(_a = inches.value) === null || _a === void 0 ? void 0 : _a.toFixed(2)} ${inches.unit}` })), !error && (_jsxs(CardContent, { children: [_jsx(Box, Object.assign({ mb: 1 }, { children: _jsx(Grid, Object.assign({ container: true, direction: "row", justify: "center" }, { children: previewImgs && (_jsxs(_Fragment, { children: [!disableColumnWidthControls && (_jsxs(Grid, Object.assign({ item: true }, { children: [_jsx(InputLabel, Object.assign({ htmlFor: "columns-simple", style: { fontSize: 14 } }, { children: "Columns Wide" })), _jsxs(Select, Object.assign({ value: columnsWide, onChange: e => {
                                                            setColumnsWide(e.target.value);
                                                        }, style: { fontSize: 14 } }, { children: [!((_c = (_b = newspaper.data()) === null || _b === void 0 ? void 0 : _b.linerMinColumns) !== null && _c !== void 0 ? _c : 0 > 1) && (_jsx(MenuItem, Object.assign({ value: 1 }, { children: "One" }))), !((_e = (_d = newspaper.data()) === null || _d === void 0 ? void 0 : _d.linerMinColumns) !== null && _e !== void 0 ? _e : 0 > 2) && (_jsx(MenuItem, Object.assign({ value: 2 }, { children: "Two" }))), _jsx(MenuItem, Object.assign({ value: 3 }, { children: "Three" }))] }))] }))), _jsx(Grid, Object.assign({ item: true }, { children: _jsxs(IconButton, Object.assign({ onClick: () => setMagnify(true), className: classes.zoomIcon }, { children: [' ', _jsx(ZoomInIcon, { className: classes.addButton, fontSize: "small" })] })) }))] })) })) })), _jsx(Grid, { container: true, direction: "row", justify: "center", alignItems: "center" }), _jsx(Grid, Object.assign({ container: true, direction: "row", justify: "center", alignItems: "center" }, { children: !!(displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.lines) && (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.lines) <= 1 ? (_jsxs("div", { children: ["To generate preview, enter more than one line of text.", ' '] })) : (_jsx(Grid, Object.assign({ item: true }, { children: previewImgs && (_jsx(Box, Object.assign({ mr: 1, mb: 1 }, { children: _jsx("img", { style: {
                                                width: columnsImageWidthMap[columnsWide],
                                                maxWidth: '100%'
                                            }, className: classes.previewImg, src: previewImgs[0], alt: "notice-preview" }) }))) }))) }))] }))] })), ' ', magnify && (_jsx(ImagePreviewModal, { setOpenModal: setMagnify, jpgURLs: error ? [] : previewImgs || [] })), _jsx("style", { children: `
        .MuiTypography-colorTextSecondary {
          color: white;
          font-size: 14px;
        }
      ` })] }));
}
export default withStyles(styles, { withTheme: true })(NoticePreviewContainer);
