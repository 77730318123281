export var NewspaperOrderStatus;
(function (NewspaperOrderStatus) {
    /**
     * Order has been archived and status has bubbled into all newspaper orders
     */
    NewspaperOrderStatus["ARCHIVED"] = "archived";
    /**
     * Order has been cancelled & refunded.
     * If a transfer has been completed, it will be reversed at end of month.
     * If a transfer has not yet been completed, not reversal will occur.
     */
    NewspaperOrderStatus["CANCELLED"] = "cancelled";
    /**
     * Newspaper order has been published
     * A transfer will occur at the end of the month it was completed
     */
    NewspaperOrderStatus["COMPLETE"] = "complete";
    /**
     * Soft deleted newspaper order, which allows us to keep transfer and other data
     */
    NewspaperOrderStatus["DELETED"] = "deleted";
    /**
     * Order ad is in the process of being created
     */
    NewspaperOrderStatus["DRAFT"] = "draft";
    /**
     * Order has been created and is awaiting review
     */
    NewspaperOrderStatus["AWAITING_REVIEW"] = "awaiting review";
    /**
     * Order is being actively reviewed by the publisher
     */
    NewspaperOrderStatus["IN_REVIEW"] = "in review";
    /**
     * Order has been confirmed by the publisher
     */
    NewspaperOrderStatus["CONFIRMED"] = "confirmed";
})(NewspaperOrderStatus || (NewspaperOrderStatus = {}));
export var NewspaperOrderLineItemStatus;
(function (NewspaperOrderLineItemStatus) {
    /**
     * Line item is awaiting publication
     */
    NewspaperOrderLineItemStatus["PENDING"] = "pending";
    /**
     * Line item has been published in the newspaper
     */
    NewspaperOrderLineItemStatus["PUBLISHED"] = "published";
    /**
     * Line item has been cancelled
     */
    NewspaperOrderLineItemStatus["CANCELLED"] = "cancelled";
})(NewspaperOrderLineItemStatus || (NewspaperOrderLineItemStatus = {}));
export function validateCompleteNewspaperOrder(newspaperOrder) {
    return (newspaperOrder !== null &&
        typeof newspaperOrder === 'object' &&
        newspaperOrder.newspaper !== undefined &&
        newspaperOrder.filingType !== undefined &&
        newspaperOrder.layout !== undefined &&
        Array.isArray(newspaperOrder.publishingDates) &&
        newspaperOrder.publishingDates.length > 0 &&
        newspaperOrder.publishingDates.every(date => typeof date === 'string') &&
        newspaperOrder.status !== undefined &&
        Object.values(NewspaperOrderStatus).includes(newspaperOrder.status));
}
