var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getModelFromRef } from 'lib/model';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { getFirebaseContext } from 'utils/firebase';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import ChoiceButton from '../../components/ChoiceButton';
import { useScrollPositionWithinParent } from './useScrollPosition';
const getBorderColorClass = (active) => ({
    'border-column-primary-400': active,
    'border-column-gray-200': !active
});
const layoutToString = ({ columns, photos }) => `${columns} columns, ${photos} photos`;
function LayoutIcon({ layout: { id, iconSvgUrl }, active }) {
    return (_jsx("div", Object.assign({ className: classNames('rounded-lg p-2 border-2 mx-auto', getBorderColorClass(active)) }, { children: _jsx("img", { className: 'h-20 mx-auto', src: iconSvgUrl, alt: id }) })));
}
function LayoutButton({ layout, active, disabled }) {
    const textClassName = classNames('text-xs text-center pt-2', {
        'text-column-primary-500': active && !disabled,
        'text-column-gray-400': !active && !disabled,
        'text-column-gray-500': active && disabled,
        'text-column-gray-100': !active && disabled
    });
    return (_jsxs(_Fragment, { children: [_jsx(LayoutIcon, { layout: layout, active: active }), _jsx("div", Object.assign({ className: textClassName }, { children: layoutToString(layout) }))] }));
}
function LayoutScrollButton({ disabled, onClick, icon }) {
    return (_jsx("div", Object.assign({ className: "hidden lg:block" }, { children: _jsx(ColumnButton, { type: "button", buttonText: icon, disabled: disabled, onClick: onClick }) })));
}
const SCROLL_INTERVAL = 400;
const NUMBER_OF_LAYOUTS_TO_SHOW_SCROLL_BUTTONS = 5;
function LayoutSelector({ onLayoutChange, layout, selectionDisabled, newspaperOrder }) {
    var _a;
    const { value: availableLayouts, isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!newspaperOrder.filingType) {
                throw Error('Missing filing type on newspaper order');
            }
            const filingTypeModel = yield getModelFromRef(FilingTypeModel, getFirebaseContext(), newspaperOrder.filingType);
            const { supportedLayouts } = filingTypeModel;
            /**
             * If the current layout is not available for the current newspaper order,
             * select the first available layout.
             */
            if (supportedLayouts.every(supportedLayout => supportedLayout.id !== layout.id)) {
                onLayoutChange(supportedLayouts[0]);
            }
            return supportedLayouts;
        }),
        dependencies: []
    });
    const [scrollPosition, setScrollPosition] = useState({
        leftOffset: 0,
        rightOffset: 0
    });
    const layoutSelectorRef = useRef(null);
    useScrollPositionWithinParent({
        elementRef: layoutSelectorRef,
        debounce: 100,
        callback: setScrollPosition
    });
    const showScrollButtons = ((_a = availableLayouts === null || availableLayouts === void 0 ? void 0 : availableLayouts.length) !== null && _a !== void 0 ? _a : 0) > NUMBER_OF_LAYOUTS_TO_SHOW_SCROLL_BUTTONS;
    if (isLoading || !availableLayouts) {
        return _jsx(LoadingState, { context: { service: ColumnService.OBITS } });
    }
    return (_jsxs("div", Object.assign({ className: "flex items-center w-full lg:w-3/4 mx-auto" }, { children: [showScrollButtons && (_jsx(LayoutScrollButton, { icon: _jsx(ChevronLeftIcon, { className: "w-6 h-6 column-gray-300 hover:column-primary-500" }), disabled: scrollPosition.leftOffset === 0, onClick: () => {
                    var _a;
                    return (_a = layoutSelectorRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
                        left: -SCROLL_INTERVAL,
                        behavior: 'smooth'
                    });
                } })), _jsx("div", Object.assign({ className: classNames('flex gap-6 overflow-x-auto', {
                    'mx-auto': !showScrollButtons,
                    'mx-6': showScrollButtons
                }), ref: layoutSelectorRef }, { children: availableLayouts.map(selectableLayout => {
                    const checked = selectableLayout.id === layout.id;
                    return (_jsx("div", Object.assign({ className: "w-36 md:40 xl:w-48 flex-shrink-0 py-4" }, { children: _jsx(ChoiceButton, Object.assign({ id: selectableLayout.id, checked: checked, option: selectableLayout, onClick: onLayoutChange, disabled: selectionDisabled }, { children: _jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(LayoutButton, { layout: selectableLayout, active: checked, disabled: selectionDisabled }) })) })) }), selectableLayout.id));
                }) })), showScrollButtons && (_jsx(LayoutScrollButton, { icon: _jsx(ChevronRightIcon, { className: "w-6 h-6 column-gray-300 hover:column-primary-500" }), disabled: scrollPosition.rightOffset === 0, onClick: () => {
                    var _a;
                    return (_a = layoutSelectorRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
                        left: SCROLL_INTERVAL,
                        behavior: 'smooth'
                    });
                } }))] })));
}
export default LayoutSelector;
