import { useEffect } from 'react';
/**
 * Listen for keydown events and run a callback when the given key is pressed
 */
export function useKeydownListener(key, callback) {
    useEffect(() => {
        const handleKeydown = (event) => {
            if (event.key === key) {
                callback();
            }
        };
        document.addEventListener('keydown', handleKeydown);
        // Clean up event listener on unmount
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [key, callback]);
}
