import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State } from 'lib/enums';
import React from 'react';
import { logAndCaptureMessage } from 'utils';
function ListingLocationInputs({ id, city, onChangeCity, state, onChangeState, zipCode, onChangeZipCode }) {
    var _a;
    const handleStateChange = (newStateLabel) => {
        var _a;
        const newState = (_a = State.by_label(newStateLabel)) === null || _a === void 0 ? void 0 : _a.value;
        if (!newState) {
            logAndCaptureMessage('Invalid state label selected in order placement flow', {
                newStateLabel
            });
            return;
        }
        onChangeState(newState);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "col-span-3" }, { children: _jsx(TextField, { id: `${id}-city`, value: city, placeholder: "City", labelText: "City", type: "text", size: "small", onChange: onChangeCity, required: !zipCode, validationMessages: {
                        valueMissing: 'City or zip code required'
                    } }) })), _jsx("div", Object.assign({ className: "col-span-3" }, { children: _jsx(ColumnSelect, { id: `${id}-state`, value: (_a = State.by_value(state)) === null || _a === void 0 ? void 0 : _a.label, placeholder: "State", labelText: "State", allowUndefined: true, size: "small", options: State.items().map(s => ({
                        label: s.label,
                        value: s.label
                    })), onChange: handleStateChange, required: !zipCode, validationMessages: {
                        valueMissing: 'State or zip code required'
                    } }) })), _jsx("div", Object.assign({ className: "pt-8 flex self-center justify-self-center col-span-1" }, { children: "OR" })), _jsx("div", Object.assign({ className: "col-span-3" }, { children: _jsx(TextField, { id: `${id}-zip-code`, value: zipCode, placeholder: "Zip Code", labelText: "Zip Code", type: "text", size: "small", pattern: '^[0-9]{5}(-[0-9]{4})?$', validationMessages: {
                        patternMismatch: 'Please enter a valid zip code',
                        valueMissing: 'Zip code or city/state required'
                    }, required: !city || !state, onChange: onChangeZipCode }) }))] }));
}
export default ListingLocationInputs;
