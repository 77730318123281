import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { DocumentIcon, DownloadIcon } from 'icons';
import { truncateString } from 'utils';
function UploadConfirmation({ isReupload, fileName, saveNCloseModal, setOpen, downloadURL }) {
    const [loading, setLoading] = useState(false);
    const buttonColor = 'bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600';
    return (_jsx(FreeformCModal, Object.assign({ setOpen: setOpen, header: isReupload ? 'Confirm Replacement Affidavit?' : 'Confirm Affidavit?', body: `After clicking "Confirm", an email will automatically be sent to the client.`, disableOverflow: true }, { children: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "grid gap-4 p-4 my-4 grid-cols-12 bg-gray-200 rounded" }, { children: [_jsx("section", Object.assign({ className: "col-span-2" }, { children: _jsx(DocumentIcon, { className: "w-full" }) })), _jsx("section", Object.assign({ className: "col-span-8" }, { children: truncateString(fileName.split('/')[2]) || '' })), _jsx(CButton, Object.assign({ className: 'col-span-2', onClick: () => {
                                if (downloadURL) {
                                    window.open(downloadURL);
                                }
                            } }, { children: _jsx(DownloadIcon, { className: "w-full col-span-1 text-gray-750 stroke-current" }) }))] })), _jsx(CButton, Object.assign({ className: `${buttonColor} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-auto`, onClick: () => {
                        setLoading(true);
                        void saveNCloseModal();
                    }, startIcon: loading ? (_jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-column-yellow-400 h-6 w-6" })) : (_jsx(_Fragment, {})) }, { children: "Confirm" }))] }) })));
}
export default UploadConfirmation;
