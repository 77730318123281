import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import FreeformModal from 'components/modals/FreeFormCModal';
export default function PayInvoiceModal({ setShowPayInvoiceModal, newspaper, invoice }) {
    return (_jsx(FreeformModal, Object.assign({ header: "Download Affidavit", body: `${newspaper
            ? `${newspaper.data().name} has uploaded your affidavit, but you must pay your outstanding invoice in order to download and view it.`
            : ''}`, setOpen: setShowPayInvoiceModal }, { children: _jsx("div", Object.assign({ className: "flex justify-center md:justify-start" }, { children: _jsx("button", Object.assign({ className: `bg-gray-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-auto`, onClick: () => {
                    if (invoice.data().isWithinBulkInvoice)
                        window.open(`${window.location.origin}/payments`);
                    else
                        window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
                } }, { children: "Pay Invoice" })) })) })));
}
