import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { ELabels } from 'lib/enums';
const styles = (theme) => createStyles({
    iconContainer: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    help: {
        color: theme.palette.primary.main,
        fontSize: 18,
        verticalAlign: 'text-top'
    }
});
function InvoiceFormCustomerTable({ classes, noticeSnap, customerName, setCustomId, customId }) {
    return (_jsx("div", { children: _jsx("div", Object.assign({ className: "bg-white shadow overflow-hidden sm:rounded-lg" }, { children: _jsx("div", Object.assign({ className: "px-4 py-5 sm:p-0" }, { children: _jsxs("dl", { children: [_jsxs("div", Object.assign({ className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Customer" })), _jsx("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: customerName }))] })), _jsxs("div", Object.assign({ className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: ELabels.publisher_id.label })), _jsxs("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: [_jsx(TextField, { id: ELabels.publisher_id.key, className: classes.formControl, label: "", value: customId, onChange: e => setCustomId(e.target.value) }), ' '] }))] })), _jsxs("div", Object.assign({ className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Notice ID" })), _jsx("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: noticeSnap.id }))] }))] }) })) })) }));
}
export default withStyles(styles)(InvoiceFormCustomerTable);
