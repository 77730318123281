var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { selectIsUserLoggedIn } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
// TODO: Merge with `useFirestoreDocumentListener`
export function useFirestoreListener(docRef) {
    const [existingSnapshot, setExistingSnapshot] = useState(null);
    const userLoggedIn = useAppSelector(selectIsUserLoggedIn);
    const setInitialSnapshot = (ref) => __awaiter(this, void 0, void 0, function* () {
        const refSnapshot = yield ref.get();
        if (!exists(refSnapshot)) {
            return;
        }
        setExistingSnapshot(refSnapshot);
    });
    useEffect(() => {
        // Don't attempt to fetch if the user is not logged in
        if (!userLoggedIn)
            return;
        if (!existingSnapshot) {
            if (docRef) {
                void setInitialSnapshot(docRef);
            }
            return;
        }
        const unsubscribe = existingSnapshot.ref.onSnapshot(nextSnap => {
            if (!exists(nextSnap)) {
                setExistingSnapshot(null);
                return;
            }
            if (!isEqual(nextSnap.data(), existingSnapshot.data())) {
                setExistingSnapshot(nextSnap);
            }
        });
        return unsubscribe;
    }, [existingSnapshot === null || existingSnapshot === void 0 ? void 0 : existingSnapshot.id, docRef === null || docRef === void 0 ? void 0 : docRef.id, userLoggedIn]);
    return [existingSnapshot, setExistingSnapshot];
}
