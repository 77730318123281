var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BillingTermType } from '../enums';
import { getCustomer, getCustomerOrganization } from '../notice/customer';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
import { getLaunchDarklyContext } from '../utils/flags';
// For the given notice and newspaper this returns if the invoice's (to be created) due date will be the end of next month
export const isCustomerOnThirtyDaysEomBilling = (ctx, notice, newspaper) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const enableThirtyDaysEomBillingFlag = yield getLaunchDarklyContext().getBooleanFeatureFlag(LaunchDarklyFlags.ENABLE_THIRTY_DAYS_END_OF_MONTH_BILLING);
    if (!enableThirtyDaysEomBillingFlag) {
        return false;
    }
    const advertiserOrg = yield ((_a = notice.data().filedBy) === null || _a === void 0 ? void 0 : _a.get());
    const advertiser = yield notice.data().filer.get();
    const customer = yield getCustomer(ctx, advertiser, newspaper);
    const customerOrganization = advertiserOrg
        ? yield getCustomerOrganization(ctx, advertiserOrg, newspaper)
        : null;
    // For advertiser organiations, check the setting on customer org level
    if (customerOrganization) {
        return (customerOrganization.data().billingTerm ===
            BillingTermType.thirty_days_end_of_month.value);
    }
    // For individuals, check the setting on customer level
    return ((customer === null || customer === void 0 ? void 0 : customer.data().billingTerm) ===
        BillingTermType.thirty_days_end_of_month.value);
});
