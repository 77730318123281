import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { State } from 'lib/enums';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
export default function StateSelectDropdown({ onChange, value }) {
    return (_jsx(SelectDropdown, { id: "state", onChange: (selected) => {
            onChange(selected);
        }, placeholder: "State", options: State.items().map((state) => ({
            id: state.value,
            label: state.label
        })), selected: State.items().filter(item => {
            return item.value === value.id
                ? { id: item.value, label: item.label }
                : '';
        }), value: State.items().filter(item => {
            return item.value === value.id
                ? { id: item.value, label: item.label }
                : '';
        }), placeholderText: '#a0aec0', dropdownHeight: 'large', required: true }));
}
