var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { centsToDollarsString } from 'lib/helpers';
import { Alert } from 'lib/components/Alert';
import OrderSummaryRow from '../OrderSummaryRow';
function PricingData({ price }) {
    return (_jsx("div", Object.assign({ className: "text-sm font-semibold" }, { children: `$${centsToDollarsString(price)}` })));
}
function PricingComparison({ orderModel, totalInCents }) {
    const { value, isError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const oldPricing = yield orderModel.getConsolidatedPricing({
                distributeFee: true,
                version: orderModel.modelData.activeVersion
            });
            return oldPricing.totalInCents;
        }),
        dependencies: []
    });
    if (isError) {
        return (_jsx(Alert, { id: "pricing-comparison-error", title: "Could not retrieve pricing" }));
    }
    if (!value) {
        return _jsx(LoadingState, { context: { service: ColumnService.OBITS } });
    }
    return (_jsxs(_Fragment, { children: [_jsx(OrderSummaryRow, { label: "Old price", data: _jsx("div", Object.assign({ className: "text-column-red-700 line-through" }, { children: _jsx(PricingData, { price: value }) })) }), _jsx(OrderSummaryRow, { label: "New price", data: _jsx("div", Object.assign({ className: "text-sm font-semibold text-column-green-600" }, { children: _jsx(PricingData, { price: totalInCents }) })) })] }));
}
export default PricingComparison;
