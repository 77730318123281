import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ExclamationCircleIcon, CheckIcon, ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { selectIsPublisher } from 'redux/auth';
import { BillingStatusType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import NoticeTableCell from './NoticeTableCell';
export default function BillingStatusCell({ row }) {
    var _a, _b, _c;
    const { original } = row;
    const { requireupfrontpayment, publisherbillingstatusvalue, advertiserbillingstatusvalue, publisherbillingstatus, advertiserbillingstatus, iscancelled, isdraft } = original;
    const isPublisher = useAppSelector(selectIsPublisher);
    if (isdraft)
        return null;
    const icons = {
        check: _jsx(CheckIcon, { className: "w-4 h-4 mr-1" }),
        waiting: _jsx(ClockIcon, { className: "w-4 h-4 mr-1" }),
        failed: _jsx(ExclamationCircleIcon, { className: "w-4 h-4 mr-1" }),
        cancelled: _jsx(XMarkIcon, { className: "w-4 h-4 mr-1" })
    };
    let billingStatus = isPublisher
        ? publisherbillingstatusvalue
        : advertiserbillingstatusvalue;
    if (!billingStatus) {
        billingStatus = isPublisher
            ? (_a = BillingStatusType.by_label(publisherbillingstatus)) === null || _a === void 0 ? void 0 : _a.value
            : (_b = BillingStatusType.by_label(advertiserbillingstatus)) === null || _b === void 0 ? void 0 : _b.value;
    }
    let statusText = !iscancelled
        ? (_c = BillingStatusType.by_value(billingStatus)) === null || _c === void 0 ? void 0 : _c.label
        : 'Cancelled';
    let statusIcon = !iscancelled
        ? icons.waiting
        : icons.cancelled;
    let status;
    if (!iscancelled)
        switch (billingStatus) {
            case BillingStatusType.awaiting_bulk_invoice.value:
                statusText = BillingStatusType.awaiting_bulk_invoice.label;
                statusIcon = isPublisher ? undefined : statusIcon;
                if (isPublisher) {
                    status = 'warning';
                }
                break;
            case BillingStatusType.invoice_submitted_to_advertiser.value:
                statusText = isPublisher ? 'Awaiting Customer Payment' : 'Unpaid';
                if (requireupfrontpayment) {
                    status = 'critical';
                    statusIcon = icons.failed;
                }
                else if (!isPublisher) {
                    status = 'warning';
                }
                break;
            case BillingStatusType.invoice_paid_by_advertiser.value:
                statusText = isPublisher ? 'Invoice Paid by Customer' : 'Invoice Paid';
                statusIcon = icons.check;
                status = 'success';
                break;
            case BillingStatusType.transfer_created.value:
                statusIcon = icons.check;
                status = 'success';
                break;
            case BillingStatusType.payment_initiated.value:
                statusText = BillingStatusType.payment_initiated.label;
                statusIcon = icons.check;
                status = 'success';
                break;
            case BillingStatusType.payment_failed.value:
                statusText = BillingStatusType.payment_failed.label;
                statusIcon = icons.failed;
                status = 'critical';
                break;
            case BillingStatusType.invoiced_outside_column.value:
                statusText = 'Outside of Column';
                statusIcon = undefined;
                break;
            case BillingStatusType.invoice_not_submitted.value:
            default:
                statusText = BillingStatusType.invoice_not_submitted.label;
                statusIcon = isPublisher ? statusIcon : undefined;
                if (isPublisher) {
                    status = 'warning';
                }
                break;
        }
    return (_jsx(NoticeTableCell, { children: _jsx(Badge, Object.assign({ size: "lg", startIcon: statusIcon, status: status }, { children: statusText })) }));
}
