var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { logAndCaptureMessage } from 'utils';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { OccupationType } from 'lib/enums';
import { selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import api from 'api';
import { ORGANIZATION_POST_REGISTRATION_ENTRYPOINT, INDIVIDUAL_REGISTRATION_ENTRYPOINT, PUBLISHER_REGISTRATION_ENTRYPOINT } from 'redux/router';
import Occupations from './Occupations';
export default function OccupationsRoute() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const [occupationType, setOccupationType] = useState();
    const [loading, setLoading] = useState(false);
    const nextButtonClicked = () => __awaiter(this, void 0, void 0, function* () {
        if (occupationType !== null) {
            yield completeBasicSignup();
        }
    });
    const completeBasicSignup = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(user))
            return;
        setLoading(true);
        const response = yield api.post('users/assign-occupation', {
            email: user.data().email,
            occupation: occupationType
        });
        if (response.success) {
            // Artifical delay before navigating to avoid showing a glimpse of previous screen
            setTimeout(() => {
                if (occupationType === OccupationType.individual.value) {
                    dispatch(push(INDIVIDUAL_REGISTRATION_ENTRYPOINT));
                }
                else if (occupationType === OccupationType.publishing.value) {
                    dispatch(push(PUBLISHER_REGISTRATION_ENTRYPOINT));
                }
                else {
                    dispatch(push(ORGANIZATION_POST_REGISTRATION_ENTRYPOINT));
                }
            }, 500);
        }
        else {
            setLoading(false);
            logAndCaptureMessage(response.error);
        }
    });
    return (_jsx(Occupations, { occupationType: occupationType, onOccupationTypeChange: setOccupationType, onOccupationSelect: nextButtonClicked, loading: loading }));
}
