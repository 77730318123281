import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { exists } from 'lib/types';
import React from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { GridInput } from 'lib/components/Card/Grid';
import { getInputType, isArrayType } from './inputs';
import { RateInput } from './RateInput';
export function KeyInput({ value, onChange, entry, keyType, valueType }) {
    var _a, _b, _c;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    if (!exists(activeOrganization)) {
        return null;
    }
    return (_jsx(GridInput, Object.assign({ fullWidth: isArrayType(valueType) }, { children: keyType === 'notice-type' ? (_jsx(ColumnSelect, { id: "entry-key-select", labelText: "Key", value: value, onChange: onChange, allowUndefined: true, options: (_c = (_b = (_a = activeOrganization
                .data()) === null || _a === void 0 ? void 0 : _a.allowedNotices) === null || _b === void 0 ? void 0 : _b.map(({ label, value }) => ({
                label: `${label} (${value})`,
                value: value.toString()
            }))) !== null && _c !== void 0 ? _c : [] })) : keyType === 'rate' ? (_jsx(RateInput, { activeOrganization: activeOrganization.ref, value: value, onChange: onChange })) : (_jsx(TextField, { id: "entry-key-text", labelText: "What key should index this cache entry?", disabled: !!entry, noteText: entry
                ? 'If the key is wrong, delete this entry and create a new one'
                : '', value: value, type: getInputType(keyType), onChange: onChange })) })));
}
