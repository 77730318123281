import { supportsDisplay } from 'lib/notice/rates';
import { RateType } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { rateTypeSupportsDisplay } from '../ratesTableSettingsUtils';
/**
 * Determines the default behavior for a rate based on the active organization and the rateRef
 * @param activeOrganization current publisher
 * @param rateRef current rate, null if creating a new rate
 * @returns {DEFAULT_RATE_BEHAVIOR} the default behavior for the rate
 */
export const getDefaultBehaviorFromNewspaperAndRateData = (activeOrganization, rateId) => {
    var _a, _b;
    if (!rateId)
        return 'none';
    const isDefaultDisplayRate = ((_a = activeOrganization.data().defaultDisplayRate) === null || _a === void 0 ? void 0 : _a.id) === rateId;
    const isDefaultLinerRate = ((_b = activeOrganization.data().defaultLinerRate) === null || _b === void 0 ? void 0 : _b.id) === rateId;
    if (isDefaultLinerRate && isDefaultDisplayRate)
        return 'both';
    if (isDefaultLinerRate)
        return 'liner';
    if (isDefaultDisplayRate)
        return 'display';
    return 'none';
};
/**
 * Figures out what options to show in the default behavior dropdown
 * @param activeOrganization current publisher
 * @param rateRef current rate, null if creating a new rate
 * @returns {{value: DEFAULT_RATE_BEHAVIOR, label: string}[]} the options for the default behavior dropdown
 */
export const getDefaultBehaviorOptions = (activeOrganization, rateData, rateId) => {
    const defaultBehavior = getDefaultBehaviorFromNewspaperAndRateData(activeOrganization, rateId);
    const options = [
        {
            value: 'liner',
            label: 'Use as the default liner rate'
        }
    ];
    if (supportsDisplay(rateData)) {
        options.push({
            value: 'display',
            label: 'Use as the default display rate'
        });
        // APP-1519: Confirm delete this option
        options.push({
            value: 'both',
            label: 'Use as the default for liner and display ads'
        });
    }
    if (defaultBehavior === 'none') {
        options.unshift({
            value: 'none',
            label: 'Use when assigned to a customer or notice type'
        });
    }
    return options;
};
/**
 * Determine if the rate supports display ads or not
 * @param rateData
 */
export const getSupportedAdTypes = (rateId, activeOrganization, rateData) => {
    var _a;
    const supportedAdTypes = [];
    if (rateId !== ((_a = activeOrganization.data().defaultDisplayRate) === null || _a === void 0 ? void 0 : _a.id)) {
        supportedAdTypes.push({
            value: 'false',
            label: 'Use this rate for liner notices only'
        });
    }
    if (rateTypeSupportsDisplay(rateData.rateType)) {
        supportedAdTypes.push({
            value: 'true',
            label: 'Use this rate for liner and display notices'
        });
    }
    return supportedAdTypes;
};
export const MAX_ADDITIONAL_RATES = 7;
export const rateSupportsDisplayAdsForPublisher = (rateId, activeOrganization, rateData) => {
    var _a;
    if (supportsDisplay(rateData))
        return true;
    if (!rateId)
        return false;
    if (rateId === ((_a = activeOrganization.data().defaultDisplayRate) === null || _a === void 0 ? void 0 : _a.id))
        return true;
    return false;
};
export const rateIsFlat = ({ rateType }) => [RateType.flat.value, RateType.per_run.value].includes(rateType);
export const getRateUnit = (adType, rateType) => {
    switch (rateType) {
        case RateType.flat.value: {
            const { plural, singular } = PRODUCT_TO_NAME[adType];
            return {
                plural: plural.toLocaleLowerCase(),
                singular: singular.toLocaleLowerCase()
            };
        }
        case RateType.per_run.value: {
            return { plural: 'runs', singular: 'run' };
        }
        case RateType.folio.value: {
            return { plural: 'folios', singular: 'folio' };
        }
        case RateType.word_count.value: {
            return { plural: 'words', singular: 'word' };
        }
        case RateType.battle_born.value:
        case RateType.iowa_form.value:
        case RateType.line.value:
        case RateType.nebraska.value:
        case RateType.oklahoma.value: {
            return { plural: 'lines', singular: 'line' };
        }
        case RateType.column_inch.value: {
            return { plural: 'column inches', singular: 'column inch' };
        }
        case RateType.inch.value: {
            return { plural: 'inches', singular: 'inch' };
        }
        default: {
            return null;
        }
    }
};
export const getRateAmountLabel = (adType, rateType) => {
    const rateUnit = getRateUnit(adType, rateType);
    if (rateUnit) {
        return `Price per ${rateUnit.singular}`;
    }
    return 'Price';
};
