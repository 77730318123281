import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CONFIRM_AD } from 'routes/placeScroll/helpers/calculatePlacementSteps';
import FreeformCModal from 'components/modals/FreeFormCModal';
export function EditNoticeConfirmationModal({ newspaper, canEditNotice, notice, setShowEditNoticeModal }) {
    const dispatch = useAppDispatch();
    const isPublisher = useAppSelector(selectIsPublisher);
    return (_jsx(FreeformCModal, Object.assign({ setOpen: setShowEditNoticeModal, header: "Edit Notice?", body: `It looks like ${newspaper.data().editRunDatesAfterInvoicePaid && canEditNotice
            ? "you've already been invoiced. Are you sure you want to edit this notice?"
            : "the deadline for the first publication date has passed or you've already been invoiced. To edit this notice, you'll need to reach out to customer support. Email help@column.us, or click below."}` }, { children: _jsx("div", Object.assign({ className: "mt-3" }, { children: newspaper.data().editRunDatesAfterInvoicePaid && canEditNotice ? (_jsx(ColumnButton, { primary: true, id: "edit-notice", onClick: () => {
                    dispatch(push(`/place/${notice.id}?step=${CONFIRM_AD}`));
                }, buttonText: "Edit Notice", type: "button" })) : (_jsx(ColumnButton, { secondary: true, onClick: () => window.open(`mailto:help@column.us?subject=${isPublisher ? 'Publisher' : 'Advertiser'} Request to edit Notice #${notice.id}`), buttonText: "Contact Support", type: "button" })) })) })));
}
