import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'lib/components/Tooltip';
import { SyncStatusType } from 'lib/enums';
import NoticeTableCell from './NoticeTableCell';
/**
 * Determine if the record is at least 10m after deadline.
 */
const getIsAfterDeadline = (record) => {
    var _a;
    const now = new Date().getTime();
    const deadline = (_a = record.firstpublicationdeadline) !== null && _a !== void 0 ? _a : 0;
    const msAfterDeadline = now - deadline;
    return msAfterDeadline > 10 * 60 * 1000;
};
export default function SyncStatusCell({ row }) {
    var _a, _b;
    const isAfterDeadline = getIsAfterDeadline(row.original);
    const syncSuccess = typeof row.original.syncstatusatdeadline === 'number'
        ? Boolean(row.original.syncstatusatdeadline)
        : undefined;
    const syncFailureReason = typeof row.original.syncfailurestatus === 'number'
        ? (_a = SyncStatusType.by_value(row.original.syncfailurestatus)) === null || _a === void 0 ? void 0 : _a.label
        : undefined;
    const preconditionSuccess = typeof row.original.syncpreconditionsuccess === 'number'
        ? Boolean(row.original.syncpreconditionsuccess)
        : undefined;
    const preconditionFailureReason = typeof row.original.syncpreconditionfailurestatus === 'number'
        ? (_b = SyncStatusType.by_value(row.original.syncpreconditionfailurestatus)) === null || _b === void 0 ? void 0 : _b.label
        : undefined;
    let cellContent;
    if (syncSuccess === false) {
        cellContent = (_jsx(Tooltip, Object.assign({ position: "right", helpText: `This notice failed to sync at deadline.${syncFailureReason ? ` Reason: ${syncFailureReason}` : ''}` }, { children: _jsx(ExclamationCircleIcon, { className: "h-6 w-6 text-column-red-500" }) })));
    }
    else if (syncSuccess === true) {
        cellContent = (_jsx(Tooltip, Object.assign({ position: "right", helpText: 'This notice synced successfully.' }, { children: _jsx(CheckCircleIcon, { className: "h-6 w-6 text-column-green-500" }) })));
    }
    else if (isAfterDeadline) {
        cellContent = (_jsx(Tooltip, Object.assign({ position: "right", helpText: 'Deadline has passed, but this notice has not synced.' }, { children: _jsx(ExclamationTriangleIcon, { className: "h-6 w-6 text-column-yellow-600" }) })));
    }
    else if (preconditionSuccess === false) {
        cellContent = (_jsx(Tooltip, Object.assign({ position: "right", helpText: `This notice requires attention for it to sync successfully at ad deadline.${preconditionFailureReason
                ? ` Reason: ${preconditionFailureReason}`
                : ''}` }, { children: _jsx(ExclamationTriangleIcon, { className: "h-6 w-6 text-column-yellow-600" }) })));
    }
    else if (preconditionSuccess === true) {
        cellContent = (_jsx(Tooltip, Object.assign({ position: "right", helpText: 'This notice meets the requirements for it to sync successfully at ad deadline.' }, { children: _jsx(ClockIcon, { className: "h-6 w-6 text-column-green-500" }) })));
    }
    else {
        cellContent = (_jsx(Tooltip, Object.assign({ position: "right", helpText: 'Deadline has not passed, notice waiting to sync.' }, { children: _jsx(ClockIcon, { className: "h-6 w-6 text-column-gray-400" }) })));
    }
    return (_jsx(NoticeTableCell, { children: _jsx("span", Object.assign({ className: "mx-auto" }, { children: cellContent })) }));
}
