import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { DropdownButton } from 'components/DropdownButton';
import PlaceNoticePreview from './PlaceNoticePreview';
import { useUpdatePreview } from './hooks/useUpdatePreview';
export default function PlacementSidebar({ showContent, setShowContent, rate, adTemplate, newspaper, uploading }) {
    const id = 'preview-sidebar';
    const updatingPreview = useUpdatePreview({ newspaper, adTemplate });
    return (_jsxs("div", Object.assign({ id: id, className: classNames('fixed h-full top-0 right-0', {
            'w-1/3': showContent,
            'w-12': !showContent
        }) }, { children: [_jsx("div", Object.assign({ style: { top: '112px', left: '-15px' }, className: "absolute" }, { children: _jsx(DropdownButton, { id: `${id}-dropdown`, textLabel: `${id}-dropdown`, size: "sm", startingDirection: 'left', overrideExpand: showContent, onClick: () => setShowContent(!showContent) }) })), _jsx("div", Object.assign({ style: { paddingTop: '88px' }, className: "mt-6 w-full h-full border-l bg-column-gray-25" }, { children: showContent && (_jsx(PlaceNoticePreview, { rate: rate, newspaper: newspaper, uploading: uploading, loading: updatingPreview })) }))] })));
}
