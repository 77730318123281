import { useCallback, useLayoutEffect, useRef } from 'react';
const isBrowser = typeof window !== 'undefined';
const getScrollPosition = (elementRef) => {
    if (!isBrowser)
        return { x: 0, y: 0, width: 0, height: 0 };
    const target = (elementRef === null || elementRef === void 0 ? void 0 : elementRef.current) ? elementRef.current : document.body;
    const position = target.getBoundingClientRect();
    return {
        x: position.left,
        y: position.top,
        width: position.width || 0,
        height: position.height || 0
    };
};
/**
 * Given a layout setup that looks like the following with:
 * 1. A parent element that has overflow-x: auto
 * 2. A number of children that overflow that parent
 * This hook will call the callback function with the left and right offset of the children
 *  ----------------------------------
 * | parent                           |
 * |  -----------   -----------    -----------
 * | | child     | | child      | | child     |
 * |  -----------   -----------    -----------
 *                                    |
 *  ----------------------------------
 * This is useful when you want to enable / disable other elements depending on your scroll
 * position within the parent element
 */
export const useScrollPositionWithinParent = ({ elementRef, debounce, callback }) => {
    const throttleTimeout = useRef(null);
    const callBack = useCallback(() => {
        var _a;
        const children = (_a = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current) === null || _a === void 0 ? void 0 : _a.children;
        if (!(children === null || children === void 0 ? void 0 : children.length))
            return;
        const firstChild = children[0];
        const lastChild = children[children.length - 1];
        const parentPosition = getScrollPosition(elementRef);
        const firstChildPosition = getScrollPosition({ current: firstChild });
        const lastChildPosition = getScrollPosition({ current: lastChild });
        const currPos = {
            leftOffset: Math.round(firstChildPosition.x - parentPosition.x),
            rightOffset: Math.round(lastChildPosition.x +
                lastChildPosition.width -
                (parentPosition.x + parentPosition.width))
        };
        callback(currPos);
        throttleTimeout.current = null;
    }, [elementRef === null || elementRef === void 0 ? void 0 : elementRef.current]);
    useLayoutEffect(() => {
        var _a;
        const handleScroll = () => {
            if (throttleTimeout.current === null) {
                throttleTimeout.current = setTimeout(callBack, debounce);
            }
        };
        if (!(elementRef === null || elementRef === void 0 ? void 0 : elementRef.current))
            return;
        handleScroll();
        (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', handleScroll);
        return () => { var _a; return (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', handleScroll); };
    }, [elementRef === null || elementRef === void 0 ? void 0 : elementRef.current, callBack, debounce]);
};
