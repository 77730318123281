import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import PercentFormatter from 'components/PercentFormatter';
import { Permissions } from 'lib/permissions/roles';
import { useHasPermission } from 'utils/useHasPermission';
import { formatPrice } from 'lib/pricing/ui';
function InvoiceFormTotalDueTable({ totalDueInCents, taxPercent, handleTaxPercentChange, subtotalInCents, belowMinimum, rateMinimum, currency, processingFeeInCents, isFeeDistributed }) {
    const canModifyPricing = useHasPermission(Permissions.INVOICES_MODIFY_PRICE);
    return (_jsx("div", { children: _jsx("div", Object.assign({ className: "bg-white shadow overflow-hidden sm:rounded-lg" }, { children: _jsx("div", Object.assign({ className: "px-4 py-5 sm:p-0" }, { children: _jsxs("dl", { children: [_jsxs("div", Object.assign({ className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Subtotal" })), _jsx("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: _jsx(NumberFormat, { value: formatPrice(currency, subtotalInCents), thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: "text" }) }))] })), belowMinimum && (_jsx("div", Object.assign({ className: "sm:px-6 sm:pb-5 sm:-mt-2 text-xs text-red-600" }, { children: `This subtotal is below your minimum of ${currency}${rateMinimum}. Adjust line item
                  pricing to reach minimum.` }))), processingFeeInCents > 0 && (_jsxs("div", Object.assign({ className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" }, { children: [_jsxs("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: ["Processing Fee", isFeeDistributed ? ' (Embedded)' : ''] })), _jsx("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: _jsx(NumberFormat, { value: formatPrice(currency, processingFeeInCents), thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: "text" }) }))] }))), _jsxs("div", Object.assign({ className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Tax" })), _jsxs("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: [canModifyPricing ? (_jsx(TextField, { className: "text-sm", label: "", value: taxPercent || '0.00', onChange: e => handleTaxPercentChange(e), id: "formatted-numberformat-input", InputProps: {
                                                style: {
                                                    fontSize: '14px',
                                                    width: '50px',
                                                    textAlign: 'right'
                                                },
                                                inputComponent: PercentFormatter
                                            } })) : (_jsx(NumberFormat, { placeholder: `${taxPercent || '0.00'}`, value: taxPercent, thousandSeparator: true, suffix: "%", decimalScale: 2, fixedDecimalScale: true, displayType: "text" })), ' '] }))] })), _jsxs("div", Object.assign({ className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Total Due" })), _jsxs("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: [_jsx(NumberFormat, { placeholder: `${currency}0.00`, value: formatPrice(currency, totalDueInCents), thousandSeparator: true, prefix: currency, decimalScale: 2, fixedDecimalScale: true, displayType: "text" }), ' '] }))] }))] }) })) })) }));
}
export default InvoiceFormTotalDueTable;
