import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
function FilingTypeLabel({ updatedFilingType, productLine }) {
    const productNameSingular = PRODUCT_TO_NAME[productLine].singular;
    return (_jsx(TextField, { id: "filing-type-label", labelText: `${productNameSingular} type name (this appears in the placement flow)`, required: true, value: updatedFilingType.label, disabled: true }));
}
function NoticeTypeLabelField({ onUpdateFilingType, updatedFilingType }) {
    return (_jsx(TextField, { id: "filing-type-label", labelText: "Notice type name (this appears in the placement flow)", required: true, value: updatedFilingType.label, onChange: newValue => onUpdateFilingType(Object.assign(Object.assign({}, updatedFilingType), { label: newValue })) }));
}
export function FilingTypeLabelField({ onUpdateFilingType, updatedFilingType, productLine }) {
    switch (productLine) {
        case Product.Notice:
            return (_jsx(NoticeTypeLabelField, { onUpdateFilingType: onUpdateFilingType, updatedFilingType: updatedFilingType }));
        default:
            return (_jsx(FilingTypeLabel, { onUpdateFilingType: onUpdateFilingType, updatedFilingType: updatedFilingType, productLine: productLine }));
    }
}
