import { OccupationType } from 'lib/enums';
import { isColumnUser } from 'lib/helpers';
import { exists } from 'lib/types';
export const shouldShowPublisherRegistrationOption = (user) => {
    if (!exists(user))
        return false;
    if (!isColumnUser(user))
        return false;
    return (!user.data().postRegistrationComplete ||
        user.data().occupation === OccupationType.publishing.value);
};
export const hasUserCompletedRegistration = (user, activeOrganization) => {
    var _a;
    return (user === null || user === void 0 ? void 0 : user.data().occupation) === OccupationType.individual.value
        ? !!(user === null || user === void 0 ? void 0 : user.data().postRegistrationComplete)
        : !!((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.postRegistrationComplete);
};
