var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import classNames from 'classnames';
import { html, css } from 'lit';
import { property, state } from 'lit/decorators.js';
import { live } from 'lit/directives/live.js';
import { ColorsCss } from './styles';
import { madlibBtoa } from '../helpers/encoding';
import { MadlibElement } from './MadlibElement';
/**
 * Custom webcomponent representing a multi-line block input
 * element with Madlibs styling.
 *
 *   <madlib-textarea
 *     key="..."
 *     placeholder="..."
 *     hint="..."
 *     transform="..."
 *     value="..."
 *   ></madlib-textarea>
 */
export class MadlibTextArea extends MadlibElement {
    constructor() {
        super(...arguments);
        this.placeholder = undefined;
        this.transform = '';
        this.encoding = 'base64';
    }
    // TODO: Unify
    _getTextTransform() {
        const valid = ['capitalize', 'lowercase', 'uppercase'];
        if (valid.includes(this.transform)) {
            return this.transform;
        }
        return 'none';
    }
    _dispatchUpdate() {
        // Dispatch event that the template has changed and we should re-render the result
        const event = new CustomEvent('template-updated', {
            bubbles: true,
            composed: true,
            detail: {
                key: this.key
            }
        });
        this.dispatchEvent(event);
    }
    _sanitizeUserInput(input) {
        return input
            .replaceAll(/–/g, '-')
            .replaceAll(/[“”]/g, '"')
            .replaceAll(/[‘’]/g, "'");
    }
    _handleInput(e) {
        const newValue = e.target.innerHTML || '';
        const sanitizedInput = this._sanitizeUserInput(newValue);
        // Encode value into base64 before storing
        this.value = madlibBtoa(sanitizedInput);
        // Dispatch event that the template has changed and we should re-render the result
        this._dispatchUpdate();
        // The first non-empty input event makes this "dirty" (has been edited)
        if (newValue) {
            this._dirty = true;
        }
    }
    /**
     * When content is pasted into the field, sanitize it down to only formatting we allow
     */
    _handlePaste(e) {
        var _a;
        e.preventDefault();
        const text = (_a = e.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text/plain');
        if (text) {
            window.document.execCommand('insertText', false, text);
        }
    }
    render() {
        const editableClass = classNames({
            valid: !this._error
        });
        const editableHtml = html `<div
      class="editable ${editableClass}"
      style="text-transform: ${this._getTextTransform()};"
      contenteditable="true"
      spellcheck="false"
      placeholder=${this.placeholder}
      @input=${this._handleInput}
      @blur=${this._handleBlur}
      @paste=${this._handlePaste}
      .innerHTML=${live(atob(this.value))}
    ></div>`;
        const wrapperClass = classNames({
            error: !!this._error
        });
        const errorHtml = this._error
            ? html `<div class="error-msg-text">${this._error}</div>`
            : undefined;
        return html `<div class="input_textarea_wrapper">
      <div class="input_textarea ${wrapperClass}">
        <madlib-hint text="${this.hint}"></madlib-hint>
        ${editableHtml}
      </div>
      ${errorHtml}
    </div>`;
    }
}
MadlibTextArea.TAG_NAME = 'madlib-textarea';
MadlibTextArea.styles = css `
    .input_textarea_wrapper {
      position: relative;
    }

    .input_textarea {
      margin: 0;
      padding: 0;

      display: flex;
      flex-direction: row;
      gap: 4px;
      border: 1px solid ${ColorsCss.LIGHT_BLUE_BORDER};
      border-radius: 2px;

      margin: 4px;
      padding: 2px 4px;
    }

    .input_textarea:focus-within {
      border: 1px solid #63a4f4;
    }

    .input_textarea.error {
      border: 1px solid ${ColorsCss.RED};
    }

    .input_textarea.valid {
      border: 1px solid ${ColorsCss.DARK_BLUE};
    }

    .input_textarea.invalid {
      border: 1px solid #ff0000;
    }

    .editable {
      width: 100%;
    }

    .editable:focus {
      outline: none;
    }

    .editable:empty::before {
      content: attr(placeholder);
      font-weight: 400;
      color: ${ColorsCss.LIGHT_BLUE};
    }

    .editable:empty:focus::before {
      content: '';
    }

    .input_textarea.error .editable:empty::before {
      color: ${ColorsCss.RED};
    }

    .error-msg-text {
      display: none;

      position: absolute;
      left: 4px;

      z-index: 100;

      background: rgba(247, 206, 206, 1);
      padding: 2px 4px;
      border-radius: 2px;

      font-family: Arial, Helvetica, sans-serif;
      font-size: 8px;
      color: rgba(201, 53, 53, 1);
      text-align: left;

      min-width: 100px;
      max-width: 100px;
    }

    .input_textarea_wrapper:hover .error-msg-text {
      display: block;
    }
  `;
__decorate([
    property()
], MadlibTextArea.prototype, "placeholder", void 0);
__decorate([
    property()
], MadlibTextArea.prototype, "transform", void 0);
__decorate([
    property()
], MadlibTextArea.prototype, "encoding", void 0);
__decorate([
    state()
], MadlibTextArea.prototype, "_error", void 0);
