import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ColumnDatePicker from 'components/ColumnDatePicker';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Label } from 'lib/components/Label';
import { getDateForDateStringInTimezone, getDateStringForDateInTimezone } from 'lib/utils/dates';
import moment from 'moment';
import React from 'react';
export const ALL_VERIFICATION_STATUSES = 'Show all';
export const ALL_NOTICES_VERIFIED = 'Publication days with no unverified notices';
export const MISSING_NOTICE_VERIFICATIONS = 'Publication days with unverified notices';
export const MISSING_E_EDITION = 'Publication days without e-edition';
const VERIFICATION_STATUSES = [
    ALL_VERIFICATION_STATUSES,
    ALL_NOTICES_VERIFIED,
    MISSING_NOTICE_VERIFICATIONS,
    MISSING_E_EDITION
];
const ALL_PUBLICATION_DATES = 'All publication dates';
export default function AutomatedAffidavitsTableFilterDialog({ activeOrganization, editedRowFilter, setEditedRowFilter }) {
    return (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "uppercase text-column-gray-300 text-xs font-bold" }, { children: "Filter by" })), _jsx(ColumnSelect, { id: "verification-status", labelText: "Verification Status", size: "small", options: VERIFICATION_STATUSES.map(status => ({
                    value: status,
                    label: status
                })), value: editedRowFilter.verificationStatus, onChange: newValue => setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { verificationStatus: newValue })) }), _jsx(ColumnSelect, { id: "publication-dates", labelText: "Publication Dates", size: "small", options: [
                    {
                        value: ALL_PUBLICATION_DATES,
                        label: ALL_PUBLICATION_DATES
                    },
                    {
                        value: 'Custom',
                        label: 'Custom Date Range'
                    }
                ], value: !editedRowFilter.dateRange ? ALL_PUBLICATION_DATES : 'Custom', onChange: newValue => {
                    if (newValue === ALL_PUBLICATION_DATES) {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { dateRange: undefined }));
                    }
                    else {
                        setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { dateRange: {
                                publicationDateFrom: getDateStringForDateInTimezone({
                                    date: moment().subtract(1, 'month').toDate(),
                                    timezone: activeOrganization.data().iana_timezone
                                }),
                                publicationDateTo: getDateStringForDateInTimezone({
                                    date: new Date(),
                                    timezone: activeOrganization.data().iana_timezone
                                })
                            } }));
                    }
                } }), editedRowFilter.dateRange && (_jsxs("div", { children: [_jsx(Label, Object.assign({ id: "date-filter-label" }, { children: "Choose a date range" })), _jsxs("div", Object.assign({ className: "flex flex-row gap-2 mt-2" }, { children: [_jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "Start date", value: editedRowFilter.dateRange.publicationDateFrom
                                    ? getDateForDateStringInTimezone({
                                        dayString: editedRowFilter.dateRange.publicationDateFrom,
                                        timezone: activeOrganization.data().iana_timezone
                                    })
                                    : undefined, onChange: newDate => newDate &&
                                    setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { dateRange: Object.assign(Object.assign({}, editedRowFilter.dateRange), { publicationDateFrom: getDateStringForDateInTimezone({
                                                date: newDate,
                                                timezone: activeOrganization.data().iana_timezone
                                            }) }) })), shouldDisableDate: date => {
                                    var _a;
                                    if (!date)
                                        return true;
                                    const dateLimit = ((_a = editedRowFilter.dateRange) === null || _a === void 0 ? void 0 : _a.publicationDateTo)
                                        ? getDateForDateStringInTimezone({
                                            dayString: editedRowFilter.dateRange.publicationDateTo,
                                            timezone: activeOrganization.data().iana_timezone
                                        })
                                        : new Date();
                                    return moment(date).isAfter(moment(dateLimit));
                                } }), _jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "End date", value: editedRowFilter.dateRange.publicationDateTo
                                    ? getDateForDateStringInTimezone({
                                        dayString: editedRowFilter.dateRange.publicationDateTo,
                                        timezone: activeOrganization.data().iana_timezone
                                    })
                                    : undefined, onChange: newDate => newDate &&
                                    setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { dateRange: Object.assign(Object.assign({}, editedRowFilter.dateRange), { publicationDateTo: getDateStringForDateInTimezone({
                                                date: newDate,
                                                timezone: activeOrganization.data().iana_timezone
                                            }) }) })), shouldDisableDate: date => {
                                    var _a;
                                    if (!date)
                                        return true;
                                    const dateLimit = ((_a = editedRowFilter.dateRange) === null || _a === void 0 ? void 0 : _a.publicationDateFrom)
                                        ? getDateForDateStringInTimezone({
                                            dayString: editedRowFilter.dateRange.publicationDateFrom,
                                            timezone: activeOrganization.data().iana_timezone
                                        })
                                        : null;
                                    if (!dateLimit)
                                        return false;
                                    return (moment(date).isBefore(dateLimit) ||
                                        moment(date).isAfter(moment()));
                                } })] }))] }))] }));
}
