var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
export class DocumentDoesNotExistError extends Error {
    constructor(ref) {
        super(`Could not get document that does not exist: ${ref === null || ref === void 0 ? void 0 : ref.path}`);
    }
}
/**
 * Get a snapshot from a ref ... for the discerning programmer who is tired of checking
 * exists() and throwing everywhere.
 */
export const getOrThrow = (ref) => __awaiter(void 0, void 0, void 0, function* () {
    const snap = yield (ref === null || ref === void 0 ? void 0 : ref.get());
    if (!exists(snap)) {
        throw new DocumentDoesNotExistError(ref);
    }
    return snap;
});
export const getOrThrowTransaction = (transaction, ref) => __awaiter(void 0, void 0, void 0, function* () {
    const snap = yield transaction.get(ref);
    if (!exists(snap)) {
        throw new DocumentDoesNotExistError(ref);
    }
    return snap;
});
