var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FullScreenModal from 'components/FullScreenModal';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import React, { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { AutomatedBulkDownload, INITIAL_BULK_DOWNLOAD_SETTINGS } from './AutomatedBulkDownload';
import { BulkTemplateSettings } from './BulkTemplateSettings';
const getBulkDownloadSettingsFromOrg = (organization) => {
    const { fullPageTemplate, twoColumnFullPageTemplate, bulkDownload } = organization.data();
    return { fullPageTemplate, twoColumnFullPageTemplate, bulkDownload };
};
export default function BulkDownloadSettings({ activeOrganization, onClose }) {
    const currentSettings = getBulkDownloadSettingsFromOrg(activeOrganization);
    const [formSettings, setFormSettings] = useState(currentSettings);
    const [oneColumnEnabled, setOneColumnEnabled] = useState(!!currentSettings.fullPageTemplate);
    const [twoColumnEnabled, setTwoColumnEnabled] = useState(!!currentSettings.twoColumnFullPageTemplate);
    const invalidBulkDownload = oneColumnEnabled && !formSettings.fullPageTemplate;
    const invalidTwoColumn = twoColumnEnabled && !formSettings.twoColumnFullPageTemplate;
    const disableSubmit = invalidBulkDownload ||
        invalidTwoColumn ||
        columnObjectsAreEqual(currentSettings, formSettings);
    return (_jsxs(FullScreenModal, Object.assign({ headerText: "Bulk Download Settings", submittable: {
            buttonText: 'Save',
            onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const fullPageTemplate = oneColumnEnabled
                    ? formSettings.fullPageTemplate
                    : getFirebaseContext().fieldValue().delete();
                const twoColumnFullPageTemplate = twoColumnEnabled
                    ? formSettings.twoColumnFullPageTemplate
                    : getFirebaseContext().fieldValue().delete();
                const bulkDownload = (_a = formSettings.bulkDownload) !== null && _a !== void 0 ? _a : INITIAL_BULK_DOWNLOAD_SETTINGS;
                yield activeOrganization.ref.update({
                    fullPageTemplate,
                    twoColumnFullPageTemplate,
                    bulkDownload
                });
                onClose();
            }),
            disabled: disableSubmit
        }, onClose: onClose, id: "bulk-download-settings" }, { children: [_jsx(BulkTemplateSettings, { value: Object.assign(Object.assign({}, formSettings), { oneColumnEnabled, twoColumnEnabled }), initialValue: currentSettings, onChange: (_a) => {
                    var { oneColumnEnabled, twoColumnEnabled } = _a, formChanges = __rest(_a, ["oneColumnEnabled", "twoColumnEnabled"]);
                    setOneColumnEnabled(oneColumnEnabled);
                    setTwoColumnEnabled(twoColumnEnabled);
                    setFormSettings(formChanges);
                } }), _jsx(AutomatedBulkDownload, { value: formSettings.bulkDownload, onChange: bulkDownload => setFormSettings(Object.assign(Object.assign({}, formSettings), { bulkDownload })), hasBulkDownload: oneColumnEnabled || twoColumnEnabled })] })));
}
