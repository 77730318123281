import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectAvailableOrganizations } from 'redux/auth';
import { Alert } from 'lib/components/Alert';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { isDefined } from 'lib/helpers';
import { capitalizeWord } from 'lib/utils/strings';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import PublisherOrgElement from './PublisherOrgElement';
function PublisherOrgSharingForm({ value, onChange, resourceNoun, owner }) {
    var _a, _b;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const availableOrganizations = (_a = useAppSelector(selectAvailableOrganizations)) !== null && _a !== void 0 ? _a : [];
    const [orgRefs, setOrgRefs] = useState(value !== null && value !== void 0 ? value : []);
    const ownerRef = owner !== null && owner !== void 0 ? owner : activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref;
    const ownerSnap = useFirestoreSnapshot(ownerRef);
    // Remaining org options are all of the user's available orgs minus:
    // - The owner 'organization'
    // - Orgs already in the shared list
    const options = availableOrganizations.filter(orgRef => orgRef.id !== (ownerRef === null || ownerRef === void 0 ? void 0 : ownerRef.id) &&
        !(orgRefs === null || orgRefs === void 0 ? void 0 : orgRefs.some(sharedOrg => (sharedOrg === null || sharedOrg === void 0 ? void 0 : sharedOrg.id) === orgRef.id)));
    const canEditSharing = activeOrganization && activeOrganization.id === (ownerRef === null || ownerRef === void 0 ? void 0 : ownerRef.id);
    // Determines if an org is in the user's alowed organizations list. If the user
    // is somehow a member of the org that owns a resource but not a member of all the
    // orgs it has been shared with, we need to show a more limited UI.
    const canAccessOrg = (orgRef) => {
        return !orgRef || availableOrganizations.some(org => org.id === (orgRef === null || orgRef === void 0 ? void 0 : orgRef.id));
    };
    if (!canEditSharing) {
        return (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { id: "alert-sharing-disabled", status: "warning", title: "Sharing disabled", description: `This ${resourceNoun} is owned by ${(_b = ownerSnap === null || ownerSnap === void 0 ? void 0 : ownerSnap.data().name) !== null && _b !== void 0 ? _b : 'another organization'}.`, icon: _jsx(LockClosedIcon, { className: "h-5 w-5" }) }) })));
    }
    return (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(EditableCardArray, { baseHeader: "Organization", columns: 2, onChange: newArr => {
                setOrgRefs(newArr);
                const publisherOrganizations = newArr.filter(isDefined);
                onChange(publisherOrganizations);
            }, archivable: {
                confirmation: {
                    header: 'Remove organization?',
                    body: `Are you sure you want to remove this organization? This ${resourceNoun} will no longer be available for notices filed in this publication`
                },
                buttonText: 'Remove',
                shouldAllow: canAccessOrg
            }, createable: {
                buttonText: 'Add organization',
                initialValue: undefined,
                disabled: options.length === 0
            }, Component: props => (_jsx(PublisherOrgElement, Object.assign({ options: options, hasAccess: canAccessOrg(props.value) }, props))), values: orgRefs }) })));
}
export function PublisherOrgSharingCard(props) {
    return (_jsx(CardGridLayout, Object.assign({ header: {
            title: `${capitalizeWord(props.resourceNoun)} Sharing`,
            description: `Share this ${props.resourceNoun} with other organizations you control.`
        } }, { children: _jsx(PublisherOrgSharingForm, Object.assign({}, props)) })));
}
