import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { RateType } from 'lib/enums';
import { centsToDollarsString } from 'lib/helpers';
import DrawerPropertyRow from 'routes/settings/publisher/DrawerPropertyRow';
const getText = (rateType, imageRateInfo) => {
    if (imageRateInfo.flatRate) {
        return `$${centsToDollarsString(imageRateInfo.flatRate)} flat rate per image`;
    }
    const displayInchText = `Rate by display inch`;
    if (rateType === RateType.line.value) {
        return `${displayInchText} - ${imageRateInfo.linesPerInch} lines/inch`;
    }
    return displayInchText;
};
function ImagePriceRow({ rate }) {
    if (!rate.images) {
        return _jsx(_Fragment, {});
    }
    const text = getText(rate.rateType, rate.images);
    return _jsx(DrawerPropertyRow, { label: "Images", value: text });
}
export default ImagePriceRow;
