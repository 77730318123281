import { createReducer, createActions } from 'reduxsauce';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    toastSuccess: ['toast'],
    toastError: ['toast'],
    clearToast: []
});
export const ToastTypes = Types;
export default Creators;
const DEFAULT_TOAST_TIMEOUT = 5000;
const INITIAL_STATE = {
    headerText: '',
    status: 'success',
    bodyText: '',
    timeout: DEFAULT_TOAST_TIMEOUT
};
// selector
export const toastSelector = (state) => state.toast;
/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.TOAST_ERROR]: (state, { toast }) => (Object.assign(Object.assign(Object.assign({}, state), toast), { status: 'error' })),
    [Types.TOAST_SUCCESS]: (state, { toast }) => (Object.assign(Object.assign(Object.assign({}, state), toast), { status: 'success' })),
    [Types.CLEAR_TOAST]: () => INITIAL_STATE
});
