import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import moment from 'moment';
import React, { useCallback } from 'react';
import { shouldDisablePublishingDate } from '../helpers/publishingDates';
import RemovableDate from './RemovableDate';
const dateToFormattedString = (date) => moment(date).format('YYYY-MM-DD');
function RemovableDateEntry({ date, selectedDates, onChange, publishingSetting, newspaper, disabled }) {
    const shouldDisableDate = useCallback((pickableDate) => {
        if (!pickableDate) {
            return false;
        }
        const stringFormattedDate = dateToFormattedString(pickableDate);
        // Don't disable the currently selected date
        if (stringFormattedDate === date) {
            return false;
        }
        return (shouldDisablePublishingDate({
            day: pickableDate,
            publishingSetting,
            newspaper
        }) || selectedDates.includes(stringFormattedDate));
    }, [date, selectedDates, publishingSetting]);
    return (_jsx(RemovableDate, { publicationDate: date, onRowDateChange: onChange, onRemoveRowClick: onChange, showDelete: selectedDates.length > 1, shouldDisableDate: shouldDisableDate, disabled: disabled }));
}
function MultiDateSelector({ selectedDates, onSelectedDatesChange, publishingSetting, newspaper, onAddMoreDatesClick, disabled }) {
    const handleSelectedDateChange = (date, index) => {
        const updatedDates = [...selectedDates];
        updatedDates[index] = dateToFormattedString(date);
        onSelectedDatesChange(updatedDates.sort());
    };
    const handleRemoveSelectedDate = (index) => {
        const updatedDates = [...selectedDates];
        updatedDates.splice(index, 1);
        onSelectedDatesChange(updatedDates);
    };
    const spanClass = 'col-span-2 lg:col-span-1';
    return (_jsxs(_Fragment, { children: [selectedDates.map((date, index) => (_jsx("div", Object.assign({ className: spanClass }, { children: _jsx(RemovableDateEntry, { date: date, onChange: (newDate) => {
                        if (newDate instanceof Date) {
                            handleSelectedDateChange(newDate, index);
                        }
                        else {
                            handleRemoveSelectedDate(index);
                        }
                    }, selectedDates: selectedDates, publishingSetting: publishingSetting, newspaper: newspaper, disabled: disabled }) }), index))), _jsx("div", Object.assign({ className: spanClass }, { children: _jsx(ColumnButton, { type: "button", onClick: onAddMoreDatesClick, secondary: true, fullWidth: true, startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5" }), buttonText: "Add another publication date", disabled: disabled }) }))] }));
}
export default MultiDateSelector;
