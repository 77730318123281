import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { exists } from 'lib/types';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import LabeledSwitch from 'lib/components/LabeledSwitch';
const ALL_OPTIONS = [
    { value: 'evenly', label: 'Distribute the fee evenly across line items' },
    { value: 'final', label: 'Embed the fee in the final line item' },
    { value: 'none', label: 'Show the fee as a distinct line item' }
];
const DISTRIBUTE_NONE = {
    evenly: false,
    finalLineItem: false
};
const DISTRIBUTE_EVENLY = {
    evenly: true,
    finalLineItem: false
};
const DISTRIBUTE_FINAL_LINE_ITEM = {
    evenly: false,
    finalLineItem: true
};
export function DistributeFees({ value, initialValue, onChange }) {
    const activeOrganization = useActiveOrganizationListener();
    if (!exists(activeOrganization)) {
        return null;
    }
    const getDistributeType = () => {
        if (value === null || value === void 0 ? void 0 : value.evenly) {
            return 'evenly';
        }
        if (value === null || value === void 0 ? void 0 : value.finalLineItem) {
            return 'final';
        }
        return 'none';
    };
    const getDefaultBehavior = () => {
        const { distributeEnoticeFee } = activeOrganization.data();
        if (distributeEnoticeFee &&
            (distributeEnoticeFee.evenly || distributeEnoticeFee.finalLineItem)) {
            const { evenly, finalLineItem, affidavitFee } = distributeEnoticeFee;
            const affidavitDescription = affidavitFee
                ? `added to the publisher's affidavit fee`
                : `included in the convenience fee`;
            if (evenly) {
                return {
                    labelDescription: 'distributed evenly across line items',
                    affidavitDescription
                };
            }
            if (finalLineItem) {
                return {
                    labelDescription: 'embedded in the final line item',
                    affidavitDescription
                };
            }
        }
        return {
            labelDescription: 'visible on invoices',
            affidavitDescription: `added to the publisher's affidavit fee`
        };
    };
    const wrappedOnChange = (change) => {
        onChange(Object.assign(Object.assign({}, value), change));
    };
    return (_jsxs(SwitchControlledCard, Object.assign({ labelProps: {
            label: 'Override convenience fee distribution settings?',
            description: `By default, Column's convenience fee is ${getDefaultBehavior().labelDescription} and affidavit fees are ${getDefaultBehavior().affidavitDescription}. If enabled, you can override how the fee(s) appears when using this rate.`,
            value: value !== undefined && value !== null,
            onChange: shouldOverride => shouldOverride
                ? onChange(initialValue !== null && initialValue !== void 0 ? initialValue : { evenly: true, finalLineItem: false })
                : onChange(undefined)
        }, header: "fee distribution" }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "override-distribute-type", options: ALL_OPTIONS, value: getDistributeType(), onChange: type => {
                        switch (type) {
                            case 'evenly': {
                                return wrappedOnChange(DISTRIBUTE_EVENLY);
                            }
                            case 'final': {
                                return wrappedOnChange(DISTRIBUTE_FINAL_LINE_ITEM);
                            }
                            case 'none': {
                                return wrappedOnChange(DISTRIBUTE_NONE);
                            }
                        }
                    }, labelText: "How should Column fees appear on invoices?" }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Add Column's affidavit fee to publisher's affidavit fee?", description: 'If enabled, the Column affidavit fee will be added to the first invoice line item that includes "affidavit" in its description.', value: !!(value === null || value === void 0 ? void 0 : value.affidavitFee), onChange: affidavitFee => wrappedOnChange({ affidavitFee }) }) }))] })));
}
