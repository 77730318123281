export function validateJoiSchema(schema, payload) {
    const { error, value } = schema.validate(payload);
    if (error) {
        return { success: false, error };
    }
    return { success: true, payload: value };
}
export function validateSchemaOrThrow(schema, data, message) {
    const validation = validateJoiSchema(schema, data);
    if (!validation.success) {
        throw new Error(`${message || 'Invalid value'}, Error: ${validation.error}, Data: ${JSON.stringify(data)}`);
    }
    return validation.payload;
}
