import { jsx as _jsx } from "react/jsx-runtime";
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { useState } from 'react';
export default function AffidavitFeeSplitSettingsCard({ onChangeGlobalAffidavitControls, globalAffidavitControls, affidavitReconciliationSettings }) {
    var _a, _b;
    const [feeSplitAmount, setFeeSplitAmount] = useState((_b = (_a = affidavitReconciliationSettings === null || affidavitReconciliationSettings === void 0 ? void 0 : affidavitReconciliationSettings.affidavitFeeSplit) === null || _a === void 0 ? void 0 : _a.feeSplit.amount) !== null && _b !== void 0 ? _b : 0);
    const [feeSplitAmountErrorText, setFeeSplitAmountErrorText] = useState('');
    const handleFeeSplitAmountChange = (newValue) => {
        var _a, _b;
        if (newValue >
            ((_a = affidavitReconciliationSettings === null || affidavitReconciliationSettings === void 0 ? void 0 : affidavitReconciliationSettings.automatedAffidavitFeeInCents) !== null && _a !== void 0 ? _a : 0)) {
            setFeeSplitAmountErrorText(`Cannot exceed fee amount ($${(((_b = affidavitReconciliationSettings === null || affidavitReconciliationSettings === void 0 ? void 0 : affidavitReconciliationSettings.automatedAffidavitFeeInCents) !== null && _b !== void 0 ? _b : 0) /
                100).toFixed(2)})`);
        }
        else {
            setFeeSplitAmount(newValue);
            setFeeSplitAmountErrorText('');
            onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { affidavitFeeSplit: {
                        feeSplit: {
                            type: 'flat',
                            amount: newValue
                        },
                        source: 'newspaper'
                    } }) }));
        }
    };
    return (_jsx(SwitchControlledCard, Object.assign({ labelProps: {
            label: "Should a publisher fee split apply to Column's affidavit automation fee?",
            description: 'This setting should only be changed with explicit confirmation from Richman.',
            value: !!affidavitReconciliationSettings.affidavitFeeSplit,
            onChange: newValue => {
                var _a, _b;
                if (!newValue) {
                    onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { affidavitFeeSplit: undefined }) }));
                }
                else {
                    onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { affidavitFeeSplit: {
                                feeSplit: {
                                    type: 'flat',
                                    amount: (_b = (_a = affidavitReconciliationSettings === null || affidavitReconciliationSettings === void 0 ? void 0 : affidavitReconciliationSettings.affidavitFeeSplit) === null || _a === void 0 ? void 0 : _a.feeSplit.amount) !== null && _b !== void 0 ? _b : 0
                                },
                                source: 'newspaper'
                            } }) }));
                }
            }
        }, header: "Fee Split Detail Settings" }, { children: !!affidavitReconciliationSettings.affidavitFeeSplit && (_jsx(CurrencyTextField, { id: "flat-fee-split-amount", labelText: "What flat amount should the publisher pay?", initialValue: feeSplitAmount, placeholder: `Enter a value in $`, max: affidavitReconciliationSettings === null || affidavitReconciliationSettings === void 0 ? void 0 : affidavitReconciliationSettings.automatedAffidavitFeeInCents, errorText: feeSplitAmountErrorText, onChange: handleFeeSplitAmountChange, required: true })) })));
}
