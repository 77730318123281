import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckCircleIcon, CogIcon, HomeIcon, HomeModernIcon, SpeakerWaveIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { PetsOutlined } from '@material-ui/icons';
import { ClassifiedFilingTypeNames } from 'lib/types/classified';
import ChoiceButton from '../../components/ChoiceButton';
const options = [
    {
        icon: _jsx(SpeakerWaveIcon, { className: "h-6 w-6 flex-shrink-0" }),
        title: ClassifiedFilingTypeNames.AnnouncementsEvents,
        description: 'Business announcements, press release, community events'
    },
    {
        icon: _jsx(UserGroupIcon, { className: "h-6 w-6 flex-shrink-0" }),
        title: ClassifiedFilingTypeNames.CommunityEngagementLetters,
        description: 'Letter to the editor, advocacy'
    },
    {
        icon: _jsx(HomeIcon, { className: "h-6 w-6 flex-shrink-0" }),
        title: ClassifiedFilingTypeNames.ForSale,
        description: 'Furniture, vehicles, motorcycles'
    },
    {
        icon: _jsx(HomeModernIcon, { className: "h-6 w-6 flex-shrink-0" }),
        title: ClassifiedFilingTypeNames.HousingProperty,
        description: 'Residential, commercial, rentals'
    },
    {
        icon: _jsx(CheckCircleIcon, { className: "h-6 w-6 flex-shrink-0" }),
        title: ClassifiedFilingTypeNames.Milestones,
        description: 'Engagement, wedding, retirement, baby announcement'
    },
    {
        icon: _jsx(PetsOutlined, { className: "h-6 w-6 flex-shrink-0" }),
        title: ClassifiedFilingTypeNames.Pets,
        description: 'For sale, re-homing'
    },
    {
        icon: _jsx(CogIcon, { className: "h-6 w-6 flex-shrink-0" }),
        title: ClassifiedFilingTypeNames.Services,
        description: 'Small business, real estate, automotive'
    }
];
function CategoryChoiceForm({ onCategoryChange, category }) {
    return (_jsx(_Fragment, { children: options.map(option => (_jsx(ChoiceButton, Object.assign({ id: option.title, onClick: onCategoryChange, checked: category === option.title, option: option.title }, { children: _jsxs("div", Object.assign({ className: "flex flex-col sm:flex-row items-start sm:items-center justify-between w-full p-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [option.icon, _jsx("span", Object.assign({ className: "ml-4 font-medium" }, { children: option.title }))] })), _jsx("span", Object.assign({ className: "text-xs sm:text-base ml-10 sm:text-right mt-2 sm:mt-0 ml-4 font-light" }, { children: option.description }))] })) }), option.title))) }));
}
export default CategoryChoiceForm;
