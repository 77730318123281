import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import FullScreenModal from 'components/FullScreenModal';
export default function CreateOrEditModal({ onClose, isCustomerOrganization, buttonText, headerText, children, loading, onSubmit, alert, disableSave, id }) {
    return (_jsx(FullScreenModal, Object.assign({ onClose: onClose, headerText: `${headerText} ${isCustomerOrganization ? 'Organization' : 'Customer'}`, submittable: {
            buttonText,
            onSubmit,
            disabled: alert === 'existingCustomer' ||
                (disableSave !== undefined ? disableSave : false)
        }, loading: loading, id: id }, { children: children })));
}
