var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { fuzzyStringContains } from 'lib/utils/strings';
import { excludeInvalidUnverifiedNotices } from 'lib/affidavits';
import { getModelFromSnapshot } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { logInfo } from 'utils/logger';
import { ALL_NOTICES_VERIFIED, MISSING_E_EDITION, MISSING_NOTICE_VERIFICATIONS } from './automatedAffidavitsTableFilterDialog';
export const shouldShowPublicationIssueRow = (rowData, allEEditions, search, rowFilter) => {
    // filter out rows outside of the date range
    if (rowFilter.dateRange) {
        const { publicationDateFrom, publicationDateTo } = rowFilter.dateRange;
        if (publicationDateFrom &&
            moment(rowData.publicationIssue.modelData.publicationDate, 'YYYY-MM-DD').isBefore(moment(publicationDateFrom, 'YYYY-MM-DD'))) {
            return false;
        }
        if (publicationDateTo &&
            moment(rowData.publicationIssue.modelData.publicationDate, 'YYYY-MM-DD').isAfter(moment(publicationDateTo, 'YYYY-MM-DD'))) {
            return false;
        }
    }
    // filter out based on verification status
    const relevantEEditions = (allEEditions || []).filter(eedition => eedition.data().publicationIssue.id === rowData.publicationIssue.id);
    if (rowFilter.verificationStatus) {
        switch (rowFilter.verificationStatus) {
            case ALL_NOTICES_VERIFIED:
                if (rowData.unverifiedRuns.length > 0) {
                    return false;
                }
                break;
            case MISSING_NOTICE_VERIFICATIONS:
                if (rowData.unverifiedRuns.length === 0) {
                    return false;
                }
                break;
            case MISSING_E_EDITION:
                if (relevantEEditions.length > 0) {
                    return false;
                }
                break;
            default:
                logInfo('No verification status filter applied');
        }
    }
    // check for matching on the issue date
    const dbPubDate = rowData.publicationIssue.modelData.publicationDate;
    const uiPubDate = moment(dbPubDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
    if (fuzzyStringContains(dbPubDate, search) ||
        fuzzyStringContains(uiPubDate, search)) {
        return true;
    }
    // check for matching noticeId
    const allRuns = rowData.unverifiedRuns.concat(rowData.verifiedRuns);
    if (allRuns.some(run => fuzzyStringContains(run.modelData.notice.id, search))) {
        return true;
    }
    // check for matching e-edition file name
    if (relevantEEditions.some(eedition => fuzzyStringContains(eedition.data().storagePath, search))) {
        return true;
    }
    return false;
};
export const sortRunsIntoVerifiedAndUnverified = (allRuns) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const verifiedRuns = allRuns.filter(run => run.isVerified());
        const unverifiedNoticeRefs = allRuns
            .filter(run => !run.isVerified())
            .map(unverifiedRun => unverifiedRun.modelData.notice);
        const unverifiedNotices = (yield excludeInvalidUnverifiedNotices(unverifiedNoticeRefs, {})).map(noticeSnap => getModelFromSnapshot(UserNoticeModel, getFirebaseContext(), noticeSnap));
        const unverifiedRuns = unverifiedNotices
            .map(notice => allRuns.find(run => run.modelData.notice.id === notice.id))
            .filter((runOrUndefined) => !!runOrUndefined);
        return wrapSuccess({
            verifiedRuns,
            unverifiedRuns,
            unverifiedNotices
        });
    }
    catch (err) {
        return wrapError(err);
    }
});
