import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBulkPaymentV2EnabledOnOrganization } from 'components/hooks/useBulkPaymentV2EnabledOnOrganization';
import { BillingTermType } from 'lib/enums';
import React from 'react';
import { DetailsRow } from './DetailsRow';
import { CustomerDrawerToggleRow } from './CustomerDrawerToggleRow';
export function CustomerDrawerSettingsTab({ linerRateDescription, displayRateDescription, upfrontPayment, affidavitsBeforePayment, activeOrganization, allowBulkInvoicing, invoicedOutsideColumn, hideDigitalAffidavits, allowAffidavitEmail, isCustomerOrganization, billingTerm, user, isFuneralHome = false, funeralHomeRates = { obituaryRate: '', deathNoticeRate: '' } }) {
    var _a, _b, _c, _d, _e, _f;
    // we only want to display the bulk invoice editing ability if the newspaper
    // has bulkPaymentEnabled_v2 on and the user affiliated with the customer is an individual,
    // or if the newspaper has bulkPaymentEnabled (regardless of user occupation)
    const isBulkPaymentV2EnabledOnOrganization = useBulkPaymentV2EnabledOnOrganization(activeOrganization);
    const userIsIndividual = !((_a = user === null || user === void 0 ? void 0 : user.allowedOrganizations) === null || _a === void 0 ? void 0 : _a.length);
    const activeOrganizationHasBulkPaymentEnabled = isBulkPaymentV2EnabledOnOrganization && userIsIndividual;
    return (_jsxs("div", Object.assign({ className: "p-4 text-sm leading-6 font-medium w-full" }, { children: [!isCustomerOrganization && (_jsxs("div", { children: [_jsx(DetailsRow, { label: "Liner Rate", value: linerRateDescription }), _jsx(DetailsRow, { label: "Display Rate", value: displayRateDescription })] })), isFuneralHome && funeralHomeRates && (_jsxs("div", { children: [_jsx(DetailsRow, { label: "Obituary Rate", value: (_b = funeralHomeRates.obituaryRate) !== null && _b !== void 0 ? _b : '' }), _jsx(DetailsRow, { label: "Death Notice Rate", value: (_c = funeralHomeRates.deathNoticeRate) !== null && _c !== void 0 ? _c : '' })] })), !allowBulkInvoicing && (_jsx(DetailsRow, { label: "Upfront payment", value: isFuneralHome ? 'Always' : upfrontPayment })), !isFuneralHome && (_jsx(CustomerDrawerToggleRow, { label: "Allow affidavits before payment?", value: affidavitsBeforePayment })), activeOrganizationHasBulkPaymentEnabled && (_jsx(CustomerDrawerToggleRow, { label: "Allow bulk invoicing?", value: allowBulkInvoicing })), ((_d = activeOrganization.data()) === null || _d === void 0 ? void 0 : _d.allowInvoiceOutsideColumn) && (_jsx(CustomerDrawerToggleRow, { label: "Allow invoice outside Column?", value: invoicedOutsideColumn })), !isCustomerOrganization && hideDigitalAffidavits && (_jsx(CustomerDrawerToggleRow, { label: "Allow emailed affidavits?", value: allowAffidavitEmail })), userIsIndividual && (_jsx(DetailsRow, { label: "Default invoice due date", value: (_f = (_e = BillingTermType.by_value(billingTerm)) === null || _e === void 0 ? void 0 : _e.label) !== null && _f !== void 0 ? _f : BillingTermType.net_thirty.label }))] })));
}
