var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { useAppDispatch } from 'redux/hooks';
import { exists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { logInfo } from 'utils/logger';
import ToastActions from 'redux/toast';
import { apiPost } from 'api/typed';
import { UpdateSettingRequestTypes } from 'lib/types/updateSettingsRequest';
import { OrganizationType } from 'lib/enums';
import ColumnManagedAffidavitSettingsCard from 'routes/settings/publisher/customers/ColumnManagedAffidavitSettingsCard';
import { getModelFromSnapshot } from 'lib/model';
import { CustomerOrganizationModel } from 'lib/model/objects/customerOrganizationModel';
import HorizontalDivider from '../HorizontalDivider';
import CreateOrEditModal from './CreateOrEditModal';
import { CreateOrEditModalDetails } from './CreateOrEditModalDetails';
import { EditCustomerOrganizationModalSettings } from './EditCustomerOrganizationModalSettings';
import { REQUIRE_UPFRONT_PAYMENT_TYPES } from './CreateOrEditCustomerModalSettings';
export default function EditCustomerOrganizationModal({ customerOrganizationData, activeOrganization, onClose, setCustomerOrganization, rates }) {
    var _a;
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    /**
     * Intiailize fields from customerOrg.
     */
    const customerOrgSnap = customerOrganizationData.customerOrganization;
    const customerOrg = customerOrgSnap.data();
    const linerRateSnap = (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a; return rate.id === ((_a = customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.defaultLinerRate) === null || _a === void 0 ? void 0 : _a.id); })) ||
        (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a, _b; return rate.id === ((_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.defaultLinerRate) === null || _b === void 0 ? void 0 : _b.id); }));
    const displayRateSnap = (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a; return rate.id === ((_a = customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.defaultDisplayRate) === null || _a === void 0 ? void 0 : _a.id); })) ||
        (rates === null || rates === void 0 ? void 0 : rates.find(rate => { var _a, _b; return rate.id === ((_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.defaultDisplayRate) === null || _b === void 0 ? void 0 : _b.id); }));
    const initialCustomerOrgData = Object.assign(Object.assign({}, customerOrg), { linerRateSnap,
        displayRateSnap, upfrontPaymentSetting: (customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.requireUpfrontPayment) === null ||
            (customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.requireUpfrontPayment) === undefined
            ? REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT
            : (customerOrg === null || customerOrg === void 0 ? void 0 : customerOrg.requireUpfrontPayment)
                ? REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE
                : REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE });
    const [updatedCustomerData, setUpdatedCustomerData] = useState(initialCustomerOrgData);
    const [showExitWithoutSavingModal, setShowExitWithoutSavingModal] = useState();
    const disableSave = columnObjectsAreEqual(updatedCustomerData, initialCustomerOrgData);
    const onSave = () => __awaiter(this, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        setLoading(true);
        const linerRateIsPublisherDefault = ((_b = updatedCustomerData.linerRateSnap) === null || _b === void 0 ? void 0 : _b.id) ===
            ((_d = (_c = activeOrganization.data()) === null || _c === void 0 ? void 0 : _c.defaultLinerRate) === null || _d === void 0 ? void 0 : _d.id);
        const displayRateIsPublisherDefault = ((_e = updatedCustomerData.displayRateSnap) === null || _e === void 0 ? void 0 : _e.id) ===
            ((_g = (_f = activeOrganization.data()) === null || _f === void 0 ? void 0 : _f.defaultDisplayRate) === null || _g === void 0 ? void 0 : _g.id);
        const updates = {
            name: (_h = updatedCustomerData.name) === null || _h === void 0 ? void 0 : _h.trim(),
            internalID: updatedCustomerData.internalID,
            phone: updatedCustomerData.phone,
            address: updatedCustomerData.address,
            addressLine2: updatedCustomerData.addressLine2,
            city: updatedCustomerData.city,
            state: typeof updatedCustomerData.state === 'number'
                ? updatedCustomerData.state
                : undefined,
            zipCode: updatedCustomerData.zipCode,
            defaultLinerRate: linerRateIsPublisherDefault
                ? getFirebaseContext().fieldValue().delete()
                : (_j = updatedCustomerData.linerRateSnap) === null || _j === void 0 ? void 0 : _j.ref,
            defaultDisplayRate: displayRateIsPublisherDefault
                ? getFirebaseContext().fieldValue().delete()
                : (_k = updatedCustomerData.displayRateSnap) === null || _k === void 0 ? void 0 : _k.ref,
            requireUpfrontPayment: updatedCustomerData.upfrontPaymentSetting.value,
            bulkPaymentEnabled_v2: updatedCustomerData.bulkPaymentEnabled_v2,
            billingTerm: updatedCustomerData.billingTerm,
            invoicedOutsideColumn: !!updatedCustomerData.invoicedOutsideColumn,
            enableAffidavitsBeforePayment: updatedCustomerData.enableAffidavitsBeforePayment,
            discountConfig: ((_m = (_l = updatedCustomerData.discountConfig) === null || _l === void 0 ? void 0 : _l.subtotal) === null || _m === void 0 ? void 0 : _m.amount) === 0
                ? {}
                : updatedCustomerData.discountConfig
        };
        removeUndefinedFields(updates);
        const { affidavitReconciliationSettings } = updatedCustomerData;
        if (!exists(customerOrgSnap)) {
            logInfo('Attempted to access non-existing customer organization');
            return;
        }
        const customerOrganizationModel = getModelFromSnapshot(CustomerOrganizationModel, getFirebaseContext(), customerOrgSnap);
        if (affidavitReconciliationSettings) {
            yield customerOrganizationModel.updateAutomatedAffidavitConfiguration(affidavitReconciliationSettings);
        }
        yield customerOrganizationData.customerOrganization.ref.update(updates);
        if (updatedCustomerData.bulkPaymentEnabled_v2 !==
            initialCustomerOrgData.bulkPaymentEnabled_v2) {
            const requestType = updatedCustomerData.bulkPaymentEnabled_v2
                ? UpdateSettingRequestTypes.ENABLE_BULK_INVOICE
                : UpdateSettingRequestTypes.DISABLE_BULK_INVOICE;
            yield apiPost('customers/verify-billing-request-to-send-notification', {
                customerOrganizationId: customerOrganizationData.customerOrganization.id,
                requestType
            });
        }
        const toastText = `Your changes to ${(_o = updatedCustomerData.name) === null || _o === void 0 ? void 0 : _o.trim()}'s details have been updated.`;
        dispatch(ToastActions.toastSuccess({
            headerText: 'Changes successfully saved',
            bodyText: toastText
        }));
        if (setCustomerOrganization) {
            setCustomerOrganization(updates);
        }
        setLoading(false);
        onClose();
    });
    /*
     * Temporary measure: The ability to edit settings is being disabled for funeral home customer organizations
     * during the obituaries launch phase.
     */
    const hideEditCustomerOrganizationModalSettings = ((_a = customerOrganizationData.clientOrganization.data()) === null || _a === void 0 ? void 0 : _a.organizationType) ===
        OrganizationType.funeral_home.value;
    const formId = 'edit-customer-org-modal-form';
    return (_jsxs(CreateOrEditModal, Object.assign({ headerText: "Edit", buttonText: "Save", onClose: onClose, isCustomerOrganization: true, loading: loading, onSubmit: onSave, disableSave: disableSave, id: formId }, { children: [_jsxs("div", Object.assign({ className: "px-3 py-6 bg-gray-80 overflow-scroll" }, { children: [_jsx(CreateOrEditModalDetails, { value: updatedCustomerData, onChange: data => {
                            setUpdatedCustomerData(data);
                        }, activeOrganization: activeOrganization, isCustomerOrganization: true }), _jsx("div", Object.assign({ className: "py-6 max-w-4xl m-auto" }, { children: _jsx(HorizontalDivider, {}) })), !hideEditCustomerOrganizationModalSettings && (_jsx(EditCustomerOrganizationModalSettings, { value: updatedCustomerData, onChange: setUpdatedCustomerData, activeOrganization: activeOrganization, rates: rates })), _jsx("div", Object.assign({ className: "py-6 max-w-4xl m-auto" }, { children: _jsx(HorizontalDivider, {}) })), _jsx(ColumnManagedAffidavitSettingsCard, { onUpdateCustomer: data => setUpdatedCustomerData(data), updatedCustomer: updatedCustomerData })] })), showExitWithoutSavingModal && (_jsx(ExitConfirmationModal, { keepEditing: () => setShowExitWithoutSavingModal(false), exitWithoutSaving: () => {
                    setShowExitWithoutSavingModal(false);
                    onClose();
                }, body: 'Your progress will be lost if you exit now. Click keep editing to go back to save your changes instead.', disableOverflow: true }))] })));
}
