import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment-timezone';
import { State } from 'lib/enums';
function InvoiceFormPaperInfoTable({ organization }) {
    var _a;
    const { address, name, city, state, zipCode } = organization;
    return (_jsx("div", { children: _jsx("div", Object.assign({ className: "bg-white shadow overflow-hidden sm:rounded-lg" }, { children: _jsx("div", Object.assign({ className: "px-4 py-5 sm:p-0" }, { children: _jsxs("dl", { children: [_jsxs("div", Object.assign({ className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Newspaper" })), _jsx("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: name }))] })), _jsxs("div", Object.assign({ className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Address" })), _jsxs("dd", Object.assign({ className: "mt-1 text-right text-sm leading-tight text-gray-900 sm:mt-0 sm:col-span-2" }, { children: [address, " ", _jsx("br", {}), " ", city, ' ', (_a = State.by_value(state)) === null || _a === void 0 ? void 0 : _a.abbrev.toUpperCase(), ", ", zipCode, ' '] }))] })), _jsxs("div", Object.assign({ className: "mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5" }, { children: [_jsx("dt", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Date" })), _jsx("dd", Object.assign({ className: "mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" }, { children: moment(new Date(Date.now())).format('MM/DD/YYYY') }))] }))] }) })) })) }));
}
export default InvoiceFormPaperInfoTable;
