var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableLayout } from 'lib/components/TableLayout';
import TabGroup from 'lib/components/Tabs';
import { useMemo, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { apiPost } from 'api/typed';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { isDefined } from 'lib/helpers';
import { push } from 'connected-react-router';
import { useAppDispatch } from 'redux/hooks';
import { Product } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { DEFAULT_ORDER_FILTER, getOrderFilters } from './filters/helpers';
import AdsTableFilterDialog from './AdsTableFilterDialog';
import AdsTableRow from './AdsTableRow';
import { AdsTableColumns } from './types';
function AdsTable({ product, ads, tabs, columns, header, loading, activeOrganization, isPublisher, selectedAdTableTab, setSelectedAdTableTab, setSearchTerm, rowFilter, setRowFilter }) {
    const filtersToShow = useMemo(() => ({
        status: columns.includes(AdsTableColumns.STATUS),
        filingType: !isPublisher || product === Product.Classified,
        customerType: isPublisher && product === Product.Obituary,
        verification: isPublisher && product === Product.Obituary,
        publicationDate: true
    }), [isPublisher, columns.join(','), product]);
    const [editedRowFilter, setEditedRowFilter] = useState(rowFilter);
    const { value: filterFacets } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!activeOrganization) {
                return null;
            }
            const req = {
                search: '',
                filters: getOrderFilters({
                    product,
                    activeOrganization,
                    selectedAdTableTab,
                    tableFilters: {},
                    isPublisher
                }),
                facets: {
                    newspaperorderstatus: {
                        type: 'value',
                        name: 'confirmationstatus'
                    },
                    filingtype: {
                        type: 'value',
                        name: 'publicationcategory'
                    }
                }
            };
            const facetResults = yield apiPost('search/orders/facets', req);
            if (facetResults.error) {
                logAndCaptureException(ColumnService.OBITS, facetResults.error, 'Could not load confirmation status facets for orders', {
                    activeOrganizationId: activeOrganization.id,
                    service: ColumnService.OBITS
                });
                return null;
            }
            return facetResults.response;
        }),
        dependencies: [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id, selectedAdTableTab.id, isPublisher]
    });
    const confirmationStatuses = filterFacets === null || filterFacets === void 0 ? void 0 : filterFacets.newspaperorderstatus[0].data.map(d => d.value);
    const publicationCategories = filterFacets === null || filterFacets === void 0 ? void 0 : filterFacets.filingtype[0].data.map(d => d.value);
    const dispatch = useAppDispatch();
    return (_jsxs("main", Object.assign({ className: "bg-white sm:rounded-lg border border-column-gray-100 shadow-column-2" }, { children: [_jsx(TabGroup, { onClickTab: setSelectedAdTableTab, activeTab: selectedAdTableTab, tabs: tabs, id: "ads-table-tabs" }), _jsx(TableLayout, { id: "ads-table", header: header, columns: columns, data: ads, clickable: {
                    onClick: item => {
                        if (item.newspaperorderstatus === NewspaperOrderStatus.DRAFT) {
                            if (item.ordertype === Product.Obituary) {
                                dispatch(push(`/obituaries/place/${item.orderid}`));
                            }
                            else if (item.ordertype === Product.Classified) {
                                dispatch(push(`/classifieds/place/${item.orderid}`));
                            }
                            else {
                                logAndCaptureMessage('Attempted to access placement flow for unimplemented order type', {
                                    orderType: item.ordertype
                                });
                            }
                        }
                        else {
                            dispatch(push(`/${item.ordertype.toLowerCase()}/${item.orderid}`));
                        }
                    }
                }, filterable: {
                    shouldShowTableItem: () => true,
                    searchEnabled: true,
                    onSearch: setSearchTerm,
                    additionalFilters: {
                        applyFilterChanges: () => setRowFilter(editedRowFilter),
                        resetFilters: () => {
                            setRowFilter(DEFAULT_ORDER_FILTER);
                            setEditedRowFilter(DEFAULT_ORDER_FILTER);
                        },
                        filterHasChanges: safeStringify(rowFilter) !== safeStringify(editedRowFilter),
                        numFiltersActive: Object.values(rowFilter).filter(isDefined).length,
                        onDialogOpen: () => setEditedRowFilter(rowFilter),
                        renderDialog: () => (_jsx(AdsTableFilterDialog, { filtersToShow: filtersToShow, confirmationStatuses: confirmationStatuses !== null && confirmationStatuses !== void 0 ? confirmationStatuses : [], publicationCategories: publicationCategories !== null && publicationCategories !== void 0 ? publicationCategories : [], rowFilter: rowFilter, editedRowFilter: editedRowFilter, setEditedRowFilter: setEditedRowFilter }))
                    }
                }, renderRow: item => _jsx(AdsTableRow, { item: item, columns: columns }), loading: loading })] })));
}
export default AdsTable;
