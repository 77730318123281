var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createReducer, createActions } from 'reduxsauce';
import { NoticeType } from 'lib/enums';
import { PlacementError } from 'lib/errors/PlacementError';
import { exists } from 'lib/types';
import { createSelector } from 'reselect';
import { getDisplayName, getNoticeTypeFromNoticeData } from 'lib/helpers';
import * as headers from 'lib/headers_footers/headers';
import { getFirebaseContext } from 'utils/firebase';
import { refreshDraftMail } from 'lib/mail';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    hydrateNoticeData: ['noticeId'],
    populateNoticeData: ['noticeData'],
    setFiler: ['filerRef'],
    setFiledBy: ['filedBy'],
    setCreatedBy: ['createdBy'],
    setFixedPrice: ['fixedPrice'],
    setNewspaper: ['newspaperRef'],
    setPublicationDates: ['publicationDates'],
    setNoticeText: ['noticeText'],
    setNoticeType: ['noticeType'],
    setPreviousNoticeType: ['previousNoticeType'],
    setFile: ['file'],
    setColumns: ['columns'],
    resetColumns: [],
    setDraft: ['draftRef'],
    setOriginal: ['originalRef'],
    setFilesToAttach: ['filesToAttach'],
    setMail: ['mail'],
    setRate: ['rateRef'],
    setTemplate: ['templateRef'],
    setDisplayUrl: ['displayUrl'],
    setDisplayParams: ['displayParams'],
    setProcessedDisplay: ['processedDisplay'],
    setSquashable: ['squashable'],
    setUnusedConfirmedHtml: ['unusedConfirmedHtml'],
    confirmSchedule: ['scheduleParams'],
    setConfirmedCrop: ['crop'],
    setModularSizeId: ['modularSizeId'],
    setPdfStoragePath: ['pdfStoragePath'],
    setUnusedDisplay: ['unusedDisplay'],
    setDynamicFooter: ['dynamicFooter'],
    setDynamicHeaders: ['dynamicHeaders'],
    setProofStoragePath: ['proofStoragePath'],
    setDefaultRateOverride: ['defaultRateOverride'],
    setDraftSnap: ['draftSnap'],
    saveDraft: [],
    resetState: [],
    clearNoticeType: [],
    setEditing: ['editing'],
    setPublicationDatesUpdated: ['publicationDatesUpdated'],
    setPlacementError: ['placementError'],
    setConfirmedText: ['confirmedText'],
    setFormattingError: ['formattingError'],
    setPostWithoutFormatting: ['postWithoutFormatting'],
    setDesignNotes: ['designNotes'],
    fileWithoutProof: [
        'formattingError',
        'postWithoutFormatting',
        'requiresFormatting'
    ],
    clearFileWithoutProof: [],
    continueWithLargeFile: [],
    setConfirming: ['confirming'],
    setCurrentStep: ['currentStep'],
    setNewCustomerInfo: ['newCustomerInfo'],
    setCustomer: ['customer'],
    setCustomerOrganization: ['customerOrganization'],
    setAccountNumber: ['accountNumber'],
    setDraftSnapshotUnsubscribe: ['draftSnapshotUnsubscribe'],
    confirmReferenceId: ['referenceId'],
    confirmInvoiceRecipient: ['data'],
    setAffidavitRecipients: ['data'],
    setAnonymousFilerId: ['anonymousFilerId'],
    setMadlibData: ['madlibData'],
    setOwner: ['owner'],
    setPostPlacementAction: ['postPlacementAction'],
    setShowPlacementErrors: ['showPlacementErrors'],
    setPreviewContent: ['previewContent'],
    clearPreviewContent: [],
    setDefaultInvoiceRecipient: ['defaultInvoiceRecipient'],
    setText: ['text'],
    setHeaderText: ['headerText']
});
export const PlacementTypes = Types;
export default Creators;
/** This type anticipates future actions which may be added */
export var NoticeDetailsAction;
(function (NoticeDetailsAction) {
    NoticeDetailsAction["REINVOICE"] = "reinvoice";
    NoticeDetailsAction["PARTIAL_REFUND"] = "partial_refund";
})(NoticeDetailsAction || (NoticeDetailsAction = {}));
export function clearMadlibData() {
    return (dispatch, getState) => {
        const state = getState();
        const { madlibData, fixedPrice } = placementSelector(state);
        if (madlibData) {
            dispatch(Creators.setMadlibData(null));
        }
        if (fixedPrice) {
            dispatch(Creators.setFixedPrice(null));
        }
        dispatch(Creators.setDisplayParams(null));
        dispatch(Creators.setNoticeText(null));
        dispatch(Creators.saveDraft());
    };
}
export function syncDynamicHeadersChange(newspaper) {
    return (dispatch, getState) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const state = getState();
        const publicationDates = selectPublicationDates(state);
        const placement = placementSelector(state);
        const noticeType = getNoticeTypeFromNoticeData(placement, newspaper, {
            skipDisplayType: true
        });
        const getHeader = (publicationDates === null || publicationDates === void 0 ? void 0 : publicationDates.length) === 1 &&
            exists(newspaper) &&
            newspaper.data().oneRunHeader
            ? newspaper.data().oneRunHeader
            : (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.headerFormatString;
        const dynamicHeaders = headers.generate(getHeader || '', publicationDates || [], noticeType);
        dispatch(Creators.setDynamicHeaders(dynamicHeaders));
    });
}
export function confirmAffidavitRecipients(affidavitRecipients) {
    return (dispatch, getState) => __awaiter(this, void 0, void 0, function* () {
        dispatch(Creators.setAffidavitRecipients(affidavitRecipients));
        const draft = selectDraftRef(getState());
        const { mail } = affidavitRecipients;
        if (!draft || !mail)
            return { success: true };
        try {
            yield refreshDraftMail(draft, mail);
            return { success: true };
        }
        catch (e) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Placement: Error updating draft mails', {
                draft: draft.id
            });
            dispatch(Creators.setPlacementError(new PlacementError()));
            return { success: false };
        }
    });
}
/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    filer: null,
    filedBy: null,
    createdBy: null,
    publicationDates: null,
    adTemplate: null,
    original: null,
    draft: null,
    rate: null,
    newspaper: null,
    noticeType: NoticeType.custom.value,
    previousNoticeType: NoticeType.custom.value,
    columns: 1,
    confirmedHtml: '',
    unusedConfirmedHtml: '',
    modularSizeId: undefined,
    confirmedCrop: null,
    displayParams: null,
    displayUrl: '',
    mail: null,
    invoiceMailings: null,
    proofStoragePath: null,
    filesToAttach: null,
    processedDisplay: false,
    squashable: false,
    dynamicHeaders: null,
    dynamicFooter: null,
    footerFormatString: '',
    pdfStoragePath: '',
    jpgStoragePath: '',
    jpgURL: '',
    referenceId: '',
    customAffidavit: '',
    defaultRateOverride: false,
    userId: '',
    publicationDatesUpdated: false,
    editing: false,
    draftSnap: null,
    unusedDisplay: '',
    placementError: null,
    confirmedText: '',
    postWithoutFormatting: false,
    requiresFormatting: false,
    designNotes: null,
    continueWithLargeFile: false,
    confirming: false,
    currentStep: { id: '' },
    customer: null,
    newCustomerInfo: null,
    customerOrganization: null,
    requireEmailAffidavit: false,
    mailAffidavitsOutsideColumn: false,
    invoiceRecipient: null,
    defaultInvoiceRecipient: null,
    fixedPrice: null,
    accountNumber: '',
    draftSnapshotUnsubscribe: null,
    anonymousFilerId: null,
    owner: null,
    postPlacementAction: null,
    showPlacementErrors: {
        wait: false,
        largeFile: false
    },
    previewContent: {
        displayParams: null,
        price: '--'
    }
};
export const placementSelector = (state) => state.placement;
export const selectHeaderText = createSelector([placementSelector], placement => placement.headerText || '');
export const selectIsEditing = createSelector([placementSelector], placement => placement.editing);
export const selectPostWithoutFormatting = createSelector([placementSelector], placement => placement.postWithoutFormatting);
export const selectColumns = createSelector([placementSelector], placement => placement.columns);
export const selectNewspaper = createSelector([placementSelector], placement => placement.newspaper);
export const selectAdTemplate = createSelector([placementSelector], placement => placement.adTemplate);
export const selectOriginalId = createSelector([placementSelector], placement => { var _a; return (_a = placement.original) === null || _a === void 0 ? void 0 : _a.id; });
export const selectCustomerRef = createSelector([placementSelector], placement => placement.customer);
export const selectCustomerOrganizationRef = createSelector([placementSelector], placement => placement.customerOrganization);
export const selectNoticeType = createSelector([placementSelector], placement => placement.noticeType);
export const selectPreviousNoticeType = createSelector([placementSelector], placement => placement.previousNoticeType);
export const selectIsDisplayNoticeType = createSelector([selectNoticeType], noticeType => noticeType === NoticeType.display_ad.value);
export const selectIsCustomNoticeType = createSelector([selectNoticeType], noticeType => noticeType === NoticeType.custom.value);
export const selectProcessedDisplay = createSelector([placementSelector], placement => placement.processedDisplay);
export const selectCurrentStepId = createSelector([placementSelector], placement => { var _a; return (_a = placement.currentStep) === null || _a === void 0 ? void 0 : _a.id; });
export const selectIsStepActive = createSelector([selectCurrentStepId, (_, stepId) => stepId], (currentStepId, stepId) => currentStepId === stepId);
export const selectDraftRef = createSelector([placementSelector], placement => placement.draft);
export const selectDraftSnap = createSelector([placementSelector], placement => placement.draftSnap);
export const selectDraftId = createSelector([selectDraftRef], draft => draft === null || draft === void 0 ? void 0 : draft.id);
export const selectColumnCount = createSelector([placementSelector], placement => placement.columns);
export const selectConfirmedHtml = createSelector([placementSelector], placement => placement.confirmedHtml);
export const selectMadlibNoticeName = createSelector([placementSelector], placement => { var _a, _b; return (_b = (_a = placement.madlibData) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.noticeName; });
export const selectReferenceId = createSelector([placementSelector], placement => placement.referenceId);
export const selectNoticeName = createSelector([selectReferenceId, selectMadlibNoticeName], (referenceId, madlibNoticeName) => {
    return referenceId || madlibNoticeName;
});
export const selectShowPlacementErrors = createSelector([placementSelector], placement => placement.showPlacementErrors);
export const selectPreviewContent = createSelector([placementSelector], placement => placement.previewContent);
export const selectRate = createSelector([placementSelector], placement => placement.rate);
export const selectCurrentlySelectedNoticeType = createSelector([selectNoticeType, selectPreviousNoticeType, selectIsDisplayNoticeType], (noticeType, previousNoticeType, isDisplayNoticeType) => isDisplayNoticeType ? previousNoticeType : noticeType);
export const selectDefaultInvoiceRecipient = createSelector([placementSelector], placement => placement.defaultInvoiceRecipient);
export const selectDisplayParams = createSelector([placementSelector], placement => placement.displayParams);
export const selectAccountNumber = createSelector([placementSelector], placement => placement.accountNumber);
export const selectFiler = createSelector([placementSelector], placement => placement.filer);
export const selectFiledBy = createSelector([placementSelector], placement => placement.filedBy);
export const selectSquashable = createSelector([placementSelector], placement => placement.squashable);
export const selectConfirmedCrop = createSelector([placementSelector], placement => placement.confirmedCrop);
export const selectModularSizeId = createSelector([placementSelector], placement => placement.modularSizeId);
export const selectModularSizeRef = createSelector([selectModularSizeId], modularSizeId => {
    if (modularSizeId) {
        return getFirebaseContext().modularSizesRef().doc(modularSizeId);
    }
});
export const selectFixedPrice = createSelector([placementSelector], placement => placement.fixedPrice);
export const selectPublicationDates = createSelector([placementSelector], placement => placement.publicationDates);
/* ------------- Reducer ------------- */
const handlers = {
    [Types.SET_DRAFT_SNAP]: (state, { draftSnap }) => (Object.assign(Object.assign({}, state), { draftSnap })),
    [Types.RESET_STATE]: (state) => {
        if (state.draftSnapshotUnsubscribe) {
            state.draftSnapshotUnsubscribe();
        }
        return Object.assign({}, INITIAL_STATE);
    },
    [Types.CLEAR_NOTICE_TYPE]: (state) => (Object.assign(Object.assign({}, state), { noticeType: NoticeType.custom.value, previousNoticeType: NoticeType.custom.value })),
    [Types.POPULATE_NOTICE_DATA]: (state, { noticeData }) => {
        const { text, confirmedHtml, modularSize } = noticeData, noticeState = __rest(noticeData, ["text", "confirmedHtml", "modularSize"]);
        return Object.assign(Object.assign(Object.assign({}, state), noticeState), { confirmedHtml: confirmedHtml || text || null });
    },
    [Types.SET_MAIL]: (state, { mail }) => (Object.assign(Object.assign({}, state), { mail })),
    [Types.SET_NEWSPAPER]: (state, { newspaperRef }) => (Object.assign(Object.assign({}, state), { newspaper: newspaperRef, 
        // Clear the filer's integration account number when the newspaper changes, it will be refreshed when the customer is fetched
        accountNumber: '' })),
    [Types.SET_FILER]: (state, { filerRef }) => (Object.assign(Object.assign({}, state), { filer: filerRef, userId: filerRef.id })),
    [Types.SET_FILED_BY]: (state, { filedBy }) => (Object.assign(Object.assign({}, state), { filedBy })),
    [Types.SET_CREATED_BY]: (state, { createdBy }) => (Object.assign(Object.assign({}, state), { createdBy })),
    [Types.SET_TEMPLATE]: (state, { templateRef }) => (Object.assign(Object.assign({}, state), { adTemplate: templateRef })),
    [Types.SET_RATE]: (state, { rateRef }) => (Object.assign(Object.assign({}, state), { rate: rateRef })),
    [Types.SET_ORIGINAL]: (state, { originalRef }) => (Object.assign(Object.assign({}, state), { original: originalRef })),
    [Types.SET_DRAFT]: (state, { draftRef }) => (Object.assign(Object.assign({}, state), { draft: draftRef })),
    [Types.SET_NOTICE_TYPE]: (state, { noticeType }) => (Object.assign(Object.assign({}, state), { noticeType })),
    [Types.SET_PREVIOUS_NOTICE_TYPE]: (state, { previousNoticeType }) => (Object.assign(Object.assign({}, state), { previousNoticeType })),
    [Types.SET_DISPLAY_URL]: (state, { displayUrl }) => (Object.assign(Object.assign({}, state), { displayUrl })),
    [Types.SET_PROCESSED_DISPLAY]: (state, { processedDisplay }) => (Object.assign(Object.assign({}, state), { processedDisplay })),
    [Types.SET_COLUMNS]: (state, { columns }) => (Object.assign(Object.assign({}, state), { columns })),
    [Types.SET_FILES_TO_ATTACH]: (state, { filesToAttach }) => (Object.assign(Object.assign({}, state), { filesToAttach })),
    [Types.SET_NOTICE_TEXT]: (state, { noticeText }) => (Object.assign(Object.assign({}, state), { confirmedHtml: noticeText })),
    [Types.SET_DEFAULT_INVOICE_RECIPIENT]: (state, { defaultInvoiceRecipient }) => (Object.assign(Object.assign({}, state), { defaultInvoiceRecipient })),
    [Types.SET_UNUSED_CONFIRMED_HTML]: (state, { unusedConfirmedHtml }) => (Object.assign(Object.assign({}, state), { unusedConfirmedHtml })),
    [Types.SET_SQUASHABLE]: (state, { squashable }) => (Object.assign(Object.assign({}, state), { squashable })),
    [Types.SET_DISPLAY_PARAMS]: (state, { displayParams }) => (Object.assign(Object.assign({}, state), { displayParams })),
    [Types.SET_CONFIRMED_CROP]: (state, { crop }) => (Object.assign(Object.assign({}, state), { confirmedCrop: crop })),
    [Types.SET_MODULAR_SIZE_ID]: (state, { modularSizeId }) => (Object.assign(Object.assign({}, state), { modularSizeId })),
    [Types.SET_PROOF_STORAGE_PATH]: (state, { proofStoragePath }) => (Object.assign(Object.assign({}, state), { proofStoragePath })),
    [Types.SET_PDF_STORAGE_PATH]: (state, { pdfStoragePath }) => (Object.assign(Object.assign({}, state), { pdfStoragePath })),
    [Types.SET_FIXED_PRICE]: (state, { fixedPrice }) => (Object.assign(Object.assign({}, state), { fixedPrice })),
    [Types.CONFIRM_SCHEDULE]: (state, { scheduleParams }) => (Object.assign(Object.assign({}, state), { publicationDates: scheduleParams.publicationDates, dynamicFooter: scheduleParams.dynamicFooter, footerFormatString: scheduleParams.footerFormatString })),
    [Types.SET_DYNAMIC_HEADERS]: (state, { dynamicHeaders }) => (Object.assign(Object.assign({}, state), { dynamicHeaders })),
    [Types.SET_DYNAMIC_FOOTER]: (state, { dynamicFooter }) => (Object.assign(Object.assign({}, state), { dynamicFooter })),
    [Types.SET_DEFAULT_RATE_OVERRIDE]: (state, { defaultRateOverride }) => (Object.assign(Object.assign({}, state), { defaultRateOverride })),
    [Types.SET_EDITING]: (state, { editing }) => (Object.assign(Object.assign({}, state), { editing })),
    [Types.SET_PUBLICATION_DATES_UPDATED]: (state, { publicationDatesUpdated }) => (Object.assign(Object.assign({}, state), { publicationDatesUpdated })),
    [Types.SET_UNUSED_DISPLAY]: (state, { unusedDisplay }) => (Object.assign(Object.assign({}, state), { unusedDisplay })),
    [Types.SET_PLACEMENT_ERROR]: (state, { placementError }) => (Object.assign(Object.assign({}, state), { placementError })),
    [Types.SET_CONFIRMED_TEXT]: (state, { confirmedText }) => (Object.assign(Object.assign({}, state), { confirmedText })),
    [Types.SET_FORMATTING_ERROR]: (state, { formattingError }) => (Object.assign(Object.assign({}, state), { formattingError })),
    [Types.SET_POST_WITHOUT_FORMATTING]: (state, { postWithoutFormatting }) => (Object.assign(Object.assign({}, state), { postWithoutFormatting })),
    [Types.SET_PUBLICATION_DATES]: (state, { publicationDates }) => (Object.assign(Object.assign({}, state), { publicationDates })),
    [Types.FILE_WITHOUT_PROOF]: (state, { formattingError, postWithoutFormatting, requiresFormatting }) => (Object.assign(Object.assign({}, state), { formattingError,
        postWithoutFormatting,
        requiresFormatting, continueWithLargeFile: false })),
    [Types.CLEAR_FILE_WITHOUT_PROOF]: (state) => (Object.assign(Object.assign({}, state), { proofStoragePath: null, jpgStoragePath: '', jpgURL: '', formattingError: null, postWithoutFormatting: false, requiresFormatting: false, continueWithLargeFile: false })),
    [Types.SET_DESIGN_NOTES]: (state, { designNotes }) => (Object.assign(Object.assign({}, state), { designNotes })),
    [Types.CONTINUE_WITH_LARGE_FILE]: (state) => (Object.assign(Object.assign({}, state), { continueWithLargeFile: true, formattingError: null, postWithoutFormatting: false, requiresFormatting: false })),
    [Types.SET_CONFIRMING]: (state, { confirming }) => (Object.assign(Object.assign({}, state), { confirming })),
    [Types.SET_CURRENT_STEP]: (state, { currentStep }) => (Object.assign(Object.assign({}, state), { currentStep })),
    [Types.SET_NEW_CUSTOMER_INFO]: (state, { newCustomerInfo }) => {
        const customerUserName = (newCustomerInfo.name ||
            getDisplayName(newCustomerInfo.firstName, newCustomerInfo.lastName)).trim();
        return Object.assign(Object.assign(Object.assign({}, state), newCustomerInfo), (customerUserName ? { userName: customerUserName } : {}));
    },
    [Types.SET_CUSTOMER]: (state, { customer }) => (Object.assign(Object.assign({}, state), { customer })),
    [Types.SET_CUSTOMER_ORGANIZATION]: (state, { customerOrganization }) => (Object.assign(Object.assign({}, state), { customerOrganization })),
    [Types.SET_ACCOUNT_NUMBER]: (state, { accountNumber }) => (Object.assign(Object.assign({}, state), { accountNumber })),
    [Types.SET_DRAFT_SNAPSHOT_UNSUBSCRIBE]: (state, { draftSnapshotUnsubscribe }) => {
        if (state.draftSnapshotUnsubscribe) {
            state.draftSnapshotUnsubscribe();
        }
        return Object.assign(Object.assign({}, state), { draftSnapshotUnsubscribe });
    },
    [Types.CONFIRM_REFERENCE_ID]: (state, { referenceId }) => (Object.assign(Object.assign({}, state), { referenceId, 
        // When the reference ID changes, set the proof storage path
        // to 'null' so that the proof is regenerated
        proofStoragePath: null })),
    [Types.CONFIRM_INVOICE_RECIPIENT]: (state, payload) => {
        const { invoiceRecipient } = payload.data;
        return Object.assign(Object.assign({}, state), { invoiceRecipient });
    },
    [Types.SET_AFFIDAVIT_RECIPIENTS]: (state, payload) => {
        const newState = Object.assign(Object.assign({}, state), { requireEmailAffidavit: payload.data.requireEmailAffidavit, mailAffidavitsOutsideColumn: payload.data.mailAffidavitsOutsideColumn });
        if (payload.data.customAffidavit !== undefined) {
            newState.customAffidavit = payload.data.customAffidavit;
        }
        if (payload.data.mail !== undefined) {
            newState.mail = payload.data.mail;
        }
        return newState;
    },
    [Types.SET_OWNER]: (state, { owner }) => (Object.assign(Object.assign({}, state), { owner })),
    [Types.SET_ANONYMOUS_FILER_ID]: (state, { anonymousFilerId }) => (Object.assign(Object.assign({}, state), { anonymousFilerId })),
    [Types.SET_MADLIB_DATA]: (state, { madlibData }) => (Object.assign(Object.assign({}, state), { madlibData })),
    [Types.SET_POST_PLACEMENT_ACTION]: (state, { postPlacementAction }) => (Object.assign(Object.assign({}, state), { postPlacementAction })),
    [Types.SET_SHOW_PLACEMENT_ERRORS]: (state, { showPlacementErrors }) => {
        const { wait, largeFile } = showPlacementErrors || {};
        return Object.assign(Object.assign({}, state), { showPlacementErrors: Object.assign(Object.assign(Object.assign({}, state.showPlacementErrors), (wait !== undefined ? { wait } : {})), (largeFile !== undefined ? { largeFile } : {})) });
    },
    [Types.SET_PREVIEW_CONTENT]: (state, { previewContent }) => (Object.assign(Object.assign({}, state), { previewContent })),
    [Types.CLEAR_PREVIEW_CONTENT]: (state) => (Object.assign(Object.assign({}, state), { previewContent: {
            displayParams: state.displayParams,
            price: '--'
        } })),
    [Types.SET_TEXT]: (state, { text }) => (Object.assign(Object.assign({}, state), { text })),
    [Types.SET_HEADER_TEXT]: (state, { headerText }) => (Object.assign(Object.assign({}, state), { headerText }))
};
export const reducer = createReducer(INITIAL_STATE, handlers);
/**
 * For testing only! Create a reducer with initial state.
 */
export const testReducerWithState = (state) => createReducer(state, handlers);
