import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
export function PDFViewer({ startOpen, header, pdfUrl, extraMetadata, children }) {
    const [showPdfPreview, setShowPdfPreview] = useState(startOpen);
    const PDF_UPLOAD_ROW_HEIGHT = '70vh';
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-3 border-b border-column-gray-100", style: {
            height: showPdfPreview ? PDF_UPLOAD_ROW_HEIGHT : 'auto'
        } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col my-4" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("div", Object.assign({ className: "truncate" }, { children: header })), _jsxs("div", Object.assign({ className: "w-4 mx-4" }, { children: [!showPdfPreview && (_jsx(ChevronUpIcon, { onClick: () => setShowPdfPreview(true), className: "cursor-pointer text-gray-500 hover:text-gray-600" })), showPdfPreview && (_jsx(ChevronDownIcon, { onClick: () => setShowPdfPreview(false), className: "cursor-pointer text-gray-500 hover:text-gray-600" }))] }))] })), _jsx("div", Object.assign({ className: "flex-1" }, { children: _jsxs("div", Object.assign({ className: "flex items-center text-xs text-grey-400" }, { children: [_jsx("a", Object.assign({ className: "text-column-primary-600 underline", href: pdfUrl, target: "_blank", rel: "noopener noreferrer" }, { children: "View PDF" })), extraMetadata && (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "mx-2" }, { children: "|" })), _jsx("span", { children: extraMetadata })] }))] })) }))] })), showPdfPreview && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "flex-1" }, { children: _jsx("iframe", { title: "Rejected Affidavit Preview", src: `${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`, frameBorder: "0", height: "100%", width: "100%" }) })), children] }))] })));
}
