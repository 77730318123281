var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import api from 'api';
import { OrganizationType, RoleType } from 'lib/enums';
import { getSubOrganizations } from 'lib/utils/organizations';
import { getFirebaseContext } from 'utils/firebase';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations, selectUser } from 'redux/auth';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Form } from 'lib/components/Form';
import Validator from 'validator';
import { ColumnButton } from 'lib/components/ColumnButton';
import { safeStringify } from 'lib/utils/stringify';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import CreateInvitesResult from './CreateInvitesResult';
import InviteWarningModal from './InviteWarningModal';
import InviteMembersInputRow from './InviteMembersInputRow';
const inviteSuccessMessage = 'User(s) invited successfully!';
function CreateInvitesFormContainer({ organization, onSetToastMessage, onSetShowInviteForm, onUpdateInviteModalHeaderText }) {
    const [loading, setLoading] = useState(false);
    const [invitesData, setInvitesData] = useState();
    const [showParentWarningModal, setShowParentWarningModal] = useState(false);
    const [values, setValues] = useState([
        { email: null, role: null }
    ]);
    const [warningModalDisplayed, setWarningModalDisplayed] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState([]);
    const [errors, setErrors] = useState();
    const availableOrganizations = useAppSelector(selectAvailableOrganizations);
    const user = useAppSelector(selectUser);
    const DUPLICATE_EMAIL_ERROR = 'Do not enter duplicate emails.';
    useEffect(() => {
        setIsEmailValid(values.map(val => (val.email ? Validator.isEmail(val.email) : true)));
        setErrors(new Array(values.length).fill(''));
    }, [safeStringify(values)]);
    const reset = () => {
        setValues([{ email: null, role: null }]);
        setLoading(false);
        setShowParentWarningModal(false);
        setWarningModalDisplayed(false);
        setInvitesData(undefined);
        onUpdateInviteModalHeaderText('Invite members to your organization');
    };
    const onConfirmClick = () => {
        setShowParentWarningModal(false);
        setWarningModalDisplayed(true);
    };
    const handleChange = (i, value) => {
        const currValues = [...values];
        currValues[i].email = value;
        setValues(currValues);
    };
    const handleRoleSelect = (i, roleValue) => {
        var _a, _b;
        const currValues = [...values];
        currValues[i].role = (_b = (_a = RoleType.by_label(roleValue)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
        setValues(currValues);
    };
    const addClick = () => {
        setValues([...values, { email: null, role: null }]);
    };
    const removeClick = (i) => {
        const newValues = [...values];
        newValues.splice(i, 1);
        setValues(newValues);
    };
    useEffect(() => {
        if (!showParentWarningModal && warningModalDisplayed) {
            void handleSubmit();
        }
    }, [showParentWarningModal, warningModalDisplayed]);
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const ctx = getFirebaseContext();
            const childOrgs = yield getSubOrganizations(ctx, organization.ref);
            const shouldShowWarningModal = organization.data().organizationType ===
                OrganizationType.newspaper.value &&
                childOrgs.length &&
                !warningModalDisplayed &&
                availableOrganizations.length > 1;
            if (shouldShowWarningModal) {
                setShowParentWarningModal(true);
                return;
            }
            // while emails are being sent, show loader in button
            // on success, clear form
            setLoading(true);
            const req = {
                inviteData: values,
                organizationId: organization.id,
                isExistingUserInvite: false
            };
            const result = yield api.post('users/invite', req);
            const inviteResponseData = result.data;
            setInvitesData(inviteResponseData);
            setLoading(false);
            const inviteSentSuccessfully = (inviteResponseData.invited.length ||
                inviteResponseData.invitesUnsnoozed) &&
                !inviteResponseData.alreadyHaveInvitesToCurrentOrg.length &&
                !inviteResponseData.alreadyMembersOfInvitedOrg.length &&
                !inviteResponseData.alreadyLinkedToIncompatibleOrganization.length &&
                !inviteResponseData.alreadyInvitedToIncompatibleOrganization.length;
            if (inviteSentSuccessfully) {
                onSetToastMessage(inviteSuccessMessage);
                onSetShowInviteForm(false);
            }
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error sending invite of an organization to user', {
                userId: user === null || user === void 0 ? void 0 : user.id,
                organizationId: organization.id,
                invitedEmails: JSON.stringify(values)
            });
        }
    });
    const handleFormSubmit = () => __awaiter(this, void 0, void 0, function* () {
        const emails = values.map(val => { var _a; return (_a = val.email) === null || _a === void 0 ? void 0 : _a.toLowerCase(); });
        const errorsFound = values.map((_v, i) => {
            if (!isEmailValid[i])
                return 'Email is invalid';
            if (new Set(emails).size !== emails.length)
                return DUPLICATE_EMAIL_ERROR;
            return '';
        });
        if (errorsFound.some(e => !!e)) {
            setErrors(errorsFound);
            return;
        }
        yield handleSubmit();
    });
    const userInvitationFailed = Boolean(invitesData &&
        (invitesData.emailsLinkedToNotices.length ||
            invitesData.alreadyHaveInvitesToCurrentOrg.length ||
            invitesData.alreadyMembersOfInvitedOrg.length ||
            invitesData.alreadyLinkedToIncompatibleOrganization.length ||
            invitesData.alreadyInvitedToIncompatibleOrganization.length));
    return (_jsxs(_Fragment, { children: [userInvitationFailed && invitesData && (_jsx("div", { children: _jsx(CreateInvitesResult, { invitesData: invitesData, onUpdateInviteModalHeaderText: onUpdateInviteModalHeaderText, onHandleTryAgainClick: reset, emailsLinkedToNotices: invitesData.emailsLinkedToNotices.length
                        ? organization.data().organizationType ===
                            OrganizationType.newspaper.value
                        : false }) })), showParentWarningModal && (_jsx("div", { children: _jsx(InviteWarningModal, { onConfirmClick: onConfirmClick, organization: organization, onUpdateInviteModalHeaderText: onUpdateInviteModalHeaderText }) })), !showParentWarningModal && !userInvitationFailed && (_jsx(_Fragment, { children: _jsxs(Form, Object.assign({ onSubmit: handleFormSubmit }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-y-2" }, { children: [_jsxs("div", Object.assign({ className: "font-regular text-large text-column-gray-400 mt-2" }, { children: ["If additional colleagues should have permission to manage", ' ', organization.data().name, "'s account on Column, please invite them here."] })), values.map((el, i) => (_jsx(InviteMembersInputRow, { index: i, value: el, loading: loading, onValueChange: (newEmail) => handleChange(i, newEmail), onRoleSelect: roleValue => handleRoleSelect(i, roleValue), onRemoveClick: () => removeClick(i), error: errors && errors[i] }, `publisher-user-invite-${i}`)))] })), _jsxs("div", Object.assign({ className: "flex items-center mt-2 w-max cursor-pointer text-column-primary-500", onClick: () => addClick() }, { children: [_jsx(PlusIcon, { className: "h-5 w-5" }), _jsx("div", Object.assign({ className: "ml-2 font-medium text-sm" }, { children: "Invite Additional User" }))] })), _jsx("div", Object.assign({ className: "mt-6" }, { children: _jsx(ColumnButton, { primary: true, id: "confirm-invite", disabled: loading, loading: loading, buttonText: "Confirm", type: "submit" }) }))] })) }))] }));
}
export default CreateInvitesFormContainer;
