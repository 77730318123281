var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import TemplateDownloadError from './TemplateDownloadError';
import { getTemplateStylesFromURL } from '../../templateSettingsUtils';
import { TemplateStyles } from '../shared/TemplateStyles';
export default function TemplateDesignTab({ template }) {
    const [templateStyles, setTemplateStyles] = useState();
    const [downloadError, setDownloadError] = useState('');
    useEffect(() => {
        setDownloadError('');
        void (() => __awaiter(this, void 0, void 0, function* () {
            const { downloadUrl, styles } = template.data();
            if (styles) {
                setTemplateStyles(styles);
                return;
            }
            try {
                setTemplateStyles(yield getTemplateStylesFromURL(downloadUrl));
            }
            catch (err) {
                logAndCaptureException(ColumnService.INDESIGN, err, 'Error loading template styles', {
                    downloadUrl
                });
                setDownloadError(`${err}`);
            }
        }))();
    }, [template === null || template === void 0 ? void 0 : template.id]);
    if (downloadError)
        return _jsx(TemplateDownloadError, { error: downloadError });
    return (_jsx("div", Object.assign({ className: "flex flex-col divide-y" }, { children: templateStyles ? (_jsx(TemplateStyles, { templateStyles: templateStyles })) : (_jsx(LoadingState, {})) })));
}
