var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import LayoutSelector from './LayoutSelector';
import { TopActionBar } from './TopActionBar';
import { NewspaperOrderInfo } from './NewspaperOrderInfo';
import ProofPreview from './ProofPreview';
import FormattedEditorWrapper from './FormattedEditorWrapper';
import ExtraFieldInputs from './ExtraFieldInputs';
function DraftContent({ adData, onAdChange, orderPricingComponent, order, version, onUpdateNewspaperOrdersFormData, newspaperOrdersFormData, reloadingPreview, canEditContent }) {
    // TODO: Pass layout in depending on which newspaperOrder we're on
    const { layout, filingType } = newspaperOrdersFormData[0];
    if (!layout) {
        return (_jsx(Alert, { id: "missing-layout", description: "Layout is missing in draft content step. Please refresh the page." }));
    }
    if (!filingType) {
        return (_jsx(Alert, { id: "missing-filing-type", description: "Publishing category is missing in draft content step. Please refresh the page." }));
    }
    const onLayoutChange = (newLayout) => {
        var _a;
        const newNewspaperOrdersFormData = newspaperOrdersFormData.map(newspaperOrder => (Object.assign(Object.assign({}, newspaperOrder), { layout: newLayout })));
        onUpdateNewspaperOrdersFormData(newNewspaperOrdersFormData);
        // If the new layout supports fewer photos than are currently on the order, we should remove the extra photos
        // also make sure to remove the crop data from the images as it may now be invalid
        const newAdData = Object.assign(Object.assign({}, adData), { orderImages: (_a = adData.orderImages) === null || _a === void 0 ? void 0 : _a.slice(0, newLayout.photos).map(image => {
                const { crop } = image, rest = __rest(image, ["crop"]);
                return Object.assign({}, rest);
            }) });
        onAdChange(newAdData);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "w-full bg-white text-center border-b border-column-gray-100 p-4" }, { children: [_jsxs(TopActionBar, { children: [_jsx(NewspaperOrderInfo, { order: order, filingTypeName: adData.filingTypeName || '' }), orderPricingComponent, _jsx(ProofPreview, { newspaperOrdersFormData: newspaperOrdersFormData, reloadingPreview: reloadingPreview, order: order, version: version })] }), _jsx(LayoutSelector, { onLayoutChange: onLayoutChange, newspaperOrder: newspaperOrdersFormData[0], layout: layout, selectionDisabled: !canEditContent })] })), _jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(ExtraFieldInputs, { adData: adData, onAdChange: onAdChange }) })), _jsxs("div", Object.assign({ className: "flex flex-wrap justify-center p-2" }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-full h-12" }, { children: _jsx("div", { id: "custom-toolbar-container" }) })), _jsx(FormattedEditorWrapper, { newspaperOrdersFormData: newspaperOrdersFormData, onAdChange: onAdChange, adData: adData, layout: layout, disableEditing: !canEditContent, product: order.modelData.product, filingType: filingType })] }))] }));
}
export default DraftContent;
