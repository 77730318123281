import { useEffect } from 'react';
import PlacementActions, { selectColumnCount, selectConfirmedCrop, selectProcessedDisplay } from 'redux/placement';
import { safeStringify } from 'lib/utils/stringify';
import { displayParamsFromNoticeAndPageParams } from 'lib/indesign/helpers';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetTemplateSpecs } from './useGetTemplateSpecs';
/**
 * Keeps the display params for a display ad in sync with the selected columns, modular size, and crop.
 */
export function useUpdateDisplayParamsForDisplayAd({ publisherOrganization, draft, selectedModularSize }) {
    const dispatch = useAppDispatch();
    const processedDisplay = useAppSelector(selectProcessedDisplay);
    const selectedColumns = useAppSelector(selectColumnCount);
    const crop = useAppSelector(selectConfirmedCrop);
    const columns = (selectedModularSize === null || selectedModularSize === void 0 ? void 0 : selectedModularSize.data().columns) || selectedColumns;
    const { templateSpecs: pageParams } = useGetTemplateSpecs({
        publisherOrganization,
        draft
    });
    useEffect(() => {
        if (!processedDisplay ||
            !columns ||
            !crop ||
            !crop.absHeight ||
            !crop.absWidth ||
            !pageParams) {
            return;
        }
        const newParams = displayParamsFromNoticeAndPageParams({
            crop,
            pageParams,
            columns,
            fixedWidthInInches: selectedModularSize === null || selectedModularSize === void 0 ? void 0 : selectedModularSize.data().width
        });
        dispatch(PlacementActions.setDisplayParams(newParams));
        dispatch(PlacementActions.setColumns(newParams.columns));
    }, [
        processedDisplay,
        columns,
        safeStringify(crop),
        safeStringify(pageParams),
        selectedModularSize === null || selectedModularSize === void 0 ? void 0 : selectedModularSize.ref.id
    ]);
}
