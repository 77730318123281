import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
export default function PaginationReviewDisplay({ paginationReview }) {
    const { combinedStyleAudit, manifestCheck, auditPassed } = paginationReview;
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "text-xl " }, { children: ["Automated Pagination Review - ", auditPassed ? 'Passed ✅' : 'Failed'] })), !auditPassed && (_jsxs(_Fragment, { children: [_jsxs("div", { children: ["Ads missing in manifest:", ' ', manifestCheck.noticesNotInManifest
                                .map(notice => notice.internalID)
                                .join(', ')] }), _jsxs("div", { children: ["Extra ads in manifest:", ' ', manifestCheck.extraNoticesInManifest
                                .map(notice => notice.internalID)
                                .join(', ')] }), _jsxs("div", { children: ["Ads for wrong publisher:", ' ', combinedStyleAudit.adsForWrongPublisher
                                .map(ad => ad.internalID)
                                .join(', ')] }), _jsxs("div", { children: ["Missing ads:", ' ', _jsx("span", Object.assign({ className: "text-column-red-600" }, { children: combinedStyleAudit.missingAds
                                    .map(ad => ad.internalID)
                                    .join(', ') }))] }), _jsxs("div", { children: [_jsx("span", Object.assign({ className: "text-column-red-600" }, { children: "Ads for wrong date: " })), combinedStyleAudit.adsForWrongDate
                                .map(ad => ad.internalID)
                                .join(', ')] }), _jsxs("div", { children: ["Duplicate ads:", ' ', paginationReview.combinedStyleAudit.duplicateAds
                                .map(ad => ad.internalID)
                                .join(', ')] }), _jsxs("div", { children: ["Unknown ads:", ' ', paginationReview.combinedStyleAudit.unknownAds.join(', ')] }), _jsx("div", { children: "Potentially failed blocks:" }), paginationReview.auditFailures.map((failure, idx) => (_jsxs("div", { children: [_jsx("div", { children: failure.visionModelResult.error_description }), _jsx("img", { src: failure.blockImageUrl, alt: `Screenshot of potentially failed block` }), _jsxs("div", { children: ["Order numbers: ", failure.ocrResult.order_numbers.join(', ')] }), _jsxs("div", { children: ["Minimal whitespace:", ' ', failure.visionModelResult.minimal_whitespace ? 'Yes' : 'No'] }), _jsxs("div", { children: ["No ads have scanned documents:", ' ', failure.visionModelResult.no_pictures ? 'Yes' : 'No'] }), _jsxs("div", { children: ["No duplicates:", ' ', failure.visionModelResult.no_duplicate_ads ? 'Yes' : 'No'] }), _jsxs("div", { children: ["No weird characters:", ' ', failure.visionModelResult.no_weird_characters ? 'Yes' : 'No'] })] }, idx)))] }))] }));
}
