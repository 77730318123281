import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PDFBounder from './PDFBounder';
import { ModularSizeField } from './ModularSizeField';
export function DisplayCropTool({ displayImageUrl, modularSizes = [], selectedModularSize }) {
    const shouldShowModularSizeField = !!(modularSizes === null || modularSizes === void 0 ? void 0 : modularSizes.length);
    return (_jsxs(_Fragment, { children: [shouldShowModularSizeField && (_jsx("div", Object.assign({ className: "mb-4" }, { children: _jsx(ModularSizeField, { modularSizes: modularSizes }) }))), _jsx(PDFBounder, { src: displayImageUrl, aspectRatio: getAspectRatioForModularSize(selectedModularSize === null || selectedModularSize === void 0 ? void 0 : selectedModularSize.data()) })] }));
}
function getAspectRatioForModularSize(modularSize) {
    if (!modularSize) {
        return;
    }
    return modularSize.width / modularSize.height;
}
