import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import NoticeTypeGlobalSettings from './noticeTypeGlobalSettings';
import NoticeTypeUpdateForm from './filingTypeUpdateForm';
import FilingTypeTable from './filingTypeTable';
import FilingTypeDrawer from './filingTypeDrawer';
/**
 * Wrapper component for the Notice Types settings page.
 */
export default function FilingTypesSettings({ activeOrganization, filingTypes, archivable, creatable, onSave, productLine }) {
    const [editedFilingType, setEditedFilingType] = useState();
    const [drawerFilingType, setDrawerFilingType] = useState();
    const [showGlobalControls, setShowGlobalControls] = useState(false);
    if (showGlobalControls) {
        return (_jsx(NoticeTypeGlobalSettings, { activeOrganization: activeOrganization, onClose: () => setShowGlobalControls(false) }));
    }
    if (editedFilingType) {
        const isNewFilingType = filingTypes.every(existingFilingType => existingFilingType.label !== editedFilingType.label);
        return (_jsx(NoticeTypeUpdateForm, { activeOrganization: activeOrganization, onClose: () => {
                setEditedFilingType(undefined);
            }, filingType: editedFilingType, onSave: onSave, productLine: productLine, isNewFilingType: isNewFilingType }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(FilingTypeTable, { onEditFilingType: setEditedFilingType, onClickGlobalControls: () => setShowGlobalControls(true), onViewFilingType: setDrawerFilingType, activeOrganization: activeOrganization, filingTypes: filingTypes, archivable: archivable, creatable: creatable
                    ? Object.assign(Object.assign({}, creatable), { onCreate: () => {
                            const newFilingType = creatable.onCreate();
                            setEditedFilingType(newFilingType);
                        } }) : undefined, productLine: productLine }), drawerFilingType && (_jsx(FilingTypeDrawer, { onEditFilingType: type => setEditedFilingType(type), filingType: drawerFilingType, onClose: () => setDrawerFilingType(undefined) }))] }));
}
