var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DetailsRow } from 'components/CustomerDrawer/DetailsRow';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { isAdvertiserOrder } from 'lib/types/order';
import { getOrThrow } from 'lib/utils/refs';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
const getFuneralHomeDetailsFromOrder = (order) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    if (isAdvertiserOrder(order.modelData)) {
        const fhOrgSnapshot = yield getOrThrow(order.modelData.advertiserOrganization);
        const fhOrg = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), fhOrgSnapshot);
        return {
            funeralHomePhone: (_a = fhOrg.modelData.phone) !== null && _a !== void 0 ? _a : '',
            funeralHomeName: (_b = fhOrg.modelData.name) !== null && _b !== void 0 ? _b : '',
            funeralHomeEmail: (_c = fhOrg.modelData.email) !== null && _c !== void 0 ? _c : ''
        };
    }
});
export function FuneralHomeDetails({ order, obituary }) {
    var _a, _b, _c;
    const { value: data, isLoading, isError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (isAdvertiserOrder(order.modelData)) {
                return yield getFuneralHomeDetailsFromOrder(order);
            }
            return obituary.modelData.deathVerification;
        }),
        dependencies: [order.id, obituary.id]
    });
    if (isError)
        return (_jsx(Alert, { id: "funeral-home-detail-alert", title: "Funeral home details not found", icon: _jsx(ExclamationCircleIcon, { className: "h-5" }) }));
    if (isLoading)
        return _jsx(LoadingSpinner, {});
    return (_jsx("div", Object.assign({ className: "text-sm leading-6 font-medium w-full" }, { children: _jsxs(AdDetailsCard, Object.assign({ id: "FuneralHomeDetails", header: {
                title: 'Funeral Home Details'
            } }, { children: [_jsx(DetailsRow, { label: "Funeral home name", value: (_a = data === null || data === void 0 ? void 0 : data.funeralHomeName) !== null && _a !== void 0 ? _a : '' }), _jsx(DetailsRow, { label: "Email", value: (_b = data === null || data === void 0 ? void 0 : data.funeralHomeEmail) !== null && _b !== void 0 ? _b : '' }), _jsx(DetailsRow, { label: "Phone", value: (_c = data === null || data === void 0 ? void 0 : data.funeralHomePhone) !== null && _c !== void 0 ? _c : '' })] })) })));
}
