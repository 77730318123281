var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { exists } from 'lib/types';
import { affidavitsAreManagedByColumn } from 'lib/affidavits';
import { logAndCaptureException } from 'utils';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import FullScreenModal from 'components/FullScreenModal';
import { isNoticeFilingType } from 'lib/types/filingType';
import LoadingState from 'components/LoadingState';
import { userIsSuper } from 'utils/permissions';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getFirebaseContext } from 'utils/firebase';
import { NoticeRateService } from 'lib/services/noticeRateService';
import { AdRateService } from 'lib/services/adRateService';
import { last } from 'lodash';
import { ColumnService } from 'lib/services/directory';
import { usePublisherOrgRates } from '../../rates/hooks/usePublisherOrgRates';
import IntegrationSettingsCard from './IntegrationSettingsCard';
import AffidavitSettingsCard from './AffidavitSettingsCard';
import BasicFilingTypePropertiesCard from './BasicFilingTypePropertiesCard';
import PublicationDatesCard from './PublicationDatesCard';
import NoticeFormatCard from './NoticeFormatCard';
import BillingSettingsCard from './BillingSettingsCard';
import ColumnManagedAffidavitSettingsCard from './ColumnManagedAffidavitSettingsCard';
import { usePublisherOrgTemplates } from '../../rates/hooks/usePublisherOrgTemplates';
import { MADLIB_ID_PATTERN, TYPEFORM_ID_PATTERN, getEntryMethodFromNoticeType } from '../noticeTypesHelpers';
import SuperUserOrderFilingTypeSettings from './SuperUserOrderFilingTypeSettings';
/**
 * Determines whether or not the filing type is valid based on the properties that are required
 * @param {FilingType} filingType the filing type we are editing
 * @returns {boolean} True if the filing type is valid else false
 */
export const filingTypeFormatIsValid = (filingType) => {
    const entryMethod = getEntryMethodFromNoticeType(filingType);
    // confirm patterns for typeform IDs and madlibs
    if (entryMethod === 'madlib') {
        if (!filingType.madlib)
            return false;
        if (!RegExp(MADLIB_ID_PATTERN).test(filingType.madlib)) {
            return false;
        }
    }
    else if (entryMethod === 'typeform') {
        if (!filingType.typeform)
            return false;
        if (!RegExp(TYPEFORM_ID_PATTERN).test(filingType.typeform))
            return false;
    }
    return true;
};
const filingTypeIsInvalid = (filingType) => {
    if (!filingType.label)
        return true;
    if (isNoticeFilingType(filingType)) {
        return !filingTypeFormatIsValid(filingType);
    }
    return !filingType.rate;
};
function StandardNoticeSettings({ activeOrganization, setUpdatedFilingType, updatedFilingType }) {
    const { activeTemplates, loading: orgTemplatesLoading } = usePublisherOrgTemplates(activeOrganization.ref);
    if (orgTemplatesLoading) {
        return _jsx(LoadingState, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(PublicationDatesCard, { activeOrganization: activeOrganization, onUpdateNoticeType: setUpdatedFilingType, updatedNoticeType: updatedFilingType }), _jsx(NoticeFormatCard, { activeOrganization: activeOrganization, onUpdateNoticeType: setUpdatedFilingType, updatedNoticeType: updatedFilingType, templates: activeTemplates })] }));
}
function SuperUserNoticeSettings({ activeOrganization, updatedFilingType, setUpdatedFilingType }) {
    return (_jsxs(_Fragment, { children: [_jsx(AffidavitSettingsCard, { onUpdateNoticeType: setUpdatedFilingType, updatedNoticeType: updatedFilingType }), affidavitsAreManagedByColumn(activeOrganization) && (_jsx(ColumnManagedAffidavitSettingsCard, { onUpdateNoticeType: noticeType => setUpdatedFilingType(noticeType), updatedNoticeType: updatedFilingType })), _jsx(IntegrationSettingsCard, { onUpdateNoticeType: noticeType => setUpdatedFilingType(noticeType), updatedNoticeType: updatedFilingType })] }));
}
/**
 * Full-screen form to update properties associated with a filing type
 */
function FilingTypeUpdateForm({ activeOrganization, filingType, updatedFilingType, setUpdatedFilingType, productLine }) {
    const user = useAppSelector(selectUser);
    const context = getFirebaseContext();
    const rateService = productLine === Product.Notice
        ? new NoticeRateService(context)
        : new AdRateService(context.adRatesRef(), productLine);
    const { orgRates, loading: orgRatesLoading } = usePublisherOrgRates(rateService, activeOrganization.ref, {
        includeArchived: true,
        product: productLine
    });
    if (orgRatesLoading) {
        return _jsx(LoadingState, {});
    }
    const rates = orgRates.filter(rate => {
        var _a;
        if (((_a = filingType.rate) === null || _a === void 0 ? void 0 : _a.id) === rate.id)
            return true;
        return !rate.data().archived;
    });
    return (_jsxs(_Fragment, { children: [_jsx(BasicFilingTypePropertiesCard, { onUpdateFilingType: setUpdatedFilingType, updatedFilingType: updatedFilingType, productLine: productLine }), isNoticeFilingType(updatedFilingType) && (_jsx(StandardNoticeSettings, { activeOrganization: activeOrganization, updatedFilingType: updatedFilingType, setUpdatedFilingType: noticeType => setUpdatedFilingType(noticeType) })), _jsx(BillingSettingsCard, { onUpdateNoticeType: setUpdatedFilingType, updatedNoticeType: updatedFilingType, activeOrganization: activeOrganization, rates: rates }), isNoticeFilingType(updatedFilingType) &&
                exists(user) &&
                userIsSuper(user) && (_jsx(SuperUserNoticeSettings, { activeOrganization: activeOrganization, updatedFilingType: updatedFilingType, setUpdatedFilingType: noticeType => setUpdatedFilingType(noticeType) })), !isNoticeFilingType(updatedFilingType) &&
                exists(user) &&
                userIsSuper(user) && (_jsx(SuperUserOrderFilingTypeSettings, { filingType: filingType, updatedFilingType: updatedFilingType, onUpdateFilingType: setUpdatedFilingType })), _jsx("div", { className: "h-48" })] }));
}
export default function FilingTypeUpdateModal({ activeOrganization, filingType, onClose, onSave, productLine, isNewFilingType }) {
    const [updating, setUpdating] = useState(false);
    const [updatedFilingType, setUpdatedFilingType] = useState(filingType);
    const edited = !columnObjectsAreEqual(updatedFilingType, filingType);
    // Enable saving instantly for new filing types, and after edit for existing filing types
    const disableSave = updating ||
        (!isNewFilingType && (!edited || filingTypeIsInvalid(updatedFilingType)));
    const updateFilingTypeProperties = () => __awaiter(this, void 0, void 0, function* () {
        setUpdating(true);
        try {
            onSave(updatedFilingType);
            onClose();
        }
        catch (err) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Unable to update filing type properties', {
                organizationId: activeOrganization.id
            });
        }
        setUpdating(false);
    });
    const productNameSingular = last(PRODUCT_TO_NAME[productLine].singular.split(' '));
    return (_jsx(FullScreenModal, Object.assign({ submittable: {
            buttonText: 'Save',
            disabled: !!disableSave,
            onSubmit: updateFilingTypeProperties
        }, headerText: isNewFilingType
            ? `Add ${productNameSingular} Type`
            : `Edit ${productNameSingular} Type`, onClose: onClose, id: "filing-type-update-form" }, { children: _jsx(FilingTypeUpdateForm, { activeOrganization: activeOrganization, filingType: filingType, updatedFilingType: updatedFilingType, setUpdatedFilingType: setUpdatedFilingType, productLine: productLine }) })));
}
