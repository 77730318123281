var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectAdTemplate, selectColumnCount, selectIsDisplayNoticeType } from 'redux/placement';
import { getTemplateSpecs } from 'components/noticePreview/indesign_client_utils';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
/**
 * Custom hook that retrieves the general template specifications for a notice draft
 * based on the current publisher organization and ad template.
 */
export function useGetTemplateSpecs({ publisherOrganization, draft }) {
    const [templateSpecs, setTemplateSpecs] = useState();
    const adTemplateRef = useAppSelector(selectAdTemplate);
    const adTemplate = useFirestoreSnapshot(adTemplateRef);
    const isDisplayNoticeType = useAppSelector(selectIsDisplayNoticeType);
    const loading = adTemplate === undefined || publisherOrganization === undefined;
    const skip = !isDisplayNoticeType;
    const columns = useAppSelector(selectColumnCount);
    useEffect(() => {
        if (skip) {
            setTemplateSpecs(undefined);
        }
        if (!loading && !skip) {
            void (function updateTemplateSpecs() {
                return __awaiter(this, void 0, void 0, function* () {
                    const updatedTemplateSpecs = yield getTemplateSpecs(publisherOrganization, adTemplate, draft, columns);
                    setTemplateSpecs(updatedTemplateSpecs);
                });
            })();
        }
    }, [adTemplateRef === null || adTemplateRef === void 0 ? void 0 : adTemplateRef.id, loading, skip, columns]);
    return { templateSpecs };
}
