import firebase from 'firebase/app';
import { IS_LOCAL_DEV } from '../../constants';
import { DebugCollectionRef } from './firebaseDebug';
import { getFirebaseContextRefs } from '../../types/firebase';
const getRefFactory = (app) => ({
    /**
     * Construct a reference to a collection that is SDK agnostic.
     */
    getDocRef(path) {
        const db = app.firestore();
        return db.doc(path).withConverter({
            toFirestore: (data) => data,
            fromFirestore: snap => snap.data()
        });
    },
    /**
     * Construct a reference to a collection that is SDK agnostic.
     */
    getCollectionRef(path) {
        const db = app.firestore();
        const ref = db.collection(path).withConverter({
            toFirestore: (data) => data,
            fromFirestore: snap => snap.data()
        });
        if (IS_LOCAL_DEV) {
            return new DebugCollectionRef(ref);
        }
        return ref;
    },
    getSubcollectionGroupRef(path) {
        const db = app.firestore();
        return db.collectionGroup(path).withConverter({
            toFirestore: (data) => data,
            fromFirestore: snap => snap.data()
        });
    }
});
/**
 * The main entry point into the land of EFirebase.
 */
export const getFirebaseContextForApp = (app) => (Object.assign(Object.assign({}, getFirebaseContextRefs(getRefFactory(app))), { fieldValue() {
        return {
            serverTimestamp: firebase.firestore.FieldValue.serverTimestamp,
            delete: firebase.firestore.FieldValue.delete,
            arrayUnion: firebase.firestore.FieldValue.arrayUnion,
            arrayRemove: firebase.firestore.FieldValue.arrayRemove,
            increment: firebase.firestore.FieldValue.increment
        };
    }, timestamp: (options) => {
        var _a;
        return options
            ? new firebase.firestore.Timestamp(options.seconds, (_a = options.nanoseconds) !== null && _a !== void 0 ? _a : 0)
            : firebase.firestore.Timestamp.now();
    }, timestampFromDate: (date) => {
        return firebase.firestore.Timestamp.fromDate(date);
    }, runTransaction: (updateFunction) => {
        return app.firestore().runTransaction(updateFunction);
    } }));
