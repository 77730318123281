/**
 * While this upload format says Florida, it is actually used for all of the states that use Quion
 * as their state site provider. This includes:
 * - Florida
 * - Louisiana
 */
export const UPLOAD_FLORIDA = 'UPLOAD_FLORIDA';
/**
 * While this upload format says Illinois, it is actually used for all of the states that use the
 * Illinois Press Association as their state site provider. This includes:
 * - Alabama
 * - Georgia
 * - Illinois
 * - Indiana
 * - Minnesota
 * - Mississippi
 * - Missouri
 * - Nebraska
 * - New Jersey
 * - North Carolina
 * - South Dakota
 * - South Carolina
 * - Texas
 * - Utah
 * - Virginia
 * - Washington
 * - Wyoming
 */
export const UPLOAD_ILLINOIS = 'UPLOAD_ILLINOIS';
export const UPLOAD_NEW_YORK = 'UPLOAD_NEW_YORK';
export const UPLOAD_NEWZGROUP = 'UPLOAD_NEWZGROUP';
