import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TimezoneType, State } from 'lib/enums';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { StateData } from 'lib/enums/State';
import { Form } from 'lib/components/Form';
import { GridInput, CardGridLayout } from 'lib/components/Card/Grid';
export const DEFAULT_PUBLISHER = {
    name: '',
    circulationSize: 1000,
    county: '',
    address: '',
    city: '',
    state: StateData.alabama.value,
    zipCode: '',
    phone: '',
    email: '',
    iana_timezone: TimezoneType['America/New_York'].logical
};
export default function PublisherDetailsForm({ onUpdatePublisherData, onCreatePublisher, publisherData, onGoBack, loading }) {
    var _a, _b, _c;
    return (_jsx(Form, Object.assign({ onSubmit: onCreatePublisher }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'Publisher Details',
                description: 'Please enter the details of your publication. This form is available to Column users only.'
            } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { value: publisherData.name, onChange: newName => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { name: newName })), labelText: "Name of publisher", required: true, id: "publisher-name" }) })), _jsx(GridInput, { children: _jsx(TextField, { value: (_a = publisherData.circulationSize) === null || _a === void 0 ? void 0 : _a.toString(), onChange: newCirculationSize => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { circulationSize: parseInt(newCirculationSize, 10) })), labelText: "Circulation Size", min: 0, type: "number", required: true, id: "circulation-size" }) }), _jsx(GridInput, { children: _jsx(TextField, { value: publisherData.county, onChange: county => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { county })), labelText: "County", required: true, id: "county" }) }), _jsx(GridInput, { children: _jsx(TextField, { value: publisherData.address, onChange: newAddress => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { address: newAddress })), labelText: "Address", required: true, id: "address" }) }), _jsx(GridInput, { children: _jsx(TextField, { value: publisherData.city, onChange: newCity => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { city: newCity })), labelText: "City", required: true, id: "city" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "State", value: (_b = publisherData.state) === null || _b === void 0 ? void 0 : _b.toString(), onChange: newState => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { state: parseInt(newState, 10) })), options: State.items().map(state => ({
                            value: state.value.toString(),
                            label: state.label
                        })), id: "state-select" }) }), _jsx(GridInput, { children: _jsx(TextField, { value: publisherData.zipCode, onChange: newZipCode => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { zipCode: newZipCode })), labelText: "Zip Code", required: true, id: "zip-code", type: "postal-code" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Timezone", value: (_c = publisherData.iana_timezone) === null || _c === void 0 ? void 0 : _c.toString(), onChange: newTimezone => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { iana_timezone: newTimezone })), options: TimezoneType.items().map(tz => ({
                            value: tz.logical,
                            label: tz.label
                        })), id: "timezone-select" }) }), _jsx(GridInput, { children: _jsx(TextField, { value: publisherData.phone, onChange: newPhone => onUpdatePublisherData(Object.assign(Object.assign({}, publisherData), { phone: newPhone })), labelText: "Phone", required: true, type: "tel", id: "phone" }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsx(ColumnButton, { tertiary: true, onClick: onGoBack, buttonText: "Back", size: "lg", type: "button" }), _jsx(ColumnButton, { primary: true, buttonText: "Create Publisher", size: "lg", id: "submit", loading: loading, type: "submit" })] })) }))] })) })));
}
