import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useState } from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { RunBasedRateInput } from './RunBasedRateInput';
import { MAX_ADDITIONAL_RATES } from '../rateUpdateFormUtils';
const DEFAULT_NUMBER_OF_RUN_RATES = 3;
const getInitialNumberOfRates = ({ rate_1, rate_2, additionalRates }) => {
    if (additionalRates) {
        return Object.keys(additionalRates).length + DEFAULT_NUMBER_OF_RUN_RATES;
    }
    if (rate_1 !== rate_2) {
        return 3;
    }
    return 2;
};
const getConsolidatedRunRates = ({ rate_0, rate_1, rate_2, additionalRates }) => {
    return [
        ['rate_0', rate_0],
        ['rate_1', rate_1],
        ['rate_2', rate_2],
        ...(additionalRates
            ? Object.entries(additionalRates).sort((a, b) => a[0].localeCompare(b[0]))
            : [])
    ];
};
export function RunBasedRates({ rateType, value, onChange }) {
    const ratesPerRun = getConsolidatedRunRates(value);
    const [totalRatedRuns, setTotalRatedRuns] = useState(getInitialNumberOfRates(value));
    const showAddRatesButton = totalRatedRuns < MAX_ADDITIONAL_RATES + DEFAULT_NUMBER_OF_RUN_RATES;
    return (_jsxs(_Fragment, { children: [ratesPerRun.map(([key, runValue], idx) => {
                const runNumber = idx + 1;
                if (runNumber > totalRatedRuns) {
                    return _jsx(_Fragment, {});
                }
                const onDelete = () => {
                    setTotalRatedRuns(runNumber - 1);
                    if (runNumber === 3) {
                        onChange(Object.assign(Object.assign({}, value), { rate_2: value.rate_1 }));
                        return;
                    }
                    if (runNumber === 4) {
                        onChange(Object.assign(Object.assign({}, value), { additionalRates: null }));
                        return;
                    }
                    const additionalRates = Object.assign({}, value.additionalRates);
                    delete additionalRates[key];
                    onChange(Object.assign(Object.assign({}, value), { additionalRates }));
                };
                const deletable = 
                /** While a rate is `runBased`, it should have at least two run rates */
                runNumber > 2 &&
                    /** Only the last rate should be removeable */
                    runNumber === totalRatedRuns
                    ? { onDelete }
                    : undefined;
                return (_jsx(RunBasedRateInput, { runNumber: runNumber, rateType: rateType, value: runValue, onChange: newValue => {
                        if (idx === 1 && totalRatedRuns === 2) {
                            onChange(Object.assign(Object.assign({}, value), { rate_1: newValue, rate_2: newValue }));
                            return;
                        }
                        if (idx <= 2) {
                            onChange(Object.assign(Object.assign({}, value), { [key]: newValue }));
                            return;
                        }
                        onChange(Object.assign(Object.assign({}, value), { additionalRates: Object.assign(Object.assign({}, value.additionalRates), { [key]: newValue }) }));
                    }, deletable: deletable, adType: value.product }, key));
            }), showAddRatesButton && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnButton, { startIcon: _jsx(PlusCircleIcon, { className: "w-6 h-6" }), buttonText: "Add another run rate", secondary: true, type: "button", link: true, onClick: () => {
                        setTotalRatedRuns(totalRatedRuns + 1);
                        /**
                         * `rate_2` is always defined on the rate, and equal to `rate_1` when
                         * `totalRatedRuns === 1`, so increasing `totalRatedRuns` is sufficient to add an
                         * input with the expected value to the form.
                         */
                        if (totalRatedRuns === 2) {
                            return;
                        }
                        /**
                         * There are always at least 2 run rates in the form, so if `totalRatedRuns !== 2`,
                         * then we need to add a key to the `additionalRates` property. These keys are zero-
                         * indexed, so we can use the current total to make the key (e.g., if we have 4 rates
                         * and want to add a 5th, then the new key should be `rate_4`).
                         */
                        const newKey = `rate_${totalRatedRuns}`;
                        /**
                         * We use the latest run rate's value when setting up the new run rate, using the
                         * combined and sorted `ratesPerRun` array.
                         */
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const [_key, newValue] = ratesPerRun[totalRatedRuns - 1];
                        onChange(Object.assign(Object.assign({}, value), { additionalRates: Object.assign(Object.assign({}, value.additionalRates), { [newKey]: newValue }) }));
                    }, id: "add-more-rates" }) })))] }));
}
