import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { isNoticeFilingType } from 'lib/types/filingType';
import TabGroup from 'lib/components/Tabs';
import BasicPropertiesTab from './BasicPropertiesTab';
import BillingTab from './BillingTab';
import AffidavitTab from './AffidavitTab';
const BASIC_PROPERTIES_TAB = 'Basic Properties';
const BILLING_TAB = 'Billing';
const AFFIDAVITS_TAB = 'Affidavits';
const TABS = [
    {
        label: BASIC_PROPERTIES_TAB,
        enabled: true,
        id: 'basic-properties-tab'
    },
    {
        label: BILLING_TAB,
        enabled: true,
        id: 'billing-tab'
    },
    {
        label: AFFIDAVITS_TAB,
        enabled: true,
        id: 'affidavits-tab'
    }
];
export default function FilingTypeDrawerBody({ filingType }) {
    const [filingTypeDrawerTab, setFilingTypeDrawerTab] = useState(TABS[0]);
    return (_jsxs(_Fragment, { children: [_jsx(TabGroup, { id: "filing-type-drawer-tabs", tabs: TABS, activeTab: filingTypeDrawerTab, onClickTab: setFilingTypeDrawerTab }), _jsxs("div", Object.assign({ className: "pl-2 overflow-scroll flex-1 pb-20" }, { children: [filingTypeDrawerTab.label === BASIC_PROPERTIES_TAB && (_jsx(BasicPropertiesTab, { filingType: filingType })), isNoticeFilingType(filingType) && (_jsxs(_Fragment, { children: [filingTypeDrawerTab.label === BILLING_TAB && (_jsx(BillingTab, { noticeType: filingType })), filingTypeDrawerTab.label === AFFIDAVITS_TAB && (_jsx(AffidavitTab, { noticeType: filingType }))] }))] }))] }));
}
