var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { safeAsync, safeGetOrThrow } from 'lib/safeWrappers';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { columnObjectsAreEqual, safeStringify } from 'lib/utils/stringify';
import { useEffect, useState } from 'react';
import { fetchMadlibsConfig } from 'routes/madlib/helpers/config';
const DEFAULT_MADLIB_DATA = {
    templateData: {},
    questionTemplateData: {}
};
export const useMadlibs = ({ filingType, adData, onChange }) => {
    var _a;
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    const [renderedHtml, setRenderedHtml] = useState(adData.content || '');
    const [madlibData, setMadlibData] = useState((_a = adData.madlibData) !== null && _a !== void 0 ? _a : DEFAULT_MADLIB_DATA);
    const [validationResults, setValidationResults] = useState({});
    const [madlibIsComplete, setMadlibIsComplete] = useState(false);
    const { value: madlibProps, isError: madlibPropsError, isLoading: madlibPropsLoading } = useSafeAsyncEffect({
        fetchData: () => __awaiter(void 0, void 0, void 0, function* () {
            const { response: filingTypeSnap, error: filiingTypeError } = yield safeGetOrThrow(filingType);
            if (filiingTypeError) {
                return wrapError(filiingTypeError);
            }
            const madlibConfigPath = filingTypeSnap.data().madlib;
            if (!madlibConfigPath) {
                return wrapSuccess(null);
            }
            const { response: templateAndQuestions, error: templateAndQuestionsError } = yield safeAsync(() => fetchMadlibsConfig(madlibConfigPath))();
            if (templateAndQuestionsError) {
                return wrapError(templateAndQuestionsError);
            }
            const { template: madlibTemplate, questions } = templateAndQuestions;
            const configFileMissing = !!madlibConfigPath && !(madlibTemplate && questions);
            if (configFileMissing) {
                return wrapError(new Error('Madlib template or questions missing for order filing type'));
            }
            return wrapSuccess({
                madlibConfigPath,
                madlibTemplate,
                questions
            });
        }),
        dependencies: [filingType === null || filingType === void 0 ? void 0 : filingType.id]
    });
    /**
     * TODO(web): I think eventually we should be able to remove this useEffect, but that will
     * likely require some refactoring of the MadlibEditor, which I don't want to do while that component
     * is shared by the notice & order placemnt flows. When we migrate notices over to the orders schema, we
     * can consolidate simplify the code in this hook and that component.
     */
    useEffect(() => {
        /**
         * These checks prevent us from setting blank madlib data on non-madlib obits & classifieds unnecessarily
         * (this has no known adverse side effects, but could get confusing to someone looking at the data)
         */
        const madlibDataIsEmptyAndUnedited = columnObjectsAreEqual(madlibData, DEFAULT_MADLIB_DATA) &&
            !adData.madlibData;
        const contentIsEmptyAndUnedited = !renderedHtml && !adData.content;
        onChange(Object.assign(Object.assign({}, (contentIsEmptyAndUnedited ? {} : { content: renderedHtml })), (madlibDataIsEmptyAndUnedited ? {} : { madlibData })));
    }, [safeStringify(madlibData), renderedHtml]);
    useEffect(() => {
        if (madlibData.templateData &&
            Object.keys(madlibData.templateData).length) {
            const noInvalidData = Object.entries(madlibData.templateData).every(([key, input]) => !!input && validationResults[key] !== false);
            setMadlibIsComplete(noInvalidData && allQuestionsAnswered);
        }
    }, [safeStringify(madlibData), safeStringify(validationResults)]);
    return {
        madlibProps,
        madlibPropsError,
        madlibPropsLoading,
        allQuestionsAnswered,
        madlibData,
        madlibIsComplete,
        setAllQuestionsAnswered,
        setValidationResults,
        setRenderedHtml,
        setMadlibData
    };
};
