import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { PartyingFace } from 'emojis';
import { CheckCircleIcon, PencilSquareIcon, DocumentTextIcon, ScaleIcon } from '@heroicons/react/24/outline';
import { ModalHeader } from './ModalHeader';
export function NoticePlacedAnonymouslyModal({ onClosed, user }) {
    return (_jsxs(CancelOrSubmitModal, Object.assign({ primaryButtonText: 'Next', noExitOutsideModal: true, onClose: onClosed, disableOverflow: true, onSubmit: () => {
            onClosed();
        }, overrideFooterButtonsAlignment: 'end' }, { children: [_jsx(ModalHeader, { id: "notice-placed-header", headerText: _jsxs(_Fragment, { children: ["Notice submitted ", _jsx(PartyingFace, {})] }) }), _jsxs("div", Object.assign({ className: "mb-8 mx-4", id: "notice-placed-modal-body" }, { children: [_jsxs("p", Object.assign({ className: "text-column-gray-400 text-sm font-medium text-center px-10" }, { children: ["Your notice has been submitted to the publisher for review. We\u2019ll send the following updates to", ' ', _jsxs("span", Object.assign({ className: "font-semibold text-column-gray-500" }, { children: [user.data().email, "."] }))] })), _jsxs("div", Object.assign({ className: "pt-6 pb-0 text-column-gray-400" }, { children: [_jsx(StepsInfoBlock, { icon: _jsx(CheckCircleIcon, { className: "h-5 w-5" }), title: "Notice Confirmation", description: "The publisher will confirm that they\u2019ve received your notice." }), _jsx(StepsInfoBlock, { icon: _jsx(PencilSquareIcon, { className: "h-8 w-8" }), title: "Notice Edits", description: "The publisher may edit your notice content or publication dates to meet formatting or legal requirements." }), _jsx(StepsInfoBlock, { icon: _jsx(DocumentTextIcon, { className: "h-5 w-5" }), title: "Invoice Creation", description: "Pay by card or ACH once the publisher creates your invoice." }), _jsx(StepsInfoBlock, { icon: _jsx(ScaleIcon, { className: "h-10 w-10" }), title: "Affidavit", description: "Once final publication is completed, your affidavit will be emailed to you, and hard copies will be mailed to any requested addresses." })] }))] }))] })));
}
function StepsInfoBlock({ icon, title, description }) {
    return (_jsxs("div", Object.assign({ className: "flex items-center border border-column-gray-100 rounded-md px-4 py-3 mb-4" }, { children: [icon, _jsxs("div", Object.assign({ className: "pl-6 text-left" }, { children: [_jsx("p", Object.assign({ className: "text-sm font-medium text-column-gray-600" }, { children: title })), _jsx("p", Object.assign({ className: "text-xs font-medium text-column-gray-400" }, { children: description }))] }))] })));
}
