import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'lib/components/Tooltip';
function SyncErrorCardHeader({ header, open, onClick, numberOfEvents }) {
    const mainIconClass = classNames('h-5 w-5 text-column-gray-400 p-1 border border-column-gray-200 rounded-full hover:bg-column-gray-100 cursor-pointer');
    const disabled = numberOfEvents === 0;
    return (_jsx(Tooltip, Object.assign({ id: "no-error-tooltip", position: "right", helpText: numberOfEvents === 0 ? `No ${header === null || header === void 0 ? void 0 : header.toString().toLowerCase()}` : '' }, { children: _jsx("button", Object.assign({ className: "w-full focus:outline-none", onClick: () => onClick(!open), disabled: disabled }, { children: _jsx("div", { children: _jsxs("div", Object.assign({ className: "w-full flex text-column-gray-500 font-medium text-sm items-center justify-between pl-8 pr-2.5 py-3.5 border-column-gray-100 border-b" }, { children: [header, _jsxs("div", Object.assign({ className: "text-column-gray-400 flex gap-2 items-center justify-between" }, { children: [`${numberOfEvents} ${numberOfEvents === 1 ? 'Notice' : 'Notices'}`, !open && _jsx(ChevronDownIcon, { className: mainIconClass }), open && _jsx(ChevronUpIcon, { className: mainIconClass })] }))] })) }) })) })));
}
export default SyncErrorCardHeader;
