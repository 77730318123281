import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import SettingsUpdateContainer from 'routes/settings/publisher/SettingsUpdateContainer';
import { CardGridLayout } from 'lib/components/Card/Grid';
import { KeyInput } from './KeyInput';
import { ValueInput } from './ValueInput';
import { getInputType } from './inputs';
const valueToText = (value, dataType) => {
    if (dataType === 'number-array' || dataType === 'string-array') {
        return JSON.stringify(value || ['']);
    }
    return value.toString();
};
const textToKey = (text, dataType) => {
    if (getInputType(dataType) === 'number') {
        return parseFloat(text);
    }
    if (getInputType(dataType) === 'text') {
        return text;
    }
    throw new Error(`Unknown data type: ${dataType}`);
};
const textToValue = (text, dataType) => {
    if (dataType === 'boolean') {
        return text === 'true';
    }
    if (dataType === 'number-array' || dataType === 'string-array') {
        return JSON.parse(text);
    }
    if (getInputType(dataType) === 'number') {
        return parseFloat(text);
    }
    if (getInputType(dataType) === 'text') {
        return text;
    }
    throw new Error(`Unknown data type: ${dataType}`);
};
/**
 * Full-screen form to update a cache entry.
 */
export function CacheEntryUpdateForm({ cache, entry, keyType, valueType, closeForm }) {
    const [keyText, setKeyText] = useState(entry ? `${entry.data().key.value}` : '');
    const [valueText, setValueText] = useState(valueToText(entry ? entry.data().value.value : '', valueType));
    const title = entry ? 'Edit Entry' : 'Create Entry';
    const description = entry
        ? 'Update entry key or value'
        : 'Enter a key and value to create a new entry';
    const saveEnabled = !!keyText && (valueType === 'boolean' || !!valueText);
    return (_jsx(SettingsUpdateContainer, Object.assign({ title: title, saveDisabled: !saveEnabled, onSaveClicked: () => {
            const keyData = textToKey(keyText, keyType);
            const valueData = textToValue(valueText, valueType);
            if (entry) {
                void cache.updateValue(keyData, valueData);
            }
            else {
                void cache.addValue(keyData, valueData);
            }
            closeForm();
        }, onCloseClicked: closeForm }, { children: _jsxs(CardGridLayout, Object.assign({ header: { title, description } }, { children: [_jsx(KeyInput, { value: keyText, onChange: setKeyText, entry: entry, keyType: keyType, valueType: valueType }), _jsx(ValueInput, { value: valueText, onChange: setValueText, valueType: valueType })] })) })));
}
