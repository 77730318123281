import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import moment from 'moment';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import MultiStepHeader from '../../components/MultiStepHeader';
import ThreeFieldDateSelect from '../../components/ThreeFieldDateSelect';
import FuneralHomeInformationForm from './FuneralHomeInformationForm';
const DEFAULT_DATE = 'NaN-NaN-NaN';
const translateDateStringToThreeFieldDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    return { year, month, day };
};
const translateThreeDateFieldToDateString = (date) => {
    const year = isNaN(date.year)
        ? 'NaN'
        : moment({ year: date.year }).format('YYYY');
    const month = isNaN(date.month)
        ? 'NaN'
        : // Months are 0-indexed so we need to compensate
            moment({ month: date.month - 1 }).format('MM');
    const day = isNaN(date.day) ? 'NaN' : moment({ day: date.day }).format('DD');
    return `${year}-${month}-${day}`;
};
function ProvideVerification({ chosenFlow, inputData, setInputData }) {
    var _a, _b, _c;
    const handleBirthDateChange = (date) => {
        const dateString = translateThreeDateFieldToDateString(date);
        setInputData(Object.assign(Object.assign({}, inputData), { deceasedBirthDate: dateString }));
    };
    const handleDeathDateChange = (date) => {
        const dateString = translateThreeDateFieldToDateString(date);
        setInputData(Object.assign(Object.assign({}, inputData), { deceasedDeathDate: dateString }));
    };
    const getValidationMessagesForDates = () => {
        const birthDateMoment = moment(inputData.deceasedBirthDate, 'YYYY-MM-DD');
        const birthDateIsValid = inputData.deceasedBirthDate &&
            birthDateMoment.isValid() &&
            !inputData.deceasedBirthDate.includes('NaN');
        const birthDateIsInFuture = birthDateIsValid && birthDateMoment.isAfter(moment(), 'day');
        const deathDateMoment = moment(inputData.deceasedDeathDate, 'YYYY-MM-DD');
        const deathDateIsValid = inputData.deceasedDeathDate &&
            deathDateMoment.isValid() &&
            !inputData.deceasedDeathDate.includes('NaN');
        const deathDateIsInFuture = deathDateIsValid && deathDateMoment.isAfter(moment(), 'day');
        const birthDateIsAfterDeathDate = birthDateIsValid &&
            deathDateIsValid &&
            birthDateMoment.isAfter(deathDateMoment, 'day');
        /**
         * If either date is not valid, the native validation messages will populate, so we don't need to return anything here
         */
        const birthDateMessage = !birthDateIsValid
            ? null
            : birthDateIsInFuture
                ? 'Date of birth cannot be in the future'
                : birthDateIsAfterDeathDate
                    ? 'Date of birth cannot be after date of death'
                    : null;
        const deathDateMessage = !deathDateIsValid
            ? null
            : deathDateIsInFuture
                ? 'Date of death cannot be in the future'
                : birthDateIsAfterDeathDate
                    ? 'Date of death cannot be before date of birth'
                    : null;
        return { birthDateMessage, deathDateMessage };
    };
    const deceasedBirthDate = (_a = inputData === null || inputData === void 0 ? void 0 : inputData.deceasedBirthDate) !== null && _a !== void 0 ? _a : DEFAULT_DATE;
    const translatedBirthDate = translateDateStringToThreeFieldDate(deceasedBirthDate);
    const deceasedDeathDate = (_b = inputData === null || inputData === void 0 ? void 0 : inputData.deceasedDeathDate) !== null && _b !== void 0 ? _b : DEFAULT_DATE;
    const translatedDeathDate = translateDateStringToThreeFieldDate(deceasedDeathDate);
    const title = 'Deceased Details';
    const description = chosenFlow === CustomerTypeString.FUNERAL_HOME
        ? undefined
        : 'To publish your listing, we need to verify the date of death with the funeral home, crematory services, or bequest program.';
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepHeader, { title: title, description: description }), _jsx("div", Object.assign({ className: "mb-8" }, { children: _jsx(TextField, { id: "deceased-name", value: (_c = inputData === null || inputData === void 0 ? void 0 : inputData.deceasedName) !== null && _c !== void 0 ? _c : '', type: "text", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { deceasedName: value })), labelText: "What is the name of the deceased?", required: true }) })), _jsx("div", Object.assign({ className: "mb-8" }, { children: _jsxs("fieldset", Object.assign({ id: "deceased-death-date" }, { children: [_jsx("legend", { children: "What is the date of death of the deceased?" }), _jsx("div", Object.assign({ className: "grid md:grid-cols-3 gap-3" }, { children: _jsx(ThreeFieldDateSelect, { id: "deceased-death-date-select", onChange: handleDeathDateChange, value: translatedDeathDate, required: true, checkAggregateDateValidation: () => getValidationMessagesForDates().deathDateMessage, reverseSortYears: false }) }))] })) })), _jsx("div", Object.assign({ className: "mb-8" }, { children: _jsxs("fieldset", Object.assign({ id: "deceased-birth-date" }, { children: [_jsx("legend", { children: "What is the date of birth of the deceased?" }), _jsx("div", Object.assign({ className: "grid md:grid-cols-3 gap-3" }, { children: _jsx(ThreeFieldDateSelect, { id: "deceased-birth-date-select", onChange: handleBirthDateChange, value: translatedBirthDate, required: true, checkAggregateDateValidation: () => getValidationMessagesForDates().birthDateMessage, reverseSortYears: true }) }))] })) })), chosenFlow !== CustomerTypeString.FUNERAL_HOME && (_jsx(FuneralHomeInformationForm, { inputData: inputData, setInputData: setInputData }))] }));
}
export default ProvideVerification;
