import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { InvoiceStatus } from 'lib/enums';
export function LatePaymentWarning({ requireUpfrontPayment, preventLatePayment, invoiceStatus }) {
    const showMissedUpfrontPaymentNotification = preventLatePayment &&
        invoiceStatus &&
        ![
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(invoiceStatus);
    const showRequiredUpfrontPaymentWarning = requireUpfrontPayment &&
        !preventLatePayment &&
        invoiceStatus &&
        ![
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value
        ].includes(invoiceStatus);
    return (_jsxs(_Fragment, { children: [showMissedUpfrontPaymentNotification && (_jsx("div", Object.assign({ className: "relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800" }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", { className: "w-2 h-16 bg-blue-500 mr-4" }), _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("b", Object.assign({ className: "mr-1" }, { children: "Deadline has passed." })), _jsx("div", { children: "Upfront payment was required for this notice. Please contact the newspaper or Column Support to reschedule your notice for a future date." })] }))] })) }))), showRequiredUpfrontPaymentWarning && (_jsx("div", Object.assign({ className: "relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800" }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", { className: "w-2 h-16 bg-blue-500 mr-4" }), _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("b", Object.assign({ className: "mr-1" }, { children: "Upfront payment required." })), _jsx("div", { children: "If you don\u2019t pay your invoice on time, your notice might not be published." })] }))] })) })))] }));
}
