import { floatToP2Float } from '../ui';
export const priceNebraska = options => {
    var _a;
    const { runNumber, displayParameters, columns, rateRecord } = options;
    const lines = (_a = displayParameters.lines) !== null && _a !== void 0 ? _a : 0;
    if (runNumber === 0) {
        return floatToP2Float(lines * columns * rateRecord.rate_0);
    }
    if (runNumber === 1) {
        return floatToP2Float(lines * columns * rateRecord.rate_1);
    }
    return floatToP2Float(lines * columns * rateRecord.rate_2);
};
