import moment from 'moment';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { firestoreTimestampOrDateToDate } from '../../helpers';
export class NoteModel extends SnapshotModel {
    get type() {
        return Collections.notes;
    }
    archive() {
        return this.update({ status: 'archived' });
    }
    restore() {
        return this.update({ status: 'public' });
    }
    isCreatedByUser(userId) {
        if (!userId) {
            return false;
        }
        return this.modelData.noteCreator.id === userId;
    }
    get createdAtLabelFromNow() {
        return moment(firestoreTimestampOrDateToDate(this.modelData.createdAt)).fromNow();
    }
    get hasAttachments() {
        return this.modelData.attachments.length > 0;
    }
}
