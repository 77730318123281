var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ConfirmationStatus, NoticeType } from '../enums';
import { getOrThrowTransaction } from '../utils/refs';
import { getCustomerForNotice } from './customer';
import { removeUndefinedFields } from '../helpers';
function getShouldAutoGenerateOrderNumsForNotice(newspaper, parent, notice) {
    var _a;
    const noticeAlreadyHasOrderNumber = !!notice.data().customId;
    if (noticeAlreadyHasOrderNumber) {
        return false;
    }
    const { shouldAutoGenOrderNums: childShouldAutoGenOrderNums } = newspaper.data();
    const { shouldAutoGenOrderNums: parentShouldAutoGenOrderNums } = (parent === null || parent === void 0 ? void 0 : parent.data()) || {};
    const shouldAutoGenOrderNums = (_a = childShouldAutoGenOrderNums !== null && childShouldAutoGenOrderNums !== void 0 ? childShouldAutoGenOrderNums : parentShouldAutoGenOrderNums) !== null && _a !== void 0 ? _a : false;
    if (!shouldAutoGenOrderNums) {
        return false;
    }
    if (typeof shouldAutoGenOrderNums === 'boolean') {
        return shouldAutoGenOrderNums;
    }
    const isDisplay = notice.data().noticeType === NoticeType.display_ad.value;
    if (isDisplay) {
        return shouldAutoGenOrderNums.display;
    }
    return shouldAutoGenOrderNums.liners;
}
export function getNoticeNumberAndCustomIdFromNewspaper(newspaper) {
    var _a, _b, _c, _d, _e, _f;
    const currentNumber = newspaper.data().numberOfOrders || 1;
    const numberOfDigits = ((_b = (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.orderNumberGeneration) === null || _b === void 0 ? void 0 : _b.orderNumDigitCount) || 4;
    const numOfZeros = numberOfDigits - `${currentNumber}`.length > 0
        ? numberOfDigits - `${currentNumber}`.length
        : 0;
    const prefix = ((_d = (_c = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _c === void 0 ? void 0 : _c.orderNumberGeneration) === null || _d === void 0 ? void 0 : _d.orderNumPrefix) || '';
    const suffix = ((_f = (_e = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _e === void 0 ? void 0 : _e.orderNumberGeneration) === null || _f === void 0 ? void 0 : _f.orderNumSuffix) || '';
    const customId = `${prefix}${'0'.repeat(numOfZeros)}${currentNumber}${suffix}`;
    return {
        currentNumber,
        customId
    };
}
function generateCustomIdForNotice(transaction, notice) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const publisherOrganization = yield getOrThrowTransaction(transaction, notice.data().newspaper);
        const parentOrganizationRef = publisherOrganization.data().parent;
        const parentOrganization = parentOrganizationRef
            ? yield getOrThrowTransaction(transaction, parentOrganizationRef)
            : undefined;
        const shouldAutoGenOrderNums = getShouldAutoGenerateOrderNumsForNotice(publisherOrganization, parentOrganization, notice);
        const orgToUpdate = ((_a = publisherOrganization.data()) === null || _a === void 0 ? void 0 : _a.shouldAutoGenOrderNums)
            ? publisherOrganization
            : parentOrganization;
        let generatedCustomId;
        if (shouldAutoGenOrderNums && orgToUpdate) {
            const { customId, currentNumber } = getNoticeNumberAndCustomIdFromNewspaper(orgToUpdate);
            generatedCustomId = customId;
            transaction.update(orgToUpdate.ref, {
                numberOfOrders: currentNumber + 1
            });
        }
        return generatedCustomId;
    });
}
function updateNoticeWithConfirmation(ctx, { notice, user }) {
    return __awaiter(this, void 0, void 0, function* () {
        yield ctx.runTransaction((transaction) => __awaiter(this, void 0, void 0, function* () {
            const updates = {
                confirmationStatus: ConfirmationStatus.Confirmed,
                confirmedReceipt: true,
                confirmedBy: user.ref,
                confirmedReceiptTime: ctx.fieldValue().serverTimestamp(),
                customId: yield generateCustomIdForNotice(transaction, notice)
            };
            transaction.update(notice.ref, removeUndefinedFields(updates));
        }));
    });
}
/** Instead of calling this directly, consider using the `confirm` method of `UserNoticeModel`. */
export function confirmNotice(ctx, { notice, user }) {
    return __awaiter(this, void 0, void 0, function* () {
        yield updateNoticeWithConfirmation(ctx, { notice, user });
        const customer = yield getCustomerForNotice(ctx, notice);
        if (customer && !customer.data().verified) {
            yield customer.ref.update({
                verified: true
            });
        }
    });
}
export const __private = {
    getShouldAutoGenerateOrderNumsForNotice
};
