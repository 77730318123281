import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { CourtHouse as CourthousesList } from 'lib/enums';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import SelectDropdown from '../SelectDropdown';
import { actions as affidavitRecipientActions, MAX_MAIL_COPIES } from './affidavitRecipient.slice';
const { deleteCourthouse, updateCourthouseRecipientCopies, selectCourthouseRecipient, addCourthouseRecipient } = affidavitRecipientActions;
export function CourthouseRecipientManager({ recipients, dispatch, publisherOrganizationStateId, errors, showErrors }) {
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const courthouseOptions = CourthousesList.map(courthouse => ({
        value: String(courthouse.id),
        label: courthouse.name
    })).sort((a, b) => a.label.localeCompare(b.label));
    return (_jsxs("div", Object.assign({ className: classNames('my-2', {
            'pl-8': !enableNewPlacementFlow
        }) }, { children: [recipients.map((a, index) => {
                var _a;
                const selectedCourthouse = ((_a = a.address) === null || _a === void 0 ? void 0 : _a.address_state)
                    ? {
                        id: a.courtHouse,
                        data: a,
                        label: a.name
                    }
                    : '';
                return (_jsx("div", Object.assign({ className: classNames({
                        'my-3': !enableNewPlacementFlow,
                        'bg-gray-100 border rounded-md p-6 my-5': enableNewPlacementFlow
                    }) }, { children: _jsxs("fieldset", Object.assign({ className: "grid grid-cols-12 gap-5" }, { children: [enableNewPlacementFlow && (_jsxs("div", Object.assign({ className: "col-span-12 flex justify-between" }, { children: [_jsx("legend", Object.assign({ className: "text-sm text-grey-400 font-medium uppercase" }, { children: "Courthouse details" })), index > 0 && (_jsx(ColumnButton, { destructive: true, link: true, "aria-label": "Delete courthouse", startIcon: _jsx(TrashIcon, { className: "w-5 h-5" }), onClick: () => {
                                            dispatch(deleteCourthouse(index));
                                        }, type: "button" }))] }))), _jsxs("div", Object.assign({ className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-8' }, { children: [enableNewPlacementFlow && (_jsx(ColumnSelect, { id: `courtHouses${index}`, labelText: "Which courthouse?", options: courthouseOptions, onChange: courthouseId => {
                                            const selectedCourthouse = CourthousesList.find(courthouse => courthouse.id === Number(courthouseId));
                                            if (selectedCourthouse) {
                                                dispatch(selectCourthouseRecipient({
                                                    index,
                                                    selectedCourthouse,
                                                    addressState: publisherOrganizationStateId
                                                }));
                                            }
                                        }, value: String(a.courtHouse), allowUndefined: true })), !enableNewPlacementFlow && (_jsx(SelectDropdown, { id: `courtHouses${index}`, className: "courthouse-select", placeholder: "Select Courthouse", onChange: (selected) => {
                                            if (selected) {
                                                dispatch(selectCourthouseRecipient({
                                                    index,
                                                    selectedCourthouse: selected.data,
                                                    addressState: publisherOrganizationStateId
                                                }));
                                            }
                                        }, selected: selectedCourthouse, value: selectedCourthouse, options: CourthousesList.map(courthouse => ({
                                            id: courthouse.id,
                                            data: courthouse,
                                            label: courthouse.name
                                        })), placeholderText: '#6B7280' }))] })), _jsx("div", Object.assign({ className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-3' }, { children: _jsx(TextField, { id: `courthouse-copies${index}`, type: "number", labelText: enableNewPlacementFlow
                                        ? 'How many copies should be sent?'
                                        : '', placeholder: "Copies", value: String(a.copies), size: enableNewPlacementFlow ? 'standard' : 'small', min: 1, max: MAX_MAIL_COPIES, onChange: newValue => {
                                        dispatch(updateCourthouseRecipientCopies({
                                            newValue: Number(newValue),
                                            index
                                        }));
                                    } }) })), index > 0 && !enableNewPlacementFlow && (_jsx("div", Object.assign({ className: "col-span-1 flex items-center" }, { children: _jsx(ColumnButton, { destructive: true, link: true, "aria-label": "Delete courthouse", startIcon: _jsx(TrashIcon, { className: "w-5 h-5" }), onClick: () => {
                                        dispatch(deleteCourthouse(index));
                                    }, type: "button" }) }))), showErrors && errors[index] && (_jsx("div", Object.assign({ className: "col-span-12 text-red-600 text-sm font-normal" }, { children: errors[index] })))] })) }), `${index}-courthouse`));
            }), _jsx(ColumnButton, { secondary: true, link: true, id: "addMoreCourthouses", startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5" }), onClick: () => dispatch(addCourthouseRecipient()), buttonText: "Add more courthouses", type: "button" })] })));
}
