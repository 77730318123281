import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getPublisherOrgTemplatesQueries } from 'lib/utils/templates';
import { getFirebaseContext } from 'utils/firebase';
export function usePublisherOrgTemplates(org) {
    var _a, _b;
    const { ownedTemplatesQuery, allowedTemplatesQuery } = getPublisherOrgTemplatesQueries(getFirebaseContext(), org);
    const ownedTemplates = useFirestoreQueryListener(ownedTemplatesQuery, [org.id], {
        message: `Error listening to owned templates for ${org.id}`
    });
    const allowedTemplates = useFirestoreQueryListener(allowedTemplatesQuery, [org.id], {
        message: `Error listening to allowed templates for ${org.id}`
    });
    const loading = allowedTemplates === null || ownedTemplates === null;
    const activeTemplates = [
        ...((_a = ownedTemplates === null || ownedTemplates === void 0 ? void 0 : ownedTemplates.docs) !== null && _a !== void 0 ? _a : []),
        ...((_b = allowedTemplates === null || allowedTemplates === void 0 ? void 0 : allowedTemplates.docs) !== null && _b !== void 0 ? _b : [])
    ].filter(template => !template.data().archived);
    return { activeTemplates, loading };
}
