import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
const styles = () => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
    }
});
function ErrorPage({ code, classes }) {
    if (code === '204') {
        return (_jsx("div", Object.assign({ className: classes.root }, { children: _jsx("div", { children: " This page has not yet been implemented!" }) })));
    }
    return _jsx(_Fragment, {});
}
const mapStateToProps = (state) => {
    var _a, _b;
    return ({
        code: (_b = (_a = matchPath(state.router.location.pathname, {
            path: '/error/:code',
            exact: true,
            strict: false
        })) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.code
    });
};
export default connect(mapStateToProps)(withStyles(styles)(ErrorPage));
