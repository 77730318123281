import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
function ChoiceButton({ id, onClick, checked, option, children, disabled }) {
    return (_jsx("label", Object.assign({ htmlFor: id }, { children: _jsxs("div", Object.assign({ className: classNames('cursor-pointer bg-white border rounded-md', {
                'border-column-primary-400': checked,
                'border-column-gray-100': !checked
            }) }, { children: [children, _jsx("input", { id: id, className: "absolute opacity-0", type: "radio", onChange: value => {
                        if (value) {
                            onClick(option);
                        }
                    }, checked: checked, disabled: disabled })] })) })));
}
export default ChoiceButton;
