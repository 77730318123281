import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
export function LoadingSpinner(props) {
    var _a;
    const size = (_a = props.size) !== null && _a !== void 0 ? _a : 'md';
    const inline = !!props.inline;
    return (_jsx("div", Object.assign({ role: "loading-spinner", className: classNames({
            'flex justify-center align-center w-full': !inline
        }) }, { children: props.gifToReplaceSpinner || (_jsx("div", { className: classNames('loader ease-linear rounded-full border-4 border-t-4 border-column-gray-200', {
                'h-4 w-4': size === 'sm',
                'h-6 w-6': size === 'md'
            }) })) })));
}
