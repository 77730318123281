import { ELAVON, PAYWAY } from '../constants';
export var InvoiceType;
(function (InvoiceType) {
    InvoiceType["BULK"] = "bulk";
    InvoiceType["PUBLIC_NOTICE"] = "public_notice";
    InvoiceType["ORDER"] = "order";
})(InvoiceType || (InvoiceType = {}));
export const isPayOrderInvoiceData = (payInvoiceData) => {
    return payInvoiceData.type === InvoiceType.ORDER;
};
export const isBulkInvoiceData = (invoiceData) => {
    return (invoiceData.invoiceType === InvoiceType.BULK ||
        !!invoiceData.isBulkInvoice_v2);
};
export const isPublicNoticeInvoiceData = (invoiceData) => {
    return (invoiceData.invoiceType === InvoiceType.PUBLIC_NOTICE ||
        ('noticeId' in invoiceData &&
            // noticeId === '0' was done for bulk invoices as a workaround for the type
            invoiceData.noticeId !== '0' &&
            invoiceData.noticeId !== ''));
};
export const isOrderInvoiceData = (invoiceData) => {
    return invoiceData.invoiceType === InvoiceType.ORDER && !!invoiceData.order;
};
/**
 * @deprecated
 * Use isPaywayInvoice() instead
 * This function is used in both SCNG and BANG integrations
 * Determines if this invoice was ever processed in Payway (i.e. has a paywayToken).  May not be in a paid state!
 */
export const wasInvoiceProcessedInPayway = (invoice) => {
    return !!(invoice === null || invoice === void 0 ? void 0 : invoice.data().paywayToken);
};
export const isElavonInvoice = (invoice) => {
    return invoice.data().paymentGateway === ELAVON;
};
export const isPaywayInvoice = (invoice) => {
    return invoice.data().paymentGateway === PAYWAY;
};
