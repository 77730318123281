import { hasProperties, isObject, isSerializedField } from './typeCheckers';
export class TimestampSerializer {
    canSerialize(val) {
        return (isObject(val) &&
            hasProperties(val, { name: 'seconds', type: 'number' }, { name: 'nanoseconds', type: 'number' }));
    }
    canDeserialize(val) {
        return (isObject(val) &&
            isSerializedField(val, 'timestamp') &&
            hasProperties(val, { name: 'seconds', type: 'number' }, { name: 'nanoseconds', type: 'number' }));
    }
    serialize(val) {
        return {
            __type: 'timestamp',
            seconds: val.seconds,
            nanoseconds: val.nanoseconds
        };
    }
    deserialize(ctx, val) {
        return ctx.timestamp(val);
    }
}
