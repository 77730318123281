import { lastNoticePublicationDate, publicationDateStarted } from 'lib/helpers';
import { isAffidavitDisabled } from 'lib/affidavits';
import { userIsSuper } from 'utils/permissions';
import { isPublisher } from 'lib/utils/users';
/**
 * Determines whether or not the affidavit upload button should be disabled
 * @param notice
 * @param newspaper
 * @param invoice
 * @param user
 * @returns {boolean} true if the affidavit upload button should be disabled
 */
export const shouldUploadsBeDisabledForNotice = (notice, newspaper, invoice, user) => {
    const finalPublicationDate = lastNoticePublicationDate(notice);
    const { iana_timezone } = newspaper.data();
    const affidavitDisabled = isAffidavitDisabled(notice.data(), newspaper);
    const userIsPublisher = isPublisher(user);
    if (userIsSuper(user) &&
        publicationDateStarted(finalPublicationDate, iana_timezone)) {
        return false;
    }
    if (notice.data().affidavit ||
        !publicationDateStarted(finalPublicationDate, iana_timezone) ||
        !invoice ||
        !userIsPublisher ||
        affidavitDisabled) {
        return true;
    }
};
