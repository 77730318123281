var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { InviteStatus, OccupationType } from 'lib/enums';
import { getOpenInvitesAssociatedWithEmail, awaitAllPromises } from 'lib/helpers';
/**
 * Determine if an organization with the given name and type already exists.
 */
export const organizationAlreadyExists = (organizationType, name) => __awaiter(void 0, void 0, void 0, function* () {
    const { exists } = yield api.get(`organizations/${organizationType}/exists`, { name });
    return exists;
});
export const isAdvertiserOrganization = (occupation) => occupation === OccupationType.lawyer.value ||
    occupation === OccupationType.other_organization.value ||
    occupation === OccupationType.government_official.value;
export const isAddressHasAnyField = (address) => address.name ||
    address.city ||
    address.phone ||
    address.state.label ||
    address.addressLine1 ||
    address.zipCode;
/**
 * Update status of anonymous users pending invites to accepted and update the user to non-anonymous
 */
export const updatePendingInviteStatusForAnonUsers = (ctx, userSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const pendingInvites = yield getOpenInvitesAssociatedWithEmail(ctx, userSnap.data().email);
    if (pendingInvites.length) {
        yield awaitAllPromises(pendingInvites.map((pendingInvite) => __awaiter(void 0, void 0, void 0, function* () {
            if (!pendingInvite.data().organizationId && userSnap.data().anonymous) {
                yield pendingInvite.ref.update({
                    status: InviteStatus.accepted.value
                });
            }
        })));
    }
    if (userSnap.data().anonymous) {
        yield userSnap.ref.update({
            anonymous: false
        });
    }
});
