import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { DEFAULT_FTP_PORT, DEFAULT_SFTP_PORT } from 'lib/types/integrations';
import React from 'react';
export function DestinationFTP({ value, onChange }) {
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "automated-bulk-download-destination-email-ftp-host", labelText: "FTP server", type: "url", value: value.host, onChange: host => onChange(Object.assign(Object.assign({}, value), { host })), required: true }) })), _jsx(GridInput, { children: _jsx(TextField, { id: "automated-bulk-download-destination-email-ftp-path", labelText: "Upload path", value: value.path, onChange: path => onChange(Object.assign(Object.assign({}, value), { path })), placeholder: "/" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "automated-bulk-download-destination-email-ftp-protocol", labelText: "Protocol", options: [
                        { label: 'FTP', value: DEFAULT_FTP_PORT.toString() },
                        { label: 'SFTP', value: DEFAULT_SFTP_PORT.toString() }
                    ], value: value.port.toString(), onChange: stringVal => onChange(Object.assign(Object.assign({}, value), { port: Number(stringVal) })), required: true }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "automated-bulk-download-destination-email-ftp-user", labelText: "Username", value: value.user, onChange: user => onChange(Object.assign(Object.assign({}, value), { user })), required: true }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "automated-bulk-download-destination-email-ftp-password", labelText: "Password", type: "password", value: value.password, onChange: password => onChange(Object.assign(Object.assign({}, value), { password })), required: true }) })] }));
}
