import { getLinesPerInch } from '../math';
import { floatToP2Float } from '../ui';
export const oklahoma = {
    /**
     * Body words are currently calculated as words that are left
     * justified. This is the default setting in our TinyMCE editor
     */
    getBodyWords: (displayParameters) => { var _a, _b; return ((_b = (_a = displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.justifications) === null || _a === void 0 ? void 0 : _a.LEFT_JUSTIFIED) === null || _b === void 0 ? void 0 : _b.words) || 0; },
    /**
     * Tabular lines are calculated as all lines that are not left
     * justified. This includes paragraphs that are explicitly "left" aligned
     * beyond the default alignment settings.
     *
     * NOTE: currently this calculation includes the header lines, but
     * there are outstanding questions that need to be resolved by the
     * Oklahoma press association to confirm that this is intended
     */
    getTabularLines: (displayParameters) => {
        var _a, _b, _c, _d, _e, _f;
        return (((_b = (_a = displayParameters.justifications) === null || _a === void 0 ? void 0 : _a.LEFT_ALIGN) === null || _b === void 0 ? void 0 : _b.lines) || 0) +
            (((_d = (_c = displayParameters.justifications) === null || _c === void 0 ? void 0 : _c.RIGHT_ALIGN) === null || _d === void 0 ? void 0 : _d.lines) || 0) +
            (((_f = (_e = displayParameters.justifications) === null || _e === void 0 ? void 0 : _e.CENTER_ALIGN) === null || _f === void 0 ? void 0 : _f.lines) || 0);
    },
    /**
     * For pricing images we use a strange formula:
     * (height in inches) x (# Lines per inch) x (# Columns) x (Legal rate per tabular line)
     *
     * This function only reports first half (height * lpi) and leaves the calculation of
     * columns and tabular line rate to the caller.
     */
    getImageLines: (displayParameters, rate) => {
        const { images } = displayParameters;
        if (!images || !images.length) {
            return 0;
        }
        const imageHeights = images.map(i => i.height);
        const totalImageHeight = imageHeights.reduce((a, b) => a + b, 0);
        const linesPerInch = getLinesPerInch(rate, 'OK rate');
        return totalImageHeight * linesPerInch;
    }
};
export const priceOklahoma = options => {
    const { displayParameters, columns, rateRecord, runNumber } = options;
    // Words are 15c for the first run, 14xcfor all runs after
    const wordRate = runNumber === 0 ? 15 : 14;
    // Tabular lines are 70c for the first run, 65c for all runs after
    const tabularRate = runNumber === 0 ? 70 : 65;
    const wordTotal = floatToP2Float(oklahoma.getBodyWords(displayParameters) * wordRate);
    const tabularTotal = floatToP2Float(oklahoma.getTabularLines(displayParameters) * tabularRate * columns);
    const imageTotal = floatToP2Float(oklahoma.getImageLines(displayParameters, rateRecord) *
        tabularRate *
        columns);
    return wordTotal + tabularTotal + imageTotal;
};
