export var PublicationIssueAttachmentStatus;
(function (PublicationIssueAttachmentStatus) {
    PublicationIssueAttachmentStatus["PENDING"] = "pending";
    PublicationIssueAttachmentStatus["APPROVED"] = "approved";
    PublicationIssueAttachmentStatus["REJECTED"] = "rejected";
    PublicationIssueAttachmentStatus["DELETED"] = "deleted";
})(PublicationIssueAttachmentStatus || (PublicationIssueAttachmentStatus = {}));
/**
 * NOTE: The first 3 characters of this string is used in the naming of the file on upload,
 * so ensure the first 3 characters are unique for each type
 */
export var PublicationIssueAttachmentType;
(function (PublicationIssueAttachmentType) {
    PublicationIssueAttachmentType["MANIFEST"] = "manifest";
    PublicationIssueAttachmentType["PAGINATION"] = "pagination";
})(PublicationIssueAttachmentType || (PublicationIssueAttachmentType = {}));
