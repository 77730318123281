import { deadlineOverrideToDeadlineOverrideDate, getDeadlineOverridePublicationDateFromKey } from 'lib/types/deadlines';
import { getDeadlineTimeForRegularDeadline, getRelevantDeadline } from 'lib/utils/deadlines';
import moment from 'moment';
export const publisherDeadlineOverridesToScheduleChangesTableData = (publisherOverrideSettings = {}) => {
    return Object.entries(publisherOverrideSettings)
        .map(([key, settings]) => {
        return {
            deadlineDate: deadlineOverrideToDeadlineOverrideDate(key, settings),
            publicationDate: getDeadlineOverridePublicationDateFromKey(key),
            publish: settings.publish,
            displayOffset: settings.displayOffset
        };
    })
        .filter(({ publicationDate }) => {
        const pubDate = moment(publicationDate);
        const today = moment();
        return pubDate.isAfter(today, 'd');
    })
        .sort((a, b) => a.publicationDate.getTime() - b.publicationDate.getTime());
};
export const scheduleChangesTableDataToPublisherDeadline = ({ publicationDate, publish, deadlineDate, displayOffset }, deadlineTime) => {
    return Object.assign({ dayEnum: publicationDate.getDay() + 1, deadline: {
            dayEnum: deadlineDate.getDay() + 1,
            time: deadlineTime !== null && deadlineTime !== void 0 ? deadlineTime : moment(deadlineDate).format('HH:mm')
        }, weeks: moment(publicationDate)
            .startOf('day')
            .diff(moment(deadlineDate).startOf('day'), 'weeks'), publish }, (displayOffset ? { displayOffset } : {}));
};
export const DEFAULT_SCHEDULE_CHANGES_FILTER = {
    publicationDate: { type: 'all' },
    deadlineDate: { type: 'all' },
    publish: null
};
export const shouldShowScheduleChangesTableItem = (item, _search, { publicationDate, deadlineDate, publish }) => {
    if (typeof publish === 'boolean' && publish !== item.publish) {
        return false;
    }
    if (publicationDate.type === 'specific') {
        const itemPubDateMoment = moment(item.publicationDate);
        const filterPubDateMoment = moment(publicationDate.date);
        if (!itemPubDateMoment.isSame(filterPubDateMoment, 'date')) {
            return false;
        }
    }
    if (deadlineDate.type === 'specific') {
        const itemDeadlineMoment = moment(item.deadlineDate);
        const filterDeadlineMoment = moment(deadlineDate.date);
        if (!itemDeadlineMoment.isSame(filterDeadlineMoment, 'date')) {
            return false;
        }
    }
    return true;
};
export const getNumScheduleChangesFilters = ({ publicationDate, deadlineDate, publish }) => {
    let numFilters = 0;
    if (publicationDate.type === 'specific') {
        ++numFilters;
    }
    if (deadlineDate.type === 'specific') {
        ++numFilters;
    }
    if (typeof publish === 'boolean') {
        ++numFilters;
    }
    return numFilters;
};
export const getFilterHasChanges = ({ publicationDate: prevPubDate, deadlineDate: prevDeadline, publish: prevPublish }, { publicationDate: currPubDate, deadlineDate: currDeadline, publish: currPublish }) => {
    if (prevPubDate.type !== currPubDate.type) {
        return true;
    }
    if (prevPubDate.type === 'specific' && currPubDate.type === 'specific') {
        const prevPubMoment = moment(prevPubDate.date);
        const currPubMoment = moment(currPubDate.date);
        if (!prevPubMoment.isSame(currPubMoment, 'date')) {
            return true;
        }
    }
    if (prevDeadline.type !== currDeadline.type) {
        return true;
    }
    if (prevDeadline.type === 'specific' && currDeadline.type === 'specific') {
        const prevDeadlineMoment = moment(prevDeadline.date);
        const currDeadlineMoment = moment(currDeadline.date);
        if (!prevDeadlineMoment.isSame(currDeadlineMoment, 'date')) {
            return true;
        }
    }
    if (prevPublish !== currPublish) {
        return true;
    }
    return false;
};
export const getScheduleChangeSettingsForNewPublicationDate = (publicationDate, activeOrganization) => {
    const { deadlines, deadlineOverrides, iana_timezone } = activeOrganization.data();
    if (!deadlines)
        throw new Error('No deadlines found for newspaper');
    const deadline = getRelevantDeadline(publicationDate, deadlines, deadlineOverrides);
    if (!deadline) {
        throw new Error('Could not identify deadline for publication date');
    }
    const deadlineMoment = getDeadlineTimeForRegularDeadline(publicationDate, deadline, iana_timezone);
    return {
        publicationDate,
        deadlineDate: deadlineMoment.toDate(),
        publish: deadline.publish,
        isEdit: false
    };
};
