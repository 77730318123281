import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { ArrowDownCircleIcon, ChevronDownIcon, DocumentArrowDownIcon, EnvelopeOpenIcon, FunnelIcon, ScaleIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash';
import { isColumnUser } from 'lib/helpers';
import { selectIsPublisher } from 'redux/auth';
import { ColumnButton } from 'lib/components/ColumnButton';
import { SuccessModal } from 'lib/components/SuccessModal';
import JoinOrganizationModal from 'components/modals/JoinOrganizationModals/JoinOrganizationModal';
import { DEFAULT_NOTICE_FILTERS, getNumActiveFilters } from 'utils/noticeFilter';
import PlaceNoticeButton from 'components/PlaceNoticeButton';
import { userIsAdmin, userIsBilling } from 'utils/permissions';
import JoinOrganizationRequestModal from 'components/modals/JoinOrganizationModals/JoinOrganizationRequestModal';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import ToastActions from 'redux/toast';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import YourNoticesTable from './YourNoticesTable';
import BulkDownloadModal from './BulkDownloadModal';
import BulkAffidavitDownload from './BulkAffidavitDownloadModal';
import BulkAffidavitUpload from './BulkAffidavitUploadModal';
import DwollaPasswordResetModal from '../../settings/publisher/paymentSettings/DwollaPasswordResetModal';
import { ACTIVE_NOTICES_TAB } from './types';
import ExportNoticesModal from './ExportNoticesModal';
import NoticesFilterDialog from './NoticesFilterDialog';
const DropdownItem = ({ id, onClick, children }) => (_jsx("button", Object.assign({ id: id, className: "w-full flex items-center py-3 px-4 font-medium text-sm text-column-gray-500 hover:bg-column-primary-50 hover:text-column-primary-500", role: "menuitem", onClick: e => onClick(e) }, { children: children })));
function NoticeExportMenu({ handleClick }) {
    const { setOpen } = useContext(PopoverContext);
    return (_jsxs("div", Object.assign({ className: "w-48 p-2" }, { children: [_jsxs(DropdownItem, Object.assign({ id: "export-csv", onClick: (_e) => {
                    handleClick(_e, 'showExportCsvModal');
                    setOpen(false);
                } }, { children: [_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx(DocumentArrowDownIcon, { className: "w-5 h-5" }) })), _jsx("span", Object.assign({ className: "pl-3 font-medium" }, { children: "Export as CSV" }))] })), _jsxs(DropdownItem, Object.assign({ id: "export-pdf", onClick: (_e) => {
                    handleClick(_e, 'showExportPdfModal');
                    setOpen(false);
                } }, { children: [_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx(DocumentArrowDownIcon, { className: "w-5 h-5", strokeWidth: "2" }) })), _jsx("span", Object.assign({ className: "pl-3 font-medium" }, { children: "Export as PDF" }))] })), _jsx(DropdownItem, Object.assign({ id: "send-to-email", onClick: (_e) => {
                    handleClick(_e, 'showExportEmailModal');
                    setOpen(false);
                } }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(EnvelopeOpenIcon, { className: "w-5 h-5", strokeWidth: "2" }), _jsx("span", Object.assign({ className: "pl-3 font-medium" }, { children: "Send to email" }))] })) }))] })));
}
function ActionButtons({ isPublisher, tab, showAllOrgsNotices, activeOrganization, noticesFilterValue, showSyncStatus, setShowExportEmailModal, setShowExportCsvModal, setShowExportPdfModal, setShowBulkDownloadModal, setShowBulkAffidavitDownloadModal, setShowBulkAffidavitUploadModal, setNoticesFilterValue }) {
    const [showMenu, setShowMenu] = useState();
    const showFilterMenu = showMenu === 'filter';
    const showMoreActionsMenu = showMenu === 'more-actions';
    const toggleMenu = (menu) => {
        if (showMenu === menu) {
            setShowMenu(undefined);
        }
        else {
            setShowMenu(menu);
        }
    };
    const numFiltersActive = getNumActiveFilters(noticesFilterValue);
    const handleClick = (e, value) => {
        e.preventDefault();
        switch (value) {
            case 'showBulkAffidavitDownloadModal':
                setShowBulkAffidavitDownloadModal(true);
                setShowMenu(undefined);
                break;
            case 'showBulkAffidavitUploadModal':
                setShowBulkAffidavitUploadModal(true);
                setShowMenu(undefined);
                break;
            case 'showBulkNoticeDownloadModal':
                setShowBulkDownloadModal(true);
                setShowMenu(undefined);
                break;
            case 'showExportCsvModal':
                setShowExportCsvModal(true);
                setShowMenu(undefined);
                break;
            case 'showExportEmailModal':
                setShowExportEmailModal(true);
                setShowMenu(undefined);
                break;
            default:
                setShowExportPdfModal(true);
                setShowMenu(undefined);
        }
    };
    const actionButtonClassName = 'border-column-gray-200 bg-white border focus:outline-none hover:border-column-primary-400 p-2 px-4 rounded-md text-column-gray-400 font-semibold items-center inline-flex';
    const showFilters = tab === ACTIVE_NOTICES_TAB;
    const showMoreActionsButton = isPublisher && tab === ACTIVE_NOTICES_TAB;
    const showAdvertiserExport = !isPublisher && !showAllOrgsNotices && tab === ACTIVE_NOTICES_TAB;
    const renderMoreActionsButton = () => {
        var _a;
        return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "relative inline-block text-left", id: "more-actions" }, { children: [_jsx("div", { children: _jsxs("button", Object.assign({ type: "button", onClick: () => toggleMenu('more-actions'), className: actionButtonClassName, id: "more-actions-menu", "aria-expanded": "true", "aria-haspopup": "true" }, { children: [_jsx("span", Object.assign({ className: "" }, { children: "More" })), _jsx(ChevronDownIcon, { className: "ml-2 w-5 h-5" })] })) }), showMoreActionsMenu && (_jsx("div", Object.assign({ className: "origin-top-right absolute right-0 mt-1 w-56 z-10 rounded-md shadow-md border border-column-gray-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none pt-4 pb-1", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "more-actions-menu" }, { children: _jsxs("div", Object.assign({ className: "py-1", role: "none" }, { children: [_jsx("div", Object.assign({ className: "uppercase text-column-gray-400 text-xs font-bold mb-3 px-4" }, { children: "more actions" })), _jsxs(DropdownItem, Object.assign({ id: "download-notice", onClick: (_e) => handleClick(_e, 'showBulkNoticeDownloadModal') }, { children: [_jsx(DocumentArrowDownIcon, { className: "w-5 h-5 mr-2" }), "Download notices"] })), !((_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.affidavitDisabled) && (_jsxs(_Fragment, { children: [_jsxs(DropdownItem, Object.assign({ id: "download-affidavits", onClick: (_e) => handleClick(_e, 'showBulkAffidavitDownloadModal') }, { children: [_jsx(ArrowDownCircleIcon, { className: "w-5 h-5 mr-2" }), "Download affidavits"] })), _jsxs(DropdownItem, Object.assign({ id: "upload-affidavits", onClick: (_e) => handleClick(_e, 'showBulkAffidavitUploadModal') }, { children: [_jsx(ScaleIcon, { className: "w-5 h-5 mr-2" }), "Upload affidavits"] }))] }))] })) })))] })) }));
    };
    return (_jsxs("div", Object.assign({ id: "export-dropdown", className: `flex items-center gap-3` }, { children: [showFilters && (_jsxs("div", Object.assign({ className: "relative inline-block text-left text-column-gray-400" }, { children: [_jsx("div", { children: _jsxs("button", Object.assign({ type: "button", className: actionButtonClassName, id: "filters-options-menu", "aria-expanded": "true", "aria-haspopup": "true", onClick: () => toggleMenu('filter') }, { children: [_jsx("span", Object.assign({ className: "mr-2" }, { children: "Filter" })), numFiltersActive > 0 && (_jsxs("div", Object.assign({ className: "text-xs text-column-primary-500 bg-column-primary-100 rounded-full px-2 py-px mr-1 whitespace-nowrap" }, { children: [_jsxs("span", { children: [numFiltersActive, " active"] }), _jsx("span", Object.assign({ className: "ml-2 cursor-pointer hover:text-column-primary-700", onClick: e => {
                                                e.stopPropagation();
                                                setNoticesFilterValue(DEFAULT_NOTICE_FILTERS);
                                                setShowMenu(undefined);
                                            } }, { children: "\u2715" }))] }))), _jsx(FunnelIcon, { className: "w-5 h-5" })] })) }), showFilterMenu && (_jsx(NoticesFilterDialog, { className: "absolute right-0", value: noticesFilterValue, onChange: v => {
                            setNoticesFilterValue(v);
                            setShowMenu(undefined);
                        }, showSyncFilter: showSyncStatus, isPublisher: isPublisher }))] }))), showMoreActionsButton ? renderMoreActionsButton() : _jsx(_Fragment, {}), showAdvertiserExport && (_jsx(Popover, Object.assign({ activator: _jsx(ColumnButton, { buttonText: "Export", endIcon: _jsx(ChevronDownIcon, { className: "w-5 h-5" }), type: "button" }), id: "export-popover", alignment: "right" }, { children: _jsx(NoticeExportMenu, { handleClick: handleClick }) })))] })));
}
export default function YourNoticesTableView({ user, activeOrganization, userAuth, userSnap, showAllOrgsNotices, tab, search, notices, total, showUserOnlyNotices, fetching, noticesActions, authActions, pageSize, sort, noticesFilterValue }) {
    var _a, _b, _c;
    const dispatch = useAppDispatch();
    const isPublisher = useAppSelector(selectIsPublisher);
    const [openBulkDownloadModal, setShowBulkDownloadModal] = useState(false);
    const [searchValue, setSearchValue] = useState(search);
    const [showExportPdfModal, setShowExportPdfModal] = useState(false);
    const [showExportCsvModal, setShowExportCsvModal] = useState(false);
    const [showExportEmailModal, setShowExportEmailModal] = useState(false);
    const [showExportSuccessModal, setShowExportSuccessModal] = useState(false);
    const [showBulkAffidavitDownloadModal, setShowBulkAffidavitDownloadModal] = useState(false);
    const [showBulkAffidavitUploadModal, setShowBulkAffidavitUploadModal] = useState(false);
    const showSyncErrors = isPublisher && isColumnUser(userSnap);
    const [successModalBody, setSuccessModalBody] = useState();
    const [showRequests, setShowRequests] = useState(false);
    // Modal for password reset that appears when newspaper user add Dwolla
    // payment method. Not shown when shadowing.
    const isShadowing = !!user.data().shadowUser;
    const passwordResetModalValue = ((_b = (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.dwolla) === null || _b === void 0 ? void 0 : _b.dwollaFundingSource) &&
        (userIsAdmin(user) || userIsBilling(user)) &&
        !user.data().dwollaPasswordSecurityEnabled;
    const [openPasswordResetModal, setOpenPasswordResetModal] = useState(!isShadowing && passwordResetModalValue);
    const updateSearch = useMemo(() => debounce(value => {
        if (value !== search) {
            noticesActions.setCurrentPage(0);
        }
        noticesActions.setSearch(value);
    }, 400), []);
    useEffect(() => {
        updateSearch(searchValue);
    }, [searchValue]);
    useEffect(() => {
        if (!!showBulkAffidavitUploadModal || !!showBulkAffidavitDownloadModal) {
            dispatch(ToastActions.clearToast());
        }
    }, [showBulkAffidavitDownloadModal, showBulkAffidavitUploadModal]);
    // For publishers with an integration, we have a column which shows sync status at deadline.
    const hasIntegration = !!((_c = useSyncExportSettings(activeOrganization)) === null || _c === void 0 ? void 0 : _c.format);
    const showSyncStatus = isPublisher && hasIntegration;
    const actionButtons = (_jsx(ActionButtons, { isPublisher: isPublisher, showAllOrgsNotices: showAllOrgsNotices, tab: tab, activeOrganization: activeOrganization, noticesFilterValue: noticesFilterValue, showSyncErrors: showSyncErrors, showSyncStatus: showSyncStatus, setShowExportCsvModal: setShowExportCsvModal, setShowExportEmailModal: setShowExportEmailModal, setShowExportPdfModal: setShowExportPdfModal, setShowBulkDownloadModal: setShowBulkDownloadModal, setShowBulkAffidavitDownloadModal: setShowBulkAffidavitDownloadModal, setShowBulkAffidavitUploadModal: setShowBulkAffidavitUploadModal, setNoticesFilterValue: noticesActions.setNoticesFilterValue }));
    const tableProps = {
        notices: fetching ? [] : notices || [],
        loading: fetching,
        setCurrentPage: noticesActions.setCurrentPage,
        sort,
        setSort: noticesActions.setSort,
        actions: actionButtons,
        total,
        setPageSize: noticesActions.setPageSize,
        setTab: noticesActions.setTab,
        setSearchValue,
        rowsPerNoticeTablePage: pageSize,
        setShowUserOnlyNotices: noticesActions.setShowUserOnlyNotices,
        showUserOnlyNotices,
        searchValue,
        showSyncStatus
    };
    return (_jsxs("div", Object.assign({ className: "pb-10 py-4 px-8" }, { children: [_jsxs("header", Object.assign({ className: "flex justify-between pb-6 items-center" }, { children: [_jsxs("div", { children: [_jsx("h1", Object.assign({ className: "text-2xl font-medium leading-tight text-column-gray-900 mb-1" }, { children: "Notices" })), _jsx("h2", Object.assign({ className: "text-column-gray-400 leading-6" }, { children: "Track the notices associated with this account." }))] }), _jsx(PlaceNoticeButton, { id: "place-notice-button-table" })] })), _jsxs("main", { children: [_jsx(YourNoticesTable, Object.assign({}, tableProps)), openBulkDownloadModal && (_jsx(BulkDownloadModal, { organization: activeOrganization, setOpen: setShowBulkDownloadModal })), openPasswordResetModal && (_jsx(DwollaPasswordResetModal, { fromMainView: true, user: userSnap, userAuth: userAuth, passwordUpdateSuccess: () => {
                            setOpenPasswordResetModal(false);
                            dispatch(ToastActions.toastSuccess({
                                headerText: 'Success',
                                bodyText: 'Your password has been changed.'
                            }));
                        } })), showBulkAffidavitUploadModal && (_jsx(BulkAffidavitUpload, { setOpen: setShowBulkAffidavitUploadModal, activeOrganization: activeOrganization, setMessage: message => dispatch(ToastActions.toastSuccess({
                            headerText: 'Success',
                            bodyText: message
                        })), user: userSnap.ref })), showBulkAffidavitDownloadModal && (_jsx(BulkAffidavitDownload, { setOpen: setShowBulkAffidavitDownloadModal, activeOrganization: activeOrganization, setMessage: message => dispatch(ToastActions.toastSuccess({
                            headerText: 'Success',
                            bodyText: message
                        })) })), showExportCsvModal && (_jsx(ExportNoticesModal, { setOpen: setShowExportCsvModal, onSuccessfulExport: setShowExportSuccessModal, setSuccessModalBody: setSuccessModalBody, user: userSnap, reportType: "csv" })), showExportPdfModal && (_jsx(ExportNoticesModal, { setOpen: setShowExportPdfModal, onSuccessfulExport: setShowExportSuccessModal, setSuccessModalBody: setSuccessModalBody, user: userSnap, reportType: "pdf" })), showExportEmailModal && (_jsx(ExportNoticesModal, { setOpen: setShowExportEmailModal, onSuccessfulExport: setShowExportSuccessModal, setSuccessModalBody: setSuccessModalBody, user: userSnap, reportType: "email" })), showExportSuccessModal && (_jsx(SuccessModal, { setOpen: setShowExportSuccessModal, gif: _jsx("img", { src: 'https://enotice-production.imgix.net/custom-documents/permalink/bb78.9cbe2-report-downloaded.gif', style: {
                                clipPath: 'circle()',
                                width: '104px',
                                height: '104px'
                            } }), header: "Report Exported", body: successModalBody, footer: _jsxs("span", { children: ["Here's what you can do next:", ' ', _jsx("a", Object.assign({ href: "/place", className: "text-column-primary-900" }, { children: "Place a new notice" }))] }) })), _jsx(JoinOrganizationModal, { user: userSnap, authActions: authActions, showRequests: value => {
                            setShowRequests(value);
                        } }), showRequests && (_jsx(JoinOrganizationRequestModal, { user: userSnap, activeOrganization: activeOrganization }))] })] })));
}
