var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Form } from 'lib/components/Form';
import { SUBDOMAIN_REGEX } from 'lib/constants/validationRegex';
import { TextField } from 'lib/components/TextField';
import { PublishTrigger } from 'lib/types/productSiteSetting';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Alert } from 'lib/components/Alert';
const publishTriggerOptions = [
    {
        value: PublishTrigger.FIRST_PUBLISHING_DATE,
        label: `On the first publishing date`
    },
    { value: PublishTrigger.CONFIRMATION, label: 'When I confirm the order' }
];
export function SiteSettingForm({ onSave, siteSetting, url, productLine, loading }) {
    var _a, _b;
    const defaultPublishTrigger = PublishTrigger.FIRST_PUBLISHING_DATE;
    const [siteEnabled, setSiteEnabled] = useState(!((_a = siteSetting === null || siteSetting === void 0 ? void 0 : siteSetting.disabled) !== null && _a !== void 0 ? _a : true));
    const [publishTrigger, setPublishTrigger] = useState((_b = siteSetting === null || siteSetting === void 0 ? void 0 : siteSetting.publishTrigger) !== null && _b !== void 0 ? _b : defaultPublishTrigger);
    const [subdomainName, setSubdomainName] = useState((siteSetting === null || siteSetting === void 0 ? void 0 : siteSetting.subdomainName) || '');
    const [userError, setUserError] = useState('');
    const handleSave = (enabled) => __awaiter(this, void 0, void 0, function* () {
        var _c;
        setUserError('');
        const newSiteSetting = {
            product: productLine,
            publishTrigger,
            subdomainName,
            disabled: !enabled
        };
        const success = yield onSave(newSiteSetting);
        if (!success) {
            setSubdomainName((siteSetting === null || siteSetting === void 0 ? void 0 : siteSetting.subdomainName) || '');
            setPublishTrigger((_c = siteSetting === null || siteSetting === void 0 ? void 0 : siteSetting.publishTrigger) !== null && _c !== void 0 ? _c : defaultPublishTrigger);
        }
    });
    const handleSiteEnabledChange = (newEnabledValue) => {
        setSiteEnabled(newEnabledValue);
        if (!newEnabledValue) {
            void handleSave(newEnabledValue); // Save immediately when disabling
        }
    };
    return (_jsxs(Form, Object.assign({ onSubmit: () => handleSave(true) }, { children: [userError && _jsx(Alert, { status: "error", id: "save-error", title: userError }), url ? (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { status: "success", id: "site-online", title: _jsxs(_Fragment, { children: ["Your site is online at:", ' ', _jsx("a", Object.assign({ href: url, target: "_blank", rel: "noopener noreferrer" }, { children: url }))] }) }) }))) : (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { status: "warning", id: "site-offline", title: "Your site is not online yet. Save your settings to get started." }) }))), _jsxs(SwitchControlledCard, Object.assign({ labelProps: {
                    label: `Enable ${productLine} site?`,
                    description: `Enable your newspaper's ${productLine} site, hosted by Column.`,
                    onChange: handleSiteEnabledChange,
                    value: siteEnabled
                }, header: `Site Settings` }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "subdomainName", labelText: "Subdomain Name", value: subdomainName, onChange: setSubdomainName, type: "text", pattern: SUBDOMAIN_REGEX, maxLength: 63, validationMessages: {
                                patternMismatch: 'Please enter a valid subdomain name'
                            }, required: true }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { options: publishTriggerOptions, value: publishTrigger, onChange: setPublishTrigger, id: "publish-trigger-select", labelText: "Publish to the site..." }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnButton, { primary: true, type: "submit", buttonText: "Save & Setup Site", loading: loading }) }))] }))] })));
}
