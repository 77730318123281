import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { DocumentTextIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { fileTypeIsImage } from '../../helpers';
import { FileType, getFileTypeFromExtensionString } from '../../types/mime';
function DocumentIcon({ fileExtensionString }) {
    const fileType = getFileTypeFromExtensionString(fileExtensionString) || FileType.WORD_DOC;
    const fileIsImage = fileType && fileTypeIsImage(fileType);
    return fileIsImage ? (_jsx(PhotoIcon, { className: "w-6 h-6 text-column-red-600" })) : (_jsx(DocumentTextIcon, { className: "w-6 h-6 text-column-primary-600" }));
}
export default DocumentIcon;
