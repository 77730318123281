var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Product } from 'lib/enums';
import SettingsPage from 'routes/settings/SettingsPage';
import { rateCodeGenerator } from 'lib/helpers';
import { logAndCaptureMessage } from 'utils';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import FilingTypeSettings from '.';
const generateUniqueNoticeTypeValueForPublication = (publicationSnap) => {
    let mostRecentValue = '';
    const MAX_ATTEMPTS = 10;
    for (let i = 1; i <= MAX_ATTEMPTS; i++) {
        const value = rateCodeGenerator();
        if (!(publicationSnap.data().allowedNotices || []).some(customNoticeType => `${customNoticeType.value}` === `${value}`)) {
            return value;
        }
        mostRecentValue = `${value}`;
    }
    logAndCaptureMessage('Unable to generate unique notice type value for newspaper', {
        mostRecentValue,
        newspaperId: publicationSnap.id
    });
    throw Error('Unable to generate unique notice type value for newspaper');
};
function NoticeTypeSettings({ activeOrganization }) {
    var _a;
    const types = (_a = activeOrganization.data().allowedNotices) !== null && _a !== void 0 ? _a : [];
    const tableNoticeTypes = types.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
    return (_jsx(SettingsPage, { children: _jsx(FilingTypeSettings, { activeOrganization: activeOrganization, onSave: (filingType) => __awaiter(this, void 0, void 0, function* () {
                const index = types.findIndex(type => filingType.value === type.value);
                types.splice(index < 0 ? types.length : index, 1, filingType);
                const organizationModel = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrganization);
                yield organizationModel.updateFilingTypes(types);
            }), archivable: {
                isArchiveDisabled: item => item.value === activeOrganization.data().defaultNoticeType,
                renderWarning: noticeType => {
                    var _a;
                    return {
                        header: `Archive Notice Type?`,
                        body: `Are you sure you want to archive ${(_a = noticeType.label) === null || _a === void 0 ? void 0 : _a.trim()}?`
                    };
                },
                onArchive: (filingType) => __awaiter(this, void 0, void 0, function* () {
                    yield activeOrganization.ref.update({
                        allowedNotices: types.filter(type => filingType.value !== type.value)
                    });
                })
            }, creatable: {
                createButtonText: `Add Notice Type`,
                onCreate: () => ({
                    label: `New Notice Type`,
                    value: generateUniqueNoticeTypeValueForPublication(activeOrganization)
                })
            }, filingTypes: tableNoticeTypes, productLine: Product.Notice }) }));
}
export default NoticeTypeSettings;
