import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { CatUploadingAHamburger } from 'lib/components/gifs';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
export function LoadingSpinnerContainer({ keepWaitingForLargeFile }) {
    const loadingSpinnerMessage = 'Preparing your files';
    return (_jsx("div", Object.assign({ className: "border rounded-md mt-6 flex items-center bg-base-1", style: { height: '408px' } }, { children: _jsxs("div", Object.assign({ className: "w-full -mt-8" }, { children: [keepWaitingForLargeFile && (_jsx(LoadingState, { timeout: 120, gifToReplaceSpinner: _jsx("img", { className: "rounded-full pt-4 pb-5 pl-4 pr-2 h-22 w-22 bg-column-gray-100", src: CatUploadingAHamburger }), message: loadingSpinnerMessage, context: {
                        location: 'NoticeContentStep (GIF loader)',
                        service: ColumnService.WEB_PLACEMENT
                    } })), !keepWaitingForLargeFile && (_jsx(LoadingState, { timeout: 120, message: loadingSpinnerMessage, context: {
                        location: 'NoticeContentStep (default loader)',
                        service: ColumnService.WEB_PLACEMENT
                    } }))] })) })));
}
