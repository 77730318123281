var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import firebase from 'firebase';
import { logAndCaptureException } from 'utils';
import { push } from 'connected-react-router';
import { useAppDispatch } from 'redux/hooks';
import { capitalizeWord } from 'lib/utils/strings';
import { logInfo } from 'utils/logger';
import { ColumnService } from 'lib/services/directory';
export default function PromptForPasswordModal({ onClose, userAuthData }) {
    const dispatch = useAppDispatch();
    const [password, setPassword] = useState('');
    const [linkingWithCredentials, setLinkingWithCredentials] = useState(false);
    const providerId = userAuthData.credential.providerId.split('.')[0];
    const setPasswordToLinkCredentials = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        setLinkingWithCredentials(true);
        const { email, credential } = userAuthData;
        try {
            yield firebase.auth().signInWithEmailAndPassword(email, password);
            if (credential && firebase.auth().currentUser) {
                logInfo(`User linking ${capitalizeWord(providerId)} account with Password`, { email });
                yield ((_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.linkWithCredential(credential));
                dispatch(push('/'));
            }
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to link with email provider', {
                email
            });
        }
        setLinkingWithCredentials(false);
    });
    return (_jsx(Modal, Object.assign({ id: "password-prompt-modal", title: 'Enter password', subtitle: `The email you are trying to register is already linked with password. To link an existing account with ${capitalizeWord(providerId)}, please enter the password of your existing account. Otherwise, click cancel.`, primaryAction: {
            type: 'button',
            loading: linkingWithCredentials,
            buttonText: 'Submit',
            onClick: setPasswordToLinkCredentials
        }, allowCloseOutsideModal: false, showCloseButton: false, secondaryActions: [
            {
                type: 'button',
                buttonText: 'Cancel',
                onClick: onClose
            }
        ], onClose: onClose }, { children: _jsx("div", Object.assign({ className: "pb-6" }, { children: _jsx(TextField, { id: "password", labelText: "Enter password", type: "password", placeholder: "******", value: password, onChange: value => setPassword(value) }) })) })));
}
