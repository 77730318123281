import { UPLOAD_FLORIDA, UPLOAD_ILLINOIS, UPLOAD_NEWZGROUP, UPLOAD_NEW_YORK } from '../types/externalUploads';
export const StateData = {
    alabama: {
        value: 2,
        label: 'Alabama',
        abbrev: 'al',
        us_census_fips_code: '01',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.alabamapublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'app.scraper.PressAssociationScraper.AlabamaScraper',
        key: 'alabama',
        showUploads: UPLOAD_ILLINOIS
    },
    alaska: {
        value: 3,
        label: 'Alaska',
        abbrev: 'ak',
        us_census_fips_code: '02',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'https://aws.state.ak.us/OnlinePublicNotices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '4',
        scraper: 'app.scraper.AlaskaScraper.AlaskaIncrementScraper',
        key: 'alaska'
    },
    arizona: {
        value: 4,
        label: 'Arizona',
        abbrev: 'az',
        us_census_fips_code: '04',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/AZ/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.ArizonaScraper',
        key: 'arizona',
        showUploads: 'INTERNAL'
    },
    arkansas: {
        value: 5,
        label: 'Arkansas',
        abbrev: 'ar',
        us_census_fips_code: '05',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/AR/',
        custom_site: 'http://www.arkansasonline.com/legals/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.ArkansasScraper',
        key: 'arkansas',
        showUploads: 'INTERNAL'
    },
    california: {
        value: 6,
        label: 'California',
        abbrev: 'ca',
        us_census_fips_code: '06',
        newzgroup: false,
        my_public_notices: 'http://ca.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.capublicnotice.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '5',
        scraper: '',
        key: 'california',
        showUploads: UPLOAD_ILLINOIS
    },
    colorado: {
        value: 7,
        label: 'Colorado',
        abbrev: 'co',
        us_census_fips_code: '08',
        newzgroup: false,
        my_public_notices: 'http://co.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticecolorado.com/',
        press_association_ssid: 663,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        hasTypeform: true,
        key: 'colorado',
        showUploads: UPLOAD_ILLINOIS
    },
    connecticut: {
        value: 8,
        label: 'Connecticut',
        abbrev: 'ct',
        us_census_fips_code: '09',
        newzgroup: false,
        my_public_notices: 'http://ct.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'connecticut'
    },
    delaware: {
        value: 9,
        label: 'Delaware',
        abbrev: 'de',
        us_census_fips_code: '10',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.mddcnews.com/index.php/public_notices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'delaware',
        showUploads: 'INTERNAL'
    },
    district_of_columbia: {
        value: 10,
        label: 'District of Columbia',
        abbrev: 'dc',
        us_census_fips_code: '11',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://classified.washingtontimes.com/category/315/Legal-Notices.html',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'district_of_columbia',
        showUploads: UPLOAD_ILLINOIS
    },
    florida: {
        value: 11,
        label: 'Florida',
        abbrev: 'fl',
        us_census_fips_code: '12',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: '',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: 'http://www.floridapublicnotices.com/',
        legal_notice_dot_org: '10',
        scraper: '',
        key: 'florida',
        stripe_account_id: {
            prod: 'acct_1HSlBQJEXHMRrM6c',
            test: 'acct_1HTVNlKO1H032WHx'
        },
        feePct: '3',
        lob_address_id: 'adr_1bcc80e032d26830',
        lob_bank_account_id: {
            test: 'bank_e90ac64381c00df'
        },
        stripe_customer_id: {
            prod: 'cus_IE8DTFEV1lWsag',
            test: 'cus_I8mlKFtwJ0S7dG'
        },
        showUploads: UPLOAD_FLORIDA
    },
    georgia: {
        value: 12,
        label: 'Georgia',
        abbrev: 'ga',
        us_census_fips_code: '13',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.georgiapublicnotice.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '11',
        scraper: '',
        key: 'georgia',
        showUploads: UPLOAD_ILLINOIS
    },
    guam: {
        value: 54,
        label: 'Guam',
        abbrev: 'gu',
        us_census_fips_code: '14',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'guam'
    },
    hawaii: {
        value: 13,
        label: 'Hawaii',
        abbrev: 'hi',
        us_census_fips_code: '15',
        newzgroup: false,
        my_public_notices: 'http://hi.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: 'http://statelegals.staradvertiser.com/category/public-notices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'hawaii'
    },
    idaho: {
        value: 14,
        label: 'Idaho',
        abbrev: 'id',
        us_census_fips_code: '16',
        newzgroup: false,
        my_public_notices: 'http://id.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.idahopublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '14',
        scraper: '',
        key: 'idaho',
        showUploads: UPLOAD_ILLINOIS
    },
    illinois: {
        value: 15,
        label: 'Illinois',
        abbrev: 'il',
        us_census_fips_code: '17',
        newzgroup: false,
        my_public_notices: 'http://il.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticeillinois.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'illinois',
        showUploads: UPLOAD_ILLINOIS
    },
    indiana: {
        value: 16,
        label: 'Indiana',
        abbrev: 'in',
        us_census_fips_code: '18',
        newzgroup: false,
        my_public_notices: 'http://in.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://hspa.eclipping.org/hspa/legals/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '16',
        scraper: '',
        key: 'indiana',
        showUploads: UPLOAD_ILLINOIS
    },
    iowa: {
        value: 17,
        label: 'Iowa',
        abbrev: 'ia',
        us_census_fips_code: '19',
        newzgroup: 'http://iapublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'iowa',
        showUploads: UPLOAD_ILLINOIS
    },
    kansas: {
        value: 18,
        label: 'Kansas',
        abbrev: 'ks',
        us_census_fips_code: '20',
        newzgroup: 'http://kspublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '17',
        scraper: '',
        hasTypeform: true,
        key: 'kansas',
        feePct: '3',
        lob_address_id: 'adr_2fb684459071fe8c',
        lob_bank_account_id: {
            prod: 'bank_dc616c8d076842c',
            test: 'bank_e90ac64381c00df'
        },
        stripe_account_id: {
            prod: 'acct_1HX65UJU1AKKkGS9',
            test: 'acct_1HTVNlKO1H032WHx'
        },
        stripe_customer_id: {
            prod: 'cus_IE8BnBuH5zBaZV',
            test: 'cus_I8mn4zkqciVlpW'
        },
        showUploads: UPLOAD_NEWZGROUP
    },
    kentucky: {
        value: 19,
        label: 'Kentucky',
        abbrev: 'ky',
        us_census_fips_code: '21',
        newzgroup: 'http://kypublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '18',
        scraper: '',
        key: 'kentucky'
    },
    louisiana: {
        value: 20,
        label: 'Louisiana',
        abbrev: 'la',
        us_census_fips_code: '22',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/LA/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.LouisianaScraper',
        key: 'louisiana',
        showUploads: UPLOAD_FLORIDA
    },
    maine: {
        value: 21,
        label: 'Maine',
        abbrev: 'me',
        us_census_fips_code: '23',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: '',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: 'http://www.mainenotices.com/',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'maine',
        showUploads: 'INTERNAL'
    },
    maryland: {
        value: 22,
        label: 'Maryland',
        abbrev: 'md',
        us_census_fips_code: '24',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.mddcnews.com/index.php/public_notices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'maryland',
        showUploads: UPLOAD_ILLINOIS
    },
    massachusetts: {
        value: 23,
        label: 'Massachusetts',
        abbrev: 'ma',
        us_census_fips_code: '25',
        newzgroup: false,
        my_public_notices: 'http://ma.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.masspublicnotices.org/',
        press_association_ssid: 162,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'massachusetts'
    },
    michigan: {
        value: 24,
        label: 'Michigan',
        abbrev: 'mi',
        us_census_fips_code: '26',
        newzgroup: 'http://mipublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'michigan'
    },
    minnesota: {
        value: 25,
        label: 'Minnesota',
        abbrev: 'mn',
        us_census_fips_code: '27',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.mnpublicnotice.com/',
        press_association_ssid: 527,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'minnesota',
        showUploads: UPLOAD_ILLINOIS
    },
    mississippi: {
        value: 26,
        label: 'Mississippi',
        abbrev: 'ms',
        us_census_fips_code: '28',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/MS/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '25',
        scraper: 'scraper.PublicNoticeAdsScraper.MississippiScraper',
        key: 'mississippi',
        showUploads: UPLOAD_ILLINOIS
    },
    missouri: {
        value: 27,
        label: 'Missouri',
        abbrev: 'mo',
        us_census_fips_code: '29',
        newzgroup: 'http://mopublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'missouri',
        showUploads: UPLOAD_ILLINOIS
    },
    montana: {
        value: 28,
        label: 'Montana',
        abbrev: 'mt',
        us_census_fips_code: '30',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://www.montanapublicnotices.com/mna/legals/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'montana'
    },
    nebraska: {
        value: 29,
        label: 'Nebraska',
        abbrev: 'ne',
        us_census_fips_code: '31',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://nepublicnotices.com/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'nebraska',
        showUploads: UPLOAD_ILLINOIS
    },
    nevada: {
        value: 30,
        label: 'Nevada',
        abbrev: 'nv',
        us_census_fips_code: '32',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/NV/',
        custom_site: 'https://notice.nv.gov/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.NevadaScraper',
        key: 'nevada',
        showUploads: UPLOAD_ILLINOIS
    },
    new_hampshire: {
        value: 31,
        label: 'New Hampshire',
        abbrev: 'nh',
        us_census_fips_code: '33',
        newzgroup: false,
        my_public_notices: 'http://nh.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'new_hampshire'
    },
    new_jersey: {
        value: 32,
        label: 'New Jersey',
        abbrev: 'nj',
        us_census_fips_code: '34',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/NJ/',
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.njpublicnotices.com/',
        press_association_ssid: 4831,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.NewJerseyScraper',
        key: 'new_jersey',
        showUploads: UPLOAD_ILLINOIS
    },
    new_mexico: {
        value: 33,
        label: 'New Mexico',
        abbrev: 'nm',
        us_census_fips_code: '35',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/NM/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.NewMexicoScraper',
        key: 'new_mexico',
        showUploads: UPLOAD_ILLINOIS
    },
    new_york: {
        value: 34,
        label: 'New York',
        abbrev: 'ny',
        us_census_fips_code: '36',
        newzgroup: false,
        my_public_notices: 'http://ny.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.newyorkpublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'new_york',
        stripe_account_id: {
            prod: 'acct_1Keg2BQadZZGhCfW',
            test: 'acct_1HRLJQBUfy4eFp3V'
        },
        feePct: '3',
        lob_address_id: '',
        lob_bank_account_id: {
            test: 'bank_e90ac64381c00df'
        },
        stripe_customer_id: {
            prod: 'cus_IOXC0co20zdD5D',
            test: 'cus_IOX9jG1IOOY2Hn'
        },
        hasSubscribers: true,
        showUploads: UPLOAD_NEW_YORK
    },
    north_carolina: {
        value: 35,
        label: 'North Carolina',
        abbrev: 'nc',
        us_census_fips_code: '37',
        newzgroup: false,
        my_public_notices: 'http://nc.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: '',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: 'http://www.ncnotices.com/',
        legal_notice_dot_org: '34',
        scraper: '',
        key: 'north_carolina',
        showUploads: UPLOAD_ILLINOIS
    },
    north_dakota: {
        value: 36,
        label: 'North Dakota',
        abbrev: 'nd',
        us_census_fips_code: '38',
        newzgroup: 'http://ndpublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.nd.gov/dhs/info/publicnotice/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'north_dakota',
        showUploads: UPLOAD_NEWZGROUP
    },
    ohio: {
        value: 37,
        label: 'Ohio',
        abbrev: 'oh',
        us_census_fips_code: '39',
        newzgroup: false,
        my_public_notices: true,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticesohio.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'app.scraper.PressAssociationScraper.OhioScraper',
        key: 'ohio',
        showUploads: UPLOAD_ILLINOIS
    },
    oklahoma: {
        value: 38,
        label: 'Oklahoma',
        abbrev: 'ok',
        us_census_fips_code: '40',
        newzgroup: false,
        my_public_notices: 'http://ok.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://opa.eclipping.org/opa/legals/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'oklahoma'
    },
    oregon: {
        value: 39,
        label: 'Oregon',
        abbrev: 'or',
        us_census_fips_code: '41',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticeoregon.com/',
        press_association_ssid: 700,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'oregon'
    },
    pennsylvania: {
        value: 40,
        label: 'Pennsylvania',
        abbrev: 'pa',
        us_census_fips_code: '42',
        newzgroup: false,
        my_public_notices: 'http://pa.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticepa.com/',
        press_association_ssid: 431,
        foundation: '',
        legal_notice_dot_org: '39',
        scraper: '',
        key: 'pennsylvania',
        stripe_account_id: {
            test: 'acct_1HTVNlKO1H032WHx'
        },
        showUploads: 'INTERNAL'
    },
    rhode_island: {
        value: 42,
        label: 'Rhode Island',
        abbrev: 'ri',
        us_census_fips_code: '44',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'rhode_island'
    },
    south_carolina: {
        value: 43,
        label: 'South Carolina',
        abbrev: 'sc',
        us_census_fips_code: '45',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.sclegalnotice.com/',
        tecnavia: false,
        press_association: 'https://www.scpublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '41',
        scraper: '',
        key: 'south_carolina',
        showUploads: UPLOAD_ILLINOIS
    },
    south_dakota: {
        value: 44,
        label: 'South Dakota',
        abbrev: 'sd',
        us_census_fips_code: '46',
        newzgroup: 'http://sdpublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'south_dakota',
        showUploads: UPLOAD_ILLINOIS
    },
    tennessee: {
        value: 45,
        label: 'Tennessee',
        abbrev: 'tn',
        us_census_fips_code: '47',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/TN/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '43',
        scraper: '',
        key: 'tennessee'
    },
    texas: {
        value: 46,
        label: 'Texas',
        abbrev: 'tx',
        us_census_fips_code: '48',
        newzgroup: 'http://www.texaslegalnotices.com/',
        my_public_notices: 'http://tx.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '44',
        scraper: '',
        key: 'texas',
        showUploads: UPLOAD_ILLINOIS
    },
    utah: {
        value: 47,
        label: 'Utah',
        abbrev: 'ut',
        us_census_fips_code: '49',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'https://www.utah.gov/pmn/index.html',
        tecnavia: false,
        press_association: 'https://utahlegals.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'utah',
        showUploads: UPLOAD_ILLINOIS
    },
    vermont: {
        value: 48,
        label: 'Vermont',
        abbrev: 'vt',
        us_census_fips_code: '50',
        newzgroup: false,
        my_public_notices: 'http://vt.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'vermont'
    },
    virginia: {
        value: 49,
        label: 'Virginia',
        abbrev: 'va',
        us_census_fips_code: '51',
        newzgroup: false,
        my_public_notices: 'http://va.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticevirginia.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'virginia',
        showUploads: UPLOAD_ILLINOIS
    },
    washington: {
        value: 50,
        label: 'Washington',
        abbrev: 'wa',
        us_census_fips_code: '53',
        newzgroup: false,
        my_public_notices: 'http://wa.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.wapublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '48',
        scraper: '',
        key: 'washington',
        showUploads: UPLOAD_ILLINOIS
    },
    west_virginia: {
        value: 51,
        label: 'West Virginia',
        abbrev: 'wv',
        us_census_fips_code: '54',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://wvlegals.com/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'west_virginia'
    },
    wisconsin: {
        value: 52,
        label: 'Wisconsin',
        abbrev: 'wi',
        us_census_fips_code: '55',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://www.wisconsinpublicnotices.org/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'wisconsin'
    },
    wyoming: {
        value: 53,
        label: 'Wyoming',
        abbrev: 'wy',
        us_census_fips_code: '56',
        newzgroup: 'http://wypublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: true,
        custom_site: 'http://www.wyomingworkforce.org/news/publicnotices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.WyomingScraper',
        key: 'wyoming',
        showUploads: UPLOAD_ILLINOIS
    }
};
