import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { TextField } from 'lib/components/TextField';
/**
 * TextField component specifically for use with decimal numbers.
 */
export default function DecimalTextField({ initialValue, decimalDigits, onChange, id, placeholder, disabled, labelText, prefix, required }) {
    const defaultValue = 0;
    const [value, setValue] = useState(initialValue === null || initialValue === void 0 ? void 0 : initialValue.toFixed(decimalDigits));
    return (_jsx(TextField, { id: id, labelText: labelText, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : defaultValue.toFixed(decimalDigits), disabled: disabled, type: "number", value: disabled ? '' : value, onChange: newValue => {
            setValue(newValue);
            onChange(parseFloat(newValue));
        }, prefix: prefix, min: 0, step: 1 / Math.pow(10, decimalDigits), required: required }));
}
