import { useEffect } from 'react';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import PlacementActions, { selectIsEditing, selectNewspaper } from 'redux/placement';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { safeStringify } from 'lib/utils/stringify';
import { usePublisherModularSizes } from './usePublisherModularSizes';
// NOTE: This hook could be better managed via a thunk Redux action, but there is
// further refactoring needed to support thunk dispatches.
export function useGetModularSizes(organization) {
    var _a;
    const dispatch = useAppDispatch();
    const placementPublisher = useAppSelector(selectNewspaper);
    const isEditing = useAppSelector(selectIsEditing);
    const enableModularSizes = getBooleanFlag(LaunchDarklyFlags.ENABLE_MODULAR_SIZES, false);
    const publisherRef = (_a = organization !== null && organization !== void 0 ? organization : placementPublisher) !== null && _a !== void 0 ? _a : null;
    const { modularSizes: publisherModularSizes } = usePublisherModularSizes(publisherRef);
    /**
     * Selects the first modular size by default, but not if we're editing
     * so that we don't override the notice's current modular size.
     */
    useEffect(() => {
        if (enableModularSizes && !isEditing && publisherModularSizes.length) {
            dispatch(PlacementActions.setModularSizeId(publisherModularSizes[0].id));
        }
    }, [
        enableModularSizes,
        isEditing,
        placementPublisher === null || placementPublisher === void 0 ? void 0 : placementPublisher.id,
        safeStringify(publisherModularSizes)
    ]);
    return enableModularSizes ? publisherModularSizes : [];
}
