import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { ColumnButton } from 'lib/components/ColumnButton';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { SelectStateDropdown } from './SelectStateDropdown';
import { PublisherLocationFilterBadge } from './PublisherLocationFilterBadge';
export function PublisherLocationFilter({ stateOptions, onStateChange, activeFilters }) {
    const [selectedState, setSelectedState] = useState(activeFilters.stateFilter ? String(activeFilters.stateFilter) : null);
    const activeFilterCount = getLocationFilterCount(activeFilters.stateFilter);
    function applyFilters(filters) {
        const { selectedState } = filters;
        onStateChange(selectedState ? Number(selectedState) : undefined);
    }
    function resetFilters() {
        setSelectedState(null);
        applyFilters({ selectedState: null });
    }
    return (_jsx(Popover, Object.assign({ id: "publisher-location-filter", headerText: "Filter publisher list by", fullWidth: true, activator: _jsx(ColumnButton, { buttonText: _jsxs(_Fragment, { children: ["Filter location", ' ', _jsx(PublisherLocationFilterBadge, { activeFilterCount: activeFilterCount, onClear: resetFilters })] }), fullWidth: true, alignment: "between", endIcon: _jsx(FunnelIcon, { className: "pb-1 pt-0.5 w-5" }), type: "button" }) }, { children: _jsx("div", Object.assign({ className: "p-4 space-y-2" }, { children: _jsx(PublisherLocationFilterForm, { stateOptions: stateOptions, selectedState: selectedState, onStateChange: setSelectedState, resetFilters: resetFilters, applyFilters: applyFilters }) })) })));
}
function PublisherLocationFilterForm({ stateOptions, selectedState, onStateChange, resetFilters, applyFilters }) {
    const { setOpen } = useContext(PopoverContext);
    const disableActions = !selectedState;
    function handleApplyFilters(filters) {
        applyFilters(filters);
        setOpen(false);
    }
    return (_jsxs(_Fragment, { children: [_jsx(SelectStateDropdown, { selectedState: selectedState, stateOptions: stateOptions, onChange: onStateChange }), _jsxs("div", Object.assign({ className: "flex justify-between pt-3" }, { children: [_jsx(ColumnButton, { disabled: disableActions, buttonText: "Reset", onClick: resetFilters, type: "button" }), _jsx(ColumnButton, { primary: true, disabled: disableActions, buttonText: "Apply", onClick: () => handleApplyFilters({ selectedState }), type: "button" })] }))] }));
}
function getLocationFilterCount(stateFilter) {
    // NOTE: Extend this calculation if we add more filters
    return [stateFilter].filter(filter => !!filter).length;
}
