var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFulfilled, getRejected } from '../helpers';
import { getModelFromSnapshot } from '.';
import { InternalServerError, NotFoundError, wrapErrorAsColumnError } from '../errors/ColumnErrors';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';
import { wrapSuccess } from '../types/responses';
import { getOrThrow } from '../utils/refs';
/**
 * Safely fetch a model from a reference, returning a ResponseOrError object
 */
export const safeGetModelFromRef = (ModelClass, ctx, ref) => __awaiter(void 0, void 0, void 0, function* () {
    const { response: snap, error } = yield safeGetOrThrow(ref);
    if (error || !snap) {
        return wrapErrorAsColumnError(error, NotFoundError);
    }
    const model = getModelFromSnapshot(ModelClass, ctx, snap);
    return wrapSuccess(model);
});
export const safeGetModelArrayFromRefs = (ModelClass, ctx, refs) => __awaiter(void 0, void 0, void 0, function* () {
    const snapshotResults = yield Promise.allSettled(refs.map(ref => getOrThrow(ref)));
    const rejected = getRejected(snapshotResults);
    if (rejected.length > 0) {
        return wrapErrorAsColumnError(rejected[0], NotFoundError);
    }
    const snapshots = getFulfilled(snapshotResults);
    const models = snapshots.map(snap => new ModelClass(ctx, { snap }));
    return wrapSuccess(models);
});
export const safeGetModelArrayFromQuery = (ModelClass, ctx, query) => __awaiter(void 0, void 0, void 0, function* () {
    const { response: queryRefs, error: queryError } = yield safeAsync(() => query.get())();
    if (queryError) {
        return wrapErrorAsColumnError(queryError, InternalServerError);
    }
    try {
        return wrapSuccess(queryRefs.docs.map(doc => getModelFromSnapshot(ModelClass, ctx, doc)));
    }
    catch (e) {
        return wrapErrorAsColumnError(e, InternalServerError);
    }
});
