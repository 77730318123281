import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { InputAccessories } from 'lib/components/InputAccessories';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import React from 'react';
export function PreviewContainer({ labelText, preview }) {
    var _a;
    /** We want the width (space allowing) to match the actual size of the notice in inches */
    const width = preview ? `${preview.width}in` : '';
    return (_jsx(InputAccessories, Object.assign({ id: "template-preview", labelText: labelText }, { children: _jsx("div", Object.assign({ className: "relative w-full bg-base-2 border border-column-gray-200 rounded-md p-4 flex justify-center" }, { children: preview ? (_jsxs(_Fragment, { children: [_jsx("img", { src: (_a = preview.imgs) === null || _a === void 0 ? void 0 : _a[0], className: "max-w-full", style: { width } }), _jsx("div", Object.assign({ className: "absolute top-0 flex gap-2 h-full w-full py-4 mx-4", style: { width, maxWidth: 'calc(100% - 2rem)' } }, { children: new Array(preview.columns).fill(null).map((_, idx) => (_jsx("div", { className: classNames('h-full w-full border-dotted border-l border-r border-column-gray-200') }, idx))) }))] })) : (_jsx(LoadingSpinner, {})) })) })));
}
