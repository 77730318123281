var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { OccupationType, OrganizationType } from 'lib/enums';
import { logAndCaptureException } from 'utils';
import useOutsideClickListener from 'lib/components/hooks/useOutsideClickListener';
import { TextField } from 'lib/components/TextField';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { Badge } from 'lib/components/Badge';
import api from 'api';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations } from 'redux/auth';
import { ColumnService } from 'lib/services/directory';
import SearchedOrganizationsList from '../SearchedOrganizationsList';
const getOrganizationsList = ({ organizationSearchText, occupationType }) => __awaiter(void 0, void 0, void 0, function* () {
    const relevantOrganizationType = {
        [OccupationType.government_official.value]: OrganizationType.government.value,
        [OccupationType.lawyer.value]: OrganizationType.law_firm.value,
        [OccupationType.other_organization.value]: OrganizationType.other_organization.value
    }[occupationType];
    const filters = [
        { type: [relevantOrganizationType] },
        { issearchable: [Number(true)] },
        { isactive: [Number(true)] }
    ];
    const postReqBody = {
        search: organizationSearchText,
        filters
    };
    const { results, error } = yield api.post('search/organizations', postReqBody);
    if (error) {
        logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, new Error('Unable to search advertiser organizations'), `Error in searching an advertiser organization while registration. Filters: ${JSON.stringify(filters)}`);
        return null;
    }
    return results;
});
export default function JoinExistingOrganization({ onStartJoiningNewOrganization, onUpdateOrganizationsToJoin, creatingNewOrganization, organizationsToJoin, occupationType }) {
    const availableOrganizations = useAppSelector(selectAvailableOrganizations);
    const [showOrganizationDropdown, setShowOrganizationDropdown] = useState(false);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [searchingOrganizations, setSearchingOrganizations] = useState(false);
    const [searchOrgText, setSearchOrgText] = useState('');
    const organizationDropdownRef = useRef(null);
    useOutsideClickListener(organizationDropdownRef, () => setShowOrganizationDropdown(false));
    const removeSelectedItem = (item) => {
        const newOrganizationsToJoin = organizationsToJoin.filter(organizationToJoin => organizationToJoin.label !== item.label);
        onUpdateOrganizationsToJoin(newOrganizationsToJoin);
    };
    useEffect(() => {
        if (creatingNewOrganization) {
            setShowOrganizationDropdown(false);
            setSearchOrgText('');
        }
    }, [creatingNewOrganization]);
    const searchOrg = useDebounce(searchOrgText, 400);
    useEffect(() => {
        const resetOrganizationsInDropdown = () => __awaiter(this, void 0, void 0, function* () {
            setSearchingOrganizations(true);
            setOrganizationsList([]);
            const newSearchedOrganizations = yield getOrganizationsList({
                organizationSearchText: searchOrg,
                occupationType
            });
            if (newSearchedOrganizations) {
                // filter out organizations that are already available to the user
                const validSearchedOrganizations = newSearchedOrganizations.filter(organization => {
                    return !availableOrganizations.find(availableOrganization => availableOrganization.id === organization.id);
                });
                setOrganizationsList(validSearchedOrganizations);
            }
            setSearchingOrganizations(false);
        });
        void resetOrganizationsInDropdown();
    }, [searchOrg]);
    return (_jsxs("div", Object.assign({ className: "w-128 mt-8" }, { children: [_jsx(TextField, { id: "search-org", labelText: "", placeholder: "Search to join existing organizations", onFocus: () => {
                    setShowOrganizationDropdown(true);
                    onStartJoiningNewOrganization();
                }, value: searchOrgText, onChange: newValue => setSearchOrgText(newValue), prefix: organizationsToJoin.length && (_jsx(_Fragment, { children: organizationsToJoin.map(item => (_jsx(Badge, Object.assign({ status: "info", endIcon: _jsx(XMarkIcon, { onClick: () => removeSelectedItem(item), className: "h-4 w-4" }) }, { children: item.label }), `${item.label}-selected-item`))) })), suffix: _jsx(MagnifyingGlassIcon, { className: "w-6 h-6 text-column-gray-400" }) }), showOrganizationDropdown && (_jsx("div", Object.assign({ className: "z-20 absolute sm:w-auto md:w-128 lg:w-128", ref: organizationDropdownRef }, { children: _jsx(SearchedOrganizationsList, { organizationsList: organizationsList, selectedItems: organizationsToJoin, updateSelectedItems: (values) => {
                        onUpdateOrganizationsToJoin(values);
                        setShowOrganizationDropdown(false);
                        setSearchOrgText('');
                    }, onCreateNewOrganization: () => {
                        setShowOrganizationDropdown(false);
                        setSearchOrgText('');
                    }, loading: searchingOrganizations }) })))] })));
}
