import { InvoiceType } from 'lib/types/invoices';
export const getPreventLatePaymentValue = (payInvoiceData) => {
    let preventLatePayment = true;
    if (payInvoiceData.type === InvoiceType.PUBLIC_NOTICE) {
        preventLatePayment = payInvoiceData.preventLatePayment;
    }
    if (payInvoiceData.type === InvoiceType.ORDER) {
        preventLatePayment = false;
    }
    return preventLatePayment;
};
