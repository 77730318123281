var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'lib/components/Form';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { exists } from 'lib/types';
import React from 'react';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { getFirebaseContext } from 'utils/firebase';
import { CardGridLayout, GridInput, useEditableCard } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
const getBorderSettingsFromOrg = (organization) => {
    if (!exists(organization)) {
        return null;
    }
    const { linerBorder, configureNoticePDFs } = organization.data();
    return { linerBorder, configureNoticePDFs };
};
export function Borders() {
    var _a;
    const activeOrganization = useActiveOrganizationListener();
    const currentSettings = getBorderSettingsFromOrg(activeOrganization);
    const { onChange, onSubmitWrapper, disabled, formSettings, editable } = useEditableCard(currentSettings);
    if (!activeOrganization || !formSettings) {
        return null;
    }
    return (_jsx(Form, Object.assign({ id: "publisher-settings-layout-borders", onSubmit: () => onSubmitWrapper(() => __awaiter(this, void 0, void 0, function* () {
            yield activeOrganization.ref.update(replaceUndefinedWithDelete(getFirebaseContext(), formSettings));
            return { success: true };
        })), "aria-label": "Borders" }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'Borders',
                description: 'Add borders to your notices.'
            }, editable: editable }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Apply border to liner notices?", description: "This setting will add a black line around all text-based notices. This setting cannot be overridden by the InDesign template.", value: !!formSettings.linerBorder, onChange: linerBorder => onChange({ linerBorder }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Apply border to display notices?", description: "This setting will add a black line around all image-based notices. This setting cannot be overriden by the InDesign template.", value: !!((_a = formSettings.configureNoticePDFs) === null || _a === void 0 ? void 0 : _a.applyBorderToDisplay), onChange: applyBorderToDisplay => onChange({
                            configureNoticePDFs: Object.assign(Object.assign({}, formSettings.configureNoticePDFs), { applyBorderToDisplay })
                        }), disabled: disabled }) }))] })) })));
}
