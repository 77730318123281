import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { Dialog } from './Dialog';
import { Backdrop } from './Backdrop';
import { LoadingSpinner } from '../LoadingSpinner';
import { ModalContainer } from './ModalContainer';
import { Header } from './Header';
import { Section } from './Section';
import { Footer } from './Footer';
export function Modal({ id, title, subtitle, titleHidden = false, children, onClose, primaryAction, secondaryActions, loading, sectioned = true, size = 'lg', allowCloseOutsideModal = true, showCloseButton = true }) {
    const handleExit = () => {
        if (!allowCloseOutsideModal)
            return;
        onClose();
    };
    const titleId = `${id}-headline`;
    const hasFooterContent = Boolean(primaryAction || secondaryActions);
    const content = loading ? _jsx(LoadingSpinner, {}) : children;
    const bodyMarkup = sectioned && content ? _jsx(Section, { children: content }) : content;
    return (_jsxs(Dialog, Object.assign({ labelledBy: titleId, onClose: handleExit }, { children: [_jsxs(ModalContainer, Object.assign({ id: id, size: size }, { children: [_jsx(Header, { id: titleId, title: title, subtitle: subtitle, titleHidden: titleHidden, onClose: onClose, showCloseButton: showCloseButton }), _jsx("div", Object.assign({ className: classNames('overflow-auto divide-y', {
                            'border-b': hasFooterContent,
                            'border-t': !titleHidden
                        }) }, { children: bodyMarkup })), _jsx(Footer, { primaryAction: primaryAction, secondaryActions: secondaryActions, loading: loading, onClose: onClose })] })), _jsx(Backdrop, { onClick: handleExit })] })));
}
Modal.Section = Section;
