import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RateType } from 'lib/enums';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { isNoticeRate } from 'lib/types/rates';
import { DayRates } from './DayRates';
import { OffsetWithFlatFee } from './OffsetWithFlatFee';
import { BoldFees } from './BoldFees';
import { DistributeFees } from './DistributeFees';
const areOffsetsEnabled = (rate) => {
    if (rate.rateType === RateType.flat.value)
        return false;
    if (rate.rateType === RateType.nebraska.value)
        return false;
    if (rate.rateType === RateType.per_run.value)
        return false;
    return true;
};
export default function AdvancedSettingsCard({ onUpdateRateData, initialRateData, updatedRateData }) {
    var _a, _b;
    const offsetsEnabled = areOffsetsEnabled(updatedRateData);
    const isEditingNoticeRate = isNoticeRate(initialRateData) && isNoticeRate(updatedRateData);
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Advanced Settings',
            description: 'Take fine-grained control over how this rate is charged and appears to customers.'
        } }, { children: [isEditingNoticeRate && (_jsx(DayRates, { values: (_a = updatedRateData.dayRates) !== null && _a !== void 0 ? _a : null, initialValue: (_b = initialRateData.dayRates) !== null && _b !== void 0 ? _b : null, onChange: dayRates => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { dayRates })) })), offsetsEnabled && (_jsx(OffsetWithFlatFee, { value: updatedRateData, initialValue: initialRateData, onChange: changes => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), changes)) })), isEditingNoticeRate && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Hide prices during placement?", description: "If enabled, customers won't see expected prices while they create their notice.", value: Boolean(updatedRateData.hidePreview), onChange: val => {
                        onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { hidePreview: val }));
                    } }) }))), _jsx(BoldFees, { value: updatedRateData, initialValue: initialRateData, onChange: changes => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), changes)) }), isEditingNoticeRate && (_jsx(DistributeFees, { value: updatedRateData.distributeEnoticeFee, initialValue: initialRateData.distributeEnoticeFee, onChange: distributeEnoticeFee => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { distributeEnoticeFee })) }))] })));
}
