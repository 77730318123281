import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
/**
 * This is a higher order component that wraps a component with our App Frame
 */
export default function withFrame(Frame, Component) {
    return props => {
        return (_jsx(Frame, Object.assign({}, props, { children: _jsx(Component, Object.assign({}, props)) })));
    };
}
