import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import MadlibOverride from 'routes/placeScroll/NoticeContentStep/MadlibOverride';
import MadlibOverrideAlert from 'routes/placeScroll/NoticeContentStep/MadlibOverrideAlert';
function MadlibInfo({ isOverrideActive, product, isPublisher, onDisableMadlib }) {
    if (isOverrideActive) {
        return _jsx(MadlibOverrideAlert, { product: product, isPublisher: isPublisher });
    }
    if (isPublisher) {
        return _jsx(MadlibOverride, { product: product, onClick: onDisableMadlib });
    }
    return _jsx(_Fragment, {});
}
export default MadlibInfo;
