var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';
import TemporaryPasswordResetContaner from 'layouts/signInLayout/TemporaryPasswordResetContainer';
import { registerUser } from 'utils/users';
import ToastActions from 'redux/toast';
import AuthActions from 'redux/auth';
import firebase from 'firebase/app';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { getDefaultRegistrationDataFromInvite } from './registerUserHelpers';
import RegisterUserHeader from './RegisterUserHeader';
import RegisterUserFooter from './RegisterUserFooter';
import RegisterUserForm from './RegisterUserForm';
import AccountLinkingConfirmationModal from './AccountLinkingConfirmationModal';
export default function Register({ invite }) {
    const dispatch = useAppDispatch();
    const [registeringUser, setRegisteringUser] = useState(false);
    const [registrationData, setRegistrationData] = useState(getDefaultRegistrationDataFromInvite(invite));
    const [showTemporaryPasswordAlert, setShowTemporaryPasswordAlert] = useState(false);
    const [existingSignedinMethods, setExistingSignedinMethods] = useState([]);
    const handleRegistrationSuccess = (token) => {
        dispatch(AuthActions.loginToken(token));
        // Go to / and let our routingSaga take you to the correct place based on the invites associated with your account
        dispatch(push('/'));
    };
    const handleRegistrationFailure = (error, type) => {
        setRegisteringUser(false);
        switch (type) {
            case 'invalid user input':
                dispatch(ToastActions.toastError({
                    headerText: 'Error',
                    bodyText: error.message
                }));
                break;
            case 'user already exists with non-temporary password':
                dispatch(ToastActions.toastError({
                    headerText: 'Error',
                    bodyText: 'A user with this email already exists'
                }));
                break;
            case 'new user has temporary password':
                setShowTemporaryPasswordAlert(true);
                break;
            case 'unknown':
            default:
                dispatch(ToastActions.toastError({
                    headerText: 'Error',
                    bodyText: 'There was a problem processing your request, please check your connection, refresh and try again'
                }));
        }
    };
    const handleAccountLinkingWithOtherProviders = () => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        try {
            const credential = firebase.auth.EmailAuthProvider.credential(registrationData.email, registrationData.password);
            if (existingSignedinMethods[0] === 'google.com') {
                setExistingSignedinMethods([]);
                yield firebase
                    .auth()
                    .signInWithPopup(new firebase.auth.GoogleAuthProvider());
                if (credential && firebase.auth().currentUser) {
                    yield ((_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.linkWithCredential(credential));
                }
            }
            else if (existingSignedinMethods[0] === 'microsoft.com') {
                setExistingSignedinMethods([]);
                yield firebase
                    .auth()
                    .signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'));
                if (credential && firebase.auth().currentUser) {
                    yield ((_b = firebase.auth().currentUser) === null || _b === void 0 ? void 0 : _b.linkWithCredential(credential));
                    dispatch(push('/'));
                }
            }
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to link email/password account with other providers');
        }
    });
    const handleRegistration = () => __awaiter(this, void 0, void 0, function* () {
        var _c;
        setRegisteringUser(true);
        const { firstName, lastName, email, password } = registrationData;
        // Verify if other providers (Google/Microsoft) signed up with same email
        const existingProviders = yield firebase
            .auth()
            .fetchSignInMethodsForEmail(email);
        if (existingProviders.length && !existingProviders.includes('password')) {
            setExistingSignedinMethods(existingProviders);
            return;
        }
        const organizationId = (_c = invite === null || invite === void 0 ? void 0 : invite.data()) === null || _c === void 0 ? void 0 : _c.organizationId;
        const userRegistrationResponse = yield registerUser({
            organizationId,
            firstName,
            lastName,
            password,
            email
        });
        if (userRegistrationResponse.success) {
            handleRegistrationSuccess(userRegistrationResponse.token);
        }
        else {
            handleRegistrationFailure(userRegistrationResponse.error, userRegistrationResponse.type);
        }
    });
    return (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsx(RegisterUserHeader, {}), _jsx(RegisterUserForm, { onChangeRegistrationData: setRegistrationData, registrationData: registrationData, submitting: registeringUser, onSubmit: handleRegistration, invite: invite === null || invite === void 0 ? void 0 : invite.data() }), showTemporaryPasswordAlert && (_jsx(TemporaryPasswordResetContaner, { email: registrationData.email })), !!existingSignedinMethods.length && (_jsx(AccountLinkingConfirmationModal, { onClose: () => {
                    setExistingSignedinMethods([]);
                    setRegisteringUser(false);
                }, onClickConfirmLinking: handleAccountLinkingWithOtherProviders })), _jsx(RegisterUserFooter, {})] })));
}
