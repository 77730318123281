import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import MultiStepHeader from '../components/MultiStepHeader';
export const MINIMUM_ORDER = {
    contactEmail: '',
    firstName: '',
    lastName: '',
    phone: ''
};
function PersonalDetail({ inputData, setInputData }) {
    const { firstName = '', lastName = '', contactEmail = '', phone = '' } = Object.assign(Object.assign({}, MINIMUM_ORDER), inputData);
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepHeader, { title: "Customer Details", description: "Please provide contact information." }), _jsxs("div", Object.assign({ className: "grid md:grid-cols-2 gap-3 mb-8" }, { children: [_jsx(TextField, { id: "first-name", value: firstName, type: "text", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { firstName: value })), onBlur: () => setInputData(Object.assign(Object.assign({}, inputData), { firstName: firstName.trim() })), labelText: "First name", required: true }), _jsx(TextField, { id: "last-name", value: lastName, type: "text", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { lastName: value })), onBlur: () => setInputData(Object.assign(Object.assign({}, inputData), { lastName: lastName.trim() })), labelText: "Last name", required: true }), _jsx("div", Object.assign({ className: "md:col-span-2" }, { children: _jsx(TextField, { id: "email", value: contactEmail, type: "email", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { contactEmail: value })), labelText: "Email address", required: true }) })), _jsx("div", Object.assign({ className: "md:col-span-2" }, { children: _jsx(TextField, { id: "phone", value: phone, type: "tel", onChange: value => setInputData(Object.assign(Object.assign({}, inputData), { phone: value })), labelText: "Phone number", required: true }) }))] }))] }));
}
export default PersonalDetail;
