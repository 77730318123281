import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import Drawer from 'lib/components/Drawer';
import SubscriptionDrawerHeader from './SubscriptionDrawerHeader';
import SubscriptionDrawerBody from './SubscriptionDrawerBody';
/**
 * Drawer component for seeing details on a particular Subscription
 */
export default function SubscriptionDrawer({ setEditedSubscription, drawerSubscription, closeDrawer }) {
    const [subscription, setSubscription] = useState();
    useEffect(() => {
        const unsub = drawerSubscription.ref.onSnapshot(subscription => {
            if (exists(subscription))
                setSubscription(subscription);
        });
        return () => unsub();
    }, [drawerSubscription.id]);
    return (_jsx(Drawer, Object.assign({ onClose: () => closeDrawer(), header: _jsx(SubscriptionDrawerHeader, { subscription: subscription || drawerSubscription }), open: true }, { children: subscription ? (_jsx(SubscriptionDrawerBody, { setEditedSubscription: editedSubscription => {
                setEditedSubscription(Object.assign(Object.assign({}, editedSubscription.data()), { id: editedSubscription.id }));
            }, subscription: subscription })) : (_jsx(LoadingState, {})) })));
}
