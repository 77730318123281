import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { OrganizationType } from 'lib/enums';
import { HomeIcon, CurrencyDollarIcon, UsersIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import OrganizationMemberSettings from './OrganizationMemberSettings';
import UserSettingsBankAccounts from './userSettings/BankAccountSettings';
import PublisherSettings from './userSettings/publisherSettings/index';
export const GENERAL_INFO = 'general-info';
export const ORGANIZATION_MEMBER_SETTINGS = 'members';
export const BANK_ACCOUNTS = 'bank-accounts';
export const PUBLISHER_SETTINGS = 'publisher-settings';
function AdvertiserOrganizationSettings({ activeOrganization, user, infoSettingsName, infoSettingsTab }) {
    const [view, setView] = useState(getLocationParams().get('tab') ||
        (userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO)
            ? GENERAL_INFO
            : ORGANIZATION_MEMBER_SETTINGS));
    const updateView = (view) => {
        window.history.replaceState(null, '', `/settings/organization?tab=${view}`);
        setView(view);
    };
    const renderTab = (tab, tabLabel, icon) => (_jsxs("div", Object.assign({ className: `flex items-center rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${view === tab && 'bg-gray-200'}`, onClick: () => updateView(tab), id: tab }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-3/12" }, { children: icon })), _jsx("div", Object.assign({ className: "w-9/12" }, { children: tabLabel }))] })));
    // We only show bank accounts in the organization settings for advertisers
    // that have the Admin or Billing role in their organization.
    const hasBillingSettingsPermission = userHasPermission(user, Permissions.BANK_ACCOUNTS_WRITE);
    const isFuneralHome = activeOrganization.data().organizationType ===
        OrganizationType.funeral_home.value;
    const showPublisherSettingsTab = !isFuneralHome;
    const showBankAccountsTab = !isFuneralHome && hasBillingSettingsPermission;
    const tabIconStyle = 'h-6 w-6';
    const renderSideBar = () => (_jsxs("div", Object.assign({ className: "bg-white rounded border border-gray-300 pt-3 px-4", style: {
            height: 'calc(100vh - 150px)'
        } }, { children: [userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) &&
                renderTab(GENERAL_INFO, infoSettingsName, _jsx(HomeIcon, { className: tabIconStyle })), renderTab(ORGANIZATION_MEMBER_SETTINGS, 'Members', _jsx(UsersIcon, { className: tabIconStyle })), showBankAccountsTab &&
                renderTab(BANK_ACCOUNTS, 'Bank Accounts', _jsx(CurrencyDollarIcon, { className: tabIconStyle })), showPublisherSettingsTab &&
                renderTab(PUBLISHER_SETTINGS, 'Publisher Settings', _jsx(NewspaperIcon, { className: tabIconStyle }))] })));
    const tabs = {
        [GENERAL_INFO]: infoSettingsTab({ activeOrganization }),
        [ORGANIZATION_MEMBER_SETTINGS]: (_jsx(OrganizationMemberSettings, { activeOrganization: activeOrganization, user: user })),
        [PUBLISHER_SETTINGS]: _jsx(PublisherSettings, { user: user })
    };
    if (showBankAccountsTab) {
        tabs[BANK_ACCOUNTS] = _jsx(UserSettingsBankAccounts, { user: user });
    }
    return (_jsxs("div", Object.assign({ className: "flex mt-4" }, { children: [renderSideBar(), _jsx("main", Object.assign({ className: "flex-grow px-4" }, { children: tabs[view] }))] })));
}
export default AdvertiserOrganizationSettings;
