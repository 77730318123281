import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { cdnIfy } from 'lib/helpers';
import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import NoticeFilePreview from 'components/noticePreview/NoticeFilePreview';
import { placementSelector, selectPreviewContent, selectShowPlacementErrors } from 'redux/placement';
import { isNoticeContentData } from 'lib/types/notice';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import NoticePreviewContainer from './NoticePreviewContainer';
import { selectCurrentlySelectedNoticeType } from './placeScrollSelectors';
export const ERRORS = {
    RESIZING_NOTICE: (_jsx("p", { children: "Your content is too wide for the selected number of columns. Finding optimal column width." })),
    RESIZING_NOTICE_V2: (_jsx("p", { children: "Your content is too wide for the selected number of columns. Try to increase column width to fit the notice content." })),
    GENERIC: (_jsxs("p", { children: ["Whoops, we're having trouble loading the preview. Please refresh the page.", ' ', _jsx("br", {}), " If that doesn't help email help@column.us and we will assist you!"] })),
    TOO_WIDE: (_jsx("p", { children: "Your content is too wide for the selected column width. We recommend adjusting the original file if you want to reduce the column width." })),
    WAIT_REQUEST: `We're sorry for the wait. This notice is taking longer than usual to generate. If you'd like, you can submit this notice without waiting for notice formatting. Click here to continue without a generated notice.`
};
export const showConvertToLinerWarning = (displayOnlyAds, isDisplay, showDisplayError, postWithoutFormatting, disableDisplay) => {
    /**
     * When we upload a display ad, we want to show a warning
     * stating that it will be cheaper to convert to liner if
     * the newspaper has linerAds, the ad is a display, we should show
     * the display error, we can post with formatting, and display ads are
     * not disabled.
     */
    return (!displayOnlyAds &&
        isDisplay &&
        showDisplayError &&
        !postWithoutFormatting &&
        !disableDisplay);
};
export default function PlaceNoticePreview({ rate, newspaper, uploading, loading }) {
    var _a, _b, _c, _d;
    const placement = useAppSelector(placementSelector);
    const previewContent = useAppSelector(selectPreviewContent);
    const [openModal, setOpenModal] = useState(false);
    const { wait: showWaitWarning } = useAppSelector(selectShowPlacementErrors);
    const selectedNoticeType = useAppSelector(state => selectCurrentlySelectedNoticeType(state, newspaper));
    const isTypeform = !!(selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.isTypeform);
    const madlibConfigPath = (selectedNoticeType === null || selectedNoticeType === void 0 ? void 0 : selectedNoticeType.madlib) || '';
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const displayJPGUrl = placement.processedDisplay && placement.displayUrl
        ? cdnIfy(placement.pdfStoragePath || placement.displayUrl, {
            cloudinaryTransformations: 'f_jpg',
            useColumnCDN
        })
        : '';
    const displayPDFUrl = placement.processedDisplay && placement.displayUrl
        ? cdnIfy(placement.pdfStoragePath || placement.displayUrl, {
            cloudinaryTransformations: 'f_pdf',
            useColumnCDN
        })
        : '';
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "z-10 pb-10 px-12", id: "place-preview" }, { children: [_jsx("h2", Object.assign({ className: "text-column-gray-800 text-xl font-semibold" }, { children: "Preview" })), placement.postWithoutFormatting &&
                        ((_a = placement.filesToAttach) === null || _a === void 0 ? void 0 : _a.length) &&
                        placement.filesToAttach.some(fileToAttach => isNoticeContentData(fileToAttach)) ? (_jsx(_Fragment, { children: placement.filesToAttach
                            .filter(fileToAttach => isNoticeContentData(fileToAttach))
                            .map((noticeFile, index) => (_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(NoticeFilePreview, { noticeFile: noticeFile, initialShowWarning: index === 0 }) }), `${noticeFile.originalFileName}-notice-file-preview`))) })) : (_jsx("div", Object.assign({ className: "mt-4" }, { children: _jsx(NoticePreviewContainer, { loading: loading || !!showWaitWarning, openModal: () => setOpenModal(true), isTypeform: isTypeform, madlibConfigPath: madlibConfigPath, uploading: uploading, displayJPGUrl: displayJPGUrl, displayPDFUrl: displayPDFUrl, rate: rate, newspaper: newspaper }) })))] })), openModal && (_jsx(ImagePreviewModal, { jpgURLs: displayJPGUrl
                    ? [displayJPGUrl]
                    : ((_c = (_b = previewContent.displayParams) === null || _b === void 0 ? void 0 : _b.imgs) === null || _c === void 0 ? void 0 : _c.length)
                        ? (_d = previewContent.displayParams) === null || _d === void 0 ? void 0 : _d.imgs
                        : [], setOpenModal: setOpenModal }))] }));
}
