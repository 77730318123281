import moment from 'moment';
import { InvoiceStatus } from 'lib/enums';
import { exists } from 'lib/types';
import { isPublisherOrganization } from 'lib/utils/organizations';
export const ALL_INVOICES_STATUS = 'all';
export const PAID_INVOICES_STATUS = 'paid';
export const UNPAID_INVOICES_STATUS = 'unpaid';
export const REFUND_INVOICES_STATUS = 'refund';
export const VOIDED_INVOICES_STATUS = 'voided';
export const PAST_DUE_INVOICES_STATUS = 'past-due';
export const INVOICE_STATUSE_OPTIONS = [
    { label: 'All', value: ALL_INVOICES_STATUS },
    { label: 'Past Due', value: PAST_DUE_INVOICES_STATUS },
    { label: 'Unpaid', value: UNPAID_INVOICES_STATUS },
    { label: 'Paid', value: PAID_INVOICES_STATUS },
    { label: 'Voided', value: VOIDED_INVOICES_STATUS },
    { label: 'Refund', value: REFUND_INVOICES_STATUS }
];
export const ALL_INVOICE_CREATION_DATES = 'all-time';
export const THIS_MONTH_INVOICE_CREATION_DATES = 'this-month';
export const LAST_MONTH_INVOICE_CREATION_DATES = 'last-month';
export const CUSTOM_INVOICE_CREATION_DATES = 'custom-date';
export const INVOICE_CREATION_DATE_OPTIONS = [
    { label: 'All time', value: ALL_INVOICE_CREATION_DATES },
    {
        label: `This month - ${moment()
            .startOf('month')
            .format('MMMM D')} to ${moment().format('MMMM D')}`,
        value: THIS_MONTH_INVOICE_CREATION_DATES
    },
    {
        label: `Last month - ${moment().subtract(1, 'month').format('MMMM')}`,
        value: LAST_MONTH_INVOICE_CREATION_DATES
    },
    { label: 'Custom date', value: CUSTOM_INVOICE_CREATION_DATES }
];
export const ALL_INVOICES_INVOICE_TYPE = 'all';
export const BULK_INVOICES_INVOICE_TYPE = 'bulk';
export const SINGLE_INVOICES_INVOICE_TYPE = 'single';
export const DEFAULT_INVOICE_STATUS_FILTER = {
    invoiceTableStatus: ALL_INVOICES_STATUS,
    dateRange: ALL_INVOICE_CREATION_DATES,
    bulkInvoiceFilter: 'all'
};
/**
 * Filters out the set of invoices based on the search criteria
 * @param invoice current invoice
 * @param invoiceFilter current filter state
 * @param search current search string
 * @returns {boolean} true if the invoice should be included in the results else false
 */
export const filterInvoices = (invoice, invoiceFilter, search) => {
    const searchLowerCase = search.toLowerCase();
    // filter out using the search string. Importantly, all strings in JS vacuously match ""
    if (!invoice.invoicenumber.toLowerCase().includes(searchLowerCase) &&
        !invoice.filername.toLowerCase().includes(searchLowerCase) &&
        !invoice.filerorganizationname.toLowerCase().includes(searchLowerCase)) {
        return false;
    }
    const invoiceOverdue = moment().isAfter(moment(invoice.formattedduedate, 'MM/DD/YYYY')) &&
        ![
            InvoiceStatus.paid.value,
            InvoiceStatus.partially_refunded.value,
            InvoiceStatus.refunded.value,
            InvoiceStatus.initiated.value
        ].includes(invoice.status) &&
        !invoice.voided;
    if (invoiceFilter.invoiceTableStatus === PAID_INVOICES_STATUS) {
        if (invoice.status !== InvoiceStatus.paid.value) {
            return false;
        }
    }
    if (invoiceFilter.invoiceTableStatus === UNPAID_INVOICES_STATUS) {
        if (invoice.status !== InvoiceStatus.unpaid.value) {
            return false;
        }
        if (invoiceOverdue) {
            return false;
        }
    }
    if (invoiceFilter.invoiceTableStatus === REFUND_INVOICES_STATUS) {
        if (invoice.status !== InvoiceStatus.refunded.value) {
            return false;
        }
    }
    if (invoiceFilter.invoiceTableStatus === VOIDED_INVOICES_STATUS) {
        return !!invoice.voided;
    }
    if (invoiceFilter.invoiceTableStatus === PAST_DUE_INVOICES_STATUS) {
        if (!invoiceOverdue) {
            return false;
        }
    }
    // filter out based on date range
    const createdDate = moment(invoice.created);
    if (invoiceFilter.dateRange === ALL_INVOICE_CREATION_DATES) {
        return true;
    }
    if (invoiceFilter.dateRange === THIS_MONTH_INVOICE_CREATION_DATES) {
        if (moment(createdDate).isBefore(moment().startOf('month'))) {
            return false;
        }
    }
    else if (invoiceFilter.dateRange === LAST_MONTH_INVOICE_CREATION_DATES) {
        if (moment(createdDate).isBefore(moment().subtract(1, 'month').startOf('month'))) {
            return false;
        }
        if (moment(createdDate).isAfter(moment().subtract(1, 'month').endOf('month'))) {
            return false;
        }
    }
    else {
        if (typeof invoiceFilter.dateRange !== 'string' &&
            moment(createdDate).isBefore(invoiceFilter.dateRange.start, 'day')) {
            return false;
        }
        if (typeof invoiceFilter.dateRange !== 'string' &&
            moment(createdDate).isAfter(invoiceFilter.dateRange.end, 'day')) {
            return false;
        }
    }
    return true;
};
/**
 * This function constructs the Elastic filters given the table filter and the user configuration
 */
export const getElasticFilters = (user, organization, invoiceFilter) => {
    const allFilters = [
        { invoiceoutsidecolumn: [Number(false)] }
    ];
    // filter to only include or not include bulk invoices as specified in filters
    if (invoiceFilter.bulkInvoiceFilter === 'bulk') {
        allFilters.push({
            isbulkinvoice: [Number(true)]
        });
    }
    else if (invoiceFilter.bulkInvoiceFilter === 'single') {
        allFilters.push({
            isbulkinvoice: [Number(false)]
        });
    }
    // Add filters to fetch invoices related to the current user/active org
    if (exists(organization)) {
        if (isPublisherOrganization(organization)) {
            allFilters.push({ publisherid: [organization.id] });
        }
        else {
            allFilters.push({ filerorganizationid: [organization.id] });
        }
    }
    else {
        allFilters.push({ filerid: [user.data().shadowUser || user.id] });
    }
    // Add filters related to invoice status
    switch (invoiceFilter.invoiceTableStatus) {
        case PAID_INVOICES_STATUS:
            allFilters.push({ status: [InvoiceStatus.paid.value] });
            break;
        case REFUND_INVOICES_STATUS:
            allFilters.push({
                status: [
                    InvoiceStatus.refunded.value,
                    InvoiceStatus.partially_refunded.value
                ]
            });
            break;
        case UNPAID_INVOICES_STATUS:
            allFilters.push({ status: [InvoiceStatus.unpaid.value] });
            allFilters.push({ voided: [Number(false)] });
            break;
        case VOIDED_INVOICES_STATUS:
            allFilters.push({ voided: [Number(true)] });
            break;
        case PAST_DUE_INVOICES_STATUS:
            allFilters.push({
                duedate: { to: moment().utc().valueOf() }
            });
            allFilters.push({ status: [InvoiceStatus.unpaid.value] });
            break;
        default:
            break;
    }
    // Add filters related to invoice creation date
    if (invoiceFilter.dateRange === THIS_MONTH_INVOICE_CREATION_DATES) {
        allFilters.push({
            created: { from: moment().utc().startOf('month').valueOf() }
        });
    }
    else if (invoiceFilter.dateRange === LAST_MONTH_INVOICE_CREATION_DATES) {
        allFilters.push({
            created: {
                from: moment().utc().subtract(1, 'month').startOf('month').valueOf(),
                to: moment().utc().startOf('month').valueOf()
            }
        });
    }
    else if (typeof invoiceFilter.dateRange !== 'string') {
        allFilters.push({
            created: {
                from: moment
                    .utc(invoiceFilter.dateRange.start)
                    .startOf('day')
                    .valueOf(),
                to: moment.utc(invoiceFilter.dateRange.end).endOf('day').valueOf()
            }
        });
    }
    return allFilters;
};
export const getInvoiceStatus = (status, due_date, voided_at, amount) => {
    var _a;
    // in case of invoice has no line items or amount is 0
    if (!amount) {
        return InvoiceStatus.paid.label;
    }
    if (voided_at) {
        return 'Void';
    }
    if (![
        InvoiceStatus.paid.value,
        InvoiceStatus.partially_refunded.value,
        InvoiceStatus.refunded.value
    ].includes(status) &&
        moment.utc().unix() > due_date)
        return 'Past Due';
    return (_a = InvoiceStatus.by_value(status)) === null || _a === void 0 ? void 0 : _a.label;
};
