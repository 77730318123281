var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RoleType } from '../enums';
import { BASE_ROLES, DEFAULT_ALLOWED_ROLES_ADVERTISER, DEFAULT_ALLOWED_ROLES_PUBLISHER } from '../permissions/roles';
import { exists } from '../types';
import { isPublisherOrganization } from './organizations';
/**
 * Get the roles which can be assigned to members of a given org.
 */
export const getAllowedRolesForOrg = (org) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const isPublisher = isPublisherOrganization(org);
    const defaultRoles = isPublisher
        ? DEFAULT_ALLOWED_ROLES_PUBLISHER
        : DEFAULT_ALLOWED_ROLES_ADVERTISER;
    if (!exists(org)) {
        return defaultRoles;
    }
    const { allowedRoles } = org.data();
    if (allowedRoles) {
        return [...BASE_ROLES, ...allowedRoles];
    }
    const parent = yield ((_a = org.data().parent) === null || _a === void 0 ? void 0 : _a.get());
    const parentAllowedRoles = (_b = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _b === void 0 ? void 0 : _b.allowedRoles;
    if (parentAllowedRoles) {
        return [...BASE_ROLES, ...parentAllowedRoles];
    }
    return defaultRoles;
});
/**
 * Filter roles which are suitable for invites to a given organization.
 */
export const getInviteRoleOptions = (publisherOrganization, allowedRoles) => {
    return RoleType.items()
        .filter(role => role.canInvite)
        .filter(role => publisherOrganization || !role.publisherOnly)
        .filter(role => allowedRoles.includes(role.value));
};
