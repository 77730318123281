var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Alert } from 'lib/components/Alert';
import { isEmpty } from 'lodash';
import { FilingTypeVisibility, Product } from 'lib/enums';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { getFirebaseContext } from 'utils/firebase';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import LoadingState from 'components/LoadingState';
import SelectSchedule from './SelectSchedule';
import { NewspapersContext } from '../../contexts/NewspapersContext';
export const getValidFilingTypes = (filingTypes, isPublisher) => {
    return filingTypes === null || filingTypes === void 0 ? void 0 : filingTypes.filter(filingType => {
        // Filing type must have a rate
        if (!filingType.modelData.rate) {
            return false;
        }
        // Filing type must not be disabled
        if (filingType.modelData.visibility === FilingTypeVisibility.disabled.value) {
            return false;
        }
        if (!isPublisher &&
            filingType.modelData.visibility ===
                FilingTypeVisibility.publisher_only.value) {
            return false;
        }
        return true;
    });
};
function PerPublisherSettings({ product, newspaperOrder, isPublisher, onNewspaperOrderChange, onFilingTypeChange, validateFilingTypeSelection, isInitialPlacementFlow, disableEdits, orderModel }) {
    var _a;
    const newspapers = useContext(NewspapersContext);
    const newspaperSnap = newspapers.find(snap => { var _a; return snap.id === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
    const [userError, setUserError] = useState('');
    const publishingSettingService = new ProductPublishingSettingsService(getFirebaseContext());
    const { value: detailedPublishingSetting, isLoading: detailedPublishingSettingLoading } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            return yield publishingSettingService.fetchOrCreateDetailedProductPublishingSetting(newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.ref, product, newspaperOrder.publishingMedium, {
                shouldCreate: false
            });
        }),
        initialData: null,
        dependencies: [newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id, newspaperOrder.publishingMedium]
    });
    const isLoading = detailedPublishingSettingLoading;
    if (isLoading || !detailedPublishingSetting) {
        return (_jsx(LoadingState, { context: {
                service: ColumnService.OBITS,
                location: 'Ad placement - PerPublisherSettings',
                tags: {
                    newspaperId: (_a = newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.id) !== null && _a !== void 0 ? _a : 'unknown',
                    product,
                    adPlacementFlow: 'true',
                    detailedPublishingSettingLoading: detailedPublishingSettingLoading
                        ? 'true'
                        : 'false',
                    detailedPublishingSetting: 'null',
                    orderId: orderModel.id
                }
            } }));
    }
    const { filingTypes, publishingSetting } = detailedPublishingSetting;
    const selectedFilingType = filingTypes.find(filingType => { var _a; return filingType.ref.id === ((_a = newspaperOrder.filingType) === null || _a === void 0 ? void 0 : _a.id); });
    const handleFilingTypeChange = (label) => {
        const newFilingType = filingTypes === null || filingTypes === void 0 ? void 0 : filingTypes.find(filingType => filingType.modelData.label === label);
        if (!newFilingType) {
            const err = new NotFoundError('Filing type not found in publishingSettings from label');
            logAndCaptureCriticalError(ColumnService.OBITS, err, 'failed to handleFilingTypeChange', {
                publishingSettingId: publishingSetting.id,
                label
            });
            setUserError('Failed to find filing type.  Please refresh the page.');
            return;
        }
        // We need to set the layout early here so our newspaperOrder is in a complete state to save to db
        const layout = newFilingType.supportedLayouts[0];
        onNewspaperOrderChange(Object.assign(Object.assign({}, newspaperOrder), { filingType: newFilingType.ref, layout }));
        // TODO: Change this when we move to a single filing type selection for an order
        onFilingTypeChange(label);
    };
    if (!newspaperSnap) {
        return (_jsx(Alert, { id: "missing-newspaper", title: "Error", description: "Missing newspaper" }));
    }
    if (!publishingSetting) {
        return (_jsx(Alert, { id: "missing-publishing-setting", title: "Error", description: `Could not retrieve publishing setting` }));
    }
    const validFilingTypes = getValidFilingTypes(filingTypes, isPublisher);
    if (!validFilingTypes || isEmpty(validFilingTypes)) {
        const message = 'No valid filing types found for newspaper order in placement/edit flow';
        logAndCaptureCriticalError(ColumnService.OBITS, new NotFoundError(message), message, {
            publishingSettingId: publishingSetting.id
        });
        return (_jsx(Alert, { id: "missing-filing-types", title: "Error", description: `${newspaperSnap.data().name} does not have any valid ${product} categories enabled. Please select a different newspaper.` }));
    }
    /**
     * Category is selected at an earlier step for classifieds
     * Also, we should not allow the filing type on an order to change post submission
     * as it is an immutable part of an order (see https://columnpbc.slack.com/archives/C063V00UK6W/p1720703737591309)
     */
    const showFilingTypeSelector = isInitialPlacementFlow && product === Product.Obituary;
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-y-3" }, { children: [userError && (_jsx(Alert, { id: "user-error", title: "Error", description: userError })), _jsx("div", { children: _jsx(SelectSchedule, { newspaperOrder: newspaperOrder, newspaper: newspaperSnap, onNewspaperOrderChange: onNewspaperOrderChange, publishingSetting: publishingSetting, product: product, disableEdits: disableEdits }) }), showFilingTypeSelector && (_jsx(ColumnSelect, { id: "publication-category", labelText: "Publication category", value: selectedFilingType === null || selectedFilingType === void 0 ? void 0 : selectedFilingType.modelData.label, options: validFilingTypes.map(filingType => ({
                    label: filingType.modelData.label,
                    value: filingType.modelData.label
                })), onChange: handleFilingTypeChange, allowUndefined: true, required: true, checkDependentValidations: validateFilingTypeSelection, disabled: disableEdits }))] })));
}
export default PerPublisherSettings;
