var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import api from 'api';
import { FirebaseAuth } from 'EnoticeFirebase';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
export default function ChangeEmailModal({ userAuth, user, onCloseChangeEmailModal }) {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isPasswordValidated, setIsPasswordValidated] = useState(false);
    const [changeEmailRequestFailedError, setChangeEmailRequestFailedError] = useState('');
    const onSubmitVerifyPassword = () => __awaiter(this, void 0, void 0, function* () {
        const credentials = FirebaseAuth.EmailAuthProvider.credential(user.data().email, password);
        try {
            yield userAuth.reauthenticateWithCredential(credentials);
            setIsPasswordValidated(true);
        }
        catch (err) {
            const error = err;
            if (error.code === 'auth/wrong-password') {
                setError(error.message);
            }
            else {
                logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error in verifying password', {
                    uid: user.id
                });
                setError('There was an issue verifying your password, please try again later.');
            }
            setPassword('');
        }
    });
    const onSubmitChangeEmail = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const { success, error } = yield api.post('users/send-change-account-email-link', {
            email
        });
        if (success) {
            onCloseChangeEmailModal();
            dispatch(ToastActions.toastSuccess({
                headerText: 'Email sent',
                bodyText: `A confirmation email has been sent to ${email}. The link to confirm will be active for 2 hours.`
            }));
        }
        if (error) {
            const errorMsg = error === 'User already exists'
                ? `It looks like this email address already exists in the system. If you would like to move notices from ${user.data().email} to ${email}. please contact us at help@column.us.`
                : 'Something went wrong while sending change email request.';
            setChangeEmailRequestFailedError(errorMsg);
            setEmail('');
        }
        setLoading(false);
    });
    return (_jsxs(Modal, Object.assign({ id: "change-email-confirmation", title: isPasswordValidated
            ? 'Confirm new email address?'
            : 'Please enter your password', subtitle: !isPasswordValidated &&
            'To ensure your security please enter your current password to continue changing your email.', allowCloseOutsideModal: false, onClose: onCloseChangeEmailModal }, { children: [!isPasswordValidated && (_jsx(Form, Object.assign({ onSubmit: onSubmitVerifyPassword }, { children: _jsxs("div", Object.assign({ className: "grid space-y-6" }, { children: [_jsx(TextField, { id: "password", required: true, labelText: "Password", placeholder: "******", value: password, type: "password", onChange: password => {
                                if (error) {
                                    setError('');
                                }
                                setPassword(password);
                            }, errorText: error }), _jsx(ColumnButton, { id: "verify-password", buttonText: 'Submit', type: "submit", primary: true })] })) }))), isPasswordValidated && (_jsx(Form, Object.assign({ onSubmit: onSubmitChangeEmail }, { children: _jsxs("div", Object.assign({ className: "grid space-y-6" }, { children: [_jsx("p", Object.assign({ className: "text-column-gray-400" }, { children: "Please enter your new email address so we can send a verification email to ensure you have access to the account." })), changeEmailRequestFailedError && (_jsx(Alert, Object.assign({ status: "error", id: "change-email-request-failed-alert", icon: _jsx(InformationCircleIcon, { className: "w-4" }) }, { children: changeEmailRequestFailedError }))), _jsx(TextField, { id: "new-email", type: "email", required: true, labelText: "New Email", placeholder: "abc@example.com", value: email, onChange: email => {
                                if (changeEmailRequestFailedError) {
                                    setChangeEmailRequestFailedError('');
                                }
                                setEmail(email);
                            } }), _jsx(ColumnButton, { id: "confirm-change-email", buttonText: 'Confirm', type: "submit", primary: true, loading: loading })] })) })))] })));
}
