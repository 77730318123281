import { NoticeTypeData } from 'lib/enums/NoticeType';
import { disableNonPublishingDays, getIsAfterPublishingDeadline, isAllowedPublicationDay, publishingDayEnumValuesFromDeadlines } from 'lib/utils/deadlines';
/**
 * Checks if the given date should be disabled in the confirm schedule step
 */
export const shouldDisablePublishingDate = ({ day, publishingSetting, newspaper }) => {
    var _a;
    const { iana_timezone } = newspaper.data();
    const { deadlines, deadlineOverrides = {} } = (_a = publishingSetting.modelData) !== null && _a !== void 0 ? _a : {};
    if (!deadlines || !day || !iana_timezone) {
        return true;
    }
    const isNonPublishingDay = disableNonPublishingDays(day, publishingDayEnumValuesFromDeadlines(deadlines), deadlineOverrides);
    const adTypeData = {
        noticeType: NoticeTypeData.other.value
    };
    const isAfterDeadline = getIsAfterPublishingDeadline(day, deadlines, deadlineOverrides, iana_timezone, adTypeData, newspaper);
    return (isAfterDeadline || !isAllowedPublicationDay(day, null) || isNonPublishingDay);
};
