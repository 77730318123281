import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect, enumKeyToSelectInput } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { OrganizationStatus } from 'lib/enums';
import React from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import OrderNumberSettings from './OrderNumberSettings';
export default function ImplementationInfo({ value, onChange }) {
    var _a, _b;
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: 'Implementation-Only Settings' } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "publisher-status", labelText: "Publisher status", value: value.organizationStatus.toString(), onChange: newVal => onChange(Object.assign(Object.assign({}, value), { organizationStatus: Number(newVal) })), options: OrganizationStatus.items()
                        .filter(item => item.value !== OrganizationStatus.not_applicable.value)
                        .map(enumKeyToSelectInput) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Disable publication?", description: "If disabled, the newspaper will not appear in the placement flow for customers.", value: !!value.disabled, onChange: disabled => onChange(Object.assign(Object.assign({}, value), { disabled })) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Always show in placement flow?", description: "If enabled, this publication will appear in the placement flow even if missing affidavit or template settings.", value: !!value.alwaysShowInPlacementFlow, onChange: alwaysShowInPlacementFlow => onChange(Object.assign(Object.assign({}, value), { alwaysShowInPlacementFlow })) }) })), _jsxs(SwitchControlledCard, Object.assign({ labelProps: {
                    label: 'Auto-generate order numbers?',
                    description: 'If enabled, Column will automatically apply order numbers to notices in this publication.',
                    value: !!value.shouldAutoGenOrderNums,
                    onChange: shouldAutoGenOrderNums => onChange(Object.assign(Object.assign({}, value), { shouldAutoGenOrderNums }))
                }, header: "order number settings" }, { children: [_jsx(OrderNumberSettings, { value: value.orderNumberGeneration, onChange: orderNumberGeneration => onChange(Object.assign(Object.assign({}, value), { orderNumberGeneration })) }), _jsx(GridInput, { children: _jsx(TextField, { id: "order-number-next-order", labelText: "Next order number", type: "number", min: 1, value: (_b = (_a = value.numberOfOrders) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '1', onChange: newValue => onChange(Object.assign(Object.assign({}, value), { numberOfOrders: Number(newValue) })) }) })] }))] })));
}
