var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';
import { createDBPricingObjectFromDataAndPublicationLineItems } from 'lib/pricing';
import { getFirebaseContext } from 'utils/firebase';
import { BillingService } from 'services';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import FullScreenModal from 'components/FullScreenModal';
import { getOrThrow } from 'lib/utils/refs';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import { getAffidavitPricingData } from 'lib/pricing/affidavits';
import { getActiveDiscountConfigForNotice } from 'lib/notice/discounts';
import { Product } from 'lib/enums';
import InvoicePreviewCard from './InvoicePreviewCard';
import InvoiceDetailsCard from './InvoiceDetailsCard';
import { getInitialInvoiceCreationDataFromNotice, getInvoiceDataInvalidReason } from './createInvoiceUtils';
const createInvoice = (currentPublisherUser, notice, invoiceCreationData) => __awaiter(void 0, void 0, void 0, function* () {
    const { lineItems } = invoiceCreationData;
    const publisher = yield getOrThrow(notice.data().newspaper);
    const rate = yield getOrThrow(notice.data().rate);
    const ctx = getFirebaseContext();
    const affidavitPricingData = yield getAffidavitPricingData(ctx, notice);
    const discountConfig = yield getActiveDiscountConfigForNotice(ctx, notice.data());
    // TODO: This should probably use calculateInvoicePricing since
    //       we are in the invoicing flow.
    const dbPricing = createDBPricingObjectFromDataAndPublicationLineItems(Product.Notice, notice.data(), publisher.data(), rate.data(), lineItems, affidavitPricingData, discountConfig);
    const advertiserSnap = yield getOrThrow(notice.data().filer);
    yield BillingService.invoiceAdvertiser({
        publisherAmountInCents: dbPricing.subtotal,
        lineItems,
        noticeSnap: notice,
        newspaperSnap: publisher,
        advertiserSnap,
        user: currentPublisherUser,
        inAppTaxPct: dbPricing.taxPct
    });
});
export default function CreateInvoice() {
    const [invoiceCreationData, setInvoiceCreationData] = useState();
    const [notice, setNotice] = useState();
    const [rate, setRate] = useState();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    useEffect(() => {
        const setInitialData = () => __awaiter(this, void 0, void 0, function* () {
            const path = matchPath(window.location.pathname, {
                path: `/notice/:noticeID/invoice/create`
            });
            if (!path)
                return;
            const notice = yield getFirebaseContext()
                .userNoticesRef()
                .doc(path.params.noticeID)
                .get();
            if (!exists(notice))
                return;
            setNotice(notice);
            const rate = yield getOrThrow(notice.data().rate);
            setRate(rate);
        });
        void setInitialData();
    }, []);
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            if (!notice)
                return;
            const invoiceData = yield getInitialInvoiceCreationDataFromNotice(notice);
            setInvoiceCreationData(invoiceData);
        }))();
    }, [notice === null || notice === void 0 ? void 0 : notice.id]);
    const loadingComplete = !!invoiceCreationData && !!notice && !!rate && !!user;
    const invalidReason = getInvoiceDataInvalidReason(notice, rate, invoiceCreationData);
    return (_jsx(FullScreenModal, Object.assign({ headerText: "Create Invoice", submittable: {
            buttonText: 'Create',
            onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                if (!loadingComplete)
                    return;
                yield createInvoice(user, notice, invoiceCreationData);
                dispatch(push(`/notice/${notice.id}`));
            }),
            disabled: !!invalidReason
        }, onClose: () => dispatch(push(`/notice/${notice === null || notice === void 0 ? void 0 : notice.id}`)), id: "create-invoice" }, { children: _jsxs("div", Object.assign({ className: "grid grid-cols-5" }, { children: [!loadingComplete && _jsx(LoadingState, {}), loadingComplete && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "col-span-3 overflow-scroll" }, { children: _jsx("div", Object.assign({ className: "max-w-4xl mx-auto" }, { children: _jsx(InvoiceDetailsCard, { updateInvoiceCreationData: invoiceDataUpdates => {
                                        setInvoiceCreationData(Object.assign(Object.assign({}, invoiceCreationData), invoiceDataUpdates));
                                    }, invoiceCreationData: invoiceCreationData }) })) })), _jsx("div", Object.assign({ className: "col-span-2 border-l" }, { children: _jsx(InvoicePreviewCard, { invoiceCreationData: invoiceCreationData, notice: notice }) }))] }))] })) })));
}
