var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { ColumnButton } from 'lib/components/ColumnButton';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { ColumnService } from 'lib/services/directory';
export default function ConfirmReceiptModal({ notice, setOpen, newspaper, user }) {
    const [confirming, setConfirming] = useState(false);
    const [error, setError] = useState(false);
    const handleConfirmNotice = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setConfirming(true);
            const noticeModel = getModelFromSnapshot(UserNoticeModel, getFirebaseContext(), notice);
            yield noticeModel.confirm(user);
            setOpen(false);
        }
        catch (err) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Error confirming notice from notice details', { noticeId: notice.id, userId: user.id });
            setConfirming(false);
            setError(true);
        }
    });
    const conditions = [
        {
            id: 'dates_shown',
            label: 'Publish this notice on the date(s) shown.',
            visible: true
        },
        {
            id: 'issue_invoice',
            label: 'Issue an invoice for this notice through column.us.',
            visible: true
        },
        {
            id: 'upload_affidavit',
            label: 'Upload the affidavit of publication to column.us.',
            visible: !newspaper.data().affidavitDisabled
        }
    ];
    return (_jsxs(FreeformCModal, Object.assign({ header: "Confirm Notice", body: `Once confirmed, you may still edit or cancel the notice prior to
      publication. By clicking Confirm, you acknowledge that${' '}
      ${newspaper.data().name} will:`, setOpen: setOpen, noExitOutsideModal: true }, { children: [_jsxs("section", Object.assign({ className: "text-sm text-column-gray-400 leading-5 mb-3" }, { children: [_jsx("div", Object.assign({ className: "m-4", id: "column-notice-conditions" }, { children: conditions.map(condition => {
                            if (!condition.visible) {
                                return null;
                            }
                            return (_jsxs("label", Object.assign({ htmlFor: condition.id, className: "block" }, { children: [_jsx("input", { id: condition.id, type: "checkbox", disabled: true, checked: true, className: "form-checkbox text-column-gray-400 mr-4" }), condition.label] }), condition.id));
                        }) })), _jsx("p", { children: "Upon clicking Confirm, the customer will receive a notification." })] })), _jsx(ColumnButton, { primary: true, size: "lg", id: "confirm-receipt", onClick: handleConfirmNotice, buttonText: "Confirm", loading: confirming, type: "button" }), error && (_jsx("p", Object.assign({ className: "text-sm text-column-red-700 mt-3" }, { children: "Sorry, there was an error confirming this notice." })))] })));
}
