import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { Modal } from 'lib/components/Modal';
import { useState } from 'react';
import AffidavitTemplateEditFormLegacy from './v2020-01-01';
import AffidavitTemplateEditFormUpdated from './v2024-03-25';
export function AffidavitTemplateEditForm({ affidavitTemplateRef, activeOrganization, affidavitTemplate, onClose }) {
    var _a;
    const [showForkModal, setShowForkModal] = useState(true);
    const [version, setVersion] = useState((_a = affidavitTemplate.version) !== null && _a !== void 0 ? _a : 'v2020-01-01');
    const isUpdatedVersion = affidavitTemplate.version === 'v2024-03-25';
    if (showForkModal) {
        return (_jsx(Modal, Object.assign({ id: "affidavit-edit-form-version-selection-modal", onClose: onClose, title: "Select Affidavit Template Version", primaryAction: {
                type: 'button',
                buttonText: 'Continue',
                onClick: () => setShowForkModal(false)
            } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [affidavitTemplateRef && (_jsx(Alert, { status: "warning", id: "existing-template-version", title: _jsxs(_Fragment, { children: ["This template currently uses our", ' ', _jsx("span", Object.assign({ className: "font-bold" }, { children: isUpdatedVersion ? 'updated' : 'legacy' })), ' ', "service. Selecting the", ' ', isUpdatedVersion ? 'legacy' : 'updated', " service here will clear existing customizations."] }), icon: _jsx(InformationCircleIcon, { className: "h-6 w-6" }) })), _jsx(CheckboxInput, { id: "use-legacy-template", checked: version === 'v2020-01-01', onChange: () => setVersion('v2020-01-01'), labelText: "Legacy Affidavit Template", labelDescription: "Use our legacy affidavit generation service with this template. This version allows for greater customization via handlebars-enabled HTML, but it uses a slower PDF rendering service." }), _jsx(CheckboxInput, { id: "use-updated-template", checked: version === 'v2024-03-25', onChange: () => setVersion('v2024-03-25'), labelText: "Updated Affidavit Template", labelDescription: "Use our updated affidavit generation service with this template. This version streamlines customization options, so we can render PDFs faster than our legacy service." })] })) })));
    }
    if (version === 'v2020-01-01') {
        return (_jsx(AffidavitTemplateEditFormLegacy, { affidavitTemplateRef: affidavitTemplateRef, activeOrganization: activeOrganization, affidavitTemplate: affidavitTemplate, onClose: onClose }));
    }
    return (_jsx(AffidavitTemplateEditFormUpdated, { affidavitTemplateRef: affidavitTemplateRef, activeOrganization: activeOrganization, affidavitTemplate: affidavitTemplate, onClose: onClose }));
}
