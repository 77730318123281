var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import sanitize from 'sanitize-html';
import api from 'api';
import { DEFAULT_DISPLAY_AD_BORDER_SIZE_IN_PIXELS, DEFAULT_DPI } from 'lib/helpers';
import { getAdTemplateCacheID } from 'lib/types/templates';
import { callIndesignTemplateSpecs } from 'utils/indesign';
import { logAndCaptureMessage } from 'utils';
export const countWords = (html) => {
    const text = sanitize(html, {
        allowedTags: [],
        textFilter(text) {
            return `${text} `;
        }
    });
    if (!text)
        return 0;
    const textCleaned = text.trim().replace(/&nbsp;/g, ' ');
    return textCleaned.split(/\s+/).length;
};
export const slackInvalidHtml = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    logAndCaptureMessage('Notice failed HTML validation', {
        noticeId: notice.id
    });
    yield api.post('notifications/slack', {
        message: `Liner Length Exceeded\n*ID:* ${notice.id}\n`
    });
});
// This limit is arbitrary!
export const MAX_CONFIRMED_HTML_WORDS = 18000;
export const validateHtml = (html) => {
    return countWords(html) < MAX_CONFIRMED_HTML_WORDS;
};
export const getTemplateSpecs = (newspaper, adTemplate, notice, columns) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!newspaper || !exists(newspaper))
        return;
    const { dynamicHeaders, dynamicFooter } = notice.data();
    const { displayAdHeader, displayAdFooter } = newspaper.data();
    const request = {
        id: getAdTemplateCacheID(adTemplate),
        noticeId: notice.id,
        downloadUrl: adTemplate.data().downloadUrl,
        columns,
        dynamicHeader: displayAdHeader ? (dynamicHeaders === null || dynamicHeaders === void 0 ? void 0 : dynamicHeaders[0]) || null : null,
        dynamicFooter: displayAdFooter ? dynamicFooter : null
    };
    const res = yield callIndesignTemplateSpecs({ request });
    const borderWidthInInches = ((_a = newspaper.data().configureNoticePDFs) === null || _a === void 0 ? void 0 : _a.applyBorderToDisplay)
        ? DEFAULT_DISPLAY_AD_BORDER_SIZE_IN_PIXELS / DEFAULT_DPI
        : null;
    return Object.assign(Object.assign({}, res.data), { borderWidthInInches });
});
