var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ColumnButton } from '../ColumnButton';
import { Tooltip } from '../Tooltip';
import { columnObjectsAreEqual, safeStringify } from '../../utils/stringify';
import CardWithHeader from './CardWithHeader';
function EditableCardActions({ disableEdit, disableSave, onDiscard, onEdit, mode, submissionLoading }) {
    if (mode === 'edit') {
        return (_jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsx(ColumnButton, { tertiary: true, buttonText: "Discard", type: "button", onClick: onDiscard, disabled: submissionLoading }), _jsx(ColumnButton, { primary: true, buttonText: "Save", type: "submit", disabled: disableSave, loading: submissionLoading })] })));
    }
    return (_jsx(Tooltip, Object.assign({ helpText: disableEdit ? 'You do not have permission to edit this section' : '', position: "topLeft" }, { children: _jsx(ColumnButton, { secondary: true, disabled: disableEdit, buttonText: "Edit", type: "button", onClick: onEdit }) })));
}
export function CardGridLayout(_a) {
    var { children, editable, header } = _a, cardProps = __rest(_a, ["children", "editable", "header"]);
    const cardHeader = header;
    if (cardHeader && editable) {
        cardHeader.actionable = _jsx(EditableCardActions, Object.assign({}, editable));
    }
    return (_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx("div", Object.assign({ className: "mx-auto max-w-4xl p-4" }, { children: _jsx(CardWithHeader, Object.assign({ header: cardHeader }, cardProps, { children: _jsx("div", Object.assign({ className: "grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2" }, { children: children })) })) })) })));
}
export function HorizontalDivider({ noPadding }) {
    return (_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx("div", Object.assign({ className: classNames('mx-auto max-w-4xl', { 'p-4': !noPadding }) }, { children: _jsx("hr", { className: "bg-column-gray-100" }) })) })));
}
/**
 * Wrapper around form inputs to ensure they are the same width.
 */
export function GridInput({ fullWidth, children }) {
    return (_jsx("div", Object.assign({ className: classNames('col-span-1', {
            'sm:col-span-2': fullWidth
        }) }, { children: children })));
}
export const useEditableCard = (currentSettings, { shouldDisableSave = columnObjectsAreEqual, shouldDisableEdit = () => false } = {}) => {
    const [formSettings, setFormSettings] = useState(currentSettings);
    const [mode, setMode] = useState('view');
    const [submissionLoading, setSubmissionLoading] = useState(false);
    const disabled = mode === 'view';
    const disableSave = shouldDisableSave(formSettings, currentSettings);
    const disableEdit = shouldDisableEdit(formSettings, currentSettings);
    useEffect(() => {
        if (mode === 'view') {
            setFormSettings(currentSettings);
        }
    }, [safeStringify(currentSettings)]);
    const onEdit = () => {
        setMode('edit');
    };
    const onChange = (updates) => {
        setFormSettings(updates == null ? updates : Object.assign(Object.assign({}, formSettings), updates));
    };
    const onDiscard = () => {
        setFormSettings(currentSettings == null ? currentSettings : Object.assign({}, currentSettings));
        setMode('view');
    };
    const onStartSubmission = () => {
        setSubmissionLoading(true);
    };
    // TODO: remove default value for `success` after we stop exporting `onFinishSubmission`
    const onFinishSubmission = (success) => {
        setSubmissionLoading(false);
        if (success) {
            setMode('view');
        }
    };
    const onSubmitWrapper = (onSubmit) => __awaiter(void 0, void 0, void 0, function* () {
        onStartSubmission();
        const { success } = yield onSubmit();
        onFinishSubmission(success);
    });
    return {
        editable: {
            onEdit,
            onDiscard,
            disableSave,
            disableEdit,
            mode,
            submissionLoading
        },
        onChange,
        onSubmitWrapper,
        disabled,
        formSettings
    };
};
