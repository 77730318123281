import { isNoticeRate } from '../types/rates';
import { dateObjectToDayEnum } from '../utils/deadlines';
/**
 * The one true pricing function is createDBPricingObjectFromData, so if you're using this outside
 * of `lib/pricing`, you're probably doing something wrong!
 */
const findDayRate = (rate, date) => {
    var _a, _b;
    const dayEnum = dateObjectToDayEnum(date.toDate());
    // Only notice rates can have day rates
    const matchingDayRate = isNoticeRate(rate)
        ? (_a = rate.dayRates) === null || _a === void 0 ? void 0 : _a.find(dRate => dRate.day === dayEnum)
        : undefined;
    const dayRate = (_b = matchingDayRate === null || matchingDayRate === void 0 ? void 0 : matchingDayRate.rate) !== null && _b !== void 0 ? _b : 0;
    return { dayRate, dayEnum };
};
export default findDayRate;
