import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { useAppDispatch } from 'redux/hooks';
import AuthActions from 'redux/auth';
export default function Impersonate() {
    const dispatch = useAppDispatch();
    const token = getLocationParams().get('token');
    useEffect(() => {
        if (token) {
            dispatch(AuthActions.loginToken(token));
            dispatch(push('/'));
        }
    }, [token]);
    return (_jsxs("div", Object.assign({ className: "w-full h-full flex flex-col items-center justify-center gap-4" }, { children: [token && (_jsxs(_Fragment, { children: [_jsx(LoadingSpinner, {}), _jsx("div", { children: "Signing in..." })] })), !token && _jsx("div", { children: "Error: Missing token" })] })));
}
