import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnButton } from 'lib/components/ColumnButton';
import { TrashIcon } from '@heroicons/react/24/outline';
import ColumnDatePicker from 'components/ColumnDatePicker';
function RemovableDate({ publicationDate, onRemoveRowClick, onRowDateChange, disabled, showDelete, shouldDisableDate }) {
    return (_jsxs("div", Object.assign({ className: "flex space-x-2" }, { children: [_jsx(ColumnDatePicker, { value: publicationDate, shouldDisableDate: shouldDisableDate, disabled: disabled, onChange: date => {
                    if (date) {
                        onRowDateChange(date);
                    }
                }, format: "MM/dd/yyyy", momentFormat: "MMMM Do", placeholderText: "MM/DD/YYYY", fullWidth: true }), !disabled && showDelete && (_jsx(ColumnButton, { link: false, destructive: true, secondary: true, startIcon: _jsx(TrashIcon, { className: "w-5 h-5" }), onClick: onRemoveRowClick, type: "button" }))] })));
}
export default RemovableDate;
