var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import fs from 'fs';
import { NoticeFileTypes } from '../types/notice';
import { getFileTypeFromExtensionString, getFileTypeFromMimeTypeString } from '../types/mime';
import { isAffinityXResponseFileName } from '../integrations/affinityx/helpers';
export const refreshDraftFiles = (ctx, draftRef, filesToAttach, postWithoutFormatting) => __awaiter(void 0, void 0, void 0, function* () {
    const fileCollectionRef = ctx.userNoticeFilesRef(draftRef);
    const fileCollectionSnap = yield fileCollectionRef.get();
    const oldFileSnaps = fileCollectionSnap.docs;
    if (oldFileSnaps && oldFileSnaps.length) {
        for (const oldFileSnap of oldFileSnaps) {
            yield oldFileSnap.ref.delete();
        }
    }
    // If we are submitting a notice without formatting, then the files
    // should be considered unformatted components of a display ad,
    // rather than finalized display or text files
    const properlyTypedFilesToAttach = postWithoutFormatting
        ? filesToAttach.map(noticeFileData => (Object.assign(Object.assign({}, noticeFileData), { type: NoticeFileTypes.display_ad_component })))
        : filesToAttach;
    for (const fileToAttach of properlyTypedFilesToAttach) {
        yield fileCollectionRef.add(fileToAttach);
    }
});
export const addDraftFilesToNotice = (ctx, draftRef, noticeRef) => __awaiter(void 0, void 0, void 0, function* () {
    const draftFilesCollectionRef = ctx.userNoticeFilesRef(draftRef);
    const draftFilesCollectionSnap = yield draftFilesCollectionRef.get();
    const noticeFilesCollectionRef = ctx.userNoticeFilesRef(noticeRef);
    const noticeFilesCollectionSnap = yield noticeFilesCollectionRef.get();
    if (noticeFilesCollectionSnap.docs) {
        for (const noticeFileSnap of noticeFilesCollectionSnap.docs) {
            yield noticeFileSnap.ref.delete();
        }
    }
    if (draftFilesCollectionSnap.docs) {
        for (const draftFileSnap of draftFilesCollectionSnap.docs) {
            yield noticeFilesCollectionRef.add(draftFileSnap.data());
            yield draftFileSnap.ref.delete();
        }
    }
});
// Used in notification flow to attach zip files to emails
export function loadZipFileIntoAttachment(path, filename) {
    return {
        content: fs.readFileSync(path).toString('base64'),
        filename,
        type: 'application/zip',
        disposition: 'attachment'
    };
}
/**
 * Looks at the `noticeFiles` on a draft and determines which should be replicated and maintained when going through the edit flow.
 *
 * Typically, we would want to copy all notice files in the edit flow, but for the AffinityX integration, when we receive a finalized
 * built ad from AffinityX, we want to replace the existing component display ads with that finalized ad.
 *
 * There are 5 possibilities that this function anticipates and 1 edge case it covers but that should never occur. The 5 agreeable
 * cases are as follows:
 * 1. There is only one draft file – this is never a case of an AffinityX notice, so we should just return the original array.
 * 2. There are multiple draft files, none of which are finalized display ads. This means no AffinityX file has been returned, and we should return the original array.
 * 3. There are mutliple draft files, only one of which is a finalized display ad. This means that AffinityX built a new ad out of multiple display components,
 * and we should replace the display ad components with the finalized, built ad.
 * 4. There are 2 finalized display ads, where one was originally placed & formatted in Column, sent to AffinityX as an external build, and returned from AffinityX.
 * In this instance, we should favor the AffinityX ad (we check this by the name of the file, which is formatted particularly when from AffinityX).
 * 5. There are 2+ finalized display ads *AND* 2+ of those finalized display ads are from AffinityX (which means that we received multiple response files from AffinityX
 * after updating the same order multiple times); in this instance, we should choose the file that was sent most recently.
 *
 * If none of these 5 conditions is met (i.e., if there are multiple finalized display ads and none is from AffinityX), then we should return the original array
 * so as not to disrupt the edit flow, but we should log this in Sentry.
 */
export const determineNoticeFilesToReattach = (draftNoticeFiles) => {
    // Case 1 (see comment above)
    if (draftNoticeFiles.length === 1) {
        return {
            noticeFiles: draftNoticeFiles
        };
    }
    // Case 2 (see comment above)
    const finalizedDisplayAds = draftNoticeFiles.filter(noticeFile => noticeFile.type === NoticeFileTypes.finalized_display_ad);
    if (!finalizedDisplayAds.length) {
        return {
            noticeFiles: draftNoticeFiles
        };
    }
    // Case 3 (see comment above)
    if (finalizedDisplayAds.length === 1) {
        return {
            noticeFiles: finalizedDisplayAds
        };
    }
    // Case 4 (see comment above)
    const affinityFinalizedDisplayAds = finalizedDisplayAds.filter(finalizedAd => isAffinityXResponseFileName(finalizedAd.sanitizedFileName || ''));
    if (affinityFinalizedDisplayAds.length === 1) {
        return {
            noticeFiles: affinityFinalizedDisplayAds
        };
    }
    // Case 5 (see comment above)
    if (affinityFinalizedDisplayAds.length > 1 &&
        affinityFinalizedDisplayAds.every(affinityAd => !!affinityAd.affinityXDeliveryTime)) {
        const mostRecentAffinityAd = affinityFinalizedDisplayAds.sort((ad1, ad2) => ad2.affinityXDeliveryTime.toMillis() -
            ad1.affinityXDeliveryTime.toMillis())[0];
        return {
            noticeFiles: [mostRecentAffinityAd]
        };
    }
    // Erroneous edge case (see comment above)
    return {
        noticeFiles: draftNoticeFiles,
        error: new Error('Notice has multiple finalized display ad files and none from AffinityX')
    };
};
export const getNoticeFilesAndSetOnDraft = (ctx, noticeRef, draftRef) => __awaiter(void 0, void 0, void 0, function* () {
    const draftFilesCollectionRef = ctx.userNoticeFilesRef(draftRef);
    const noticeFilesCollectionRef = ctx.userNoticeFilesRef(noticeRef);
    const noticeFilesCollectionSnap = yield noticeFilesCollectionRef.get();
    const noticeFilesData = noticeFilesCollectionSnap.docs.map(snapshot => snapshot.data());
    const { noticeFiles: noticeFilesToReattach, error } = determineNoticeFilesToReattach(noticeFilesData);
    const filesArray = [];
    for (const fileData of noticeFilesToReattach) {
        yield draftFilesCollectionRef.add(fileData);
        filesArray.push(fileData);
    }
    return {
        filesArray,
        error
    };
});
export const getFilesDataFromNoticeOrDraft = (ctx, noticeOrDraft) => __awaiter(void 0, void 0, void 0, function* () {
    const filesCollectionRef = ctx.userNoticeFilesRef(noticeOrDraft);
    const filesCollectionSnap = yield filesCollectionRef.get();
    const filesArray = [];
    for (const fileSnap of filesCollectionSnap.docs) {
        filesArray.push(fileSnap.data());
    }
    return filesArray;
});
/**
 * We check with the file name first as that is existing behavior across the app
 */
export const getFileTypeFromFile = (file) => {
    const fileName = file.name;
    const extensionString = fileName.split('.').pop();
    let fileType = extensionString
        ? getFileTypeFromExtensionString(extensionString)
        : undefined;
    if (fileType) {
        return fileType;
    }
    const mimeTypeString = file.type;
    fileType = getFileTypeFromMimeTypeString(mimeTypeString);
    return fileType;
};
