import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Day, enumItemToSelectInput } from 'lib/enums';
import { Autocomplete } from 'lib/components/Autocomplete';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import classNames from 'classnames';
import { publishingDayEnumValuesFromDeadlines } from 'lib/utils/deadlines';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import DeadlineOffsetForm from './DeadlineOffsetForm';
const PUBLICATION_DATE_OPTIONS = [
    {
        value: 'default',
        label: `Let customers select the number of publications`
    },
    {
        value: '1',
        label: 'One'
    },
    {
        value: '2',
        label: 'Two'
    },
    {
        value: '3',
        label: 'Three'
    },
    {
        value: '4',
        label: 'Four'
    },
    {
        value: '5',
        label: 'Five'
    },
    {
        value: '6',
        label: 'Six'
    }
];
export default function PublicationDatesCard({ activeOrganization, onUpdateNoticeType, updatedNoticeType }) {
    var _a, _b, _c, _d, _e, _f;
    const { deadlines } = activeOrganization.data();
    if (!deadlines)
        throw new Error('No deadlines found for newspaper');
    const publishingDays = publishingDayEnumValuesFromDeadlines(deadlines);
    const restrictedPublicationDaysOptions = Day.items()
        .filter(day => publishingDays.includes(day.value))
        .filter(day => { var _a; return !((_a = updatedNoticeType.restrictedPublicationDays) === null || _a === void 0 ? void 0 : _a.includes(day.value)); })
        .map(day => enumItemToSelectInput(day));
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Publication Schedule',
            description: 'Configure publication schedule requirements for this notice type.'
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "notice-type-num-publications", labelText: "Number of publication dates", options: PUBLICATION_DATE_OPTIONS, value: ((_a = updatedNoticeType.requiredPublications) === null || _a === void 0 ? void 0 : _a.toString()) || 'default', onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (newValue === 'default') {
                            delete newNoticeType.requiredPublications;
                        }
                        else {
                            newNoticeType.requiredPublications = parseInt(newValue, 10);
                        }
                        onUpdateNoticeType(newNoticeType);
                    } }) })), updatedNoticeType.requiredPublications && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { value: updatedNoticeType.canAddBeyondRequired || false, onChange: newValue => onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { canAddBeyondRequired: newValue })), label: "Can customers add more publication dates?", description: `If enabled, customers can schedule this notice type for more than ${updatedNoticeType.requiredPublications} publication date${updatedNoticeType.requiredPublications > 1 ? 's' : ''}.` }) }))), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Days between publication dates", step: 1, min: 1, max: 100, type: "number", placeholder: "7", value: (_b = updatedNoticeType.defaultDaysBetweenPublication) === null || _b === void 0 ? void 0 : _b.toString(), onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (newValue === '') {
                            delete newNoticeType.defaultDaysBetweenPublication;
                        }
                        else {
                            newNoticeType.defaultDaysBetweenPublication = parseInt(newValue, 10);
                        }
                        onUpdateNoticeType(newNoticeType);
                    }, id: "days-between-publications" }) }), _jsx(GridInput, { children: _jsx(Autocomplete, { labelText: "What days of the week is this notice type available?", value: undefined, placeholder: !((_c = updatedNoticeType.restrictedPublicationDays) === null || _c === void 0 ? void 0 : _c.length)
                        ? 'All publication days'
                        : '', onChange: newValue => {
                        onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { restrictedPublicationDays: (updatedNoticeType.restrictedPublicationDays || []).concat(parseInt(newValue, 10)) }));
                    }, options: restrictedPublicationDaysOptions, prefix: _jsx("div", Object.assign({ className: classNames({
                            'w-44': updatedNoticeType.restrictedPublicationDays &&
                                ((_d = updatedNoticeType.restrictedPublicationDays) === null || _d === void 0 ? void 0 : _d.length) === 2,
                            'w-72': updatedNoticeType.restrictedPublicationDays &&
                                ((_e = updatedNoticeType.restrictedPublicationDays) === null || _e === void 0 ? void 0 : _e.length) > 2
                        }) }, { children: (_f = updatedNoticeType.restrictedPublicationDays) === null || _f === void 0 ? void 0 : _f.map(publicationDay => {
                            var _a;
                            return (_jsx("button", Object.assign({ onClick: () => {
                                    var _a;
                                    const newPublicationDays = (_a = updatedNoticeType.restrictedPublicationDays) === null || _a === void 0 ? void 0 : _a.filter(day => day !== publicationDay);
                                    onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { restrictedPublicationDays: newPublicationDays }));
                                } }, { children: _jsx(Badge, Object.assign({ startIcon: _jsx(XMarkIcon, { className: "w-4 h-4" }), status: "info" }, { children: (_a = Day.by_value(publicationDay)) === null || _a === void 0 ? void 0 : _a.label })) }), publicationDay));
                        }) })), id: "publication-dates-available" }) }), _jsx(DeadlineOffsetForm, { onUpdateNoticeType: onUpdateNoticeType, updatedNoticeType: updatedNoticeType })] })));
}
