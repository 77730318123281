var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { Box, IconButton } from '@material-ui/core';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { DeleteIcon, PlusCircleIcon } from 'icons';
import * as EmailValidator from 'email-validator';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { ColumnService } from 'lib/services/directory';
function ExportNoticesModal({ setOpen, reportType, onSuccessfulExport, setSuccessModalBody, user }) {
    const [radioButtonSelected, setRadioButtonSelected] = useState(-1);
    const [disabled, setDisabled] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emails, setEmails] = useState([user.data().email]);
    const [emailError, setEmailError] = useState([]);
    const addNewEmailRef = useRef(null);
    const backToSendReportModal = () => {
        setShowEmailModal(false);
        setDisabled(false);
    };
    const fileHeader = `Export Report as ${reportType.toUpperCase()}`;
    const emailHeader = 'Send Report to Email';
    const lastMonth = moment().subtract(1, 'month').format('MMMM');
    const startOfThisMonth = moment().startOf('month').format('MMMM D');
    const currentDate = moment().format('MMMM D');
    const ALL_NOTICES = 1;
    const THIS_MONTHS_NOTICES = 2;
    const LAST_MONTHS_NOTICES = 3;
    const CUSTOM_RANGE_NOTICES = 4;
    const EMAIL = 'email';
    const dateRangeOptions = [
        {
            id: ALL_NOTICES,
            startDate: moment(0).toDate(),
            endDate: new Date(),
            dateRange: 'All time',
            inputId: 'alltime'
        },
        {
            id: LAST_MONTHS_NOTICES,
            startDate: moment().startOf('month').subtract(1, 'month').toDate(),
            endDate: moment().startOf('month').subtract(1, 'day').toDate(),
            dateRange: 'Last month',
            description: ` - ${lastMonth}`,
            inputId: 'lastMonth'
        },
        {
            id: THIS_MONTHS_NOTICES,
            startDate: moment().startOf('month').toDate(),
            endDate: new Date(),
            dateRange: 'This month',
            description: ` - ${startOfThisMonth} to ${currentDate}`,
            inputId: 'thisMonth'
        },
        {
            id: CUSTOM_RANGE_NOTICES,
            startDate: undefined,
            endDate: undefined,
            dateRange: 'Custom date',
            inputId: 'customDate'
        }
    ];
    const getEmailModalBody = () => {
        let suffix;
        switch (radioButtonSelected) {
            case ALL_NOTICES:
                suffix = 'of all notices';
                break;
            case LAST_MONTHS_NOTICES:
                suffix = `from last month - ${lastMonth}`;
                break;
            case THIS_MONTHS_NOTICES:
                suffix = `from this month - ${startOfThisMonth} to ${currentDate}`;
                break;
            default:
                suffix = `from ${moment(startDate).format('MMM Do, YYYY')} to ${moment(endDate).format('MMM Do, YYYY')}`;
        }
        return `Sending a report ${suffix}.`;
    };
    const addMailAddress = () => {
        setEmails([...emails, '']);
    };
    const generateNoticeReport = () => __awaiter(this, void 0, void 0, function* () {
        const { url, error } = yield api.post('users/generate-notice-detail-report', {
            fromDate: moment(startDate).format('MM/DD/YYYY'),
            toDate: moment(endDate).add(1, 'd').format('MM/DD/YYYY'),
            reportType,
            emails
        });
        if (error) {
            logAndCaptureException(ColumnService.PAYMENTS, error, 'An error occurred when pulling notices for notice report for user ', {
                userId: user.id
            });
            return;
        }
        if (url) {
            window.open(url);
        }
        if (reportType === EMAIL) {
            setSuccessModalBody(_jsxs("span", { children: ["Your report has been sent to", ' ', _jsx("a", Object.assign({ href: `mailto:${emails[0]}`, className: "text-blue-900" }, { children: emails[0] })), emails.length === 1
                        ? ''
                        : ` and ${emails.length - 1} other${emails.length > 2 ? 's' : ''}`, "."] }));
        }
        else {
            setSuccessModalBody('Your report has been successfully exported. Check your downloads to view it.');
        }
        onSuccessfulExport(true);
        setOpen(false);
    });
    const submitButtonHandler = () => __awaiter(this, void 0, void 0, function* () {
        if (reportType === EMAIL && !showEmailModal) {
            setOpen(true);
            validateEmail();
            setShowEmailModal(true);
        }
        else {
            setOpen(true);
            yield generateNoticeReport();
        }
    });
    useEffect(() => {
        if (startDate && endDate && endDate < startDate) {
            const tempDate = endDate;
            setEndDate(startDate);
            setStartDate(tempDate);
        }
    }, [startDate, endDate]);
    const validateEmail = () => {
        const newError = [...emailError];
        let foundError = false;
        for (const [i, e] of emails.entries()) {
            const previous = emails.slice(0, i);
            const validEmail = EmailValidator.validate(e);
            if (!e) {
                newError[i] = 'Recipient email is required';
                foundError = true;
            }
            else if (!validEmail) {
                newError[i] = 'You need to enter a valid email.';
                foundError = true;
            }
            else if (previous.indexOf(e) > -1) {
                newError[i] = 'Duplicate email.';
                foundError = true;
            }
            else {
                newError[i] = null;
            }
        }
        setEmailError([...newError]);
        setDisabled(foundError);
    };
    // automatically scroll to the bottom of emails list when another email is added
    useEffect(() => {
        var _a;
        if (showEmailModal) {
            (_a = addNewEmailRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            validateEmail();
        }
    }, [emails]);
    return (_jsxs("div", { children: [showEmailModal && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setOpen(false), body: getEmailModalBody(), header: "Add Recipient", noExitOutsideModal: true, disablePrimaryButton: disabled, disableOverflow: true, onSubmit: submitButtonHandler, tertiaryButtonText: "Back", primaryButtonText: "Export", overrideTertiaryClose: backToSendReportModal, showLoadingSpinner: true }, { children: _jsxs(Box, Object.assign({ ml: 1 }, { children: [emails.map((email, i) => {
                            return (_jsxs("div", Object.assign({ id: "add-emails-modal", className: `pt-6 ${i === 0 ? 'mt-2' : ''}` }, { children: [_jsx("span", Object.assign({ className: "text-sm font-medium text-gray-900" }, { children: "Email address" })), _jsxs("span", Object.assign({ className: "flex" }, { children: [_jsx("input", { id: `name${i}`, className: `mt-2 font-medium ${emailError[i] && i !== emailError.length - 1
                                                    ? 'form-input-invalid'
                                                    : 'form-input'} block ${i === 0 ? 'w-full py-3' : 'w-11/12 py-2'} py-2 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none ${emailError[i] && i !== emailError.length - 1
                                                    ? 'focus:border-red-500 focus:shadow-outline-red'
                                                    : 'focus:border-blue-900 focus:shadow-outline-blue'} transition duration-150 ease-in-out sm:text-sm sm:leading-5`, placeholder: "Email", value: email, onChange: e => {
                                                    const updatedEmails = [...emails];
                                                    updatedEmails[i] = e.target.value;
                                                    setEmails(updatedEmails);
                                                }, autoComplete: "off" }), i > 0 && (_jsx("span", Object.assign({ className: "mt-2" }, { children: _jsx("div", Object.assign({ className: "ml-4 bg-red-50 rounded-md" }, { children: _jsx(IconButton, Object.assign({ id: `delete-invoice-mail-${i}`, className: '', disableRipple: true, onClick: () => {
                                                            const newEmails = emails
                                                                .slice(0, i)
                                                                .concat(emails.slice(i + 1, emails.length));
                                                            setEmails(newEmails);
                                                        }, style: {
                                                            backgroundColor: 'transparent',
                                                            outline: 'none'
                                                        } }, { children: _jsx(DeleteIcon, { className: "text-red-600" }) })) })) })))] })), emails.length === 1 && !emailError[0] && (_jsx("div", Object.assign({ className: "pt-2" }, { children: _jsx("span", Object.assign({ className: "text-xs text-gray-700" }, { children: "Your email is used by default. Edit or add a new email." })) }))), emailError[i] && i !== emailError.length - 1 && (_jsx("div", Object.assign({ className: "pt-2" }, { children: _jsx("span", Object.assign({ className: "text-xs font-medium text-red-600" }, { children: emailError[i] })) })))] }), `${i}-email-container`));
                        }), _jsx("div", Object.assign({ className: "pt-6 pb-8", ref: addNewEmailRef }, { children: _jsx("button", Object.assign({ id: "addMoreRecipients", onClick: addMailAddress, className: "ml-0", style: { outline: 'none' } }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(PlusCircleIcon, { className: "w-5 h-5", stroke: "#2D9BDB", strokeWidth: "2" }), _jsx(Box, { children: _jsx("div", Object.assign({ className: "pl-1 font-medium text-sm text-gray-700 normal-case text-blue-900" }, { children: "Add New Email" })) })] })) })) }))] })) }))), !showEmailModal && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setOpen(false), body: `Select dates to ${reportType === EMAIL ? 'send a PDF' : 'download a'} report about your notices and payments. The report will exclude notices without invoices.`, header: reportType === EMAIL ? emailHeader : fileHeader, noExitOutsideModal: true, disablePrimaryButton: disabled, disableOverflow: true, onSubmit: submitButtonHandler, tertiaryButtonText: "Cancel", primaryButtonText: reportType === EMAIL ? 'Continue' : 'Export', primaryButtonId: "export-notice-report", showLoadingSpinner: true }, { children: _jsx("div", Object.assign({ id: "export-modal" }, { children: _jsxs("fieldset", Object.assign({ className: "space-y-5 pb-8 pt-3 text-sm font-medium" }, { children: [_jsx("legend", Object.assign({ className: "sr-only" }, { children: "Date Range" })), dateRangeOptions.map(dateRangeOption => {
                                return (_jsxs("div", Object.assign({ className: `relative flex items-start border justify-center ${radioButtonSelected === dateRangeOption.id
                                        ? 'border-blue-900'
                                        : 'border-gray-300'} rounded-md bg-white`, onClick: () => {
                                        setRadioButtonSelected(dateRangeOption.id);
                                        setDisabled(dateRangeOption.id === CUSTOM_RANGE_NOTICES);
                                        setStartDate(dateRangeOption.startDate);
                                        setEndDate(dateRangeOption.endDate);
                                    } }, { children: [_jsxs("div", Object.assign({ className: "w-11/12 px-4.5 py-3.5" }, { children: [_jsx("span", Object.assign({ id: `${dateRangeOption.inputId}-description`, className: `${radioButtonSelected === dateRangeOption.id &&
                                                        'text-blue-900'}` }, { children: dateRangeOption.dateRange })), dateRangeOption.description && (_jsx("span", Object.assign({ className: `${radioButtonSelected === dateRangeOption.id
                                                        ? 'text-blue-900'
                                                        : 'text-gray-600'}` }, { children: dateRangeOption.description })))] })), _jsx("div", Object.assign({ className: "flex items-center w-1/12 py-4 pl-0.5" }, { children: _jsx("input", { id: `${dateRangeOption.inputId}`, "aria-describedby": `${dateRangeOption.inputId}-button`, name: `${dateRangeOption.inputId}`, type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: radioButtonSelected === dateRangeOption.id }) }))] }), dateRangeOption.inputId));
                            }), radioButtonSelected === CUSTOM_RANGE_NOTICES && (_jsxs("div", Object.assign({ className: "" }, { children: [_jsx("div", Object.assign({ className: "pb-3 w-full" }, { children: _jsx("span", { children: "Pick a date range" }) })), _jsxs("div", Object.assign({ className: "w-full flex gap-3" }, { children: [_jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd, yyyy", momentFormat: "MMM DD, YYYY", placeholderText: "Select start date", value: startDate, onChange: newDate => {
                                                    setStartDate(newDate || undefined);
                                                    if (endDate) {
                                                        setDisabled(false);
                                                    }
                                                }, shouldDisableDate: date => {
                                                    if (date && date > new Date()) {
                                                        return true;
                                                    }
                                                    return false;
                                                } }), _jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd, yyyy", momentFormat: "MMM DD, YYYY", placeholderText: "Select end date", value: endDate, onChange: newDate => {
                                                    setEndDate(newDate || undefined);
                                                    if (startDate) {
                                                        setDisabled(false);
                                                    }
                                                }, shouldDisableDate: date => {
                                                    if (date && date > new Date()) {
                                                        return true;
                                                    }
                                                    return false;
                                                } })] }))] })))] })) })) })))] }));
}
export default ExportNoticesModal;
