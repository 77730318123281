var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LitElement } from 'lit';
import { property, state } from 'lit/decorators';
export class MadlibElement extends LitElement {
    constructor() {
        super();
        this.key = undefined;
        this.hint = '';
        this.value = '';
        this._dirty = false;
        // get form-related abilities
        this._internals = this.attachInternals();
    }
    // Handles input validation
    _isInvalid() {
        if (!this.value.trim()) {
            return 'Missing Value';
        }
        return '';
    }
    checkValidity() {
        this._dirty = true;
        const valid = this.validate();
        this._setValidity();
        return valid;
    }
    reportValidity() {
        return this.checkValidity();
    }
    get validity() {
        const error = this._isInvalid();
        const ret = {
            badInput: false,
            customError: Boolean(error),
            patternMismatch: false,
            rangeOverflow: false,
            rangeUnderflow: false,
            stepMismatch: false,
            tooLong: false,
            tooShort: false,
            typeMismatch: false,
            valid: !error,
            valueMissing: false
        };
        return ret;
    }
    get validationMessage() {
        return this._isInvalid();
    }
    _setValidity() {
        this._internals.setValidity(this.validity, this.validationMessage);
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            // wait for the DOM to update
            yield this.updateComplete;
            this._setValidity();
        });
    }
    _handleBlur() {
        const isInvalid = this._isInvalid();
        if (isInvalid && this._dirty) {
            this._error = isInvalid;
        }
        else {
            this._error = undefined;
        }
        this._setValidity();
    }
    validate() {
        this._handleBlur();
        const isInvalid = this._isInvalid();
        return !isInvalid;
    }
}
MadlibElement.formAssociated = true;
__decorate([
    property()
], MadlibElement.prototype, "key", void 0);
__decorate([
    property()
], MadlibElement.prototype, "hint", void 0);
__decorate([
    property()
], MadlibElement.prototype, "value", void 0);
__decorate([
    state()
], MadlibElement.prototype, "_internals", void 0);
__decorate([
    state()
], MadlibElement.prototype, "_dirty", void 0);
__decorate([
    state()
], MadlibElement.prototype, "_error", void 0);
