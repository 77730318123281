var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnButton } from 'lib/components/ColumnButton';
import React from 'react';
export const SuccessModal = ({ setOpen, gif, buttonText, onSubmit, header, body, footer, id, noExitOutsideModal, width, maxWidth, disableOverflow }) => {
    const handleExit = () => {
        if (noExitOutsideModal)
            return;
        setOpen && setOpen(false);
    };
    // TODO: we need to change the image URL to something in the codebase instead of URL
    return (_jsx("div", Object.assign({ id: id, className: `fixed z-100 inset-0 ${!disableOverflow ? 'overflow-y-auto' : ''}` }, { children: _jsxs("div", Object.assign({ className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" }, { children: [_jsx("div", Object.assign({ className: "fixed inset-0 transition-opacity", onClick: handleExit }, { children: _jsx("div", { className: "absolute inset-0 bg-black opacity-50" }) })), _jsx("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }), "\u200B", _jsx("div", Object.assign({ className: `inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth || 'sm:max-w-lg'} ${!!width && width} sm:w-full`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: `${id}-inner` }, { children: _jsx("div", Object.assign({ className: "p-14 border-b border-gray-300" }, { children: _jsxs("div", Object.assign({ className: `sm:mt-0 text-center` }, { children: [_jsx("div", Object.assign({ className: "flex justify-center" }, { children: gif })), header && (_jsx("h3", Object.assign({ className: "text-xl pt-10 leading-6 font-semibold text-gray-900", id: "modal-headline" }, { children: header }))), body && (_jsx("div", Object.assign({ className: "pt-4 pb-10" }, { children: _jsx("span", Object.assign({ className: "text-sm font-medium leading-5 text-gray-700" }, { children: body })) }))), _jsx(ColumnButton, { primary: true, buttonText: buttonText || 'Done', size: "xl", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        onSubmit && (yield onSubmit());
                                        setOpen && setOpen(false);
                                    }), type: "button" }), footer && (_jsx("div", Object.assign({ className: "pt-10" }, { children: _jsx("span", Object.assign({ className: "text-sm font-medium leading-5 text-gray-700" }, { children: footer })) })))] })) })) }))] })) })));
};
