import { jsx as _jsx } from "react/jsx-runtime";
import { TableLayout } from 'lib/components/TableLayout';
import { capitalizeWord } from 'lib/utils/strings';
import { useState } from 'react';
import { RegularDeadlineUpdateForm } from './Form';
import { RegularDeadlinesRow } from './TableRow';
export function RegularDeadlines({ productLine, deadlineSettings, timeZone, updateRow, configurable }) {
    const [rowToUpdate, setRowToUpdate] = useState();
    if (rowToUpdate) {
        return (_jsx(RegularDeadlineUpdateForm, { productLine: productLine, timeZone: timeZone, deadlineSettings: rowToUpdate, onClose: () => setRowToUpdate(undefined), onSubmit: updateRow }));
    }
    return (_jsx("div", Object.assign({ className: "pb-4 sm:rounded-lg" }, { children: _jsx(TableLayout, { filterable: { shouldShowTableItem: () => true, searchEnabled: false }, header: {
                title: `Regular ${capitalizeWord(productLine)} Deadlines`,
                subtitle: 'Set default deadline settings for each day of the week.'
            }, renderRow: deadlineSetting => (_jsx(RegularDeadlinesRow, { timeZone: timeZone, deadlineSetting: deadlineSetting })), editable: {
                onEdit: setRowToUpdate,
                editTooltip: `Edit`
            }, data: deadlineSettings !== null && deadlineSettings !== void 0 ? deadlineSettings : [], columns: [
                'Publication Day',
                'Publishing',
                'Deadline Day',
                'Deadline Time'
            ], pagination: { pageSize: 7 }, configurable: configurable }) })));
}
