import Joi from 'joi';
export const PAYWAY_SUCCESS_CODE = '5000';
export const PaywayCardAccountSchema = Joi.object({
    accountNotes1: Joi.string().allow(''),
    accountNotes2: Joi.string().allow(''),
    accountNotes3: Joi.string().allow(''),
    accountNumber: Joi.string().allow(''),
    address: Joi.string().allow(''),
    city: Joi.string().allow(''),
    email: Joi.string().allow(''),
    expirationDate: Joi.string().required(),
    firstName: Joi.string().required(),
    fsv: Joi.string().allow(''),
    lastName: Joi.string().required(),
    middleName: Joi.string().allow(''),
    phone: Joi.string().allow(''),
    state: Joi.string().allow(''),
    zip: Joi.string().required(),
    paywayToken: Joi.number()
}).options({ allowUnknown: true });
export const PaywayCardTransactionDataSchema = Joi.object({
    amount: Joi.number().required(),
    eciType: Joi.number().required(),
    name: Joi.string().required(),
    processorSoftDescriptor: Joi.string().allow(''),
    sourceId: Joi.number().required(),
    tax: Joi.number().required(),
    transactionNotes1: Joi.string().allow(''),
    transactionNotes2: Joi.string().allow(''),
    transactionNotes3: Joi.string().allow('')
}).options({ allowUnknown: true });
export const PaywayGatewaySessionResponseSchema = Joi.object({
    sessionToken: Joi.string().required(),
    gatewayTransactionData: PaywayCardTransactionDataSchema.required()
});
export const PaywayGatewayTransactionResponseSchema = Joi.object({
    paywayCode: Joi.string().required(),
    paywayMessage: Joi.string().required().allow(''),
    cardTransaction: PaywayCardTransactionDataSchema.required(),
    cardAccount: PaywayCardAccountSchema.required()
}).options({ allowUnknown: true });
