import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Route } from 'react-router';
import withFrame from 'layouts/withFrame';
import { FIREBASE_PROJECT, PRODUCTION_PROJECT } from 'lib/constants';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import AppFrame from '.';
import DocumentedRoute from './DocumentedRoute';
export default function AppRoute({ exact, path, component: WrappedComponent, hideFrame, Documentation }) {
    const ExtendedComponent = hideFrame
        ? WrappedComponent
        : withFrame(AppFrame, WrappedComponent);
    const showInAppDocs = useBooleanFlag(LaunchDarklyFlags.ENABLE_IN_APP_DOCS);
    // don't render docs in production or if we haven't explicitly enabled it using LD
    if (FIREBASE_PROJECT === PRODUCTION_PROJECT || !showInAppDocs) {
        return (_jsx(Route, { exact: exact, path: path, render: routeProps => _jsx(ExtendedComponent, Object.assign({}, routeProps)) }));
    }
    // inject MDX docs into the left hand side of the route
    return (_jsx(DocumentedRoute, Object.assign({}, { exact, path, Documentation, ExtendedComponent })));
}
