import { hasProperties, isObject, isSerializedField } from './typeCheckers';
export class DateSerializer {
    canSerialize(val) {
        return val instanceof Date;
    }
    canDeserialize(val) {
        return (isObject(val) &&
            isSerializedField(val, 'date') &&
            hasProperties(val, { name: 'time', type: 'number' }));
    }
    serialize(val) {
        return {
            __type: 'date',
            time: val.getTime()
        };
    }
    deserialize(ctx, val) {
        return new Date(val.time);
    }
}
