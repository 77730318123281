import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import SectionDivider from 'routes/placeScroll/SectionDivider';
import classNames from 'classnames';
import CustomerSearchOption from './CustomerSearchOption';
export default function CustomerSearchOptionsList({ searchableCustomers, onSelect, setUserTypedString }) {
    return (_jsx("div", Object.assign({ className: "max-h-64 pt-2 overflow-y-scroll" }, { children: searchableCustomers.map((searchableCustomer, index) => (_jsxs("div", Object.assign({ className: "hover:bg-column-gray-100" }, { children: [index !== 0 && (_jsx("div", Object.assign({ className: "pb-2 px-1" }, { children: _jsx(SectionDivider, {}) }))), _jsx("div", Object.assign({ className: classNames('pb-2 px-3', {
                        'pt-2': index === 0
                    }) }, { children: _jsx(CustomerSearchOption, { searchableCustomer: searchableCustomer, index: index, onSelect: onSelect, setUserTypedString: setUserTypedString }) }))] }), searchableCustomer.id))) })));
}
