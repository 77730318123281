var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { removeUndefinedFields } from 'lib/helpers';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnService } from 'lib/services/directory';
import { validateCompleteNewspaperOrder } from 'lib/types/newspaperOrder';
import { updateOrderPricing } from '../helpers/orderOperations';
import { hashString } from '../helpers/crypt';
const RECALCULATE_PRICE_DEBOUNCE_MS = 3000;
export const usePricing = ({ newspaperOrdersFormData, orderModel, adFormData, adModel, orderFormData, version }) => {
    const [consolidatedOrderPricing, setConsolidatedOrderPricing] = useState();
    const [priceIsStale, setPriceIsStale] = useState(true);
    const [orderDataCacheKey, setOrderDataCacheKey] = useState('');
    const [priceLoading, setPriceLoading] = useState(false);
    const [error, setError] = useState('');
    const handleRecalculateOrderPrice = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!orderModel) {
            return;
        }
        try {
            setError('');
            yield adModel.ref.update(removeUndefinedFields(adFormData));
            yield orderModel.ref.update(removeUndefinedFields(orderFormData));
            yield orderModel.updateNewspaperOrders(removeUndefinedFields(newspaperOrdersFormData), version);
            setPriceLoading(true);
            yield updateOrderPricing(orderModel.id, version);
            yield orderModel.refreshData();
            setConsolidatedOrderPricing(yield orderModel.getConsolidatedPricing({
                distributeFee: true,
                version
            }));
            setPriceIsStale(false);
            setPriceLoading(false);
        }
        catch (e) {
            setError('Failed to recalculate order price.  Please try again later.');
            logAndCaptureException(ColumnService.OBITS, e, 'Failed to recalculate order price', {
                orderId: orderModel.id
            });
        }
    });
    /**
     * Update the priceIsStale state when the form data changes and set a "cache key" that will be used to debounce
     * the recalculation of the order price.
     * We re-calculte the cache key whenever we change text, images or our layout.
     */
    useEffect(() => {
        if (!priceIsStale) {
            setPriceIsStale(true);
        }
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            for (const newspaperOrder of newspaperOrdersFormData) {
                if (!validateCompleteNewspaperOrder(newspaperOrder)) {
                    return;
                }
            }
            const orderCacheKey = (yield hashString(safeStringify(newspaperOrdersFormData) || '')) +
                (yield hashString(safeStringify(adFormData) || ''));
            setOrderDataCacheKey(orderCacheKey);
        }))();
    }, [
        safeStringify(newspaperOrdersFormData),
        safeStringify(adFormData),
        adFormData.filingTypeName
    ]);
    const debouncedCacheKey = useDebounce(orderDataCacheKey, RECALCULATE_PRICE_DEBOUNCE_MS);
    useEffect(() => {
        void handleRecalculateOrderPrice();
    }, [orderModel === null || orderModel === void 0 ? void 0 : orderModel.id, debouncedCacheKey]);
    return {
        priceIsStale,
        consolidatedOrderPricing,
        priceLoading,
        handleRecalculateOrderPrice,
        priceError: error
    };
};
