import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useAdForm } from '../contexts/AdFormStatusProvider';
function MilestoneTrackerWithTopProgressbar({ steps }) {
    const { currentStep } = useAdForm();
    const totalMilestones = steps.length;
    const progressWidth = ((currentStep - 1) / totalMilestones) * 100;
    return (_jsxs("div", Object.assign({ className: "grid w-full overflow-x-auto bg-white h-auto p-5 lg:border-none border-t border-column-grey-100" }, { children: [_jsx("div", Object.assign({ className: "h-1 mb-3 bg-column-gray-100 rounded" }, { children: _jsx("div", { style: {
                        width: `${progressWidth}%`,
                        transition: 'width 0.5s ease'
                    }, className: `bg-column-primary-500 h-full rounded` }) })), _jsx("div", Object.assign({ className: "flex flex-wrap justify-between min-w-full w-max mr-22 lg:mr-0" }, { children: steps.map((step, index) => {
                    const isActive = currentStep - 1 === index;
                    const isComplete = currentStep - 1 > index;
                    const isIncomplete = currentStep - 1 < index;
                    return (_jsxs("div", Object.assign({ className: classNames('flex items-center font-medium text-sm', {
                            'text-column-primary-500': isActive || isComplete,
                            'text-column-gray-300': isIncomplete
                        }) }, { children: [_jsxs("div", Object.assign({ className: "w-5" }, { children: [isComplete && _jsx(CheckIcon, {}), !isComplete && (_jsx("svg", Object.assign({ viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("circle", { cx: "12", cy: "12", r: "4", fill: "currentColor" }) })))] })), _jsx("span", Object.assign({ className: classNames({
                                    'text-column-gray-500': isActive
                                }) }, { children: step }))] }), index));
                }) }))] })));
}
export default MilestoneTrackerWithTopProgressbar;
