var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrganizationStatus } from 'lib/enums';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { getFirebaseContext } from 'utils/firebase';
export const createOrganization = ({ organizationType, name, address, addressLine2, city, state, zipCode, phone, createdBy, stripeId, userName, email }) => __awaiter(void 0, void 0, void 0, function* () {
    const organizationData = {
        organizationType,
        organizationStatus: OrganizationStatus.not_applicable.value,
        name,
        address,
        addressLine2: addressLine2 || '',
        city,
        state,
        zipCode,
        phone,
        createdBy,
        showInSearch: true
    };
    // Create stripe customer id for organization
    if (stripeId) {
        organizationData.stripeId = stripeId;
    }
    else {
        const createCustomer = getCreateCustomerFn();
        const result = yield createCustomer({
            name: userName,
            email,
            firestoreUID: createdBy !== null && createdBy !== void 0 ? createdBy : ''
        });
        organizationData.stripeId = result.data.stripeId;
    }
    // TODO: better typing for different types of organizations
    // Had to as any here because this org is missing
    // fields like fullPageTemplate which is not relevant for a govt
    const newlyCreatedOrganization = yield getFirebaseContext()
        .organizationsRef()
        .add(organizationData);
    return newlyCreatedOrganization;
});
