import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import moment from 'moment';
import { getNoticeText } from 'lib/notice/helpers';
export default function VerifyNoticesTableRow({ noticeAndRunData, runIdsToVerify, onCheckRun }) {
    const truncateContent = (content, length) => `${content.slice(0, length)}${content.length >= length ? '...' : ''}`;
    const { noticeModel: unverifiedNoticeModel, noticeRuns } = noticeAndRunData;
    return (_jsxs(_Fragment, { children: [_jsxs("td", Object.assign({ className: "pr-4" }, { children: [_jsx("p", Object.assign({ className: "text-column-gray-500 truncate" }, { children: truncateContent(unverifiedNoticeModel.modelData.referenceId || '', 30) })), _jsx("p", Object.assign({ className: "text-column-gray-400" }, { children: truncateContent(getNoticeText(unverifiedNoticeModel), 20) }))] })), _jsx("td", Object.assign({ className: "grid grid-cols-3 gap-2 py-3" }, { children: noticeRuns
                    .sort((run1, run2) => run1.modelData.publicationDate.localeCompare(run2.modelData.publicationDate))
                    .map(run => (_jsx(CheckboxInput, { checked: runIdsToVerify.has(run.id), disabled: run.isVerified(), onChange: checked => onCheckRun(run.id, checked), labelText: moment(run.modelData.publicationDate, 'YYYY-MM-DD').format('M/D/YY'), id: `${run.id}-manual-verification-checkbox`, inputPosition: "left" }, run.id))) }))] }));
}
