import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PlacementActions, { selectConfirmedCrop } from 'redux/placement';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { safeStringify } from 'lib/utils/stringify';
export default function PDFBounder({ src, aspectRatio }) {
    const dispatch = useAppDispatch();
    const confirmedCrop = useAppSelector(selectConfirmedCrop);
    const defaultCrop = {
        height: 100,
        width: !aspectRatio ? 100 : undefined,
        absHeight: 1,
        absWidth: 1,
        unit: '%',
        aspect: aspectRatio,
        x: 0,
        y: 0
    };
    const [crop, setCrop] = useState(confirmedCrop || defaultCrop);
    const debouncedCrop = useDebounce(crop, 1000);
    useEffect(() => {
        dispatch(PlacementActions.setConfirmedCrop(debouncedCrop));
    }, [src, safeStringify(debouncedCrop)]);
    // When the fixed aspect ratio changes due to a new modular size selection, reset the crop tool
    useEffect(() => {
        if (aspectRatio && aspectRatio !== (confirmedCrop === null || confirmedCrop === void 0 ? void 0 : confirmedCrop.aspect)) {
            setCrop(defaultCrop);
        }
    }, [aspectRatio]);
    return (_jsx(ReactCrop, { src: src, crop: crop, keepSelection: true, onChange: (cropAbs, cropPct) => {
            setCrop(Object.assign(Object.assign({}, cropPct), { absWidth: cropAbs.width, absHeight: cropAbs.height }));
        } }));
}
