var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { DocumentDuplicateIcon, TrashIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { logAndCaptureException } from 'utils';
import { duplicateNotice } from 'utils/duplicateNotice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ActionsPopover } from 'lib/components/ActionsPopover';
import { ColumnService } from 'lib/services/directory';
import DeleteDraftModal from './DeleteDraftModal';
var TableAction;
(function (TableAction) {
    TableAction[TableAction["DELETE_DRAFT"] = 0] = "DELETE_DRAFT";
    TableAction[TableAction["DUPLICATE_NOTICE"] = 1] = "DUPLICATE_NOTICE";
    TableAction[TableAction["OPEN_NOTICE"] = 2] = "OPEN_NOTICE";
})(TableAction || (TableAction = {}));
function NoticeActionOption({ action, onClick }) {
    const optionClasses = 'flex flex-row items-center gap-2 cursor-pointer rounded p-3 hover:bg-column-primary-50 hover:text-column-primary-500 focus:outline-none transition ease-in duration-100';
    if (action === TableAction.OPEN_NOTICE) {
        return (_jsxs("div", Object.assign({ className: optionClasses, onClick: onClick }, { children: [_jsx(ArrowUpRightIcon, { className: "w-4 h-4" }), _jsx("span", { children: "Open" })] })));
    }
    if (action === TableAction.DELETE_DRAFT) {
        return (_jsxs("div", Object.assign({ className: optionClasses, onClick: onClick }, { children: [_jsx(TrashIcon, { className: "w-4 h-4" }), _jsx("span", { children: "Delete" })] })));
    }
    if (action === TableAction.DUPLICATE_NOTICE) {
        return (_jsxs("div", Object.assign({ className: optionClasses, onClick: onClick }, { children: [_jsx(DocumentDuplicateIcon, { className: "w-4 h-4" }), _jsx("span", { children: "Duplicate" })] })));
    }
    return _jsx(_Fragment, {});
}
export default function TableActions({ row }) {
    const [noticeToDelete, setNoticeToDelete] = useState();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const [duplicating, setDuplicating] = useState(false);
    const { original } = row;
    const availableActions = original.isdraft
        ? [TableAction.OPEN_NOTICE, TableAction.DELETE_DRAFT]
        : [TableAction.OPEN_NOTICE, TableAction.DUPLICATE_NOTICE];
    const handleDuplicateNoticeClick = () => __awaiter(this, void 0, void 0, function* () {
        if (!user) {
            console.warn('Cannot duplicate notice, user is null');
            return;
        }
        if (duplicating) {
            return;
        }
        const noticeId = original.id;
        try {
            setDuplicating(true);
            const { newNoticeRef } = yield duplicateNotice(noticeId, user);
            dispatch(push(`/place/${newNoticeRef.id}?duplicate=true`));
        }
        catch (e) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Error duplicating notice', {
                noticeId
            });
        }
        finally {
            setDuplicating(false);
        }
    });
    const handleActionClick = (action) => {
        switch (action) {
            case TableAction.OPEN_NOTICE:
                if (original.isdraft) {
                    dispatch(push(`/place/${original.orginalnoticeid}`));
                }
                else {
                    dispatch(push(`/notice/${original.id}`));
                }
                return;
            case TableAction.DUPLICATE_NOTICE:
                return handleDuplicateNoticeClick();
            case TableAction.DELETE_DRAFT:
                return setNoticeToDelete(original);
        }
    };
    const actionLoading = duplicating;
    return (_jsxs(_Fragment, { children: [noticeToDelete && (_jsx(DeleteDraftModal, { noticeToDelete: noticeToDelete, handleClose: () => setNoticeToDelete(undefined), setOpen: () => setNoticeToDelete(undefined), open: !!noticeToDelete })), actionLoading && (_jsx("div", Object.assign({ className: "ml-8" }, { children: _jsx(LoadingSpinner, { inline: true, size: "sm" }) }))), !actionLoading && (_jsx(ActionsPopover, Object.assign({ id: `actions-popover-${row.original.id}` }, { children: _jsx("div", Object.assign({ className: "text-sm" }, { children: availableActions.map(a => (_jsx(NoticeActionOption, { action: a, notice: row.original, onClick: () => handleActionClick(a) }, a))) })) })))] }));
}
