var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import { ArrowTopRightOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { logAndCaptureException } from 'utils';
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import classNames from 'classnames';
import { ColumnService } from 'lib/services/directory';
import { PpiSelect } from './metadata/PpiSelect';
import { getMetaFilesFromFiles, isJpgFileName } from './metadata/helpers';
const UPLOAD_FILE_MESSAGE = 'Uploading...';
const DELETE_FILE_MESSAGE = 'Deleting...';
export function AttachmentUploadField(props) {
    const [error, setError] = useState();
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [deleting, setDeleting] = useState(false);
    // State used to lock features of this component until a rerender
    const [waitingForUpdatedAttachments, setWaitingForUpdatedAttachments] = useState(false);
    const { id: uploadFieldId, multiSelect, readOnly, loading: parentLoading, acceptFileTypes, uploadedAttachments, onUpdateMetadata, onUploadFiles, onDeleteAttachment } = props;
    const loading = waitingForUpdatedAttachments ||
        parentLoading ||
        !!deleting ||
        !!filesToUpload.length;
    useEffect(() => {
        if (parentLoading === true)
            return;
        setWaitingForUpdatedAttachments(false);
        setDeleting(false);
        setFilesToUpload([]);
    }, [parentLoading]);
    const onFileDrop = (files) => __awaiter(this, void 0, void 0, function* () {
        setError('');
        const filesWithMetadata = getMetaFilesFromFiles(files);
        setFilesToUpload(filesWithMetadata);
        setWaitingForUpdatedAttachments(true);
        try {
            yield onUploadFiles(uploadFieldId, filesWithMetadata);
        }
        catch (error) {
            logAndCaptureException(ColumnService.PAGINATION, error, 'Failed to upload file', {
                uploadFieldId
            });
            setError('Failed to upload file');
        }
    });
    const onPopOutClick = (url) => {
        window.open(url);
    };
    const onDeleteClick = (attachmentId) => __awaiter(this, void 0, void 0, function* () {
        setDeleting(true);
        setWaitingForUpdatedAttachments(true);
        yield onDeleteAttachment(uploadFieldId, attachmentId);
    });
    return (_jsxs(_Fragment, { children: [error && (_jsx(Alert, { id: "uploadModalError", status: "error", title: "Something went wrong", description: error })), _jsx(FileDropzone, { id: uploadFieldId, multiple: multiSelect, acceptFileTypes: acceptFileTypes, disabled: readOnly, onDrop: (file) => __awaiter(this, void 0, void 0, function* () {
                    yield onFileDrop(file);
                }) }), deleting && (_jsx("div", Object.assign({ className: "flex flex-row items-center mt-2 space-x-2" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3" }, { children: [_jsx("div", Object.assign({ className: "flex pl-3 items-center justify-center rounded-b" }, { children: _jsx(LoadingSpinner, {}) })), _jsx(Alert, { id: `${uploadFieldId}-deleting-message`, status: "info", title: DELETE_FILE_MESSAGE })] })) }))), filesToUpload &&
                filesToUpload.map((fileToUpload, index) => (_jsx("div", Object.assign({ className: "text-sm" }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center mt-2 space-x-2" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3" }, { children: [_jsx("div", Object.assign({ className: "flex pl-3 items-center justify-center rounded-b" }, { children: _jsx(LoadingSpinner, {}) })), _jsx(Alert, { id: `${uploadFieldId}-upload-message`, status: "info", title: UPLOAD_FILE_MESSAGE })] })) })) }), `${uploadFieldId} uploading-file ${index}`))), uploadedAttachments &&
                uploadedAttachments.map((uploadedAttachment, index) => (_jsxs("div", Object.assign({ className: `text-sm flex justify-between` }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row items-center space-x-2" }, { children: [_jsx("div", Object.assign({ className: "border border-column-gray-100 align-middle rounded-md bg-column-gray-50 text-column-gray-400 flex items-center pl-3.5 min-h-11 w-full py-3" }, { children: uploadedAttachment.name })), _jsx(ArrowTopRightOnSquareIcon, { className: "bg-column-primary-50 h-11 w-12 rounded-md text-column-primary-500 p-2 cursor-pointer", onClick: () => onPopOutClick(uploadedAttachment.url) }), _jsx(TrashIcon, { className: classNames('bg-column-red-50 h-11 w-12 rounded-md text-column-red-500 p-2', {
                                        'pointer-events-none': loading || readOnly,
                                        'cursor-pointer': !loading && !readOnly
                                    }), onClick: () => onDeleteClick(uploadedAttachment.id), "aria-disabled": loading || readOnly })] })), isJpgFileName(uploadedAttachment === null || uploadedAttachment === void 0 ? void 0 : uploadedAttachment.name) && (_jsx("div", Object.assign({ className: "flex items-center mb-8" }, { children: _jsx(PpiSelect, { uploadFieldId: uploadFieldId, attachmentId: uploadedAttachment.id, metadata: uploadedAttachment.metadata, onChange: onUpdateMetadata, disabled: readOnly }) })))] }), `${uploadFieldId} uploaded-file ${index}`)))] }));
}
