var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SnapshotModel, getModelFromRef } from '..';
import { Collections } from '../../constants';
import { isVerifiedRunStatus, isDisabledRunStatus, isUnverifiableRunStatus, isCancelledRunStatus, isVerifiableRunStatus } from '../../services/runService';
import { RUN_STATUS_CHANGE } from '../../types/events';
import { wrapSuccess, wrapError } from '../../types/responses';
import { RunStatusType } from '../../types/runs';
import { PublicationIssueModel } from './publicationIssueModel';
import { UserNoticeModel } from './userNoticeModel';
export class RunModel extends SnapshotModel {
    get type() {
        return Collections.runs;
    }
    isVerifiable() {
        return isVerifiableRunStatus(this.modelData.status);
    }
    isVerified() {
        return isVerifiedRunStatus(this.modelData.status);
    }
    isUnverifiable() {
        return isUnverifiableRunStatus(this.modelData.status);
    }
    isDisabled() {
        return isDisabledRunStatus(this.modelData.status);
    }
    isCancelled() {
        return isCancelledRunStatus(this.modelData.status);
    }
    getNotice() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { notice: noticeRef } = this.modelData;
                const notice = yield getModelFromRef(UserNoticeModel, this.ctx, noticeRef);
                return wrapSuccess(notice);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    getPublicationIssue() {
        return __awaiter(this, void 0, void 0, function* () {
            return PublicationIssueModel.fromRef(this.ctx, this.modelData.publicationIssue);
        });
    }
    getStatusChanges(sortOrder) {
        return __awaiter(this, void 0, void 0, function* () {
            const allStatusChanges = yield this.ctx
                .eventsRef()
                .where('type', '==', RUN_STATUS_CHANGE)
                .where('ref', '==', this.ref)
                .orderBy('createdAt', sortOrder)
                .get();
            return allStatusChanges.docs;
        });
    }
    addStatusChange(newStatusData) {
        return __awaiter(this, void 0, void 0, function* () {
            const { statusChangedAt, statusChangedBy, status } = newStatusData, data = __rest(newStatusData, ["statusChangedAt", "statusChangedBy", "status"]);
            yield this.ctx.eventsRef().add(Object.assign(Object.assign({ createdAt: statusChangedAt || this.ctx.fieldValue().serverTimestamp(), ref: this.ref, type: RUN_STATUS_CHANGE, status }, (statusChangedBy ? { statusChangedBy } : {})), (data ? { data } : { data: {} })));
        });
    }
    updateStatus(newStatusData) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isDisabled() &&
                ![
                    RunStatusType.PENDING,
                    RunStatusType.CANCELLED,
                    RunStatusType.DISABLED
                ].includes(newStatusData.status)) {
                throw new Error('Run must be enabled before its verification status can be set');
            }
            yield this.addStatusChange(newStatusData);
            const { notice, publicationIssue, publicationDate } = this.modelData;
            yield this.set(Object.assign({ notice,
                publicationIssue,
                publicationDate }, newStatusData));
        });
    }
}
