var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import { OccupationType } from '../../enums';
import { safeGetOrThrow } from '../../safeWrappers';
import { InviteService } from '../../services/inviteService';
import { wrapError, wrapSuccess } from '../../types/responses';
import { OrganizationModel } from './organizationModel';
export class UserModel extends SnapshotModel {
    get type() {
        return Collections.users;
    }
    get fullName() {
        return `${this.modelData.firstName} ${this.modelData.lastName}`;
    }
    get isPublisher() {
        return (this.modelData.occupation === OccupationType.publishing.value ||
            this.modelData.occupation ===
                OccupationType.press_association_manager.value);
    }
    isOccupationType(type) {
        return this.modelData.occupation === type.value;
    }
    get isColumnUser() {
        const allowedDomains = ['column.us', 'enotice.io'];
        return allowedDomains.some(domain => this.modelData.email.endsWith(domain));
    }
    getOrCreateInvite(customerData, organization, publisher) {
        return __awaiter(this, void 0, void 0, function* () {
            const inviteService = new InviteService(this.ctx);
            const outstandingInvite = yield inviteService.maybeGetOutstandingInvite(this);
            if (outstandingInvite) {
                return outstandingInvite;
            }
            return inviteService.createInvite(customerData.email, organization, publisher);
        });
    }
    getActiveOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: activeOrgSnap, error: activeOrgSnapError } = yield safeGetOrThrow(this.modelData.activeOrganization);
            if (activeOrgSnapError) {
                return wrapError(activeOrgSnapError);
            }
            return wrapSuccess(getModelFromSnapshot(OrganizationModel, this.ctx, activeOrgSnap));
        });
    }
}
