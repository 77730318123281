var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'lib/components/Form';
import { getOrganizationGateway } from 'lib/billing/gateways';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { exists } from 'lib/types';
import React from 'react';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { getFirebaseContext } from 'utils/firebase';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { CardGridLayout, GridInput, useEditableCard } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { AllowedPaymentMethods } from './AllowedPaymentMethods';
import { DistributeFeeSettings } from './DistributeFeeSettings';
import { InheritableSettingSelectInput } from '../../InheritableSettingSelectInput';
const getImplementationSettingsFromOrg = (organization) => {
    if (!exists(organization)) {
        return null;
    }
    const { allowInvoiceOutsideColumn, sendInvoiceNotificationsForIOCNotices, disableControlUpfrontPayment, restrictPaymentMethods, distributeEnoticeFee } = organization.data();
    return {
        allowInvoiceOutsideColumn,
        sendInvoiceNotificationsForIOCNotices,
        disableControlUpfrontPayment,
        restrictPaymentMethods,
        distributeEnoticeFee
    };
};
export function ImplementationBillingSettings() {
    var _a;
    const activeOrganization = useActiveOrganizationListener();
    const currentSettings = getImplementationSettingsFromOrg(activeOrganization);
    const { editable, onChange, disabled, onSubmitWrapper, formSettings } = useEditableCard(currentSettings);
    const { value: gateway } = useAsyncEffect({
        fetchData: () => getOrganizationGateway(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref),
        dependencies: [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id, (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.gateway]
    });
    // Shouldn't be possible, but needed for type narrowing.
    if (!activeOrganization || !formSettings) {
        return null;
    }
    return (_jsx(Form, Object.assign({ id: "publisher-settings-billing-implementation", onSubmit: () => onSubmitWrapper(() => __awaiter(this, void 0, void 0, function* () {
            yield activeOrganization.ref.update(replaceUndefinedWithDelete(getFirebaseContext(), formSettings));
            return { success: true };
        })), "aria-label": "Implementation Settings" }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'Implementation Settings',
                description: 'Configure implementation-only billing settings.'
            }, editable: editable }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow invoicing outside of Column?", description: "If enabled, the publisher can enable IOC for customers, notice types, and notices.", value: !!formSettings.allowInvoiceOutsideColumn, onChange: allowInvoiceOutsideColumn => onChange({ allowInvoiceOutsideColumn }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InheritableSettingSelectInput, { id: "exclusive-ioc-and-rup-setting", labelText: 'Send invoices for IOC notices?', optionText: {
                            yesLabel: 'Yes — send customers emails with invoice PDFs for notices marked IOC',
                            noLabel: 'No — do not send customers emails for notices marked IOC',
                            defaultLabel: 'Default — use default settings on parent (if any)'
                        }, value: formSettings.sendInvoiceNotificationsForIOCNotices, onChange: sendInvoiceNotificationsForIOCNotices => onChange({ sendInvoiceNotificationsForIOCNotices }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InheritableSettingSelectInput, { id: "invoice-outside-column-email-setting", labelText: 'Make upfront payment and IOC mutually exclusive?', optionText: {
                            yesLabel: 'Yes — users cannot create invoices where RUP and IOC are both true or both false',
                            noLabel: 'No — users can create invoices with any combination of RUP and IOC',
                            defaultLabel: 'Default — use default settings on parent (if any)'
                        }, value: formSettings.disableControlUpfrontPayment, onChange: disableControlUpfrontPayment => onChange({ disableControlUpfrontPayment }), disabled: disabled }) })), _jsx(DistributeFeeSettings, { distributeEnoticeFee: formSettings.distributeEnoticeFee, onChange: distributeEnoticeFee => onChange({ distributeEnoticeFee }), disabled: disabled }), gateway === 'stripe' && (_jsx(AllowedPaymentMethods, { restrictPaymentMethods: formSettings.restrictPaymentMethods, onChange: restrictPaymentMethods => onChange({ restrictPaymentMethods }), disabled: disabled }))] })) })));
}
