var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { requestDisplayParameters } from 'lib/indesign/request';
import { slackInvalidHtml, validateHtml } from 'components/noticePreview/indesign_client_utils';
import { generateFormattedFooter } from 'lib/headers_footers/footers';
import PlacementActions, { placementSelector, selectCurrentStepId, selectPreviewContent } from 'redux/placement';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getFirebaseContext } from 'utils/firebase';
import { getPartialNoticeFromPlacement } from 'utils/dataCleaning';
import { getIndesignServerClient } from 'utils/indesign';
import { getOrThrow } from 'lib/utils/refs';
import { createDBPricingObject } from 'lib/pricing';
import { createDbPricingObjFromPlacement } from 'utils/pricing';
import { getBooleanFlag } from 'utils/flags';
import { dbToUICurrency } from 'lib/pricing/ui';
import { Product } from 'lib/enums';
import { selectColumnCountRangeConfig } from '../placeScrollSelectors';
import { CONFIRM_AD, CONFIRM_PROOF, CONFIRM_PUBLISHER, SCHEDULE_NOTICE } from '../helpers/calculatePlacementSteps';
/**
 * This hook is responsible for updating the preview content in the sidebar
 * and generating the final display params for the notice.
 *
 * TODO: It is in significant need of refactoring, and likely most of this logic should
 * be handled in a thunk or encapsulated in a library, and the string of useEffects
 * should be reorganized for clarity and performance.
 */
export function useUpdatePreview({ newspaper, adTemplate }) {
    var _a, _b, _c, _d, _e, _f;
    const dispatch = useAppDispatch();
    const currentStepId = useAppSelector(selectCurrentStepId);
    const readyToFetch = [
        CONFIRM_AD,
        CONFIRM_PUBLISHER,
        SCHEDULE_NOTICE,
        CONFIRM_PROOF
    ].includes(currentStepId);
    const placement = useAppSelector(placementSelector);
    const isDisplay = !!placement.processedDisplay;
    const previewContent = useAppSelector(selectPreviewContent);
    const [loading, setLoading] = useState(false);
    const [completedInit, setCompletedInit] = useState(false);
    const [optimizeColumns, setOptimizeColumns] = useState(false);
    const debouncedPlacement = useDebounce(placement, 500);
    const newspaperSnap = newspaper;
    const { maxColumns } = useAppSelector(state => selectColumnCountRangeConfig(state, newspaperSnap));
    const shouldStopAutoIncreaseColumnToOptimalColumnWidth = getBooleanFlag(LaunchDarklyFlags.STOP_AUTO_INCREASE_COLUMN_WIDTH, false);
    useEffect(() => {
        // Manage Large Files Error Modals and Warnings
        // TODO: Investigate ways to consolidate formatting error logic with NoticeContentStep
        // TODO: Complete deprecation of placement.continueWithLargeFile
        if (!placement.processedDisplay &&
            placement.formattingError &&
            !placement.postWithoutFormatting) {
            // TODO: Remove this condition when we can stop parsing of already processed files
            if (!placement.confirmedHtml) {
                dispatch(PlacementActions.setShowPlacementErrors({ largeFile: true }));
            }
        }
    }, [placement.formattingError]);
    const updatePreview = (
    /**
     * Because this function is often called multiple times in the placement flow via useEffects, it
     * is possible that we could accidentally set stale displayParams due to race conditions.
     * Luckily useEffect has a built in solution for this:
     * https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
     *
     * This function tells us if the useEffect is still fresh or stale before we set display params.
     */
    shouldSetDisplayParams) => __awaiter(this, void 0, void 0, function* () {
        var _g, _h, _j;
        if (!placement.draft)
            return;
        if (!(newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.ref))
            return;
        let notice = yield placement.draft.get();
        try {
            if ((!placement.processedDisplay && !placement.confirmedHtml) ||
                (placement.processedDisplay && !placement.displayParams)) {
                // Clear old preview if it exists
                if (previewContent.displayParams) {
                    dispatch(PlacementActions.clearPreviewContent());
                }
                return;
            }
            if (!adTemplate)
                return;
            if (!placement.publicationDates)
                return;
            if (!((_g = notice.data()) === null || _g === void 0 ? void 0 : _g.publicationDates))
                return;
            setLoading(true);
            // Try to generate pricing from placement but ignore errors
            // which often occur if things are not configred
            let pricing;
            try {
                pricing = yield createDbPricingObjFromPlacement(placement, Product.Notice);
            }
            catch (e) {
                // Ignore..
            }
            const footer = yield generateFormattedFooter(getFirebaseContext(), getPartialNoticeFromPlacement(placement), pricing, window.DOMParser);
            yield notice.ref.update(Object.assign({ dynamicFooter: footer, adTemplate: adTemplate.ref, newspaper: newspaperSnap.ref, columns: placement.columns, publicationDates: placement.publicationDates, dynamicHeaders: placement.dynamicHeaders || null }, (placement.headerText && { headerText: placement.headerText })));
            let finalDisplayParams;
            if (!placement.processedDisplay &&
                !placement.continueWithLargeFile &&
                placement.confirmedHtml) {
                const valid = validateHtml(placement.confirmedHtml);
                if (!valid) {
                    dispatch(PlacementActions.setShowPlacementErrors({ largeFile: true }));
                    setLoading(false);
                    void slackInvalidHtml(notice);
                    return;
                }
                notice = yield placement.draft.get(); // replace with onsnapshot
                finalDisplayParams = yield requestDisplayParameters(getFirebaseContext(), getIndesignServerClient(), notice, window.DOMParser, { optimizeColumns, resizeTextFramesForProofPDF: false });
            }
            else {
                finalDisplayParams = placement.displayParams;
            }
            /**
             * When indesign returns columns greater than the than maxiumum limit of newspaper,
             * still set the width warning to true to calculate the optimised column and set the
             * column to max of newspaper if optimised is greater than the max width a newspaper allows
             */
            const showWidthWarning = finalDisplayParams.isTableOverflow ||
                finalDisplayParams.areAnyTablesTooWide ||
                finalDisplayParams.columns > maxColumns;
            if (!shouldStopAutoIncreaseColumnToOptimalColumnWidth) {
                setOptimizeColumns(showWidthWarning && finalDisplayParams.columns <= maxColumns);
            }
            // When optimizing columns, adjust if the server returned a greater number
            if (optimizeColumns) {
                const originalColumns = ((_h = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _h === void 0 ? void 0 : _h.columns) || 1;
                const finalColumns = finalDisplayParams.columns;
                if (finalColumns > originalColumns && finalColumns <= maxColumns) {
                    dispatch(PlacementActions.setColumns(finalColumns));
                }
                else {
                    dispatch(PlacementActions.setColumns(maxColumns));
                }
            }
            if (isDisplay && !finalDisplayParams.height) {
                setLoading(false);
                return;
            }
            const noticeSnap = yield getOrThrow(notice.ref);
            const dbPricingObject = yield createDBPricingObject(getFirebaseContext(), noticeSnap, finalDisplayParams, (_j = placement.rate) !== null && _j !== void 0 ? _j : undefined);
            if (shouldSetDisplayParams()) {
                const price = dbToUICurrency(dbPricingObject.total).toFixed(2);
                dispatch(PlacementActions.setPreviewContent({
                    displayParams: finalDisplayParams,
                    price
                }));
            }
            if (!isDisplay && shouldSetDisplayParams()) {
                dispatch(PlacementActions.setDisplayParams(finalDisplayParams));
            }
            if (!completedInit)
                setCompletedInit(true);
        }
        catch (err) {
            console.error(err);
        }
        setLoading(false);
    });
    useEffect(() => {
        if (!readyToFetch)
            return;
        let shouldSet = true;
        const shouldSetDisplayParams = () => shouldSet;
        void updatePreview(shouldSetDisplayParams);
        return () => {
            shouldSet = false;
        };
    }, [
        debouncedPlacement.confirmedHtml,
        debouncedPlacement.columns,
        isDisplay && debouncedPlacement.displayParams,
        (_b = (_a = debouncedPlacement.draftSnap) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.noticeType,
        debouncedPlacement.publicationDates,
        (_c = debouncedPlacement.rate) === null || _c === void 0 ? void 0 : _c.id,
        debouncedPlacement.dynamicHeaders,
        (_d = debouncedPlacement.adTemplate) === null || _d === void 0 ? void 0 : _d.id,
        debouncedPlacement.headerText
    ]);
    useEffect(() => {
        // Clear the price when the newspaper switches mid-placement
        if (previewContent.displayParams) {
            dispatch(PlacementActions.clearPreviewContent());
        }
    }, [(_e = debouncedPlacement.newspaper) === null || _e === void 0 ? void 0 : _e.id, debouncedPlacement.noticeType]);
    useEffect(() => {
        if (!readyToFetch)
            return;
        if (optimizeColumns) {
            let shouldSet = true;
            const shouldSetDisplayParams = () => shouldSet;
            void updatePreview(shouldSetDisplayParams);
            return () => {
                shouldSet = false;
            };
        }
    }, [optimizeColumns]);
    useEffect(() => {
        // Fetch ID preview on intial scroll to step
        if (completedInit)
            return;
        let shouldSet = true;
        const shouldSetDisplayParams = () => shouldSet;
        void updatePreview(shouldSetDisplayParams);
        return () => {
            shouldSet = false;
        };
    }, [readyToFetch]);
    useEffect(() => {
        var _a;
        // Fetch ID preview after rate update
        if (!((_a = placement.rate) === null || _a === void 0 ? void 0 : _a.id))
            return;
        setCompletedInit(false);
    }, [(_f = placement.rate) === null || _f === void 0 ? void 0 : _f.id]);
    return loading;
}
