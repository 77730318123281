import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import RegisterIndividualHeader from './RegisterIndividualHeader';
import RegisterIndividualForm from './RegisterIndividualForm';
export default function RegisterIndividual() {
    const user = useAppSelector(selectUser);
    if (!exists(user))
        return _jsx(LoadingState, {});
    return (_jsxs("div", Object.assign({ className: "w-200 mx-auto" }, { children: [_jsx(RegisterIndividualHeader, {}), _jsx(RegisterIndividualForm, { user: user })] })));
}
