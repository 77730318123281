import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
export function UploadFlorida({ value, onChange, disabled }) {
    const onChangePublisherLogin = (publisherLogin) => {
        onChange({
            publisherLogin: Object.assign(Object.assign({}, value.publisherLogin), publisherLogin)
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { labelText: "Newspaper ID", noteText: "A numeric code used by Quion to link uploads to the right newspaper.", value: value.newspaperCode, onChange: newspaperCode => onChange({ newspaperCode }), disabled: disabled, id: "upload-external-newspaper-code", required: true }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { labelText: "Username", noteText: "The newspaper's username used to authenticate FTP uploads", value: value.publisherLogin.user, onChange: user => onChangePublisherLogin({ user }), disabled: disabled, id: "upload-external-username", required: true }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { labelText: "Password", noteText: "The newspaper's password used to authenticate FTP uploads", value: value.publisherLogin.password, onChange: password => onChangePublisherLogin({ password }), disabled: disabled, id: "upload-external-password", required: true }) }))] }));
}
