var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { STRIPE } from '../constants';
import { getInheritedData } from '../utils/inheritance';
import { maybeGetXMLSyncExportSettings } from '../helpers';
import { getOrThrow } from '../utils/refs';
export const getOrganizationGateway = (organization) => __awaiter(void 0, void 0, void 0, function* () {
    if (!organization) {
        return STRIPE;
    }
    const { gateway } = yield getInheritedData(organization);
    if (gateway) {
        return gateway;
    }
    return STRIPE;
});
export const getOrganizationExportFormatFromInvoice = (invoice) => __awaiter(void 0, void 0, void 0, function* () {
    const organization = yield getOrThrow(invoice.data().organization);
    const xmlSyncExportSettings = yield maybeGetXMLSyncExportSettings(organization);
    if (!xmlSyncExportSettings) {
        throw new Error(`Unable to retrieve payway source ID from invoice ${invoice.id} because unable to retrieve XML sync export settings for organization ${organization.id}`);
    }
    const { format } = xmlSyncExportSettings;
    return format;
});
