import { BillingStatusType, NoticeType } from 'lib/enums';
import moment from 'moment';
export const VALUE_SHOW_ALL = 'show-all';
export const DEFAULT_NOTICE_FILTERS = {
    status: VALUE_SHOW_ALL,
    invoice: VALUE_SHOW_ALL,
    affidavit: VALUE_SHOW_ALL,
    publicationDate: VALUE_SHOW_ALL,
    sync: VALUE_SHOW_ALL,
    noticeCategory: VALUE_SHOW_ALL
};
export const STATUS_OPTIONS = [
    { label: 'Show all', value: VALUE_SHOW_ALL },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Unconfirmed', value: 'unconfirmed' }
];
export const INVOICE_OPTIONS_PUBLISHER = [
    { label: 'Show all', value: VALUE_SHOW_ALL },
    { label: 'Awaiting invoice creation', value: 'awaiting-invoice-creation' },
    { label: 'Awaiting client payment', value: 'awaiting-client-payment' },
    { label: 'Invoice paid', value: 'invoice-paid' }
];
export const INVOICE_OPTIONS_ADVERTISER = [
    { label: 'Show all', value: VALUE_SHOW_ALL },
    { label: 'Unpaid', value: 'awaiting-client-payment' },
    { label: 'Invoice paid', value: 'invoice-paid' },
    {
        label: 'Awaiting invoice creation',
        value: 'awaiting-invoice-creation'
    }
];
export const AFFIDAVIT_OPTIONS = [
    { label: 'Show all', value: VALUE_SHOW_ALL },
    { label: 'Awaiting final publication', value: 'awaiting-final-publication' },
    { label: 'Incomplete', value: 'incomplete' },
    { label: 'Submitted', value: 'submitted' }
];
export const PUBLICATION_DATE_OPTIONS = [
    { label: 'All time', value: VALUE_SHOW_ALL },
    { label: 'This month', value: 'this-month' },
    { label: 'Last month', value: 'last-month' },
    { label: 'Custom date', value: 'custom-date' }
];
export const SYNC_OPTIONS = [
    { label: 'All', value: VALUE_SHOW_ALL },
    { label: 'Success', value: 'success' },
    { label: 'Failure', value: 'failure' },
    { label: 'Requires attention', value: 'requires-attention' }
];
export const NOTICE_CATEGORY_OPTIONS = [
    { label: 'Show all', value: VALUE_SHOW_ALL },
    { label: 'Liner', value: 'liner' },
    { label: 'Display', value: 'display' }
];
export const getSearchableNoticeRecordFilters = (filterValue) => {
    // Filters which are AND-ed together
    const filters = [];
    // Filters which are OR-ed together
    const anyFilters = [];
    // Filters which must not match
    const noneFilters = [];
    switch (filterValue.status) {
        case 'confirmed':
            filters.push({ isconfirmed: [1] });
            break;
        case 'unconfirmed':
            filters.push({ isconfirmed: [0] });
            break;
    }
    switch (filterValue.invoice) {
        case 'awaiting-invoice-creation':
            filters.push({
                publisherbillingstatusvalue: [
                    BillingStatusType.invoice_not_submitted.value
                ]
            });
            break;
        case 'awaiting-client-payment':
            filters.push({
                publisherbillingstatusvalue: [
                    BillingStatusType.invoice_submitted_to_advertiser.value,
                    BillingStatusType.payment_initiated.value,
                    BillingStatusType.payment_failed.value,
                    BillingStatusType.payment_refunded.value
                ]
            });
            break;
        case 'invoice-paid':
            filters.push({
                publisherbillingstatusvalue: [
                    BillingStatusType.invoice_paid_by_advertiser.value,
                    BillingStatusType.invoiced_outside_column.value,
                    BillingStatusType.transfer_created.value
                ]
            });
            break;
    }
    switch (filterValue.affidavit) {
        case 'awaiting-final-publication':
            // The last publication date is in the future.
            filters.push({
                lastpublicationtimestamp: {
                    from: moment().startOf('day').toDate().getTime()
                }
            });
            break;
        case 'incomplete':
            // The last publication date is in the past and the affidavit
            // has not been submitted.
            filters.push({
                affidavitsubmitted: [0]
            }, {
                lastpublicationtimestamp: {
                    to: moment().startOf('day').toDate().getTime()
                }
            });
            break;
        case 'submitted':
            // The affidavit has been submitted.
            filters.push({
                affidavitsubmitted: [1]
            });
            break;
    }
    let startDate;
    let endDate;
    switch (filterValue.publicationDate) {
        case 'this-month':
            startDate = moment().startOf('month').toDate();
            endDate = moment().endOf('month').toDate();
            break;
        case 'last-month':
            startDate = moment().subtract(1, 'month').startOf('month').toDate();
            endDate = moment().subtract(1, 'month').endOf('month').toDate();
            break;
        case 'custom-date': {
            if (!filterValue.publicationDateRange) {
                console.warn('custom-date filter without publicationDateRange');
                break;
            }
            const startDayString = moment(filterValue.publicationDateRange.start).format('YYYY-MM-DD');
            const endDayString = moment(filterValue.publicationDateRange.end).format('YYYY-MM-DD');
            startDate = moment.utc(startDayString).startOf('day').toDate();
            endDate = moment.utc(endDayString).endOf('day').toDate();
            break;
        }
    }
    if (startDate && endDate) {
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();
        anyFilters.push({
            publicationtimestamps: {
                from: startTime,
                to: endTime
            }
        });
    }
    switch (filterValue.sync) {
        case 'success':
            filters.push({ syncstatusatdeadline: [1] });
            break;
        case 'failure':
            filters.push({ syncstatusatdeadline: [0] });
            break;
        case 'requires-attention':
            filters.push({
                syncpreconditionsuccess: [0]
            });
            noneFilters.push({
                syncstatusatdeadline: [0, 1]
            });
            break;
    }
    switch (filterValue.noticeCategory) {
        case 'display':
            anyFilters.push({
                noticetype: [NoticeType.display_ad.value]
            }, { postwithoutformatting: [1] });
            break;
        case 'liner':
            noneFilters.push({ noticetype: [NoticeType.display_ad.value] }, { postwithoutformatting: [1] });
            break;
    }
    return { filters, anyFilters, noneFilters };
};
export const getNumActiveFilters = (noticesFilterValue) => {
    let numActive = 0;
    if (noticesFilterValue.status !== VALUE_SHOW_ALL) {
        numActive++;
    }
    if (noticesFilterValue.invoice !== VALUE_SHOW_ALL) {
        numActive++;
    }
    if (noticesFilterValue.affidavit !== VALUE_SHOW_ALL) {
        numActive++;
    }
    if (noticesFilterValue.sync !== VALUE_SHOW_ALL) {
        numActive++;
    }
    if (noticesFilterValue.publicationDate !== VALUE_SHOW_ALL) {
        numActive++;
    }
    if (noticesFilterValue.noticeCategory !== VALUE_SHOW_ALL) {
        numActive++;
    }
    return numActive;
};
