import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { logAndCaptureMessage } from 'utils';
import { Label } from 'lib/components/Label';
import ColumnDatePicker from 'components/ColumnDatePicker';
const ALL_TIME_OPTION = {
    value: 'all-time',
    label: 'All time'
};
const THIS_MONTH_OPTION = {
    value: 'this-month',
    label: 'This month',
    description: moment().format('MMMM')
};
const LAST_MONTH_OPTION = {
    value: 'last-month',
    label: 'Last month',
    description: moment().subtract(1, 'month').format('MMMM')
};
const CUSTOM_DATE_OPTION = {
    value: 'custom-date',
    label: 'Custom date'
};
const startOfThisMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfThisMonth = moment().endOf('month').format('YYYY-MM-DD');
const startOfLastMonth = moment()
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');
const endOfLastMonth = moment()
    .subtract(1, 'month')
    .endOf('month')
    .format('YYYY-MM-DD');
const getPublicationDateSelectValueFromFilter = (filterValue) => {
    if (!filterValue || !Object.keys(filterValue).length) {
        return ALL_TIME_OPTION.value;
    }
    if (filterValue.publicationDateFrom === startOfThisMonth &&
        filterValue.publicationDateTo === endOfThisMonth) {
        return THIS_MONTH_OPTION.value;
    }
    if (filterValue.publicationDateFrom === startOfLastMonth &&
        filterValue.publicationDateTo === endOfLastMonth) {
        return LAST_MONTH_OPTION.value;
    }
    return CUSTOM_DATE_OPTION.value;
};
const getPublicationDateFilterPreset = (presetDateRange) => {
    switch (presetDateRange) {
        case ALL_TIME_OPTION.value:
            return undefined;
        case THIS_MONTH_OPTION.value:
            return {
                publicationDateFrom: startOfThisMonth,
                publicationDateTo: endOfThisMonth
            };
        case LAST_MONTH_OPTION.value:
            return {
                publicationDateFrom: startOfLastMonth,
                publicationDateTo: endOfLastMonth
            };
        default:
            logAndCaptureMessage('Unexpected preset date range value in obituaries table filter', {
                presetDateRange
            });
            return undefined;
    }
};
const PUBLICATION_DATE_OPTIONS = [
    ALL_TIME_OPTION,
    THIS_MONTH_OPTION,
    LAST_MONTH_OPTION,
    CUSTOM_DATE_OPTION
];
export function PublicationDateFilter({ rowFilter, editedRowFilter, setEditedRowFilter }) {
    var _a, _b;
    const [publicationDateOption, setPublicationDateOption] = useState(getPublicationDateSelectValueFromFilter(editedRowFilter.publicationDate));
    useEffect(() => {
        /**
         * When the user selects the custom date option,
         * we should clear the start & end dates (see APP-2865),
         * but we should not clear the start & end dates if they
         * are being populated by the current active filter.
         */
        if (publicationDateOption === 'custom-date') {
            const currentFilterSelectValue = getPublicationDateSelectValueFromFilter(rowFilter.publicationDate);
            const currentFilterHasCustomRange = currentFilterSelectValue === CUSTOM_DATE_OPTION.value;
            setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { publicationDate: currentFilterHasCustomRange
                    ? rowFilter.publicationDate
                    : undefined }));
        }
    }, [publicationDateOption]);
    return (_jsxs(_Fragment, { children: [_jsx(ColumnSelect, { id: "publication-date", labelText: "Publication date", size: "small", options: PUBLICATION_DATE_OPTIONS, value: publicationDateOption, placeholder: "All time", allowUndefined: true, onChange: newValue => {
                    setPublicationDateOption(newValue);
                    switch (newValue) {
                        case THIS_MONTH_OPTION.value:
                        case LAST_MONTH_OPTION.value:
                            setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { publicationDate: getPublicationDateFilterPreset(newValue) }));
                            break;
                        case ALL_TIME_OPTION.value:
                            setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { publicationDate: undefined }));
                            break;
                        case CUSTOM_DATE_OPTION.value:
                        default:
                            // do nothing - the user will select the date range manually
                            break;
                    }
                } }), publicationDateOption === CUSTOM_DATE_OPTION.value && (_jsxs("div", { children: [_jsx(Label, Object.assign({ id: "date-filter-label" }, { children: "Choose a date range" })), _jsxs("div", Object.assign({ className: "flex flex-row gap-2 mt-2" }, { children: [_jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "Start date", value: ((_a = editedRowFilter.publicationDate) === null || _a === void 0 ? void 0 : _a.publicationDateFrom)
                                    ? moment(editedRowFilter.publicationDate.publicationDateFrom).toDate()
                                    : undefined, onChange: newDate => newDate &&
                                    setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { publicationDate: Object.assign(Object.assign({}, editedRowFilter.publicationDate), { publicationDateFrom: moment(newDate).format('YYYY-MM-DD') }) })), shouldDisableDate: date => {
                                    var _a;
                                    if (!date)
                                        return true;
                                    const dateLimit = ((_a = editedRowFilter.publicationDate) === null || _a === void 0 ? void 0 : _a.publicationDateTo)
                                        ? moment(editedRowFilter.publicationDate.publicationDateTo)
                                        : null;
                                    if (!dateLimit)
                                        return false;
                                    return moment(date).isAfter(dateLimit);
                                } }), _jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "End date", value: ((_b = editedRowFilter.publicationDate) === null || _b === void 0 ? void 0 : _b.publicationDateTo)
                                    ? moment(editedRowFilter.publicationDate.publicationDateTo).toDate()
                                    : undefined, onChange: newDate => newDate &&
                                    setEditedRowFilter(Object.assign(Object.assign({}, editedRowFilter), { publicationDate: Object.assign(Object.assign({}, editedRowFilter.publicationDate), { publicationDateTo: moment(newDate).format('YYYY-MM-DD') }) })), shouldDisableDate: date => {
                                    var _a;
                                    if (!date)
                                        return true;
                                    const dateLimit = ((_a = editedRowFilter.publicationDate) === null || _a === void 0 ? void 0 : _a.publicationDateFrom)
                                        ? moment(editedRowFilter.publicationDate.publicationDateFrom)
                                        : null;
                                    if (!dateLimit)
                                        return false;
                                    return moment(date).isBefore(dateLimit);
                                } })] }))] }))] }));
}
