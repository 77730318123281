import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Drawer from 'lib/components/Drawer';
import classNames from 'classnames';
import { ColumnButton } from 'lib/components/ColumnButton';
import FilingTypeDrawerBody from './FilingTypeDrawerBody';
export default function FilingTypeDrawer({ onEditFilingType, filingType, onClose }) {
    return (_jsxs(Drawer, Object.assign({ onClose: onClose, header: _jsx("div", Object.assign({ className: "flex items-center text-column-gray-800 font-semibold text-xl gap-2" }, { children: filingType.label })), open: true, className: "h-screen flex flex-col" }, { children: [_jsx(FilingTypeDrawerBody, { filingType: filingType }), _jsxs("div", Object.assign({ className: classNames('absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between') }, { children: [_jsx("div", { className: "flex-1" }), _jsx(ColumnButton, { buttonText: "Edit", size: "lg", id: "edit-filing-type", tertiary: true, onClick: () => {
                            onClose();
                            onEditFilingType(filingType);
                        }, type: "button" })] }))] })));
}
