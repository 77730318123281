var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { cdnIfy, DEFAULT_DPI } from '../helpers';
import { wrapSuccess, wrapError } from '../types/responses';
export const imageUrlToDataUrl = (srcUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(srcUrl);
    if (!response.ok) {
        return wrapError(new Error(`HTTP error! status: ${response.status}`));
    }
    const blob = yield response.blob();
    const reader = new FileReader();
    const result = yield new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
    return wrapSuccess(result);
});
export const getSmashedDataUrl = (fullPath, grayscale) => __awaiter(void 0, void 0, void 0, function* () {
    const transformations = [`dn_${DEFAULT_DPI},f_png,e_trim:0:white`];
    if (grayscale) {
        transformations.push('e_grayscale');
    }
    const transformationsString = transformations.join(',');
    const srcUrl = cdnIfy(fullPath, {
        cloudinaryTransformations: transformationsString,
        useColumnCDN: true
    });
    const { error, response: dataUrl } = yield imageUrlToDataUrl(srcUrl);
    if (error)
        return wrapError(error);
    return wrapSuccess(dataUrl);
});
