import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import LoadingState from '../../components/LoadingState';
function Logout({ authActions }) {
    useEffect(() => {
        authActions.logout();
    }, []);
    return _jsx(LoadingState, {});
}
export default Logout;
