import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import React from 'react';
import { FileUploadFormattingOption } from './FileUploadFormattingOption';
export function FileUploadFormattingOptionsModal({ modalHeader, actions, modalDescription, overlay }) {
    return (_jsx("div", Object.assign({ className: classNames('absolute z-20 inset-0 flex items-center justify-center bg-base-1 rounded-md border border-column-gray-150', { 'bg-opacity-97': overlay }) }, { children: _jsxs("div", Object.assign({ className: "transform", style: { width: '430px' } }, { children: [_jsx("p", Object.assign({ className: "font-semibold text-base text-column-gray-500 text-center mx-10" }, { children: modalHeader })), modalDescription && (_jsx("p", Object.assign({ className: "font-medium text-xs text-column-gray-400 text-center mt-2" }, { children: modalDescription }))), actions.map(action => {
                    const actionOption = (_jsx(FileUploadFormattingOption, { id: action.id, headerText: action.header, descriptionText: action.description, onHandleClick: () => action.onClick(), disabled: action.disabled }));
                    if (action.tooltipText) {
                        return (_jsx(Tooltip, Object.assign({ helpText: action.tooltipText, position: "topCenter" }, { children: actionOption }), `${action.id}-tooltip`));
                    }
                    return actionOption;
                })] })) })));
}
