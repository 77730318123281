import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { State, State as States } from 'lib/enums';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import SelectDropdown from '../SelectDropdown';
import { actions as affidavitRecipientActions, MAX_MAIL_COPIES } from './affidavitRecipient.slice';
const { deleteMailingRecipient, updateMailingRecipientName, updateMailingRecipientCopies, updateMailingRecipientAddress } = affidavitRecipientActions;
export function AffidavitMailingAddressForm({ mailingAddress, index, dispatch, error, showErrors }) {
    var _a, _b, _c, _d, _e, _f;
    const enableNewPlacementFlow = getBooleanFlag(LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW);
    const selectedState = ((_a = mailingAddress.address) === null || _a === void 0 ? void 0 : _a.address_state)
        ? {
            id: `${mailingAddress.address.address_state}`,
            label: (_b = States.by_value(mailingAddress.address.address_state)) === null || _b === void 0 ? void 0 : _b.label
        }
        : {};
    const inputSize = enableNewPlacementFlow ? 'standard' : 'small';
    function onMailAddressFieldChange(change) {
        dispatch(updateMailingRecipientAddress({ update: change, index }));
    }
    function onDelete() {
        dispatch(deleteMailingRecipient(index));
    }
    return (_jsx("div", Object.assign({ className: classNames('my-6', {
            'bg-gray-100 border rounded-md p-6': enableNewPlacementFlow
        }) }, { children: _jsx("form", { children: _jsxs("fieldset", Object.assign({ className: "grid grid-cols-12 gap-4" }, { children: [enableNewPlacementFlow && (_jsxs("div", Object.assign({ className: "col-span-12 flex justify-between" }, { children: [_jsx("legend", Object.assign({ className: "text-sm text-grey-400 font-medium uppercase" }, { children: "Recipient details" })), index > 0 && (_jsx(ColumnButton, { destructive: true, link: true, "aria-label": "Delete address", startIcon: _jsx(TrashIcon, { className: "w-5 h-5" }), onClick: onDelete, type: "button" }))] }))), _jsx("div", Object.assign({ className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-8' }, { children: _jsx(TextField, { id: `name${index}`, labelText: enableNewPlacementFlow ? 'Full name' : '', required: enableNewPlacementFlow, placeholder: "Name", size: inputSize, value: mailingAddress.name, onChange: newValue => {
                                dispatch(updateMailingRecipientName({
                                    newValue,
                                    index
                                }));
                            } }) })), _jsx("div", Object.assign({ className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-3' }, { children: _jsx(TextField, { type: "number", id: `copies${index}`, labelText: enableNewPlacementFlow ? 'How many copies should be sent?' : '', placeholder: "Copies", required: enableNewPlacementFlow, value: String(mailingAddress.copies), size: inputSize, min: 1, max: MAX_MAIL_COPIES, onChange: newValue => {
                                dispatch(updateMailingRecipientCopies({
                                    newValue: Number(newValue),
                                    index
                                }));
                            } }) })), index > 0 && !enableNewPlacementFlow && (_jsx("div", Object.assign({ className: "col-span-1 flex items-center" }, { children: _jsx(ColumnButton, { destructive: true, link: true, "aria-label": "Delete address", startIcon: _jsx(TrashIcon, { className: "w-5 h-5" }), onClick: onDelete, type: "button" }) }))), _jsx("div", Object.assign({ className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-8' }, { children: _jsx(TextField, { id: `addressLine1${index}`, labelText: enableNewPlacementFlow ? 'Address 1' : '', placeholder: "Address Line 1", required: enableNewPlacementFlow, value: (_c = mailingAddress.address) === null || _c === void 0 ? void 0 : _c.address_line1, size: inputSize, onChange: newValue => {
                                onMailAddressFieldChange({
                                    address_line1: newValue
                                });
                            } }) })), _jsx("div", Object.assign({ className: enableNewPlacementFlow ? 'col-span-6' : 'col-span-3' }, { children: _jsx(TextField, { id: `addressLine2${index}`, labelText: enableNewPlacementFlow ? 'Address 2' : '', placeholder: "Address Line 2", value: ((_d = mailingAddress.address) === null || _d === void 0 ? void 0 : _d.address_line2) || '', size: inputSize, onChange: newValue => {
                                onMailAddressFieldChange({
                                    address_line2: newValue
                                });
                            } }) })), _jsx("div", Object.assign({ className: "col-span-4" }, { children: _jsx(TextField, { id: `city${index}`, labelText: enableNewPlacementFlow ? 'City' : '', required: enableNewPlacementFlow, placeholder: "City", value: (_e = mailingAddress.address) === null || _e === void 0 ? void 0 : _e.address_city, size: inputSize, onChange: newValue => {
                                onMailAddressFieldChange({
                                    address_city: newValue
                                });
                            } }) })), !enableNewPlacementFlow && (_jsx("div", Object.assign({ className: "col-span-4" }, { children: _jsx(SelectDropdown, { id: `state${index}`, className: "state-select", placeholder: "State", onChange: (selected) => {
                                if (selected) {
                                    onMailAddressFieldChange({
                                        address_state: selected.id
                                    });
                                }
                            }, value: selectedState.id ? selectedState : '', selected: selectedState, options: States.items().map((state) => ({
                                id: state.value,
                                label: state.label
                            })), placeholderText: '#6B7280' }) }))), _jsx("div", Object.assign({ className: enableNewPlacementFlow ? 'col-span-4' : 'col-span-3' }, { children: _jsx(TextField, { id: `zipcode${index}`, type: "postal-code", labelText: enableNewPlacementFlow ? 'Zip code' : '', required: enableNewPlacementFlow, placeholder: "Zip Code", value: (_f = mailingAddress.address) === null || _f === void 0 ? void 0 : _f.address_zip, size: inputSize, onChange: newValue => {
                                onMailAddressFieldChange({
                                    address_zip: newValue
                                });
                            } }) })), enableNewPlacementFlow && (_jsx("div", Object.assign({ className: "col-span-4" }, { children: _jsx(ColumnSelect, { id: `state${index}`, labelText: "State", placeholder: "State", required: true, onChange: stateId => {
                                onMailAddressFieldChange({
                                    address_state: Number(stateId)
                                });
                            }, allowUndefined: true, options: State.items().map((state) => ({
                                value: String(state.value),
                                label: state.label
                            })), size: inputSize, value: selectedState.id }) }))), showErrors && error && (_jsx("div", Object.assign({ className: "col-span-12 text-red-600 text-sm font-normal pt-2" }, { children: error })))] })) }) })));
}
