import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { XIcon } from 'icons';
/** @deprecated Use Modal instead */
export default function TailwindModal({ header, body, close, onButtonClick, buttonText, warning, children, loading, noExitOutsideModal, widthPct, disabled }) {
    return (_jsxs("div", Object.assign({ className: "fixed bottom-0 md:inset-x-0 inset-0 flex items-center justify-center z-max" }, { children: [_jsx("div", Object.assign({ className: "fixed inset-0 transition-opacity" }, { children: _jsx("div", { className: "absolute inset-0 bg-gray-500 opacity-75", onClick: () => !noExitOutsideModal && close() }) })), _jsxs("div", Object.assign({ className: `flex bg-white w-7/20 rounded-lg pl-10 pr-5 py-8 overflow-hidden shadow-xl transform transition-all`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", style: widthPct ? { width: `${widthPct}%` } : undefined }, { children: [_jsxs("div", Object.assign({ className: "flex-grow" }, { children: [_jsx("div", Object.assign({ className: "font-semibold text-lg text-gray-900 mb-4 flex justify-between mr-4" }, { children: header })), body && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "font-normal text-sm text-gray-700 mb-8 mr-4" }, { children: body })), buttonText && (_jsx("button", Object.assign({ className: `rounded-md font-semibold ${warning
                                            ? 'bg-red-200 text-red-700 focus:border-red-500 hover:bg-red-600 hover:text-white'
                                            : 'bg-blue-500 bg-opacity-25 text-blue-600'} ${disabled &&
                                            !warning &&
                                            'bg-gray-200 focus:border-gray-500 text-gray-700 hover:bg-gray-600'} text-sm flex items-center px-5 py-2`, type: "button", onClick: () => onButtonClick && onButtonClick(), disabled: loading }, { children: _jsxs("span", Object.assign({ className: "flex" }, { children: [loading && (_jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })), buttonText] })) })))] })), children] })), _jsx("div", { children: _jsx("span", Object.assign({ id: "close-modal", className: "cursor-pointer", onClick: () => close() }, { children: _jsx(XIcon, { className: "h-6 w-6" }) })) })] }))] })));
}
