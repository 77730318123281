var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { mapProductToRoute } from 'utils/product';
import { push } from 'connected-react-router';
import { useAppDispatch } from 'redux/hooks';
import { NoticeStatusType, Product } from 'lib/enums';
import { CopyText } from 'lib/components/CopyText';
import { ColumnButton } from 'lib/components/ColumnButton';
import moment from 'moment';
import { getDueDateAndTimeString } from 'lib/helpers';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { NOTICE_PREVIEW_DETAIL_TAB, ACTIVITY_LOG_DETAIL_TAB } from './noticeDetailDrawer';
import { DuplicateNoticeButton } from './DuplicateNoticeButton';
/**
 * Determines if the edit button should be disabled and returns the reason why
 * @param {ESnapshotExists<ENotice>} noticeSnap notice snapshot
 * @param {boolean} isPublisher whether or not the user is a publisher
 * @returns {Promise<string | undefined>} reason why the edit button should be disabled
 */
export const getDisableEditButtonReason = (noticeSnap, isPublisher) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if (!noticeSnap.data().newspaper || !noticeSnap.data().publicationDates)
        return '';
    if (noticeSnap.data().isArchived)
        return 'Notice is archived.';
    const newspaperSnap = yield noticeSnap.data().newspaper.get();
    const draftsSnaps = yield Promise.all((noticeSnap.data().drafts || []).map(draftRef => draftRef.get()));
    const dateToCompare = noticeSnap.data().editedAt || noticeSnap.data().confirmedAt;
    if (draftsSnaps.find(draft => { var _a, _b; return ((_b = (_a = draft.data()) === null || _a === void 0 ? void 0 : _a.editedAt) === null || _b === void 0 ? void 0 : _b.toMillis()) === dateToCompare.toMillis(); })) {
        return 'There was an error loading this notice. Please refresh the page and try again';
    }
    if (!isPublisher && !((_a = newspaperSnap.data()) === null || _a === void 0 ? void 0 : _a.advertiserEditingEnabled))
        return 'This newspaper disabled editing.';
    if ((_b = noticeSnap.data().buildSyncData) === null || _b === void 0 ? void 0 : _b.syncInProgress) {
        return isPublisher
            ? 'AffinityX sync in progress. Sync must complete or be cancelled before you can edit the notice.'
            : 'Notice cannot be edited while formatting is in progress.';
    }
    return '';
});
/**
 * Formatted and returned the notice details to be copied on the clipboard
 */
const copyNoticeDetails = (newspaper, notice, invoiceSnap) => {
    const paperName = newspaper.data().name;
    const publicationDates = notice
        .data()
        .publicationDates.map(date => {
        return moment(date.toDate()).format('MMMM D, YYYY');
    })
        .join(', ');
    let billingStatuses = `Your notice is scheduled to be published in the ${paperName} on ${publicationDates}.`;
    if (exists(invoiceSnap) && notice.data().requireUpfrontPayment) {
        billingStatuses = billingStatuses.concat(` Your payment must be received by ${getDueDateAndTimeString(invoiceSnap, newspaper)}. For your convenience, you can quickly access your invoice and pay securely online using a credit/debit card or bank transfer (ACH) by clicking this link: ${invoiceSnap.data().hosted_invoice_url}.`);
    }
    const copiedText = `\nYour Notice ID is: ${notice.id}`;
    return billingStatuses.concat(copiedText);
};
/**
 * Shows the name of the notice and the edit button
 */
export default function NoticeDetailTitle({ setShowPreviewOnMobile, setActiveDetailTab, disableEditReason, onEditClicked, notice, newspaper, invoiceSnap }) {
    const dispatch = useAppDispatch();
    const [noticeIdCopied, setNoticeIdCopied] = useState(false);
    useEffect(() => {
        if (noticeIdCopied) {
            const timeout = setTimeout(() => {
                setNoticeIdCopied(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [noticeIdCopied]);
    const allowingCopyNoticeDetails = getBooleanFlag(LaunchDarklyFlags.ENABLE_COPY_NOTICE_DETAILS_FOR_REPS, false);
    const isNoticeCancelled = notice.data().noticeStatus === NoticeStatusType.cancelled.value;
    return (_jsxs("div", Object.assign({ className: "mb-6" }, { children: [_jsx("div", { children: _jsxs("div", Object.assign({ id: "notice-name", className: "lg:flex gap-2 text-2xl font-semibold text-column-gray-500 items-center justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(ColumnButton, { type: "button", link: true, startIcon: _jsx(ArrowLeftIcon, { className: "h-4 w-4 stroke-2 text-column-gray-400 hover:text-column-primary-600" }), onClick: () => dispatch(push(`/${mapProductToRoute(Product.Notice)}/`)), "aria-label": "Back" }), notice.data().referenceId || 'Notice Details', isNoticeCancelled && (_jsx("span", Object.assign({ className: "align-middle bg-column-red-400 leading-5 ml-2 px-2 py-1 rounded text-sm text-white uppercase" }, { children: "Cancelled" })))] })), _jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [!isNoticeCancelled && (_jsx(ColumnButton, { buttonText: "Edit", id: "edit-notice", onClick: onEditClicked, disabled: !!disableEditReason, tertiary: true, type: "button", "aria-label": disableEditReason })), _jsx(DuplicateNoticeButton, { notice: notice })] }))] })) }), _jsxs("div", Object.assign({ className: "text-column-gray-400" }, { children: ["Notice ID: ", _jsx(CopyText, Object.assign({ copyText: notice.id }, { children: notice.id }))] })), allowingCopyNoticeDetails && (_jsx("div", Object.assign({ id: "notice-details-copy", className: "text-column-gray-400" }, { children: _jsx(CopyText, Object.assign({ copyText: copyNoticeDetails(newspaper, notice, invoiceSnap) }, { children: "Click to copy notice details" })) }))), _jsxs("div", Object.assign({ className: "flex lg:hidden gap-2 mt-4" }, { children: [_jsx(ColumnButton, { buttonText: _jsx("div", Object.assign({ className: "w-28 text-left" }, { children: "Notice Preview" })), onClick: () => {
                            setActiveDetailTab(NOTICE_PREVIEW_DETAIL_TAB);
                            setShowPreviewOnMobile(true);
                        }, endIcon: _jsx(ArrowRightIcon, { className: "w-4 h-6" }), secondary: true, type: "button" }), _jsx(ColumnButton, { buttonText: _jsx("div", Object.assign({ className: "w-28 text-left" }, { children: "Activity Log" })), onClick: () => {
                            setActiveDetailTab(ACTIVITY_LOG_DETAIL_TAB);
                            setShowPreviewOnMobile(true);
                        }, endIcon: _jsx(ArrowRightIcon, { className: "w-4 h-6" }), secondary: true, size: "sm", type: "button" })] }))] })));
}
