var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { removeUndefinedFields } from 'lib/helpers';
import { NoticeStatusType } from 'lib/enums';
import { isPublisher } from 'lib/utils/users';
import Firebase from 'EnoticeFirebase';
import api from 'api';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import AffidavitActionSectionHeader from './AffidavitActionSectionHeader';
import AffidavitDownloadSection from './AffidavitDownloadSection';
import ReplaceAffidavitModal from './ReplaceAffidavitModal';
import UploadConfirmation from './UploadConfirmation';
import PayInvoiceModal from './PayInvoiceModal';
import MailTable from './MailTable';
import AdDetailsCard from '../AdDetailsCard';
export default function AffidavitActions({ alwaysAllowAffidavitDownload, invoiceSnap, newspaper, notice, user }) {
    const [showReplaceAffidavitModal, setShowReplaceAffidavitModal] = useState(false);
    const [showPayInvoiceModal, setShowPayInvoiceModal] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [affidavitUpdates, setAffidavitUpdates] = useState({});
    const [affidavitURL, setAffidavitURL] = useState(notice.data().affidavitURL);
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);
    const updateAffidavitValuesOnNotice = () => __awaiter(this, void 0, void 0, function* () {
        yield notice.ref.update(affidavitUpdates);
        const updated = yield notice.ref.get();
        if (exists(updated)) {
            yield setPersistentAffidavitURL(updated);
        }
        setShowUploadFileModal(false);
    });
    const setPersistentAffidavitURL = (updated) => __awaiter(this, void 0, void 0, function* () {
        const url = yield Firebase.storage()
            .ref(updated.data().affidavit)
            .getDownloadURL();
        yield notice.ref.update({
            affidavitURL: url
        });
        setAffidavitURL(url);
    });
    const setPersistentGeneratedAffidavitURL = (notice) => __awaiter(this, void 0, void 0, function* () {
        if (notice.data().generatedAffidavitURL ||
            !notice.data().generatedAffidavitStoragePath) {
            return;
        }
        const url = yield Firebase.storage()
            .ref(notice.data().generatedAffidavitStoragePath)
            .getDownloadURL();
        yield notice.ref.update({
            generatedAffidavitURL: url
        });
    });
    const uploadFile = (file) => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoading(true);
            const snapshot = yield Firebase.storage()
                .ref()
                .child(`affidavits/${notice.id}/${file.name}`)
                .put(file);
            /**
             * We should never change the status of a cancelled notice upon affidavit upload.
             * If the notice still requires a transfer (e.g., in the event of a partial refund),
             * the affidavit upload should still trigger a transfer (see logic in onNoticeUpdate and createNoticeTransferIfNotExists)
             */
            const shouldNotUpdateNoticeStatus = notice.data().noticeStatus === NoticeStatusType.cancelled.value;
            const firstTimeAffidavitUploadedToNotice = !notice.data()
                .affidavitFirstUploadedAt;
            setAffidavitUpdates(removeUndefinedFields(Object.assign(Object.assign({ affidavit: snapshot.ref.fullPath, noticeStatus: shouldNotUpdateNoticeStatus
                    ? undefined
                    : NoticeStatusType.affidavit_submitted.value, affidavitUploadedBy: user.ref }, (firstTimeAffidavitUploadedToNotice
                ? {
                    affidavitFirstUploadedAt: getFirebaseContext()
                        .fieldValue()
                        .serverTimestamp()
                }
                : {})), { affidavitLastUploadedAt: getFirebaseContext()
                    .fieldValue()
                    .serverTimestamp() })));
            setFileName(snapshot.ref.fullPath);
            setAffidavitURL(yield snapshot.ref.getDownloadURL());
            setShowUploadFileModal(true);
            setLoading(false);
        }
        catch (err) {
            logAndCaptureException(ColumnService.AFFIDAVITS, err, 'Failed to upload affidavit file', {
                noticeId: notice.id
            });
        }
    });
    useEffect(() => {
        const regenerateAffidavit = () => __awaiter(this, void 0, void 0, function* () {
            try {
                yield api.post(`documents/${notice.id}/regenerate`, {
                    docType: 'AFFIDAVIT'
                });
            }
            catch (e) {
                // This can happen sometimes if the invoice is removed from the notice
                // while the affidavit is regenerating.
                logAndCaptureException(ColumnService.AFFIDAVITS, e, 'Failed to regenerate affidavit for notice', {
                    noticeId: notice.id
                });
            }
        });
        if (!exists(notice))
            return;
        if (notice.data().invoice && !notice.data().generatedAffidavitStoragePath) {
            void regenerateAffidavit();
        }
        if (notice.data().affidavit && !notice.data().affidavitURL) {
            void setPersistentAffidavitURL(notice);
        }
        if (notice.data().generatedAffidavitStoragePath &&
            !notice.data().generatedAffidavitURL) {
            void setPersistentGeneratedAffidavitURL(notice);
        }
    }, [notice === null || notice === void 0 ? void 0 : notice.id]);
    return (_jsxs(_Fragment, { children: [_jsxs(AdDetailsCard, Object.assign({ id: "affidavit-action", header: {
                    title: 'Affidavit',
                    actions: (_jsx(AffidavitActionSectionHeader, { alwaysAllowAffidavitDownload: alwaysAllowAffidavitDownload, uploadFile: uploadFile, newspaper: newspaper, invoice: invoiceSnap, loading: loading, notice: notice, user: user }))
                } }, { children: [_jsx("div", Object.assign({ className: "mb-6" }, { children: _jsx(AffidavitDownloadSection, { setShowReplaceAffidavitModal: setShowReplaceAffidavitModal, setShowPayInvoiceModal: setShowPayInvoiceModal, affidavitURL: affidavitURL, isPublisher: isPublisher(user), newspaper: newspaper, notice: notice }) })), _jsx(MailTable, { isPublisher: isPublisher(user), invoiceSnap: invoiceSnap, notice: notice })] })), showReplaceAffidavitModal && (_jsx(ReplaceAffidavitModal, { saveNCloseModal: updateAffidavitValuesOnNotice, uploadFile: uploadFile, setOpen: setShowReplaceAffidavitModal, open: showReplaceAffidavitModal })), showUploadFileModal && (_jsx(UploadConfirmation, { saveNCloseModal: updateAffidavitValuesOnNotice, downloadURL: affidavitURL, fileName: fileName, setOpen: setShowUploadFileModal, open: showUploadFileModal })), showPayInvoiceModal && invoiceSnap && (_jsx(PayInvoiceModal, { setShowPayInvoiceModal: setShowPayInvoiceModal, newspaper: newspaper, invoice: invoiceSnap }))] }));
}
