var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { TableLayout } from 'lib/components/TableLayout';
import { fuzzyStringContains } from 'lib/utils/strings';
import { OccupationType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import PublisherSettingsTableRow from './PublisherSettingsTableRow';
export default function UsersPublisherSettings({ user, onUpdatePublisherSettingsClick }) {
    const activeOrganization = useAppSelector(selectActiveOrganization) || null;
    const [loadingCustomerOrgInfo, setLoadingCustomerOrgInfo] = useState(false);
    const [customersOrgData, setCustomersOrgData] = useState();
    const userIsIndividual = user.data().occupation === OccupationType.individual.value;
    const getCustomerOrgsPublicationSettings = (customersOrg, customers) => __awaiter(this, void 0, void 0, function* () {
        const data = [];
        yield Promise.all(customersOrg.map((cusOrg) => __awaiter(this, void 0, void 0, function* () {
            const { organization, bulkPaymentEnabled_v2, enableAffidavitsBeforePayment, internalID } = cusOrg.data();
            const orgSnap = yield organization.get();
            if (!exists(orgSnap))
                return;
            const customerOfCustomerOrg = customers.find(cus => cus.data().organization.id === organization.id);
            data.push({
                organization: orgSnap,
                customerOrg: cusOrg.ref,
                isBillingEnabled: bulkPaymentEnabled_v2,
                isEarlyAffidavitsEnabled: enableAffidavitsBeforePayment ||
                    (customerOfCustomerOrg === null || customerOfCustomerOrg === void 0 ? void 0 : customerOfCustomerOrg.data().enableAffidavitsBeforePayment),
                internalID
            });
        })));
        return data;
    });
    const getCustomer = () => __awaiter(this, void 0, void 0, function* () {
        return yield getFirebaseContext()
            .customersRef()
            .where('user', '==', user.ref)
            .get();
    });
    const getCustomersPublicationSettings = () => __awaiter(this, void 0, void 0, function* () {
        setLoadingCustomerOrgInfo(true);
        const customers = yield getCustomer();
        if (!customers.docs.length)
            return;
        const data = [];
        yield Promise.all(customers.docs.map((customer) => __awaiter(this, void 0, void 0, function* () {
            const { organization, bulkPaymentEnabled_v2, enableAffidavitsBeforePayment, internalID } = customer.data();
            const orgSnap = yield organization.get();
            if (!exists(orgSnap))
                return;
            data.push({
                organization: orgSnap,
                isBillingEnabled: bulkPaymentEnabled_v2,
                isEarlyAffidavitsEnabled: enableAffidavitsBeforePayment,
                internalID
            });
        })));
        setCustomersOrgData(data);
        setLoadingCustomerOrgInfo(false);
    });
    const getCustomersOrgInfo = (customersOrgSnaps) => __awaiter(this, void 0, void 0, function* () {
        if (!customersOrgSnaps)
            return;
        setLoadingCustomerOrgInfo(true);
        const customersSnaps = yield getCustomer();
        const customersOrgData = yield getCustomerOrgsPublicationSettings(customersOrgSnaps, customersSnaps.docs);
        setCustomersOrgData(customersOrgData);
        setLoadingCustomerOrgInfo(false);
    });
    const activeOrgRef = activeOrganization ? activeOrganization.ref : null;
    const query = getFirebaseContext()
        .customerOrganizationsRef()
        .where('client', '==', activeOrgRef);
    const customersOrgs = useFirestoreQueryListener(query, []);
    useEffect(() => {
        if (user.data().allowedOrganizations &&
            exists(activeOrganization) &&
            (customersOrgs === null || customersOrgs === void 0 ? void 0 : customersOrgs.docs.length)) {
            void getCustomersOrgInfo(customersOrgs === null || customersOrgs === void 0 ? void 0 : customersOrgs.docs);
        }
        else {
            void getCustomersPublicationSettings();
        }
    }, [customersOrgs]);
    const customersOrCustomerOrgsPublisherInfoTableFilter = (customersOrgData, matchString) => {
        if (fuzzyStringContains(customersOrgData.organization.data().name, matchString)) {
            return true;
        }
        if (fuzzyStringContains(customersOrgData.internalID || '', matchString)) {
            return true;
        }
        return false;
    };
    return (_jsx(_Fragment, { children: _jsx(TableLayout, { id: "publishers-info-settings", header: {
                title: 'Publisher Settings',
                subtitle: 'View and manage all settings with publishers'
            }, columns: [
                'Publisher Name',
                'Account Number',
                'Bulk Invoicing',
                'Affidavit Pre-payment'
            ], data: customersOrgData || [], filterable: {
                shouldShowTableItem: (customersOrgData, searchTerm) => customersOrCustomerOrgsPublisherInfoTableFilter(customersOrgData, searchTerm)
            }, renderRow: item => (_jsx(PublisherSettingsTableRow, { publisherSettingsInfo: item })), loading: loadingCustomerOrgInfo, editable: !userIsIndividual
                ? {
                    editTooltip: 'Edit this publisher`s settings.',
                    onEdit: item => {
                        onUpdatePublisherSettingsClick(item);
                    }
                }
                : undefined }) }));
}
