var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Firebase from 'EnoticeFirebase';
import { NoticeType } from 'lib/enums';
import { PdfSmasher } from 'lib/files/pdfSmasher';
import { assetQuality, cdnIfy, getFileExtension } from 'lib/helpers';
import { NoticeFileTypes } from 'lib/types/notice';
import { FileType, getVerifiedExtensionFromFileName } from 'lib/types/mime';
import { cloudConvertFile } from 'utils/cloudconvert';
import { wordOrPDFToHtml } from 'utils/word';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { logAndCaptureMessage } from 'utils';
import { getSmashedDataUrl } from 'lib/files/images';
import getDocumentProperties from './getDocumentProperties';
const clientSideImageHelper = {
    getCanvas: (width) => {
        const canvas = document.createElement('canvas');
        canvas.height = 0;
        canvas.width = width;
        return canvas;
    },
    getNewImage: () => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        return image;
    }
};
/**
 * Process each page of a multi page document through Cloudinary transformations
 * Combine each page into one image file and save to Firebase storage
 */
const getFileAsCroppableImage = (properties, fullPath, grayscale) => __awaiter(void 0, void 0, void 0, function* () {
    const use_cloumn_cdn = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    if (use_cloumn_cdn) {
        const { error, response } = yield getSmashedDataUrl(fullPath, grayscale);
        if (error) {
            logAndCaptureMessage(error.message);
            throw new Error(error.message);
        }
        return response;
    }
    const imageSmasher = new PdfSmasher(clientSideImageHelper);
    return imageSmasher.getSmashedDataUrl(properties, fullPath, grayscale);
});
const clientSideSmash = (fileRef, fileType, shouldGrayscalePDFDisplays) => __awaiter(void 0, void 0, void 0, function* () {
    const properties = yield getDocumentProperties(fileRef, fileType);
    const croppableImage = yield getFileAsCroppableImage(properties, fileRef.fullPath, shouldGrayscalePDFDisplays);
    const smashedSnapshot = yield Firebase.storage()
        .ref()
        .child(`${fileRef.fullPath}_smashed.png`)
        .putString(croppableImage, 'data_url', {
        contentType: 'image/png'
    });
    return smashedSnapshot;
});
const getPdfFileChanges = (convertedUpload, fileWithProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const formattedUpload = yield clientSideSmash(convertedUpload, fileWithProperties.fileType, fileWithProperties.shouldGrayscalePDFDisplays);
    return {
        noticeType: NoticeType.display_ad.value,
        file: {
            // We set this to finalized_display_ad assuming that it is the only display file being uploaded
            // If this is actually a component file, that gets updated in NoticeContentStep, where we can see
            // how many files have been uploaded
            type: NoticeFileTypes.finalized_display_ad,
            linkToUploadedFile: fileWithProperties.linkToUploadedFile,
            linkToFormattedFile: yield formattedUpload.ref.getDownloadURL(),
            firebaseStoragePath: formattedUpload.ref.fullPath,
            originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            originalFileName: fileWithProperties.originalName,
            fileFormat: fileWithProperties.originalFormat || null,
            fileType: fileWithProperties.fileType || null,
            sanitizedFileName: fileWithProperties.sanitizedName
        },
        processedDisplay: true
    };
});
const getTextContentOfFile = (fileWithProperties) => new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsText(fileWithProperties.uploadedFile);
    reader.onloadend = () => resolve(reader.result);
});
const getTextFileChanges = (fileWithProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const html = yield getTextContentOfFile(fileWithProperties);
    return {
        noticeType: NoticeType.custom.value,
        text: html,
        file: {
            // We set this to text_file assuming that it is the only file being uploaded as notice content
            // If this is actually a component of a display ad, that gets updated in NoticeContentStep, where we can see
            // how many files have been uploaded
            type: NoticeFileTypes.text_file,
            linkToUploadedFile: fileWithProperties.linkToUploadedFile,
            firebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            sanitizedFileName: fileWithProperties.sanitizedName,
            fileFormat: fileWithProperties.originalFormat || null,
            fileType: fileWithProperties.fileType || null,
            originalFileName: fileWithProperties.sanitizedName
        }
    };
});
const wordToDisplay = (fileWithProperties, hasImage) => __awaiter(void 0, void 0, void 0, function* () {
    const input = getFileExtension(fileWithProperties.uploadedFile.name);
    if (!input) {
        console.error('Input file format must be specified');
        return null;
    }
    const pdf = yield cloudConvertFile(fileWithProperties.relatedUploadRef.fullPath, input, FileType.PDF, {
        pages_fit_wide: 1
    });
    const displayUpload = yield Firebase.storage()
        .ref()
        .child(`${fileWithProperties.relatedUploadRef.fullPath}.pdf`)
        .put(pdf);
    const formattedUpload = yield clientSideSmash(displayUpload.ref, FileType.PDF, true);
    return {
        noticeType: NoticeType.display_ad.value,
        file: {
            // We set this to finalized_display_ad assuming that it is the only display file being uploaded
            // If this is actually a component file, that gets updated in NoticeContentStep, where we can see
            // how many files have been uploaded
            type: NoticeFileTypes.finalized_display_ad,
            originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            firebaseStoragePath: formattedUpload.ref.fullPath,
            linkToFormattedFile: yield formattedUpload.ref.getDownloadURL(),
            linkToUploadedFile: fileWithProperties.linkToUploadedFile,
            originalFileName: fileWithProperties.originalName,
            fileFormat: fileWithProperties.originalFormat || null,
            fileType: fileWithProperties.fileType || null,
            sanitizedFileName: fileWithProperties.sanitizedName
        },
        processedDisplay: true,
        hasImage
    };
});
const getWordFileChanges = (newspaper, fileWithProperties, isTableContentSquashable, options) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!newspaper)
        return null;
    const wordContent = yield getTextContentOfFile(fileWithProperties);
    const imageInWordContent = (wordContent === null || wordContent === void 0 ? void 0 : wordContent.indexOf('word/media/image')) !== -1;
    const hasImage = imageInWordContent && !(options === null || options === void 0 ? void 0 : options.allowImages);
    if (hasImage) {
        return yield wordToDisplay(fileWithProperties, hasImage);
    }
    // If there are no images in the Word doc, we can proceed with parsing it as a liner.
    const { html } = yield wordOrPDFToHtml(fileWithProperties.relatedUploadRef.fullPath, (_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.cleanVariant, isTableContentSquashable, options);
    return {
        noticeType: NoticeType.custom.value,
        text: html,
        file: {
            // We set this to text_file assuming that it is the only file being uploaded as notice content
            // If this is actually a component of a display ad, that gets updated in NoticeContentStep, where we can see
            // how many files have been uploaded
            type: NoticeFileTypes.text_file,
            linkToUploadedFile: fileWithProperties.linkToUploadedFile,
            firebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            originalFileName: fileWithProperties.originalName,
            fileFormat: fileWithProperties.originalFormat || null,
            fileType: fileWithProperties.fileType || null,
            sanitizedFileName: fileWithProperties.sanitizedName
        },
        hasImage: imageInWordContent
    };
});
const getExcelFileChanges = (fileWithProperties) => __awaiter(void 0, void 0, void 0, function* () {
    const pdf = yield cloudConvertFile(fileWithProperties.relatedUploadRef.fullPath, 'xlsx', FileType.PDF, {
        pages_fit_wide: 1
    });
    const displayUpload = yield Firebase.storage()
        .ref()
        .child(`${fileWithProperties.relatedUploadRef.fullPath}.pdf`)
        .put(pdf);
    const formattedUpload = yield clientSideSmash(displayUpload.ref, FileType.PDF, true);
    return {
        noticeType: NoticeType.display_ad.value,
        file: {
            // We set this to finalized_display_ad assuming that it is the only display file being uploaded
            // If this is actually a component file, that gets updated in NoticeContentStep, where we can see
            // how many files have been uploaded
            type: NoticeFileTypes.finalized_display_ad,
            originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            firebaseStoragePath: formattedUpload.ref.fullPath,
            linkToFormattedFile: yield formattedUpload.ref.getDownloadURL(),
            linkToUploadedFile: fileWithProperties.linkToUploadedFile,
            originalFileName: fileWithProperties.originalName,
            fileFormat: fileWithProperties.originalFormat || null,
            fileType: fileWithProperties.fileType || null,
            sanitizedFileName: fileWithProperties.sanitizedName
        },
        processedDisplay: true
    };
});
const getOtherFileChanges = (fileWithProperties) => {
    return {
        noticeType: NoticeType.custom.value,
        text: '',
        file: {
            // We set this to text_file assuming that it is the only file being uploaded as notice content
            // If this is actually a component of a display ad, that gets updated in NoticeContentStep, where we can see
            // how many files have been uploaded
            type: NoticeFileTypes.text_file,
            linkToUploadedFile: fileWithProperties.linkToUploadedFile,
            firebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
            originalFileName: fileWithProperties.originalName,
            fileFormat: fileWithProperties.originalFormat || null,
            fileType: fileWithProperties.fileType || null,
            sanitizedFileName: fileWithProperties.sanitizedName
        }
    };
};
export const getFileChanges = (fileWithProperties, newspaper, isTableContentSquashable, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { fileType, relatedUploadRef } = fileWithProperties;
    let uploadType = fileType;
    let convertedUpload;
    let changes;
    if (uploadType &&
        [FileType.TIF, FileType.PNG, FileType.JPG].indexOf(uploadType) !== -1 &&
        relatedUploadRef.fullPath) {
        const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
        /* TODO: Figure out why we're converting to JPG in Cloudinary, then pretending it's a PDF in Firestore */
        const resp = yield fetch(cdnIfy(relatedUploadRef.fullPath, {
            cloudinaryTransformations: `f_jpg,w_${assetQuality.high.width}/e_sharpen`,
            useColumnCDN
        }));
        const pdfBlob = yield resp.blob();
        const nameBeforeConversion = pdfBlob.name ||
            relatedUploadRef.fullPath.split('/')[relatedUploadRef.fullPath.split('/').length - 1];
        const { fileNameMinusExtension } = getVerifiedExtensionFromFileName(nameBeforeConversion);
        const convertedPdfName = `converted_${fileNameMinusExtension}.pdf`;
        const pdf = new File([pdfBlob], convertedPdfName, {
            type: 'application/pdf'
        });
        const newUploadPath = `${relatedUploadRef.fullPath
            .split('/')
            .slice(0, -1)
            .join('/')}/${convertedPdfName}`;
        const convertedUploadSnap = yield Firebase.storage()
            .ref()
            .child(newUploadPath)
            .put(pdf);
        convertedUpload = convertedUploadSnap.ref;
        uploadType = FileType.PDF;
    }
    else {
        convertedUpload = relatedUploadRef;
    }
    if (uploadType === FileType.PDF) {
        changes = yield getPdfFileChanges(convertedUpload, fileWithProperties);
    }
    else if (uploadType === FileType.TEXT) {
        changes = yield getTextFileChanges(fileWithProperties);
    }
    else if (uploadType === FileType.WORD_DOC) {
        changes = yield getWordFileChanges(newspaper, fileWithProperties, isTableContentSquashable, options);
    }
    else if (uploadType === FileType.EXCEL) {
        changes = yield getExcelFileChanges(fileWithProperties);
    }
    else {
        changes = yield getOtherFileChanges(fileWithProperties);
    }
    return changes;
});
