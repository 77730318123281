import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
const styles = (theme) => createStyles({
    container: {
        padding: theme.spacing(2)
    }
});
const SettingsWrapper = ({ classes, children }) => (_jsx(Grid, Object.assign({ item: true, xs: 10 }, { children: _jsx(Paper, Object.assign({ className: classes.container }, { children: children })) })));
export default withStyles(styles)(SettingsWrapper);
