var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Badge } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { DEMO_PROJECT, FIREBASE_PROJECT, PRODUCTION_PROJECT } from 'lib/constants';
import { useContext, useEffect, useState } from 'react';
import { logAndCaptureMessage } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
const getAvailablePaginators = () => {
    if (FIREBASE_PROJECT === PRODUCTION_PROJECT) {
        return [
            {
                name: 'Saawi',
                id: 'jrdyMywTQAhFaeTw65kmu4u1HZD3',
                color: 'column-blue'
            },
            {
                name: 'Tatiana',
                id: 'lvW2mDWoToO8qwWKThvETOXg6VH2',
                color: 'column-green'
            },
            {
                name: 'Leo',
                id: '9UgGtkXUERh6MUUyv9Ak',
                color: 'column-yellow'
            },
            {
                name: 'Puloma',
                id: '99jQgWS7lfeGNKCscCeaW2RGVok1',
                color: 'column-red'
            },
            {
                name: 'Maddi',
                id: 'J3RtGwQQuLdFm5LcQ7q3e7LHMD63',
                color: 'column-gray'
            }
        ];
    }
    if (FIREBASE_PROJECT === DEMO_PROJECT) {
        return [
            {
                name: 'Leo',
                id: 'QrteGJ0PPqH6aJkYqaNv',
                color: 'column-yellow'
            },
            {
                name: 'Megan',
                id: 'WeelaFRRAdCTtB4hRG0A',
                color: 'column-red'
            },
            {
                name: 'Teresa',
                id: '0wNRVmGCl4kENA41wt3s',
                color: 'column-green'
            }
        ];
    }
    if (FIREBASE_PROJECT === 'enotice-example-f061a') {
        return [
            {
                name: 'Hatem',
                id: '6uVN5v5sTDTFZyEn4KUrpwH9An93',
                color: 'column-blue'
            }
        ];
    }
    return [];
};
function PaginatorSelect({ updatePaginator }) {
    const { setOpen } = useContext(PopoverContext);
    return (_jsx("div", Object.assign({ className: "w-64 space-y-2 p-4" }, { children: getAvailablePaginators().map(paginator => {
            return (_jsx("div", Object.assign({ className: "w-full flex" }, { children: _jsx(ColumnButton, { type: 'button', link: true, buttonText: paginator.name, onClick: () => {
                        void updatePaginator(paginator.id);
                        setOpen(false);
                    } }, `${paginator.id}`) }), `wrapper-${paginator.id}`));
        }) })));
}
export default function PaginatorBadge({ publicationIssue, section }) {
    const [paginator, setPaginator] = useState();
    const updatePaginator = (id) => __awaiter(this, void 0, void 0, function* () {
        const newPaginator = getAvailablePaginators().find(p => p.id === id);
        if (!newPaginator) {
            logAndCaptureMessage('Selected paginator not available', {
                selectedPagintorId: id,
                currentPaginatorId: paginator === null || paginator === void 0 ? void 0 : paginator.id,
                currentPaginatorName: paginator === null || paginator === void 0 ? void 0 : paginator.name
            });
            return;
        }
        yield (section || publicationIssue).ref.update({
            paginator: getFirebaseContext().usersRef().doc(id)
        });
        setPaginator(newPaginator);
    });
    useEffect(() => {
        var _a;
        const initialPaginatorId = (_a = (section || publicationIssue).modelData.paginator) === null || _a === void 0 ? void 0 : _a.id;
        const initialPaginator = getAvailablePaginators().find(p => p.id === initialPaginatorId);
        setPaginator(initialPaginator);
    }, [section === null || section === void 0 ? void 0 : section.id, publicationIssue === null || publicationIssue === void 0 ? void 0 : publicationIssue.id]);
    return (_jsx("div", Object.assign({ onClick: (e) => {
            // Prevent the drawer from opening
            e.stopPropagation();
        } }, { children: _jsx(Popover, Object.assign({ id: "paginator-badge-popover", activator: _jsx(Badge, { children: _jsxs("div", Object.assign({ className: classNames('flex space-x-1 cursor-pointer', `bg-${paginator === null || paginator === void 0 ? void 0 : paginator.color}-100 text-${paginator === null || paginator === void 0 ? void 0 : paginator.color}-500`) }, { children: [_jsx("span", { children: (paginator === null || paginator === void 0 ? void 0 : paginator.name) || 'Unassgined' }), _jsx("span", Object.assign({ className: "m-auto" }, { children: _jsx(ChevronDownIcon, { className: "w-3 h-3" }) }))] })) }) }, { children: _jsx(PaginatorSelect, { updatePaginator: updatePaginator }) })) })));
}
