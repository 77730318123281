var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getModelFromRef } from '../../model';
import { NoteModel } from '../../model/objects/noteModel';
import * as EventTypes from '../../types/events';
import { NoteServiceError } from './NoteServiceError';
export class NoteService {
    constructor(ctx) {
        this.ctx = ctx;
    }
    createNote({ noteTopic, noteContent, attachments }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const newNote = yield this.saveNote({
                    noteTopic,
                    noteContent,
                    attachments
                });
                if (noteTopic.noteType === 'notice-note') {
                    yield this.createNoticeNoteAddedEvent({
                        note: newNote.ref,
                        noteTopic
                    });
                }
                return { note: newNote };
            }
            catch (err) {
                const error = NoteServiceError.getError(err);
                error.captureError({
                    noteCreatorId: noteContent.noteCreator.id,
                    noteCreatorOrganizationId: noteContent.noteCreatorOrganization.id,
                    noteType: noteTopic.noteType,
                    noteContent: noteContent.content
                });
                return { errorCode: error.errorCode };
            }
        });
    }
    createNoticeNoteAddedEvent({ note, noteTopic }) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ctx.eventsRef().add({
                type: EventTypes.NOTICE_NOTE_ADDED,
                createdAt: this.ctx.timestamp(),
                notice: noteTopic.notice,
                data: {
                    note
                }
            });
        });
    }
    saveNote({ noteTopic, noteContent, attachments }) {
        return __awaiter(this, void 0, void 0, function* () {
            const noteRef = yield this.ctx.notesRef().add(Object.assign(Object.assign(Object.assign({}, noteTopic), noteContent), { attachments, status: 'public', createdAt: this.ctx.timestamp() }));
            return yield getModelFromRef(NoteModel, this.ctx, noteRef);
        });
    }
}
NoteService.uploadPath = 'documentcloud/notes';
