import { jsx as _jsx } from "react/jsx-runtime";
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
export function AffidavitTemplateSelect({ labelText, value, onChange, disabled }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const query = getFirebaseContext()
        .affidavitTemplatesRef()
        .where('publisher', '==', activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref);
    const allTemplates = useFirestoreQueryListener(query, [
        activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id
    ]);
    const activeTemplates = allTemplates === null || allTemplates === void 0 ? void 0 : allTemplates.docs.filter(template => !template.data().archived).map(template => ({
        value: template.id,
        label: template.data().name
    }));
    return (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "custom-affidavit-template", disabled: disabled, labelText: labelText, options: activeTemplates !== null && activeTemplates !== void 0 ? activeTemplates : [], value: value === null || value === void 0 ? void 0 : value.id, onChange: id => onChange(getFirebaseContext().affidavitTemplatesRef().doc(id)) }) })));
}
