import { RateType } from '../enums';
import getApplicableRate from './getApplicableRate';
import { priceBattleBorn } from './rateTypes/battleBorn';
import { priceBerthoudGovernment } from './rateTypes/berthoudGovernment';
import { priceColumnInch } from './rateTypes/columnInch';
import { priceEnterprise } from './rateTypes/enterprise';
import { priceFlat } from './rateTypes/flat';
import { priceFolio } from './rateTypes/folio';
import { priceInch } from './rateTypes/inch';
import { priceIowaForm } from './rateTypes/iowaForm';
import { priceLine } from './rateTypes/line';
import { priceNebraska } from './rateTypes/nebraska';
import { priceOklahoma } from './rateTypes/oklahoma';
import { pricePerRun } from './rateTypes/perRun';
import { priceSingleColumnCentimetre } from './rateTypes/singleColumnCentimetre';
import { priceWordCount } from './rateTypes/wordCount';
/**
 * Calculate the price of a run in cents.
 *
 * TODO: This method is only used in two places
 *   - calculateSingleRunPrice
 *   - In a ReTool route (test-pricing) which I don't think is used much
 *
 * If the ReTool route can be removed or changed to rely on calculateSingleRunPrice()
 * then this method would not need to be exported.
 */
export const calculateDBPrice = (noticeRecord, rateRecord, 
/**
 * Note: For some reason we sometimes pass in {} as displayParameters,
 * which results in the "Unknown pricing scheme" error
 * as we're accessing undefined properties.
 */
displayParameters, numRuns, columns, dayRate, runNumber) => {
    if (noticeRecord.fixedPrice) {
        if (runNumber > 0)
            return 0;
        return noticeRecord.fixedPrice;
    }
    let result;
    const applicableRate = dayRate || getApplicableRate(numRuns, rateRecord, runNumber + 1);
    const pricingOptions = {
        noticeRecord,
        rateRecord,
        displayParameters,
        numRuns,
        columns,
        dayRate,
        runNumber,
        applicableRate
    };
    if (rateRecord.rateType === RateType.flat.value) {
        return priceFlat(pricingOptions);
    }
    if (rateRecord.rateType === RateType.per_run.value) {
        return pricePerRun(pricingOptions);
    }
    result = runNumber === 0 ? applicableRate : 0;
    if (rateRecord.rateType === RateType.word_count.value) {
        result = priceWordCount(pricingOptions);
    }
    if (rateRecord.rateType === RateType.folio.value) {
        result = priceFolio(pricingOptions);
    }
    if (rateRecord.rateType === RateType.inch.value) {
        result = priceInch(pricingOptions);
    }
    if (rateRecord.rateType === RateType.column_inch.value) {
        result = priceColumnInch(pricingOptions);
    }
    if (rateRecord.rateType === RateType.line.value) {
        result = priceLine(pricingOptions);
    }
    if (rateRecord.rateType === RateType.nebraska.value) {
        result = priceNebraska(pricingOptions);
    }
    if (rateRecord.rateType === RateType.oklahoma.value) {
        result = priceOklahoma(pricingOptions);
    }
    if (rateRecord.rateType === RateType.battle_born.value) {
        result = priceBattleBorn(pricingOptions);
    }
    if (rateRecord.rateType === RateType.berthoud_government.value) {
        result = priceBerthoudGovernment(pricingOptions);
    }
    if (rateRecord.rateType === RateType.enterprise.value) {
        result = priceEnterprise(pricingOptions);
    }
    if (rateRecord.rateType === RateType.single_column_centimetre.value) {
        result = priceSingleColumnCentimetre(pricingOptions);
    }
    if (rateRecord.rateType === RateType.iowa_form.value) {
        result = priceIowaForm(pricingOptions);
    }
    if (typeof result !== 'number') {
        console.error(`Unknown pricing scheme: ${rateRecord.rateType} with result ${result}`);
    }
    return result;
};
