import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { removeUndefinedFields } from 'lib/helpers';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { AffidavitTemplateSelect } from './AffidavitTemplateSelect';
/**
 * Determines whether or not Column manages automated affidavit signing for a notice type.
 * If the notice type does not have an affidavit reconciliation settings object, it is assumed
 * that Column manages signing for that notice type.
 * @param noticeType The notice type to check
 * @returns {boolean} Whether or not Column manages signing for the notice type
 */
const columnManagesSigningForNoticeType = (noticeType) => {
    var _a;
    if (!noticeType.affidavitReconciliationSettings)
        return true;
    // undefined is falsey, so we need to check for undefined explicitly
    if (noticeType.affidavitReconciliationSettings.affidavitsManagedByColumn ===
        undefined)
        return true;
    return !!((_a = noticeType.affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.affidavitsManagedByColumn);
};
const getNoticeTypeWetSignatureRequirementOptions = () => {
    return [
        {
            value: 'undefined',
            label: 'Use default requirement from newspaper'
        },
        {
            value: 'true',
            label: 'Always require wet signatures for this notice type'
        },
        {
            value: 'false',
            label: 'Never require wet signatures for this notice type'
        }
    ];
};
const getWetSignatureRequirementValueFromNoticeType = (noticeType) => {
    const { notarizationVendor } = noticeType.affidavitReconciliationSettings || {};
    if (notarizationVendor === undefined) {
        return 'undefined';
    }
    return notarizationVendor === 'manual' ? 'true' : 'false';
};
const getNoticeTypeInStateNotaryRequirementOptions = () => {
    return [
        {
            value: 'undefined',
            label: 'Use default requirement from newspaper'
        },
        {
            value: 'true',
            label: 'Always require an in-state notary for this notice type'
        },
        {
            value: 'false',
            label: 'Never require an in-state notary for this notice type'
        }
    ];
};
const getInStateNotaryRequirementValueFromNoticeType = (noticeType) => {
    const { requiresInStateNotary } = noticeType.affidavitReconciliationSettings || {};
    if (requiresInStateNotary === undefined) {
        return 'undefined';
    }
    return requiresInStateNotary === true ? 'true' : 'false';
};
export default function ColumnManagedAffidavitSettingsCard({ onUpdateNoticeType, updatedNoticeType }) {
    var _a;
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: 'Column Managed Affidavit Settings', description: '' } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Should Column sign affidavits for this notice type?", description: "If enabled, affidavits will be included in Column's automated affidavits service.", onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        const newReconciliationSettings = Object.assign(Object.assign({}, updatedNoticeType.affidavitReconciliationSettings), { affidavitsManagedByColumn: newValue });
                        if (newReconciliationSettings.affidavitsManagedByColumn) {
                            delete newReconciliationSettings.affidavitsManagedByColumn;
                        }
                        if (columnObjectsAreEqual(newReconciliationSettings, {})) {
                            delete newNoticeType.affidavitReconciliationSettings;
                        }
                        else {
                            newNoticeType.affidavitReconciliationSettings = newReconciliationSettings;
                        }
                        onUpdateNoticeType(newNoticeType);
                    }, value: columnManagesSigningForNoticeType(updatedNoticeType) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "wet-signature-requirement", labelText: "Should this notice type require a wet signature?", onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        const notarizationVendor = newValue === 'undefined'
                            ? undefined
                            : newValue === 'true'
                                ? 'manual'
                                : 'notarize';
                        const newReconciliationSettings = removeUndefinedFields(Object.assign(Object.assign({}, updatedNoticeType.affidavitReconciliationSettings), { notarizationVendor }));
                        newNoticeType.affidavitReconciliationSettings = newReconciliationSettings;
                        onUpdateNoticeType(newNoticeType);
                    }, options: getNoticeTypeWetSignatureRequirementOptions(), value: getWetSignatureRequirementValueFromNoticeType(updatedNoticeType) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "wet-signature-requirement", labelText: "Should this notice type require an in-state notary?", onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        const requiresInStateNotary = newValue === 'undefined' ? undefined : newValue === 'true';
                        const newReconciliationSettings = removeUndefinedFields(Object.assign(Object.assign({}, updatedNoticeType.affidavitReconciliationSettings), { requiresInStateNotary }));
                        newNoticeType.affidavitReconciliationSettings = newReconciliationSettings;
                        onUpdateNoticeType(newNoticeType);
                    }, options: getNoticeTypeInStateNotaryRequirementOptions(), value: getInStateNotaryRequirementValueFromNoticeType(updatedNoticeType) }) })), _jsx(AffidavitTemplateSelect, { labelText: "What affidavit template should we use for Column managed affidavits?", value: (_a = updatedNoticeType.affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.managedAffidavitTemplate, onChange: managedAffidavitTemplate => onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { affidavitReconciliationSettings: Object.assign(Object.assign({}, updatedNoticeType.affidavitReconciliationSettings), { managedAffidavitTemplate }) })) })] })));
}
