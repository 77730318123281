import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AuthActions, { selectIsUserLoggedOut } from 'redux/auth';
export function usePlacementAnonymousLogin() {
    const dispatch = useAppDispatch();
    const isLoggedOut = useAppSelector(selectIsUserLoggedOut);
    useEffect(() => {
        // If no user is logged in, create an anonymous login
        if (isLoggedOut) {
            dispatch(AuthActions.anonymousLogin());
            sessionStorage.setItem('startedFromAnonymousFlow', 'true');
        }
    }, [isLoggedOut]);
}
