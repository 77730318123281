import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useKeydownListener } from '../hooks/useKeydownListener';
import useOutsideClickListener from '../hooks/useOutsideClickListener';
export const PopoverContext = createContext({
    setOpen: () => null,
    open: false
});
export function Popover({ id, activator, children, popoverType, headerText, alignment = 'left', position = 'bottom', fullWidth = false, stopPropagation = true }) {
    const [open, setOpen] = useState(false);
    const popoverRef = useRef(null);
    const popoverRole = typeof popoverType === 'string' ? popoverType : 'menu';
    const closePopover = useCallback(() => setOpen(false), []);
    useKeydownListener('Escape', closePopover);
    useOutsideClickListener(popoverRef, closePopover);
    const popoverProviderValue = useMemo(() => ({ setOpen, open }), [
        setOpen,
        open
    ]);
    function togglePopover(event) {
        stopPropagation && event.stopPropagation();
        setOpen(!open);
    }
    const Activator = activator.type;
    const activatorProps = activator.props;
    const onClick = activatorProps.onClick || togglePopover;
    const activatorId = activatorProps.id || `${id}-toggle`;
    const panelId = `${id}-${popoverRole}`;
    const wrapperClasses = classNames('inline-block relative', {
        'w-full': fullWidth
    });
    const panelClasses = classNames(`absolute z-20 bg-white shadow-column-3 rounded-md border border-column-gray-150`, {
        hidden: !open,
        'w-full': fullWidth,
        'right-0': alignment === 'right'
    });
    const positionStyle = {
        [position === 'top' ? 'bottom' : 'top']: 'calc(100% + .5rem)'
    };
    return (_jsx(PopoverContext.Provider, Object.assign({ value: popoverProviderValue }, { children: _jsxs("span", Object.assign({ className: wrapperClasses, ref: popoverRef }, { children: [_jsx(Activator, Object.assign({ id: activatorId }, activatorProps, { "aria-expanded": open, "aria-haspopup": popoverRole, "aria-controls": panelId, onClick: onClick })), _jsxs("div", Object.assign({ style: positionStyle, id: panelId, className: panelClasses, role: popoverRole, "aria-labelledby": activatorId, hidden: !open, "aria-hidden": !open }, { children: [headerText && (_jsx("h2", Object.assign({ className: "uppercase font-semibold text-xs text-column-gray-300 px-4 pt-4" }, { children: headerText }))), children] }))] })) }), id));
}
