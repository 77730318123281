var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Badge } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import CreateCustomerModal from 'components/CustomerDrawer/CreateCustomerModal';
import UserIDCard from 'lib/components/UserIDCard';
import { getFirebaseContext } from 'utils/firebase';
import ToastActions from 'redux/toast';
import { TableLayout } from 'lib/components/TableLayout';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import GlobalCustomerSettings from './GlobalCustomerSettings';
const REGISTERED = 'Registered';
const NOT_REGISTERED = 'Not registered';
/**
 * Customer table row on the customers settings page
 */
function CustomerRow({ customer }) {
    return (_jsxs(_Fragment, { children: [_jsx("td", { children: _jsx(UserIDCard, { name: customer.userName, email: customer.userEmail }) }), _jsx("td", Object.assign({ className: "text-sm font-medium text-column-gray-400" }, { children: customer.internalId || '--' })), _jsx("td", { children: _jsx(Badge, Object.assign({ status: customer.lastSignInTime ? 'success' : 'critical' }, { children: customer.lastSignInTime ? REGISTERED : NOT_REGISTERED })) })] }));
}
/**
 * Wrapper component for the Customers table page.
 */
export default function CustomerTableTab({ data, loading, updateTableData, activeOrganization, onClickRow, onClickEdit, onChangeSearch, rates }) {
    const dispatch = useAppDispatch();
    const [loadingCustomerReport, setLoadingCustomerReport] = useState(false);
    const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
    const [showGlobalCustomerSettings, setShowGlobalCustomerSettings] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(TableLayout, { header: {
                    subtitle: 'People who have placed notices with your publication.',
                    title: 'Customers',
                    additionalContent: (_jsx("div", Object.assign({ className: "mr-3" }, { children: _jsx(ColumnButton, { size: "md", buttonText: "Download", startIcon: _jsx(ArrowDownTrayIcon, { className: "h-5 w-5" }), loading: loadingCustomerReport, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                setLoadingCustomerReport(true);
                                try {
                                    const { url } = yield api.get(`organizations/${activeOrganization.id}/customers-report`);
                                    window.open(url);
                                }
                                catch (e) {
                                    logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, e, 'Failed to download customers report', {
                                        newspaperId: activeOrganization.id
                                    });
                                }
                                finally {
                                    setLoadingCustomerReport(false);
                                }
                            }), type: "button" }) })))
                }, columns: [
                    'Name',
                    _jsx("th", Object.assign({ className: "font-medium pr-20" }, { children: "ID" }), "ID"),
                    'Status'
                ], renderRow: customer => CustomerRow({ customer }), data: data || [], loading: loading, id: "customer-settings", clickable: {
                    onClick: customer => onClickRow(customer.customerId)
                }, filterable: {
                    shouldShowTableItem: () => {
                        return true;
                    },
                    onSearch: (searchTerm) => {
                        onChangeSearch(searchTerm);
                    }
                }, creatable: {
                    onCreate: () => {
                        setShowCreateCustomerModal(true);
                    },
                    createButtonText: 'Add Customer'
                }, editable: {
                    editTooltip: 'Edit this customer.',
                    onEdit: customer => onClickEdit(customer.customerId)
                }, archivable: {
                    renderWarning: () => ({
                        header: `Delete Customer?`,
                        body: 'Are you sure you want to delete this customer? Once a customer is deleted, they will no longer be accessible. Please click “Delete” to confirm this action or “Cancel” to go back.',
                        buttonText: 'Delete'
                    }),
                    onArchive: (customer) => __awaiter(this, void 0, void 0, function* () {
                        try {
                            const customerRef = getFirebaseContext()
                                .customersRef()
                                .doc(customer.customerId);
                            yield customerRef.update({
                                archived: true
                            });
                            dispatch(ToastActions.toastSuccess({
                                headerText: 'Customer Deleted',
                                bodyText: `${customer.userName} has been successfully deleted from your customer list.`
                            }));
                            updateTableData();
                        }
                        catch (e) {
                            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, e, 'Error archiving customer', {
                                customerId: customer.customerId
                            });
                        }
                    }),
                    isArchiveDisabled: false,
                    enabledArchiveTooltip: 'Delete this customer.'
                }, configurable: {
                    buttonText: 'Global Settings',
                    onClick: () => {
                        setShowGlobalCustomerSettings(true);
                    }
                } }), showCreateCustomerModal && (_jsx(CreateCustomerModal, { closeModal: () => {
                    setShowCreateCustomerModal(false);
                    updateTableData();
                }, activeOrganization: activeOrganization, rates: rates })), showGlobalCustomerSettings && (_jsx(GlobalCustomerSettings, { onClose: () => setShowGlobalCustomerSettings(false) }))] }));
}
