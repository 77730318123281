import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { capitalizeWord } from 'lib/utils/strings';
import React from 'react';
import { SHOW_ALL_OPTION_VALUE } from './helpers';
export function ConfirmationStatusFilter({ confirmationStatuses, editedRowFilter, onSelectNewFilterValue }) {
    return (_jsx(ColumnSelect, { id: "newspaper-order-status", labelText: "Confirmation status", size: "small", options: [
            {
                value: SHOW_ALL_OPTION_VALUE,
                label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
            }
        ].concat(confirmationStatuses.map(status => ({
            value: status,
            label: capitalizeWord(status)
        }))), value: editedRowFilter.status, onChange: onSelectNewFilterValue }));
}
