import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import TabGroup from 'lib/components/Tabs';
import AutomatedAffidavitsTableDrawerEEditionsTab from './AutomatedAffidavitsTableDrawerEEditionsTab';
import NoticeList from './NoticeList';
export default function AutomatedAffidavitsTableDrawerBody({ drawerData, onUploadEEditionClick }) {
    const { verifiedRuns, unverifiedRuns, unverifiedNotices } = drawerData;
    const EEDITIONS_TAB = {
        id: 'eeditions-tab',
        label: 'E-Editions',
        enabled: true
    };
    const VERIFIED_NOTICES_TAB = {
        id: 'verified-notices-tab',
        label: `Verified Notices (${verifiedRuns.length})`,
        enabled: !!verifiedRuns.length
    };
    const UNVERIFIED_NOTICES_TAB = {
        id: 'unverified-notices-tab',
        label: `Unverified Notices (${unverifiedRuns.length})`,
        enabled: !!unverifiedRuns.length
    };
    const tabs = [EEDITIONS_TAB, VERIFIED_NOTICES_TAB, UNVERIFIED_NOTICES_TAB];
    const [activeTab, setActiveTab] = useState(EEDITIONS_TAB);
    return (_jsxs("div", Object.assign({ id: "automated-affidavits-table-drawer-body", className: "overflow-scroll flex flex-col flex-1" }, { children: [_jsx("div", Object.assign({ className: "border-b flex" }, { children: _jsx(TabGroup, { onClickTab: tab => setActiveTab(tab), activeTab: activeTab, tabs: tabs, id: "automated-affidavit-table-drawer-tabs" }) })), _jsxs("div", Object.assign({ className: "px-6 overflow-scroll flex-1" }, { children: [activeTab.id === EEDITIONS_TAB.id && (_jsx(AutomatedAffidavitsTableDrawerEEditionsTab, { publicationIssue: drawerData.publicationIssue, onUploadEEditionClick: onUploadEEditionClick })), activeTab.id === VERIFIED_NOTICES_TAB.id && (_jsx(NoticeList, { runs: verifiedRuns })), activeTab.id === UNVERIFIED_NOTICES_TAB.id && (_jsx(NoticeList, { runs: unverifiedRuns, preLoadedNotices: unverifiedNotices }))] }))] })));
}
