import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { selectShowAllOrgsNotices } from 'redux/auth';
import { Tooltip } from 'lib/components/Tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Popover } from 'lib/components/Popover';
import LinkTo from 'components/LinkTo';
import { modifierKey } from 'utils/hotkeys';
import HeaderBarClickableIcon from './HeaderBarClickableIcon';
function SettingsDropdownMenuItem({ description, link }) {
    return (_jsx(LinkTo, Object.assign({ className: "h-12 p-1 pl-3 font-medium text-sm cursor-pointer flex items-center w-full rounded-md hover:bg-column-primary-50 hover:text-column-primary-500 text-column-gray-400", href: link }, { children: description })));
}
export default function SettingsHeaderBarClickableIcon({ user }) {
    const userHasOrganization = !!user.data().activeOrganization;
    const showAllOrgNotices = useAppSelector(selectShowAllOrgsNotices);
    const dispatch = useAppDispatch();
    useHotkeys('mod+,', () => dispatch(push('/settings')), {
        enabled: !showAllOrgNotices,
        splitKey: '/',
        preventDefault: true
    }, []);
    useHotkeys('mod+.', () => dispatch(push('/settings/organization')), {
        enabled: !showAllOrgNotices && userHasOrganization
    }, []);
    return (_jsx(Popover, Object.assign({ id: "settings-dropdown-popover", activator: _jsx(HeaderBarClickableIcon, { id: "settings-icon", disabled: showAllOrgNotices, icon: _jsx(Cog8ToothIcon, { className: "w-6 h-6 mt-1" }), label: "Settings" }), alignment: "right" }, { children: _jsxs("div", Object.assign({ className: "w-48 p-2" }, { children: [_jsx(SettingsDropdownMenuItem, { description: _jsx(Tooltip, Object.assign({ helpText: `${modifierKey} + ,` }, { children: _jsx("span", { children: "My Account" }) })), link: "/settings" }), userHasOrganization && (_jsx(SettingsDropdownMenuItem, { description: _jsx(Tooltip, Object.assign({ helpText: `${modifierKey} + .` }, { children: _jsx("span", { children: "My Organization" }) })), link: "/settings/organization" })), _jsx(SettingsDropdownMenuItem, { description: "Logout", link: "/logout" })] })) })));
}
