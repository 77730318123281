var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { safeStringify } from 'lib/utils/stringify';
import LoadingState from 'components/LoadingState';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import api from 'api';
import { ColumnService } from 'lib/services/directory';
const PREVIEW_HEIGHT = 750;
// convert using the size of an A4 sheet of paper
const PREVIEW_WIDTH = Math.ceil((8.3 / 11.7) * PREVIEW_HEIGHT);
export default function InvoicePreview({ invoiceCreationData, notice }) {
    const [invoiceHtml, setInvoiceHTML] = useState('');
    const [loading, setLoading] = useState(true);
    const refreshInvoicePreview = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            const { invoiceHtml } = yield api.post('documents/generate-invoice-preview', {
                invoiceCreationData,
                noticeId: notice.id
            });
            setInvoiceHTML(invoiceHtml);
            setLoading(false);
        }
        catch (err) {
            logAndCaptureException(ColumnService.PAYMENTS, err, 'Error generating invoice preview', {
                noticeId: notice.id
            });
            setLoading(false);
        }
    });
    const debouncedInvoiceCreationData = useDebounce(safeStringify(invoiceCreationData), 2000);
    useEffect(() => {
        void refreshInvoicePreview();
    }, [debouncedInvoiceCreationData]);
    return (_jsxs("div", Object.assign({ className: "col-span-6 p-6 pl-9" }, { children: [loading && _jsx(LoadingState, {}), !loading && (_jsx("div", Object.assign({ className: "shadow-column-2 border-column-gray-100" }, { children: _jsx("div", Object.assign({ style: { height: PREVIEW_HEIGHT } }, { children: _jsx("iframe", { title: "Invoice Preview", className: "bg-white h-full w-full", srcDoc: invoiceHtml, height: PREVIEW_HEIGHT, width: PREVIEW_WIDTH }) })) })))] })));
}
