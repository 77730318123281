import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ExclamationCircle } from 'icons';
import { Alert } from 'lib/components/Alert';
import { ColumnButton } from 'lib/components/ColumnButton';
import DocumentIcon from 'lib/components/DocumentIcon';
function NoticeFilePreview({ noticeFile, isPublisher, noticeId, push, initialShowWarning = true }) {
    var _a;
    const urlFromOldSchemaMetadata = (noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.oldSchemaMetadata)
        ? noticeFile.oldSchemaMetadata[Object.keys(noticeFile.oldSchemaMetadata).filter((key) => !key.includes('File'))[0]]
        : undefined;
    const url = (noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.linkToUploadedFile) || urlFromOldSchemaMetadata;
    const [showWarning, setShowWarning] = useState(initialShowWarning);
    return (_jsxs(_Fragment, { children: [isPublisher && showWarning && (_jsx("div", Object.assign({ className: "shadow sm:rounded-md bottom-1/4 relative" }, { children: _jsx(Alert, { id: "no-upfront-proof-warning", title: _jsxs("p", { children: ["This notice needs to be formatted before it can be published. Download the file and complete the layout. Once it's ready, upload the file copy via \"Edit Notice\" or by", ' ', _jsx("span", Object.assign({ className: "font-bold underline cursor-pointer", onClick: () => {
                                    noticeId &&
                                        push &&
                                        push(`/place/${noticeId}?step=confirm-ad`);
                                } }, { children: "clicking here" })), '', "."] }), icon: _jsx(ExclamationCircle, { className: "w-5 h-5" }), onDismiss: () => setShowWarning(false), status: "warning" }) }))), _jsx("div", Object.assign({ className: `bg-white shadow-sm border border-grey-100 sm:rounded-md w-full relative mt-3 ${showWarning ? 'bottom-1/2' : 'bottom-3/4'}` }, { children: _jsxs("div", Object.assign({ className: "p-4 flex flex-row justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row items-center text-grey-400 truncate pr-3" }, { children: [_jsx(DocumentIcon, { fileExtensionString: noticeFile.fileFormat || '' }), _jsx("div", Object.assign({ className: "pl-3 w-full min-w-1/3 truncate" }, { children: (noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.originalFileName) ||
                                        ((_a = noticeFile === null || noticeFile === void 0 ? void 0 : noticeFile.oldSchemaMetadata) === null || _a === void 0 ? void 0 : _a['File Name']) }))] })), _jsx(ColumnButton, { secondary: true, size: "sm", buttonText: "Download", onClick: () => window.open(url, '_blank'), type: "button" })] })) }))] }));
}
export default NoticeFilePreview;
