var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { asyncFilter } from 'lib/helpers';
import { getModelFromRef } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { getFirebaseContext } from 'utils/firebase';
export const sortRunDataByNotice = (publicationIssuesAndRuns, options) => __awaiter(void 0, void 0, void 0, function* () {
    const runsSortedByNotice = publicationIssuesAndRuns
        .flatMap(pubIssueAndRuns => pubIssueAndRuns.verifiedRuns.concat(pubIssueAndRuns.unverifiedRuns))
        .reduce((noticeAndRunData, run) => {
        const noticeId = run.modelData.notice.id;
        const existingEntry = noticeAndRunData.find(noticeAndRun => noticeAndRun.noticeId === noticeId);
        if (existingEntry) {
            existingEntry.noticeRuns.push(run);
        }
        else {
            noticeAndRunData.push({
                noticeId,
                noticeRuns: [run]
            });
        }
        return noticeAndRunData;
    }, []);
    const noticeAndRunDataResp = yield asyncFilter(runsSortedByNotice, (noticeAndRunData) => __awaiter(void 0, void 0, void 0, function* () {
        if (options.limitToNoticesWithUnverifiedRuns &&
            noticeAndRunData.noticeRuns.every(run => run.isVerified())) {
            return null;
        }
        const noticeModel = yield getModelFromRef(UserNoticeModel, getFirebaseContext(), getFirebaseContext().userNoticesRef().doc(noticeAndRunData.noticeId));
        return Object.assign(Object.assign({}, noticeAndRunData), { noticeModel });
    }));
    if (noticeAndRunDataResp.error) {
        throw noticeAndRunDataResp.error;
    }
    return noticeAndRunDataResp.response;
});
/**
 * Returns all runs for the given publication issues, grouped by notice, where at least one run for each notice is unverified.
 */
export function useSortRunDataByNotice(publicationIssuesAndRuns, options) {
    const { value: noticeAndRunData, isLoading: noticeAndRunDataLoading, isError: noticeAndRunDataError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () { return sortRunDataByNotice(publicationIssuesAndRuns, options); }),
        dependencies: [
            publicationIssuesAndRuns.map(pi => pi.publicationIssue.id).join(',')
        ]
    });
    return {
        noticeAndRunData,
        noticeAndRunDataLoading,
        noticeAndRunDataError
    };
}
