import { CONFIRM_PROOF, STEP_IDS_TO_TITLES } from 'routes/placeScroll/helpers/calculatePlacementSteps';
export const getAffidavitMilestoneTrackerParams = (isInvoicePaid, isNoticePublished, isAffidavitAvailable, isAffidavitDisabled, isInvoicedOutsideColumn) => {
    const invoicePaidOutsideColumn = isInvoicePaid && isInvoicedOutsideColumn;
    return [
        {
            status: invoicePaidOutsideColumn
                ? 'completeOutsideColumn'
                : isInvoicePaid
                    ? 'complete'
                    : isNoticePublished
                        ? 'error'
                        : 'inactive',
            nextConnector: isInvoicePaid && isNoticePublished ? 'active' : 'inactive',
            text: invoicePaidOutsideColumn
                ? 'Invoiced Outside Column'
                : 'Invoice Paid',
            tooltip: isInvoicedOutsideColumn
                ? 'Contact newspaper for your invoice and payment status.'
                : ''
        },
        {
            status: isNoticePublished ? 'complete' : 'inactive',
            nextConnector: isNoticePublished && (isAffidavitAvailable || isAffidavitDisabled)
                ? 'active'
                : 'inactive',
            text: 'Publication Completed'
        },
        {
            status: isAffidavitAvailable || isAffidavitDisabled ? 'complete' : 'inactive',
            text: isAffidavitDisabled
                ? 'Affidavit Not Required'
                : 'Affidavit Available'
        }
    ];
};
const stepVisibleInPlacement = (step) => {
    // Confirm Proof appears as a button at the end of a placement
    // instead of a real step with inputs like the other steps
    return ![CONFIRM_PROOF].includes(step);
};
export const getNoticePlacementMilestoneTrackerParams = (steps, current) => {
    const visibleSteps = steps.filter(stepVisibleInPlacement);
    const currentIndex = visibleSteps.indexOf(current);
    return visibleSteps.map((step, index) => {
        return {
            status: index < currentIndex
                ? 'complete'
                : index === currentIndex
                    ? 'active'
                    : 'inactive',
            text: STEP_IDS_TO_TITLES[step]
        };
    });
};
