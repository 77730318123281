import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();
export const routerSelector = (state) => state.router;
export const reducer = (history) => connectRouter(history);
export const ADD_ORGANIZATION_ENTRYPOINT = '/add-organization/';
export const POST_REGISTRATION_ENTRYPOINT = '/register/occupations';
export const INDIVIDUAL_REGISTRATION_ENTRYPOINT = '/register/individual/';
export const PUBLISHER_REGISTRATION_ENTRYPOINT = '/register/publisher/';
export const ORGANIZATION_POST_REGISTRATION_ENTRYPOINT = '/register/organization';
export const APP_ENTRYPOINT = '/notices';
export const POST_ORGANIZATION_REGISTRATION_ENTRYPOINT = '/register/organization/post-registration';
export const PUBLISHER_PAYMENT_SETTINGS = '/settings/organization/?tab=payment-settings';
export const PAGINATION_TABLE = '/pagination';
