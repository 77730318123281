var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getOrThrow } from '../utils/refs';
import { wrapError, wrapSuccess } from '../types/responses';
import { ColumnService } from '../services/directory';
import { InternalServerError, wrapErrorAsColumnError } from '../errors/ColumnErrors';
import { getErrorReporter } from '../utils/errors';
export function safeAsync(func) {
    return (...args) => __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield func(...args);
            return wrapSuccess(response);
        }
        catch (error) {
            return wrapError(error);
        }
    });
}
export function safeGetOrThrow(ref) {
    return safeAsync(getOrThrow)(ref);
}
export function safeGetQuery(query) {
    return __awaiter(this, void 0, void 0, function* () {
        const { response: orderDetailResults, error: queryError } = yield safeAsync(() => query.get())();
        if (queryError) {
            getErrorReporter().logAndCaptureError(ColumnService.DATABASE, queryError, 'Error fetching query results');
            return wrapErrorAsColumnError(queryError, InternalServerError);
        }
        return wrapSuccess(orderDetailResults);
    });
}
