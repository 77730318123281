var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { push } from 'connected-react-router';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import AdvertisersInvitesForm from 'routes/invites/AdvertisersInvitesForm';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getRedirect } from 'utils/urls';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
export default function PostOrgRegistration() {
    const dispatch = useAppDispatch();
    const userSnap = useAppSelector(selectUser);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const onInviteCompleted = () => __awaiter(this, void 0, void 0, function* () {
        yield (userSnap === null || userSnap === void 0 ? void 0 : userSnap.ref.update({
            postRegistrationComplete: true
        }));
        yield (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref.update({
            postRegistrationComplete: true
        }));
        dispatch(push(`${getRedirect()}&activeOrg=${activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id}`));
    });
    if (!exists(activeOrganization))
        return _jsx(LoadingState, {});
    return (_jsx(AdvertisersInvitesForm, { onInviteCompleted: onInviteCompleted, organization: activeOrganization }));
}
