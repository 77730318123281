import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { exists } from 'lib/types';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { ExternalUploadForm } from './ExternalUploadForm';
export function ExternalUpload({ uploadFormat }) {
    const activeOrganization = useActiveOrganizationListener();
    if (!exists(activeOrganization)) {
        return null;
    }
    const { externalUploadSettings } = activeOrganization.data();
    return (_jsxs(_Fragment, { children: [_jsx(SettingsHeader, { header: "Upload to Your Statewide Site", description: "Upload invoiced notices on publication to your statewide site." }), _jsx(ExternalUploadForm, { uploadFormat: uploadFormat, uploadSettings: externalUploadSettings })] }));
}
