import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from '@material-ui/core';
import { RateType } from 'lib/enums';
import { Badge } from 'lib/components/Badge';
import { isRateShared, centsToExtendedCurrency } from 'lib/utils/rates';
import CurrencyBadge from 'components/CurrencyBadge';
import { shouldShowThirdRunRate, isDefaultRate, isLegacyRate } from './ratesTableSettingsUtils';
import { hidePricingDetailsInRatesTable } from '../ratesUtils';
function RateTableRowCell({ rate }) {
    const { additionalRates, rate_0, rate_1, rate_2 } = rate;
    let cellContent = (_jsxs("span", Object.assign({ className: "rate-row-cell" }, { children: [_jsx(CurrencyBadge, { value: rate_0 }), (rate.runBased || isLegacyRate(rate)) && (_jsxs(_Fragment, { children: [_jsx(CurrencyBadge, { value: rate_1 }), shouldShowThirdRunRate(rate) && _jsx(CurrencyBadge, { value: rate_2 })] })), additionalRates && (_jsx("span", { children: _jsxs(Badge, Object.assign({ status: "info" }, { children: ["+", Object.keys(additionalRates).length] })) }))] })));
    if (hidePricingDetailsInRatesTable(rate)) {
        cellContent = _jsx("span", Object.assign({ className: "rate-row-cell" }, { children: "N/A" }));
    }
    if (additionalRates) {
        const tooltipTitle = (_jsxs("p", { children: [_jsx("span", Object.assign({ className: "text-xs font-bold pb-1" }, { children: "Run Rates" })), _jsx("br", {}), _jsxs("span", Object.assign({ className: "text-xs pb-.5" }, { children: ["1st run rate \u2192 $", centsToExtendedCurrency(rate_0)] })), _jsx("br", {}), _jsxs("span", Object.assign({ className: "text-xs pb-.5" }, { children: ["2nd run rate \u2192 $", centsToExtendedCurrency(rate_1)] })), _jsx("br", {}), _jsxs("span", Object.assign({ className: "text-xs pb-.5" }, { children: ["3rd run rate \u2192 $", centsToExtendedCurrency(rate_2)] })), Object.values(additionalRates).map((rateValue, idx) => (_jsxs("span", Object.assign({ className: "text-xs pb-.5" }, { children: [_jsx("br", {}), idx + 4, "th run rate \u2192 $", centsToExtendedCurrency(rateValue)] }), idx)))] }));
        cellContent = _jsx(Tooltip, Object.assign({ title: tooltipTitle }, { children: cellContent }));
    }
    return _jsx("td", { children: cellContent });
}
const getDefaultDescription = (rateId, activeOrganization) => {
    var _a, _b;
    const isDefaultLinerRate = ((_a = activeOrganization.data().defaultLinerRate) === null || _a === void 0 ? void 0 : _a.id) === rateId;
    const isDefaultDisplayRate = ((_b = activeOrganization.data().defaultDisplayRate) === null || _b === void 0 ? void 0 : _b.id) === rateId;
    if (isDefaultLinerRate && isDefaultDisplayRate) {
        return 'Default Liner & Display Rate';
    }
    if (isDefaultLinerRate) {
        return 'Default Liner Rate';
    }
    return 'Default Display Rate';
};
export default function RatesTableRow({ activeOrganization, rate, rateId }) {
    var _a;
    return (_jsxs(_Fragment, { children: [_jsxs("td", Object.assign({ className: "text-column-gray-500" }, { children: [rate.description, isDefaultRate(activeOrganization, rateId) && (_jsx(Tooltip, Object.assign({ title: getDefaultDescription(rateId, activeOrganization) }, { children: _jsx("span", Object.assign({ className: "ml-2" }, { children: _jsx(Badge, Object.assign({ status: "success" }, { children: "Default" })) })) }))), isRateShared(rate) && (_jsx(Tooltip, Object.assign({ title: "Shared rate" }, { children: _jsx("span", Object.assign({ className: "ml-2" }, { children: _jsx(Badge, Object.assign({ status: "info" }, { children: "Shared" })) })) }))), isLegacyRate(rate) && (_jsx(Tooltip, Object.assign({ title: _jsxs("p", Object.assign({ className: "flex flex-col" }, { children: [_jsx("span", Object.assign({ className: "text-sm mb-1" }, { children: "Legacy rate" })), _jsx("span", Object.assign({ className: "text-xs" }, { children: "This rate uses a legacy structure and is no longer recommended. To update your rates to Column's suggested setup, reach out to your Customer Success Manager." }))] })) }, { children: _jsx("span", Object.assign({ className: "ml-2" }, { children: _jsx(Badge, { children: "Legacy Rate" }) })) })))] })), _jsx("td", { children: (_a = RateType.by_value(rate.rateType)) === null || _a === void 0 ? void 0 : _a.label }), _jsx(RateTableRowCell, { rate: rate })] }));
}
