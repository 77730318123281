export const ACTIVE_NOTES_VIEW = 'active-notes';
export const ARCHIVED_NOTES_VIEW = 'archived-notes';
const MAX_ATTACHMENT_NAME_LENGTH = 20;
/**
 * Makes sure that the attachments never exceed 20 characters
 */
export const formatAttachmentName = (name) => {
    if (name.length <= MAX_ATTACHMENT_NAME_LENGTH)
        return name;
    const extension = name.split('.').pop();
    const nameWithoutExtension = name.replace(`.${extension}`, '');
    const extensionLength = (extension === null || extension === void 0 ? void 0 : extension.length) || 0;
    const nameWithoutExtensionMaxLength = MAX_ATTACHMENT_NAME_LENGTH - extensionLength;
    return `${nameWithoutExtension.substring(0, nameWithoutExtensionMaxLength)}...${extension}`;
};
