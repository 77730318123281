var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { roundUp } from 'lib/pricing';
import { RateType, NoticeType } from 'lib/enums';
import { cdnIfy, getDisplayUnits, getNoticeType, removeUndefinedFields } from 'lib/helpers';
import { requestDisplayParameters } from 'lib/indesign/request';
import { exists } from 'lib/types';
import { ExclamationCircleIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import NoticeFilePreview from 'components/noticePreview/NoticeFilePreview';
import { getCreatePublisherDocsOnCallFn } from 'utils/callableFunctions';
import { NoticeFileTypes, isNoticeContent } from 'lib/types/notice';
import { getFirebaseContext } from 'utils/firebase';
import { DESIGN_NOTES_MAX_CHARS } from 'routes/placeScroll/helpers';
import { DESIGN_NOTES_UPDATED } from 'lib/types/events';
import { getIndesignServerClient } from 'utils/indesign';
import { Alert } from 'lib/components/Alert';
import AffinityXSyncPanel from 'components/AffinityXSyncPanel';
import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import UserIDCard from 'lib/components/UserIDCard';
import { Buddy } from 'lib/components/gifs';
import { logAndCaptureMessage } from 'utils';
import { TextAreaField } from 'lib/components/TextAreaField';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { NoticePreviewDownloadButtons } from './NoticePreviewDownloadButtons';
function NoticePreview({ invoiceOverdue, isPublisher, newspaper, notice, user, push, showAffinityXSyncPanel }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const [isHovering, setIsHovering] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [rate, setRate] = useState();
    const [displayParams, setDisplayParams] = useState((_a = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _a === void 0 ? void 0 : _a.displayParams);
    const [noticeFiles, setNoticeFiles] = useState(null);
    const designNotesMessage = (_b = notice.data().designNotes) === null || _b === void 0 ? void 0 : _b.message;
    const [editedDesignNotes, setEditedDesignNotes] = useState(designNotesMessage || '');
    const [lastDesignNotesEditor, setLastDesignNotesEditor] = useState();
    const [showEditDesignNotesModal, setShowEditDesignNotesModal] = useState(false);
    const [designNotesErrorText, setDesignNotesErrorText] = useState('');
    const inInvoiceRoute = window.location.pathname.includes('invoice');
    const [createPublisherDocsAttempts, setCreatePublisherDocsAttempts] = useState(1);
    const measurement = getDisplayUnits(rate, (_c = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _c === void 0 ? void 0 : _c.displayParams);
    useEffect(() => {
        if (!exists(notice))
            return;
        const fetchNoticeFiles = () => __awaiter(this, void 0, void 0, function* () {
            const filesFromNotice = yield getFirebaseContext()
                .userNoticeFilesRef(notice.ref)
                .get();
            if (filesFromNotice.size) {
                setNoticeFiles(filesFromNotice.docs);
            }
            else {
                setNoticeFiles(null);
            }
        });
        void fetchNoticeFiles();
    }, [
        notice.id,
        // Listening to displayUrl to force refresh of notice files when ad is sideloaded
        notice.data().displayUrl
    ]);
    useEffect(() => {
        var _a;
        if ((_a = notice.data().designNotes) === null || _a === void 0 ? void 0 : _a.lastEditedBy) {
            const fetchDesignNotesEditor = () => __awaiter(this, void 0, void 0, function* () {
                var _b, _c;
                const editorUser = yield ((_c = (_b = notice.data().designNotes) === null || _b === void 0 ? void 0 : _b.lastEditedBy) === null || _c === void 0 ? void 0 : _c.get());
                if (exists(editorUser)) {
                    setLastDesignNotesEditor(editorUser);
                }
            });
            void fetchDesignNotesEditor();
        }
    }, [(_e = (_d = notice.data().designNotes) === null || _d === void 0 ? void 0 : _d.lastEditedBy) === null || _e === void 0 ? void 0 : _e.id]);
    // TODO(APP-150): We were calling createPublisherDocs too often. This should
    // reduce the frequency of the calls without changing the actual behavior.
    const MAX_ATTEMPTS = 3;
    const debounceDelayInMs = 1000 * createPublisherDocsAttempts;
    const debouncedNotice = useDebounce(notice, debounceDelayInMs);
    useEffect(() => {
        if (!notice)
            return;
        if (createPublisherDocsAttempts > MAX_ATTEMPTS) {
            logAndCaptureMessage('Failed to create publisher docs from notice preview 3 times; will not try again', {
                noticeId: notice.id,
                userId: (user === null || user === void 0 ? void 0 : user.id) || 'undefined'
            });
            return;
        }
        const shouldRetryFileCreation = () => {
            if (notice.data().noticeType === NoticeType.display_ad.value ||
                notice.data().pdfURL ||
                notice.data().postWithoutFormatting) {
                return false;
            }
            return true;
        };
        if (shouldRetryFileCreation()) {
            const createPublisherDocs = getCreatePublisherDocsOnCallFn();
            void createPublisherDocs({
                noticeId: notice.id
            });
            setCreatePublisherDocsAttempts(createPublisherDocsAttempts + 1);
        }
    }, [debouncedNotice]);
    const getColumns = () => __awaiter(this, void 0, void 0, function* () {
        setRate(yield notice.data().rate.get());
    });
    const getDisplayParams = () => __awaiter(this, void 0, void 0, function* () {
        const displayParameters = yield requestDisplayParameters(getFirebaseContext(), getIndesignServerClient(), notice, window.DOMParser);
        setDisplayParams(displayParameters);
        yield notice.ref.update({
            displayParams: displayParameters
        });
    });
    useEffect(() => {
        if (notice.data().postWithoutFormatting)
            return;
        if (notice && notice.data().noticeType === NoticeType.display_ad.value) {
            void getColumns();
        }
        if (notice && notice.data().noticeType !== NoticeType.display_ad.value) {
            if (!notice.data().displayParams) {
                void getDisplayParams();
            }
            else {
                setDisplayParams(notice.data().displayParams);
            }
        }
    }, [notice, newspaper]);
    useEffect(() => {
        if (displayParams) {
            void (() => __awaiter(this, void 0, void 0, function* () { return setRate(yield notice.data().rate.get()); }))();
        }
    }, [displayParams, newspaper]);
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const handleOpenModal = () => {
        if (notice.data().noticeType === NoticeType.display_ad.value) {
            const { pdfStoragePath } = notice.data();
            if (pdfStoragePath) {
                window.open(cdnIfy(pdfStoragePath, { useColumnCDN }));
            }
        }
        else {
            setOpenModal(true);
        }
    };
    const showClick = (_jsx("a", Object.assign({ className: "absolute rounded-t h-full w-full flex items-center justify-center", onClick: () => setOpenModal(true) }, { children: _jsxs("div", Object.assign({ className: "opacity-100 text-current text-center text-2xl " }, { children: [_jsx("div", Object.assign({ className: "h-12" }, { children: _jsx(ArrowsPointingOutIcon, { className: "inline-block align-baseline stroke-current mx-10 h-full" }) })), "Click to expand"] })) })));
    const [imgLoaded, setImgLoaded] = useState(false);
    const { jpgStoragePath, pdfStoragePath } = notice.data();
    const renderDesignNotesAndAffinityX = () => {
        const noticeType = getNoticeType(notice, newspaper, {
            skipDisplayType: true
        });
        const showDesignNotes = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.showDesignNotes) ||
            notice.data().postWithoutFormatting ||
            (notice.data().processedDisplay && newspaper.data().buildExport);
        return (_jsxs(_Fragment, { children: [showDesignNotes && (_jsxs("div", Object.assign({ className: "mt-8 w-full" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex flex-row justify-between items-center" }, { children: [_jsx("h2", Object.assign({ className: "text-xl font-bold text-gray-800" }, { children: "Design Note" })), isPublisher && (_jsxs("div", Object.assign({ onClick: () => setShowEditDesignNotesModal(true), className: "inline-block items-center px-2 text-base font-medium text-primary-500 cursor-pointer" }, { children: [designNotesMessage ? 'Edit' : 'Add', " note"] })))] })), designNotesMessage ? (_jsxs("div", Object.assign({ className: "p-4 mt-3 max-h-16 border shadow-sm sm:rounded-lg w-full relative bottom-3/4 text-sm border-gray-300 text-grey-400" }, { children: [designNotesMessage, lastDesignNotesEditor && (_jsx("div", Object.assign({ className: "flex flex-row justify-between items-end mt-3" }, { children: _jsx(UserIDCard, { name: lastDesignNotesEditor.data().name, email: lastDesignNotesEditor.data().email }) })))] }))) : (_jsxs("div", Object.assign({ className: "h-full flex flex-col items-center shadow-sm border sm:rounded-md border-grey-150 p-8 mt-3" }, { children: [_jsx("div", { children: _jsx("img", { className: "h-12 bg-column-primary-100 rounded-full p-1", src: Buddy }) }), _jsx("div", Object.assign({ className: "text-sm text-grey-300 mt-1" }, { children: "You don't have any notes yet" }))] }))), showAffinityXSyncPanel && (_jsx("div", Object.assign({ className: "text-grey-300 text-xs mt-1" }, { children: "This will be sent in the sync to AffinityX." })))] }))), showAffinityXSyncPanel && user && (_jsx(AffinityXSyncPanel, { noticeSnap: notice, requestingUser: user, newspaperSnap: newspaper, push: push }))] }));
    };
    return (_jsxs("div", Object.assign({ className: "flex-1" }, { children: [_jsxs("div", Object.assign({ className: "grid grid-cols-4 gap-4 p-8 h-full" }, { children: [_jsx("div", Object.assign({ className: "row row-span-1 col-span-4" }, { children: notice.data().postWithoutFormatting &&
                            !!noticeFiles &&
                            noticeFiles.some(noticeFile => isNoticeContent(noticeFile)) &&
                            !inInvoiceRoute ? (_jsxs(_Fragment, { children: [noticeFiles
                                    .filter(noticeFile => noticeFile.data().type ===
                                    NoticeFileTypes.display_ad_component)
                                    .map((noticeFile, index) => (_jsx(NoticeFilePreview, { noticeFile: noticeFile.data(), noticeId: notice.id, isPublisher: isPublisher, push: push, initialShowWarning: index === 0 }, `${noticeFile.data().originalFileName}-notice-file-preview`))), renderDesignNotesAndAffinityX()] })) : (_jsx("div", Object.assign({ className: `rounded w-full ${inInvoiceRoute ? '' : 'border border-gray-400'} shadow md:shadow-none` }, { children: _jsxs("div", Object.assign({ className: "grid" }, { children: [_jsxs("div", Object.assign({ className: `relative ${inInvoiceRoute
                                            ? 'bg-gray-600 rounded-t'
                                            : 'bg-gray-150 rounded'} flex`, onMouseEnter: () => setIsHovering(true), onMouseLeave: () => setIsHovering(false) }, { children: [_jsxs("button", Object.assign({ className: `grid items-center outline-none row-span-1 p-3 h-80 overflow-y-auto ${isHovering ? 'z-1 opacity-50' : ''} w-full`, onClick: handleOpenModal }, { children: [_jsx("img", { id: "notice-preview-image", src: jpgStoragePath
                                                            ? cdnIfy(jpgStoragePath, { useColumnCDN })
                                                            : pdfStoragePath
                                                                ? cdnIfy(pdfStoragePath, {
                                                                    cloudinaryTransformations: 'f_jpg',
                                                                    useColumnCDN
                                                                })
                                                                : '', className: `w-auto m-auto ${isHovering ? 'opacity-50' : ''} ${!imgLoaded ? 'hidden' : ''}`, ref: img => {
                                                            if (!img)
                                                                return;
                                                            // eslint-disable-next-line no-param-reassign
                                                            img.onload = () => {
                                                                setImgLoaded(true);
                                                            };
                                                        } }), _jsx("div", { className: ` ${imgLoaded ? 'hidden' : ''} block align-middle m-auto loader ease-linear rounded-full border-4 text-center border-t-4 border-white-500 h-6 w-6` })] })), isHovering && showClick] })), _jsxs("div", Object.assign({ className: `${inInvoiceRoute ? 'bg-gray-400' : 'bg-gray-150 bg-opacity-20'} p-3 rounded-b row-span-1` }, { children: [_jsx("h4", Object.assign({ className: "font-medium text-gray-900 uppercase" }, { children: notice.data().noticeType === NoticeType.display_ad.value
                                                    ? 'Display Ad'
                                                    : 'Liner Ad' })), notice.data().noticeType === NoticeType.display_ad.value ? (_jsx("p", { children: `${notice.data().columns} Column(s) Wide - ${measurement.value
                                                    ? roundUp(measurement.value, (_f = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _f === void 0 ? void 0 : _f.roundOff).toFixed(2)
                                                    : 0} ${measurement.unit}` })) : (''), displayParams &&
                                                (displayParams === null || displayParams === void 0 ? void 0 : displayParams.words) &&
                                                rate &&
                                                notice.data().noticeType !== NoticeType.display_ad.value ? (_jsx("p", Object.assign({ "data-testid": "display-unit" }, { children: `${displayParams.words} ${RateType.word_count.plural} - ${displayParams.lines} ${RateType.line.plural} - ${measurement.value && notice.data().displayParams
                                                    ? roundUp(measurement.value, (_g = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _g === void 0 ? void 0 : _g.roundOff).toFixed(2)
                                                    : 0} ${measurement.unit}` }))) : ('')] }))] })) }))) })), invoiceOverdue && isPublisher && (_jsx("div", Object.assign({ className: "row row-span-1 col-span-4" }, { children: _jsx(Alert, { id: "alert-invoice-overdue", status: "warning", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }), title: `Warning: The advertiser's invoice payment is overdue. Are you sure you would still like to publish this notice?` }) }))), !notice.data().postWithoutFormatting && (_jsxs("footer", Object.assign({ className: "col-span-4 mt-auto" }, { children: [_jsx("h4", Object.assign({ className: `text-lg ${inInvoiceRoute ? 'text-white' : 'text-column-gray-500'} mb-4 font-medium` }, { children: "Download" })), _jsx(NoticePreviewDownloadButtons, { notice: notice, isPublisher: isPublisher, noticeFiles: noticeFiles }), renderDesignNotesAndAffinityX()] })))] })), openModal && (_jsx(ImagePreviewModal, { jpgURLs: pdfStoragePath
                    ? [
                        cdnIfy(pdfStoragePath, {
                            cloudinaryTransformations: `f_jpg/e_sharpen`,
                            useColumnCDN
                        })
                    ]
                    : [], setOpenModal: setOpenModal })), showEditDesignNotesModal && (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setShowEditDesignNotesModal(false), primaryButtonText: "Save", tertiaryButtonText: "Cancel", header: "Design Note", body: "Make changes to the design note and save.", onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                    const lastEdited = getFirebaseContext().timestamp();
                    yield notice.ref.update({
                        designNotes: removeUndefinedFields({
                            lastEditedAt: lastEdited,
                            message: editedDesignNotes,
                            lastEditedBy: user === null || user === void 0 ? void 0 : user.ref
                        })
                    });
                    yield getFirebaseContext()
                        .eventsRef()
                        .add({
                        type: DESIGN_NOTES_UPDATED,
                        createdAt: getFirebaseContext().timestamp(),
                        notice: notice.ref,
                        data: removeUndefinedFields({
                            editedAt: lastEdited,
                            message: editedDesignNotes,
                            editedBy: user === null || user === void 0 ? void 0 : user.ref
                        })
                    });
                    setShowEditDesignNotesModal(false);
                }) }, { children: _jsxs("div", Object.assign({ className: "my-6" }, { children: [_jsx(TextAreaField, { id: "design-notes-edit-modal-input", value: editedDesignNotes, rows: 3, labelText: "", onChange: value => {
                                let sanitizedValue;
                                if (value.length > DESIGN_NOTES_MAX_CHARS) {
                                    setDesignNotesErrorText(`Maximum ${DESIGN_NOTES_MAX_CHARS} characters allowed.`);
                                    sanitizedValue = value.slice(0, DESIGN_NOTES_MAX_CHARS);
                                }
                                else {
                                    setDesignNotesErrorText('');
                                    sanitizedValue = value;
                                }
                                setEditedDesignNotes(sanitizedValue);
                            }, disableResizing: true }), _jsx("div", Object.assign({ id: "design-notes-edit-modal-input-error", role: "alert", className: "pt-2 text-xs text-red-600" }, { children: designNotesErrorText }))] })) })))] })));
}
export default NoticePreview;
