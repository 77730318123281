var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Form } from 'lib/components/Form';
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import MultiStepHeader from './components/MultiStepHeader';
function ResumeOrder({ email, login }) {
    const [loading, setLoading] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [error, setError] = useState();
    const loadOrderAndGoToNextStep = (accessCode, email) => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield login(accessCode, email);
        }
        catch (_a) {
            return setError('The access code you entered does not match the email address associated. Please re-enter the access code found in your email to resume your order.');
        }
        finally {
            setLoading(false);
        }
        // Remove query params from URL if they exist, e.g. ?email=
        window.history.replaceState(null, '', window.location.pathname);
    });
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepHeader, { title: "Resume your order", description: "Enter your email and access code to resume your order" }), _jsx(Form, Object.assign({ onSubmit: () => loadOrderAndGoToNextStep(accessCode, email) }, { children: _jsxs("div", Object.assign({ className: "space-y-3" }, { children: [_jsx(TextField, { id: "email", value: email, type: "email", labelText: "Email", disabled: true, required: true }), _jsx(TextField, { id: "access-code", value: accessCode, type: "text", onChange: value => {
                                if (error) {
                                    setError('');
                                }
                                setAccessCode(value);
                            }, labelText: "Access Code", required: true, errorText: error }), _jsx(ColumnButton, { primary: true, type: "submit", buttonText: "Load Order", loading: loading })] })) }))] }));
}
export default ResumeOrder;
