import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import { Permissions } from 'lib/permissions/roles';
import { getIsAfterPublishingDeadlineSimplified } from 'lib/utils/deadlines';
import { useState, useEffect } from 'react';
import { userIsSuper } from 'utils/permissions';
import { useHasPermission } from 'utils/useHasPermission';
const getEnabledButton = () => ({
    enabled: true,
    disableReason: null
});
const getDisabledButton = (disableReason) => ({
    enabled: false,
    disableReason
});
export const useSyncButton = (ctx, { notice, newspaper, user }) => {
    const exportSettings = useSyncExportSettings(newspaper);
    const { disableSyncButton } = exportSettings !== null && exportSettings !== void 0 ? exportSettings : {};
    const syncEventQuery = ctx
        .eventsRef()
        .where('notice', '==', notice.ref)
        .orderBy('data.syncStatus')
        .orderBy('createdAt', 'desc')
        .limit(1);
    const syncEvents = useFirestoreQueryListener(syncEventQuery, [notice.id]);
    const [isAfterDeadline, setIsAfterDeadline] = useState(getIsAfterPublishingDeadlineSimplified(notice, newspaper));
    // In case the page is loaded before deadline, we set a timeout to update the `isAfterDeadline`
    // value once the deadline is reached.
    useEffect(() => {
        if (isAfterDeadline) {
            return;
        }
        const intervalMs = 5 /** minutes */ * 60 /** seconds */ * 1000; /** milliseconds */
        const interval = setInterval(() => {
            const isAfterDeadlineNow = getIsAfterPublishingDeadlineSimplified(notice, newspaper);
            if (isAfterDeadlineNow) {
                clearInterval(interval);
            }
            setIsAfterDeadline(isAfterDeadlineNow);
        }, intervalMs);
        return () => clearInterval(interval);
    }, [notice.id]);
    const isSuper = userIsSuper(user);
    const hasSyncNoticePermission = useHasPermission(Permissions.NOTICES_MANUAL_SYNC);
    if (!hasSyncNoticePermission) {
        return getDisabledButton('You do not have permission to sync notices.');
    }
    if (!disableSyncButton || isSuper) {
        return getEnabledButton();
    }
    const { basedOnDeadline, basedOnSyncStatus } = disableSyncButton;
    if (!basedOnDeadline && !basedOnSyncStatus) {
        return getDisabledButton('Your newspaper prevents syncing manually.');
    }
    switch (basedOnDeadline) {
        case 'after': {
            if (isAfterDeadline) {
                return getDisabledButton('Your newspaper prevents syncing manually after deadline.');
            }
            break;
        }
        case 'before': {
            if (!isAfterDeadline) {
                return getDisabledButton('Your newspaper prevents syncing manually before deadline.');
            }
            break;
        }
        default: {
            break;
        }
    }
    if (basedOnSyncStatus === null || basedOnSyncStatus === void 0 ? void 0 : basedOnSyncStatus.length) {
        // If basedOnSyncStatus is set and the sync event query is still loading, we can't determine
        // if the sync button should be enabled yet.
        if (syncEvents === null) {
            return getDisabledButton('Confirming you can sync this notice.');
        }
        const [latestSyncEvent] = syncEvents.docs;
        if (latestSyncEvent) {
            const { syncStatus } = latestSyncEvent.data().data;
            if (basedOnSyncStatus.includes(syncStatus)) {
                return getDisabledButton('This notice has already synced.');
            }
        }
    }
    return getEnabledButton();
};
