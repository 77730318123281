import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createPortal } from 'react-dom';
import { useKeydownListener } from '../hooks/useKeydownListener';
export function Dialog({ children, labelledBy, onClose }) {
    useKeydownListener('Escape', onClose);
    const dialogClasses = 'fixed inset-0 z-modal flex flex-col justify-end md:justify-center md:items-center md:p-4 max-h-full';
    const container = document.getElementById('root') || document.body;
    return createPortal(_jsx("div", Object.assign({ className: dialogClasses, role: "dialog", "aria-modal": true, "aria-labelledby": labelledBy }, { children: children })), container);
}
