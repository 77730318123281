var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'lib/components/Form';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { exists } from 'lib/types';
import React from 'react';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { getFirebaseContext } from 'utils/firebase';
import { CardGridLayout, GridInput, useEditableCard } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
const getFileUploadSettings = (organization) => {
    if (!exists(organization)) {
        return null;
    }
    const { disableDisplay, displayOnlyAds, grayscalePDFDisplayUploads, allowImagesInLiners, allowMultipleDisplayFileUploads, cleanVariant, restrictSubmitWithoutFormatting } = organization.data();
    return {
        disableDisplay,
        displayOnlyAds,
        grayscalePDFDisplayUploads,
        allowImagesInLiners,
        allowMultipleDisplayFileUploads,
        cleanVariant,
        restrictSubmitWithoutFormatting
    };
};
export function FileUploads() {
    const activeOrganization = useActiveOrganizationListener();
    const currentSettings = getFileUploadSettings(activeOrganization);
    const { onChange, onSubmitWrapper, editable, formSettings, disabled } = useEditableCard(currentSettings);
    if (!activeOrganization || !formSettings) {
        return null;
    }
    return (_jsx(Form, Object.assign({ id: "publisher-settings-layout-file-uploads", onSubmit: () => onSubmitWrapper(() => __awaiter(this, void 0, void 0, function* () {
            yield activeOrganization.ref.update(replaceUndefinedWithDelete(getFirebaseContext(), formSettings));
            return { success: true };
        })), "aria-label": "File Uploads" }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'File Uploads',
                description: 'Configure how notices are created from file uploads.'
            }, editable: editable }, { children: [_jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Disable display notices?", description: "If enabled, all notices will be processed as text-based notices.", value: !!formSettings.disableDisplay, onChange: disableDisplay => {
                            // If disabling displays, then `displayOnlyAds` cannot be true.
                            if (disableDisplay) {
                                onChange({ disableDisplay, displayOnlyAds: false });
                            }
                            else {
                                onChange({ disableDisplay });
                            }
                        }, disabled: disabled }) }), _jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Allow only display notices?", description: "If enabled, all notices will be processed as display notices (images).", value: !!formSettings.displayOnlyAds, onChange: displayOnlyAds => {
                            // If enabling only displays, then `disableDisplays` cannot be true.
                            if (displayOnlyAds) {
                                onChange({ displayOnlyAds, disableDisplay: false });
                            }
                            else {
                                onChange({ displayOnlyAds });
                            }
                        }, disabled: disabled }) }), _jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Grayscale display notices?", description: "If enabled, all display notices (PDFs and images) will be converted to black-and-white for publication.", value: !!formSettings.grayscalePDFDisplayUploads, onChange: grayscalePDFDisplayUploads => onChange({ grayscalePDFDisplayUploads }), disabled: disabled }) }), _jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Allow images in liner notices?", description: "If enabled customers can include images in text-based notices, such as logos or signatures.", value: !!formSettings.allowImagesInLiners, onChange: allowImagesInLiners => onChange({ allowImagesInLiners }), disabled: disabled }) }), _jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Disable tables?", description: "This settings will remove table formatting from all files. Tables will be converted into plain text.", value: formSettings.cleanVariant === 'squash', onChange: toggleValue => {
                            if (toggleValue) {
                                onChange({ cleanVariant: 'squash' });
                            }
                            else {
                                onChange({ cleanVariant: undefined });
                            }
                        }, disabled: disabled }) }), _jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Restrict submit without formatting?", description: "If enabled, customers will not see the option to submit without formatting unless their files are too large to process in a timely fashion.", value: Boolean(formSettings.restrictSubmitWithoutFormatting), onChange: restrictSubmitWithoutFormatting => onChange({ restrictSubmitWithoutFormatting }), disabled: disabled }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Allow multiple file uploads?", description: "If enabled, customers can upload multiple files when placing notices. Notices submitted with multiple files require formatting before publication.", value: !!formSettings.allowMultipleDisplayFileUploads, onChange: allowMultipleDisplayFileUploads => onChange({ allowMultipleDisplayFileUploads }), disabled: disabled }) }))] })) })));
}
