var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { exists } from 'lib/types';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { logInfo } from 'utils/logger';
import { userIsSuper } from 'utils/permissions';
import { useState } from 'react';
import { removeUndefinedFields } from 'lib/helpers';
import { Product } from 'lib/enums';
import GlobalDeadlineSettings from './globalDeadlineSettings';
import { RegularDeadlines } from './RegularDeadlines';
function RegularNoticeDeadlines() {
    const user = useAppSelector(selectUser);
    const isSuperUser = user && userIsSuper(user);
    const activeOrganization = useActiveOrganizationListener();
    const [showGlobalDeadlineSettings, setShowGlobalDeadlineSettings] = useState(false);
    if (!exists(activeOrganization)) {
        return _jsx(LoadingSpinner, {});
    }
    if (showGlobalDeadlineSettings) {
        return (_jsx(GlobalDeadlineSettings, { onClose: () => setShowGlobalDeadlineSettings(false), onSubmit: (formState) => __awaiter(this, void 0, void 0, function* () {
                const { allowInitiated, requireInvoiceAtDeadline, requireUpfrontPaymentAtDeadline, requireConfirmation } = formState;
                yield activeOrganization.ref.update(removeUndefinedFields({
                    cancelNoticeSettings: {
                        allowInitiated,
                        requireUpfrontPaymentAtDeadline,
                        requireInvoiceAtDeadline
                    },
                    requireConfirmation
                }));
            }) }));
    }
    const { iana_timezone, deadlines } = activeOrganization.data();
    return (_jsx(RegularDeadlines, { productLine: Product.Notice, deadlineSettings: deadlines !== null && deadlines !== void 0 ? deadlines : [], timeZone: iana_timezone, updateRow: (updatedDeadlineSettings) => __awaiter(this, void 0, void 0, function* () {
            const { deadlines } = activeOrganization.data();
            const { dayEnum } = updatedDeadlineSettings;
            if (!deadlines)
                throw new Error('No deadlines found for newspaper');
            const newDeadlines = [
                ...deadlines.slice(0, dayEnum - 1),
                updatedDeadlineSettings,
                ...deadlines.slice(dayEnum)
            ];
            // This is temporary until we can add proper audit logging on org updates
            logInfo('Updating notice deadlines', {
                newDeadlines,
                dayEnum,
                deadlines,
                userId: user === null || user === void 0 ? void 0 : user.id,
                publisher: activeOrganization.id
            });
            yield activeOrganization.ref.update({
                deadlines: newDeadlines
            });
        }), configurable: isSuperUser
            ? {
                buttonText: 'Global Deadline Settings',
                onClick: () => {
                    setShowGlobalDeadlineSettings(true);
                }
            }
            : undefined }));
}
export default RegularNoticeDeadlines;
