import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { isNoticeFilingType } from 'lib/types/filingType';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { userIsSuper } from 'utils/permissions';
import { exists } from 'lib/types';
import { FilingTypeVisibility } from 'lib/enums';
import DrawerPropertyRow from '../../DrawerPropertyRow';
import FormSwitch from '../../FormSwitch';
import { getOrganizationDefaultColumnDescription } from '../noticeTypesHelpers';
function NoticeFilingTypeBasicProperties({ noticeType }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const template = useFirestoreSnapshot(noticeType.template);
    const user = useAppSelector(selectUser);
    return (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: 'Required publications', value: noticeType.requiredPublications }), _jsx(DrawerPropertyRow, { label: 'InDesign template', value: (template === null || template === void 0 ? void 0 : template.data().name) || 'Default Template' }), _jsx(DrawerPropertyRow, { label: 'Default column width', value: noticeType.defaultColumns ||
                    (exists(activeOrganization) &&
                        getOrganizationDefaultColumnDescription(activeOrganization)) }), exists(user) && userIsSuper(user) && (_jsx(DrawerPropertyRow, { label: 'Internal ID', value: noticeType.internalID })), _jsx(DrawerPropertyRow, { label: "Notice template", value: _jsx(FormSwitch, { disabled: true, checked: !!(noticeType.madlib || noticeType.typeform) }) })] }));
}
export default function BasicPropertiesTab({ filingType }) {
    var _a;
    const getFilingTypeVisibilityLabelForDrawer = (visiblitySelection) => {
        if (visiblitySelection === FilingTypeVisibility.disabled.value) {
            return 'Disabled';
        }
        if (visiblitySelection === FilingTypeVisibility.publisher_only.value) {
            return 'Enabled for publishers only';
        }
        return 'Enabled for all users';
    };
    return (_jsxs(_Fragment, { children: [_jsx(DrawerPropertyRow, { label: "Name", value: filingType.label }), isNoticeFilingType(filingType) && (_jsx(NoticeFilingTypeBasicProperties, { noticeType: filingType })), _jsx(DrawerPropertyRow, { label: "Status", value: getFilingTypeVisibilityLabelForDrawer((_a = filingType.visibility) !== null && _a !== void 0 ? _a : undefined) })] }));
}
