var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { exists } from 'lib/types';
import { combineCustomerNameFields } from 'lib/notice/customer';
import { extractCustomerDataFromCustomer } from 'routes/settings/publisher/customers/customerTable/utils';
import { memberStyles } from 'lib/components/UserIDCard';
import CustomerTableLoader from './CustomerTableLoader';
export function CustomerOrganizationDrawerMembersTab({ customers, loadingCustomers, setShowCustomerDrawer, setCustomerIdToDisplay, customerEmails, customerRoles }) {
    return (_jsxs("div", Object.assign({ className: "w-full pb-16 h-75vh max-h-full overflow-y-scroll hide-scrollbar text-sm leading-6 font-medium" }, { children: [loadingCustomers && _jsx(CustomerTableLoader, {}), !loadingCustomers &&
                (customers === null || customers === void 0 ? void 0 : customers.map((customer, index) => {
                    var _a, _b, _c;
                    return (_jsx("div", Object.assign({ className: "w-full hover:bg-column-primary-50 cursor-pointer", onClick: () => __awaiter(this, void 0, void 0, function* () {
                            if (exists(customer)) {
                                const customerTableData = yield extractCustomerDataFromCustomer(customer);
                                setShowCustomerDrawer(true);
                                setCustomerIdToDisplay((customerTableData === null || customerTableData === void 0 ? void 0 : customerTableData.customerId) || null);
                            }
                        }) }, { children: _jsxs("div", Object.assign({ className: "px-4 flex border-b border-column-gray-50 items-center justify-between pl-4 pb-4 pt-4" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: `${memberStyles[index % memberStyles.length]} w-10 h-10 rounded-full flex items-center justify-center` }, { children: _jsxs("div", Object.assign({ className: "text-white text-sm leading-6 font-semibold uppercase" }, { children: [(customer === null || customer === void 0 ? void 0 : customer.data().firstName)
                                                        ? ((_a = customer === null || customer === void 0 ? void 0 : customer.data().firstName) === null || _a === void 0 ? void 0 : _a.charAt(0)) || ''
                                                        : '', ((_b = customer === null || customer === void 0 ? void 0 : customer.data().lastName) === null || _b === void 0 ? void 0 : _b.trim())
                                                        ? ((_c = customer === null || customer === void 0 ? void 0 : customer.data().lastName) === null || _c === void 0 ? void 0 : _c.charAt(0)) || ''
                                                        : ''] })) })), _jsxs("div", Object.assign({ className: "inline-block align-middle" }, { children: [_jsx("div", Object.assign({ className: "pl-4 text-column-gray-500" }, { children: combineCustomerNameFields(customer) || '--' })), _jsx("div", Object.assign({ className: "pl-4 mt-1 text-column-gray-400" }, { children: customerEmails[index] }))] }))] })), _jsx("div", Object.assign({ className: "pr-4 text-column-gray-400" }, { children: customerRoles[index] }))] })) }), index));
                }))] })));
}
