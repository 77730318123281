import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
export function ColumnButton({ id, onClick, buttonText, startIcon, endIcon, primary, secondary, link, destructive, dropzone, disabled, loading, fullWidth, fullHeight = false, size = 'md', alignment = 'center', formId, 'aria-label': ariaLabel, 'aria-expanded': ariaExpanded, 'aria-haspopup': ariaHaspopup, 'aria-controls': ariaControls, trackingClass, type, rounded = 'all', border = 'all' }) {
    const isDisabled = disabled || loading;
    const hasIcons = !!((startIcon || endIcon) && buttonText);
    const tertiary = !primary && !secondary;
    const { layoutClasses, buttonBaseClasses } = getButtonClasses({
        primary,
        secondary,
        tertiary,
        link,
        isDisabled,
        destructive,
        dropzone,
        fullWidth,
        fullHeight,
        size,
        hasIcons,
        alignment,
        trackingClass,
        rounded,
        border
    });
    function handleOnClick(event) {
        if (onClick) {
            onClick(event);
        }
    }
    return (_jsx("button", Object.assign({ id: id, form: formId, className: buttonBaseClasses, disabled: isDisabled, onClick: handleOnClick, "aria-label": ariaLabel, "aria-expanded": ariaExpanded, "aria-haspopup": ariaHaspopup, "aria-controls": ariaControls, title: ariaLabel, type: type }, { children: _jsxs("div", Object.assign({ className: layoutClasses }, { children: [loading && _jsx(ButtonSpinner, { hasIcons: hasIcons, size: size }), !loading && (_jsxs(_Fragment, { children: [startIcon && _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: startIcon })), _jsx("span", Object.assign({ className: classNames({ 'w-max': !dropzone }) }, { children: buttonText })), endIcon && _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: endIcon }))] }))] })) })));
}
function ButtonSpinner({ hasIcons, size }) {
    return (_jsxs("div", Object.assign({ role: "status", className: "rounded-b p-1" }, { children: [_jsx("div", { className: classNames('loader ease-linear rounded-full border-4 border-t-4 border-column-gray-50', {
                    'h-3 w-3': !hasIcons && (size === 'sm' || size === 'md'),
                    'h-4 w-4': hasIcons || size === 'lg' || size === 'xl',
                    'h-5 w-5': size === '2xl'
                }) }), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Loading..." }))] })));
}
function getButtonClasses({ primary, secondary, tertiary, link, isDisabled, destructive, fullWidth, fullHeight, dropzone, size, hasIcons, alignment, trackingClass, border, rounded }) {
    const fontClasses = classNames('font-medium', {
        'text-sm': size === 'sm' || size === 'md',
        'text-base': size === 'lg' || size === 'xl',
        'text-lg': size === '2xl'
    });
    const layoutClasses = classNames('flex flex-wrap items-center text-center', {
        'justify-center': alignment === 'center',
        'justify-between': alignment === 'between',
        'gap-1': hasIcons && size !== '2xl',
        'gap-3': hasIcons && size === '2xl'
    }, !link && {
        'py-1.5 px-2.5': size === 'sm',
        'py-2.5 px-3': size === 'md',
        'py-2.5 px-3.5': size === 'lg',
        'py-3 px-4.5': size === 'xl',
        'py-3.5 px-5': size === '2xl'
    });
    const variantClases = classNames({
        'text-white': primary,
        'border-red-600 bg-red-600 hover:bg-red-700': primary && destructive,
        'border-primary-500 bg-primary-500 hover:bg-column-primary-600': primary && !destructive,
        'text-primary-600 bg-primary-50 hover:bg-primary-100 hover:text-primary-700 border-primary-500 hover:border-primary-600': secondary && !destructive,
        'text-red-600 bg-red-50 hover:bg-red-100 hover:text-red-700 border-red-300 hover:border-red-400': secondary && destructive,
        'text-column-gray-500 border-column-gray-200 hover:bg-column-gray-50 bg-white': tertiary,
        'border-red-300 text-red-600 hover:text-red-700 hover:border-red-400 hover:bg-red-50': tertiary && destructive,
        'focus:shadow-outline-red': destructive,
        'focus:shadow-outline-blue': !destructive,
        'opacity-25 cursor-not-allowed': isDisabled,
        'w-full': fullWidth,
        'w-max': !fullWidth,
        'border-dashed': dropzone
    });
    const heightClasses = classNames({
        'h-full': !!fullHeight
    });
    const borderClasses = classNames(link
        ? 'bg-opacity-0 hover:bg-opacity-0'
        : {
            'rounded-l': rounded === 'left',
            'rounded-r': rounded === 'right',
            'rounded-md': rounded === 'all',
            'border-l': border === 'left',
            'border-r': border === 'right',
            border: border === 'all'
        });
    const buttonBaseClasses = classNames('transition duration-300 ease-in-out focus:outline-none', borderClasses, fontClasses, variantClases, heightClasses, trackingClass ? `tracking-${trackingClass}` : undefined);
    return { layoutClasses, buttonBaseClasses };
}
