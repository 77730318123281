import { exists } from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { Product } from 'lib/enums';
const getOrdersTabItemsInner = ({ publicNoticesOrdersEnabled, obituariesOrdersEnabled, classifiedsOrdersEnabled }) => [
    {
        id: 'public-notices',
        path: '/notices',
        label: 'Public Notices',
        enabled: publicNoticesOrdersEnabled
    },
    {
        id: 'obits',
        path: '/obituaries',
        label: 'Obituaries',
        enabled: obituariesOrdersEnabled
    },
    {
        id: 'classifieds',
        path: '/classifieds',
        label: 'Classifieds',
        enabled: classifiedsOrdersEnabled
    }
];
export const useGetOrdersTabItems = (activeOrganization) => {
    if (!exists(activeOrganization)) {
        return getOrdersTabItemsInner({
            publicNoticesOrdersEnabled: true,
            obituariesOrdersEnabled: false,
            classifiedsOrdersEnabled: false
        });
    }
    const organizationModel = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrganization);
    const publicNoticesOrdersEnabled = organizationModel.hasAdTypeActive(Product.Notice);
    const obituariesOrdersEnabled = organizationModel.hasAdTypeActive(Product.Obituary);
    const classifiedsOrdersEnabled = organizationModel.hasAdTypeActive(Product.Classified);
    return getOrdersTabItemsInner({
        publicNoticesOrdersEnabled,
        obituariesOrdersEnabled,
        classifiedsOrdersEnabled
    });
};
