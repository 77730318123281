var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InternalServerError, wrapErrorAsColumnError } from '../errors/ColumnErrors';
import { getErrorReporter } from '../utils/errors';
import { wrapError, wrapSuccess } from '../types/responses';
import { getModelFromSnapshot } from '../model';
import { safeAsync, safeGetQuery } from '../safeWrappers';
import { OrderDetailModel } from '../model/objects/orderDetailModel';
import { ColumnService } from './directory';
import { safeGetModelFromRef } from '../model/getModel';
export class OrderDetailService {
    constructor(ctx) {
        this.ctx = ctx;
    }
    createByOrderAndVersion(orderRef, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const logData = {
                orderId: orderRef.id,
                version: version.toString()
            };
            const { response: orderDetailRef, error: addError } = yield safeAsync(() => this.ctx.orderOrderDetailsRef(orderRef).add({
                orderVersion: version
            }))();
            if (addError) {
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, addError, 'Error adding order detail', logData);
                return wrapErrorAsColumnError(addError, InternalServerError);
            }
            const { response: orderDetail, error: getError } = yield safeGetModelFromRef(OrderDetailModel, this.ctx, orderDetailRef);
            if (getError) {
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, getError, 'Error adding order detail', logData);
                return wrapErrorAsColumnError(getError, InternalServerError);
            }
            getErrorReporter().logInfo('Order detail created', logData);
            return wrapSuccess(orderDetail);
        });
    }
    getByOrderAndVersion(orderRef, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const logData = {
                orderId: orderRef.id,
                version: version.toString()
            };
            const orderDetailQuery = this.ctx
                .orderOrderDetailsRef(orderRef)
                .where('orderVersion', '==', version);
            const { response: orderDetailResults, error: queryError } = yield safeGetQuery(orderDetailQuery);
            if (queryError) {
                return wrapError(queryError);
            }
            const orderDetails = orderDetailResults.docs.map(doc => getModelFromSnapshot(OrderDetailModel, this.ctx, doc));
            if (orderDetails.length === 1) {
                return wrapSuccess(orderDetails[0]);
            }
            if (orderDetails.length > 1) {
                const err = new InternalServerError('Multiple order details found for version');
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, err, 'Multiple order details found', logData);
                return wrapErrorAsColumnError(err, InternalServerError);
            }
            getErrorReporter().logInfo('No order detail found', logData);
            return wrapSuccess(null);
        });
    }
    getOrCreateByOrderAndVersion(orderRef, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: existing, error: getError } = yield this.getByOrderAndVersion(orderRef, version);
            if (getError) {
                return wrapError(getError);
            }
            if (existing) {
                return wrapSuccess(existing);
            }
            return this.createByOrderAndVersion(orderRef, version);
        });
    }
}
