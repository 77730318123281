import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useAppSelector } from 'redux/hooks';
import { isColumnUser } from 'lib/helpers';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
export default function BasicPropertiesCard({ onChangeAffidavitTemplateEditData, affidavitTemplateEditData }) {
    const activeUser = useAppSelector(state => state.auth.user);
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: 'Basic Properties' } }, { children: [_jsx(GridInput, { children: _jsx(TextField, { required: true, labelText: "Affidavit template name", onChange: name => onChangeAffidavitTemplateEditData(Object.assign(Object.assign({}, affidavitTemplateEditData), { name })), value: affidavitTemplateEditData.name, id: "affidavit-template-name" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Is this the newspaper's default template?", options: [
                        {
                            label: 'Use as the default template',
                            value: 'publisher_default'
                        },
                        {
                            label: 'Use when assigned to a customer or notice type',
                            value: 'not_publisher_default'
                        }
                    ], onChange: newDefaultStatus => onChangeAffidavitTemplateEditData(Object.assign(Object.assign({}, affidavitTemplateEditData), { defaultStatus: newDefaultStatus })), value: affidavitTemplateEditData.defaultStatus, id: "select-default-behavior" }) }), activeUser && isColumnUser(activeUser) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { labelText: "Does Column use this template for automated affidavits?", options: [
                        {
                            label: 'Yes, this affidavit is for Column usage.',
                            value: 'true'
                        },
                        {
                            label: 'No, this affidavit is for the publisher only.',
                            value: 'false'
                        }
                    ], onChange: newValue => onChangeAffidavitTemplateEditData(Object.assign(Object.assign({}, affidavitTemplateEditData), { isColumnManaged: newValue === 'true' })), value: affidavitTemplateEditData.isColumnManaged ? 'true' : 'false', id: "select-default-behavior" }) })))] })));
}
