export var LaunchDarklyFlags;
(function (LaunchDarklyFlags) {
    /*
     * A kill switch for all AA notifications
     * */
    LaunchDarklyFlags["ALLOW_AUTOMATED_AFFIDAVIT_NOTIFICATIONS"] = "allow-automated-affidavit-notifications";
    /**
     * Pause the entire Dwolla payouts pipeline. This is intended only as an
     * emergency kill switch if we are having payments isues.
     */
    LaunchDarklyFlags["DISABLE_DWOLLA_PAYOUTS"] = "disable-dwolla-payouts";
    /**
     * Pause the monthly bulk invoice generation pipeline. This is intended only as an
     * emergency kill switch if we are having payments isues.
     */
    LaunchDarklyFlags["DISABLE_BULK_INVOICE_SCHEDULER"] = "disable-bulk-invoice-scheduler";
    /**
     * Kill switch to pause affidavit reminders.
     * This is intended as a temporary flag until ONCALL-1133 gets resolved
     */
    LaunchDarklyFlags["DISABLE_AFFIDAVIT_REMINDER_NOTIFICATIONS"] = "disable-affidavit-reminder-notifications";
    /**
     * Kill switch to pause invoice upfront payment required reminders.
     * This is intended as a temporary flag until ONCALL-1133 gets resolved
     */
    LaunchDarklyFlags["DISABLE_INVOICE_REMINDER_NOTIFICATIONS"] = "disable-invoice-reminder-notifications";
    /**
     * Allow multi-org publishers to select the placement paper
     * See COREDEV-950
     * @deprecated
     */
    LaunchDarklyFlags["ENABLE_NEWSPAPER_SELECTION_FOR_PUBLISHERS"] = "enable-newspaper-selection-for-publishers";
    /**
     * Enable bank account verification via Stripe UI instead of Plaid
     * See COREDEV_469
     */
    LaunchDarklyFlags["ENABLE_STRIPE_UI_BANK_ACCOUNT_VERIFICATION"] = "enable-stripe-ui-bank-account-verification";
    /**
     * For auto-invoiced notices, allow invoices to be regenerated after edits.
     * See COREDEV-1669
     */
    LaunchDarklyFlags["ENABLE_AUTO_INVOICE_AFTER_EDITS"] = "enable-auto-invoice-after-edits";
    /**
     * Enables new hyphenation settings for long sequences in notice content.
     * See: IT-4401 and ONCALL-1530
     */
    LaunchDarklyFlags["ENABLE_ADVANCED_HYPHENATION"] = "enable-advanced-hyphenation";
    /**
     * Test feature flag.
     */
    LaunchDarklyFlags["TEST_FEATURE"] = "test-feature";
    /**
     * This flag determines whether the "Upload E-Edition" button will be visible to users
     * See COREDEV-1116
     */
    LaunchDarklyFlags["SHOW_E_EDITION_UPLOADER"] = "show-e-edition-uploader";
    /**
     * @deprecated Enables indexing customer documents to Elastic
     */
    LaunchDarklyFlags["ENABLE_SYNC_CUSTOMERS_TO_ELASTIC"] = "enable-sync-customers-to-elastic";
    /**
     * Enables whether or not a publisher can edit the order number for an invoice
     */
    LaunchDarklyFlags["ENABLE_ORDER_NUMBER_EDITING"] = "enable-order-number-editing";
    /**
     * Will allow for bulk pagination without ever hitting the Indesign server
     */
    LaunchDarklyFlags["ENABLE_TURBO_BULK_PAGINATION"] = "enable-turbo-bulk-pagination";
    /**
     * @deprecated To be used to push code piecemeal while changing our refund flows. Will be deleted once ticket is fully finished & tested.
     */
    LaunchDarklyFlags["APP_5_HANDLE_TRANSFERS_AFTER_REFUNDS"] = "app-5-handle-transfers-after-refunds";
    /**
     * Used for column express, where we redirect users through a typeform
     * to enter their newspaper information and proceed to file notices on their behalf
     * This will be toggled off (Feb 23) until we restructure Column Express
     */
    LaunchDarklyFlags["ENABLE_CANT_FIND_NEWSPAPER"] = "enable-cant-find-newspaper";
    /**
     * @deprecated this flag was replaced by another one.
     */
    LaunchDarklyFlags["COREDEV_1166_HANDLE_TRANSFERS_AFTER_REFUNDS"] = "coredev-1166-handle-transfers-after-refunds";
    /**
     * Determines whether we use the new integration gateway/script for a given publisher.
     */
    LaunchDarklyFlags["ENABLE_EVENT_DRIVEN_INTEGRATION"] = "enable-event-driven-integration";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_MADLIBS_EDITOR"] = "enable-madlibs-editor";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_MADLIBS_CONFIG"] = "enable-madlibs-config";
    /**
     * @deprecated determines what triggers integrations that sync at deadline.
     */
    LaunchDarklyFlags["ENABLE_DEADLINE_SYNCS_BASED_ON_EVENT_CREATION"] = "enable-deadline-syncs-based-on-event-creation";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_SHOW_REPORTS_TAB"] = "enable-show-reports-tab";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_MORE_ACTIONS_BUTTON"] = "enable-more-actions-button";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_TEMPLATED_NOTICE_EDITOR"] = "enable-templated-notice-editor";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_NOTICE_TABLE_FILTERS"] = "enable-notice-table-filters";
    /**
     * We want to test sending PDFs through the Seaton integration, and this toggle will make it easy
     * to switch between PDF- and text-based methods while testing.
     */
    LaunchDarklyFlags["ENABLE_PDF_AD_COMPOSITION_FOR_SEATON_INTEGRATION"] = "toggle-ad-composition-for-seaton-integration";
    /**
     * Enable the new version of the placement flow
     */
    LaunchDarklyFlags["ENABLE_NEW_PLACEMENT_FLOW"] = "enable-new-placement-flow";
    /*
     * Numeric feature flag. Returns number 0-100
     */
    LaunchDarklyFlags["INDESIGN_ERROR_SAMPLE_RATE"] = "indesign-error-sample-rate";
    /**
     * @deprecated Enable the new version of the rates settings page
     */
    LaunchDarklyFlags["ENABLE_NEW_RATES_SETTINGS"] = "new-rates-settings";
    /*
     * See APP-848
     */
    LaunchDarklyFlags["ENABLE_ADD_ORGS_FROM_CUSTOMER_LIST"] = "enable-add-orgs-from-customer-list";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_LOGOS_IN_LINERS"] = "enable-logos-in-liners";
    /**
     * Enable Account ID management in the placement flow
     */
    LaunchDarklyFlags["ENABLE_ACCOUNT_ID_IN_PLACEMENT"] = "enable-account-id-in-placement";
    /**
     * Disable migrations (kill switch)
     */
    LaunchDarklyFlags["DISABLE_MIGRATIONS"] = "disable-migrations";
    /**
     * Use Lee's production Adpoint
     */
    LaunchDarklyFlags["USE_LEE_PRODUCTION_ADPOINT"] = "use-lee-production-adpoint";
    /**
     * PERMANENT FLAG for overriding signing info.
     */
    LaunchDarklyFlags["NOTARIZE_WHITE_TEXT_OVERRIDES"] = "notarize-white-text-overrides";
    /**
     * Shows subscriptions for government publishers in the header bar
     */
    LaunchDarklyFlags["SHOW_SUBSCRIPTION_IN_HEADER"] = "show-subscription-in-header";
    /**
     * Enable the new version of our application haeder
     */
    LaunchDarklyFlags["NEW_LAYOUT_FRAME"] = "enable-new-layout-frame";
    /**
     * Enable notice type editing in-app
     */
    LaunchDarklyFlags["ENABLE_NOTICE_TYPE_SETTINGS"] = "enable-notice-type-settings";
    /**
    /*
     * Enable notice type editing in-app
     */
    LaunchDarklyFlags["PREVENT_NON_GOVERNMENT_CHECK_RECONCILIATION"] = "prevent-non-government-check-reconciliation";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["ENABLE_BULK_INVOICE_V2_TABLE"] = "enable-bulk-invoice-v2-table";
    /**
     * @deprecated this experiment succeeded and the flag is no longer used.
     */
    LaunchDarklyFlags["REDIRECT_EXAVAULT_TO_FILEMAGE"] = "redirect-exavault-to-filemage";
    /**
     * When turned on, Champion AMP ads clear (are not set on) setaside
     */
    LaunchDarklyFlags["ENABLE_CHAMPION_ADS_CLEAR_SETASIDE"] = "enable_champion_ads_clear_setaside";
    /**
     * @deprecated Show the sync column in the Your Notices tbale
     */
    LaunchDarklyFlags["ENABLE_SYNC_STATUS_IN_NOTICE_TABLE"] = "enable-sync-status-in-notice-table";
    /**
     * When turned on, we route to a new and improved invoice creation screen
     */
    LaunchDarklyFlags["ENABLE_NEW_CREATE_INVOICE_SCREEN"] = "enable-new-create-invoice-screen";
    /**
     * When turned on, add a buffer to the newspaper deadline in notice placement flow
     */
    LaunchDarklyFlags["ENABLE_NOTICE_PLACEMENT_DEADLINE_BUFFER"] = "enable-notice-placement-deadline-buffer";
    /**
     * When turned on, deadline settings will use new components
     * @deprecated This flag was used to toggle on updated deadline settings.
     */
    LaunchDarklyFlags["ENABLE_NEW_DEADLINES_SETTINGS"] = "enable-new-deadlines-settings";
    /**
     * When turned on, the sync error table will be available and the icon will appear in the app header
     */
    LaunchDarklyFlags["ENABLE_SYNC_ERROR_TABLE"] = "enable-sync-error-table";
    /**
     * When turned on, display site and archived tab in newspaper settings will use Elastic to query public notices
     */
    LaunchDarklyFlags["ENABLE_QUERYING_PUBLIC_NOTICES_FROM_ELASTIC"] = "enable-querying-public-notices-from-elastic";
    /**
     * When turned on, the invoices route will be available to non bulk invoicing users and will show all invoices
     */
    LaunchDarklyFlags["SHOW_ALL_INVOICES_IN_INVOICES_TABLE"] = "show-all-invoices-in-invoices-table";
    /**
     * @deprecated This flag was used to send a monthly report of unpaid invoices to the org/user
     */
    LaunchDarklyFlags["ENABLE_MONTHLY_UNPAID_INVOICES_REPORT"] = "enable-monthly-unpaid-invoices-report";
    /**
     * When turned on, invoices table will use Elastic to query invoices
     * @deprecated This flag was used to toggle on updated deadline settings.
     */
    LaunchDarklyFlags["ENABLE_QUERYING_INVOICES_FROM_ELASTIC"] = "enable-querying-invoices-from-elastic";
    /**
     * Show the DisplayAdActions card on the notice details page
     */
    LaunchDarklyFlags["ENABLE_DISPLAY_AD_ACTIONS_CARD"] = "enable-display-ad-actions-card";
    /**
     * Show modular size options in the placement flow for display ads
     */
    LaunchDarklyFlags["ENABLE_MODULAR_SIZES"] = "enable-modular-sizes";
    /**
     * When turned on, SCNG's integration will use the common MNG XML builder
     */
    LaunchDarklyFlags["SCNG_USE_MNG_XML_BUILDER"] = "scng-use-mng-xml-builder";
    /**
     * When turned on, SCNG's integration will be events-based
     */
    LaunchDarklyFlags["USE_EVENTS_BASED_FOR_SCNG"] = "use-events-based-for-scng";
    /**
     * When turned on, show toggle in template settings to enable/disble customised headers in a notice
     */
    LaunchDarklyFlags["ENABLE_CUSTOMISED_HEADERS"] = "enable-customised-headers";
    /**
     * Show the new affidavit table in settings
     */
    LaunchDarklyFlags["ENABLE_AFFIDAVIT_SETTINGS_TABLE"] = "enable-affidavit-settings-table";
    /*
     * When set to true, will attempt to extract text from e-editions using only
     * OCR, and will try to extract text directly from the e-edition as a non-rasterized
     * PDF only if OCR fails. When set to false, will always run both OCR and read the
     * non-rasterized text and concatenate the results.
     */
    LaunchDarklyFlags["OCR_FIRST_E_EDITION_TEXT_EXTRACTION"] = "ocr-first-e-edition-text-extraction";
    /**
     * Enable using CloudConvert instead of the Indesign Server for bulk pagination RTF
     */
    LaunchDarklyFlags["ENABLE_BULK_RTF_CLOUDCONVERT"] = "enable-bulk-rtf-cloud-convert";
    /**
     * TEMPORARY FLAG for enabling TBT merchant account attribution in Payway card transactions.
     * If false, we default to using the test TBT Payway account.
     */
    LaunchDarklyFlags["ENABLE_TBT_PROD_PAYWAY"] = "enable-tbt-prod-payway";
    /**
     * Enable ability to add notes to notices on the notice details page
     */
    LaunchDarklyFlags["ENABLE_NOTICE_NOTES"] = "enable-notice-notes";
    /*
     * @deprecated Used for managing Lee pagination
     */
    LaunchDarklyFlags["ENABLE_PAGINATION_TABLE"] = "enable-pagination-table";
    /*
     * Used to limit indesign calls during AA
     */
    LaunchDarklyFlags["SKIP_INDESIGN_FOR_AFFIDAVIT_GENERATION"] = "skip_indesign_for_affidavit_generation";
    /**
     * @deprecated Backend-only flag that will allow for the creation & maintenance of publicationIssues for papers that do not use pagination.
     */
    LaunchDarklyFlags["ENABLE_PUBLICATION_ISSUES_OUTSIDE_PAGINATION"] = "enable-publication-issues-outside-pagination";
    /**
     * Enable pulling verification data from the new runs-based schema rather than events
     * @deprecated
     */
    LaunchDarklyFlags["USE_RUNS_BASED_VERIFICATION_SCHEMA"] = "use-runs-based-verification-schema";
    /**
     * Enable the new e-edition infrastrucutre using processable events for e-edition uploads
     * @deprecated
     */
    LaunchDarklyFlags["USE_CRON_BASED_EEDITION_PROCESSOR"] = "use-cron-based-eedition-processor";
    /** Enable the user to put the notice in a "Reviewing" status */
    LaunchDarklyFlags["ENABLE_AWAITING_CONFIRMATION_STATUS"] = "enable-awaiting-confirmation-status";
    /**
     * Show in-app docs rendered via Markdown
     */
    LaunchDarklyFlags["ENABLE_IN_APP_DOCS"] = "enable-in-app-docs";
    /**
     * Enable ability to put customers on 30 days end of month billing
     */
    LaunchDarklyFlags["ENABLE_THIRTY_DAYS_END_OF_MONTH_BILLING"] = "enable-thirty-days-end-of-month-billing";
    /**
     * TEMPORARY FLAG for enabling SDUT merchant account attribution in Payway card transactions.
     * If false, we default to using the test SDUT Payway account.
     */
    LaunchDarklyFlags["ENABLE_SDUT_PROD_PAYWAY"] = "enable-sdut-prod-payway";
    /**
     * When enabled, confirmed Champion AMP ads (whether paid or unpaid) will sync (whether or not such ads have synced before)
     * when manually requested by W Sorrells (Champion admin) for notices within a certain period.
     */
    LaunchDarklyFlags["ENABLE_CHAMPION_MONTHS_END_PAYMENT_SYNCS"] = "enable_champion_months_end_payment_syncs";
    /**
     * When turned on, enable the new advertiser onboarding flow
     */
    LaunchDarklyFlags["ADVERTISER_ONBOARDING_SELFSERVE"] = "advertiser-onboarding-selfserve";
    /**
     * Enable the new version of the partial refunds flow
     */
    LaunchDarklyFlags["ENABLE_PARTIAL_REFUNDS_V_2"] = "enable-partial-refunds-v-2";
    /**
     * Controls which model we are using for our automated support drafting
     */
    LaunchDarklyFlags["SUPPORT_ASSISTANT_MODEL"] = "support-assistant-model";
    /**
     * Enable in-app pagination review
     */
    LaunchDarklyFlags["ENABLE_IN_APP_PAGINATION_REVIEW"] = "enable-in-app-pagination-review";
    /**
     * When enabled, TBT and Fort Wayne Adbase integrations can (if further configured) send the <Documents/> tag (as requested by TBT)
     */
    LaunchDarklyFlags["ENABLE_ADBASE_DOCUMENT_TAG"] = "enable-Adbase-document-tag";
    /**
     * When enabled, will re-size unsquashed tables in the bulk download file to fit the columns in the template.
     * Currently behind a flag as the method to do that was written specifically for Forum and needs further testing
     * before we're comfortable rolling out to all publishers.
     */
    LaunchDarklyFlags["ADJUST_TABLE_WIDTHS_IN_BULK_DOWNLOAD_FILE"] = "adjust-table-widths-in-bulk-download-file";
    /**
     * When enabled, Champion payments on billable ads will sync to Champion AMP when synced
     */
    LaunchDarklyFlags["ENABLE_CHAMPION_SYNC_PAYMENTS_FOR_BILLABLE_ADS"] = "enable-champion-sync-payments-for-billable-ads";
    /**
     * An experimental flag which keeps <table> tags in TinyMCE editor
     */
    LaunchDarklyFlags["ENABLE_TABLE_PASTING_IN_NOTICE_EDITOR"] = "enable-table-pasting-in-notice-editor";
    /**
     * @deprecated Given a hash of an affidavit HTML template, returns whether or not we should use the updated
     * affidavit generation process.
     */
    LaunchDarklyFlags["ENABLE_PDFKIT_AFFIDAVIT_GENERATION"] = "enable-pdfkit-affidavit-generation";
    /**
     * When enabled, allowing reps to copy notice details on the Notice Details page
     */
    LaunchDarklyFlags["ENABLE_COPY_NOTICE_DETAILS_FOR_REPS"] = "enable-copy-notice-details-for-reps";
    /**
     * Allows users to see the TinyMCE html 'code'
     */
    LaunchDarklyFlags["ENABLE_TINY_MCE_SOURCE_CODE"] = "enable-tiny-mce-source-code";
    /**
     * When enabled, PDF document to text conversion in the palcement flow will
     * use cloud convert (pdf to docx to html) instead of OCR
     */
    LaunchDarklyFlags["ENABLE_PDF_TO_HTML_CLOUD_CONVERT"] = "enable-pdf-to-html-cloud-convert";
    /**
     * Enable self-serve push in our placement inbox from front automations
     */
    LaunchDarklyFlags["ENABLE_SELF_SERVE_PUSH"] = "enable-self-serve-push";
    /**
     * When enabled, stop auto-increase column width of notice and instead show user a message to increase
     * column width to fit the content.
     */
    LaunchDarklyFlags["STOP_AUTO_INCREASE_COLUMN_WIDTH"] = "stop-auto-increase-column-width";
    /**
     * When enabled, we don't send the <AMPACCTNBR/> tag in the XML. This was causing (purportedly) duplication of ads in Champion for TL but not the other papers
     */
    LaunchDarklyFlags["CHAMPION_WITHHOLD_AMPACCTNBR_FROM_XML"] = "champion-withhold-ampacctnbr-from-xml";
    /**
     * @deprecated The associated cron has been removed and this flag is no longer used.
     */
    LaunchDarklyFlags["E_EDITION_OCR_SUCCESS_EVENT_PROCESSOR_CONFIG"] = "e-edition-ocr-success-event-processor-config";
    /**
     * Can be used to override the default processor config for the processEEditionUploadedEventsCron
     */
    LaunchDarklyFlags["E_EDITION_UPLOADED_EVENT_PROCESSOR_CONFIG"] = "e-edition-uploaded-event-processor-config";
    /**
     * @deprecated The associated cron has been removed and this flag is no longer used.
     */
    LaunchDarklyFlags["E_EDITION_VERIFICATION_EVENT_PROCESSOR_CONFIG"] = "e-edition-verification-event-processor-config";
    /**
     * Can be used to override the default processor config for the processNoticeReconcileRunsEventsCron
     */
    LaunchDarklyFlags["NOTICE_RECONCILE_RUNS_EVENT_PROCESSOR_CONFIG"] = "notice-reconcile-runs-event-processor-config";
    /**
     * Can be used to override the default processor config for the processRunVerificationInitiatedEventsCron
     */
    LaunchDarklyFlags["RUN_VERIFICATION_EVENT_PROCESSOR_CONFIG"] = "run-verification-event-processor-config";
    /**
     * When turned on, use the new events-based apprach of sending notice related notifications
     */
    LaunchDarklyFlags["HANDLE_NOTIFICATIONS_WITH_EVENTS"] = "handle_notifications_with_events";
    /**
     * Enable attachment of PDF of unpaid invoices report to each new invoice notification
     */
    LaunchDarklyFlags["ENABLE_ADD_OF_UNPAID_INVOICES_REPORT_TO_NEW_INVOICE_NOTIFICATIONS"] = "enable-add-of-unpaid-invoices-report-to-new-invoice-notifications";
    /**
     * When turned on, all Elastic requests will be performed with the new Elastic node client @elastic/enterprise-search
     */
    LaunchDarklyFlags["USE_NEW_ELASTIC_CLIENT"] = "use-new-elastic-client";
    /**
     * Enable a kill switch on running elastic consistency syncs
     */
    LaunchDarklyFlags["ENABLE_ELASTIC_EVENTUAL_CONSISTENCY"] = "enable-elastic-eventual-consistency";
    /**
     * Cache balance transaction data on our payout records to speed up access
     */
    LaunchDarklyFlags["ENABLE_CACHE_BALANCE_TRANSACTIONS"] = "enable-cache-balance-transactions";
    /**
     * In Cloudinary, width transformation will error for images greater than 100 megapixels. When on transformations
     * are allowed but do not contain a width property.
     */
    LaunchDarklyFlags["SKIP_CLOUDINARY_LARGE_FILE_WIDTH_TRANSFORMATION"] = "skip-cloudinary-large-file-width-transformation";
    /**
     * Enable Job ID Caching for InDesign requests
     * */
    LaunchDarklyFlags["USE_INDESIGN_PAYLOAD_HASH_AS_JOB_ID"] = "use-indesign-payload-hash-as-job-id";
    /**
     * When enabled, all reps only features will be visible to reps in app
     */
    LaunchDarklyFlags["ENABLE_REPS_ONLY_FEATURES"] = "enable-reps-only-features";
    /**
     * Enable Display Ad Submitted Slack notifications
     * */
    LaunchDarklyFlags["ENABLE_DISPLAY_AD_SUBMITTED_NOTIFICATIONS"] = "enable-display-ad-submitted-notifications";
    /**
     * Enable Display Ad Slack post witout formatting notifications
     * */
    LaunchDarklyFlags["ENABLE_DISPLAY_AD_POST_WITHOUT_FORMATTING_NOTIFICATIONS"] = "enable-display-ad-post-without-formatting-notifications";
    /**
     * When enabled, payments through Elavon for Lee papers using Adpoint will use a surcharged-enabled terminal (which will apply surcharges for CCs that accept surcharges)
     */
    LaunchDarklyFlags["ENABLE_LEE_ADPOINT_SURCHARGES_IN_ELAVON"] = "enable-lee-adpoint-surcharges-in-elavon";
    /**
     * Unclear if borders should be added to large display ads. The current workaround is to upload large display ads via Column Admin.
     * Uploading files this way does not add a border and so far no complaints from publishers. THis will default to false but can be turn on to allow borders.
     */
    LaunchDarklyFlags["ENABLE_CLOUDINARY_BORDER_TRANSFORMATION"] = "enable-cloudinary-border-transformation";
    /**
     * Set max number of affidavits that can go in a single auto-affidavit signing batch
     */
    LaunchDarklyFlags["AUTO_AFFIDAVIT_SIGNING_BATCH_SIZE"] = "AUTO_AFFIDAVIT_SIGNING_BATCH_SIZE";
    /**
     * Allow users to change email address from the account settings
     */
    LaunchDarklyFlags["ALLOW_USERS_TO_CHANGE_EMAIL"] = "allow-users-to-change-email";
    /**
     * Allows users to edit obituaries & classifieds
     */
    LaunchDarklyFlags["ENABLE_ORDER_EDIT_FLOW"] = "enable-order-edit-flow";
    /**
     * Generalized Wick specific functions for Affinity X. If the general fns don't workm as expected switch back to Wick specific
     */
    LaunchDarklyFlags["USE_GENERALIZED_AX_FUNCTIONS"] = "use-generalized-ax-functions";
    /**
     * Allows users to select multiple invoices and pay them with a single stripe charge.
     */
    LaunchDarklyFlags["SHOW_BULK_PAYMENT_IN_INVOICES_TABLE"] = "show-bulk-payment-in-invoices-table";
    /**
     * Can be used to override the default processor config for the processBulkPaymentReceiptReadyEventsCron
     */
    LaunchDarklyFlags["BULK_PAYMENT_RECEIPT_READY_PROCESSOR_CONFIG"] = "bulk-payment-receipt-ready-processor-config";
    /**
     * Use Column's CDN and image transformer
     */
    LaunchDarklyFlags["ENABLE_COLUMN_CDN"] = "enable-column-cdn";
    /**
     * Allow pdf to upload as an image when images in liners is enabled in newspaper
     */
    LaunchDarklyFlags["ALLOW_PDF_TO_IMAGE_IN_LINERS"] = "allow-pdf-to-image-in-liners";
    /**
     * When enabled the notice category filter will be shown in the notices table
     */
    LaunchDarklyFlags["ENABLE_NOTICE_CATEGORY_FILTER"] = "enable-notice-category-filter";
})(LaunchDarklyFlags || (LaunchDarklyFlags = {}));
