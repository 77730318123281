var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import Validator from 'validator';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import api from 'api';
import { RoleType } from 'lib/enums';
import TailwindModal from 'components/TailwindModal';
import { safeStringify } from 'lib/utils/stringify';
import InviteMembersInputRow from './InviteMembersInputRow';
import CreateInvitesResult from './CreateInvitesResult';
export default function AdvertisersInvitesForm({ onInviteCompleted, organization }) {
    const [errors, setErrors] = useState();
    const [loading, setLoading] = useState(false);
    const [showInvitationFailedModal, setShowInvitationFailedModal] = useState(false);
    const [invitesData, setInvitesData] = useState();
    const DUPLICATE_EMAIL_ERROR = 'Do not enter duplicate emails.';
    const [values, setValues] = useState([
        { email: null, role: null }
    ]);
    const disable = values.some(val => {
        return val.email === null || val.email === '' || val.role === null;
    });
    useEffect(() => {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        setErrors(values.map(_ => ''));
    }, [safeStringify(values)]);
    const confirmInvites = () => __awaiter(this, void 0, void 0, function* () {
        const emails = values.map(val => { var _a; return (_a = val.email) === null || _a === void 0 ? void 0 : _a.toLowerCase(); });
        const isEmailValid = values.map(val => val.email ? Validator.isEmail(val.email) : true);
        const errorsFound = values.map((v, i) => {
            if (!isEmailValid[i])
                return 'Email is invalid';
            const duplicateEmail = emails.filter(email => email === v.email).length;
            if (duplicateEmail > 1)
                return DUPLICATE_EMAIL_ERROR;
            return '';
        });
        if (errorsFound.some(e => !!e)) {
            setErrors(errorsFound);
            return;
        }
        setLoading(true);
        const req = {
            inviteData: values,
            organizationId: organization.id,
            isExistingUserInvite: false
        };
        const result = yield api.post('users/invite', req);
        const inviteResponseData = result.data;
        setInvitesData(inviteResponseData);
        setLoading(false);
        const isInvitedSuccessfully = (inviteResponseData.invited.length ||
            inviteResponseData.invitesUnsnoozed) &&
            !inviteResponseData.alreadyHaveInvitesToCurrentOrg.length &&
            !inviteResponseData.alreadyMembersOfInvitedOrg.length &&
            !inviteResponseData.alreadyLinkedToIncompatibleOrganization.length &&
            !inviteResponseData.alreadyInvitedToIncompatibleOrganization.length;
        if (isInvitedSuccessfully) {
            onInviteCompleted();
        }
        else {
            setShowInvitationFailedModal(true);
        }
    });
    const onAddClick = () => {
        setValues([...values, { email: null, role: null }]);
    };
    const onRoleSelect = (i, roleValue) => {
        var _a, _b;
        const currValues = [...values];
        currValues[i].role = (_b = (_a = RoleType.by_label(roleValue)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
        setValues(currValues);
    };
    const onChange = (i, value) => {
        const currValues = [...values];
        currValues[i].email = value;
        setValues(currValues);
    };
    const onRemoveClick = (i) => {
        const currValues = [...values];
        currValues.splice(i, 1);
        setValues(currValues);
    };
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "mt-8 text-center" }, { children: [_jsx("p", Object.assign({ className: "text-column-gray-500 font-semibold leading-8 text-2xl" }, { children: "Get your teammates onboard" })), _jsx("p", Object.assign({ className: "text-column-gray-400 font-medium text-sm leading-6 mt-4" }, { children: "One last step. Invite anyone responsible for managing your organization's public notices and payments." }))] })), _jsxs("div", Object.assign({ className: "mt-10 border rounded-md shadow bg-white py-4 px-8 max-h-1/4 overflow-y-scroll" }, { children: [values.map((el, i) => (_jsx(InviteMembersInputRow, { index: i, value: el, loading: loading, onValueChange: (newEmail) => onChange(i, newEmail), onRoleSelect: roleValue => onRoleSelect(i, roleValue), onRemoveClick: () => onRemoveClick(i), error: errors && errors[i] }, `user-invite-${i}`))), _jsxs("div", Object.assign({ className: "flex items-center mt-6 mb-6 text-column-primary-500", onClick: () => onAddClick() }, { children: [_jsx(PlusCircleIcon, { className: "w-5" }), _jsx("div", Object.assign({ className: "ml-2 cursor-pointer not-italic font-medium text-sm flex items-center" }, { children: "Add another team member" }))] }))] })), _jsx(AdvertiserRegistrationFooter, { id: 'confirm-invite', backButtonText: 'Skip for now', nextButtonText: "Send invitation", onBackButtonClick: onInviteCompleted, onNextButtonClick: confirmInvites, loading: loading, disableNextButton: disable }), showInvitationFailedModal && invitesData && (_jsx(TailwindModal, Object.assign({ noExitOutsideModal: true, header: 'Oops! The invitation was not sent.', close: () => setShowInvitationFailedModal(false) }, { children: _jsx(CreateInvitesResult, { invitesData: invitesData, onHandleTryAgainClick: () => setShowInvitationFailedModal(false), emailsLinkedToNotices: false }) })))] }));
}
