var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from '../types';
import { getOrThrow } from './refs';
export const getFilerFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const filer = yield getOrThrow(notice.data().filer);
    return filer;
});
export const getFilerOrganizationFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const filerOrganization = yield ((_a = notice.data().filedBy) === null || _a === void 0 ? void 0 : _a.get());
    if (!exists(filerOrganization)) {
        return null;
    }
    return filerOrganization;
});
export const getNewspaperFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaper = yield getOrThrow(notice.data().newspaper);
    return newspaper;
});
export const maybeGetInvoiceFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const invoice = yield ((_b = notice.data().invoice) === null || _b === void 0 ? void 0 : _b.get());
    return exists(invoice) ? invoice : null;
});
export const getInvoiceFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const invoice = yield maybeGetInvoiceFromNotice(notice);
    if (!invoice) {
        throw new Error(`Invoice does not exist for notice: ${notice.id}.`);
    }
    return invoice;
});
export const getRateFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const rate = yield getOrThrow(notice.data().rate);
    return rate;
});
export const maybeGetModularSize = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const modularSize = yield ((_c = notice.data().modularSize) === null || _c === void 0 ? void 0 : _c.get());
    return exists(modularSize) ? modularSize : null;
});
export const getCreatorFromNotice = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    const creatorRef = (_d = notice.data().createdBy) !== null && _d !== void 0 ? _d : notice.data().filer;
    const creator = yield getOrThrow(creatorRef);
    return creator;
});
