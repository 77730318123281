export var PublicationIssueStatus;
(function (PublicationIssueStatus) {
    /**
     * Soft-delete state for when the last deadline for a publication is deleted
     */
    PublicationIssueStatus["DISABLED"] = "disabled";
    /**
     * Not yet ready for pagination
     */
    PublicationIssueStatus["PENDING"] = "pending";
    /**
     * Ready for pagination
     */
    PublicationIssueStatus["READY_FOR_PAGINATION"] = "ready-for-pagination";
    /**
     * Approved by Column. Triggers email to publisher for approval.
     */
    PublicationIssueStatus["AWAITING_APPROVAL"] = "awaiting-approval";
    /**
     * Approved by the publisher. Triggers upload to publisher
     */
    PublicationIssueStatus["APPROVED"] = "approved";
    /**
     * Used by the publisher to indicate that issue notices have been laid out and
     * are ready to print.
     */
    PublicationIssueStatus["PRINT_READY"] = "print-ready";
    /**
     * Changes requested instead of approval
     */
    PublicationIssueStatus["CHANGES_REQUESTED"] = "changes-requested";
    /**
     * TODO(goodpaul): Occurs after publication date has passed
     */
    PublicationIssueStatus["ARCHIVED"] = "archived";
})(PublicationIssueStatus || (PublicationIssueStatus = {}));
