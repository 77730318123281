import { calculateSubtotalFromLineItems, isAdditionalFeeLineItem } from './index';
import { LineItemType } from '../enums';
import { isFlatAdditionalFee, isPercentAdditionalFee } from '../types/rates';
/**
 * This function is used for applying the rounding difference in cents to the last non-fee and non-discount line item.
 * If a non-fee or a non-discount line item does not exist, this function will return 0 so we don't lose the rounding difference.
 */
export const getLastNonFeeAndNonDiscountLineItemIndex = (lineItems) => {
    let lastNonFeeLineItemIndex = 0;
    for (const [i, item] of lineItems.entries()) {
        if (!isAdditionalFeeLineItem(item) &&
            item.type !== LineItemType.discount.value)
            lastNonFeeLineItemIndex = i;
    }
    return lastNonFeeLineItemIndex;
};
function getAdditionalFeesFromRate(rate) {
    if (rate.additionalFees) {
        return rate.additionalFees
            .filter(fee => {
            if (isFlatAdditionalFee(fee)) {
                return !!fee.amount;
            }
            return !!fee.feePercentage;
        })
            .sort((a, b) => {
            // sort flat fees before percent fees so that we can calculate the
            // percent fee line items with the flat fee line items in the subtotal
            const { type: aType } = a;
            const { type: bType } = b;
            if (aType === bType) {
                return 0;
            }
            if (aType === 'flat' && bType === 'percent') {
                return -1;
            }
            return 1;
        });
    }
    return [];
}
const calculateAmountForAdditionalFeeLineItem = (additionalFee, currentLineItems) => {
    if (isFlatAdditionalFee(additionalFee)) {
        return additionalFee.amount;
    }
    const currentSubtotal = calculateSubtotalFromLineItems(currentLineItems);
    return Math.round((additionalFee.feePercentage / 100) * currentSubtotal);
};
export function getAffidavitAdditionalFeeLineItemsFromRate(rate, mail, lastPubDatePlusOneMinute) {
    const additionalFees = getAdditionalFeesFromRate(rate);
    if (!(mail === null || mail === void 0 ? void 0 : mail.length))
        return [];
    const totalNumberOfAffidavits = mail.reduce((a, m) => a + (m.copies || 0), 0);
    const affidavitFeeLineItems = additionalFees
        .filter((additionalFee) => isFlatAdditionalFee(additionalFee) && !!additionalFee.perAffidavitFee)
        .map(additionalFee => ({
        date: lastPubDatePlusOneMinute,
        amount: additionalFee.amount * totalNumberOfAffidavits,
        unitPricing: {
            price: additionalFee.amount,
            quantity: totalNumberOfAffidavits
        },
        description: additionalFee.description,
        type: LineItemType.fee.value
    }));
    return affidavitFeeLineItems;
}
export const getNonAffidavitAdditionalFeeLineItemsFromRate = (rate, notice, lastPubDatePlusOneMinute, currentLineItems) => {
    const additionalFees = getAdditionalFeesFromRate(rate);
    // allow for additional line items at the rate level
    // Note: some typeform-zap notice types have fixed pricing and
    // no associated rate; do not add rate-associated line items
    // for those
    const blockAdditionalRateFee = !!notice.fixedPrice;
    if (blockAdditionalRateFee)
        return [];
    let lineItemsForCalculation = [...currentLineItems];
    const additionalFeeLineItems = additionalFees
        .filter(additionalFee => isPercentAdditionalFee(additionalFee) ||
        (isFlatAdditionalFee(additionalFee) && !additionalFee.perAffidavitFee))
        .map(additionalFee => {
        let feeAmount = calculateAmountForAdditionalFeeLineItem(additionalFee, lineItemsForCalculation);
        if (isFlatAdditionalFee(additionalFee) &&
            additionalFee.perRun &&
            notice.publicationDates) {
            feeAmount *= notice.publicationDates.length;
        }
        const newLineItem = {
            date: lastPubDatePlusOneMinute,
            amount: feeAmount,
            description: additionalFee.description,
            type: LineItemType.fee.value
        };
        lineItemsForCalculation = lineItemsForCalculation.concat(newLineItem);
        return newLineItem;
    });
    return additionalFeeLineItems;
};
export const getResetAdditionalFeeData = (additionalFeeData, type) => {
    if (type === 'flat') {
        return {
            description: additionalFeeData.description,
            type: 'flat',
            amount: 0,
            perRun: false,
            perAffidavitFee: false
        };
    }
    return {
        description: additionalFeeData.description,
        type: 'percent',
        feePercentage: 0
    };
};
export const __private = {
    calculateAmountForAdditionalFeeLineItem,
    getAdditionalFeesFromRate
};
