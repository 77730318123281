import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { getInputTypeValidationConfig } from '../helpers/inputValidation';
import { InputAccessories, getAccessibilityLinks } from '../InputAccessories';
import { useValidationChecks } from './hooks/useValidationChecks';
import { ColumnButton } from '../ColumnButton';
const paddingMap = {
    small: 'p-2',
    standard: 'p-3'
};
export function TextField({ id, role, type, labelText, errorText, noteText, required, disabled, value, placeholder, size = 'standard', onChange, onFocus, onBlur, onClick, onKeyDown, format, max, min, step, maxLength, pattern, autoComplete, region, validationMessages, prefix, suffix, setValidityTracker, integratedButton, 'aria-expanded': ariaExpanded, 'aria-haspopup': ariaHaspopup, 'aria-controls': ariaControls }) {
    var _a, _b;
    const [showErrors, setShowErrors] = useState(!!errorText);
    const [focus, setFocus] = useState(false);
    const inputRef = useRef(null);
    const inputTypeConfig = getInputTypeValidationConfig(type, region);
    const { currentValidationMessage } = useValidationChecks({
        value,
        inputRef,
        errorText,
        validationMessages: validationMessages || ((_a = inputTypeConfig.props) === null || _a === void 0 ? void 0 : _a.validationMessages),
        setValidityTracker,
        setShowErrors
    });
    const errorMessage = errorText || currentValidationMessage;
    const inErrorState = showErrors && !!errorMessage;
    const wrapperClasses = classNames('flex text-sm rounded-md border', {
        'border-column-gray-200': !focus && !inErrorState,
        'border-column-primary-500 shadow-outline-column-primary': focus && !inErrorState,
        'border-column-red-600 shadow-outline-column-red': inErrorState,
        'bg-column-gray-25 text-column-gray-300': disabled,
        'bg-white': !disabled
    });
    function handleOnFocus(event) {
        setFocus(true);
        if (onFocus) {
            onFocus(event);
        }
    }
    function handleOnBlur(event) {
        onBlur && onBlur(event);
        setFocus(false);
        setShowErrors(true);
    }
    const validationProps = Object.assign({ max,
        min,
        step,
        pattern,
        maxLength }, inputTypeConfig.props);
    const accessibilityLinks = getAccessibilityLinks({
        id,
        noteText,
        errorMessage
    });
    return (_jsx(InputAccessories, Object.assign({ id: id, labelText: labelText, errorText: showErrors ? errorMessage : '', noteText: noteText, required: required, disabled: disabled }, { children: _jsxs("div", Object.assign({ className: wrapperClasses }, { children: [_jsx(TextInput, Object.assign({ id: id, role: role, type: type, inputRef: inputRef, required: required, disabled: disabled, size: size, value: value, placeholder: placeholder, prefix: prefix, suffix: suffix }, validationProps, { autoComplete: autoComplete, format: (_b = inputTypeConfig.format) !== null && _b !== void 0 ? _b : format, accessibilityLinks: accessibilityLinks, onChange: onChange, onFocus: handleOnFocus, onBlur: handleOnBlur, onClick: onClick, onKeyDown: onKeyDown, "aria-expanded": ariaExpanded, "aria-haspopup": ariaHaspopup, "aria-controls": ariaControls })), integratedButton && (_jsx(ColumnButton, Object.assign({}, integratedButton, { disabled: integratedButton.disabled || inErrorState || disabled, rounded: "right", border: "left", primary: true })))] })) })));
}
function TextInput({ id, role, inputRef, type = 'text', placeholder, size = 'standard', value, disabled, required, prefix, suffix, onChange, onFocus, onBlur, onClick, onKeyDown, accessibilityLinks, format, min, max, step, maxLength, pattern, autoComplete, 'aria-expanded': ariaExpanded, 'aria-haspopup': ariaHaspopup, 'aria-controls': ariaControls }) {
    const inputType = getSimplifiedInputType(type);
    const inputClasses = classNames(`${paddingMap[size]} w-full rounded-md focus:outline-none font-medium text-column-gray-500 bg-transparent`, {
        'pl-0': prefix,
        'pr-0': suffix
    });
    function handleOnChange(e) {
        e.target.value = format ? format(e.target.value) : e.target.value;
        onChange && onChange(e.target.value);
    }
    function onWheel() {
        var _a;
        if (type === 'number') {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    }
    function focusMainInput() {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
    function handlePrefixSuffixClick() {
        focusMainInput();
        onClick && onClick();
    }
    const inputProps = Object.assign(Object.assign({ id,
        role, ref: inputRef, name: id, type: inputType, value: format && value ? format(value) : value, disabled,
        required,
        min,
        max,
        step,
        maxLength,
        pattern, 
        /**
         * For some reason, `autoComplete="off"` does not work in Chrome, but this hack
         * works. See ONCALL-2070 for why we do this (to prevent autofill of the create new
         * organization form from populating the search bar).
         */
        autoComplete: autoComplete === 'off' ? 'nope' : autoComplete }, accessibilityLinks), { placeholder, onChange: handleOnChange, onFocus,
        onBlur,
        onClick,
        onKeyDown,
        onWheel, className: inputClasses, 'aria-expanded': ariaExpanded, 'aria-haspopup': ariaHaspopup, 'aria-controls': ariaControls });
    const prefixSuffixClickHandler = inputProps.disabled
        ? undefined
        : handlePrefixSuffixClick;
    return (_jsxs(_Fragment, { children: [_jsx(InputSection, Object.assign({ onClick: prefixSuffixClickHandler, size: size }, { children: prefix })), _jsx("input", Object.assign({}, inputProps)), _jsx(InputSection, Object.assign({ onClick: prefixSuffixClickHandler, size: size }, { children: suffix }))] }));
}
function getSimplifiedInputType(type) {
    switch (type) {
        case 'currency':
            return 'number';
        case 'postal-code':
            return 'text';
        default:
            return type;
    }
}
function InputSection({ children, size, onClick }) {
    if (!children) {
        return null;
    }
    const classes = [paddingMap[size], 'flex', 'items-center', 'rounded'];
    if (onClick) {
        classes.push('cursor-pointer');
    }
    return (_jsx("div", Object.assign({ onClick: onClick, className: classes.join(' ') }, { children: children })));
}
