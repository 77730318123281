import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { BuildingOffice2Icon, ChevronDownIcon, PlusCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { exists } from 'lib/types';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import { selectShowAllOrgsNotices } from 'redux/auth';
import { buildingStyles } from 'lib/components/UserIDCard';
import { TextField } from 'lib/components/TextField';
import { fuzzyStringContains } from 'lib/utils/strings';
/**
 * Runs through a series of formatting rules to make the publisher name look nice.
 * @param {ESnapshotExists<EOrganization>} organization name of the publisher
 * @returns {string} cleaned name
 */
export const getPublisherNameForDropdown = (organization) => {
    const publisherName = organization.data().name;
    if (publisherName.startsWith('The ')) {
        return publisherName.replace('The ', '');
    }
    return publisherName;
};
function OrganizationSelectPopoverItem({ iconStyles, onClick, active, icon, name, id }) {
    return (_jsxs("button", Object.assign({ id: id, className: "p-2 hover:bg-column-gray-25 cursor-pointer rounded-md flex items-center", onClick: onClick }, { children: [_jsxs("div", Object.assign({ className: "w-12 flex" }, { children: [_jsx("div", Object.assign({ className: classNames('w-10 h-10 rounded-full flex items-center justify-center', iconStyles) }, { children: icon })), active && (_jsx("div", { className: "-ml-2 mt-6 rounded-full bg-column-green-400 w-3 h-3 border border-white" }))] })), _jsx("span", Object.assign({ className: "ml-2 truncate font-medium text-sm text-column-gray-500" }, { children: name }))] })));
}
function ActiveOrganizationPopover({ options, value, onChange, onAllOrgsClicked, onCreateOrgClicked, showAllOrgsOption, showCreateOrgOption }) {
    const initialSearchOrgState = '';
    // Limit of the number of organizations where the search organization field will not appear
    const organizationLimit = 3;
    const { setOpen, open } = useContext(PopoverContext);
    const [searchOrgValue, setSearchOrgValue] = useState(initialSearchOrgState);
    useEffect(() => {
        // On closing of popover if there is some value in search field; it should reset to empty state
        if (!open && searchOrgValue) {
            setSearchOrgValue(initialSearchOrgState);
        }
    }, [searchOrgValue, open]);
    const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices) || false;
    const filteredOrgs = options.filter(org => {
        return fuzzyStringContains(getPublisherNameForDropdown(org), searchOrgValue);
    });
    return (_jsxs(_Fragment, { children: [options.length > organizationLimit && (_jsx(OrganizationSearchBox, { searchOrgValue: searchOrgValue, onChangeSearchOrgValue: setSearchOrgValue })), !filteredOrgs.length && (_jsx("div", Object.assign({ className: "w-72 text-center text-column-gray-300 pb-3" }, { children: "No organization found." }))), filteredOrgs.length > 0 && (_jsxs("div", Object.assign({ className: "w-72 p-2 max-h-64 overflow-y-scroll" }, { children: [showAllOrgsOption && filteredOrgs.length > 1 && (_jsx(OrganizationSelectPopoverItem, { id: "organization-select-all", onClick: () => {
                            setOpen(false);
                            if (onAllOrgsClicked) {
                                onAllOrgsClicked();
                            }
                        }, active: Boolean(showAllOrgsNotices), iconStyles: ACTIVE_ICON_STYLES, icon: _jsx(BuildingOffice2Icon, { className: classNames('w-5 h-5') }), name: "All Organizations" })), filteredOrgs
                        .sort((a, b) => getPublisherNameForDropdown(a).localeCompare(getPublisherNameForDropdown(b)))
                        .map((organization, index) => {
                        return (_jsx(OrganizationSelectPopoverItem, { id: `organization-select-${organization.id.slice(0, 10)}`, onClick: () => {
                                setOpen(false);
                                onChange(organization);
                            }, iconStyles: buildingStyles[index % buildingStyles.length], active: !showAllOrgsNotices && (value === null || value === void 0 ? void 0 : value.id) === organization.id, name: getPublisherNameForDropdown(organization), icon: _jsx(BuildingOffice2Icon, { className: classNames('w-5 h-5') }) }, organization.id));
                    }), showCreateOrgOption && (_jsx(OrganizationSelectPopoverItem, { id: "organization-select-add-organization", onClick: () => {
                            setOpen(false);
                            if (onCreateOrgClicked) {
                                onCreateOrgClicked();
                            }
                        }, iconStyles: "text-column-primary-500 bg-column-primary-50", name: _jsx("span", Object.assign({ className: "text-column-primary-500" }, { children: "Add Organization" })), active: false, icon: _jsx(PlusCircleIcon, { className: classNames('w-5 h-5') }) }))] })))] }));
}
// Search component in the organization select dropdown
function OrganizationSearchBox({ searchOrgValue, onChangeSearchOrgValue }) {
    return (_jsx("div", Object.assign({ className: "mx-4 my-3" }, { children: _jsx(TextField, { type: "text", id: "organization-search", labelText: "", placeholder: "Search", value: searchOrgValue, onChange: value => onChangeSearchOrgValue(value), prefix: _jsx(MagnifyingGlassIcon, { className: "h-5 w-5 text-column-gray-300 stroke-2" }) }) })));
}
// choose to make the active icon the red icon
const ACTIVE_ICON_STYLES = buildingStyles[2];
/**
 * Dropdown select component in the header bar to switch between organizations.
 */
export default function OrganizationSelect({ options, value, onChange, onAllOrgsClicked, onCreateOrgClicked, popoverHeader, showAllOrgsOption, showCreateOrgOption }) {
    const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices) && !!showAllOrgsOption;
    const activatorButtonText = exists(value)
        ? showAllOrgsNotices
            ? 'All Organizations'
            : getPublisherNameForDropdown(value)
        : 'Select organization';
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "hidden md:flex" }, { children: _jsx(Popover, Object.assign({ id: "organization-select-popover", headerText: popoverHeader, activator: _jsx(ColumnButton, { buttonText: activatorButtonText, startIcon: _jsx(BuildingOffice2Icon, { className: classNames('w-5 h-5', ACTIVE_ICON_STYLES) }), fullWidth: true, alignment: "between", endIcon: _jsx(ChevronDownIcon, { className: "w-4 h-4" }), type: "button" }), alignment: "left" }, { children: _jsx(ActiveOrganizationPopover, { options: options, value: value, onChange: onChange, showAllOrgsOption: showAllOrgsOption, onAllOrgsClicked: onAllOrgsClicked, showCreateOrgOption: showCreateOrgOption, onCreateOrgClicked: onCreateOrgClicked }) })) })), _jsx("div", Object.assign({ className: "md:hidden" }, { children: _jsx(Popover, Object.assign({ id: "organization-select-popover", headerText: popoverHeader, activator: _jsxs("button", Object.assign({ className: "bg-red-50 rounded-full p-2 md:hidden" }, { children: [_jsx(BuildingOffice2Icon, { className: classNames('w-4 h-4', ACTIVE_ICON_STYLES) }), _jsx("div", { className: "bg-column-green-500 w-3 h-3 absolute ml-4 -mt-.5 rounded-full border-white border-2" })] })), alignment: "right" }, { children: _jsx(ActiveOrganizationPopover, { options: options, value: value, onChange: onChange, showAllOrgsOption: showAllOrgsOption, onAllOrgsClicked: onAllOrgsClicked, showCreateOrgOption: showCreateOrgOption, onCreateOrgClicked: onCreateOrgClicked }) })) }))] }));
}
