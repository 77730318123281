import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { State } from 'lib/enums';
import { Autocomplete } from 'lib/components/Autocomplete';
export function SelectStateDropdown({ stateOptions, selectedState, onChange }) {
    return (_jsx(Autocomplete, { id: "selectState", labelText: "State", onChange: onChange, placeholder: "Select a state", value: selectedState || '', options: stateOptions.map(stateId => {
            var _a;
            return ({
                value: String(stateId),
                label: ((_a = State.by_value(stateId)) === null || _a === void 0 ? void 0 : _a.label) || ''
            });
        }) }));
}
