import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import { useState } from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { valueIsNumber } from 'lib/pricing';
import CurrencyTextField from 'lib/components/CurrencyTextField';
export default function AdminSettingsCard({ onChange, value }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [feeSplitType, setFeeSplitType] = useState((_a = value === null || value === void 0 ? void 0 : value.convenienceFeeSplit) === null || _a === void 0 ? void 0 : _a.type);
    const [showFeeSplitCard, setShowFeeSplitCard] = useState(!!((_b = value === null || value === void 0 ? void 0 : value.convenienceFeeSplit) === null || _b === void 0 ? void 0 : _b.type));
    const showFlatFeeSplitInput = feeSplitType === 'flat';
    const showPercentFeeSplitInput = feeSplitType === 'percent';
    const [showPercentFeeSplitCap, setShowPercentFeeSplitCap] = useState(feeSplitType === 'percent' && valueIsNumber((_c = value === null || value === void 0 ? void 0 : value.convenienceFeeSplit) === null || _c === void 0 ? void 0 : _c.cap));
    const feeSplitOptions = {
        full_waiver: `Full Waiver (no one pays)`,
        flat: `Flat Split (publishers pays up to a fixed amount)`,
        percent: `Percent Split (publisher pays a % of the fee)`
    };
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Admin Settings',
            description: 'These settings are available to Column super users only.'
        } }, { children: [_jsx(SwitchControlledCard, Object.assign({ labelProps: {
                    label: "Should we override Column's processing fee for this rate?",
                    description: 'This setting should only be changed with explicit confirmation from Richman.',
                    value: value.enotice_fee_pct !== null,
                    onChange: newVal => onChange(newVal
                        ? Object.assign(Object.assign({}, value), { enotice_fee_pct: 10 }) : Object.assign(Object.assign({}, value), { enotice_fee_pct: null }))
                }, header: "" }, { children: _jsx(GridInput, { children: _jsx(TextField, { id: "enotice-fee-pct", labelText: "What is the processing fee (in %) for this rate?", value: (value.enotice_fee_pct || 0).toString(), type: "number", min: 0, max: 30, step: 1, onChange: val => onChange(Object.assign(Object.assign({}, value), { enotice_fee_pct: parseFloat(val || '0') })) }) }) })), _jsxs(SwitchControlledCard, Object.assign({ labelProps: {
                    label: "Should a publisher fee split apply to Column's processing fee for this rate?",
                    description: 'This setting should only be changed with explicit confirmation from Richman.',
                    value: showFeeSplitCard,
                    onChange: newValue => {
                        if (!newValue) {
                            onChange(Object.assign(Object.assign({}, value), { convenienceFeeSplit: undefined }));
                            setShowFeeSplitCard(false);
                        }
                        else {
                            setShowFeeSplitCard(true);
                        }
                    }
                }, header: "Fee Split Detail Settings" }, { children: [showFeeSplitCard && (_jsx(ColumnSelect, { id: "rate-fee-split-type", labelText: "What sort of fee split?", value: (_d = value.convenienceFeeSplit) === null || _d === void 0 ? void 0 : _d.type, options: Object.entries(feeSplitOptions).map(([key, val]) => ({
                            label: val,
                            value: key
                        })), allowUndefined: true, disabled: value.enotice_fee_pct === 0, required: true, placeholder: "No fee split type selected", onChange: newValue => {
                            const newType = newValue;
                            setFeeSplitType(newType);
                            onChange(Object.assign(Object.assign({}, value), { convenienceFeeSplit: {
                                    type: newType,
                                    amount: 0
                                } }));
                        } })), showFlatFeeSplitInput && (_jsx(CurrencyTextField, { id: "flat-fee-split-amount", labelText: "What flat amount should the publisher pay?", initialValue: (_f = (_e = value.convenienceFeeSplit) === null || _e === void 0 ? void 0 : _e.amount) !== null && _f !== void 0 ? _f : 0, placeholder: `Enter a value in $`, onChange: newValue => onChange(Object.assign(Object.assign({}, value), { convenienceFeeSplit: {
                                type: 'flat',
                                amount: newValue
                            } })), required: true })), showPercentFeeSplitInput && (_jsx(GridInput, { children: _jsx(TextField, { id: "enotice-fee-split-pct-input", labelText: "What % of the fee should the publisher pay?", value: (((_g = value.convenienceFeeSplit) === null || _g === void 0 ? void 0 : _g.amount) || 0).toString(), type: "number", min: 0, max: 100, step: 1, placeholder: `enter a value from 0 to 100`, required: true, onChange: newVal => onChange(Object.assign(Object.assign({}, value), { convenienceFeeSplit: {
                                    type: 'percent',
                                    amount: parseFloat(newVal || '0')
                                } })) }) })), showPercentFeeSplitInput && (_jsx(LabeledSwitch, { label: "Is there a per-notice fee cap (max amount) agreed upon with this publisher?", value: ((_h = value.convenienceFeeSplit) === null || _h === void 0 ? void 0 : _h.type) === 'percent' &&
                            valueIsNumber(value.convenienceFeeSplit.cap), onChange: newVal => {
                            var _a, _b;
                            onChange(Object.assign(Object.assign({}, value), { convenienceFeeSplit: {
                                    amount: (_b = (_a = value.convenienceFeeSplit) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0,
                                    type: 'percent',
                                    cap: newVal ? 0 : undefined
                                } }));
                            setShowPercentFeeSplitCap(newVal);
                        }, disabled: ((_j = value.convenienceFeeSplit) === null || _j === void 0 ? void 0 : _j.type) !== 'percent' })), showPercentFeeSplitCap && showPercentFeeSplitInput && (_jsx(CurrencyTextField, { id: "percent-fee-split-cap", labelText: "Fee cap per notice (in $)", initialValue: (_k = value.convenienceFeeSplit) === null || _k === void 0 ? void 0 : _k.cap, onChange: newValue => {
                            var _a, _b;
                            return onChange(Object.assign(Object.assign({}, value), { convenienceFeeSplit: {
                                    amount: (_b = (_a = value.convenienceFeeSplit) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0,
                                    type: 'percent',
                                    cap: newValue
                                } }));
                        }, required: true }))] }))] })));
}
