import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Badge } from 'lib/components/Badge';
import { XMarkIcon } from '@heroicons/react/24/outline';
export function PublisherLocationFilterBadge({ activeFilterCount, onClear }) {
    if (activeFilterCount === 0) {
        return null;
    }
    function handleClear(e) {
        e.stopPropagation();
        onClear();
    }
    return (_jsxs(Badge, Object.assign({ status: "info", endIcon: _jsx(XMarkIcon, { "aria-label": "Reset filters", "aria-hidden": "false", role: "button", className: "w-3", onClick: handleClear }) }, { children: [activeFilterCount, " active"] })));
}
