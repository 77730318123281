var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiPost } from 'api/typed';
import { OrderModel } from 'lib/model/objects/orderModel';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromId } from 'lib/model';
import { hashString } from './crypt';
export const createOrder = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const createResult = yield apiPost('orders/create', params);
    if (!createResult.success) {
        throw createResult.error;
    }
    const { accessCode } = createResult;
    return accessCode;
});
export const getOrderToken = (accessCode, email, options) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const hashedAccessCode = yield hashString(accessCode);
    const accessResult = yield apiPost('orders/grant-access', {
        accessCode: hashedAccessCode,
        authEmail: email,
        forFuneralHomeVerification: (_a = options === null || options === void 0 ? void 0 : options.forFuneralHomeVerification) !== null && _a !== void 0 ? _a : false,
        editVersion: options === null || options === void 0 ? void 0 : options.editVersion
    });
    if (!accessResult.success) {
        throw Error(accessResult.error);
    }
    const { token, orderId } = accessResult;
    return { token, orderId };
});
export const loadOrderItems = (orderId, version) => __awaiter(void 0, void 0, void 0, function* () {
    const context = getFirebaseContext();
    const orderModel = yield getModelFromId(OrderModel, context, context.ordersRef(), orderId);
    const { response: adModel, error: adModelError } = yield orderModel.getAdByVersion(version);
    if (adModelError) {
        throw adModelError;
    }
    const newspaperOrderModels = yield orderModel.getNewspaperOrders({
        specifiedVersion: version
    });
    const newspaperOrders = newspaperOrderModels.map(newspaperOrderModel => newspaperOrderModel.modelData);
    return { orderModel, adModel, newspaperOrders };
});
export const updateOrderPricing = (orderId, version) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield apiPost(`orders/update-price`, { orderId, version });
    if (result.error) {
        throw result.error;
    }
});
export function getLoadOrderEmail() {
    const queryParams = new URLSearchParams(location.search);
    const encodedEmail = queryParams.get('email');
    return encodedEmail ? decodeURIComponent(encodedEmail) : null;
}
