var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoticesActions from 'redux/notices';
import FreeformCModal from 'components/modals/FreeFormCModal';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';
function DeleteDraftModal({ handleClose, setOpen, noticeToDelete, noticeActions }) {
    const user = useAppSelector(selectUser);
    const isOwner = (user === null || user === void 0 ? void 0 : user.id) === noticeToDelete.draftownerid;
    const deleteNotice = () => __awaiter(this, void 0, void 0, function* () {
        const req = {
            noticeId: noticeToDelete.orginalnoticeid,
            isdraft: !!noticeToDelete.isdraft,
            draftownerid: noticeToDelete.draftownerid,
            ispublisherdraft: !!noticeToDelete.ispublisherdraft
        };
        try {
            yield api.post(`drafts/${noticeToDelete.id}/delete`, req);
            // Refresh drafts table after 3 seconds to allow it to be deleted in Elastic
            setTimeout(() => {
                noticeActions.updateNotices();
            }, 3000);
        }
        catch (e) {
            logAndCaptureException(ColumnService.WEB_PLACEMENT, e, 'Error deleting notice', {
                noticeId: noticeToDelete.id
            });
        }
        handleClose();
    });
    return (_jsx(FreeformCModal, Object.assign({ id: "delete-draft-modal", setOpen: setOpen, header: "Delete draft?" }, { children: _jsxs(_Fragment, { children: [!isOwner && (_jsx("div", Object.assign({ className: "py-2" }, { children: _jsx(Alert, Object.assign({ id: "draft-owned-by-other-warning", icon: _jsx(ExclamationCircleIcon, { className: "w-5 h-5" }), status: "error" }, { children: "This notice was created by another user in your organization." })) }))), _jsx("div", Object.assign({ className: "text-sm font-medium leading-5 text-gray-700 pt-2 pb-4" }, { children: "Are you sure you would like to delete this draft? Clicking Delete cannot be undone." })), _jsx(ColumnButton, { id: 'confirm-delete', onClick: deleteNotice, type: "button", primary: true, destructive: true, buttonText: "Delete" })] }) })));
}
const mapDispatchToProps = (dispatch) => ({
    noticeActions: bindActionCreators(NoticesActions, dispatch)
});
export default connect(null, mapDispatchToProps)(DeleteDraftModal);
