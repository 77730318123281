import { findMatchingNoticeType } from '../publishers';
import { RateType } from '../enums';
import { valueIsNumber } from '../pricing/math';
/**
 * These rate types always allow images.
 */
const ALWAYS_ALLOW_IMAGES = [
    RateType.inch.value,
    RateType.flat.value,
    RateType.column_inch.value
];
/**
 * Check if a given rate can correctly price a liner ad which contains
 * an image. Example: column-inch rates support images but word rates
 * do not.
 */
export const rateSupportsImagesInLiners = (rate) => {
    var _a, _b;
    const type = rate.data().rateType;
    if (ALWAYS_ALLOW_IMAGES.includes(type)) {
        return true;
    }
    // Oklahoma rates with this variable set allow images
    if (type === RateType.oklahoma.value) {
        return valueIsNumber((_a = rate.data().images) === null || _a === void 0 ? void 0 : _a.linesPerInch);
    }
    // Line rates with a number value for images?.linePerInch (whether zero or positive)
    // support images
    if (type === RateType.line.value && rate.data().images) {
        return valueIsNumber((_b = rate.data().images) === null || _b === void 0 ? void 0 : _b.linesPerInch);
    }
    return false;
};
/**
 * If the notice HTML includes an image and the rate can't price images (ex: per word)
 * then we need to remove the images from the html.
 */
export const shouldRemoveImagesFromLiners = (rate, confirmedHtml) => {
    return !rateSupportsImagesInLiners(rate) && (confirmedHtml === null || confirmedHtml === void 0 ? void 0 : confirmedHtml.includes('<img'));
};
/**
 * Check if imagpes in liners are explicitly allowed at either the notice type or
 * organiation level.
 */
export const noticeTypeSupportsImagesInLiners = (newspaper, noticeTypeValue) => {
    // If the value is explicitly set at this level, it overrides the organization
    const noticeType = findMatchingNoticeType(newspaper, noticeTypeValue);
    if (typeof (noticeType === null || noticeType === void 0 ? void 0 : noticeType.allowImagesInLiners) === 'boolean') {
        return noticeType.allowImagesInLiners;
    }
    return !!newspaper.data().allowImagesInLiners;
};
