import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
/**
 * Header component for the e-edition drawer
 */
export default function SubscriptionDrawerHeader({ subscription }) {
    var _a, _b;
    const [localSubscription, setLocalSubscription] = useState(subscription);
    useEffect(() => {
        const unsub = subscription.ref.onSnapshot(newSnapshot => {
            if (exists(newSnapshot))
                setLocalSubscription(newSnapshot);
        });
        return () => unsub();
    }, [subscription.id]);
    return (_jsx("div", Object.assign({ className: "flex items-center text-column-gray-500 font-semibold text-xl" }, { children: _jsxs("div", Object.assign({ className: "mr-2" }, { children: ["Subscription for ", (_b = (_a = localSubscription.data().mailing) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.name] })) })));
}
