import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { TextField } from 'lib/components/TextField';
import { ExportFormatType } from 'lib/enums';
import { DEFAULT_FTP_PORT } from 'lib/types/integrations';
import React, { useState } from 'react';
import { DefaultSettings } from './DefaultSettings';
import { DestinationEmail } from './DestinationEmail';
import { DestinationFTP } from './DestinationFTP';
export const INITIAL_BULK_DOWNLOAD_SETTINGS = {
    defaultSettings: {
        linerFormat: ExportFormatType.jpg.value,
        displayFormat: ExportFormatType.pdf.value,
        grouping: 'all_liners'
    },
    noLinerImages: false,
    noRTF: false,
    noTXT: false
};
export function AutomatedBulkDownload({ value, onChange, hasBulkDownload }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [emailSettings, setEmailSettings] = useState(((_a = value === null || value === void 0 ? void 0 : value.destination) === null || _a === void 0 ? void 0 : _a.type) === 'email'
        ? value.destination
        : {
            replyTo: '',
            to: [''],
            type: 'email'
        });
    const [ftpSettings, setFtpSettings] = useState(((_b = value === null || value === void 0 ? void 0 : value.destination) === null || _b === void 0 ? void 0 : _b.type) === 'ftp'
        ? value.destination
        : {
            to: {
                format: 'FTP',
                host: '',
                user: '',
                password: '',
                path: '',
                port: DEFAULT_FTP_PORT,
                secure: false
            },
            type: 'ftp'
        });
    const wrappedOnChange = (change) => {
        const current = value !== null && value !== void 0 ? value : INITIAL_BULK_DOWNLOAD_SETTINGS;
        onChange(Object.assign(Object.assign({}, current), change));
    };
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Bulk Download Settings',
            description: 'Configure default behavior for bulk downloads, and automatically send bulk downloads at/after deadline.'
        } }, { children: [_jsx(DefaultSettings, { value: (_c = value === null || value === void 0 ? void 0 : value.defaultSettings) !== null && _c !== void 0 ? _c : INITIAL_BULK_DOWNLOAD_SETTINGS.defaultSettings, onChange: defaultSettings => wrappedOnChange({ defaultSettings }), hasBulkDownload: hasBulkDownload }), _jsxs(SwitchControlledCard, Object.assign({ header: '', labelProps: {
                    label: 'Automatically export bulk download at deadline?',
                    description: 'If enabled, Column will automatically send a ZIP file for each publication date via email or FTP.',
                    value: !!(value === null || value === void 0 ? void 0 : value.automatic),
                    onChange: automatic => {
                        var _a;
                        return wrappedOnChange({
                            automatic,
                            destination: (_a = value === null || value === void 0 ? void 0 : value.destination) !== null && _a !== void 0 ? _a : emailSettings
                        });
                    }
                } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { type: "number", id: "automated-bulk-download-offset", labelText: "When should we send the bulk download (in minutes after deadline)?", value: (_d = value === null || value === void 0 ? void 0 : value.offsetMinutes) === null || _d === void 0 ? void 0 : _d.toString(), onChange: stringValue => wrappedOnChange({ offsetMinutes: Number(stringValue) }), placeholder: "5" }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(RadioButtonGroup, { id: "automated-bulk-download-destination-type", labelText: "How should we send the bulk download?", options: [
                                {
                                    labelText: 'Send an email with a link to a ZIP file',
                                    value: 'email'
                                },
                                {
                                    labelText: 'Send a ZIP file via FTP',
                                    value: 'ftp'
                                }
                            ], value: (_f = (_e = value === null || value === void 0 ? void 0 : value.destination) === null || _e === void 0 ? void 0 : _e.type) !== null && _f !== void 0 ? _f : 'email', onChange: destinationType => {
                                switch (destinationType) {
                                    case 'email': {
                                        return wrappedOnChange({ destination: emailSettings });
                                    }
                                    case 'ftp': {
                                        return wrappedOnChange({ destination: ftpSettings });
                                    }
                                }
                            }, required: true }) })), ((_g = value === null || value === void 0 ? void 0 : value.destination) === null || _g === void 0 ? void 0 : _g.type) === 'email' && (_jsx(DestinationEmail, { value: emailSettings, onChange: changes => {
                            const destination = Object.assign(Object.assign({}, emailSettings), changes);
                            setEmailSettings(destination);
                            wrappedOnChange({ destination });
                        } })), ((_h = value === null || value === void 0 ? void 0 : value.destination) === null || _h === void 0 ? void 0 : _h.type) === 'ftp' && (_jsx(DestinationFTP, { value: ftpSettings.to, onChange: to => {
                            const destination = Object.assign(Object.assign({}, ftpSettings), { to });
                            setFtpSettings(destination);
                            wrappedOnChange({ destination });
                        } }))] }))] })));
}
