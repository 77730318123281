var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { SnapshotModel, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import { InvoiceStatus } from '../../enums';
import { isBulkInvoiceData, isOrderInvoiceData, isPublicNoticeInvoiceData } from '../../types/invoices';
import { UserNoticeModel } from './userNoticeModel';
import { wrapError, wrapSuccess } from '../../types/responses';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
import { OrderModel } from './orderModel';
import { BadRequestError, NotFoundError, wrapErrorAsColumnError } from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
export const INVOICE_STATUSES_UNPAID = [
    InvoiceStatus.unpaid.value,
    InvoiceStatus.payment_failed.value,
    InvoiceStatus.draft.value
];
/**
 * We lump partial refund in here because we currently treat the invoice as "good as paid" in
 * integrations. That status is essentially used to track when the invoice can hold no more
 * refunds because before invoice transactions that was the limitation. Reassess this status
 * when we support multiple partial refunds.
 */
export const INVOICE_STATUSES_FUNDS_RECEIVED = [
    InvoiceStatus.paid.value,
    InvoiceStatus.partially_refunded.value
];
export const INVOICE_STATUSES_FUNDS_PENDING = [
    InvoiceStatus.initiated.value,
    InvoiceStatus.authorized.value
];
export const INVOICE_STATUSES_PAYMENT_OR_PARTIAL_REFUND = [
    ...INVOICE_STATUSES_FUNDS_RECEIVED,
    ...INVOICE_STATUSES_FUNDS_PENDING
];
/**
 * Finalized invoices are non-draft invoices in Stripe that are no longer mutable
 * This concept is used in bulk invoices and some integrations to guard a sync
 */
export const INVOICE_STATUSES_FINALIZED = [
    ...INVOICE_STATUSES_FUNDS_RECEIVED,
    InvoiceStatus.refunded.value
];
/**
 * This model supports a generic type T that extends Invoice, but defaults to PublicNoticeInvoice
 * if no type is provided. This allows us to override cases where this is a different invoice type.
 * Once the discriminated type 'Invoice' is fully implemented, we will no longer need a default here.
 */
export class InvoiceModel extends SnapshotModel {
    get type() {
        return Collections.invoices;
    }
    getNotice() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isPublicNoticeInvoice()) {
                const err = new Error('Invoice is not a public notice invoice');
                getErrorReporter().logAndCaptureError(ColumnService.GENERAL_INFRASTRUCTURE, err, 'Cannot get notice for a non-public notice invoice', {
                    invoiceId: this.id
                });
                return wrapError(err);
            }
            const { response: notice, error: noticeError } = yield safeGetOrThrow(this.ctx.userNoticesRef().doc(this.modelData.noticeId));
            if (noticeError) {
                getErrorReporter().logAndCaptureError(ColumnService.GENERAL_INFRASTRUCTURE, noticeError, 'Unable to get notice for invoice', {
                    noticeId: this.modelData.noticeId,
                    invoiceId: this.id
                });
                return wrapError(noticeError);
            }
            return wrapSuccess(getModelFromSnapshot(UserNoticeModel, this.ctx, notice));
        });
    }
    getOrder() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isOrderInvoice()) {
                const err = new Error('Invoice is not an order invoice');
                getErrorReporter().logAndCaptureError(ColumnService.GENERAL_INFRASTRUCTURE, err, 'Cannot get order for a non-order invoice', {
                    invoiceId: this.id
                });
                return wrapErrorAsColumnError(err, BadRequestError);
            }
            const { response: orderSnap, error: getOrderError } = yield safeGetOrThrow(this.modelData.order);
            if (getOrderError || !orderSnap) {
                getErrorReporter().logAndCaptureError(ColumnService.GENERAL_INFRASTRUCTURE, getOrderError, 'Unable to get order for invoice', {
                    orderId: this.modelData.order.id,
                    invoiceId: this.id
                });
                return wrapErrorAsColumnError(getOrderError, NotFoundError);
            }
            return wrapSuccess(getModelFromSnapshot(OrderModel, this.ctx, orderSnap));
        });
    }
    isOrderInvoice() {
        return isOrderInvoiceData(this.modelData);
    }
    isPublicNoticeInvoice() {
        return isPublicNoticeInvoiceData(this.modelData);
    }
    isBulkInvoice() {
        return isBulkInvoiceData(this.modelData);
    }
    isPastDue() {
        const { due_date } = this.modelData;
        const currentDate = moment();
        const dueDate = moment(due_date * 1000);
        return moment(dueDate).isBefore(currentDate);
    }
    isUnpaid() {
        return (INVOICE_STATUSES_UNPAID.includes(this.modelData.status) &&
            !this.modelData.void);
    }
    isRefunded() {
        return this.modelData.status === InvoiceStatus.refunded.value;
    }
    isVoided() {
        return !!this.modelData.void;
    }
    getStatusLabel() {
        var _a, _b;
        return (_b = (_a = InvoiceStatus.by_value(this.modelData.status)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : 'Unknown';
    }
    paymentReceived() {
        return (INVOICE_STATUSES_FUNDS_RECEIVED.includes(this.modelData.status) &&
            !this.modelData.void);
    }
    paymentPending() {
        return (INVOICE_STATUSES_FUNDS_PENDING.includes(this.modelData.status) &&
            !this.modelData.void);
    }
}
