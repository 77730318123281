var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import { combineCustomerNameFields, getCustomerOrganization } from 'lib/notice/customer';
import classNames from 'classnames';
import { Badge } from 'lib/components/Badge';
import LoadingState from 'components/LoadingState';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import TabGroup from 'lib/components/Tabs';
import { DotIcon } from 'icons';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import Drawer from 'lib/components/Drawer';
import { getInvitesAssociatedWithEmail, isDefined } from 'lib/helpers';
import { InviteStatusTypeData } from 'lib/enums/InviteStatusType';
import { OccupationType } from 'lib/enums';
import { getFirebaseContext } from '../../utils/firebase';
import { CustomerDrawerDetailsTab } from './CustomerDrawerDetailsTab';
import { CustomerDrawerOrganizationTab } from './CustomerDrawerOrganizationsTab';
import { CustomerDrawerSettingsTab } from './CustomerDrawerSettingsTab';
import { CustomerDrawerFooter } from './CustomerDrawerFooter';
import { REQUIRE_UPFRONT_PAYMENT_TYPES } from './CreateOrEditCustomerModalSettings';
import NotesTab from './NotesTab/NotesTab';
const DETAILS_TAB = {
    label: 'Details',
    enabled: true,
    id: 'details'
};
const getCustomerDrawerTabsForUser = (user, notesQuery, hasOrganizationInvites, hideSettingsTab = false) => {
    var _a;
    const numberOfNotes = (notesQuery === null || notesQuery === void 0 ? void 0 : notesQuery.docs.filter(doc => doc.data().status !== 'archived').length) ||
        0;
    const tabs = [
        DETAILS_TAB,
        {
            label: 'Organization',
            enabled: !!((_a = user === null || user === void 0 ? void 0 : user.data().allowedOrganizations) === null || _a === void 0 ? void 0 : _a.length) || hasOrganizationInvites,
            id: 'organizations'
        },
        {
            label: numberOfNotes > 0 ? `Notes (${numberOfNotes})` : 'Notes',
            enabled: true,
            id: 'notes'
        }
    ];
    if (!hideSettingsTab) {
        tabs.splice(2, 0, {
            label: 'Settings',
            enabled: true,
            id: 'settings'
        });
    }
    return tabs;
};
export default function CustomerDrawer({ customerSnap, userSnap, activeOrganization, onCloseDrawer, onEditCustomer, setShowCustomerOrganizationDrawer, setCustomerOrganization }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const [activeTab, setActiveTab] = useState(DETAILS_TAB);
    const [loadingCustomerOrganizations, setLoadingCustomerOrganizations] = useState(false);
    const [customerOrganizations, setCustomerOrganizations] = useState();
    const [allowedOrganizationNames, setAllowedOrganizationNames] = useState([]);
    const [invitedOrganizatonNames, setInvitedOrganizatonNames] = useState([]);
    const requireUpfrontPayment = (customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().requireUpfrontPayment) === null ||
        (customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().requireUpfrontPayment) === undefined
        ? REQUIRE_UPFRONT_PAYMENT_TYPES.DEFAULT
        : (customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().requireUpfrontPayment)
            ? REQUIRE_UPFRONT_PAYMENT_TYPES.ALWAYS_REQUIRE
            : REQUIRE_UPFRONT_PAYMENT_TYPES.NEVER_REQUIRE;
    const { hideDigitalAffidavits } = activeOrganization.data() || {};
    /*
     * Load the publisher and rates snaps from Firestore
     */
    const org = useFirestoreSnapshot((_a = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()) === null || _a === void 0 ? void 0 : _a.organization);
    const customerLinerRate = useFirestoreSnapshot(customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().linerRate);
    const orgDefaultLinerRate = useFirestoreSnapshot(org === null || org === void 0 ? void 0 : org.data().defaultLinerRate);
    const defaultLinerRate = customerLinerRate !== null && customerLinerRate !== void 0 ? customerLinerRate : orgDefaultLinerRate;
    const customerDisplayRate = useFirestoreSnapshot(customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().displayRate);
    const orgDefaultDisplayRate = useFirestoreSnapshot(org === null || org === void 0 ? void 0 : org.data().defaultDisplayRate);
    const defaultDisplayRate = customerDisplayRate !== null && customerDisplayRate !== void 0 ? customerDisplayRate : orgDefaultDisplayRate;
    const hideSettingsTab = userSnap.data().occupation === OccupationType.funeral_director.value;
    useEffect(() => {
        const getCustomerOrganizations = () => __awaiter(this, void 0, void 0, function* () {
            setLoadingCustomerOrganizations(true);
            // Fetch relevant organizations from allowedOrganizations
            const allowedOrganizations = (userSnap === null || userSnap === void 0 ? void 0 : userSnap.data().allowedOrganizations) || [];
            let organizationsAffiliatedWithNewspaper = yield Promise.all(allowedOrganizations.map((organization) => __awaiter(this, void 0, void 0, function* () {
                const organizationSnap = yield organization.get();
                const customerOrgSnap = yield getCustomerOrganization(getFirebaseContext(), organizationSnap, activeOrganization);
                return customerOrgSnap;
            })));
            organizationsAffiliatedWithNewspaper = organizationsAffiliatedWithNewspaper.filter(organization => organization !== null);
            const orgNames = yield Promise.all(allowedOrganizations.map((organization) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const organizationSnap = yield organization.get();
                return (_a = organizationSnap.data()) === null || _a === void 0 ? void 0 : _a.name;
            })));
            // Fetch relevant organization from pending invites
            const invites = yield getInvitesAssociatedWithEmail(getFirebaseContext(), userSnap.data().email);
            setInvitedOrganizatonNames(invites
                .map(invite => invite.data().status === InviteStatusTypeData.pending.value ||
                invite.data().status === InviteStatusTypeData.snoozed.value
                ? invite.data().organizationName
                : undefined)
                .filter(isDefined)
                .filter(x => !orgNames.includes(x)));
            setCustomerOrganizations(organizationsAffiliatedWithNewspaper);
            setAllowedOrganizationNames(orgNames);
            setLoadingCustomerOrganizations(false);
        });
        void getCustomerOrganizations();
    }, [safeStringify(customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data()), safeStringify(userSnap === null || userSnap === void 0 ? void 0 : userSnap.data())]);
    const customerNotes = useFirestoreQueryListener(getFirebaseContext()
        .notesRef()
        .where('customer', '==', customerSnap.ref)
        .where('noteCreatorOrganization', '==', activeOrganization.ref), [customerSnap.id]);
    const tabs = getCustomerDrawerTabsForUser(userSnap, customerNotes, invitedOrganizatonNames.length > 0, hideSettingsTab);
    const REGISTERED = 'Registered';
    const NOT_REGISTERED = 'Not registered';
    const customerName = combineCustomerNameFields(customerSnap) || '--';
    const header = (_jsx("div", Object.assign({ className: "text-column-gray-500 font-semibold text-xl" }, { children: customerName })));
    const subheader = (_jsxs(_Fragment, { children: [_jsxs("span", Object.assign({ className: "text-xs text-column-gray-400 font-medium" }, { children: [userSnap === null || userSnap === void 0 ? void 0 : userSnap.data().email, _jsx("span", Object.assign({ className: "mx-3" }, { children: "|" }))] })), _jsx(Badge, Object.assign({ status: (userSnap === null || userSnap === void 0 ? void 0 : userSnap.data().lastSignInTime) ? 'success' : 'critical' }, { children: _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(DotIcon, { className: classNames({
                                'text-column-red-700': !(userSnap === null || userSnap === void 0 ? void 0 : userSnap.data().lastSignInTime),
                                'text-column-green-600': !!(userSnap === null || userSnap === void 0 ? void 0 : userSnap.data().lastSignInTime)
                            }) }), _jsx("div", Object.assign({ className: "pl-1" }, { children: (userSnap === null || userSnap === void 0 ? void 0 : userSnap.data().lastSignInTime) ? REGISTERED : NOT_REGISTERED }))] })) }))] }));
    if (!customerSnap || !userSnap)
        return (_jsx(Drawer, Object.assign({ open: true, onClose: onCloseDrawer, header: ' ' }, { children: _jsx(LoadingState, {}) })));
    const customerRow = customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data();
    const notesTabIsActive = activeTab.id === 'notes';
    return (_jsxs(Drawer, Object.assign({ open: true, onClose: onCloseDrawer, header: _jsxs("div", { children: [header, subheader] }) }, { children: [_jsx(TabGroup, { tabs: tabs, activeTab: activeTab, onClickTab: setActiveTab, id: "customer-drawer-tabs" }), _jsxs("div", Object.assign({ className: "flex-1 flex overflow-scroll w-full" }, { children: [activeTab.label === 'Details' && (_jsx(CustomerDrawerDetailsTab, { userName: customerName !== null && customerName !== void 0 ? customerName : undefined, address: (_b = customerRow.address) !== null && _b !== void 0 ? _b : undefined, addressLine2: (_c = customerRow.addressLine2) !== null && _c !== void 0 ? _c : undefined, phone: (_d = customerRow.phone) !== null && _d !== void 0 ? _d : undefined, city: (_e = customerRow.city) !== null && _e !== void 0 ? _e : undefined, state: typeof customerRow.state === 'number'
                            ? customerRow.state
                            : undefined, zipCode: (_f = customerRow.zipCode) !== null && _f !== void 0 ? _f : undefined, internalID: (_g = customerRow.internalID) !== null && _g !== void 0 ? _g : undefined, showOrganizationName: !!(customerOrganizations === null || customerOrganizations === void 0 ? void 0 : customerOrganizations.length), organizationName: customerRow.organizationName, email: userSnap === null || userSnap === void 0 ? void 0 : userSnap.data().email })), activeTab.label === 'Organization' && (_jsx(CustomerDrawerOrganizationTab, { customerOrganizations: customerOrganizations, loadingCustomerOrganizations: loadingCustomerOrganizations, setShowCustomerOrganizationDrawer: () => {
                            setShowCustomerOrganizationDrawer(true);
                            onCloseDrawer();
                        }, setCustomerOrganizationToDisplay: setCustomerOrganization, allowedOrganizationNames: allowedOrganizationNames, invitedOrganizatonNames: invitedOrganizatonNames })), activeTab.label === 'Settings' && !hideSettingsTab && (_jsx(CustomerDrawerSettingsTab, { linerRateDescription: (_h = defaultLinerRate === null || defaultLinerRate === void 0 ? void 0 : defaultLinerRate.data()) === null || _h === void 0 ? void 0 : _h.description, displayRateDescription: (_j = defaultDisplayRate === null || defaultDisplayRate === void 0 ? void 0 : defaultDisplayRate.data()) === null || _j === void 0 ? void 0 : _j.description, upfrontPayment: requireUpfrontPayment.description, affidavitsBeforePayment: customerRow.enableAffidavitsBeforePayment, activeOrganization: activeOrganization, allowBulkInvoicing: customerRow.bulkPaymentEnabled_v2, billingTerm: customerRow.billingTerm, invoicedOutsideColumn: customerRow.invoicedOutsideColumn, hideDigitalAffidavits: hideDigitalAffidavits, allowAffidavitEmail: customerRow.allowAffidavitEmail, user: userSnap.data() })), notesTabIsActive && (_jsx(NotesTab, { noteTopic: {
                            customer: customerSnap.ref,
                            noteType: 'customer-note'
                        }, notes: customerNotes === null || customerNotes === void 0 ? void 0 : customerNotes.docs })), !notesTabIsActive && (_jsx(CustomerDrawerFooter, { customerObjectSnap: customerSnap, onEditCustomer: onEditCustomer }))] }))] })));
}
