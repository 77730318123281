import { getFirebaseContextForApp } from 'lib/frontend/utils/firebase';
import Firebase, { FirestoreTimestamp } from '../EnoticeFirebase';
/**
 * The main entry point into the land of EFirebase.
 */
export const getFirebaseContext = () => {
    return getFirebaseContextForApp(Firebase);
};
/**
 * Coerce a Firestore timestamp or Date into a Firestore timestamp. Note that
 * this utility cannot be moved into 'lib' because there are slight differences
 * between how Firestore timestamps are created in firebase vs. firebase-admin.
 */
export const timestampOrDateToTimestamp = (d) => {
    if (d instanceof Date ||
        Object.prototype.toString.call(d) === '[object Date]') {
        return FirestoreTimestamp.fromDate(d);
    }
    if (typeof d.toDate === 'function') {
        return d;
    }
    throw new Error(`Could not convert object to timestamp: ${JSON.stringify(d)} (type ${typeof d})`);
};
