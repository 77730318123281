import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
export function EraseContentModal({ setShowEraseContentModal, cancel, confirm }) {
    return (_jsx(CancelOrSubmitModal, Object.assign({ id: "erase-content", destructive: true, header: "Erase Notice Content?", onClose: () => {
            cancel();
            setShowEraseContentModal(false);
        }, tertiaryButtonText: "Cancel", primaryButtonText: "Yes, Clear Content", onSubmit: () => {
            confirm();
            setShowEraseContentModal(null);
        } }, { children: _jsx("p", Object.assign({ className: "py-8 text-column-gray-400 font-medium text-sm" }, { children: "Are you sure you want to clear the content of your notice and start over?" })) })));
}
