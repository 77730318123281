import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { Alert } from 'lib/components/Alert';
import { Form } from 'lib/components/Form';
import { GridInput } from 'lib/components/Card/Grid';
export const DEFAULT_FORGET_PASSWORD_DATA = {
    email: ''
};
export default function ForgotPasswordForm({ onChangeForgetPasswordData, forgetPasswordData, resettingPassword, sentResetPassword, onSubmit }) {
    return (_jsx(Form, Object.assign({ onSubmit: onSubmit }, { children: _jsxs("div", Object.assign({ className: "flex flex-col sm:grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8" }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "email", required: true, type: "email", labelText: "Email", value: forgetPasswordData.email, onChange: newValue => onChangeForgetPasswordData(Object.assign(Object.assign({}, forgetPasswordData), { email: newValue })), disabled: sentResetPassword }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx("div", { children: _jsx(ColumnButton, { size: "lg", buttonText: "Send Reset Email", loading: resettingPassword, disabled: sentResetPassword, fullWidth: true, primary: true, type: "submit", id: "submit" }) }) })), sentResetPassword && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, Object.assign({ status: "success", id: "password-reset-success", title: "Password Reset Email Sent", icon: _jsx(CheckCircleIcon, { className: "w-6 h-6" }) }, { children: "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder." })) })))] })) })));
}
