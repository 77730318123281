import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
export function usePublisherOrgRates(rateService, org, config) {
    var _a, _b;
    const { ownedRatesQuery, allowedRatesQuery } = rateService.getPublisherOrgRatesQueries(org);
    const ownedRates = useFirestoreQueryListener(ownedRatesQuery, [org.id, config === null || config === void 0 ? void 0 : config.product], {
        message: 'Error listening to owned rates',
        tags: { organizationId: org.id }
    });
    const allowedRates = useFirestoreQueryListener(allowedRatesQuery, [org.id, config === null || config === void 0 ? void 0 : config.product], {
        message: 'Error listening to allowed rates',
        tags: { organizationId: org.id }
    });
    const loading = allowedRates === null || ownedRates === null;
    const orgRates = [...((_a = ownedRates === null || ownedRates === void 0 ? void 0 : ownedRates.docs) !== null && _a !== void 0 ? _a : []), ...((_b = allowedRates === null || allowedRates === void 0 ? void 0 : allowedRates.docs) !== null && _b !== void 0 ? _b : [])];
    if (!(config === null || config === void 0 ? void 0 : config.includeArchived)) {
        return {
            orgRates: orgRates.filter(rate => !rate.data().archived),
            loading
        };
    }
    return { orgRates, loading };
}
