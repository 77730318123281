var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { logAndCaptureException } from 'utils';
import ToastActions from 'redux/toast';
import { ColumnService } from 'lib/services/directory';
import ForgotPasswordHeader from './ForgotPasswordHeader';
import ForgotPasswordForm, { DEFAULT_FORGET_PASSWORD_DATA } from './ForgotPasswordForm';
import { sendPasswordResetEmail } from './forgotPasswordHelpers';
export default function ForgotPassword() {
    const dispatch = useAppDispatch();
    const [forgotPasswordData, setForgotPasswordData] = useState(DEFAULT_FORGET_PASSWORD_DATA);
    const [resettingPassword, setResettingPassword] = useState(false);
    const [sentResetPassword, setSentResetPassword] = useState(false);
    const sendResetEmail = () => __awaiter(this, void 0, void 0, function* () {
        setResettingPassword(true);
        try {
            const { success, noUserFound } = yield sendPasswordResetEmail(forgotPasswordData.email);
            if (success) {
                dispatch(ToastActions.toastSuccess({
                    headerText: 'Check your inbox',
                    bodyText: "We've sent you a link to reset your password"
                }));
                setSentResetPassword(true);
            }
            if (noUserFound) {
                dispatch(ToastActions.toastError({
                    headerText: 'Password reset unsuccessful',
                    bodyText: 'No user found corresponding to the provided email. Please re-check email or contact help@column.us if email is correct'
                }));
            }
        }
        catch (e) {
            dispatch(ToastActions.toastError({
                headerText: 'Password reset unsuccessful',
                bodyText: 'Please try again in a few minutes or contact help@column.us'
            }));
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, e, 'Failed to reset password', {
                email: forgotPasswordData.email
            });
        }
        finally {
            setResettingPassword(false);
        }
    });
    return (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsx(ForgotPasswordHeader, {}), _jsx(ForgotPasswordForm, { onChangeForgetPasswordData: setForgotPasswordData, forgetPasswordData: forgotPasswordData, resettingPassword: resettingPassword, sentResetPassword: sentResetPassword, onSubmit: sendResetEmail })] })));
}
