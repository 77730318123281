var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import FullScreenModal from 'components/FullScreenModal';
import { exists } from 'lib/types';
import { isColumnUser } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import AffidavitTemplateEditorCard from './AffidavitContentEditor';
import { extractAffidavitTemplateData } from './affidavitTemplateEditorHelpers';
import { htmlToStoragePath } from '../htmlHelpers';
import BasicPropertiesCard from '../BasicPropertiesCard';
import { updateTemplateDefaultStatus } from '../statusHelpers';
export default function AffidavitTemplateEditForm({ affidavitTemplateRef, activeOrganization, affidavitTemplate, onClose }) {
    const [affidavitTemplateEditData, setAffidavitTemplateEditData] = useState();
    const [initialTemplateData, setInitialTemplateData] = useState();
    const user = useAppSelector(selectUser);
    const { value: initialAffidavitTemplateEditData } = useAsyncEffect({
        fetchData: () => extractAffidavitTemplateData(activeOrganization, affidavitTemplateRef, affidavitTemplate),
        dependencies: [affidavitTemplateRef === null || affidavitTemplateRef === void 0 ? void 0 : affidavitTemplateRef.id, affidavitTemplate.storagePath]
    });
    useEffect(() => {
        if (!initialAffidavitTemplateEditData)
            return;
        setInitialTemplateData(initialAffidavitTemplateEditData);
        setAffidavitTemplateEditData(initialAffidavitTemplateEditData);
    }, [Boolean(initialAffidavitTemplateEditData)]);
    const onSave = () => __awaiter(this, void 0, void 0, function* () {
        if (!affidavitTemplateEditData)
            return;
        const { name, publisher, defaultStatus, isColumnManaged, affidavitTemplateHTML } = affidavitTemplateEditData;
        const newStoragePath = yield htmlToStoragePath(activeOrganization, affidavitTemplateHTML, isColumnManaged ? 'column' : 'custom');
        const affidavitTemplateUpdate = {
            version: 'v2020-01-01',
            storagePath: newStoragePath,
            publisher,
            name,
            isColumnManaged
        };
        let editedAffidavitTemplateRef;
        if (affidavitTemplateRef) {
            yield affidavitTemplateRef.update(affidavitTemplateUpdate);
            editedAffidavitTemplateRef = affidavitTemplateRef;
        }
        else {
            editedAffidavitTemplateRef = yield getFirebaseContext()
                .affidavitTemplatesRef()
                .add(affidavitTemplateUpdate);
        }
        yield updateTemplateDefaultStatus(activeOrganization, editedAffidavitTemplateRef, {
            defaultStatus,
            isColumnManaged,
            newStoragePath
        });
        onClose();
    });
    const edited = !columnObjectsAreEqual(initialTemplateData, affidavitTemplateEditData);
    const templateExistsAndIsUnedited = !!affidavitTemplateRef && !edited;
    const disabled = !!templateExistsAndIsUnedited;
    return (_jsx(FullScreenModal, Object.assign({ headerText: affidavitTemplateRef
            ? 'Edit Affidavit Template'
            : 'New Affidavit Template', submittable: {
            buttonText: 'Save',
            onSubmit: onSave,
            disabled
        }, onClose: onClose, id: "edit-affidavit-template" }, { children: affidavitTemplateEditData ? (_jsxs(_Fragment, { children: [_jsx(BasicPropertiesCard, { affidavitTemplateEditData: affidavitTemplateEditData, onChangeAffidavitTemplateEditData: setAffidavitTemplateEditData }), exists(user) && isColumnUser(user) && (_jsx(AffidavitTemplateEditorCard, { onChangeAffidavitTemplateEditData: setAffidavitTemplateEditData, affidavitTemplateEditData: affidavitTemplateEditData }))] })) : (_jsx(LoadingState, {})) })));
}
