import { logAndCaptureMessage } from 'utils';
export const getErrorFromElavonTokenizerResponse = (response, isDeclined) => {
    let error;
    const { errorName, errorMessage, ssl_token_response, ssl_result_message, ssl_avs_response } = response;
    const uppercaseResultMessage = ssl_result_message === null || ssl_result_message === void 0 ? void 0 : ssl_result_message.toUpperCase();
    if (ssl_avs_response && ssl_avs_response === 'N') {
        error =
            'The payment failed due to zip code not matching the card holder information. Please try again.';
    }
    if (errorName === 'Credit Card Number Invalid') {
        error = 'The payment failed due to invalid card number. Please try again.';
    }
    else if (uppercaseResultMessage === 'SERV NOT ALLOWED') {
        error =
            'Only Visa, Mastercard, or Discover cards are accepted. Please try again.';
    }
    else if (ssl_avs_response && ssl_avs_response === 'N') {
        error =
            'The payment failed due to zip code not matching the card holder information. Please try again.';
    }
    else if (uppercaseResultMessage === 'EXPIRED CARD') {
        error =
            'The payment failed due to an invalid expiry date. Please try again.';
    }
    else if (errorMessage) {
        error = `Failed to process payment: ${errorMessage}  Please try again.`;
    }
    else if (ssl_token_response && isDeclined) {
        error = `Failed to process payment: ${ssl_token_response}  Please try again.`;
    }
    else if (isDeclined) {
        logAndCaptureMessage(`Unexpected tokenization decline response from Checkout.js: ${JSON.stringify(response)}`);
        error = 'Failed to process payment. Please try again.';
    }
    return error;
};
