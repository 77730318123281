var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { NoticeType } from 'lib/enums';
import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { placementSelector } from 'redux/placement';
import { isNoticeContentData } from 'lib/types/notice';
import { useAppSelector } from 'redux/hooks';
import Firebase from 'EnoticeFirebase';
import { EraseContentModal } from '../EraseContentModal';
import { getAcceptedFiles, getOriginalFirebaseStoragePath, getOriginalFileName } from '../helpers';
import { UploadMultipleFilesModal } from '../UploadMultipleFilesModal';
export function UploadButton({ allowMultipleDisplayFileUploads, isTypeform, parsing, placementActions, uploadedFileState, setUploadedFileState, newspaper, setInitialEditorState, setMCEKey, mceKey, setShowEraseContentModal, uploadLocation, setUploading, showEraseContentModal, showSubmitFileWithoutFormattingModal, renderSubmitFileWithoutFormattingModal, setIsNoticeFormattingOptionSelected }) {
    var _a, _b;
    const placement = useAppSelector(placementSelector);
    const [showUploadMultipleFilesModal, setShowUploadMultipleFilesModal] = useState(null);
    function eraseContent() {
        placementActions.setDisplayParams(null);
        placementActions.setNoticeText(null);
        placementActions.setFormattingError(null);
        placementActions.setProcessedDisplay(null);
        placementActions.setConfirmedCrop(null);
        placementActions.setUnusedConfirmedHtml(null);
        placementActions.setDisplayUrl(null);
        placementActions.setUnusedDisplay(null);
        setInitialEditorState('');
        setUploadedFileState(null);
        placementActions.setFilesToAttach(null);
        setMCEKey(mceKey + 1);
        placementActions.setPdfStoragePath(null);
        placementActions.resetColumns();
        placementActions.setProofStoragePath(null);
        placementActions.clearFileWithoutProof();
        placementActions.setDesignNotes(null);
        // This needs to turn off because may be user has squashed table content after file uploading
        placementActions.setSquashable(false);
        // Update placement error to default state to avoid showing any waiting modals
        placementActions.setShowPlacementErrors({ wait: false, largeFile: false });
        setIsNoticeFormattingOptionSelected(false);
        // Custom filing notices converted to liners should be assigned correct notice types
        if (placement.noticeType === NoticeType.display_ad.value) {
            if (placement.previousNoticeType &&
                placement.previousNoticeType !== NoticeType.custom.value)
                placementActions.setNoticeType(placement.previousNoticeType);
            else
                placementActions.setNoticeType(NoticeType.custom.value);
        }
        placementActions.saveDraft();
    }
    return (_jsxs("div", { children: [!!(placement.filesToAttach || []).filter(fileToAttach => isNoticeContentData(fileToAttach)).length && (_jsx(_Fragment, { children: placement
                    .filesToAttach.filter(noticeFile => isNoticeContentData(noticeFile))
                    .map(noticeFile => (_jsx(FileUploadListItem, { firebaseStoragePath: getOriginalFirebaseStoragePath(noticeFile), displayFileName: getOriginalFileName(noticeFile), deleteConfirmation: placement.filesToAttach.length > 1
                        ? undefined
                        : () => __awaiter(this, void 0, void 0, function* () {
                            return new Promise(resolve => {
                                setShowEraseContentModal({
                                    confirm: () => resolve(true),
                                    cancel: () => resolve(false)
                                });
                            });
                        }), disableView: parsing, onDelete: () => {
                        var _a, _b;
                        const relatedFileAndRef = (_a = uploadedFileState === null || uploadedFileState === void 0 ? void 0 : uploadedFileState.uploadedFilesAndRefs) === null || _a === void 0 ? void 0 : _a.find(fileAndRef => fileAndRef.uploadRef.name === noticeFile.sanitizedFileName);
                        const newFilesAndSnapshots = (_b = uploadedFileState === null || uploadedFileState === void 0 ? void 0 : uploadedFileState.uploadedFilesAndRefs) === null || _b === void 0 ? void 0 : _b.filter(fileAndRef => fileAndRef.file !== (relatedFileAndRef === null || relatedFileAndRef === void 0 ? void 0 : relatedFileAndRef.file));
                        setUploadedFileState((newFilesAndSnapshots === null || newFilesAndSnapshots === void 0 ? void 0 : newFilesAndSnapshots.length)
                            ? {
                                uploadedFilesAndRefs: newFilesAndSnapshots,
                                userAction: true
                            }
                            : null);
                        if (placement.filesToAttach.length === 1) {
                            eraseContent();
                        }
                    }, disableDelete: parsing, deleteTooltipText: parsing
                        ? 'You cannot delete a file while it is being processed'
                        : '', fileExtensionString: noticeFile.fileFormat || undefined, storage: Firebase.storage() }, `${getOriginalFileName(noticeFile)}-list-item`))) })), !isTypeform && (_jsx("div", { children: _jsx(FileUpload, { id: "notice-content-file-upload", multiple: allowMultipleDisplayFileUploads, disabled: parsing ||
                        (!allowMultipleDisplayFileUploads &&
                            Boolean(placement.filesToAttach && ((_a = placement.filesToAttach) === null || _a === void 0 ? void 0 : _a.length) > 0)), acceptFileTypes: getAcceptedFiles(newspaper), uploadFolder: uploadLocation, uploadConfirmation: ((_b = placement.filesToAttach) === null || _b === void 0 ? void 0 : _b.length) !== 1 ||
                        !!placement.postWithoutFormatting
                        ? undefined
                        : () => __awaiter(this, void 0, void 0, function* () {
                            return new Promise(resolve => {
                                setShowUploadMultipleFilesModal({
                                    confirm: () => resolve(true),
                                    cancel: () => resolve(false)
                                });
                            });
                        }), onFileUpload: (newFilesAndRefs) => __awaiter(this, void 0, void 0, function* () {
                        setUploading(true);
                        placementActions.setNoticeText(null);
                        placementActions.setPdfStoragePath(null);
                        setUploadedFileState({
                            uploadedFilesAndRefs: ((uploadedFileState === null || uploadedFileState === void 0 ? void 0 : uploadedFileState.uploadedFilesAndRefs) || []).concat(newFilesAndRefs),
                            userAction: true
                        });
                    }), storage: Firebase.storage() }) })), showEraseContentModal && (_jsx(EraseContentModal, { setShowEraseContentModal: setShowEraseContentModal, confirm: showEraseContentModal.confirm, cancel: showEraseContentModal.cancel })), showUploadMultipleFilesModal && (_jsx(UploadMultipleFilesModal, { setShowUploadMultipleFilesModal: setShowUploadMultipleFilesModal, confirm: showUploadMultipleFilesModal.confirm, cancel: showUploadMultipleFilesModal.cancel })), showSubmitFileWithoutFormattingModal &&
                renderSubmitFileWithoutFormattingModal()] }));
}
