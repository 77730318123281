import { RoleType } from '../enums';
export var Permissions;
(function (Permissions) {
    /** View and update organization bank accounts */
    Permissions["BANK_ACCOUNTS_WRITE"] = "bank_accounts.write";
    /** Create an invoice from the notice details screen */
    Permissions["INVOICES_CREATE"] = "invoices.create";
    /** Modify oricing on the invoice creation screen */
    Permissions["INVOICES_MODIFY_PRICE"] = "invoices.modify_price";
    /** Mark an invoice as "payment received" from the notice details screen */
    Permissions["INVOICES_MARK_PAID"] = "invoices.mark_paid";
    /** Void an invoice. Does not include the permission to refund! */
    Permissions["INVOICES_VOID"] = "invoices.void";
    /** Refund an invoice. */
    Permissions["INVOICES_REFUND"] = "invoices.refund";
    /** Manually sync a notice from the notice details page */
    Permissions["NOTICES_MANUAL_SYNC"] = "notices.manual_sync";
    /** Invite / delete users to the organization */
    Permissions["ORGANIZATION_MODIFY_MEMBERS"] = "organizations.modify_members";
    /** View the report at /payments */
    Permissions["PAYMENTS_REPORT"] = "payments.report";
    /** View and use the Newspaper/Organization Info settings tab */
    Permissions["SETTINGS_ORGANIZATION_INFO"] = "settings.organization_info";
    /** View and use the Rates settings tab */
    Permissions["SETTINGS_RATES"] = "settings.rates";
    /** View and use the Notice Types settings tab */
    Permissions["SETTINGS_NOTICE_TYPES"] = "settings.notice_types";
    /** View and use the Advertising Deadlines settings tab */
    Permissions["SETTINGS_DEADLINES"] = "settings.deadlines";
    /** View and use the Affidavits settings tab */
    Permissions["SETTINGS_AFFIDAVITS"] = "settings.affidavits";
    /** View and use the Payments settings tab */
    Permissions["SETTINGS_PAYMENT"] = "settings.payment";
    /** View and use the Invoice Reminders settings tab */
    Permissions["SETTINGS_INVOICE_REMINDERS"] = "settings.invoice_reminders";
    /** Modify invoice settings */
    Permissions["SETTINGS_BILLING"] = "settings.billing";
    /** View and use the Customers settings tab */
    Permissions["SETTINGS_CUSTOMERS"] = "settings.customers";
    /** View and use the Bulk Invoices settings tab */
    Permissions["SETTINGS_BULK_INVOICES"] = "settings.bulk_invoices";
    /** View and use the Templates settings tab */
    Permissions["SETTINGS_DESIGN_AND_LAYOUT"] = "settings.design_and_layout";
    /** View and use the Custom Branding settings tab */
    Permissions["SETTINGS_CUSTOM_BRANDING"] = "settings.custom_branding";
    /** Locally override feature flags */
    Permissions["SETTINGS_FEATURE_FLAGS"] = "settings.feature_flags";
    /** Control integrations caches */
    Permissions["SETTINGS_INTEGRATIONS_CACHES"] = "settings.integrations.caches";
    /** Control admin fee properties */
    Permissions["SETTINGS_RATES_ADMIN"] = "settings.rates.admin";
    /** View and use order type settings for Obit and Classifieds */
    Permissions["SETTINGS_ORDER_TYPES"] = "settings.order_types";
    /** Configuration for a particular product's display site  */
    Permissions["SETTINGS_PRODUCT_SITE"] = "settings.product_site";
})(Permissions || (Permissions = {}));
export const ROLE_SUPER = {
    role: RoleType.super.value,
    permissions: [
        Permissions.SETTINGS_CUSTOM_BRANDING,
        Permissions.SETTINGS_FEATURE_FLAGS,
        Permissions.SETTINGS_RATES_ADMIN
    ],
    inheritedRoles: [RoleType.admin.value]
};
export const ROLE_ADMIN = {
    role: RoleType.admin.value,
    permissions: [
        Permissions.NOTICES_MANUAL_SYNC,
        Permissions.ORGANIZATION_MODIFY_MEMBERS,
        Permissions.SETTINGS_ORGANIZATION_INFO,
        Permissions.SETTINGS_AFFIDAVITS,
        Permissions.SETTINGS_DEADLINES,
        Permissions.SETTINGS_DESIGN_AND_LAYOUT,
        Permissions.SETTINGS_NOTICE_TYPES,
        Permissions.SETTINGS_ORDER_TYPES,
        Permissions.SETTINGS_PRODUCT_SITE
    ],
    inheritedRoles: [RoleType.billing.value]
};
export const ROLE_COLUMNIST = {
    role: RoleType.columnist.value,
    permissions: [],
    inheritedRoles: [RoleType.admin.value]
};
export const ROLE_BILLING = {
    role: RoleType.billing.value,
    permissions: [
        Permissions.NOTICES_MANUAL_SYNC,
        Permissions.BANK_ACCOUNTS_WRITE,
        Permissions.INVOICES_CREATE,
        Permissions.INVOICES_MODIFY_PRICE,
        Permissions.INVOICES_MARK_PAID,
        Permissions.INVOICES_VOID,
        Permissions.INVOICES_REFUND,
        Permissions.PAYMENTS_REPORT,
        Permissions.SETTINGS_RATES,
        Permissions.SETTINGS_PAYMENT,
        Permissions.SETTINGS_INVOICE_REMINDERS,
        Permissions.SETTINGS_BILLING,
        Permissions.SETTINGS_CUSTOMERS,
        Permissions.SETTINGS_BULK_INVOICES
    ],
    inheritedRoles: [RoleType.user.value]
};
export const ROLE_RATES = {
    role: RoleType.rates.value,
    permissions: [
        Permissions.NOTICES_MANUAL_SYNC,
        Permissions.SETTINGS_RATES,
        Permissions.SETTINGS_DEADLINES,
        Permissions.SETTINGS_CUSTOMERS
    ],
    inheritedRoles: [RoleType.user.value]
};
export const ROLE_USER = {
    role: RoleType.user.value,
    permissions: [],
    inheritedRoles: []
};
export const ROLE_LEE_BILLING = {
    role: RoleType.lee_billing.value,
    // Generally meant to be the same as normal billing users
    // but without:
    //  - INVOICES_MARK_PAID
    //  - INVOICES_MODIFY_PRICE
    //  - SETTINGS_INVOICES
    permissions: [
        Permissions.BANK_ACCOUNTS_WRITE,
        Permissions.INVOICES_CREATE,
        Permissions.INVOICES_VOID,
        Permissions.INVOICES_REFUND,
        Permissions.NOTICES_MANUAL_SYNC,
        Permissions.PAYMENTS_REPORT,
        Permissions.SETTINGS_RATES,
        Permissions.SETTINGS_PAYMENT,
        Permissions.SETTINGS_INVOICE_REMINDERS,
        Permissions.SETTINGS_CUSTOMERS,
        Permissions.SETTINGS_BULK_INVOICES
    ],
    inheritedRoles: [RoleType.user.value]
};
export const ROLE_EMPTY = {
    role: 0,
    permissions: [],
    inheritedRoles: []
};
const ROLES_MAP = {
    [RoleType.super.value]: ROLE_SUPER,
    [RoleType.columnist.value]: ROLE_COLUMNIST,
    [RoleType.admin.value]: ROLE_ADMIN,
    [RoleType.billing.value]: ROLE_BILLING,
    [RoleType.user.value]: ROLE_USER,
    [RoleType.rates.value]: ROLE_RATES,
    [RoleType.lee_billing.value]: ROLE_LEE_BILLING,
    0: ROLE_EMPTY
};
/**
 * All organizations must have Admin (can do everything) and User (can do nothing) roles
 * available.
 */
export const BASE_ROLES = [RoleType.admin.value, RoleType.user.value];
/**
 * If an advertiser organization does not have any configured 'allowedRoles', this is the
 * set they get.
 */
export const DEFAULT_ALLOWED_ROLES_ADVERTISER = [
    ...BASE_ROLES,
    RoleType.billing.value
];
/**
 * If a publisher organization does not have any configured 'allowedRoles', this is the
 * set they get.
 */
export const DEFAULT_ALLOWED_ROLES_PUBLISHER = [
    ...BASE_ROLES,
    RoleType.billing.value,
    RoleType.rates.value
];
export const roleHasPermission = (role, permission) => {
    var _a;
    const rolePermissions = (_a = ROLES_MAP[role]) !== null && _a !== void 0 ? _a : ROLE_EMPTY;
    // Check if the role has the permission explicitly
    if (rolePermissions.permissions.includes(permission)) {
        return true;
    }
    // Recursively check all inherited roles
    return rolePermissions.inheritedRoles.some(r => roleHasPermission(r, permission));
};
