import { createBrowserHistory } from 'history';
const browserHistory = createBrowserHistory();
/**
 * Track page views with Customer.io. We need to do this manually as Column is a single page app and
 * Customer.io's tracking script is not designed to work with navigation inside of a single page app.
 *
 */
browserHistory.listen(() => {
    var _a;
    (_a = window._cio) === null || _a === void 0 ? void 0 : _a.page(window.location.href);
});
export default browserHistory;
