import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { exists } from 'lib/types';
import { Badge } from 'lib/components/Badge';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
export default function TemplateDrawerHeader({ template }) {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    if (!exists(activeOrganization) || !template) {
        return null;
    }
    const isDefaultTemplate = activeOrganization.data().adTemplate &&
        template.id === ((_a = activeOrganization.data().adTemplate) === null || _a === void 0 ? void 0 : _a.id);
    return (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsx("p", Object.assign({ className: "text-column-gray-500 font-semibold text-xl max-w-md truncate" }, { children: template.data().name })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("a", Object.assign({ className: "text-xs font-medium underline text-column-primary-500", target: "_blank", href: template.data().downloadUrl, rel: "noopener noreferrer" }, { children: "Download IDML" })), isDefaultTemplate && (_jsxs("div", { children: [_jsx("span", { className: "border-l border-column-gray-200 ml-4 pl-4" }), _jsx(Badge, Object.assign({ status: "success" }, { children: "Default Template" }))] }))] }))] })));
}
