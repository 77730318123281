import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import LinkTo from 'components/LinkTo';
const COLUMN_LOGO_URL = 'https://enotice-production.imgix.net/custom-documents/permalink/5138.f87f3-Logo%20wrap.svg';
/**
 * The logo in the top left of the header.
 */
export default function HeaderNavBarLogo() {
    return (_jsx("div", Object.assign({ className: "w-28 md:w-32", style: { flexShrink: 0 } }, { children: _jsx(LinkTo, Object.assign({ href: "/" }, { children: _jsx("img", { className: "w-full", src: COLUMN_LOGO_URL }) })) })));
}
