var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { MagnifyingGlassIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { BuildingIcon } from 'icons';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { TextField } from 'lib/components/TextField';
import SectionDivider from 'routes/placeScroll/SectionDivider';
import classNames from 'classnames';
import LoadingState from 'components/LoadingState';
import { getOrganizationIconColors } from 'components/modals/JoinOrganizationModals/helpers';
import { State } from 'lib/enums';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { fuzzyStringContains } from 'lib/utils/strings';
import { Badge } from 'lib/components/Badge';
import useDebounce from 'lib/frontend/hooks/useDebounce';
export default function OrganizationSearch({ activeOrganization, updatedCustomerData, onChange, disabled }) {
    const [organizationList, setOrganizationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userTypedString, setUserTypedString] = useState('');
    const searchString = useDebounce(userTypedString, 250);
    const [showOptions, setShowOptions] = useState(false);
    const values = updatedCustomerData.customerOrgAddOptions;
    const existingCustomerOrgsQuery = useFirestoreQueryListener(getFirebaseContext()
        .customerOrganizationsRef()
        .where('organization', '==', activeOrganization.ref), [activeOrganization.id]);
    useEffect(() => {
        const getOrganizations = () => __awaiter(this, void 0, void 0, function* () {
            setLoading(true);
            const existingCustomerOrgs = existingCustomerOrgsQuery === null || existingCustomerOrgsQuery === void 0 ? void 0 : existingCustomerOrgsQuery.docs;
            const allOrganizations = yield Promise.all((values || []).map((organization) => __awaiter(this, void 0, void 0, function* () {
                if (existingCustomerOrgs === null || existingCustomerOrgs === void 0 ? void 0 : existingCustomerOrgs.some((x) => x.data().client.id === organization.id))
                    return null;
                const orgSnapshot = yield organization.get();
                if (!exists(orgSnapshot))
                    return null;
                return {
                    organization: {
                        id: orgSnapshot.id,
                        name: orgSnapshot.data().name,
                        state: orgSnapshot.data().state,
                        city: orgSnapshot.data().city
                    },
                    selected: false
                };
            })));
            const filteredOrganizations = allOrganizations.filter((x) => {
                return (x !== null &&
                    (searchString.length === 0 ||
                        fuzzyStringContains(x.organization.name, searchString) ||
                        fuzzyStringContains(x.organization.city, searchString)));
            });
            setOrganizationList(filteredOrganizations);
            setLoading(false);
            setShowOptions(true);
        });
        if (values)
            void getOrganizations();
    }, [searchString, existingCustomerOrgsQuery === null || existingCustomerOrgsQuery === void 0 ? void 0 : existingCustomerOrgsQuery.docs.length]);
    return (_jsxs("div", { children: [_jsxs(Popover, Object.assign({ id: "confirm-filer-step-customer-search", activator: _jsx(OrganizationSearchInput, { value: userTypedString, onChange: setUserTypedString, disabled: disabled }), popoverType: "menu", headerText: "Select one or more organizations", fullWidth: true }, { children: [showOptions && !loading && (_jsx(OrganizationSearchOptionsList, { organizationList: organizationList, setOrganizationList: setOrganizationList, onChange: onChange, updatedCustomerData: updatedCustomerData })), showOptions && loading && _jsx(LoadingState, {})] })), _jsx("div", Object.assign({ className: "flex mt-2" }, { children: organizationList.map((item, index) => item.selected && (_jsx("button", Object.assign({ id: `ask-join-org-${index}`, onClick: () => {
                        var _a;
                        const organizationListCopy = [...organizationList];
                        organizationListCopy[index] = Object.assign(Object.assign({}, item), { selected: false });
                        setOrganizationList(organizationListCopy);
                        const newList = (_a = updatedCustomerData.organizationsToAdd) === null || _a === void 0 ? void 0 : _a.filter(x => x.id !== item.organization.id);
                        onChange(newList || []);
                    } }, { children: _jsx(Badge, Object.assign({ status: "info", endIcon: _jsx(XMarkIcon, { className: "w-4 h-4" }), size: "md" }, { children: item.organization.name })) })))) }))] }));
}
function OrganizationSearchInput({ value, onChange, disabled }) {
    const { setOpen } = useContext(PopoverContext);
    return (_jsx(TextField, { id: "confirm-filer-step-customer-search-bar", labelText: "Select which organizations you'd like to add from this customer's organization list.", value: value, onChange: onChange, onClick: () => setOpen(true), placeholder: "Select one or more organizations", autoComplete: "off", disabled: disabled, suffix: _jsx(MagnifyingGlassIcon, { strokeWidth: "2", className: "h-5 w-5 text-column-gray-400" }) }));
}
function OrganizationSearchOptionsList({ organizationList, setOrganizationList, updatedCustomerData, onChange }) {
    return (_jsx("div", Object.assign({ className: "max-h-64 pt-2 overflow-y-scroll" }, { children: organizationList.map((item, index) => (_jsxs("div", Object.assign({ className: "hover:bg-column-gray-100" }, { children: [index !== 0 && (_jsx("div", Object.assign({ className: "pb-2 px-1" }, { children: _jsx(SectionDivider, {}) }))), _jsx("div", Object.assign({ className: classNames('pb-2 px-3', {
                        'pt-2': index === 0
                    }) }, { children: _jsx("div", { children: _jsx(OrganizationListItem, { item: item, onItemSelected: (item) => {
                                var _a;
                                const organizationListCopy = [...organizationList];
                                organizationListCopy[index] = Object.assign(Object.assign({}, item), { selected: true });
                                setOrganizationList(organizationListCopy);
                                const newList = (_a = updatedCustomerData.organizationsToAdd) === null || _a === void 0 ? void 0 : _a.concat([
                                    getFirebaseContext()
                                        .organizationsRef()
                                        .doc(item.organization.id)
                                ]);
                                onChange(newList || []);
                            }, index: index }) }) }), `search-item-${item.organization.id}`)] }), item.organization.id))) })));
}
function OrganizationListItem({ item, onItemSelected, index }) {
    var _a;
    const { organization } = item;
    const iconStyles = getOrganizationIconColors(index);
    return (_jsxs("div", Object.assign({ className: "flex justify-between items-center py-2 px-2" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: `h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}` }, { children: _jsx(BuildingIcon, { className: "m-auto transform translate-y-2", stroke: iconStyles.stroke }) })), _jsxs("div", Object.assign({ className: "pl-2" }, { children: [_jsx("p", Object.assign({ className: "font-medium\ttext-sm\ttext-column-gray-500" }, { children: organization.name })), _jsx("p", Object.assign({ className: "text-column-gray-400 text-sm leading-400" }, { children: `${(_a = State.by_value(organization.state)) === null || _a === void 0 ? void 0 : _a.label}, ${organization.city}` }))] }))] })), _jsx("div", { children: item.selected ? (_jsx(Badge, Object.assign({ status: "success", shape: "pill", size: "md", endIcon: _jsx(CheckIcon, { className: "w-4 h-4" }) }, { children: _jsx("p", { children: "Selected" }) }))) : (_jsx("button", Object.assign({ id: `ask-join-org-${index}`, onClick: () => {
                        onItemSelected(item);
                    } }, { children: _jsx(Badge, Object.assign({ status: "info", shape: "rectangle", size: "md" }, { children: "Add" })) }))) })] })));
}
