var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { exists } from 'lib/types';
import { awaitAllPromises, isDefined, shouldInitializeLD } from 'lib/helpers';
import { logAndCaptureException } from 'utils';
import { State } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { ENV, PROD, DEMO, TEST } from '../constants';
const PROD_CLIENT_SIDE_ID = '627ea6452e2c5315224da77b';
const TEST_CLIENT_SIDE_ID = '627ea6452e2c5315224da77a';
const DEMO_CLIENT_SIDE_ID = '629e6fc9b2fb3f1544ef101a';
const DEV_CLIENT_SIDE_ID = '62e8f1cfbd13ad10c0ad99ad';
const ANONYMOUS = { anonymous: true };
let _CLIENT;
/**
 * See: https://docs.launchdarkly.com/sdk/concepts/client-side-server-side#client-side-id
 */
const getClientSideId = () => {
    switch (ENV) {
        case PROD:
            return PROD_CLIENT_SIDE_ID;
        case DEMO:
            return DEMO_CLIENT_SIDE_ID;
        case TEST:
            return TEST_CLIENT_SIDE_ID;
        default:
            return DEV_CLIENT_SIDE_ID;
    }
};
/**
 * Get the LaunchDarkly client singleton.
 */
const getClient = (initialUser = ANONYMOUS) => {
    if (!_CLIENT) {
        _CLIENT = LDClient.initialize(getClientSideId(), initialUser, {
            inspectors: [
                {
                    type: 'flag-used',
                    name: 'dd-inspector',
                    method: (key, detail) => {
                        datadogRum.addFeatureFlagEvaluation(key, detail.value);
                    }
                }
            ]
        });
    }
    return _CLIENT;
};
/**
 * Get the value of a boolean feature flag.
 */
export const getBooleanFlag = (flag, defaultValue = false) => {
    const override = getBooleanFlagOverride(flag);
    if (typeof override === 'boolean') {
        return override;
    }
    if (!shouldInitializeLD(ENV))
        return defaultValue;
    return getClient().variation(flag, defaultValue);
};
/**
 * Subscribe to changes to a single boolean feature flag.
 * Returns a function which can be invoked to unsubscrube.
 */
export const subscribeToBooleanFlag = (flag, callback) => {
    if (!shouldInitializeLD(ENV))
        return () => { };
    const client = getClient();
    const key = `change:${flag}`;
    client.on('ready', () => {
        const value = client.variation(flag);
        callback(value);
    });
    client.on(key, callback);
    return () => {
        client.off(key, callback);
    };
};
/**
 * Custom hook to use the value of a feature flag, with live updates.
 */
export const useBooleanFlag = (flag, defaultValue = false) => {
    const [val, setVal] = useState(getBooleanFlag(flag, defaultValue));
    useEffect(() => {
        return subscribeToBooleanFlag(flag, setVal);
    }, [flag]);
    const override = getBooleanFlagOverride(flag);
    if (typeof override === 'boolean') {
        return override;
    }
    return val;
};
// Allow for state-level targeting on the user level.
// For readability, uses the two letter lowercase state abbreviation
// instead of the enum value. See State.ts for more
// Example output: ['ks', 'ny']
export const getUserStates = (user) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const uniqueStates = new Set();
    const { state, allowedOrganizations } = user.data();
    if (state) {
        uniqueStates.add((_a = State.by_value(state)) === null || _a === void 0 ? void 0 : _a.abbrev);
    }
    if (allowedOrganizations === null || allowedOrganizations === void 0 ? void 0 : allowedOrganizations.length) {
        yield awaitAllPromises(allowedOrganizations.map((allowedOrg) => __awaiter(void 0, void 0, void 0, function* () {
            var _b, _c;
            const org = yield allowedOrg.get();
            if (exists(org) && org.data().state) {
                uniqueStates.add((_c = State.by_value((_b = org.data()) === null || _b === void 0 ? void 0 : _b.state)) === null || _c === void 0 ? void 0 : _c.abbrev);
            }
        })));
    }
    return [...uniqueStates].filter(isDefined);
});
/**
 * Set the current user and wait for the client to be ready.
 */
export const setUser = (user) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    try {
        if (!shouldInitializeLD(ENV, user))
            return;
        const ldUser = exists(user)
            ? {
                key: user.id,
                email: user.data().email,
                custom: {
                    activeOrganization: ((_d = user.data().activeOrganization) === null || _d === void 0 ? void 0 : _d.id) || '',
                    occupation: user.data().occupation,
                    states: yield getUserStates(user)
                }
            }
            : ANONYMOUS;
        const client = getClient(ldUser);
        yield client.waitUntilReady();
        const flagSet = yield client.identify(ldUser);
        console.log('Feature Flags', flagSet);
    }
    catch (err) {
        logAndCaptureException(ColumnService.LAUNCH_DARKLY_SERVICE, err, 'Failed to set launchDarkly user', {
            userId: (user === null || user === void 0 ? void 0 : user.id) || '',
            environment: ENV
        });
    }
});
export const getAllFeatureFlags = () => {
    if (!shouldInitializeLD(ENV))
        return {};
    return getClient().allFlags();
};
const getOverrideKey = (key) => {
    return `flagoverride.${key}`;
};
export const clearAllBooleanFlagOverrides = () => {
    const keys = Object.keys(window.sessionStorage);
    const overrides = keys.filter(k => k.indexOf('flagoverride.') === 0);
    for (const o of overrides) {
        window.sessionStorage.removeItem(o);
    }
};
export const getBooleanFlagOverride = (key) => {
    try {
        const val = window.sessionStorage.getItem(getOverrideKey(key));
        if (val === null) {
            return undefined;
        }
        return val === 'true';
    }
    catch (e) {
        // Just in case there are any SessionStorage issues on some devices
        return undefined;
    }
};
export const setBooleanFlagOverride = (key, value) => {
    if (value === undefined) {
        window.sessionStorage.removeItem(getOverrideKey(key));
    }
    else {
        window.sessionStorage.setItem(getOverrideKey(key), `${value}`);
    }
};
