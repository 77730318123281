var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { ColumnButton } from '../ColumnButton';
/** @deprecated Use Modal instead */
export function CancelOrSubmitModal({ onClose, header, body, children, id, noExitOutsideModal, overrideExitOutsideModal, onSubmit, width, maxWidth, disableOverflow, disablePrimaryButton, disableSecondaryButton, destructive, formId, tertiaryButtonText, secondaryButtonText, primaryButtonText, primaryButtonId, overrideTertiaryClose, showLoadingSpinner, backgroundStyle, isParentLoading, loadingMessage, overrideChildMargins, overrideEntablaturePadding, overrideFooterButtonsAlignment, onSecondaryButtonClick, maxHeightOverride }) {
    const DEFAULT_MAX_HEIGHT = '550px';
    const [loading, setLoading] = useState(false);
    const handleExit = () => __awaiter(this, void 0, void 0, function* () {
        if (noExitOutsideModal)
            return;
        if (overrideExitOutsideModal) {
            yield overrideExitOutsideModal();
        }
        else {
            onClose && onClose();
        }
    });
    const handleClick = (e) => {
        // Don't propagate clicks to parent components. If we don't want to always do this, add a prop to the component.
        e.stopPropagation();
    };
    return (_jsx("div", Object.assign({ id: id || 'cancel-or-submit-modal', className: `fixed z-modal inset-0 ${!disableOverflow ? 'overflow-hidden' : ''}`, onClick: handleClick }, { children: _jsxs("div", Object.assign({ className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" }, { children: [_jsx("div", Object.assign({ className: "fixed inset-0 transition-opacity", onClick: handleExit }, { children: _jsx("div", { className: "absolute inset-0 bg-black opacity-50" }) })), _jsx("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }), _jsxs("div", Object.assign({ className: `inline-block align-bottom ${backgroundStyle || 'bg-white'} rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth || 'sm:max-w-lg'} ${!!width && width} sm:w-full`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: `${id || 'cancel-or-submit-modal'}-inner` }, { children: [_jsxs("div", Object.assign({ className: `${overrideEntablaturePadding || 'p-6'} border-gray-300 ${(header || body) && 'border-b'}` }, { children: [header && (_jsx("h3", Object.assign({ className: "text-xl leading-6 font-semibold text-gray-900", id: "modal-headline" }, { children: header }))), body && (_jsx("div", Object.assign({ className: "mt-2" }, { children: _jsx("span", Object.assign({ className: "text-sm font-medium leading-5 text-gray-700" }, { children: body })) })))] })), _jsx("div", Object.assign({ className: "overflow-scroll hide-scrollbar" }, { children: _jsxs("div", Object.assign({ className: `${overrideChildMargins || 'mx-6'} sm:mt-0 text-left`, style: {
                                    maxHeight: maxHeightOverride !== null && maxHeightOverride !== void 0 ? maxHeightOverride : DEFAULT_MAX_HEIGHT
                                } }, { children: [children, loading ? _jsxs("div", { children: [" ", loadingMessage, " "] }) : undefined] })) })), _jsxs("div", Object.assign({ className: classNames('flex py-5 px-6 border-t border-gray-300 gap-4', {
                                'justify-center': !(tertiaryButtonText || secondaryButtonText) &&
                                    !overrideFooterButtonsAlignment,
                                'justify-end': overrideFooterButtonsAlignment === 'end',
                                'justify-start': overrideFooterButtonsAlignment === 'start'
                            }) }, { children: [tertiaryButtonText && (_jsx(ColumnButton, { size: "lg", buttonText: tertiaryButtonText, disabled: loading || isParentLoading, onClick: () => {
                                        if (overrideTertiaryClose) {
                                            void overrideTertiaryClose();
                                        }
                                        else {
                                            onClose();
                                        }
                                    }, type: "button" })), secondaryButtonText && (_jsx("div", Object.assign({ className: "ml-auto" }, { children: _jsx(ColumnButton, { size: "lg", secondary: true, buttonText: secondaryButtonText, disabled: loading || isParentLoading || disableSecondaryButton, onClick: () => {
                                            onSecondaryButtonClick && onSecondaryButtonClick();
                                        }, type: "button" }) }))), _jsx("div", Object.assign({ className: classNames({
                                        'ml-auto': tertiaryButtonText && !secondaryButtonText
                                    }) }, { children: _jsx(ColumnButton, { id: primaryButtonId, formId: formId, primary: true, destructive: destructive, size: "lg", disabled: disablePrimaryButton, buttonText: primaryButtonText, loading: showLoadingSpinner ? loading || isParentLoading : undefined, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                            setLoading(true);
                                            onSubmit && (yield onSubmit());
                                            setLoading(false);
                                        }), type: "submit" }) }))] }))] }))] })) })));
}
