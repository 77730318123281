import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State } from 'lib/enums';
import React from 'react';
import { GridInput } from 'lib/components/Card/Grid';
export function AffidavitMailingCard({ value: mailingAddress, onChange, index }) {
    var _a;
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: `mailing-name-${index}`, labelText: "Name", value: mailingAddress.name, required: true, onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, mailingAddress), { name: newValue }));
                    } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: `mailing-copies-${index}`, labelText: "Copies", type: "number", value: (_a = mailingAddress.copies) === null || _a === void 0 ? void 0 : _a.toString(), min: 1, max: 10, step: 1, required: true, onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, mailingAddress), { copies: parseInt(newValue, 10) }));
                    } }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: `mailing-address-${index}`, labelText: "Address Line 1", value: mailingAddress.address.address_line1, required: true, onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, mailingAddress), { address: Object.assign(Object.assign({}, mailingAddress.address), { address_line1: newValue }) }));
                    } }) })), _jsx(GridInput, { children: _jsx(TextField, { id: `mailing-address2-${index}`, labelText: "Address Line 2", value: mailingAddress.address.address_line2, onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, mailingAddress), { address: Object.assign(Object.assign({}, mailingAddress.address), { address_line2: newValue }) }));
                    } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: `mailing-city-${index}`, labelText: "City", required: true, value: mailingAddress.address.address_city, onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, mailingAddress), { address: Object.assign(Object.assign({}, mailingAddress.address), { address_city: newValue }) }));
                    } }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: `state-${index}`, labelText: "State", required: true, value: mailingAddress.address.address_state.toString(), options: State.items().map(stateItem => ({
                        value: stateItem.value.toString(),
                        label: stateItem.label
                    })), onChange: newValue => onChange(Object.assign(Object.assign({}, mailingAddress), { address: Object.assign(Object.assign({}, mailingAddress.address), { address_state: parseInt(newValue, 10) }) })) }) }), _jsx(GridInput, { children: _jsx(TextField, { id: `mailing-zip-${index}`, labelText: "Zip Code", type: "postal-code", required: true, value: mailingAddress.address.address_zip, onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, mailingAddress), { address: Object.assign(Object.assign({}, mailingAddress.address), { address_zip: newValue }) }));
                    } }) })] }));
}
