import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { InvoiceType } from 'lib/types/invoices';
import SavedPaymentMethodButton from './buttons/SavedPaymentMethodButton';
import LinkBankAccountButton from './buttons/LinkBankAccountButton';
import { getPreventLatePaymentValue } from '../helpers/preventLatePayment';
const styles = () => createStyles({
    gotoText: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#697386' // gives the go to my text a dark gray color
    }
});
function SavedPaymentMethods({ payInvoiceData, userPaymentMethods, savedPaymentsAccess, user, selectedSavedMethodId, selectPaymentClickHandler, setShowLoginModal, classes }) {
    var _a;
    const isOrder = payInvoiceData.type === InvoiceType.ORDER;
    const { disableSavedCards, disableACH, activeOrganizationMismatch } = savedPaymentsAccess;
    let billingName = '';
    const preventLatePayment = getPreventLatePaymentValue(payInvoiceData);
    if (payInvoiceData.type === InvoiceType.PUBLIC_NOTICE) {
        billingName = (_a = payInvoiceData.billingName) !== null && _a !== void 0 ? _a : '';
    }
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: disableACH
                    ? `Linked bank accounts can only be used to pay for notices within your organization.`
                    : '' }, { children: _jsx(Box, Object.assign({ style: !disableACH
                        ? {
                            opacity: '100%',
                            cursor: 'pointer',
                            marginTop: '8px'
                        }
                        : {
                            opacity: '35%',
                            cursor: 'not-allowed',
                            marginTop: '8px'
                        } }, { children: !disableACH &&
                        (userPaymentMethods === null || userPaymentMethods === void 0 ? void 0 : userPaymentMethods.bankAccounts.map((bankAccount, index) => bankAccount.status === 'verified' && (_jsx(SavedPaymentMethodButton, { paymentMethodBrand: bankAccount.bank_name, paymentMethodId: bankAccount.id, paymentMethodLast4: bankAccount.last4, paymentMethodType: 'saved-ach', isSelected: bankAccount.id === selectedSavedMethodId, clickHandler: selectPaymentClickHandler }, index)))) })) })), (!userPaymentMethods || userPaymentMethods.bankAccounts.length === 0) &&
                !disableACH && (_jsx(LinkBankAccountButton, { setShowLoginModal: setShowLoginModal, disabled: preventLatePayment, isLoggedIn: !!user })), _jsx(Box, Object.assign({ style: {
                    opacity: '100%',
                    cursor: 'pointer',
                    marginTop: '8px'
                } }, { children: !disableACH &&
                    (userPaymentMethods === null || userPaymentMethods === void 0 ? void 0 : userPaymentMethods.bankAccountsPaymentMethods.map((bankAccount, index) => (_jsx(SavedPaymentMethodButton, { paymentMethodBrand: bankAccount.us_bank_account.bank_name, paymentMethodId: bankAccount.id, paymentMethodLast4: bankAccount.us_bank_account.last4, paymentMethodType: 'saved-bank', isSelected: bankAccount.id === selectedSavedMethodId, clickHandler: selectPaymentClickHandler }, index)))) })), _jsx(Box, Object.assign({ style: { opacity: '100%', cursor: 'pointer', marginTop: '8px' } }, { children: !disableSavedCards &&
                    (userPaymentMethods === null || userPaymentMethods === void 0 ? void 0 : userPaymentMethods.cards.map((creditCard, index) => (_jsx(SavedPaymentMethodButton, { paymentMethodBrand: creditCard.card.brand, paymentMethodId: creditCard.id, paymentMethodLast4: creditCard.card.last4, paymentMethodType: 'saved-card', isSelected: creditCard.id === selectedSavedMethodId, clickHandler: selectPaymentClickHandler }, index)))) })), !disableACH && activeOrganizationMismatch && (_jsx(Box, Object.assign({ mt: 2, mx: 2, className: "text-center" }, { children: _jsx(Typography, Object.assign({ variant: "caption", align: "center", color: "textSecondary" }, { children: _jsxs("span", { children: ["Don't see your bank accounts? Log into \"", billingName, "\" to pay via ACH."] }) })) }))), user && !disableACH && !isOrder && (_jsxs(Box, Object.assign({ className: classes.gotoText, mt: 1 }, { children: ["Go to my", _jsx("span", Object.assign({ style: { color: '#5469D4', cursor: 'pointer' }, onClick: () => window.open(`${window.location.origin}/settings/?tab=bank-accounts`) }, { children: ` Payment Methods.` }))] })))] }));
}
export default withStyles(styles)(SavedPaymentMethods);
