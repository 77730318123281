import BigNumber from 'bignumber.js';
export const isRateShared = (rate) => {
    var _a;
    return !!((_a = rate.publisherOrganizations) === null || _a === void 0 ? void 0 : _a.length);
};
/**
 * Converts cents to a string with 4 decimal places to support fractional cents.
 * Uses BigNumber to avoid floating point errors (ex. 120.32 / 100 = 1.2031999999999998)
 * Uses a custom BigNumber instance to avoid changing the global BigNumber instance.
 * @param cents
 * @returns {string} string with up to 4 decimal places
 */
export function centsToExtendedCurrency(cents) {
    const DECIMAL_PLACES = 4;
    const BNExtendedCurrency = BigNumber.clone({ DECIMAL_PLACES });
    let fourDecimalResult = new BNExtendedCurrency(cents)
        .dividedBy(100)
        .toFixed(DECIMAL_PLACES);
    // we support up to 4 decimal places, but we don't want to show trailing zeroes after the first two decimal places
    for (let i = 0; i < DECIMAL_PLACES - 2; i++) {
        if (fourDecimalResult.endsWith('0')) {
            fourDecimalResult = fourDecimalResult.slice(0, -1);
        }
    }
    return fourDecimalResult;
}
/**
 * Converts currency string to cents.
 * Uses BigNumber to avoid floating point errors (ex. 5.1 * 100 = 509.99999999999994)
 * @param currency
 * @returns {number} number of cents
 */
export function currencyToCents(currency) {
    return new BigNumber(currency).multipliedBy(100).toNumber();
}
