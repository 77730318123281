import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { isFlatAdditionalFee, isPercentAdditionalFee } from 'lib/types/rates';
import { Alert } from 'lib/components/Alert';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { Product } from 'lib/enums';
import { getSettingsProductFromLocationParams } from 'routes/settings/publisher/utils';
import { getAdditionalFeeChargeMethod, getAdditionalFeeChargeMethodSelectOption, isValidChargeMethod } from '../../../ratesUtils';
const getFlatAdditionalFeeChargeOptions = (product) => {
    const options = [
        getAdditionalFeeChargeMethodSelectOption('once'),
        getAdditionalFeeChargeMethodSelectOption('perRun')
    ];
    if (product === Product.Notice) {
        options.push(getAdditionalFeeChargeMethodSelectOption('perMailedAffidavit'));
    }
    return options;
};
export default function AdditionalFeeElement({ onChange, value, index }) {
    const product = getSettingsProductFromLocationParams();
    const additionalFee = value;
    const isOffsetFee = isFlatAdditionalFee(additionalFee) && additionalFee.isOffsetFee;
    return (_jsxs(_Fragment, { children: [isOffsetFee && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(Alert, { id: "offset-fee-warning", title: `This rate uses a "flat fee with open rate structure". This fee is used to charge a starting price for this rate; you can change the description here and amount below under Advanced Settings.`, icon: _jsx(InformationCircleIcon, { className: "w-5 h-5" }), status: "warning" }) }))), _jsx(GridInput, Object.assign({ fullWidth: isOffsetFee }, { children: _jsx(TextField, { id: `additional-fee-description-${index}`, labelText: "Fee description (this will appear on invoices)", type: "text", onChange: description => onChange(Object.assign(Object.assign({}, additionalFee), { description })), value: additionalFee.description }) })), isFlatAdditionalFee(additionalFee) && !isOffsetFee && (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(ColumnSelect, { id: `additional-fee-charge-method-${index}`, labelText: "How do you charge this fee?", onChange: value => {
                                if (!isValidChargeMethod(value)) {
                                    return;
                                }
                                if (value === 'once') {
                                    onChange(Object.assign(Object.assign({}, additionalFee), { perRun: false, perAffidavitFee: false }));
                                }
                                else if (value === 'perRun') {
                                    onChange(Object.assign(Object.assign({}, additionalFee), { perRun: true, perAffidavitFee: false }));
                                }
                                else if (value === 'perMailedAffidavit') {
                                    onChange(Object.assign(Object.assign({}, additionalFee), { perRun: false, perAffidavitFee: true }));
                                }
                            }, value: getAdditionalFeeChargeMethod(additionalFee), options: getFlatAdditionalFeeChargeOptions(product) }) }), _jsx(GridInput, { children: _jsx(CurrencyTextField, { id: `additional-fee-amount-${index}`, labelText: "Fee amount", initialValue: additionalFee.amount, onChange: amount => onChange(Object.assign(Object.assign({}, additionalFee), { amount })) }) })] })), isPercentAdditionalFee(additionalFee) && (_jsx(GridInput, { children: _jsx(TextField, { id: `additional-fee-percentage-${index}`, labelText: "Set fee percentage", type: "number", step: "0.5", value: `${additionalFee.feePercentage}`, suffix: "%", onChange: feePercentage => onChange(Object.assign(Object.assign({}, additionalFee), { feePercentage: parseFloat(feePercentage) })) }) }))] }));
}
