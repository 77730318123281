import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getWordsFromNumber } from 'lib/helpers';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { exists } from 'lib/types';
import { userIsSuper } from 'utils/permissions';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getColumnRangeConfigForPublisher } from 'lib/notice/columns';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { MADLIB_ID_PATTERN, TYPEFORM_ID_PATTERN, getEntryMethodFromNoticeType, getOrganizationDefaultColumnDescription } from '../noticeTypesHelpers';
/**
 * Converts a set of templates into a set of options for a select input
 */
const templatesToTemplateSelectOptions = (templates) => {
    const templateOptions = [
        {
            label: 'Default template',
            value: 'default'
        }
    ];
    templates.forEach(template => {
        templateOptions.push({
            label: template.data().name,
            value: template.id
        });
    });
    return templateOptions;
};
const booleanToString = (val, trueString, falseString, undefinedString) => {
    if (val === true) {
        return trueString;
    }
    if (val === false) {
        return falseString;
    }
    return undefinedString;
};
export default function NoticeFormatCard({ activeOrganization, onUpdateNoticeType, updatedNoticeType, templates }) {
    var _a, _b, _c, _d, _e, _f;
    const user = useAppSelector(selectUser);
    const [entryMethod, setEntryMethod] = useState(getEntryMethodFromNoticeType(updatedNoticeType));
    const linerPublisherColumnConfig = getColumnRangeConfigForPublisher(activeOrganization, false);
    const displayPublisherColumnConfig = getColumnRangeConfigForPublisher(activeOrganization, true);
    const linerColumnOptions = getColumnRangeOptions(linerPublisherColumnConfig);
    const displayColumnOptions = getColumnRangeOptions(displayPublisherColumnConfig);
    const { allowImagesInLiners: organizationAllowImagesInliners } = activeOrganization.data();
    // TODO: Show warning messages for conflicting min, max and default column settings
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Notice Format',
            description: 'Configure special formatting settings for this notice type.'
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "notice-type-template-select", labelText: "InDesign template", options: templatesToTemplateSelectOptions(templates), value: ((_a = updatedNoticeType.template) === null || _a === void 0 ? void 0 : _a.id) || 'default', onChange: newValue => {
                        var _a;
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (newValue !== 'default') {
                            newNoticeType.template = (_a = templates.find(r => r.id === newValue)) === null || _a === void 0 ? void 0 : _a.ref;
                        }
                        else {
                            delete newNoticeType.template;
                        }
                        onUpdateNoticeType(newNoticeType);
                    } }) })), _jsx(ColumnSelect, { id: "min-liner-cols", labelText: "Minimum column width for liner ads", value: ((_b = updatedNoticeType.linerMinColumns) === null || _b === void 0 ? void 0 : _b.toString()) || 'none', options: [
                    {
                        value: 'none',
                        label: `Organization default (${getWordsFromNumber(linerPublisherColumnConfig.minColumns)})`
                    },
                    ...linerColumnOptions
                ], onChange: newValue => {
                    const newNoticeType = Object.assign({}, updatedNoticeType);
                    if (newValue === 'none') {
                        delete newNoticeType.linerMinColumns;
                    }
                    else {
                        newNoticeType.linerMinColumns = Number(newValue);
                    }
                    onUpdateNoticeType(newNoticeType);
                } }), _jsx(ColumnSelect, { id: "max-liner-cols", labelText: "Maximum column width for liner ads", value: ((_c = updatedNoticeType.linerMaxColumns) === null || _c === void 0 ? void 0 : _c.toString()) || 'none', options: [
                    {
                        value: 'none',
                        label: `Organization default (${getWordsFromNumber(linerPublisherColumnConfig.maxColumns)})`
                    },
                    ...linerColumnOptions
                ], onChange: newValue => {
                    const newNoticeType = Object.assign({}, updatedNoticeType);
                    if (newValue === 'none') {
                        delete newNoticeType.linerMaxColumns;
                    }
                    else {
                        newNoticeType.linerMaxColumns = Number(newValue);
                    }
                    onUpdateNoticeType(newNoticeType);
                } }), _jsx(ColumnSelect, { id: "min-display-cols", labelText: "Minimum column width for display ads", value: ((_d = updatedNoticeType.displayMinColumns) === null || _d === void 0 ? void 0 : _d.toString()) || 'none', options: [
                    {
                        value: 'none',
                        label: `Organization default (${getWordsFromNumber(displayPublisherColumnConfig.minColumns)})`
                    },
                    ...displayColumnOptions
                ], onChange: newValue => {
                    const newNoticeType = Object.assign({}, updatedNoticeType);
                    if (newValue === 'none') {
                        delete newNoticeType.displayMinColumns;
                    }
                    else {
                        newNoticeType.displayMinColumns = Number(newValue);
                    }
                    onUpdateNoticeType(newNoticeType);
                } }), _jsx(ColumnSelect, { id: "max-display-cols", labelText: "Maximum column width for display ads", value: ((_e = updatedNoticeType.displayMaxColumns) === null || _e === void 0 ? void 0 : _e.toString()) || 'none', options: [
                    {
                        value: 'none',
                        label: `Organization default (${getWordsFromNumber(displayPublisherColumnConfig.maxColumns)})`
                    },
                    ...displayColumnOptions
                ], onChange: newValue => {
                    const newNoticeType = Object.assign({}, updatedNoticeType);
                    if (newValue === 'none') {
                        delete newNoticeType.displayMaxColumns;
                    }
                    else {
                        newNoticeType.displayMaxColumns = Number(newValue);
                    }
                    onUpdateNoticeType(newNoticeType);
                } }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "notice-type-columns", labelText: "Default number of columns", noteText: "If set, notices of this type will default to the specified number of columns and be editable within the range specified above.", options: [
                        {
                            value: 'none',
                            label: getOrganizationDefaultColumnDescription(activeOrganization)
                        },
                        ...linerColumnOptions
                    ], value: ((_f = updatedNoticeType.defaultColumns) === null || _f === void 0 ? void 0 : _f.toString()) || 'none', onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (newValue === 'none') {
                            delete newNoticeType.defaultColumns;
                        }
                        else {
                            newNoticeType.defaultColumns = Number(newValue);
                        }
                        onUpdateNoticeType(newNoticeType);
                    } }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "notice-type-images-in-liners", labelText: "Allow images in liners", noteText: "If set, notices of this type will allow images and text.", options: [
                        {
                            value: 'default',
                            label: `Organization default (${booleanToString(organizationAllowImagesInliners, 'Yes', 'No', 
                            // TODO: If we change images in liners in the future to be
                            // available to all publishers we will want to update this
                            // wording. Right now unset === No.
                            'No')})`
                        },
                        {
                            value: 'true',
                            label: 'Yes'
                        },
                        {
                            value: 'false',
                            label: 'No'
                        }
                    ], value: booleanToString(updatedNoticeType.allowImagesInLiners, 'true', 'false', 'default'), onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (newValue === 'default') {
                            delete newNoticeType.allowImagesInLiners;
                        }
                        else {
                            newNoticeType.allowImagesInLiners = newValue === 'true';
                        }
                        onUpdateNoticeType(newNoticeType);
                    } }) }), exists(user) && userIsSuper(user) && (_jsxs(_Fragment, { children: [_jsx(GridInput, Object.assign({ fullWidth: entryMethod === 'text' }, { children: _jsx(ColumnSelect, { id: "notice-type-entry-method", labelText: "Content creation method", options: [
                                {
                                    value: 'text',
                                    label: 'Enter text or upload file using default text editor'
                                },
                                {
                                    value: 'typeform',
                                    label: 'Complete questionnaire and auto-generate results (Typeform)'
                                },
                                {
                                    value: 'madlib',
                                    label: 'Complete fill-in-the-blank template (Madlib)'
                                }
                            ], value: entryMethod, onChange: newValue => {
                                const newNoticeType = Object.assign({}, updatedNoticeType);
                                if (newValue === 'text') {
                                    delete newNoticeType.typeform;
                                    delete newNoticeType.madlib;
                                    setEntryMethod('text');
                                }
                                else if (newValue === 'typeform') {
                                    delete newNoticeType.madlib;
                                    setEntryMethod('typeform');
                                }
                                else if (newValue === 'madlib') {
                                    setEntryMethod('madlib');
                                }
                                onUpdateNoticeType(newNoticeType);
                            } }) })), entryMethod === 'typeform' && (_jsx(GridInput, { children: _jsx(TextField, { id: "notice-type-typeform-id", value: updatedNoticeType.typeform || '', labelText: "Typeform ID", pattern: TYPEFORM_ID_PATTERN, validationMessages: {
                                patternMismatch: 'Please input a valid typeform ID.',
                                valueMissing: 'Please enter a typeform ID.'
                            }, required: true, onChange: newValue => {
                                onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { typeform: newValue }));
                            }, placeholder: "typeformID" }) })), entryMethod === 'madlib' && (_jsx(GridInput, { children: _jsx(TextField, { id: "notice-type-madlib-id", value: updatedNoticeType.madlib || '', labelText: "Madlib storage path", pattern: MADLIB_ID_PATTERN, validationMessages: {
                                patternMismatch: 'Please input a valid madlib config.',
                                valueMissing: 'Please enter a path to a madlib config.'
                            }, required: true, onChange: newValue => {
                                onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { madlib: newValue }));
                            }, placeholder: "madlibs/publisher/folder" }) }))] }))] })));
}
function getColumnRangeOptions(columnConfig) {
    const options = [];
    for (let i = columnConfig.minColumns; i <= columnConfig.maxColumns; i++) {
        options.push({
            value: `${i}`,
            label: `${getWordsFromNumber(i)}`
        });
    }
    return options;
}
