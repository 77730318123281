import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectContextOrganizationName, selectIsOnSubdomain } from 'redux/auth';
import { Helmet } from 'react-helmet';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
function useOrganizationName() {
    var _a;
    const orgContextName = useAppSelector(selectContextOrganizationName);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const isOnSubdomain = useAppSelector(selectIsOnSubdomain);
    const organizationName = isOnSubdomain
        ? orgContextName
        : (_a = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.name;
    return organizationName;
}
export default function Header({ productLine }) {
    const organizationName = useOrganizationName();
    const productName = PRODUCT_TO_NAME[productLine];
    return (_jsx(Helmet, { children: _jsx("title", { children: organizationName
                ? `${organizationName} | ${productName.plural}`
                : `Column - ${productName.singular}` }) }));
}
