var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, FormControl, Input, Button, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { TimezoneType } from 'lib/enums';
import SettingsWrapper from '../SettingsWrapper';
const styles = (theme) => createStyles({
    formControl: {
        display: 'flex'
    },
    saveButton: {
        marginTop: theme.spacing(4),
        width: '100%'
    },
    header: {
        marginBottom: theme.spacing(3)
    }
});
function PressAssociationSettingsInfo({ activeOrganization, classes }) {
    const orgData = activeOrganization.data();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [timezone, setTimezone] = useState();
    useEffect(() => {
        if (orgData) {
            setName(orgData.name);
            setPhone(orgData.phone);
            setTimezone(orgData.iana_timezone);
        }
    }, [activeOrganization]);
    return (_jsx(SettingsWrapper, { children: _jsxs(ValidatorForm, Object.assign({ onSubmit: (e) => __awaiter(this, void 0, void 0, function* () {
                e.preventDefault();
                yield activeOrganization.ref.update({
                    iana_timezone: timezone,
                    phone,
                    name
                });
            }) }, { children: [_jsx(Typography, Object.assign({ variant: "h5", className: classes.header }, { children: "Basic information" })), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true }, { children: [_jsx(InputLabel, { children: "Organization Name" }), _jsx(Input, { required: true, autoComplete: "none", value: name, onChange: e => setName(e.target.value) })] })) })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsx(FormControl, Object.assign({ required: true, fullWidth: true }, { children: _jsx(InputMask, Object.assign({ mask: "(999) 999-9999", maskChar: "", value: phone, onChange: e => setPhone(e.target.value) }, { children: () => (_jsx(TextValidator, { name: "phone", value: phone, required: true, autoComplete: "none", label: "Phone number", validators: ['minStringLength:14'], errorMessages: ['Phone number must be 10 digits'] })) })) })) })), _jsx(Grid, Object.assign({ item: true, xs: 6 }, { children: _jsxs(FormControl, Object.assign({ required: true, fullWidth: true }, { children: [_jsx(InputLabel, { children: "Timezone" }), _jsx(Select, Object.assign({ onChange: e => {
                                            setTimezone(e.target.value);
                                        }, value: timezone }, { children: TimezoneType.items().map(tz => (_jsx(MenuItem, Object.assign({ value: tz.logical }, { children: tz.label }), tz.value))) }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Button, Object.assign({ id: "InfoSave", type: "submit", variant: "contained", color: "primary", className: classes.saveButton }, { children: "Save" })) }))] }))] })) }));
}
export default withStyles(styles)(PressAssociationSettingsInfo);
