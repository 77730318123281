import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { Tooltip } from 'lib/components/Tooltip';
import moment from 'moment-timezone';
import React from 'react';
// TODO: add tests
// TODO: probably want badge style to also depend on status
export function DeadlineBadge({ deadline, timezone }) {
    if (deadline === null) {
        return null;
    }
    const deadlineDate = deadline.toDate();
    const isToday = moment(deadlineDate).startOf('day').isSame(moment(), 'day');
    const isPast = moment(deadlineDate).isBefore(moment().startOf('day'));
    const isTomorrow = moment(deadlineDate)
        .startOf('day')
        .isSame(moment().add(1, 'day'), 'day');
    const DAY_FORMAT_STRING = isToday
        ? '[Today]'
        : isTomorrow
            ? '[Tomorrow]'
            : 'MM/DD/YY';
    // Table row shows deadline in local timezone which is inferred from the browser environment
    const localTimezone = moment.tz.guess();
    return (_jsx(Tooltip, Object.assign({ helpText: `Newspaper time: ${moment(deadlineDate)
            .tz(timezone)
            .format(`MM/DD/YY [by] h:mm A`)}${' '}
        ${moment.tz(timezone).format('z')}` }, { children: _jsxs(Badge, Object.assign({ status: isToday || isPast ? 'critical' : isTomorrow ? 'warning' : undefined, startIcon: _jsx(BellAlertIcon, { className: "w-4 h-4" }) }, { children: [moment(deadlineDate)
                    .tz(localTimezone)
                    .format(`${DAY_FORMAT_STRING} [by] h:mm A`), ' ', moment.tz(localTimezone).format('z')] })) })));
}
