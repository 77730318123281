import { NoticeType } from '../enums';
import { calculateDBPrice } from './calculateDbPrice';
import calculateFee from './calculateFee';
import findDayRate from './findDayRate';
const calculateBoldPrices = (rateRecord, displayParameters) => {
    if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.bold_words) && (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.boldWords)) {
        return displayParameters.boldWords * rateRecord.bold_words;
    }
    // if we have a flat bold rate tied to the notice add that on top
    // of the price charged if the notice contains a bold word
    if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.flatBoldPricing) && (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.boldWords)) {
        return rateRecord.flatBoldPricing;
    }
    if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.line_with_bold_words) &&
        (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.nonTableBoldedLines)) {
        return (displayParameters.nonTableBoldedLines * rateRecord.line_with_bold_words);
    }
    return 0;
};
/**
 * The one true pricing function is createDBPricingObjectFromData, so if you're using this outside
 * of `lib/pricing`, you're probably doing something wrong!
 *
 * Calculate the "db price" in cents for a single run of a notice. This is
 * the default price unless it is modified in the invoice step.
 */
const calculateSingleRunPrice = (notice, displayParameters, newspaper, rate, runNumber) => {
    var _a, _b, _c, _d, _e;
    const numRuns = notice.publicationDates.length;
    if (runNumber >= numRuns) {
        throw new Error(`Cannot compute pricing for run #${runNumber} of notice with ${numRuns} runs`);
    }
    const publicationDate = notice.publicationDates[runNumber];
    const { dayRate } = findDayRate(rate, publicationDate);
    const columns = displayParameters.columns || notice.columns || 1;
    const baseDbPrice = calculateDBPrice(notice, rate, displayParameters, numRuns, columns, dayRate, runNumber);
    // Display ads can't have bold pricing
    const boldPrice = notice.noticeType === NoticeType.display_ad.value
        ? 0
        : calculateBoldPrices(rate, displayParameters);
    const fee = (_a = calculateFee(newspaper, numRuns, runNumber)) !== null && _a !== void 0 ? _a : 0;
    const imageFee = ((_c = (_b = displayParameters.images) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) * ((_e = (_d = rate.images) === null || _d === void 0 ? void 0 : _d.flatRate) !== null && _e !== void 0 ? _e : 0);
    // Round to the nearest cent at the end
    return Math.round(baseDbPrice + boldPrice + fee + imageFee);
};
export default calculateSingleRunPrice;
