var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
/**
 * Gets the template styles and transforms them for the UI
 * @param templateDownloadURL URL of the IDML template
 * @returns
 */
export const getTemplateStylesFromURL = (templateDownloadURL) => __awaiter(void 0, void 0, void 0, function* () {
    const stylesResp = yield api.get('templates/styles', {
        downloadUrl: templateDownloadURL
    });
    if (!stylesResp.success) {
        throw new Error('Failed to get template styles');
    }
    return stylesResp.styles;
});
/**
 * Updates template styles, converting UI width and height to server values using the default DPI
 * @param templateURL
 * @param styles
 * @returns
 */
export const forkTemplateStyles = (templateURL, styles) => __awaiter(void 0, void 0, void 0, function* () {
    const forkResponse = yield api.post('templates/fork', {
        downloadUrl: templateURL,
        styles
    });
    if (!forkResponse.success) {
        throw new Error('Failed to fork template styles');
    }
    const { downloadUrl } = forkResponse;
    return downloadUrl;
});
export const DEFAULT_FULL_PAGE_TEMPLATE = 'https://enotice-production.imgix.net/custom-documents/permalink/6e79.8a54c-Base Full Page Template 20221205.idml';
export const DEFAULT_AD_TEMPLATE = 'https://enotice-production.imgix.net/custom-documents/permalink/cb40.0470a-StartingTemplate2%20(1).idml';
/**
 * 4.200001 => 4.2
 * 4 => 4
 */
export const prettyFormatStyleValue = (value) => {
    if (typeof value === 'number') {
        if (Math.round(value) === value) {
            return value;
        }
        return value.toFixed(2);
    }
    if (typeof value === 'boolean') {
        return value ? 'True' : 'False';
    }
    return value;
};
