import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
export default function BasicSettingsCard({ onChangeGlobalAffidavitControls, globalAffidavitControls }) {
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: 'Basic Settings' } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { labelText: "Signatory name", value: globalAffidavitControls.signatoryName, onChange: newValue => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { signatoryName: newValue })), required: true, id: "signatory-name" }) })), _jsx(GridInput, { children: _jsx(TextField, { labelText: "Signatory role", value: globalAffidavitControls.signatoryRole, onChange: newValue => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { signatoryRole: newValue })), required: true, id: "signatory-role" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { labelText: "Publication frequency", options: [
                        {
                            label: 'Daily',
                            value: 'Daily'
                        },
                        {
                            label: 'Weekly',
                            value: 'Weekly'
                        }
                    ], value: globalAffidavitControls.publicationFrequency, onChange: newValue => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { publicationFrequency: newValue })), required: true, id: "publication-frequency" }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { description: "If enabled, an affidavit mailing address will be required with every notice submission.", onChange: newValue => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { physicalAffidavit: newValue })), label: "Send all affidavits by mail?", value: globalAffidavitControls.physicalAffidavit }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { description: "If enabled, all customers can receive affidavits as soon as they're uploaded to Column \u2014 even if the invoice is unpaid.", onChange: newValue => onChangeGlobalAffidavitControls(Object.assign(Object.assign({}, globalAffidavitControls), { alwaysAllowAffidavitDownload: newValue })), label: "Always allow affidavit download?", value: globalAffidavitControls.alwaysAllowAffidavitDownload }) }))] })));
}
