var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { queryPublicationIssues, queryPublicationIssuesAndSections } from 'lib/services/publicationIssueService';
import { comparePublicationIssuesSort } from '../paginationTableUtils';
export function useGetPublicationIssues(query, refreshTimestamp, paginationTab) {
    const [publicationIssuesWithSections, setPublicationIssuesWithSections] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchPublicationIssues = () => __awaiter(this, void 0, void 0, function* () {
            setLoading(true);
            if (!query.publisherIds.length) {
                return;
            }
            const { response: publicationIssues, error: queryPublicationIssuesError } = yield queryPublicationIssues(getFirebaseContext(), query);
            if (queryPublicationIssuesError) {
                return setError(queryPublicationIssuesError.message);
            }
            const { response: publicationIssuesWithSections, error: queryPublicationIssuesWithSectionsError } = yield queryPublicationIssuesAndSections(publicationIssues, query);
            if (queryPublicationIssuesWithSectionsError) {
                return setError(queryPublicationIssuesWithSectionsError.message);
            }
            setPublicationIssuesWithSections(publicationIssuesWithSections);
            setLoading(false);
        });
        void fetchPublicationIssues();
    }, [query, refreshTimestamp]);
    const sortedPublicationIssuesWithSections = publicationIssuesWithSections.sort((a, b) => comparePublicationIssuesSort(a, b, paginationTab));
    return {
        loading,
        error,
        publicationIssuesWithSections: sortedPublicationIssuesWithSections
    };
}
