var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class AdRateService {
    constructor(collection, adType) {
        this.collection = collection;
        this.adType = adType;
    }
    restrictQueryToRatesOfType(query) {
        return query.where('product', '==', this.adType);
    }
    getNewspaperOwnedRatesQuery(newspaper) {
        const query = this.collection.where('organization', '==', newspaper);
        return this.restrictQueryToRatesOfType(query);
    }
    getNewspaperOwnedRates(newspaper) {
        return this.getNewspaperOwnedRatesQuery(newspaper).get();
    }
    getPublisherOrgRatesQueries(org) {
        const ownedRatesQuery = this.getNewspaperOwnedRatesQuery(org);
        let allowedRatesQuery = this.collection.where('publisherOrganizations', 'array-contains', org);
        allowedRatesQuery = this.restrictQueryToRatesOfType(allowedRatesQuery);
        return {
            ownedRatesQuery,
            allowedRatesQuery
        };
    }
    getPublisherOrgRates(org) {
        return __awaiter(this, void 0, void 0, function* () {
            const { ownedRatesQuery, allowedRatesQuery } = this.getPublisherOrgRatesQueries(org);
            const ownedRates = yield ownedRatesQuery.get();
            const allowedRates = yield allowedRatesQuery.get();
            return [...ownedRates.docs, ...allowedRates.docs];
        });
    }
}
