import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Editor from '@monaco-editor/react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { InputAccessories } from 'lib/components/InputAccessories';
import { TextField } from 'lib/components/TextField';
import { DEFAULT_EDITOR_OPTIONS } from '../htmlHelpers';
export function AffidavitConfiguration({ formData, onChange }) {
    var _a, _b, _c, _d, _e;
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: 'Template Configuration' } }, { children: [_jsx(GridInput, { children: _jsx(ColumnSelect, { options: [
                        { value: 'column', label: `Use Column's logo` },
                        { value: 'publisher', label: `Use the Publisher's logo` },
                        { value: 'none', label: `Don't add a logo to this affidavit` }
                    ], value: formData.logoSource || 'column', onChange: logoSource => onChange(Object.assign(Object.assign({}, formData), { logoSource })), id: "affidavit-template-logo-select", labelText: "Which logo should be used on this affidavit?" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { options: [
                        { value: '0.25', label: '1/4 inches' },
                        { value: '0.5', label: '1/2 inches' },
                        { value: '0.75', label: '3/4 inches' },
                        { value: '1', label: '1 inch' }
                    ], value: (_b = (_a = formData.logoHeightInInches) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '0.25', onChange: stringValue => {
                        const logoHeightInInches = stringValue
                            ? parseFloat(stringValue)
                            : undefined;
                        onChange(Object.assign(Object.assign({}, formData), { logoHeightInInches }));
                    }, id: "affidavit-template-logo-height", labelText: "How tall should the logo be? (in inches)" }) }), _jsx(GridInput, { children: _jsx(TextField, { type: "number", step: 0.1, value: (_c = formData.blockWidthInInches) === null || _c === void 0 ? void 0 : _c.toString(), placeholder: "4.5", onChange: stringValue => {
                        const blockWidthInInches = stringValue
                            ? parseFloat(stringValue)
                            : undefined;
                        onChange(Object.assign(Object.assign({}, formData), { blockWidthInInches }));
                    }, id: "affidavit-template-block-width", labelText: "How wide should the attestation block be? (in inches)" }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { options: [
                        { value: '10', label: '10 pt' },
                        { value: '12', label: '12 pt' },
                        { value: '14', label: '14 pt' }
                    ], value: (_e = (_d = formData.fontSize) === null || _d === void 0 ? void 0 : _d.toString()) !== null && _e !== void 0 ? _e : '12', onChange: stringValue => onChange(Object.assign(Object.assign({}, formData), { fontSize: Number(stringValue) })), id: "affidavit-template-font-size", labelText: "What font size should be used in the attestation block?" }) }), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InputAccessories, Object.assign({ id: "affidavit-template-html-editor", labelText: "Customize the attestation block content" }, { children: _jsx(Editor, { height: "80vh", className: 'rounded-md border border-column-gray-200 p-2', defaultLanguage: "html", defaultValue: formData.affidavitTemplateHTML, onChange: newContent => {
                            if (newContent) {
                                onChange(Object.assign(Object.assign({}, formData), { affidavitTemplateHTML: newContent }));
                            }
                        }, options: DEFAULT_EDITOR_OPTIONS }) })) }))] })));
}
