import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { DEFAULT_MAILING as MAIL_ADDRESS_WITHOUT_STATE } from 'routes/placeScroll/AffidavitRecipients/affidavitRecipient.slice';
import { State } from 'lib/enums';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { SwitchControlledCards } from 'lib/components/Card/SwitchControlledCards';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { AffidavitMailingCard } from './AffidavitMailingCard';
import { AffidavitTemplateSelect } from './AffidavitTemplateSelect';
export const DEFAULT_AFFIDAVIT_MAILING_ADDRESS = Object.assign(Object.assign({}, MAIL_ADDRESS_WITHOUT_STATE), { address: Object.assign(Object.assign({}, MAIL_ADDRESS_WITHOUT_STATE.address), { address_state: State.alabama.value }) });
export default function AffidavitSettingsCard({ onUpdateNoticeType, updatedNoticeType }) {
    var _a;
    const [useCustomAffidavitTemplate, setUseCustomAffidavitTemplate] = useState(!!updatedNoticeType.defaultAffidavitTemplate);
    const [useColumnMailAffidavit, setUseColumnMailAffidavit] = useState(!!((_a = updatedNoticeType.defaultMailingAddresses) === null || _a === void 0 ? void 0 : _a.length));
    const affidavitMailingAddresses = updatedNoticeType.defaultMailingAddresses || [];
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Affidavit Settings',
            description: 'Configure special affidavit settings for this notice type.'
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Are affidavits required for this notice type?", description: "If disabled, notices of this type won't require affidavits after publication.", onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (newValue) {
                            delete newNoticeType.affidavitDisabled;
                        }
                        else {
                            newNoticeType.affidavitDisabled = true;
                        }
                        onUpdateNoticeType(newNoticeType);
                    }, value: updatedNoticeType.affidavitDisabled !== true }) })), _jsx(SwitchControlledCard, Object.assign({ labelProps: {
                    label: 'Use a custom affidavit for this notice type?',
                    description: 'If enabled, this notice type will use a different affidavit template from your default affidavit.',
                    value: useCustomAffidavitTemplate,
                    onChange: useCustomAffidavit => {
                        setUseCustomAffidavitTemplate(useCustomAffidavit);
                        if (!useCustomAffidavit) {
                            const newNoticeType = Object.assign({}, updatedNoticeType);
                            delete newNoticeType.customAffidavit;
                            delete newNoticeType.defaultAffidavitTemplate;
                            onUpdateNoticeType(newNoticeType);
                        }
                    }
                }, header: "" }, { children: _jsx(AffidavitTemplateSelect, { labelText: "Custom Template", value: updatedNoticeType.defaultAffidavitTemplate, onChange: templateRef => {
                        onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { defaultAffidavitTemplate: templateRef }));
                    }, disabled: updatedNoticeType.affidavitDisabled }) })), _jsx(SwitchControlledCards, { labelProps: {
                    label: 'Should affidavits be mailed for this notice type?',
                    description: 'If enabled, the provided mailing address will populate by default in the affidavits section of the placement flow.',
                    onChange: newValue => {
                        const newNoticeType = Object.assign({}, updatedNoticeType);
                        if (!newValue) {
                            delete newNoticeType.defaultMailingAddresses;
                        }
                        else {
                            newNoticeType.defaultMailingAddresses = [
                                DEFAULT_AFFIDAVIT_MAILING_ADDRESS
                            ];
                        }
                        onUpdateNoticeType(newNoticeType);
                        setUseColumnMailAffidavit(newValue);
                    },
                    value: useColumnMailAffidavit
                }, cardProps: {
                    baseHeader: 'Mailing Address',
                    values: affidavitMailingAddresses,
                    onChange: defaultMailingAddresses => onUpdateNoticeType(Object.assign(Object.assign({}, updatedNoticeType), { defaultMailingAddresses })),
                    Component: AffidavitMailingCard,
                    createable: {
                        buttonText: 'Add Mailing Address',
                        initialValue: DEFAULT_AFFIDAVIT_MAILING_ADDRESS
                    },
                    archivable: {
                        buttonText: 'Remove Mailing Address',
                        shouldAllow: () => affidavitMailingAddresses.length > 1
                    }
                } })] })));
}
