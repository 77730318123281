import { FileType } from '../types/mime';
export const ALLOWED_FINALIZED_DISPLAY_TYPES = [
    FileType.JPG,
    FileType.PDF
];
export const isAllowedFinalizedDisplayType = (fileType) => {
    if (!fileType) {
        return false;
    }
    return ALLOWED_FINALIZED_DISPLAY_TYPES.includes(fileType);
};
