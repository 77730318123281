import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CopyText } from 'lib/components/CopyText';
import React from 'react';
/**
 * Manages external links in the activity log. This component only appears when events have an external link.
 */
function EventSeeMoreLink({ link }) {
    return (_jsx("a", Object.assign({ className: "underline ml-1", href: link.url, target: "_blank", rel: "noreferrer" }, { children: link.text })));
}
function ActivityLogEvent({ event }) {
    var _a;
    const headerTag = _jsx("h3", Object.assign({ className: "text-sm font-medium" }, { children: event.header }));
    const eventHeader = event.header && event.userEmail ? (_jsx(CopyText, Object.assign({ copyText: event.userEmail, copyLabel: 'email' }, { children: headerTag }))) : (headerTag);
    return (_jsxs("li", Object.assign({ className: "pt-6 pb-8 text-gray-800" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex justify-between space-x-2" }, { children: [eventHeader, _jsx("div", Object.assign({ className: "text-xs text-column-gray-400" }, { children: event.dateString }))] })), _jsxs("p", Object.assign({ className: "text-sm" }, { children: [(_a = event.content) !== null && _a !== void 0 ? _a : '', event.link && _jsx(EventSeeMoreLink, { link: event.link })] }))] })));
}
function ActivityLogEvents({ events }) {
    return (_jsx("ul", Object.assign({ className: "divide-y divide-gray-200 px-6" }, { children: events.map((event, index) => event.hidden ? undefined : (_jsx(ActivityLogEvent, { event: event }, `${index}-${event.type}`))) })));
}
export default ActivityLogEvents;
