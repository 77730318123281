var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CardGridLayout, GridInput, useEditableCard } from 'lib/components/Card/Grid';
import { UPLOAD_FLORIDA, UPLOAD_ILLINOIS, UPLOAD_NEWZGROUP, UPLOAD_NEW_YORK } from 'lib/types/externalUploads';
import { Form } from 'lib/components/Form';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { getDefaultSettingsForFormat } from './defaults';
import { UploadIllinois } from './UploadIllinois';
import { UploadNewYork } from './UploadNewYork';
import { UploadFlorida } from './UploadQuion';
import { UploadNewzgroup } from './UploadNewzgroup';
export function ExternalUploadForm({ uploadFormat, uploadSettings }) {
    var _a, _b;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const { formSettings, onChange, onSubmitWrapper, editable, disabled } = useEditableCard(uploadSettings !== null && uploadSettings !== void 0 ? uploadSettings : getDefaultSettingsForFormat(uploadFormat));
    return (_jsx(Form, Object.assign({ onSubmit: () => __awaiter(this, void 0, void 0, function* () {
            return onSubmitWrapper(() => __awaiter(this, void 0, void 0, function* () {
                if (!activeOrganization) {
                    return { success: false, message: 'No active organization' };
                }
                yield activeOrganization.ref.update({
                    externalUploadSettings: formSettings
                });
                return { success: true };
            }));
        }) }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'Upload Settings',
                description: 'Save information needed to upload your notices.'
            }, editable: editable }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Upload to Your Statewide Site?", description: "If enabled, we will upload your notices every morning at 9:30 AM ET with the settings saved here.", value: formSettings.uploadToExternalSite, onChange: uploadToExternalSite => onChange({ uploadToExternalSite }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { labelText: "When should we upload notices?", options: [
                            { value: '1', label: 'The day before publication' },
                            { value: '0', label: 'The day of publication' },
                            { value: '-1', label: 'The day after publication' }
                        ], value: (_b = (_a = formSettings.timing) === null || _a === void 0 ? void 0 : _a.days.toString()) !== null && _b !== void 0 ? _b : '0', onChange: days => onChange({
                            timing: { type: 'fixed-offset', days: Number(days) }
                        }), id: "external-upload-timing", disabled: disabled, required: true }) })), formSettings.format === UPLOAD_FLORIDA && (_jsx(UploadFlorida, { value: formSettings, onChange: onChange, disabled: disabled })), formSettings.format === UPLOAD_ILLINOIS && (_jsx(UploadIllinois, { value: formSettings, onChange: onChange, disabled: disabled })), formSettings.format === UPLOAD_NEW_YORK && (_jsx(UploadNewYork, { value: formSettings, onChange: onChange, disabled: disabled })), formSettings.format === UPLOAD_NEWZGROUP && (_jsx(UploadNewzgroup, { value: formSettings, onChange: onChange, disabled: disabled }))] })) })));
}
