var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Tooltip, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Help } from '@material-ui/icons';
import { getDeadlineTimeForPaper, isNoticeAfterPublicationDeadline } from 'lib/utils/deadlines';
import { firstNoticePublicationDate, getNoticeRequiresUpfrontPayment, getNoticeIsInvoicedOutsideColumn, getDueDate } from 'lib/helpers';
import { CalendarIcon } from 'icons';
import { getFirebaseContext } from 'utils/firebase';
import classNames from 'classnames';
import { useInheritedProperty } from 'lib/frontend/hooks/useInheritedProperty';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
function ToggleSwitch({ id, value, disabled, onChange }) {
    return (_jsxs("button", Object.assign({ type: "button", "data-testid": id, id: id, className: classNames('flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500', value ? 'bg-blue-650 bg-opacity-75' : 'bg-gray-200', {
            'cursor-pointer': !disabled,
            'opacity-25': disabled
        }), disabled: disabled, onClick: () => {
            if (disabled) {
                return;
            }
            onChange(!value);
        } }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Use setting" })), _jsx("span", { "aria-hidden": "true", className: `bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-400 ${value && 'bg-blue-650 bg-opacity-75'}` }), _jsx("span", { "aria-hidden": "true", className: `bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${value && 'translate-x-5'}` })] })));
}
function InvoiceFormDueDate({ newspaper, dueDate, setDueDate, noticeSnap, requireUpfrontPayment, setRequireUpfrontPayment, ianaTimezone, invoiceOutsideColumn, setInvoiceOutsideColumn, isWithinBulkPayments }) {
    const [disableRequireUpfrontPayment, setDisableRequireUpfrontPayment] = useState(false);
    const [deadline, setDeadline] = useState();
    useEffect(() => {
        const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaper.data();
        if (!deadlines) {
            logAndCaptureException(ColumnService.PAYMENTS, new Error('No deadlines found for newspaper'), 'Missing deadlines in InvoiceFormDueDate', {
                newspaperId: newspaper.id
            });
            return;
        }
        const closestPublicationDate = firstNoticePublicationDate(noticeSnap);
        const deadline = getDeadlineTimeForPaper(closestPublicationDate, deadlines, deadlineOverrides, iana_timezone, noticeSnap.data(), newspaper);
        setDeadline(deadline.toDate());
    }, [noticeSnap, newspaper]);
    useEffect(() => {
        const checkDeadline = () => __awaiter(this, void 0, void 0, function* () {
            if (deadline) {
                if (isWithinBulkPayments) {
                    // Upfront payment should always be false
                    // if a notice is within a bulk invoice
                    setDisableRequireUpfrontPayment(true);
                    setRequireUpfrontPayment(false);
                }
                else {
                    const isAfterDeadline = yield isNoticeAfterPublicationDeadline(noticeSnap);
                    const { iana_timezone } = newspaper.data();
                    if (!isAfterDeadline) {
                        setDisableRequireUpfrontPayment(false);
                    }
                    else if (!isWithinBulkPayments) {
                        setDisableRequireUpfrontPayment(true);
                        setRequireUpfrontPayment(false);
                        setDueDate(moment().tz(iana_timezone).add(1, 'M').toDate().getTime() / 1000 -
                            2);
                    }
                }
            }
        });
        void checkDeadline();
    }, [deadline, isWithinBulkPayments]);
    // When the organization has the disableControlUpfrontPayment setting,
    // require upfront payment and invoiceOutsideColumn are mutually exclusive.
    // We use IOC as the "controlling" variable and derive RUP accordingly.
    const disableControlUpfrontPayment = useInheritedProperty(newspaper.ref, 'disableControlUpfrontPayment');
    // In the case where RUP is not an option (after deadline) and the newspaper has
    // the setting which makes IOC and RUP mutally exclusive, they cannot control either
    const isAfterDeadlineAndIOCRUPMutuallyExclusive = !requireUpfrontPayment &&
        disableRequireUpfrontPayment &&
        disableControlUpfrontPayment;
    const blockNonIOCInvoices = useInheritedProperty(newspaper.ref, 'blockNonIOCInvoices');
    const forceInvoiceOutsideColumn = blockNonIOCInvoices || isAfterDeadlineAndIOCRUPMutuallyExclusive;
    useEffect(() => {
        if (forceInvoiceOutsideColumn) {
            setInvoiceOutsideColumn(true);
        }
    }, [forceInvoiceOutsideColumn]);
    useEffect(() => {
        if (disableControlUpfrontPayment &&
            requireUpfrontPayment === invoiceOutsideColumn) {
            setInvoiceOutsideColumn(!requireUpfrontPayment);
        }
    }, [
        disableControlUpfrontPayment,
        requireUpfrontPayment,
        invoiceOutsideColumn
    ]);
    const handleToggleInvoiceOutsideColumn = (val) => {
        setInvoiceOutsideColumn(val);
        if (disableControlUpfrontPayment && val === requireUpfrontPayment) {
            setRequireUpfrontPayment(!val);
        }
    };
    useEffect(() => {
        void (() => __awaiter(this, void 0, void 0, function* () {
            const ctx = getFirebaseContext();
            const requireUpfrontPayment = yield getNoticeRequiresUpfrontPayment(ctx, noticeSnap);
            const shouldInvoiceOutsideColumn = yield getNoticeIsInvoicedOutsideColumn(ctx, noticeSnap);
            setRequireUpfrontPayment(!isWithinBulkPayments && requireUpfrontPayment);
            if (shouldInvoiceOutsideColumn) {
                setInvoiceOutsideColumn(true);
            }
        }))();
    }, []);
    return (_jsx("div", { children: _jsx("div", Object.assign({ className: "bg-white shadow overflow-hidden sm:rounded-lg mt-5" }, { children: _jsx("div", Object.assign({ className: "px-4 py-5 sm:p-0" }, { children: _jsxs("dl", { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center px-4 pt-5 pb-3 border-b border-gray-300" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center text-sm leading-5 font-bold text-gray-800" }, { children: [_jsx("div", Object.assign({ className: "mr-2" }, { children: "Require upfront payment?" })), _jsx(Tooltip, Object.assign({ title: `When enabled, upfront payment will be required such that invoice will have a due date one day prior the deadline` }, { children: _jsx(Help, { color: "disabled", style: { width: '1rem', fill: '#2A394A' } }) }))] })), _jsx("div", Object.assign({ className: "flex mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0" }, { children: _jsx(Tooltip, Object.assign({ title: disableRequireUpfrontPayment
                                            ? isWithinBulkPayments
                                                ? `This publisher is set to pay invoices in bulk at the end of the month.`
                                                : `The ad deadline for this notice has already passed, and the advertiser will not have enough time to fulfill the invoice before publication.`
                                            : `` }, { children: _jsx(ToggleSwitch, { id: "require-upfront-payment", value: !!requireUpfrontPayment, onChange: val => setRequireUpfrontPayment(val), disabled: disableControlUpfrontPayment ||
                                                disableRequireUpfrontPayment }) })) }))] })), newspaper.data().allowInvoiceOutsideColumn && (_jsxs("div", Object.assign({ className: "flex justify-between items-center px-4 pt-5 pb-3 border-b border-gray-300" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center text-sm leading-5 font-bold text-gray-800" }, { children: [_jsx("div", Object.assign({ className: "mr-2" }, { children: "Invoice outside of Column?" })), _jsx(Tooltip, Object.assign({ title: `When enabled, invoices will be sent outside of the Column System` }, { children: _jsx(Help, { color: "disabled", style: { width: '1rem', fill: '#2A394A' } }) }))] })), _jsx("div", Object.assign({ className: "flex mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0" }, { children: _jsx(Tooltip, Object.assign({ title: invoiceOutsideColumn
                                            ? `This customer will receive their invoice outside of Column`
                                            : 'This customer will receive their invoice inside of Column' }, { children: _jsx(ToggleSwitch, { id: "invoice-outside-column", value: !!invoiceOutsideColumn, onChange: handleToggleInvoiceOutsideColumn, disabled: forceInvoiceOutsideColumn }) })) }))] }))), _jsxs("div", Object.assign({ className: "flex justify-between items-center px-4 pt-3 pb-5" }, { children: [_jsx("div", Object.assign({ className: "text-sm leading-5 font-bold text-gray-800" }, { children: "Invoice Due Date" })), _jsx("div", Object.assign({ className: "w-32 mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0" }, { children: _jsx(Tooltip, Object.assign({ title: isWithinBulkPayments
                                            ? `This publisher is set to pay invoices in bulk at the end of the month.`
                                            : `` }, { children: _jsx(MuiPickersUtilsProvider, Object.assign({ utils: DateFnsUtils }, { children: _jsx(DatePicker, { "data-testid": "datePicker", label: "", value: dueDate &&
                                                    ianaTimezone &&
                                                    moment(dueDate * 1000)
                                                        .tz(ianaTimezone)
                                                        .toDate()
                                                        .toLocaleString('en-US'), placeholder: "MMM dd, YYYY", format: "MMM dd, yyyy", className: 'text-xs', InputProps: {
                                                    disableUnderline: true,
                                                    startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(CalendarIcon, { className: "text-xs p-0.25" }) }))),
                                                    className: 'text-xs'
                                                }, shouldDisableDate: date => {
                                                    if (!date) {
                                                        return true;
                                                    }
                                                    const now = new Date();
                                                    if (date.getTime() < now.getTime())
                                                        return true;
                                                    if (requireUpfrontPayment &&
                                                        deadline &&
                                                        date.getTime() > deadline.getTime()) {
                                                        return true;
                                                    }
                                                    return false;
                                                }, autoOk: true, onChange: (date) => __awaiter(this, void 0, void 0, function* () {
                                                    if (ianaTimezone && date) {
                                                        if (requireUpfrontPayment) {
                                                            const dueDate = yield getDueDate(getFirebaseContext(), noticeSnap, requireUpfrontPayment, moment(date.getTime()).tz(ianaTimezone).toDate());
                                                            setDueDate(dueDate);
                                                        }
                                                        else {
                                                            setDueDate(moment(date.getTime())
                                                                .tz(ianaTimezone)
                                                                .toDate()
                                                                .getTime() /
                                                                1000 -
                                                                2);
                                                        }
                                                    }
                                                }), minDateMessage: "Selected date after publication deadline", disabled: isWithinBulkPayments }) })) })) }))] }))] }) })) })) }));
}
export default InvoiceFormDueDate;
