import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Grid } from '@material-ui/core';
import { exists } from 'lib/types';
import { Report } from 'components/datastudio';
/**
 * Returns the GDS report for the given organization.
 * @param organization organization to get reports for
 * @returns {string} report url
 */
export const getReportUrl = (organization) => {
    var _a;
    if (organization.data().reportUrl)
        return organization.data().reportUrl;
    const reportParams = {
        parent_organization_id: ((_a = organization.data().parent) === null || _a === void 0 ? void 0 : _a.id) || organization.id,
        root_organization_id: organization.id,
        aging_newspaper_id: organization.id
    };
    const encodedParams = encodeURIComponent(JSON.stringify(reportParams));
    return `https://datastudio.google.com/embed/reporting/193efc28-0f38-462e-912e-0072a33f088c/page/CMiGC?params=${encodedParams}`;
};
function Reports({ activeOrganization }) {
    if (!exists(activeOrganization))
        return null;
    const reportUrl = getReportUrl(activeOrganization);
    return (_jsx(Grid, Object.assign({ style: {
            height: 'calc(100vh - 65px)',
            position: 'fixed',
            left: 0,
            top: 65,
            width: '100vw'
        }, id: "studio-dashboard" }, { children: _jsx(Report, { reportUrl: reportUrl, height: "100%" }) })));
}
export default Reports;
