import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { InputAdornment } from '@material-ui/core';
import { ColumnButton } from 'lib/components/ColumnButton';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getNoticeType } from 'lib/helpers';
import { placementSelector } from 'redux/placement';
import { TrashIcon } from '@heroicons/react/24/outline';
import { shouldDisableDate } from './helpers';
const styles = () => createStyles({
    picker: {
        border: '1px #cbd5e0 solid',
        borderRadius: '0.375rem',
        padding: '4px 0px 4px 12px',
        width: '100%'
    }
});
function ScheduleDecisionRow({ newspaper, publicationDate, classes, index, handleRemoveRowClick, handlePubDateChange, isPublisher, user, disabled, showDelete, isDuplicateDate, notice }) {
    const placement = useAppSelector(placementSelector);
    const noticeType = getNoticeType(notice, newspaper);
    return (_jsxs("div", Object.assign({ className: 'flex space-x-2' }, { children: [_jsx("div", Object.assign({ className: `w-full rounded-md ${isDuplicateDate
                    ? 'border border-column-red-600 shadow-outline-column-red'
                    : ''}` }, { children: _jsx(MuiPickersUtilsProvider, Object.assign({ utils: DateFnsUtils }, { children: _jsx(DatePicker, { id: `date-picker-${index}`, value: publicationDate, placeholder: "MM/DD/YYYY", className: `${classes.picker} date-picker-publish`, shouldDisableDate: day => {
                            if (day) {
                                // Currently selected date should not disabled
                                if (publicationDate.toLocaleDateString() ===
                                    day.toLocaleDateString())
                                    return false;
                                return shouldDisableDate({
                                    day,
                                    newspaper,
                                    user,
                                    notice: placement,
                                    noticeType,
                                    isPublisher
                                });
                            }
                            return true;
                        }, InputProps: {
                            disableUnderline: true,
                            endAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx("svg", Object.assign({ className: "w-5 h-5", fill: "none", stroke: disabled ? '#718096' : '#171F2E', viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" }) })) }))),
                            className: 'cursor-pointer'
                        }, disablePast: !isPublisher, 
                        // eslint-disable-next-line react/jsx-boolean-value
                        disabled: disabled ||
                            (!!(noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
                                !isPublisher &&
                                index > 0 &&
                                index < (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications)), autoOk: true, onChange: date => date && handlePubDateChange(date, index), minDateMessage: "Selected date after publication deadline" }) })) })), !disabled &&
                showDelete &&
                !(!!(noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications) &&
                    !isPublisher &&
                    index < (noticeType === null || noticeType === void 0 ? void 0 : noticeType.requiredPublications)) && (_jsx(ColumnButton, { link: false, destructive: true, secondary: true, startIcon: _jsx(TrashIcon, { className: 'w-5 h-5' }), onClick: () => handleRemoveRowClick(index), type: "button" }))] })));
}
export default connect(() => ({}))(withStyles(styles)(ScheduleDecisionRow));
