import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
function PreventLatePrepayModal({ setOpen, notice }) {
    const handlePreventLatePrepay = () => {
        setOpen(false);
        return window.open(`mailto:help@column.us?subject=Request to reschedule notice ${notice.id}`);
    };
    const modalId = 'modal-prevent-late-prepay';
    const buttonText = 'Contact Support';
    const buttonId = 'contact-support';
    const body = `Upfront payment was required for this notice. Since the newspaper's deadline has already passed, your notice cannot be published. Please contact support to reschedule your notice for a future date.`;
    return (_jsx(FreeformCModal, Object.assign({ id: modalId, setOpen: setOpen, header: "Deadline has passed", body: body }, { children: _jsx(CButton, Object.assign({ id: buttonId, className: 'bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 transition w-full md:w-auto mt-4 hover:bg-blue-600 hover:text-white', onClick: handlePreventLatePrepay }, { children: buttonText })) })));
}
export default PreventLatePrepayModal;
