var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiPost } from 'api/typed';
import { wrapError, wrapSuccess } from 'lib/types/responses';
export const requestNewOrderInvoice = (orderId, version) => __awaiter(void 0, void 0, void 0, function* () {
    const { response: invoiceId, error: createError } = yield apiPost('orders/create-invoice', {
        orderId,
        version
    });
    if (createError !== null) {
        return wrapError(new Error(createError));
    }
    return wrapSuccess(invoiceId);
});
