import { Product } from 'lib/enums';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { CLASSIFIEDS, OBITUARIES, PRICING_RESTRICTIONS } from './PublisherSettingsSidebar';
const getProductFromTabParam = (tab) => {
    switch (tab) {
        case PRICING_RESTRICTIONS:
            return Product.Notice;
        case OBITUARIES:
            return Product.Obituary;
        case CLASSIFIEDS:
            return Product.Classified;
        case null:
        default:
            return null;
    }
};
/**
 * Returns the relevant product (notice, obit, classified) based on the
 * settings tab the user has selected.
 *
 * Returns null if the product cannot be determined or if the settings
 * are not product-specific.
 */
export const getSettingsProductFromLocationParams = () => {
    const settingsTab = getLocationParams().get('tab');
    const product = getProductFromTabParam(settingsTab);
    return product;
};
