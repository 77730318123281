var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { SmilingFaceWithSmilingEyes } from 'emojis';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { PasswordField } from 'components/PasswordField';
import { loginUser } from 'utils/users';
import FormError from 'routes/errors/FormError';
export default function PlacementLoginModal({ onClose, email }) {
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const submitLogin = () => __awaiter(this, void 0, void 0, function* () {
        const [success, authError] = yield loginUser(email, password);
        if (success) {
            onClose();
        }
        else {
            setLoginError(authError.message);
        }
    });
    return (_jsxs(CancelOrSubmitModal, Object.assign({ onClose: onClose, overrideTertiaryClose: onClose, onSubmit: submitLogin, disablePrimaryButton: !password.length, primaryButtonText: 'Login', tertiaryButtonText: 'Back' }, { children: [_jsxs("div", Object.assign({ className: "mx-10 mb-8 text-center z-20", id: "placement-password-reset-modal-header" }, { children: [_jsxs("div", Object.assign({ className: "font-semibold text-xl m-auto", id: "pw-reset-heading" }, { children: ["We found an account with this email", _jsx(SmilingFaceWithSmilingEyes, {})] })), _jsxs("div", Object.assign({ className: "mt-4 font-medium text-sm text-column-grey-500", id: "pw-reset-subheading" }, { children: ["Enter your Column password for", ' ', _jsx("span", Object.assign({ className: "text-column-primary-500" }, { children: email })), " to login and complete the notice."] }))] })), _jsxs("div", Object.assign({ className: "mb-12", id: "placement-password-reset-modal-body" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between font-medium text-sm leading-6 mb-2" }, { children: [_jsxs("p", Object.assign({ className: "text-column-grey-500" }, { children: ["Password ", _jsx("span", Object.assign({ className: "text-column-grey-400" }, { children: "*" }))] })), _jsx("a", Object.assign({ className: 'text-column-primary-500', href: "/forgot-password", target: "_blank", rel: "noreferrer" }, { children: "Forgot password?" }))] })), _jsx("div", { children: _jsx(PasswordField, { id: "placement-password-reset-new-password", value: password, onValueChange: (value) => {
                                setPassword(value);
                            }, placeHolderText: "\u2022\u2022\u2022\u2022\u2022\u2022" }) }), loginError && _jsx(FormError, { error: loginError })] }))] })));
}
