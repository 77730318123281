import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from 'lib/components/TextField';
import React from 'react';
import { GridInput } from 'lib/components/Card/Grid';
export default function OrderNumberSettings({ value, onChange }) {
    var _a, _b, _c, _d;
    return (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(TextField, { id: "order-number-digit-count", labelText: "Minimum number of digits", type: "number", min: 1, value: (_b = (_a = value === null || value === void 0 ? void 0 : value.orderNumDigitCount) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '1', onChange: newValue => {
                        onChange(Object.assign(Object.assign({}, value), { orderNumDigitCount: Number(newValue) }));
                    } }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "order-number-prefix", labelText: "Prefix", type: "text", value: (_c = value === null || value === void 0 ? void 0 : value.orderNumPrefix) !== null && _c !== void 0 ? _c : '', onChange: orderNumPrefix => onChange(Object.assign(Object.assign({}, value), { orderNumPrefix })) }) }), _jsx(GridInput, { children: _jsx(TextField, { id: "order-number-suffix", labelText: "Suffix", type: "text", value: (_d = value === null || value === void 0 ? void 0 : value.orderNumSuffix) !== null && _d !== void 0 ? _d : '', onChange: orderNumSuffix => onChange(Object.assign(Object.assign({}, value), { orderNumSuffix })) }) })] }));
}
