var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { getOrThrow } from '../../utils/refs';
export class AffidavitTemplateModel extends SnapshotModel {
    get type() {
        return Collections.affidavitTemplates;
    }
    isV1() {
        return this.modelData.version === 'v2020-01-01';
    }
    isV2() {
        return this.modelData.version === 'v2024-03-25';
    }
    static fromRef(ctx, ref) {
        return __awaiter(this, void 0, void 0, function* () {
            const snap = yield getOrThrow(ref);
            return new AffidavitTemplateModel(ctx, { snap });
        });
    }
    static fromStoragePathAndPublisher(ctx, storagePath, publisherRef, nameIfNotExists) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = yield ctx
                .affidavitTemplatesRef()
                .where('storagePath', '==', storagePath)
                .where('publisher', '==', publisherRef)
                .get();
            const activeTemplates = query.docs.filter(template => !template.data().archived);
            if (activeTemplates.length > 0) {
                return this.fromRef(ctx, activeTemplates[0].ref);
            }
            const ref = ctx.affidavitTemplatesRef().doc();
            const templateData = {
                name: nameIfNotExists !== null && nameIfNotExists !== void 0 ? nameIfNotExists : 'Custom Affidavit',
                storagePath,
                publisher: publisherRef,
                archived: false,
                version: 'v2020-01-01',
                isColumnManaged: false
            };
            yield ref.set(templateData);
            return this.fromRef(ctx, ref);
        });
    }
    static fromSourceRef(ctx, sourceSnap, sourceName, publisherRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const { customAffidavit, defaultAffidavitTemplate } = sourceSnap.data();
            if (defaultAffidavitTemplate) {
                return this.fromRef(ctx, defaultAffidavitTemplate);
            }
            if (!customAffidavit) {
                return;
            }
            const template = yield this.fromStoragePathAndPublisher(ctx, customAffidavit, publisherRef, `Custom Affidavit — ${sourceName}`);
            yield sourceSnap.ref.update({
                defaultAffidavitTemplate: template.ref
            });
            return template;
        });
    }
    static fromNoticeType(ctx, noticeType, publisherSnap) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!noticeType) {
                return;
            }
            const { customAffidavit, defaultAffidavitTemplate } = noticeType;
            if (defaultAffidavitTemplate) {
                return this.fromRef(ctx, defaultAffidavitTemplate);
            }
            if (!customAffidavit) {
                return;
            }
            const template = yield this.fromStoragePathAndPublisher(ctx, customAffidavit, publisherSnap.ref, `Custom Affidavit — ${noticeType.label}`);
            const { allowedNotices = [] } = publisherSnap.data();
            const newNoticeTypes = allowedNotices.map(nt => {
                if (nt.value === noticeType.value) {
                    return Object.assign(Object.assign({}, nt), { defaultAffidavitTemplate: template.ref });
                }
                return nt;
            });
            yield publisherSnap.ref.update({
                allowedNotices: newNoticeTypes
            });
            return template;
        });
    }
    static fromSourceRefNotarized(ctx, source, sourceName, publisherRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const { affidavitReconciliationSettings } = source.modelData;
            if (!affidavitReconciliationSettings) {
                return;
            }
            const { managedAffidavitTemplateStoragePath, managedAffidavitTemplate } = affidavitReconciliationSettings;
            if (managedAffidavitTemplate) {
                return this.fromRef(ctx, managedAffidavitTemplate);
            }
            if (!managedAffidavitTemplateStoragePath) {
                return;
            }
            const template = yield this.fromStoragePathAndPublisher(ctx, managedAffidavitTemplateStoragePath, publisherRef, `Automated Affidavit — ${sourceName}`);
            yield source.updateAutomatedAffidavitConfiguration(Object.assign(Object.assign({}, affidavitReconciliationSettings), { managedAffidavitTemplate: template.ref }));
            return template;
        });
    }
    static fromNoticeTypeNotarized(ctx, noticeType, publisher) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!noticeType) {
                return;
            }
            const { affidavitReconciliationSettings } = noticeType;
            if (!affidavitReconciliationSettings) {
                return;
            }
            const { managedAffidavitTemplateStoragePath, managedAffidavitTemplate } = affidavitReconciliationSettings;
            if (managedAffidavitTemplate) {
                return this.fromRef(ctx, managedAffidavitTemplate);
            }
            if (!managedAffidavitTemplateStoragePath) {
                return;
            }
            const template = yield this.fromStoragePathAndPublisher(ctx, managedAffidavitTemplateStoragePath, publisher.ref, `Automated Affidavit — ${noticeType.label}`);
            const { allowedNotices = [] } = publisher.modelData;
            const newNoticeTypes = allowedNotices.map(nt => {
                if (nt.value === noticeType.value) {
                    return Object.assign(Object.assign({}, nt), { affidavitReconciliationSettings: Object.assign(Object.assign({}, affidavitReconciliationSettings), { managedAffidavitTemplate: template.ref }) });
                }
                return nt;
            });
            yield publisher.updateFilingTypes(newNoticeTypes);
            return template;
        });
    }
}
