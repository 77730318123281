import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { Label } from 'lib/components/Label';
import React from 'react';
export function ScheduleChangesFilterDialog({ onUpdate, value }) {
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-3" }, { children: [_jsx("p", Object.assign({ className: "uppercase text-column-gray-300 text-xs font-bold border-b border-column-gray-100 pb-3" }, { children: "Filter by" })), _jsx(ColumnSelect, { id: "schedule-changes-pub-date-filter-type", labelText: "Publication date", onChange: newValue => {
                    if (newValue === 'all') {
                        onUpdate(Object.assign(Object.assign({}, value), { publicationDate: { type: 'all' } }));
                    }
                    else {
                        onUpdate(Object.assign(Object.assign({}, value), { publicationDate: { type: 'specific', date: new Date() } }));
                    }
                }, options: [
                    { label: 'All', value: 'all' },
                    { label: 'Specific date', value: 'specific' }
                ], value: value.publicationDate.type }), value.publicationDate.type === 'specific' && (_jsx("div", Object.assign({ className: "my-2" }, { children: _jsxs(Label, Object.assign({ id: "schedule-changes-pub-date-filter-date" }, { children: ["Choose a date", _jsx(ColumnDatePicker, { className: "p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary", format: "MMM dd, yyyy", momentFormat: "MMM DD, YYYY", placeholderText: "Filter Date", value: value.publicationDate.date, onChange: newDate => onUpdate(Object.assign(Object.assign({}, value), { publicationDate: {
                                    type: 'specific',
                                    date: newDate !== null && newDate !== void 0 ? newDate : new Date()
                                } })) })] })) }))), _jsx(ColumnSelect, { id: "schedule-changes-deadline-date-filter-type", labelText: "Deadline date", onChange: newValue => {
                    if (newValue === 'all') {
                        onUpdate(Object.assign(Object.assign({}, value), { deadlineDate: { type: 'all' } }));
                    }
                    else {
                        onUpdate(Object.assign(Object.assign({}, value), { deadlineDate: { type: 'specific', date: new Date() } }));
                    }
                }, options: [
                    { label: 'All', value: 'all' },
                    { label: 'Specific date', value: 'specific' }
                ], value: value.deadlineDate.type }), value.deadlineDate.type === 'specific' && (_jsx("div", Object.assign({ className: "my-2" }, { children: _jsxs(Label, Object.assign({ id: "schedule-changes-deadline-date-filter-date" }, { children: ["Choose a date", _jsx(ColumnDatePicker, { className: "p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary", format: "MMM dd, yyyy", momentFormat: "MMM DD, YYYY", placeholderText: "Filter Date", value: value.deadlineDate.date, onChange: newDate => onUpdate(Object.assign(Object.assign({}, value), { deadlineDate: {
                                    type: 'specific',
                                    date: newDate !== null && newDate !== void 0 ? newDate : new Date()
                                } })) })] })) }))), _jsx(ColumnSelect, { id: "schedule-changes-publishing-on-filter", labelText: "Publishing?", onChange: newValue => {
                    if (newValue === 'any') {
                        onUpdate(Object.assign(Object.assign({}, value), { publish: null }));
                    }
                    else if (newValue === 'yes') {
                        onUpdate(Object.assign(Object.assign({}, value), { publish: true }));
                    }
                    else if (newValue === 'no') {
                        onUpdate(Object.assign(Object.assign({}, value), { publish: false }));
                    }
                }, options: [
                    { label: 'Any', value: 'any' },
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' }
                ], value: value.publish ? 'yes' : value.publish === false ? 'no' : 'any' })] })));
}
