export class ComboSerializer {
    constructor(serializers) {
        this.serializers = serializers;
    }
    canSerialize(val) {
        return this.serializers.some(s => s.canSerialize(val));
    }
    canDeserialize(val) {
        return this.serializers.some(s => s.canDeserialize(val));
    }
    serialize(val) {
        const s = this.serializers.find(s => s.canSerialize(val));
        if (!s) {
            throw new Error(`Cannot serialize value: ${val}`);
        }
        return s.serialize(val);
    }
    deserialize(ctx, val) {
        const s = this.serializers.find(s => s.canDeserialize(val));
        if (!s) {
            throw new Error(`Cannot deserialize value: ${val}`);
        }
        return s.deserialize(ctx, val);
    }
}
