var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { getSyncEventsForNotice } from 'lib/notice/helpers';
export const useGetNoticeSyncEvents = (notice) => {
    const [noticeSyncEvents, setNoticeSyncEvents] = useState([]);
    useEffect(() => {
        const fetchNoticeSyncEvents = () => __awaiter(void 0, void 0, void 0, function* () {
            const ctx = getFirebaseContext();
            const syncEvents = yield getSyncEventsForNotice(ctx, ctx.userNoticesRef().doc(notice.id), {
                sortByCreatedAtDirection: 'desc'
            });
            setNoticeSyncEvents(syncEvents);
        });
        void fetchNoticeSyncEvents();
    }, [notice.id]);
    return noticeSyncEvents;
};
