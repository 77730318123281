import { hasProperties, isObject, isSerializedField } from './typeCheckers';
export const isRef = (val) => {
    return (isObject(val) &&
        hasProperties(val, { name: 'path', type: 'string' }, { name: 'id', type: 'string' }, { name: 'get', type: 'function' }));
};
export class RefSerializer {
    canSerialize(val) {
        return isRef(val);
    }
    canDeserialize(val) {
        return (isObject(val) &&
            isSerializedField(val, 'ref') &&
            hasProperties(val, { name: 'path', type: 'string' }));
    }
    serialize(val) {
        return {
            __type: 'ref',
            path: val.path
        };
    }
    deserialize(ctx, val) {
        return ctx.doc(val.path);
    }
}
