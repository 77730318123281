import { jsx as _jsx } from "react/jsx-runtime";
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
/**
 * Generalized tab component that is used to organize pages
 */
export default function TabGroup({ onClickTab, className, activeTab, tabs, id }) {
    return (_jsx(Tab.Group, Object.assign({ onChange: (index) => {
            if (tabs[index].enabled) {
                onClickTab(tabs[index]);
            }
        } }, { children: _jsx(Tab.List, Object.assign({ className: classNames('w-full border-b border-column-gray-100', className), id: id }, { children: tabs.map((tab, index) => (_jsx(Tab, Object.assign({ className: classNames('ml-6 py-6 focus:outline-none', {
                    'cursor-not-allowed': !tab.enabled
                }) }, { children: _jsx("span", Object.assign({ className: classNames('text-base font-medium py-6', {
                        'text-column-primary-600 border-b-2 border-column-primary-500': activeTab.id === tab.id
                    }, {
                        'text-column-gray-500': activeTab.id !== tab.id && tab.enabled
                    }, {
                        'hover:text-column-primary-600': tab.enabled
                    }, {
                        'text-column-gray-300 cursor-not-allowed': !tab.enabled
                    }) }, { children: tab.label })) }), index))) })) })));
}
