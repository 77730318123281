import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TextField } from 'lib/components/TextField';
import PlacementActions, { selectHeaderText } from 'redux/placement';
export function NoticeTitleField({ required = false }) {
    const dispatch = useAppDispatch();
    const headerText = useAppSelector(selectHeaderText);
    return (_jsx(TextField, { id: "notice-header", labelText: "Title", value: headerText, onChange: newHeaderText => {
            dispatch(PlacementActions.setHeaderText(newHeaderText));
        }, required: required }));
}
