import { jsx as _jsx } from "react/jsx-runtime";
import CheckboxInput from '../../Checkbox/CheckboxInput';
export function SelectableHeaderCell({ selectable, rows }) {
    if (!selectable) {
        return null;
    }
    const { selectedItems, serializeItemToUniqueId, isSelectDisabled } = selectable;
    const selectableRows = rows.filter(row => !isSelectDisabled || !isSelectDisabled(row));
    const allSelected = !!selectableRows.length &&
        selectableRows.every(row => selectedItems.has(serializeItemToUniqueId(row)));
    return (_jsx("th", Object.assign({ className: "pr-4" }, { children: _jsx(CheckboxInput, { id: "select-all-checkbox", checked: allSelected, onChange: checked => {
                selectable.onSelectItems(rows.filter(row => !selectable.isSelectDisabled ||
                    !selectable.isSelectDisabled(row)), checked);
            }, labelText: "" }) })));
}
export function SelectableRowCell({ selectable, rowData }) {
    if (!selectable) {
        return null;
    }
    const { onSelectItems: onSelect, serializeItemToUniqueId, selectedItems } = selectable;
    const uniqueId = serializeItemToUniqueId(rowData);
    const isSelected = selectedItems.has(uniqueId);
    return (_jsx("td", Object.assign({ className: "pr-4" }, { children: _jsx(CheckboxInput, { id: `${uniqueId}-checkbox`, checked: isSelected, onChange: checked => onSelect([rowData], checked), labelText: "", disabled: selectable.isSelectDisabled && selectable.isSelectDisabled(rowData) }) })));
}
