import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
export function FileUploadFormattingOption({ id, headerText, descriptionText, onHandleClick, disabled }) {
    return (_jsxs("button", Object.assign({ id: id, className: classNames('group mt-5 h-20 py-4 px-5 flex items-center justify-between rounded border shadow-2 w-full focus:outline-none', {
            'cursor-not-allowed opacity-75 bg-column-gray-25 border-column-gray-100': disabled,
            'cursor-pointer hover:border-column-primary-500 bg-white border-column-gray-150': !disabled
        }), onClick: onHandleClick, disabled: disabled }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start" }, { children: [_jsx("p", Object.assign({ className: classNames('font-medium text-sm', {
                            'text-column-gray-300': disabled,
                            'text-column-gray-600 group-hover:text-column-primary-500': !disabled
                        }) }, { children: headerText })), _jsx("p", Object.assign({ className: classNames('mt-2 font-medium text-xs', {
                            'text-column-gray-300': disabled,
                            'text-column-gray-400': !disabled
                        }) }, { children: descriptionText }))] })), _jsx("div", { className: classNames('h-4 w-4 rounded-full border border-column-gray-200', {
                    'bg-column-gray-50': disabled,
                    'bg-white group-hover:bg-column-primary-100 group-hover:border-column-primary-500': !disabled
                }) })] })));
}
