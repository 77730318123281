var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import htmlToText from 'html-to-text';
import moment from 'moment';
import { SYNC_EVENT_TYPES, NOTICE_AT_DEADLINE, NOTICE_CANCELLED } from '../types/events';
import { getNoticeTypeFromNoticeDataUnwrapped } from '../helpers';
import { isDisplay } from './display';
import { getCustomerAndCustomerOrganization } from './customer';
import { getErrorReporter } from '../utils/errors';
/**
 * Get whether the notice requires upfront payment without using async methods
 * Useful in placement flow before notice has been created
 */
export function syncGetRequiresUpfrontPayment(notice, newspaper, customer, customerOrganization) {
    // Logic hierarchy for requireUpfrontPayment:
    // 1. Notice
    if (notice.requireUpfrontPayment != null) {
        return notice.requireUpfrontPayment;
    }
    // 2. Customer (user x for newspaper y)
    if ((customer === null || customer === void 0 ? void 0 : customer.requireUpfrontPayment) != null) {
        return customer === null || customer === void 0 ? void 0 : customer.requireUpfrontPayment;
    }
    // 3. Customer organization (advertiser org x for newspaper y)
    if ((customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.requireUpfrontPayment) != null) {
        return customerOrganization.requireUpfrontPayment;
    }
    // 4. Custom notice type
    const noticeType = getNoticeTypeFromNoticeDataUnwrapped(notice, newspaper);
    if (noticeType && noticeType.upFrontPayment != null) {
        return noticeType.upFrontPayment;
    }
    // 5. Newspaper (does the newspaper default to requiring upfront payment?)
    if (newspaper.requireUpfrontPayment != null) {
        return newspaper.requireUpfrontPayment;
    }
    return false;
}
export const isSyncEvent = (event) => {
    const { type } = event.data();
    if (SYNC_EVENT_TYPES.includes(type)) {
        return true;
    }
    return false;
};
export const getSyncEventsForNotice = (ctx, notice, options) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeEvents = yield ctx
        .eventsRef()
        .where('notice', '==', notice)
        .orderBy('createdAt', options.sortByCreatedAtDirection)
        .get();
    const syncEvents = noticeEvents.docs.filter(isSyncEvent);
    return syncEvents;
});
export const isEligibleForAsyncDesign = (noticeSnapshot) => isDisplay(noticeSnapshot) || noticeSnapshot.data().postWithoutFormatting;
export const getInternalIdFromNotice = (ctx, notice) => __awaiter(void 0, void 0, void 0, function* () {
    const { filer, newspaper, filedBy } = notice.data();
    const { customer, customerOrganization } = yield getCustomerAndCustomerOrganization(ctx, {
        filer,
        newspaper,
        filedBy
    });
    const customerInternalId = customer === null || customer === void 0 ? void 0 : customer.data().internalID;
    const customerOrgInternalId = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().internalID;
    return { customerInternalId, customerOrgInternalId };
});
export const getNoticeAtDeadlineEvent = (ctx, noticeRef) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeAtDeadlineEvents = (yield ctx
        .eventsRef()
        .where('notice', '==', noticeRef)
        .where('type', '==', NOTICE_AT_DEADLINE)
        .orderBy('createdAt', 'desc')
        .get()).docs;
    if (!noticeAtDeadlineEvents.length) {
        return undefined;
    }
    if (noticeAtDeadlineEvents.length > 1) {
        getErrorReporter().logInfo('In getNoticeAtDeadlineEvent more than one deadline event found. Returning most recent deadline event.', { eventId: noticeAtDeadlineEvents[0].id });
    }
    return noticeAtDeadlineEvents[0];
});
export const getNoticeCancelledEvent = (ctx, noticeRef) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeCancelledEvents = (yield ctx
        .eventsRef()
        .where('notice', '==', noticeRef)
        .where('type', '==', NOTICE_CANCELLED)
        .orderBy('createdAt', 'desc')
        .get()).docs;
    if (!noticeCancelledEvents.length) {
        return undefined;
    }
    if (noticeCancelledEvents.length > 1) {
        const cancelEventIds = noticeCancelledEvents.map(event => event.id);
        getErrorReporter().logAndCaptureWarning('In getNoticeCancelledEvent multiple notice cancelled events found. Returning most recent event.', {
            noticeId: noticeRef.id,
            cancelEventIds: cancelEventIds.join(),
            mostRecentEventId: noticeCancelledEvents[0].id
        });
    }
    return noticeCancelledEvents[0];
});
/**
 * Most notices have many tells that they have not been submitted, but some notices,
 * particularly duplicate notices, appear with many of the properties that only submitted
 * notices otherwise have. One of the few properties that does not get copied over to a
 * duplicated notice is the noticeStatus, which is why it's used as the determinative
 * property here.
 */
export const noticeIsSubmitted = (notice) => {
    const { noticeStatus } = notice.data();
    return !!noticeStatus;
};
export const getNoticeText = (notice) => {
    const { confirmedHtml, unusedConfirmedHtml } = notice.data();
    const noticeHtml = confirmedHtml || unusedConfirmedHtml || '';
    // Remove any base64 data URLs from the payload before deciding on the
    // max length. This regex replaces 'src="..."' attributes as well as
    // 'data-mce-src="..."' attributes.
    const noticeHtmlWithoutImageSrcs = noticeHtml.replace(/src="data:.+?"/g, '');
    return htmlToText.fromString(noticeHtmlWithoutImageSrcs);
};
/**
 * Gets the draft that was just copied to this notice, if any.
 * @param noticeSnap
 */
export const getJustSubmittedDraft = (noticeSnap, drafts) => {
    var _a, _b;
    const noticeLastModifiedDate = ((_a = noticeSnap.data()) === null || _a === void 0 ? void 0 : _a.editedAt) || ((_b = noticeSnap.data()) === null || _b === void 0 ? void 0 : _b.confirmedAt);
    /**
     * The draft that was just copied to the notice is the one with
     * the same last modified date as the notice
     */
    const justSubmittedDraft = !!noticeLastModifiedDate &&
        drafts.find(draft => {
            var _a;
            const draftEditedDate = (_a = draft.data()) === null || _a === void 0 ? void 0 : _a.editedAt;
            return (!!draftEditedDate &&
                Math.abs(moment(noticeLastModifiedDate.toDate()).diff(moment(draftEditedDate.toDate()), 'seconds')) < 1);
        });
    return justSubmittedDraft;
};
