var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDisplayName } from 'lib/helpers';
import { safeAsync } from 'lib/safeWrappers';
import { ColumnService } from 'lib/services/directory';
import { logAndCaptureException } from 'utils';
import ToastActions from 'redux/toast';
export function updateUserInfoSettings({ user, updateData }) {
    return (dispatch) => __awaiter(this, void 0, void 0, function* () {
        const safeUserUpdate = safeAsync(() => user.ref.update(Object.assign(Object.assign({}, updateData), { name: getDisplayName(updateData.firstName, updateData.lastName) })));
        const { error } = yield safeUserUpdate();
        if (error) {
            logAndCaptureException(ColumnService.SETTINGS_MANAGEMENT, error, 'Failed to update user settings', {
                user: user.id
            });
            dispatch(ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Failed to update user info. Please try again.'
            }));
            return;
        }
        dispatch(ToastActions.toastSuccess({
            headerText: 'Success',
            bodyText: 'User info updated successfully.'
        }));
    });
}
export function updateUserNotificationsSettings({ user, newNotifications, organizationLevelNotifications }) {
    return (dispatch) => __awaiter(this, void 0, void 0, function* () {
        const { notifications: oldNotifications } = user.data();
        const update = Object.assign({ notifications: Object.assign(Object.assign({}, oldNotifications), newNotifications) }, (organizationLevelNotifications
            ? {
                receiveOrganizationNotifications: organizationLevelNotifications
            }
            : {}));
        const safeUserUpdate = safeAsync(() => user.ref.update(update));
        const { error } = yield safeUserUpdate();
        if (error) {
            logAndCaptureException(ColumnService.SETTINGS_MANAGEMENT, error, 'Failed to update user notifications', {
                user: user.id
            });
            dispatch(ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Failed to update notifications settings. Please try again.'
            }));
            return;
        }
        dispatch(ToastActions.toastSuccess({
            headerText: 'Success',
            bodyText: 'Notifications settings updated successfully.'
        }));
    });
}
