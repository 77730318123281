var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { exists } from 'lib/types';
import { InvoiceStatus, MailStatus, State } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { ExternalLinkIcon } from 'icons/index';
import EModal from 'components/modals/EModal';
function MailTable({ invoiceSnap, isPublisher, notice }) {
    const [affidavitSnap, setAffidavitSnap] = useState();
    const [mailSnapshots, setMailSnapshots] = useState();
    const [invoiceMailings, setInvoiceMailings] = useState([]);
    const [mailAffidavitsOutsideColumn, setMailAffidavitsOutsideColumn] = useState(false);
    /**
     * Use an effect to subscribe to the mail collection for this notice.
     */
    useEffect(() => {
        const mailSnapshotSubscription = getFirebaseContext()
            .userNoticesRef()
            .doc(notice.id)
            .collection('mail')
            .onSnapshot(mailQuery => {
            setMailSnapshots(mailQuery.docs);
        });
        return () => mailSnapshotSubscription();
    }, [notice === null || notice === void 0 ? void 0 : notice.id]);
    useEffect(() => {
        const getOrganisationFromNotice = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            setMailAffidavitsOutsideColumn(!!((_a = (yield notice.data().newspaper.get()).data()) === null || _a === void 0 ? void 0 : _a.mailAffidavitsOutsideColumn));
        });
        const { invoiceMailings } = notice.data();
        if (invoiceMailings) {
            setInvoiceMailings(invoiceMailings);
        }
        void getOrganisationFromNotice();
    }, [notice === null || notice === void 0 ? void 0 : notice.id]);
    const isInvoicePaymentInitiatedOrPaid = () => exists(invoiceSnap) &&
        // TODO: Should this include InvoiceStatus.partially_refunded.value https://columnpbc.atlassian.net/browse/IT-4424
        [InvoiceStatus.paid.value, InvoiceStatus.initiated.value].includes(invoiceSnap.data().status);
    if (!mailSnapshots && !invoiceMailings.length)
        return null;
    const getStateLine = (mail) => {
        var _a;
        return `${mail.address.address_city}, 
  ${typeof mail.address.address_state === 'number'
            ? (_a = State.by_value(mail.address.address_state)) === null || _a === void 0 ? void 0 : _a.label
            : mail.address.address_state} 
  ${mail.address.address_zip}`;
    };
    function getMailStatusLine(mail) {
        const { url } = mail;
        return (_jsxs(_Fragment, { children: [url && (_jsxs("p", Object.assign({ className: "text-gray-700" }, { children: ["Mail Scheduled", ' ', _jsx("button", Object.assign({ onClick: () => window.open(url) }, { children: _jsx(ExternalLinkIcon, { className: 'stroke-current text-gray-700 w-4 h-4' }) }))] }))), mail.expected_delivery_date && (_jsxs("p", Object.assign({ className: "text-gray-700" }, { children: ["Expected Delivery", ' ', moment(mail.expected_delivery_date).format('MM/DD/YYYY')] })))] }));
    }
    function getCourthouseStatus(affidavitSnapshot) {
        var _a;
        const { expected_delivery_date, mailStatus, url } = affidavitSnapshot.data();
        return (_jsxs(_Fragment, { children: [mailStatus
                    ? (_a = MailStatus.by_value(mailStatus)) === null || _a === void 0 ? void 0 : _a.label
                    : !notice.data().affidavit
                        ? 'Waiting for affidavit upload...'
                        : !isInvoicePaymentInitiatedOrPaid()
                            ? 'Waiting for invoice payment...'
                            : '', url && (_jsxs("p", Object.assign({ className: "text-gray-700" }, { children: ["Mail Scheduled", ' ', _jsx("button", Object.assign({ onClick: () => window.open(url) }, { children: _jsx(ExternalLinkIcon, { className: 'stroke-current text-gray-700 w-4 h-4' }) }))] }))), expected_delivery_date && (_jsxs("p", Object.assign({ className: "text-gray-700" }, { children: ["Expected Delivery", ' ', moment(expected_delivery_date).format('MM/DD/YYYY')] })))] }));
    }
    const mailMobileView = (mail, i, isInvoice = false) => {
        return (_jsxs("div", Object.assign({ className: "text-gray-700 text-sm md:hidden" }, { children: [_jsx("div", { className: "border-b border-gray-300 w-full mb-2 mt-5" }), _jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1" }, { children: "address" })), _jsx("div", Object.assign({ className: "leading-4" }, { children: mail.name })), _jsx("div", Object.assign({ className: "leading-4" }, { children: mail.address.address_line1 })), _jsx("div", Object.assign({ className: "leading-4" }, { children: getStateLine(mail) }))] }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1" }, { children: "document" })), _jsx("div", { children: isInvoice ? 'Invoice ' : 'Affidavit' })] }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1" }, { children: "copies" })), _jsx("div", { children: mail.copies || '1' })] })] })), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1 mt-2" }, { children: "status" })), _jsx("div", { children: mailAffidavitsOutsideColumn
                                ? 'Mailing outside of Column'
                                : getMailStatusLine(mail) })] })] }), `mail-row${i}`));
    };
    const courthouseMobileView = (affidavitSnapshot, i) => {
        return (_jsxs("div", Object.assign({ className: "text-sm text-gray-700 md:hidden" }, { children: [_jsx("div", { className: "border-b border-gray-300 w-full mb-2 mt-5" }), _jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1" }, { children: "address" })), _jsx("div", Object.assign({ className: "leading-4" }, { children: affidavitSnapshot.data().name })), _jsx("div", Object.assign({ className: "leading-4" }, { children: affidavitSnapshot.data().address.address_line1 })), _jsx("div", Object.assign({ className: "leading-4" }, { children: getStateLine(affidavitSnapshot.data()) }))] }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1" }, { children: "document" })), _jsx("div", { children: "Affidavit" })] }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1" }, { children: "copies" })), _jsx("div", { children: affidavitSnapshot.data().copies })] })] })), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "uppercase font-light mb-1 mt-2" }, { children: "status" })), _jsx("div", { children: mailAffidavitsOutsideColumn
                                ? 'Mailing outside of Column'
                                : getCourthouseStatus(affidavitSnapshot) })] })] }), `courthouse-row${i}`));
    };
    return (_jsxs("article", Object.assign({ className: "mb-4" }, { children: [_jsx("h5", Object.assign({ className: "text-lg text-column-gray-500 font-medium" }, { children: "Mail Requests" })), _jsxs("div", { children: [!(mailSnapshots === null || mailSnapshots === void 0 ? void 0 : mailSnapshots.length) && !invoiceMailings.length ? (_jsx("h4", Object.assign({ className: "text-base text-column-gray-400" }, { children: isPublisher
                            ? 'No print affidavit requested.'
                            : 'No mailed affidavits requested' }))) : (_jsxs(_Fragment, { children: [_jsxs("div", { children: [mailSnapshots &&
                                        mailSnapshots
                                            .filter((doc) => !doc.data().isCourthouse)
                                            .map((affidavitSnapshot, i) => mailMobileView(affidavitSnapshot.data(), i)), mailSnapshots &&
                                        mailSnapshots
                                            .filter((doc) => doc.data().isCourthouse)
                                            .map((affidavitSnapshot, i) => courthouseMobileView(affidavitSnapshot, i)), invoiceMailings.length
                                        ? invoiceMailings.map((mail, i) => mailMobileView(mail, i, true))
                                        : ''] }), _jsxs("table", Object.assign({ className: "hidden md:table min-w-full divide-y divide-gray-200" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, { children: "Address" })), _jsx("th", Object.assign({ className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, { children: "Document" })), _jsx("th", Object.assign({ className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, { children: "Copies" })), _jsx("th", Object.assign({ className: "px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, { children: "Status" }))] }) }), _jsxs("tbody", Object.assign({ className: "bg-white divide-y divide-gray-200", "x-max": "1" }, { children: [mailSnapshots &&
                                                mailSnapshots
                                                    .filter((doc) => !doc.data().isCourthouse)
                                                    .map((affidavitSnapshot, i) => (_jsxs("tr", { children: [_jsxs("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700" }, { children: [_jsx("b", { children: affidavitSnapshot.data().name }), _jsx("br", {}), affidavitSnapshot.data().address.address_line1, _jsx("br", {}), affidavitSnapshot.data().address.address_line2, _jsx("br", {}), getStateLine(affidavitSnapshot.data())] })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: "Affidavit" })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: affidavitSnapshot.data().copies })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: mailAffidavitsOutsideColumn
                                                                ? 'Mailing outside of Column'
                                                                : getMailStatusLine(affidavitSnapshot.data()) }))] }, `${i}-mailing-address`))), mailSnapshots &&
                                                mailSnapshots
                                                    .filter((doc) => doc.data().isCourthouse)
                                                    .map((affidavitSnapshot, i) => (_jsxs("tr", { children: [_jsxs("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700" }, { children: [_jsx("b", { children: affidavitSnapshot.data().name }), _jsx("br", {}), affidavitSnapshot.data().address.address_line1, _jsx("br", {}), getStateLine(affidavitSnapshot.data())] })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: "Affidavit" })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: affidavitSnapshot.data().copies })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: mailAffidavitsOutsideColumn
                                                                ? 'Mailing outside of Column'
                                                                : getCourthouseStatus(affidavitSnapshot) }))] }, `${i}-courthouse-address`))), invoiceMailings.map((invoiceMail, i) => (_jsxs("tr", { children: [_jsxs("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700" }, { children: [_jsx("b", { children: invoiceMail.name }), _jsx("br", {}), invoiceMail.address.address_line1, _jsx("br", {}), getStateLine(invoiceMail)] })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: "Invoice" })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: '1' })), _jsx("td", Object.assign({ className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700" }, { children: mailAffidavitsOutsideColumn
                                                            ? 'Mailing outside of Column'
                                                            : getMailStatusLine(invoiceMail) }))] }, `${i}-courthouse-address`)))] }))] }))] })), affidavitSnap && (_jsx(EModal, { setOpen: () => setAffidavitSnap(undefined), header: "SEND PRINT AFFIDAVIT?", body: "e-notice will automatically send the paper affidavit on your behalf via snail mail. Once you click this button you do not need to take further action.", buttonText: "Confirm", onConfirm: () => affidavitSnap.ref
                            .update({
                            mailStatus: MailStatus.initiated.value
                        })
                            .then(() => setAffidavitSnap(undefined)) }))] })] })));
}
export default MailTable;
