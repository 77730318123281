import { floatToP2Float } from '../ui';
export const priceIowaForm = options => {
    const { applicableRate // This contains the per line rate
     } = options;
    // Iowa forms need to be manually priced at invoice creation
    // so we just use this default value as a placeholder
    // for easier calculation
    const DEFAULT_IOWA_LINES = 1;
    return floatToP2Float(DEFAULT_IOWA_LINES * applicableRate);
};
