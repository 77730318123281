export const AutomatedAffidavitNotaries = {
    pam_baez: {
        value: 1,
        key: 'pam_baez',
        label: 'Pam Baez',
        email: 'p.baez820@gmail.com',
        state: 'Florida',
        county: 'Orange',
        proof_id: 'us_npqmz4r',
        active: true
    },
    rachael_schultz: {
        value: 2,
        key: 'rachael_schultz',
        label: 'Rachael Schultz',
        email: 'rschultz1974@hotmail.com',
        state: 'Florida',
        county: 'Charlotte',
        proof_id: 'us_nkjy86j',
        active: true
    },
    emmie_atwood: {
        value: 3,
        key: 'emmie_atwood',
        label: 'Emmie Atwood',
        email: 'emmie@column.us',
        state: 'Texas',
        county: 'Travis',
        proof_id: 'us_d7bk57r',
        active: true
    },
    shannea: {
        value: 4,
        key: 'shannea',
        label: 'Shannea Holmes',
        email: 'shomles85@icloud.com',
        state: 'New Jersey',
        county: 'Hudson',
        proof_id: 'us_d5686g5',
        active: true
    },
    mitzi_hill: {
        value: 5,
        key: 'mitzi_hill',
        label: 'Mitzi Hill',
        email: 'mitzihill13@gmail.com',
        state: 'Texas',
        county: 'Ellis',
        proof_id: 'us_ng9wzyb',
        active: false
    },
    nicole_burkholder: {
        value: 6,
        key: 'nicole_burkholder',
        label: 'Nicole Burkholder',
        email: 'njburkholder@gmail.com',
        state: 'Pennsylvania',
        county: 'Lancaster',
        proof_id: 'us_dwgopeq',
        active: true
    },
    robert_hollie_iii: {
        value: 7,
        key: 'robert_hollie_iii',
        label: 'Robert Hollie III',
        email: 'kevin@column.us',
        state: 'Texas',
        county: 'Bexar',
        proof_id: 'us_qjbag5m',
        active: true
    },
    destiny_jackson: {
        value: 8,
        key: 'destiny_jackson',
        label: 'Destiny Jackson',
        email: 'destinykjackson@gmail.com',
        state: 'Florida',
        county: 'Duval',
        proof_id: 'us_www998z',
        active: true
    },
    lauren_predmore: {
        value: 9,
        key: 'lauren_predmore',
        label: 'Lauren Predmore',
        email: 'lauren_predmore@yahoo.com',
        state: 'New York',
        county: 'Orange',
        proof_id: 'us_nowyb9o',
        active: true
    }
};
export const stringIsNotaryKey = (signerKeyString) => {
    return Object.keys(AutomatedAffidavitNotaries).some(key => signerKeyString === key);
};
