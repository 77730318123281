import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { XIcon } from 'icons';
function FreeformCModal({ setOpen, header, body, children, id, noExitOutsideModal, afterExit, width, customStyle, maxWidth, disableOverflow }) {
    const handleExit = () => {
        if (noExitOutsideModal)
            return;
        setOpen && setOpen(false);
    };
    return (_jsx("div", Object.assign({ id: id, className: `fixed z-100 inset-0 ${!disableOverflow ? 'overflow-y-auto' : ''}` }, { children: _jsxs("div", Object.assign({ className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" }, { children: [_jsx("div", Object.assign({ className: "fixed inset-0 transition-opacity", onClick: handleExit }, { children: _jsx("div", { className: "absolute inset-0 bg-black opacity-50" }) })), _jsx("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }), "\u200B", _jsxs("div", Object.assign({ className: `inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth || 'sm:max-w-lg'} ${!!width && width} sm:w-full sm:p-6`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: `${id}-inner` }, { children: [setOpen && (_jsx("div", Object.assign({ className: "absolute top-0 right-0 pt-5 md:pt-6 pr-4" }, { children: _jsxs("button", Object.assign({ type: "button", onClick: () => {
                                    setOpen(false);
                                    afterExit && afterExit();
                                }, className: "bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Close" })), _jsx(XIcon, { className: "h-6 w-6" })] })) }))), _jsx("div", Object.assign({ className: "sm:flex sm:items-start" }, { children: _jsxs("div", Object.assign({ className: `sm:mt-0 sm:mx-4 text-left ${customStyle}` }, { children: [header && (_jsx("h3", Object.assign({ className: "text-lg leading-6 font-medium text-gray-900", id: "modal-headline" }, { children: header }))), _jsx("div", Object.assign({ className: "mt-2" }, { children: _jsx("span", Object.assign({ className: "text-sm font-medium leading-5 text-gray-700" }, { children: body })) }))] })) })), _jsx("div", Object.assign({ className: "mt-3 sm:mt-0 sm:mx-4 text-left" }, { children: children }))] }))] })) })));
}
export default FreeformCModal;
