import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CheckCircleIcon, XMarkIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { ConfirmationStatus } from 'lib/enums';
export default function ConfirmationStatusBadge({ confirmationStatus, isCancelled }) {
    const getBadgeInformation = () => {
        if (isCancelled) {
            return {
                badgeStatus: undefined,
                badgeText: 'Cancelled',
                badgeIcon: _jsx(XMarkIcon, { className: "w-4 h-4 mr-1" })
            };
        }
        if (confirmationStatus === ConfirmationStatus.Confirmed) {
            return {
                badgeStatus: 'success',
                badgeText: 'Confirmed',
                badgeIcon: _jsx(CheckCircleIcon, { className: "w-4 h-4 mr-1" })
            };
        }
        if (confirmationStatus === ConfirmationStatus.AwaitingConfirmation) {
            return {
                badgeStatus: 'warning',
                badgeText: 'Reviewing',
                badgeIcon: _jsx(ClockIcon, { className: "w-4 h-4 mr-1" })
            };
        }
        return {
            badgeStatus: 'warning',
            badgeText: 'Awaiting Review',
            badgeIcon: _jsx(ClockIcon, { className: "w-4 h-4 mr-1" })
        };
    };
    const { badgeStatus, badgeText, badgeIcon } = getBadgeInformation();
    return (_jsx(Badge, Object.assign({ status: badgeStatus, startIcon: badgeIcon, size: "lg" }, { children: badgeText })));
}
