import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import LoadingState from 'components/LoadingState';
import NotesDisplay from './NotesDisplay';
import { ACTIVE_NOTES_VIEW } from './notesHelpers';
import NotesEditor from './NotesEditor';
import { NoteViewToggle } from './NoteViewToggle';
export default function NotesTab({ noteTopic, notes }) {
    const [currentView, setCurrentView] = useState(ACTIVE_NOTES_VIEW);
    if (!notes)
        return _jsx(LoadingState, {});
    const isActiveView = currentView === ACTIVE_NOTES_VIEW;
    const sortedNotes = notes.sort((a, b) => {
        if (a.data().createdAt < b.data().createdAt)
            return -1;
        if (a.data().createdAt > b.data().createdAt)
            return 1;
        return 0;
    });
    const filteredNotes = sortedNotes.filter(note => {
        if (isActiveView) {
            return note.data().status === 'public';
        }
        return note.data().status === 'archived';
    });
    return (_jsxs("div", Object.assign({ className: "px-6 flex-1 flex flex-col overflow-scroll" }, { children: [_jsx(NotesDisplay, { currentView: currentView, notes: filteredNotes }), isActiveView && _jsx(NotesEditor, { noteTopic: noteTopic }), _jsx(NoteViewToggle, { onChangeView: setCurrentView, currentView: currentView })] })));
}
