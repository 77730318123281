import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
export function Badge({ children, status, shape = 'pill', startIcon, endIcon, size = 'sm', onClick }) {
    const layoutClasses = classNames('inline-flex items-center space-x-1', {
        'px-2 py-0.25': size === 'sm',
        'px-2.5 py-0.25': size === 'md',
        'px-3 py-1': size === 'lg'
    });
    const fontClasses = classNames('whitespace-no-wrap font-medium', {
        'text-xs': size === 'sm',
        'text-sm': size === 'md' || size === 'lg'
    });
    const statusVariantClasses = classNames({
        'bg-column-gray-50 text-column-gray-800': !status,
        'bg-column-red-50 text-column-red-700': status === 'critical',
        'bg-column-green-50 text-column-green-800': status === 'success',
        'bg-column-primary-50 text-column-primary-500': status === 'info',
        'bg-column-yellow-100 text-column-yellow-900': status === 'warning'
    });
    const shapeClasses = classNames({
        'rounded-full': shape === 'pill',
        rounded: shape === 'rectangle'
    });
    const badgeClasses = classNames(layoutClasses, fontClasses, statusVariantClasses, shapeClasses);
    return (_jsxs("span", Object.assign({ className: badgeClasses, onClick: onClick }, { children: [startIcon && _jsx("span", { children: startIcon }), _jsx("span", { children: children }), endIcon && _jsx("span", { children: endIcon })] })));
}
