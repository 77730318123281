var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from 'api';
import { logAndCaptureException } from 'utils';
import { InvoiceType } from 'lib/types/invoices';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';
import { ColumnService } from 'lib/services/directory';
export function getPayInvoicePaymentData(invoiceId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const data = yield api.get(`invoices/${invoiceId}/pay-invoice-data`, undefined, { Authorization: 'Aloh0mor4' });
            const { serializedInvoice } = data;
            if (data.type === InvoiceType.PUBLIC_NOTICE) {
                const invoice = new InvoiceModel(getFirebaseContext(), {
                    serialized: serializedInvoice
                });
                const { serializedAdvertiser } = data;
                const advertiser = new UserModel(getFirebaseContext(), {
                    serialized: serializedAdvertiser
                });
                return Object.assign(Object.assign({}, data), { advertiser,
                    invoice });
            }
            if (data.type === InvoiceType.ORDER) {
                const invoice = new InvoiceModel(getFirebaseContext(), {
                    serialized: serializedInvoice
                });
                const { serializedAdvertiser } = data;
                const advertiser = serializedAdvertiser
                    ? new UserModel(getFirebaseContext(), {
                        serialized: serializedAdvertiser
                    })
                    : undefined;
                return Object.assign(Object.assign({}, data), { invoice,
                    advertiser, orderVersion: invoice.modelData.orderVersion });
            }
        }
        catch (err) {
            logAndCaptureException(ColumnService.PAYMENTS, err, 'Failed to load pay invoice data', {
                invoiceId
            });
        }
    });
}
