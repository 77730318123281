var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Product } from '../enums';
import { isPublisherOrganization } from '../utils/organizations';
import { getOrThrow } from '../utils/refs';
import { AdRateService } from './adRateService';
export class NoticeRateService {
    constructor(ctx) {
        this.ctx = ctx;
        this.collection = ctx.ratesRef();
        this.adRateService = new AdRateService(this.collection, Product.Notice);
    }
    getNewspaperOwnedRatesQuery(newspaper) {
        return this.adRateService.getNewspaperOwnedRatesQuery(newspaper);
    }
    getNewspaperOwnedRateByCodeQuery(newspaper, code) {
        return this.adRateService
            .getNewspaperOwnedRatesQuery(newspaper)
            .where('code', '==', code);
    }
    doesRateCodeExist(code) {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield this.adRateService
                .restrictQueryToRatesOfType(this.collection.where('code', '==', code))
                .get();
            return Boolean(results.size);
        });
    }
    getNewspaperOwnedRates(newspaper) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.adRateService.getNewspaperOwnedRatesQuery(newspaper).get();
        });
    }
    getPublisherOrgRatesQueries(org) {
        return this.adRateService.getPublisherOrgRatesQueries(org);
    }
    getPublisherOrgRates(org) {
        return __awaiter(this, void 0, void 0, function* () {
            const { ownedRatesQuery, allowedRatesQuery } = this.getPublisherOrgRatesQueries(org);
            const ownedRates = yield ownedRatesQuery.get();
            const allowedRates = yield allowedRatesQuery.get();
            return [...ownedRates.docs, ...allowedRates.docs];
        });
    }
    getAdvertiserOrgRates(newspaper, advertiserOrg) {
        return __awaiter(this, void 0, void 0, function* () {
            if (newspaper) {
                // TODO: We should deprecate the 'organizations' feature as it does not support shared rates
                const ownedRatesQuery = this.getNewspaperOwnedRatesQuery(newspaper);
                const allowedRates = yield ownedRatesQuery
                    .where('organizations', 'array-contains', advertiserOrg)
                    .get();
                return allowedRates.docs;
            }
            const allowedRates = yield this.adRateService
                .restrictQueryToRatesOfType(this.collection.where('organizations', 'array-contains', advertiserOrg))
                .get();
            return allowedRates.docs;
        });
    }
    getAdvertiserRates(newspaper, advertiser) {
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: We should deprecate the 'filers' feature as it does not support shared rates
            const ownedRatesQuery = this.getNewspaperOwnedRatesQuery(newspaper);
            const allowedRates = yield ownedRatesQuery
                .where('filers', 'array-contains', advertiser)
                .get();
            return allowedRates.docs;
        });
    }
    /**
     * @param options object containing either userId or orgId
     * @returns a list of rates.
     */
    getAssociatedRates(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { userId, orgId } = options;
            const rateDocs = [];
            if (userId) {
                const userSnap = yield getOrThrow(this.ctx.usersRef().doc(userId));
                const rateSnaps = yield this.adRateService
                    .restrictQueryToRatesOfType(this.collection.where('filers', 'array-contains', userSnap.ref))
                    .get();
                rateDocs.push(...rateSnaps.docs);
            }
            if (orgId) {
                const orgSnap = yield getOrThrow(this.ctx.organizationsRef().doc(orgId));
                if (isPublisherOrganization(orgSnap)) {
                    const rates = yield this.getPublisherOrgRates(orgSnap.ref);
                    rateDocs.push(...rates);
                }
                else {
                    const rates = yield this.getAdvertiserOrgRates(undefined, orgSnap.ref);
                    rateDocs.push(...rates);
                }
            }
            return rateDocs;
        });
    }
}
