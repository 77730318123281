var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'lib/components/Form';
import { TextField } from 'lib/components/TextField';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { exists } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import React from 'react';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { useBooleanFlag } from 'utils/flags';
import { userIsSuper } from 'utils/permissions';
import { CardGridLayout, GridInput, useEditableCard } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
const getHeaderFooterSettingsFromOrg = (organization) => {
    if (!exists(organization)) {
        return null;
    }
    const { headerFormatString, footerFormatString, oneRunHeader, oneRunFooter, displayAdHeader, displayAdFooter, noticeHeaders } = organization.data();
    return {
        headerFormatString,
        footerFormatString,
        oneRunHeader,
        oneRunFooter,
        displayAdHeader,
        displayAdFooter,
        noticeHeaders
    };
};
export function HeadersAndFooters() {
    var _a, _b, _c, _d, _e, _f;
    const activeOrganization = useActiveOrganizationListener();
    const user = useAppSelector(selectUser);
    const headersFlag = useBooleanFlag(LaunchDarklyFlags.ENABLE_CUSTOMISED_HEADERS, false);
    const showHeadersToggle = exists(user) && userIsSuper(user) && headersFlag;
    const currentSettings = getHeaderFooterSettingsFromOrg(activeOrganization);
    const { onChange, onSubmitWrapper, disabled, formSettings, editable } = useEditableCard(currentSettings, {
        shouldDisableEdit: () => !exists(user) || !userIsSuper(user)
    });
    if (!activeOrganization || !formSettings) {
        return null;
    }
    return (_jsx(Form, Object.assign({ id: "publisher-settings-layout-headers-and-footers", onSubmit: () => onSubmitWrapper(() => __awaiter(this, void 0, void 0, function* () {
            yield activeOrganization.ref.update(replaceUndefinedWithDelete(getFirebaseContext(), formSettings));
            return { success: true };
        })), "aria-label": "Headers and Footers" }, { children: _jsxs(CardGridLayout, Object.assign({ header: {
                title: 'Headers and Footers',
                description: 'Control what appears at the top and bottom of notices.'
            }, editable: editable }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "default-header", labelText: "Default headers", value: (_a = formSettings.headerFormatString) !== null && _a !== void 0 ? _a : '', onChange: headerFormatString => onChange({ headerFormatString }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "default-footer", labelText: "Default footers", value: (_b = formSettings.footerFormatString) !== null && _b !== void 0 ? _b : '', onChange: footerFormatString => onChange({ footerFormatString }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "one-run-header", labelText: "One-run header", value: (_c = formSettings.oneRunHeader) !== null && _c !== void 0 ? _c : '', onChange: oneRunHeader => onChange({ oneRunHeader }), disabled: disabled }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(TextField, { id: "one-run-footer", labelText: "One-run footer", value: (_d = formSettings.oneRunFooter) !== null && _d !== void 0 ? _d : '', onChange: oneRunFooter => onChange({ oneRunFooter }), disabled: disabled }) })), _jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Apply headers to display notices?", description: "If enabled, headers will apply to displays as well as liners.", value: !!formSettings.displayAdHeader, onChange: displayAdHeader => onChange({ displayAdHeader }), disabled: disabled }) }), _jsx(GridInput, { children: _jsx(LabeledSwitch, { label: "Apply footers to display notices?", description: "If enabled, footers will apply to displays as well as liners.", value: !!formSettings.displayAdFooter, onChange: displayAdFooter => onChange({ displayAdFooter }), disabled: disabled }) }), showHeadersToggle && (_jsx(SwitchControlledCard, Object.assign({ header: 'Notice Titles', disabled: disabled, labelProps: {
                        label: 'Enable notice titles?',
                        description: 'If enabled, you can add titles with custom formatting above the notice body. InDesign templates must include custom header styles.',
                        value: !!((_e = formSettings.noticeHeaders) === null || _e === void 0 ? void 0 : _e.enabled),
                        onChange: enabled => {
                            onChange({
                                noticeHeaders: Object.assign(Object.assign({}, formSettings.noticeHeaders), { enabled })
                            });
                        }
                    } }, { children: _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(LabeledSwitch, { label: "Require notice titles?", description: "If enabled, notice titles are required for all notices.", value: !!((_f = formSettings.noticeHeaders) === null || _f === void 0 ? void 0 : _f.required), onChange: required => onChange({
                                noticeHeaders: Object.assign(Object.assign({}, formSettings.noticeHeaders), { required })
                            }), disabled: disabled }) })) })))] })) })));
}
