export var NotarizationStatus;
(function (NotarizationStatus) {
    NotarizationStatus["PENDING"] = "pending";
    NotarizationStatus["INITIATED"] = "initiated";
    NotarizationStatus["COMPLETED"] = "completed";
    NotarizationStatus["CANCELLED"] = "cancelled";
})(NotarizationStatus || (NotarizationStatus = {}));
export var NotarizationVendor;
(function (NotarizationVendor) {
    NotarizationVendor["PROOF"] = "proof";
    NotarizationVendor["PUBLIC_OATH"] = "public_oath";
})(NotarizationVendor || (NotarizationVendor = {}));
