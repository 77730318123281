import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import MilestoneConnector from './MilestoneConnector';
import MilestoneNode from './MilestoneNode';
function MilestoneTracker({ milestones }) {
    return (_jsx("div", Object.assign({ className: "w-full flex justify-between items-center space-x-5 xl:space-x-8" }, { children: milestones.map((milestone, index) => (_jsx(MilestoneElementWrapper, { milestoneElement: milestone }, index))) })));
}
function MilestoneElementWrapper({ milestoneElement }) {
    return (_jsxs(_Fragment, { children: [_jsx(MilestoneNode, { milestoneElement: milestoneElement }), milestoneElement.nextConnector && (_jsx(MilestoneConnector, { status: milestoneElement.nextConnector }))] }));
}
export default MilestoneTracker;
