var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { exists } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { getCustomerName } from 'lib/notice/customer';
import { ColumnService } from 'lib/services/directory';
/*
 * Converts a SearchableCustomerRecord (elastic) to CustomerTableData
 */
export const extractCustomerDataFromSearchableCustomerRecord = (customerRecord) => __awaiter(void 0, void 0, void 0, function* () {
    if (customerRecord.archived) {
        return null;
    }
    return {
        customerId: customerRecord.id,
        userName: customerRecord.username,
        userEmail: customerRecord.useremail,
        userOrganizationName: customerRecord.userorganizationname,
        internalId: customerRecord.internalid,
        lastSignInTime: customerRecord.lastsignintime
    };
});
export const extractCustomerDataFromCustomer = (customer) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    if (!exists(customer) || customer.data().archived) {
        return null;
    }
    let userSnap;
    try {
        userSnap = (yield customer.data().user.get());
    }
    catch (err) {
        logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error pulling user for customer', {
            customerId: customer.id,
            newspaperId: customer.data().organization.id,
            userId: (_a = customer.data().user) === null || _a === void 0 ? void 0 : _a.id
        });
        return null;
    }
    if (!exists(userSnap))
        return null;
    return {
        userName: getCustomerName(customer, userSnap, true),
        userEmail: userSnap.data().email,
        userOrganizationName: customer.data().organizationName ||
            userSnap.data().organizationName ||
            '',
        internalId: customer.data().internalID || '',
        customerId: customer.id
    };
});
