var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { RateType } from 'lib/enums';
import RatesTable from '../rates/ratesTable';
const initialData = {
    archived: false,
    description: `New Rate ${new Date().toLocaleDateString()}`,
    rateType: RateType.column_inch.value,
    roundOff: 0,
    minimum: 100,
    rate_0: 500,
    rate_1: 500,
    rate_2: 500
};
function ProductRatesTable({ activeOrganization, productLine }) {
    return (_jsx(RatesTable, { adType: productLine, activeOrganization: activeOrganization, getInitialData: () => __awaiter(this, void 0, void 0, function* () {
            return Object.assign(Object.assign({}, initialData), { product: productLine, organization: activeOrganization.ref });
        }) }));
}
export default ProductRatesTable;
