import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import ArchiveTableItemModal from './ArchiveTableItemModal';
import TableLayoutHeader from './TableLayoutHeader';
import DataTable from './DataTable';
/**
 * Table layout component. Contains header, table, and archive modal.
 * Has lifecycle methods to handle clicking rows and editing rows.
 */
export function TableLayout({ configurable, actionable, filterable, archivable, selectable, creatable, clickable, downloadable, renderRow, noResultsContent, loading, editable, columns, header, data, id, pagination }) {
    var _a;
    const [itemToArchive, setItemToArchive] = useState();
    const [search, setSearch] = useState('');
    const filteredTableData = data.filter(item => filterable.shouldShowTableItem(item, search));
    return (_jsxs(_Fragment, { children: [itemToArchive && archivable && (_jsx(ArchiveTableItemModal, { renderArchiveWarning: archivable.renderWarning, itemToArchive: itemToArchive, onArchive: archivable.onArchive, onClose: () => setItemToArchive(undefined), id: `${id}-archive-modal` })), _jsxs("div", Object.assign({ className: "bg-column-gray-25 rounded-lg flex flex-col", style: { maxHeight: 790 } }, { children: [_jsx(TableLayoutHeader, { configurable: configurable, actionable: actionable, downloadable: downloadable, filterable: filterable, searchable: {
                            enabled: (_a = filterable.searchEnabled) !== null && _a !== void 0 ? _a : true,
                            search,
                            setSearch: (value) => {
                                if (filterable.onSearch) {
                                    filterable.onSearch(value);
                                }
                                setSearch(value);
                            }
                        }, creatable: creatable, header: header, id: `${id}-header` }), _jsx(DataTable, { onArchive: setItemToArchive, archivable: archivable, selectable: selectable, renderRow: renderRow, clickable: clickable, editable: editable, columns: columns, data: filteredTableData, search: search, id: `${id}-table`, noResultsContent: noResultsContent, loading: loading, pagination: pagination })] }))] }));
}
