var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isBulkPaymentV2EnabledOnOrganization } from 'lib/helpers';
import { exists } from 'lib/types';
import { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
export const useBulkPaymentV2EnabledOnOrganization = (activeOrganization) => {
    var _a, _b;
    const [enabled, setEnabled] = useState(isBulkPaymentV2EnabledOnOrganization(activeOrganization, null));
    useEffect(() => {
        var _a, _b;
        const parentId = (_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.id;
        if (!parentId)
            return;
        const unsubscribe = getFirebaseContext()
            .organizationsRef()
            .doc(parentId)
            .onSnapshot((snapshot) => __awaiter(void 0, void 0, void 0, function* () {
            if (!exists(snapshot))
                return;
            setEnabled(isBulkPaymentV2EnabledOnOrganization(activeOrganization, snapshot));
        }));
        return unsubscribe;
    }, [(_b = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.id]);
    return enabled;
};
