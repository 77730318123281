import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect, enumKeyToSelectInput } from 'lib/components/ColumnSelect';
import { RateType } from 'lib/enums';
export default function RateTableFilterDialog({ onUpdate, value }) {
    return (_jsxs("div", { children: [_jsx("p", Object.assign({ className: "uppercase text-column-gray-300 text-xs font-bold mb-3 border-b border-column-gray-100 pb-3" }, { children: "Filter by" })), _jsx(ColumnSelect, { id: "rate-type-filter", labelText: "Rate type", options: [
                    {
                        value: 'all',
                        label: 'All rate types'
                    },
                    enumKeyToSelectInput(RateType.column_inch),
                    enumKeyToSelectInput(RateType.line),
                    enumKeyToSelectInput(RateType.flat),
                    enumKeyToSelectInput(RateType.word_count),
                    enumKeyToSelectInput(RateType.folio),
                    enumKeyToSelectInput(RateType.per_run)
                ], value: String(value.rateType) || 'all', onChange: newValue => {
                    if (newValue === 'all') {
                        onUpdate(Object.assign(Object.assign({}, value), { rateType: null }));
                        return;
                    }
                    const newRateType = RateType.by_value(parseInt(newValue, 10));
                    if (!newRateType) {
                        return;
                    }
                    onUpdate(Object.assign(Object.assign({}, value), { rateType: newRateType.value }));
                }, size: "small" }), _jsx("br", {}), _jsx(ColumnSelect, { id: "run-type-filter", labelText: "Charge per run", options: [
                    {
                        value: 'all',
                        label: 'All'
                    },
                    {
                        value: 'nonRunBased',
                        label: 'Charge the same rate per run'
                    },
                    {
                        value: 'runBased',
                        label: 'Charge different rates per run'
                    }
                ], value: value.runType || 'all', onChange: newValue => onUpdate(Object.assign(Object.assign({}, value), { runType: newValue === 'all'
                        ? null
                        : newValue })), size: "small" })] }));
}
