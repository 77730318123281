var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import Firebase from 'EnoticeFirebase';
import AppLayoutHeader from './AppLayoutHeader';
export default function AppLayoutFrame({ children }) {
    const [user, setUser] = useState(null);
    const [activeOrganization, setActiveOrganization] = useState(null);
    /**
     * Listen for auth state changes and update the user state accordingly.
     */
    useEffect(() => {
        const authSubscription = Firebase.auth().onAuthStateChanged((userAuth) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const userID = userAuth === null || userAuth === void 0 ? void 0 : userAuth.uid;
            if (userID) {
                const userSnapshot = yield getFirebaseContext()
                    .usersRef()
                    .doc(userID)
                    .get();
                if (exists(userSnapshot)) {
                    setUser(userSnapshot);
                    const activeOrganizationSnapshot = (yield ((_b = (_a = userSnapshot.data()) === null || _a === void 0 ? void 0 : _a.activeOrganization) === null || _b === void 0 ? void 0 : _b.get())) || null;
                    if (exists(activeOrganizationSnapshot)) {
                        setActiveOrganization(activeOrganizationSnapshot);
                    }
                }
            }
            else {
                setUser(null);
                setActiveOrganization(null);
            }
        }));
        return () => authSubscription();
    }, []);
    return (_jsxs("div", Object.assign({ className: "flex flex-col h-screen" }, { children: [_jsx(AppLayoutHeader, { activeOrganization: activeOrganization, user: user }), _jsx("main", Object.assign({ className: "bg-column-gray-25 flex-1 w-screen overflow-auto" }, { children: children }))] })));
}
