import stringify from 'safe-stable-stringify';
import { isRef } from '../model/refs';
export const REF_PATH_REPLACER = {
    shouldReplace: val => isRef(val),
    replace: val => val.path
};
/**
 * Safely stringifies an object with stable key ordering, more suitable
 * than JSON.stringify for hashing complex objects.
 */
export const safeStringify = (val, options) => {
    if (!options) {
        return stringify(val);
    }
    return stringify(val, (key, value) => {
        var _a;
        const replacer = (_a = options.replaceOptions) === null || _a === void 0 ? void 0 : _a.replacer;
        if (replacer === null || replacer === void 0 ? void 0 : replacer.shouldReplace(value)) {
            return replacer.replace(value);
        }
        return value;
    });
};
/**
 * Default equality checks don't correctly handle refs, so we need to proactively
 * replace them with the corresponding paths. This function should be used in place
 * of lodash's isEqual or === operators. Usage of this function is enforced via
 * semgrep in the file use-column-is-equal-for-equality-checks.yaml
 * @param v1 first value to compare
 * @param v2 second value to compare
 * @returns {boolean} true if the objects are equal else false
 */
export const columnObjectsAreEqual = (v1, v2) => {
    const options = {
        replaceOptions: {
            replacer: REF_PATH_REPLACER
        }
    };
    return safeStringify(v1, options) === safeStringify(v2, options);
};
