import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid, Typography, Button, LinearProgress, Fade } from '@material-ui/core';
import AdornedButton from '../AdornedButton';
const styles = (theme) => createStyles({
    root: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(10)
    },
    headerItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2)
    },
    buttonRow: {
        marginTop: theme.spacing(2)
    },
    subheader: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    footer: {
        position: 'fixed',
        zIndex: 10,
        background: 'white',
        bottom: 0,
        width: '100vw',
        left: 0
    },
    buttonWrapper: {
        marginLeft: 60,
        marginBottom: theme.spacing(2)
    },
    button: {
        backgroundColor: '#2D9BDB',
        '&:hover': {
            backgroundColor: '#24415B'
        }
    }
});
const FormStep = ({ classes, title, description, children, forward, back, stepComplete, forwardLabel, saving, id }) => (_jsx("div", Object.assign({ className: classes.root, id: id }, { children: _jsxs(ValidatorForm, Object.assign({ onSubmit: () => forward() }, { children: [_jsx(Grid, Object.assign({ item: true, className: classes.headerItem }, { children: _jsx(Grid, Object.assign({ container: true, alignItems: "center", spacing: 2 }, { children: _jsx(Grid, Object.assign({ item: true }, { children: _jsx("div", Object.assign({ className: "text-3xl text-gray-800 font-normal" }, { children: title })) })) })) })), description && (_jsx(Grid, Object.assign({ item: true, className: `${classes.headerItem} ${classes.subheader}` }, { children: _jsx(Grid, Object.assign({ container: true, alignItems: "center", spacing: 2 }, { children: _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "h6", className: classes.header }, { children: description })) })) })) }))), children, _jsxs(Grid, Object.assign({ className: classes.footer }, { children: [_jsx(Fade, Object.assign({ in: saving, style: { transitionDelay: '3000ms' }, unmountOnExit: true }, { children: _jsx(LinearProgress, {}) })), _jsx("div", Object.assign({ className: classes.buttonWrapper }, { children: _jsxs("div", Object.assign({ className: classes.buttonRow }, { children: [_jsx(AdornedButton, Object.assign({ id: "next", type: "submit", variant: "contained", color: "primary", className: classes.button, disabled: !stepComplete || saving, loading: !!saving }, { children: forwardLabel || 'Next' })), _jsx(Button, Object.assign({ disabled: !back, className: classes.backButton, onClick: () => back && back() }, { children: "Back" }))] })) }))] }))] })) })));
export default withStyles(styles, { withTheme: true })(FormStep);
