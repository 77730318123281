var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import api from 'api';
import { getCurrencySymbol } from 'lib/helpers';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useEffect } from 'react';
import InvoiceFormTable from '../billing/oldCreateInvoice/InvoiceFormTable';
import RefundInvoiceModalInnerInvoiceTotalTable from './RefundInvoiceModalInnerInvoiceTotal';
const fetchCompletedRefundLineItems = (invoiceId) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield api.get(`invoice-transactions/${invoiceId}/refund-transactions`);
    if (res.success) {
        return res.refundLineItems;
    }
    return [];
});
export default function RefundInvoiceModalInnerDisplayBox({ notice, newspaper, invoice, lineItemValues, invoicePubLineItemDiffs, totalRefundedInCentsFromPriorInvoiceTransactions, setTotalRefundedInCentsFromPriorInvoiceTransactions }) {
    const currency = getCurrencySymbol(newspaper.data().currency);
    const { value: priorRefundsLineItems } = useAsyncEffect({
        fetchData: () => fetchCompletedRefundLineItems(invoice.id),
        dependencies: [invoice.id]
    });
    useEffect(() => {
        if (priorRefundsLineItems === null) {
            setTotalRefundedInCentsFromPriorInvoiceTransactions(0);
        }
        if (!priorRefundsLineItems || priorRefundsLineItems.length === 0) {
            setTotalRefundedInCentsFromPriorInvoiceTransactions(0);
        }
        const totalRefundedInCents = priorRefundsLineItems
            ? priorRefundsLineItems.reduce((acc, curr) => {
                var _a;
                return (_a = acc + curr.amount) !== null && _a !== void 0 ? _a : 0;
            }, 0)
            : 0;
        setTotalRefundedInCentsFromPriorInvoiceTransactions(totalRefundedInCents);
    }, [priorRefundsLineItems]);
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: `Paid Invoice Review`,
            description: `Paid invoice for notice ${notice.id} ("${notice.data().referenceId}")`
        } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(InvoiceFormTable, { values: lineItemValues, diffValues: {
                        invoicePubLineItemDiffValues: invoicePubLineItemDiffs,
                        noticePubDateDiffValues: null
                    }, currency: currency, handleChange: () => { }, preventPricingModification: true }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(RefundInvoiceModalInnerInvoiceTotalTable, { notice: notice, invoice: invoice, newspaper: newspaper, refunds: priorRefundsLineItems, totalRefundedInCentsFromPriorInvoiceTransactions: totalRefundedInCentsFromPriorInvoiceTransactions }) }))] })));
}
