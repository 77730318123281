var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import ToastActions from 'redux/toast';
import { Form } from 'lib/components/Form';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import { createNoteFromClient } from 'utils/notes';
import { TextAreaField } from 'lib/components/TextAreaField';
import { NoteAttachmentField } from './NoteAttachmentField';
export default function NotesEditor({ noteTopic }) {
    const dispatch = useAppDispatch();
    const [noteContent, setNoteContent] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [notesAttachment, setNotesAttachment] = useState(null);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const user = useAppSelector(selectUser);
    const addNote = () => __awaiter(this, void 0, void 0, function* () {
        if (!exists(user) || !exists(activeOrganization))
            return;
        setSubmitting(true);
        const noteBaseContent = {
            noteCreatorOrganization: activeOrganization.ref,
            noteCreator: user.ref,
            content: noteContent
        };
        const result = yield createNoteFromClient({
            noteTopic,
            noteContent: noteBaseContent,
            notesAttachment
        });
        if (result.errorCode) {
            dispatch(ToastActions.toastError(errorMap[result.errorCode]));
        }
        setNotesAttachment(null);
        setNoteContent('');
        setSubmitting(false);
    });
    return (_jsx(Form, Object.assign({ id: "create-note-form", onSubmit: addNote }, { children: _jsxs("div", Object.assign({ className: "border border-column-gray-100 rounded-md pt-4 px-4 pb-2" }, { children: [_jsx(TextAreaField, { id: "comment", maxLength: 400, labelText: "", rows: 1, placeholder: "Leave a note...", value: noteContent, onChange: newValue => setNoteContent(newValue), disableResizing: true, inline: true }), _jsxs("div", Object.assign({ className: "flex justify-between py-2" }, { children: [_jsx(NoteAttachmentField, { notesAttachment: notesAttachment, setNotesAttachment: setNotesAttachment }), _jsx(ColumnButton, { primary: true, endIcon: _jsx(PaperAirplaneIcon, { className: "h-5 w-5" }), buttonText: "Submit", type: "submit", size: "sm", disabled: submitting || !noteContent })] }))] })) })));
}
const errorMap = {
    NOTE_CREATION_FAILED: {
        headerText: 'Error creating note',
        bodyText: 'Please try refreshing your page and re-submitting your note.'
    },
    NOTE_ATTACHMENT_UPLOAD_FAILED: {
        headerText: 'Error uploading attachment',
        bodyText: 'Please check the file size you are uploading and your internet connection.'
    }
};
