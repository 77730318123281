var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isUndefined } from 'lodash';
import { SyncStatusType } from '../../enums';
import { WickPaperAbbreviation } from './wick/constants';
import { awaitAllPromises, getFulfilled } from '../../helpers';
import { MANUAL_BUILD_AD_REQUEST, MANUAL_CANCEL_BUILD_AD_REQUEST } from '../../types/events';
import { FileType, getFileTypeFromExtensionString, getVerifiedExtensionFromFileName } from '../../types/mime';
import { syncStatusMatchesCriteria } from '../../utils/events';
import { AFFINITY_ORDER_NUMBER_INCREMENTORS } from './types';
import { ColumnPublisherAbbreviation } from './column/constants';
import { CacheManager } from '../caches';
import { AX_CONSTANTS_ID, CACHE_KEYS } from './constants';
import { wrapError, wrapSuccess } from '../../types/responses';
export const getLatestAffinityXEventsQuery = (ctx, request) => {
    let query = ctx.eventsRef();
    for (const [key, value] of Object.entries(request)) {
        query = query.where(key, '==', value);
    }
    // Note: altering this orderBy will have negative downstream
    // effects, as we assume the first event returned from this query
    // is the most recent (see AffinityXSyncPanel/index.ts & AffinityXSyncPanel/helpers.ts)
    return query.orderBy('createdAt', 'desc');
};
export const hasBuildRequestBeenCancelled = (ctx, buildRequestEvent) => __awaiter(void 0, void 0, void 0, function* () {
    const { notice: noticeRef } = buildRequestEvent.data();
    const cancellationRequests = yield getLatestAffinityXEventsQuery(ctx, {
        notice: noticeRef,
        type: MANUAL_CANCEL_BUILD_AD_REQUEST
    })
        .where('data.initialOrderRequest', '==', buildRequestEvent.ref)
        // limiting to 1 because we're just checking existence of at least one matching event
        .limit(1)
        .get();
    return !!cancellationRequests.size;
});
const getSyncEventsForTriggerEvent = (ctx, triggerEvent) => __awaiter(void 0, void 0, void 0, function* () {
    const { notice: noticeRef } = triggerEvent.data();
    const syncEventQuerySnap = yield ctx
        .eventsRef()
        .where('notice', '==', noticeRef)
        .where('trigger', '==', triggerEvent.ref)
        .get();
    return syncEventQuerySnap;
});
/**
 * The purpose of this function is to determine whether, given a trigger event,
 * an order was _actually created_ in AffinityX. This occurs when we actually send them
 * an XML to create the order, so we should return `false` from this function if the sync
 * failed before any XML was sent.
 *
 * For simplicity, we use the presence of an `awaiting_response` event on a trigger to
 * determine whether or not the XML was sent. Importantly, this function is designed with
 * the Wick integration in mind, so it may need to be updated in future versions of this
 * integration if, for example, the integration uses a method other than `awaiting_response`
 * to receive response files.
 */
export const checkHasSyncEventWithStatus = (ctx, triggerEvent, syncCategoryAndStatusCriteria) => __awaiter(void 0, void 0, void 0, function* () {
    const noticeSyncEvents = yield getSyncEventsForTriggerEvent(ctx, triggerEvent);
    const hasAwaitingResponseEvent = noticeSyncEvents.docs.some(syncEvent => {
        const { syncStatus } = syncEvent.data().data;
        return syncStatusMatchesCriteria(syncStatus, syncCategoryAndStatusCriteria);
    });
    return hasAwaitingResponseEvent;
});
const getIsBuildRequestForOpenAffinityXOrder = (ctx, buildRequestEvent) => __awaiter(void 0, void 0, void 0, function* () {
    const orderHasBeenCreatedInAffinity = yield checkHasSyncEventWithStatus(ctx, buildRequestEvent, { categories: [], statuses: [SyncStatusType.awaiting_response] });
    const orderHasBeenCancelledInAffinity = yield hasBuildRequestBeenCancelled(ctx, buildRequestEvent);
    return orderHasBeenCreatedInAffinity && !orderHasBeenCancelledInAffinity;
});
export const maybeGetMostRecentBuildRequestEventForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const buildAdRequestQuery = getLatestAffinityXEventsQuery(ctx, {
        type: MANUAL_BUILD_AD_REQUEST,
        notice: noticeSnap.ref
    });
    const buildAdRequestQuerySnap = yield buildAdRequestQuery.get();
    if (buildAdRequestQuerySnap.empty) {
        return undefined;
    }
    return buildAdRequestQuerySnap.docs[0];
});
export const getNoticeHasOpenAffinityXOrder = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const mostRecentBuildRequestEvent = yield maybeGetMostRecentBuildRequestEventForNotice(ctx, noticeSnap);
    if (!mostRecentBuildRequestEvent) {
        return false;
    }
    return yield getIsBuildRequestForOpenAffinityXOrder(ctx, mostRecentBuildRequestEvent);
});
export const getOrderNumberHasAlreadyBeenSentToAffinity = (ctx, orderNumber, affinityBuildEventsQuerySnap) => __awaiter(void 0, void 0, void 0, function* () {
    const triggerEventsWithOrderNumber = affinityBuildEventsQuerySnap.docs.filter(triggerEvent => triggerEvent.data().data.orderNumber === orderNumber);
    const orderNumberHasAlreadySyncedResults = yield awaitAllPromises(triggerEventsWithOrderNumber.map((triggerEvent) => __awaiter(void 0, void 0, void 0, function* () {
        return yield checkHasSyncEventWithStatus(ctx, triggerEvent, {
            categories: [],
            statuses: [SyncStatusType.awaiting_response]
        });
    })));
    const orderNumberHasAlreadyBeenSyncedToAffinity = getFulfilled(orderNumberHasAlreadySyncedResults).some(result => !!result);
    return orderNumberHasAlreadyBeenSyncedToAffinity;
});
/**
 * The character limit for the XML field is 24. We are standardizing AffinityX order number lengths
 * to 9 characters for readability, and conditionally appending a 10th character as an "incrementor" in rare
 * instances where we may need to create multiple orders for the same notice.
 *
 * Note that an extra 3-4 character AffinityX prefix will be prepended to the order number when synced,
 * so even if we alter this formula in the future, the length of the string generated by this function
 * must never exceed 20.
 */
const WICK_ORDER_NUMBER_LENGTH_NO_INCREMENTOR = 9;
const WICK_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR = 10;
// Column order numbers will contain an additional three characters
// Ex. FCCGFH123456 to help differeniate publishers we have
// the parent abbreviation FCC, newspaper abbreviation GFH, and an order number 123456
const COLUMN_ORDER_NUMBER_LENGTH_NO_INCREMENTOR = 12;
const COLUMN_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR = 13;
const isWickOrderNumber = (noticeCustomId) => {
    return Object.values(WickPaperAbbreviation).includes(noticeCustomId.slice(0, 3));
};
const getIsExpectedLength = (noticeCustomId) => {
    if (isWickOrderNumber(noticeCustomId)) {
        return (noticeCustomId.length === WICK_ORDER_NUMBER_LENGTH_NO_INCREMENTOR ||
            noticeCustomId.length === WICK_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR);
    }
    return (noticeCustomId.length === COLUMN_ORDER_NUMBER_LENGTH_NO_INCREMENTOR ||
        noticeCustomId.length === COLUMN_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR);
};
const getStartsWithAcceptedAbbreviation = (noticeCustomId) => {
    if (isWickOrderNumber(noticeCustomId)) {
        return Object.values(WickPaperAbbreviation).includes(noticeCustomId.slice(0, 3));
    }
    return Object.values(ColumnPublisherAbbreviation).includes(noticeCustomId.slice(0, 3));
};
const getNextSixCharsAfterAbbrevAreDigits = (noticeCustomId) => {
    if (isWickOrderNumber(noticeCustomId)) {
        return /^\d{6}$/.test(noticeCustomId.slice(3, 9));
    }
    return /^\d{6}$/.test(noticeCustomId.slice(6, 12));
};
const getIncrementor = (orderNumber) => {
    if (isWickOrderNumber(orderNumber)) {
        return orderNumber.length === WICK_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR;
    }
    return orderNumber.length === COLUMN_ORDER_NUMBER_LENGTH_WITH_INCREMENTOR;
};
export const getOrderNumberIncrementor = (orderNumber) => {
    const incrementor = getIncrementor(orderNumber)
        ? orderNumber.split('').pop()
        : '';
    if (isUndefined(incrementor) ||
        !AFFINITY_ORDER_NUMBER_INCREMENTORS.includes(incrementor)) {
        return undefined;
    }
    return incrementor;
};
/**
 * AffinityX order numbers must be unique and include the following, in order:
 * - A stipulated 3-letter abbreviation for the publication
 * - A 6-digit number unique within the publication to the particular notice
 * - Optionally, an incrementor ('A', 'B', 'C', or 'D') used for notices that need multiple order numbers generated
 *
 * TODO: as needed, expand the use of this function beyond Wick
 */
export const isAffinityXOrderNumber = (noticeCustomId) => {
    if (!noticeCustomId)
        return false;
    const isExpectedLength = getIsExpectedLength(noticeCustomId);
    const startsWithAcceptedAbbreviation = getStartsWithAcceptedAbbreviation(noticeCustomId);
    const nextSixCharsAfterAbbrevAreDigits = getNextSixCharsAfterAbbrevAreDigits(noticeCustomId);
    const qualifiedIncrementor = getOrderNumberIncrementor(noticeCustomId);
    return (isExpectedLength &&
        startsWithAcceptedAbbreviation &&
        nextSixCharsAfterAbbrevAreDigits &&
        !isUndefined(qualifiedIncrementor));
};
/**
 * A response file from AffinityX will be a PDF named with the order number
 * (minus the AffinityX prefix for the publisher, e.g. "WCC")
 */
export const isAffinityXResponseFileName = (fileName) => {
    const { verifiedExtension, fileNameMinusExtension } = getVerifiedExtensionFromFileName(fileName);
    const isPDF = getFileTypeFromExtensionString(verifiedExtension || '') === FileType.PDF;
    const isValidAffinityXOrderNumber = isAffinityXOrderNumber(fileNameMinusExtension);
    return isPDF && isValidAffinityXOrderNumber;
};
export const getAffinityXConstantsFromCache = (ctx, newspaperRef) => __awaiter(void 0, void 0, void 0, function* () {
    const cache = new CacheManager(ctx, newspaperRef, AX_CONSTANTS_ID);
    const accountNumber = yield cache.getValueIfExists(CACHE_KEYS.ACCOUNT_NUMBER);
    if (!accountNumber) {
        return wrapError(new Error('Newspaper does not have an account number in the cache'));
    }
    const publicationCode = yield cache.getValueIfExists(CACHE_KEYS.PUBLICATION_CODE);
    if (!publicationCode) {
        return wrapError(new Error('Newspaper does not have a publication code in the cache'));
    }
    const newspaperAbbreviation = yield cache.getValueIfExists(CACHE_KEYS.NEWSPAPER_ABBREVIATION);
    if (!newspaperAbbreviation) {
        return wrapError(new Error('Newspaper does not have a newspaper abbreviation in the cache'));
    }
    return wrapSuccess({ accountNumber, publicationCode, newspaperAbbreviation });
});
