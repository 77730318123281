var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DEFAULT_DPI, cdnIfy } from '../helpers';
const BORDER_WIDTH = 5;
export class PdfSmasher {
    constructor(imageHelper) {
        this.imageHelper = imageHelper;
        this.getAcceptableCloudinaryWidth = (documentProperties) => {
            const pixelsPerMegapixel = Math.pow(10, 6);
            // our limit is 100 megapixels and was increased on a one-off basis.
            // see https://columnpbc.atlassian.net/browse/ONCALL-3212?focusedCommentId=63677
            const maxAcceptableCloudinaryMegapixels = 100;
            const maxAcceptableCloudinaryPixels = maxAcceptableCloudinaryMegapixels * pixelsPerMegapixel;
            const { height, width, units } = documentProperties.dimensions;
            if (units !== 'pt' && units !== 'px') {
                throw Error(`Received units in ${units}, but need pixels or points`);
            }
            // This is rough but 1 pt = 1/72in and 1px is often considered as 1/96in
            const scaleFactor = units === 'pt' ? 96 / 72 : 1;
            const heightPx = scaleFactor * height;
            const widthPx = scaleFactor * width;
            const currentPixels = heightPx * widthPx;
            // at one point in our Cloudinary transformation process, there is a border around the entire notice, with a double-thick border at the bottom.
            const borderPixels = 2 * (heightPx + widthPx) * BORDER_WIDTH + BORDER_WIDTH * widthPx;
            const scaleRatio = Math.sqrt(maxAcceptableCloudinaryPixels / (currentPixels + borderPixels));
            const scaledWidth = Math.floor(scaleRatio * widthPx);
            return Math.min(scaledWidth, 1500); // Why 1500?
        };
    }
    /**
     * 1. Trim the whitespace around each page of the file while merging into one image (I believe this is
     * done to support removing gaps between pages when uploading a multi-page document, but maybe there are
     * other reasons for it too.)
     * 2. Convert to grayscale if necessary.
     * 3. Mysteriously add and crop out a red border twice for unknown reasons
     */
    static getCloudinaryTransformations(page, width, grayscale) {
        const transformations = [
            `dn_${DEFAULT_DPI}`,
            `f_png,pg_${page + 1},w_${width}`,
            `e_trim:0:white`
        ];
        if (grayscale) {
            transformations.push('e_grayscale');
        }
        // Why is this red border added and then cropped?
        const border = `bo_${BORDER_WIDTH}px_solid_red`;
        const cropBorder = `,x_0,y_0,w_w_sub_${BORDER_WIDTH * 2},h_h_sub_${BORDER_WIDTH}`;
        transformations.push(border, 'q_100', `c_crop,g_south${cropBorder}`, border, `c_crop,g_north${cropBorder}`, 'e_trim');
        return transformations.join('/');
    }
    static loadImage(img, src) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-param-reassign
            img.onload = () => resolve();
            // eslint-disable-next-line no-param-reassign
            img.onerror = () => reject(new Error(`Failed to retrieve ${src}`));
            // eslint-disable-next-line no-param-reassign
            img.src = src;
        });
    }
    getSmashedDataUrl(documentProperties, fullPath, grayscale) {
        return __awaiter(this, void 0, void 0, function* () {
            const width = this.getAcceptableCloudinaryWidth(documentProperties);
            const canvas = this.imageHelper.getCanvas(width);
            const context = canvas.getContext('2d');
            if (!context) {
                throw Error('Could not get context');
            }
            const images = new Array();
            for (let page = 0; page < documentProperties.metadata.numPages; page++) {
                const img = this.imageHelper.getNewImage();
                const src = cdnIfy(fullPath, {
                    cloudinaryTransformations: PdfSmasher.getCloudinaryTransformations(page, width, Boolean(grayscale))
                });
                // eslint-disable-next-line no-await-in-loop
                yield PdfSmasher.loadImage(img, src);
                images.push(img);
                // eslint-disable-next-line no-param-reassign
                canvas.height += img.height;
            }
            let currentY = 0;
            images.forEach(img => {
                context.drawImage(img, 0, currentY);
                currentY += img.height;
            });
            return canvas.toDataURL();
        });
    }
}
