var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { safeGetModelArrayFromQuery, safeGetModelFromRef } from '../model/getModel';
import { wrapErrorAsColumnError, InternalServerError, NotFoundError, BadRequestError } from '../errors/ColumnErrors';
import { ProductPublishingSettingModel } from '../model/objects/productPublishingSettingModel';
import { wrapError, wrapSuccess } from '../types/responses';
import { safeAsync } from '../safeWrappers';
import { PublishingSettingsService } from './publishingSettingsService';
import { ColumnService } from './directory';
import { FilingTypeService } from './filingTypeService';
import { getErrorReporter } from '../utils/errors';
export class ProductPublishingSettingsService {
    constructor(ctx) {
        /**
         * Helper method that handles retrieving and optionally creating returns the full tree of objects
         * including default values:
         *  - productPublishingSetting
         *  - publishingSetting
         *  - filingTypes
         */
        this.fetchOrCreateDetailedProductPublishingSetting = (organizationRef, product, publishingMedium, options) => __awaiter(this, void 0, void 0, function* () {
            if (!organizationRef) {
                return wrapError(new BadRequestError('Newspaper snapshot not found'));
            }
            if (!publishingMedium) {
                return wrapError(new BadRequestError('Publishing medium not set'));
            }
            let productPublishingSetting;
            if (options.shouldCreate) {
                const productPublishingSettingResponse = yield this.fetchOrCreateProductPublishingSetting(organizationRef, product, publishingMedium);
                if (productPublishingSettingResponse.error) {
                    return wrapError(productPublishingSettingResponse.error);
                }
                productPublishingSetting = productPublishingSettingResponse.response;
            }
            else {
                const productPublishingSettingResponse = yield this.fetchProductPublishingSettingArray(organizationRef, product, publishingMedium);
                if (productPublishingSettingResponse.error) {
                    return wrapError(productPublishingSettingResponse.error);
                }
                if (productPublishingSettingResponse.response.length === 0) {
                    return wrapError(new NotFoundError('ProductPublishingSetting not found'));
                }
                if (productPublishingSettingResponse.response.length > 1) {
                    const err = new InternalServerError('Too many productPublishingSettings found!');
                    getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, err, 'fetchOrCreateDetailedProductPublishingSetting', {
                        organizationRef: organizationRef.id,
                        product,
                        publishingMedium
                    });
                    return wrapError(err);
                }
                [productPublishingSetting] = productPublishingSettingResponse.response;
            }
            const { response: publishingSetting, error: publishingSettingError } = yield productPublishingSetting.fetchPublishingSetting();
            if (publishingSettingError) {
                return wrapError(publishingSettingError);
            }
            const { response: filingTypes, error: filingTypesError } = yield publishingSetting.fetchFilingTypes();
            if (filingTypesError) {
                return wrapError(filingTypesError);
            }
            return wrapSuccess({
                productPublishingSetting,
                publishingSetting,
                filingTypes
            });
        });
        this.ctx = ctx;
        this.publishingSettingsService = new PublishingSettingsService(ctx);
        this.filingTypeService = new FilingTypeService(ctx);
    }
    fetchProductPublishingSettingArray(organizationRef, product, publishingMedium) {
        return __awaiter(this, void 0, void 0, function* () {
            let productPublishingSettingsQuery = this.ctx.organizationProductPublishingSettingsRef(organizationRef);
            if (product) {
                productPublishingSettingsQuery = productPublishingSettingsQuery.where('product', '==', product);
            }
            if (publishingMedium) {
                productPublishingSettingsQuery = productPublishingSettingsQuery.where('publishingMedium', '==', publishingMedium);
            }
            return safeGetModelArrayFromQuery(ProductPublishingSettingModel, this.ctx, productPublishingSettingsQuery);
        });
    }
    /**
     * This function handles creating the full tree of objects including default values:
     *   - productPublishingSetting
     *   - publishingSetting
     *   - filingTypes
     */
    fetchOrCreateProductPublishingSetting(organizationRef, product, publishingMedium) {
        return __awaiter(this, void 0, void 0, function* () {
            const logData = {
                organizationId: organizationRef.id,
                product,
                publishingMedium
            };
            const { response: productPublishingSetting, error: fetchError } = yield this.fetchProductPublishingSettingArray(organizationRef, product, publishingMedium);
            if (fetchError) {
                return wrapError(fetchError);
            }
            if (productPublishingSetting.length === 1) {
                getErrorReporter().logInfo('Found existing productPublishingSetting for organization', logData);
                return wrapSuccess(productPublishingSetting[0]);
            }
            if (productPublishingSetting.length > 1) {
                const error = new InternalServerError('More than one productPublishingSetting found for organization');
                getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, error, 'Failed to fetchOrCreateProductPublishingSetting', logData);
                return wrapError(new InternalServerError('More than one productPublishingSetting found for organization'));
            }
            const { response: filingTypes, error: filingTypesError } = yield this.filingTypeService.createFilingTypesForProduct(product, organizationRef);
            if (filingTypesError) {
                return wrapError(filingTypesError);
            }
            const filingTypeRefs = filingTypes.map(filingType => filingType.ref);
            const { response: newPublishingSetting, error: publishingSettingError } = yield this.publishingSettingsService.createPublishingSetting({
                filingTypes: filingTypeRefs
            }, organizationRef);
            if (publishingSettingError) {
                return wrapError(publishingSettingError);
            }
            const { response: newProductPublishingSetting, error: addError } = yield safeAsync(() => this.ctx.organizationProductPublishingSettingsRef(organizationRef).add({
                product,
                publishingMedium,
                publishingSetting: newPublishingSetting.ref
            }))();
            if (addError) {
                getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, addError, 'Failed to fetchOrCreateProductPublishingSetting - Could not add productPublishingSetting', logData);
                return wrapErrorAsColumnError(addError, InternalServerError);
            }
            return safeGetModelFromRef(ProductPublishingSettingModel, this.ctx, newProductPublishingSetting);
        });
    }
}
