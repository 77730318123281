import { isRef } from '../../model/refs';
const safeStringifyQueryValue = (value) => {
    if (isRef(value)) {
        return value.path;
    }
    return `${value}`;
};
export class DebugQuery {
    constructor(base, basePath, clauses) {
        this.base = base;
        this.basePath = basePath;
        this.clauses = clauses;
        // ...
    }
    wrap(newBase, newClauses) {
        return new DebugQuery(newBase, this.basePath, [
            ...this.clauses,
            newClauses.map(safeStringifyQueryValue)
        ]);
    }
    where(fieldPath, opStr, value) {
        return this.wrap(this.base.where(fieldPath, opStr, value), [
            'where',
            fieldPath,
            opStr,
            value
        ]);
    }
    orderBy(fieldPath, directionStr) {
        return this.wrap(this.base.orderBy(fieldPath, directionStr), [
            'orderBy',
            fieldPath,
            directionStr
        ]);
    }
    limit(limit) {
        return this.wrap(this.base.limit(limit), ['limit']);
    }
    limitToLast(limit) {
        return this.wrap(this.base.limitToLast(limit), ['limitToLast']);
    }
    startAt(...fieldValues) {
        return this.wrap(this.base.startAt(fieldValues), ['startAt', fieldValues]);
    }
    startAfter(...fieldValues) {
        return this.wrap(this.base.startAfter(fieldValues), ['startAfter']);
    }
    endBefore(...fieldValues) {
        return this.wrap(this.base.endBefore(fieldValues), ['endBefore']);
    }
    endAt(...fieldValues) {
        return this.wrap(this.base.endAt(fieldValues), ['endAt', fieldValues]);
    }
    get() {
        console.log('[fs][query.get]', this.basePath, this.clauses);
        return this.base.get();
    }
    onSnapshot(onNext, onError) {
        console.log('[fs][query.onSnapshot]', this.basePath, this.clauses);
        return this.base.onSnapshot(onNext, onError);
    }
}
/**
 * Because we often use ERef as field values we can't wrap them, otherwise
 * they won't be recognized by the Firestore SDK. But we can wrap them in a proxy.
 */
const proxyDocRef = (ref) => {
    return new Proxy(ref, {
        get(target, property, receiver) {
            if (typeof property === 'string' &&
                ['get', 'onSnapshot', 'set', 'update', 'delete'].includes(property)) {
                console.log(`[fs][doc.${property}]`, target.path);
            }
            return Reflect.get(target, property, receiver);
        }
    });
};
export class DebugCollectionRef extends DebugQuery {
    constructor(baseColl) {
        super(baseColl, baseColl.path, []);
        this.baseColl = baseColl;
    }
    get id() {
        return this.baseColl.id;
    }
    get parent() {
        return this.baseColl.parent;
    }
    get path() {
        return this.baseColl.path;
    }
    doc(documentPath) {
        if (typeof documentPath === 'string') {
            return proxyDocRef(this.baseColl.doc(documentPath));
        }
        return proxyDocRef(this.baseColl.doc());
    }
    add(data) {
        console.log('[fs][add]', this.path);
        return this.baseColl.add(data).then(r => proxyDocRef(r));
    }
}
