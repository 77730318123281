import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@material-ui/core';
import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
const styles = (theme) => createStyles({
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    paymentMethodBox: {
        border: '1px solid #DADADA',
        boxSizing: 'border-box',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(1),
        alignItems: 'center',
        backgroundColor: 'white',
        justifyContent: 'space-between'
    },
    paymentMethodBadge: {
        fontWeight: 'bold',
        fontSize: '9px',
        lineHeight: '20px',
        textAlign: 'center',
        alignSelf: 'center',
        color: 'white',
        textTransform: 'uppercase',
        background: '#4A5568',
        borderRadius: '8px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    paymentMethodText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '28px',
        textAlign: 'center',
        color: '#4A5568' // makes the text color  dark gray
    },
    bold: {
        fontWeight: 'bold'
    }
});
function getPaymentMethodTypeString(paymentMethod) {
    switch (paymentMethod) {
        case 'saved-card':
            return 'credit card';
        case 'saved-bank':
            return 'bank account';
        case 'saved-ach':
            return 'ach';
        default:
            return 'unknown';
    }
}
function SavedPaymentMethodButton({ paymentMethodBrand, paymentMethodId, paymentMethodLast4, paymentMethodType, isSelected, key, clickHandler, classes }) {
    return (_jsx(Box, Object.assign({ id: `${paymentMethodType}${key}`, className: classes.paymentMethodBox, onClick: () => {
            clickHandler(paymentMethodType, paymentMethodId);
        }, style: {
            border: isSelected ? '1px solid #4285F4' : '1px solid #DADADA' //  blue border if selected, gray otherwise
        } }, { children: _jsxs(Box, Object.assign({ style: { display: 'flex' } }, { children: [_jsx(Box, Object.assign({ className: classes.paymentMethodBadge, style: {
                        background: isSelected ? '#4285F4' : '#4A5568' //  blue payment method badge if selected, gray otherwise
                    } }, { children: getPaymentMethodTypeString(paymentMethodType) })), _jsxs(Box, Object.assign({ className: classes.flex, style: { alignItems: 'flex-start' } }, { children: [_jsx(Box, Object.assign({ className: `${classes.paymentMethodText} ${classes.bold}` }, { children: paymentMethodBrand.toUpperCase() })), _jsx(Box, Object.assign({ className: classes.paymentMethodText }, { children: `**** **** **** ${paymentMethodLast4}` }))] }))] })) }), key));
}
export default withStyles(styles)(SavedPaymentMethodButton);
