import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Tooltip } from 'lib/components/Tooltip';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import NoticeTableCell from './NoticeTableCell';
export default function ReferenceIDCell({ row }) {
    var _a;
    const isPublisher = useAppSelector(selectIsPublisher);
    const { original } = row;
    return (_jsxs(NoticeTableCell, { children: [_jsxs("div", Object.assign({ className: "word-wrap max-w-xl" }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-500" }, { children: isPublisher && original.customid
                            ? `${original.noticename} - ${original.customid}`
                            : original.noticename })), _jsx("div", { children: isPublisher
                            ? `${((_a = original.filername) === null || _a === void 0 ? void 0 : _a.trim()) || 'User Pending Registration'}${original.filerorganizationname
                                ? ` - ${original.filerorganizationname}`
                                : ''}`
                            : original.publication })] })), isPublisher && original.failedsync ? (_jsx(Tooltip, Object.assign({ helpText: "Sync failed", classes: "w-8" }, { children: _jsx(ExclamationCircleIcon, { className: "ml-1 text-column-red-800 h-6 w-6" }) }))) : ('')] }));
}
