var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { isFlatAdditionalFee, isPercentAdditionalFee } from 'lib/types/rates';
import FullScreenModal from 'components/FullScreenModal';
import { COLUMN_REP_FEE } from 'lib/constants';
import { getColumnRepFeeFromNewspaper, isColumnRepFee } from 'lib/pricing';
import { getFirebaseContext } from 'utils/firebase';
import { PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED } from 'lib/types/events';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { removeUndefinedFields } from 'lib/helpers';
import ColumnRepFeeCard from './ColumnRepFeeCard';
import AdditionalFeeCard from './AddtionalFeeCard';
function feeUpdatesAreValid(fee) {
    if (isFlatAdditionalFee(fee)) {
        return fee.amount >= 0 && (fee.amount * 100) % 1 === 0;
    }
    return fee.feePercentage >= 0 && (fee.feePercentage * 2) % 1 === 0;
}
/**
 * Full-screen form to update properties associated with a rate
 */
export default function AdditionalFeeUpdateForm({ editedAdditionalFeeIndex, editedAdditionalFee, activeOrganization, closeForm }) {
    const user = useAppSelector(selectUser);
    const [updatedAdditionalFee, setUpdatedAdditionalFee] = useState(editedAdditionalFee);
    const additionalFees = activeOrganization.data().additionalFees || [];
    const existingColumnRepFee = getColumnRepFeeFromNewspaper(activeOrganization);
    const defaultRepFee = {
        type: 'percent',
        description: COLUMN_REP_FEE,
        feePercentage: 0
    };
    const editedColumnRepFee = existingColumnRepFee || defaultRepFee;
    const [updatedColumnRepFee, setUpdatedColumnRepFee] = useState(editedColumnRepFee);
    const [updating, setUpdating] = useState(false);
    const additionalFeeEdited = !columnObjectsAreEqual(updatedAdditionalFee, editedAdditionalFee);
    const columnRepFeeEdited = !columnObjectsAreEqual(updatedColumnRepFee, editedColumnRepFee);
    const edited = additionalFeeEdited || columnRepFeeEdited;
    const updateRateProperties = () => __awaiter(this, void 0, void 0, function* () {
        setUpdating(true);
        let newFees = [...additionalFees];
        // Create a new additional fee
        if (editedAdditionalFeeIndex === -1) {
            if (additionalFeeEdited) {
                newFees = [...newFees, updatedAdditionalFee];
            }
            if (columnRepFeeEdited) {
                newFees = [...newFees, updatedColumnRepFee];
            }
        }
        // Update an existing additional fee object
        else if (additionalFeeEdited) {
            newFees[editedAdditionalFeeIndex] = updatedAdditionalFee;
        }
        // Update an existing column fee object
        else {
            newFees[editedAdditionalFeeIndex] = updatedColumnRepFee;
        }
        if (user) {
            yield getFirebaseContext()
                .eventsRef()
                .add(removeUndefinedFields({
                type: PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED,
                publisher: activeOrganization.ref,
                createdAt: getFirebaseContext().timestamp(),
                data: {
                    before: {
                        additionalFees: [...additionalFees]
                    },
                    after: {
                        additionalFees: [...newFees]
                    },
                    changedBy: user === null || user === void 0 ? void 0 : user.ref
                }
            }));
        }
        yield activeOrganization.ref.update({
            additionalFees: newFees
        });
        setUpdating(false);
        closeForm();
    });
    const editingRate = editedAdditionalFeeIndex > -1;
    const flatRateIncomplete = isFlatAdditionalFee(updatedAdditionalFee) && !updatedAdditionalFee.amount;
    const percentRateIncomplete = isPercentAdditionalFee(updatedAdditionalFee) &&
        !updatedAdditionalFee.feePercentage;
    const incomplete = !updatedAdditionalFee.description ||
        !updatedAdditionalFee.type ||
        flatRateIncomplete ||
        percentRateIncomplete;
    // Enable saving instantly for new rates, and after edit for existing rates
    const disableSave = incomplete ||
        (editingRate && (updating || !edited)) ||
        !feeUpdatesAreValid(updatedAdditionalFee) ||
        !feeUpdatesAreValid(editedColumnRepFee) ||
        !edited;
    return (_jsxs(FullScreenModal, Object.assign({ submittable: {
            buttonText: editingRate ? 'Save' : 'Create Fee',
            disabled: !!disableSave,
            onSubmit: updateRateProperties
        }, onClose: closeForm, headerText: `${editedAdditionalFeeIndex === -1 ? 'Create' : 'Edit'} Additional Fee`, id: "additional-fee-update-form" }, { children: [editedAdditionalFee.description !== COLUMN_REP_FEE && (_jsx(AdditionalFeeCard, { onUpdateAdditionalFee: setUpdatedAdditionalFee, updatedAdditionalFee: updatedAdditionalFee })), ((!editingRate && !existingColumnRepFee) ||
                isColumnRepFee(additionalFees[editedAdditionalFeeIndex])) && (_jsx(ColumnRepFeeCard, { onUpdateColumnRepFee: setUpdatedColumnRepFee, updatedColumnRepFee: updatedColumnRepFee }))] })));
}
