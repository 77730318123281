import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { RateType } from 'lib/enums';
import { useState } from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { isNoticeRate } from 'lib/types/rates';
import { isDefined } from 'lib/helpers';
import { showLinesPerInchRatio } from '../../../ratesUtils';
import DecimalTextField from '../../../DecimalTextField';
const DEFAULT_LINES_PER_INCH = 5;
function ImageRateSettings({ activeOrganization, updatedRateData, onUpdateRateData }) {
    var _a, _b, _c, _d;
    // TODO: In the future we will probably want to show this for all liner rates
    const doesOrgAllowImagesInLiners = updatedRateData.rateType === RateType.oklahoma.value ||
        !!activeOrganization.data().allowImagesInLiners ||
        !isNoticeRate(updatedRateData);
    const initialImageRateType = isDefined((_a = updatedRateData.images) === null || _a === void 0 ? void 0 : _a.flatRate)
        ? RateType.flat.label
        : isDefined((_b = updatedRateData.images) === null || _b === void 0 ? void 0 : _b.linesPerInch)
            ? RateType.inch.label
            : undefined;
    const includeImagesInPricingForLinerRate = Boolean(initialImageRateType);
    const showIncludeImagesInPricing = doesOrgAllowImagesInLiners && isNoticeRate(updatedRateData);
    const [imageRateType, setImageRateType] = useState(initialImageRateType);
    const showImageRateTypeSelection = (showIncludeImagesInPricing && includeImagesInPricingForLinerRate) ||
        !isNoticeRate(updatedRateData);
    const imageRateTypes = [RateType.flat.label];
    if ([
        RateType.column_inch.value,
        RateType.line.value,
        RateType.oklahoma.value,
        RateType.iowa_form.value
    ].includes(updatedRateData.rateType)) {
        imageRateTypes.push(RateType.inch.label);
    }
    const imageRateTypeOptions = imageRateTypes.map(type => ({ label: type, value: type }));
    const showLinesPerInch = showLinesPerInchRatio(updatedRateData) &&
        showImageRateTypeSelection &&
        imageRateType === RateType.inch.label;
    const showFlatRate = showImageRateTypeSelection && imageRateType === RateType.flat.label;
    return (_jsxs(_Fragment, { children: [showIncludeImagesInPricing && (_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "include-images-in-pricing", labelText: "Should pricing include images in the notice?", options: [
                        {
                            label: 'Yes, images should count toward pricing',
                            value: 'true'
                        },
                        {
                            label: 'No, images should not count toward pricing',
                            value: 'false'
                        }
                    ], onChange: newValue => {
                        const includeImages = newValue === 'true';
                        if (includeImages) {
                            onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: {
                                    linesPerInch: DEFAULT_LINES_PER_INCH
                                } }));
                        }
                        else {
                            onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: {} }));
                        }
                    }, value: includeImagesInPricingForLinerRate ? 'true' : 'false' }) })), showImageRateTypeSelection && (_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "image-rate-type-select", labelText: "How should images be priced?", options: imageRateTypeOptions, onChange: value => {
                        setImageRateType(value);
                        if (value === RateType.inch.label) {
                            onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: {
                                    linesPerInch: DEFAULT_LINES_PER_INCH
                                } }));
                        }
                        else {
                            onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: {
                                    flatRate: 0
                                } }));
                        }
                    }, value: imageRateType, required: true, allowUndefined: true }) })), showLinesPerInch && (_jsx(GridInput, { children: _jsx(DecimalTextField, { id: "image-lines-inch-conversion", labelText: "What is the ratio of lines/inch for images?", initialValue: (_c = updatedRateData.images) === null || _c === void 0 ? void 0 : _c.linesPerInch, decimalDigits: 2, onChange: newValue => {
                        onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: {
                                linesPerInch: newValue
                            } }));
                    }, required: true }) })), showFlatRate && (_jsx(GridInput, { children: _jsx(CurrencyTextField, { id: "image-flat-rate-field", labelText: "What is flat rate per image?", initialValue: (_d = updatedRateData.images) === null || _d === void 0 ? void 0 : _d.flatRate, onChange: newValue => {
                        onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: {
                                flatRate: newValue
                            } }));
                    }, required: true }) }))] }));
}
export default ImageRateSettings;
