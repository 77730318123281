import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { exists } from 'lib/types';
import { SuccessModal } from 'lib/components/SuccessModal';
import EEditionUploaderModal from 'routes/notices/table/EEditionUploaderModal';
import { PartyEmoji } from 'lib/components/gifs';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { getDateForDateStringInTimezone } from 'lib/utils/dates';
import UploadEeditionButton from './UploadEeditionButton';
import AutomatedAffidavitTable from './automatedAffidavitsTable';
export default function Affidavits() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    // TODO: deprecate as this is redudant given Toast messages
    const [successMessage, setSuccessMessage] = useState();
    // allow uploading new e-editions via the e-edition uploader modal, and enable locking in a date (for new affidavits table)
    const [showEEditionUploadModal, setShowEEditionUploadModal] = useState({ show: false });
    if (!exists(activeOrganization))
        return _jsx(LoadingState, {});
    return (_jsxs("div", Object.assign({ className: "pb-10 py-4 px-8 m-4" }, { children: [_jsxs("header", Object.assign({ className: "flex justify-between pb-6 items-center" }, { children: [_jsx("h1", Object.assign({ className: "text-2xl font-medium leading-tight text-column-gray-900 mb-1" }, { children: "Automated Affidavits" })), _jsx(UploadEeditionButton, { onClick: () => {
                            setShowEEditionUploadModal({
                                show: true,
                                publicationIssue: null
                            });
                        } })] })), _jsx("div", { children: _jsx(AutomatedAffidavitTable, { activeOrganization: activeOrganization, onShowEEditionUploadModalForPubIssue: (publicationIssue) => setShowEEditionUploadModal({
                        show: true,
                        publicationIssue
                    }), setSuccessMessage: setSuccessMessage }) }), showEEditionUploadModal.show && (_jsx(EEditionUploaderModal, { activeOrganization: activeOrganization, fixedUploadDate: showEEditionUploadModal.publicationIssue
                    ? getDateForDateStringInTimezone({
                        dayString: showEEditionUploadModal.publicationIssue.modelData
                            .publicationDate,
                        timezone: activeOrganization.data().iana_timezone,
                        time: '12:00'
                    })
                    : undefined, setOpen: newValue => newValue
                    ? setShowEEditionUploadModal({
                        show: true,
                        publicationIssue: null
                    })
                    : setShowEEditionUploadModal({ show: false }), id: "automated-affdavit-table-eedition-upload-modal" })), successMessage && (_jsx(SuccessModal, { setOpen: () => {
                    setSuccessMessage(undefined);
                }, gif: _jsx("img", { className: "rounded-full py-4.5 px-3 h-24 bg-column-yellow-100", src: PartyEmoji }), header: successMessage.header, body: successMessage.body }))] })));
}
