var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ArrowTopRightOnSquareIcon, ExclamationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { UploadedFileDisplay } from '../UploadedFileDisplay';
import { getErrorReporter } from '../../utils/errors';
import { useFirebaseStorageDownloadUrl } from '../../frontend/hooks/useFirebaseStorageDownloadUrl';
export function FileUploadListItem({ firebaseStoragePath, deleteConfirmation, displayFileName, fileExtensionString, disableView, viewButtonText = 'View', viewButtonIcon = _jsx(ArrowTopRightOnSquareIcon, { className: "h-6 w-6" }), onDelete, disableDelete, deleteTooltipText, storage }) {
    const storageRef = storage.ref(firebaseStoragePath);
    const { downloadUrl, previewState } = useFirebaseStorageDownloadUrl(storageRef);
    const [deletionState, setDeletionState] = useState('idle');
    function deleteFile() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const isConfirmed = deleteConfirmation
                    ? yield deleteConfirmation()
                    : true;
                if (isConfirmed) {
                    setDeletionState('deleting');
                    onDelete && onDelete();
                    return;
                }
                setDeletionState('idle');
            }
            catch (error) {
                setDeletionState('error');
                getErrorReporter().logAndCaptureError(ColumnService.GENERAL_INFRASTRUCTURE, error, 'Unable to delete file', {
                    storagePath: storageRef.fullPath
                });
            }
        });
    }
    const downloadUrlNotFound = previewState === 'error';
    const deletionHelpText = {
        idle: 'Delete',
        deleting: 'Deleting...',
        error: 'Unable to delete'
    }[deletionState];
    const previewHelpText = {
        idle: viewButtonText,
        loading: 'Loading preview...',
        error: 'File not found'
    }[previewState];
    return (_jsx(UploadedFileDisplay, { fileExtensionString: fileExtensionString || '', displayFileName: displayFileName || storageRef.name, viewLoading: previewState === 'loading', disableView: downloadUrlNotFound || !!disableView, viewStartIcon: downloadUrlNotFound ? (_jsx(ExclamationCircleIcon, { className: "h-6 w-6" })) : (viewButtonIcon), viewFileUrl: downloadUrl, viewButtonText: previewHelpText, deleteOptions: onDelete && {
            deleteTooltipText: deleteTooltipText || '',
            deleteLoading: deletionState === 'deleting',
            disableDelete: !!disableDelete,
            deleteStartIcon: _jsx(TrashIcon, { className: "h-6 w-6 mt-0.5" }),
            onDeleteFile: deleteFile,
            deleteButtonText: deletionHelpText
        } }));
}
