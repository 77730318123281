var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import React, { useState } from 'react';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { userIsSuper } from 'utils/permissions';
import { HorizontalDivider } from 'lib/components/Card/Grid';
import SettingsPage from 'routes/settings/SettingsPage';
import TabGroup from 'lib/components/Tabs';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { GeneralBillingSettings } from './GeneralBillingSettings';
import { ImplementationBillingSettings } from './ImplementationBillingSettings';
import { InvoiceSettings } from './InvoiceSettings';
import { ModularSizes } from './modularSizes';
const billingTab = {
    label: 'Billing',
    enabled: true,
    id: 'Billing',
    superUserOnly: false
};
const plansTab = {
    label: 'Plans',
    enabled: true,
    id: 'Plans',
    superUserOnly: true
};
export function BillingSettings() {
    const user = useAppSelector(selectUser);
    const activeOrganization = useActiveOrganizationListener();
    const [activeTab, setActiveTab] = useState(billingTab);
    const { value: parentOrganization } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!exists(activeOrganization)) {
                return;
            }
            const parent = yield ((_a = activeOrganization.data().parent) === null || _a === void 0 ? void 0 : _a.get());
            return exists(parent) ? parent : undefined;
        }),
        dependencies: [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]
    });
    const tabs = [billingTab, plansTab].filter(tab => (user && userIsSuper(user)) || !tab.superUserOnly);
    if (!exists(activeOrganization)) {
        return null;
    }
    if (activeOrganization.data().parent && !exists(parentOrganization)) {
        return null;
    }
    const getActiveTabComponent = () => {
        switch (activeTab.label) {
            case billingTab.label:
                return (_jsxs(_Fragment, { children: [_jsx(SettingsHeader, { header: "Billing", description: "Configure billing settings for your publication." }), _jsx(GeneralBillingSettings, { activeOrganization: activeOrganization, parentOrganization: parentOrganization }), _jsx(HorizontalDivider, {}), _jsx(InvoiceSettings, {}), exists(user) && userIsSuper(user) && (_jsxs(_Fragment, { children: [_jsx(HorizontalDivider, {}), _jsx(ImplementationBillingSettings, {}), _jsx(HorizontalDivider, {})] }))] }));
            case plansTab.label:
            default:
                return _jsx(ModularSizes, {});
        }
    };
    const activeTabComponent = getActiveTabComponent();
    return (_jsxs(SettingsPage, { children: [_jsx(TabGroup, { tabs: tabs, activeTab: activeTab, onClickTab: setActiveTab, id: "customer-drawer-tabs" }), activeTabComponent] }));
}
