var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, SvgIcon, Divider, CircularProgress } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Firebase from 'EnoticeFirebase';
import moment from 'moment';
import api from 'api';
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
const styles = (theme) => createStyles({
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    checkIcon: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        marginBottom: theme.spacing(1)
    },
    initiatedText: {
        color: '#697386',
        marginTop: theme.spacing(1)
    },
    paidText: {
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#4FA376',
        textTransform: 'uppercase'
    },
    pastDue: {
        backgroundColor: '#FFC400',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1), inset 0px 1px 6px rgba(0, 0, 0, 0.1)',
        color: '#502B06',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '28px',
        textTransform: 'uppercase',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    pdfButton: {
        border: '0.5px solid #DADADA',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        fontSize: '13px',
        height: theme.spacing(4),
        textTransform: 'none'
    },
    voidedText: {
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#E25950',
        textTransform: 'uppercase'
    },
    voidedSubText: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#697386'
    }
});
const formatDate = (date) => {
    if (date === undefined)
        return '';
    let convertedDate;
    if (typeof date === 'number') {
        convertedDate = new Date(date);
    }
    else {
        convertedDate = firestoreTimestampOrDateToDate(date);
    }
    return moment(convertedDate).format('MMMM DD, YYYY');
};
function PaidStatus({ paidDate, netTotal, surchargeInCents = 0, invoice, currency, classes }) {
    const [loading, setLoading] = useState(false);
    const [receipt, setReceipt] = useState();
    /**
     * TODO(goodpaul): Once receipt generation is happening syncronously we can clean this up as we won't have to support
     * the FE generating a receipt
     */
    const handleReceiptDownload = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        if (receipt || invoice.modelData.receipt_pdf) {
            setLoading(false);
            window.open(yield Firebase.storage()
                .ref()
                .child(`/${receipt || invoice.modelData.receipt_pdf}`)
                .getDownloadURL());
        }
        else {
            const { receiptUrl } = yield api.post('payments/create-receipt-pdf', {
                invoiceId: invoice.id
            });
            setReceipt(receiptUrl);
            setLoading(false);
            window.open(yield Firebase.storage().ref().child(`/${receiptUrl}`).getDownloadURL());
        }
    });
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ mt: 3, className: classes.flex }, { children: [_jsx(CheckCircleRoundedIcon, { className: classes.checkIcon, style: { fill: '#4FA376' } }), _jsx(Typography, Object.assign({ className: classes.paidText }, { children: " Invoice paid" })), _jsx(Box, Object.assign({ mt: 3 }, { children: _jsxs(Typography, Object.assign({ style: { fontSize: 18 }, align: "center" }, { children: [currency.symbol, ((netTotal + surchargeInCents) / 100).toFixed(2), ' ', currency.label_abbrev, " paid ", paidDate] })) })), _jsx(Box, Object.assign({ mt: 2, mb: 3 }, { children: _jsx(Button, Object.assign({ className: classes.pdfButton, onClick: handleReceiptDownload, disabled: loading, startIcon: loading ? (_jsx(Box, Object.assign({ ml: 1 }, { children: _jsx(CircularProgress, { size: 15 }) }))) : (_jsx(SvgIcon, Object.assign({ fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: "currentColor", style: { fill: 'none' } }, { children: _jsx("path", { d: "M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" }) }))) }, { children: "Download Receipt" })) }))] })) }));
}
function VoidedStatus({ voidedDate, classes }) {
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ mt: 3, className: classes.flex }, { children: [_jsx(NotInterestedIcon, { className: classes.checkIcon, style: { fill: '#E25950' } }), _jsx(Typography, Object.assign({ className: classes.voidedText }, { children: " Invoice voided" })), _jsx(Box, Object.assign({ mt: 3 }, { children: _jsxs(Typography, Object.assign({ style: { fontSize: 18 }, align: "center" }, { children: ["Invoice voided on ", voidedDate] })) })), _jsx(Box, Object.assign({ mt: 1, mb: 3, ml: 7, mr: 7 }, { children: _jsx(Typography, Object.assign({ className: classes.voidedSubText }, { children: "If you're expecting an updated invoice, please check your Column account or reach out to help@column.us" })) }))] })) }));
}
function PendingStatus({ initiatedDate, netTotal, currency, classes }) {
    return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ mt: 3, className: classes.flex }, { children: [_jsx("svg", Object.assign({ width: "32", height: "32", viewBox: "0 0 32 32" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM10 14H6V18H10V14ZM26 14H22V18H26V14ZM14 14H18V18H14V14Z", fill: "#F2C94C" }) })), _jsx(Typography, Object.assign({ className: classes.initiatedText }, { children: "Payment initiated" })), _jsx(Box, Object.assign({ mt: 3, mb: 3 }, { children: _jsxs(Typography, Object.assign({ style: { fontSize: 20 }, align: "center" }, { children: [currency.symbol, (netTotal / 100).toFixed(2), " initiated ", initiatedDate] })) }))] })) }));
}
function UnpaidStatus({ netTotal, currency, dueDate }) {
    return (_jsx(Box, Object.assign({ mt: 3 }, { children: _jsxs(Typography, Object.assign({ style: { fontSize: 18 }, align: "center" }, { children: [currency.symbol, netTotal && (netTotal / 100).toFixed(2), " ", currency.label_abbrev, " due", ' ', formatDate(dueDate * 1000)] })) })));
}
function InvoiceStatusInfo({ invoice, invoicePricingData, classes }) {
    var _a;
    // Do not get the netTotal from the invoiceData object because the pricing data has a more reliable netTotal
    const invoiceData = invoice.data();
    const { finalized_at, due_date } = invoiceData;
    const voidedAt = (_a = invoiceData.voided_at) !== null && _a !== void 0 ? _a : undefined;
    const { currency, totalAmount, netTotal, surchargeInCents } = invoicePricingData;
    const isUnpaid = invoice.isUnpaid();
    // Since orders (obits & classifieds) are always paid upfront, we should not show the default due date for those products
    const showDueDate = isUnpaid && invoice.isOrderInvoice();
    return (_jsxs(_Fragment, { children: [isUnpaid && invoice.isPastDue() && (_jsx(Box, Object.assign({ className: classes.pastDue }, { children: "past due" }))), _jsx(Divider, {}), voidedAt && (_jsx(VoidedStatus, { classes: classes, voidedDate: formatDate(voidedAt) })), (invoice.paymentReceived() || totalAmount === 0) && (_jsx(PaidStatus, { paidDate: formatDate(finalized_at), invoice: invoice, netTotal: netTotal || 0, surchargeInCents: surchargeInCents || 0, currency: currency, classes: classes })), invoice.paymentPending() && (_jsx(PendingStatus, { classes: classes, initiatedDate: formatDate(finalized_at), netTotal: netTotal || 0, currency: currency })), showDueDate && (_jsx(UnpaidStatus, { netTotal: netTotal || 0, currency: currency, dueDate: due_date }))] }));
}
export default withStyles(styles)(InvoiceStatusInfo);
