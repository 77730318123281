import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { DoublePaperAndShadow } from 'lib/components/gifs';
function ExitConfirmationModal({ keepEditing, exitWithoutSaving, body, id, width, maxWidth, disableOverflow }) {
    // TODO: we need to change the image URL to something in the codebase instead of URL
    return (_jsx("div", Object.assign({ id: id, className: `fixed z-modal inset-0 ${!disableOverflow ? 'overflow-y-auto' : ''}` }, { children: _jsxs("div", Object.assign({ className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" }, { children: [_jsx("div", Object.assign({ className: "fixed inset-0 transition-opacity" }, { children: _jsx("div", { className: "absolute inset-0 bg-black opacity-50" }) })), _jsx("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }), "\u200B", _jsx("div", Object.assign({ className: `inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth || 'sm:max-w-lg'} ${!!width && width} sm:w-full`, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: `${id}-inner` }, { children: _jsx("div", Object.assign({ className: "p-12 border-b border-gray-300" }, { children: _jsxs("div", Object.assign({ className: `sm:mt-0 text-center` }, { children: [_jsx("div", Object.assign({ className: "flex justify-center" }, { children: _jsx("img", { src: DoublePaperAndShadow, style: {
                                            clipPath: 'circle()',
                                            backgroundColor: '#FFDF66',
                                            width: '104px',
                                            height: '104px'
                                        } }) })), _jsx("h3", Object.assign({ className: "text-xl pt-12 leading-6 font-semibold text-gray-900", id: "modal-headline" }, { children: "Are you sure you want to exit?" })), body && (_jsx("div", Object.assign({ className: "pt-6 pb-12" }, { children: _jsx("span", Object.assign({ className: "text-sm font-medium leading-5 text-gray-700" }, { children: body })) }))), _jsxs("div", Object.assign({ className: "text-center" }, { children: [_jsx("span", Object.assign({ className: "pr-4" }, { children: _jsx(ColumnButton, { secondary: true, size: "lg", buttonText: "Keep editing", onClick: keepEditing, type: "button" }) })), _jsx(ColumnButton, { primary: true, size: "lg", buttonText: "Yes, exit", onClick: exitWithoutSaving, type: "button" })] }))] })) })) }))] })) })));
}
export default ExitConfirmationModal;
