import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
function LabeledSwitch({ label, description, value, onChange, disabled }) {
    return (_jsx(Switch.Group, { children: _jsxs("div", Object.assign({ className: "border border-column-grey-25 rounded-md pb-4 bg-white" }, { children: [_jsxs("div", Object.assign({ className: "pt-4 pl-4 flex items-center" }, { children: [_jsxs(Switch, Object.assign({ checked: !!value, onChange: () => {
                                onChange && onChange(!value);
                            }, className: classNames('relative inline-flex h-5 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2', {
                                'bg-column-primary-500 hover:bg-primary-600': value && !disabled,
                                'bg-column-primary-200': value && disabled,
                                'bg-column-gray-50': !value,
                                'hover:bg-column-gray-200': !value && !disabled,
                                'cursor-pointer': !disabled,
                                'cursor-not-allowed': disabled
                            }), style: {
                                width: '36px',
                                height: '20px'
                            }, disabled: disabled }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Use setting" })), _jsx("span", { "aria-hidden": "true", className: classNames(value ? 'translate-x-4' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })] })), _jsx(Switch.Label, Object.assign({ className: classNames('pl-3 font-medium text-sm leading-6 inline-block', {
                                'text-column-gray-500': !disabled,
                                'text-column-gray-400': disabled
                            }) }, { children: label }))] })), description && (_jsx("div", Object.assign({ className: "pt-2 pl-16 pr-4 font-medium text-sm text-column-gray-400" }, { children: description })))] })) }));
}
export default LabeledSwitch;
