export const NOTICE_SYNC_EVENT = 'notices.sync';
export const NOTICE_SYNC_FAILURE_EVENT = 'notices.sync_failed';
export const NOTICE_SYNC_RESPONSE = 'notices.sync_response';
export const NOTICE_SYNC_RESPONSE_FILE_RECEIVED_EVENT = 'notices.sync_response.file_received';
export const NOTICE_SUBMITTED_EVENT = 'notices.submitted';
export const INVOICE_MAIL_SENT_EVENT = 'invoices.mail_sent';
export const NOTICE_AT_DEADLINE = 'notices.at_deadline';
export const NOTICE_CREATED = 'notices.created';
export const NOTICE_DATA_EXTRACTED = 'notices.data.extracted';
export const MANUAL_SYNC_REQUEST = 'notices.manual_sync_request';
export const MANUAL_BUILD_AD_REQUEST = 'notices.build_ad.manual_build_request';
export const MANUAL_CANCEL_BUILD_AD_REQUEST = 'notices.build_ad.manual_cancel_request';
export const NOTICE_NEWSPAPER_REQUESTED = 'notices.newspaper.requested';
export const NOTICE_CONFIRMED = 'notices.confirmed';
export const NOTICE_EDITED = 'notices.edited';
export const NOTICE_CANCELLED = 'notices.cancelled';
export const NOTICE_CANCELLATION_REQUESTED = 'notices.cancellation.requested';
export const AFFIDAVIT_UPLOADED = 'notices.affidavit.uploaded';
export const AFFIDAVIT_UPLOAD_REJECTED = 'notices.affidavit.rejected';
export const CUSTOM_ID_UPDATED = 'notices.custom_id.updated';
export const DESIGN_NOTES_UPDATED = 'notices.design_notes.updated';
export const AFFIDAVIT_RE_UPLOADED = 'notices.affidavit.reuploaded';
export const NOTICE_TRANSFERRED = 'notices.transferred';
export const NOTICE_NOTE_ADDED = 'notices.note.added';
export const NOTICE_AFFILIATE_CLICKED = 'notices.affiliate.clicked';
export const NOTICE_ASYNC_DESIGN_FINALIZED = 'notices.asyncdesign.finalized';
export const NOTICE_RECONCILE_RUNS = 'notices.reconcile.runs';
export const RUN_STATUS_CHANGE = 'runs.status_change';
export const NOTARIZATION_STATUS_CHANGE = 'notarization.status_change';
export const LEDGER_ITEM_STATUS_CHANGE = 'ledger.status_change';
export const LEDGER_ITEM_RECEIPT_READY = 'ledger.receipt_ready';
export const PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED = 'settings.publisher.global_rates.updated';
export const PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED = 'settings.publisher.cross_paper_fee.updated';
export const INVOICE_CREATED = 'invoices.created';
export const INVOICE_CANCELLED = 'invoices.cancelled';
export const INVOICE_REFUNDED = 'invoices.refunded';
export const ORDER_INVOICE_REFUNDED = 'order.invoices.refunded';
export const ORDER_INVOICE_CREATED = 'order.invoices.created';
export const ORDER_INVOICE_PAID = 'order.invoices.paid';
export const INVOICE_RE_CREATED = 'invoices.recreated';
export const INVOICE_REMINDER_SENT = 'invoices.reminder.sent';
export const INVOICE_PAYMENT_PROCESSED = 'invoice.paid';
export const INVOICE_PAYMENT_INITIATED = 'invoice.initiated';
export const INVOICE_PAYMENT_AUTHORIZED = 'invoice.authorized';
export const INVOICE_PAYMENT_PROCESSED_MANUAL = 'invoice.paid.manual';
export const INVOICE_PAYMENT_NOTE = 'invoice.paid.note';
export const INVOICE_PAID_OUTSIDE = 'invoice.paid_outside';
export const INVOICE_CREATION_INITIATED = 'invoice.creation.initiated';
export const INVOICE_CREATION_FAILED = 'invoice.creation.failed';
export const INVOICE_UPFRONT_PAYMENT_WAIVER = 'invoice.upfront_payment_waiver';
export const NOTICE_CANCELLED_AND_REFUNDED = 'invoices.cancelled.refunded';
export const NEWSPAPER_AT_DEADLINE = 'newspaper.at_deadline';
export const NEWSPAPER_EXTERNAL_UPLOAD = 'newspaper.external_upload';
export const NEWSPAPER_AUTOMATIC_BULK_EXPORT = 'newspaper.automatic_bulk_export';
export const DEADLINE_OVERRIDE_EVENT = 'newspaper.deadline_overriden';
export const REALTIME_ELASTIC_SYNC = 'elastic.sync';
export const AFFIDAVIT_NOTARIZATION_INITIATED = 'affidavit.notarization.initiated';
export const AFFIDAVIT_NOTARIZATION_SUCCESS = 'affidavit.notarization.success';
export const AFFIDAVIT_NOTARIZATION_FAILED = 'affidavit.notarization.failed';
export const INVITATION_CREATED = 'invites.created';
export const AUTO_EEDITION_UPLOAD_SUCCESS = 'affidavit.eedition.success';
export const AFFIDAVIT_EEDITION_SCRAPER_INITIATED = 'affidavit.eedition.scraper.initiated';
export const DISPLAY_SITE_UPLOAD = 'display_site.upload';
export const EEDITION_UPLOADED = 'eedition.uploaded';
export const INBOX_AUTOMATION_DRAFT_REQUEST = 'inbox.automation.draft.request';
export const INBOX_AUTOMATION_DRAFT_CREATED = 'inbox.automation.draft.created';
export const INBOX_AUTOMATION_CONTEXT_CALL = 'inbox.automation.context.call';
export const INBOX_AUTOMATION_DRAFT_SENT = 'inbox.automation.draft.sent';
export const INBOX_AUTOMATION_NOTICE_UPLOAD_REQUEST = 'inbox.automation.notice.upload.request';
export const INBOX_AUTOMATION_CANCELLATION_REQUEST = 'inbox.automation.cancellation.request';
export const INBOX_AUTOMATION_CONFIRM_PUBLICATION_REQUEST = 'inbox.automation.confirm.publication.request';
export const INBOX_AUTOMATION_AFFIDAVIT_REQUEST = 'inbox.automation.affidavit.request';
export const ADMIN_OPERATION = 'admin.operation';
export const TRANSACTIONAL_EMAIL_EVENT = 'email.transactional_event';
export const PUBLICATION_ISSUE_STATUS_CHANGE = 'publication_issue.status_change';
export const PUBLICATION_ISSUE_READY_FOR_PAGINATION = 'publication_issue.ready_for_pagination';
export const PUBLICATION_ISSUE_SECTION_STATUS_CHANGE = 'publication_issue_section.status_change';
export const RUN_VERIFICATION_INITIATED = 'run.verification.initiated';
export const STRIPE_ID_UPDATED = 'stripeid.updated';
export const EMAIL_UPDATED = 'email.updated';
export const INVOICE_REMINDER_BEFORE_TWO_DAYS_DUE = 'invoice.reminder.before.two.days.due';
export const SYNC_TRIGGER_EVENT_TYPES = [
    CUSTOM_ID_UPDATED,
    INVOICE_CREATED,
    INVOICE_PAID_OUTSIDE,
    INVOICE_PAYMENT_INITIATED,
    INVOICE_PAYMENT_PROCESSED_MANUAL,
    INVOICE_PAYMENT_PROCESSED,
    INVOICE_REFUNDED,
    INVOICE_UPFRONT_PAYMENT_WAIVER,
    MANUAL_BUILD_AD_REQUEST,
    MANUAL_CANCEL_BUILD_AD_REQUEST,
    MANUAL_SYNC_REQUEST,
    NOTICE_AT_DEADLINE,
    NOTICE_CANCELLED,
    NOTICE_CONFIRMED,
    NOTICE_CREATED,
    NOTICE_EDITED
];
export const CUSTOM_ID_UPDATED_SYNC = `${CUSTOM_ID_UPDATED}.sync`;
export const INVOICE_CREATED_SYNC = `${INVOICE_CREATED}.sync`;
export const INVOICE_PAID_OUTSIDE_SYNC = `${INVOICE_PAID_OUTSIDE}.sync`;
export const INVOICE_PAYMENT_INITIATED_SYNC = `${INVOICE_PAYMENT_INITIATED}.sync`;
export const INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC = `${INVOICE_PAYMENT_PROCESSED_MANUAL}.sync`;
export const INVOICE_PAYMENT_PROCESSED_SYNC = `${INVOICE_PAYMENT_PROCESSED}.sync`;
export const INVOICE_REFUNDED_SYNC = `${INVOICE_REFUNDED}.sync`;
export const INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC = `${INVOICE_UPFRONT_PAYMENT_WAIVER}.sync`;
export const MANUAL_BUILD_AD_REQUEST_SYNC = `${MANUAL_BUILD_AD_REQUEST}.sync`;
export const MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC = `${MANUAL_CANCEL_BUILD_AD_REQUEST}.sync`;
export const MANUAL_SYNC_REQUEST_SYNC = `${MANUAL_SYNC_REQUEST}.sync`;
export const NOTICE_AT_DEADLINE_SYNC = `${NOTICE_AT_DEADLINE}.sync`;
export const NOTICE_CANCELLED_SYNC = `${NOTICE_CANCELLED}.sync`;
export const NOTICE_CONFIRMED_SYNC = `${NOTICE_CONFIRMED}.sync`;
export const NOTICE_CREATED_SYNC = `${NOTICE_CREATED}.sync`;
export const NOTICE_EDITED_SYNC = `${NOTICE_EDITED}.sync`;
export const SYNC_EVENT_TYPES = [
    CUSTOM_ID_UPDATED_SYNC,
    INVOICE_CREATED_SYNC,
    INVOICE_PAID_OUTSIDE_SYNC,
    INVOICE_PAYMENT_INITIATED_SYNC,
    INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC,
    INVOICE_PAYMENT_PROCESSED_SYNC,
    INVOICE_REFUNDED_SYNC,
    INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC,
    MANUAL_BUILD_AD_REQUEST_SYNC,
    MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC,
    MANUAL_SYNC_REQUEST_SYNC,
    NOTICE_AT_DEADLINE_SYNC,
    NOTICE_CANCELLED_SYNC,
    NOTICE_CONFIRMED_SYNC,
    NOTICE_CREATED_SYNC,
    NOTICE_EDITED_SYNC
];
export var BulkExportStatus;
(function (BulkExportStatus) {
    /**
     * There were notices relevant to the publication date, and the export was successful
     */
    BulkExportStatus["EXPORT_SUCCESS"] = "export_success";
    /**
     * There were no notices relevant to the publication date, so no export was performed
     */
    BulkExportStatus["EXPORT_NO_OP"] = "export_no_op";
    /**
     * There were notices relevant to the publication date, but the export failed
     */
    BulkExportStatus["EXPORT_FAILED"] = "export_failed";
})(BulkExportStatus || (BulkExportStatus = {}));
