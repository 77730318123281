import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { State } from 'lib/enums';
import { exists } from 'lib/types';
import { Autocomplete } from 'lib/components/Autocomplete';
export function SelectPublisherDropdown({ publisherOrganization, onPublisherOrganizationChange, publisherOptions, loading }) {
    const formattedPublisherOptions = publisherOptions.map(formatOptions);
    const noteText = getPublisherLocationNoteText(publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data());
    return (_jsx(Autocomplete, { id: "selectPublisher", labelText: "Publisher", noteText: !loading ? noteText : '', placeholder: "Select a publisher", value: exists(publisherOrganization) && !loading
            ? publisherOrganization.id
            : '', options: formattedPublisherOptions, onChange: onPublisherOrganizationChange, loading: loading }));
}
function formatOptions(paper) {
    return {
        value: paper.id,
        label: paper.name
    };
}
function getPublisherLocationNoteText(publisherOrganization) {
    var _a;
    const { state: stateId } = publisherOrganization || {};
    const stateLabel = (_a = State.by_value(stateId)) === null || _a === void 0 ? void 0 : _a.label;
    const noteText = stateLabel
        ? `This publisher is located in ${stateLabel}.`
        : undefined;
    return noteText;
}
