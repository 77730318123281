const DEFAULT_IMAGE_STYLES = {
    width: '100%',
    objectPosition: 'top',
    objectFit: 'cover',
    height: '100%'
};
/**
 * This function takes an OrderImageCrop and returns the styles that should be applied to the image in React
 * IMPORTANT! Right now this code is making an important assumption that the crop width is always 100%
 * If the crop width is not 100%, then this will break and will not produce the results that we
 * expect!
 */
export const getReactImageStylesFromCrop = (crop) => {
    if (!crop) {
        return DEFAULT_IMAGE_STYLES;
    }
    const { heightPct, widthPct, yPct, xPct } = crop;
    if (heightPct === undefined ||
        widthPct === undefined ||
        yPct === undefined ||
        xPct === undefined) {
        return DEFAULT_IMAGE_STYLES;
    }
    const yPosition = (yPct * 100) / (100 - heightPct);
    return {
        objectFit: 'cover',
        objectPosition: `${xPct}% ${yPosition}%`,
        height: `100%`,
        width: `100%`
    };
};
/**
 * This function takes an OrderImageCrop and returns the styles that should be applied directly in HTML.
 */
export const getInlineCSSStylesFromCrop = (crop) => {
    const imageStyles = getReactImageStylesFromCrop(crop);
    const cssValues = [];
    for (const [property, value] of Object.entries(imageStyles)) {
        if (property === 'objectFit') {
            cssValues.push(`object-fit:${value}`);
        }
        else if (property === 'objectPosition') {
            cssValues.push(`object-position:${value}`);
        }
        else if (property === 'height' || property === 'width') {
            cssValues.push(`${property}:${value}`);
        }
        else {
            throw new Error(`Unknown property ${property}`);
        }
    }
    const inlineString = cssValues.join('; ');
    return inlineString;
};
