import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TrashIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'lib/components/Tooltip';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { RoleType } from 'lib/enums';
import { useRolesForOrg } from 'hooks/useRolesForOrg';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
function InviteMembersInputRow({ index, value, onValueChange, onRoleSelect, onRemoveClick, error, loading }) {
    var _a;
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const { inviteRoles } = useRolesForOrg(activeOrganization);
    return (_jsxs("div", Object.assign({ className: "flex gap-x-5 mt-4" }, { children: [_jsx("div", Object.assign({ className: "w-7/12" }, { children: _jsx(TextField, { id: `email-${index}`, type: "email", labelText: "Email address", placeholder: "user@email.com", value: value.email || '', onChange: value => onValueChange(value), required: true, disabled: loading, errorText: error || '' }) })), _jsx("div", Object.assign({ className: "w-5/12" }, { children: _jsx(ColumnSelect, { id: `role-${index}`, labelText: "Role", allowUndefined: true, options: inviteRoles.map(role => {
                        return { label: role.label, value: role.label };
                    }), onChange: value => onRoleSelect(value), value: ((_a = RoleType.by_value(value.role)) === null || _a === void 0 ? void 0 : _a.label) || '', itemsListDescription: "Choose user's role", required: true }) })), _jsx("div", Object.assign({ className: "text-column-gray-700 mt-12" }, { children: index !== 0 ? (_jsx("button", Object.assign({ onClick: () => onRemoveClick(), disabled: loading }, { children: _jsx(TrashIcon, { className: "h-6 w-6" }) }))) : (_jsx(Tooltip, Object.assign({ helpText: "Roles determine which parts of your Column organization members can edit. If you are unsure, choose User", position: "topLeft" }, { children: _jsx(InformationCircleIcon, { className: "h-6 w-6" }) }))) }))] })));
}
export default InviteMembersInputRow;
