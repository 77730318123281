import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
function PayInvoiceNoticePastDueModal({ setOpen, invoice, notice, onPayInvoiceClicked }) {
    const handleContactSupportClicked = () => {
        const emailSubject = `Invoice ${invoice.id}`;
        window.open(`mailto:help@column.us?subject=${emailSubject} `);
    };
    const firstPublicationDate = notice.data().publicationDates[0].toDate();
    const publicationDatePast = new Date().getTime() > firstPublicationDate.getTime();
    const buttonClasses = 'whitespace-no-wrap font-medium leading-6 mt-3 px-3 py-2 rounded-md shadow-sm hover:shadow-none sm:leading-5 sm:text-sm text-sm md:text-base transition';
    return (_jsx(FreeformCModal, Object.assign({ setOpen: setOpen, header: "Pay invoice?", body: `Upfront payment was required for this notice. Since the newspaper's deadline has already passed, your notice ${publicationDatePast
            ? 'may not have been published'
            : 'may not be published'}. Please contact support for more details.` }, { children: _jsxs("div", Object.assign({ className: "flex flex-row gap-4 mt-4" }, { children: [_jsx(CButton, Object.assign({ id: "modal-contact-support", className: `w-1/2 bg-blue-200 text-blue-700 hover:bg-blue-600 hover:text-white ${buttonClasses}`, onClick: handleContactSupportClicked }, { children: "Contact Support" })), _jsx(CButton, Object.assign({ id: "modal-pay-invoice", className: `w-1/2 bg-red-200 text-red-700 hover:bg-red-600 hover:text-white ${buttonClasses}`, onClick: onPayInvoiceClicked }, { children: "Pay Invoice" }))] })) })));
}
export default PayInvoiceNoticePastDueModal;
