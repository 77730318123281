import Handlebars from 'handlebars';
import { madlibBtoa } from './encoding';
export const registerHandlebarsHelpers = () => {
    Handlebars.registerHelper('input_text', function (options) {
        var _a, _b;
        const key = options.hash.key;
        const keyData = (_b = (_a = options.data) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b[key];
        if (!options.data.editMode) {
            // @ts-expect-error this is the idiomatic way to call handlebars default
            const renderedContents = options.fn(this);
            return renderedContents || keyData;
        }
        const placeholder = options.hash.placeholder;
        const hint = options.hash.hint;
        const transform = options.hash.transform;
        const type = options.hash.type;
        const value = options.data.root[key];
        return new Handlebars.SafeString(`<madlib-input key="${key}" placeholder="${placeholder}" hint="${hint || ''}" transform="${transform}" value="${value || ''}" ${type ? `type="${type}"` : ''}></madlib-input>`);
    });
    Handlebars.registerHelper('input_textarea', function (options) {
        var _a, _b;
        const key = options.hash.key;
        const keyData = (_b = (_a = options.data) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b[key];
        if (!options.data.editMode) {
            // @ts-expect-error this is the idiomatic way to call handlebars default
            const renderedContents = options.fn(this);
            return renderedContents || keyData;
        }
        const placeholder = options.hash.placeholder;
        const hint = options.hash.hint;
        const transform = options.hash.transform;
        const value = options.data.root[key];
        // The value here is arbitrary HTML so we pass it down base64 encoded
        return new Handlebars.SafeString(`<madlib-textarea key="${key}" placeholder="${placeholder}" hint="${hint || ''}" transform="${transform}" encoding="base64" value="${madlibBtoa(value || '')}"></madlib-textarea>`);
    });
    Handlebars.registerHelper('input_date', function (options) {
        var _a, _b;
        const key = options.hash.key;
        const keyData = (_b = (_a = options.data) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b[key];
        if (!options.data.editMode) {
            // @ts-expect-error this is the idiomatic way to call handlebars default
            const renderedContents = options.fn(this);
            return renderedContents || keyData;
        }
        const hint = options.hash.hint;
        const format = options.hash.format;
        const value = options.data.root[key];
        return new Handlebars.SafeString(`<madlib-date-input key="${key}" hint="${hint || ''}" value="${value || ''}" ${format ? `format="${format}"` : ''}></madlib-date-input>`);
    });
    Handlebars.registerHelper('input_multiple_choice', function (options) {
        var _a, _b;
        const key = options.hash.key;
        const keyData = (_b = (_a = options.data) === null || _a === void 0 ? void 0 : _a.root) === null || _b === void 0 ? void 0 : _b[key];
        if (!options.data.editMode) {
            // @ts-expect-error this is the idiomatic way to call handlebars default
            const renderedContents = options.fn(this);
            return renderedContents || keyData;
        }
        const hint = options.hash.hint;
        const value = options.data.root[key];
        const optionsData = options.hash.options;
        return new Handlebars.SafeString(`
    <madlib-multiple-choice-input key="${key}" hint="${hint}" value="${value || ''}" options='${Handlebars.escapeExpression(optionsData)}'
      ></madlib-multiple-choice-input>
    `);
    });
    Handlebars.registerHelper('eq', function (arg1, arg2) {
        return arg1 === arg2;
    });
    Handlebars.registerHelper('lt', function (arg1, arg2) {
        return arg1 < arg2;
    });
    Handlebars.registerHelper('lte', function (arg1, arg2) {
        return arg1 <= arg2;
    });
    Handlebars.registerHelper('gt', function (arg1, arg2) {
        return arg1 > arg2;
    });
    Handlebars.registerHelper('gte', function (arg1, arg2) {
        return arg1 >= arg2;
    });
    Handlebars.registerHelper('add', function (arg1, arg2) {
        return arg1 + arg2;
    });
    Handlebars.registerHelper('sub', function (arg1, arg2) {
        return arg1 - arg2;
    });
    Handlebars.registerHelper('mul', function (arg1, arg2) {
        return arg1 * arg2;
    });
    Handlebars.registerHelper('div', function (arg1, arg2) {
        return arg1 / arg2;
    });
};
