import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import amber from '@material-ui/core/colors/amber';
import computeColors from './lib/theme';
const defaultPrimary = computeColors('#2d9bdb');
const nonProgrammaticDarkBlue = '#24415b';
defaultPrimary[900] = nonProgrammaticDarkBlue;
const defaultTheme = {
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        subtitle2: {
            fontWeight: 200
        }
    },
    palette: {
        primary: defaultPrimary,
        secondary: amber,
        grey: {
            button: '#efefef',
            inputBackground: 'rgba(255, 255, 255, 0.25)',
            scrollFormHeading: 'rgba(0, 0, 0, 0.54)',
            scrollFormBorder: `1px solid rgba(0, 0, 0, 0.1)`,
            scrollFormInteractiveElt: 'rgb(218, 218, 218)',
            scrollFormInteractiveEltHov: 'rgb(190, 190, 190)',
            buttonDark: '#dadada'
        },
        tertiary: {
            main: '#e24f21',
            600: '#cc481f'
        }
    }
};
export default function EnoticeTheme({ children }) {
    const theme = createMuiTheme(defaultTheme);
    return _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: children }));
}
