var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { INVOICE_STATUSES_FUNDS_PENDING, INVOICE_STATUSES_FUNDS_RECEIVED, INVOICE_STATUSES_PAYMENT_OR_PARTIAL_REFUND, INVOICE_STATUSES_UNPAID } from '../model/objects/invoiceModel';
import { cdnIfy, firestoreTimestampOrDateToDate, getDisplayName } from '../helpers';
import { createDBPricingFromNotice, getInvoiceAmountsBreakdown, getPublicationLineItemForDate, getPublicationLineItems, pricesAreTheSame } from '../pricing';
import { exists } from '../types';
import { isElavonInvoice, wasInvoiceProcessedInPayway } from '../types/invoices';
import { COLUMN_LOGO } from '../constants';
import { getOrThrow } from './refs';
export const getRecipientName = (recipient) => {
    return (recipient.name ||
        recipient.organizationName ||
        getDisplayName(recipient.firstName, recipient.lastName));
};
/**
 * Get the email address that should be used for notifications related to an invoice.
 */
export const getNotificationEmailAddress = (notice, invoice) => __awaiter(void 0, void 0, void 0, function* () {
    const { invoiceRecipient } = notice.data();
    if (invoiceRecipient && invoiceRecipient.type === 'email') {
        return invoiceRecipient.email;
    }
    const advertiserSnap = yield getOrThrow(invoice.data().advertiser);
    return advertiserSnap.data().email;
});
/**
 * Determine if an invoice was paid directly to publisher (ex: cash or check)
 * and not through a gateway like Stripe or Payway.
 */
export const isPaidDirectToPublisher = (invoice) => {
    return (exists(invoice) &&
        !!invoice.data().paid_outside_stripe &&
        !wasInvoiceProcessedInPayway(invoice) &&
        !isElavonInvoice(invoice));
};
/**
 * Determine if an invoice was paid outside of our normal Stripe flow.
 * Examples:
 *  - Paid via payway, elavon, etc.
 *  - Paid via cash (publisher marked as paid manually)
 *  - Paid by a bulk invoice which was paid outside the Stripe flow
 */
export const isPaidOutsideStripe = (invoice, bulkInvoice) => {
    if (!exists(invoice)) {
        return false;
    }
    if (exists(bulkInvoice) && invoice.data().paidByBulkInvoice) {
        return (bulkInvoice.data().paid_outside_stripe ||
            bulkInvoice.data().invoiceOutsideColumn);
    }
    return (invoice.data().paid_outside_stripe || invoice.data().invoiceOutsideColumn);
};
/**
 * @deprecated this function is not reliable due to the fact that the 'pricing' object on
 * a notice is often outdated. See ONCALL-2131.
 *
 * Determine if an invoice's price is the default (based on the notice line items) or if it was
 * customized at invoicing time.
 *
 */
export const isInvoicePriceAdjusted = (notice, invoice) => {
    const noticePricing = notice.data().pricing;
    const invoicePricing = getInvoiceAmountsBreakdown(invoice);
    if ((noticePricing === null || noticePricing === void 0 ? void 0 : noticePricing.total) !== invoicePricing.totalInCents ||
        (noticePricing === null || noticePricing === void 0 ? void 0 : noticePricing.subtotal) !== invoicePricing.subtotalInCents) {
        return true;
    }
    return false;
};
/**
 * Check if the notice price and the invoice price are the same
 */
export const noticeAndInvoiceTotalsMatch = (ctx, notice, invoice) => __awaiter(void 0, void 0, void 0, function* () {
    const noticePricing = yield createDBPricingFromNotice(ctx, notice);
    const invoicePricing = getInvoiceAmountsBreakdown(invoice);
    return pricesAreTheSame(invoicePricing.totalInCents, noticePricing.total);
});
/**
 * Payment or partial refund has occurred when the status of the invoice is one of the following
 * 1. paid (value = 3)
 * 2. partially_refunded (value = 6)
 * 3. inititated (an ACH payment started but not fully processed, value = 2)
 */
export const hasPaymentOrPartialRefund = (invoice) => {
    return INVOICE_STATUSES_PAYMENT_OR_PARTIAL_REFUND.includes(invoice.data().status);
};
export const paymentPendingForInvoice = (invoice) => {
    return INVOICE_STATUSES_FUNDS_PENDING.includes(invoice.data().status);
};
export const paymentReceivedForInvoice = (invoice) => {
    return INVOICE_STATUSES_FUNDS_RECEIVED.includes(invoice.data().status);
};
/**
 * Checks not only if the invoice is unpaid but also that the invoice is payable that's why we do not include `unpayable` status
 */
export const isUnpaidSearchableInvoice = (invoice) => {
    return INVOICE_STATUSES_UNPAID.includes(invoice.status) && !invoice.voided;
};
/**
 * Checks not only if the invoice is unpaid but also that the invoice is payable that's why we do not include `unpayable` status
 */
export const isUnpaidInvoice = (invoice) => {
    return isUnpaidSearchableInvoice({
        status: invoice.data().status,
        voided: Number(!!invoice.data().void)
    });
};
/**
 * Check if a notice and invoice have the same publication line items.
 */
export const compareNoticeAndInvoiceLineItems = (noticeSnap, invoiceSnap) => {
    const { publicationDates } = noticeSnap.data();
    const publicationLineItems = getPublicationLineItems(invoiceSnap);
    if (publicationDates.length !== publicationLineItems.length) {
        return { match: false, error: 'wrong_num_line_items' };
    }
    for (const timestampOrDate of publicationDates) {
        const date = firestoreTimestampOrDateToDate(timestampOrDate);
        const matching = getPublicationLineItemForDate(invoiceSnap, date);
        if (!matching) {
            return { match: false, error: 'missing_line_item' };
        }
    }
    return { match: true };
};
/** We currently send a `new_invoice` notification to advertisers when a publisher creates an invoice for a notice; however, this notification only goes to advertisers when the invoice is _not_ invoiced outside Column.
 * Some publishers would still like for advertisers to receive a notification when a notice is invoiced outside Column; the notification will provide the invoice receipt (or invoice, depending on document availability) as a PDF attachment and indicate that a payable invoice will be sent by the publisher.
 * This function determines whether a given invoice should trigger this override.
 * @param invoiceSnap
 * @returns A boolean value that indicates whether invoices created for a given notice should send the invoice override notification described above.
 */
export const getRequiresInvoiceNotificationOverrideFromNotice = (noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g;
    const newspaperSnap = (yield ((_a = noticeSnap
        .data()) === null || _a === void 0 ? void 0 : _a.newspaper.get()));
    const parent = (yield ((_c = (_b = newspaperSnap
        .data()) === null || _b === void 0 ? void 0 : _b.parent) === null || _c === void 0 ? void 0 : _c.get()));
    const propertyExistsOnNewspaper = ((_e = (_d = newspaperSnap.data()) === null || _d === void 0 ? void 0 : _d.sendInvoiceNotificationsForIOCNotices) !== null && _e !== void 0 ? _e : null) !==
        null;
    const propertyExistsOnParent = ((_g = (_f = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _f === void 0 ? void 0 : _f.sendInvoiceNotificationsForIOCNotices) !== null && _g !== void 0 ? _g : null) !== null;
    return propertyExistsOnNewspaper
        ? newspaperSnap.data().sendInvoiceNotificationsForIOCNotices
        : propertyExistsOnParent
            ? parent.data().sendInvoiceNotificationsForIOCNotices
            : false;
});
export const getInvoiceLogoFromNewspaper = (newspaper) => {
    if (!exists(newspaper)) {
        return COLUMN_LOGO;
    }
    const { invoiceConfiguration } = newspaper.data();
    if (!invoiceConfiguration) {
        return COLUMN_LOGO;
    }
    const { logo } = invoiceConfiguration;
    if (!logo) {
        return COLUMN_LOGO;
    }
    return cdnIfy(logo.storagePath, { cloudinaryTransformations: 'e_trim' });
};
