var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { Product } from 'lib/enums';
import { ScheduleChanges } from './ScheduleChanges';
function NoticeScheduleChanges() {
    const activeOrganization = useActiveOrganizationListener();
    if (!exists(activeOrganization))
        return _jsx(LoadingState, {});
    return (_jsx(ScheduleChanges, { productLine: Product.Notice, activeOrganization: activeOrganization, onChange: (newOverrides) => __awaiter(this, void 0, void 0, function* () {
            yield activeOrganization.ref.update({
                deadlineOverrides: newOverrides
            });
        }), deadlineOverrides: activeOrganization.data().deadlineOverrides }));
}
export default NoticeScheduleChanges;
