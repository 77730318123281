import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ACTIVE_NOTES_VIEW, ARCHIVED_NOTES_VIEW } from './notesHelpers';
export function NoteViewToggle({ currentView, onChangeView }) {
    const buttonText = currentView === ARCHIVED_NOTES_VIEW
        ? 'Switch back to active notes'
        : 'Switch to archived notes';
    return (_jsx("div", Object.assign({ className: "py-4 text-center" }, { children: _jsx(ColumnButton, { link: true, buttonText: buttonText, type: "button", onClick: () => onChangeView(currentView === ARCHIVED_NOTES_VIEW
                ? ACTIVE_NOTES_VIEW
                : ARCHIVED_NOTES_VIEW), startIcon: _jsx(ArrowPathRoundedSquareIcon, { className: "h-5 w-5" }) }) })));
}
