var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel, getModelFromRef } from '../index';
import { Collections } from '../../constants';
import { getOverrideAAC } from '../../types/affidavits/convertARS';
import { getErrorReporter } from '../../utils/errors';
import { removeUndefinedFields } from '../../helpers';
import { OrganizationModel } from './organizationModel';
import { ColumnService } from '../../services/directory';
export class CustomerModel extends SnapshotModel {
    constructor() {
        super(...arguments);
        this.type = Collections.customers;
        this.publisher = null;
    }
    /**
     * Updates the account number on the customer.
     *
     * @param {string} accountNumber - The new account number to update.
     * @returns {Promise<string>} - A promise that resolves to the account number
     */
    maybeUpdateAccountNumberOnCustomer(accountNumber) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.modelData.internalID)
                return this.modelData.internalID;
            yield this.update({
                internalID: accountNumber
            });
            return accountNumber;
        });
    }
    getPublisher() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.publisher) {
                this.publisher = yield getModelFromRef(OrganizationModel, this.ctx, this.modelData.organization);
            }
            return this.publisher;
        });
    }
    updateAutomatedAffidavitConfiguration(affidavitReconciliationSettings) {
        return __awaiter(this, void 0, void 0, function* () {
            const publisher = yield this.getPublisher();
            const { response: automatedAffidavitConfiguration, error } = yield getOverrideAAC(this.ctx, publisher, affidavitReconciliationSettings);
            if (error) {
                getErrorReporter().logAndCaptureError(ColumnService.AFFIDAVITS, error, 'Failed to get automated affidavit configuration from affidavit reconciliation settings for customer', {
                    customerId: this.id
                });
                return yield this.ref.update({
                    affidavitReconciliationSettings
                });
            }
            yield this.ref.update(removeUndefinedFields({
                affidavitReconciliationSettings,
                automatedAffidavitConfiguration
            }));
        });
    }
}
