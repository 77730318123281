var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { DeleteIcon, ExternalLinkIcon, AddImageIcon } from 'icons';
import { logAndCaptureException } from 'utils';
import Firebase from 'EnoticeFirebase';
import Modal from 'components/modals/FreeFormCModal';
import FormError from 'routes/errors/FormError';
import CButton from 'components/CButton';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import { dateToAbbrev, firestoreTimestampOrDateToDate, sanitize } from 'lib/helpers';
import { Collections } from 'lib/constants';
import api from 'api';
import { NoticeStatusType } from 'lib/enums';
import { exists } from 'lib/types';
import moment from 'moment';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
const mapStateToProps = (state) => ({
    showAllOrgsNotices: state.auth.showAllOrgsNotices
});
function BulkAffidavitUpload({ setOpen, setMessage, activeOrganization, user, showAllOrgsNotices }) {
    const BUTTON = {
        styles: 'w-4/12 px-4 bg-blue-200 text-blue-700 border border-transparent duration-150 ease-in-out focus:outline-none font-medium leading-6 mt-3 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base transition',
        active: 'hover:bg-blue-600 hover:text-white focus:border-blue-500 ',
        disabled: 'opacity-75 cursor-default'
    };
    const [loading, setLoading] = useState(false);
    const MAX_UPLOADS_CEILING = 10;
    const [maxUploads, setMaxUploads] = useState(MAX_UPLOADS_CEILING);
    const [noticesWithoutAffidavits, setNoticesWithoutAffidavits] = useState([]);
    const [error, setError] = useState();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const MESSAGES = {
        AFFIDAVITS_UPLOADED: 'Success! Your affidavit(s) have been uploaded.'
    };
    const dropzoneRef = useRef();
    const ERRORS = {
        MAX_FILES: `Only ${maxUploads} ${maxUploads > 1 ? 'notices are' : 'notice is'} ready for affidavit upload.`,
        MAX_FILES_CEILING_EXCEEDED: `You have attempted to upload more files than the maximum number of notices that can be uploaded at once. Please reduce the number of files per upload to ${MAX_UPLOADS_CEILING} and try again.`,
        NO_NOTICES_WITH_AFFIDAVITS: 'No notices are ready for affidavit upload.',
        NO_SELECTION: 'All uploaded files must be matched to a notice name.',
        UNSELECTED_UPLOAD: 'A file with no notice exists, please delete additional files or assign notice',
        FILE_LOADING: 'Please wait for a few seconds before we load the file'
    };
    const getAllOutstandingResults = () => __awaiter(this, void 0, void 0, function* () {
        const filters = [
            { affidavitsubmitted: [Number(false)] },
            { hasinvoice: [Number(true)] },
            { isarchived: [Number(false)] },
            { isdraft: [Number(false)] },
            { affidavitdisabled: [Number(false)] }
        ];
        let searchResults = [];
        for (let current = 1; current < 10; current++) {
            const req = {
                showAllOrgsNotices,
                isPublisher: true,
                current,
                filters,
                search: '',
                size: 100,
                activeOrganizationId: activeOrganization.id
            };
            // eslint-disable-next-line no-await-in-loop
            const { results } = yield api.post('search/usernotices', req);
            searchResults = searchResults.concat(results);
            if (results.length < 100) {
                break;
            }
        }
        return searchResults;
    });
    const loadNoticesWithoutAffidavits = () => __awaiter(this, void 0, void 0, function* () {
        const results = yield getAllOutstandingResults();
        const filteredNotices = results.filter((notice) => new Date().getTime() >=
            moment(Number(notice.publicationtimestamps[notice.publicationtimestamps.length - 1]))
                .startOf('day')
                .valueOf());
        setNoticesWithoutAffidavits(filteredNotices);
    });
    useEffect(() => {
        void loadNoticesWithoutAffidavits();
    }, []);
    useEffect(() => {
        if (noticesWithoutAffidavits === null || noticesWithoutAffidavits === void 0 ? void 0 : noticesWithoutAffidavits.length) {
            setMaxUploads(Math.min(noticesWithoutAffidavits.length, MAX_UPLOADS_CEILING));
        }
    }, [noticesWithoutAffidavits]);
    const publishedDatesString = (publicationDates) => !publicationDates
        ? ''
        : publicationDates
            .map((ts) => dateToAbbrev(firestoreTimestampOrDateToDate(ts)))
            .join(', ');
    const getFileNameWithoutExtension = (filename) => {
        const ext = filename.split('.').slice(0, -1).join('.');
        return ext == null ? '' : ext;
    };
    const saveAffidavits = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const filesWithNotices = uploadedFiles.filter(fl => fl.notice);
        if (filesWithNotices.length === 0) {
            setLoading(false);
            setError(ERRORS.NO_SELECTION);
            return;
        }
        if (uploadedFiles.length > filesWithNotices.length) {
            setLoading(false);
            setError(ERRORS.UNSELECTED_UPLOAD);
            return;
        }
        setError('');
        yield Promise.all(filesWithNotices.map(fwn => {
            var _a, _b;
            if (!((_a = fwn.notice) === null || _a === void 0 ? void 0 : _a.id)) {
                return;
            }
            const firstTimeAffidavitUploadedToNotice = !fwn.notice.data().affidavitFirstUploadedAt &&
                !fwn.notice.data().affidavit;
            return getFirebaseContext()
                .userNoticesRef()
                .doc((_b = fwn.notice) === null || _b === void 0 ? void 0 : _b.id)
                .update(Object.assign(Object.assign({ noticeStatus: NoticeStatusType.affidavit_submitted.value, affidavit: fwn.url, affidavitUploadedBy: user }, (firstTimeAffidavitUploadedToNotice
                ? {
                    affidavitFirstUploadedAt: getFirebaseContext()
                        .fieldValue()
                        .serverTimestamp()
                }
                : {})), { affidavitLastUploadedAt: getFirebaseContext()
                    .fieldValue()
                    .serverTimestamp() }));
        }));
        setMessage(MESSAGES.AFFIDAVITS_UPLOADED);
        setOpen(false);
    });
    const matchFiletoNotice = (file) => {
        if (!noticesWithoutAffidavits) {
            setError(ERRORS.NO_NOTICES_WITH_AFFIDAVITS);
            return null;
        }
        const fileNameSplit = file.name.toLowerCase().split(/-/g);
        const fileName = fileNameSplit[fileNameSplit.length - 1];
        const noticeMatched = noticesWithoutAffidavits
            .filter(notice => uploadedFiles.every(file => { var _a; return ((_a = file.notice) === null || _a === void 0 ? void 0 : _a.id) !== notice.id; }))
            .filter(notice => (notice.noticename && notice.noticename.toLowerCase() === fileName) ||
            (notice.id && notice.id.toLowerCase() === fileName))[0];
        if (!noticeMatched) {
            return null;
        }
        return noticeMatched;
    };
    const fileListItems = () => {
        const b = new ClipboardEvent('').clipboardData || new DataTransfer();
        return b.files;
    };
    const clearDropZone = () => {
        if (dropzoneRef && dropzoneRef.current) {
            dropzoneRef.current.files = fileListItems();
        }
    };
    const addFileToNotice = (notice, index) => __awaiter(this, void 0, void 0, function* () {
        const relevantNoticeSnapshot = yield getFirebaseContext()
            .userNoticesRef()
            .doc(notice.id)
            .get();
        const updatedUploadedFiles = Object.assign([...uploadedFiles], {
            [index]: Object.assign(Object.assign({}, uploadedFiles[index]), (relevantNoticeSnapshot.exists && { notice: relevantNoticeSnapshot }))
        });
        setUploadedFiles(updatedUploadedFiles);
    });
    const uploadFiles = (filesToUpload) => __awaiter(this, void 0, void 0, function* () {
        const allFilesLength = uploadedFiles.length + filesToUpload.length;
        if (allFilesLength > MAX_UPLOADS_CEILING) {
            setError(ERRORS.MAX_FILES_CEILING_EXCEEDED);
            return;
        }
        if (allFilesLength > maxUploads) {
            setError(ERRORS.MAX_FILES);
            return;
        }
        setLoading(true);
        const modifiedFiles = [];
        const uploadStartTime = new Date().getTime();
        const promises = filesToUpload.map((file, index) => __awaiter(this, void 0, void 0, function* () {
            const id = `${uploadStartTime}-${index}`;
            const snapshot = yield Firebase.storage()
                .ref()
                .child(`${Collections.affidavits}/${uploadStartTime}/${sanitize(file.name)}`)
                .put(file);
            let modifiedFile = {
                id,
                originalName: file.name,
                name: getFileNameWithoutExtension(file.name),
                url: snapshot.ref.fullPath,
                ref: snapshot.ref,
                affidavitUrl: yield snapshot.ref.getDownloadURL()
            };
            const matchedNotice = matchFiletoNotice(modifiedFile);
            if (matchedNotice) {
                const matchedSnapshot = yield getFirebaseContext()
                    .userNoticesRef()
                    .doc(matchedNotice.id)
                    .get();
                if (exists(matchedSnapshot)) {
                    modifiedFile = Object.assign(Object.assign({}, modifiedFile), { notice: matchedSnapshot });
                }
            }
            modifiedFiles.push(modifiedFile);
        }));
        try {
            yield Promise.all(promises);
        }
        catch (e) {
            logAndCaptureException(ColumnService.AFFIDAVITS, e, 'Error uploading affidavits');
        }
        setUploadedFiles(uploadedFiles => [...uploadedFiles, ...modifiedFiles]);
        setLoading(false);
        setError('');
        clearDropZone();
    });
    const deleteAffidavit = (deleteFile) => {
        setUploadedFiles(files => files.filter(f => f.id !== deleteFile.id));
        setError('');
        clearDropZone();
    };
    const orderNumber = (notice) => {
        if (notice === null || notice === void 0 ? void 0 : notice.customId)
            return `${notice === null || notice === void 0 ? void 0 : notice.customId} `;
        return '';
    };
    const dropRejected = () => {
        setError(noticesWithoutAffidavits.length > 0
            ? ERRORS.MAX_FILES
            : ERRORS.NO_NOTICES_WITH_AFFIDAVITS);
    };
    const fileNameTrim = (value) => {
        if (value.length > 35)
            return `${value.slice(0, 35)}...`;
        return value;
    };
    return (_jsx(Modal, Object.assign({ setOpen: setOpen, body: "Upload affidavit files, then pair with corresponding public notices.", header: "Bulk upload affidavits", noExitOutsideModal: true, width: "md:max-w-4xl" }, { children: _jsxs("div", Object.assign({ className: "mt-5 mb-2", id: "bulk-upload-modal" }, { children: [_jsx("div", Object.assign({ className: loading
                        ? 'grid grid-cols mx-1 justify-center mb-5'
                        : 'grid grid-cols mx-1 mb-5' }, { children: loading ? (_jsx(CircularProgress, {})) : (_jsx("section", Object.assign({ className: "col-span-2" }, { children: _jsx(Dropzone, Object.assign({ multiple: true, onDropRejected: dropRejected, onDrop: (files) => __awaiter(this, void 0, void 0, function* () {
                                uploadedFiles.length < maxUploads
                                    ? yield uploadFiles(files)
                                    : dropRejected();
                            }), accept: '.pdf' }, { children: ({ getRootProps, getInputProps }) => (_jsx("div", Object.assign({ className: "w-full", id: "react-dropZone" }, getRootProps(), { onClick: () => {
                                    dropzoneRef && dropzoneRef.current.click();
                                } }, { children: _jsx("div", Object.assign({ className: "flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-48" }, { children: _jsxs("div", Object.assign({ className: "text-center" }, { children: [_jsx(AddImageIcon, { className: "mx-auto h-12 w-12 text-gray-400" }), _jsx(_Fragment, { children: _jsxs("p", Object.assign({ className: "mt-1 mr-1 text-sm text-gray-600" }, { children: [_jsxs("button", Object.assign({ type: "button", className: "mr-1 font-medium focus:outline-none focus:underline transition duration-150 ease-in-out", style: { color: 'rgb(47, 128, 237)' } }, { children: ["Upload", ' ', uploadedFiles.length > 1 ? 'another' : 'a', " file"] })), "or drag and drop"] })) }), _jsx("input", Object.assign({ id: "temp-upload" }, getInputProps(), { ref: dropzoneRef }))] })) })) }))) })) }))) })), _jsxs("div", Object.assign({ className: uploadedFiles.length > 4
                        ? `grid grid-cols-4 mx-1 h-64 overflow-y-scroll customScroll flex-grow mb-5 mt-5`
                        : 'grid grid-cols-4 mx-1 flex-grow mb-5 mt-5' }, { children: [_jsx("section", Object.assign({ className: "col-span-2 mr-3" }, { children: _jsxs("div", { children: [_jsx("p", Object.assign({ className: "text-xs uppercase text-gray-500 font-medium" }, { children: "Affidavit" })), uploadedFiles.length > 0 ? (uploadedFiles.map((file, index) => (_jsx("div", { children: _jsx(CTooltip, Object.assign({ position: "left", helpText: file.originalName }, { children: _jsxs("div", Object.assign({ className: "flex w-full mb-2 relative h-10 bg-white items-center border border-grey rounded" }, { children: [_jsx("input", { type: "text", className: "border-0 h-8 border-grey-light rounded rounded-l-none px-3 w-full self-center relative  font-roboto text-sm outline-none", placeholder: "Waiting for file upload...", value: fileNameTrim(file.originalName), readOnly: true }), _jsx("div", Object.assign({ onClick: () => {
                                                            var _a;
                                                            if (!file.affidavitUrl) {
                                                                setError(ERRORS.FILE_LOADING);
                                                                return;
                                                            }
                                                            (_a = window === null || window === void 0 ? void 0 : window.open(file.affidavitUrl, '_blank')) === null || _a === void 0 ? void 0 : _a.focus();
                                                        }, id: "open-new-tab-icon", className: "absolute inset-y-0 z-10 right-0 pr-3 flex items-center mr-6 text-gray-750 cursor-pointer" }, { children: _jsx(ExternalLinkIcon, {}) })), _jsx("div", Object.assign({ onClick: () => deleteAffidavit(file), id: "delete-icon", className: "absolute inset-y-0 z-10 right-0 pr-3 flex items-center cursor-pointer" }, { children: _jsx(DeleteIcon, {}) }))] })) })) }, index)))) : (_jsx("div", Object.assign({ className: "flex flex-wrap w-full mb-2 relative h-10 bg-white items-center border border-grey rounded" }, { children: _jsx("input", { type: "text", className: "border-0 h-8 border-grey-light rounded rounded-l-none px-3 self-center relative  font-roboto text-sm outline-none", placeholder: "Waiting for file upload...", readOnly: true }) })))] }) })), _jsx("section", Object.assign({ className: "col-span-2" }, { children: _jsxs("div", Object.assign({ className: "h-full" }, { children: [_jsx("p", Object.assign({ className: "text-xs uppercase text-gray-500 font-medium" }, { children: "Notice" })), uploadedFiles.length > 0 &&
                                        noticesWithoutAffidavits.length > 0 ? (uploadedFiles.map((file, index) => {
                                        var _a, _b;
                                        return (_jsx("div", Object.assign({ className: "flex flex-wrap w-full mb-2 relative h-10 bg-white items-center rounded" }, { children: _jsx(SelectDropdown, { id: "notices-dropdown", className: "w-100", notSearchable: true, selected: {
                                                    id: file.id || 'select-notice',
                                                    label: file.notice
                                                        ? `${orderNumber(file.notice.data())}${file.notice.data().referenceId || file.notice.id} - ${publishedDatesString(file.notice
                                                            .data()
                                                            .publicationDates.map(timestamp => timestamp.toDate()))}`
                                                        : 'Select notice'
                                                }, value: {
                                                    id: file.id,
                                                    label: file.notice
                                                        ? `${orderNumber(file.notice.data())}${file.notice.data().referenceId || ((_a = file.notice) === null || _a === void 0 ? void 0 : _a.id)} - ${publishedDatesString(file.notice
                                                            .data()
                                                            .publicationDates.map((timestamp) => timestamp.toDate()))}`
                                                        : 'Select notice'
                                                }, placeholder: file.notice
                                                    ? `${orderNumber(file.notice.data())}${file.notice.data().referenceId || ((_b = file.notice) === null || _b === void 0 ? void 0 : _b.id)} - ${publishedDatesString(file.notice
                                                        .data()
                                                        .publicationDates.map(timestamp => timestamp.toDate()))}`
                                                    : 'Select notice', onChange: (notice) => __awaiter(this, void 0, void 0, function* () {
                                                    if (!notice)
                                                        return;
                                                    setError('');
                                                    yield addFileToNotice(notice, index);
                                                }), options: noticesWithoutAffidavits
                                                    .filter(notice => uploadedFiles.every(file => { var _a; return ((_a = file.notice) === null || _a === void 0 ? void 0 : _a.id) !== notice.id; }))
                                                    .map(notice => {
                                                    var _a;
                                                    return {
                                                        id: notice.id,
                                                        label: `${orderNumber((_a = file.notice) === null || _a === void 0 ? void 0 : _a.data())}${notice.noticename || notice.id} - ${publishedDatesString(notice.publicationtimestamps.map(timestamp => moment
                                                            .utc(Number(timestamp))
                                                            .format('YYYY/MM/DD')))}`
                                                    };
                                                }) }) }), index));
                                    })) : (_jsx("div", Object.assign({ className: "flex w-full mb-2 relative h-10 bg-white items-center border border-grey rounded" }, { children: _jsx("input", { type: "text", className: "border-0 h-8 border-grey-light rounded rounded-l-none px-3 w-full self-center relative  font-roboto text-sm outline-none", placeholder: 'Select Notice', 
                                            // value={''}
                                            readOnly: true }) })))] })) }))] })), error && (_jsx("div", Object.assign({ className: "mb-6" }, { children: _jsx(FormError, { error: error }) }))), _jsx(CButton, Object.assign({ id: "save", onClick: () => {
                        if (uploadedFiles.length > 0)
                            void saveAffidavits();
                    }, className: `${BUTTON.styles} ${uploadedFiles.length > 0 ? BUTTON.active : BUTTON.disabled} ` }, { children: "Upload" }))] })) })));
}
export default connect(mapStateToProps)(BulkAffidavitUpload);
