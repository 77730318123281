var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import LoadingState from 'components/LoadingState';
import { TableLayout } from 'lib/components/TableLayout';
import SubscriptionEditForm from './subscriptionEditForm';
import SubscriptionDrawer from './subscriptionDrawer';
export default function SubscriptionManagement() {
    const [subscriptions, setSubscriptions] = useState();
    const [drawerSubscription, setDrawerSubscription] = useState();
    const [editedSubscription, setEditedSubscription] = useState();
    // TODO: update the behavior here to be on a per-publisher basis
    useEffect(() => {
        const unsubscribe = getFirebaseContext()
            .subscriptionsRef()
            .where('mailing.mailEnabled', '==', true)
            .onSnapshot(snapshot => {
            setSubscriptions(snapshot.docs);
        });
        return () => unsubscribe();
    }, []);
    if (!subscriptions) {
        return _jsx(LoadingState, {});
    }
    return (_jsxs("div", Object.assign({ className: "bg-column-gray-25 w-full flex flex-col shadow rounded-md overflow-hidden" }, { children: [drawerSubscription && (_jsx(SubscriptionDrawer, { setEditedSubscription: setEditedSubscription, drawerSubscription: drawerSubscription, closeDrawer: () => setDrawerSubscription(undefined) })), editedSubscription && (_jsx(SubscriptionEditForm, { editedSubscription: editedSubscription, closeForm: () => setEditedSubscription(undefined) })), _jsx(TableLayout, { archivable: {
                    renderWarning: subscription => ({
                        header: 'Are you sure you want to delete this subscription?',
                        body: `${subscription.data().email || 'The recipient'} will no longer receive notifications for ${subscription.data().query} when new public notices are published.`,
                        buttonText: 'Delete'
                    }),
                    onArchive: (subscription) => __awaiter(this, void 0, void 0, function* () {
                        yield subscription.ref.delete();
                    }),
                    isArchiveDisabled: false
                }, clickable: {
                    onClick: setDrawerSubscription
                }, filterable: {
                    shouldShowTableItem: (item, filter) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
                        return !!(item.data().email.toLowerCase().includes(filter) ||
                            item.data().query.toLowerCase().includes(filter) ||
                            ((_c = (_b = (_a = item
                                .data()
                                .mailing) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(filter)) ||
                            ((_f = (_e = (_d = item
                                .data()
                                .mailing) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.line1) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes(filter)) ||
                            ((_j = (_h = (_g = item
                                .data()
                                .mailing) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.line2) === null || _j === void 0 ? void 0 : _j.toLowerCase().includes(filter)) ||
                            ((_m = (_l = (_k = item
                                .data()
                                .mailing) === null || _k === void 0 ? void 0 : _k.address) === null || _l === void 0 ? void 0 : _l.city) === null || _m === void 0 ? void 0 : _m.toLowerCase().includes(filter)) ||
                            ((_q = (_p = (_o = item
                                .data()
                                .mailing) === null || _o === void 0 ? void 0 : _o.address) === null || _p === void 0 ? void 0 : _p.state) === null || _q === void 0 ? void 0 : _q.toLowerCase().includes(filter)) ||
                            ((_t = (_s = (_r = item
                                .data()
                                .mailing) === null || _r === void 0 ? void 0 : _r.address) === null || _s === void 0 ? void 0 : _s.postalCode) === null || _t === void 0 ? void 0 : _t.toLowerCase().includes(filter)));
                    }
                }, header: {
                    title: 'Subscriptions',
                    subtitle: `Manage alert subscriptions to your display site.`
                }, data: subscriptions, id: "subscription-management", columns: ['Name', 'Search terms', 'Address'], editable: {
                    onEdit(subscription) {
                        setEditedSubscription(Object.assign(Object.assign({}, subscription.data()), { id: subscription.id }));
                    }
                }, creatable: {
                    createButtonText: 'Create new subscription',
                    onCreate() {
                        setEditedSubscription({
                            elasticFilters: [],
                            email: '',
                            query: '',
                            id: null,
                            mailing: {
                                mailEnabled: true
                            }
                        });
                    }
                }, renderRow: subscription => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                    return (_jsxs(_Fragment, { children: [_jsx("td", { children: (_b = (_a = subscription.data().mailing) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.name }), _jsx("td", { children: subscription.data().query }), _jsxs("td", { children: [(_d = (_c = subscription.data().mailing) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.line1, ((_f = (_e = subscription.data().mailing) === null || _e === void 0 ? void 0 : _e.address) === null || _f === void 0 ? void 0 : _f.line2) && (_jsxs(_Fragment, { children: [_jsx("br", {}), (_h = (_g = subscription.data().mailing) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.line2] })), _jsx("br", {}), (_k = (_j = subscription.data().mailing) === null || _j === void 0 ? void 0 : _j.address) === null || _k === void 0 ? void 0 : _k.city, ",", ' ', (_m = (_l = subscription.data().mailing) === null || _l === void 0 ? void 0 : _l.address) === null || _m === void 0 ? void 0 : _m.state, ' ', (_p = (_o = subscription.data().mailing) === null || _o === void 0 ? void 0 : _o.address) === null || _p === void 0 ? void 0 : _p.postalCode] })] }));
                } })] })));
}
