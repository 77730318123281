var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ColumnService } from 'lib/services/directory';
import { useEffect, useState } from 'react';
import { getErrorReporter } from '../../utils/errors';
export function useFirebaseStorageDownloadUrl(storageRef) {
    const [downloadUrl, setDownloadUrl] = useState('');
    const [previewState, setPreviewState] = useState('loading');
    useEffect(() => {
        const getDownloadUrl = () => __awaiter(this, void 0, void 0, function* () {
            if (!storageRef) {
                return;
            }
            try {
                setPreviewState('loading');
                const downloadUrl = yield storageRef.getDownloadURL();
                setDownloadUrl(downloadUrl);
                setPreviewState('idle');
            }
            catch (error) {
                setPreviewState('error');
                getErrorReporter().logAndCaptureError(ColumnService.STATIC_ASSETS, error, 'Unable to get download url', {
                    storagePath: storageRef.fullPath
                });
            }
        });
        void getDownloadUrl();
    }, [storageRef === null || storageRef === void 0 ? void 0 : storageRef.fullPath]);
    return {
        downloadUrl,
        previewState
    };
}
