var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';
import PlacementActions from 'redux/placement';
import { selectContextOrganizationId } from 'redux/auth';
import { getFirebaseContext } from 'utils/firebase';
import { NoticeType } from '../../lib/enums';
import AddNoticeTypeScreen from './AddNoticeTypeScreen';
const drawerWidth = 35;
const styles = (theme) => createStyles({
    root: {
        display: 'flex'
    },
    drawer: {
        width: theme.spacing(drawerWidth),
        flexShrink: 0
    },
    drawerPaper: {
        width: theme.spacing(drawerWidth)
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar
});
function AddNotices({ noticeId, noticeSlug, user }) {
    const [newspaper, setNewspaper] = useState();
    const [draftId, setDraftId] = useState('');
    const fetchNewspaper = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const noticeSnap = yield getFirebaseContext()
            .userNoticesRef()
            .doc(noticeId)
            .get();
        if (!exists(noticeSnap)) {
            return;
        }
        const drafts = yield Promise.all((noticeSnap.data().drafts || []).map(draft => draft.get()));
        const draftSnap = user
            ? drafts.find(draft => { var _a, _b; return ((_b = (_a = draft === null || draft === void 0 ? void 0 : draft.data()) === null || _a === void 0 ? void 0 : _a.owner) === null || _b === void 0 ? void 0 : _b.id) === user.id; })
            : drafts[0];
        if (draftSnap) {
            setDraftId(draftSnap.id);
            const newspaperSnap = yield ((_a = draftSnap.data()) === null || _a === void 0 ? void 0 : _a.newspaper.get());
            if (newspaperSnap) {
                setNewspaper(newspaperSnap.data());
            }
        }
    });
    useEffect(() => {
        void fetchNewspaper();
    }, []);
    // get *probate* from e.g. /subnotices/probate/
    if (!newspaper)
        return _jsx(LoadingState, {});
    const matchingNotice = NoticeType.by_key(noticeSlug);
    let notices = matchingNotice
        ? matchingNotice.children()
        : NoticeType.rootItems();
    notices = notices.filter(notice => { var _a; return (_a = notice.states) === null || _a === void 0 ? void 0 : _a.includes(newspaper.state); });
    return (_jsx(AddNoticeTypeScreen, { noticesToDisplay: notices, noticeId: noticeId, draftId: draftId }));
}
const mapStateToProps = (state) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        noticeId: (_b = (_a = matchPath(state.router.location.pathname, {
            path: '/file/:noticeId/:noticeSlug?',
            exact: true,
            strict: false
        })) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.noticeId,
        noticeSlug: (_d = (_c = matchPath(state.router.location.pathname, {
            path: '/file/:noticeId/:noticeSlug?',
            exact: true,
            strict: false
        })) === null || _c === void 0 ? void 0 : _c.params) === null || _d === void 0 ? void 0 : _d.noticeSlug,
        noticeType: (_f = (_e = matchPath(state.router.location.pathname, {
            path: '/file/by-type/:noticeSlug',
            exact: true,
            strict: false
        })) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.noticeSlug,
        user: state.auth.user,
        draft: state.placement.draftSnap
    });
};
const mapDispatchToProps = (dispatch) => ({
    placementActions: bindActionCreators(PlacementActions, dispatch),
    push: (path) => dispatch(push(path))
});
function AddNoticeWrapper(_a) {
    var { placementActions, noticeType, draft, push } = _a, props = __rest(_a, ["placementActions", "noticeType", "draft", "push"]);
    const contextOrganizationId = useAppSelector(selectContextOrganizationId);
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (!noticeType) {
            setInitialized(true);
            return;
        }
        if (draft)
            return;
        if (!contextOrganizationId)
            return;
        if (initialized)
            return;
        if (noticeType) {
            placementActions.hydrateNoticeData(null, false);
        }
    }, [draft === null || draft === void 0 ? void 0 : draft.id, contextOrganizationId]);
    useEffect(() => {
        const processPopulatedDraft = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!noticeType || !draft)
                return;
            const noticeTypeValue = (_a = NoticeType.by_key(noticeType)) === null || _a === void 0 ? void 0 : _a.value;
            yield draft.ref.update({
                noticeType: noticeTypeValue
            });
            setInitialized(true);
            push(`/form/${draft.id}/${noticeTypeValue}`);
        });
        void processPopulatedDraft();
    }, [draft === null || draft === void 0 ? void 0 : draft.id]);
    if (!initialized)
        return _jsx(LoadingState, {});
    return _jsx(AddNotices, Object.assign({}, props));
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddNoticeWrapper));
