import { fileTypeIsImage } from 'lib/helpers';
import { FileType } from 'lib/types/mime';
export var AdTypeRecommendation;
(function (AdTypeRecommendation) {
    AdTypeRecommendation[AdTypeRecommendation["NoRecommendation"] = 1] = "NoRecommendation";
    AdTypeRecommendation[AdTypeRecommendation["AsyncDisplay"] = 2] = "AsyncDisplay";
    AdTypeRecommendation[AdTypeRecommendation["SyncDisplay"] = 3] = "SyncDisplay";
    AdTypeRecommendation[AdTypeRecommendation["Liner"] = 4] = "Liner";
})(AdTypeRecommendation || (AdTypeRecommendation = {}));
// hard coded settings
// TODO: change these as we learn more about what is a display ad
const MAX_NUMBER_OF_IMAGES = 3;
const MAX_NUMBER_OF_PAGES = 5;
const MAX_TABLE_DIMENSION = 3;
const MAX_CLOUDINARY_FILESIZE = 40000000;
const MAX_CLOUDINARY_PIXELS = 50000000;
const shouldBeAsyncDisplayAd = (documentProperties) => {
    const { metadata, dimensions } = documentProperties;
    // If the document is greater than the max Cloudinary filesize, recommend an async display
    const isLargerThanMaxFilesize = metadata.fileSizeBytes > MAX_CLOUDINARY_FILESIZE;
    // If the document is over N pages, recommend an async display
    const hasTooManyPagesForSyncDisplay = metadata.numPages > MAX_NUMBER_OF_PAGES;
    // If the document is an image and it's over the max number of pixels Cloudinary accepts, recommend an async display
    const hasTooManyPixels = dimensions.height * dimensions.width > MAX_CLOUDINARY_PIXELS;
    const isReallyBigImage = fileTypeIsImage(documentProperties.fileType) && hasTooManyPixels;
    return (hasTooManyPagesForSyncDisplay || isReallyBigImage || isLargerThanMaxFilesize);
};
const getIsComplexWordDocument = (documentProperties) => {
    const { fileType, features } = documentProperties;
    if (fileType !== FileType.WORD_DOC) {
        return false;
    }
    const wordDocumentFeatures = features;
    // If the document uses headers, footers, or columns, recommend a sync display
    // View the following Loom on what is a header, footer, and column: https://www.loom.com/share/f43da8f99d83421b87c0f8e47e63dca3
    const hasDifficultToExtractText = wordDocumentFeatures.headers ||
        wordDocumentFeatures.footers ||
        wordDocumentFeatures.columns;
    // If the document has a table, we should recommend a synchronous display if any table dimension is above the max
    // TODO: Are there scenarios where we should recommend an async display based on certain table properties?
    const hasBigTable = wordDocumentFeatures.tables.some(table => table.rows > MAX_TABLE_DIMENSION ||
        table.cells / table.rows > MAX_TABLE_DIMENSION);
    return hasDifficultToExtractText || hasBigTable;
};
const shouldBeSyncDisplayAd = (documentProperties, allowImages) => {
    const { features } = documentProperties;
    // Noticed a lot of notices being uploaded as images included complex table content. For now, let's suggest a sync display
    // if the file uploaded is within the pixel dimension
    const isStaticFile = fileTypeIsImage(documentProperties.fileType) ||
        documentProperties.fileType === FileType.PDF;
    const isComplexWordDocument = getIsComplexWordDocument(documentProperties);
    // If a document has more than "N" images, it should be a display ad unless it is logos in liners
    const hasTooManyImagesForLiner = features.images > MAX_NUMBER_OF_IMAGES && !allowImages;
    return isStaticFile || isComplexWordDocument || hasTooManyImagesForLiner;
};
export const getAdTypeRecommendation = (documentProperties, allowImages) => {
    if (shouldBeAsyncDisplayAd(documentProperties)) {
        return AdTypeRecommendation.AsyncDisplay;
    }
    if (shouldBeSyncDisplayAd(documentProperties, allowImages)) {
        return AdTypeRecommendation.SyncDisplay;
    }
    return AdTypeRecommendation.Liner;
};
