import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import CustomerDrawer from 'components/CustomerDrawer/CustomerDrawer';
import CustomerOrganizationDrawer from 'components/CustomerDrawer/CustomerOrganizationDrawer';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import EditCustomerModal from 'components/CustomerDrawer/EditCustomerModal';
import EditCustomerOrganizationModal from 'components/CustomerDrawer/EditCustomerOrganizationModal';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import TabGroup from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import { NoticeRateService } from 'lib/services/noticeRateService';
import CustomerOrganizationTable from './customerOrganizationTable';
import CustomerTable from './customerTable';
import { useGetCustomerTableData } from './customerTable/useGetCustomerTableData';
import { usePublisherOrgRates } from '../rates/hooks/usePublisherOrgRates';
const CUSTOMERS_TABLE_TAB = {
    label: 'Customers',
    enabled: true,
    id: 'customers-settings-tab'
};
const CUSTOMER_ORGANIZATIONS_TABLE_TAB = {
    label: 'Organizations',
    enabled: true,
    id: 'customer-organizations-settings-tab'
};
export default function CustomersSettings({ activeOrganization }) {
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedsearchQuery = useDebounce(searchQuery, 500);
    const { customers, loading, setRefreshCustomersList } = useGetCustomerTableData(debouncedsearchQuery);
    const [activeTab, setActiveTab] = useState(CUSTOMERS_TABLE_TAB);
    const [customerId, setCustomerId] = useState();
    const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
    const [customerOrg, setCustomerOrg] = useState();
    const [showCustomerOrgDrawer, setShowCustomerOrgDrawer] = useState(false);
    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [showEditCustomerOrgModal, setShowEditCustomerOrgModal] = useState(false);
    useEffect(() => {
        const customerIdFromUrl = getLocationParams().get('customerId');
        if (customerIdFromUrl) {
            setCustomerId(customerIdFromUrl);
            setShowCustomerDrawer(true);
        }
    }, [getLocationParams().get('customerId')]);
    const context = getFirebaseContext();
    /*
     * Load the customer and user snaps from Firestore anytime the
     *  target customerId changes
     */
    const customerRef = customerId !== null && customerId !== undefined
        ? context.customersRef().doc(customerId)
        : null;
    const customerSnap = useFirestoreSnapshot(customerRef);
    const userSnap = useFirestoreSnapshot(customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().user);
    const rateService = new NoticeRateService(context);
    const { orgRates } = usePublisherOrgRates(rateService, activeOrganization.ref, {
        includeArchived: true
    });
    return (_jsxs(_Fragment, { children: [_jsxs(SettingsPage, { children: [_jsx(TabGroup, { onClickTab: tab => setActiveTab(tab), activeTab: activeTab, tabs: [CUSTOMERS_TABLE_TAB, CUSTOMER_ORGANIZATIONS_TABLE_TAB], id: "customers-settings-tabs" }), activeTab.label === CUSTOMERS_TABLE_TAB.label && (_jsx(CustomerTable, { data: customers !== null && customers !== void 0 ? customers : [], loading: loading, updateTableData: () => setRefreshCustomersList(true), activeOrganization: activeOrganization, onClickRow: id => {
                            setCustomerId(id);
                            setShowCustomerDrawer(true);
                        }, onClickEdit: id => {
                            setCustomerId(id);
                            setShowEditCustomerModal(true);
                        }, onChangeSearch: query => setSearchQuery(query), rates: orgRates })), activeTab.label === CUSTOMER_ORGANIZATIONS_TABLE_TAB.label && (_jsx(CustomerOrganizationTable, { activeOrganization: activeOrganization, setCustomerOrg: org => setCustomerOrg(org), setShowCustomerOrgDrawer: show => setShowCustomerOrgDrawer(show), setShowEditCustomerOrgModal: show => setShowEditCustomerOrgModal(show) }))] }), showCustomerDrawer && exists(customerSnap) && exists(userSnap) && (_jsx(CustomerDrawer, { onCloseDrawer: () => {
                    setShowCustomerDrawer(false);
                    setCustomerId(null);
                }, onEditCustomer: () => {
                    setShowCustomerDrawer(false);
                    setShowEditCustomerModal(true);
                }, setShowCustomerOrganizationDrawer: show => setShowCustomerOrgDrawer(show), setCustomerOrganization: org => setCustomerOrg(org), customerSnap: customerSnap, userSnap: userSnap, activeOrganization: activeOrganization })), showCustomerOrgDrawer && customerOrg && (_jsx(CustomerOrganizationDrawer, { closeDrawer: () => {
                    setShowCustomerOrgDrawer(false);
                }, setShowCustomerDrawer: show => setShowCustomerDrawer(show), setCustomerId: id => setCustomerId(id), setCustomerOrg: org => setCustomerOrg(org), customerOrganizationData: customerOrg, activeOrganization: activeOrganization, rates: orgRates, open: true })), showEditCustomerModal && userSnap && customerSnap && (_jsx(EditCustomerModal, { closeModal: edited => {
                    setShowEditCustomerModal(false);
                    setCustomerId(null);
                    if (edited) {
                        setRefreshCustomersList(true);
                    }
                }, userSnap: userSnap, customerSnap: customerSnap, activeOrganization: activeOrganization, rates: orgRates })), showEditCustomerOrgModal && customerOrg && (_jsx(EditCustomerOrganizationModal, { onClose: () => {
                    setShowEditCustomerOrgModal(false);
                    setShowCustomerOrgDrawer(false);
                    setCustomerOrg(undefined);
                }, customerOrganizationData: customerOrg, activeOrganization: activeOrganization, rates: orgRates }))] }));
}
