/**
 * @returns amount of total refundAmountInCents to recover from the publisher;
 * which value is ratable (proportional to the ratio the publisherAmount bears to
 * the total amount paid) on partial refunds v2; otherwise, it is the refundAmount
 * minus the columnAmountInCents (min of 0).
 */
export const getAmountOfRefundToRecoverFromPublisherInCents = (refundAmountInCents, totalInCents, publisherAmountInCents, columnAmountInCents, enablePartialRefundsv2) => {
    return enablePartialRefundsv2
        ? /* When a partial refund is requested, it is drawn proportionately from the publisher and Column */
            Math.ceil(refundAmountInCents * (publisherAmountInCents / totalInCents))
        : /* DEPRECATED--we no longer use this method of calculating the amount to recover from the publisher
        historically, for partial refunds we drew first from the Column fee then from the publisher amount */
            Math.max(refundAmountInCents - columnAmountInCents, 0);
};
